import {connect} from 'react-redux'

import {Languages} from './Languages'
import {AppDispatch} from 'Store'
import {
  getLanguages,
  getLanguageLevels,
  addLanguage,
  editLanguage,
  deleteLanguage,
} from 'Store/Actions'
import {RootState} from 'Store/Reducers'
import {LanguageType} from 'Interfaces'
import {canChangeProfile} from 'Utils/Profile'

const mapStateToProps = (state: RootState) => ({
  taxonomies: state.taxonomies,
  profile: state.seeker,
  languages: state.seeker.data?.full?.languages || [],
  canChange: canChangeProfile(state.auth.data?.rjbProfileStatus),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getTaxonomies: () => {
    dispatch(getLanguages())
    dispatch(getLanguageLevels())
  },
  addLanguage: (value: LanguageType) => dispatch(addLanguage(value)),
  editLanguage: (value: LanguageType) => dispatch(editLanguage(value)),
  deleteLanguage: (value: LanguageType) => dispatch(deleteLanguage(value)),
})

export const LanguagesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Languages)
