const onboarding = {
  'onboarding.jobCategory.required': 'Job category is required',
  'Job category is required.': 'Job category is required.',
  'Regions are required': 'Regions are required',
  'Work from home field is required': 'Work from home field is required',
  'Salary is required': 'Salary is required',
  'Job search status is required': 'Job search status is required',
  'Availability is required': 'Availability is required',
  'Desired job positions are required': 'Desired job positions are required',
  'Select max of 3 job positions': 'Select max of 3 job positions',
  'Current job title field is required': 'Current job title field is required',
  'Total years of experience field is required':
    'Total years of experience field is required',
  'Job position is required': 'Job position is required',
  'Skills are required': 'Skills are required',
  'Desired work environment description is required':
    'Desired work environment description is required',
  'Additional benefits field is required':
    'Additional benefits field is required',
  'Education is required': 'Education is required',
  'Field of study is required': 'Field of study is required',
  'Current salary is required': 'Current salary is required',
  'Description is too long.': 'Description is too long',
  'Title is too long.': 'Title is too long',
  'Phone number must be a valid international phone number.':
    'Phone number must be a valid international phone number.',
  'Phone number is required': 'Phone number is required',
  'Street and number are required': 'Street and number are required',
  'Must be 50 characters or less': 'Must be 50 characters or less',
  'Other job category value must be 50 characters or less':
    'Other job category value must be 50 characters or less',
  'Job position entered under “Other“ can not contain delimiter symbols ( , ; | )':
    'Job position entered under “Other“ can not contain delimiter symbols ( , ; | )',
  'Enter a valid postal code': 'Enter a valid postal code',
  'Zip code is required': 'Zip code is required',
  'Town is required': 'City is required',
  'Country is required': 'Country is required',
  'Must be longer than or equal to 4 characters':
    'Must be longer than or equal to 4 characters',
  'Must be shorter than or equal to 6 characters':
    'Must be shorter than or equal to 6 characters',
  'Postal code must have 4 digits for Slovenia':
    'Postal code must have 4 digits for Slovenia',
  'Attachment is required': 'Attachment is required',
  'File too large': 'File too large',
  'Contact’s Person Position in Company is required':
    'Contact’s Person Position in Company is required',
  'Address is required': 'Address is required',
  'Company long name is required': 'Company long name is required',
  'Field is required': 'Field is required',
  'Accepting Privacy policy is required':
    'Accepting Privacy policy is required',
  'Number of recruits field is required':
    'Number of recruits field is required',
  'Salary range is required': 'Salary range is required',
  'Fill education level field': 'Fill education level field',
  'Fill field of study field': 'Fill field of study field',
  'Fill occupation field': 'Fill occupation field',
  'Fill salary field': 'Fill salary field',
  'Fill work experience field': 'Fill work experience field',
}

export default onboarding
