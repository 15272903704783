import {connect} from 'react-redux'

import {EmailVerificationNotice} from './EmailVerificationNotice'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {getUser} from 'Store/Actions'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getUser: () => dispatch(getUser()),
})

export const EmailVerificationNoticeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailVerificationNotice)
