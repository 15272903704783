export const emailchangeverification = {
  'emailchangeverification.successTitle': 'E-mail changed successfully',
  'emailchangeverification.successLinkText': 'Back to dashboard',

  'emailchangeverification.pending': 'Verification pending',
  'emailchangeverification.pleaseWaitText':
    'Please wait a moment. You will be redirected automatically.',

  'emailchangeverification.verificationFailed': 'Verification failed.',
  'emailchangeverification.verificationFailedDescription':
    'Please log into your account and try again.',
}
