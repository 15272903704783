export const blacklistsettings = {
  'blacklistsettings.title': 'Employer Black list',
  'blacklistsettings.titleBlockedCompanies': 'BLOCKED COMPANIES',
  'blacklistsettings.descriptionBlockedCompanies':
    'Block companies from seeing your profile. Any employer listed here will not be able to search for or view your profile',
  'blacklistsettings.enterCompanyName': 'Enter company name...',
  'blacklistsettings.noData':
    'You don’t have any companies on Employer Black List. Add them by entering company name in field above.',
  'blacklistsettings.removeCompany': 'Remove company',
  'blacklistsettings.yesImSure': "Yes, I'm sure",
  'blacklistsettings.areYouSure': 'Are you sure you want to remove',
  'blacklistsettings.fromBlocked': 'from blocked companies?',
}
