import {FC} from 'react'
import {Add as AddIcon, EditOutlined} from '@mui/icons-material'
import classnames from 'classnames'
import styles from './SectionHeader.module.scss'
import Title from 'Components/Title'

interface SectionHeaderProps {
  title: string
  onAdd?: () => void
  onEdit?: () => void
  theme?: string
  className?: string
}

export const SectionHeader: FC<SectionHeaderProps> = ({
  title,
  onAdd,
  theme,
  onEdit,
  className,
}: SectionHeaderProps) => {
  return (
    <div
      className={classnames(styles.header, className, {
        [styles.noPaddingX]: theme === 'noPaddingX',
      })}
    >
      <Title text={title} />
      {onAdd && (
        <button
          onClick={(e) => {
            e.preventDefault()
            onAdd()
          }}
        >
          <AddIcon />
        </button>
      )}
      {onEdit && (
        <button
          onClick={(e) => {
            e.preventDefault()
            onEdit()
          }}
        >
          <EditOutlined className={styles.edit} sx={{fontSize: 25}} />
        </button>
      )}
    </div>
  )
}
