import {FC, useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useLocation} from 'react-router'
import {Badge} from '@mui/material'

import {AuthStateType, TaxonomiesStateType} from 'Interfaces'
import styles from './LeftSidebar.module.scss'
import Preferenca from 'Assets/Images/preferenca-logo-white.svg'
import {
  Menu,
  Close,
  FaceOutlined,
  DescriptionOutlined,
  LogoutOutlined,
  WorkOutline,
  StarBorderOutlined,
  ForumOutlined,
  DateRangeOutlined,
  // InsertChartOutlinedOutlined,
  // MarkunreadOutlined,
} from '@mui/icons-material'
import {EmployerDashboardLinks} from './EmployerDashboardLinks'
import {SeekerDashboardLinks} from './SeekerDashboardLinks'
import {EmployerCandidatesLinks} from './EmployerCandidatesLinks'
import {SeekerMyProfileLinks} from './SeekerMyProfileLinks'

import classNames from 'classnames'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute, SidebarTranslations as st} from 'Services/I18n/Constants'
import {useMediaQuery} from 'react-responsive'
import {FormattedMessage} from 'react-intl'

// options: with RS, Rs=, withLogo,
interface iLeftSidebarProps {
  auth?: AuthStateType
  unreadCount?: number
  taxonomies: TaxonomiesStateType
  getTaxonomies: () => void
}

const LeftSidebar: FC<iLeftSidebarProps> = ({
  auth,
  unreadCount,
  taxonomies,
  getTaxonomies,
}: iLeftSidebarProps) => {
  const isNotDesktop = useMediaQuery({
    query: '(max-width: 1023px)',
  })
  const [activeLi, setActiveLi] = useState(isNotDesktop ? 1 : 0)
  const [sidebarOpened, setSidebarOpened] = useState(false)
  const location = useLocation()

  useEffect(() => {
    closeSidebar()
    if (auth?.data?.type === 'company_user') {
      getTaxonomies()
    }
  }, [location])

  useEffect(() => {
    if (!isNotDesktop) setActiveLi(0)
  }, [isNotDesktop])

  const handleLiClick = (e: any, cnt: number) => {
    e.stopPropagation()
    if (isNotDesktop) setActiveLi(cnt)
  }

  const openSidebar = () => {
    setSidebarOpened(true)
    document.body.classList.add('lsh-sidebar-opened')
  }
  const closeSidebar = () => {
    setSidebarOpened(false)
    setActiveLi(isNotDesktop ? 1 : 0)
    document.body.classList.remove('lsh-sidebar-opened')
  }

  // @ts-ignore
  return (
    <div
      className={classNames(styles.sidebar, [{sidebarOpened: sidebarOpened}])}
      onClick={(e) => e.stopPropagation}
      onBlur={() => setSidebarOpened(false)}
    >
      <div className={styles.verticalStipe} />

      <div className={classNames(styles.sidebarControl, styles.desktopControl)}>
        <Menu className={classNames(styles.hamburgerIcon)} />
      </div>

      <div
        className={classNames(styles.sidebarControl, styles.mobileControl, {
          [styles.opened]: sidebarOpened,
        })}
      >
        <Menu
          className={classNames(styles.hamburgerIcon, styles.hamburgerMenuIcon)}
          onClick={openSidebar}
        />
        <Close
          className={classNames(
            styles.hamburgerIcon,
            styles.hamburgerCloseIcon
          )}
          onClick={closeSidebar}
        />
      </div>

      <div className={styles.sidebarContent}>
        <div className={styles.logoWrap}>
          <img className={styles.logo} src={Preferenca} alt="Logo" />
        </div>
        <ul>
          {/* Dashboard */}
          <li
            onClick={(e) => {
              handleLiClick(e, 1)
            }}
            className={classNames({[styles.active]: 1 === activeLi})}
          >
            <span className={styles.iconWrap}>
              <Badge badgeContent={0} className={styles.badgeGreen}>
                <DescriptionOutlined />
              </Badge>
            </span>
            <div className={styles.dashboardLinks}>
              {auth?.data?.type === 'company_user' ? (
                <EmployerDashboardLinks />
              ) : (
                <SeekerDashboardLinks />
              )}
            </div>
          </li>

          {/* Candidates / Profile */}
          <li
            onClick={(e) => {
              handleLiClick(e, 2)
            }}
            className={classNames({[styles.active]: 2 === activeLi})}
          >
            <span className={styles.iconWrap}>
              <Badge badgeContent={0} className={styles.badgeGreen}>
                <FaceOutlined />
              </Badge>
            </span>
            <div className={styles.secondLinks}>
              {auth?.data?.type === 'company_user' ? (
                <EmployerCandidatesLinks taxonomies={taxonomies} />
              ) : (
                <SeekerMyProfileLinks />
              )}
            </div>
          </li>

          {/* Company profile */}
          {auth?.data?.type === 'company_user' && (
            <li
              onClick={(e) => {
                handleLiClick(e, 3)
              }}
              className={classNames({[styles.active]: 3 === activeLi})}
            >
              <span className={styles.iconWrap}>
                <Badge badgeContent={0} className={styles.badgeGreen}>
                  <WorkOutline />
                </Badge>
              </span>
              <div className={styles.thirdLinks}>
                <h2>
                  <FormattedMessage id={st.companyProfile} />
                </h2>
                <ul>
                  <li>
                    <Link to={getRoute(AppRoute.CompanyProfile)}>
                      <FormattedMessage id={st.companyProfile} />
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          )}
          {/*  Message Center  */}
          {auth?.data?.type === 'company_user' ? (
            <li
              onClick={(e) => {
                handleLiClick(e, 4)
              }}
              className={classNames({[styles.active]: 4 === activeLi})}
            >
              <span className={styles.iconWrap}>
                <Badge badgeContent={0} className={styles.badgeGreen}>
                  <ForumOutlined />
                  {unreadCount && unreadCount > 0 ? (
                    <span className={styles.newMessagesIndicator}>
                      {unreadCount}
                    </span>
                  ) : (
                    ''
                  )}
                </Badge>
              </span>
              <div className={styles.fourthLinks}>
                <h2>
                  <FormattedMessage id={st.messageCenter} />
                </h2>
                <ul>
                  <li>
                    <Link to={getRoute(AppRoute.CandidateMessages)}>
                      <FormattedMessage id={st.messageCenter} />
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          ) : (
            <li
              className={classNames(styles.withoutContent, {
                [styles.active]: 4 === activeLi,
              })}
            >
              <Link to={getRoute(AppRoute.EmployerMessages)}>
                <span className={styles.iconWrap}>
                  <Badge badgeContent={0} className={styles.badgeGreen}>
                    <ForumOutlined />
                    {unreadCount && unreadCount > 0 ? (
                      <span className={styles.newMessagesIndicator}>
                        {unreadCount}
                      </span>
                    ) : (
                      ''
                    )}
                  </Badge>
                </span>
              </Link>
            </li>
          )}
          {/* Calendar */}
          {auth?.data?.type === 'company_user' ? (
            <li
              onClick={(e) => {
                handleLiClick(e, 5)
              }}
              className={classNames({[styles.active]: 5 === activeLi})}
            >
              <span className={styles.iconWrap}>
                <Badge badgeContent={0} className={styles.badgeGreen}>
                  <DateRangeOutlined />
                </Badge>
              </span>
              <div className={styles.fifthLinks}>
                <h2>
                  <FormattedMessage id={st.calendar} />
                </h2>
                <ul>
                  <li>
                    <Link to={getRoute(AppRoute.EmployerCalendar)}>
                      <FormattedMessage id={st.calendar} />
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          ) : (
            <li
              className={classNames(styles.withoutContent, {
                [styles.active]: 5 === activeLi,
              })}
            >
              <Link to={getRoute(AppRoute.CandidateCalendar)}>
                <span className={styles.iconWrap}>
                  <Badge badgeContent={0} className={styles.badgeGreen}>
                    <DateRangeOutlined />
                  </Badge>
                </span>
              </Link>
            </li>
          )}

          {/*/!* Companies/Company *!/*/}
          {/*<li*/}
          {/*  className={classNames(styles.withoutContent, {*/}
          {/*    [styles.active]: 5 === activeLi,*/}
          {/*  })}*/}
          {/*>*/}
          {/*  <Link*/}
          {/*    to={*/}
          {/*      auth?.data?.type === 'company_user'*/}
          {/*        ? getRoute(AppRoute.CompanyProfile)*/}
          {/*        : getRoute(AppRoute.CandidateCompanies)*/}
          {/*    }*/}
          {/*  >*/}
          {/*    <span className={styles.iconWrap}>*/}
          {/*      <Badge badgeContent={0} className={styles.badgeGreen}>*/}
          {/*        <InsertChartOutlinedOutlined />*/}
          {/*      </Badge>*/}
          {/*    </span>*/}
          {/*  </Link>*/}
          {/*</li>*/}

          {/*/!* Advice *!/*/}
          {/*<li*/}
          {/*  className={classNames(styles.withoutContent, {*/}
          {/*    [styles.active]: 5 === activeLi,*/}
          {/*  })}*/}
          {/*>*/}
          {/*  <Link*/}
          {/*    to={*/}
          {/*      auth?.data?.type === 'company_user'*/}
          {/*        ? getRoute(AppRoute.EmployerAdvice)*/}
          {/*        : getRoute(AppRoute.CandidateAdvice)*/}
          {/*    }*/}
          {/*  >*/}
          {/*    <span className={styles.iconWrap}>*/}
          {/*      <Badge badgeContent={0} className={styles.badgeGreen}>*/}
          {/*        <StarBorderOutlined />*/}
          {/*      </Badge>*/}
          {/*    </span>*/}
          {/*  </Link>*/}
          {/*</li>*/}

          {/* FAQ */}
          {auth?.data?.type !== 'company_user' && (
            <li
              className={classNames(styles.withoutContent, {
                [styles.active]: 6 === activeLi,
              })}
            >
              <Link
                to={
                  // auth?.data?.type === 'company_user'
                  //   ? getRoute(AppRoute.EmployerFAQ)
                  //   : getRoute(AppRoute.CandidateFAQ)
                  getRoute(AppRoute.CandidateFAQ)
                }
              >
                <span className={styles.iconWrap}>
                  <Badge badgeContent={0} className={styles.badgeGreen}>
                    <StarBorderOutlined />
                  </Badge>
                </span>
              </Link>
            </li>
          )}
          {auth?.data?.type === 'company_user' ? (
            <li
              onClick={(e) => {
                handleLiClick(e, 7)
              }}
              className={classNames({[styles.active]: 7 === activeLi})}
            >
              <span className={styles.iconWrap}>
                <Badge badgeContent={0} className={styles.badgeGreen}>
                  <LogoutOutlined />
                </Badge>
              </span>
              <div className={styles.sixthLinks}>
                <h2>
                  <FormattedMessage id={st.logout} />
                </h2>
                <ul>
                  <li>
                    <Link to={getRoute(AppRoute.Logout)}>
                      <FormattedMessage id={st.logout} />
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
          ) : (
            <li
              className={classNames(styles.withoutContent, {
                [styles.active]: 7 === activeLi,
              })}
            >
              <Link to={getRoute(AppRoute.Logout)}>
                <span className={styles.iconWrap}>
                  <Badge badgeContent={0} className={styles.badgeGreen}>
                    <LogoutOutlined />
                  </Badge>
                </span>
              </Link>
            </li>
          )}
        </ul>
      </div>
    </div>
  )
}

export default LeftSidebar
