import {connect} from 'react-redux'

import {Profile} from './Profile'
import {AppDispatch} from 'Store'
import {
  getSeekerProfile,
  confirmProfile,
  getCandidateCompleteness,
} from 'Store/Actions'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  user: state.seeker,
  profileCompleteness: state.candidateProfileCompleteness.data.value || 0,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSeekerProfile: () => dispatch(getSeekerProfile()),
  confirmProfile: () => dispatch(confirmProfile()),
  getCompleteness: () => dispatch(getCandidateCompleteness()),
})

export const ProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile)
