export enum CandidateInfo {
  title = 'CandidateInfo.title',
  titleYellow = 'CandidateInfo.titleYellow',
  paragraphMain = 'CandidateInfo.paragraphMain',
  subtitle = 'CandidateInfo.subtitle',
  paragraphFirstPart = 'CandidateInfo.paragraphFirstPart',
  paragraphSecondPart = 'CandidateInfo.paragraphSecondPart',
  subtitleSecond = 'CandidateInfo.subtitleSecond',
  paragraphSecond = 'CandidateInfo.paragraphSecond',
  subtitleThird = 'CandidateInfo.subtitleThird',
  paragraphThird = 'CandidateInfo.paragraphThird',
  paragraphThirdTitle = 'CandidateInfo.paragraphThirdTitle',
  paragraphThirdItemFirst = 'CandidateInfo.paragraphThirdItemFirst',
  paragraphThirdItemSecond = 'CandidateInfo.paragraphThirdItemSecond',
  paragraphThirdItemThird = 'CandidateInfo.paragraphThirdItemThird',

  subtitleFourth = 'CandidateInfo.subtitleFourth',
  paragraphFourth = 'CandidateInfo.paragraphFourth',

  subtitleFifth = 'CandidateInfo.subtitleFifth',
  paragraphFifth = 'CandidateInfo.paragraphFifth',

  subtitleSixth = 'CandidateInfo.subtitleSixth',
  paragraphSixthFirstPart = 'CandidateInfo.paragraphSixthFirstPart',
  paragraphSixthSecondPart = 'CandidateInfo.paragraphSixthSecondPart',
  yellowButton = 'CandidateInfo.yellowButton',
}
