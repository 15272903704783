import {FC} from 'react'
import {CandidateSearchTranslations as cs} from 'Services/I18n/Constants'
import styles from './CandidateBadge.module.scss'
import classnames from 'classnames'
import {FormattedMessage} from 'react-intl'

interface CandidateBadge {
  type: 'available' | 'new'
}
export const CandidateBadge: FC<CandidateBadge> = ({type}) => {
  return (
    <div className={styles.containerBadges}>
      <div
        className={classnames({
          [styles.whiteTxt]: type === 'available',
          [styles.yellowTxt]: type === 'new',
        })}
      >
        <FormattedMessage
          id={
            type === 'available'
              ? cs.availableNowBadgeFirstPart
              : cs.newCandidateBadgeFirstPart
          }
        />
      </div>
      <div
        className={classnames({
          [styles.yellowTxt]: type === 'available',
          [styles.whiteTxt]: type === 'new',
        })}
      >
        <FormattedMessage
          id={
            type === 'available'
              ? cs.availableNowBadgeSecondPart
              : cs.newCandidateBadgeSecondPart
          }
        />
      </div>
    </div>
  )
}
