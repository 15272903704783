import {FC, useState} from 'react'
import {FormattedMessage} from 'react-intl'

import Modal from 'Components/Modal'
import {ThumbsFooter} from 'Components/Modal/ThumbsFooter'
import {IncomingRequestType, ApproveRejectModalRequestType} from 'Interfaces'
import styles from './ModalRejectOfferRequest.module.scss'
import ModalOfferRequest from 'Components/IncomingRequests/Modals/ModalOfferRequest'
import {CandidateDashboardTranslations as cdt} from 'Services/I18n/Constants'
import {ForumOutlined} from '@mui/icons-material'

interface ModalRejectOfferRequest {
  request: IncomingRequestType
  rejectModal: boolean
  setRejectModal: (rejecModal: boolean) => void
  showDetails?: boolean
  setIsOpenOfferRequest?: (setIsOpenOfferRequest: boolean) => void
  rejectOfferRequest: (payload: ApproveRejectModalRequestType) => void
}

export const ModalRejectOfferRequest: FC<ModalRejectOfferRequest> = ({
  request,
  rejectModal,
  setRejectModal,
  rejectOfferRequest,
  setIsOpenOfferRequest,
  showDetails = true,
}) => {
  const [isOpenOfferRequestDetails, setIsOpenOfferRequestDetails] =
    useState(false)
  const [modalDetails, setModalDetails] = useState(false)
  return (
    <>
      {!modalDetails ? (
        <Modal
          className={styles.modal}
          icon={ForumOutlined}
          isOpen={rejectModal}
          title={cdt.rejectOfferrequest}
          theme={'error'}
          onAfterClose={() => {
            setRejectModal(false)
          }}
          Footer={
            <ThumbsFooter
              rejectText={cdt.reject}
              theme={'thumbDown'}
              onReject={() => {
                rejectOfferRequest({
                  id: request.id,
                })
                setRejectModal(false)
                setIsOpenOfferRequest && setIsOpenOfferRequest(false)
              }}
              onCancel={() => {
                setRejectModal(false)
              }}
            />
          }
        >
          <div className={styles.containerInterview}>
            <p className={styles.text}>
              <FormattedMessage id={cdt.modalRejectOfferAreYouSure} />{' '}
              {request.companyName}?
            </p>
            <p className={styles.description}>
              <FormattedMessage id={cdt.modalRejectOfferYouWillMiss} />
            </p>

            {showDetails && (
              <button
                onClick={() => {
                  setRejectModal(false)
                  setModalDetails(true)
                  setIsOpenOfferRequestDetails(true)
                  setIsOpenOfferRequest && setIsOpenOfferRequest(false)
                }}
                className={styles.button}
              >
                <FormattedMessage id={cdt.viewDetails} />
              </button>
            )}
          </div>
        </Modal>
      ) : (
        <ModalOfferRequest
          request={request}
          isOpenOfferRequest={isOpenOfferRequestDetails}
          setIsOpenOfferRequest={setIsOpenOfferRequestDetails}
          setModalDetails={setModalDetails}
          modalDetails={modalDetails}
        />
      )}
    </>
  )
}
