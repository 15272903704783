import Title from 'Components/Title'
import {
  AuthStateType,
  ChangePasswordType,
  ValidationErrorType,
} from 'Interfaces'
import MainLayout from 'Layouts/MainLayout'
import {FC, useEffect} from 'react'
import styles from './ChangePassword.module.scss'
import {useFormik} from 'formik'
import YellowButton from 'Components/YellowButton'
import {useHistory} from 'react-router-dom'
import PasswordInput from 'Components/Inputs/PasswordInput'
import Validation from './Validation'
import TextError from 'Components/Error/TextError'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {MainHeader} from 'Components/MainHeader/MainHeader'
import {WidgetBarContainer} from '../../Layouts/MainLayout/WidgetBar'
import {SidebarLink} from '../../Components/SidebarLink'
import {
  ChangePasswordTranslations as cpt,
  SidebarTranslations as st,
  MyAccountTranslations as mat,
} from 'Services/I18n/Constants'
import {useTranslation} from 'Hooks'
import {FormattedMessage} from 'react-intl'
import CheckCircle from '@mui/icons-material/CheckCircle'

type ChangePasswordProps = {
  auth: AuthStateType
  changePassword: (values: ChangePasswordType) => void
  hideChangePasswordMessage: () => void
}

export const ChangePassword: FC<ChangePasswordProps> = ({
  auth,
  changePassword,
  hideChangePasswordMessage,
}) => {
  const history = useHistory()
  const translation = useTranslation()

  const defaultValues: ChangePasswordType = {
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  }

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: Validation,
    onSubmit: (values) => {
      changePassword(values)
    },
  })

  const validationError =
    auth.error && (auth.error as ValidationErrorType)?.details
  const error =
    auth.error?.message &&
    auth.error?.message !== 'Validation Exception' &&
    auth.error?.message

  const companyUserRole =
    auth?.data?.role === 'employer' ||
    auth?.data?.role === 'company_owner' ||
    auth?.data?.role === 'recruiter'

  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: '/'},
    {
      name: mat.myAccount,
      link: getRoute(
        companyUserRole ? AppRoute.EmployerMyAccount : AppRoute.MyAccount
      ),
    },
    {
      name: st.changePassword,
      link: getRoute(
        companyUserRole
          ? AppRoute.EmployerChangePassword
          : AppRoute.ChangePassword
      ),
    },
  ]

  useEffect(() => {
    if (auth.displayMessage.passwordChanged) {
      setTimeout(() => {
        hideChangePasswordMessage()
      }, 5000)
    }
  }, [auth.displayMessage.passwordChanged])

  return (
    <div className={styles.pageWrap}>
      <MainLayout
        fixedRight={false}
        rightSidebarContent={
          <WidgetBarContainer
            sidebarContent={
              <>
                <div className={styles.linksContainer}>
                  <SidebarLink
                    routeName={
                      companyUserRole
                        ? AppRoute.EmployerMyAccount
                        : AppRoute.MyAccount
                    }
                    text={st.editPersonalData}
                  />
                  <SidebarLink
                    routeName={
                      companyUserRole
                        ? AppRoute.EmployerChangeEmail
                        : AppRoute.ChangeEmail
                    }
                    text={st.changeEmailAddress}
                  />
                  <SidebarLink
                    routeName={
                      companyUserRole
                        ? AppRoute.EmployerChangePassword
                        : AppRoute.ChangePassword
                    }
                    text={st.changePassword}
                  />
                </div>
              </>
            }
          />
        }
      >
        <div className={styles.headerWrap}>
          <MainHeader breadcrumbsArray={breadcrumbsArray} title={cpt.title} />
        </div>
        <Title text={cpt.titleChangePassword} className={styles.title} />
        <div className={styles.cardItemWrap}>
          <PasswordInput
            name="oldPassword"
            label={translation[cpt.oldPassword]}
            className={styles.passwordField}
            onChange={(e) =>
              formik.setFieldValue('oldPassword', e.currentTarget.value)
            }
            value={formik.values.oldPassword}
            error={
              formik.submitCount && formik.touched['oldPassword']
                ? formik.errors.oldPassword
                : undefined
            }
          />
          <PasswordInput
            name="newPassword"
            label={translation[cpt.newPassword]}
            className={styles.passwordField}
            onChange={(e) =>
              formik.setFieldValue('newPassword', e.currentTarget.value)
            }
            value={formik.values.newPassword}
            error={
              formik.submitCount && formik.touched['newPassword']
                ? formik.errors.newPassword
                : undefined
            }
          />
          <PasswordInput
            name="confirmNewPassword"
            label={translation[cpt.repeatNewPassword]}
            className={styles.passwordField}
            onChange={(e) =>
              formik.setFieldValue('confirmNewPassword', e.currentTarget.value)
            }
            value={formik.values.confirmNewPassword}
            error={
              formik.submitCount && formik.touched['confirmNewPassword']
                ? formik.errors.confirmNewPassword
                : undefined
            }
          />

          {validationError && typeof validationError !== 'string'
            ? validationError.map((error) =>
                error.constraints.map((constraint) => (
                  <TextError text={constraint.message} />
                ))
              )
            : validationError}
          {error && <TextError text={error} />}
        </div>
        {auth.displayMessage.passwordChanged && (
          <div className={styles.successMessage}>
            <CheckCircle />
            <FormattedMessage id={cpt.successMessage} />
          </div>
        )}

        <div className={styles.ctasWrap}>
          <YellowButton
            text={cpt.saveChanges}
            onClick={() => formik.submitForm()}
            className={styles.saveBtn}
          />

          <button className={styles.cancelBtn} onClick={() => history.goBack()}>
            <FormattedMessage id={cpt.cancel} />
          </button>
        </div>
      </MainLayout>
    </div>
  )
}
