export const employerHomePage = {
  'employerHomePage.buttonLandingTxt': 'STOPITE V STIK',
  'employerHomePage.titleLandingTxt':
    'VAŠE PODJETJE JE USPEŠNO LE TOLIKO, KOT JE USPEŠNA',
  'employerHomePage.yellowLandingTxt': 'VAŠA EKIPA',
  'employerHomePage.paragraphFirst':
    '  Ne izgubljajte časa z iskanjem in preverjanjem kandidatov – na razgovor povabite le tistega pravega za vas.',
  'employerHomePage.paragraphSecond':
    '   Preferenca vam omogoča takojšen dostop do baze preverjenih kandidatov, ki so trenutno odprti za novo zaposlitev.',

  'employerHomePage.titleSteps': 'ZAKAJ ZAPOSLOVATI S',
  'employerHomePage.yellowTxtSteps': 'PREFERENCO?',
  'employerHomePage.subTitleStepsFirst': 'VRHUNSKI',
  'employerHomePage.subTitleStepsBreakFirst': ' KADRI',
  'employerHomePage.subTitleStepsFirstParagraph':
    'Najboljši na svojem področju, pripravljeni na nove izzive',

  'employerHomePage.subTitleStepsSecond': 'PREVERJENI',
  'employerHomePage.subTitleStepsBreakSecond': 'KANDIDATI',
  'employerHomePage.subTitleStepsSecondParagraph':
    'Z njimi smo že opravili prvotne razgovore in potrdili njihove kompetence',

  'employerHomePage.subTitleStepsThird': 'ENOSTAVNO IN HITREJŠE ZAPOSLOVANJE',
  'employerHomePage.subTitleStepsThirdParagraph':
    'Pomagamo pri hitrem in mehkem prehodu na novo delovno mesto',

  'employerHomePage.howDoesItWorkTitlePartOne': 'KAKO',
  'employerHomePage.howDoesItWorkTitlePartTwo': 'DELUJE?',
  'employerHomePage.howDoesItWorksubTitleFirst':
    'Natančen vpogled v preverjene spretnosti in kompetence kandidatov',
  'employerHomePage.howDoesItWorksubTitleSecond': 'Želje kandidatov',
  'employerHomePage.howDoesItWorksubTitleThird':
    'Hitro in preprosto zaposlovanje',
  'employerHomePage.howDoesItWorksubTitleFourth':
    'Nadzor nad stroški zaposlovanja',
  'employerHomePage.howDoesItWorkParagraphFirst':
    'Za vas smo preverili znanja in reference.',
  'employerHomePage.howDoesItWorkParagraphSecond':
    'Seznanite se z njihovimi pričakovanji glede plače in delovnih pogojev ter se izognite neprijetnostim pri pogajanjih.',
  'employerHomePage.howDoesItWorkParagraphThird':
    'Prihranimo vam ure, dni in mesece, saj zaposlitveni postopek občutno skrajšamo, pri prehodu na novo delovno mesto pa zagotavljamo strokovno podporo.',
  'employerHomePage.howDoesItWorkParagraphFourth':
    'Prilagodimo se željam pri zaposlovanju in ponujamo modele, ki ustrezajo potrebam malih in velikih podjetij.',
}
