import {FC, useMemo, useState} from 'react'
import {useFormik, FieldArray, FormikProvider} from 'formik'
import SectionHeader from 'Components/Profile/SectionHeader'
import ItemHeader from 'Components/Profile/ItemHeader'
import {Question} from 'Components/CompanyProfile/Questions/Question'
import ShowMore from 'Components/Profile/ShowMore'
import Validation from './Validation'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import Title from 'Components/Title'
import Modal from 'Components/Modal'
import {AddQuestionForm} from 'Components/CompanyProfile/Questions/AddQuestionForm'
import {Button} from 'Components/Button'
import {Add as AddIcon, ForumOutlined} from '@mui/icons-material'
import Hr from 'Components/Hr'
import styles from './Questions.module.scss'
import {SendQuestionsValue, QuestionValue} from 'Interfaces'
import {CompanyFaqTranslations as cft} from 'Services/I18n/Constants'
import {useTranslation} from 'Hooks'

interface QuestionsProps {
  canChange: boolean
  sendQuestions: (value: QuestionValue[]) => void
  companyQuestions: QuestionValue[]
}

const packQuestionsValues = (
  formikValues: SendQuestionsValue
): QuestionValue[] => {
  let questions: QuestionValue[] = []
  formikValues.companyQuestions.forEach((question: QuestionValue) => {
    if (question.id !== undefined) {
      questions.push({
        question: question.question,
        answer: question.answer,
        id: question.id,
      })
    } else {
      questions.push({
        question: question.question,
        answer: question.answer,
      })
    }
  })

  return questions
}

export const Questions: FC<QuestionsProps> = ({
  sendQuestions,
  canChange = false,
  companyQuestions,
}) => {
  const translation = useTranslation()

  const [modalOpen, setModalOpen] = useState(false)
  const [modalMode, setModalMode] = useState('sendQuestions')
  const [showMoreIndex, setShowMoreIndex] = useState(4)

  const emptyQuestion = {question: '', answer: '', id: undefined}

  const emptyValues: SendQuestionsValue = {
    companyQuestions: [emptyQuestion],
  }

  const localQuestions = useMemo(() => {
    return companyQuestions.slice(0, showMoreIndex)
  }, [companyQuestions, showMoreIndex])

  const openAdd = () => {
    setModalMode('Add')
    formik.resetForm({
      values: emptyValues,
    })
    setModalOpen(true)
  }

  const openEdit = () => {
    setModalMode('Edit')
    formik.resetForm({
      values: {
        companyQuestions: companyQuestions,
      },
    })

    setModalOpen(true)
  }

  const formik = useFormik({
    initialValues: emptyValues,
    validationSchema: Validation,
    onSubmit: (values) => {
      if (modalMode === 'Edit') {
        sendQuestions(packQuestionsValues(values))
        setModalOpen(false)
      } else {
        companyQuestions.forEach((question) => {
          values.companyQuestions.push(question)
        })
        sendQuestions(packQuestionsValues(values))
        setModalOpen(false)
      }
    },
  })

  return (
    <div>
      {(companyQuestions.length > 0 || canChange) && (
        <SectionHeader
          onAdd={canChange ? openAdd : undefined}
          title={cft.faqTitle}
          className={styles.header}
        />
      )}
      {companyQuestions.length > 0 && (
        <div className={styles.questionsContainer}>
          <div className={styles.itemHeaderWrapper}>
            <ItemHeader
              title={cft.basicInformation}
              icon={ForumOutlined}
              onEdit={
                canChange
                  ? () => {
                      openEdit()
                    }
                  : undefined
              }
              className={styles.itemHeader}
            />
          </div>
          <div className={styles.questionsContainer}>
            {localQuestions.map((question: QuestionValue, index: number) => {
              return (
                <Question key={`${question.id}-${index}`} question={question} />
              )
            })}
          </div>
          <Hr className={styles.hr} />
          <ShowMore
            items={companyQuestions}
            showMoreIndex={showMoreIndex}
            setShowMoreIndex={setShowMoreIndex}
            defaultShowMoreIndex={4}
            textMore={cft.moreText}
            textFewer={cft.lessText}
            hr={false}
            icon={true}
            classname={styles.questionsShowMore}
          />
        </div>
      )}
      <Modal
        isOpen={modalOpen}
        onAfterClose={() => {
          setModalOpen(false)
        }}
        title={`
          ${
            modalMode === 'Add'
              ? translation[cft.modalAddText]
              : translation[cft.modalEditText]
          }
          ${translation[cft.faqTitle]}`}
        icon={ForumOutlined}
        ariaHideApp={false}
        className={styles.modal}
        theme="desktopFlex"
        Footer={
          <SubmitFooter
            onSubmit={formik.handleSubmit}
            onCancel={() => {
              setModalOpen(false)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <div className={styles.modalContent}>
          <FormikProvider value={formik}>
            <FieldArray name="companyQuestions">
              {({push, remove}) => (
                <>
                  <Title
                    text={cft.modalFaqFullTitle}
                    className={styles.title}
                    theme="modal"
                  />
                  {formik.values.companyQuestions.map(
                    (_: QuestionValue, index: number) => (
                      <AddQuestionForm
                        key={index}
                        index={index}
                        formik={formik}
                        questionName={`companyQuestions[${index}].question`}
                        answerName={`companyQuestions[${index}].answer`}
                        remove={remove}
                      />
                    )
                  )}
                  <Button
                    onClick={() => push(emptyQuestion)}
                    text={cft.modalAddQuestion}
                    className={styles.addFormikItemButton}
                    icon={<AddIcon />}
                  />
                </>
              )}
            </FieldArray>
          </FormikProvider>
        </div>
      </Modal>
    </div>
  )
}
