import * as type from 'Store/types'
import {
  ActionType,
  CandidateStateType,
  ErrorType,
  IncomingRequestType,
  MinifeedsType,
  CandidateDataType,
} from 'Interfaces'

const initialState = {
  data: {
    fullCandidateData: {
      id: '',
      firstName: '',
      lastName: '',
      proffession: '',
      profileImage: '',
      country: {
        id: '',
        translation: '',
      },
      town: {
        id: '',
        translation: '',
      },
      streetAddress: '',
      postalCode: '',
      email: '',
      phoneNumber: '',
      incomingRequests: [],
    },
  },
  loading: false,
  error: undefined,
  minifeeds: {
    data: [],
    loading: false,
    error: undefined,
  },
}

const CandidateReducer = (
  state: CandidateStateType = initialState,
  action: ActionType<
    | CandidateDataType
    | IncomingRequestType
    | MinifeedsType[]
    | ErrorType
    | string
    | undefined
  >
): CandidateStateType => {
  switch (action.type) {
    case type.candidate.fullCandidateData.get.requested:
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case type.candidate.minifeeds.get.requested:
      return {
        ...state,
        minifeeds: {
          ...state.minifeeds,
          loading: true,
          error: undefined,
        },
      }
    case type.candidate.fullCandidateData.get.succeeded:
      return {
        ...state,
        data: {
          ...state.data,
          fullCandidateData: action.payload as CandidateDataType,
        },
        loading: false,
        error: undefined,
      }
    case type.candidate.minifeeds.get.succeeded:
      return {
        ...state,
        minifeeds: {
          ...state.minifeeds,
          data: action.payload as MinifeedsType[],
          loading: false,
          error: undefined,
        },
      }
    case type.candidate.fullCandidateData.get.failed:
    case type.candidate.minifeeds.get.failed:
      return {
        ...state,
        minifeeds: {
          ...state.minifeeds,
          loading: false,
          error: action.payload as ErrorType,
        },
      }
    default:
      return state
  }
}

export default CandidateReducer
