import {
  ActionType,
  ApproveRejectModalRequestType,
  CancelInterviewType,
  IncomingRequestsSearchParamsType,
  NewInterviewTimePayloadType,
} from 'Interfaces'
import * as type from '../types'

export const getDashboardIncomingRequests = (): ActionType<undefined> => {
  return {
    type: type.incomingRequests.dashboard.requested,
  }
}

export const getCandidateJourneyStep = (): ActionType<undefined> => {
  return {
    type: type.incomingRequests.journey.requested,
  }
}

export const searchIncomingRequests = (
  payload?: IncomingRequestsSearchParamsType
): ActionType<IncomingRequestsSearchParamsType> => {
  return {
    type: type.incomingRequests.search.requested,
    payload: payload,
  }
}

export const loadMoreIncomingRequests = (
  payload: string
): ActionType<string> => {
  return {
    type: type.incomingRequests.loadMore.requested,
    payload: payload,
  }
}

export const getCompaniesIncomingRequests = () => {
  return {type: type.incomingRequests.getCompanies.requested}
}

export const proposeNewTime = (
  payload: NewInterviewTimePayloadType
): ActionType<NewInterviewTimePayloadType> => {
  return {
    type: type.incomingRequests.update.proposeNewTime.requested,
    payload: payload,
  }
}
export const approveInterviewInvitation = (
  payload: ApproveRejectModalRequestType
): ActionType<ApproveRejectModalRequestType> => {
  return {
    type: type.incomingRequests.update.approveInterviewInvitation.requested,
    payload: payload,
  }
}
export const rejectInterviewInvitation = (
  payload: ApproveRejectModalRequestType
): ActionType<ApproveRejectModalRequestType> => {
  return {
    type: type.incomingRequests.update.rejectInterviewInvitation.requested,
    payload: payload,
  }
}
export const cancelInterviewInvitation = (
  payload: CancelInterviewType
): ActionType<CancelInterviewType> => {
  return {
    type: type.incomingRequests.update.cancelInterviewInvitation.requested,
    payload: payload,
  }
}
export const approveContactRequest = (
  payload: ApproveRejectModalRequestType
): ActionType<ApproveRejectModalRequestType> => {
  return {
    type: type.incomingRequests.update.approveContactRequest.requested,
    payload: payload,
  }
}
export const rejectContactRequest = (
  payload: ApproveRejectModalRequestType
): ActionType<ApproveRejectModalRequestType> => {
  return {
    type: type.incomingRequests.update.rejectContactRequest.requested,
    payload: payload,
  }
}
export const approveOfferRequest = (
  payload: ApproveRejectModalRequestType
): ActionType<ApproveRejectModalRequestType> => {
  return {
    type: type.incomingRequests.update.approveOfferRequest.requested,
    payload: payload,
  }
}

export const rejectOfferRequest = (
  payload: ApproveRejectModalRequestType
): ActionType<ApproveRejectModalRequestType> => {
  return {
    type: type.incomingRequests.update.rejectOfferRequest.requested,
    payload: payload,
  }
}
export const confirmPlacement = (
  payload: ApproveRejectModalRequestType
): ActionType<ApproveRejectModalRequestType> => {
  return {
    type: type.incomingRequests.update.confirmPlacement.requested,
    payload: payload,
  }
}

export const rejectPlacement = (
  payload: ApproveRejectModalRequestType
): ActionType<ApproveRejectModalRequestType> => {
  return {
    type: type.incomingRequests.update.rejectPlacement.requested,
    payload: payload,
  }
}
