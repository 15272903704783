import MainHeader from 'Components/MainHeader'
import styles from './FrequentlyAskedQuestions.module.scss'
import FaqCategory from 'Components/CompanyProfile/Questions/FaqCategory'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {useTranslation} from 'Hooks'
import {
  CompanyFaqTranslations as cft,
  SupportTranslations as st,
} from 'Services/I18n/Constants'

export const FrequentlyAskedQuestions = () => {
  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: getRoute(AppRoute.HomePageCandidate)},
    {name: cft.support, link: getRoute(AppRoute.HelpSupport)},
    {name: cft.faq, link: '/'},
  ]
  const translation = useTranslation()

  let questionsGeneral = [
    {
      question: `${translation[st.cardQuestion1]}`,
      answer: `${translation[st.cardAnswer1]}`,
      answerSecondPart: `${translation[st.cardAnswer1SecondPart]}`,
    },
    {
      question: `${translation[st.cardQuestion4]}`,
      answerTitle: `${translation[st.cardAnswer4EmployersTitle]}`,
      answer: `${translation[st.cardAnswer4EmployersText]}`,
      answerSecondPartTitle: `${translation[st.cardAnswer4CandidatesTitle]}`,
      answerSecondPart: `${translation[st.cardAnswer4CandidatesText]}`,
    },
    {
      question: `${translation[st.cardQuestion3]}`,
      answer: `${translation[st.cardAnswer3]}`,
      answerSecondPart: `${translation[st.cardAnswer3SecondPart]}`,
    },
  ]

  let questionsCandidate = [
    {
      question: `${translation[cft.questionForCandidate1]}`,
      answer: `${translation[cft.answer1ForCandidate1]}`,
    },
    {
      question: `${translation[cft.questionForCandidate2]}`,
      answer: `${translation[cft.answer1ForCandidate2]}`,
    },
    {
      question: `${translation[cft.questionForCandidate3]}`,
      answer: `${translation[cft.answer1ForCandidate3]}`,
    },
    {
      question: `${translation[cft.questionForCandidate4]}`,
      answer: `${translation[cft.answer1ForCandidate4]}`,
    },
    {
      question: `${translation[cft.questionForCandidate5]}`,
      answer: `${translation[cft.answer1ForCandidate5]}`,
    },
    {
      question: `${translation[cft.questionForCandidate6]}`,
      answer: `${translation[cft.answer1ForCandidate6]}`,
    },
    {
      question: `${translation[cft.questionForCandidate7]}`,
      answer: `${translation[cft.answer1ForCandidate7]}`,
    },
    {
      question: `${translation[cft.questionForCandidate8]}`,
      answer: `${translation[cft.answer1ForCandidate8]}`,
    },
  ]

  let questionsEmployers = [
    {
      question: `${translation[cft.questionForEmployer1]}`,
      answer: `${translation[cft.answerForEmployer1]}`,
    },
    {
      question: `${translation[cft.questionForEmployer2]}`,
      answer: `${translation[cft.answerForEmployer2PartOne]}`,
      answerSecondPart: `${translation[cft.answerForEmployer2PartTwo]}`,
    },
    {
      question: `${translation[cft.questionForEmployer3]}`,
      answer: `${translation[cft.answerForEmployer3]}`,
      answerSecondPartTitle: `${
        translation[cft.answerForEmployer3FirstPartTitle]
      }`,
      answerSecondPart: `${translation[cft.answerForEmployer3FirstPart]}`,
      answerThirdPartTitle: `${
        translation[cft.answerForEmployer3SecondPartTitle]
      }`,
      answerThirdPart: `${translation[cft.answerForEmployer3SecondPart]}`,
      answerFourthPartTitle: `${
        translation[cft.answerForEmployer3ThirdPartTitle]
      }`,
      answerFourthPart: `${translation[cft.answerForEmployer3ThirdPart]}`,
      answerFifthPartTitle: `${
        translation[cft.answerForEmployer3FourthPartTitle]
      }`,
      answerFifthPart: `${translation[cft.answerForEmployer3FourthPart]}`,
      answerSixthPartTitle: `${
        translation[cft.answerForEmployer3FifthPartTitle]
      }`,
      answerSixthPart: `${translation[cft.answerForEmployer3FifthPart]}`,
      answerSixthPart2: `${translation[cft.answerForEmployer3FifthPart2]}`,
    },
    {
      question: `${translation[cft.questionForEmployer4]}`,
      answer: `${translation[cft.answerForEmployer4]}`,
    },
    {
      question: `${translation[cft.questionForEmployer5]}`,
      answer: `${translation[cft.answerForEmployer5]}`,
    },
    {
      question: `${translation[cft.questionForEmployer6]}`,
      answer: `${translation[cft.answerForEmployer6]}`,
    },
    {
      question: `${translation[cft.questionForEmployer7]}`,
      answer: `${translation[cft.answerForEmployer7]}`,
    },
    {
      question: `${translation[cft.questionForEmployer8]}`,
      answer: `${translation[cft.answerForEmployer8]}`,
    },
    {
      question: `${translation[cft.questionForEmployer9]}`,
      answer: `${translation[cft.answerForEmployer9]}`,
    },
    {
      question: `${translation[cft.questionForEmployer10]}`,
      answer: `${translation[cft.answerForEmployer10]}`,
    },
    {
      question: `${translation[cft.questionForEmployer11]}`,
      answer: `${translation[cft.answerForEmployer11]}`,
    },
    {
      question: `${translation[cft.questionForEmployer12]}`,
      answer: `${translation[cft.answerForEmployer12]}`,
    },
    {
      question: `${translation[cft.questionForEmployer13]}`,
      answer: `${translation[cft.answerForEmployer13]}`,
      answerTitle: `${translation[cft.answerForEmployer13Title]}`,
      answerSecondPart: `${translation[cft.answerForEmployer13SecondPart]}`,
      answerSecondPartTitle: `${
        translation[cft.answerForEmployer13SecondPartTitle]
      }`,
      answerThirdPart: `${translation[cft.answerForEmployer13ThirdPart]}`,
      answerThirdPartTitle: `${
        translation[cft.answerForEmployer13ThirdPartTitle]
      }`,
      answerFourthPart: `${translation[cft.answerForEmployer13FourthPart]}`,
      answerFourthPartTitle: `${
        translation[cft.answerForEmployer13FourthPartTitle]
      }`,
      answerFifthPart: `${translation[cft.answerForEmployer13FifthPart]}`,
      answerFifthPartTitle: `${
        translation[cft.answerForEmployer13FifthPartTitle]
      }`,
    },
    {
      question: `${translation[cft.questionForEmployer14]}`,
      answer: `${translation[cft.answerForEmployer14]}`,
    },
  ]

  return (
    <>
      <div className={styles.container}>
        <MainHeader
          breadcrumbsArray={breadcrumbsArray}
          title={translation[cft.modalFaqFullTitle]}
        />
        <div className={styles.questions}>
          <FaqCategory questions={questionsGeneral} title={'GENERAL'} />
          <FaqCategory
            questions={questionsCandidate}
            title={translation[cft.forCandidate]}
          />
          <FaqCategory questions={questionsEmployers} title={'FOR EMPLOYERS'} />
        </div>
      </div>
    </>
  )
}
