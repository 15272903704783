export enum BlacklistTranslations {
  title = 'blacklistsettings.title',
  titleBlockedCompanies = 'blacklistsettings.titleBlockedCompanies',
  descriptionBlockedCompanies = 'blacklistsettings.descriptionBlockedCompanies',
  enterCompanyName = 'blacklistsettings.enterCompanyName',
  noData = 'blacklistsettings.noData',
  yesImSure = 'blacklistsettings.yesImSure',
  removeCompany = 'blacklistsettings.removeCompany',
  areYouSure = 'blacklistsettings.areYouSure',
  fromBlocked = 'blacklistsettings.fromBlocked',
}
