import {FC} from 'react'

import styles from './EmployerSteps.module.scss'
import YellowText from 'Components/YellowText'
import classnames from 'classnames'
import {EmployerHomePageTranslations as el} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'

export const EmployerSteps: FC = () => {
  return (
    <div className={styles.mainContainer}>
      <h2 className={styles.mainTitleStyle}>
        <FormattedMessage id={el.titleSteps} />
        <YellowText text={el.yellowTxtSteps} addCutPaintWidth={-30} />
      </h2>

      <div className={styles.stepsContainer}>
        <div className={styles.stepsItem}>
          <div className={styles.numberAndTitle}>
            <div className={styles.numberStyle}>1</div>
            <div className={styles.titleFixPos}>
              <h3 className={styles.titleStyle}>
                <FormattedMessage id={el.subTitleStepsFirst} />
                <br />
                <FormattedMessage id={el.subTitleStepsBreakFirst} />
              </h3>
            </div>
          </div>
          <p className={styles.paragraphStyle}>
            <FormattedMessage id={el.subTitleStepsFirstParagraph} />
          </p>
        </div>

        <div className={styles.stepsItem}>
          <div className={styles.numberAndTitle}>
            <div className={styles.numberStyle}>2</div>
            <div className={styles.titleFixPos}>
              <h3 className={styles.titleStyle}>
                <FormattedMessage id={el.subTitleStepsSecond} />
                <br />
                <FormattedMessage id={el.subTitleStepsBreakSecond} />
              </h3>
            </div>
          </div>
          <p className={styles.paragraphStyle}>
            <FormattedMessage id={el.subTitleStepsSecondParagraph} />
          </p>
        </div>

        <div className={styles.stepsItem}>
          <div className={styles.numberAndTitle}>
            <div className={styles.numberStyle}>3</div>
            <h3 className={classnames(styles.titleStyle, styles.titleFixPos)}>
              <FormattedMessage id={el.subTitleStepsThird} />
            </h3>
          </div>
          <p className={styles.paragraphStyle}>
            <FormattedMessage id={el.subTitleStepsThirdParagraph} />
          </p>
        </div>
      </div>
    </div>
  )
}
