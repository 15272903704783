export const onboarding = {
  'onboarding.step': 'Korak',
  'onboarding.of': 'od',
  'onboarding.titleCV': 'Vaš CV',
  'onboarding.descriptionCV': 'Izpolnite vaš osnovni profil in zanimanja.',
  'onboarding.titleOccupationalFields': 'Področja dela',
  'onboarding.buttonNext': 'Naprej',
  'onboarding.buttonBack': 'Nazaj',
  'onboarding.titleUploadCV': 'NALOŽITE VAŠ CV',
  'onboarding.cvDisclaimer':
    'Spodaj lahko naložite svoj CV. S CV-ja bomo prebrali podatke ter jih strukturirano vnesli v vaš profil. Vse samodejno vpisane podatke lahko kasneje spremenite ali izbrišete. Če nimate CV-ja, ga lahko naložite kasneje, podatke pa v svoj profil vpišete ročno. ',
  'onboarding.uploadFileFormats':
    'Podprti formati datotek: .doc, .docx, .pdf. (Maks. velikost: 5 MB)',
  'onboarding.uploadFileFormatsImage':
    'Podprti formati datotek: .jpg, .jpeg, .png, .gif. (Maks. velikost: 5 MB)',
  'onboarding.uploadDragAndDrop': 'Povlecite in spustite vaše datoteke tukaj',
  'onboarding.uploadOr': 'ali',
  'onboarding.uploadBrowseFiles': 'Prebrskajte datoteke',
  'onboarding.uploadGoogleDrive': 'Google Drive',
  'onboarding.uploadiCloud': 'iCloud',
  'onboarding.uploadDropbox': 'Dropbox',
  'onboarding.uploadOneDrive': 'OneDrive',
  'onboarding.uploadNoFile': 'Ni datoteke',
  'onboarding.uploadRemove': 'Odstrani',
  'onboarding.titleJobPreferences': 'Delovne preference',
  'onboarding.descriptionJobPreferences': 'Kakšne so vaše delovne preference?',
  'onboarding.titleDesiredRegions': 'Želene regije',
  'onboarding.titleWorkFromHome': 'DELO OD DOMA',
  'onboarding.titleDesiredSalary': 'Želena Plača',
  'onboarding.titleAvailability': 'RAZPOLOŽLJIVOST ZA NOVO DELOVNO MESTO',
  'onboarding.titleWorkExperience': 'Delovne izkušnje',
  'onboarding.titleTotalWorkExperience': 'Skupno število let delovnih izkušenj',
  'onboarding.titleAddWorkExperience': 'Dodajte delovne izkušnje',
  'onboarding.titleEditWorkExperience': 'Urejanje Delovnih izkušenj',
  'onboarding.descriptionWorkExperience':
    'Kakšne so vaše delovne izkušnje & znanja?',
  'onboarding.titleTotalYearsWorkExperience': 'SKUPNO LET DELOVNIH IZKUŠENJ',
  'onboarding.titleExperiencePerPosition':
    'RELEVANTNA LETA DELOVNIH IZKUŠENJ GLEDE NA DELOVNO MESTO',
  'onboarding.typeAheadEnterPosition': 'Vnesite vaše delovno mesto ...',
  'onboarding.titleSkills': 'ZNANJA IN SPRETNOSTI',
  'onboarding.selectMultiValue': 'Izberite več možnosti',
  'onboarding.selectTypeYourSkills': 'Vpišite vaša znanja in spretnosti',
  'onboarding.titleBenefitsAndEducation': 'Ugodnosti & Izobrazba',
  'onboarding.descriptionBenefitsAndEducation':
    'Kakšna so vaša pričakovanja in izobrazba?',
  'onboarding.titleWorkEnvironment': 'VAŠE ŽELENO DELOVNO OKOLJE',
  'onboarding.placeholderTextWorkEnvironment':
    'Primer: Želim si delati v sproščenem okolju, kjer je veliko ekipnega dela...',
  'onboarding.titleAdditionalBenefits': 'Dodatne ugodnosti delodajalcev',
  'onboarding.titleEducation': 'Izobrazba',
  'onboarding.titleAddEducation': 'Dodajte Izobrazbo',
  'onboarding.titleEditEducation': 'Urejanje Izobrazbe',
  'onboarding.dropDownSelectFromList': 'Izberite iz seznama',
  'onboarding.titleFieldOfStudy': 'PODROČJE IZOBRAZBE',
  'onboarding.titleCurrentSalary': 'TRENUTNA PLAČA',
  'onboarding.sideBarYourCV': 'Vaš CV',
  'onboarding.sideBarJobPreferences': 'Delovne preference',
  'onboarding.sideBarWorkExperience': 'Delovne izkušnje',
  'onboarding.sideBarBenefitsAndEducation': 'Ugodnosti & Izobrazba',
  'onboarding.titleDesiredJobPositions': 'Želena delovna mesta',
  'onboarding.titleJobSearchStatus': 'STATUS ISKANJA ZAPOSLITVE',
  'onboarding.titleAreYouSureModal': 'Opozorilo',
  'onboarding.descriptionAreYouSureModal':
    'Ste prepričani, da želite zavreči spremembe, ki ste jih naredili?',
  'onboarding.modalSaveChanges': 'Shrani spremembe',
  'onboarding.modalDiscard': 'Zavrzi',
  'onboarding.typeAheadTypeYour': 'Vnesite svoje',
  'onboarding.typeAheadTypeYourSkills': 'Vnesite svoje Spretnosti',
  'onboarding.apiErrorMessage':
    'Ups, nekaj je šlo narobe. Prosimo poskusite ponovno ali nadaljujte na naslednji korak in vnesite podatke kasneje.',
  'onboarding.continueWithoutParsedCV':
    'Nadaljujte brez razčlenjenega življenjepisa',
  'onboarding.tryAgain': 'Poskusi ponovno',
  'onboarding.cvParsing': 'Razčlenjevanje življenjepisa',
  'onboarding.other': 'Drugo',
  'onboarding.otherJobPositionPlaceholder': 'Vnesite želeno delovno mesto',
  'onboarding.placeholderCurrentJobTitle':
    'Prosimo vnesite naziv trenutnega delovnega mesta',
  'onboarding.currentJobTitle': 'NAZIV TRENUTNEGA DELOVNEGA MESTA',
  'onboarding.agree': 'Strinjam se s ',
  'onboardging.generalTerms': 'Splošni pogoji ',
  'onboardging.and': 'in ',
  'onboardging.termsAndConditions':
    'Pravila in pogoji uporabe na Preferenca.si ',
  'onboardging.privacyPolicy':
    's Politiko zasebnosti in varstva osebnih podatkov ',
  'onboarding.thirdPartyAgreement':
    'Strinjam se z obdelavo mojih osebnih podatkov za izdelavo uporabniškega profila na spletnem portalu in transakcijski e-pošti Preferenca.si z namenom zagotavljanja storitev, namenjenih registriranim uporabnikom. Storitve spletnega portala in transakcijska elektronska pošta vključujejo tudi elektronska sporočila, povezana z delovanjem spletnega portala in transakcijska elektronska sporočila.',
  'onboarding.fileTooLarge':
    'Datoteka, ki ste jo poskušali naložiti, je prevelika. Prosimo vas, da naložite datoteko manjšo od,',
}
