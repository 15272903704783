import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import API from 'Services/API'
import * as type from 'Store/types'
import {
  ActionType,
  AddCandidateToProjectType,
  AllProjectsStateType,
  ArchiveMyProjectType,
  EditMyProjectType,
  MyProjectCandidatesSearchParams,
  MyProjectCandidatesStateType,
  RemoveCandidateFromProjectsType,
  SearchMyProjectsParams,
  SeekerEvaluationType,
  SeekerReviewType,
} from 'Interfaces'
import {generateURL} from 'Utils/CommonHelpers'

const getProjectsUrl = '/company-user/projects'
const getProjectCandidatesUrl = (id: string) =>
  `/company-user/projects/${id}/seekers`
const searchProjectsUrl = '/company-user/seekers-with-projects'

function* getMyProject(action: ActionType<string>) {
  const {response, error} = yield call(
    API.get,
    `/company-user/projects/${action.payload}`
  )

  if (response) {
    yield put({
      type: type.myProjects.project.get.succeeded,
      payload: response.data,
    })
  } else {
    yield put({type: type.myProjects.project.get.failed, payload: error})
  }
}

function* getMyProjectCandidates(
  action: ActionType<MyProjectCandidatesSearchParams>
) {
  const urlWithParams = generateURL(
    getProjectCandidatesUrl(action.payload?.id as string),
    {},
    0,
    10
  )
  const {response, error} = yield call(API.get, urlWithParams)

  if (response) {
    yield put({
      type: type.myProjects.projectCandidates.get.succeeded,
      payload: {
        data: response.data.items,
        total: response.data.total,
      },
    })
  } else {
    yield put({
      type: type.myProjects.projectCandidates.get.failed,
      payload: error,
    })
  }
}

function* fetchMoreProjectCandidates(
  action: ActionType<MyProjectCandidatesSearchParams>
) {
  const projectCandidates: MyProjectCandidatesStateType = yield select(
    (state) => state.myProjects.projectCandidates
  )
  const totalCandidates = projectCandidates.total
  const totalCandidatesFetched = projectCandidates.data.length
  let loadMore

  if (totalCandidates && totalCandidates > totalCandidatesFetched) {
    loadMore = generateURL(
      getProjectCandidatesUrl(action.payload?.id as string),
      action.payload?.params,
      totalCandidatesFetched,
      10
    )
  }

  const {response, error} = yield call(API.get, loadMore as string)

  if (response) {
    yield put({
      type: type.myProjects.projectCandidates.loadMore.succeeded,
      payload: {
        data: response.data.items,
        loadMore,
      },
    })
  } else {
    yield put({
      type: type.myProjects.projectCandidates.loadMore.failed,
      payload: error,
    })
  }
}

function* getMyWidgetProjects(_action: ActionType<string>) {
  const {response, error} = yield call(API.get, '/company-user/projects')

  if (response) {
    yield put({
      type: type.myProjects.widget.get.succeeded,
      payload: response.data.items,
    })
  } else {
    yield put({type: type.myProjects.widget.get.failed, payload: error})
  }
}

function* getAllProjects(action: ActionType<SearchMyProjectsParams>) {
  const urlWithParams = generateURL(getProjectsUrl, {...action.payload})
  const {response, error} = yield call(API.get, urlWithParams)

  if (response) {
    yield put({
      type: type.myProjects.all.get.succeeded,
      payload: {
        data: response.data.items,
        total: response.data.total,
      },
    })
  } else {
    yield put({type: type.myProjects.all.get.failed, payload: error})
  }
}

function* addNewProject(action: ActionType<string>) {
  const {response, error} = yield call(API.post, '/company-user/projects', {
    title: action.payload,
  })
  if (response) {
    yield put({
      type: type.myProjects.addNewProject.succeeded,
      payload: {project: response.data},
    })
  } else {
    yield put({type: type.myProjects.addNewProject.failed, payload: error})
  }
}

function* editProject(action: ActionType<EditMyProjectType>) {
  const {response, error} = yield call(
    API.patch,
    `/company-user/projects/${action.payload?.id}`,
    {
      title: action.payload?.name,
    }
  )
  if (response) {
    yield put({
      type: type.myProjects.editProject.succeeded,
      payload: action.payload,
    })
  } else {
    yield put({type: type.myProjects.editProject.failed, payload: error})
  }
}

function* deleteProject(action: ActionType<string>) {
  const {response, error} = yield call(
    API.delete,
    `/company-user/projects/${action.payload}`
  )
  if (response) {
    yield put({
      type: type.myProjects.delete.succeeded,
      payload: action.payload,
    })
  } else {
    yield put({type: type.myProjects.delete.failed, payload: error})
  }
}

function* addCandidateToNewProject(
  action: ActionType<AddCandidateToProjectType>
) {
  const {response, error} = yield call(API.post, '/company-user/projects', {
    title: action.payload?.projectName,
  })
  if (response) {
    yield put({
      type: type.myProjects.addNewProject.succeeded,
      payload: {project: response.data, seekerId: action.payload?.seekerId},
    })

    const {response: seekerResponse, error: seekerErr} = yield call(
      API.post,
      `/company-user/projects/${response.data.id}/seekers`,
      {
        seekerUserId: action.payload?.seekerId,
      }
    )
    if (seekerResponse) {
      yield put({
        type: type.myProjects.addToNewProject.succeeded,
        payload: seekerResponse.data,
      })
    } else {
      yield put({
        type: type.myProjects.addToNewProject.failed,
        payload: seekerErr,
      })
    }
  } else {
    yield put({type: type.myProjects.addNewProject.failed, payload: error})
  }
}

function* addCandidateToProjects(
  action: ActionType<AddCandidateToProjectType>
) {
  const {response, error} = yield call(
    API.post,
    `/company-user/seeker/${action.payload?.seekerId}/projects`,
    {
      projectIds: action.payload?.projectIds,
    }
  )

  if (response) {
    yield put({
      type: type.myProjects.addToProject.succeeded,
      payload: response.data,
    })
  } else {
    yield put({type: type.myProjects.addToProject.failed, payload: error})
  }
}

function* removeCandidateFromProjects(
  action: ActionType<RemoveCandidateFromProjectsType>
) {
  const {response, error} = yield call(
    API.delete,
    `/user/seeker/${action.payload?.seekerId}/company-user-projects`,
    {
      ids: action.payload?.projectIds,
    }
  )

  if (response) {
    yield put({
      type: type.myProjects.removeFromProjects.succeeded,
      payload: {
        seekerId: action.payload?.seekerId,
        projectIds: action.payload?.projectIds,
      },
    })
  } else {
    yield put({type: type.myProjects.removeFromProjects.failed, payload: error})
  }
}

function* removeCandidateFromAllProjects(
  action: ActionType<RemoveCandidateFromProjectsType>
) {
  const {response, error} = yield call(
    API.delete,
    `/user/seeker/${action.payload?.seekerId}/company-user-projects/all`
  )
  if (response) {
    yield put({
      type: type.myProjects.removeFromAllProjects.succeeded,
      payload: {
        seekerId: action.payload?.seekerId,
        projectIds: action.payload?.projectIds,
      },
    })
  } else {
    yield put({
      type: type.myProjects.removeFromAllProjects.failed,
      payload: error,
    })
  }
}
function* archiveProject(action: ActionType<ArchiveMyProjectType>) {
  const {response, error} = yield call(
    API.patch,
    `/company-user/projects/${action.payload?.id}`,
    {
      status: action.payload?.status,
    }
  )
  if (response) {
    yield put({
      type: type.myProjects.archive.succeeded,
      payload: response.data,
    })
    yield put({
      type: type.myProjects.all.get.requested,
      payload: {
        projectStatus: action.payload?.projectsByStatus,
      },
    })
  } else {
    yield put({type: type.myProjects.archive.failed, payload: error})
  }
}

function* fetchMoreProjects() {
  const projects: AllProjectsStateType = yield select(
    (state) => state.myProjects.allProjects
  )
  const totalProjects = projects.total
  const totalProjectsFetched = projects.data.length
  let loadMore = undefined

  if (totalProjects && totalProjects > totalProjectsFetched) {
    loadMore = generateURL(getProjectsUrl, {}, totalProjectsFetched)
  }

  const {response, error} = yield call(API.get, loadMore as string)

  if (response) {
    yield put({
      type: type.myProjects.loadMore.succeeded,
      payload: {
        data: response.data.items,
        loadMore,
      },
    })
  } else {
    yield put({
      type: type.myProjects.loadMore.failed,
      payload: error,
    })
  }
}

function* searchMyProjects(action: ActionType<SearchMyProjectsParams>) {
  const params = {...action.payload, sort: 'firstName'}
  const urlWithParams = generateURL(searchProjectsUrl, params)
  const {response, error} = yield call(API.get, urlWithParams)

  if (response) {
    yield put({
      type: type.myProjects.search.succeeded,
      payload: {
        data: response.data.items,
        total: response.data.total,
        params,
      },
    })
  } else {
    yield put({
      type: type.myProjects.search.failed,
      payload: error,
    })
  }
}

function* fetchMoreSearchResults() {
  const searchResults: MyProjectCandidatesStateType = yield select(
    (state) => state.myProjects.searchResults
  )
  const totalSearchResults = searchResults.total
  const totalFetched = searchResults.data.length
  const params = searchResults.params as SearchMyProjectsParams
  let loadMore

  if (totalSearchResults && totalSearchResults > totalFetched) {
    loadMore = generateURL(searchProjectsUrl, params, totalFetched)
  }

  const {response, error} = yield call(API.get, loadMore as string)

  if (response) {
    yield put({
      type: type.myProjects.loadMoreSearch.succeeded,
      payload: {
        data: response.data.items,
        loadMore,
      },
    })
  } else {
    yield put({
      type: type.myProjects.loadMoreSearch.failed,
      payload: error,
    })
  }
}

function* addMyProjectCandidateEvaluation(
  action: ActionType<SeekerEvaluationType>
) {
  const {response, error} = yield call(
    API.put,
    `/company-user/projects/${action.payload?.projectId}/${action.payload?.seekerId}/evaluation`,
    {
      evaluation: action.payload?.evaluation,
    }
  )
  if (response) {
    yield put({
      type: type.myProjects.rateCandidate.add.succeeded,
      payload: {
        seekerId: action.payload?.seekerId,
        evaluation: action.payload?.evaluation,
      },
    })
  } else {
    yield put({
      type: type.myProjects.rateCandidate.add.failed,
      error,
    })
  }
}

function* editMyProjectCandidateEvaluation(
  action: ActionType<SeekerEvaluationType>
) {
  const {response, error} = yield call(
    API.put,
    `/company-user/projects/${action.payload?.projectId}/${action.payload?.seekerId}/evaluation`,
    {
      evaluation: action.payload?.evaluation,
    }
  )
  if (response) {
    yield put({
      type: type.myProjects.rateCandidate.edit.succeeded,
      payload: {
        seekerId: action.payload?.seekerId,
        evaluation: action.payload?.evaluation,
      },
    })
  } else {
    yield put({
      type: type.myProjects.rateCandidate.edit.failed,
      error,
    })
  }
}

function* deleteMyProjectCandidateEvaluation(
  action: ActionType<SeekerEvaluationType>
) {
  const {response, error} = yield call(
    API.delete,
    `/company-user/projects/${action.payload?.projectId}/${action.payload?.seekerId}/evaluation`
  )
  if (response) {
    yield put({
      type: type.myProjects.rateCandidate.delete.succeeded,
      payload: {
        seekerId: action.payload?.seekerId,
      },
    })
  } else {
    yield put({
      type: type.myProjects.rateCandidate.delete.failed,
      error,
    })
  }
}

function* setMyProjectCandidateReview(action: ActionType<SeekerReviewType>) {
  const {response, error} = yield call(
    API.put,
    `/company-user/projects/seeker/${action.payload?.reviewId}`,
    {
      review: action.payload?.reviewed,
    }
  )
  if (response) {
    yield put({
      type: type.myProjects.reviewCandidate.edit.succeeded,
      payload: {
        id: action.payload?.id,
        reviewed: action.payload?.reviewed,
      },
    })
  } else {
    yield put({
      type: type.myProjects.reviewCandidate.edit.failed,
      error,
    })
  }
}

function* getSearchResultsSavedCandidates(action: ActionType<any>) {
  const params = {...action.payload.params}
  const urlWithParams = generateURL('', params, 0, 10)
  const {response, error} = yield call(
    API.get,
    `company-user/projects/${action.payload?.projectId}/seekers${urlWithParams}`
  )
  if (response) {
    yield put({
      type: type.myProjects.searchSavedCandidates.succeeded,
      payload: {
        data: response.data.items,
        total: response.data.total,
        loadMore: urlWithParams,
        params,
      },
    })
  } else {
    yield put({
      type: type.myProjects.searchSavedCandidates.failed,
      error,
    })
  }
}

export default function* MyProjectsSaga(): Generator {
  yield all([
    takeLatest(type.myProjects.project.get.requested, getMyProject),
    takeLatest(
      type.myProjects.projectCandidates.get.requested,
      getMyProjectCandidates
    ),
    takeLatest(type.myProjects.widget.get.requested, getMyWidgetProjects),
    takeLatest(type.myProjects.all.get.requested, getAllProjects),
    takeLatest(
      type.myProjects.addToNewProject.requested,
      addCandidateToNewProject
    ),
    takeLatest(type.myProjects.addToProject.requested, addCandidateToProjects),
    takeLatest(
      type.myProjects.removeFromProjects.requested,
      removeCandidateFromProjects
    ),
    takeLatest(
      type.myProjects.removeFromAllProjects.requested,
      removeCandidateFromAllProjects
    ),
    takeLatest(type.myProjects.addNewProject.requested, addNewProject),
    takeLatest(type.myProjects.editProject.requested, editProject),
    takeLatest(type.myProjects.delete.requested, deleteProject),
    takeLatest(type.myProjects.archive.requested, archiveProject),
    takeLatest(
      type.myProjects.rateCandidate.add.requested,
      addMyProjectCandidateEvaluation
    ),
    takeLatest(
      type.myProjects.rateCandidate.edit.requested,
      editMyProjectCandidateEvaluation
    ),
    takeLatest(
      type.myProjects.rateCandidate.delete.requested,
      deleteMyProjectCandidateEvaluation
    ),
    takeLatest(type.myProjects.loadMore.requested, fetchMoreProjects),
    takeLatest(type.myProjects.search.requested, searchMyProjects),
    takeLatest(
      type.myProjects.reviewCandidate.edit.requested,
      setMyProjectCandidateReview
    ),
    takeLatest(
      type.myProjects.projectCandidates.loadMore.requested,
      fetchMoreProjectCandidates
    ),
    takeLatest(
      type.myProjects.searchSavedCandidates.requested,
      getSearchResultsSavedCandidates
    ),
    takeLatest(
      type.myProjects.loadMoreSearch.requested,
      fetchMoreSearchResults
    ),
  ])
}
