import styles from './UnderConstruction.module.scss'
import Preferenca from 'Assets/Images/preferenca-logo.svg'
import {Event, Cached, HelpOutline} from '@mui/icons-material'

import {Link} from 'react-router-dom'

export const UnderConstruction = () => (
  <div className={styles.container}>
    <div className={styles.background}></div>
    <div className={styles.contentLayout}>
      <Link to="/">
        <div className={styles.logoContainer}>
          <img className={styles.logoStyle} src={Preferenca} alt="Logo" />
        </div>
      </Link>
      <div className={styles.title}>Stran v izdelavi</div>
      <div className={styles.info}>
        <div className={styles.circle1}>
          <HelpOutline />
          <div className={styles.desktopQText}>KAJ?</div>
          <div className={styles.text}>
            Prva zaposlitvena platforma{' '}
            <span className={styles.bold}>osredotočena na iskalce -</span>
            predvsem razvijalce, strojnike in elektrotehnike
          </div>
        </div>
        <div className={styles.circle2}>
          <Cached />
          <div className={styles.desktopQText}>KAKO?</div>
          <div className={styles.text}>
            <span className={styles.bold}>Zamenjana pravila igre -</span>
            podjetja pošiljajo prijave, iskalci le izbirajo.
          </div>
        </div>
        <div className={styles.circle3}>
          <Event />
          <div className={styles.desktopQText}>KDAJ?</div>
          <div className={styles.text}>
            <span className={styles.bold}>Prihaja septembra</span>
          </div>
        </div>
      </div>
    </div>
  </div>
)
