import {SpectoProposeNewTimeParamsType} from 'Interfaces'
import {connect} from 'react-redux'

import {AppDispatch} from 'Store'
import {
  interviewInvitationsApprove,
  interviewInvitationsReject,
  setSpectoProposeNewTime,
} from 'Store/Actions'
import {ModalInterviewInvitationEmp} from './ModalInterviewInvitationEmp'

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  interviewInvitationsApprove: (payload: string) =>
    dispatch(interviewInvitationsApprove(payload)),
  interviewInvitationsReject: (payload: string) =>
    dispatch(interviewInvitationsReject(payload)),
  setSpectoProposeNewTime: (payload: SpectoProposeNewTimeParamsType) =>
    dispatch(setSpectoProposeNewTime(payload)),
})

export const ModalInterviewInvitationEmpContainer = connect(
  null,
  mapDispatchToProps
)(ModalInterviewInvitationEmp)
