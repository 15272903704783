import * as Yup from 'yup'
import {mapValues} from 'lodash'

const Validation = Yup.object().shape({
  titles: Yup.lazy((obj) =>
    Yup.object(mapValues(obj, () => Yup.string().max(50, 'Title is too long.')))
  ),
})

export default Validation
