export const companyImpressions = {
  'companyImpressions.impressionsTitle': 'Utrinki',
  'companyImpressions.featuredTitle': 'Izpostavljeno',

  'companyImpressions.modalTitle': 'Uredite utrinke',
  'companyImpressions.modalImpressionsTitle': 'UTRINKA',
  'companyImpressions.modalImpressionsTitleTitle': ' IME UTRINKA',
  'companyImpressions.filesNumberLimit': 'Naložite lahko največ 12 fotografij.',
  'companyImpressions.supportedFileFormats':
    'Podprti formati: .jpg, .jpeg, .png, .gif (Maksimalna velikost: 5MB) Naložite lahko največ 12 fotografij.',
  'companyImpressions.apiError': 'An error has occurred',
}
