import classNames from 'classnames'
import {IncomingRequestType} from 'Interfaces'
import {FC, useState} from 'react'
import styles from './IncomingRequests.module.scss'
import {RequestListItemContainer} from './RequestListItem/RequestListItemContainer'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {FormattedMessage} from 'react-intl'
import {CandidateDashboardTranslations as cdt} from 'Services/I18n/Constants'

type IncomingRequestsProps = {
  sortIncomingRequests?: (sort: string, order: string) => void
  requests: IncomingRequestType[]
}

export const IncomingRequests: FC<IncomingRequestsProps> = ({
  requests,
  sortIncomingRequests,
}) => {
  const [order, setOrder] = useState('DESC')
  const [sort, setSort] = useState('status')

  const onSort = (value: string) => {
    if (sortIncomingRequests) {
      if (sort === value) {
        setOrder((old) => (old === 'ASC' ? 'DESC' : 'ASC'))
      } else {
        setSort(value)
        setOrder('ASC')
      }
      sortIncomingRequests(sort, order)
    }
  }

  const HeaderTitle = ({value, title}: {value: string; title: string}) => {
    const className = classNames({
      [styles.headerTitle]: true,
      [styles.active]: !!sortIncomingRequests && value === sort,
      [styles.desc]: order === 'DESC',
      [styles.sortBtn]: !!sortIncomingRequests,
    })

    return (
      <div onClick={() => onSort(value)} className={className}>
        <FormattedMessage id={title} />
        <ArrowDropDownIcon />
      </div>
    )
  }

  return (
    <div className={styles.contentWrap}>
      <div className={styles.contentHeader}>
        <HeaderTitle value="companyName" title={cdt.company} />
        <HeaderTitle value="type" title={cdt.viewDetails} />
        <HeaderTitle value="status" title={cdt.status} />
        <HeaderTitle value="expiration" title={cdt.expire} />
        <div className={styles.leftMargin}>
          <FormattedMessage id={cdt.action} />
        </div>
      </div>
      {requests.map((request, idx) => (
        <RequestListItemContainer key={idx} request={request} />
      ))}
    </div>
  )
}
