import classnames from 'classnames'
import {Popover, PopoverItemType} from 'Components/Popover'
import {FC, MouseEventHandler, ReactNode, useState} from 'react'
import styles from './PopoverList.module.scss'

type PopoverListProps = {
  triggerText?: string
  triggerIcon?: ReactNode
  items: PopoverItemType[]
  disablePortal?: boolean
  theme?: string
  threeDotsBtn?: boolean
  className?: string
  shouldCloseOnSelection?: boolean
}

export const PopoverList: FC<PopoverListProps> = ({
  triggerText,
  triggerIcon,
  items,
  theme,
  threeDotsBtn,
  className,
  shouldCloseOnSelection,
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)
  const handleClick: MouseEventHandler = (event) => {
    event.stopPropagation()
    setIsPopoverOpen(!isPopoverOpen)
  }

  return (
    <div
      className={styles.container}
      tabIndex={0}
      onBlur={(e) => {
        if (!e.currentTarget.contains(e.relatedTarget)) {
          setIsPopoverOpen(false)
        }
      }}
    >
      <button
        onClick={handleClick}
        className={classnames(styles.trigger, className, {
          [styles.threeDotsBtn]: threeDotsBtn,
        })}
      >
        {triggerIcon && triggerIcon}
        {triggerText && triggerText}
      </button>
      <Popover
        items={items}
        theme={theme}
        open={isPopoverOpen}
        onClick={() => {
          setIsPopoverOpen(!shouldCloseOnSelection)
        }}
      />
    </div>
  )
}
