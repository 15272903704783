import {getTranslation} from 'Services/I18n/Utils'

export const frequencyOptions = [
  {
    id: 'disabled',
    translation: getTranslation('Disabled'),
  },
  {
    id: 'daily',
    translation: getTranslation('Daily'),
  },
  {
    id: 'monday',
    translation: getTranslation('Monday'),
  },
  {
    id: 'tuesday',
    translation: getTranslation('Tuesday'),
  },
  {
    id: 'wednesday',
    translation: getTranslation('Wednesday'),
  },
  {
    id: 'thursday',
    translation: getTranslation('Thursday'),
  },
  {
    id: 'friday',
    translation: getTranslation('Friday'),
  },
  {
    id: 'saturday',
    translation: getTranslation('Saturday'),
  },
  {
    id: 'sunday',
    translation: getTranslation('Sunday'),
  },
  {
    id: 'monthly',
    translation: getTranslation('Monthly'),
  },
]
