import {RootState} from './../Reducers/index'
import {getChannel} from 'Utils/CommonHelpers'
import {
  ActionType,
  ChangeCognitiveConsentStatusType,
  ChangeConsentStatusType,
  ChangeSelbyMillsConsentStatusType,
} from 'Interfaces'
import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import API from 'Services/API'
import * as type from 'Store/types'
import {getRoute, getRouteLang} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {history} from 'Store/history'

function* fetchConsents() {
  const userType: string = yield select(
    (state: RootState) => state.auth?.data?.type
  )
  const {response, error} = yield call(
    API.get,
    `${
      userType === 'company_user'
        ? '/consent/company-consents'
        : '/consent/user-consents'
    }`
  )

  if (response) {
    yield put({
      type: type.consents.getConsents.succeeded,
      payload: response.data.items,
    })
  } else {
    yield put({type: type.consents.getConsents.failed, error})
  }
}

function* changeShelbyMillsConsentStatus(
  action: ActionType<ChangeSelbyMillsConsentStatusType>
) {
  const {response, error} = yield call(API.patch, '/consent/user-consents', {
    consents: [
      {
        id: action.payload?.consentId as string,
        status: 'granted',
      },
    ],
  })

  if (response) {
    yield put({
      type: type.consents.changeConsentStatus.succeeded,
      payload: response.data.items,
    })

    yield put({
      type: type.assessments.startAssessment.requested,
      payload: action.payload?.testId,
    })

    if (action.payload) {
      var form = document.createElement('form')
      //form.setAttribute('target', '_blank') TODO
      form.setAttribute('method', 'post')
      const routeLang = getRouteLang()
      form.setAttribute(
        'action',
        getChannel().selbyMillsPostUrls[routeLang] as string
      )

      form.style.display = 'none'

      const lastName = document.createElement('input')
      lastName.setAttribute('type', 'text')
      lastName.setAttribute('name', 'Biog4')
      lastName.setAttribute('value', action.payload.lastName)

      const firstName = document.createElement('input')
      firstName.setAttribute('type', 'text')
      firstName.setAttribute('name', 'Biog5')
      firstName.setAttribute('value', action.payload.firstName)

      const email = document.createElement('input')
      email.setAttribute('type', 'text')
      email.setAttribute('name', 'Biog7')
      email.setAttribute('value', action.payload.email)

      const initials = document.createElement('input')
      initials.setAttribute('type', 'text')
      initials.setAttribute('name', 'Biog6')
      initials.setAttribute('value', action.payload.initials)

      const title = document.createElement('input')
      title.setAttribute('type', 'text')
      title.setAttribute('name', 'Biog3')
      title.setAttribute('value', action.payload.title)

      const id = document.createElement('input')
      id.setAttribute('type', 'text')
      id.setAttribute('name', 'Biog27')
      id.setAttribute('value', action.payload.id)

      form.appendChild(lastName)
      form.appendChild(firstName)
      form.appendChild(email)
      form.appendChild(initials)
      form.appendChild(title)
      form.appendChild(id)
      document.body.appendChild(form)

      form.submit()
    }
  } else {
    yield put({
      type: type.consents.changeConsentStatus.failed,
      payload: error,
    })
  }
}

function* changeConsentStatus(action: ActionType<ChangeConsentStatusType>) {
  const userType: string = yield select(
    (state: RootState) => state.auth?.data?.type
  )

  const {response, error} = yield call(
    API.patch,
    `${
      userType === 'company_user'
        ? '/consent/company-consents'
        : '/consent/user-consents'
    }`,
    {
      consents: [
        {
          id: action.payload?.consentId as string,
          status: action.payload?.consentStatus as string,
          ...(action.payload?.companyId !== null && {
            companyId: action.payload?.companyId,
          }),
        },
      ],
    }
  )
  if (response) {
    yield put({
      type: type.consents.changeConsentStatus.succeeded,
      payload: response.data.items,
    })
  } else {
    yield put({
      type: type.consents.changeConsentStatus.failed,
      payload: error,
    })
  }
}

function* changeCognitiveConsentStatus(
  action: ActionType<ChangeCognitiveConsentStatusType>
) {
  const {response, error} = yield call(API.patch, '/consent/user-consents', {
    consents: [
      {
        id: action.payload?.consentId as string,
        status: action.payload?.consentStatus as string,
      },
    ],
  })
  if (response) {
    yield put({
      type: type.consents.changeCognitiveConsentStatus.succeeded,
      payload: action.payload,
    })

    history.push(
      `${getRoute(AppRoute.StartCognitiveTest)}?testId=${
        action.payload?.testId
      }&consentId=${action.payload?.consentId}`
    )
  } else {
    yield put({
      type: type.consents.changeCognitiveConsentStatus.failed,
      payload: error,
    })
  }
}

export default function* ConsentsSaga(): Generator {
  yield all([
    takeLatest(type.consents.getConsents.requested, fetchConsents),
    takeLatest(
      type.consents.changeShelbyMillsConsentStatus.requested,
      changeShelbyMillsConsentStatus
    ),
    takeLatest(
      type.consents.changeConsentStatus.requested,
      changeConsentStatus
    ),
    takeLatest(
      type.consents.changeCognitiveConsentStatus.requested,
      changeCognitiveConsentStatus
    ),
  ])
}
