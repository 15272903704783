import {ResetPassword} from './ResetPassword'
import {connect} from 'react-redux'
import {resetPassword, hideResetPasswordMessage} from 'Store/Actions'
import {AppDispatch} from 'Store'
import {ResetPasswordType} from 'Interfaces'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  resetPassword: (data: ResetPasswordType) => dispatch(resetPassword(data)),
  hideResetPasswordMessage: () => dispatch(hideResetPasswordMessage()),
})

export const ResetPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPassword)
