import {FC} from 'react'
import styles from './ItemHeader.module.scss'
import classnames from 'classnames'
import {FormattedMessage} from 'react-intl'
import {OverridableComponent} from '@mui/material/OverridableComponent'
import {SvgIconTypeMap} from '@mui/material'
import {DeleteOutline, EditOutlined} from '@mui/icons-material'

interface ItemHeaderProps {
  title: string
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string
  }
  onEdit?: () => void
  onDelete?: () => void
  className?: string
  titleExpand?: string
}

export const ItemHeader: FC<ItemHeaderProps> = ({
  icon,
  title,
  onEdit,
  onDelete,
  className,
  titleExpand,
}: ItemHeaderProps) => {
  const ItemIcon = icon

  return (
    <div className={classnames(styles.header, className)}>
      <div className={styles.icon}>
        {ItemIcon && <ItemIcon sx={{fontSize: 28, color: '#fbb015'}} />}
      </div>
      <div className={styles.title}>
        {title && (
          <div>
            <FormattedMessage id={title} />
            {titleExpand && (
              <span className={styles.titleExpand}>{` (${titleExpand})`}</span>
            )}
          </div>
        )}
      </div>
      {onEdit && (
        <button onClick={onEdit}>
          <EditOutlined sx={{fontSize: 25}} />
        </button>
      )}
      {onDelete && (
        <button onClick={onDelete} className={styles.deleteIcon}>
          <DeleteOutline sx={{fontSize: 25}} />
        </button>
      )}
    </div>
  )
}
