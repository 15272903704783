import {CancelInterviewType} from 'Interfaces'
import {connect} from 'react-redux'

import {AppDispatch} from 'Store'
import {interviewInvitationsCancel} from 'Store/Actions'
import {ModalEmployerCancelInterview} from './ModalEmployerCancelInterview'

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  cancelInterviewInvitation: (payload: CancelInterviewType) =>
    dispatch(interviewInvitationsCancel(payload)),
})

export const ModalEmployerCancelInterviewContainer = connect(
  null,
  mapDispatchToProps
)(ModalEmployerCancelInterview)
