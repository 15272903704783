// import classNames from 'classnames'
import {FC} from 'react'
import styles from './CircularProgressBar.module.scss'
import {Avatar} from 'Components/Widgets/Avatar'
import {ImageType} from 'Interfaces'
interface CircularProgressBarProps {
  sqSize: number
  percentage: number
  strokeWidth: number
  image?: ImageType | File | null
  firstName?: string | null
  lastName?: string | null
}

export const CircularProgressBar: FC<CircularProgressBarProps> = ({
  sqSize = 145,
  percentage = 100,
  strokeWidth = 5,
  image,
  firstName,
  lastName,
}) => {
  // Size of the enclosing square
  const enclosingSqSize = sqSize

  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (enclosingSqSize - strokeWidth) / 2

  //Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${enclosingSqSize} ${enclosingSqSize}`

  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2

  // Scale 100% coverage overlay with given percent
  const dashOffset = dashArray - (dashArray * percentage) / 100
  return (
    <div className={styles.circleProgressBarContainer}>
      <div className={styles.image}>
        <Avatar
          firstName={firstName as string}
          lastName={lastName as string}
          image={image as ImageType}
        />
      </div>
      <p className={styles.circleText}>{`${percentage}%`}</p>
      <svg
        className={styles.circleSvg}
        width={enclosingSqSize}
        height={enclosingSqSize}
        viewBox={viewBox}
      >
        <circle
          className={styles.circleBackground}
          cx={enclosingSqSize / 2}
          cy={enclosingSqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
        />
        <circle
          className={styles.circleProgress}
          cx={enclosingSqSize / 2}
          cy={enclosingSqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          // Progress is starting at 12 clock
          transform={`rotate(-90 ${enclosingSqSize / 2} ${
            enclosingSqSize / 2
          })`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
        />
      </svg>
    </div>
  )
}
