import * as Yup from 'yup'
import {ExtraLongHTML} from 'Validations'

const Validation = Yup.object().shape({
  aboutCompany: ExtraLongHTML,
  mainActivities: ExtraLongHTML,
  visionAndGoals: ExtraLongHTML,
  structureAndCulture: ExtraLongHTML,
  customCompanyDescription: ExtraLongHTML,
})

export default Validation
