export const unsubscribeReason = {
  'unsubscribeReason.title': 'You unsubscribed successfully. ',
  'unsubscribeReason.description':
    'As we value your opinion, please let us know how satisfied you are with the content of the newsletters you receive.',
  'unsubscribeReason.reasonSuccess':
    'You have successfully unsubscribed from newsletter.',
  'unsubscribeReason.warning': 'There has been a mistake. Please try again. ',
  'unsubscribeReason.checkBoxLabel1': 'Newsletter is too frequent',
  'unsubscribeReason.checkBoxLabel2': 'I am not interested in newsletter',
  'unsubscribeReason.checkBoxLabel3': 'Content is not what I expected it to be',
  'unsubscribeReason.checkBoxLabel4': 'Other',
  'unsubscribeReason.button': 'Send',
}
