import {Button} from 'Components/Button'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'
import {FC} from 'react'
import styles from './ButtonThumb.module.scss'

interface ButtonThumbProps {
  children: string
  theme: string
  onClick: () => void
}

export const ButtonThumb: FC<ButtonThumbProps> = ({
  theme,
  children,
  onClick,
}) => {
  return (
    <Button
      text={children}
      theme={'thumb'}
      icon={
        theme === 'thumbUp' ? (
          <ThumbUpOffAltIcon className={styles.green} />
        ) : (
          <ThumbDownOffAltIcon className={styles.red} />
        )
      }
      onClick={onClick}
      className={styles.buttonThumb}
    />
  )
}
