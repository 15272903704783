import {ChangeEvent, FC, useState, useMemo} from 'react'
import classnames from 'classnames'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import styles from './Dropdown.module.scss'
import {BaseTaxonomyType} from 'Interfaces'
import {RadioIcon} from 'Components/Icons'
import TextError from 'Components/Error/TextError'
import {FormattedMessage} from 'react-intl'

interface Props {
  name: string
  value?: string | number
  items: BaseTaxonomyType[] | number[] | string[]
  setValue: (name: string, value?: string | number) => void
  error?: string
  label?: string
  withRadio?: boolean
  theme?: 'grey' | 'white' | 'whiteShortList' | 'withoutShadow'
  emptyValueLabel?: string
  setTouched?: (v: boolean) => void
  className?: string
  disabled?: boolean
  listUp?: boolean
}

export const Dropdown: FC<Props> = ({
  name,
  items,
  value,
  setValue,
  error,
  label,
  withRadio = false,
  theme = 'grey',
  emptyValueLabel = 'Select from list',
  setTouched,
  className,
  disabled = false,
  listUp = false,
}) => {
  const [open, setOpen] = useState(false)

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(name, e.currentTarget.value)
    if (setTouched) {
      setTouched(true)
    }
  }

  const localItems = useMemo<BaseTaxonomyType[]>(() => {
    if (typeof items[0] !== 'number') {
      return items as BaseTaxonomyType[]
    }
    return items.map((i) => {
      return {
        translation: i.toString(),
        id: i.toString(),
      } as BaseTaxonomyType
    })
  }, [items])

  const localValue = useMemo<BaseTaxonomyType>(() => {
    const cmpValue = typeof value === 'string' ? value : value?.toString()
    return localItems.find((l) => l.id === cmpValue) as BaseTaxonomyType
  }, [value, localItems])

  const component = useMemo(() => {
    return (
      <div
        className={classnames(
          styles.dropdownContainer,
          className,
          styles[theme],
          {
            [styles.open]: open,
            [styles.disabled]: disabled,
          }
        )}
        tabIndex={0}
        onBlur={() => {
          setOpen(false)
        }}
      >
        <div
          onClick={() => {
            if (!disabled) setOpen(!open)
          }}
          className={classnames(styles.dropdownBTN, {
            [styles.errorBorder]: error,
          })}
        >
          <div>
            {label && (
              <p
                className={classnames(styles.dropdownLabel, {
                  [styles.error]: error,
                })}
              >
                <FormattedMessage id={label} />
              </p>
            )}
            {!value ? (
              <p className={styles.dropdownValue}>
                <FormattedMessage id={emptyValueLabel} />
              </p>
            ) : (
              <p className={styles.dropdownValue}>{localValue?.translation}</p>
            )}
          </div>
          <ArrowDropDownIcon
            className={classnames(styles.arrow, {
              [styles.rotate]: open,
            })}
          />
        </div>
        <div
          className={classnames(styles.dropdownList, {
            [styles.open]: open,
            [styles.listUp]: listUp,
          })}
        >
          {localItems.map((item, index) => (
            <div key={`${item.id}-${index}`}>
              <label className={styles.itemLabel}>
                {withRadio && (
                  <RadioIcon selected={localValue?.id === item.id} />
                )}
                <input
                  onClick={() => {
                    if (!disabled) setOpen(!open)
                  }}
                  onChange={handleChange}
                  className={styles.hidden}
                  type="radio"
                  name={name}
                  value={item.id}
                />
                <span className={styles.translation}>{item.translation}</span>
              </label>
            </div>
          ))}
        </div>
        {error && <TextError text={error} />}
      </div>
    )
  }, [
    localItems,
    open,
    name,
    setValue,
    error,
    label,
    withRadio,
    theme,
    emptyValueLabel,
    setTouched,
    className,
    disabled,
    localValue,
  ])

  return component
}
