import {connect} from 'react-redux'

import {SearchHeader} from './SearchHeader'
import {
  sendContactRequestsBulk,
  sortCandidatesSearchResults,
  saveCandidateSearch,
  updateSavedSearch,
} from 'Store/Actions'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {SavedSearchType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  taxonomies: state.taxonomies,
  searchKeywords: state.searchCandidates.searchKeywords,
  savedSearch: state.searchCandidates.savedSearch,
  filtersType: state.searchCandidates.filtersType,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  // TODO: add test & contact request bulk sagas
  sendContactRequestsBulk: (ids: string[]) =>
    dispatch(sendContactRequestsBulk(ids)),
  sortCandidatesSearchResults: (sortBy: string) =>
    dispatch(sortCandidatesSearchResults(sortBy)),
  saveCandidateSearch: (value: SavedSearchType) =>
    dispatch(saveCandidateSearch(value)),
  updateSavedSearch: (value: SavedSearchType) =>
    dispatch(updateSavedSearch(value)),
})

export const SearchHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchHeader)
