import {FC, ReactNode} from 'react'
import styles from './Button.module.scss'
import classnames from 'classnames'
import {FormattedMessage} from 'react-intl'
export interface ButtonType {
  text?: string
  className?: string
  type?: 'submit' | 'reset' | 'button'
  theme?: string
  onClick?: () => void
  icon?: JSX.Element
  disabled?: boolean
  children?: string | ReactNode
}
export const Button: FC<ButtonType> = ({
  text,
  className,
  type = 'submit',
  theme = 'blue',
  onClick,
  icon,
  disabled,
  children = null,
}: ButtonType) => {
  return (
    <button
      className={classnames(
        styles.button,
        className,
        styles[theme] ? styles[theme] : ''
      )}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      {icon && <div className={styles.icon}>{icon}</div>}
      <span>
        {text && <FormattedMessage id={text} />}
        {children}
      </span>
    </button>
  )
}
