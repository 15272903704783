import * as type from 'Store/types'
import {
  ActionType,
  ErrorType,
  HomepageStateType,
  InviteFriendFormikType,
  TestimonialType,
  NewsletterConsent,
  TopCompaniesType,
  UpdateNewsletterConsentResponseType,
} from 'Interfaces'

const initialState = {
  inviteFriend: {
    data: undefined,
    loading: false,
    error: undefined,
  },
  testimonials: {
    data: [],
    loading: false,
    error: undefined,
  },
  topCompanies: {
    data: [],
    loading: false,
    error: undefined,
  },
  consents: {
    data: [],
    loading: false,
    error: undefined,
  },
  consentsReasonLog: {
    data: undefined,
    loading: false,
    error: undefined,
    success: false,
  },
}

const HomepageReducer = (
  state: HomepageStateType = initialState,
  action: ActionType<
    | InviteFriendFormikType
    | TestimonialType[]
    | TopCompaniesType[]
    | NewsletterConsent[]
    | UpdateNewsletterConsentResponseType
    | string[]
    | ErrorType
  >
): HomepageStateType => {
  switch (action.type) {
    case type.homepage.inviteFriend.requested:
      return {
        ...state,
        inviteFriend: {
          ...state.inviteFriend,
          loading: true,
          error: undefined,
        },
      }
    case type.homepage.inviteFriend.succeeded:
      return {
        ...state,
        inviteFriend: {
          data: true,
          loading: false,
          error: undefined,
        },
      }
    case type.homepage.inviteFriend.failed:
      return {
        ...state,
        inviteFriend: {
          data: false,
          loading: false,
          error: action.payload as ErrorType,
        },
      }
    case type.homepage.testimonials.requested:
      return {
        ...state,
        testimonials: {
          ...state.testimonials,
          loading: true,
          error: undefined,
        },
      }
    case type.homepage.consents.requested:
      return {
        ...state,
        consents: {
          ...state.consents,
          data: [],
          loading: true,
          error: undefined,
        },
      }
    case type.homepage.testimonials.succeeded:
      return {
        ...state,
        testimonials: {
          ...state.testimonials,
          data: action.payload as TestimonialType[],
          loading: false,
          error: undefined,
        },
      }
    case type.homepage.testimonials.failed:
      return {
        ...state,
        testimonials: {
          ...state.testimonials,
          loading: false,
          error: action.payload as ErrorType,
        },
      }
    case type.homepage.topCompanies.requested:
      return {
        ...state,
        topCompanies: {
          ...state.topCompanies,
          loading: true,
          error: undefined,
        },
      }
    case type.homepage.topCompanies.succeeded:
      return {
        ...state,
        topCompanies: {
          ...state.topCompanies,
          data: action.payload as TopCompaniesType[],
          loading: false,
          error: undefined,
        },
      }
    case type.homepage.topCompanies.failed:
      return {
        ...state,
        topCompanies: {
          ...state.topCompanies,
          loading: false,
          error: action.payload as ErrorType,
        },
      }
    case type.homepage.consents.succeeded:
      return {
        ...state,
        consents: {
          ...state.consents,
          data: action.payload as NewsletterConsent[],
          loading: true,
          error: undefined,
        },
      }
    case type.homepage.consents.failed:
      return {
        ...state,
        consents: {
          ...state.consents,
          loading: false,
          error: action.payload as ErrorType,
        },
      }

    case type.homepage.updateConsents.requested:
      return {
        ...state,
        consents: {
          ...state.consents,
          loading: true,
          error: undefined,
        },
      }
    case type.homepage.updateConsents.succeeded:
      return {
        ...state,
        consentsReasonLog: {
          ...state.consentsReasonLog,
          data: action.payload as string[],
        },
      }
    case type.homepage.logConsentReason.succeeded:
      return {
        ...state,
        consentsReasonLog: {
          ...state.consentsReasonLog,
          success: true,
        },
      }
    case type.homepage.updateConsents.failed:
      return {
        ...state,
        consentsReasonLog: {
          ...state.consentsReasonLog,
          data: undefined,
        },
      }
    default:
      return state
  }
}

export default HomepageReducer
