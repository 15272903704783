import {
  FC,
  ReactNode,
  useState,
  useEffect,
  useRef,
  MutableRefObject,
} from 'react'
import {useMediaQuery} from 'react-responsive'
import styles from './WidgetBar.module.scss'
import classNames from 'classnames'
import {Button} from 'Components/Button'
import {Close, ArrowBack} from '@mui/icons-material'
import {ApplicationsMenu} from '../ApplicationsMenu'
import SettingsMenu from '../SettingsMenu'
import {useLocation} from 'react-router-dom'
import {Candidate, IncomingRequestSeekerType} from 'Interfaces'

interface iWidgetBarProps {
  Sidebar?: ReactNode
  sidebarContent?: ReactNode
  toggleSidebarStatus?: () => void
  setSidebarWidgetRef?: (value: MutableRefObject<any>) => void
  sidebarClosed?: boolean
  setSidebarClosed: (sidebarClosed: boolean) => void
  containerClass?: string
  setCandidateBasicData: (
    candidate: Candidate | IncomingRequestSeekerType | null
  ) => void
}

const WidgetBar: FC<iWidgetBarProps> = ({
  sidebarContent,
  setSidebarWidgetRef,
  sidebarClosed,
  setSidebarClosed,
  containerClass,
  setCandidateBasicData,
}: iWidgetBarProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const sidebarWidgetRef: MutableRefObject<any> = useRef()
  let location = useLocation()

  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)',
  })

  useEffect(() => {
    if (!isDesktop) {
      setSidebarClosed(true)
    }
  }, [location])

  useEffect(() => {
    if (setSidebarWidgetRef) {
      setSidebarWidgetRef(sidebarWidgetRef)
    }
  }, [])

  const handleClickOutside = (e: any) => {
    if (
      !e.target.closest(containerClass) &&
      !sidebarWidgetRef.current?.contains(e.target)
    ) {
      setCandidateBasicData(null)
    }
  }

  // TODO refactor this, use onBlur instead addEventListener
  useEffect(() => {
    if (containerClass) {
      document.addEventListener('mousedown', handleClickOutside)
      return () => document.removeEventListener('mousedown', handleClickOutside)
    }
    return
  })

  return (
    <div
      className={classNames(styles.sidebar)}
      onClick={(e) => e.stopPropagation}
      ref={sidebarWidgetRef}
    >
      {/* Search Sidebar */}
      <div className={styles.widgetHeaderBackgroundWrap}></div>
      <div
        className={classNames(styles.searchSidebar, {
          [styles.searchSidebarClosed]: sidebarClosed,
          [styles.searchSidebarOpened]: !sidebarClosed,
        })}
      >
        <div className={styles.avatarIconContainer}>
          <div className={styles.iconsContainer}>
            {/* <div className={styles.iconWrapper}>
              <icons.HelpOutline className={styles.helpIcon} />
            </div>
            <div className={styles.iconWrapper}>
              <icons.SettingsOutlined className={styles.settingsIcon} />
            </div> */}

            <div className={styles.iconWrapper}>
              <ApplicationsMenu
                setIsMenuOpen={setIsMenuOpen}
                isMenuOpen={isMenuOpen}
                className={styles.widgetBarApplicationsMenu}
              />
            </div>
          </div>
          <div
            className={classNames(styles.closeButtonAvatarWrapper, {
              [styles.closeButtonAvatarClosedWrapper]: sidebarClosed,
            })}
          >
            <div
              className={classNames(styles.settingsMenuWrap, {
                [styles.settingsMenuWrapMobile]: sidebarClosed,
              })}
              onClick={(e) => e.stopPropagation}
            >
              <SettingsMenu />
            </div>

            {isDesktop && !isMenuOpen && (
              <Button
                onClick={() => {
                  setSidebarClosed(!sidebarClosed)
                }}
                icon={sidebarClosed ? <ArrowBack /> : <Close />}
                className={styles.closeButton}
              />
            )}
            {!isDesktop && (
              <Button
                onClick={() => {
                  setSidebarClosed(!sidebarClosed)
                }}
                icon={sidebarClosed ? <ArrowBack /> : <Close />}
                className={styles.closeButton}
              />
            )}
          </div>
        </div>
        {sidebarContent && (
          <div className={styles.sidebarContentHolder}>{sidebarContent}</div>
        )}
      </div>
    </div>
  )
}

export default WidgetBar
