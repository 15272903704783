import Title from 'Components/Title'
import {
  AuthStateType,
  ChangeEmailFormikType,
  ValidationErrorType,
} from 'Interfaces'
import MainLayout from 'Layouts/MainLayout'
import {FC, useEffect} from 'react'
import styles from './ChangeEmail.module.scss'
import {useFormik} from 'formik'
import YellowButton from 'Components/YellowButton'
import {useHistory} from 'react-router-dom'
import {TextField} from '@mui/material'
import Validation from './Validation'
import TextError from 'Components/Error/TextError'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {MainHeader} from 'Components/MainHeader/MainHeader'
import {WidgetBarContainer} from 'Layouts/MainLayout/WidgetBar'
import {SidebarLink} from 'Components/SidebarLink'
import {
  ChangeEmailTranslations as cet,
  GeneralTranslations as gt,
  SidebarTranslations as st,
  MyAccountTranslations as mat,
} from 'Services/I18n/Constants'
import {useTranslation} from 'Hooks'
import {FormattedMessage} from 'react-intl'
import {CheckCircle} from '@mui/icons-material'

type ChangeEmailProps = {
  auth: AuthStateType
  changeEmail: (email: string) => void
  hideChangeEmailMessage: () => void
}

export const ChangeEmail: FC<ChangeEmailProps> = ({
  auth,
  changeEmail,
  hideChangeEmailMessage,
}) => {
  const history = useHistory()
  const translation = useTranslation()

  const defaultValues: ChangeEmailFormikType = {
    newEmail: '',
  }

  const validationError =
    auth.error && (auth.error as ValidationErrorType)?.details

  const error =
    auth.error?.message &&
    auth.error?.message !== 'Validation Exception' &&
    auth.error?.message

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: Validation,

    onSubmit: (values) => {
      changeEmail(values.newEmail as string)
    },
  })
  const companyUserRole =
    auth?.data?.role === 'employer' ||
    auth?.data?.role === 'company_owner' ||
    auth?.data?.role === 'recruiter'

  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: '/'},
    {
      name: mat.myAccount,
      link: getRoute(
        companyUserRole ? AppRoute.EmployerMyAccount : AppRoute.MyAccount
      ),
    },
    {
      name: st.changeEmailAddress,
      link: getRoute(
        companyUserRole ? AppRoute.EmployerChangeEmail : AppRoute.ChangeEmail
      ),
    },
  ]
  const hasError =
    formik.submitCount &&
    (formik.errors.newEmail ||
      validationError ||
      (formik.touched['newEmail'] && formik.errors.newEmail))

  useEffect(() => {
    if (auth.displayMessage.emailChanged) {
      setTimeout(() => {
        hideChangeEmailMessage()
      }, 5000)
    }
  }, [auth.displayMessage.emailChanged])

  return (
    <div className={styles.pageWrap}>
      <MainLayout
        fixedRight={false}
        rightSidebarContent={
          <WidgetBarContainer
            sidebarContent={
              <>
                <div className={styles.linksContainer}>
                  <SidebarLink
                    routeName={
                      companyUserRole
                        ? AppRoute.EmployerMyAccount
                        : AppRoute.MyAccount
                    }
                    text={st.editPersonalData}
                  />
                  <SidebarLink
                    routeName={
                      companyUserRole
                        ? AppRoute.EmployerChangeEmail
                        : AppRoute.ChangeEmail
                    }
                    text={st.changeEmailAddress}
                  />
                  <SidebarLink
                    routeName={
                      companyUserRole
                        ? AppRoute.EmployerChangePassword
                        : AppRoute.ChangePassword
                    }
                    text={st.changePassword}
                  />
                </div>
              </>
            }
          />
        }
      >
        <div className={styles.headerWrap}>
          <MainHeader breadcrumbsArray={breadcrumbsArray} title={cet.title} />
        </div>
        <Title text={cet.titleChangeEmail} className={styles.title} />
        <div className={styles.cardItemWrap}>
          <TextField
            name="oldEmail"
            label={translation[gt.emailAddress]}
            variant="filled"
            multiline
            value={auth.data?.email}
            InputProps={{
              readOnly: true,
              disableUnderline: true,
            }}
            className={styles.oldEmailInput}
          />
          <TextField
            name="newEmail"
            label={translation[cet.newEmailAddress]}
            placeholder={translation[cet.typeNewAddress]}
            multiline={false}
            variant="filled"
            onChange={(e) =>
              formik.setFieldValue('newEmail', e.currentTarget.value)
            }
            value={formik.values.newEmail}
            InputProps={{
              disableUnderline: true,
            }}
            className={hasError ? styles.withError : styles.emailInput}
          />
          {formik.submitCount &&
          formik.touched['newEmail'] &&
          formik.errors.newEmail ? (
            <TextError text={formik.errors.newEmail} />
          ) : null}
          {validationError && typeof validationError !== 'string'
            ? validationError.map((error) =>
                error.constraints.map((constraint) => (
                  <TextError text={constraint.message} />
                ))
              )
            : validationError}
          {error && <TextError text={error} />}
        </div>
        {auth.displayMessage.emailChanged && (
          <div className={styles.successMessage}>
            <CheckCircle />
            <FormattedMessage id={cet.successMessage} />
          </div>
        )}
        <div className={styles.ctasWrap}>
          <YellowButton
            text={cet.saveChanges}
            onClick={() => formik.submitForm()}
            className={styles.saveBtn}
          />
          <button className={styles.cancelBtn} onClick={() => history.goBack()}>
            <FormattedMessage id={cet.cancel} />
          </button>
        </div>
      </MainLayout>
    </div>
  )
}
