import {FC, useState} from 'react'
import {FormattedMessage} from 'react-intl'

import Modal from 'Components/Modal'
import {ThumbsFooter} from 'Components/Modal/ThumbsFooter'
import styles from './ModalApproveContactRequest.module.scss'
import ModalContactRequest from 'Components/IncomingRequests/Modals/ModalContactRequest'
import ProposeNewTimeModal from 'Components/IncomingRequests/RequestActions/ProposeNewTimeModal'
import {CandidateDashboardTranslations as cdt} from 'Services/I18n/Constants'
import {IncomingRequestType, ApproveRejectModalRequestType} from 'Interfaces'
import {CombinedRequestStatues} from 'Components/IncomingRequests/Constants'
import {ForumOutlined} from '@mui/icons-material'

interface ModalApproveContactRequestProps {
  request: IncomingRequestType
  approveModal?: boolean
  setApproveModal?: (approveModal: boolean) => void
  showDetails?: boolean
  setIsOpenContactRequest?: (setIsOpenContactRequest: boolean) => void
  approveContactRequest: (payload: ApproveRejectModalRequestType) => void
  getAssessments: () => void
  isOpenContactRequestDetails?: boolean
  setIsOpenContactRequestDetails?: (
    isOpenContactRequestDetails: boolean
  ) => void
}

export const ModalApproveContactRequest: FC<
  ModalApproveContactRequestProps
> = ({
  request,
  approveModal,
  setApproveModal,
  showDetails = true,
  setIsOpenContactRequest,
  getAssessments,
  isOpenContactRequestDetails,
  setIsOpenContactRequestDetails,
  approveContactRequest,
}) => {
  const [modalDetails, setModalDetails] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {!modalDetails ? (
        <Modal
          className={styles.modal}
          icon={ForumOutlined}
          isOpen={!!approveModal}
          title={cdt.approveContactRequest}
          onAfterClose={() => {
            setApproveModal && setApproveModal(false)
          }}
          Footer={
            request.status === CombinedRequestStatues.PENDING && (
              <ThumbsFooter
                theme={'thumbUp'}
                onApprove={() => {
                  approveContactRequest({
                    id: request.id,
                  })
                  setApproveModal && setApproveModal(false)
                  setIsOpenContactRequest && setIsOpenContactRequest(false)
                  getAssessments()
                }}
                approveText={cdt.approve}
                onCancel={() => {
                  setApproveModal && setApproveModal(false)
                }}
              />
            )
          }
        >
          <div className={styles.containerInterview}>
            <p className={styles.text}>
              <FormattedMessage id={cdt.modalApproveContactAreYouSure} />
              {request.companyName}?
            </p>
            <p className={styles.paragraph}>
              <FormattedMessage id={cdt.modalApproveContactWhitThis} />
            </p>

            <div className={styles.buttons}>
              {showDetails && (
                <button
                  onClick={() => {
                    setApproveModal && setApproveModal(false)
                    setModalDetails(true)
                    setIsOpenContactRequestDetails &&
                      setIsOpenContactRequestDetails(true)
                    setIsOpenContactRequest && setIsOpenContactRequest(false)
                  }}
                  className={styles.button}
                >
                  <FormattedMessage id={cdt.viewDetails} />
                </button>
              )}
            </div>
          </div>
        </Modal>
      ) : (
        <ModalContactRequest
          request={request}
          isOpenContactRequest={isOpenContactRequestDetails}
          setIsOpenContactRequest={setIsOpenContactRequestDetails}
          setModalDetails={setModalDetails}
          modalDetails={modalDetails}
          approveModal={approveModal}
          setApproveModal={setApproveModal}
        />
      )}

      <ProposeNewTimeModal
        request={request}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  )
}
