import {connect} from 'react-redux'

import {GeneralTermsAndConditions} from './GeneralTermsAndConditions'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
})

export const GeneralTermsAndConditionsContainer = connect(
  mapStateToProps,
  null
)(GeneralTermsAndConditions)
