import {connect} from 'react-redux'
import {CreateConversationType, CreateMessageType} from 'Interfaces'
import {AppDispatch} from 'Store'
import {createConversationSocket, createMessageSocket} from 'Store/Actions'
import {ModalSendMessageEmp} from './ModalSendMessageEmp'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  conversation: state.messageCenter.data[0],
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createConversation: (data: CreateConversationType) =>
    dispatch(createConversationSocket(data)),
  createMessage: (data: CreateMessageType) =>
    dispatch(createMessageSocket(data)),
})

export const ModalSendMessageEmpContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalSendMessageEmp)
