import {connect} from 'react-redux'

import {EmployerSeekerSpectoCalendar} from './EmployerSeekerSpectoCalendar'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {setSpectoCandidateId, getSpectoToken} from 'Store/Actions'

const mapStateToProps = (state: RootState) => ({
  spectoState: state.specto,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSpectoToken: () => dispatch(getSpectoToken()),
  setSpectoCandidateId: (id: string) => dispatch(setSpectoCandidateId(id)),
})

export const EmployerSeekerSpectoCalendarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerSeekerSpectoCalendar)
