import {connect} from 'react-redux'
import {CandidateMyProjectSidebarContent} from './CandidateMyProjectSidebarContent'
import {
  ConversationType,
  RemoveCandidateFromProjectsType,
  SendRequestContactType,
} from 'Interfaces'
import {AppDispatch} from 'Store'
import {
  getCandidateComments,
  getCandidateHistory,
  getConversationByUserIds,
  selectConversation,
  resetMessageCenterData,
  sendRequestContact,
  removeCandidateFromProjects,
} from 'Store/Actions'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  candidateBasicData: state.widgetBar.data.candidateBasicData,
  comments: state.widgetBar.data.comments,
  history: state.widgetBar.data.history,
  openedConversation: state.messageCenter.data[0],
  messages: state.messageCenter.openedConversation?.messages,
  request: state.widgetBar.data.request,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCandidateComments: (id: string) => dispatch(getCandidateComments(id)),
  getCandidateHistory: (id: string) => dispatch(getCandidateHistory(id)),
  getConversationByUserIds: (query: string) =>
    dispatch(getConversationByUserIds(query)),
  selectConversation: (conversation?: ConversationType) =>
    dispatch(selectConversation(conversation)),
  resetMessageCenterData: () => dispatch(resetMessageCenterData()),
  sendRequestContact: (value: SendRequestContactType) =>
    dispatch(sendRequestContact(value)),
  removeCandidateFromProjects: (value: RemoveCandidateFromProjectsType) =>
    dispatch(removeCandidateFromProjects(value)),
})

export const CandidateMyProjectSidebarContentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateMyProjectSidebarContent)
