import {connect} from 'react-redux'

import {EducationEndDate} from './EducationEndDate'
import {AppDispatch} from 'Store'
import {
  addEducation,
  deleteEducation,
  editEducation,
  getCountries,
  getEducationInstitutions,
  getEducationLevels,
} from 'Store/Actions'
import {RootState} from 'Store/Reducers'
import {EducationFormikValue} from 'Interfaces'
import {canChangeProfile} from 'Utils/Profile'

const mapStateToProps = (state: RootState) => ({
  taxonomies: state.taxonomies,
  educations: state.seeker.data?.full?.educations || [],
  profile: state.seeker,
  canChange: canChangeProfile(state.auth.data?.rjbProfileStatus),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getTaxonomies: () => {
    dispatch(getEducationLevels())
    dispatch(getEducationInstitutions())
    dispatch(getCountries())
  },
  addEducation: (value: EducationFormikValue) => dispatch(addEducation(value)),
  deleteEducation: (id: string) => dispatch(deleteEducation(id)),
  editEducation: (value: EducationFormikValue) =>
    dispatch(editEducation(value)),
})

export const EducationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EducationEndDate)
