import {FC, useEffect, useMemo, useState} from 'react'
import {useFormik} from 'formik'
import SectionHeader from 'Components/Profile/SectionHeader'
import SectionItem from 'Components/Profile/SectionItem'
import ItemHeader from 'Components/Profile/ItemHeader'
import ItemTextWithIcon from 'Components/Profile/ItemTextWithIcon'
import TextField from 'Components/Inputs/TextField'
import Checkbox from 'Components/Inputs/Checkbox'
import Dropdown from 'Components/Inputs/Dropdown'
import TextArea from 'Components/Inputs/TextArea'
import Modal, {DeleteModal} from 'Components/Modal'
import ShowMore from 'Components/Profile/ShowMore'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import {
  ContactDataTranslations as cd,
  DateTranslations as dt,
  OnboardingTranslations as ot,
  ProfileTranslations as pt,
} from 'Services/I18n/Constants'

import styles from './Education.module.scss'
import Validation from './Validation'
import {
  BaseTaxonomyType,
  EducationFormikValue,
  EducationProfileItemType,
  SeekerStateType,
  TaxonomiesStateType,
} from 'Interfaces'
import {sort} from './Sort'
import Title from 'Components/Title'
import Hr from 'Components/Hr'
import {useApiError, useTranslation, useYears} from 'Hooks'
import {DropdownProps, OnChangeProps, TypeAheadProps} from 'Utils/FormikProps'
import {FormattedMessage, useIntl} from 'react-intl'
import TownAndCountry from 'Utils/TownAndCountry'
import nl2br from 'react-nl2br'
import TypeAheadImproved from 'Components/Inputs/TypeAheadImproved'
import {
  AccountBalanceOutlined,
  FmdGoodOutlined,
  HistoryEduOutlined,
  SchoolOutlined,
  UpdateOutlined,
} from '@mui/icons-material'
import {TypeAheadFetch} from '../../Inputs/TypeAheadFetch'

interface EducationEndDateProps {
  profile: SeekerStateType
  educations: EducationProfileItemType[]
  taxonomies: TaxonomiesStateType
  getTaxonomies: () => void
  addEducation: (value: EducationFormikValue) => void
  editEducation: (value: EducationFormikValue) => void
  canChange?: boolean
  deleteEducation: (id: string) => void
}

export const EducationEndDate: FC<EducationEndDateProps> = ({
  profile,
  educations = [],
  taxonomies,
  getTaxonomies,
  addEducation,
  editEducation,
  canChange = false,
  deleteEducation,
}) => {
  const translation = useTranslation()
  const emptyValues: EducationFormikValue = {
    title: '',
    educationLevel: undefined,
    educationInstitution: undefined,
    town: undefined,
    country: undefined,
    endYear: '',
    inProgress: false,
    description: '',
  }

  const [modalOpen, setModalOpen] = useState(false)
  const [modalMode, setModalMode] = useState('Add')
  const [deleteModal, setDeleteModal] = useState<boolean | number>(false)
  const [showMoreIndex, setShowMoreIndex] = useState(1)
  const localEducations = useMemo(() => {
    return sort(educations).slice(0, showMoreIndex)
  }, [educations, showMoreIndex])

  const openEdit = (id?: string) => {
    getTaxonomies()
    setModalMode('Edit')
    const eduItem = educations.find(
      (e) => e.id === id
    ) as EducationProfileItemType
    formik.resetForm({
      values: {
        id: eduItem.id,
        title: eduItem.title || '',
        educationLevel: eduItem.educationLevel,
        educationInstitution: {...eduItem.educationInstitution},
        town: eduItem.town,
        country: eduItem.country,
        endYear: ('endYear' in eduItem && eduItem.endYear?.toString()) || '',
        inProgress: eduItem.inProgress || false,
        description: eduItem.description || '',
      },
    })
    setModalOpen(true)
  }

  const openAdd = () => {
    setModalMode('Add')
    formik.resetForm({
      values: emptyValues,
    })
    getTaxonomies()
    setModalOpen(true)
  }

  const formik = useFormik({
    initialValues: emptyValues,
    validationSchema: Validation,
    onSubmit: (values) => {
      if (modalMode === 'Edit') {
        editEducation(values)
      } else addEducation(values)
    },
  })

  const endYears = useYears(4)

  const setTypeAheadField = (name: string, values: BaseTaxonomyType[]) => {
    formik.setFieldValue(name, values[0])
  }

  const setDropdownField = (name: string, value?: string | number) => {
    const educationLevel = taxonomies.educationLevels.data.find((c) => {
      return typeof value === 'string' && c.id === value
    })
    formik.setFieldValue(name, educationLevel)
  }

  useApiError(formik.setFieldError, profile.error)
  useEffect(() => {
    if (!profile.loading && !profile.error) {
      setModalOpen(false)
      setDeleteModal(false)
    }
  }, [profile.loading])

  const dateText = (eduItem: EducationProfileItemType) => {
    let text = ''
    if ('endYear' in eduItem && eduItem.endYear) {
      let endDate = `${eduItem.endYear}`

      if (eduItem.inProgress) {
        endDate = translation[pt.endDateInProgress] + endDate + ')'
      }
      text += endDate
    }
    return text !== '' ? (
      <ItemTextWithIcon
        primaryText={pt.yearOfCompletion}
        secondaryText={text}
        icon={UpdateOutlined}
        withColon
      />
    ) : null
  }

  const memoizedEduInstitutionTypeahead = useMemo(() => {
    if (!taxonomies.educationInstitutions.data.length) return null
    return (
      <TypeAheadImproved
        name="educationInstitution"
        theme="modal"
        items={taxonomies.educationInstitutions.data}
        defaultValue={
          formik.values.educationInstitution
            ? [formik.values.educationInstitution]
            : undefined
        }
        setValue={setTypeAheadField}
        setTouched={formik.getFieldHelpers('educationInstitution').setTouched}
        placeholder={pt.placeholderEducationalInstitution}
        maxNumSelections={1}
        selectNonExisting
        error={
          (formik.submitCount && formik.errors?.educationInstitution) ||
          undefined
        }
      />
    )
  }, [
    formik.values.educationInstitution,
    taxonomies.educationInstitutions.data,
    formik.errors?.educationInstitution,
    formik.submitCount,
  ])

  const {formatMessage} = useIntl()

  return (
    <div className={styles.section}>
      <div>
        <SectionHeader
          onAdd={canChange ? openAdd : undefined}
          title={ot.titleEducation}
        />
      </div>
      {localEducations.map((eduItem, index) => {
        return (
          <SectionItem key={eduItem.id}>
            <ItemHeader
              title={eduItem.title}
              icon={SchoolOutlined}
              onEdit={
                canChange
                  ? () => {
                      openEdit(eduItem.id)
                    }
                  : undefined
              }
              onDelete={
                canChange
                  ? () => {
                      setDeleteModal(index)
                    }
                  : undefined
              }
            />
            <div className={styles.content}>
              {dateText(eduItem)}
              {eduItem.educationInstitution?.translation && (
                <ItemTextWithIcon
                  primaryText={pt.educationalInstitution}
                  secondaryText={eduItem.educationInstitution?.translation}
                  icon={AccountBalanceOutlined}
                  withColon
                />
              )}
              {eduItem.educationLevel?.translation && (
                <ItemTextWithIcon
                  primaryText={pt.educationLevel}
                  secondaryText={eduItem.educationLevel?.translation}
                  icon={HistoryEduOutlined}
                  withColon
                />
              )}
              {eduItem.town?.translation && (
                <ItemTextWithIcon
                  primaryText={pt.location}
                  secondaryText={TownAndCountry(eduItem)}
                  icon={FmdGoodOutlined}
                  withColon
                />
              )}
              {eduItem.description && (
                <p className={styles.text}>{nl2br(eduItem.description)}</p>
              )}
            </div>
          </SectionItem>
        )
      })}
      <ShowMore
        items={educations}
        showMoreIndex={showMoreIndex}
        setShowMoreIndex={setShowMoreIndex}
      />
      <Modal
        isOpen={modalOpen}
        onAfterClose={() => {
          setModalOpen(false)
        }}
        title={
          modalMode === 'Add' ? ot.titleAddEducation : ot.titleEditEducation
        }
        icon={SchoolOutlined}
        ariaHideApp={false}
        className={styles.modal}
        theme="desktopFlex"
        Footer={
          <SubmitFooter
            onSubmit={formik.handleSubmit}
            onCancel={() => {
              setModalOpen(false)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <Title text={pt.educationTitle} theme="modal" />
        <TextField
          name="title"
          placeholder={pt.placeholderEducationTitle}
          {...OnChangeProps(formik, 'title')}
          theme="modal"
        />
        <Hr theme="modal" />
        <Title text={pt.educationLevel} theme="modal" />
        <Dropdown
          name="educationLevel"
          items={taxonomies.educationLevels.data}
          theme="white"
          {...TypeAheadProps(formik, 'educationLevel')}
          setValue={setDropdownField}
          error={
            (formik.submitCount && formik.errors?.educationLevel) || undefined
          }
        />
        <Hr theme="modal" />
        <Title text={pt.educationalInstitution} theme="modal" />
        {memoizedEduInstitutionTypeahead}
        <Hr theme="modal" />
        <Title text="City" theme="modal" />
        <TypeAheadFetch
          name="town"
          value={formik.values.town || null}
          options={[]}
          optionsQueryUrl={`/small-index/towns?${
            formik.values.country
              ? `countryId=${formik.values.country.id}&`
              : ''
          }name=`}
          setValue={formik.setFieldValue}
          label={cd.city}
          placeholder={pt.placeholderTown}
          error={
            formik.submitCount && formik.errors?.town
              ? formik.errors.town
              : undefined
          }
          smallIndex
          autoComplete
          themes={['white', 'withMargin']}
        />
        <Hr theme="modal" />
        <Title text="Country" theme="modal" />
        <TypeAheadImproved
          name="country"
          theme="modalSimpleWhite"
          items={taxonomies.countries.data}
          defaultValue={
            formik.values.country ? [formik.values.country] : undefined
          }
          setValue={(name, value) => {
            setTypeAheadField(name, value)
            if (formik.values.country?.id !== value[0]?.id) {
              formik.setFieldValue('town', null)
            }
          }}
          label={cd.country}
          setTouched={formik.getFieldHelpers('country').setTouched}
          placeholder={cd.selectYourCountry}
          maxNumSelections={1}
          error={(formik.submitCount && formik.errors?.country) || undefined}
        />
        <Hr theme="modal" />
        <div>
          <Title text={pt.yearOfCompletion} theme="modal" />
          <div className={styles.dates}>
            <Dropdown
              name="endYear"
              items={endYears}
              label={dt.yearOfCompletion}
              theme="white"
              className={styles.date}
              {...DropdownProps(formik, 'endYear')}
            />
          </div>
          <Checkbox
            name="inProgress"
            value={!!formik.values.inProgress}
            onChange={formik.handleChange}
            setTouched={formik.getFieldHelpers('inProgress').setTouched}
            showTextError
            error={
              formik.submitCount && formik.touched['inProgress']
                ? formik.errors.inProgress
                : undefined
            }
            theme="modal"
          >
            <FormattedMessage id={pt.educationInProgress} />
          </Checkbox>
        </div>
        <Hr theme="modal" />
        <Title text={pt.educationDescription} theme="modal" />
        <TextArea
          name="description"
          value={`${formik.values.description}`}
          onChange={formik.handleChange}
          placeholderText={pt.placeholderEducationDescription}
          error={
            formik.touched.description || formik.values.description !== ''
              ? formik.errors.description
              : undefined
          }
          theme="modal"
          maximumCharactersNumber={1000}
          type="letterCalculator"
        />
      </Modal>
      <DeleteModal
        deleteAction={deleteEducation}
        isOpen={!!deleteModal || deleteModal === 0}
        setOpen={setDeleteModal}
        text={`${formatMessage({id: pt.deletePartOne})}${
          localEducations?.find((_, i) => i === deleteModal)?.title || ''
        }${formatMessage({id: pt.deletePartTwo})}`}
        id={localEducations?.find((_, i) => i === deleteModal)?.id || ''}
      />
    </div>
  )
}
