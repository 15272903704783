export enum CompanyHeaderTranslations {
  logotypeModalTitle = 'companyHeader.logotypeModalTitle',
  logotypeTitle = 'companyHeader.logotypeTitle',

  coverImageModalTitle = 'companyHeader.coverImageModalTitle',
  coverImageTitle = 'companyHeader.coverImageTitle',

  headerModalTitle = 'companyHeader.headerModalTitle',
  headerCompanyNameTitle = 'companyHeader.headerCompanyNameTitle',
  headerCompanyNamePlaceholderText = 'companyHeader.headerCompanyNamePlaceholderText',
  headerDescriptionTitle = 'companyHeader.headerDescriptionTitle',
  headerDescriptionPlaceholderText = 'companyHeader.headerDescriptionPlaceholderText',
  headerJobCategoryTitle = 'companyHeader.headerJobCategoryTitle',
  headerCountryTitle = 'companyHeader.headerCountryTitle',
  headerWebsiteTitle = 'companyHeader.headerWebsiteTitle',
  headerWebsitePlaceholderText = 'companyHeader.headerWebsitePlaceholderText',

  townInputPlaceholder = 'companyHeader.townInputPlaceholder',
  countryLabel = 'companyHeader.countryLabel',
  cityLabel = 'companyHeader.cityLabel',
}
