import {FC} from 'react'
import classnames from 'classnames'
import {FormattedMessage} from 'react-intl'

import styles from './TextError.module.scss'

export interface TextErrorType {
  text: string
  className?: string
}

export const TextError: FC<TextErrorType> = ({
  text,
  className,
}: TextErrorType) => {
  return (
    <div className={classnames(styles.error, className)}>
      <FormattedMessage id={text} />
    </div>
  )
}
