import {MouseEventHandler, ReactNode} from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import classnames from 'classnames'

import styles from './SidebarLink.module.scss'
import {getRoute, useActiveRoute} from 'Services/I18n/Utils'
import {useTranslation} from '../../Hooks'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'
import {NavHashLink} from 'react-router-hash-link'

interface iSidebarLinkProps {
  routeName?: string
  onClick?: MouseEventHandler
  text: string
  icon?: ReactNode
  hash?: boolean
  route?: string
  withMarginBottom?: boolean
}

export const SidebarLink = ({
  routeName,
  text,
  onClick,
  icon,
  hash,
  route,
  withMarginBottom = true,
}: iSidebarLinkProps) => {
  const translation = useTranslation()
  const {isActive} = useActiveRoute(route ? route : getRoute(routeName || '#'))

  return (
    <>
      {(routeName || route) &&
        (!hash ? (
          <Link
            to={route ? route : getRoute(routeName || '#')}
            className={classnames(styles.sidebarLink, {
              [styles.active]: isActive,
              [styles.withMarginBottom]: withMarginBottom,
            })}
          >
            <div className={styles.contentWrapper}>
              <div className={styles.iconTextWrapper}>
                {icon}
                <p>
                  <FormattedMessage id={text} />
                </p>
              </div>
              <ArrowForwardIcon className={styles.arrowIcon} />
            </div>
          </Link>
        ) : (
          <NavHashLink
            to={route ? route : ''}
            className={classnames(styles.sidebarLink, {
              [styles.active]: isActive,
              [styles.withMarginBottom]: withMarginBottom,
            })}
            smooth
          >
            <div className={styles.contentWrapper}>
              <div className={styles.iconTextWrapper}>
                {icon}
                <p>
                  <FormattedMessage id={text} />
                </p>
              </div>
              <ArrowForwardIcon className={styles.arrowIcon} />
            </div>
          </NavHashLink>
        ))}

      {onClick && (
        <button
          type={'button'}
          onClick={onClick}
          className={classnames(styles.sidebarLink, {
            [styles.withMarginBottom]: withMarginBottom,
          })}
        >
          <div className={styles.contentWrapper}>
            <div className={styles.iconTextWrapper}>
              {icon}
              <p>
                <FormattedMessage id={text} />
              </p>
            </div>
            <ArrowForwardIcon className={styles.arrowIcon} />
          </div>
        </button>
      )}
      {!routeName && !route && !onClick && (
        <div className={styles.sidebarLink}>
          <div className={styles.linkWrap}>
            <span className={styles.linkText}>{translation[text] || text}</span>
            <ArrowForwardIcon className={styles.arrowIcon} />
          </div>
        </div>
      )}
    </>
  )
}
