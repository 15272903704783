import {FC, useState, ReactNode, useRef} from 'react'
import styles from './ContactRequestsListItem.module.scss'
import {IncomingRequestType} from 'Interfaces'
import classNames from 'classnames'
import RequestActions from './RequestActions'
import {
  CombinedRequestStatues,
  employerRequestStatusTranslationMap,
  RequestType,
} from 'Components/IncomingRequests/Constants'
import {SeekerProfile} from './SeekerProfile'
import {FormattedMessage} from 'react-intl'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'
import {ExpirationValue} from './RequestValues'
import ModalContactRequestsDetails from 'Components/EmployerDashboard/ContactRequestListItem/ModalContactRequestEmp/index'

const ItemDetail: FC<{
  label?: string
  value: ReactNode
  className?: string
}> = ({label, value, className}) => {
  return (
    <div className={`${styles.detailWrap} ${className}`}>
      {label && label !== edt.candidate && (
        <div className={styles.label}>
          <FormattedMessage id={label} />
        </div>
      )}
      {value}
    </div>
  )
}

// TODO candidate-dashboard: centralize request colors?
const statusColors = {
  [CombinedRequestStatues.PENDING]: {color: styles.purpleText},
  [CombinedRequestStatues.APPROVED]: {color: styles.greenText},
  [CombinedRequestStatues.REJECTED]: {color: styles.redText},
  [CombinedRequestStatues.EXPIRED]: {color: styles.blackText},

  [CombinedRequestStatues.CANCELLED]: {color: styles.redText},
  [CombinedRequestStatues.EXTENDED]: {color: styles.greenText},
}

export type ContactRequestsDisplayType = 'all' | 'pending' | 'approved'
type ContactRequestsListItemProps = {
  request: IncomingRequestType
  displayType: ContactRequestsDisplayType
  setSidebarClosed: (sidebarClosed: boolean) => void
  setSelectedRequestWidget: (request: IncomingRequestType) => void
  setIsModalOpen: (v: boolean) => void
}

export const ContactRequestsListItem: FC<ContactRequestsListItemProps> = ({
  request,
  displayType = 'all',
  setSidebarClosed,
  setSelectedRequestWidget,
  setIsModalOpen,
}) => {
  const [isContactRequestDetailsOpen, setIsContactRequestDetailsOpen] =
    useState(false)
  const [isCandidateSelected, setIsCandidateSelected] = useState(false)
  const itemRef = useRef<HTMLDivElement>(null)
  return (
    <div
      ref={itemRef}
      className={classNames({
        [styles.selectedCandidateRow]: isCandidateSelected,
      })}
      onClick={() => {
        setSidebarClosed(false)
        setSelectedRequestWidget({
          ...request,
          type: RequestType.CONTACT_REQUEST,
        })
        setIsCandidateSelected(true)
      }}
      tabIndex={0}
      onBlur={() => {
        setIsCandidateSelected(false)
      }}
    >
      <div
        className={classNames(styles.requestItemWrap, 'requestItemWrap', {
          [styles.all]: displayType === 'all',
          [styles.candidateRow]: !isCandidateSelected,
        })}
      >
        {/* CANDIDATE */}
        <ItemDetail
          label={edt.candidate}
          value={
            <SeekerProfile request={request} setIsModalOpen={setIsModalOpen} />
          }
          className={styles.flexContainer}
        />

        {/* REQUEST TYPE */}
        <ItemDetail
          label={edt.contactRequestRequestType}
          value={
            <div
              onClick={(e) => {
                e.stopPropagation()
                setIsContactRequestDetailsOpen(true)
              }}
              className={styles.detailsValue}
            >
              <FormattedMessage id={edt.contactRequest} />
            </div>
          }
        />

        {/* STATUS */}
        {displayType !== 'pending' && (
          <ItemDetail
            label={edt.status}
            value={
              <div
                className={classNames(
                  styles.statusValue,
                  statusColors[request.status] &&
                    statusColors[request.status].color
                )}
              >
                <FormattedMessage
                  id={employerRequestStatusTranslationMap[request.status]}
                />
              </div>
            }
          />
        )}

        {/* EXPIRE */}
        {displayType !== 'approved' &&
          (request.expirationDate ? (
            <ItemDetail
              label={edt.expire}
              value={
                request.status !== 'approved' &&
                request.status !== 'rejected' && (
                  <ExpirationValue date={request.expirationDate} />
                )
              }
            />
          ) : (
            <div />
          ))}

        {/* ACTION */}
        <ItemDetail
          className={styles.justifyEnd}
          value={
            <RequestActions
              request={{...request, type: RequestType.CONTACT_REQUEST}}
            />
          }
        />
        <ModalContactRequestsDetails
          request={request}
          isOpenContactRequest={isContactRequestDetailsOpen}
          setIsOpenContactRequest={(modalOpen) => {
            if (!modalOpen && itemRef.current) {
              itemRef.current.focus()
            }
            setIsContactRequestDetailsOpen(modalOpen)
          }}
        />
      </div>
    </div>
  )
}
