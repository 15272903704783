import {FC, useEffect, useState} from 'react'
import {useFormik} from 'formik'
import classnames from 'classnames'
import {Grid} from '@mui/material'
import {
  CakeOutlined,
  LocationOnOutlined,
  MailOutlineTwoTone,
  PhoneIphone,
  LockOutlined,
  EditOutlined,
  Warning,
  ModeEditOutlineOutlined,
} from '@mui/icons-material'

import styles from './AboutMe.module.scss'
import {AvatarContainer} from 'Components/Avatar'
import {
  SeekerProfileType,
  SeekerStateType,
  TaxonomiesStateType,
  UserUpdateBioType,
  ImageType,
} from 'Interfaces'
import Validation from './Validation'
import Modal from 'Components/Modal'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import Title from 'Components/Title'
import Dropdown from 'Components/Inputs/Dropdown'
import Hr from 'Components/Hr'
import TextArea from 'Components/Inputs/TextArea'
import {ProfileTranslations as pt} from 'Services/I18n/Constants/Seeker/ProfileTranslations'
import {FormattedDate, FormattedMessage} from 'react-intl'
import nl2br from 'react-nl2br'
import {useApiError, useTranslation} from 'Hooks'
import ShowMoreContent from 'Components/ShowMoreContent'
import {formatedPhoneNumber} from 'Utils/FormatedPhoneNumber'

interface iAboutMeProps {
  user?: SeekerProfileType
  profile: SeekerStateType
  updateBioData: (value: UserUpdateBioType) => void
  canChange?: boolean
  taxonomies: TaxonomiesStateType
  getJobStatuses: () => void
  imageOrInitials?: ImageType | string
  userType?: string
  isEmployer: boolean
}

export const AboutMe: FC<iAboutMeProps> = ({
  user,
  profile,
  updateBioData,
  canChange = false,
  taxonomies,
  getJobStatuses,
  imageOrInitials,
  isEmployer,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const defaultValues: UserUpdateBioType = {
    jobSearchStatus: user?.jobSearchStatus,
    aboutMe: user?.aboutMe || '',
  }
  const translation = useTranslation()
  const openEdit = () => {
    getJobStatuses()
    formik.resetForm({
      values: {
        ...defaultValues,
      },
    })
    setModalOpen(true)
  }

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: Validation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      updateBioData(values)
    },
  })

  useApiError(formik.setFieldError, profile.error)

  useEffect(() => {
    if (!profile.loading && !profile.error) setModalOpen(false)
  }, [profile.loading])

  const setDropdownField = (name: string, value?: string | number) => {
    const jobSearchStatus = taxonomies.jobSearchStatuses.data.find((c) => {
      return typeof value === 'string' && c.id === value
    })
    formik.setFieldValue(name, jobSearchStatus)
  }
  return (
    <div>
      <div className={styles.top}>
        <div className={styles.bg}></div>
        {canChange && (
          <button className={styles.editBtn} onClick={openEdit} type="button">
            <EditOutlined className={styles.editIcon} />
          </button>
        )}
      </div>
      <div className={styles.main}>
        <div className={styles.left}>
          <div className={styles.avatarHolder}>
            <AvatarContainer
              className={styles.profileAvatar}
              imageOrInitials={imageOrInitials}
            />
          </div>
          <h1 className={styles.title}>
            {user?.firstName} {user?.lastName}{' '}
            <LockOutlined color="disabled" sx={{fontSize: 10}} />
          </h1>
          <div className={styles.leftContainer}>
            <div className={styles.textContainer}>
              <ShowMoreContent
                horizontalLine={false}
                height={70}
                mobileHeight={70}
                className={styles.showMoreContent}
                noMargin={true}
              >
                <span
                  className={classnames({[styles.greyText]: !user?.aboutMe})}
                >
                  {user?.aboutMe
                    ? nl2br(user?.aboutMe)
                    : !isEmployer
                    ? translation[pt.enterShortDescription]
                    : null}
                </span>
              </ShowMoreContent>
            </div>
            {user?.dateOfBirth && (
              <div className={styles.birthday}>
                <Grid container direction="row" alignItems="flex-end">
                  <CakeOutlined
                    color="disabled"
                    className={styles.birthdayIcon}
                  />
                  <p className={styles.birthdayText}>
                    {user?.dateOfBirth ? (
                      <FormattedDate
                        value={user.dateOfBirth}
                        year="numeric"
                        month="long"
                        day="numeric"
                      />
                    ) : null}
                  </p>
                  <div className={styles.birthdayLockIcon}>
                    <LockOutlined color="disabled" sx={{fontSize: 10}} />
                  </div>
                </Grid>
              </div>
            )}
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.street}>
            <div className={styles.locationWrap}>
              <LocationOnOutlined color="disabled" />
              {user?.streetAddress && (
                <span className={styles.streetText}>
                  {user?.streetAddress}, {user?.postalCode}{' '}
                  {user?.homeTown?.translation},{' '}
                  {user?.homeCountry?.translation}
                  <span className={styles.streetIcon}>
                    <LockOutlined color="disabled" sx={{fontSize: 10}} />
                  </span>
                </span>
              )}
            </div>
          </div>

          <div className={styles.mail}>
            <Grid container direction="row" alignItems="center">
              <MailOutlineTwoTone color="disabled" />
              <p className={styles.mailText}>{user?.email}</p>
              <div className={styles.icon}>
                <LockOutlined color="disabled" sx={{fontSize: 10}} />
              </div>
            </Grid>
          </div>
          {user?.phone && (
            <div className={styles.phone}>
              <Grid container direction="row" alignItems="center">
                <PhoneIphone color="disabled" />
                <p className={styles.phoneNumber}>
                  {formatedPhoneNumber(user?.phone)}
                </p>
                <div className={styles.icon}>
                  <LockOutlined color="disabled" sx={{fontSize: 10}} />
                </div>
              </Grid>
            </div>
          )}
        </div>
        <Modal
          isOpen={modalOpen}
          onAfterClose={() => {
            setModalOpen(false)
          }}
          title={pt.editAboutMe}
          icon={ModeEditOutlineOutlined}
          ariaHideApp={false}
          className={styles.modal}
          theme="desktopFlex"
          Footer={
            <SubmitFooter
              onSubmit={formik.handleSubmit}
              onCancel={() => {
                setModalOpen(false)
              }}
              theme="modalProfileSubmit"
            />
          }
        >
          <Title text={pt.jobSearchStatus} theme="modal" />
          <Dropdown
            name="jobSearchStatus"
            items={taxonomies.jobSearchStatuses.data}
            setValue={setDropdownField}
            value={formik.values.jobSearchStatus?.id}
            error={formik.errors.jobSearchStatus}
            theme="white"
          />
          <Hr className={styles.hr} theme="modalBig" />
          <Title text={pt.shortDescription} theme="modal" />
          <TextArea
            name="aboutMe"
            value={`${formik.values.aboutMe}`}
            onChange={formik.handleChange}
            placeholderText={pt.placeholderAboutMe}
            theme="modal"
            className={classnames(
              styles.editAboutMeTxt,
              styles.editAboutMeTxtArea
            )}
            error={
              formik.touched.aboutMe || formik.values.aboutMe !== ''
                ? formik.errors.aboutMe
                : undefined
            }
            maximumCharactersNumber={500}
            type="letterCalculator"
          />
          <div className={styles.modalLegend}>
            <Warning />
            <span>
              <FormattedMessage id={pt.managedDataText} />
            </span>
          </div>
        </Modal>
      </div>
    </div>
  )
}
