import {FC} from 'react'
import styles from './Buttons.module.scss'
import {Button} from 'Components/Button'
import YellowButton from 'Components/YellowButton'
import {GeneralTranslations as gt} from 'Services/I18n/Constants'

interface ButtonsProps {
  onBack?: () => void
  onNext?: () => void
  disabledNext?: boolean
}

export const Buttons: FC<ButtonsProps> = ({
  onBack,
  onNext,
  disabledNext = false,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.next}>
        <YellowButton text={gt.next} onClick={onNext} disabled={disabledNext} />
      </div>
      {onBack && (
        <div className={styles.back}>
          <Button text={gt.back} theme="back" onClick={onBack} type="button" />
        </div>
      )}
    </div>
  )
}
