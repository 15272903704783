import {all, call, put, takeLatest} from 'redux-saga/effects'
import API from 'Services/API'
import * as type from 'Store/types'
import {ActionType} from 'Interfaces'

function* getBlacklist() {
  const {response, error} = yield call(API.get, '/user/seeker-blacklist')

  if (response) {
    yield put({
      type: type.blacklist.get.succeeded,
      payload: response.data.items,
    })
  } else {
    yield put({type: type.blacklist.get.failed, payload: error})
  }
}

function* getBlacklistDropdownItems() {
  const {response, error} = yield call(API.get, '/companies/basic-all')
  if (response) {
    yield put({
      type: type.blacklist.getDropdownItems.succeeded,
      payload: response.data.items,
    })
  } else {
    yield put({type: type.blacklist.getDropdownItems.failed, payload: error})
  }
}

function* createBlacklist(action: ActionType<string>) {
  const {response, error} = yield call(
    API.post,
    `/user/seeker-blacklist/${action.payload}`,
    null
  )

  if (response) {
    yield put({
      type: type.blacklist.create.succeeded,
      payload: response.data,
    })
  } else {
    yield put({type: type.blacklist.create.failed, payload: error})
  }
}

function* deleteBlacklist(action: ActionType<string>) {
  const {response, error} = yield call(
    API.delete,
    `/user/seeker-blacklist/${action.payload}`
  )

  if (response) {
    yield put({
      type: type.blacklist.delete.succeeded,
      payload: action.payload,
    })
  } else {
    yield put({type: type.blacklist.delete.failed, payload: error})
  }
}

export default function* BlacklistSaga(): Generator {
  yield all([
    takeLatest(type.blacklist.get.requested, getBlacklist),
    takeLatest(
      type.blacklist.getDropdownItems.requested,
      getBlacklistDropdownItems
    ),
    takeLatest(type.blacklist.create.requested, createBlacklist),
    takeLatest(type.blacklist.delete.requested, deleteBlacklist),
  ])
}
