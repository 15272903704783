import {useEffect, useState} from 'react'
import {isInLessThan10min, timeUntil} from '../DateEmployer'

export const useTimeUntil = (date: Date) => {
  const [timeUntilString, setTimeUntilString] = useState(timeUntil(date))

  const [isSoon, setIsSoon] = useState(isInLessThan10min(date))

  useEffect(() => {
    const id = setInterval(() => {
      setIsSoon(isInLessThan10min(date))
      setTimeUntilString(timeUntil(date))
    }, 10000)

    return () => clearInterval(id)
  }, [])

  return [isSoon, timeUntilString]
}
