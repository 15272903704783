import {LongDescription} from 'Validations'
import * as Yup from 'yup'

const Validation = Yup.object().shape({
  endYear: Yup.number().nullable().required('End year is required'),
  educationInstitution: Yup.object()
    .nullable()
    .required('Education institution is required'),
  trainingTitle: Yup.object().nullable().required('Training title is required'),
  description: LongDescription,
})

// Old approach, not used at the moment
// const Validation = Yup.object().shape(
//   {
//     startMonth: Yup.number().nullable(),
//     startYear: Yup.number()
//       .nullable()
//       .when('startMonth', (started, yup) => {
//         return started ? yup.required('Start year is required') : yup
//       }),
//     endMonth: Yup.number().nullable().test(DateTest.endMonth),
//     endYear: Yup.number()
//       .nullable()
//       .when('startYear', (started, yup) =>
//         yup.min(started, 'End year cannot be before start year')
//       )
//       .when('endMonth', (started, yup) => {
//         return started ? yup.required('End year is required') : yup
//       }),
//     educationInstitution: Yup.object()
//       .nullable()
//       .required('Education institution is required'),
//     trainingTitle: Yup.object()
//       .nullable()
//       .required('Training title is required'),
//     description: LongDescription,
//   },
//   [['endMonth', 'endYear']]
// )

export default Validation
