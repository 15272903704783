import {FC, ReactNode, useState} from 'react'
import styles from './InterviewInvitationListItem.module.scss'
import {IncomingRequestType} from 'Interfaces'
import classNames from 'classnames'
import {SeekerProfile} from './SeekerProfile/SeekerProfile'
import {formatFullDate, formatInterviewTime} from 'Utils/Date'
import RequestActions from './RequestActions'
import {ExpirationValue, StartsInValue} from './RequestValues'
import {CombinedRequestStatues} from 'Components/IncomingRequests/Constants'
import {FormattedMessage} from 'react-intl'
import {employerRequestStatusTranslationMap, RequestType} from './Constants'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'
import {Rating} from '@mui/material'
import {InterviewItemDisplayType} from 'Pages/EmployerDashboard/EmployerInterviewInvitations/EmployerInterviewInvitations'
import {StarRate} from '@mui/icons-material'

const ItemDetail: FC<{
  label?: string
  value: ReactNode
  className?: string
}> = ({label, value, className}) => {
  return (
    <div className={`${styles.detailWrap} ${className}`}>
      {label && label !== edt.candidate && (
        <div className={styles.label}>
          <FormattedMessage id={label} />
        </div>
      )}
      {value}
    </div>
  )
}

// TODO candidate-dashboard: centralize request colors?
const statusColors = {
  [CombinedRequestStatues.AVAILABLE]: styles.greenText,
  [CombinedRequestStatues.UNAVAILABLE]: styles.redText,
  [CombinedRequestStatues.INTERVIEW_CONFIRMED]: styles.greenText,
  [CombinedRequestStatues.ACCEPTED]: styles.greenText,
  [CombinedRequestStatues.PENDING]: styles.purpleText,
  [CombinedRequestStatues.PENDING_EXPIRING]: styles.yellowText,
  [CombinedRequestStatues.NEW_TIME_PROPOSED]: styles.brownText,
  [CombinedRequestStatues.REJECTED]: styles.redText,
  [CombinedRequestStatues.EXPIRED]: styles.blackText,
  [CombinedRequestStatues.COMPLETED]: styles.greenText,
  [CombinedRequestStatues.NEW_OFFER_PROPOSED]: styles.yellowText,
  [CombinedRequestStatues.CANCELLED]: styles.redText,
  [CombinedRequestStatues.NOT_ATTENDED]: styles.redText,
  [CombinedRequestStatues.APPROVED]: styles.greenText,
  [CombinedRequestStatues.EXTENDED]: styles.greenText,

  [CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_PENDING]:
    styles.purpleText,
  [CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_REJECTED]:
    styles.orange2Text,
  [CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_CONFIRMED]:
    styles.purpleText,
  [CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_PENDING]:
    styles.purpleText,
  [CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_REJECTED]:
    styles.orange2Text,
  [CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_CONFIRMED]:
    styles.purpleText,
  [CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_PENDING]:
    styles.orange2Text,
  [CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_REJECTED]:
    styles.orange2Text,
  [CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_CONFIRMED]:
    styles.orange2Text,
}

type InterviewInvitationListItemProps = {
  request: IncomingRequestType
  displayType: InterviewItemDisplayType
  setSidebarClosed: (sidebarClosed: boolean) => void
  setSelectedRequestWidget: (request: IncomingRequestType) => void
  setIsModalOpen: (v: boolean) => void
}

export const InterviewInvitationListItem: FC<
  InterviewInvitationListItemProps
> = ({
  request,
  displayType = 'starts_in',
  setSidebarClosed,
  setSelectedRequestWidget,
  setIsModalOpen,
}) => {
  const [isCandidateSelected, setIsCandidateSelected] = useState(false)
  return (
    <div
      className={classNames({
        [styles.selectedCandidateRow]: isCandidateSelected,
      })}
      onClick={() => {
        setSidebarClosed(false)
        setSelectedRequestWidget({
          ...request,
          type: RequestType.PLACEMENT_REQUEST,
        })
        setIsCandidateSelected(true)
      }}
      tabIndex={0}
      onBlur={() => {
        setIsCandidateSelected(false)
      }}
    >
      <div
        className={classNames(styles.requestItemWrap, 'requestItemWrap', {
          [styles.candidateRow]: !isCandidateSelected,
        })}
      >
        <ItemDetail
          label={edt.candidate}
          value={
            <SeekerProfile request={request} setIsModalOpen={setIsModalOpen} />
          }
          className={styles.flexContainer}
        />
        <ItemDetail
          label={edt.status}
          value={
            <div
              className={classNames(
                styles.statusValue,
                statusColors[request.status]
              )}
            >
              <FormattedMessage
                id={employerRequestStatusTranslationMap[request.status]}
              />
            </div>
          }
        />
        {displayType === 'starts_in' &&
          (request.startDate ? (
            <ItemDetail
              label={edt.startsIn}
              value={<StartsInValue date={request.startDate} />}
            />
          ) : (
            <div />
          ))}
        {displayType === 'expire' &&
          (request.expirationDate ? (
            <ItemDetail
              label={edt.expire}
              value={<ExpirationValue date={request.expirationDate} />}
            />
          ) : (
            <div />
          ))}
        {(displayType === 'starts_in' || displayType === 'expire') &&
          (request.startDate && request.endDate ? (
            <ItemDetail
              label={edt.proposedTime}
              value={
                <div className={styles.proposedTime}>
                  <div className={styles.date}>
                    {formatFullDate(request.startDate)}
                  </div>
                  <div className={styles.time}>
                    {formatInterviewTime(request.startDate, request.endDate)}
                  </div>
                </div>
              }
            />
          ) : (
            <div />
          ))}
        {displayType === 'performed' &&
          (request.startDate && request.endDate ? (
            <>
              <ItemDetail
                label={edt.performed}
                value={
                  <div className={styles.proposedTime}>
                    <div className={styles.date}>
                      {formatFullDate(request.startDate)}
                    </div>
                    <div className={styles.time}>
                      {formatInterviewTime(request.startDate, request.endDate)}
                    </div>
                  </div>
                }
              />
              <ItemDetail
                label={edt.rating}
                value={
                  request.seeker.seekerEvaluations[0] ? (
                    <Rating
                      readOnly
                      name="read-only"
                      size="small"
                      value={request.seeker.seekerEvaluations[0].evaluation}
                      emptyIcon={
                        <StarRate
                          fontSize="small"
                          className={styles.grayStarIcon}
                        />
                      }
                    />
                  ) : (
                    <Rating name="read-only" size="small" value={0} readOnly />
                  )
                }
              />
            </>
          ) : (
            <>
              <div />
              <div />
            </>
          ))}
        <ItemDetail
          className={classNames(styles.justifyEnd)}
          value={<RequestActions request={request} />}
        />
      </div>
    </div>
  )
}
