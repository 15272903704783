export const companyDescription = {
  'companyDescription.descriptionTitle': 'OPIS PODJETJA',

  'companyDescription.basicDescriptionTitle': 'Splošen opis',
  'companyDescription.mainActivitiesTitle': 'Glavne aktivnosti',
  'companyDescription.visionAndGoalsTitle': 'Vizija in cilji',
  'companyDescription.structureAndCultureTitle':
    'Organizacijska struktura in kultura',
  'companyDescription.customCompanyDescriptionTitle': 'Dodaten opis podjetja',

  'companyDescription.companyLogoAlt': 'logotip podjetja',

  'companyDescription.biziSiIndustryTitle': 'DEJAVNOST:',
  'companyDescription.biziSiNumberOfEmployersTitle': ' ŠTEVILO ZAPOSLENIH:',
  'companyDescription.biziSiRevenues':
    'PRIHODKI (ZADNJI RAZPOLOŽLJIVI PODATEK):',

  'companyDescription.biziSiLogoAlt': 'bizi.si logo',

  'companyDescription.modalTitle': 'Opis Podjetja',
  'companyDescription.modalAboutTitle': 'O podjetju',
  'companyDescription.modalAboutPlaceholderText':
    'Vnesite informacije o podjetju...',
  'companyDescription.modalMainActivitiesTitle': 'Glavne aktivnosti',
  'companyDescription.modalMainActivitiesPlaceholderText':
    'Vnesite glavne aktivnosti...',
  'companyDescription.modalVisionAndGoalsTitle': 'Vizija in cilji',
  'companyDescription.modalVisionAndGoalsPlaceholderText':
    'Vnesite vizijo in cilje...',
  'companyDescription.modalStructureAndCultureTitle':
    'Organizacijska struktura in kultura',
  'companyDescription.modalStructureAndCulturePlaceholderText':
    'Vnesite informacije o organizacijski strukturi in kulturi...',
  'companyDescription.modalCustomCompanyDescriptionTitle':
    'Dodaten opis podjetja',
  'companyDescription.modalCustomCompanyDescriptionPlaceholderText':
    'Vnesite dodaten opis podjetja...',

  'companyDescription.modalAddText': 'Dodajte',
  'companyDescription.modalEditText': 'Urejanje',
}
