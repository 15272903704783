import {connect} from 'react-redux'

import {ChangePassword} from './ChangePassword'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {changePassword, hideChangePasswordMessage} from 'Store/Actions'
import {ChangePasswordType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  changePassword: (values: ChangePasswordType) =>
    dispatch(changePassword(values)),
  hideChangePasswordMessage: () => dispatch(hideChangePasswordMessage()),
})

export const ChangePasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePassword)
