import {FC} from 'react'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'

import {TextProps} from 'Utils/FormikProps'
import classnames from 'classnames'
import {LoginType, AuthStateType} from 'Interfaces'
import TextField from 'Components/Inputs/TextField'
import styles from './SeekerLogin.module.scss'
import {Validation} from './Validation'
import LoginLayout from 'Layouts/LoginLayout'
import {Title, Description} from 'Components/Login'
import {getRoute} from 'Services/I18n/Utils'
import {
  AppRoute,
  LoginTranslations as lt,
  GeneralTranslations as gt,
  ForgotPasswordTranslations as ft,
} from 'Services/I18n/Constants'
import YellowButton from 'Components/YellowButton'
import {useApiError, useTranslation} from 'Hooks'
import {FormattedMessage} from 'react-intl'
import PasswordInput from 'Components/Inputs/PasswordInput'

interface SeekerLoginProps {
  auth: AuthStateType
  login: (data: LoginType) => void
}

export const SeekerLogin: FC<SeekerLoginProps> = ({login, auth}) => {
  const translation = useTranslation()
  const formik = useFormik({
    initialValues: {
      identifier: '',
      password: '',
    },
    validationSchema: Validation,
    onSubmit: (values) => {
      login({...values})
    },
  })

  useApiError(formik.setFieldError, auth.error, 'password')

  const registrationLink = getRoute(AppRoute.Registration)
  const forgotPasswordLink = getRoute(AppRoute.ForgotPassword)

  return (
    <LoginLayout>
      <div className={styles.formWrap}>
        <Title text={lt.title} />
        <p className={styles.description}>
          <Description text={lt.description} />
          <Link
            to={registrationLink}
            className={styles.link}
            style={{marginLeft: 5}}
          >
            <FormattedMessage id={lt.createAccount} />
          </Link>
        </p>
        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <div className={styles.inputWrap}>
            <TextField
              name="identifier"
              type="text"
              label={gt.emailAddress}
              theme="minWidth"
              {...TextProps(formik, 'identifier')}
            />
            <PasswordInput
              name="password"
              label={translation[gt.password]}
              onChange={(e) =>
                formik.setFieldValue('password', e.currentTarget.value)
              }
              value={formik.values.password}
              error={
                formik.submitCount && formik.touched['password']
                  ? formik.errors.password
                  : undefined
              }
              className={styles.passwordField}
            />
            <YellowButton className={styles.loginButton} text={lt.login} />
          </div>
          <Link
            to={forgotPasswordLink}
            className={classnames(styles.link, styles.forgotPassword)}
          >
            <FormattedMessage id={ft.title} />
          </Link>
        </form>
      </div>
    </LoginLayout>
  )
}
