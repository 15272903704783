export const myAccount = {
  'myAccount.myAccount': 'My Account',
  'myAccount.personalData': 'Personal Data',
  'myAccount.uploadPhoto': 'Upload photo',
  'myAccount.day': 'Day',
  'myAccount.month': 'Month',
  'myAccount.year': 'Year',
  'myAccount.birthdate': 'Date of birth',
  'myAccount.streetAddress': 'Street Address',
  'myAccount.postalCode': 'Postal code',
  'myAccount.region': 'Region',
  'myAccount.contactData': 'Contact data',
}
