export enum UnsubscribeReason {
  title = 'unsubscribeReason.title',
  description = 'unsubscribeReason.description',
  reasonSuccess = 'unsubscribeReason.reasonSuccess',
  warning = 'unsubscribeReason.warning',
  checkBoxLabel1 = 'unsubscribeReason.checkBoxLabel1',
  checkBoxLabel2 = 'unsubscribeReason.checkBoxLabel2',
  checkBoxLabel3 = 'unsubscribeReason.checkBoxLabel3',
  checkBoxLabel4 = 'unsubscribeReason.checkBoxLabel4',
  button = 'unsubscribeReason.button',
}
