import {FC, useEffect, ReactNode, useState} from 'react'
import {useMediaQuery} from 'react-responsive'

import HeaderMessagesSection from './HeaderMessagesSection'
import MessageMessagesSection from './MessageMessagesSection'
import MessageTextField from 'Components/Inputs/MessageTextField'
import {useFormik} from 'formik'
import {TextProps} from 'Utils/FormikProps'
import {
  CreateMessageType,
  ConversationType,
  CreateConversationType,
  ItemType,
  AuthStateType,
  OpenedConversationType,
  LoadMoreMessagesType,
} from 'Interfaces'
import NewConversationInput from 'Components/MessageCenter/NewConversationInput'
import {MessageCenterTranslations as mct} from 'Services/I18n/Constants/MessageCenter'
import {FormattedMessage} from 'react-intl'
import {getRecipientType} from 'Utils/GetRecipientType'
import {getAge} from 'Utils/Date'
import classNames from 'classnames'
import ConditionalWrapper from 'Components/ConditionalWrapper'
import Modal from 'Components/Modal'
import {MessagesSectionMobileFooter} from './MessagesSectionMobileFooter'
import {CircularProgress} from '@material-ui/core'
import InfiniteScroll from 'react-infinite-scroll-component'

import styles from './MessagesSection.module.scss'
import {MailOutlineOutlined} from '@mui/icons-material'

interface MessagesSectionProps {
  createMessage: (data: CreateMessageType) => void
  createConversation: (data: CreateConversationType) => void
  messageRecipients: ItemType[]
  getMessageRecipients: () => void
  conversations: ConversationType[]
  openedConversation?: OpenedConversationType
  auth: AuthStateType
  selectConversation: (conversation?: ConversationType) => void
  setShowRecipientHeader: Function
  showRecipientHeader: boolean
  modalOpen: boolean
  setModalOpen: Function
  loadMoreMessagesUrl: string
  loadMoreMessages: (data: LoadMoreMessagesType) => void
}

export const MessagesSection: FC<MessagesSectionProps> = ({
  createMessage,
  messageRecipients,
  createConversation,
  conversations,
  openedConversation,
  auth,
  selectConversation,
  setShowRecipientHeader,
  showRecipientHeader,
  modalOpen,
  setModalOpen,
  getMessageRecipients,
  loadMoreMessagesUrl,
  loadMoreMessages,
}) => {
  const [notLoggedUserFullData, setNotLoggedUserFullData] = useState<
    ItemType | undefined
  >(undefined)
  const isMobile = useMediaQuery({
    query: '(max-width: 900px)',
  })
  const [itemsLength, setItemsLength] = useState(5)

  // TODO: Handle unread/read messages
  const defaultValues = {
    body: '',
    conversationId: '',
    newConversationId: '',
  }

  useEffect(() => {
    setItemsLength(5)
  }, [openedConversation])

  const formik = useFormik({
    initialValues: defaultValues,

    onSubmit: (values) => {
      if (values.body !== '') {
        if (values.newConversationId) {
          const packedValues = {
            messageBody: values.body,
            otherParticipantsUserIds: [values.newConversationId],
          }
          createConversation(packedValues)
          formik.setFieldValue('newConversationId', '')
          formik.setFieldValue('body', '')
        } else if (values.conversationId) {
          createMessage({
            body: values.body,
            conversationId: values.conversationId,
          })
          formik.setFieldValue('body', '')
        }
      }
    },
  })

  // we need all this steps because only in messageRecipients we have full data
  const loggedUserId = auth?.data?.id
  const findConversationId =
    openedConversation && openedConversation.conversation
      ? openedConversation?.conversation?.id
      : formik?.values.newConversationId

  const notLoggedUser =
    openedConversation?.conversation?.conversationUsers.find(
      (conversationUser) => conversationUser.userId !== loggedUserId
    )

  const onLoadMore = () => {
    setItemsLength((prevState) => prevState + 5)
    loadMoreMessages({
      url: loadMoreMessagesUrl,
      conversationId: findConversationId,
    })
  }

  useEffect(() => {
    getMessageRecipients()
  }, [])

  useEffect(() => {
    if (openedConversation && openedConversation.conversation?.id) {
      formik.setFieldValue(
        'conversationId',
        openedConversation.conversation?.id
      )
      setModalOpen(true)
    }

    const notLoggedUserId = notLoggedUser?.userId

    setNotLoggedUserFullData(
      messageRecipients &&
        messageRecipients.find((recipient) => {
          return recipient.id === notLoggedUserId
        })
    )
  }, [openedConversation])

  useEffect(() => {
    setNotLoggedUserFullData(
      messageRecipients &&
        messageRecipients.find((recipient) => {
          return recipient.id === findConversationId
        })
    )
  }, [showRecipientHeader])

  return (
    <ConditionalWrapper
      condition={isMobile}
      wrapper={(children: ReactNode) => (
        <Modal
          isOpen={modalOpen}
          onAfterClose={() => {
            selectConversation(undefined)
            setModalOpen(false)
          }}
          title="Send message"
          icon={MailOutlineOutlined}
          ariaHideApp={false}
          Footer={<MessagesSectionMobileFooter />}
          className={styles.messagesSectionModal}
        >
          <div className={styles.mobileMainContainer}>{children}</div>
        </Modal>
      )}
    >
      <div
        className={classNames(styles.container, {
          [styles.hideSectionContainer]: !openedConversation,
        })}
      >
        {openedConversation?.conversation ? (
          <>
            {isMobile && (
              <h2 className={styles.sendMessageMobileTitle}>Send message to</h2>
            )}
            <HeaderMessagesSection
              id={
                openedConversation?.conversation?.id
                  ? openedConversation.conversation.id
                  : ''
              }
              firstName={notLoggedUser ? notLoggedUser.firstName : ''}
              lastName={notLoggedUser ? notLoggedUser.lastName : ''}
              // TODO: replace this with real data
              occupation={
                notLoggedUserFullData
                  ? notLoggedUserFullData[
                      getRecipientType(notLoggedUserFullData.type)
                    ]?.publicOccupation?.translation
                  : ''
              }
              age={
                notLoggedUserFullData &&
                notLoggedUserFullData[
                  getRecipientType(notLoggedUserFullData.type)
                ]?.dateOfBirth
                  ? getAge(
                      notLoggedUserFullData[
                        getRecipientType(notLoggedUserFullData.type)
                      ]?.dateOfBirth
                    )
                  : ''
              }
              city={
                notLoggedUserFullData
                  ? notLoggedUserFullData[
                      getRecipientType(notLoggedUserFullData.type)
                    ].town?.translation
                  : ''
              }
              company={
                notLoggedUserFullData
                  ? notLoggedUserFullData[
                      getRecipientType(notLoggedUserFullData.type)
                    ]?.company?.name
                  : ''
              }
              avatar={notLoggedUser ? notLoggedUser.profilePicture : null} // TODO replace this with real image
            />

            <div id="scrollableDiv" className={styles.messagesContainer}>
              <InfiniteScroll
                dataLength={itemsLength}
                next={() => onLoadMore()}
                style={{display: 'flex', flexDirection: 'column-reverse'}} //To put endMessage and loader to the top.
                inverse={true} //
                hasMore={true}
                loader={
                  <div className={styles.progressSpinner}>
                    <CircularProgress />
                  </div>
                }
                scrollableTarget="scrollableDiv"
              >
                {openedConversation.messages.map((message, idx) => {
                  return (
                    <MessageMessagesSection
                      key={`${message.id}-${idx}`}
                      message={message}
                    />
                  )
                })}
              </InfiniteScroll>
            </div>
          </>
        ) : (
          <div className={styles.newMessagesContainer}>
            {!showRecipientHeader ? (
              <>
                <p className={styles.newMessagesHeader}>
                  <FormattedMessage id={mct.newMessage} />
                </p>
                <NewConversationInput
                  setShowRecipientHeader={setShowRecipientHeader}
                  setRecipient={(id) =>
                    formik.setFieldValue('newConversationId', id)
                  }
                  items={messageRecipients}
                  conversations={conversations}
                  selectConversation={selectConversation}
                />
              </>
            ) : (
              <HeaderMessagesSection
                id={
                  conversations && conversations[0]
                    ? (conversations[0].id as string)
                    : ''
                }
                showClose={true}
                onClose={() => {
                  setShowRecipientHeader(false)
                  formik.setFieldValue('newConversationId', '')
                  if (conversations && conversations[0])
                    selectConversation(conversations[0])
                }}
                firstName={
                  notLoggedUserFullData
                    ? notLoggedUserFullData[
                        getRecipientType(notLoggedUserFullData.type)
                      ]?.firstName
                    : ''
                }
                lastName={
                  notLoggedUserFullData
                    ? notLoggedUserFullData[
                        getRecipientType(notLoggedUserFullData.type)
                      ]?.lastName
                    : ''
                }
                // TODO: replace this with real data
                occupation={
                  notLoggedUserFullData
                    ? notLoggedUserFullData[
                        getRecipientType(notLoggedUserFullData.type)
                      ]?.publicOccupation?.translation
                    : ''
                }
                age={
                  notLoggedUserFullData &&
                  notLoggedUserFullData[
                    getRecipientType(notLoggedUserFullData.type)
                  ]?.dateOfBirth
                    ? getAge(
                        notLoggedUserFullData[
                          getRecipientType(notLoggedUserFullData.type)
                        ]?.dateOfBirth
                      )
                    : ''
                }
                city={
                  notLoggedUserFullData
                    ? notLoggedUserFullData[
                        getRecipientType(notLoggedUserFullData.type)
                      ].town?.translation
                    : ''
                }
                company={
                  notLoggedUserFullData
                    ? notLoggedUserFullData[
                        getRecipientType(notLoggedUserFullData.type)
                      ]?.company?.name
                    : ''
                }
                avatar={
                  notLoggedUserFullData
                    ? notLoggedUserFullData[
                        getRecipientType(notLoggedUserFullData.type)
                      ]?.profilePicture
                    : null
                } // TODO replace this with real image
              />
            )}
          </div>
        )}
        <div className={styles.textField}>
          <MessageTextField
            placeholder={mct.writeAmessage}
            name="body"
            {...TextProps(formik, 'body')}
            onSubmit={formik.submitForm}
            disabled={
              formik.values.newConversationId === '' && !openedConversation
            }
          />
        </div>
      </div>
    </ConditionalWrapper>
  )
}
