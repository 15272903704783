import {FC} from 'react'
import classnames from 'classnames'

import {Button} from 'Components/Button'
import {useTranslation} from 'Hooks'

import styles from './Modal.module.scss'

interface AssessmentsSubmitFooterProps {
  onSubmit: () => void
  onCancel: () => void
  submitText?: string
  cancelText?: string
  disabled?: boolean
  shelbyMillsFormContent?: JSX.Element | boolean | null
  consentId?: string
  userId: string
}

export const AssessmentsSubmitFooter: FC<AssessmentsSubmitFooterProps> = ({
  onSubmit,
  onCancel,
  submitText = 'Save changes',
  cancelText = 'Cancel',
  disabled,
}: AssessmentsSubmitFooterProps) => {
  const translation = useTranslation()

  return (
    <div className={styles.submitFooter}>
      <div className={classnames(styles.buttonsContainer)}>
        <Button
          disabled={disabled}
          text={translation[submitText] || submitText}
          theme="modalSubmit"
          onClick={onSubmit}
        />
        <Button
          text={translation[cancelText] || cancelText}
          type="button"
          theme="back"
          onClick={onCancel}
        />
      </div>
    </div>
  )
}
