import {FC, useState, useEffect} from 'react'
import styles from './ItemStarsEdit.module.scss'

import {Star, StarBorderOutlined} from '@mui/icons-material'
import {DataType, BaseTaxonomyType} from 'Interfaces'
import {ProfileTranslations as pt} from 'Services/I18n/Constants'
import {useTranslate} from 'Hooks'
import classnames from 'classnames'
import {useMediaQuery} from 'react-responsive'

interface ItemStarsProps {
  level: number
  onChangeSkillLevel: (
    id: string,
    levelId: string,
    stateStarLevel: string | number,
    skillLevelTranslation: string,
    skillCategoryName: string
  ) => void
  id: string
  skillLevels: DataType<BaseTaxonomyType>
  skillCategoryName: string
}

export const ItemStarsEdit: FC<ItemStarsProps> = ({
  level = 1,
  id,
  skillLevels,
  onChangeSkillLevel,
  skillCategoryName,
}) => {
  const translate = useTranslate()
  const rateYourSkillText = translate(pt.rateYourSkill)
  const stars = []
  const [stateStarLevel, setStateStarLevel] = useState(level)
  const [levelId, setLevelId] = useState('')
  const [isStarClicked, setIsStarClicked] = useState(false)

  const isMobile = useMediaQuery({
    query: '(max-width: 991px)',
  })

  useEffect(() => {
    if (stateStarLevel === 0) return
    if (!skillLevels.loading && !skillLevels.error) {
      setLevelId(
        skillLevels.data.length
          ? skillLevels.data[stateStarLevel - 1 > 0 ? stateStarLevel - 1 : 0].id
          : ''
      )
    }
  }, [skillLevels.loading, stateStarLevel])

  useEffect(() => {
    if (stateStarLevel === 0) return
    if (
      skillLevels.data.length &&
      !skillLevels.loading &&
      !skillLevels.error &&
      isStarClicked
    ) {
      onChangeSkillLevel(
        id,
        levelId,
        stateStarLevel,
        skillLevels.data[stateStarLevel - 1 > 0 ? stateStarLevel - 1 : 0]
          ?.translation,
        skillCategoryName
      )
    }
  }, [levelId])

  for (let i = 0; i < 5; i++) {
    if (i < stateStarLevel) {
      stars[i] = (
        <button key={i}>
          <Star
            onClick={() => {
              setStateStarLevel(i + 1)
              setIsStarClicked(true)
            }}
            sx={{fontSize: 24, color: '#000000'}}
          />
        </button>
      )
    } else {
      stars[i] = (
        <button key={i}>
          <StarBorderOutlined
            onClick={() => {
              setStateStarLevel(i + 1)
              setIsStarClicked(true)
            }}
            sx={{fontSize: 24, color: '#949494'}}
          />
        </button>
      )
    }
  }
  return (
    <div className={styles.edit}>
      {stars}
      {!isMobile && (
        <p
          className={classnames(styles.editLevel, {
            [styles.darkerText]: stateStarLevel === 0,
          })}
        >
          {stateStarLevel === 0
            ? rateYourSkillText
            : skillLevels.data.length > 0
            ? skillLevels.data[
                stateStarLevel - 1 > 0 ? stateStarLevel - 1 : 0
              ]?.translation
                .split('-')[1]
                .trim()
            : null}
        </p>
      )}
    </div>
  )
}
