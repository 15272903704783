import {FC} from 'react'
import styles from './SidebarSectionTitle.module.scss'

interface SidebarSectionTitleProps {
  title: string
}

export const SidebarSectionTitle: FC<SidebarSectionTitleProps> = ({title}) => {
  return (
    <div className={styles.sidebarTitleWrapper}>
      <h3 className={styles.title}>{title}</h3>
    </div>
  )
}
