import {ChangeEvent, FC, useMemo, useState} from 'react'

import {ConversationType, ItemType} from 'Interfaces'
import NewMessageRecipient from '../NewMessageRecipient'

import styles from './NewConversationInput.module.scss'
import {ClickAwayListener} from '@mui/material'
import {getRecipientType} from 'Utils/GetRecipientType'
import {useRouteMatch} from 'react-router-dom'
import {AppRoute} from 'Services/I18n/Constants'
import {getRoute} from 'Services/I18n/Utils'
import {MessageCenterTranslations as mct} from 'Services/I18n/Constants/MessageCenter'
import {useTranslation} from 'Hooks'

interface NewConversationInputProps {
  items: ItemType[]
  setRecipient: (id: string) => void
  setShowRecipientHeader: Function
  conversations: ConversationType[]
  selectConversation: (conversation?: ConversationType) => void
}

export const NewConversationInput: FC<NewConversationInputProps> = ({
  items,
  setRecipient,
  setShowRecipientHeader,
  conversations,
  selectConversation,
}) => {
  const [textInput, setTextInput] = useState('')
  const [showDropdown, setShowDropdown] = useState(false)
  const {path} = useRouteMatch()
  const isEmployer = path === getRoute(AppRoute.CandidateMessages)
  const translation = useTranslation()

  const filteredItems = useMemo(() => {
    if (!textInput) {
      return items
    }
    return items.filter((item) => {
      const itemType = item.type
      return (
        textInput === '' ||
        (item[itemType]?.firstName &&
          item[itemType]?.firstName
            .toLowerCase()
            .includes(textInput.toLowerCase())) ||
        (item[itemType]?.lastName &&
          item[itemType]?.lastName
            .toLowerCase()
            .includes(textInput.toLowerCase()))
      )
    })
  }, [items, textInput])

  const onTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    setTextInput(value)
  }

  const onSetRecipient = (item: ItemType) => {
    setShowDropdown(false)
    // check conversation existance in store
    const newConversationExistsInData = conversations.find((conversation) => {
      return (
        conversation.conversationUsers[0].userId === item.id ||
        conversation.conversationUsers[1].userId === item.id
      )
    })

    if (!newConversationExistsInData) {
      setRecipient(item.id)
      setTextInput(
        'To: ' +
          item[getRecipientType(item.type)]?.firstName +
          ' ' +
          item[getRecipientType(item.type)]?.lastName
      )
      setShowRecipientHeader(true)
    } else {
      selectConversation(newConversationExistsInData)
    }
  }

  return (
    <div className={styles.wrap}>
      <input
        type="text"
        className={styles.input}
        value={textInput}
        placeholder={
          isEmployer
            ? translation[mct.employerPlaceholder]
            : translation[mct.candidatePlaceholder]
        }
        onChange={onTextChange}
        onClick={() => {
          setTextInput('')
          setShowDropdown(true)
        }}
      />

      {showDropdown && filteredItems && (
        <ClickAwayListener
          mouseEvent="onMouseDown"
          touchEvent="onTouchStart"
          onClickAway={() => setShowDropdown(false)}
        >
          <div className={styles.recipientItemsWrap}>
            {filteredItems.length > 0 ? (
              filteredItems.map((item) => (
                <NewMessageRecipient
                  key={item.id}
                  onClick={() => {
                    onSetRecipient(item)
                  }}
                  item={item}
                />
              ))
            ) : (
              <div className={styles.noResults}>No recipients</div>
            )}
          </div>
        </ClickAwayListener>
      )}
    </div>
  )
}
