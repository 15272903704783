import {VisibilityOffOutlined, VisibilityOutlined} from '@mui/icons-material'
import {IconButton} from '@mui/material'
import TextField from 'Components/Inputs/TextField'
import {ChangeEventHandler, FC, MouseEventHandler, useState} from 'react'
import styles from './PasswordInput.module.scss'
import classNames from 'classnames'

type PasswordInputProps = {
  name: string
  label: string
  value: string
  onChange: ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  className?: string
  error?: string
}

export const PasswordInput: FC<PasswordInputProps> = ({
  name,
  label,
  value,
  onChange,
  className,
  error,
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleMouseDownPassword: MouseEventHandler<HTMLButtonElement> = (
    event
  ) => {
    event.preventDefault()
  }

  return (
    <div className={classNames(styles.wrap, className)}>
      <TextField
        name={name}
        label={label}
        value={value}
        className={styles.passwordInput}
        type={showPassword ? 'text' : 'password'}
        onChange={onChange}
        error = {error}
      />
      {!error && <IconButton
        onClick={() => {
          setShowPassword((old) => !old)
        }}
        onMouseDown={handleMouseDownPassword}
        edge="end"
        className={styles.toogleShowBtn}
      >
        {showPassword ? (
          <VisibilityOffOutlined className={styles.showIcon} />
        ) : (
          <VisibilityOutlined className={styles.showIcon} />
        )}
      </IconButton>}
    </div>
  )
}
