import Modal, {SubmitFooter} from 'Components/Modal'
import {useFormik} from 'formik'
import {IncomingRequestType, NewInterviewTimePayloadType} from 'Interfaces'
import {FC} from 'react'
import styles from './ProposeNewTimeModal.module.scss'
import TextArea from 'Components/Inputs/TextArea'
import {OnChangeProps} from 'Utils/FormikProps'
import Validation from './validation'
import {FormattedMessage} from 'react-intl'
import {CandidateDashboardTranslations as cdt} from 'Services/I18n/Constants'
import {DateRange} from '@mui/icons-material'

type ProposeNewTimeModalProps = {
  request: IncomingRequestType
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  proposeNewTime: (payload: NewInterviewTimePayloadType) => void
}

export const ProposeNewTimeModal: FC<ProposeNewTimeModalProps> = ({
  request,
  isOpen,
  setIsOpen,
  proposeNewTime,
}) => {
  const defaultValues = {
    id: request.id,
    reason: '',
  }

  const formik = useFormik({
    validationSchema: Validation,
    initialValues: defaultValues,
    onSubmit: (value) => {
      proposeNewTime(value)
      setIsOpen(false)
    },
  })

  return (
    <Modal
      isOpen={isOpen}
      onAfterClose={() => {
        setIsOpen(false)
      }}
      title={cdt.proposeNewTime}
      icon={DateRange}
      className={styles.modal}
      Footer={
        <SubmitFooter
          onSubmit={formik.handleSubmit}
          onCancel={() => {
            setIsOpen(false)
          }}
          submitText={cdt.submit}
          theme="modalProfileSubmit"
        />
      }
    >
      <div className={styles.modalContent}>
        <p className={styles.title}>
          <FormattedMessage id={cdt.message} />
        </p>
        <p className={styles.paragraph}>
          <FormattedMessage id={cdt.modalPrposeNewTimePleaseEnterMessage} />
        </p>
        <TextArea
          name="reason"
          placeholderText={cdt.modalPrposeNewTimEnterMessage}
          {...OnChangeProps(formik, 'reason')}
          className={styles.textArea}
        />
      </div>
    </Modal>
  )
}
