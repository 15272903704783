import {DateTime} from 'luxon'
import {FC} from 'react'
import {FormattedMessage} from 'react-intl'
import styles from './RequestValues.module.scss'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'
import {ExpirationValuesInDays} from 'Components/ExpirationValuesInDays'
export const ExpirationValue: FC<{date: string}> = ({date}) => {
  const expiration = DateTime.fromISO(date)
  const now = DateTime.now()
  const inDays = Math.floor(expiration.diff(now, ['days']).days)

  if (inDays > 7 || expiration < now) {
    return (
      <div className={styles.dateTextIn}>{expiration.toFormat('d.L.yyyy')}</div>
    )
  }

  if (now.day === expiration.day) {
    return (
      <div className={styles.dateTextSoon}>
        <FormattedMessage id={edt.expiresToday} />
      </div>
    )
  }

  return <ExpirationValuesInDays inDays={inDays} />
}
