import Modal from 'Components/Modal'
import {ThumbsFooter} from 'Components/Modal/ThumbsFooter'
import styles from './ModalApprovePlacementConfirmation.module.scss'

import {IncomingRequestType, ApproveRejectModalRequestType} from 'Interfaces'
import {FC} from 'react'
import {FormattedMessage} from 'react-intl'
import {CandidateDashboardTranslations as cdt} from 'Services/I18n/Constants'
import {ForumOutlined} from '@mui/icons-material'

interface ModalApprovePlacementConfirmationProps {
  request: IncomingRequestType
  approveModal: boolean
  setApproveModal: (approveModal: boolean) => void
  showDetails?: boolean
  setIsOpenPlacementModal?: (value: boolean) => void
  approvePlacementConfirmation: (payload: ApproveRejectModalRequestType) => void
}

export const ModalApprovePlacementConfirmation: FC<
  ModalApprovePlacementConfirmationProps
> = ({
  request,
  approveModal,
  setApproveModal,
  setIsOpenPlacementModal,
  approvePlacementConfirmation,
}) => {
  return (
    <>
      <Modal
        className={styles.modal}
        icon={ForumOutlined}
        isOpen={approveModal}
        title={cdt.confirmPlacement}
        onAfterClose={() => {
          setApproveModal(false)
        }}
        Footer={
          <ThumbsFooter
            approveText={cdt.accept}
            theme={'thumbUp'}
            onApprove={() => {
              approvePlacementConfirmation({
                id: request.id,
              })
              setApproveModal(false)
              setIsOpenPlacementModal && setIsOpenPlacementModal(false)
            }}
            onCancel={() => {
              setApproveModal(false)
            }}
          />
        }
      >
        <div className={styles.containerInterview}>
          <p className={styles.text}>
            <FormattedMessage id={cdt.placementApproveAreYouSure} />{' '}
            {request.companyName}?
          </p>
          <p className={styles.paragraph}>
            <FormattedMessage id={cdt.placementApproveConfirmingThePlacement} />
          </p>
        </div>
      </Modal>
    </>
  )
}
