import {FC, ReactNode, useState} from 'react'
import {DateTime} from 'luxon'
import {FormattedMessage} from 'react-intl'
import classnames from 'classnames'
import styles from './RequestListItem.module.scss'
import RequestActionsContainer from '../RequestActions/'
import {IncomingRequestType} from 'Interfaces'
import ModalInterviewInvitation from 'Components/IncomingRequests/Modals/ModalInterviewInvitation/index'
import ModalOfferRequest from 'Components/IncomingRequests/Modals/ModalOfferRequest/index'
import ModalPlacementRequest from '../Modals/ModalPlacementRequest'
import {formatDate} from 'Utils/Date'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'
import {
  seekerRequestStatusTranslationMap,
  requestTypeTranslationMap,
  typeColors,
  RequestType,
  CombinedRequestStatues,
} from '../Constants'
import ModalContactRequest from '../Modals/ModalContactRequest'
import {
  AppRoute,
  CandidateDashboardTranslations as cdt,
} from 'Services/I18n/Constants'
import {Link} from 'react-router-dom'
import {getRoute} from 'Services/I18n/Utils'
import ModalCancelInterview from '../Modals/ModalInterviewInvitation/ModalCancelInterview'

const ItemDetail: FC<{
  label?: string
  value: ReactNode
  className?: string
}> = ({label, value, className}) => (
  <div className={`${styles.detailWrap} ${className}`}>
    {label && <div className={styles.label}>{label}</div>}
    {value}
  </div>
)

// TODO candidate-dashboard: centralize request colors?
export const statusColors = {
  [CombinedRequestStatues.AVAILABLE]: styles.greenText,
  [CombinedRequestStatues.UNAVAILABLE]: styles.redCinnabarText,
  [CombinedRequestStatues.INTERVIEW_CONFIRMED]: styles.greenText,
  [CombinedRequestStatues.ACCEPTED]: styles.greenText,
  [CombinedRequestStatues.PENDING]: styles.purpleText,
  [CombinedRequestStatues.PENDING_EXPIRING]: styles.yellowText,
  [CombinedRequestStatues.NEW_TIME_PROPOSED]: styles.brownText,
  [CombinedRequestStatues.REJECTED]: styles.redCinnabarText,
  [CombinedRequestStatues.EXPIRED]: styles.blackText,
  [CombinedRequestStatues.COMPLETED]: styles.greenText,
  [CombinedRequestStatues.NEW_OFFER_PROPOSED]: styles.brownText,
  [CombinedRequestStatues.CANCELLED]: styles.redCinnabarText,
  [CombinedRequestStatues.NOT_ATTENDED]: styles.redCinnabarText,
  [CombinedRequestStatues.APPROVED]: styles.greenText,
  [CombinedRequestStatues.EXTENDED]: styles.greenText,

  [CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_PENDING]:
    styles.purpleText,
  [CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_REJECTED]:
    styles.redCinnabarText,
  [CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_CONFIRMED]:
    styles.purpleText,
  [CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_PENDING]:
    styles.purpleText,
  [CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_REJECTED]:
    styles.redCinnabarText,
  [CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_CONFIRMED]:
    styles.greenText,
  [CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_PENDING]:
    styles.redCinnabarText,
  [CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_REJECTED]:
    styles.redCinnabarText,
  [CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_CONFIRMED]:
    styles.redCinnabarText,
}

const ExpirationValue: FC<{date: string}> = ({date}) => {
  const now = DateTime.now()
  // TODO candidate-dashboard: Set locale:
  // const expiration = DateTime.fromISO(date).setLocale()
  const expiration = DateTime.fromISO(date)
  const diff = Math.floor(expiration.diff(now, ['days']).days)

  if (diff > 7 || expiration < now) {
    return <div className={styles.expirationValue}>{formatDate(date)}</div>
  }
  if (now.day === expiration.day) {
    return (
      <div className={styles.expirationText}>
        <FormattedMessage id={edt.expiresToday} />
      </div>
    )
  }
  return (
    <div className={styles.expirationText}>
      <FormattedMessage id={cdt.in} />
      {` ${diff} `}
      {diff === 1 ? (
        <FormattedMessage id={cdt.day} />
      ) : (
        <FormattedMessage id={cdt.days} />
      )}
    </div>
  )
}

type RequestListItemProps = {
  request: IncomingRequestType
}

export const RequestListItem: FC<RequestListItemProps> = ({request}) => {
  const [isOpenInterviewInvitation, setIsOpenInterviewInvitation] =
    useState(false)
  const [isOpenContactRequest, setIsOpenContactRequest] = useState(false)
  const [isOpenOffer, setIsOpenOffer] = useState(false)
  const [isOpenPlacementConfirmation, setIsOpenPlacementConfirmation] =
    useState(false)
  const [cancelInterviewModal, setCancelInterviewModal] = useState(false)

  const companyUrl = getRoute(AppRoute.PublicCompanyProfile).replace(
    ':id',
    request.companyId || ''
  )

  const onViewRequestDetails = () => {
    request.type === RequestType.INTERVIEW_REQUEST &&
      setIsOpenInterviewInvitation(true)
    request.type === RequestType.CONTACT_REQUEST &&
      setIsOpenContactRequest(true)
    request.type === RequestType.OFFER_REQUEST && setIsOpenOffer(true)
    request.type === RequestType.PLACEMENT_REQUEST &&
      setIsOpenPlacementConfirmation(true)
  }

  const isConfirmedByBoth =
    request.status ===
    CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_CONFIRMED

  return (
    <div
      className={classnames(styles.requestItemWrap, {
        [styles.greenBackground]: isConfirmedByBoth,
      })}
    >
      <ItemDetail
        label={'COMPANY'}
        value={
          <Link to={companyUrl} className={styles.companyValue}>
            {request.companyName}
          </Link>
        }
      />
      <ItemDetail
        label={'VIEW DETAILS'}
        value={
          <div
            role="button"
            onClick={onViewRequestDetails}
            className={styles.detailsValue}
            style={typeColors[request.type]}
          >
            <FormattedMessage id={requestTypeTranslationMap[request.type]} />
          </div>
        }
      />
      <ItemDetail
        label={'STATUS'}
        value={
          <div
            className={classnames(
              styles.statusValue,
              statusColors[request.status]
            )}
          >
            <FormattedMessage
              id={seekerRequestStatusTranslationMap[request.status]}
            />
          </div>
        }
      />
      {request.expirationDate ? (
        <ItemDetail
          label={'EXPIRE'}
          value={<ExpirationValue date={request.expirationDate} />}
        />
      ) : (
        <div />
      )}
      <ItemDetail
        className={styles.justifyEnd}
        value={<RequestActionsContainer request={request} />}
      />
      <ModalInterviewInvitation
        request={request}
        isOpenInterviewInvitation={isOpenInterviewInvitation}
        setIsOpenInterviewInvitation={setIsOpenInterviewInvitation}
        setCancelModal={setCancelInterviewModal}
      />
      <ModalContactRequest
        request={request}
        isOpenContactRequest={isOpenContactRequest}
        setIsOpenContactRequest={setIsOpenContactRequest}
        modalDetails={true}
      />
      <ModalPlacementRequest
        request={request}
        isOpenPlacementModal={isOpenPlacementConfirmation}
        setIsOpenPlacementModal={setIsOpenPlacementConfirmation}
      />
      <ModalOfferRequest
        request={request}
        isOpenOfferRequest={isOpenOffer}
        setIsOpenOfferRequest={setIsOpenOffer}
        modalDetails={true}
      />
      <ModalCancelInterview
        request={request}
        cancelModal={cancelInterviewModal}
        setCancelModal={setCancelInterviewModal}
        isOpenInterviewInvitation={isOpenInterviewInvitation}
        setIsOpenInterviewInvitation={setIsOpenInterviewInvitation}
      />
    </div>
  )
}
