import {useEffect, useMemo} from 'react'
import {useHistory} from 'react-router-dom'

import {
  AuthStateType,
  CbtAccountStatus,
  RjbProfileStatus,
  SeekerOnboardingStatus,
  UserType,
} from 'Interfaces'
import {AppRoute} from 'Services/I18n/Constants'
import {getRoute} from 'Services/I18n/Utils'
import {hasGrantedMandatoryConsents} from 'Utils/Consents/Consents'

interface AuthGuardProps {
  role: UserType
  auth: AuthStateType
  getUser: () => void
  children?: JSX.Element | JSX.Element[]
}

const AuthGuard = ({
  auth,
  getUser,
  role,
  children,
}: AuthGuardProps): JSX.Element => {
  const history = useHistory()

  useEffect(() => {
    if (!auth.data?.id) {
      getUser()
    }
  }, [getUser])

  const grantedConsents = useMemo(
    () => hasGrantedMandatoryConsents(auth.data),
    [auth.data]
  )

  useEffect(() => {
    if (auth.data?.id && auth.data?.type !== role) {
      history.push(getRoute(AppRoute.Page404))
    } else if (auth.data?.id && auth.data?.type === UserType.SEEKER) {
      if (auth.data.cbtAccountStatus === CbtAccountStatus.UNVERIFIED) {
        history.push(getRoute(AppRoute.EmailVerification))
      } else if (!grantedConsents) {
        history.push(getRoute(AppRoute.SeekerLoginConsents))
      } else if (
        (auth.data.rjbProfileStatus === RjbProfileStatus.DRAFT &&
          auth.data.onboardingStatus !== SeekerOnboardingStatus.FINISHED) ||
        (!auth.data.rjbProfileStatus && !auth.data.onboardingStatus)
      ) {
        history.push(getRoute(AppRoute.Onboarding))
      }
    } else if (auth.data?.id && auth.data?.type === UserType.EMPLOYER) {
      if (!grantedConsents) {
        history.push(getRoute(AppRoute.EmployerLoginConsents))
      }
      /*} else if (
        auth.data.companyStructure?.company?.companyOnboardingStatus !== 'done'
      ) {
        history.push(getRoute(AppRoute.EmployerOnboarding)) */
      // }
    }
  }, [auth, grantedConsents])

  if (auth.data?.id && auth.data?.type === role) {
    return <>{children}</>
  }

  return <></>
}

export default AuthGuard
