import {FC, useEffect} from 'react'
import {useFormik} from 'formik'
import {Link} from 'react-router-dom'

import {TextProps} from 'Utils/FormikProps'
import styles from './EmployerForgotPassword.module.scss'
import TextField from 'Components/Inputs/TextField'
import {Validation} from './Validation'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {Title, Description} from 'Components/Login'
import {AuthStateType, ForgotPasswordType} from 'Interfaces'
import YellowButton from 'Components/YellowButton'
import {useApiError, useTranslation} from 'Hooks'
import {
  ForgotPasswordTranslations as ft,
  GeneralTranslations as gt,
} from 'Services/I18n/Constants'
import {EmployerLoginLayout} from 'Layouts/EmployerLoginLayout/EmployerLoginLayout'
import {CheckCircle} from '@mui/icons-material'
import {FormattedMessage} from 'react-intl'

interface ForgotPasswordProp {
  auth: AuthStateType
  forgotPassword: (values: ForgotPasswordType) => void
  hideForgotPasswordMessage: () => void
}

export const EmployerForgotPassword: FC<ForgotPasswordProp> = ({
  auth,
  forgotPassword,
  hideForgotPasswordMessage,
}) => {
  const formik = useFormik({
    initialValues: {
      email: '',
      path: getRoute(AppRoute.EmployerResetPassword)
        .replace('/:token', '')
        .slice(1, getRoute(AppRoute.EmployerResetPassword).length),
    },
    validationSchema: Validation,
    onSubmit: (values) => {
      forgotPassword(values)
    },
  })

  useEffect(() => {
    if (auth.displayMessage.forgotPasswordEmailSent) {
      setTimeout(() => {
        hideForgotPasswordMessage()
      }, 5000)
    }
  }, [auth.displayMessage.forgotPasswordEmailSent])

  const translation = useTranslation()

  const employerLoginLink = getRoute(AppRoute.EmployerLogin)

  useApiError(formik.setFieldError, auth.error, 'email')

  return (
    <EmployerLoginLayout>
      <div className={styles.formWrap}>
        <Title text={ft.title} className={styles.title} />
        <Description text={ft.description} className={styles.description} />
        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <div className={styles.inputWrap}>
            <TextField
              name="email"
              type="email"
              label={gt.emailAddress}
              theme="minWidth"
              {...TextProps(formik, 'email')}
            />
            {auth.displayMessage.forgotPasswordEmailSent && (
              <div className={styles.successMessage}>
                <CheckCircle />
                <FormattedMessage id={ft.successMessage} />
              </div>
            )}
            <div className={styles.resetButton}>
              <YellowButton text={ft.buttonResetPassword} />
            </div>
            <Link to={employerLoginLink} className={styles.link}>
              <div className={styles.linkTxt}>
                {' '}
                {translation[ft.backToLogIn]}
              </div>
            </Link>
          </div>
        </form>
      </div>
    </EmployerLoginLayout>
  )
}
