import {FC, useEffect} from 'react'
import {FormattedMessage} from 'react-intl'

import CloseIcon from '@mui/icons-material/Close'
import {Button} from 'Components/Button'
import {SidebarTranslations as st} from 'Services/I18n/Constants'

import styles from './HelpfulHint.module.scss'
import {HelpfulHintType} from 'Interfaces'

interface HelpfullHintOpenProps {
  helpfullHintClosed: boolean
  setHelpfulHintStatus: (helpfullHintOpen: HelpfulHintType) => void
  getHelpfulHintStatus: (name: string) => void
}

export const HelpfulHint: FC<HelpfullHintOpenProps> = ({
  helpfullHintClosed,
  setHelpfulHintStatus,
  getHelpfulHintStatus,
}) => {
  useEffect(() => {
    getHelpfulHintStatus('helpfulHint')
  }, [])

  const helfulHintValue = {name: 'helpfulHint', active: true}

  if (helpfullHintClosed) return null
  return (
    <div className={styles.hintWrapper}>
      <Button
        theme="buttonWithIcon"
        icon={<CloseIcon className={styles.chartIcon} />}
        className={styles.closeButton}
        onClick={() => setHelpfulHintStatus(helfulHintValue)}
      />
      <div className={styles.helpfulHintContainer}>
        <h3>
          <FormattedMessage id={st.helpfulHint} />
        </h3>
        <p>
          <FormattedMessage id={st.selectCandidate} />
        </p>
      </div>
    </div>
  )
}
