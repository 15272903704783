import {FC, useEffect, useState, Fragment} from 'react'
import {
  WelcomeMessageDataFormikType,
  TaxonomiesStateType,
  ErrorType,
  WelcomeMessageDataType,
  AuthStateType,
  BaseTaxonomyType,
  SingleWelcomeMessageType,
} from 'Interfaces'
import styles from './WelcomeMessage.module.scss'
import Modal from 'Components/Modal'
import {WelcomeMessageForm} from './WelcomeMessageForm/WelcomeMessageForm'
import {CompanyWelcomeMessageTranslations as cwmt} from 'Services/I18n/Constants'
import classNames from 'classnames'
import {useTranslation} from 'Hooks'
import SectionHeader from 'Components/Profile/SectionHeader'
import {FavoriteBorder, ModeEditOutlineOutlined, Add} from '@mui/icons-material'
import {HTMLText} from 'Components/HTMLText'

interface WelcomeMessageEditableProps {
  addWelcomeMessage: (value: WelcomeMessageDataFormikType) => void
  editWelcomeMessage: (value: WelcomeMessageDataFormikType) => void
  getTaxonomies: () => void
  getCompanyData: () => void
  auth: AuthStateType
  seekerJobCategory?: BaseTaxonomyType
  taxonomies: TaxonomiesStateType
  welcomeMessages: WelcomeMessageDataType[]
  canChange?: boolean
  apiError?: ErrorType
}

export const WelcomeMessageEditable: FC<WelcomeMessageEditableProps> = ({
  addWelcomeMessage,
  editWelcomeMessage,
  getTaxonomies,
  getCompanyData,
  auth,
  taxonomies,
  welcomeMessages,
}) => {
  const translation = useTranslation()

  // Filter messages by language:
  const [visibleWelcomeMessages, setVisibleWelcomeMessages] = useState<
    SingleWelcomeMessageType[]
  >([])
  // Radio buttons state to change displayed message:
  const [displayedMessageIndex, setDisplayedMessageIndex] = useState(0)

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [currentModalMode, setCurrentModalMode] = useState('')

  // Open Modal in Add or Edit mode:
  const openModalInMode = (modalMode: string): void => {
    setIsModalOpen(true)
    setCurrentModalMode(modalMode)
  }
  const closeModal = (): void => {
    setIsModalOpen(false)
    setCurrentModalMode('')
  }

  // Mounting - Fetch company data (welcomeMessages):
  useEffect(() => {
    getCompanyData()
    getTaxonomies()
  }, [])

  // Auth-LanguageId or taxonomies data Fetched - Set Messages Language
  useEffect(() => {
    taxonomies.systemLanguages.data = taxonomies.systemLanguages.data.map(
      (lang) => ({
        id: lang.id,
        translation: lang.name, // have to add for dropdowns - improve(refactor dropdown component / backend rename)
        name: lang.name,
      })
    )
  }, [auth?.data?.languageId, taxonomies?.systemLanguages])

  useEffect(() => {
    const mappedVisibleMessages: SingleWelcomeMessageType[] = []

    welcomeMessages.forEach((message) => {
      const {translatedTexts, ...other} = message
      translatedTexts.forEach((translatedTxt) => {
        mappedVisibleMessages.push({...other, translatedTxt})
      })
    })
    // Sort translated texts messages
    mappedVisibleMessages.sort((a, b) => {
      if (a.translatedTxt?.dateUpdated && b.translatedTxt?.dateUpdated) {
        return (
          new Date(a.translatedTxt?.dateUpdated).getTime() -
          new Date(b?.translatedTxt?.dateUpdated).getTime()
        )
      }
      return 0
    })

    setVisibleWelcomeMessages(mappedVisibleMessages)
  }, [welcomeMessages])

  return (
    <>
      {/* ADD Title & Button when 0 existing entries & Company user */}
      {visibleWelcomeMessages.length === 0 && (
        <SectionHeader
          title={cwmt.modalTitle}
          onAdd={() => openModalInMode('add')}
          className={styles.header}
        />
      )}

      {/*  Display existing data on Company Profile - Only for the chosen language */}
      {visibleWelcomeMessages.map(
        (welcomeMessage: SingleWelcomeMessageType, index: number) => {
          // Show message which index is selected in radio buttons below
          return welcomeMessage && displayedMessageIndex === index ? (
            <Fragment
              key={`${welcomeMessage.id}-${welcomeMessage.translatedTxt.language}`}
            >
              {/* Show Add icon if company_user & less than max msgs (job-cat * langs)  */}
              {welcomeMessages.reduce(
                (prev, curr) => curr.translatedTexts.length + prev,
                0
              ) <
                taxonomies.jobCategories.data.length *
                  taxonomies.systemLanguages.data.length && (
                <div className={styles.addButton}>
                  <button onClick={() => openModalInMode('add')}>
                    <Add />
                  </button>
                </div>
              )}

              <div className={styles.mainContent}>
                <div className={styles.leftContent}>
                  <FavoriteBorder
                    className={styles.heartIcon}
                    fontSize="large"
                  />
                </div>
                <div className={styles.breakLine}></div>

                <div className={styles.rightContent}>
                  <div className={styles.editButton}>
                    <button
                      onClick={() => openModalInMode('edit')}
                      className={styles.edit}
                    >
                      <ModeEditOutlineOutlined />
                    </button>
                  </div>

                  <>
                    {/* Welcome Title & Text  - there will be only 1(for the selected language) translated text - (filtered above) */}
                    <h1 className={styles.title}>
                      {welcomeMessage?.translatedTxt?.welcomeTitle
                        ? welcomeMessage?.translatedTxt?.welcomeTitle
                        : ''}
                    </h1>
                    <div className={styles.paragraph}>
                      <HTMLText
                        htmlInputValue={
                          welcomeMessage?.translatedTxt?.welcomeText
                        }
                      />
                    </div>
                  </>

                  {/* SWITCH PERSONA BUTTONS */}
                  {/* Switching Messages based on persona/job-cat (only for company user, seekers sees only for his category)  */}
                  {visibleWelcomeMessages.length > 1 && (
                    <div className={styles.radioBox}>
                      {visibleWelcomeMessages.map(
                        (visibleWelcomeMessages, index) => {
                          return (
                            <span
                              key={visibleWelcomeMessages.translatedTxt.id}
                              className={classNames(
                                styles.radioBoxButton,
                                index === displayedMessageIndex &&
                                  styles.radioBoxButtonActive
                              )}
                              onClick={() => {
                                setDisplayedMessageIndex(index)
                              }}
                            />
                          )
                        }
                      )}
                    </div>
                  )}
                </div>
              </div>
            </Fragment>
          ) : null
        }
      )}

      <Modal
        isOpen={isModalOpen}
        onAfterClose={closeModal}
        title={`${
          currentModalMode === 'add'
            ? translation[cwmt.add]
            : translation[cwmt.edit]
        } ${translation[cwmt.modalTitle]}`}
        icon={FavoriteBorder}
        theme="desktopFlex"
        ariaHideApp={false}
        className={styles.modal}
      >
        <div className={styles.modalWrapper}>
          {/* Inputs */}
          {/* Each chosen Language should have N(persona-job cat) number of welcomeMessages for edit (empty for add)
            - Filter welcomeMessages for the language/job-cat and pass only those. (Use system lang to filter) */}
          <WelcomeMessageForm
            closeModal={closeModal}
            currentModalMode={currentModalMode}
            addWelcomeMessage={addWelcomeMessage}
            editWelcomeMessage={editWelcomeMessage}
            taxonomies={taxonomies}
            welcomeMessages={welcomeMessages}
          />
        </div>
      </Modal>
    </>
  )
}
