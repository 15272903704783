import {FC} from 'react'
import {FormattedMessage} from 'react-intl'
import Modal, {SubmitFooter} from 'Components/Modal'
import styles from './NoLongerActiveCandidateModal.module.scss'
import {Warning} from '@mui/icons-material'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'

interface NoLongerActiveCandidateModalProps {
  open: boolean
  setOpen: (open: boolean) => void
}

export const NoLongerActiveCandidateModal: FC<
  NoLongerActiveCandidateModalProps
> = ({open, setOpen}) => {
  return (
    <Modal
      className={styles.noActiveCandidateModal}
      icon={Warning}
      title={edt.noLongerActiveCandidateTitle}
      isOpen={open}
      Footer={
        <SubmitFooter
          onSubmit={() => {
            setOpen(false)
          }}
          submitText={edt.noLongerActiveCandidateSubmitText}
          theme="understand"
        />
      }
      onAfterClose={() => {
        setOpen(false)
      }}
    >
      <div className={styles.infoWrapper}>
        <div>
          <FormattedMessage id={edt.noLongerActiveCandidateModalPart1} />
        </div>
        <ul>
          <li>
            <FormattedMessage id={edt.noLongerActiveCandidateModalPart2} />
          </li>
          <li>
            <FormattedMessage id={edt.noLongerActiveCandidateModalPart3} />
          </li>
          <li>
            <FormattedMessage id={edt.noLongerActiveCandidateModalPart4} />
          </li>
        </ul>
      </div>
    </Modal>
  )
}
