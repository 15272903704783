import {FC} from 'react'
import styles from './EmployerFAQ.module.scss'
import ShowMoreContent from 'Components/ShowMoreContent'
import {PublicPagesHeader} from 'Components/PublicPagesHeader'
import {useMediaQuery} from 'react-responsive'
import YellowText from 'Components/YellowText'
import subpage1 from 'Assets/Images/homepage/subpage1.jpg'
import subpage2 from 'Assets/Images/homepage/subpage2.jpg'
import subpage4 from 'Assets/Images/homepage/subpage4.jpg'
import {EmployerFAQTranslations as ef} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'

export const EmployerFAQ: FC = () => {
  const isWide = useMediaQuery({
    query: '(min-width: 1920px)',
  })

  return (
    <div>
      <div className={styles.layout}>
        <div className={styles.container}>
          <PublicPagesHeader page="employer" />
          <div className={styles.containerSecond}>
            <div className={styles.titleContainer}>
              <h1 className={styles.title}>
                <YellowText>
                  <FormattedMessage id={ef.titleYellow} />
                </YellowText>
                <FormattedMessage id={ef.mainTitle} />
              </h1>
            </div>
            <div className={styles.section}>
              <img
                className={styles.image}
                src={subpage1}
                alt="Person Looking at Laptop"
              />
              <div className={styles.sectionLeft}>
                <p className={styles.paragraphTitle}>
                  <FormattedMessage id={ef.titleFirst} />
                </p>
                <ShowMoreContent
                  height={isWide ? 230 : 100}
                  mobileHeight={120}
                  className={styles.showMore}
                  theme="whiteIcon"
                >
                  <p className={styles.paragraph}>
                    <FormattedMessage id={ef.paragraphFirst} />
                  </p>
                </ShowMoreContent>
              </div>
            </div>
            <div className={styles.section}>
              <img
                className={styles.image}
                src={subpage2}
                alt="Person typing on laptop"
              />
              <div className={styles.sectionLeft}>
                <p className={styles.paragraphTitle}>
                  <FormattedMessage id={ef.titleSecond} />
                </p>
                <p className={styles.paragraph}>
                  <FormattedMessage id={ef.paragraphSecond} />
                </p>
              </div>
            </div>
            <div className={styles.sectionBig}>
              <div className={styles.sectionUp}>
                <img
                  className={styles.image}
                  src={subpage4}
                  alt="Person typing on laptop"
                />
                <div className={styles.sectionLeft}>
                  <p className={styles.paragraphTitle}>
                    <FormattedMessage id={ef.titleThird} />
                  </p>
                  <p className={styles.paragraph}>
                    <FormattedMessage id={ef.paragraphThird} />
                  </p>
                </div>
              </div>
              <ShowMoreContent
                height={300}
                mobileHeight={0}
                className={styles.showMore}
                theme="whiteIcon"
              >
                <div className={styles.grid}>
                  <div>
                    <p className={styles.paragraphTitleGrid}>
                      <FormattedMessage id={ef.subTitleFirst} />
                    </p>
                    <p className={styles.paragraph}>
                      <FormattedMessage id={ef.subParagraphFirst} />
                    </p>
                  </div>
                  <div>
                    <p className={styles.paragraphTitleGrid}>
                      <FormattedMessage id={ef.subTitleSecond} />
                    </p>
                    <p className={styles.paragraph}>
                      <FormattedMessage id={ef.subParagraphSecond} />
                    </p>
                  </div>
                  <div>
                    <p className={styles.paragraphTitleGrid}>
                      <FormattedMessage id={ef.subTitleThird} />
                    </p>
                    <p className={styles.paragraph}>
                      <FormattedMessage id={ef.subParagraphThird} />
                    </p>
                  </div>
                  <div>
                    <p className={styles.paragraphTitleGrid}>
                      <FormattedMessage id={ef.subTitleFourth} />
                    </p>
                    <p className={styles.paragraph}>
                      <FormattedMessage id={ef.subParagraphFourth} />
                    </p>
                  </div>
                  <div>
                    <p className={styles.paragraphTitleGrid}>
                      <FormattedMessage id={ef.subTitleFifth} />
                    </p>
                    <p className={styles.paragraph}>
                      <FormattedMessage id={ef.subParagraphFifth} />
                    </p>
                  </div>
                  <div>
                    <p className={styles.paragraph}>
                      <FormattedMessage id={ef.subParagraphLast} />
                    </p>
                  </div>
                </div>
              </ShowMoreContent>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
