import {FC} from 'react'
import {useMediaQuery} from 'react-responsive'

import MainHeader from 'Components/MainHeader'
import MainLayout from 'Layouts/MainLayout'
import FooterAssessments from 'Components/Footer'
import {MobileFooter} from 'Components/MobileFooter'
import {useTranslation} from 'Hooks'
import {AppRoute} from 'Services/I18n/Constants'
import {getRoute} from 'Services/I18n/Utils'
import {GeneralAssessmentsTranslations as gat} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'

import styles from './AssessmentsSuccess.module.scss'

export const AssessmentsSuccess: FC = () => {
  const isMobile = useMediaQuery({
    query: '(max-width: 900px)',
  })

  const translation = useTranslation()

  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: getRoute(AppRoute.HomePageCandidate)},
    {name: translation[gat.title], link: getRoute(AppRoute.Assessments)},
    {name: translation[gat.assessmentsSuccess]},
  ]

  const links = [
    {
      name: translation[gat.personalityTestTitle],
      route: getRoute(AppRoute.Profile),
    },
    {
      name: translation[gat.languageTestTitle],
      route: getRoute(AppRoute.Profile),
    },
    {name: translation[gat.skillsTestTitle], route: getRoute(AppRoute.Profile)},
  ]

  const Footer: FC = () => {
    return (
      <>{isMobile ? <MobileFooter links={links} /> : <FooterAssessments />}</>
    )
  }

  return (
    <MainLayout Footer={<Footer />}>
      <div className={styles.mainAssessmentsContainer}>
        <div className={styles.container}>
          <MainHeader
            breadcrumbsArray={breadcrumbsArray}
            title={gat.assessmentsSuccess}
            className={styles.assessmentsSuccessTitle}
          />

          <p className={styles.success}>
            <FormattedMessage id={gat.assessmentsSuccessMessage} />
          </p>
        </div>
      </div>
    </MainLayout>
  )
}
