import Title from 'Components/Title'
import Hr from 'Components/Hr'
import DropdownFiltered from 'Components/Inputs/DropdownFiltered'
import {FC, useEffect, useState} from 'react'
import {TaxonomiesStateType, WelcomeMessageDataType} from 'Interfaces'
import styles from './AddWelcomeMessageForm.module.scss'
import {Button} from 'Components/Button'
import {CompanyWelcomeMessageTranslations as cwmt} from 'Services/I18n/Constants'
import TextField from 'Components/Inputs/TextField'
import {OnChangeProps} from 'Utils/FormikProps'
import {setDropdownField, setupJobCategoriesLanguages} from '../Utils'
import {
  VisibilityOffOutlined,
  VisibilityOutlined,
  DeleteOutlineOutlined,
} from '@mui/icons-material'
import {HTMLInput} from 'Components/Inputs/HTMLInput/HTMLInput'

interface AddWelcomeMessageFormProps {
  index: number
  editNewPersonasIndex?: number
  formik: any
  remove: Function
  taxonomies: TaxonomiesStateType
  numOfPersonasInAdd: number
  setNumOfPersonasInAdd: Function
  welcomeMessages: WelcomeMessageDataType[]
  jobCategoriesLanguagesState: any
  setJobCategoriesLanguagesState: Function
  jobCategoriesOptionsState: any
}

export const AddWelcomeMessageForm: FC<AddWelcomeMessageFormProps> = ({
  index,
  editNewPersonasIndex,
  formik,
  taxonomies,
  remove,
  numOfPersonasInAdd,
  setNumOfPersonasInAdd,
  welcomeMessages,
  jobCategoriesLanguagesState,
  setJobCategoriesLanguagesState,
  jobCategoriesOptionsState,
}) => {
  const currentTranslatedTextIndex =
    formik.values.welcomeMessages[index]?.translatedTexts?.length - 1

  const [visibilityStatus, setVisibilityStatus] = useState(
    formik.values.welcomeMessages[index].translatedTexts[
      currentTranslatedTextIndex
    ].visibility
  )
  const [selectedLanguageState, setSelectedLanguageState] = useState<any>()
  const [selectedJobCategoryState, setSelectedJobCategoryState] =
    useState<any>()
  // Initial LanguagesOptions Values - Taxonomies -> JobCategoryLanguages only available languages
  const languagesWithTranslationField = taxonomies.systemLanguages?.data.map(
    (language) => ({
      ...language,
      translation: language.name,
    })
  )
  const [languageOptionsState, setLanguageOptionsState] = useState(
    languagesWithTranslationField
  )

  // Update JobCategoriesLanguages - When jobCategory and Language selected
  useEffect(() => {
    // index+1 is current add msg addPersona, if more than current num, add new persona is clicked (lock current values)
    const addPersonaIndex =
      editNewPersonasIndex || editNewPersonasIndex === 0
        ? editNewPersonasIndex
        : index + 1

    if (
      selectedJobCategoryState &&
      selectedLanguageState &&
      addPersonaIndex < numOfPersonasInAdd
    ) {
      const selectedJobCategoryId = selectedJobCategoryState.id
      const oldLanguagesIds = jobCategoriesLanguagesState[selectedJobCategoryId]
      const newLanguagesIds = oldLanguagesIds.filter(
        (languageId: string) => languageId !== selectedLanguageState.id
      )

      const newJobCategoriesLanguages = {
        ...jobCategoriesLanguagesState,
        [selectedJobCategoryId]: newLanguagesIds,
      }
      setJobCategoriesLanguagesState(newJobCategoriesLanguages)
    }
  }, [selectedJobCategoryState, selectedLanguageState, numOfPersonasInAdd])

  // Job Category Selected - Language not = Update available languages for that category
  useEffect(() => {
    if (selectedJobCategoryState && !selectedLanguageState) {
      const selectedJobCategoryId = selectedJobCategoryState.id
      const jobCategoryLanguagesIds =
        jobCategoriesLanguagesState[selectedJobCategoryId]

      const availableLanguagesForCategory =
        languagesWithTranslationField.filter((language) =>
          jobCategoryLanguagesIds.includes(language.id)
        )
      setLanguageOptionsState(availableLanguagesForCategory)
    }
  }, [selectedJobCategoryState, selectedLanguageState])

  // Job Category changed - update langs
  useEffect(() => {
    if (selectedJobCategoryState) {
      const selectedJobCategoryId = selectedJobCategoryState?.id
      const jobCategoryLanguagesIds =
        jobCategoriesLanguagesState[selectedJobCategoryId]

      const availableLanguagesForCategory =
        languagesWithTranslationField.filter((language) =>
          jobCategoryLanguagesIds.includes(language.id)
        )
      setLanguageOptionsState(availableLanguagesForCategory)
    }
  }, [selectedJobCategoryState])

  //  Set Visibility statuses
  useEffect(() => {
    formik.setFieldValue(
      `welcomeMessages[${index}].translatedTexts[${currentTranslatedTextIndex}].visibility`,
      visibilityStatus
    )
  }, [visibilityStatus])

  return (
    <>
      {/* JOB CATEGORY Dropdown */}
      <div className={styles.visibility}>
        <span
          onClick={() => {
            const prevStatus =
              formik.values.welcomeMessages[index].translatedTexts[
                currentTranslatedTextIndex
              ].visibility
            setVisibilityStatus(!prevStatus)
          }}
        >
          {visibilityStatus ? (
            <VisibilityOutlined
              color="disabled"
              className={styles.visibilityIcon}
            />
          ) : (
            <VisibilityOffOutlined
              color="disabled"
              className={styles.visibilityIcon}
            />
          )}
        </span>
        <Title
          text={cwmt.personaTitle}
          className={styles.title}
          theme="modal"
        />
      </div>
      <DropdownFiltered
        name={`welcomeMessages[${index}].jobCategory`}
        items={jobCategoriesOptionsState}
        value={formik.values.welcomeMessages[index]?.jobCategory}
        theme="modal"
        setValue={(name, value) => {
          setSelectedJobCategoryState(value)
          setDropdownField(name, value, formik, taxonomies)

          // Reset Languages if newly selected job cat doesn't have it
          const jobCategoryLanguagesIds =
            jobCategoriesLanguagesState[value?.id as string]
          const isCurrentlySelectedLangAvailableForCategory =
            jobCategoryLanguagesIds.includes(selectedLanguageState?.id)
          if (!isCurrentlySelectedLangAvailableForCategory) {
            formik.setFieldValue(
              `welcomeMessages[${index}].translatedTexts[${currentTranslatedTextIndex}].language`,
              ''
            )
          }
        }}
        error={
          formik?.submitCount &&
          formik?.errors?.welcomeMessages?.[index]?.jobCategory?.id
            ? formik?.errors?.welcomeMessages?.[index]?.jobCategory?.id
            : undefined
        }
      />
      <Hr theme="modal" />

      {/* LANGUAGE Dropdown */}
      <div className={styles.visibility}>
        <Title text={cwmt.languageTitle} theme="modal" />
      </div>
      <DropdownFiltered
        name={`welcomeMessages[${index}].translatedTexts[${currentTranslatedTextIndex}].language`}
        items={languageOptionsState}
        value={
          formik.values.welcomeMessages[index]?.translatedTexts[
            currentTranslatedTextIndex
          ].language
        }
        theme="modal"
        setValue={(name, value) => {
          setSelectedLanguageState(value)
          setDropdownField(name, value, formik, taxonomies)
        }}
        error={
          formik?.submitCount &&
          formik?.errors?.welcomeMessages?.[index]?.translatedTexts[
            currentTranslatedTextIndex
          ]?.language?.id
            ? formik.errors?.welcomeMessages?.[index]?.translatedTexts[
                currentTranslatedTextIndex
              ]?.language?.id
            : undefined
        }
      />
      <Hr theme="modal" />

      {/* WELCOME TITLE TextInput */}
      <Title text={cwmt.welcomeTitleTitle} theme="modal" />
      <TextField
        theme="modal"
        name={`welcomeMessages[${index}].translatedTexts[${currentTranslatedTextIndex}].welcomeTitle`}
        placeholder={cwmt.welcomeTitlePlaceholderText}
        {...OnChangeProps(
          formik,
          `welcomeMessages[${index}].translatedTexts[${currentTranslatedTextIndex}].welcomeTitle`
        )}
        error={
          formik?.submitCount &&
          formik?.errors?.welcomeMessages?.[index]?.translatedTexts[
            currentTranslatedTextIndex
          ]?.welcomeTitle
            ? formik.errors?.welcomeMessages?.[index]?.translatedTexts[
                currentTranslatedTextIndex
              ]?.welcomeTitle
            : undefined
        }
      />
      <Hr theme="modal" />

      {/* WELCOME TEXT TextInput */}
      <Title text={cwmt.welcomeTextTitle} theme="modal" />
      <HTMLInput
        name={`welcomeMessages[${index}].translatedTexts[${currentTranslatedTextIndex}].welcomeText`}
        onChange={formik.setFieldValue}
        setError={formik.setFieldError}
        charLimit={750}
        value={
          formik.values.welcomeMessages[index]?.translatedTexts[
            currentTranslatedTextIndex
          ].welcomeText
        }
        error={
          formik?.errors?.welcomeMessages?.[index]?.translatedTexts[
            currentTranslatedTextIndex
          ]?.welcomeText || undefined
        }
      />

      <Hr theme="modalSmall" />

      {/* DELETE BUTTON */}
      {/* More than 1 welcome message - add delete button for each welcome message added */}
      {/* If only 1, keep it - no delete = no empty form */}
      {formik?.values?.welcomeMessages?.length > 1 && (
        <div className={styles.deleteFormikItemButtonWrapper}>
          <Button
            onClick={() => {
              remove(index)
              setNumOfPersonasInAdd(numOfPersonasInAdd - 1)

              // Update options - Add back deleted values:
              const deletedMessage = formik.values.welcomeMessages[index]
              const deletedMessageLanguageId =
                deletedMessage.translatedTexts[0].language.id
              let combinedMessages = [
                ...welcomeMessages,
                ...formik.values.welcomeMessages,
              ]
              combinedMessages = combinedMessages.filter((message) => {
                return !(
                  message.jobCategory.id === deletedMessage.jobCategory.id &&
                  message?.translatedTexts[0]?.language.id ===
                    deletedMessageLanguageId
                )
              })

              const newJobCategoriesLanguages = setupJobCategoriesLanguages(
                taxonomies,
                combinedMessages
              )
              setJobCategoriesLanguagesState(newJobCategoriesLanguages)
            }}
            text={cwmt.deleteButtonText}
            className={styles.deleteFormikItemButton}
            icon={<DeleteOutlineOutlined />}
          />
        </div>
      )}
    </>
  )
}
