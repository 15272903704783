export const date = {
  'date.selectDay': 'Select day',
  'date.selectMonth': 'Select month',
  'date.selectYear': 'Select year',
  'date.startMonth': 'Start date (month)',
  'date.endMonth': 'End date (month)',
  'date.startYear': 'Start date (year)',
  'date.endYear': 'End date (year)',
  'date.yearOfCompletion': 'Year of completion',
  'date.projectEndYear': 'Project year',
  'date.january': 'January',
  'date.february': 'February',
  'date.march': 'March',
  'date.april': 'April',
  'date.may': 'May',
  'date.june': 'June',
  'date.july': 'July',
  'date.august': 'August',
  'date.september': 'September',
  'date.october': 'October',
  'date.november': 'November',
  'date.december': 'December',
}
