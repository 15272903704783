import * as Yup from 'yup'
import {PhoneNumber, PostalCode} from 'Validations'

export const ValidationNext = Yup.object().shape({
  companyLongName: Yup.string().required('Company long name is required'),
  address: Yup.string().required('Address is required'),
  postalCode: PostalCode,
  town: Yup.object().nullable().required('City is required'),
  country: Yup.object().nullable().required('Country is required'),
  contactsPersonTelephoneNumber: PhoneNumber.required(
    'Phone number is required'
  ),
  contactsPersonPositionInCompany: Yup.string().required(
    'Contact’s Person Position in Company is required'
  ),
})
