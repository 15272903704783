import {FC, useEffect, useState} from 'react'
import {useLocation} from 'react-router-dom'
import Snackbar from '@mui/material/Snackbar'

import styles from './SneckbarNotification.module.scss'
import {SnackbarControlerType} from 'Interfaces'

interface SnackbarProps {
  snackbars: SnackbarControlerType[]
  cleanSnackbars: () => void
}

export const SnackbarNotification: FC<SnackbarProps> = ({
  snackbars,
  cleanSnackbars,
}) => {
  const location = useLocation()
  const [open, setOpen] = useState(
    Object.assign(
      {},
      snackbars.map(() => true)
    )
  )

  useEffect(() => {
    setOpen({
      ...Object.assign(
        {},
        snackbars.map(() => true)
      ),
      ...open,
    })
  }, [snackbars])

  useEffect(() => {
    cleanSnackbars()
  }, [location.pathname])

  return (
    <div className={styles.container}>
      <div className={styles.containerFlex}>
        {snackbars.map((item, index) => {
          return (
            <Snackbar
              key={index}
              className={styles.shackbar}
              open={open[index]}
              onClose={() => setOpen({...open, [index]: false})}
              autoHideDuration={item.duration || 2000}
            >
              <div className={styles.shackbarMessage}>
                <span>{item.message}</span>
              </div>
            </Snackbar>
          )
        })}
      </div>
    </div>
  )
}
