import {connect} from 'react-redux'

import {PrivacyPolicy} from './PrivacyPolicy'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
})

export const PrivacyPolicyContainer = connect(
  mapStateToProps,
  null
)(PrivacyPolicy)
