export const date = {
  'date.selectDay': 'Izberite dan',
  'date.selectMonth': 'Izberite mesec',
  'date.selectYear': 'Izberite leto',
  'date.startMonth': 'Datum začetka (mesec)',
  'date.endMonth': 'Datum zaključka (mesec)',
  'date.startYear': 'Datum začetka (leto)',
  'date.endYear': 'Datum zaključka (leto)',
  'date.yearOfCompletion': 'Leto zaključka',
  'date.projectEndYear': 'Leto projekta',
  'date.january': 'Januar',
  'date.february': 'Februar',
  'date.march': 'Marec',
  'date.april': 'April',
  'date.may': 'Maj',
  'date.june': 'Junij',
  'date.july': 'Julij',
  'date.august': 'Avgust',
  'date.september': 'September',
  'date.october': 'Oktober',
  'date.november': 'November',
  'date.december': 'December',
}
