import styles from './ContactData.module.scss'
import Validation from './Validation'
import TextField from 'Components/Inputs/TextField'
import {useFormik} from 'formik'
import {
  AuthStateType,
  BaseTaxonomyType,
  ContactDataType,
  SeekerStateType,
  TaxonomiesStateType,
} from 'Interfaces'
import MainLayout from 'Layouts/MainLayout'
import {FC, useEffect} from 'react'
import {TextProps} from 'Utils/FormikProps'
import YellowButton from 'Components/YellowButton'
import {useApiError} from 'Hooks'
import {
  ContactDataTranslations as cd,
  ProfileTranslations as pt,
} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import TypeAheadImproved from 'Components/Inputs/TypeAheadImproved'
import {TypeAheadFetch} from 'Components/Inputs/TypeAheadFetch'

interface contactDataProps {
  auth: AuthStateType
  seeker: SeekerStateType
  contactData: (data: ContactDataType) => void
  taxonomies: TaxonomiesStateType
  getCountries: () => void
  getSeekerProfile: () => void
}

interface ContactDataFormikValues {
  phoneNumber: string
  streetAddress: string
  postalCode: string
  town?: BaseTaxonomyType | null
  country?: BaseTaxonomyType | null
  email: string
}

export const ContactData: FC<contactDataProps> = ({
  auth,
  seeker,
  contactData,
  taxonomies,
  getCountries,
  getSeekerProfile,
}) => {
  useEffect(() => {
    if (auth.data) {
      getSeekerProfile()
      setTimeout(() => {
        getCountries()
      }, 0)
    }
  }, [auth.data])

  const formik = useFormik<ContactDataFormikValues>({
    initialValues: {
      phoneNumber: '+386',
      streetAddress: '',
      postalCode: '',
      town: null,
      country: null,
      email: '',
    },
    validationSchema: Validation,
    onSubmit: (values) => {
      let data = {
        phoneNumber: values.phoneNumber,
        streetAddress: values.streetAddress,
        postalCode: values.postalCode,
        countryId: values.country?.id || '',
      }
      if (values.town?.id.includes('newValue~')) {
        contactData({...data, townName: values.town.translation})
      } else {
        contactData({...data, townId: values.town?.id || ''})
      }
    },
  })

  useEffect(() => {
    formik.setValues({
      email: seeker.data?.full?.email || '',
      phoneNumber: seeker.data?.full?.phone || '+386',
      streetAddress: seeker.data?.full?.streetAddress || '',
      postalCode: seeker.data?.full?.postalCode || '',
      town: seeker.data?.full?.homeTown,
      country: seeker.data?.full?.homeCountry,
    })
  }, [seeker.data?.full])

  useApiError(formik.setFieldError, seeker.error)
  const setTypeAheadField = (name: string, values: BaseTaxonomyType[]) => {
    formik.setFieldValue(name, values[0])
  }

  return (
    <MainLayout background={true}>
      {auth.data && (
        <div className={styles.Container}>
          <div className={styles.ContainerUp}>
            <h1 className={styles.ContainerUpTitle}>
              <FormattedMessage id={cd.title} />
            </h1>
            <p className={styles.ContainerUpText}>
              <FormattedMessage id={cd.message} />
            </p>
          </div>
          <div className={styles.ContainerMiddle}>
            <p className={styles.Text}>
              <FormattedMessage id={cd.description} />
            </p>
          </div>
          <div className={styles.ContainerDown}>
            <p className={styles.ContainerDownText}>
              <FormattedMessage id={cd.checkMessage} />
            </p>
            <div className={styles.ContainerInputs}>
              <form onSubmit={formik.handleSubmit}>
                <div className={styles.ContainerInputsRow}>
                  <TextField
                    label={cd.phoneNumber}
                    name="phoneNumber"
                    type="text"
                    {...TextProps(formik, 'phoneNumber')}
                    theme="white"
                  />
                  <TextField
                    label={cd.email}
                    name="email"
                    type="text"
                    value={formik.values.email}
                    theme="white"
                    disabled
                    onChange={() => {}}
                  />
                </div>
                <div className={styles.ContainerInputsRow}>
                  <TextField
                    label={cd.streetAndNumber}
                    name="streetAddress"
                    type="text"
                    {...TextProps(formik, 'streetAddress')}
                    theme="white"
                  />
                  <TextField
                    label={cd.zipCode}
                    name="postalCode"
                    type="text"
                    {...TextProps(formik, 'postalCode')}
                    onChange={(e) => {
                      const numsRegex = /^[0-9\b]+$/
                      if (
                        e.target.value === '' ||
                        numsRegex.test(e.target.value)
                      ) {
                        formik.handleChange(e)
                      }
                    }}
                    theme="white"
                  />
                </div>

                <div className={styles.ContainerInputsRowDropdown}>
                  <div className={styles.one}>
                    <TypeAheadFetch
                      name="town"
                      value={formik.values.town || null}
                      options={[]}
                      optionsQueryUrl={`/small-index/towns?${
                        formik.values.country
                          ? `countryId=${formik.values.country.id}&`
                          : ''
                      }name=`}
                      setValue={formik.setFieldValue}
                      label={cd.city}
                      placeholder={pt.placeholderTown}
                      error={
                        formik.submitCount && formik.errors?.town
                          ? formik.errors.town
                          : undefined
                      }
                      smallIndex
                      autoComplete
                      themes={['white']}
                    />
                  </div>
                  <div className={styles.two}>
                    <TypeAheadImproved
                      name="country"
                      theme="simpleWhite"
                      items={taxonomies.countries.data}
                      defaultValue={
                        formik.values.country
                          ? [formik.values.country]
                          : undefined
                      }
                      setValue={(name, value) => {
                        setTypeAheadField(name, value)
                        if (formik.values.country?.id !== value[0]?.id) {
                          formik.setFieldValue('town', null)
                        }
                      }}
                      label={cd.country}
                      setTouched={formik.getFieldHelpers('country').setTouched}
                      placeholder={cd.selectYourCountry}
                      maxNumSelections={1}
                      error={
                        (formik.submitCount && formik.errors?.country) ||
                        undefined
                      }
                    />
                  </div>
                </div>

                <div className={styles.ContainerInputsBTN}>
                  <YellowButton
                    text={cd.confirmMessage}
                    type="submit"
                    className={styles.btn}
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </MainLayout>
  )
}
