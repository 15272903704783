import {connect} from 'react-redux'

import {RequestContact} from './RequestContact'
import {AppDispatch} from 'Store'
import {sendRequestContact} from 'Store/Actions'
import {RootState} from 'Store/Reducers'
import {IncomingRequestType, SendRequestContactType} from 'Interfaces'

interface RequestContactProps {
  contactRequest?: IncomingRequestType | null
  seekerId: string
}

const mapStateToProps = (state: RootState, ownProps: RequestContactProps) => ({
  contactRequest: ownProps.contactRequest,
  seekerId: ownProps.seekerId,
  state: state,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  sendRequestContact: (value: SendRequestContactType) =>
    dispatch(sendRequestContact(value)),
})

export const RequestContactContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestContact)
