import {employerDashboard} from './Dashboard/en'
import {login} from './Login/en'
import {thankYou} from './ThankYou/en'
import {employeronboarding} from './Onboarding/en'
import {candidateSearch} from './Search/en'
import {spectoCalendarTranslations} from './Calendar/en'
import {myProject} from './MyProject/en'
import {employerHomePage} from './EmployerHomePage/en'
import {employerFAQ} from './EmployerFAQ/en'
import {employerInfoLandingPage} from './EmployerInfoLandingPage/en'
import {companyProfile} from './CompanyProfile/en'

export const employer = {
  ...login,
  ...thankYou,
  ...employeronboarding,
  ...employerDashboard,
  ...employeronboarding,
  ...candidateSearch,
  ...spectoCalendarTranslations,
  ...myProject,
  ...employerHomePage,
  ...employerFAQ,
  ...employerInfoLandingPage,
  ...companyProfile,
}
