import Modal from 'Components/Modal'
import {ThumbsFooter} from 'Components/Modal/ThumbsFooter'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import styles from './ModalInterviewInvitationEmp.module.scss'
import ModalSendOfferEmp from 'Components/EmployerDashboard/InterviewInvitationListItem/ModalSendOfferEmp/index'
import classnames from 'classnames'
import {
  IncomingRequestType,
  SpectoProposeNewTimeParamsType,
  RjbProfileStatus,
} from 'Interfaces'
import {FC, useState} from 'react'
import {DateTime} from 'luxon'
import {FormattedMessage} from 'react-intl'
import {
  AppRoute,
  CandidateDashboardTranslations as cdt,
} from 'Services/I18n/Constants'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'
import {
  InterviewRequestStatus,
  employerRequestStatusTranslationMap,
  CombinedRequestStatues,
} from '../Constants'
import {
  HistoryEdu,
  ConnectWithoutContact,
  Notifications,
  DateRange,
  Update,
  HourglassBottom,
  DescriptionOutlined,
} from '@mui/icons-material'
import {formatDate} from 'Utils/Date'
import {useHistory} from 'react-router-dom'
import {getRoute} from 'Services/I18n/Utils'

const ExpirationValue: FC<{date: string}> = ({date}) => {
  const now = DateTime.now()
  // TODO: Set locale:
  // const expiration = DateTime.fromISO(date).setLocale()
  const expiration = DateTime.fromISO(date)
  const diff = Math.floor(expiration.diff(now, ['days']).days)
  let dayOfTheWeek = expiration.toFormat('cccc')

  if (diff > 7 || expiration < now) {
    return (
      <p className={styles.expirationValueNormal}>
        <FormattedMessage id={dayOfTheWeek} />
        <span className={styles.date}>
          {expiration.toFormat(' (d.L.yyyy)')}
        </span>
      </p>
    )
  }
  if (now.day === expiration.day || diff === 0) {
    return (
      <div className={styles.expirationValueContainer}>
        <p className={styles.expirationValueTextRed}>
          <FormattedMessage id={edt.expiresToday} />
        </p>
      </div>
    )
  }
  return (
    <div className={styles.expirationValueContainer}>
      <p className={styles.expirationValueTextRed}>
        <FormattedMessage id={cdt.in} />
        {` ${diff} `}
        {diff === 1 ? (
          <FormattedMessage id={cdt.day} />
        ) : (
          <FormattedMessage id={cdt.days} />
        )}
      </p>
      <p className={styles.expirationValueText}>
        {expiration.toFormat('(d.L.yyyy)')}
      </p>
    </div>
  )
}

const RejectedValue: FC<{date: string}> = ({date}) => {
  const expiration = DateTime.fromISO(date)

  return (
    <p className={styles.rejectionValue}>
      <span className={styles.date}>{expiration.toFormat('d.M.yyyy')}</span>
    </p>
  )
}
interface ModalInterviewInvitationProps {
  request: IncomingRequestType
  isOpenInterviewInvitation: boolean
  setIsOpenInterviewInvitation: (open: boolean) => void
  interviewInvitationsReject: (payload: string) => void
  interviewInvitationsApprove: (payload: string) => void
  setSpectoProposeNewTime: (values: SpectoProposeNewTimeParamsType) => {}
  setCancelModal?: (open: boolean) => void
}

export const ModalInterviewInvitationEmp: FC<ModalInterviewInvitationProps> = ({
  request,
  isOpenInterviewInvitation,
  setIsOpenInterviewInvitation,
  setSpectoProposeNewTime,
  setCancelModal,
}) => {
  const [modalSendOfferEmp, setModalSendOfferEmp] = useState(false)
  const history = useHistory()
  const noLongerActiveCandidate =
    !request.seeker.hasFinishedPlacementWithCurrentEmployer &&
    request.seeker.rjbProfileStatus === RjbProfileStatus.CLOSED
  return (
    <>
      <Modal
        className={classnames(styles.modalDetails, {
          [styles.modalDetailsWithoutFooter]:
            request.status !== CombinedRequestStatues.NEW_TIME_PROPOSED &&
            request.status !== CombinedRequestStatues.COMPLETED,
        })}
        icon={DescriptionOutlined}
        isOpen={isOpenInterviewInvitation}
        title={cdt.interviewInvitationDetails}
        onAfterClose={() => {
          setIsOpenInterviewInvitation(false)
        }}
        shouldReturnFocusAfterClose={false}
        Footer={
          <>
            {request.status === CombinedRequestStatues.NEW_TIME_PROPOSED && (
              <ThumbsFooter
                thirdBtnText={cdt.proposeNewTime}
                theme="thirdBtn"
                onCancel={() => {
                  setIsOpenInterviewInvitation(false)
                }}
                onThirdBtn={() => {
                  setSpectoProposeNewTime({
                    requestId: request.id,
                    candidateId: request.seeker.userId,
                  })
                  history.push(getRoute(AppRoute.ProposeNewTimeCalendar))
                }}
                disabled={noLongerActiveCandidate}
              />
            )}
            {request.status === CombinedRequestStatues.COMPLETED && (
              <SubmitFooter
                onSubmit={() => setModalSendOfferEmp(true)}
                onCancel={() => setIsOpenInterviewInvitation(false)}
                theme="modalProfileSubmit"
                submitText={edt.sendOffer}
                disabled={noLongerActiveCandidate}
              />
            )}
          </>
        }
      >
        <div className={styles.containerInterviewDetails}>
          <div className={styles.titleContainer}>
            <img
              className={styles.avatar}
              src={
                request.seeker.profilePicture
                  ? request.seeker.profilePicture?.url
                  : ''
              }
              alt={`${request.seeker.firstName} ${request.seeker.lastName}`}
              onError={(event) =>
                ((event.target as HTMLInputElement).style.display = 'none')
              }
            />
            <p className={styles.title}>
              {request.seeker.firstName} {request.seeker.lastName}
            </p>
          </div>

          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <ConnectWithoutContact className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.requestType} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              <p className={styles.videoText}>
                <FormattedMessage id={edt.interviewInvitation} />
              </p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <Notifications className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.requestStatus} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              <p
                className={classnames(styles.pending, {
                  [styles.redStatus]:
                    request.status === InterviewRequestStatus.REJECTED ||
                    request.status === InterviewRequestStatus.CANCELLED ||
                    request.status === InterviewRequestStatus.NOT_ATTENDED,
                  [styles.accepted]:
                    request.status ===
                    InterviewRequestStatus.INTERVIEW_CONFIRMED,
                  [styles.expired]:
                    request.status === InterviewRequestStatus.EXPIRED,
                  [styles.completed]:
                    request.status === InterviewRequestStatus.COMPLETED,
                  [styles.newTimeProposal]:
                    request.status === InterviewRequestStatus.NEW_TIME_PROPOSED,
                })}
              >
                <FormattedMessage
                  id={employerRequestStatusTranslationMap[request.status]}
                />
              </p>
            </div>
          </div>

          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <Update className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.dateOfRequestSent} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              <p className={styles.itemRightText}>
                {request.sentDate && formatDate(request.sentDate)}
              </p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              {request.status === InterviewRequestStatus.REJECTED ? (
                <>
                  <Update className={styles.icon} />
                  <p className={styles.itemLeftText}>
                    {' '}
                    <FormattedMessage id={cdt.rejectionDate} />:
                  </p>
                </>
              ) : (
                <>
                  <HourglassBottom className={styles.icon} />
                  <p className={styles.itemLeftText}>
                    <FormattedMessage id={cdt.expirationDate} />:
                  </p>
                </>
              )}
            </div>
            <div className={styles.itemRight}>
              {request.expirationDate &&
                (request.status === InterviewRequestStatus.REJECTED ? (
                  <RejectedValue date={request.expirationDate} />
                ) : (
                  <ExpirationValue date={request.expirationDate} />
                ))}
            </div>
          </div>
          {request.startDate && request.endDate && (
            <div className={styles.item}>
              <div className={styles.itemLeft}>
                <DateRange className={styles.icon} />
                <p className={styles.dateTimeLeft}>
                  <p>
                    <FormattedMessage id={edt.proposedDate} />
                  </p>
                  <p>
                    &nbsp;& <FormattedMessage id={edt.time} />:
                  </p>
                </p>
              </div>
              <div className={styles.itemRight}>
                <p className={styles.dateTime}>
                  {DateTime.fromISO(request.startDate).toFormat('d.L.yyyy')}
                  <p className={styles.line}>&nbsp;|&nbsp;</p>
                  {`${DateTime.fromISO(request.startDate).toFormat(
                    'HH:mm'
                  )} - ${DateTime.fromISO(request.endDate).toFormat('HH:mm')}`}
                </p>
                {(request.status === InterviewRequestStatus.PENDING ||
                  request.status ===
                    InterviewRequestStatus.INTERVIEW_CONFIRMED) && (
                  <button
                    onClick={() => {
                      setCancelModal && setCancelModal(true)
                      setIsOpenInterviewInvitation(false)
                    }}
                    className={styles.button}
                  >
                    <FormattedMessage id={edt.cancelInterview} />
                  </button>
                )}
              </div>
            </div>
          )}
          {/* TODO specto flow: Add new time proposed when specto adds it */}
          {/* <div className={styles.item}>
            <div className={styles.itemLeft}>
              <DateRange className={styles.icon} />
              <p className={styles.dateTimeLeft}>
                <p>
                  <FormattedMessage id={edt.newProposedDate} /> &nbsp;
                </p>
                <p>
                  & <FormattedMessage id={edt.time} />:
                </p>
              </p>
            </div>
            <div className={styles.itemRight}>
                <p className={styles.dateTime}>
                  {DateTime.fromISO(request.startDate).toFormat('d.L.yyyy')}
                  <p className={styles.line}>&nbsp;|&nbsp;</p>
                  {request.startDate &&
                    `${DateTime.fromISO(request.startDate).toFormat(
                      'hh:mm'
                    )} - ${DateTime.fromISO(request.endDate).toFormat(
                      'hh:mm'
                    )}`}
                </p>
            </div>
          </div> */}
          {(request.seekerReason || request.companyUserReason) && (
            <div className={styles.item}>
              <div className={styles.itemLeft}>
                <HistoryEdu className={styles.icon} />
                <p className={styles.dateTimeLeft}>
                  <FormattedMessage id={edt.message} />
                </p>
              </div>
              <div className={styles.itemRight}>
                <p className={styles.paragraphMessage}>
                  {request.seekerReason || request.companyUserReason}
                </p>
              </div>
            </div>
          )}
        </div>
      </Modal>
      <ModalSendOfferEmp
        isOpen={modalSendOfferEmp}
        setIsOpen={setModalSendOfferEmp}
        requestId={request.journeyId}
      />
    </>
  )
}
