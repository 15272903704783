import classnames from 'classnames'

import styles from './JourneyTracker.module.scss'

interface JourneyStep {
  translation: string
  count?: number
}

interface JourneyTrackerProps {
  steps: JourneyStep[]
  activeStep: string | undefined
}

export const JourneyTracker: React.FC<JourneyTrackerProps> = ({
  steps,
  activeStep,
}) => {
  return (
    <div className={styles.journeyTrackerContainer}>
      <ul>
        {steps.map((step) => {
          const isActive = activeStep === step.translation
          return (
            <li
              key={step.translation}
              className={classnames({[styles.current]: isActive})}
            >
              <div className={styles.content}>
                {step.translation}
                {step.count ? <span> ({step.count})</span> : null}
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
