import {connect} from 'react-redux'

import {Avatar} from './Avatar'

import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  me: state.auth.data,
  profile: state.seeker.data?.full
})

export const AvatarContainer = connect(mapStateToProps, null)(Avatar)
