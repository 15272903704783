import registration from './Registration/sl'
import onboarding from './Onboarding/sl'
import profile from './Profile/sl'
import company from './Company/sl'

const validation = {
  ...registration,
  ...onboarding,
  ...profile,
  ...company,
  'Password is required.': 'Geslo je obvezno.',
  'Password must be 6-20 characters long. It may contain only latin alpha-numeric characters and must contain at least one numeric digit, one uppercase and one lowercase letter.':
    'Geslo mora vsebovati od 6 do 20 znakov. Vsebuje lahko samo latinične alfanumerične znake in mora vsebovati vsaj eno številko, eno veliko in eno malo črko.',
  'Email is invalid': 'Elektronski naslov ni veljaven',
  'Email is required': 'Elektronski naslov je zahtevan',
  'Email is already taken': 'Elektronski naslov že obstaja',
  'First name is required.': 'Ime je obvezno.',
  'Last name is required.': 'Priimek je obvezno',
  'Forbidden access to Assessment': 'Dostop do Testiranj ni dovoljen',
  'Assessment not found': 'Test ne obstaja',
  'Seeker is not approved': 'Uporabnik ni odobren',
  'Only to expired invitations or assessments user can be invited again':
    'Uporabnik je lahko ponovno povabljen na test le, če je potekla veljavnost testa ali vabila',
  'Seeker Assessment combination already exists':
    'Test za tega uporabnika že obstaja',
  'You must accept consents before starting Assessment':
    'Pred začetkom testa je sprejetje soglasja obvezno',
  'Forbidden access to Seeker Assessment':
    'Dostop do Testiranj kandidata ni dovoljen',
  'Seeker Assessment not found': 'Testi kandidata ne obstajajo',
  'Client data is invalid': 'Podatki uporabnika niso veljavni',
  'Grant type is unsupported': 'Tip dodelitve ni podprt',
  'Scope is invalid or unsupported': 'Tip obsega ni veljaven ali podprt',
  'Can`t change status - please log in with supplied email':
    'Statusa ni možno spremeniti - prosimo, vpišite se z veljavnim elektronskim naslovom',
  'Some of the consent change log ids are invalid':
    'Nekateri ID-ji dnevnikov soglasij niso veljavni',
  'Contact data already submitted': 'Kontaktni podatki so že shranjeni',
  'Contact data - onboarding not completed':
    'Kontaktni podatki - registracijski postopek še ni končan',
  'Contact request already approved': 'Zahtevek za kontakt je že odobren',
  'Contact request not approved': 'Zahtevek za kontakt ni odobren',
  'Contact request not pending or extended':
    'Zahtevek za kontakt ni na čakanju ali podaljšan',
  'Employment start is greater than employment end':
    'Datum začetka zaposlitve je manjši kot datum konca zaposlitve',
  'Language header missing': 'Manjka jezik',
  'Tenant header missing': 'Manjka tenant',
  'User header missing': 'Manjka uporabnik',
  'Interview request expired': 'Vabilo na intervju je poteklo',
  'Interview request not expired': 'Vabilo na intervju ni poteklo',
  'Interview request not pending or extended':
    'Vabilo na intervju ni na čakanju ali poteklo',
  'New time proposed not provided': 'Nov predlagan termin ni vnesen',
  'Offer request expired': 'Ponudba je potekla',
  'Offer request not confirmed': 'Ponudba ni sprejeta',
  'Wrong onboarding status': 'Napačen status registracije',
  'Placement already cancelled': 'Zaposlitev je preklicana',
  'Placement already confirmed': 'Zaposlitev je že bila potrjena',
  'Placement already rejected': 'Zaposlitev je bila zavrnjena',
  'Placement not confirmed by seeker':
    'Zaposlitev ni potrjena s strani kandidata',
  'Placement request expired': 'Zahtevek za potrditev zaposlitve je potekel',
  'Postal code - bad format': 'Poštna številka - napačen format',
  'You can subscribe to up to ${data.limit} searches in your Search favorites.':
    'Lahko si shranite do ${data.limit} iskanj v svojih shranjeniih iskanjih',
  'You can show up to ${data.limit} searches in your Search favorites.':
    'Lahko prikažete do ${data.limit} iskanj v svojih shranjenih iskanjih',
  'Query param seekerId missing': 'Manjka iskalni parameter seekerId',
  'Send email to friend wrong "from" email':
    'Pošlji sporočilo prijatelju - napačen "Od" elektronski naslov',
  'Specto get calendar item bad request':
    'Specto dobi napačen zahtevek za element v koledarju',
  'Specto get token bad request': 'Specto dobi napačen zahtevek za token',
  'Company welcome message (companyId - jobCategoryId combination) already exists':
    'Pozdravno sporočilo podjetja (kombinacija ID podjetja & ID Področja dela) že obstaja',
  'Company welcome message translation (Company welcome message - language combination) already exists':
    'Prevod pozdravnega sporočila podjetja (Pozdravno sporočilo & kombinacija jezika) že obstaja',
  'Contact request already exists': 'Zahtevek za kontakt že obstaja',
  'Contact request expired': 'Zahtevek za kontakt je potekel',
  'Contact request not expired': 'Zahtevek za kontakt ni potekel',
  'Interview request already accepted':
    'Vabilo na intervju je že bilo sprejeto',
  'Interview request already exists': 'Vabilo na intervju že obstaja',
  'Interview request already rejected':
    'Vabilo na intervju je že bilo zavrnjeno',
  'Can`t cancel Interview request that has the current status.':
    'Vabila na intervju s tem statusom ni mogoče preklicati',
  'Can`t complete Interview request that has the current status.':
    'Intervju s tem statusom ne more biti zaključen',
  'Can`t propose new time for Interview request that has the current status.':
    'Predlaganje novega termina za intervju ni mogoče za zahtevek v trenutnem statusu',
  'Interview request is not pending approval':
    'Vabilo na intervju ni v statusu "V čakanju"',
  'Offer request already accepted': 'Ponudba je že bila sprejeta',
  'Offer request already exists': 'Ponudba že obstaja',
  'Offer request already rejected': 'Ponudba je že bila zavrnjena',
  'Seeker already has the given occupation':
    'Kandidat že ima izbrano to delovno mesto',
  'User has insufficient scope': 'Uporabnik nima zadostnega obsega podatkov',
  'Forbidden access to Company Question':
    'Dostop do Vprašanj delodajalca ni dovoljen',
  'Forbidden access to Company Video':
    'Dostop do Video vsebin delodajalca ni dovoljen',
  'Company Video - Has too many videos':
    'Video vsebine - naloženih je preveč video vsebin',
  'Forbidden access to Company Welcome Message':
    'Dostop do Pozdravnega sporočila delodajalca ni dovoljen',
  'Forbidden access to company user project':
    'Dostop do Projektov uporabnika delodajalca ni dovoljen',
  'Can not edit profile before approval!':
    'Profila ni mogoče urejati pred odobritvijo',
  'Forbidden access to Saved Search': 'Dostop do Shranjenih iskanj ni dovoljen',
  'Too many attachments, delete some before adding more':
    'Dosegli ste najvišje število dovoljenih priponk, če želite dodati novo, morate eno od obstoječih izbrisati',
  'Forbidden access to seeker attachment':
    'Dostop do kandidatovih priponk ni dovoljen',
  'Forbidden access to seeker award':
    'Dostop do kandidatovih Nagrad ni dovoljen',
  'Forbidden access to seeker DrivingLicense':
    'Dostop do kandidatovih Vozniških dovoljenj ni dovoljen',
  'Forbidden access to seeker education':
    'Dostop do kandidatove Izobrazbe ni dovoljen',
  'Forbidden access to seeker language':
    'Dostop do kandidatovih Jezikov ni dovoljen',
  'Forbidden access to seeker project':
    'Dostop do kandidatovih Projektov ni dovoljen',
  'Forbidden access to seeker skill':
    'Dostop do kandidatovih Znanj ni dovoljen',
  'Forbidden access to seeker training':
    'Dostop do kandidatovih Izobraževanj ni dovoljen',
  'Specto connect users forbidden': 'Specto povezava z uporabniki ni dovoljena',
  'Specto delete user forbidden': 'Specto brisanje uporabnikov ni dovoljeno',
  'Specto update user forbidden': 'Specto posodobitev uporabnikov ni dovoljeno',
  'Forbidden resource for user.': 'Dostop do vira za uporabnika ni dovoljen',
  'Specto create user not acceptable':
    'Specto kreiranje uporabnika ni bilo sprejeto',
  'Specto get token not acceptable':
    'Specto pridobivanje žetona ni bilo sprejeto',
  'Specto get user calendar not acceptable':
    'Specto pridobivanje koledarja uporabnika ni bilo sprejeto',
  'Additional benefit not found': 'Dodatne ugodnosti ne obstajajo',
  'Availability not found': 'Razpoložljivost ni najdena',
  'Blacklist item not found': 'Element na Črnem seznamu ni bil najden',
  'Career level not found': 'Karierni nivo ni bil najden',
  'Company Impression not found': 'Slika ne obstaja',
  "Company Impression can't have same file attached to impression":
    'Ni mogoče pripeti enake slike',
  'Company not found': 'Podjetje ne obstaja',
  'Company question not found': 'Vprašanje podjetja ne obstaja',
  'Company user not found': 'Uporabnik podjetja ne obstaja',
  'Company Video not found': 'Video podjetja ne obstaja',
  'Company Welcome Message not found':
    'Pozdravno sporočilo podjetja ne obstaja',
  'Company seeker journey not found': 'Pot do zaposlitve ne obstaja',
  'Company seeker journey contact request not found':
    'Zahtevek za kontakt ne obstaja',
  'Company seeker journey offer request not found': 'Ponudba ne obstaja',
  'Company seeker journey placement request not found': 'Zaposlitev ne obstaja',
  'Company user project not found': 'Projekt ne obstaja',
  'Company user seeker project not found': 'Projekt ne obstaja',
  'Consent not found': 'Soglasje ne obstaja',
  'Contact request already rejected': 'Zahtevek za kontakt je že bil zavrnjen',
  'Contact request not found': 'Zahtevek za kontakt ne obstaja',
  'Country not found': 'Država ne obstaja',
  'Education institution not found': 'Izobraževalna ustanova ne obstaja',
  'Education level not found': 'Stopnja izobrazbe ne obstaja',
  'Employer not found': 'Delodajalec ne obstaja',
  'Employment Type not found': 'Vrsta zaposlitve ne obstaja',
  'Faq groups not found': 'FAQ skupina ne obstaja',
  'Faq items not found': 'FAQ elementi ne obstjajo',
  'Field of study not found': 'Področje izobrazbe ne obstaja',
  'File not found': 'Datoteka ne obstaja',
  'Identity not found': 'Identiteta ne obstaja',
  'Interview request not found': 'Vabilo na razgovor ne obstaja',
  'Job category not found': 'Področje dela ne obstaja',
  'Job search status not found': 'Status iskanja zaposlitve ne obstaja',
  'Language level not found': 'Nivo jezika ne obstaja',
  'Language not found': 'Jezik ne obstaja',
  'Language taxonomy not found': 'Šifrant jezikov ne obstaja',
  'Occupation not found': 'Delovno mesto ne obstaja',
  'Region not found': 'Regija ne obstaja',
  'Request not found': 'Zahtevek ne obstaja',
  'Salary not found': 'Plača ne obstaja',
  'Saved Search not found': 'Shranjeno iskanje ne obstaja',
  'Seeker attachment not found': 'Priponka ne obstaja',
  'Seeker award not found': 'Nagrada ne obstaja',
  'Seeker Driving License not found': 'Vozniško dovoljenje ne obstaja',
  'Seeker education not found': 'Izobrazba ne obstaja',
  'Seeker language title not found': 'Jezik ne obstaja',
  'Seeker language test not found': 'Test jezika ne obstaja',
  'Seeker not found': 'Kandidat ne obstaja',
  'Seeker Occupation Experience not found': 'Delovne izkušnje ne obstajajo',
  'Seeker project not found': 'Projekt ne obstaja',
  'Seeker skill not found': 'Znanje ne obstaja',
  'Seeker training not found': 'Izobraževanje ne obstaja',
  'Forbidden access to seeker work experience':
    'Dostop do Delovnih izkušenj kandidata ni dovoljen',
  'Seeker work experience not found': 'Delovne izkušnje ne obstajajo',
  'Seeker evaluation not found': 'Ocena kandidata ne obstaja',
  'Skill level not found': 'Nivo znanja ne obstaja',
  'Skill not found': 'Znanje ne obstaja',
  'Specto calendar item not found': 'Zapis na koledarju ne obstaja',
  'Specto token not found': 'Žeton ne obstaja',
  'Specto user calendar item not found':
    'Zapis koledarja za tega uporabnika ne obstaja',
  'Specto user data not found': 'Podatki o uporabniku ne obstajajo',
  'Specto user not found': 'Uporabnik ne obstaja',
  'Language for tenant not found': 'Jezik za portal ne obstaja',
  'Tenant not found': 'Portal ne obstaja',
  'Testimonials not found': 'Izjave uporabnikov ne obstajajo',
  'Total work experience not found': 'Skupno let delovnih izkušenj ni najdeno',
  'Town not found': 'Mesto ne obstaja',
  'Training title not found': 'Naziv izobraževanja ne obstaja',
  'Transactional email not found': 'Transakcijski email ne obstaja',
  'User not found': 'Uporabnik ne obstaja',
  'Work from home not found': 'Delo od doma ne obstaja',
  'Script tags found in HTML': 'Script tags najdeni v HTML',
  'Access token is missing or invalid': 'Manjka žeton za dostop oz ni veljaven',
  'User is not authorized': 'Uporabnik ni pooblaščen',
  'Wrong email or password': 'Napačen email ali geslo',
  'Wrong password': 'Napačno geslo',
  'Your attachment is in a wrong format. Please use: docx, pdf, jpeg, gif':
    'Vaša priponka ni v ustreznem formatu. Prosimo uporabite: docx, pdf, jpeg, gif',
  'Your CV is not in correct format...':
    'Vaš življenjepis ni v ustreznem formatu',
  'Validation Exception': 'Napaka pri preverjanju',
  'Supported file formats: .doc, .docx, .pdf. (Max size: 5 MB)':
    'Podprti formati datotek: .doc, .docx, .pdf. (Maks. velikost: 5 MB)',
  'Supported file formats: .jpg, .jpeg, .png, .gif. (Max size: 5 MB)':
    'Podprti formati datotek: .jpg, .jpeg, .png, .gif. (Maks. velikost: 5 MB)',
  "Employer doesn't exists.": 'Delodajalec ne obstaja',
  "Seeker doesn't exists.": 'Ta kandidat ne obstaja',
  'Test provider is required': 'Ponudnik testa je obvezen',
  'Test name is required': 'Naziv testa je obvezen',
  'Test taken date is required': 'Datum opravljanja testa je obvezen',
  'Skill test date can not be set in the future':
    'Datum testa ne sme biti nastavljen v prihodnosti',
  'Language test date can not be set in the future':
    'Datum testa ne sme biti nastavljen v prihodnosti',
  'Expiration date can not be before date of test':
    'Datum poteka veljavnosti ne mora biti manjši kot datum testa',
  'Language name is required': 'Polje Jezik je obvezno',
  'Language level is required': 'Polje Nivo jezika je obvezno',
  'Your attachment is in a wrong format. Please use: .doc, .docx, .pdf, .ppt, .pptx, .jpg, .jpeg, .png, .gif':
    'Datoteka, ki ste jo poskušali naložiti, je v napačnem formatu. Prosimo vas, da naložite .doc, .docx, .pdf, .ppt, .pptx, .jpg, .jpeg, .png, .gif',
  'Network error': 'Omrežna napaka',
}

export default validation
