import {connect} from 'react-redux'

import {HiringPlan} from './HiringPlan'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {
  getJobCategories,
  getRegions,
  getOccupations,
  getSalaries,
  getTotalWorkExperiences,
  getEducationLevels,
  getFieldsOfStudy,
  saveStepHiringPlan,
  saveStepPartialHiringPlan,
  setEmployerOnboardingStep,
  getNumberOfRecruits,
} from 'Store/Actions'
import {
  HiringPlanSaveType,
  SavePartialHiringPlanType,
} from 'Interfaces/EmployerOnboardingType'

const mapStateToProps = (state: RootState) => ({
  taxonomies: state.taxonomies,
  employerOnboarding: state.employerOnboarding,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getTaxonomies: () => {
    dispatch(getOccupations())
    dispatch(getRegions())
    dispatch(getJobCategories())
    dispatch(getNumberOfRecruits())
    dispatch(getSalaries())
    dispatch(getTotalWorkExperiences())
    dispatch(getEducationLevels())
    dispatch(getFieldsOfStudy())
  },
  saveStepHiringPlan: (saveValues: HiringPlanSaveType, step?: number) =>
    dispatch(saveStepHiringPlan(saveValues, step)),
  saveStepPartialHiringPlan: (values: SavePartialHiringPlanType) =>
    dispatch(saveStepPartialHiringPlan(values)),
  setEmployerOnboardingStep: (n: number) =>
    dispatch(setEmployerOnboardingStep(n)),
})

export const HiringPlanContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HiringPlan)
