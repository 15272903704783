import {FC, useEffect, useMemo, useState} from 'react'
import {useFormik} from 'formik'
import {cloneDeep, isEqual} from 'lodash'

import {EmployerOnboardingTranslations as eonb} from 'Services/I18n/Constants'
import styles from './HiringPlan.module.scss'
import {
  BaseTaxonomyType,
  EmployerOnboardingStateType,
  EmployerOnboardingStatus,
  HiringPlanFormikType,
  HiringPlanSaveType,
  SavePartialHiringPlanType,
  TaxonomiesStateType,
} from 'Interfaces'
import TypeAheadImproved, {ItemType} from 'Components/Inputs/TypeAheadImproved'
import Hr from 'Components/Hr'
import Title from 'Components/Title'
import {useApiError} from 'Hooks'
import {SetValuesProps} from 'Utils/FormikProps'
import CheckboxGroup from 'Components/Inputs/CheckboxGroup'
import Dropdown from 'Components/Inputs/Dropdown'
import {ValidationBack, ValidationNext} from './Validation'
import Buttons from 'Components/Onboarding/Buttons'
import AreYouSureModal from 'Components/Onboarding/AreYouSureModal'
import SectionHeader from 'Components/Profile/SectionHeader'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'

interface HiringPlanProps {
  employerOnboarding: EmployerOnboardingStateType
  taxonomies: TaxonomiesStateType
  getTaxonomies: () => void
  saveStepHiringPlan: (saveValues: HiringPlanSaveType, step?: number) => void
  saveStepPartialHiringPlan: (values: SavePartialHiringPlanType) => void
  setEmployerOnboardingStep: (step: number) => void
}

export const HiringPlan: FC<HiringPlanProps> = ({
  employerOnboarding,
  taxonomies,
  saveStepHiringPlan,
  saveStepPartialHiringPlan,
  getTaxonomies,
  setEmployerOnboardingStep,
}) => {
  useEffect(() => {
    if (!employerOnboarding.loading && !employerOnboarding.error)
      getTaxonomies()
  }, [employerOnboarding.loading])

  const defaultValues: HiringPlanFormikType = {
    numberOfRecruits: employerOnboarding.data?.numberOfRecruits || null,
    salary: employerOnboarding.data?.salary || null,
    jobCategories: employerOnboarding.data?.jobCategories || [],
    regions: employerOnboarding.data?.regions || [],
    educationOfCandidates:
      employerOnboarding.data?.educationOfCandidates &&
      employerOnboarding.data.educationOfCandidates.length > 0
        ? employerOnboarding.data.educationOfCandidates
        : [],
    yearsOfExperiencePerPosition:
      employerOnboarding.data?.yearsOfExperiencePerPosition &&
      employerOnboarding.data.yearsOfExperiencePerPosition.length > 0
        ? employerOnboarding.data.yearsOfExperiencePerPosition.map((yoepp) => ({
            salary: yoepp.salary,
            totalWorkExperience: yoepp.totalWorkExperience,
            occupation: {
              checked: true,
              ...(yoepp.occupation as BaseTaxonomyType),
            },
          }))
        : [],
  }

  const packSave = (values: HiringPlanFormikType): HiringPlanSaveType => ({
    salaryId: values.salary?.id,
    numberOfRecruitsId: values.numberOfRecruits?.id,
    jobCategoriesIds: values.jobCategories.length
      ? values.jobCategories.map((j) => j.id)
      : undefined,
    regions: values.regions.map((r) => ({id: r.id})),
    educationOfCandidates: values.educationOfCandidates
      .filter((e) => !e.hasOwnProperty('empty'))
      .map((e) => ({
        educationLevelId: e.educationLevel?.id,
        fieldOfStudyId: e.fieldOfStudy?.id,
      })),
    yearsOfExperiencePerPosition: values.yearsOfExperiencePerPosition
      .filter((y) => !y.hasOwnProperty('empty'))
      .map((y) => ({
        yearsOfExperienceId: y.totalWorkExperience?.id as string,
        salaryRangeId: y.salary?.id as string,
        position: y.occupation?.id.includes('newValue~')
          ? {name: y.occupation.translation}
          : {id: y.occupation?.id as string},
      })),
  })

  const formikNext = useFormik({
    initialValues: defaultValues,
    validationSchema: ValidationNext,
    onSubmit: (formikValues) => {
      saveStepHiringPlan(packSave(formikValues))
    },
  })
  const formikBack = useFormik({
    initialValues: defaultValues,
    validationSchema: ValidationBack,
    onSubmit: (values) => {
      saveStepPartialHiringPlan(packSave(values))
    },
  })
  const [modalOpen, setModalOpen] = useState(false)

  const memoOccupations = useMemo(() => {
    return taxonomies.occupations.data.map((o) => {
      return {
        ...o,
        checked: formikNext.values?.yearsOfExperiencePerPosition.every(
          (yo) => yo.occupation?.id === o.id
        ),
      }
    })
  }, [
    taxonomies.occupations.data,
    formikNext.values.yearsOfExperiencePerPosition,
  ])

  const stepBack = () => {
    if (
      formikNext.dirty &&
      employerOnboarding.data?.onboardingStatus ===
        EmployerOnboardingStatus.TWELVEMONTHHIRINGPLAN
    ) {
      formikBack.validateForm(formikNext.values).then((errors) => {
        if (Object.keys(errors).length) {
          formikBack.submitForm()
          setTimeout(() => {
            formikBack.setFormikState({
              ...formikBack,
              errors: errors,
              submitCount: 1,
            })
          }, 0)
        } else {
          const newValues = packSave(cloneDeep(formikNext.values))
          Object.keys(newValues).forEach((key) => {
            if (
              newValues[key] === '' ||
              (typeof newValues[key] !== 'string' &&
                (!newValues[key] || newValues[key].length === 0))
            ) {
              delete newValues[key]
            }
          })
          saveStepPartialHiringPlan(newValues)
        }
      })
    } else {
      //check changes if there are none, just set step
      if (formikNext.dirty) {
        setModalOpen(true)
      } else {
        setEmployerOnboardingStep(1)
      }
    }
  }
  const handleModalSubmit = () => {
    setEmployerOnboardingStep(1)
  }
  const handleModalCancel = () => {
    formikNext.validateForm(formikNext.values).then((errors) => {
      if (Object.keys(errors).length) {
        formikNext.submitForm()
      } else {
        saveStepHiringPlan(packSave(formikNext.values), 1)
      }
    })

    setModalOpen(false)
  }

  useApiError(formikNext.setFieldError, employerOnboarding.error)

  const setDropdownField = (name: string, value?: string | number) => {
    const taxName = name === 'salary' ? 'salaries' : name
    const dropdownValue = taxonomies[taxName].data.find((c) => {
      return typeof value === 'string' && c.id === value
    })
    formikNext.setFieldValue(name, dropdownValue)
  }

  const setCheckboxField = (name: string, value?: string | string[]) => {
    const checkboxValues = taxonomies[name].data.filter((c) => {
      return (value as string[]).includes(c.id)
    })
    formikNext.setFieldValue(name, checkboxValues)
  }

  const setEducationDropdown = (
    name: string,
    index: number,
    value?: string | number
  ) => {
    const dropdownValue = taxonomies[
      name === 'educationLevel' ? 'educationLevels' : 'fieldsOfStudy'
    ].data.find((c) => c.id === value)
    const newValues = cloneDeep(formikNext.values.educationOfCandidates)
    newValues[index][name] = dropdownValue
    if (newValues[index].empty) delete newValues[index].empty
    formikNext.setFieldValue('educationOfCandidates', newValues)
  }

  const setPositionDropdown = (
    name: string,
    index: number,
    value?: string | number
  ) => {
    const dropdownValue = taxonomies[
      name === 'salary' ? 'salaries' : 'workExperiences'
    ].data.find((c) => c.id === value)
    const newValues = cloneDeep(formikNext.values.yearsOfExperiencePerPosition)
    newValues[index][name] = dropdownValue
    if (newValues[index].empty) delete newValues[index].empty
    formikNext.setFieldValue('yearsOfExperiencePerPosition', newValues)
  }

  const setPositionTypeahead = (
    name: string,
    index: number,
    value?: ItemType[]
  ) => {
    const newValues = cloneDeep(formikNext.values.yearsOfExperiencePerPosition)
    newValues[index][name] = value ? value[0] : {empty: true}
    const occupationsOne = formikNext.values.yearsOfExperiencePerPosition.map(
      (yo) => yo.occupation?.id
    )
    const occupationsTwo = newValues.map((yo) => yo.occupation?.id)
    if (!isEqual(occupationsOne, occupationsTwo)) {
      formikNext.setFieldValue('yearsOfExperiencePerPosition', newValues)
    }
  }

  const addEducation = () => {
    formikNext.setFieldValue('educationOfCandidates', [
      ...formikNext.values.educationOfCandidates,
      {
        empty: true,
      },
    ])
  }
  const addPosition = () => {
    formikNext.setFieldValue('yearsOfExperiencePerPosition', [
      ...formikNext.values.yearsOfExperiencePerPosition,
      {
        empty: true,
      },
    ])
  }

  const removeEducation = () => {
    formikNext.setFieldValue('educationOfCandidates', [
      ...formikNext.values.educationOfCandidates.slice(0, -1),
    ])
  }

  const removePosition = () => {
    formikNext.setFieldValue('yearsOfExperiencePerPosition', [
      ...formikNext.values.yearsOfExperiencePerPosition.slice(0, -1),
    ])
  }

  return (
    <div className={styles.container}>
      <form onSubmit={formikNext.handleSubmit}>
        <div>
          <Title
            text={eonb.titleEmploymentPlan}
            weight={400}
            className={styles.title}
          />
          <Dropdown
            name="numberOfRecruits"
            items={taxonomies.numberOfRecruits?.data || []}
            withRadio
            setValue={setDropdownField}
            value={formikNext.values.numberOfRecruits?.id}
            error={
              formikNext.submitCount && formikNext.errors.numberOfRecruits
                ? formikNext.errors.numberOfRecruits
                : undefined
            }
          />
          <Hr className={styles.hr} />
        </div>
        <div>
          <Title
            text={eonb.titleOccupationalFieldsCandidates}
            weight={400}
            className={styles.title}
          />
          <CheckboxGroup
            name="jobCategories"
            items={taxonomies.jobCategories.data}
            minRows={3}
            multiple
            {...SetValuesProps(formikNext, 'jobCategories')}
            values={formikNext.values.jobCategories.map((j) => j.id)}
            setValues={setCheckboxField}
            className={styles.categories}
          />

          <Hr className={styles.hr2} />
        </div>
        <div>
          <Title
            text={eonb.titleSalaryRange}
            weight={400}
            className={styles.title}
          />
          <Dropdown
            name="salary"
            items={taxonomies.salaries?.data || []}
            withRadio
            setValue={setDropdownField}
            value={formikNext.values.salary?.id}
            error={
              formikNext.submitCount && formikNext.errors.salary
                ? formikNext.errors.salary
                : undefined
            }
          />
          <Hr className={styles.hr} />
        </div>
        <div>
          <Title text={eonb.titleRegions} onboarding className={styles.title} />
          <CheckboxGroup
            name="regions"
            items={taxonomies.regions.data}
            multiple
            {...SetValuesProps(formikNext, 'regions')}
            values={formikNext.values.regions.map((j) => j.id)}
            setValues={setCheckboxField}
          />
          <Hr className={styles.hr2} />
        </div>
        <div>
          <SectionHeader
            title={eonb.titleYearsOfExp}
            className={styles.title}
            onAdd={addPosition}
          />
          {formikNext.values.yearsOfExperiencePerPosition.map(
            (yoepp, index) => {
              return (
                <div className={styles.yearsOfExpContainer} key={index}>
                  <div className={styles.yearsOfExpItem}>
                    <TypeAheadImproved
                      name={`occupation-${index}`}
                      theme="likeDropdown"
                      items={memoOccupations}
                      label={eonb.typeaheadCandidatesPos}
                      placeholder={eonb.typeaheadCandidatesPosPlaceholder}
                      maxNumSelections={1}
                      selectNonExisting
                      defaultValue={yoepp.occupation ? [yoepp.occupation] : []}
                      setValue={(_, value) => {
                        setPositionTypeahead('occupation', index, value)
                      }}
                      error={
                        (formikNext.submitCount &&
                        formikNext.errors.yearsOfExperiencePerPosition &&
                        formikNext.errors.yearsOfExperiencePerPosition[index] &&
                        formikNext.errors.yearsOfExperiencePerPosition[index][
                          'occupation'
                        ]
                          ? formikNext.errors.yearsOfExperiencePerPosition[
                              index
                            ]['occupation']['id']
                          : undefined) ||
                        (formikBack.submitCount &&
                        formikBack.errors.yearsOfExperiencePerPosition &&
                        formikBack.errors.yearsOfExperiencePerPosition[index] &&
                        formikBack.errors.yearsOfExperiencePerPosition[index][
                          'occupation'
                        ]
                          ? formikBack.errors.yearsOfExperiencePerPosition[
                              index
                            ]['occupation']['id']
                          : undefined)
                      }
                    />
                  </div>
                  <div className={styles.yearsOfExpItem}>
                    <div className={styles.yearsOfExpSubItem1}>
                      <Dropdown
                        name={`totalWorkExperience-${index}`}
                        label={eonb.positionYearsOfExp}
                        items={taxonomies.workExperiences.data}
                        withRadio
                        value={yoepp.totalWorkExperience?.id}
                        setValue={(_, value) => {
                          setPositionDropdown(
                            'totalWorkExperience',
                            index,
                            value
                          )
                        }}
                        error={
                          (formikNext.submitCount &&
                          formikNext.errors.yearsOfExperiencePerPosition &&
                          formikNext.errors.yearsOfExperiencePerPosition[
                            index
                          ] &&
                          formikNext.errors.yearsOfExperiencePerPosition[index][
                            'totalWorkExperience'
                          ]
                            ? formikNext.errors.yearsOfExperiencePerPosition[
                                index
                              ]['totalWorkExperience']['id']
                            : undefined) ||
                          (formikBack.submitCount &&
                          formikBack.errors.yearsOfExperiencePerPosition &&
                          formikBack.errors.yearsOfExperiencePerPosition[
                            index
                          ] &&
                          formikBack.errors.yearsOfExperiencePerPosition[index][
                            'totalWorkExperience'
                          ]
                            ? formikBack.errors.yearsOfExperiencePerPosition[
                                index
                              ]['totalWorkExperience']['id']
                            : undefined)
                        }
                      />
                    </div>
                    <div className={styles.yearsOfExpSubItem2}>
                      <Dropdown
                        name={`salary-${index}`}
                        label={eonb.positionSalaryRange}
                        items={taxonomies.salaries.data}
                        withRadio
                        value={yoepp.salary?.id}
                        setValue={(_, value) => {
                          setPositionDropdown('salary', index, value)
                        }}
                        error={
                          (formikNext.submitCount &&
                          formikNext.errors.yearsOfExperiencePerPosition &&
                          formikNext.errors.yearsOfExperiencePerPosition[
                            index
                          ] &&
                          formikNext.errors.yearsOfExperiencePerPosition[index][
                            'salary'
                          ]
                            ? formikNext.errors.yearsOfExperiencePerPosition[
                                index
                              ]['salary']['id']
                            : undefined) ||
                          (formikBack.submitCount &&
                          formikBack.errors.yearsOfExperiencePerPosition &&
                          formikBack.errors.yearsOfExperiencePerPosition[
                            index
                          ] &&
                          formikBack.errors.yearsOfExperiencePerPosition[index][
                            'salary'
                          ]
                            ? formikBack.errors.yearsOfExperiencePerPosition[
                                index
                              ]['salary']['id']
                            : undefined)
                        }
                      />
                    </div>
                    <div className={styles.yearsOfExpSubItem3}>
                      <button type="button" onClick={removePosition}>
                        <DeleteOutlineIcon />
                      </button>
                    </div>
                  </div>
                </div>
              )
            }
          )}
          <Hr className={styles.hr} />
        </div>
        <div>
          <SectionHeader
            title={eonb.titleEducation}
            className={styles.title}
            onAdd={addEducation}
          />
          {formikNext.values.educationOfCandidates.map((eoc, index) => {
            return (
              <div className={styles.educationContainer} key={index}>
                <div className={styles.educationItem}>
                  <Dropdown
                    name={`educationLevel-${index}`}
                    label={eonb.typeaheadDegree}
                    items={taxonomies.educationLevels.data}
                    withRadio
                    value={eoc.educationLevel?.id}
                    setValue={(_, value) => {
                      setEducationDropdown('educationLevel', index, value)
                    }}
                    error={
                      (formikNext.submitCount &&
                      formikNext.errors.educationOfCandidates &&
                      formikNext.errors.educationOfCandidates[index] &&
                      formikNext.errors.educationOfCandidates[index][
                        'educationLevel'
                      ]
                        ? formikNext.errors.educationOfCandidates[index][
                            'educationLevel'
                          ]['id']
                        : undefined) ||
                      (formikBack.submitCount &&
                      formikBack.errors.educationOfCandidates &&
                      formikBack.errors.educationOfCandidates[index] &&
                      formikBack.errors.educationOfCandidates[index][
                        'educationLevel'
                      ]
                        ? formikBack.errors.educationOfCandidates[index][
                            'educationLevel'
                          ]['id']
                        : undefined)
                    }
                  />
                </div>
                <div className={styles.educationItem}>
                  <Dropdown
                    name={`fieldOfStudy-${index}`}
                    label={eonb.typeaheadFieldOfStudy}
                    items={taxonomies.fieldsOfStudy.data}
                    withRadio
                    value={eoc.fieldOfStudy?.id}
                    setValue={(_, value) => {
                      setEducationDropdown('fieldOfStudy', index, value)
                    }}
                    error={
                      (formikNext.submitCount &&
                      formikNext.errors.educationOfCandidates &&
                      formikNext.errors.educationOfCandidates[index] &&
                      formikNext.errors.educationOfCandidates[index][
                        'fieldOfStudy'
                      ]
                        ? formikNext.errors.educationOfCandidates[index][
                            'fieldOfStudy'
                          ]['id']
                        : undefined) ||
                      (formikBack.submitCount &&
                      formikBack.errors.educationOfCandidates &&
                      formikBack.errors.educationOfCandidates[index] &&
                      formikBack.errors.educationOfCandidates[index][
                        'fieldOfStudy'
                      ]
                        ? formikBack.errors.educationOfCandidates[index][
                            'fieldOfStudy'
                          ]['id']
                        : undefined)
                    }
                  />
                </div>
                <div className={styles.educationItem2}>
                  <button type="button" onClick={removeEducation}>
                    <DeleteOutlineIcon />
                  </button>
                </div>
              </div>
            )
          })}
          <Hr className={styles.hr} />
        </div>

        <div className={styles.buttonWrap}></div>
        <Buttons onBack={stepBack} />
      </form>
      <AreYouSureModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleModalSubmit={handleModalSubmit}
        handleModalCancel={handleModalCancel}
      />
    </div>
  )
}
