import {connect} from 'react-redux'

import {AddRemoveCandidateToProject} from './AddRemoveCandidateToProject'
import {AppDispatch} from 'Store'
import {
  addCandidateToNewProject,
  removeCandidateFromProjects,
  getAllMyProjects,
  addCandidateToProjects,
  removeCandidateFromAllProjects,
} from 'Store/Actions'
import {
  AddCandidateToProjectType,
  RemoveCandidateFromProjectsType,
} from 'Interfaces'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  allProjects: state.myProjects.allProjects.data,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addCandidateToNewProject: (value: AddCandidateToProjectType) =>
    dispatch(addCandidateToNewProject(value)),
  addCandidateToProjects: (value: AddCandidateToProjectType) =>
    dispatch(addCandidateToProjects(value)),
  removeCandidateFromProjects: (value: RemoveCandidateFromProjectsType) =>
    dispatch(removeCandidateFromProjects(value)),
  removeCandidateFromAllProjects: (value: RemoveCandidateFromProjectsType) =>
    dispatch(removeCandidateFromAllProjects(value)),
  getAllMyProjects: () => dispatch(getAllMyProjects()),
})

export const AddRemoveCandidateToProjectContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddRemoveCandidateToProject)
