const registration = {
  'Enter your first name.': 'Vnesite vaše ime.',
  'Enter your last name.': 'Vnesite vaš priimek.',
  'Passwords must match': 'Gesli se morata ujemati',
  'Accepting Terms of service is required':
    'Sprejetje Splošnih pogojev je obvezno',
  'Accepting Privacy policy is required':
    'Sprejetje Politike zasebnosti je obvezno',
}

export default registration
