import * as type from 'Store/types'
import {
  ActionType,
  CandidateProfileCompletenessStateType,
  ErrorType,
  CandidateProfileCompletenessDataType,
} from 'Interfaces'

const initialState = {
  data: {
    value: undefined,
    fields: {
      aboutMe: false,
      city: false,
      country: false,
      dateOfBirth: false,
      drivingLicense: false,
      education: false,
      psychometricTest: false,
      cognitiveTest: false,
      firstName: false,
      jobPreferences: false,
      lastName: false,
      postalCode: false,
      profilePicture: false,
      skills: false,
      streetAddress: false,
      workExperiences: false,
    },
  },
  loading: false,
  error: undefined,
}

const CandidateProfileCompletenessReducer = (
  state: CandidateProfileCompletenessStateType = initialState,
  action: ActionType<
    CandidateProfileCompletenessDataType | ErrorType | string | undefined
  >
): CandidateProfileCompletenessStateType => {
  switch (action.type) {
    case type.candidate.completeness.requested:
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case type.candidate.completeness.succeeded:
      return {
        ...state,
        data: {
          ...state.data,
          ...(action.payload as CandidateProfileCompletenessDataType),
        },
        loading: false,
        error: undefined,
      }
    case type.candidate.completeness.failed:
      return {
        ...state,
        loading: false,
        error: action.payload as ErrorType,
      }
    default:
      return state
  }
}

export default CandidateProfileCompletenessReducer
