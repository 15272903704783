import {FC} from 'react'
import {LabelsGroupType} from 'Interfaces/BaseType'
import classnames from 'classnames'
import styles from './LabelsGroup.module.scss'

export const LabelsGroup: FC<LabelsGroupType> = ({labels, className}) => {
  const renderLabels = () => {
    return labels.map((label?: string) => {
      return label ? (
        <p key={label} className={classnames(styles.labelItemFixedWidth)}>
          {label}
        </p>
      ) : null
    })
  }
  return (
    <div className={classnames(styles.labelsGroupContainer, className)}>
      {renderLabels()}
    </div>
  )
}
