import * as type from 'Store/types'
import {
  ActionType,
  ErrorType,
  EmployerOnboardingStateType,
  EmployerOnboardingType,
} from 'Interfaces'

const initialState = {
  data: undefined,
  loading: false,
  error: undefined,
}

const EmployerOnboardingReducer = (
  state: EmployerOnboardingStateType = initialState,
  action: ActionType<EmployerOnboardingType | ErrorType | number>
): EmployerOnboardingStateType => {
  switch (action.type) {
    case type.employerOnboarding.getEmployerOnboarding.requested:
    case type.employerOnboarding.stepCompanyContactInfo.requested:
    case type.employerOnboarding.stepHiringPlan.requested:
    case type.employerOnboarding.stepPartialHiringPlan.requested:
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case type.employerOnboarding.getEmployerOnboarding.succeeded:
    case type.employerOnboarding.stepCompanyContactInfo.succeeded:
    case type.employerOnboarding.stepHiringPlan.succeeded:
    case type.employerOnboarding.stepPartialHiringPlan.succeeded:
      return {
        data: {...state.data, ...(action.payload as EmployerOnboardingType)},
        loading: false,
        error: undefined,
      }
    case type.employerOnboarding.getEmployerOnboarding.failed:
    case type.employerOnboarding.stepCompanyContactInfo.failed:
    case type.employerOnboarding.stepHiringPlan.failed:
    case type.employerOnboarding.stepPartialHiringPlan.failed:
      return {
        ...state,
        loading: false,
        error: action.payload as ErrorType,
      }
    case type.employerOnboarding.setEmployerStep.requested:
      return {
        ...state,
        // @ts-ignore
        data: {...state.data, onboardingStep: action.payload as number},
      }

    default:
      return state
  }
}

export default EmployerOnboardingReducer
