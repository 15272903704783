export interface IChannel {
  id: number
  tenantId: string
  channelId: string
  host: string
  type: string
  name?: string
  tenant?: string
  defaultLanguage?: {
    id: string
    shortcode: string
  }
  languages: {
    [key: string]: string
  }
  didomiApiKey?: string
  gtmId?: string
  selbyMillsPostUrls: {
    [lang: string]: string
  }
  spectoCalendarUrl: string
  sessionTimeout: number
  candidateSearchPageSize: number
}

export const CHANNELS: IChannel[] = [
  {
    id: 1,
    tenantId: '8b8a6066-bcb1-4686-bc62-372eff5933a9',
    channelId: '876cb91c-fb79-40bc-a2cf-807dcd81bff0',
    host: 'localhost',
    name: 'LocalDevRJB',
    type: 'RJB',
    tenant: 'Alterset',
    defaultLanguage: {
      id: 'a9abab44-c298-4399-9ee1-57e433c6e26e',
      shortcode: 'sl',
    },
    languages: {
      sl: 'a9abab44-c298-4399-9ee1-57e433c6e26e',
      en: '1f67628c-4b5e-4bf5-b28a-4cbf19da8818',
    },
    selbyMillsPostUrls: {
      sl: 'http://preferenca-slo.smtesting.co.uk/',
      en: 'http://preferenca-eng.smtesting.co.uk/',
    },
    spectoCalendarUrl: 'https://stg-calendar.preferenca.si/calendar',
    sessionTimeout: 3600000,
    candidateSearchPageSize: 100,
  },
  {
    id: 2,
    tenantId: '8b8a6066-bcb1-4686-bc62-372eff5933a9',
    channelId: '876cb91c-fb79-40bc-a2cf-807dcd81bff0',
    host: '127.0.0.1',
    name: 'LocalDevRJB 127',
    type: 'RJB',
    tenant: 'Alterset',
    defaultLanguage: {
      id: 'a9abab44-c298-4399-9ee1-57e433c6e26e',
      shortcode: 'sl',
    },
    languages: {
      sl: 'a9abab44-c298-4399-9ee1-57e433c6e26e',
      en: '1f67628c-4b5e-4bf5-b28a-4cbf19da8818',
    },
    selbyMillsPostUrls: {
      sl: 'http://preferenca-slo.smtesting.co.uk/',
      en: 'http://preferenca-eng.smtesting.co.uk/',
    },
    spectoCalendarUrl: 'https://stg-calendar.preferenca.si/calendar',
    sessionTimeout: 3600000,
    candidateSearchPageSize: 100,
  },
  {
    id: 3,
    tenantId: '8b8a6066-bcb1-4686-bc62-372eff5933a9',
    channelId: '876cb91c-fb79-40bc-a2cf-807dcd81bff0',
    host: 'migration.mangart.xyz',
    name: 'Migration dev',
    type: 'RJB',
    tenant: 'Alterset',
    defaultLanguage: {
      id: 'a9abab44-c298-4399-9ee1-57e433c6e26e',
      shortcode: 'sl',
    },
    languages: {
      sl: 'a9abab44-c298-4399-9ee1-57e433c6e26e',
      en: '1f67628c-4b5e-4bf5-b28a-4cbf19da8818',
    },
    selbyMillsPostUrls: {
      sl: 'http://preferenca-slo.smtesting.co.uk/',
      en: 'http://preferenca-eng.smtesting.co.uk/',
    },
    spectoCalendarUrl: 'https://stg-calendar.preferenca.si/calendar',
    sessionTimeout: 3600000,
    candidateSearchPageSize: 100,
  },
  {
    id: 4,
    tenantId: '8b8a6066-bcb1-4686-bc62-372eff5933a9',
    channelId: '876cb91c-fb79-40bc-a2cf-807dcd81bff0',
    host: 'tst-www.preferenca.si',
    name: 'Test Preferenca',
    type: 'RJB',
    tenant: 'TenantName',
    defaultLanguage: {
      id: 'f0ad5d22-5c24-4f70-a239-9cc1205d6009',
      // id: '8d2ddacf-f9a1-4369-bb56-b28a66aae34d',
      shortcode: 'sl',
    },
    languages: {
      sl: 'f0ad5d22-5c24-4f70-a239-9cc1205d6009',
      en: '8d2ddacf-f9a1-4369-bb56-b28a66aae34d',
    },
    didomiApiKey: '938bbf47-f443-4ae0-ab2a-c965bfd2f72d',
    gtmId: 'GTM-PNJF9PX',
    selbyMillsPostUrls: {
      sl: 'http://preferenca-slo.smtesting.co.uk/',
      en: 'http://preferenca-eng.smtesting.co.uk/',
    },
    spectoCalendarUrl: 'https://stg-calendar.preferenca.si/calendar',
    sessionTimeout: 3600000,
    candidateSearchPageSize: 100,
  },
  {
    id: 5,
    tenantId: '8b8a6066-bcb1-4686-bc62-372eff5933a9',
    channelId: '876cb91c-fb79-40bc-a2cf-807dcd81bff0',
    host: 'mangart.xyz',
    name: 'MangartXYZ Test',
    type: 'RJB',
    tenant: 'Alterset',
    didomiApiKey: '938bbf47-f443-4ae0-ab2a-c965bfd2f72d',
    defaultLanguage: {
      id: 'a9abab44-c298-4399-9ee1-57e433c6e26e',
      shortcode: 'sl',
    },
    languages: {
      sl: 'a9abab44-c298-4399-9ee1-57e433c6e26e',
      en: '1f67628c-4b5e-4bf5-b28a-4cbf19da8818',
    },
    selbyMillsPostUrls: {
      sl: 'http://preferenca-slo.smtesting.co.uk/',
      en: 'http://preferenca-eng.smtesting.co.uk/',
    },
    spectoCalendarUrl: 'https://stg-calendar.preferenca.si/calendar',
    sessionTimeout: 3600000,
    candidateSearchPageSize: 100,
  },
  {
    id: 6,
    tenantId: '8b8a6066-bcb1-4686-bc62-372eff5933a9',
    channelId: '876cb91c-fb79-40bc-a2cf-807dcd81bff0',
    host: 'preferenca.si',
    name: 'Preferenca',
    type: 'RJB',
    tenant: 'Preferenca',
    didomiApiKey: '938bbf47-f443-4ae0-ab2a-c965bfd2f72d',
    defaultLanguage: {
      id: 'db3c58e6-a083-4f72-b30b-39f2127bb18d',
      shortcode: 'sl',
    },
    languages: {
      sl: 'db3c58e6-a083-4f72-b30b-39f2127bb18d',
      en: 'b12341f8-a37f-447b-98c8-70ae387d38f2',
    },
    gtmId: 'GTM-PFWWTZT',
    selbyMillsPostUrls: {
      sl: 'http://preferenca-slo.testsdirect.com/',
      en: 'http://preferenca-eng.testsdirect.com/',
    },
    spectoCalendarUrl: 'https://calendar.preferenca.si/calendar',
    sessionTimeout: 3600000,
    candidateSearchPageSize: 10,
  },
  {
    id: 7,
    tenantId: '8b8a6066-bcb1-4686-bc62-372eff5933a9',
    channelId: '876cb91c-fb79-40bc-a2cf-807dcd81bff0',
    host: 'www.preferenca.si',
    name: 'Preferenca',
    type: 'RJB',
    tenant: 'Preferenca',
    didomiApiKey: '938bbf47-f443-4ae0-ab2a-c965bfd2f72d',
    defaultLanguage: {
      id: 'db3c58e6-a083-4f72-b30b-39f2127bb18d',
      shortcode: 'sl',
    },
    languages: {
      sl: 'db3c58e6-a083-4f72-b30b-39f2127bb18d',
      en: 'b12341f8-a37f-447b-98c8-70ae387d38f2',
    },
    gtmId: 'GTM-PFWWTZT',
    selbyMillsPostUrls: {
      sl: 'http://preferenca-slo.testsdirect.com/',
      en: 'http://preferenca-eng.testsdirect.com/',
    },
    spectoCalendarUrl: 'https://calendar.preferenca.si/calendar',
    sessionTimeout: 3600000,
    candidateSearchPageSize: 10,
  },
  {
    id: 8,
    tenantId: '8b8a6066-bcb1-4686-bc62-372eff5933a9',
    channelId: '876cb91c-fb79-40bc-a2cf-807dcd81bff0',
    host: 'stg-www.preferenca.si',
    name: 'Preferenca',
    type: 'RJB',
    tenant: 'Preferenca',
    didomiApiKey: '938bbf47-f443-4ae0-ab2a-c965bfd2f72d',
    defaultLanguage: {
      id: 'db3c58e6-a083-4f72-b30b-39f2127bb18d',
      shortcode: 'sl',
    },
    languages: {
      sl: 'db3c58e6-a083-4f72-b30b-39f2127bb18d',
      en: 'b12341f8-a37f-447b-98c8-70ae387d38f2',
    },
    gtmId: 'GTM-PFWWTZT',
    selbyMillsPostUrls: {
      sl: 'http://preferenca-slo.smtesting.co.uk/',
      en: 'http://preferenca-eng.smtesting.co.uk/',
    },
    spectoCalendarUrl: 'https://stg-calendar.preferenca.si/calendar',
    sessionTimeout: 3600000,
    candidateSearchPageSize: 100,
  },
  {
    id: 9,
    tenantId: '78f6aca2-49a7-44f6-a088-24264c11d58d',
    channelId: '0b2ebef8-e533-4918-b6a2-4f25ae39ab0e',
    host: 'tst-demo.preferenca.si',
    name: 'Test 2 Preferenca',
    type: 'RJB',
    tenant: 'TenantName',
    defaultLanguage: {
      id: 'f0ad5d22-5c24-4f70-a239-9cc1205d6009',
      shortcode: 'sl',
    },
    languages: {
      sl: 'f0ad5d22-5c24-4f70-a239-9cc1205d6009',
      en: '8d2ddacf-f9a1-4369-bb56-b28a66aae34d',
    },
    didomiApiKey: '938bbf47-f443-4ae0-ab2a-c965bfd2f72d',
    gtmId: 'GTM-PNJF9PX',
    selbyMillsPostUrls: {
      sl: 'http://preferenca-slo.smtesting.co.uk/',
      en: 'http://preferenca-eng.smtesting.co.uk/',
    },
    spectoCalendarUrl: 'https://stg-calendar.preferenca.si/calendar',
    sessionTimeout: 3600000,
    candidateSearchPageSize: 100,
  },
]
