export const employeronboarding = {
  'employeronboarding.statementTitlePartOne': 'Dejodajalec na Preferenca.si,',
  'employeronboarding.statementTxtPartOne':
    'ki nastopa kot upravljalec (v nadaljevanju “upravljalec”)',
  'employeronboarding.statementTxtPartTwo': 'in',
  'employeronboarding.statementBoldTxtPartOne':
    ' Styria digital marketplaces d.o.o.',
  'employeronboarding.statementAddress':
    ',Verovškova 55, 1000 Ljubljana, davčna št.: SI58257551, ki nastopa kot pogodbeni obdelovalec podatkov (v nadaljevanju: “pogodbeni obdelovalec”)',
  'employeronboarding.statementAddressInfo':
    '(v nadaljevanju “upravljalec” in “pogodbeni obdelovalec”, skupaj poimenovana “pogodbeni stranki”)',
  'employeronboarding.statementAddressInfoLast': ' sklepata naslednjo:',
  'employeronboarding.statementTitle': 'POGODBO O POGODBENI OBDELAVI PODATKOV',
  'employeronboarding.statementTitleTxt':
    ' v skladu s tretjim odstavkom 28. člena Splošne uredbe EU o varstvu podatkov',
  'employeronboarding.statementBoldTxtPartTwo': '1. Preambula/predmet pogodbe',
  'employeronboarding.statementBoldNumber': '1.1 ',
  'employeronboarding.statementBoldNumberTxtPartOne':
    ' V skladu s pogodbenimi ali pravnimi razmerji, ki so podrobneje navedena v Aneksu št. 1 (v nadaljevanju “Aneks”) pogodbeni obdelovalec za upravljalca izvaja storitve, ki so v celoti ali delno sestavljene iz obdelave osebnih podatkov v skladu z 2. točko 4. člena Splošne uredbe EU o varstvu podatkov (EU General Data Protection Regulation, v nadaljevanju “GDPR”). Ta Pogodba predstavlja podlago za takšno obdelovanje podatkov v skladu s tretjim odstavkom 28. člena GDPR. V tem oziru je upravljalec podatkov odgovoara oseba, pogodbeni obdelovalec pa pogodbeno obdeluje podatke.',
  'employeronboarding.statementBoldNumberSecond': '1.2',
  'employeronboarding.statementBoldNumberSecondTxt':
    ' V primeru dvomov ali nasprotij glede obdelovanja podatkov ima ta Pogodba v celoti prednost pred osnovno pogodbo oziroma naročilnoc (v nadaljevanju: “osnovna pogodba”)',
  'employeronboarding.statementBoldNumberThird': '1.3 ',
  'employeronboarding.statementBoldNumberThirdTxt':
    ' Ne glede na podatke in/ali definicije, ki so navedene v Aneksu, ta Pogodba pokriva vse osebne podatke, ki jih pogodbeni obdelovalec obdeluje z namenom izpolnitve osnovne pogodbe, v katerikoli obliki, oziroma do njih dostopa ali lahko dostopi.',
  'employeronboarding.statementBoldTxtPartThree':
    '2. Opis obdelovanja podatkov',
  'employeronboarding.statementBoldNumberFourht': '2.1',
  'employeronboarding.statementBoldNumberFourhtTxt':
    'Obdelovanje podatkov se izvaja v okviru obveznosti iz osnovne pogodbe, katere so natančneje opredeljene in navedene v Aneksu. Tam so opisani in/ali povzeti tudi predmet, trajanje, vrsta in nameni obdelovanja osebnih podatkov ter osebni podatki, ki so sporočeni ali dostopni pogodbenemu obdelovalcu za namen izpolnjevanja njegovih pogodbenih obveznosti in kategorije posameznikov, na katere se nanašajo osebni podatki.',
  'employeronboarding.statementBoldNumberFifth': '2.2',
  'employeronboarding.statementBoldNumberFifthTxt':
    ' Pogodbeni obdelovalec obdeluje osebne podatke izključno z namenom izpolnjevanja svojih pogodbenih obveznosti do upravljalca, kot je dogovorjeno v osnovni ali tej Pogodbi in Aneksu ali v skladu z navodili upravljalca. Pogodbeni obdelovalec bo upravljalca vnaprej in v dopustnem obsegu obveščal, o katerikoli drugih pravnih obveznostih v zvezi z obdelovanjem.',
  'employeronboarding.statementCheckBoxLabel':
    ' Izjavljam, da sem seznanjen/a z vsebino Pogodbe o pogodbeni obdelavi podatkov in jo v celoti sprejemam. S potrditvijo na spodnji gumb je pogodba sklenjena, vsebina pa je za pogodbeni stranki v celoti zavezujoča.',
  'employeronboarding.step1Title': 'Podatki o podjetju in kontaktni osebi',
  'employeronboarding.step2Title': '12-mesečni plan zaposlovanja',
  'employeronboarding.step3Title': 'Hvala',
  'employeronboarding.step1SidebarTitle': 'Splošni podatki',
  'employeronboarding.step2SidebarTitle':
    'Podatki o podjetju in kontaktni osebi',
  'employeronboarding.step3SidebarTitle': '12-mesečni plan zaposlovanja',
  'employeronboarding.titleEmploymentPlan':
    'PLAN ZAPOSLOVANJA PODJETJA ZA NASLEDNJIH 12 MESECEV',
  'employeronboarding.titleSalaryRange': 'Plačni razpon',
  'employeronboarding.titleOccupationalFieldsCandidates':
    'PODROČJA DELA KANDIDATOV',
  'employeronboarding.titleRegions': 'REGIJE ZAPOSLOVANJA',
  'employeronboarding.titleYearsOfExp': 'LETA IZKUŠENJ GLEDE NA DELOVNO MESTO',
  'employeronboarding.titleEducation': 'IZOBRAZBA KANDIDATOV',
  'employeronboarding.typeaheadCandidatesPos': 'Delovna mesta kandidatov',
  'employeronboarding.typeaheadCandidatesPosPlaceholder':
    'Enter candidates position',
  'employeronboarding.positionYearsOfExp': 'Leta izkušenj',
  'employeronboarding.positionSalaryRange': 'Plačni razpon',
  'employeronboarding.typeaheadDegree': 'Raven izobrazbe',
  'employeronboarding.typeaheadFieldOfStudy': 'Področje študija',
  'employeronboarding.companyContactInfoDescription':
    'Prosimo preverite vnesene podatke in dopolnite podatke o kontaktni osebi.',
  'employeronboarding.twelveMonthHiringPlanDescription':
    'Povejte nam, kakšen je vaš 12-mesečni plan zaposlovanja, da vam lahko pomagamo najti boljše kandidate.',
  'employeronboarding.statementDescription':
    'Prosimo, preberite Pogodbo o pogodbeni obdelavi podatkov. Izjavo o sklenitvi pogodbe lahko podate, ko pogledate celotno besedilo.',
  'employeronboarding.companyLongName': 'DOLGO IME PODJETJA',
  'employeronboarding.contactPersonTelephoneNumber':
    'TELEFONSKA ŠTEVILKA KONTAKTNE OSEBE',
  'employeronboarding.contactPersonPosition':
    'POZICIJA V PODJETJU KONTAKTNE OSEBE',
  'employeronboarding.address': 'Naslov',
  'employeronboarding.postalCode': 'Poštna številka',
  'employeronboarding.city': 'Mesto',
  'employeronboarding.country': 'Država',
  'employeronboarding.selectValue': 'Izberite vrednost',
  'employeronboarding.selectYourCountry': 'Select your country',
  'employeronboarding.phoneNumber': 'Telefonska številka',
}
