import * as type from '../types'
import {
  ActionType,
  IncomingRequestSeekerType,
  SaveCommentType,
  Candidate,
  IncomingRequestType,
  HelpfulHintType,
  SeekerProfileType,
  PublicSeekerProfileType,
} from 'Interfaces'

export const setSidebarClosed = (
  sidebarClosed: boolean
): ActionType<boolean> => {
  return {
    type: type.widgetBar.sidebarClosed.setStatus,
    payload: sidebarClosed,
  }
}

export const setCandidateBasicData = (
  candidateBasicData:
    | Candidate
    | IncomingRequestSeekerType
    | null
    | SeekerProfileType
    | PublicSeekerProfileType
): ActionType<
  | Candidate
  | IncomingRequestSeekerType
  | null
  | SeekerProfileType
  | PublicSeekerProfileType
> => {
  return {
    type: type.widgetBar.candidateBasicData.setCandidateBasicData,
    payload: candidateBasicData,
  }
}

export const setSelectedRequestWidget = (
  payload: IncomingRequestType
): ActionType<IncomingRequestType> => {
  return {
    type: type.widgetBar.selectedRequest.setSelectedRequest,
    payload: payload,
  }
}

export const getCandidateComments = (id: string): ActionType<string> => {
  return {
    type: type.widgetBar.comments.get.requested,
    payload: id,
  }
}

export const getCandidateHistory = (id: string): ActionType<string> => {
  return {
    type: type.widgetBar.history.get.requested,
    payload: id,
  }
}

export const saveCandidateComment = (
  value: SaveCommentType
): ActionType<SaveCommentType> => {
  return {
    type: type.widgetBar.comments.save.requested,
    payload: value,
  }
}

export const setHelpfulHintStatus = (
  helpfulHintValue: HelpfulHintType
): ActionType<HelpfulHintType> => {
  return {
    type: type.widgetBar.helpfulHintClosed.setStatus.requested,
    payload: helpfulHintValue,
  }
}

export const getHelpfulHintStatus = (name: string): ActionType<string> => {
  return {
    type: type.widgetBar.helpfulHintClosed.getStatus.requested,
    payload: name,
  }
}
