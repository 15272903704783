import * as Yup from 'yup'

export const ValidationNext = Yup.object().shape({
  totalWorkExperienceId: Yup.string().required(
    'Total years of experience field is required'
  ),
  occupationExperiences: Yup.array()
    .min(1, 'Job position is required')
    .required('Job position is required'),
  skills: Yup.array()
    .min(1, 'Skills are required')
    .required('Skills are required'),
  publicOccupation:  Yup.string().required(
    'Current job title field is required'
  ),
})

export const ValidationBack = Yup.object().shape({
  totalWorkExperienceId: Yup.string(),
  occupationExperiences: Yup.array(),
  skills: Yup.array(),
  publicOccupation: Yup.string()
})
