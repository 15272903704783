import {FC, ReactNode, useState} from 'react'
import styles from './OfferListItem.module.scss'
import {IncomingRequestType} from 'Interfaces'
import classNames from 'classnames'
import RequestActions from './RequestActions'
import {ExpirationValue} from './RequestValues'
import {
  CombinedRequestStatues,
  employerRequestStatusTranslationMap,
  RequestType,
} from 'Components/IncomingRequests/Constants'
import {SeekerProfile} from './SeekerProfile'
import {FormattedMessage} from 'react-intl'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'

const ItemDetail: FC<{
  label?: string
  value: ReactNode
  className?: string
}> = ({label, value, className}) => {
  return (
    <div className={`${styles.detailWrap} ${className}`}>
      {label && label !== edt.candidate && (
        <div className={styles.label}>
          <FormattedMessage id={label} />
        </div>
      )}
      {value}
    </div>
  )
}

// TODO candidate-dashboard: centralize request colors?
const statusColors = {
  [CombinedRequestStatues.ACCEPTED]: {color: styles.greenText},
  [CombinedRequestStatues.PENDING]: {color: styles.purpleText},
  [CombinedRequestStatues.REJECTED]: {color: styles.redCinnabarText},
  [CombinedRequestStatues.EXPIRED]: {color: styles.blackText},
  [CombinedRequestStatues.COMPLETED]: {color: styles.greenText},
  [CombinedRequestStatues.NEW_OFFER_PROPOSED]: {color: styles.brownText},
  [CombinedRequestStatues.CANCELLED]: {color: styles.redCinnabarText},
  [CombinedRequestStatues.APPROVED]: {color: styles.greenText},
  [CombinedRequestStatues.EXTENDED]: {color: styles.blueText},
}

export type OfferDisplayType = 'all' | 'pending' | 'accepted'
type OfferListItemProps = {
  request: IncomingRequestType
  displayType: OfferDisplayType
  setSidebarClosed: (sidebarClosed: boolean) => void
  setSelectedRequestWidget: (request: IncomingRequestType) => void
  activeTab?: string
  setIsModalOpen: (v: boolean) => void
}

export const OfferListItem: FC<OfferListItemProps> = ({
  request,
  displayType = 'all',
  setSidebarClosed,
  setSelectedRequestWidget,
  activeTab,
  setIsModalOpen,
}) => {
  const [isCandidateSelected, setIsCandidateSelected] = useState(false)

  return (
    <div
      className={classNames({
        [styles.selectedCandidateRow]: isCandidateSelected,
      })}
      onClick={() => {
        setSidebarClosed(false)
        setSelectedRequestWidget({
          ...request,
          type: RequestType.PLACEMENT_REQUEST,
        })
        setIsCandidateSelected(true)
      }}
      tabIndex={0}
      onBlur={() => {
        setIsCandidateSelected(false)
      }}
    >
      <div
        className={classNames(styles.requestItemWrap, 'requestItemWrap', {
          [styles.all]: displayType === 'all',
          [styles.accepted]: displayType === 'accepted',
          [styles.candidateRow]: !isCandidateSelected,
        })}
      >
        <ItemDetail
          label={edt.candidate}
          value={
            <SeekerProfile request={request} setIsModalOpen={setIsModalOpen} />
          }
          className={styles.flexContainer}
        />
        {activeTab !== 'accepted' && (
          <ItemDetail
            label={edt.status}
            value={
              <div
                className={classNames(
                  styles.statusValue,
                  statusColors[request.status].color
                )}
              >
                <FormattedMessage
                  id={employerRequestStatusTranslationMap[request.status]}
                />
              </div>
            }
          />
        )}
        {activeTab !== 'accepted' && (
          <ItemDetail
            label={edt.offerSent}
            value={
              <div>
                <p className={styles.date}>
                  {request.sentDate &&
                    new Date(request.sentDate).toLocaleDateString('sl-SI')}
                </p>
              </div>
            }
          />
        )}
        {activeTab === 'accepted' && (
          <ItemDetail
            label={edt.offerAccepted}
            value={
              <div>
                <p className={styles.date}>
                  {request.sentDate &&
                    new Date(request.sentDate).toLocaleDateString('sl-SI')}
                </p>
              </div>
            }
          />
        )}
        {request.expirationDate ? (
          <ItemDetail
            label={edt.expire}
            value={
              request.status !== 'rejected' && (
                <ExpirationValue date={request?.expirationDate} />
              )
            }
          />
        ) : (
          <div />
        )}
        <ItemDetail
          className={styles.justifyEnd}
          value={
            <RequestActions
              request={{...request, type: RequestType.OFFER_REQUEST}}
            />
          }
        />
      </div>
    </div>
  )
}
