import {all, put, takeLatest} from 'redux-saga/effects'
import {getRoute} from 'Services/I18n/Utils'
import {push} from 'connected-react-router'
import * as type from 'Store/types'
import {AppRoute} from 'Services/I18n/Constants'
import {
  FullCandidateData,
  MinifeedData,
} from 'Store/MockedData/FullCandidateData'

function* fetchFullCandidateData() {
  //const {response, error} = yield call(API.get, '/candidate/profile')
  const response = FullCandidateData
  const error = 'error'
  if (response) {
    yield put({
      type: type.candidate.fullCandidateData.get.succeeded,
      payload: response.data,
    })
  } else {
    yield put({
      type: type.candidate.fullCandidateData.get.failed,
      payload: error,
    })
    yield put(push(getRoute(AppRoute.SeekerLogin)))
  }
}

function* fetchMinifeedData() {
  //const {response, error} = yield call(API.get, '/candidate/profile')
  const response = MinifeedData
  const error = 'error'
  if (response) {
    yield put({
      type: type.candidate.minifeeds.get.succeeded,
      payload: response,
    })
  } else {
    yield put({
      type: type.candidate.minifeeds.get.failed,
      payload: error,
    })
    yield put(push(getRoute(AppRoute.SeekerLogin)))
  }
}

export default function* CandidateSaga(): Generator {
  yield all([
    takeLatest(
      type.candidate.fullCandidateData.get.requested,
      fetchFullCandidateData
    ),
    takeLatest(type.candidate.minifeeds.get.requested, fetchMinifeedData),
  ])
}
