import {connect} from 'react-redux'

import {EmployerLoginConsents} from './EmployerLoginConsents'
import {ConsentToSend} from 'Interfaces'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {
  acceptEmployerLoginConsents,
  getEmployerLoginConsents,
} from 'Store/Actions'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getEmployerLoginConsents: () => dispatch(getEmployerLoginConsents()),
  acceptEmployerLoginConsents: (values: ConsentToSend[]) =>
    dispatch(acceptEmployerLoginConsents(values)),
})

export const EmployerLoginConsentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerLoginConsents)
