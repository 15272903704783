import {useState, FC, useMemo, useEffect} from 'react'
import {useFormik} from 'formik'
import classnames from 'classnames'
import ExtensionIcon from '@mui/icons-material/Extension'

import SectionItem from 'Components/Profile/SectionItem'
import {SelectDropdownTests} from 'Components/Assessments/SelectDropdownTests'
import {LabelsGroup} from 'Components/LabelsGroup'
import Modal, {DeleteModal} from 'Components/Modal'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import {TypeAheadWithCategory} from 'Components/Inputs/TypeAheadImproved'
import {
  BaseTaxonomyType,
  TaxonomiesStateType,
  AssessmentsStateType,
  TrainingTitleAddType,
  TrainingTitleEditType,
  TrainingTitleType,
  TrainingTitleFormikType,
} from 'Interfaces'
import Hr from 'Components/Hr'
import TextField from 'Components/Inputs/TextField'
import {OnChangeProps} from 'Utils/FormikProps'
import Title from 'Components/Title'
import Validation, {EditValidation} from './Validation'
import {useApiError, useTranslation} from 'Hooks'
import {
  GeneralAssessmentsTranslations as gat,
  ModalsAssessmentsTranslations as mat,
} from 'Services/I18n/Constants'
import SectionHeader from 'Components/Profile/SectionHeader'
import {DatePicker} from 'Components/Inputs/DatePicker'

import styles from './SkillTests.module.scss'
interface SkillTestsProps {
  skillTests: TrainingTitleType[]
  getTaxonomies: () => void
  taxonomies: TaxonomiesStateType
  assessments: AssessmentsStateType
  addSkillTest: (value: TrainingTitleAddType) => void
  editSkillTest: (value: TrainingTitleEditType) => void
  deleteSkillTest: (id: string) => void
  isRecruiter: boolean
  seekerUserId: string
}

export const SkillTests: FC<SkillTestsProps> = ({
  skillTests,
  getTaxonomies,
  taxonomies,
  assessments,
  addSkillTest,
  isRecruiter = false,
  editSkillTest,
  deleteSkillTest,
  seekerUserId,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const translation = useTranslation()

  const packAddSkillTestValues = (
    formikValues: TrainingTitleFormikType
  ): TrainingTitleAddType => {
    const {id, translation} = formikValues.trainingTitle as BaseTaxonomyType
    return {
      provider: formikValues.provider,
      dateOfTest: formikValues.dateOfTest,
      expirationDate:
        formikValues.expirationDate === '' ? null : formikValues.expirationDate,
      trainingTitle: id.includes('newValue~') ? {name: translation} : {id},
      seekerUserId,
    }
  }

  const packEditSkillTestValues = (
    formikValues: TrainingTitleFormikType
  ): TrainingTitleEditType => {
    const {id} = formikValues.trainingTitle as BaseTaxonomyType
    return {
      id: formikValues.id,
      provider: formikValues.provider,
      dateOfTest: formikValues.dateOfTest,
      expirationDate:
        formikValues.expirationDate === '' ? null : formikValues.expirationDate,
      trainingTitle: {id},
    }
  }

  const testsLabels = [
    translation[gat.testTypeLabel],
    translation[gat.testProviderLabel],
    translation[gat.testDateLabel],
    translation[gat.testExpirationDateLabel],
  ]

  const emptyValues: TrainingTitleFormikType = {
    id: '',
    provider: '',
    dateOfTest: '',
    expirationDate: '',
    trainingTitle: undefined,
  }

  const formik = useFormik({
    initialValues: emptyValues,
    validationSchema: Validation,

    onSubmit: (values) => {
      addSkillTest(packAddSkillTestValues(values))
    },
  })

  const formikEdit = useFormik({
    initialValues: emptyValues,
    validationSchema: EditValidation,
    onSubmit: (values) => {
      editSkillTest(packEditSkillTestValues(values))
    },
  })

  useEffect(() => {
    if (!assessments.loading && !assessments.error) {
      setModalOpen(false)
      setModalEditOpen(false)
      setDeleteModalOpen(false)
    }
  }, [assessments.loading])

  const openAdd = () => {
    formik.resetForm({
      values: emptyValues,
    })
    getTaxonomies()
    setModalOpen(true)
  }

  const onEdit = (editValues: TrainingTitleFormikType) => {
    formikEdit.setValues(editValues)
    setModalEditOpen(true)
  }

  const onDelete = (id: string) => {
    deleteSkillTest(id)
  }

  const setTypeAheadField = (name: string, values: BaseTaxonomyType[]) => {
    formik.setFieldValue(name, values[0])
  }

  const uncheckedSkills = useMemo(() => {
    const taxonomySkills = taxonomies?.trainingTitles?.data
    const map = new Map()
    skillTests.forEach((s) => {
      map.set(s.trainingTitle.id, true)
    })
    return taxonomySkills.filter((t) => !map.has(t.id))
  }, [taxonomies.trainingTitles.data, skillTests])

  const checkedSkills = useMemo(() => {
    return skillTests.map((s) => s.trainingTitle)
  }, [skillTests])

  const Other = translation['Other']

  const mappedSkills = useMemo(() => {
    const map = {}
    skillTests.forEach((s) => {
      const name = s.trainingTitle.skillGroup?.nameTranslation || Other
      map[name] = map[name] ? [...map[name], s] : [s]
    })
    return map
  }, [skillTests])

  useApiError(formik.setFieldError, assessments.error, 'trainingTitle')
  useApiError(formikEdit.setFieldError, assessments.error, 'trainingTitle')

  return (
    <div className={styles.container}>
      {(skillTests.length > 0 || isRecruiter) && (
        <SectionHeader
          title={gat.skillsTestTitle}
          onAdd={isRecruiter ? openAdd : undefined}
          className={styles.headerSkillTest}
        />
      )}

      {skillTests.length > 0 && (
        <SectionItem className={styles.sectionItem}>
          <div>
            <LabelsGroup
              labels={testsLabels}
              className={classnames(styles.rowLabels, styles.testsLabels)}
            />
            {Object.keys(mappedSkills).map((key) => (
              <div key={key} className={styles.selectBoxWrapper}>
                <SelectDropdownTests
                  skillTests={mappedSkills[key]}
                  testCategory={key}
                  onEdit={onEdit}
                  setDeleteModalOpen={setDeleteModalOpen}
                  setDeleteId={setDeleteId}
                  isRecruiter={isRecruiter}
                />
              </div>
            ))}
          </div>
        </SectionItem>
      )}
      <Modal
        isOpen={modalOpen}
        onAfterClose={() => {
          setModalOpen(false)
        }}
        title={mat.addSkillTest}
        icon={ExtensionIcon}
        ariaHideApp={false}
        className={styles.modal}
        Footer={
          <SubmitFooter
            onSubmit={formik.handleSubmit}
            onCancel={() => {
              setModalOpen(false)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <div className={styles.titleWrapper}>
          <Title text={mat.skillTestName} className={styles.title} />
        </div>
        <TypeAheadWithCategory
          className={styles.typeahead}
          name="trainingTitle"
          value={
            formik.values.trainingTitle ? [formik.values.trainingTitle] : []
          }
          theme="white"
          items={uncheckedSkills}
          maxNumSelections={1}
          placeholder={`${translation[mat.skillTestName]} ...`}
          setValue={setTypeAheadField}
          selectNonExisting
          categoryPropName="skillGroup"
          categoryTranslationName="nameTranslation"
          invalidNonExisting={checkedSkills}
          setTouched={formik.getFieldHelpers('trainingTitle').setTouched}
          error={
            formik.submitCount && formik.touched['trainingTitle']
              ? formik.errors.trainingTitle
                ? formik.errors.trainingTitle['id']
                : undefined
              : undefined
          }
        />
        <Hr />
        <div className={styles.titleWrapper}>
          <Title text={mat.skillTestProvider} className={styles.title} />
        </div>
        <TextField
          name="provider"
          placeholder={`${translation[mat.skillTestProvider]} ...`}
          {...OnChangeProps(formik, 'provider')}
        />
        <Hr />
        <div className={styles.titleWrapper}>
          <Title text={mat.skillTestTakenDate} className={styles.title} />
        </div>
        <DatePicker
          value={formik.values.dateOfTest}
          formik={formik}
          error={
            formik.submitCount && formik.touched['dateOfTest']
              ? formik.errors['dateOfTest']
              : undefined
          }
          formikFieldName="dateOfTest"
        />
        <Hr />
        <div className={styles.titleWrapper}>
          <Title text={mat.skillTestExpirationDate} className={styles.title} />
        </div>
        <DatePicker
          value={formik.values.expirationDate}
          formik={formik}
          error={
            formik.submitCount && formik.touched['expirationDate']
              ? formik.errors['expirationDate']
              : undefined
          }
          formikFieldName="expirationDate"
        />
      </Modal>
      <Modal
        isOpen={modalEditOpen}
        onAfterClose={() => {
          setModalEditOpen(false)
        }}
        title={mat.editSkillTest}
        icon={ExtensionIcon}
        ariaHideApp={false}
        className={styles.modal}
        Footer={
          <SubmitFooter
            onSubmit={formikEdit.handleSubmit}
            onCancel={() => {
              setModalEditOpen(false)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <div className={styles.titleWrapper}>
          <Title
            text={formikEdit.values.trainingTitle?.translation || ''}
            className={styles.title}
          />
        </div>
        <Hr />
        <div className={styles.titleWrapper}>
          <Title text={mat.skillTestProvider} className={styles.title} />
        </div>
        <TextField
          name="provider"
          placeholder={`${translation[mat.skillTestProvider]} ...`}
          {...OnChangeProps(formikEdit, 'provider')}
        />
        <Hr />
        <div className={styles.titleWrapper}>
          <Title text={mat.skillTestTakenDate} className={styles.title} />
        </div>
        <DatePicker
          value={formikEdit.values.dateOfTest}
          formik={formikEdit}
          error={
            formikEdit.submitCount && formikEdit.touched['dateOfTest']
              ? formikEdit.errors['dateOfTest']
              : undefined
          }
          formikFieldName="dateOfTest"
        />
        <Hr />
        <div className={styles.titleWrapper}>
          <Title text={mat.skillTestExpirationDate} className={styles.title} />
        </div>
        <DatePicker
          value={formikEdit.values.expirationDate}
          formik={formikEdit}
          error={
            formikEdit.submitCount && formikEdit.touched['expirationDate']
              ? formikEdit.errors['expirationDate']
              : undefined
          }
          formikFieldName="expirationDate"
        />
      </Modal>
      <DeleteModal
        deleteAction={onDelete}
        isOpen={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        text={translation[mat.deleteSkillTest]}
        id={deleteId}
      />
    </div>
  )
}
