import {FC} from 'react'
import styles from './YellowButton.module.scss'
import paint from './paint.svg'
import paintDisabled from './paintDisabled.svg'
import paintBlue from './paintBlue.svg'
import classNames from 'classnames'
import {FormattedMessage} from 'react-intl'

interface ButtonType {
  text: string
  type?: 'submit' | 'reset' | 'button'
  className?: string
  onClick?: () => void
  disabled?: boolean
}

export const YellowButton: FC<ButtonType> = ({
  text,
  type = 'submit',
  className,
  onClick,
  disabled = false,
}: ButtonType) => {
  return (
    <button
      className={classNames(styles.button, className, {
        [styles.disabled]: disabled,
      })}
      type={type}
      onClick={onClick}
      disabled={disabled}
    >
      <img className={styles.paint} src={disabled ? paintDisabled : paint} />
      <img
        className={styles.paintBlue}
        src={disabled ? paintDisabled : paintBlue}
      />
      <span className={styles.text}>
        <FormattedMessage id={text} />
      </span>
    </button>
  )
}
