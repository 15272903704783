export const TaxonomyUrl = (name: string): string => {
  switch (name) {
    case 'educationLevels':
      return '/taxonomy/education-levels'
    case 'skillLevels':
      return '/taxonomy/skill-levels'
    case 'jobCategories':
      return '/taxonomy/job-categories'
    case 'workFromHome':
      return '/taxonomy/work-from-home'
    case 'workExperiences':
      return '/taxonomy/total-work-experiences'
    case 'additionalBenefits':
      return '/taxonomy/additional-benefits'
    case 'fieldsOfStudy':
      return '/taxonomy/fields-of-study'
    case 'careerLevels':
      return '/taxonomy/career-levels'
    case 'employmentTypes':
      return '/taxonomy/employment-type'
    case 'jobCategory':
      return '/taxonomy/job-categories'
    case 'systemLanguages':
      return '/taxonomy/system-language'
    case 'languages':
      return '/taxonomy/languages'
    case 'languageLevels':
      return '/taxonomy/language-levels'
    case 'jobSearchStatuses':
      return '/taxonomy/job-search-statuses'
    case 'numberOfRecruits':
      return '/taxonomy/number-of-recruits'
    case 'searchKeywords':
      return '/taxonomy/search-keywords'
    case 'skills':
      return '/small-index/skills'
    case 'occupations':
      return '/small-index/occupations'
    case 'educationInstitutions':
      return '/small-index/education-institutions'
    case 'trainingTitles':
      return '/small-index/training-titles'
    case 'employers':
      return '/small-index/employers'
    case 'towns':
      return '/small-index/towns'
    case 'regions':
      return '/small-index/regions'
    case 'drivingLicenseCategories':
      return '/taxonomy/driving-license-categories'
    default:
      return `/taxonomy/${name}`
  }
}
