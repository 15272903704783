import Modal from 'Components/Modal'
import {ThumbsFooter} from 'Components/Modal/ThumbsFooter'
import styles from './ModalPlacementRequest.module.scss'
import {DateTime} from 'luxon'

import ModalRejectPlacementConfirmation from './ModalRejectPlacementConfirmation'
import ModalApprovePlacementConfirmation from './ModalApprovePlacementConfirmation'
import {FormattedMessage} from 'react-intl'
import {CandidateDashboardTranslations as cdt} from 'Services/I18n/Constants'

import classnames from 'classnames'
import {IncomingRequestType} from 'Interfaces'
import {FC, useState} from 'react'
import {
  Business,
  ConnectWithoutContact,
  Notifications,
  Update,
  HourglassBottom,
  ForumOutlined,
} from '@mui/icons-material'
import {formatDate} from 'Utils/Date'
import {
  CombinedRequestStatues,
  RequestType,
  seekerRequestStatusTranslationMap,
} from 'Components/IncomingRequests/Constants'

const ExpirationValue: FC<{date: string}> = ({date}) => {
  const now = DateTime.now()
  const expiration = DateTime.fromISO(date)
  const diff = Math.floor(expiration.diff(now, ['days']).days)
  let dayOfTheWeek = expiration.toFormat('cccc')
  if (now.day === expiration.day) {
    return (
      <div className={styles.expirationValueContainer}>
        <p className={styles.expirationValueTextRed}>
          <FormattedMessage id={cdt.expiresToday} />
        </p>
      </div>
    )
  }
  if (diff > 7 || expiration < now) {
    return (
      <p className={styles.expirationValueNormal}>
        <FormattedMessage id={dayOfTheWeek} />
        <span className={styles.date}>
          {expiration.toFormat(' (d.L.yyyy)')}
        </span>
      </p>
    )
  }

  return (
    <div className={styles.expirationValueContainer}>
      <p className={styles.expirationValueTextRed}>
        <FormattedMessage id={cdt.in} />
        {` ${diff} `}
        {diff === 1 ? (
          <FormattedMessage id={cdt.day} />
        ) : (
          <FormattedMessage id={cdt.days} />
        )}
      </p>
      <p className={styles.expirationValueText}>
        {expiration.toFormat('(d.L.yyyy)')}
      </p>
    </div>
  )
}

interface ModalPlacementRequestProps {
  request: IncomingRequestType
  isOpenPlacementModal: boolean
  setIsOpenPlacementModal: (open: boolean) => void
  modalDetails?: boolean
  setModalDetails?: (modalDetails: boolean) => void
}

export const ModalPlacementRequest: FC<ModalPlacementRequestProps> = ({
  request,
  isOpenPlacementModal,
  setIsOpenPlacementModal,
}) => {
  const [rejectModal, setRejectModal] = useState(false)
  const [approveModal, setApproveModal] = useState(false)

  const isPending =
    request.status ===
      CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_PENDING ||
    request.status ===
      CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_PENDING

  const isRejected =
    request.status ===
      CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_REJECTED ||
    request.status ===
      CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_CONFIRMED ||
    request.status ===
      CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_REJECTED ||
    request.status ===
      CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_REJECTED ||
    request.status ===
      CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_PENDING
  const isCanceled = request.status === CombinedRequestStatues.CANCELLED
  return (
    <>
      <Modal
        className={styles.modal}
        icon={ForumOutlined}
        isOpen={isOpenPlacementModal}
        title={cdt.placementDetails}
        onAfterClose={() => {
          setIsOpenPlacementModal(false)
        }}
        shouldReturnFocusAfterClose={false}
        Footer={
          isPending && (
            <ThumbsFooter
              theme={'thumbs'}
              onApprove={() => {
                setApproveModal(true)
              }}
              approveText={cdt.confirmPlacement}
              rejectText={cdt.rejectPlacement}
              onReject={() => {
                setRejectModal(true)
              }}
              onCancel={() => {
                setIsOpenPlacementModal(false)
              }}
            />
          )
        }
      >
        <div className={styles.containerInterviewDetails}>
          {request.type === RequestType.PLACEMENT_REQUEST && isPending && (
            <p className={styles.title}>
              <FormattedMessage id={cdt.placementWeAreHappyPart1} />
              {request.companyName}
              <FormattedMessage id={cdt.placementWeAreHappyPart2} />
              {request.status !==
                CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_CONFIRMED &&
                request.status !== CombinedRequestStatues.EXPIRED && (
                  <FormattedMessage id={cdt.placementWeAreHappyPart3} />
                )}
            </p>
          )}
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <Business className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.company} />
              </p>
            </div>
            <div className={styles.itemRight}>
              <p className={styles.itemRightText}>{request.companyName}</p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <ConnectWithoutContact className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.requestType} />
              </p>
            </div>
            <div className={styles.itemRight}>
              <p className={styles.ContactRequestText}>
                <FormattedMessage id={cdt.placementSecond} />
              </p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <Notifications className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.requestStatus} />
              </p>
            </div>
            <div className={styles.itemRight}>
              <p
                className={classnames(styles.pending, {
                  [styles.rejected]: isRejected || isCanceled,
                  [styles.accepted]:
                    request.type === RequestType.PLACEMENT_REQUEST &&
                    request.status ===
                      CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_CONFIRMED,
                  [styles.expired]:
                    request.status === CombinedRequestStatues.EXPIRED,
                })}
              >
                <FormattedMessage
                  id={seekerRequestStatusTranslationMap[request.status]}
                />
              </p>
            </div>
          </div>
          {request.sentDate && (
            <div className={styles.item}>
              <div className={styles.itemLeft}>
                <Update className={styles.icon} />
                <p className={styles.itemLeftText}>
                  <FormattedMessage id={cdt.dateOfRequestSent} />:
                </p>
              </div>
              <div className={styles.itemRight}>
                <p className={styles.itemRightText}>
                  {formatDate(request.sentDate)}
                </p>
              </div>
            </div>
          )}
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <HourglassBottom className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.expirationDate} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              {request.expirationDate && (
                <ExpirationValue date={request.expirationDate} />
              )}
            </div>
          </div>
        </div>
      </Modal>

      <ModalApprovePlacementConfirmation
        request={request}
        approveModal={approveModal}
        setApproveModal={setApproveModal}
        setIsOpenPlacementModal={setIsOpenPlacementModal}
      />

      <ModalRejectPlacementConfirmation
        request={request}
        rejectModal={rejectModal}
        setRejectModal={setRejectModal}
        setIsOpenPlacementModal={setIsOpenPlacementModal}
      />
    </>
  )
}
