import {FC, useEffect, useRef, useState} from 'react'
import {useMediaQuery} from 'react-responsive'

import MainHeader from 'Components/MainHeader'
import {TestMicrophoneCameraNotificationContent} from 'Components/Assessments/TestMicrophoneCameraNotificationContent'
import YellowButton from 'Components/YellowButton'
import {WhiteBoxWrapper} from 'Components/WhiteBoxWrapper'
import {
  AssessmentsStateType,
  ChangeCognitiveConsentStatusType,
  ConsentType,
  MeType,
  SeekerConsentId,
  Test,
  UserConsentsVersionsType,
} from 'Interfaces'
import Modal, {AssessmentsSubmitFooter} from 'Components/Modal'
import {TestResultsWhiteBoxContent} from 'Components/Assessments/TestResultsWhiteBoxContent'
import CognitiveIcon from 'Assets/Images/cognitive-tests.svg'
import CheckBox from 'Components/Inputs/Checkbox'
import {useTranslation} from 'Hooks'
import {
  AppRoute,
  CognitiveAssessmentsTranslations as cat,
  ConsentsTranslations as ct,
  GeneralAssessmentsTranslations as gat,
  ModalsAssessmentsTranslations as mat,
  PsychometricAssessmentsTranslations as pat,
  SidebarTranslations as st,
} from 'Services/I18n/Constants'
import {getRoute} from 'Services/I18n/Utils'
import {FormattedMessage} from 'react-intl'
import {DownloadAndPrintHeader} from 'Components/DownloadAndPrintHeader'
import {Link} from 'react-router-dom'
import {MobileFooter} from 'Components/MobileFooter'
import FooterAssessments from 'Components/Footer'
import MainLayout from 'Layouts/MainLayout'
import {SidebarLink} from 'Components/SidebarLink'

import styles from './CognitiveTest.module.scss'
import {EditOutlined} from '@mui/icons-material'

interface CognitiveTestProps {
  getCognitiveTestResults: (value: string) => void
  downloadLink: {
    url: string
  }
  assessments: AssessmentsStateType
  getAssessments: () => void
  consents: ConsentType[]
  getConsents: Function
  user: MeType | undefined
  changeCognitiveTestStatus: (testId: string) => void
  changeCognitiveConsentStatus: (
    value: ChangeCognitiveConsentStatusType
  ) => void
}

export const CognitiveTest: FC<CognitiveTestProps> = ({
  consents,
  getCognitiveTestResults,
  downloadLink,
  assessments,
  getAssessments,
  getConsents,
  user,
  changeCognitiveConsentStatus,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [checkboxValue, setCheckboxValue] = useState(false)
  const [resultButtonClicked, setResultButtonClicked] = useState(false)
  const cognitiveTest: Test = assessments.data.items.filter((test: Test) => {
    return test.name === 'Cognitive test'
  })[0]
  const [cognitiveConsent, setCognitiveConsent] = useState<
    undefined | UserConsentsVersionsType
  >()
  const [cognitiveConsentId, setCognitiveConsentId] = useState<
    string | undefined
  >(undefined)

  const isMobile = useMediaQuery({
    query: '(max-width: 900px)',
  })

  const translation = useTranslation()

  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: getRoute(AppRoute.HomePageCandidate)},
    {name: translation[gat.title], link: getRoute(AppRoute.Assessments)},
    {name: translation[cat.title]},
  ]

  const links = [
    {
      name: translation[st.editPublicProfile],
      route: AppRoute.PublicProfile,
    },
    {
      name: translation[st.editCv],
      route: AppRoute.Profile,
    },
  ]

  const Footer: FC = () => {
    return (
      <>{isMobile ? <MobileFooter links={links} /> : <FooterAssessments />}</>
    )
  }

  const linkRef = useRef<HTMLAnchorElement | null>(null)

  useEffect(() => {
    // download hr potentials test results
    if (
      linkRef &&
      linkRef.current &&
      downloadLink &&
      downloadLink.url &&
      resultButtonClicked
    ) {
      linkRef.current.click()
    }
  }, [downloadLink])

  useEffect(() => {
    getAssessments()
    getCognitiveTestResults('')
    getConsents()
  }, [])

  useEffect(() => {
    if (consents && consents.length > 0) {
      const cognitiveTest = consents.find((consent) => {
        return consent.id === SeekerConsentId.CognitiveTest
      })

      setCognitiveConsent(
        cognitiveTest?.consentVersions[0].userConsentsVersions.find(
          (cognitiveConsent) => {
            return cognitiveConsent?.company === null
          }
        )
      )

      setCognitiveConsentId(cognitiveTest?.id)
    }
  }, [consents])

  const buttonContent = (
    status: string | undefined,
    testId: string | undefined
  ) => {
    if (status === 'completed' && cognitiveConsent?.status === 'granted') {
      return (
        <YellowButton
          text={translation[gat.viewResultsText]}
          onClick={() => {
            // TODO this is a quick fix for triggering results download when going from page to page, it would be better to set results with action to an empty array
            setResultButtonClicked(true)
            getCognitiveTestResults('')
          }}
        />
      )
    } else if (
      status === 'completed' &&
      cognitiveConsent?.status === 'revoked'
    ) {
      return
    } else if (status === 'results_pending') {
      return (
        <YellowButton
          text={translation[gat.pendingButtonText]}
          className={styles.pendingButton}
        />
      )
    } else {
      if (testId) {
        return (
          <YellowButton
            onClick={() => {
              setModalOpen(true)
            }}
            text={translation[gat.startTestText]}
          />
        )
      }
      return
    }
  }

  return (
    <MainLayout
      withoutLogo
      content={
        <div className={styles.linksWrap}>
          <SidebarLink
            text={st.editPublicProfile}
            routeName={AppRoute.PublicProfile}
          />
          <SidebarLink text={st.editCv} routeName={AppRoute.Profile} />
        </div>
      }
      Footer={<Footer />}
    >
      <div className={styles.testContainer}>
        <DownloadAndPrintHeader
          setResultButtonClicked={setResultButtonClicked}
          downloadLink={
            cognitiveTest?.status === 'completed' &&
            downloadLink.url &&
            cognitiveConsent?.status === 'granted'
              ? downloadLink.url
              : undefined
          }
        />
        <MainHeader
          breadcrumbsArray={breadcrumbsArray}
          title={translation[cat.title]}
          className={styles.cognitiveTestHeader}
          image={
            <img
              className={styles.cognitiveIcon}
              src={CognitiveIcon}
              alt={translation[cat.title]}
            />
          }
        />
        <div className={styles.testContent}>
          <h2 className={styles.title}>
            Merjenje kognitivnih sposobnosti je najbolj pogosto orodje za
            ocenjevanje posameznikov tako na delovnem mestu kot nasploh.
          </h2>
          <p className={styles.text}>
            Vsi smo že kdaj slišali za t.i. IQ test, ki naj bi nam povedal,
            kakšne so naše sposobnosti. Dejansko pa ne določa le rezultat IQ
            testa naših sposobnosti in uspeha v življenju (ali karieri ali pri
            študiju). Del naše inteligentnosti je vezan na znanje, ki ga
            pridobivamo z izobraževanji, izkušnjami in vsemi informacijami, ki
            smo jih kadarkoli v življenju prejeli in si jih zapomnili. Ta
            »inteligentnost« se imenjue kristalizirana inteligentnost in narašča
            skozi vse naše življenje.
          </p>
          <p className={styles.text}>
            Drugi del naše »inteligentnosti« pa se nanaša na sposobnost
            prepoznavanja vzorcev oziroma osmišljanje novih informacij; na našo
            sposobnost hitrega učenja in razumevanja novosti. Testiranje, ki je
            pred vami, je namenjeno merjenju ravno te inteligentnosti, ki jih
            običajno sestavljajo naloge, ki zahtevajo sklepanje. Največja
            prednost takšnih testov je, da za uspešno reševanje ne zahtevajo
            nobenega znanja in na njihov rezultat ne vpliva izobrazba.
          </p>
          <p className={styles.text}>
            Kakšne koristne informacije nam nudi testiranje kognitivnih
            sposobnosti:
          </p>
          <ul>
            <li>Kako učinkovito se učimo novih spretnosti,</li>
            <li>Kako hitro se učimo novih spretnosti, </li>
            <li>Naše sposobnosti analiziranja informacij,</li>
            <li>
              Naše sposobnosti združevanja novih in obstoječih informacij (pri
              svojem delu).
            </li>
          </ul>
          <p className={styles.text}>
            Na ta način dobimo uvid v svoje sposobnosti povezovanja informacij,
            ki nam koristijo tako pri vodenju projektov v oglaševalski agenciji
            kot pri popravljanju varilnih strojev v avtomobilskem obratu.
          </p>
          <p className={styles.text}>
            Zelo pomembno je pri tem poudariti, da ne glede na naš rezultat na
            kognitivnem testiranju, nam testiranje ne more napovedati, kako
            uspešni bomo pri svojem delu ali v življenju. Naša uspešnost je
            odvisna od številnih faktorjev, med drugim tudi od naših osebnostih
            lastnosti, vztrajnosti, želje in motivacije za uspeh.
          </p>
          <p className={styles.text}>
            Test, ki je pred vami, je sestavljen iz 32 nalog. Poiskati boste
            morali nadaljevanje zaporedja. Test je časovno omejen na 30 minut,
            zato je pomembno, da če ne najdete rešitve, preskočite nalogo in se
            lotite naslednje. Zahtevnost nalog se stopnjuje.
          </p>
          {(cognitiveTest?.status === 'invited' ||
            cognitiveTest?.status === 'completed') && (
            <div className={styles.testMicrophoneCameraNotificationWrapper}>
              <WhiteBoxWrapper
                children={
                  cognitiveTest?.status === 'invited' ? (
                    <TestMicrophoneCameraNotificationContent />
                  ) : (
                    <TestResultsWhiteBoxContent
                      children={
                        cognitiveConsent?.status === 'granted' ? (
                          <>
                            <h3>
                              {cognitiveTest.name === 'Cognitive test' ? (
                                <FormattedMessage
                                  id={cat.cognitiveTestResults}
                                />
                              ) : (
                                <FormattedMessage
                                  id={pat.psychometricTestResults}
                                />
                              )}
                            </h3>
                            <p>
                              <FormattedMessage id={gat.resultsValidUntil} />{' '}
                              {cognitiveTest.expirationDate &&
                                new Date(
                                  cognitiveTest.expirationDate
                                ).toLocaleDateString('sl-SI')}
                            </p>
                          </>
                        ) : (
                          <p>
                            <FormattedMessage
                              id={cat.cognitiveTestNoConsentBoxValue}
                            />{' '}
                            <Link
                              className={styles.consentLink}
                              to={getRoute(AppRoute.Consents)}
                            >
                              <FormattedMessage id={ct.title} />
                            </Link>
                          </p>
                        )
                      }
                    />
                  )
                }
              />
            </div>
          )}

          <div className={styles.buttonWrapper}>
            <div className={styles.buttonContainer}>
              {buttonContent(cognitiveTest?.status, cognitiveTest?.id)}
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalOpen}
          onAfterClose={() => {
            setModalOpen(false)
          }}
          title={mat.cognitiveTestTitle}
          icon={EditOutlined}
          ariaHideApp={false}
          className={styles.modal}
          Footer={
            <AssessmentsSubmitFooter
              disabled={!checkboxValue}
              userId={user ? user.id : ''}
              consentId={cognitiveConsent?.id}
              onSubmit={() => {
                setCognitiveConsentId(cognitiveTest?.id)
                changeCognitiveConsentStatus({
                  consentId: cognitiveConsentId as SeekerConsentId,
                  consentStatus: 'granted',
                  testId: cognitiveTest?.id as string,
                })
                setModalOpen(false)
              }}
              onCancel={() => {
                setModalOpen(false)
              }}
              submitText={translation[ct.consentGrant]}
            />
          }
        >
          <div className={styles.modalCognitiveTestContainer}>
            <h2 className={styles.modalTitle}>
              <FormattedMessage id={mat.cognitiveTestTitle} />
            </h2>
            <div className={styles.checkboxTextWrapper}>
              <CheckBox
                name="consent1B"
                value={checkboxValue ? checkboxValue : false}
                onChange={() => {
                  setCheckboxValue && setCheckboxValue(!checkboxValue)
                }}
                topAlign
              />
              <p className={styles.modalText}>
                <FormattedMessage id={mat.cognitiveTestTextFirstPart} />{' '}
                <Link to={getRoute(AppRoute.HomePageCandidate)}>
                  Preferenca.si
                </Link>
                . <FormattedMessage id={mat.cognitiveTestTextSecondPart} />{' '}
                <Link
                  to={getRoute(AppRoute.PrivacyPolicy)}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <FormattedMessage id={mat.privacyPolicy} />.
                </Link>
              </p>
            </div>
          </div>
        </Modal>

        <a
          href={
            cognitiveTest?.status === 'completed' && downloadLink.url
              ? downloadLink.url
              : '#'
          }
          className={styles.invisibleLink}
          ref={linkRef}
          download
        ></a>
      </div>
    </MainLayout>
  )
}
