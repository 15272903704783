import {DataType, LanguageLevelType} from './BaseType'

export interface BaseTaxonomyType {
  id: string
  translation: string
  other?: boolean
  name?: string
}
export interface AvailabilityType {
  id: string
  translation: string
  name: string
  other?: boolean
}

export interface TaxonomyWithCategoryType extends BaseTaxonomyType {
  category?: string
  jobCategory?: BaseTaxonomyType
  skillCategory?: BaseTaxonomyType
}

export interface SystemLanguageType {
  id: string
  name: string
}

export interface SearchKeywordType {
  occupations?: TaxonomyWithCategoryType[]
  jobCategories?: BaseTaxonomyType[]
  skills?: TaxonomyWithCategoryType[]
  employers?: BaseTaxonomyType[]
}

export type TaxonomiesStateType = {
  systemLanguages: DataType<SystemLanguageType>
  languageLevels: {data: LanguageLevelType[]}
} & {
  [name: string]: DataType<BaseTaxonomyType>
}

export interface TaxonomiesQueryType {
  [name: string]: string
}

export enum TestGroupEnum {
  skillTest = 'Skill test',
  languageTest = 'Language test',
}
