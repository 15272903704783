import {FC, useState} from 'react'
import {DateTime} from 'luxon'
import {FormattedMessage} from 'react-intl'
import classnames from 'classnames'

import Modal from 'Components/Modal'
import {ThumbsFooter} from 'Components/Modal/ThumbsFooter'
import styles from './ModalInterviewInvitation.module.scss'
import ModalInterviewInvitationReject from './ModalRejectInterviewInvitation'
import ProposeNewTimeModal from 'Components/IncomingRequests/RequestActions/ProposeNewTimeModal'
import {IncomingRequestType, ApproveRejectModalRequestType} from 'Interfaces'

import {
  CandidateDashboardTranslations as cdt,
  EmployerDashboardTranslations as edt,
} from 'Services/I18n/Constants'
import {
  Business,
  ConnectWithoutContact,
  Notifications,
  DateRange,
  Update,
  HourglassBottom,
  DescriptionOutlined,
  HistoryEdu,
} from '@mui/icons-material'
import {formatDate} from 'Utils/Date'
import {
  CombinedRequestStatues,
  seekerRequestStatusTranslationMap,
  InterviewRequestStatus,
} from 'Components/IncomingRequests/Constants'

const ExpirationValue: FC<{date: string}> = ({date}) => {
  const now = DateTime.now()
  // TODO specto flow:
  // TODO candidate-dashboard: Set locale:
  // const expiration = DateTime.fromISO(date).setLocale()
  const expiration = DateTime.fromISO(date)
  const diff = Math.floor(expiration.diff(now, ['days']).days)
  let dayOfTheWeek = expiration.toFormat('cccc')
  if (now.day === expiration.day) {
    return (
      <div className={styles.expirationValueContainer}>
        <p className={styles.expirationValueTextRed}>
          <FormattedMessage id={cdt.expiresToday} />
        </p>
      </div>
    )
  }
  if (diff > 7 || expiration < now) {
    return (
      <p className={styles.expirationValueNormal}>
        <FormattedMessage id={dayOfTheWeek} />
        <span className={styles.date}>
          {expiration.toFormat(' (d.L.yyyy)')}
        </span>
      </p>
    )
  }

  return (
    <div className={styles.expirationValueContainer}>
      <p className={styles.expirationValueTextRed}>
        <FormattedMessage id={cdt.in} />
        {` ${diff} `}
        {diff === 1 ? (
          <FormattedMessage id={cdt.day} />
        ) : (
          <FormattedMessage id={cdt.days} />
        )}
      </p>
      <p className={styles.expirationValueText}>
        {expiration.toFormat('(d.L.yyyy)')}
      </p>
    </div>
  )
}
interface ModalInterviewInvitationProps {
  request: IncomingRequestType
  isOpenInterviewInvitation: boolean
  setIsOpenInterviewInvitation: (open: boolean) => void
  modalDetails?: boolean
  setModalDetails?: (modalDetails: boolean) => void
  showDetails?: boolean
  approveInterviewInvitation: (payload: ApproveRejectModalRequestType) => void
  setCancelModal?: (open: boolean) => void
}

export const ModalInterviewInvitation: FC<ModalInterviewInvitationProps> = ({
  request,
  isOpenInterviewInvitation,
  setIsOpenInterviewInvitation,
  approveInterviewInvitation,
  setModalDetails,
  modalDetails,
  showDetails = true,
  setCancelModal,
}) => {
  const [details, setDetails] = useState(modalDetails)
  const [rejectModal, setRejectModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Modal
        className={styles.modalDetails}
        icon={DescriptionOutlined}
        isOpen={isOpenInterviewInvitation}
        title={cdt.interviewInvitationDetails}
        onAfterClose={() => {
          setIsOpenInterviewInvitation(false)
          setModalDetails && setModalDetails(false)
        }}
        shouldReturnFocusAfterClose={false}
        Footer={
          request.status === CombinedRequestStatues.NEW_TIME_PROPOSED &&
          request.seekerReason === null ? (
            <ThumbsFooter
              approveText={cdt.accept}
              rejectText={cdt.reject}
              onApprove={() => {
                approveInterviewInvitation({
                  id: request.id,
                })

                setIsOpenInterviewInvitation(false)
              }}
              onReject={() => {
                setRejectModal(true)
              }}
              onCancel={() => {
                setIsOpenInterviewInvitation(false)
                setModalDetails && setModalDetails(false)
              }}
            />
          ) : request.status === CombinedRequestStatues.INTERVIEW_CONFIRMED ? (
            <ThumbsFooter
              approveText={cdt.proposeNewTime}
              rejectText={edt.cancelInterview}
              onApprove={() => {
                setIsOpen(true)
                setIsOpenInterviewInvitation(false)
              }}
              onReject={() => {
                setCancelModal && setCancelModal(true)
                setIsOpenInterviewInvitation(false)
              }}
              onCancel={() => {
                setIsOpenInterviewInvitation(false)
                setModalDetails && setModalDetails(false)
              }}
              noThumbIcon
            />
          ) : null
        }
      >
        <div className={styles.containerInterviewDetails}>
          <p className={styles.title}>{request.companyName}</p>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <Business className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.company} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              <p className={styles.itemRightText}>{request.companyName}</p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <ConnectWithoutContact className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.requestType} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              <p className={styles.videoText}>
                <FormattedMessage id={cdt.interviewInvitation} />
              </p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <Notifications className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.requestStatus} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              <p
                className={classnames(styles.pending, {
                  [styles.redStatus]:
                    request.status === InterviewRequestStatus.REJECTED ||
                    request.status === InterviewRequestStatus.CANCELLED ||
                    request.status === InterviewRequestStatus.NOT_ATTENDED,
                  [styles.accepted]:
                    request.status ===
                    InterviewRequestStatus.INTERVIEW_CONFIRMED,
                  [styles.expired]:
                    request.status === InterviewRequestStatus.EXPIRED,
                  [styles.completed]:
                    request.status === InterviewRequestStatus.COMPLETED,
                  [styles.newTimeProposal]:
                    request.status === InterviewRequestStatus.NEW_TIME_PROPOSED,
                })}
              >
                <FormattedMessage
                  id={seekerRequestStatusTranslationMap[request.status]}
                />
              </p>
            </div>
          </div>
          {request.startDate && request.endDate && (
            <div className={styles.item}>
              <div className={styles.itemLeft}>
                <DateRange className={styles.icon} />
                <p className={styles.dateTimeLeft}>
                  <span>
                    <FormattedMessage id={cdt.InterviewDate} /> &nbsp;
                  </span>
                  <span>
                    & <FormattedMessage id={cdt.time} />:
                  </span>
                </p>
              </div>
              <div className={styles.itemRight}>
                <p className={styles.dateTime}>
                  {DateTime.fromISO(request.startDate).toFormat('d.L.yyyy')}
                  <span className={styles.line}>&nbsp;|&nbsp;</span>
                  {`${DateTime.fromISO(request.startDate).toFormat(
                    'HH:mm'
                  )} - ${DateTime.fromISO(request.endDate).toFormat('HH:mm')}`}
                </p>
                {request.status !== InterviewRequestStatus.CANCELLED &&
                  request.status !== InterviewRequestStatus.NEW_TIME_PROPOSED &&
                  request.status !== InterviewRequestStatus.REJECTED &&
                  request.status !==
                    InterviewRequestStatus.INTERVIEW_CONFIRMED && (
                    <button
                      onClick={() => {
                        setIsOpen(true)
                      }}
                      className={styles.button}
                    >
                      <FormattedMessage id={cdt.proposeNewTime} />
                    </button>
                  )}
              </div>
            </div>
          )}
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <Update className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.dateOfRequestSent} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              <p className={styles.itemRightText}>
                {request.sentDate && formatDate(request.sentDate)}
              </p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <HourglassBottom className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.expirationDate} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              {request.expirationDate && (
                <ExpirationValue date={request.expirationDate} />
              )}
            </div>
          </div>
          {(request.companyUserReason || request.seekerReason) && (
            <div className={styles.item}>
              <div className={styles.itemLeft}>
                <HistoryEdu className={styles.icon} />
                <p className={styles.dateTimeLeft}>
                  <FormattedMessage id={edt.message} />
                </p>
              </div>
              <div className={styles.itemRight}>
                <p className={styles.paragraphMessage}>
                  {request.companyUserReason || request.seekerReason}
                </p>
              </div>
            </div>
          )}
        </div>
      </Modal>
      <ModalInterviewInvitationReject
        request={request}
        rejecModal={rejectModal}
        setRejectModal={setRejectModal}
        setDetails={setDetails}
        setIsOpenInterviewInvitation={setIsOpenInterviewInvitation}
        showDetails={!details ? showDetails : false}
      />
      <ProposeNewTimeModal
        request={request}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  )
}
