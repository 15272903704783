import {FC, ChangeEvent, useState, memo, useEffect, useRef} from 'react'
import classnames from 'classnames'
import {FormattedMessage} from 'react-intl'
import {ArrowDropDown} from '@mui/icons-material'

import styles from './DropdownTextInput.module.scss'
import {useTranslation} from 'Hooks'

interface DropdownTextInputProps {
  name: string
  value?: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  label?: string
  placeholder?: string
  disabled?: boolean
  theme?: 'default' | 'left'
}

export const DropdownTextInput: FC<DropdownTextInputProps> = memo(
  ({
    name,
    value = '',
    onChange,
    label = 'Text input',
    placeholder = 'Enter text',
    disabled = false,
    theme = 'default',
  }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [localValue, setLocalValue] = useState(value)
    const ref = useRef<HTMLDivElement>(null)
    const translation = useTranslation()

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      setLocalValue(e.currentTarget.value)
      onChange(e)
    }

    useEffect(() => {
      if (value !== localValue) {
        setLocalValue(value)
      }
    }, [value])

    return (
      <div
        className={classnames(styles.container, {
          [styles.open]: isOpen,
          [styles.disabled]: disabled,
          [styles[theme]]: theme,
        })}
        tabIndex={0}
        onBlur={(e) => {
          if (!ref.current?.contains(e.relatedTarget)) setIsOpen(false)
        }}
        ref={ref}
      >
        <div
          className={styles.buttonWrap}
          onClick={() => {
            setIsOpen(!isOpen)
          }}
        >
          <div className={styles.label}>
            <FormattedMessage id={label} />
          </div>
          <ArrowDropDown
            className={classnames(styles.arrow, {
              [styles.rotate]: isOpen,
            })}
          />
        </div>
        <div className={styles.dropdown}>
          <input
            className={styles.input}
            name={name}
            type="text"
            onChange={handleChange}
            value={localValue}
            placeholder={translation[placeholder]}
            disabled={disabled}
          />
        </div>
      </div>
    )
  }
)
