import {
  ActionType,
  BaseTaxonomyType,
  ErrorType,
  TaxonomiesStateType,
} from 'Interfaces'
import * as type from '../types'

const initialState = {
  occupations: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  educationLevels: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  educationInstitutions: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  countries: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  towns: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  workFromHome: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  salaries: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  regions: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  availabilities: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  additionalBenefits: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  drivingLicences: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  drivingLicenseCategories: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  skillLevels: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  skills: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  employmentTypes: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  jobCategory: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  employers: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  jobCategories: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  industry: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  languages: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  languageLevels: {
    data: [],
    loading: false,
    error: undefined,
  },
  workExperiences: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  fieldsOfStudy: {data: [], loading: undefined, error: undefined},
  trainingTitles: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  careerLevels: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  systemLanguages: {
    data: [],
    loading: undefined,
    error: undefined,
  },
  jobSearchStatuses: {
    data: [],
    loading: undefined,
    error: undefined,
  },
}

const TaxonomiesReducer = (
  state: TaxonomiesStateType = initialState,
  action: ActionType<BaseTaxonomyType[] | ErrorType | undefined>
): TaxonomiesStateType => {
  const name = action.type.split('_')[0]
  switch (action.type) {
    case type.USER_LOGOUT_SUCCEEDED:
      return {
        ...initialState,
      }

    case type.educationLevels.requested:
    case type.skillLevels.requested:
    case type.skills.requested:
    case type.educationInstitutions.requested:
    case type.countries.requested:
    case type.employmentTypes.requested:
    case type.jobCategory.requested:
    case type.employers.requested:
    case type.towns.requested:
    case type.jobCategories.requested:
    case type.industry.requested:
    case type.regions.requested:
    case type.numberOfRecruits.requested:
    case type.workFromHome.requested:
    case type.salaries.requested:
    case type.occupations.requested:
    case type.availabilities.requested:
    case type.workExperiences.requested:
    case type.additionalBenefits.requested:
    case type.fieldsOfStudy.requested:
    case type.trainingTitles.requested:
    case type.systemLanguages.requested:
    case type.languages.requested:
    case type.languageLevels.requested:
    case type.careerLevels.requested:
    case type.jobSearchStatuses.requested:
    case type.drivingLicenseCategories.requested:
      return {
        ...state,
        [name]: {
          data: [],
          loading: true,
          error: undefined,
        },
      }
    case type.educationLevels.succeeded:
    case type.skillLevels.succeeded:
    case type.skills.succeeded:
    case type.educationInstitutions.succeeded:
    case type.countries.succeeded:
    case type.employmentTypes.succeeded:
    case type.jobCategory.succeeded:
    case type.employers.succeeded:
    case type.towns.succeeded:
    case type.jobCategories.succeeded:
    case type.industry.succeeded:
    case type.regions.succeeded:
    case type.numberOfRecruits.succeeded:
    case type.workFromHome.succeeded:
    case type.salaries.succeeded:
    case type.occupations.succeeded:
    case type.availabilities.succeeded:
    case type.workExperiences.succeeded:
    case type.additionalBenefits.succeeded:
    case type.fieldsOfStudy.succeeded:
    case type.trainingTitles.succeeded:
    case type.systemLanguages.succeeded:
    case type.languages.succeeded:
    case type.languageLevels.succeeded:
    case type.careerLevels.succeeded:
    case type.jobSearchStatuses.succeeded:
    case type.drivingLicenseCategories.succeeded:
      return {
        ...state,
        [name]: {
          data: action.payload as BaseTaxonomyType[],
          loading: false,
          error: undefined,
        },
      }
    case type.educationLevels.failed:
    case type.skillLevels.failed:
    case type.skills.failed:
    case type.educationInstitutions.failed:
    case type.countries.failed:
    case type.employmentTypes.failed:
    case type.jobCategory.failed:
    case type.employers.failed:
    case type.towns.failed:
    case type.jobCategories.failed:
    case type.regions.failed:
    case type.numberOfRecruits.failed:
    case type.workFromHome.failed:
    case type.salaries.failed:
    case type.occupations.failed:
    case type.availabilities.failed:
    case type.workExperiences.failed:
    case type.additionalBenefits.failed:
    case type.fieldsOfStudy.failed:
    case type.trainingTitles.failed:
    case type.systemLanguages.failed:
    case type.languages.failed:
    case type.languageLevels.failed:
    case type.careerLevels.failed:
    case type.jobSearchStatuses.failed:
    case type.drivingLicenseCategories.failed:
      return {
        ...state,
        [name]: {
          data: [],
          loading: false,
          error: action.payload as ErrorType,
        },
      }
    default:
      return state
  }
}

export default TaxonomiesReducer
