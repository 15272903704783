import {support} from './Support/sl'
import {privacyPolicy} from './PrivacyPolicy/sl'
import {termsAndConditions} from './TermsAndConditions/sl'
import {dataProtectionAgreement} from './DataProtectionAgreement/en'

export const publicPages = {
  ...support,
  ...privacyPolicy,
  ...termsAndConditions,
  ...dataProtectionAgreement,
}
