import {connect} from 'react-redux'

import {WorkExperiences} from './WorkExperiences'
import {AppDispatch} from 'Store'
import {
  addWorkExperience,
  deleteWorkExperience,
  editWorkExperience,
  getCountries,
  getEmployers,
  getEmploymentTypes,
  getJobCategory,
  getOccupations,
} from 'Store/Actions'
import {RootState} from 'Store/Reducers'
import {WorkExperienceFormikValue} from 'Interfaces'
import {canChangeProfile} from 'Utils/Profile'

const mapStateToProps = (state: RootState) => ({
  taxonomies: state.taxonomies,
  workExperiences: state.seeker.data?.full?.workExperiences,
  profile: state.seeker,
  canChange: canChangeProfile(state.auth.data?.rjbProfileStatus),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addWorkExperience: (value: WorkExperienceFormikValue) =>
    dispatch(addWorkExperience(value)),
  editWorkExperience: (value: WorkExperienceFormikValue) =>
    dispatch(editWorkExperience(value)),
  deleteWorkExperience: (id: string) => dispatch(deleteWorkExperience(id)),
  getTaxonomies: () => (
    dispatch(getEmploymentTypes()),
    dispatch(getJobCategory()),
    dispatch(getOccupations()),
    dispatch(getEmployers()),
    dispatch(getCountries())
  ),
})

export const WorkExperiencesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkExperiences)
