export const unsubscribePage = {
  'unsubscribePage.title': 'Žal nam je, da odhajate',
  'unsubscribePage.subtitle':
    'Želim prejemati obvestila iz označenih področij:',
  'unsubscribePage.description':
    '  Tukaj smo zaradi vas in pomembno nam je, da vam posredujemo relevantne vsebine. Preden se razidemo, vas prosimo, da iz spodnjega seznama izberete teme, ki vas še vedno zanimajo.',
  'unsubscribePage.warning': 'Prišlo je do napake. Prosimo, poskusite ponovno.',
  'unsubscribePage.helpTxt':
    ' Če ne želite prejemati nobenih obvestil več, odstranite vse kljukice.',
  'unsubscribePage.footer':
    'Vaše preference bodo posodobljene v kratkem. V tem času boste morda še prejeli naša obvestila, ki so že v odpošiljanju, zato vas prosimo za razumevanje.',
  'unsubscribePage.submit': 'Shrani',
}
