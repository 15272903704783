import {FC, ChangeEvent, MouseEventHandler} from 'react'
import classnames from 'classnames'
import styles from './Checkbox.module.scss'
import ReportProblemIcon from '@mui/icons-material/ReportProblem'
import {CheckBoxIcon} from 'Components/Icons'
import {FormattedMessage} from 'react-intl'

interface CheckBoxProps {
  value: boolean
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  name: string
  error?: string
  className?: string
  topAlign?: boolean
  statement?: boolean
  showTextError?: boolean
  setTouched?: (v: boolean) => void
  size?: number
  theme?: 'modal'
  disabled?: boolean
  onMouseEnter?: MouseEventHandler<HTMLDivElement>
  onMouseLeave?: MouseEventHandler<HTMLDivElement>
}

export const Checkbox: FC<CheckBoxProps> = ({
  value,
  onChange,
  name,
  error,
  showTextError = false,
  children,
  className,
  topAlign = false,
  statement = false,
  setTouched = () => {},
  size = 24,
  theme = 'none',
  disabled = false,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <>
      <div
        className={classnames(styles.checkContainer, className, {
          [styles.topAlign]: topAlign,
          [styles.error]: error,
          [styles.statement]: statement,
          [styles[theme]]: theme,
        })}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        {error && <ReportProblemIcon className={styles.reportProblemIcon} />}
        <label
          htmlFor={name}
          tabIndex={0}
          className={styles.label}
          style={{width: size, height: size}}
        >
          <CheckBoxIcon
            checked={value}
            className={classnames(styles.checkboxIcon, {[styles.error]: error})}
          />
          <input
            onChange={onChange}
            id={name}
            className={styles.hidden}
            type="checkbox"
            checked={value}
            onClick={() => setTouched(true)}
            disabled={disabled}
          />
        </label>
        <div
          className={classnames(styles.checkBoxText, {[styles.error]: error})}
        >
          {children}
        </div>
      </div>
      {showTextError && error && (
        <div className={styles.errorText}>
          <FormattedMessage id={error} />
        </div>
      )}
    </>
  )
}
