export const getFacetProp = (key: string): string => {
  const values = {
    availabilityFacets: 'availabilities',
    // additionalBenefitFacets: 'additionalBenefits',
    // careerStatusFacets: 'careerStatuses',
    currentJobCategoryFacets: 'jobCategories',
    // educationFieldOfStudyFacets: 'Education',
    educationLevelFacets: 'educationLevels',
    // employmentTypeFacets: 'Employment type',
    languageTaxonomyFacets: 'languages',
    languageLevelFacets: 'languageLevels',
    occupationFacets: 'occupations',
    regionFacets: 'regions',
    // salaryFacets: 'salaries',
    skillFacets: 'skills',
    skillLevelFacets: 'skillLevels',
    drivingLicenseFacets: 'drivingLicenseCategories',
    totalWorkExperienceFacets: 'workExperiences',
    // workExperienceJobCategoryFacets: 'Work experience job category',
  }
  return values[key] || ''
}
