import {FC, useState} from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {QuestionValue} from 'Interfaces'
import styles from './QuestionsCategoryItem.module.scss'
import classnames from 'classnames'

interface QuestionsCategoryItemProps {
  question: QuestionValue
}

export const QuestionsCategoryItem: FC<QuestionsCategoryItemProps> = ({
  question,
}) => {
  const [showMore, setShowMore] = useState(false)

  return (
    <div
      className={styles.questionsCategoryItem}
      onClick={() => setShowMore(!showMore)}
    >
      <div className={styles.question}>
        <p className={styles.questionLeft}>Q:</p>
        <p
          className={classnames(styles.questionMiddle, {
            [styles.questionMiddleMore]: showMore,
          })}
        >
          {question.question}
        </p>
        <ArrowDropDownIcon
          className={classnames(styles.questionRight, {
            [styles.questionRightMore]: showMore,
          })}
        />
      </div>
      <div
        className={classnames(styles.answer, {
          [styles.answerMore]: showMore,
        })}
      >
        <p className={styles.answerLeft}>A:</p>
        <div className={styles.answerContainer}>
          <div
            className={classnames(styles.answerFirstPart, {
              [styles.answerRight]:
                question.answerTitle && question.answerSecondPart,
            })}
          >
            {question.answerTitle && (
              <b>
                <p
                  className={classnames(styles.answerRight, {
                    [styles.inlineParagraph]: question.answerTitle,
                  })}
                >
                  {question.answerTitle && question.answerTitle}
                </p>
              </b>
            )}
            <p
              className={classnames(styles.answerRight, {
                [styles.inlineParagraph]: question.answerTitle,
              })}
            >
              {question.answer}
            </p>
          </div>
          {question.answerSecondPart && (
            <div
              className={classnames(styles.answerRight, {
                [styles.inlineParagraph]: question.answerSecondPartTitle,
              })}
            >
              {question.answerSecondPartTitle && (
                <b>
                  <p
                    className={classnames(styles.answerRight, {
                      [styles.inlineParagraph]: question.answerSecondPartTitle,
                    })}
                  >
                    {question.answerSecondPartTitle &&
                      question.answerSecondPartTitle}
                  </p>
                </b>
              )}
              <p
                className={classnames(styles.answerRight, {
                  [styles.inlineParagraph]: question.answerSecondPartTitle,
                })}
              >
                {question.answerSecondPart}
              </p>
            </div>
          )}
          {question.answerThirdPart && (
            <div
              className={classnames(styles.answerRight, {
                [styles.inlineParagraph]: question.answerThirdPartTitle,
              })}
            >
              {question.answerThirdPartTitle && (
                <b>
                  <p
                    className={classnames(styles.answerRight, {
                      [styles.inlineParagraph]: question.answerThirdPartTitle,
                    })}
                  >
                    {question.answerThirdPartTitle &&
                      question.answerThirdPartTitle}
                  </p>
                </b>
              )}
              <p
                className={classnames(styles.answerRight, {
                  [styles.inlineParagraph]: question.answerThirdPartTitle,
                })}
              >
                {question.answerThirdPart}
              </p>
            </div>
          )}
          {question.answerFourthPart && (
            <div
              className={classnames(styles.answerRight, {
                [styles.inlineParagraph]: question.answerFourthPartTitle,
              })}
            >
              {question.answerFourthPartTitle && (
                <b>
                  <p
                    className={classnames(styles.answerRight, {
                      [styles.inlineParagraph]: question.answerFourthPartTitle,
                    })}
                  >
                    {question.answerFourthPartTitle &&
                      question.answerFourthPartTitle}
                  </p>
                </b>
              )}
              <p
                className={classnames(styles.answerRight, {
                  [styles.inlineParagraph]: question.answerFourthPartTitle,
                })}
              >
                {question.answerFourthPart}
              </p>
            </div>
          )}
          {question.answerFifthPart && (
            <div
              className={classnames(styles.answerRight, {
                [styles.inlineParagraph]: question.answerFifthPartTitle,
              })}
            >
              {question.answerFifthPartTitle && (
                <b>
                  <p
                    className={classnames(styles.answerRight, {
                      [styles.inlineParagraph]: question.answerFifthPartTitle,
                    })}
                  >
                    {question.answerFifthPartTitle &&
                      question.answerFifthPartTitle}
                  </p>
                </b>
              )}
              <p
                className={classnames(styles.answerRight, {
                  [styles.inlineParagraph]: question.answerFifthPartTitle,
                })}
              >
                {question.answerFifthPart}
              </p>
            </div>
          )}
          {question.answerSixthPart && (
            <div
              className={classnames(styles.answerRight, {
                [styles.inlineParagraph]: question.answerSixthPartTitle,
              })}
            >
              {question.answerSixthPartTitle && (
                <b>
                  <p
                    className={classnames(styles.answerRight, {
                      [styles.inlineParagraph]: question.answerSixthPartTitle,
                    })}
                  >
                    {question.answerSixthPartTitle &&
                      question.answerSixthPartTitle}
                  </p>
                </b>
              )}
              <p
                className={classnames(styles.answerRight, {
                  [styles.inlineParagraph]: question.answerSixthPartTitle,
                })}
              >
                {question.answerSixthPart}
              </p>
              <br />
              <br />
              {question.answerSixthPart2 && (
                <p
                  className={classnames(styles.answerRight, {
                    [styles.inlineParagraph]: question.answerSixthPartTitle,
                  })}
                >
                  {question.answerSixthPart2}
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
