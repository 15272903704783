import {connect} from 'react-redux'

import {LanguageSettings} from './LanguageSettings'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {
  getSystemLanguages,
  updateContentLanguage,
  updateSystemLanguage,
} from 'Store/Actions'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  languageId: state.auth.data?.languageId,
  contentLanguageId: state.auth.data?.contentLanguageId,
  systemLanguages: state.taxonomies.systemLanguages,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getTaxonomies: () => {
    dispatch(getSystemLanguages())
  },
  updateSystemLanguage: (id: string) => dispatch(updateSystemLanguage(id)),
  updateContentLanguage: (id: string) => dispatch(updateContentLanguage(id)),
})

export const LanguageSettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageSettings)
