import {FC} from 'react'

import {FormattedMessage} from 'react-intl'
import classNames from 'classnames'

import styles from './TestName.module.scss'

interface TestNameProps {
  icon?: JSX.Element
  testName: string
  className?: string
}

export const TestName: FC<TestNameProps> = ({icon, testName, className}) => {
  return (
    <div
      className={classNames(
        styles.testWrapper,
        styles.testNameWrapper,
        className
      )}
    >
      {icon && <div className={styles.iconDesktop}>{icon}</div>}
      <p className={styles.testName}>
        <FormattedMessage id={testName} />
      </p>
    </div>
  )
}
