import {NewInterviewTimePayloadType} from 'Interfaces'
import {connect} from 'react-redux'

import {AppDispatch} from 'Store'
import {proposeNewTime} from 'Store/Actions'
import {ProposeNewTimeModal} from './ProposeNewTimeModal'

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  proposeNewTime: (payload: NewInterviewTimePayloadType) =>
    dispatch(proposeNewTime(payload)),
})

export const ProposeNewTimeModalContainer = connect(
  null,
  mapDispatchToProps
)(ProposeNewTimeModal)
