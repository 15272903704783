import {FC, useState} from 'react'
import {Link} from 'react-router-dom'
import classNames from 'classnames'
import {BasicConsentItemProps} from 'Interfaces/ConsentsType'
import {Status} from 'Components/Status'
import {FeatherIcon} from 'Components/Icons/FeatherIcon'
import {Button} from 'Components/Button'
import {SwitchContainer} from 'Components/Switch'
import Modal from 'Components/Modal'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import {
  AppRoute,
  CandidateDashboardTranslations as cdt,
  ConsentsTranslations as ct,
  ModalsAssessmentsTranslations as mat,
} from 'Services/I18n/Constants'
import {getRoute} from 'Services/I18n/Utils'
import Hr from 'Components/Hr'
import {useTranslation} from 'Hooks'
import {FormattedMessage} from 'react-intl'
import {isNewsletter} from '../ConsentItemContainer'
import {Check, Close, HistoryEduOutlined} from '@mui/icons-material'
import {SeekerConsentId} from 'Interfaces'

import styles from './ConsentItem.module.scss'

export const ConsentItem: FC<BasicConsentItemProps> = ({
  type,
  consent,
  className,
  changeConsentStatus,
  subcategory,
  switchConsent,
  consentName,
  consentId,
  consentCategory,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [modalText, setModalText] = useState('')
  const [checkboxValue, setCheckboxValue] = useState(false)
  const translation = useTranslation()

  let route
  switch (consentId) {
    case SeekerConsentId.TermsAndConditionsRjb:
      route = AppRoute.TermsAndConditions
      break
    case SeekerConsentId.GeneralTermsOfUse:
    case SeekerConsentId.CompanyGeneralTermsOfUse:
      route = AppRoute.GeneralTermsAndConditions
      break
    case SeekerConsentId.PrivacyPolicy:
    case SeekerConsentId.PrivacyPolicyCompany:
    case SeekerConsentId.PrivacyPolicyRjbAnex:
      route = AppRoute.PrivacyPolicy
      break
    case SeekerConsentId.DataProtectionAgreement:
      route = AppRoute.DataProtectionAgreement
      break
    default:
      route = AppRoute.PrivacyPolicy
  }

  const modalContent = () => {
    switch (consentId) {
      case SeekerConsentId.GeneralTermsOfUse:
      case SeekerConsentId.CompanyGeneralTermsOfUse:
      case SeekerConsentId.TermsAndConditionsRjb:
      case SeekerConsentId.PrivacyPolicy:
      case SeekerConsentId.PrivacyPolicyCompany:
      case SeekerConsentId.PrivacyPolicyRjbAnex:
      case SeekerConsentId.DataProtectionAgreement:
        setModalText(translation[ct.modalCantBeRevoked])
        break
      default:
        return
    }
  }

  const ModalBlockquote: FC<{consentId: string}> = ({consentId}) => {
    if (consentId === SeekerConsentId.PsychometricTest) {
      return (
        <p className={styles.modalTextTests}>
          <FormattedMessage id={mat.psychometricTestTextFirstPart} />{' '}
          <Link to={getRoute(AppRoute.HomePageCandidate)}>Preferenca.si</Link>
          , <FormattedMessage id={mat.psychometricTestTextSecondPart} />{' '}
          <Link
            to={getRoute(AppRoute.PrivacyPolicy)}
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            <FormattedMessage id={mat.privacyPolicy} />
          </Link>
        </p>
      )
    } else if (consentId === SeekerConsentId.CognitiveTest) {
      return (
        <p className={styles.modalTextTests}>
          <FormattedMessage id={mat.cognitiveTestTextFirstPart} />{' '}
          <Link to={getRoute(AppRoute.HomePageCandidate)}>Preferenca.si</Link>
          , <FormattedMessage id={mat.cognitiveTestTextSecondPart} />{' '}
          <Link
            to={getRoute(AppRoute.PrivacyPolicy)}
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            <FormattedMessage id={mat.privacyPolicy} />
          </Link>
        </p>
      )
    } else if (isNewsletter(consentId)) {
      return (
        <p className={styles.modalTextTests}>
          <FormattedMessage id={ct.infoModalNewsletter} />
          <Link
            to={getRoute(AppRoute.PrivacyPolicy)}
            target={'_blank'}
            rel={'noopener noreferrer'}
          >
            <FormattedMessage id={ct.privacyPolicy} />
          </Link>
        </p>
      )
    } else {
      return <FormattedMessage id={ct.modalPersonalData} />
    }
  }
  const ModalUnquotedText: FC<{consentId: string}> = ({consentId}) => {
    return (
      <>
        <p>
          {infoModalMessage[consentId]}
          <Link
            to={getRoute(AppRoute.PrivacyPolicy)}
            target={'_blank'}
            rel={'noopener noreferrer'}
            className={styles.link}
          >
            {consentId === SeekerConsentId.ThirdPartyMarketing ||
            isNewsletter(consentId) ? (
              <FormattedMessage id={ct.privacyPolicy} />
            ) : (
              <FormattedMessage id={ct.privacyPolicyPsychometric} />
            )}
          </Link>
        </p>
        {isNewsletter(consentId) && (
          <p className={styles.modalSecondText}>
            <FormattedMessage id={ct.infoModalNewsletter2} />
          </p>
        )}
      </>
    )
  }

  const modalTitle = {
    [SeekerConsentId.ThirdPartyMarketing]: translation[ct.thirdPartyMarketing],
    [SeekerConsentId.PsychometricTest]:
      translation[cdt.completenessPsychometricTest],
    [SeekerConsentId.CognitiveTest]: translation[mat.cognitiveTestTitle],
  }

  const infoModalMessage = {
    [SeekerConsentId.ThirdPartyMarketing]: (
      <FormattedMessage id={ct.infoModalPersonalData} />
    ),
    [SeekerConsentId.PsychometricTest]: (
      <FormattedMessage id={ct.infoModalPsychometric} />
    ),
    [SeekerConsentId.CognitiveTest]: (
      <FormattedMessage id={ct.infoModalCognitive} />
    ),
    [SeekerConsentId.Newsletter]: (
      <FormattedMessage id={ct.infoModalNewsletter} />
    ),
  }

  const grantedModalMessage = (consentId: string) => {
    if (
      consentId === SeekerConsentId.CognitiveTest ||
      consentId === SeekerConsentId.PsychometricTest
    ) {
      return <FormattedMessage id={ct.modalWithdrawTestsConsents} />
    } else if (isNewsletter(consentId)) {
      return <FormattedMessage id={ct.infoModalNewsletter2} />
    } else if (
      isGeneralTermsOfUse ||
      isPrivacyPolicy ||
      isTermsAndConditions ||
      isDataProtectionAgreement ||
      isPrivacyPolicyRjbAnex
    ) {
      return <FormattedMessage id={ct.modalCantBeRevoked} />
    } else {
      return <FormattedMessage id={ct.modalMarketingText} />
    }
  }

  const renderCheckboxText = (consentId: string) => {
    switch (consentId) {
      case SeekerConsentId.ThirdPartyMarketing:
        return <FormattedMessage id={ct.checkboxMarketing} />
      case SeekerConsentId.Newsletter:
        return <FormattedMessage id={ct.checkboxNewsletter} />
      default:
        return infoModalMessage[consentId]
    }
  }

  const isGeneralTermsOfUse =
    consentId === SeekerConsentId.GeneralTermsOfUse ||
    consentId === SeekerConsentId.CompanyGeneralTermsOfUse

  const isPrivacyPolicy =
    consentId === SeekerConsentId.PrivacyPolicy ||
    consentId === SeekerConsentId.PrivacyPolicyCompany

  const isTermsAndConditions =
    consentId === SeekerConsentId.TermsAndConditionsRjb

  const isPrivacyPolicyRjbAnex =
    consentId === SeekerConsentId.PrivacyPolicyRjbAnex

  const isMarketingGrantModal =
    consentId === SeekerConsentId.ThirdPartyMarketing &&
    (consent?.status === 'revoked' || !consent?.status)

  const isPsychometricGrantModal =
    consentId === SeekerConsentId.PsychometricTest &&
    (consent?.status === 'revoked' || !consent?.status)

  const isCognitiveGrantModal =
    consentId === SeekerConsentId.CognitiveTest &&
    (consent?.status === 'revoked' || !consent?.status)

  const isDataProtectionAgreement =
    consentId === SeekerConsentId.DataProtectionAgreement

  return (
    <div className={classNames(styles.consentItem, className)}>
      <div
        className={classNames(styles.titleWrapper, {
          [styles.translatedTitle]: subcategory,
          [styles.switchConsentTitleWrapper]: switchConsent,
        })}
      >
        <p className={styles.label}>
          <FormattedMessage id={ct.labelTitle} />
        </p>

        <div className={styles.titleTextWrapper}>
          {switchConsent && (
            <div className={styles.switchTitleContainer}>
              <SwitchContainer
                checked={consent?.status === 'granted'}
                handleChecked={() => {
                  if (consent?.status === 'granted') {
                    changeConsentStatus({
                      consentId: consentId,
                      consentStatus: 'revoked',
                      companyId: consent?.company?.id,
                    })
                  } else if (
                    consent?.status === 'revoked' ||
                    !consent?.status
                  ) {
                    changeConsentStatus({
                      consentId: consentId,
                      consentStatus: 'granted',
                      companyId: consent?.company?.id,
                    })
                  }
                }}
              />
              <div className={styles.titleContainer}>
                <h2 className={styles.title}>
                  {consent?.company?.name && consent?.company?.name}
                </h2>
                <p>
                  {consent?.companyContactPersonEmail &&
                    consent?.companyContactPersonEmail}
                </p>
              </div>
            </div>
          )}
          <h2 className={styles.title}>{!switchConsent && consentName}</h2>
          {consent?.contact && (
            <div className={styles.contact}>{consent?.contact}</div>
          )}
        </div>
      </div>
      <div className={styles.statusWrapper}>
        <p className={styles.label}>
          <FormattedMessage id={ct.labelStatus} />
        </p>
        <Status status={consent?.status} />
      </div>
      <div
        className={classNames(styles.dateWrapper, {
          [styles.dateWithMargin]: type === 'Consents for notification',
        })}
      >
        <p className={styles.label}>
          <FormattedMessage id={ct.labelDate} />
        </p>
        <p className={styles.title}>
          {consent?.dateUpdated &&
            new Date(consent?.dateUpdated).toLocaleDateString('sl-SI')}
        </p>
      </div>
      <div className={styles.contentWrapper}>
        <p className={styles.label}>
          <FormattedMessage id={ct.labelContent} />
        </p>
        {consentId === SeekerConsentId.ThirdPartyMarketing ||
        consentId === SeekerConsentId.PsychometricTest ||
        consentId === SeekerConsentId.CognitiveTest ||
        isNewsletter(consentId) ? (
          <button onClick={() => setInfoModalOpen(true)}>
            <div className={styles.featherIconContainer}>
              <FeatherIcon className={styles.borderedFeatherIcon} />
            </div>
          </button>
        ) : (
          <Link to={getRoute(route)}>
            <div className={styles.featherIconContainer}>
              <FeatherIcon className={styles.borderedFeatherIcon} />
            </div>
          </Link>
        )}
      </div>

      {!switchConsent && (
        <div className={styles.buttonWrapper}>
          {!consent?.contact && (
            <Button
              onClick={() => {
                setCheckboxValue(false)
                modalContent() // TODO this is temporary solution, write this again after finished api

                if (consent?.status === 'granted') {
                  setCheckboxValue(true)
                }

                setModalOpen(true)
              }}
              text={
                consent?.status === 'granted'
                  ? consentCategory === SeekerConsentId.BasicConsents
                    ? translation[ct.consentRecall]
                    : translation[ct.consentRevoke]
                  : consentCategory === SeekerConsentId.BasicConsents
                  ? translation[ct.consentAccept]
                  : translation[ct.consentGrant]
              }
              theme="acceptRevoke"
              icon={
                consent?.status === 'granted' ? (
                  <div
                    className={classNames(
                      styles.iconWrapper,
                      styles.closeIconWrapper
                    )}
                  >
                    <Close className={styles.closeIcon} />
                  </div>
                ) : (
                  <div
                    className={classNames(
                      styles.iconWrapper,
                      styles.checkIconWrapper
                    )}
                  >
                    <Check className={styles.checkIcon} />
                  </div>
                )
              }
            />
          )}
        </div>
      )}
      <Modal
        isOpen={modalOpen}
        onAfterClose={() => {
          setModalOpen(false)
        }}
        title={
          isPrivacyPolicy
            ? translation[ct.privacyPolicyModalTitle]
            : consentId !== SeekerConsentId.ThirdPartyMarketing &&
              consentId !== SeekerConsentId.PsychometricTest &&
              consentId !== SeekerConsentId.CognitiveTest
            ? `${consentName}`
            : modalTitle[consentId]
        }
        icon={HistoryEduOutlined}
        ariaHideApp={false}
        className={styles.modal}
        theme={consent?.status === 'revoked' || !consent?.status ? '' : 'error'}
        shouldReturnFocusAfterClose={false}
        Footer={
          <SubmitFooter
            disabled={
              !checkboxValue || modalText === translation[ct.modalCantBeRevoked]
            }
            checkboxValue={checkboxValue}
            setCheckboxValue={setCheckboxValue}
            type={
              consent?.status === 'revoked' ||
              !consent?.status ||
              consent?.status === 'Umaknjeno'
                ? 'checkboxFooter'
                : ''
            }
            modalText={modalText}
            theme={consent?.status === 'granted' ? 'error' : ''}
            onSubmit={() => {
              if (consent?.status === 'granted') {
                changeConsentStatus({
                  consentId: consentId,
                  consentStatus: 'revoked',
                  companyId: consent?.company?.id,
                })
              } else if (consent?.status === 'revoked' || !consent?.status) {
                changeConsentStatus({
                  consentId: consentId,
                  consentStatus: 'granted',
                  companyId: consent?.company?.id,
                })
              }
              setModalOpen(false)
            }}
            onCancel={() => {
              setModalOpen(false)
            }}
            submitText={
              consent?.status === 'revoked' || !consent?.status
                ? SeekerConsentId.BasicConsents
                  ? translation[ct.consentAccept]
                  : translation[ct.consentGrant]
                : consentCategory === SeekerConsentId.BasicConsents
                ? translation[ct.consentRecall]
                : translation[ct.consentRevoke]
            }
            checkboxText={
              (consent?.status === 'revoked' || !consent?.status) &&
              renderCheckboxText(consentId)
            }
            link={!(isNewsletter(consentId) || isMarketingGrantModal)}
          />
        }
      >
        {/* TODO this is temporary solution, write this again after finished api*/}
        <div className={styles.modalContainer}>
          {isGeneralTermsOfUse ||
          isPrivacyPolicy ||
          isTermsAndConditions ||
          isPrivacyPolicyRjbAnex ||
          isDataProtectionAgreement ? null : (
            <>
              <div className={styles.blockquoteContainer}>
                <span className={styles.quote}>“</span>
                <blockquote>
                  <ModalBlockquote consentId={consentId} />
                </blockquote>
                <span className={styles.quote}>”</span>
              </div>
              {isMarketingGrantModal ||
              isPsychometricGrantModal ||
              isCognitiveGrantModal ||
              (isNewsletter(consentId) &&
                (consent?.status === 'revoked' || !consent?.status)) ? null : (
                <div className={styles.hrWrapper}>
                  <Hr className={styles.darkGrayHr} />
                </div>
              )}
            </>
          )}
          {consent?.status === 'revoked' ||
          !consent?.status ||
          consentCategory === SeekerConsentId.BasicConsents ? null : (
            <h2 className={styles.consentTitle}>
              <FormattedMessage id={ct.withdrawConsent} />
            </h2>
          )}
          <p className={styles.modalText}>
            {consent?.status === 'granted'
              ? grantedModalMessage(consentName)
              : modalText}
          </p>
        </div>
      </Modal>
      <Modal
        isOpen={infoModalOpen}
        onAfterClose={() => {
          setInfoModalOpen(false)
        }}
        title={
          consentId === SeekerConsentId.CognitiveTest || isNewsletter(consentId)
            ? `${consentName}`
            : modalTitle[consentId]
        }
        icon={HistoryEduOutlined}
        ariaHideApp={false}
        className={styles.modal}
      >
        <div className={styles.modalMarketingText}>
          <ModalUnquotedText consentId={consentId} />
        </div>
      </Modal>
    </div>
  )
}
