import {connect} from 'react-redux'
import {AppDispatch} from 'Store'
import {ModalSendOfferEmp} from './ModalSendOfferEmp'
import {sendOfferEmp} from 'Store/Actions'
import {sendOfferModalEmpType} from 'Interfaces'
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  sendOffer: (payload: sendOfferModalEmpType) =>
    dispatch(sendOfferEmp(payload)),
})
export const ModalSendOfferEmpContainer = connect(
  null,
  mapDispatchToProps
)(ModalSendOfferEmp)
