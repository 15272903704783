import {FC, useMemo} from 'react'
import {FormattedMessage} from 'react-intl'
import {formatFullDate} from 'Utils/Date'
import {useTimeUntil} from 'Utils/Date/Hooks/useTimeUntil'
import styles from './RequestValues.module.scss'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'
import {useTranslation} from 'Hooks'
import {getTranslatedTime} from './Utils'
import {ExpirationValuesInDays} from 'Components/ExpirationValuesInDays'

export const StartsInValue: FC<{date: string | Date}> = ({date}) => {
  if (typeof date !== 'object') {
    date = new Date(date)
  }
  const today = new Date()
  const inDays = Math.floor(
    (date.getTime() - today.getTime()) / (1000 * 3600 * 24)
  )

  if (date < new Date() || inDays > 7) {
    return null
  }
  const translation = useTranslation()
  const [startsSoon, timeUntilString] = useTimeUntil(date)
  let translatedTime = getTranslatedTime(timeUntilString)
  const timeValue = timeUntilString.toString().substring(0, 2)

  return (
    <div className={startsSoon ? styles.dateTextSoon : styles.dateTextIn}>{`${
      translation[edt.in]
    } ${timeValue}  ${translatedTime}`}</div>
  )
}

export const ExpirationValue: FC<{date: string | Date}> = ({date}) => {
  if (typeof date !== 'object') {
    date = new Date(date)
  }
  const today = new Date()
  const inDays = Math.floor(
    (date.getTime() - today.getTime()) / (1000 * 3600 * 24)
  )
  const expirationDay = date.getDate()
  const currentDay = today.getDate()
  const expirationMonth = date.getMonth()
  const currentMonth = date.getMonth()
  const expirationYear = date.getFullYear()
  const currentYear = date.getFullYear()

  const isExpirationToday = useMemo(
    () =>
      expirationDay === currentDay &&
      expirationMonth === currentMonth &&
      expirationYear === currentYear,
    [
      expirationDay,
      currentDay,
      expirationMonth,
      currentMonth,
      expirationYear,
      currentYear,
    ]
  )
  if (inDays > 7 || date < new Date()) {
    return <div className={styles.dateTextIn}>{formatFullDate(date)}</div>
  }
  if (isExpirationToday) {
    return (
      <div className={styles.dateTextSoon}>
        <FormattedMessage id={edt.expiresToday} />
      </div>
    )
  }

  return <ExpirationValuesInDays inDays={inDays} />
}
