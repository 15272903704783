import {FC, useState} from 'react'
import {QuestionValue} from 'Interfaces'
import styles from './FaqCategory.module.scss'
import QuestionsCategoryItem from 'Components/CompanyProfile/Questions/FaqCategory/QuestionsCategoryItem'
import {CompanyFaqTranslations as cft} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'

interface FaqCategoryProps {
  questions: QuestionValue[]
  title: string
}

export const FaqCategory: FC<FaqCategoryProps> = ({questions, title}) => {
  const [showMore, setShowMore] = useState(true)

  return (
    <>
      <p className={styles.title}>{title}</p>
      <div className={styles.questionsCategory}>
        {showMore
          ? questions
              .slice(0, 5)
              .map((question, key) => (
                <QuestionsCategoryItem question={question} key={key} />
              ))
          : questions.map((question, key) => (
              <QuestionsCategoryItem question={question} key={key} />
            ))}
        <p onClick={() => setShowMore(!showMore)} className={styles.showMore}>
          {showMore ? (
            <FormattedMessage id={cft.showMoreText} />
          ) : (
            <FormattedMessage id={cft.showLessText} />
          )}
        </p>
      </div>
    </>
  )
}
