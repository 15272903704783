import {FC, useEffect, useMemo, useState} from 'react'
import styles from './AddRemoveCandidateToProject.module.scss'
import {StarOutlined, StarBorderOutlined, Save} from '@mui/icons-material'
import Modal from 'Components/Modal'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import {useFormik} from 'formik'

import {
  AddCandidateToProjectType,
  EmployerProjectItemType,
  EmployerProjectCandidatesItemType,
  RemoveCandidateFromProjectsType,
  Candidate,
} from 'Interfaces'
import {DropdownProps} from 'Utils/FormikProps'
import Title from 'Components/Title'
import TextField from 'Components/Inputs/TextField'
import Checkbox from 'Components/Inputs/Checkbox'
import {cloneDeep} from 'lodash'
import HrWithText from 'Components/HrWithText'
import {CandidateSearchTranslations as cs} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import {useTranslation} from 'Hooks'
import DropdownMultiple from 'Components/Inputs/DropdownMultiple'
import classNames from 'classnames'
import Validation from './Validation'
import {AreYouSureModal} from 'Components/Onboarding/AreYouSureModal/AreYouSureModal'

interface AddRemoveCandidateToProjectProps {
  seekerId: string
  firstName: string | null
  lastName?: string | null
  candidate?: Candidate
  candidateProjects: EmployerProjectCandidatesItemType[]
  allProjects: EmployerProjectItemType[]
  addCandidateToNewProject: (value: AddCandidateToProjectType) => void
  addCandidateToProjects: (value: AddCandidateToProjectType) => void
  removeCandidateFromProjects: (value: RemoveCandidateFromProjectsType) => void
  removeCandidateFromAllProjects: (
    value: RemoveCandidateFromProjectsType
  ) => void
  getAllMyProjects: () => void
  withStar?: boolean
}

export const AddRemoveCandidateToProject: FC<
  AddRemoveCandidateToProjectProps
> = ({
  seekerId,
  firstName,
  lastName,
  candidate,
  candidateProjects,
  allProjects,
  getAllMyProjects,
  addCandidateToNewProject,
  addCandidateToProjects,
  removeCandidateFromProjects,
  removeCandidateFromAllProjects,
  withStar = true,
}) => {
  const [modalAdd, setModalAdd] = useState(false)
  const [areYouSureModal, setAreYouSureModal] = useState(false)
  const [modalRemove, setModalRemove] = useState(false)
  const translation = useTranslation()
  const [filterCandidateProjectsInput, setFilterCandidateProjectsInput] =
    useState('')
  const formikAdd = useFormik({
    initialValues: {
      projectName: '',
      employer: '',
      projectIds: [],
    },
    validationSchema: Validation,
    onSubmit: (values) => {
      if (values.projectIds.length) {
        addCandidateToProjects({
          seekerId,
          projectIds: values.projectIds,
        })
      } else {
        addCandidateToNewProject({
          seekerId,
          projectName: values.projectName,
        })
      }
      formikAdd.resetForm()
      setModalAdd(false)
    },
  })

  const formikRemove = useFormik({
    initialValues: {
      ids: candidateProjects ? candidateProjects.map(() => false) : [],
      allFlag: false,
    },
    // TODO: Add validation
    onSubmit: (values) => {
      let valuesToRemove: string[] = []
      if (values.allFlag) {
        valuesToRemove = candidateProjects.map((p) => p.companyUserProject.id)
        removeCandidateFromAllProjects({
          seekerId,
          projectIds: valuesToRemove,
        })
      } else {
        values.ids.forEach((value, idx) => {
          if (value)
            valuesToRemove.push(candidateProjects[idx].companyUserProject.id)
        })

        removeCandidateFromProjects({
          seekerId,
          projectIds: valuesToRemove,
        })
      }
      formikRemove.resetForm()
      setModalRemove(false)
    },
  })

  useEffect(() => {
    if (modalAdd || modalRemove) {
      getAllMyProjects()
    }
  }, [modalAdd, modalRemove])

  const filterCandidateProjects = useMemo(() => {
    if (filterCandidateProjectsInput === '') return candidateProjects
    return cloneDeep(candidateProjects).filter((project) =>
      project.companyUserProject.title
        .toLowerCase()
        .includes(filterCandidateProjectsInput.toLowerCase())
    )
  }, [candidateProjects, filterCandidateProjectsInput])

  const onModalRemoveClose = () => {
    setModalRemove(false)
    setFilterCandidateProjectsInput('')
    formikRemove.resetForm()
  }

  const onModalAreYouSureClose = () => {
    setAreYouSureModal(false)
  }

  const onModalAddClose = () => {
    setModalAdd(false)
    formikAdd.resetForm()
  }
  const setMultiDropdown = (name: string, values?: string[]) => {
    if (values && values.length) {
      formikAdd.setFieldValue(name, values)
    } else formikAdd.setFieldValue(name, [])
  }
  return (
    <>
      {!(candidateProjects?.length > 0) ? (
        <div
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <button
            className={styles.bookmarkWrap}
            onClick={() => {
              setModalAdd(true)
            }}
          >
            {withStar && <StarBorderOutlined className={styles.star} />}
            <span className={styles.text}>
              <FormattedMessage id={cs.addProject} />
            </span>
          </button>
          <Modal
            isOpen={modalAdd}
            onAfterClose={() => {
              onModalAddClose()
            }}
            title={cs.saveProject}
            icon={Save}
            ariaHideApp={false}
            className={styles.modal}
            Footer={
              <SubmitFooter
                onSubmit={() => {
                  formikAdd.handleSubmit()
                }}
                submitText={cs.saveProject}
                onCancel={() => {
                  onModalAddClose()
                }}
                theme="modalProfileSubmit"
              />
            }
          >
            <Title
              text={`${translation[cs.saveTitleFirstPart]} ${
                firstName ? firstName + ' ' : ''
              } ${translation[cs.saveTitleSecondPart]}`}
            />
            <DropdownMultiple
              theme="white"
              name="projectIds"
              className={styles.newProjectsDropdown}
              items={allProjects.map((project) => ({
                id: project.id,
                translation: project.title,
              }))}
              {...DropdownProps(formikAdd, 'projectIds')}
              setValues={setMultiDropdown}
              disabled={!allProjects.length}
            />
            <HrWithText text="or" />
            <Title text={cs.createTitle} />
            <TextField
              name="projectName"
              placeholder={cs.projectName}
              className={styles.newProjectsName}
              value={`${formikAdd.values.projectName}`}
              onChange={formikAdd.handleChange}
              error={
                formikAdd.errors.projectName
                  ? formikAdd.errors.projectName
                  : undefined
              }
            />
          </Modal>
        </div>
      ) : (
        <div
          onClick={(e) => {
            e.stopPropagation()
          }}
        >
          <button
            className={styles.bookmarkWrap}
            onClick={() => {
              setModalRemove(true)
            }}
          >
            <StarOutlined
              className={classNames(styles.star, styles.yellowStar)}
            />
            <span className={styles.text}>
              <FormattedMessage id={cs.removeButton} />
            </span>
          </button>
          <Modal
            isOpen={modalRemove}
            onAfterClose={() => {
              onModalRemoveClose()
            }}
            title={cs.removeIndividuallProject}
            icon={Save}
            ariaHideApp={false}
            className={styles.modal}
            Footer={
              <SubmitFooter
                onSubmit={() => {
                  setAreYouSureModal(true)
                }}
                submitText={cs.removeIndividuallProject}
                onCancel={() => {
                  onModalRemoveClose()
                }}
                theme="modalProfileSubmit"
              />
            }
          >
            <Title
              text={`${translation[cs.removeTitleFirstPart]} ${
                firstName ? firstName + ' ' : ''
              } ${translation[cs.removeTitleSecondPart]}`}
            />

            <TextField
              name="projectName"
              value={filterCandidateProjectsInput}
              onChange={(event) =>
                setFilterCandidateProjectsInput(event.currentTarget.value)
              }
              placeholder={cs.findProject}
              className={styles.currentProjectsSearch}
              theme="white"
            />
            <div className={styles.currentProjectsItemsWrap}>
              {filterCandidateProjects && filterCandidateProjects.length > 0 ? (
                filterCandidateProjects.map((project, idx) => (
                  <Checkbox
                    key={`project-item-selected-${idx}`}
                    name={`project-item-selected-${idx}`}
                    value={formikRemove.values.ids[idx]}
                    className={styles.currentProjectsItem}
                    onChange={(value) => {
                      let newValues = cloneDeep(formikRemove.values.ids)
                      newValues[idx] = value.target.checked

                      formikRemove.setFieldValue('ids', newValues)
                    }}
                  >
                    {project.companyUserProject?.title}
                  </Checkbox>
                ))
              ) : (
                <div className={styles.noProjects}>
                  <FormattedMessage id={cs.noProject} />
                </div>
              )}
            </div>
            <HrWithText text="or" />
            <Checkbox
              name="allFlag"
              value={formikRemove.values.allFlag}
              onChange={() => {
                formikRemove.setFieldValue(
                  'allFlag',
                  !formikRemove.values.allFlag
                )
              }}
            >
              <FormattedMessage id={cs.removeProject} />
            </Checkbox>
          </Modal>

          <AreYouSureModal
            modalOpen={areYouSureModal}
            setModalOpen={setAreYouSureModal}
            handleModalSubmit={() => {
              formikRemove.handleSubmit()
            }}
            handleModalCancel={() => {
              onModalAreYouSureClose()
            }}
            text={`Are you sure you want to remove ${
              firstName ? firstName : ''
            } ${lastName ? lastName : ''} ${
              firstName
                ? '' || lastName
                  ? ''
                  : ''
                : candidate?.publicOccupation?.translation
            } from project ${candidateProjects[0].companyUserProject?.title}?`}
            submitText={'Remove'}
            cancelText={'Cancel'}
          />
        </div>
      )}
    </>
  )
}
