import {CircularProgress} from '@material-ui/core'
import classNames from 'classnames'
import {useOnScreen} from 'Hooks/useOnScreen'
import {FC, ReactNode, useEffect, useRef} from 'react'
import styles from './InfiniteScroll.module.scss'

type InfiniteScrollProps = {
  loading: boolean
  hasMore: boolean
  loadMore: () => void
  className?: string
  children: ReactNode
}

export const InfiniteScroll: FC<InfiniteScrollProps> = ({
  loading,
  loadMore,
  hasMore,
  className,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const isVisible = useOnScreen(ref)

  useEffect(() => {
    if (!loading && isVisible && hasMore) {
      loadMore()
    }
  }, [isVisible])

  return (
    <div className={classNames(styles.infiniteScrollWrap, className)}>
      {children}
      <div className={styles.scrollEnd} ref={ref}></div>
      {loading && (
        <div className={styles.progressSpinner}>
          <CircularProgress />
        </div>
      )}
    </div>
  )
}
