import {connect} from 'react-redux'

import {EmployerJourneyTracker} from './EmployerJourneyTracker'

import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {getEmployerActiveRequests} from 'Store/Actions'

const mapStateToProps = (state: RootState) => ({
  activeRequests: state.employer.data?.activeRequests,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getEmployerActiveRequests: () => dispatch(getEmployerActiveRequests()),
})

export const EmployerJourneyTrackerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerJourneyTracker)
