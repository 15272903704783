import {LongDescription} from 'Validations'
import * as Yup from 'yup'

const Validation = Yup.object().shape({
  occupation: Yup.object()
    .nullable()
    .required('Job classification is required field'),
  employer: Yup.object().nullable().required('Employer is required'),
  startMonth: Yup.number(),
  startYear: Yup.number()
    .min(1970, 'Start year is required')
    .max(new Date().getFullYear(), 'Enter valid start year')
    .required('Start year is required'),
  endMonth: Yup.number().when(['startYear', 'endYear'], {
    is: (startYear: number, endYear: number) => startYear === endYear,
    then: (schema) =>
      schema.when('startMonth', (started, yup) =>
        yup.min(started, "Can't end before it started")
      ),
  }),
  endYear: Yup.number()
    .when('startYear', (started, yup) =>
      yup.min(started, 'End year cannot be before start year')
    )
    .when('currentEmployer', {
      is: true,
      then: (schema) => schema,
      otherwise: (schema) => schema.required('End year is required'),
    }),
  currentEmployer: Yup.boolean().nullable(),
  employmentType: Yup.object()
    .nullable()
    .required('Employment type is required'),
  workDescriptionAndAchievements: LongDescription.required(
    'Job description is required'
  ),
  jobTitle: Yup.string()
    .max(50, 'Must be 50 characters or less')
    .required('Job title is required field'),
  town: Yup.object().nullable().required('City is required'),
  country: Yup.object().nullable().required('Country is required'),
})

export default Validation
