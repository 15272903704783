import {connect} from 'react-redux'

import {PublicProfile} from './PublicProfile'
import {AppDispatch} from 'Store'
import {getSeekerPublicProfile} from 'Store/Actions'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  user: state.seeker,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSeekerPublicProfile: (id: string) => dispatch(getSeekerPublicProfile(id)),
})

export const PublicProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicProfile)
