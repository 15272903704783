import {ApproveRejectModalRequestType} from 'Interfaces'
import {connect} from 'react-redux'

import {AppDispatch} from 'Store'
import {confirmPlacement} from 'Store/Actions'
import {ModalApprovePlacementConfirmation} from './ModalApprovePlacementConfirmation'

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  approvePlacementConfirmation: (payload: ApproveRejectModalRequestType) =>
    dispatch(confirmPlacement(payload)),
})

export const ModalApprovePlacementConfirmationContainer = connect(
  null,
  mapDispatchToProps
)(ModalApprovePlacementConfirmation)
