import {connect} from 'react-redux'

import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {RateCandidate} from './RateCandidate'
import {
  addDashboardSeekerEvaluation,
  editDashboardSeekerEvaluation,
} from 'Store/Actions'
import {SeekerEvaluationType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  company: state.company,
  candidateBasicData: state.widgetBar.data.candidateBasicData,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addSeekerEvaluation: (seekerEvaluation: SeekerEvaluationType) =>
    dispatch(addDashboardSeekerEvaluation(seekerEvaluation)),
  editSeekerEvaluation: (seekerEvaluation: SeekerEvaluationType) =>
    dispatch(editDashboardSeekerEvaluation(seekerEvaluation)),
})

export const RateCandidateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RateCandidate)
