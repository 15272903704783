import {FC, useEffect, useMemo} from 'react'
import styles from './Onboarding.module.scss'
import classnames from 'classnames'
import {StepCircles} from './StepCircles'
import {EmployerOnboardingSteps} from './employerSteps'
import Logo from 'Assets/Images/preferenca-logo.svg'
import {FormattedMessage} from 'react-intl'
import {EmployerOnboardingStateType} from 'Interfaces/EmployerOnboardingType'
import Hr from 'Components/Hr'
import CompanyContactInfoContainer from 'Components/Employer/Onboarding/CompanyContactInfo'
import HiringPlanContainer from 'Components/Employer/Onboarding/EmployerHiringPlan'
import {AuthStateType} from 'Interfaces/UserType'

interface EmployerOnboardingProps {
  employerOnboarding: EmployerOnboardingStateType
  getEmployerOnboarding: (companyId?: string) => void
  auth?: AuthStateType
}

export const EmployerOnboarding: FC<EmployerOnboardingProps> = ({
  employerOnboarding,
  getEmployerOnboarding,
  auth,
}) => {
  useEffect(() => {
    if (auth?.data?.companyStructure?.company.id) {
      getEmployerOnboarding(auth.data.companyStructure.company.id)
    }
  }, [auth?.data?.companyStructure?.company.id])

  const StepDisplay = useMemo(() => {
    const onboardingStep = employerOnboarding.data?.onboardingStep
    if (onboardingStep === 2) {
      return <HiringPlanContainer />
    } else return <CompanyContactInfoContainer />
  }, [employerOnboarding.data?.onboardingStep])

  useEffect(() => {
    window.scrollTo({top: 0})
  }, [employerOnboarding.data?.onboardingStep])

  const step = employerOnboarding.data?.onboardingStep || 1

  useEffect(() => {
    window.scrollTo({top: 0})
  }, [employerOnboarding.data?.onboardingStep])

  return employerOnboarding.data?.onboardingStep ? (
    <div className={styles.onboarding}>
      <div className={styles.main}>
        <div className={styles.logo}>
          <img src={Logo} alt={'logo'} />
        </div>
        <div className={styles.inner}>
          <div className={styles.title}>
            <FormattedMessage id={EmployerOnboardingSteps[step].title} />
          </div>
          <div className={styles.description}>
            <FormattedMessage id={EmployerOnboardingSteps[step].description} />
          </div>
          <StepCircles step={step + 1} total={3} />
          <Hr />

          <div className="body">{StepDisplay}</div>
        </div>
      </div>
      <div className={styles.rightColumn}>
        <div className={styles.desktopSteps}>
          {Array.from({length: 3}, (_, i) => (
            <div
              key={i}
              className={classnames(styles.desktopStep, {
                [styles.filled]: step === i,
                [styles.done]: step > i,
              })}
            >
              <div className={styles.stepCnt}>{i + 1}</div>
              <div className={styles.stepName}>
                <FormattedMessage id={EmployerOnboardingSteps[i + 1].sidebar} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : null
}
