import {FC, ReactNode} from 'react'

interface ConditionalWrapperProps {
  condition?: string | boolean
  wrapper: Function
  children: ReactNode
}

export const ConditionalWrapper: FC<ConditionalWrapperProps> = ({
  condition,
  wrapper,
  children,
}) => {
  return condition ? wrapper(children) : children
}
