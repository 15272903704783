import {FC} from 'react'
import classnames from 'classnames'
import styles from './HomePageSteps.module.scss'
import YellowText from 'Components/YellowText'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {HomeTranslations as home} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import YellowLink from 'Components/YellowLink'

interface HomePageStepsProps {
  theme?: 'candidate' | 'employer'
}

export const HomePageSteps: FC<HomePageStepsProps> = ({
  theme = 'candidate',
}) => {
  return (
    <div
      className={classnames(styles.HomePageStepsContainer, {
        [styles.theme]: theme,
      })}
    >
      <h2 className={styles.h2}>
        <FormattedMessage id={home.question_1} />
        <YellowText text={home.question_2} />
      </h2>
      <div className={styles.steps}>
        <div className={classnames(styles.step, styles.step1)}>
          <div className={styles.number}>1</div>
          <p className={styles.text}>
            <FormattedMessage id={home.answer1} />
          </p>
        </div>
        <div className={classnames(styles.step, styles.step2)}>
          <div className={styles.number}>2</div>
          <p className={styles.text}>
            <FormattedMessage id={home.answer2} />
          </p>
        </div>
        <div className={classnames(styles.step, styles.step3)}>
          <div className={styles.number}>3</div>
          <p className={styles.text}>
            <FormattedMessage id={home.answer3} />
          </p>
        </div>
        <div className={classnames(styles.step, styles.step4)}>
          <div className={styles.number}>4</div>
          <p className={styles.text}>
            <FormattedMessage id={home.answer4} />
          </p>
        </div>
      </div>
      <div className={styles.buttonWrap}>
        <div className={styles.button}>
          <YellowLink
            to={getRoute(AppRoute.Registration)}
            text={home.btnCreate}
            className={styles.yellowButton}
          />
        </div>
      </div>
    </div>
  )
}
