import styles from './Popover.module.scss'
import {Dispatch, FC, MouseEventHandler, SetStateAction} from 'react'
import {FormattedMessage} from 'react-intl'
import classnames from 'classnames'
import {Link} from 'react-router-dom'

export type PopoverItemType = {
  text: string
} & ({onClick: MouseEventHandler} | {route: string})

export type PopoverProps = {
  items: PopoverItemType[]
  theme?: string
  isPopoverOpen?: string
  setIsPopoverOpen?: Dispatch<SetStateAction<boolean>>
  open?: boolean
  onClick?: () => void
}

export const Popover: FC<PopoverProps> = ({
  items,
  theme = 'fixedLeftOne',
  setIsPopoverOpen,
  open = false,
  onClick = () => {},
}) => {
  const handleClose: MouseEventHandler = (event) => {
    event.stopPropagation()
    if (setIsPopoverOpen) {
      setIsPopoverOpen(false)
    }
  }

  return (
    <div
      className={classnames(styles.popoverListWrap, {
        [styles[theme]]: [theme],
        [styles.hidden]: !open,
      })}
    >
      {items.map((item, idx) => {
        return 'onClick' in item ? (
          <button
            key={`popover-item-${idx}`}
            onClick={(e) => {
              handleClose(e)
              item.onClick(e)
              onClick()
            }}
            className={styles.popoverListItem}
          >
            {item.text && <FormattedMessage id={item.text} />}
          </button>
        ) : (
          <Link
            to={item.route}
            className={styles.popoverListItem}
            key={`popover-item-${idx}`}
          >
            <FormattedMessage id={item.text} />
          </Link>
        )
      })}
    </div>
  )
}
