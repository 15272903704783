import {connect} from 'react-redux'

import {LanguageTests} from './LanguageTests'
import {AppDispatch} from 'Store'
import {
  addLanguageTest,
  deleteLanguageTest,
  editLanguageTest,
  getTrainingTitles,
} from 'Store/Actions'
import {RootState} from 'Store/Reducers'
import {
  TestGroupEnum,
  TrainingTitleAddType,
  TrainingTitleEditType,
} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  taxonomies: state.taxonomies,
  user: state.auth.data,
  assessments: state.assessments,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getTaxonomies: () => {
    dispatch(getTrainingTitles(TestGroupEnum.languageTest))
  },
  addLanguageTest: (value: TrainingTitleAddType) =>
    dispatch(addLanguageTest(value)),
  editLanguageTest: (value: TrainingTitleEditType) =>
    dispatch(editLanguageTest(value)),
  deleteLanguageTest: (id: string) => dispatch(deleteLanguageTest(id)),
})

export const LanguageTestsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageTests)
