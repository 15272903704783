import {FC, useEffect} from 'react'

import {SpectoStateType} from 'Interfaces'
import MainLayout from 'Layouts/MainLayout'
import styles from './SpectoCalendar.module.scss'
import {getLang} from 'Services/I18n/Utils'
import {getChannel} from 'Utils/CommonHelpers'
import {WidgetBarContainer} from 'Layouts/MainLayout/WidgetBar'
import {SidebarLink} from 'Components/SidebarLink'
import {
  AppRoute,
  spectoCalendarTranslations as sct,
} from 'Services/I18n/Constants'

interface SpectoCalendarProps {
  spectoState: SpectoStateType
  getSpectoToken: () => void
}

export const SeekerCalendar: FC<SpectoCalendarProps> = ({
  spectoState,
  getSpectoToken,
}) => {
  useEffect(() => {
    if (!spectoState.data.token) {
      getSpectoToken()
    }
  }, [])
  const lang = getLang()
  const channel = getChannel()
  const spectoCalendarUrl = channel?.spectoCalendarUrl

  return (
    <MainLayout
      withoutLogo
      fixedRight={false}
      rightSidebarContent={
        <WidgetBarContainer
          sidebarContent={
            <div className={styles.linksContainer}>
              <SidebarLink
                routeName={AppRoute.CandidateDashboard}
                text={sct.linkCandidateDashboard}
              />
              <SidebarLink
                routeName={AppRoute.AllIncomingRequests}
                text={sct.linkAllRequests}
              />
              <SidebarLink routeName={AppRoute.Profile} text={sct.linkEditCV} />
            </div>
          }
        />
      }
    >
      <div className={styles.iframeWrap}>
        {spectoState.data.token && (
          <iframe
            src={`${spectoCalendarUrl}/?token=${spectoState.data.token}&lang=${lang}`}
          />
        )}
      </div>
    </MainLayout>
  )
}
