import * as type from 'Store/types'
import {
  ActionType,
  CommentType,
  ErrorType,
  HelpfulHintType,
  HistoryType,
  IncomingRequestSeekerType,
  IncomingRequestsStateType,
  IncomingRequestType,
  MessageType,
  SeekerEvaluationType,
  WidgetBarStateType,
} from 'Interfaces'
import {
  Candidate,
  DashboardSeekerEvaluationType,
  SendContactRequestResponseType,
} from 'Interfaces/CandidateType'

const initialState = {
  data: {
    sidebarClosed: true,
    helpfullHintValue: {
      id: '',
      name: '',
      active: true,
    },
    candidateBasicData: null,
    request: null,
    comments: {items: []},
    messages: [],
    history: [],
  },
  loading: false,
  error: undefined,
}

const WidgetBarReducer = (
  state: WidgetBarStateType = initialState,
  action: ActionType<
    | string
    | Candidate
    | number
    | boolean
    | {items: CommentType[]}
    | MessageType[]
    | HistoryType[]
    | IncomingRequestType
    | DashboardSeekerEvaluationType
    | ErrorType
    | SendContactRequestResponseType
  >
): WidgetBarStateType => {
  switch (action.type) {
    case type.widgetBar.comments.get.requested:
    case type.widgetBar.messages.get.requested:
    case type.widgetBar.history.get.requested:
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case type.widgetBar.comments.get.succeeded:
      const comments = (action.payload as {items: CommentType[]}) || []
      return {
        data: {
          ...state.data,
          comments: comments,
        },
        loading: false,
        error: undefined,
      }
    case type.widgetBar.messages.get.succeeded:
      return {
        data: {
          ...state.data,
          messages: action.payload as MessageType[],
        },
        loading: false,
        error: undefined,
      }
    case type.widgetBar.history.get.succeeded:
      return {
        data: {
          ...state.data,
          history: action.payload as HistoryType[],
        },
        loading: false,
        error: undefined,
      }
    case type.widgetBar.comments.save.succeeded:
      return {
        data: {
          ...state.data,
          comments: {
            ...(state.data.comments as {items: CommentType[]}),
            items: [
              action.payload,
              ...(state.data.comments?.items as CommentType[]),
            ] as CommentType[],
          },
        },
        loading: false,
        error: undefined,
      }
    case type.widgetBar.sidebarClosed.setStatus:
      return {
        data: {
          ...state.data,
          sidebarClosed: action.payload as boolean,
        },
        loading: false,
        error: undefined,
      }
    case type.widgetBar.helpfulHintClosed.setStatus.succeeded:
      return {
        data: {
          ...state.data,
          helpfullHintValue: action.payload as HelpfulHintType,
        },
        loading: false,
        error: undefined,
      }
    case type.widgetBar.helpfulHintClosed.getStatus.succeeded:
      return {
        data: {
          ...state.data,
          helpfullHintValue: action.payload as HelpfulHintType,
        },
        loading: false,
        error: undefined,
      }
    case type.widgetBar.candidateBasicData.setCandidateBasicData:
      return {
        data: {
          ...state.data,
          candidateBasicData: action.payload as
            | Candidate
            | IncomingRequestSeekerType
            | null,
        },
        loading: false,
        error: undefined,
      }
    case type.widgetBar.selectedRequest.setSelectedRequest:
      const selectedRequestPayload = action.payload as IncomingRequestType
      return {
        data: {
          ...state.data,
          candidateBasicData: selectedRequestPayload.seeker,
          request: selectedRequestPayload,
        },
        loading: false,
        error: undefined,
      }

    case type.employer.allRequests.rateCandidate.add.succeeded:
      const addRatePayload = action.payload as DashboardSeekerEvaluationType
      return {
        data: {
          ...state.data,
          candidateBasicData: {
            ...(state.data.candidateBasicData as
              | Candidate
              | IncomingRequestSeekerType),
            seekerEvaluations: [{evaluation: addRatePayload.evaluation}],
          },
        },
        loading: false,
        error: undefined,
      }
    case type.employer.allRequests.rateCandidate.edit.succeeded:
      const rateCandidatePayload =
        action.payload as DashboardSeekerEvaluationType
      return {
        data: {
          ...state.data,
          candidateBasicData: {
            ...(state.data.candidateBasicData as
              | Candidate
              | IncomingRequestSeekerType),
            seekerEvaluations: [{evaluation: rateCandidatePayload.evaluation}],
          },
        },
        loading: false,
        error: undefined,
      }
    case type.myProjects.rateCandidate.add.succeeded:
      const ratePayloadAdd = action.payload as SeekerEvaluationType
      return {
        data: {
          ...state.data,
          candidateBasicData: {
            ...(state.data.candidateBasicData as
              | Candidate
              | IncomingRequestSeekerType),
            companyUserSeekerProject: {
              ...state.data.candidateBasicData?.companyUserSeekerProject,
              seekerEvaluation: {
                ...state.data.candidateBasicData?.companyUserSeekerProject
                  ?.seekerEvaluation,
                evaluation: ratePayloadAdd.evaluation,
              },
            },
          },
        },
        loading: false,
        error: undefined,
      }
    case type.candidateSearch.requestContact.save.succeeded:
      const sendContactRequestResponse = action.payload as {
        id: string
        contactRequest?: IncomingRequestsStateType | null
      }
      return {
        data: {
          ...state.data,
          candidateBasicData: {
            ...(state.data.candidateBasicData as IncomingRequestSeekerType),
            companySeekerJourney: sendContactRequestResponse,
          },
        },
        loading: false,
        error: undefined,
      }
    case type.myProjects.removeFromProjects.succeeded:
      return {
        data: {
          ...state.data,
          sidebarClosed: true,
          candidateBasicData: null,
        },
        loading: false,
        error: undefined,
      }

    case type.myProjects.rateCandidate.edit.succeeded:
      const ratePayloadEdit = action.payload as SeekerEvaluationType
      return {
        data: {
          ...state.data,
          candidateBasicData: {
            ...(state.data.candidateBasicData as
              | Candidate
              | IncomingRequestSeekerType),
            companyUserSeekerProject: {
              ...state.data.candidateBasicData?.companyUserSeekerProject,
              seekerEvaluation: {
                ...state.data.candidateBasicData?.companyUserSeekerProject
                  ?.seekerEvaluation,
                evaluation: ratePayloadEdit.evaluation,
              },
            },
          },
        },
        loading: false,
        error: undefined,
      }
    case type.myProjects.rateCandidate.delete.succeeded:
      return {
        data: {
          ...state.data,
          candidateBasicData: {
            ...(state.data.candidateBasicData as
              | Candidate
              | IncomingRequestSeekerType),
            companyUserSeekerProject: {
              ...state.data.candidateBasicData?.companyUserSeekerProject,
              seekerEvaluation: null,
            },
          },
        },
        loading: false,
        error: undefined,
      }
    case type.widgetBar.comments.get.failed:
    case type.widgetBar.messages.get.failed:
    case type.widgetBar.history.get.failed:
    case type.widgetBar.helpfulHintClosed.setStatus.failed:
      return {
        ...state,
        loading: false,
        error: action.payload as ErrorType,
      }
    default:
      return state
  }
}

export default WidgetBarReducer
