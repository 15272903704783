export const termsAndConditions = {
  'termsAndConditions.termsAndConditionsHtml': `<div class="termsAndConditionsContainerDown">
  <p class="termsAndConditionsContainerDownText termsAndConditionsFirstText">
    Spletno mesto Preferenca.si predstavlja možnost aktivnega iskanja kadra, pri
    čemer spletno mesto vodi vse Uporabnike teh spletnih strani skozi proces
    spoznavanja in zaposlovanja ter pri tem spodbuja njihovo aktivno udeležbo.
    Namen tovrstnih oblik iskanja kadra so krajši postopki zaposlovanja z visoko
    učinkovitostjo ter ujemanjem iskalca zaposlitve in delodajalca.
    Specifičnemu načinu kadrovanja na Preferenca.si je prilagojeno tudi delovanje
    spletnega mesta in njegovih funkcionalnosti. Za spletno mesto Preferenca.si
    se uporabljajo <a href="#general-terms-and-conditions" target="_blank">Splošni pogoji uporabe</a> ter ta Pravila in pogoji uporabe
    Preferenca.si, kot enoten dokument.
  </p>
  <h2 class="termsAndConditionsContainerDownTitle">DEFINICIJE POJMOV</h2>
  <p class="termsAndConditionsContainerDownText">
    Pojmi v teh Pravilih in pogojih uporabe Preferenca.si, pisani z veliko
    začetnico, imajo točno določen pomen, ki je natančneje pojasnjen v
    nadaljevanju. Navedeni pojmi zajemajo tako žensko kot moško obliko, ednino,
    dvojino in množino, ob tem pa tudi pojmi, opisani v <a href="#general-terms-and-conditions" target="_blank">Splošnih pogojih
    uporabe</a>, ohranjajo enak pomen, razen v primerih, ko iz sobesedila izhaja
    drugače.
  </p>
  <div class="termsAndConditionsContainerDownText">
    <ul class="termsAndConditionsListStyle">
      <li>
        Preferenca.si se nanaša na domeno www.preferenca.si, njene poddomene,
        vse njene strani in podstrani, spletne in mobilne aplikacije.
      </li>
      <li>
        Pravila in pogoji se nanaša na ta Pravila in pogoje uporabe
        Preferenca.si, ki definirajo pravila in pogoje dostopanja do spletih
        strani www.preferenca.si in uporabo njihovih Storitev, ki so Uporabnikom
        v danem trenutku na voljo. Pravila in pogoji veljajo do njihovega
        preklica ali izdaje novih. O tovrstnih spremembah so Uporabniki vselej
        obveščeni po elektronski poti. Spremembe stopijo v veljavo z dnem
        njihove objave na www.preferenca.si.
      </li>
      <li>
        Uporabnik se nanaša na pravne in fizične osebe, ki dostopajo in
        pregledujejo te spletne strani, ne glede na način dostopa (kot
        registriran ali neregistriran uporabnik), pojem uporabnik v teh Pravilih
        in pogojih se nanaša tako na Iskalce zaposlitve kot na Delodajalce.
      </li>
      <li>
        Iskalec zaposlitve je registriran Uporabnik in se nanaša na osebe, ki
        dostopajo do teh spletnih strani z namenom iskanja novih zaposlitvenih
        možnosti.
      </li>
      <li>
        Delodajalec je pravna ali fizična oseba oz. njuni predstavniki, ki
        dostopajo do Storitev na spletnem mestu Preferenca.si kot Registrirani
        uporabniki, in sicer z namenom iskanja novega kadra. Delodajalec lahko
        išče kader v lastnem imenu ali v imenu svojih naročnikov,če za takšno
        iskanje kadra razpolaga z ustrezno pravno podlago.
      </li>
      <li>
        Storitev na Preferenca.si zajema vse storitve, ki so Uporabnikom na
        voljo. Zaradi narave delovanja spletnega mesta Preferenca.si je za
        uporabo Storitev obvezna registracija Uporabnika. Storitve zajemajo
        različne načine spoznavanja in komuniciranja Uporabnikov, preverjanja
        znanj ter druge kadrovske in selekcijske aktivnosti oziroma postopke, ki
        se lahko občasno spremenijo ali dopolnijo, pri čemer se takšne spremembe in dopolnitve
        štejejo kot del Storitev in ne predstavljajo spremembe teh Pravil in
        pogojev.
      </li>
    </ul>
  </div>
</div>
<div class="termsAndConditionsContainerDown">
  <h2 class="termsAndConditionsContainerDownTitle">
    UPORABA STORITEV in OBVEZNOSTI UPORABNIKOV
  </h2>
  <p class="termsAndConditionsContainerDownText">
    Namen spletnega mesta Preferenca.si je vzpostavljanje stika in končnega
    poslovnega sodelovanja med Delodajalcem in Iskalcem zaposlitve. Za uporabo
    Storitev Preferenca.si in izpolnitev namena mora vsak Uporabnik ustvariti
    Uporabniški profil na tem spletnem mestu ter sprejeti ta Pravila in pogoje.
  </p>
  <p class="termsAndConditionsContainerDownText">
    Namen Storitev Preferenca je v celoti dosežen, ko Delodajalec in Iskalec
    zaposlitve uspešno in prostovoljno realizirata sodelovanje na podlagi
    vzpostavljenega stika na teh spletnih straneh. Za doseganje namena Ponudnik
    zagotavlja različne Storitve, plačljive Storitve so natančneje opredeljene v
    Ceniku, brezplačne pa na mestih, kjer Uporabnik do njih dostopa. V primeru,
    ko Iskalec zaposlitve in Delodajalec skleneta sodelovanja prek
    Preferenca.si, je Ponudnik upravičen tudi do zaračunavanja provizije iz
    naslova uspešnosti. Sodelovanje se šteje za uspešno sklenjeno v primerih,
    (i) ko to pisno potrdita Delodajalec in Iskalec zaposlitve, (ii) ko tako
    izhaja iz dejanskega stanja in (iii) tudi v primerih, ko je sodelovanje med
    Delodajalcem in Iskalcem zaposlitve sklenjeno v roku dvanajst (12) mesecev
    po izteku naročniškega razmerja med Ponudnikom in Delodajalcem za uporabo Storitev Preferenca.si 
    oziroma kot posledica vzpostavljenega stika na
    Preferenca.si. Šteje se, da je sodelovanje uspešno sklenjeno prek
    Preferenca.si ne glede na delovno mesto, na katerem Delodajalec zaposli
    kandidata, in tudi v primerih, ko sodelovanje z Iskalcem zaposlitve sklene
    hčerinsko podjetje Delodajalca oziroma povezana družba v smislu določil
    Zakona o gospodarskih družbah (Ur. l. RS, št. 64/09 s spremembami, »ZGD-1«).
    Za tako sklenjeno sodelovanje se uporabljajo ta Pravila in pogoji.
  </p>
  <p class="termsAndConditionsContainerDownText">
    Preferenca.si s svojimi Storitvami predstavlja premium produkt Upravljavca.
    Upravljavec si pridržuje pravico do ponujanja Storitev na način, ki po
    njegovem mnenju zagotavljajo optimalni rezultat, kar pomeni, da so v danem
    trenutku lahko na voljo storitve (i) le v okviru določenih panog, (ii)
    iskanje kadra na omejenih področjih in/ali (iii) iskanje kadra s specifičnim
    znanjem. Skladno z navedenim so Storitve na teh spletnih straneh na voljo v
    takšni obliki »kot so«. Ponudnik pri tem ne daje nikakršnih jamstev glede
    njihove dostopnosti, ustreznosti, pravilnosti ali ažurnosti, razen v
    primerih, ko je to izrecno navedeno v teh Pravilih in pogojih. Ponudnik
    ravno tako ne jamči odzivnosti Uporabnikov ter ne posega in ni odgovoren za
    končne odločitve glede kakršnega koli sodelovanja med Delodajalcem in
    Iskalcem zaposlitve. Odločitev za podajo in/ali sprejem zahteve za
    vzpostavitev stika, podajo in/ali sprejem ponudbe za sodelovanje med
    Delodajalcem in Iskalcem zaposlitve ostaja diskrecijska pravica Delodajalca
    oziroma Iskalca zaposlitve.
  </p>
</div>
<div class="termsAndConditionsContainerDown">
  <h2 class="termsAndConditionsContainerDownTitle">
    OBVEZNOSTI ISKALCA ZAPOSLITVE
  </h2>
  <p class="termsAndConditionsContainerDownText">
    Iskalec zaposlitve lahko uporablja Storitev brezplačno in v okviru
    funkcionalnosti, ki jih zagotavlja Preferenca.si, vedno pa le z namenom
    iskanja novih zaposlitvenih priložnosti. Iskalec zaposlitve se zavezuje
    posredovati le lastne, resnične in pravilne podatke ter jih ohranjati ves
    čas uporabe Storitev ažurno posodobljene. Navajanje izmišljenih podatkov ter
    izdajanje za tretjo osebo oz. vpisovanje podatkov tretjih oseb brez
    njihovega soglasja je strogo prepovedano. Vsi podatki na teh spletnih
    straneh se vnašajo v slovenščini razen v primerih, ko je drugače dogovorjeno
    med Iskalcem zaposlitve in Delodajalcem in če tako dopušča veljavna
    zakonodaja. Iskalec zaposlitve se zavezuje, da bo Storitve uporabljal na
    način, ki omogoča nadaljnje zagotavljanje Storitev na teh spletnih straneh v
    obsegu in na način kot opredeljeno v teh Pravilih in pogojih. S tem namenom
    bo tekom uporabe Storitev Iskalec zaposlitve pozvan k posredovanju dodatnih
    podatkov, opravljanju morebitnih testov, preverjanju znanj in izvedbi drugih 
    povezanih kadrovskih postopkov oziroma
    aktivnosti, kar predstavlja kvalifikacijski proces Iskalca zaposlitve.
    Druge aktivnosti oziroma komunikacija se izvaja neposredno med Uporabniki in izključno za kadrovske namene,
    pošiljanje irelevantnih, promocijskih, zlonamernih oziroma grozilnih
    sporočil ali sporočil, ki so kakorkoli drugače v nasprotju z nameni
    spletnega mesta Preferenca.si je izrecno prepovedano. Izrecno prepovedano je
    tudi poseganje ali poskus poseganja v programsko opremo Preferenca.si, njeno
    izvorno kodo, objavljene vsebine, dokumente ali podatke, ki jih na teh
    spletnih straneh vnesejo drugi Uporabniki.
  </p>
  <p class="termsAndConditionsContainerDownText">
    Vsak Iskalec zaposlitve je dolžan ažurno obveščati Ponudnika o (i) prejetih
    in/ali sprejetih ponudbah Delodajalcev za sklenitev nadaljnjega sodelovanja,
    (ii) že sklenjenih sodelovanjih z Delodajalci, (iii) morebitnih odpovedih že
    sklenjenih sodelovanj z Delodajalci v roku dvanajst (12) mesecev od njihove
    sklenitve, vse v okviru vzpostavljenega stika z Delodajalcem prek Spletnega
    mesta Preferenca.si oziroma v okviru uporabe Storitev Preferenca.si. Iskalec
    zaposlitve se zavezuje vzdržati vseh ravnanj, s katerimi bi Ponudniku (i)
    onemogočil ali preprečil zagotavljanje Storitev Preferenca.si, zlasti
    goljufivih dejanj, (ii) onemogočil zaračunavanje plačljivih Storitev ali
    (iii) kako drugače oviral izvajanje Storitev, goljufal ali škodil drugim
    Uporabnikom, Spletnemu mestu, Ponudniku ali njegovim lastnikom.
  </p>
  <p class="termsAndConditionsContainerDownText">
    Preferenca.si je premium spletno mesto Upravljavca, zato na teh spletnih
    straneh veljajo posebni pogoji. Uporaba Storitev Preferenca.si ni združljiva
    z uporabo vseh Spletnih mest, s katerimi Ponudnik upravlja oziroma ni
    združljiva z uporabo vseh Storitev, ki jih ponuja. Iskalec zaposlitve se z
    začetkom kvalifikacijskega procesa na Preferenca.si strinja, da bo njegova
    uporaba Storitev na spletnem mestu www.mojedelo.com, za čas uporabe Storitev
    na www.preferenca.si, omejena na dostopanje do in posodabljanje
    Uporabniškega profila MojeDelo.com ter uporabo storitev e-izobraževalnih
    vsebin in hekatonov. Za Iskalca zaposlitve, ki preneha z uporabo Storitev
    Prefereca.si, ne glede na razloge za to, navedena omejitev ne velja. Šteje
    se, da Iskalec zaposlitve ne uporablja Storitev Preferenca.si, (i) če ne
    zaključi registracijskega postopka Uporabniškega računa ali si ne ustvari
    Uporabniškega profila Preferenca.si, (ii) če ne zaključi kvalifikacijskega
    postopka na Preferenca.si, (iii) v primeru izbrisa Uporabniškega računa ali
    Uporabniškega profila na Preferenca.si, (iv) v primeru deaktivacije
    Uporabniškega profila Preferenca.si oziroma (iv) v primeru uspešno
    vzpostavljenega sodelovanja z Delodajalcem na Preferenca.si. Iskalec
    zaposlitve se strinja, da po uspešno vzpostavljenem sodelovanju z
    Delodajalcem, ne bo uporabljal spletnega mesta Preferenca.si za namen
    iskanja novih zaposlitvenih priložnosti oziroma mu bo dostop do
    Preferenca.si za ta namen onemogočen, in sicer za obdobje dvanajst (12)
    mesecev od uspešno vzpostavljenega sodelovanja.
  </p>
  <p class="termsAndConditionsContainerDownText">
    Za vse kršitve Splošnih pogojev, Pravil in pogojev in veljavne zakonodaje je
    Uporabnik lahko tudi kazensko ali odškodninsko odgovoren.
  </p>
</div>
<div class="termsAndConditionsContainerDown">
  <h2 class="termsAndConditionsContainerDownTitle">OBVEZNOSTI DELODAJALCA</h2>
  <p class="termsAndConditionsContainerDownText">
    Delodajalec lahko uporablja Storitve Preferenca.si le na podlagi uspešno
    zaključene registracije in v skladu s <a href="#general-terms-and-conditions" target="_blank">Splošnimi pogoji</a>, temi <a href="#terms-and-conditions">Pravili in
    pogoji</a>, <a href="#privacy-policy">Politiko zasebnosti in varovanja osebnih podatkov</a> ter drugimi
    individualnimi dogovori s Ponudnikom. Delodajalec lahko uporablja Storitve
    Prefrenca.si z namenom iskanja kadra ter izvajanja drugih kadrovskih
    procesov in aktivnosti v okviru funkcionalnosti, ki jih omogoča
    Preferenca.si. Delodajalec se zavezuje, da (i) bo pri dostopanju do
    Preferenca.si in uporabi Storitev ravnal skladno Zakonom o delovnih
    razmerjih, Zakonom o varstvu osebnih podatkov, Splošno uredbo EU o varstvu
    podatkov in drugo relevantno zakonodajo, (ii) bo objavljal le lastne,
    resnične in pravilne vsebine, pri tem pa ne bo kršil pravic tretjih oseb,
    (iii) bo Iskalcem zaposlitve posredoval le resnične in pravilne informacije,
    (iv) se bo vzdržal ravnanj, ki bi prizadela človekovo dostojanstvo,
    vzpodbujala rasne, spolne ali narodnostne diskriminacije in verske ali
    politične nestrpnosti ter ravnanj, ki škodujejo zdravju in varnosti ljudi
    ali zaščiti okolja ali kulturne dediščine, žalijo verska ali politična
    prepričanja ali škodijo interesom drugih Uporabnikov Preferenca.si, (vi) bo
    vestno odgovarjal na vprašanja Iskalcev zaposlitve, (vii) bo ažurno oziroma
    najpozneje v roku osem (8) dni od nastanka spremembe, pisno obvestil
    Upravljavca o vsaki spremembi kontaktnih oseb in kontaktnih podatkov,
    poslovnega naslova ali drugih poslovnih podatkov, podani ponudbi,
    (ne)uspešni sklenitvi sodelovanja z Iskalcem zaposlitve in (viii) drugih
    bistvenih informacijah, ki vplivajo ali bi lahko vplivale na Ponudnikove
    pravice in obveznosti ter zagotavljanje Storitev Uporabnikom na
    Preferenca.si.
  </p>
  <p class="termsAndConditionsContainerDownText">
    V primeru uspešno sklenjenega sodelovanja Iskalca zaposlitve z drugim
    Delodajalcem, Delodajalec ni več upravičen do podatkov in nadaljnje
    interakcije s takšnim Iskalcem zaposlitve na Preferenca.si.
  </p>
  <p class="termsAndConditionsContainerDownText">
    Delodajalcem, ki Storitve naročajo za vnaprej določeno obdobje, se uporaba
    Storitev onemogoči z naslednjim dnem po izteku dogovorjenega obdobja naročenih
    Storitev.
  </p>
  <p class="termsAndConditionsContainerDownText">
    Storitve Preferenca.si zajemajo tudi obdelavo osebnih podatkov v imenu in za
    račun Delodajalcev, in sicer (i) na podlagi prostovoljnega posredovanja
    osebnih podatkov s strani Iskalca zaposlitve ali (ii) prek drugih storitev,
    v okviru katerih Iskalec zaposlitve soglaša z deljenjem svojih osebnih
    podatkov. Prejemnik osebnih podatkov Iskalca zaposlitve v okviru Storitev
    Preferenca.si je lahko le Delodajalec, kot naročnik Storitev Preferenca.si.
    Delodajalec se zavezuje s prejetimi osebnimi podatki ravnati skladno z
    zakonodajo, ki ureja področje varstva osebnih podatkov, pri tem pa prejete
    osebne podatke obdeluje, kot njihov samostojni upravljavec. Za takšno
    obdelavo podatkov na teh spletnih straneh, Ponudnik in Delodajalec sklepata
    <a href="#data-protection-agreement">Pogodba o obdelavi osebnih podatkov</a>, ki skladno z določili Splošne uredbe o
    varstvu podatkov in vsakokrat veljavnim Zakonom o varstvu podatkov,
    opredeljuje namen, način in obseg obdelave, kot tudi druge obveznosti ter
    sprejete tehnične in organizacijske ukrepe potrebne za zakonito in varno
    obdelavo osebnih podatkov na Preferenca.si.
  </p>
</div>
<div class="termsAndConditionsContainerDown">
  <h2 class="termsAndConditionsContainerDownTitle">CENA IN PLAČILO</h2>
  <p class="termsAndConditionsContainerDownText">
    Storitve na teh spletnih straneh so plačljive le za Delodajalce. Vse
    naročene storitve so na voljo v skladu s Cenikom na podlagi Pogodbenega
    razmerja vzpostavljenega med Delodajalcem in Ponudnikom in potrditve cene.
    Plačilo se izvede skladno z roki opredeljenimi v Ceniku (predhodno ali po
    opravljeni Storitvi). Rok za plačilo opravljenih Storitev je osem (8) dni od
    prejema ustrezno izstavljenega računa s strani Ponudnika. V primeru zamude s
    plačilom je Ponudnik upravičen do zaračunavanja zakonskih zamudnih obresti
    in vseh ostalih stroškov, ki so mu v zvezi s takšno zamudo nastali.
  </p>
  <p class="termsAndConditionsContainerDownText">
    Ponudnik zaračunava vse Storitev po Ceniku kot tudi provizijo za uspešno sklenjeno
    sodelovanje med Delodajalcem in Iskalcem zaposlitve, in sicer ločeno za vsako sklenjeno sodelovanje.
    Znesek, ki zapade v plačilo po tovrstnem uspešno sklenjenem sodelovanju je opredeljen s Cenikom.
  </p>
</div>
<div class="termsAndConditionsContainerDown">
  <h2 class="termsAndConditionsContainerDownTitle">POGODBENA KAZEN</h2>
  <p class="termsAndConditionsContainerDownText">
    Ponudnik je upravičen zaračunati pogodbeno kazen Delodajalcu, ki krši ta
    Pravila in pogoje in Ponudnika ne obvesti (i) o podani oziroma ne podani
    ponudbi Iskalcu zaposlitve, s katerim je vzpostavljen stik prek
    Preferenca.si, (ii) sprejeti ali zavrnjeni ponudbi takšnega Iskalca
    zaposlitve, (iii) če svoje obveznosti pod točko (i) in (ii) ne izpolni niti
    v roku trideset (30) dni od prejema pisnega poziva Ponudnika za razjasnitev
    posameznega dogodka med Delodajalcem in Iskalcem zaposlitve, ali (iv) v
    primeru podajanja lažnih informacij glede podanih ponudb Iskalcu zaposlitve
    in uspešnosti sklenitve sodelovanja med Delodajalcem in Iskalcem zaposlitve
    v okviru Spletnega mesta Preferenca.si. Ponudnik je upravičen zaračunati
    pogodbeno kazen za vsako ugotovljeno kršitev, in sicer v višini vrednosti
    Storitve, ki bi jo Ponudnik upravičeno zaračunal v primeru uspešno
    sklenjenega sodelovanja med Delodajalcem in Iskalcem zaposlitve.
  </p>
  <p class="termsAndConditionsContainerDownText">
    Ne glede na zaračunano pogodbeno kazen, si Ponudnik pridržuje pravico
    onemogočiti dostop do Uporabniškega profila Delodajalca in nadaljnjo uporabo
    Storitev Preferenca.si Delodajalcu, za katerega sumi, da krši določila teh
    Pravil in pogojev brez predhodnega opozorila.
  </p>
</div>
<div class="termsAndConditionsContainerDown">
  <h2 class="termsAndConditionsContainerDownTitle">
    PREKINITEV UPORABE STORITEV
  </h2>
  <p class="termsAndConditionsContainerDownText">
    Uporabnikom so Storitve na Preferenca.si voljo vse dokler ima Uporabnik
    dostop do svojega Uporabniškega profila Preferenca.si, 
    Delodajalec mora imeti tudi sklenjeno veljavno naročnino za uporabo Storitev. Upravljavec si
    pridržuje pravico do prekinitve zagotavljanja Storitev brez kakršnegakoli
    razloga, o čemer si bo prizadeval pravočasno obvestiti Uporabnika. V
    primerih, ko Uporabnik krši ta Pravila in pogoje ali veljavno zakonodajo
    lahko Upravljavec prekine uporabo Storitev in pogodbeni odnos med Ponudnikom
    in Uporabnikom brez odpovednega roka. Če je odprava kršitev možna, bo
    Upravljavec o tem obvestil Uporabnika in mu dodelil razumen rok za njihovo
    odpravo, ki ne sme biti krajši od petnajst (15) dni. V primeru, da kršitev
    ni odpravljena v danem roku, oziroma če iz okoliščin izhaja, da odprava
    kršitev ni možna, se Uporabniku onemogoči nadaljnja uporaba Storitev
    Preferenca.si. Pogodbeno razmerje med Uporabnikom in Ponudnikom se s tem
    prekine. Uporabnik lahko enostransko prekine Pogodbeno razmerje na
    Preferenca.si tako, da zahteva izbris svojega Uporabniškega profila.
    Uporabnik lahko še petnajst (15) dni po ukinitvi svojega Uporabniškega
    profila zahteva prenos morebitnih podatkov in vsebin, ki so v njegovi lasti
    in jih je vnesel tekom uporabe Spletnega mesta Prefrenca.si. Po izteku
    navedenega obdobja se vsi podatki, za katere ne obstaja pravna podlaga za
    daljšo hrambo, trajno brišejo.
  </p>
  <p class="termsAndConditionsContainerDownText">
    Določila vezana na finančne obveznosti Uporabnikov, avtorske pravice,
    varovanje poslovne skrivnosti in morebitna druge pravice in obveznosti, za
    katera se je v praksi oblikovalo pravilo, da ostanejo v veljavi tudi po
    prekinitvi Pogodbenega razmerja, ostanejo v veljavi toliko časa, kot je to
    opredeljeno v teh Pravilih in pogojih ali drugem pisnem dokument sklenjenim
    med Uporabnikom in Ponudnikom ali v skladu z veljavno zakonodajo Republike
    Slovenije.
  </p>
</div>
<div class="termsAndConditionsContainerDown">
  <h2 class="termsAndConditionsContainerDownTitle">KONČNE DOLOČBE</h2>
  <p class="termsAndConditionsContainerDownText">
    Upravljavec si pridržuje pravico, da občasnih sprememb teh Pravil in
    pogojev, če meni, da je to potrebno. Spremenjena Pravila in pogoji stopijo v
    veljajo z dnem njihove objave na tem Spletnem mestu. Obstoječi Uporabniki
    bodo o morebitnih spremembah obveščeni po elektronski poti, in sicer
    petnajst (15) dni pred pričetkom veljavnosti tovrstnih sprememb. V danem
    obdobju lahko Uporabnik prekine obstoječe Pogodbeno razmerje in preneha
    uporabljati Storitve ter izbriše svoj uporabniški račun Preferenca.si, če
    tega ne stori v danem roku ali nadaljuje z uporabo Storitev, se šteje, da se
    z novimi Pravili in pogoji v celoti strinja.
  </p>
  <p class="termsAndConditionsContainerDownText">
    Če je ali postane posamezno določilo v teh Pravilih in pogojih neveljavno
    ali neizvršljivo, takšno določilo ne vpliva na veljavnost in izvršljivost
    preostalih določil. Upravljavec si bo prizadeval neveljavno ali neizvršljivo
    določilo nadomestiti z novim, ki odraža namen prvotnega določila.
  </p>
  <p class="termsAndConditionsContainerDownText">
    Za razlago neopredeljenih zadev v teh Pravilih in pogojih se uporablja
    veljavno pravo Republike Slovenije.
  </p>
  <p class="termsAndConditionsContainerDownText">
    Vse spore v zvezi s Spletnim mestom Preferenca.si in njegovimi Storitvami si
    rešujejo po mirni poti in na sporazumen način. V primeru, da sporazumna
    rešitev spora ni možna, je za sodno reševanje sporov pristojno sodišče v
    Ljubljani.
  </p>
  <p class="termsAndConditionsContainerDownText">
    V primeru vprašanj glede teh Pravil in pogojev ali <a href="#privacy-policy">Politike zasebnosti in
    varovanja osebnih podatkov</a> ali če želite dodatna pojasnila v zvezi z
    delovanjem Spletnega mesta Preferenca.si in njenih Storitev, nam pišite na
    elektronski naslov podpora@preferenca.si. Potrudili se bomo, da vam bomo
    odgovorili v najkrajšem možnem času.
  </p>
</div>
<div class="termsAndConditionsContainerDown">
  <h2 class="termsAndConditionsContainerDownTitle">KONTAKTNI PODATKI</h2>
  <p class="termsAndConditionsContainerDownText">
    Styria digital marketplaces, d.o.o. <br />
    Verovškova 55 <br />
    SI - 1000 Ljubljana <br />
    Davčna številka: SI58257551 <br />
    Matična številka: 5391997000 <br />
    <br />
    Email: podpora@preferenca.si <br />
    Tel: +386 1 513 57 00 / 080 1 DELO (080 1 3356)
  </p>
</div>
<p class="termsAndConditionsDateText">Ljubljana, 31. 01. 2023.</p>`,
  'termsAndConditions.generalTermsAndConditionsHtml': `
<div class="termsAndConditionsContainerDown">
  <p class="termsAndConditionsContainerDownText termsAndConditionsFirstText">
    Družba Styria digital marketplaces, d.o.o. je lastnik in upravljavec različnih domen in poddomen,
  na katerih so na voljo storitve povezane z zaposlovanjem (ponujanje in iskanje zaposlitve oziroma 
    vzpostavljanje stika med iskalci zaposlitve in delodajalci), pridobivanjem oziroma nadgrajevanjem 
    neformalnih veščin in znanj kot tudi druge storitve, ki so na kakršen koli način povezane s kadrovanjem 
    in razvojem kadra.
  </p>
  <h2 class="termsAndConditionsContainerDownTitle">DEFINICIJE POJMOV</h2>
  <p class="termsAndConditionsContainerDownText">
    Pojmi v teh Splošnih pogojih, pisani z veliko začetnico, imajo vnaprej določen pomen, kot natančneje opisan
    v nadaljevanju. Navedeni pojmi zajemajo tako žensko kot moško obliko, ednino, dvojino in množino, razen v 
    primerih, ko iz sobesedila izhaja drugače.
  </p>
  <div class="termsAndConditionsContainerDownText">
      <ul class="termsAndConditionsListStyle">
        <li>
          Upravljavec, Ponudnik ali Lastnik se nanaša na družbo Styria digital marketplaces, d.o.o., Verovškova 55,
          SI- 1000 Ljubljana, ki je lastnik in upravljavec vseh Spletnih mest, na katerih so na voljo Storitve.
        </li>
        <li>
          Spletno mesto se nanaša na vsa spletna mesta, platforme in portale, ki se nahajajo na domenah
          <a href="https://www.mojedelo.com" target="_blank">www.mojedelo.com</a>,
            <a href="https://www.preferenca.si" target="_blank">www.preferenca.si</a>, 
            <a href="https://www.kariernisejem.com" target="_blank">kariernisejem.com</a> in 
            <a href="https://www.placa.si" target="_blank">plača.si</a> ter vseh njihovih poddomenah, 
            kakor tudi na vse njihove strani in podstrani, spletne in mobilne aplikacije.
        </li>
        <li>
          Splošni pogoji se nanaša na te Splošne pogoje uporabe, ki definirajo pravila in pogoje dostopanja do
          Spletnih mest in uporabo Storitev, ki so Uporabnikom v danem trenutku na voljo na Spletnem mestu. 
          Splošni pogoji veljajo do njihovega preklica ali izdaje novih. O tovrstnih spremembah so Uporabniki 
          vselej obveščeni po elektronski poti. Spremembe stopijo v veljavo z dnem njihove objave na Spletnem mestu.
        </li>
        <li>
          Povezan dokument je vsak dokument, ki definira individualne pogoje in značilnosti dostopanja do posameznega
          Spletnega mesta in/ali uporabo posameznih Storitev, ne glede na njegovo poimenovanje (med navedene dokumente
            se med drugim vštevajo pogoji uporabe posameznega Spletnega mesta), pri čemer tak povezan dokument 
            skupaj s temi Splošnimi pogoji tvori nedeljivo celoto. V primeru odstopanj med Splošnimi pogoji in 
            povezanim dokumentom, se primarno uporabljajo pogoji povezanega dokumenta, kot specialnejšega dokumenta. 
            Vsak povezan dokument se lahko občasno posodobi ali prekliče, in sicer pod enakimi pogoji, kot to velja
              za Splošne pogoje.
        </li>
        <li>
          Uporabnik se nanaša tako na pravne kot fizične osebe, ki dostopajo in pregledujejo Spletno mesto oziroma 
          uporabljajo njegove Storitve, ne glede na način dostopa (kot registriran ali neregistriran uporabnik). 
          Pojem uporabnik se nanaša na vse tipe uporabnikov. Kadar je potrebna distinkcija med uporabniki 
          (na primer med pravnimi in fizičnimi osebami ali registriranimi in neregistriranimi uporabniki) se lahko 
          vpeljejo drugi pojmi, ki natančneje opredeljuje različne tipe uporabnikov.
        </li>
        <li>
        Neregistriran uporabnik je vsak Uporabnik, ki ni opravil registracije in so mu 
        na voljo omejene funkcionalnosti Spletnega mesta.
        </li>
        <li>
          Registriran uporabnik je vsak Uporabnik, ki v celoti izpolni registracijski obrazec in uspešno zaključi
          registracijski postopek na Spletnem mestu ter s tem kreira svoj Uporabniški račun. Registriran uporabnik 
          mora biti na datum registracije star 16 let ali več.
        </li>
        <li>
          Uporabniški račun se nanaša na račun Registriranega uporabnika, ki se vzpostavi s sprejemom teh Splošnih 
          pogojev in predstavlja enotne dostopne podatke za prijavo in dostopanje do Spletnih mesti in njihovih 
          Storitev ter za upravljanje z Uporabniškimi profili Spletnih mest. Vsak Uporabnik ima lahko le en 
          aktiven uporabniški račun.
        </li>
        <li>
          Uporabniški profil se nanaša na profil Registriranega uporabnika, namenjen uporabi Storitev posameznega 
          Spletnega mesta in je sestavni del Uporabniškega računa. Uporabniški profil se dopolnjuje skladno s 
          funkcionalnostmi Spletnega mesta in željami Registriranega uporabnika. Vsak Uporabnik ima lahko le en 
          aktiven Uporabniški profil na posameznem Spletnem mestu.
        </li>
        <li>
          Potrošnik je Uporabnik, ki je fizična oseba, kot ga opredeljuje Zakon o varstvu potrošnikov 
          (Ur. l. RS, št. 98/04 s spremembami, »ZVPot«), in nastopa izven svoje poklicne ali pridobitne 
          dejavnosti.
        </li>
        <li>
          Storitev zajema vse storitve, ki so na voljo na Spletnem mestu, pri čemer je za uporabo in dostopanje 
          do večine storitev potrebna registracija Uporabnika. Storitve zajemajo različne funkcionalnosti, ki se
          lahko občasno spremenijo ali dopolnijo, kar se šteje kot del storitev in ne predstavlja spremembe 
          Splošnih pogojev.
        </li>
        <li>
          Cenik je dokument, ki zajema vse redne cene za Storitve in/ali uporabo oziroma dostopanje do Spletnega 
          mesta. Vse cene v ceniku so v eurih (EUR) in ne vključujejo davka na dodano vrednost (DDV), ki se 
          obračunava skladno z vsakokrat veljavno davčno stopnjo. Potrošnik do storitev dostopa brezplačno, če ni 
          drugače opredeljeno v Povezanih dokumentih, ki individualno urejajo uporabo posameznega Spletnega mesta 
          in/ali Storitev. Kadar Upravljavec v sklopu Spletnega mesta omogoča plačljive storitve Potrošniku, cene 
          takšnih storitev vedno vključujejo tudi DDV. Vsi Ceniki so na voljo na spletnih straneh Spletnega mesta.
        </li>
        <li>
          Pogodbeno razmerje je razmerje med Ponudnikom in Uporabnikom, ki se vzpostavi na podlagi sprejema teh
          Splošnih pogojev na Spletnem mestu ali na drug ustrezen način, ki se vselej zabeleži v pisni obliki. 
          Pogodbeno razmerje, ki je sklenjeno na podlagi individualnega dokumenta oz. izven teh spletnih strani 
          Spletnega mesta vedno zajema tudi registracijo Uporabnika na Spletnem mestu.
        </li>
        <li>
          Vsebine pomeni vse vsebine, ki so objavljene na Spletnem mestu in so v lasti Upravljavca, vključno z deli
          teksta, grafikami, slikami logotipi, gumbi, multimedijskimi vsebinami, programsko opremo in izvorno. 
          Med te vsebine se ne štejejo vsebine Uporabnikov, ki jih slednji samostojno objavljajo na Spletnem mestu.
        </li>
        <li>
          Znamka se nanaša na vse pravice iz znamk in v povezavi z njimi, logotipe, korporativna imena, imena domen
          ter katerikoli drug znak ali označbo, katerega imetnik je Upravljavec, ne glede na to, ali je znamka 
          registrirana oziroma kako drugače zaščitena ali ne.
        </li>
      </ul>
    </div>
</div>
 <div class="termsAndConditionsContainerDown">
    <h1 class="termsAndConditionsContainerDownTitle">
      UPORABA STORITEV IN REGISTRACIJA
    </h1>
    <p class="termsAndConditionsContainerDownText">
      Spletno mesto vsem Uporabnikom, tako registriranim kot neregistriranim, zagotavlja dostop do svojih 
      spletnih strani in pregledovanje osnovnih informacij, na določenih Spletnih mestih pa tudi naročanje na 
      prejemanje občasnih elektronskih novic, za kakršnokoli nadaljnjo uporabo je obvezna registracija Uporabnika.
    </p>
    <p class="termsAndConditionsContainerDownText">
      Z registracijo na Spletnem mestu se vzpostavi Pogodbeno razmerje med Ponudnikom in Uporabnikom. 
      Registracija Uporabnika zajema vnos osnovnih podatkov v registracijski obrazec, potrebnih za kreiranje 
      Uporabniškega računa, kot so ime, priimek, elektronski naslov in določitev gesla v skladu z navodili, ki
      jih zahteva Spletno mesto v času registracije ter sprejem teh Splošnih pogojev. Kadar Uporabnik
      registracijo opravi na Spletnem mestu, za katerega veljajo tudi ločena pravila in pogoji, z registracijo 
      sprejema tudi tovrstna pravila in pogoje uporabe Spletnega mesta, na podlagi katerih se Uporabniku 
      kreira Uporabniški profil takšnega Spletnega mesta. Registracija je zaključena po njeni potrditvi prek 
      potrditvenega sporočila, ki ga Uporabnik prejme na svoj elektronski naslov, naveden ob registraciji. 
      Uporabnik izvede potrditev in s tem zaključi registracijo v roku 72 ur od prejema obvestila v svoj 
      e-poštni predal, v nasprotnem primeru se registracija šteje za preklicano. Prejeti podatki Uporabnikov, 
      ki so registracijo preklicali, se brišejo v roku 48 ur od izteka roka za potrditev registracije.
    </p>
    <p class="termsAndConditionsContainerDownText">
      Z registracijo se Uporabnik strinja, da bo Spletno mesto in vse njegove Storitve uporabljal v skladu s temi
       Splošnimi pogoji in drugimi Povezanimi dokumenti, da bo Storitve uporabljal le v imenu in za račun osebe, 
       ki je registracijo opravila. Upravljavec si pridržuje izrecno pravico do zavrnitve registracije
        kateregakoli Uporabnika, ki (i) ne izpolnjuje ali za katerega obstaja sum oziroma velika verjetnost, 
        da ne bo izpolnjeval svojih obveznosti, kot izhaja iz vsakokrat objavljenih Splošnih pogojev in Povezanih
         dokumentov, (ii) posreduje nepravilne ali neresnične podatke ali podatke tretjih oseb ali (iii) že 
         razpolaga z aktivnim Uporabniškim računom.
    </p>
    <p class="termsAndConditionsContainerDownText">
      Vnos drugih podatkov, ki so potrebni za uporabo posameznih Storitev in presegajo podatke, zahtevane v 
      okviru osnovne registracije, se beležijo v Uporabniškem profilu Uporabnika, ustvarjenem za namen in skladno 
      s pravili in pogoji posameznega Spletnega mesta. Pri tem so vsi podatki, ki jih Uporabnik vnese na enem 
      Spletnem mestu, na voljo tudi na drugem Spletnem mestu, če drugo Spletno mesto omogoča vnos in prikaz 
      enakih vrst podatkov. Spremembe, ki jih Uporabnik vnese na enem Spletnem mestu so posledično vidne tudi 
      na drugem Spletnem mestu (na primer sprememba življenjepisa na Spletnem mestu 
        <a href="https://www.preferenca.si" target="_blank">www.preferenca.si</a> 
        vpliva na vsebino življenjepisa v okviru Spletnega mesta <a href="https://www.mojedelo.com" target="_blank">www.mojedelo.com</a>).
    </p>
  </div>
  <div class="termsAndConditionsContainerDown">
    <h1 class="termsAndConditionsContainerDownTitle">
    VSEBINE SPLETNEGA MESTA
    </h1>
    <p class="termsAndConditionsContainerDownText">
      Upravljavec je imetnik spletnih domen www.mojedelo.com, www.preferenca.si, kariernisejem.com in plača.si, 
      njihovih poddomen kot tudi povezanih Znamk. Navedene domene, poddomene in Znamke skupaj z vsemi Vsebinami 
      so avtorsko pravno zaščiteni. Kakršnakoli uporaba navedenih domen, poddomen ali Znamk <a href="https://www.mojedelo.com" target="_blank">MojeDelo.com</a>), 
      <a href="https://www.preferenca.si" target="_blank">Preferenca.si</a>, <a href="https://www.kariernisejem.com" target="_blank">KarierniSejem.com</a> oziroma njihovih izpeljank v okviru poddomen kot tudi Vsebin, v kateri 
      koli obliki in za kateri koli namen, je prepovedana razen, če je takšna uporaba izrecno dovoljena s temi 
      Splošnimi pogoji, Povezanimi dokumenti ali veljavno zakonodajo.
    </p>
    <p class="termsAndConditionsContainerDownText">
    Vsebine Spletnega mesta je dovoljeno uporabljati le za zasebne namene, če drugačen način uporabe ni posebej 
    dovoljen ali prepovedan s temi Splošnimi pogoji, Povezanimi dokumenti ali veljavno zakonodajo. Prepovedani so 
    prodaja ali nadgradnja Spletnega mesta, posnemanje in izvajanje dejavnosti, ki bi škodovale ugledu, delovanju
    in/ali uporabi Spletnega mesta in/ali Upravljavca. Prepovedana je kraja ali kopiranje baze podatkov delno 
    ali v celoti, uporaba avtomatskih poizvedb ali drugih robotov, ki avtomatsko pridobivajo podatke s Spletnega
    mesta ali na drugačen način avtomatsko posegajo ali komunicirajo s Spletnim mestom, razen za spletne 
    iskalnike (ang. universal search engines - iskalniki, ki iščejo in objavljajo povezave na vse spletne 
    strani iz določenega geografskega področja in niso omejeni glede na vsebino, panogo, tipe datotek ali druge 
    značilnosti) oziroma če ni to izrecno dogovorjeno z Upravljavcem. Za vsako komercialno rabo Vsebin Spletnega 
    mesta je potreben predhoden pisni dogovor z Upravljavcem. 
    </p>
    <p class="termsAndConditionsContainerDownText">
    Spletno mesto lahko omogoči Uporabniku pripravo in objavo določenih lastnih vsebin vključno z lastnimi 
    logotipi z namenom uporabljanja vseh Storitev posameznega Spletnega mesta, pri čemer Uporabnik, s 
    posredovanjem in/ali objavo lastnih vsebin na Spletnem mestu, prenaša na Upravljavca neizključno in 
    teritorialno neomejeno vse materialne avtorske pravice kot opredeljene v 22. členu Zakona o avtorski in 
    sorodnih pravicah, na posredovanih in/ali objavljenih vsebinah, vse dokler med Upravljavcem in Uporabnikom 
    obstaja veljavno Pogodbeno razmerje. Prenos avtorskih pravic se uredi le za potrebe nemotene uporabe vseh 
    funkcionalnosti in Storitev na Spletnem mestu. Uporabnik lahko prenos avtorskih pravic predčasno prekliče
    tako, da svoje vsebine izbriše s Spletnega mesta. Vsebine Uporabnika se lahko uporabljajo v obsegu in za 
    namene, ki ga določi Uporabnik, ki je vsebine objavil.
    </p>
    <p class="termsAndConditionsContainerDownText">
    Upravljavec dovoljuje ogledovanje ter uporabo Vsebin na Spletnem mestu vsem Uporabnikom, pri čemer pa le-ti 
    ne smejo kršiti avtorskih pravic Upravljavca ali tretjih oseb. Brez pisnega dovoljenja avtorja oziroma 
    Upravljavca ali Uporabnika, ki je vsebine objavil, ali njihovih pooblaščenih oseb ni dovoljeno vsebin na 
    Spletnem mestu delno ali v celoti kopirati, razmnoževati, ponovno objavljati, spreminjati, nalagati, tiskati 
    in razširjati, razen za osebno uporabo in uporabo, ki je izrecno dovoljena s posameznimi Storitvami 
    Spletnega mesta.
    </p>
    <p class="termsAndConditionsContainerDownText">
    Upravljavec se bo trudil za točnost, pravilnost, ažurnost ter koristnost Vsebin, vendar ni odgovoren za 
    njihovo točnost, pravilnost, ažurnost ali koristnost. Upravljavec ni odgovoren za škodljive posledice uporabe 
    Vsebin Spletnega mesta.
    </p>
  </div>
  <div class="termsAndConditionsContainerDown">
    <h1 class="termsAndConditionsContainerDownTitle">
      DOSTOPNOST SPLETNEGA MESTA
    </h1>
    <p class="termsAndConditionsContainerDownText">
      Upravljavec si prizadeva, da bodo Vsebine in Storitve Spletnega mesta dostopne za uporabo vse dni na leto, 
      24 ur na dan, razen kadar gre za krajše ustavitve iz tehničnih razlogov. Upravljavec si pridržuje pravico, 
      da kadar koli, iz katerega koli razloga in za kakršno koli obdobje, brez predhodnega obvestila prekine 
      delovanje posameznega Spletnega mesta, če meni, da je to potrebno. V primeru, da gre za daljše ustavitve, 
      ob večjih tehničnih posegih in vzdrževalnih delih (več kot 12 zaporednih ur), se Upravljavec zavezuje, da 
      bo o tovrstnih motnjah Uporabnike obvestil prek Spletnega mesta do tri (3) dni pred začetkom del, če bo to 
      le mogoče.
    </p>
    <p class="termsAndConditionsContainerDownText">
      Zaradi varnostnih razlogov je čas vpisa podatkov v spletne obrazce omejen na trideset (30) minut, po 
      preteku navedenega časa se seja avtomatsko zaključi, morebitni neshranjeni podatki pa brišejo. Upravljavec 
      poziva Uporabnike, da, z namenom preprečevanja neželenih izgub podatkov, pogosteje shranjujejo vpisane 
      podatke.
    </p>
  </div>
  <div class="termsAndConditionsContainerDown">
    <h1 class="termsAndConditionsContainerDownTitle">
      OSEBNI PODATKI
    </h1>
    <p class="termsAndConditionsContainerDownText">
      Osebne podatke Uporabnikov na teh spletnih straneh obdelujejo le pooblaščene osebe Upravljavca. Upravljavec 
      obdeluje osebne podatke Uporabnikov z namenom zagotavljanja dostopa do Spletnega mesta in omogočanja uporabe 
      lastnih Storitev, kot natančneje opredeljeno v 
      <a href="#privacy-policy" target="_blank">Politiki zasebnosti in varovanja osebnih podatkov</a>, ki je 
      sestavni del teh Splošnih pogojev.
    </p>
    <p class="termsAndConditionsContainerDownText">
      Vsak Uporabnik prostovoljno vnaša svoje osebne podatke na Spletnem mestu in jih posreduje Upravljavca oziroma 
      določa njihovo mejo vidnosti drugim Uporabnikom, če je takšno upravljanje osebnih podatkov Uporabnika 
      omogočeno v sklopu funkcionalnosti Spletnega mesta.
    </p>
    <p class="termsAndConditionsContainerDownText">
      Izrecno prepovedano je vnašanje osebnih podatkov tretjih oseb in pripenjanje kopij osebnih dokumentov na 
      Spletnem mestu. Upravljavec poziva vse Uporabnike, da tudi sicer preudarno vnašajo svoje podatke na Spletno 
      mesto in izpustijo podatke, ki jih ne želijo deliti z Upravljavcem in/ali tretjimi osebami ali na podlagi 
      katerih jih je možno prepoznati. Upravljavec ne odgovarja za kakršno koli škodo, ki bi jo posamezniki 
      lahko utrpeli, kot posledico posredovanja osebnih dokumentov in navedenih podatkov.
    </p>
  </div>
  <div class="termsAndConditionsContainerDown">
    <h1 class="termsAndConditionsContainerDownTitle">
      GESLA UPORABNIKOV
    </h1>
    <p class="termsAndConditionsContainerDownText">
      Upravljavec se zavezuje, da bo vsa uporabniška imena in gesla hranil kot poslovno skrivnost. Z njimi bodo 
      ravnale le pooblaščene osebe Upravljavca. Upravljavec si pridržuje pravico do deaktivacije Uporabnikov in 
      onemogočanja gesel v primeru suma zlorabe gesla ali kršenja teh Splošnih pogojev s strani Uporabnika, 
      kateremu je geslo dodeljeno.
    </p>
    <p class="termsAndConditionsContainerDownText">
      Uporabniška gesla so namenjena v uporabo osebam, ki so jim izdana. Uporabnik je dolžan prejeto geslo 
      uporabljati ter zanj skrbeti kot dober gospodar in ga venomer varovati v skladu z zakonodajo s področja 
      varovanja osebnih podatkov. Izrecno prepovedano je neupravičeno in/ali nezakonito poseganje v zasebnost 
      drugih Uporabnikov, kamor se všteva tudi komunikacija, ki ni neposredno povezana z namenom Spletne strani. 
      Gesla, ki so dodeljena predstavnikom oziroma pooblaščenim osebam Uporabnika, se ne sme deliti s tretjimi 
      oziroma nepooblaščenimi osebami. Vsi Uporabniki so odgovorni za dejanja opravljena z njihovimi 
      uporabniškimi podatki.
    </p>
  </div>
  <div class="termsAndConditionsContainerDown">
    <h1 class="termsAndConditionsContainerDownTitle">
      OMEJITEV ODGOVORNOSTI
    </h1>
    <p class="termsAndConditionsContainerDownText">
      Upravljavec Spletnih mest kot tudi njegovi lastniki niso odgovorni za:
    </p>
    <div class="termsAndConditionsContainerDownText">
      <ul class="termsAndConditionsListStyle">
        <li>
          kakršnekoli okvare ali izgubo podatkov, ki so posledica napačne uporabe, tehnične neustreznosti programske 
          ali strojne opreme ali neznanja oziroma nespretnosti Uporabnika;
        </li>
        <li>
          morebitne poškodbe programske ali računalniške opreme, s katero Uporabniki dostopajo do Spletnega mesta 
          in Storitev;
        </li>
        <li>
          slabo delovanje ali nedelovanje Spletnega mesta zaradi motenj ali nedelovanja internetnega omrežja med 
          Uporabnikom in Upravljavcem;
        </li>
        <li>
          motnje delovanja Spletnega mesta, ki so posledica višje sile;
        </li>
        <li>
          morebitno škodo, ki bi nastala Uporabniku zaradi nezmožnosti uporabe Spletnega mesta, zaradi zaupanja v 
          vsebino Storitve, zaradi izgube podatkov ali zaradi drugih razlogov povezanih z delovanjem in dostopnostjo Spletnega mesta;
        </li>
        <li>
          vsebine, ki so objavljene na Spletnem mestu, kjer gre za vsebine, ki jih vnašajo Uporabniki samostojno, 
          posledično Upravljavec ne prevzema odgovornosti za resničnost in zakonitost objavljenih vsebin, ki jih na 
          Spletnem mestu objavijo Uporabniki;
        </li>
        <li>
          napačne ali neresnične podatke Uporabnikov, ki jih slednji samostojno vpisujejo na Spletnem mestu;
        </li>
        <li>
          zagotovitev odzivnosti Uporabnikov pri uporabi katerekoli Storitve;
        </li>
        <li>
          pozno, pomanjkljivo dostavo ali nedostavo elektronskih sporočil posredovanih v okviru Spletnega mesta 
          in/ali SMS sporočil, če takšno funkcijo Spletno mesto omogoči;
        </li>
        <li>
          za škodljive posledice uporabe vsebin Spletnega mesta, za njihovo pravilnost, točnost, popolnost ali ažurnost.
        </li>
      </ul>
    </div>
    <p class="termsAndConditionsContainerDownText">
      V primeru, da Uporabnik zazna informacije, podatke ali kakršne koli druge vsebine na teh spletnih straneh, 
      ki so v nasprotju s temi Splošnimi pogoji, Povezanimi dokumenti in/ali veljavno zakonodajo oziroma ki 
      kakorkoli kršijo pravice tretjih oseb, lahko o tem obvesti Upravljavca prek e-naslova podpora@preferenca.si, 
      ki bo po preverjanju ustrezno ukrepal in, v primeru izkazanih nepravilnosti, sporne vsebine odstranil.
    </p>
    <p class="termsAndConditionsContainerDownText">
      V povezavi z zgoraj navedenim si Upravljavec pridržuje pravico do izbrisa katere koli vsebine, če meni, da 
      slednja kot celota ali v delu ne ustreza tem Splošnim pogojem ali krši oz. bi lahko predstavljala kršitev 
      veljavne zakonodaje ali pravic tretjih oseb. Prav tako si Upravljavec pridržuje pravico, da iz istega 
      razloga izbriše posameznega Uporabnika in mu onemogoči nadaljnjo uporabo Spletnega mesta. Poleg tega si 
      Upravljavec pridržuje pravico, da prepreči uporabo Spletnih mest Uporabniku, ki ni izpolnil vseh svojih 
      obveznosti po teh Splošnih pogojih in/ali drugih Povezanih dokumentih, vključno s finančnimi, ob dostopanju 
      oziroma uporabi katerekoli izmed Storitev. Uporabnik ni upravičen zahtevati vračila plačil zaradi nezmožnosti 
      uporabe oziroma dostopanja do Storitev na teh Spletnih straneh, ki so mu bile onemogočene iz naslova kršitev 
      teh Splošnih pogojev.
    </p>
  </div>
  <div class="termsAndConditionsContainerDown">
    <h1 class="termsAndConditionsContainerDownTitle">
      KONČNE DOLOČBE
    </h1>
    <p class="termsAndConditionsContainerDownText">
      Upravljavec si pridržuje pravico, da občasnih sprememb teh Splošnih pogojev, če meni, da je to potrebno. 
      Spremenjeni Splošni pogoji stopijo v veljajo z dnem njihove objave na Spletnem mestu. Obstoječi Uporabniki 
      bodo o spremembah obveščeni po elektronski poti, in sicer petnajst (15) dni pred pričetkom veljavnosti 
      tovrstnih sprememb. V danem obdobju lahko Uporabnik prekine obstoječe Pogodbeno razmerje in preneha 
      uporabljati Storitve ter izbriše svoj Uporabniški račun, če tega ne stori v danem roku ali nadaljuje z 
      uporabo Storitev, se šteje, da se z novimi Splošnimi pogoji v celoti strinja.
    </p>
    <p class="termsAndConditionsContainerDownText">
     Če je ali postane posamezno določilo v teh Splošnih pogojih neveljavno ali neizvršljivo, takšno določilo ne 
     vpliva na veljavnost in izvršljivost preostalih določil. Upravljavec si bo prizadeval neveljavno ali 
     neizvršljivo določilo nadomestiti z novim, ki odraža namen prvotnega določila.
    </p>
    <p class="termsAndConditionsContainerDownText">
      Za razlago neopredeljenih zadev v teh Splošnih pogojih se uporablja veljavno pravo Republike Slovenije.
    </p>
    <p class="termsAndConditionsContainerDownText">
      Vse spore v zvezi s Spletnim mestom in njegovimi Storitvami se rešujejo po mirni poti in na sporazumen način. 
      V primeru, da sporazumna rešitev spora ni možna, je za sodno reševanje sporov pristojno sodišče v Ljubljani.
    </p>
    <p class="termsAndConditionsContainerDownText">
      V primeru vprašanj glede teh Splošnih pogojev ali 
      <a href="#privacy-policy" target="_blank">Politike zasebnosti in varovanja osebnih podatkov</a> 
      ali če želite dodatna pojasnila v zvezi z delovanjem Spletnega mesta in njegovih Storitev, nam pišite na 
      elektronski naslov podpora@preferenca.si Potrudili se bomo, da vam odgovorimo v najkrajšem možnem času.
    </p>
  </div>
  <div class="termsAndConditionsContainerDown">
    <h2 class="termsAndConditionsContainerDownTitle">KONTAKTNI PODATKI</h2>
    <p class="termsAndConditionsContainerDownText">
      Styria digital marketplaces, d.o.o. <br />
      Verovškova 55 <br />
      SI - 1000 Ljubljana <br />
      Davčna številka: SI58257551 <br />
      Matična številka: 5391997000 <br />
      <br />
      Email: podpora@preferenca.si <br />
      Tel: +386 1 513 57 00 / 080 1 DELO (080 1 3356) 
    </p>
  </div>
  <p class="termsAndConditionsDateText">Ljubljana, 31. 01. 2023.</p>
`,
}
