import {blacklistsettings} from './Blacklist/en'
import {changeemailsettings} from './ChangeEmail/en'
import {changepasswordsettings} from './ChangePassword/en'
import {languagesettings} from './Language/en'
import {settingsdropdown} from './SettingsDropdown/en'
import {myAccount} from './MyAccount/en'

export const settings = {
  ...languagesettings,
  ...changeemailsettings,
  ...changepasswordsettings,
  ...blacklistsettings,
  ...settingsdropdown,
  ...myAccount,
}
