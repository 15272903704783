import {DateTime} from 'luxon'

const MONTHS_SHORT_EN = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
]

export const formatFullDate = (date: Date | string) => {
  if (typeof date !== 'object') {
    date = new Date(date)
  }
  const monthIndex = date.getMonth()
  const year = date.getFullYear()
  const day = date.getDate()
  const monthName = MONTHS_SHORT_EN[monthIndex]

  return `${day} ${monthName} ${year}`
}

export const formatTime = (date: Date | string) => {
  if (typeof date !== 'object') {
    date = new Date(date)
  }

  return `${date.getHours()}:${date.getMinutes()}`
}

export const formatInterviewTime = (startDate: string, endDate: string) => {
  const startTime = DateTime.fromISO(startDate).toFormat('HH:mm')
  const endTime = DateTime.fromISO(endDate).toFormat('HH:mm')

  return `${startTime} - ${endTime}`
}

export const getAge = (date: string | Date) => {
  if (typeof date !== 'object') {
    date = new Date(date)
  }

  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000)
  let intervalType = 'year'

  const interval = Math.floor(seconds / 31536000)

  if (interval > 1 || interval === 0) {
    intervalType += 's'
  }

  return interval + ' ' + intervalType
}

export const isInLessThan10min = (
  startDate?: Date | string,
  endDate?: Date | string
): boolean => {
  if (!startDate) return false
  let localStartDate, localEndDate
  if (typeof startDate !== 'object') {
    localStartDate = new Date(startDate)
  } else localStartDate = startDate
  if (typeof endDate !== 'object') {
    localEndDate = endDate ? new Date(endDate) : endDate
  } else localEndDate = endDate

  const TEN_MINUTES = 10 * 60
  let secondsToStart = Math.floor(
    (localStartDate.getTime() - new Date().getTime()) / 1000
  )
  let secondsToEnd = localEndDate
    ? Math.floor(
        ((localEndDate as Date).getTime() - new Date().getTime()) / 1000
      )
    : 0

  return (
    secondsToStart < TEN_MINUTES && (secondsToStart > 0 || secondsToEnd > 0)
  )
}

const timeInterval = (seconds: number) => {
  let intervalType

  let interval = Math.floor(seconds / 31536000)
  if (interval >= 1) {
    intervalType = 'year'
  } else {
    interval = Math.floor(seconds / 2592000)
    if (interval >= 1) {
      intervalType = 'month'
    } else {
      interval = Math.floor(seconds / 86400)
      if (interval >= 1) {
        intervalType = 'day'
      } else {
        interval = Math.floor(seconds / 3600)
        if (interval >= 1) {
          intervalType = 'hour'
        } else {
          interval = Math.floor(seconds / 60)
          if (interval >= 1) {
            intervalType = 'minute'
          } else {
            interval = seconds
            intervalType = 'second'
          }
        }
      }
    }
  }

  if (interval > 1 || interval === 0) {
    intervalType += 's'
  }

  return [interval, intervalType]
}

export const timeUntil = (date: Date | string) => {
  if (typeof date !== 'object') {
    date = new Date(date)
  }

  const seconds = Math.floor((date.getTime() - new Date().getTime()) / 1000)

  const [interval, intervalType] = timeInterval(seconds)

  return interval + ' ' + intervalType
}
