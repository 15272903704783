export const changeemailsettings = {
  'changeemailsettings.title': 'Moj račun',
  'changeemailsettings.titleChangeEmail': 'SPREMENITE ELEKTRONSKI NASLOV',
  'changeemailsettings.newEmailAddress': 'Nov elektronski naslov',
  'changeemailsettings.typeNewAddress': 'Vpišite nov elektronski naslov..',
  'changeemailsettings.cancel': 'Prekličite',
  'changeemailsettings.saveChanges': 'Shrani spremembe',
  'changeemailsettings.successMessage':
    'Na vaš elektronski naslov smo poslali potrditveno sporočilo. Preverite svoj poštni predal in potrdite spremembo elektronskega naslova.',
}
