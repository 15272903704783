import {createStore, applyMiddleware} from 'redux'
import createSagaMiddleware from 'redux-saga'
import {history} from './history'
import {routerMiddleware} from 'connected-react-router'
import {composeWithDevTools} from 'redux-devtools-extension'

import RootReducer from './Reducers'
import RootSaga from './Sagas'

const sagaMiddleware = createSagaMiddleware()

const store = createStore(
  RootReducer(history),
  {},
  composeWithDevTools(
    applyMiddleware(routerMiddleware(history), sagaMiddleware)
  )
)

sagaMiddleware.run(RootSaga)

export type AppDispatch = typeof store.dispatch
export default store
