import Modal from 'Components/Modal'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import {useFormik} from 'formik'
import classnames from 'classnames'
import {
  BaseTaxonomyType,
  SkillType,
  TaxonomiesStateType,
  AddSkillType,
  SeekerStateType,
} from 'Interfaces'
import {FC, useState, useMemo, Fragment, useEffect} from 'react'
import {useApiError, useTranslate} from 'Hooks'
import SectionHeader from '../SectionHeader'
import Validation from './Validation'
import styles from './Skills.module.scss'

import Hr from 'Components/Hr'
import {TypeAheadWithCategory} from 'Components/Inputs/TypeAheadImproved'
import Dropdown from 'Components/Inputs/Dropdown'
import ItemTextWithStars from 'Components/Profile/Skills/ItemTextWithStars'
import ItemStarsEdit from 'Components/Profile/Skills/ItemStarsEdit'
import ItemHeader from 'Components/Profile/ItemHeader'
import SectionItem from 'Components/Profile/SectionItem'

import ShowMore from 'Components/Profile/ShowMore'
import ShowMoreContent from 'Components/ShowMoreContent'
import {Sort} from './Sort'
import {
  ProfileTranslations as pt,
  OnboardingTranslations as ot,
} from 'Services/I18n/Constants'
import {cloneDeep} from 'lodash'
import {Extension, DeleteOutline} from '@mui/icons-material'

interface iSkillsProps {
  profile: SeekerStateType
  skills?: SkillType[]
  taxonomies: TaxonomiesStateType
  addSkill: (value: AddSkillType) => void
  editSkill: (value: SkillType) => void
  deleteSkill: (value: SkillType) => void
  getTaxonomies: () => void
  canChange?: boolean
}

export const Skills: FC<iSkillsProps> = ({
  profile,
  skills = [],
  taxonomies,
  addSkill,
  editSkill,
  deleteSkill,
  getTaxonomies,
  canChange = false,
}: iSkillsProps) => {
  //TODO should be refactored to use just one ItemStars component
  const defSkillForEdt: any[] = []
  const [modalAddOpen, setModalAddOpen] = useState(false)
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [modalEditCategory, setModalEditCategory] = useState('')
  const [skillsForDeletion, setSkillsForDeletion] = useState(defSkillForEdt)
  const [showMoreIndex, setShowMoreIndex] = useState(5)
  const translate = useTranslate()

  const forDeleteSkills = useMemo(() => {
    return skills.map((item) => item.skill?.id)
  }, [skills])

  const taxonomiesPure = useMemo(() => {
    let taxonomiesSkillsCopy = cloneDeep(taxonomies.skills.data)
    return taxonomiesSkillsCopy.filter((e: any) => {
      return !forDeleteSkills.includes(e.id)
    })
  }, [taxonomies.skills.data, forDeleteSkills])

  const selectedSkills = useMemo(() => {
    return skills.filter((item) => item.skill).map((item) => item.skill)
  }, [skills])

  const skillsByCategory = useMemo(() => {
    const tempSkills: {[catName: string]: SkillType[]} = {}
    skills.forEach((skill) => {
      if (skill.skillCategoryName) {
        if (!tempSkills[skill.skillCategoryName]) {
          tempSkills[skill.skillCategoryName] = []
        }
        tempSkills[skill.skillCategoryName].push(skill)
      }
    })

    return tempSkills
  }, [skills])

  let copiedSkillsByCategory = cloneDeep(skillsByCategory)

  interface iEmptyAddSkillValues {
    skill?: BaseTaxonomyType | null
    skillLevelId: string
  }

  const emptyAddSkillValues: iEmptyAddSkillValues = {
    skill: null,
    skillLevelId: '',
  }

  const openAdd = () => {
    getTaxonomies()
    formik.resetForm()
    setModalAddOpen(true)
  }
  const openEdit = (skillCategoryName: string) => {
    setSkillsForDeletion(copiedSkillsByCategory[skillCategoryName])
    setModalEditCategory(skillCategoryName)
    setModalEditOpen(true)
  }
  const onRemoveSkill = (id: string) => {
    const skillsAfterRemove = skillsForDeletion.map((skill: any) => {
      if (skill.id === id) {
        skill.deleted = true
      }
      return {...skill}
    })
    setSkillsForDeletion([...skillsAfterRemove])
  }

  const updateSkillsLevel = (skillsForEdit: any[]) => {
    skillsForEdit.forEach((skill: any) => {
      if (!skill) return
      if (skill.changed) {
        editSkill({
          skillId: skill.skill.id,
          skillName: skill.skill.translation,
          id: skill.id,
          skillLevel: {
            ...skill.skillLevel,
          },
        })
      }
    })
  }

  useEffect(() => {
    getTaxonomies()
  }, [])

  const onChangeSkillLevel = (
    id: string,
    levelId?: string,
    stateStarLevel?: string | number,
    skillLevelTranslation?: string,
    skillCategoryName?: string
  ) => {
    let skillsForRating =
      skillCategoryName && copiedSkillsByCategory[skillCategoryName]
    const skills =
      skillsForRating &&
      skillsForRating.map((skill) => {
        if (!skill) return

        if (skill.id === id && skill.skillLevel?.id !== levelId) {
          return {
            ...skill,
            changed: true,
            skillLevel: {
              id: levelId,
              level: stateStarLevel,
              translation: skillLevelTranslation,
            },
          }
        }
        return {...skill}
      })
    skills && updateSkillsLevel(skills)
  }
  const deleteSkills = () => {
    skillsForDeletion.forEach((skill) => {
      if (!skill) return
      if (skill.deleted) {
        deleteSkill(skill)
      }
    })
  }

  const formik = useFormik({
    initialValues: emptyAddSkillValues,
    validationSchema: Validation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      if (values.skill?.id.startsWith('newValue~')) {
        addSkill({
          skillLevelId: values.skillLevelId,
          skillName: values.skill.translation,
        })
      } else {
        addSkill({skillLevelId: values.skillLevelId, skillId: values.skill?.id})
      }
    },
  })

  const setTypeAheadField = (name: string, values: BaseTaxonomyType[]) => {
    formik.setFieldValue(name, values[0])
  }

  useApiError(formik.setFieldError, profile.error, 'skill')
  useEffect(() => {
    if (!profile.loading && !profile.error) {
      setModalAddOpen(false)
      setModalEditOpen(false)
    }
  }, [profile.loading])

  const sortedSkills = useMemo(() => {
    const groupedSkills = cloneDeep(skillsByCategory)
    for (const skillCategoryName in groupedSkills) {
      groupedSkills[skillCategoryName] = Sort(groupedSkills[skillCategoryName])
    }

    return groupedSkills
  }, [skillsByCategory])

  useEffect(() => {
    formik.validateForm()
  }, [formik.values.skill, formik.values.skillLevelId])
  const Other = translate('Other')

  return (
    <div className={styles.section}>
      <div>
        <SectionHeader
          onAdd={canChange ? openAdd : undefined}
          title={pt.skillsTitle}
        />
      </div>

      {Object.keys(sortedSkills)
        .slice(0, showMoreIndex)
        .sort((a, b) => (a === Other ? 1 : b === Other ? -1 : 0))
        .map((skillCategoryName) => (
          <SectionItem key={skillCategoryName}>
            <ItemHeader
              title={skillCategoryName}
              icon={Extension}
              onEdit={canChange ? () => openEdit(skillCategoryName) : undefined}
            />
            <div className={styles.content}>
              {sortedSkills[skillCategoryName]
                .slice(0, 5)
                .map((skillItem: any, index: number) => (
                  <Fragment key={skillItem.id || index}>
                    <Hr className={styles.HrLine} />
                    <div className={styles.skillContainer}>
                      <p className={styles.text}>
                        {skillItem.skill?.translation}
                      </p>
                      {canChange ? (
                        <ItemStarsEdit
                          id={skillItem.id}
                          level={(skillItem.skillLevel?.level as number) || 0}
                          onChangeSkillLevel={onChangeSkillLevel}
                          skillLevels={taxonomies.skillLevels}
                          skillCategoryName={skillCategoryName}
                        />
                      ) : (
                        <ItemTextWithStars skill={skillItem} />
                      )}
                    </div>
                  </Fragment>
                ))}
              <ShowMoreContent theme="black" mobileHeight={1} height={1}>
                <>
                  {sortedSkills[skillCategoryName]
                    .slice(5, sortedSkills[skillCategoryName].length)
                    .map((skillItem: any, index: number) => (
                      <Fragment key={skillItem.id || index}>
                        <Hr className={styles.HrLine} />
                        <div className={styles.skillContainer}>
                          <p className={styles.text}>
                            {skillItem.skill?.translation}
                          </p>

                          {canChange ? (
                            <ItemStarsEdit
                              id={skillItem.id}
                              level={
                                (skillItem.skillLevel?.level as number) || 0
                              }
                              onChangeSkillLevel={onChangeSkillLevel}
                              skillLevels={taxonomies.skillLevels}
                              skillCategoryName={skillCategoryName}
                            />
                          ) : (
                            <ItemTextWithStars skill={skillItem} />
                          )}
                        </div>
                      </Fragment>
                    ))}

                  <Hr className={styles.HrLastLine} />
                </>
              </ShowMoreContent>
            </div>
          </SectionItem>
        ))}
      <ShowMore
        items={Object.keys(skillsByCategory)}
        showMoreIndex={showMoreIndex}
        setShowMoreIndex={setShowMoreIndex}
        defaultShowMoreIndex={5}
      />
      <Modal
        isOpen={modalAddOpen}
        onAfterClose={() => {
          setModalAddOpen(false)
        }}
        title={pt.skillAdd}
        icon={Extension}
        ariaHideApp={false}
        className={styles.modal}
        Footer={
          <SubmitFooter
            onSubmit={formik.handleSubmit}
            onCancel={() => {
              setModalAddOpen(false)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <TypeAheadWithCategory
          className={styles.addTypeAhead}
          name="skill"
          theme="white"
          items={taxonomiesPure}
          value={formik.values.skill ? [formik.values.skill] : undefined}
          setValue={setTypeAheadField}
          setTouched={formik.getFieldHelpers('skill').setTouched}
          placeholder={ot.selectTypeYourSkills}
          maxNumSelections={1}
          selectNonExisting
          error={
            formik.submitCount && formik.errors?.skill
              ? formik.errors.skill
              : undefined
          }
          invalidNonExisting={selectedSkills as BaseTaxonomyType[]}
          categoryPropName="skillCategory"
          categoryOpenOnTyping
        />

        <Dropdown
          label={pt.selectSkillLevel}
          name="skillLevelId"
          items={taxonomies.skillLevels.data}
          value={formik.values.skillLevelId}
          error={
            formik.submitCount && formik.errors.skillLevelId
              ? formik.errors.skillLevelId
              : undefined
          }
          setValue={formik.setFieldValue}
          theme="white"
        />
      </Modal>

      <Modal
        isOpen={modalEditOpen}
        onAfterClose={() => {
          setModalEditOpen(false)
          setModalEditCategory('')
        }}
        title={pt.skillEdit}
        icon={Extension}
        ariaHideApp={false}
        className={classnames(styles.modal, styles.modalEdit)}
        Footer={
          <SubmitFooter
            onSubmit={() => {
              setModalEditOpen(false)
              setModalEditCategory('')
              deleteSkills()
            }}
            onCancel={() => {
              setModalEditOpen(false)
              setModalEditCategory('')
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        {Object.keys(skillsByCategory).map((skillCategoryName) => {
          if (skillCategoryName == modalEditCategory) {
            return (
              <SectionItem
                key={skillCategoryName}
                className={styles.editSkillsModalSection}
              >
                <ItemHeader title={skillCategoryName} icon={Extension} />
                <div className={styles.content}>
                  {skillsForDeletion.map(
                    (skillItem: SkillType, index: number) => {
                      if (skillItem && !skillItem.deleted) {
                        return (
                          <Fragment key={skillItem.id || index}>
                            {index !== 0 ? (
                              <Hr className={styles.hrMargin} />
                            ) : null}
                            <div className={styles.gridRow}>
                              <div className={styles.gridRowModalEdit}>
                                <p className={styles.skillName}>
                                  {skillItem.skill?.translation}
                                </p>
                                <button
                                  className={styles.deleteBoxSmall}
                                  type="button"
                                  onClick={() => onRemoveSkill(skillItem.id)}
                                >
                                  <DeleteOutline />
                                </button>
                              </div>
                              <div className={styles.editItem}>
                                <ItemTextWithStars skill={skillItem} />
                                <button
                                  className={styles.deleteBoxBig}
                                  type="button"
                                  onClick={() => onRemoveSkill(skillItem.id)}
                                >
                                  <DeleteOutline />
                                </button>
                              </div>
                            </div>
                          </Fragment>
                        )
                      }
                      return null
                    }
                  )}
                </div>
              </SectionItem>
            )
          }
          return null
        })}
      </Modal>
    </div>
  )
}
