import {RjbProfileStatus} from 'Interfaces'
export const canChangeProfile = (
  rjbProfileStatus?: RjbProfileStatus
): boolean => {
  return rjbProfileStatus
    ? [
        RjbProfileStatus.NEW,
        RjbProfileStatus.APPROVED,
        RjbProfileStatus.PENDING_REVIEW,
        RjbProfileStatus.DRAFT,
      ].includes(rjbProfileStatus)
    : false
}

export const canSubmitProfile = (
  rjbProfileStatus?: RjbProfileStatus
): boolean => {
  return rjbProfileStatus
    ? [
        RjbProfileStatus.NEW,
        RjbProfileStatus.APPROVED,
        RjbProfileStatus.PENDING_REVIEW,
      ].includes(rjbProfileStatus)
    : false
}
