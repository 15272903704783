import {
  useEffect,
  FC,
  useState,
  useMemo,
  useRef,
  useCallback,
  ChangeEvent,
} from 'react'
import classnames from 'classnames'
import {cloneDeep, isEqual} from 'lodash'
import {Link} from 'react-router-dom'
import {FormattedMessage} from 'react-intl'
import {
  PlayCircleOutline,
  EditOutlined,
  DeleteOutline,
  Close,
} from '@mui/icons-material'

import styles from './SavedSearch.module.scss'
import MainLayout from 'Layouts/MainLayout'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import CompanyProfileFooter from 'Components/Footer'
import {WidgetBarContainer as WidgetBar} from 'Layouts/MainLayout/WidgetBar'
import MainHeader from 'Components/MainHeader'
import {SavedSearchDataType, SavedSearchType} from 'Interfaces'
import Checkbox from 'Components/Inputs/Checkbox'
import {formatDate} from 'Utils/Date'
import {Button} from 'Components/Button'
import TextError from 'Components/Error/TextError'
import {DeleteModal} from 'Components/Modal'
import {savedSearchToUrl} from 'Utils/Search'
import EditSavedSearchModal from 'Components/CandidateSearch/SavedSearch/EditSavedSearchModal'
import {CandidateSearchTranslations as cst} from 'Services/I18n/Constants'
import {useTranslation} from 'Hooks'
import {SidebarLink} from 'Components/SidebarLink'

interface SavedSearchProps {
  getSavedSearch: () => void
  deleteSavedSearch: (id: string) => void
  savedSearch: SavedSearchDataType
  updateSavedSearch: (values: SavedSearchType) => void
}

export const SavedSearch: FC<SavedSearchProps> = ({
  getSavedSearch,
  deleteSavedSearch,
  savedSearch,
  updateSavedSearch,
}) => {
  const [localSavedSearch, setLocalSavedSearch] = useState(
    cloneDeep(savedSearch.data)
  )
  const [deleteModal, setDeleteModal] = useState<undefined | number>(undefined)
  const [editModal, setEditModal] = useState<undefined | number>(undefined)
  const [isMoreInfoOpen, setIsMoreInfoOpen] = useState<
    undefined | {left: number; top: number; height: number}
  >(undefined)
  const moreInfoRef = useRef<HTMLDivElement>(null)

  const translation = useTranslation()

  const clearMoreInfo = () => {
    setIsMoreInfoOpen(undefined)
  }

  useEffect(() => {
    getSavedSearch()
    document.body.addEventListener('scroll', clearMoreInfo)
    return () => {
      document.body.removeEventListener('scroll', clearMoreInfo)
    }
  }, [])

  useEffect(() => {
    if (!savedSearch.loading) setLocalSavedSearch(cloneDeep(savedSearch.data))
  }, [savedSearch.data])

  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: getRoute(AppRoute.HomePageEmployer)},
    {name: cst.savedSearchPageTitle},
  ]

  const canAddToFavorite = useMemo(
    () => localSavedSearch.filter((s) => s.isFavorite).length < 5,
    [localSavedSearch]
  )
  const canSave = useMemo(
    () => !isEqual(savedSearch.data, localSavedSearch),
    [savedSearch.data, localSavedSearch]
  )

  const Footer: FC = () => {
    return (
      <div>
        <CompanyProfileFooter />
      </div>
    )
  }

  const SidebarContent = () => {
    return (
      <>
        <div className={styles.sidebarLinks}>
          <SidebarLink
            routeName={AppRoute.CandidateSearch}
            text={cst.candidateSearchLink}
          />
          <SidebarLink
            routeName={AppRoute.EmployerDashboard}
            text={cst.dashboardLink}
          />
        </div>
      </>
    )
  }

  const MoreInfoPopover = useMemo(() => {
    if (isMoreInfoOpen) {
      const {top, left, height} = isMoreInfoOpen

      return (
        <div
          className={styles.infoPopoverWrap}
          style={{
            top: `${top + height + 13}px`,
            left: `${left - 240}px`,
          }}
          tabIndex={0}
          ref={moreInfoRef}
          onBlur={clearMoreInfo}
        >
          <FormattedMessage id="You can show up to 5 searches in your search favorites." />
          <button className={styles.closeButton} onClick={clearMoreInfo}>
            <Close />
          </button>
        </div>
      )
    }
    return null
  }, [isMoreInfoOpen])

  const handleSave = () => {
    localSavedSearch.forEach((ls, index) => {
      if (!isEqual(ls, savedSearch.data[index])) {
        updateSavedSearch(ls)
      }
    })
  }

  useEffect(() => {
    if (!savedSearch.loading && !savedSearch.error) {
      setEditModal(undefined)
      setDeleteModal(undefined)
      setIsMoreInfoOpen(undefined)
    }
  }, [savedSearch.loading, savedSearch.error])

  const handleFavorite = useCallback(
    (
      saved: SavedSearchType,
      index: number,
      e: ChangeEvent<HTMLInputElement>
    ) => {
      if (
        !canAddToFavorite &&
        !saved.isFavorite &&
        e.currentTarget.parentNode
      ) {
        const {top, left, height} = (
          e.currentTarget.parentNode as HTMLLabelElement
        ).getBoundingClientRect()
        setIsMoreInfoOpen({top, left, height})
      }
      if (saved.isFavorite || canAddToFavorite) {
        const newSavedSearch = cloneDeep(localSavedSearch)
        newSavedSearch[index].isFavorite = e.target.checked
        setLocalSavedSearch(newSavedSearch)
      }
    },
    [canAddToFavorite, localSavedSearch, setIsMoreInfoOpen, setLocalSavedSearch]
  )

  useEffect(() => {
    if (moreInfoRef?.current) moreInfoRef.current.focus()
  }, [moreInfoRef, MoreInfoPopover])

  return (
    <div className={styles.container}>
      <MainLayout
        fixedRight={false}
        rightSidebarContent={<WidgetBar sidebarContent={<SidebarContent />} />}
        Footer={<Footer />}
      >
        <div className={styles.headerWrap}>
          <MainHeader
            breadCrumbsWrapClassName={styles.breadcrumbsWrapper}
            breadcrumbsArray={breadcrumbsArray}
            title={cst.savedSearchPageTitle}
          />
        </div>
        <div className={styles.listWrap}>
          <div className={styles.listContainer}>
            <div
              className={classnames(styles.listHeader, {
                [styles.withScrollbar]: localSavedSearch.length > 4,
              })}
            >
              <div>
                <FormattedMessage id={cst.savedSearchColumnHeaderName} />
              </div>
              <div>
                <FormattedMessage id={cst.savedSearchColumnHeaderCreated} />
              </div>
              <div className={styles.center}>
                <FormattedMessage id={cst.savedSearchColumnHeaderFavorites} />
              </div>
              <div className={styles.center}>
                <FormattedMessage
                  id={cst.savedSearchColumnHeaderNotifications}
                />
              </div>
              <div>
                <FormattedMessage id={cst.savedSearchFrequencyTitle} />
              </div>
              <div>
                <FormattedMessage id={cst.savedSearchColumnHeaderLastCSASent} />
              </div>
              <div>
                <FormattedMessage id={cst.savedSearchColumnHeaderActions} />
              </div>
            </div>
            <div
              className={classnames(styles.list, {
                [styles.withScrollbar]: localSavedSearch.length > 4,
              })}
              onScroll={() => {
                if (localSavedSearch.length > 4 && isMoreInfoOpen)
                  clearMoreInfo()
              }}
            >
              {localSavedSearch.map((saved, index) => (
                <div key={saved.id} className={styles.listItem}>
                  <div className={styles.name}>
                    <span>{saved.name}</span>
                  </div>
                  <div>
                    <span className={styles.label}>
                      <FormattedMessage
                        id={cst.savedSearchColumnHeaderCreated}
                      />
                      :
                    </span>
                    {formatDate(saved.dateCreated || '')}
                  </div>
                  <div className={styles.checkboxWrap}>
                    <span className={styles.label}>
                      <FormattedMessage
                        id={cst.savedSearchColumnHeaderFavorites}
                      />
                      :
                    </span>
                    <Checkbox
                      value={saved.isFavorite}
                      onChange={(e) => {
                        handleFavorite(saved, index, e)
                      }}
                      name={`${saved.name}-isFavorite`}
                      className={classnames(styles.checkbox, {
                        [styles.disabledCheckbox]:
                          !canAddToFavorite && !saved.isFavorite,
                      })}
                    />
                  </div>
                  <div className={styles.checkboxWrap}>
                    <span className={styles.label}>
                      <FormattedMessage
                        id={cst.savedSearchColumnHeaderNotifications}
                      />
                      :
                    </span>
                    <Checkbox
                      value={saved.frequency !== 'disabled'}
                      onChange={(e) => {
                        const newSavedSearch = cloneDeep(localSavedSearch)
                        newSavedSearch[index].frequency = e.target.checked
                          ? 'daily'
                          : 'disabled'
                        setLocalSavedSearch(newSavedSearch)
                      }}
                      name={`${saved.name}-notifications`}
                      className={styles.checkbox}
                    />
                  </div>
                  <div className={styles.frequency}>
                    <span className={styles.label}>
                      <FormattedMessage id={cst.savedSearchFrequencyTitle} />:
                    </span>
                    <FormattedMessage id={saved.frequency || 'disabled'} />
                  </div>
                  <div>
                    {saved.dateLastCSASent ? (
                      <>
                        <span className={styles.label}>
                          <FormattedMessage
                            id={cst.savedSearchColumnHeaderLastCSASent}
                          />
                          :
                        </span>
                        <span>{formatDate(saved.dateLastCSASent || '')}</span>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className={styles.actions}>
                    <Link
                      to={`${getRoute(
                        AppRoute.CandidateSearch
                      )}?${savedSearchToUrl(saved)}`}
                    >
                      <PlayCircleOutline className={styles.icon} />
                    </Link>
                    <button
                      onClick={() => {
                        setEditModal(index)
                      }}
                    >
                      <EditOutlined className={styles.icon} />
                    </button>
                    <button
                      onClick={() => {
                        setDeleteModal(index)
                      }}
                    >
                      <DeleteOutline className={styles.icon} />
                    </button>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.errorSaveWrap}>
              {savedSearch.error && (
                <TextError text={savedSearch.error.message} />
              )}
              {canSave && (
                <div className={styles.buttons}>
                  <Button
                    theme="back"
                    className={styles.cancel}
                    onClick={() => {
                      setLocalSavedSearch(savedSearch.data)
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    theme="acceptRevoke"
                    className={styles.save}
                    onClick={handleSave}
                    disabled={savedSearch.loading}
                  >
                    Save
                  </Button>
                </div>
              )}
            </div>
          </div>
          {!canAddToFavorite && MoreInfoPopover}
        </div>
        <DeleteModal
          isOpen={deleteModal !== undefined}
          setOpen={(open) => {
            setDeleteModal(open ? deleteModal : undefined)
          }}
          text={translation[cst.savedSearchDeleteModal]}
          deleteAction={() => {
            deleteSavedSearch(
              (deleteModal !== undefined &&
                localSavedSearch[deleteModal]?.id) ||
                'emptyDelete'
            )
            setDeleteModal(undefined)
          }}
          id={
            (deleteModal !== undefined && localSavedSearch[deleteModal]?.id) ||
            'emptyDelete'
          }
        />
        {editModal !== undefined && (
          <EditSavedSearchModal
            isOpen
            setOpen={(b) => {
              setEditModal(!b ? undefined : editModal)
            }}
            savedSearchToEdit={localSavedSearch[editModal || 0]}
            editSavedSearch={updateSavedSearch}
            savedSearchState={savedSearch}
          />
        )}
      </MainLayout>
    </div>
  )
}
