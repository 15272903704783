import {connect} from 'react-redux'

import {ProfileSidebar} from './ProfileSidebar'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  user: state?.seeker?.data?.full,
})

export const ProfileSidebarContainer = connect(
  mapStateToProps,
  null
)(ProfileSidebar)
