import {connect} from 'react-redux'

import {CandidateSidebarContent} from './CandidateSidebarContent'
import {ConversationType} from 'Interfaces'
import {AppDispatch} from 'Store'
import {
  getCandidateComments,
  getCandidateHistory,
  getConversationByUserIds,
  selectConversation,
  resetMessageCenterData,
} from 'Store/Actions'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  request: state.widgetBar.data.request,
  candidateBasicData: state.widgetBar.data.candidateBasicData,
  comments: state.widgetBar.data.comments,
  history: state.widgetBar.data.history,
  openedConversation: state.messageCenter.data[0],
  messages: state.messageCenter.openedConversation?.messages,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCandidateComments: (id: string) => dispatch(getCandidateComments(id)),
  getCandidateHistory: (id: string) => dispatch(getCandidateHistory(id)),
  getConversationByUserIds: (query: string) =>
    dispatch(getConversationByUserIds(query)),
  selectConversation: (conversation?: ConversationType) =>
    dispatch(selectConversation(conversation)),
  resetMessageCenterData: () => dispatch(resetMessageCenterData()),
})

export const CandidateSidebarContentContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateSidebarContent)
