export const consents = {
  'consents.title': 'Moja Soglasja',
  'consents.labelTitle': 'NAZIV',
  'consents.labelStatus': 'STATUS',
  'consents.labelDate': 'DATUM',
  'consents.labelContent': 'VSEBINA',
  'consents.labelAction': 'AKCIJA',
  'consents.consentGrant': 'Podajte soglasje',
  'consents.consentRevoke': 'Umaknite soglasje',
  'consents.consentAccept': 'Sprejmi soglasje',
  'consents.consentRecall': 'Umaknite soglasje',
  'consents.consentGranted': 'Podano',
  'consents.consentRevoked': 'Umaknjeno',
  'consents.termsOfUse': 'Pogoji uporabe',
  'consents.privacyPolicyPsychometric': 'Politiki zasebnosti.',
  'consents.privacyPolicy':
    'Politiki zasebnosti in varovanja osebnih podatkov.',
  'consents.cookiesPolicy': 'Politika piškotkov',
  'consents.consentsExpired': 'Potečeno',
  'consents.consentsUpToDate': 'Vaša soglasja so posodobljena.',
  'consents.consentsUpToDateFullAdvantage': 'Vaša soglasja so posodobljena.', //missing the full version of this translation (check english for comparison)
  'consents.consentsContent': 'Vsebina',
  'consents.consentsDownload': 'Prenesite',
  'consents.consentsPrint': 'Natisnite',
  'consents.modalTermsOfUSeAgree':
    'Strinjam se s pogoji uporabe portal  & transactional emailsa',
  'consents.infoModalPsychometric':
    'Soglašam z obdelavo svojih osebnih podatkov za pripravo mojega poročila psihometričnega testiranja na strani družbe Styria digital marketplaces, d.o.o., ki ga bom prejel/a v uporabniški profil na spletnem portalu Preferenca.si, z namenom zagotavljanja prilagojenih ponudb novih zaposlitvenih možnosti. Več informacij o obdelavi vaših osebnih podatkov si preberite v ',
  'consents.infoModalCognitive':
    'Soglašam z obdelavo svojih osebnih podatkov, posredovanih na podlagi sledečega vprašalnika, z namenom priprave mojega poročila testiranja kognitivnih sposobnosti na spletnem portalu Preferenca.si, ki ga bom prejel/a v svoj uporabniški profil. Priprava poročila temelji na avtomatizirani obdelavi posredovanih podatkov, več informacij o obdelavi podatkov si preberite v ',
  'consents.infoModalNewsletter':
    'eNovice Preferenca.si so elektronska obvestila namenjena informiranju registriranih uporabnikov o novostih in sorodnih storitvah spletnega mesta Preferenca.si, če se od prejemanja tovrstnih obvestil niste odjavili v trenutku registracije ali v katerem koli trenutku po opravljeni registraciji. Več informacij v ',
  'consents.infoModalNewsletter2':
    'V primeru vaše odjave od prejemanja eNovice Preferenca.si, bomo vaš e-naslov prenehali uporabljati za namene obveščanja o novostih in sorodnih storitvah spletnega mesta Preferenca.si, in sicer v roku 15 dni od prejema vaše odjave.',
  'consents.infoModalPersonalData':
    'Soglašam z obdelavo svojega e-naslova za namen neposrednega trženja, ki vključuje oblikovanje meni prilagojenih ponudb na podlagi mojih interesov, navad in drugih informacij, ki jih družba Styria digital marektlaces, d.o.o. prejme kot upravljavec mojih osebnih podatkov. S podajo vaše privolitve vas bomo obveščali o vsebinah družbe Styria digital marketplaces, d.o.o. kot tudi o vsebinah njenih partnerjev (tretjih oseb). Tovrstna obvestila zajemajo informacije o aktualnih akcijah, novostih, produktih, nagradnih igrah in drugih dogodkih. Več informacij o obdelavi vaših podatkov si preberite v ',
  'consents.modalPersonalData':
    'Soglašam z obdelavo svojega e-naslova za namen neposrednega trženja, ki vključuje oblikovanje meni prilagojenih ponudb na podlagi mojih interesov, navad in drugih informacij, ki jih družba Styria digital marketplaces, d.o.o. prejme kot upravljavec mojih osebnih podatkov.',
  'consents.modalMarketingText':
    'V tem primeru vas ne bomo več obveščali o vsebinah družbe Styria digital marketplaces, d.o.o. niti o vsebinah njenih partnerjev (tretjih oseb). Tovrstna obvestila zajemajo informacije o aktualnih akcijah, novostih, produktih, nagradnih igrah in drugih dogodkih.',
  'consents.modalCantBeRevoked':
    'Odvzem soglasja iz tehničnih razlogov ter zaradi zagotavljanja storitev ni mogoč. Če imate dodatna vprašanja ali želite izbrisati svoj uporabniški račun, nas lahko kontaktirate na podpora@preferenca.si.',
  'consents.modalWithdrawPrivacyOrCookies':
    'Ali ste prepričani, da želite umakniti soglasje? V tem primeru bomo deaktivirali vaš karierni profil, s čimer bodo trajno onemogočeni vsi vaši podatki in vse storitve, ki so na voljo registriranim uporabnikov (npr. prijave na prosta delovna mesta preko portal  & transactional emailsa MojeDelo.com, kreiranje e-opomnikov, urejanje življenjepisa ipd.).',
  'consents.statementOfConsent': 'IZJAVA O SOGLASJU',
  'consents.withdrawConsent':
    'ALI STE PREPRIČANI, DA ŽELITE UMAKNITI SOGLASJE?',
  'consents.modalWithdrawTestsConsents':
    'Opravljanje testa ali ogled rezultatov po odvzemu soglasja ne bo mogoč.',
  'consents.basicConsents': 'Osnovna soglasja',
  'consents.consentsForNotifications': 'Soglasja za obveščanje',
  'consents.consentsForTests': 'Soglasja za testiranja',
  'consents.granted': 'Podano',
  'consents.revoked': 'Umaknjeno',
  'consents.checkmarkText':
    'Strinjam se z obdelovanjem svojih osebnih podatkov z namenom kreiranja uporabniškega računa na portalu Preferenca ter zagotavljanja storitev, ki so namenjene registriranim uporabnikom.',
  'consents.privacyPolicyModalTitle':
    'Politika zasebnosti in varovanja osebnih podatkov',
  'consents.checkboxNewsletter':
    'Soglašam s prejemanjem eNovice Preferenca.si za namene obveščanja o novostih in sorodnih storitvah spletnega mesta Preferenca.si.',
  'consents.checkboxMarketing':
    'Soglašam z obdelavo svojega e-naslova za namen neposrednega trženja, ki vključuje oblikovanje meni prilagojenih ponudb na podlagi mojih interesov, navad in drugih informacij, ki jih družba Styria digital marektlaces, d.o.o. prejme kot upravljavec mojih osebnih podatkov.',
  'consents.thirdPartyMarketing':
    'Soglasje za namen neposrednega trženja družbe Styria digital marketplaces, d.o.o.',
  'consents.mandatoryConsentsText': 'Soglasja',
  'consents.mandatoryConsentsTitle': 'Potrebujemo vaše soglasje',
  'consents.mandatoryConsentsDescription':
    'You have successfully logged in to Preferenca.si, but we need the following consents from you to continue.',
}
