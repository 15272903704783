import {useEffect, useState, FC} from 'react'
import {useMediaQuery} from 'react-responsive'

import MainLayout from 'Layouts/MainLayout'
import MainHeader from 'Components/MainHeader'
import ConsentItemsContainer from 'Components/Consents/ConsentItemsContainer'
import {MobileFooter} from 'Components/MobileFooter'
import {ConsentType} from 'Interfaces/ConsentsType'
import FooterConsents from 'Components/Footer'
import styles from './Consents.module.scss'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {DownloadAndPrintHeader} from 'Components/DownloadAndPrintHeader'
import {
  ConsentsTranslations as ct,
  MyAccountTranslations as mat,
  SidebarTranslations as st,
} from 'Services/I18n/Constants'
import {useTranslation} from 'Hooks'

import {SidebarLink} from 'Components/SidebarLink'
import {AuthStateType} from 'Interfaces'
interface ConsentsContainerProps {
  allConsents: any
  getConsents: Function
  auth: AuthStateType
}

export const Consents: FC<ConsentsContainerProps> = ({
  allConsents,
  getConsents,
  auth,
}) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 900px)',
  })

  const translation = useTranslation()

  const companyUserRole =
    auth?.data?.role === 'employer' ||
    auth?.data?.role === 'company_owner' ||
    auth?.data?.role === 'recruiter'

  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: getRoute(AppRoute.HomePageCandidate)},
    {
      name: mat.myAccount,
      link: getRoute(
        companyUserRole ? AppRoute.EmployerMyAccount : AppRoute.MyAccount
      ),
    },
    {name: translation[ct.title]},
  ]
  const links = [
    {name: translation[ct.basicConsents], route: '#basicConsents'},
    {
      name: translation[ct.consentsForNotifications],
      route: '#notificationConsents',
    },
    {name: translation[ct.consentsForTests], route: '#testsConsents'},
  ]

  const [allConsentsArray, setAllConsentsArray] = useState<any>([])

  const Footer: FC = () => {
    return (
      <>{isMobile ? <MobileFooter links={links} hash /> : <FooterConsents />}</>
    )
  }

  const consentsCategories = (consents: ConsentType[]) => {
    return consents.map((consent: ConsentType) => {
      return consent.consentCategory.name
    })
  }

  const consentsUniqueCategories = (consents: ConsentType[]) => {
    return Array.from(new Set(consentsCategories(consents)))
  }

  const consents = (consents: ConsentType[]) => {
    const allConsents = {}

    const uniqueConsents = consentsUniqueCategories(consents)
    uniqueConsents.forEach((consentAll: string) => {
      allConsents[consentAll] = consents.filter((consent: ConsentType) => {
        return consent.consentCategory.name === consentAll
      })
    })

    return allConsents
  }

  useEffect(() => {
    if (allConsents.data) getConsents()
  }, [])

  useEffect(() => {
    setAllConsentsArray(consents(allConsents.data.consents))
  }, [allConsents])

  return (
    <MainLayout
      withoutLogo
      Footer={<Footer />}
      content={
        companyUserRole && (
          <>
            <SidebarLink
              routeName={AppRoute.CompanyProfile}
              text={st.companyProfile}
            />
          </>
        )
      }
    >
      <div className={styles.container}>
        {allConsents.data && (
          <>
            <div className={styles.consentsContainer}>
              <DownloadAndPrintHeader
                downloadLink={
                  process.env.PUBLIC_URL + '/files/moja_soglasja_04.07.2022.pdf'
                }
              />
              <MainHeader
                breadcrumbsArray={breadcrumbsArray}
                title={ct.title}
                className={styles.title}
              />
              <ConsentItemsContainer consents={allConsentsArray} />
            </div>
          </>
        )}
      </div>
    </MainLayout>
  )
}
