import {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {useFormik} from 'formik'
import SectionHeader from 'Components/Profile/SectionHeader'
import SectionItem from 'Components/Profile/SectionItem'
import ItemHeader from 'Components/Profile/ItemHeader'
import ItemTextWithIcon from 'Components/Profile/ItemTextWithIcon'
import CheckBox from 'Components/Inputs/Checkbox'
import TypeAheadImproved, {
  TypeAheadWithCategory,
} from 'Components/Inputs/TypeAheadImproved'
import TextArea from 'Components/Inputs/TextArea'
import Modal, {DeleteModal} from 'Components/Modal'
import ShowMore from 'Components/Profile/ShowMore'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import Dropdown from 'Components/Inputs/Dropdown'
import TextField from 'Components/Inputs/TextField'

import styles from './WorkExperiences.module.scss'
import Validation from './Validation'
import {
  BaseTaxonomyType,
  SeekerStateType,
  TaxonomiesStateType,
  WorkExperienceFormikValue,
  WorkExperienceItem,
} from 'Interfaces'
import {Sort} from './Sort'
import Title from 'Components/Title'
import Hr from 'Components/Hr'
import {
  useApiError,
  useGetMonth,
  useMonths,
  useTranslation,
  useYears,
} from 'Hooks'
import {DropdownProps, OnChangeProps, TypeAheadProps} from 'Utils/FormikProps'
import {
  ContactDataTranslations as cd,
  DateTranslations as dt,
  OnboardingTranslations as ot,
  ProfileTranslations as pt,
} from 'Services/I18n/Constants'
import {FormattedMessage, useIntl} from 'react-intl'
import TownAndCountry from 'Utils/TownAndCountry'
import nl2br from 'react-nl2br'
import {
  BusinessOutlined,
  LocationOnOutlined,
  TimerOutlined,
  UpdateOutlined,
  WorkOutlined,
} from '@mui/icons-material'
import {TypeAheadFetch} from 'Components/Inputs/TypeAheadFetch'

interface WorkExperiencesProps {
  profile: SeekerStateType
  workExperiences?: WorkExperienceItem[]
  taxonomies: TaxonomiesStateType
  getTaxonomies: () => void
  addWorkExperience: (value: WorkExperienceFormikValue) => void
  editWorkExperience: (value: WorkExperienceFormikValue) => void
  deleteWorkExperience: (id: string) => void
  canChange?: boolean
}

export const WorkExperiences: FC<WorkExperiencesProps> = ({
  profile,
  workExperiences = [],
  taxonomies,
  addWorkExperience,
  editWorkExperience,
  deleteWorkExperience,
  getTaxonomies,
  canChange = false,
}) => {
  const emptyValues: WorkExperienceFormikValue = {
    occupation: undefined,
    employer: undefined,
    currentEmployer: false,
    startMonth: '',
    startYear: '',
    endMonth: '',
    endYear: '',
    employmentType: undefined,
    town: undefined,
    country: undefined,
    workDescriptionAndAchievements: '',
    jobTitle: '',
  }

  const [modalOpen, setModalOpen] = useState(false)
  const [modalMode, setModalMode] = useState('Add')
  const [deleteModal, setDeleteModal] = useState<boolean | number>(false)
  const [showMoreIndex, setShowMoreIndex] = useState(3)
  const localWorkExperiences = useMemo(() => {
    return workExperiences.sort(Sort).slice(0, showMoreIndex)
  }, [workExperiences, showMoreIndex])
  const translation = useTranslation()

  const openEdit = (id?: string) => {
    setModalMode('Edit')
    const workItem = workExperiences.find(
      (e) => e.id === id
    ) as WorkExperienceItem

    formik.resetForm({
      values: {
        id: workItem.id,
        occupation: workItem.jobClassification,
        employer: workItem.employer,
        currentEmployer: workItem.currentEmployer,
        startMonth: workItem.startMonth ? workItem.startMonth.toString() : '',
        startYear: workItem.startYear ? workItem.startYear.toString() : '',
        endMonth: workItem.endMonth ? workItem.endMonth.toString() : '',
        endYear: workItem.endYear ? workItem.endYear.toString() : '',
        employmentType: workItem.employmentType,
        workDescriptionAndAchievements: workItem.workDescriptionAndAchievements,
        jobTitle: workItem.jobTitle ? workItem.jobTitle : '',
        town: workItem.town,
        country: workItem.country || taxonomies.countries?.data[0],
      },
    })
    getTaxonomies()
    setModalOpen(true)
  }

  const openAdd = () => {
    setModalMode('Add')
    formik.resetForm({
      values: emptyValues,
    })
    getTaxonomies()
    setModalOpen(true)
  }

  const formik = useFormik({
    initialValues: emptyValues,
    validationSchema: Validation,

    onSubmit: (values) => {
      if (modalMode === 'Edit') {
        editWorkExperience(values)
      } else addWorkExperience(values)
      setModalOpen(false)
    },
  })

  const years = useYears()
  const months = useMonths()

  const setTypeAheadField = useCallback(
    (name: string, values: BaseTaxonomyType[]) => {
      formik.setFieldValue(name, values[0])
    },
    []
  )

  const setDropdownField = useCallback(
    (name: string, value?: string | number) => {
      const employmentType = taxonomies.employmentTypes?.data.find((c) => {
        return typeof value === 'string' && c.id === value
      })
      formik.setFieldValue(name, employmentType)
    },
    [taxonomies.employmentTypes]
  )

  useApiError(formik.setFieldError, profile.error)
  useEffect(() => {
    if (!profile.loading && !profile.error) {
      setModalOpen(false)
      setDeleteModal(false)
    }
  }, [profile.loading])

  const memoizedEmployerDropdown = useMemo(() => {
    return (
      <TypeAheadImproved
        name="employer"
        defaultValue={formik.values.employer ? [formik.values.employer] : []}
        theme="modal"
        items={taxonomies.employers?.data}
        maxNumSelections={1}
        placeholder={pt.placeholderCompanyName}
        label={pt.companyName}
        setTouched={formik.getFieldHelpers('employer').setTouched}
        error={
          formik.submitCount && formik.errors?.employer
            ? formik.errors.employer
            : undefined
        }
        setValue={setTypeAheadField}
        selectNonExisting
      />
    )
  }, [
    formik.values.employer,
    taxonomies.employers.data,
    formik.errors?.employer,
    formik.submitCount,
  ])

  useEffect(() => {
    if (formik.values.currentEmployer) {
      formik.setFieldValue('endMonth', '')
      formik.setFieldValue('endYear', '')
    }
  }, [formik.values.currentEmployer])
  const uMonths = useGetMonth()
  const {formatMessage} = useIntl()

  const memoizedCountryTypeahead = useMemo(() => {
    return (
      <TypeAheadImproved
        name="country"
        theme="modalSimpleWhite"
        items={taxonomies.countries.data}
        defaultValue={
          formik.values.country
            ? [formik.values.country]
            : taxonomies.countries?.data[0]
            ? [taxonomies.countries?.data[0]]
            : undefined
        }
        setValue={(name, value) => {
          setTypeAheadField(name, value)
          if (formik.values.country?.id !== value[0]?.id) {
            formik.setFieldValue('town', null)
          }
        }}
        label={cd.country}
        setTouched={formik.getFieldHelpers('country').setTouched}
        placeholder={cd.selectYourCountry}
        maxNumSelections={1}
        error={(formik.submitCount && formik.errors?.country) || undefined}
      />
    )
  }, [
    formik.values.country,
    taxonomies.countries.data,
    formik.errors?.country,
    formik.submitCount,
  ])

  return (
    <div className={styles.section}>
      <div>
        <SectionHeader
          onAdd={canChange ? openAdd : undefined}
          title={ot.titleWorkExperience}
        />
      </div>
      {localWorkExperiences.map((workItem, index) => {
        return (
          <SectionItem key={workItem.id}>
            <ItemHeader
              title={
                workItem.jobTitle
                  ? `${workItem.jobTitle}`
                  : `${workItem.jobClassification?.translation}`
              }
              titleExpand={
                workItem.jobTitle
                  ? `${workItem.jobClassification?.translation}`
                  : ''
              }
              icon={WorkOutlined}
              onEdit={
                canChange
                  ? () => {
                      openEdit(workItem.id)
                    }
                  : undefined
              }
              onDelete={
                canChange
                  ? () => {
                      setDeleteModal(index)
                    }
                  : undefined
              }
            />
            <div className={styles.content}>
              <ItemTextWithIcon
                primaryText={pt.employer}
                secondaryText={workItem.employer?.translation}
                icon={BusinessOutlined}
                withColon
              />
              {workItem.town && workItem.country && (
                <ItemTextWithIcon
                  primaryText={pt.location}
                  secondaryText={TownAndCountry(workItem)}
                  icon={LocationOnOutlined}
                  withColon
                />
              )}
              <ItemTextWithIcon
                primaryText={pt.employmentDate}
                secondaryText={`${
                  workItem.startMonth ? uMonths[workItem.startMonth] : ''
                } ${workItem.startYear} - ${
                  workItem.endMonth ? uMonths[workItem.endMonth] : ''
                } ${
                  workItem.endYear
                    ? workItem.endYear
                    : workItem.currentEmployer
                    ? ` ${formatMessage({id: pt.present})}`
                    : ''
                }  `}
                icon={UpdateOutlined}
                withColon
              />
              <ItemTextWithIcon
                primaryText={pt.employmentType}
                secondaryText={workItem.employmentType?.translation}
                icon={TimerOutlined}
                withColon
              />
              {workItem.workDescriptionAndAchievements && (
                <p className={styles.text}>
                  {nl2br(workItem.workDescriptionAndAchievements)}
                </p>
              )}
            </div>
          </SectionItem>
        )
      })}
      <ShowMore
        items={workExperiences}
        showMoreIndex={showMoreIndex}
        setShowMoreIndex={setShowMoreIndex}
        defaultShowMoreIndex={3}
      />
      <Modal
        isOpen={modalOpen}
        onAfterClose={() => {
          setModalOpen(false)
        }}
        title={
          translation[
            modalMode === 'Add'
              ? ot.titleAddWorkExperience
              : ot.titleEditWorkExperience
          ]
        }
        icon={WorkOutlined}
        ariaHideApp={false}
        className={styles.modal}
        theme="desktopFlex"
        Footer={
          <SubmitFooter
            onSubmit={formik.handleSubmit}
            onCancel={() => {
              setModalOpen(false)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <div className={styles.workExperiencesContent}>
          <Title text={pt.jobClassification} theme="modal" />
          <TypeAheadWithCategory
            name="occupation"
            value={formik.values.occupation ? [formik.values.occupation] : []}
            theme="modal"
            items={taxonomies.occupations?.data}
            maxNumSelections={1}
            placeholder={pt.placeholderJobTitle}
            setValue={setTypeAheadField}
            setTouched={formik.getFieldHelpers('occupation').setTouched}
            error={
              formik.submitCount && formik.errors?.occupation
                ? formik.errors.occupation
                : undefined
            }
            selectNonExisting
            categoryPropName="jobCategory"
          />
          <Hr theme="modalSmall" />
          <Title text={pt.jobTitle} theme="modal" />
          <TextField
            name="jobTitle"
            placeholder={pt.placeholderJobTitle}
            {...OnChangeProps(formik, 'jobTitle')}
            theme="modal"
          />
          <Hr theme="modalSmall" />
          <Title text={pt.employmentType} theme="modal" />
          <Dropdown
            theme="white"
            name="employmentType"
            items={taxonomies.employmentTypes?.data}
            {...TypeAheadProps(formik, 'employmentType')}
            setValue={setDropdownField}
            error={
              formik.submitCount ? formik.errors['employmentType'] : undefined
            }
          />
          <Hr theme="modalSmall" />
          <Title text="employer" theme="modal" />
          <div className={styles.employer}>{memoizedEmployerDropdown}</div>
          <div className={styles.employerCurrent}>
            <label className={styles.employerCurrentLabel}>
              <CheckBox
                name="currentEmployer"
                value={!!formik.values.currentEmployer}
                onChange={() => {
                  formik.setFieldValue(
                    'currentEmployer',
                    !formik.values.currentEmployer
                  )
                }}
                setTouched={
                  formik.getFieldHelpers('currentEmployer').setTouched
                }
                error={
                  formik.submitCount
                    ? formik.errors['currentEmployer']
                    : undefined
                }
                theme="modal"
              />
              <FormattedMessage id={pt.myCurrentEmployer} />
            </label>
          </div>
          <Hr theme="modalSmall" />
          <Title text="City" theme="modal" />
          <TypeAheadFetch
            name="town"
            value={formik.values.town || null}
            options={[]}
            optionsQueryUrl={`/small-index/towns?${
              formik.values.country
                ? `countryId=${formik.values.country.id}&`
                : ''
            }name=`}
            setValue={formik.setFieldValue}
            label={cd.city}
            placeholder={pt.placeholderTown}
            error={
              formik.submitCount && formik.errors?.town
                ? formik.errors.town
                : undefined
            }
            smallIndex
            autoComplete
            themes={['white', 'withMargin']}
          />
          <Hr theme="modalSmall" />
          <Title text="Country" theme="modal" />
          {memoizedCountryTypeahead}

          <Hr theme="modalSmall" />
          <Title text={pt.employmentDate} theme="modal" />
          <div className={styles.employmentDateUp}>
            <Dropdown
              theme="white"
              label={dt.startMonth}
              name="startMonth"
              items={months}
              {...DropdownProps(formik, 'startMonth')}
            />
            <Dropdown
              theme="white"
              label={dt.startYear}
              name="startYear"
              items={years}
              {...DropdownProps(formik, 'startYear')}
            />
          </div>
          <div className={styles.employmentDateDown}>
            <Dropdown
              theme="white"
              label={dt.endMonth}
              name="endMonth"
              items={months}
              disabled={formik.values.currentEmployer}
              {...DropdownProps(formik, 'endMonth')}
            />
            <Dropdown
              theme="white"
              name="endYear"
              label={dt.endYear}
              items={years}
              disabled={formik.values.currentEmployer}
              {...DropdownProps(formik, 'endYear')}
            />
          </div>
          <Hr theme="modalSmall" />
          <Title text={pt.jobDescription} theme="modal" />
          <TextArea
            theme="modal"
            name="workDescriptionAndAchievements"
            placeholderText={pt.placeholderJobDescription}
            {...OnChangeProps(formik, 'workDescriptionAndAchievements')}
            maximumCharactersNumber={1000}
            type="letterCalculator"
            error={
              formik.touched.workDescriptionAndAchievements ||
              formik.values.workDescriptionAndAchievements !== ''
                ? formik.errors.workDescriptionAndAchievements
                : undefined
            }
          />
        </div>
      </Modal>
      <DeleteModal
        deleteAction={deleteWorkExperience}
        isOpen={!!deleteModal || deleteModal === 0}
        setOpen={setDeleteModal}
        text={`${formatMessage({id: pt.deletePartOne})}${
          localWorkExperiences?.find((_, i) => i === deleteModal)?.occupation
            ?.translation || ''
        }${formatMessage({id: pt.deletePartTwo})}`}
        id={localWorkExperiences?.find((_, i) => i === deleteModal)?.id || ''}
      />
    </div>
  )
}
