import {FC, useCallback, useEffect, useMemo} from 'react'
import {useFormik} from 'formik'
import {FormattedMessage, useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import {Warning, MailOutline} from '@mui/icons-material'
import classnames from 'classnames'

import Modal, {SubmitFooter} from 'Components/Modal'
import Title from 'Components/Title'
import styles from '../SavedSearchModal.module.scss'
import {CandidateSearchTranslations as cst} from 'Services/I18n/Constants'
import TextField from 'Components/Inputs/TextField'
import Validation from '../Validation'
import {
  BaseTaxonomyType,
  DataType,
  SaveSearchModalFormikType,
  SavedSearchType,
  TaxonomiesStateType,
  SavedSearchDataType,
} from 'Interfaces'
import Checkbox from 'Components/Inputs/Checkbox'
import Hr from 'Components/Hr'
import Dropdown from 'Components/Inputs/Dropdown'
import {frequencyOptions} from '../frequency'
import {urlToTaxonomies} from 'Utils/Search'
import {packSavedSearch} from 'Store/Packing'
import {useApiError} from 'Hooks'
import RadioGroup from 'Components/Inputs/RadioGroup'

interface SaveSearchModalProps {
  isOpen: boolean
  setOpen: (value: boolean) => void
  saveCandidateSearch: (value: SavedSearchType) => void
  updateSavedSearch: (value: SavedSearchType) => void
  taxonomies: TaxonomiesStateType
  searchKeywords: DataType<BaseTaxonomyType>
  savedSearch: SavedSearchDataType
}

export const SaveSearchModal: FC<SaveSearchModalProps> = ({
  isOpen,
  setOpen,
  saveCandidateSearch,
  taxonomies,
  searchKeywords,
  savedSearch,
  updateSavedSearch,
}) => {
  const formikDefaults: SaveSearchModalFormikType = {
    name: '',
    isFavorite: false,
    frequency: undefined,
    newOrUpdate: 'new',
    savedSearchId: undefined,
  }

  const location = useLocation()
  const {formatMessage} = useIntl()

  const formik = useFormik({
    initialValues: formikDefaults,
    validationSchema: Validation,
    onSubmit: (values) => {
      const searchValues = urlToTaxonomies(
        taxonomies,
        searchKeywords,
        location.search
      )
      const {newOrUpdate, savedSearchId, name, ...newValues} = values
      if (values.newOrUpdate === 'new') {
        saveCandidateSearch({
          ...newValues,
          ...packSavedSearch(searchValues),
          name,
          frequency: values.frequency || 'daily',
        })
      } else {
        const {name: oldName} = savedSearch.data.find(
          (s) => s.id === savedSearchId
        ) as SavedSearchType
        updateSavedSearch({
          id: savedSearchId,
          name: oldName,
          ...newValues,
          ...packSavedSearch(searchValues, true),
          frequency: values.frequency || 'daily',
        })
      }
    },
  })

  const savedSearchOptions = useMemo<BaseTaxonomyType[]>(
    () =>
      savedSearch.data.map((s) => ({id: s.id as string, translation: s.name})),
    [savedSearch.data]
  )

  const handleChooseSavedSearch = useCallback(
    (id: string) => {
      const saved = savedSearch.data.find((s) => s.id === id)
      formik.resetForm({
        values: {
          ...formik.values,
          savedSearchId: id,
          frequency: saved?.frequency,
          isFavorite: !!saved?.isFavorite,
        },
        errors: {},
      })
    },
    [savedSearch.data, formik.values]
  )

  const canAddToFavorite = useMemo(
    () =>
      savedSearch.data.filter((s) =>
        formik.values.newOrUpdate === 'new'
          ? s.isFavorite
          : s.isFavorite && s.id !== formik.values.savedSearchId
      ).length < 5,
    [savedSearch.data, formik.values.newOrUpdate, formik.values.savedSearchId]
  )

  useEffect(() => {
    if (!savedSearch.loading && !savedSearch.error) {
      setOpen(false)
    }
  }, [savedSearch.loading, savedSearch.error])

  useApiError(formik.setFieldError, savedSearch.error, 'name')

  return (
    <Modal
      isOpen={isOpen}
      onAfterClose={() => {
        formik.resetForm({values: formikDefaults})
        setOpen(false)
      }}
      title={cst.savedSearchModalTitle}
      icon={MailOutline}
      className={styles.modal}
      theme="desktopFlex"
      Footer={
        <SubmitFooter
          onSubmit={formik.handleSubmit}
          onCancel={() => {
            setOpen(false)
          }}
          theme="modalProfileSubmit"
          submitText={
            formik.values.newOrUpdate === 'new'
              ? cst.savedSearchSaveSearch
              : cst.savedSearchUpdateSearch
          }
        />
      }
    >
      <Title text={cst.savedSearchSaveUpdateTitle} theme="modal" />
      <RadioGroup
        name="newOrUpdate"
        value={formik.values.newOrUpdate}
        items={[
          {
            id: 'new',
            translation: formatMessage({id: cst.savedSearchNewSavedSearch}),
          },
          {
            id: 'update',
            translation: formatMessage({id: cst.savedSearchUpdateSavedSearch}),
          },
        ]}
        setValue={(name, value) => {
          formik.setFieldValue(name, value)
        }}
      />
      {formik.values.newOrUpdate !== 'new' ? (
        <div className={styles.warning}>
          <Warning />
          <span>
            <FormattedMessage id={cst.savedSearchOverwriteWarning} />
          </span>
        </div>
      ) : null}
      <Hr theme="modalSmall" />
      {formik.values.newOrUpdate === 'new' ? (
        <>
          <Title text={cst.savedSearchTitle} theme="modal" />
          <TextField
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            placeholder={cst.savedSearchInputPlaceholder}
            error={formik.submitCount ? formik.errors?.name : undefined}
            className={styles.name}
            theme="modal"
          />
        </>
      ) : (
        <>
          <Title text={cst.savedSearchExistingTitle} theme="modal" />
          <Dropdown
            emptyValueLabel={cst.savedSearchExistingTitle}
            name="savedSearchId"
            value={formik.values.savedSearchId}
            items={savedSearchOptions}
            className={styles.radiusInput}
            setValue={(_, value) => {
              handleChooseSavedSearch(value as string)
            }}
            theme="white"
            error={
              formik.submitCount > 0 ? formik.errors?.savedSearchId : undefined
            }
          />
        </>
      )}
      <Checkbox
        name="isFavorite"
        onChange={formik.handleChange}
        value={formik.values.isFavorite}
        disabled={!canAddToFavorite}
        className={classnames(styles.checkbox, {
          [styles.disabledCheckbox]: !canAddToFavorite,
        })}
      >
        <FormattedMessage id={cst.savedSearchAddToFavorites} />
      </Checkbox>
      <Hr theme="modal" />
      <Title text={cst.savedSearchFrequencyTitle} theme="modal" />
      <div className={styles.description}>
        <FormattedMessage id={cst.savedSearchFrequencyDescription} />
      </div>
      <Dropdown
        emptyValueLabel={cst.savedSearchSelectFrequency}
        name="frequency"
        value={formik.values.frequency ? formik.values.frequency : undefined}
        items={frequencyOptions}
        className={styles.radiusInput}
        setValue={formik.setFieldValue}
        theme="white"
        listUp
        error={formik.errors?.frequency}
      />
    </Modal>
  )
}
