import React, {useEffect} from 'react'
import {Switch, RouteProps, useLocation} from 'react-router'
import {useIntl} from 'react-intl'
import {initAPIHeaders} from 'Services/API/API'

/**
 *
 * @param path can be string, undefined or string array
 * @returns Localized string path or path array
 */
export const LocalizeRoutePath = (
  path?: string | readonly string[]
): string | string[] => {
  /**
   * inject params and formatMessage through hooks, so we can localize the route
   */
  const {formatMessage, locale} = useIntl()
  let location = useLocation()

  const isFallbackRoute = path === '*'
  let lang = `/${locale}`
  if (!location.pathname.includes(`/${locale}/`)) {
    lang = ''
  }
  switch (typeof path) {
    case 'undefined':
      return '#'
    case 'object':
      return path.map((key) => `${lang}` + formatMessage({id: key}))
    default:
      return isFallbackRoute ? path : `${lang}` + formatMessage({id: path})
  }
}

export const LocalizedSwitch: React.FC = ({children}) => {
  const {locale} = useIntl()
  useEffect(() => {
    initAPIHeaders()
  }, [locale])

  /**
   * Apply localization to all routes
   * Also checks if all children elements are <Route /> components
   */
  return (
    <Switch>
      {React.Children.map(children, (child) =>
        React.isValidElement<RouteProps>(child)
          ? React.cloneElement(child, {
              ...child.props,
              path: LocalizeRoutePath(child.props.path),
            })
          : child
      )}
    </Switch>
  )
}
