import {useEffect, useState, FC} from 'react'
import {useMediaQuery} from 'react-responsive'
import {useParams} from 'react-router-dom'

import SkillTestsContainer from 'Components/Assessments/SkillTests'
import MainHeader from 'Components/MainHeader'
import MainLayout from 'Layouts/MainLayout'
import FooterAssessments from 'Components/Footer'
import PersonalityTestsContainer from 'Components/Assessments/PersonalityTests'
import {MobileFooter} from 'Components/MobileFooter'
import LanguageTestsContainer from 'Components/Assessments/LanguageTests'
import {
  AuthStateType,
  EmployerCandidateProfileStateType,
  MeType,
  Test,
  TrainingTitleType,
} from 'Interfaces'
import {AppRoute} from 'Services/I18n/Constants'
import {getRoute} from 'Services/I18n/Utils'
import {SidebarLink} from 'Components/SidebarLink'
import {useTranslation} from 'Hooks'
import {
  GeneralAssessmentsTranslations as gat,
  SidebarTranslations as st,
} from 'Services/I18n/Constants'
import {DownloadAndPrintHeader} from 'Components/DownloadAndPrintHeader'

import styles from './Assessments.module.scss'
interface AssessmentsProps {
  assessments: any
  getAssessments: () => void
  user: MeType
  getLanguageTests: (seekerUserId: string) => void
  getSkillTests: (seekerUserId: string) => void
  languageTests: TrainingTitleType[]
  skillTests: TrainingTitleType[]
  auth: AuthStateType
  getSeekerAssessments: (seekerId: string) => void
  candidate: EmployerCandidateProfileStateType
  getCandidateProfile: (id: string) => void
}

export const Assessments: FC<AssessmentsProps> = ({
  assessments,
  getAssessments,
  getLanguageTests,
  user,
  languageTests,
  getSkillTests,
  skillTests,
  auth,
  getSeekerAssessments,
  candidate,
  getCandidateProfile,
}) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 900px)',
  })
  let {id} = useParams<{id: string}>()
  useEffect(() => {
    id && getCandidateProfile(id)
  }, [getCandidateProfile, id])
  const companyUserRole =
    auth?.data?.role === 'employer' ||
    auth?.data?.role === 'company_owner' ||
    auth?.data?.role === 'recruiter'

  // Show modals for adding language and skill tests only if user is recruter

  const isRecruiter = auth?.data?.role === 'recruiter'

  const translation = useTranslation()

  const [personalityTests, setPersonalityTests] = useState([])
  let profile = candidate?.data?.profile

  let title = !companyUserRole
    ? translation[gat.title]
    : profile?.firstName && profile?.lastName
    ? `${profile?.firstName} ${profile?.lastName} - ${
        translation[gat.assessments]
      }`
    : translation[gat.assessments]

  const profileUrl =
    auth.data &&
    getRoute(AppRoute.EmployerCandidateProfilePublic).replace(':id', id)

  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: getRoute(AppRoute.HomePageCandidate)},
    {name: translation[gat.title]},
  ]

  const publicAssessmentsBreadcrumbsArray = profile
    ? [
        {name: 'Preferenca.si', link: getRoute(AppRoute.HomePageCandidate)},
        {
          name: `${profile?.firstName} ${profile?.lastName}`,
          link: profileUrl,
        },
        {name: translation[gat.assessments]},
      ]
    : []

  const links = [
    {
      name: translation[st.editPublicProfile],
      route: AppRoute.PublicProfile,
    },
    {
      name: translation[st.editCv],
      route: `${profileUrl}`,
    },
  ]

  const Footer: FC = () => {
    return (
      <>{isMobile ? <MobileFooter links={links} /> : <FooterAssessments />}</>
    )
  }

  useEffect(() => {
    if (!companyUserRole) {
      getAssessments()
      getLanguageTests(user.id)
      getSkillTests(user.id)
    }
  }, [])

  useEffect(() => {
    if (companyUserRole) {
      getSeekerAssessments(id)
      getLanguageTests(id)
      getSkillTests(id)
    }
  }, [getSeekerAssessments, id])

  const assessmentsItems = (
    assessments: Test[],
    setAssessments: Function,
    categoryTranslation: string
  ) => {
    return (
      assessments.length > 0 &&
      setAssessments(
        assessments.filter(
          (assessment: any) =>
            assessment.category.translation === categoryTranslation
        )
      )
    )
  }

  useEffect(() => {
    assessmentsItems(
      assessments.data.items,
      setPersonalityTests,
      'Personality tests'
    )
  }, [assessments])

  return (
    <MainLayout
      withoutLogo
      content={
        !companyUserRole ? (
          <div className={styles.linksWrap}>
            <SidebarLink
              text={st.editPublicProfile}
              routeName={AppRoute.PublicProfile}
            />
            <SidebarLink text={st.editCv} routeName={AppRoute.Profile} />
          </div>
        ) : (
          <SidebarLink text={st.viewCandidateProfile} route={profileUrl} />
        )
      }
      Footer={<Footer />}
    >
      <div className={styles.mainAssessmentsContainer}>
        {!companyUserRole && (
          <DownloadAndPrintHeader
            downloadLink={
              process.env.PUBLIC_URL + '/files/moja_testiranja_04.07.2022.pdf'
            }
          />
        )}
        <div className={styles.container}>
          <MainHeader
            breadcrumbsArray={
              !companyUserRole
                ? breadcrumbsArray
                : publicAssessmentsBreadcrumbsArray
            }
            title={title}
            className={styles.assessmentsHeader}
          />

          {personalityTests.length > 0 && (
            <PersonalityTestsContainer
              personalityTests={personalityTests}
              typeOfTests={translation[gat.personality]}
            />
          )}
          <LanguageTestsContainer
            languageTests={languageTests}
            typeOfTests={translation[gat.language]}
            isRecruiter={isRecruiter}
            seekerUserId={id}
          />
          <SkillTestsContainer
            skillTests={skillTests}
            isRecruiter={isRecruiter}
            seekerUserId={id}
          />
        </div>
      </div>
    </MainLayout>
  )
}
