import styles from './HomeCallToActions.module.scss'
import {FC} from 'react'
import YellowButton from 'Components/YellowButton'
import YellowText from 'Components/YellowText'
import {AppRoute, HomeTranslations as home} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import YellowLink from 'Components/YellowLink'
import {getRoute} from 'Services/I18n/Utils'

export interface HomeCallToActionsType {
  text?: string
}
export const HomeCallToActions: FC<HomeCallToActionsType> = () => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.wrap}>
        <div className={styles.item}>
          <h2 className={styles.title}>
            <FormattedMessage id={home.ctaTitle1_1} />
            <YellowText text={home.ctaTitle1_2} />
          </h2>
          <p className={styles.textContainer}>
            <FormattedMessage id={home.ctaParagraph1} />
          </p>

          <div className={styles.buttonContainer}>
            <a
              href="https://placa.si/"
              target={'_blank'}
              className={styles.link}
            >
              <div className={styles.button}>
                <YellowButton
                  className={styles.yellowButton}
                  text={home.cta1}
                />
              </div>
            </a>
          </div>
        </div>
        <div className={styles.item}>
          <h2 className={styles.title}>
            <FormattedMessage id={home.ctaTitle2_1} />
            <YellowText text={home.ctaTitle2_2} />
          </h2>
          <p className={styles.textContainerSecond}>
            <FormattedMessage id={home.ctaParagraph} />
          </p>

          <div className={styles.buttonContainer}>
            <div className={styles.link}>
              <YellowLink
                to={getRoute(AppRoute.CandidateFAQ)}
                text={home.cta2}
                className={styles.yellowButton}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
