import * as Yup from 'yup'

const Validation = Yup.object({
  type: Yup.string().required('Attachment type is required'),
  name: Yup.string().required('Attachment name is required'),
  file: Yup.object().test({
    name: 'file',
    message: 'Attachment is required',
    test: (value): boolean => {
      if (value.url) return true
      return false
    },
  }),

  // level: Yup.string().required('Required'),
  // institution: Yup.string().required('Required'),
  // city: Yup.string().required('Required'),
  // country: Yup.string().required('Required'),
  // yearStart: Yup.number().required('Required'),
})

export default Validation
