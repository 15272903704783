import {Link} from 'react-router-dom'
import {FC} from 'react'
import {AppRoute} from 'Services/I18n/Constants'
import {getRoute} from 'Services/I18n/Utils'

import styles from './MessagesSectionMobileFooter.module.scss'

export const MessagesSectionMobileFooter: FC = ({}) => {
  const currentYear = new Date().getFullYear().toString()
  return (
    <div className={styles.messagesSectionMobileFooterContainer}>
      <Link to={getRoute(AppRoute.HomePageCandidate)}>
        @{currentYear}, Preferenca.si
      </Link>
    </div>
  )
}
