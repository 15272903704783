import {
  ActionType,
  TrainingTitleAddType,
  TrainingTitleEditType,
} from 'Interfaces'
import {takeLatest, all, put, call} from 'redux-saga/effects'
import API from 'Services/API'

import * as type from 'Store/types'

function* getSeekerAssessments(action: ActionType<string | undefined>) {
  const {response, error} = yield call(
    API.get,
    `/assessment/?userId=${action.payload}`
  )
  if (response) {
    yield put({
      type: type.assessments.getSeekerAssessments.succeeded,
      payload: {items: response.data},
    })
  } else {
    yield put({type: type.assessments.getSeekerAssessments.failed, error})
  }
}

function* fetchTests(action: ActionType<string>) {
  const {response, error} = yield call(
    API.post,
    '/assessment/all-assessments',
    action.payload
  )

  if (response) {
    yield put({
      type: type.assessments.getAssessments.succeeded,
      payload: response.data,
    })
  } else {
    yield put({type: type.assessments.getAssessments.failed, error})
  }
}

function* getCognitiveTestResults(action: ActionType<string>) {
  const {response, error} = yield call(
    API.get,
    `/assessment/user/cognitive-results?provider=hr potentials${action.payload}`
  )

  if (response) {
    yield put({
      type: type.assessments.getCognitiveTestResults.succeeded,
      payload: response.data,
    })
  } else {
    yield put({type: type.assessments.getCognitiveTestResults.failed, error})
  }
}
function* getPsychometricTestResults(action: ActionType<string>) {
  const {response, error} = yield call(
    API.get,
    `/assessment/${action.payload}/psychometric-result`
  )

  if (response) {
    yield put({
      type: type.assessments.getPsychometricTestResults.succeeded,
      payload: response.data,
    })
  } else {
    yield put({type: type.assessments.getPsychometricTestResults.failed, error})
  }
}

function* getPsychometricResultsStatements(action: ActionType<string>) {
  const {response, error} = yield call(
    API.get,
    `/psychometric-results-statements?seekerUserId=${action.payload}`
  )

  if (response) {
    yield put({
      type: type.assessments.getPsychometricResultsStatements.succeeded,
      payload: response.data.items,
    })
  } else {
    yield put({
      type: type.assessments.getPsychometricResultsStatements.failed,
      error,
    })
  }
}

function* startAssessment(action: ActionType<string>) {
  const {response, error} = yield call(
    API.post,
    `/assessment/start-assessment/${action.payload}`,
    action.payload
  )

  // hr potentials tests - if url exists
  if (response) {
    if (response.data.url) {
      yield put({
        type: type.assessments.startAssessment.succeeded,
        payload: {url: response.data.url, testId: action.payload},
      })
    }
  } else {
    yield put({
      type: type.assessments.startAssessment.failed,
      payload: error,
    })
  }
}

function* getLanguageTests(action: ActionType<string>) {
  const {response, error} = yield call(
    API.get,
    `/seeker-training-titles?testGroup=Language%20test&seekerUserId=${action.payload}`
  )
  if (response) {
    yield put({
      type: type.assessments.getLanguageTests.succeeded,
      payload: response.data.items,
    })
  } else {
    yield put({type: type.assessments.getLanguageTests.failed, error})
  }
}

function* getSkillTests(action: ActionType<string | undefined>) {
  const {response, error} = yield call(
    API.get,
    `/seeker-training-titles?testGroup=Skill%20test&seekerUserId=${action.payload}`
  )
  if (response) {
    yield put({
      type: type.assessments.getSkillTests.succeeded,
      payload: response.data.items,
    })
  } else {
    yield put({type: type.assessments.getSkillTests.failed, error})
  }
}

function* addLanguageTest(action: ActionType<TrainingTitleAddType>) {
  const {response, error} = yield call(
    API.post,
    '/seeker-training-titles?testGroup=Language%20test',
    action.payload
  )
  if (response) {
    yield put({
      type: type.assessments.addLanguageTest.succeeded,
      payload: response.data,
    })
  } else
    yield put({
      type: type.assessments.addLanguageTest.failed,
      payload: error,
    })
}

function* editLanguageTest(action: ActionType<TrainingTitleEditType>) {
  const {id, ...rest} = action.payload || {}
  const {response, error} = yield call(
    API.patch,
    `/seeker-training-titles/${id}?testGroup=Language%20test`,
    rest
  )
  if (response) {
    yield put({
      type: type.assessments.editLanguageTest.succeeded,
      payload: response.data,
    })
  } else
    yield put({
      type: type.assessments.editLanguageTest.failed,
      payload: error,
    })
}

function* deleteLanguageTest(action: ActionType<string>) {
  const {response, error} = yield call(
    API.delete,
    `/seeker-training-titles/${action.payload}`
  )

  if (response) {
    yield put({
      type: type.assessments.deleteLanguageTest.succeeded,
      payload: action.payload,
    })
  } else {
    yield put({
      type: type.assessments.deleteLanguageTest.failed,
      payload: error,
    })
  }
}

function* addSkillTest(action: ActionType<TrainingTitleAddType>) {
  const {response, error} = yield call(
    API.post,
    '/seeker-training-titles?testGroup=Skill%20test',
    action.payload
  )

  if (response) {
    yield put({
      type: type.assessments.addSkillTest.succeeded,
      payload: response.data,
    })
  } else yield put({type: type.assessments.addSkillTest.failed, payload: error})
}

function* editSkillTest(action: ActionType<TrainingTitleEditType>) {
  const {id, ...rest} = action.payload || {}
  const {response, error} = yield call(
    API.patch,
    `/seeker-training-titles/${id}?testGroup=Skill%20test`,
    rest
  )
  if (response) {
    yield put({
      type: type.assessments.editSkillTest.succeeded,
      payload: response.data,
    })
  } else {
    yield put({
      type: type.assessments.editSkillTest.failed,
      payload: error,
    })
  }
}

function* deleteSkillTest(action: ActionType<string>) {
  const {response, error} = yield call(
    API.delete,
    `/seeker-training-titles/${action.payload}`
  )
  if (response) {
    yield put({
      type: type.assessments.deleteSkillTest.succeeded,
      payload: action.payload,
    })
  } else {
    yield put({
      type: type.assessments.deleteSkillTest.failed,
      payload: error,
    })
  }
}

export default function* TestsSaga(): Generator {
  yield all([
    takeLatest(
      type.assessments.getSeekerAssessments.requested,
      getSeekerAssessments
    ),

    takeLatest(type.assessments.getAssessments.requested, fetchTests),
    takeLatest(
      type.assessments.getCognitiveTestResults.requested,
      getCognitiveTestResults
    ),
    takeLatest(type.assessments.startAssessment.requested, startAssessment),
    takeLatest(type.assessments.getLanguageTests.requested, getLanguageTests),
    takeLatest(type.assessments.getSkillTests.requested, getSkillTests),
    takeLatest(type.assessments.addLanguageTest.requested, addLanguageTest),
    takeLatest(type.assessments.editLanguageTest.requested, editLanguageTest),
    takeLatest(
      type.assessments.deleteLanguageTest.requested,
      deleteLanguageTest
    ),
    takeLatest(type.assessments.addSkillTest.requested, addSkillTest),
    takeLatest(type.assessments.editSkillTest.requested, editSkillTest),
    takeLatest(type.assessments.deleteSkillTest.requested, deleteSkillTest),
    takeLatest(
      type.assessments.getPsychometricTestResults.requested,
      getPsychometricTestResults
    ),
    takeLatest(
      type.assessments.getPsychometricResultsStatements.requested,
      getPsychometricResultsStatements
    ),
  ])
}
