import * as type from '../types'
import {
  ActionType,
  TrainingTitleAddType,
  TrainingTitleEditType,
} from 'Interfaces'

export const getSeekerAssessments = (seekerId: string): ActionType<string> => {
  return {
    type: type.assessments.getSeekerAssessments.requested,
    payload: seekerId,
  }
}

export const getAssessments = (): ActionType<unknown> => {
  return {
    type: type.assessments.getAssessments.requested,
  }
}

export const startAssessment = (
  seekerAssessmentId: string
): ActionType<string> => {
  return {
    type: type.assessments.startAssessment.requested,
    payload: seekerAssessmentId,
  }
}

export const getCognitiveTestResults = (
  providerName: string
): ActionType<string> => {
  return {
    type: type.assessments.getCognitiveTestResults.requested,
    payload: providerName,
  }
}

export const getLanguageTests = (
  id: string | undefined
): ActionType<string> => {
  return {
    type: type.assessments.getLanguageTests.requested,
    payload: id,
  }
}

export const getSkillTests = (id: string | undefined): ActionType<string> => {
  return {
    type: type.assessments.getSkillTests.requested,
    payload: id,
  }
}

export const addLanguageTest = (
  value: TrainingTitleAddType
): ActionType<TrainingTitleAddType> => {
  return {
    type: type.assessments.addLanguageTest.requested,
    payload: value,
  }
}

export const editLanguageTest = (
  value: TrainingTitleEditType
): ActionType<TrainingTitleEditType> => {
  return {
    type: type.assessments.editLanguageTest.requested,
    payload: value,
  }
}

export const deleteLanguageTest = (id: string): ActionType<string> => {
  return {
    type: type.assessments.deleteLanguageTest.requested,
    payload: id,
  }
}

export const addSkillTest = (
  value: TrainingTitleAddType
): ActionType<TrainingTitleAddType> => {
  return {
    type: type.assessments.addSkillTest.requested,
    payload: value,
  }
}

export const editSkillTest = (
  value: TrainingTitleEditType
): ActionType<TrainingTitleEditType> => {
  return {
    type: type.assessments.editSkillTest.requested,
    payload: value,
  }
}

export const deleteSkillTest = (id: string): ActionType<string> => {
  return {
    type: type.assessments.deleteSkillTest.requested,
    payload: id,
  }
}

export const getPsychometricTestResults = (id: string): ActionType<string> => {
  return {
    type: type.assessments.getPsychometricTestResults.requested,
    payload: id,
  }
}

export const getPsychometricResultsStatements = (
  id: string
): ActionType<string> => {
  return {
    type: type.assessments.getPsychometricResultsStatements.requested,
    payload: id,
  }
}

export const changeCognitiveTestStatus = (
  testId: string
): ActionType<string> => {
  console.log(testId)
  return {
    type: type.assessments.changeCognitiveTestStatus.requested,
    payload: testId,
  }
}
