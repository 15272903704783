export enum ChangePasswordTranslations {
  title = 'changepasswordsettings.title',
  titleChangePassword = 'changepasswordsettings.titleChangePassword',
  oldPassword = 'changepasswordsettings.oldPassword',
  newPassword = 'changepasswordsettings.newPassword',
  repeatNewPassword = 'changepasswordsettings.repeatNewPassword',
  cancel = 'changepasswordsettings.cancel',
  saveChanges = 'changepasswordsettings.saveChanges',
  successMessage = 'changepasswordsettings.successMessage',
}
