import {FC, useState} from 'react'
import classNames from 'classnames'
import {useParams} from 'react-router-dom'
import {Star, StarBorderOutlined} from '@mui/icons-material'
import {SeekerEvaluationType} from 'Interfaces'
import Rating from '@mui/material/Rating'
import styles from './RateCandidate.module.scss'
import {SidebarTranslations as st} from 'Services/I18n/Constants'
import {useTranslation} from 'Hooks'

interface RateCandidateProps {
  addSeekerEvaluation: (seekerEvaluation: SeekerEvaluationType) => void
  editSeekerEvaluation: (seekerEvaluation: SeekerEvaluationType) => void
  deleteSeekerEvaluation: (seekerEvaluation: SeekerEvaluationType) => void
  seekerEvaluation?: SeekerEvaluationType | null
  placement: string
  seekerId?: string
  readOnly?: boolean
}

export const RateCandidate: FC<RateCandidateProps> = ({
  seekerEvaluation,
  placement,
  seekerId,
  addSeekerEvaluation,
  editSeekerEvaluation,
  deleteSeekerEvaluation,
  readOnly = false,
}) => {
  let {id} = useParams<{id: string}>()
  const [_, setHover] = useState(-1)
  const translation = useTranslation()

  return (
    <div className={classNames(styles.rateCandidate, styles[placement])}>
      {placement === 'widget' && <p>{translation[st.rate]}</p>}
      <div className={styles.starsContainer}>
        <Rating
          readOnly={readOnly}
          name="hover-feedback"
          value={seekerEvaluation ? seekerEvaluation?.evaluation : null}
          onChange={(_, newValue: number | null | undefined) => {
            if (seekerEvaluation === null && newValue) {
              addSeekerEvaluation({
                seekerId: seekerId,
                projectId: id as string,
                evaluation: newValue,
              })
            } else if (seekerEvaluation !== null && newValue && newValue > 0) {
              editSeekerEvaluation({
                seekerId: seekerId,
                projectId: id as string,
                evaluation: newValue,
              })
            } else if (seekerEvaluation && seekerEvaluation.evaluation === 1) {
              deleteSeekerEvaluation({
                seekerId: seekerId,
                projectId: id as string,
              })
            }
          }}
          onChangeActive={(_, newHover) => {
            setHover(newHover)
          }}
          emptyIcon={
            placement === 'card' ? (
              <StarBorderOutlined className={styles.starBorderOutlined} />
            ) : (
              <Star />
            )
          }
        />
      </div>
    </div>
  )
}
