// Shortens sentence string to given character count(default 50) & adds optional suffix.
import h2p from './htmlToPlainText'

export const shortenSentenceText = (
  messageText: string,
  suffix?: string,
  charCountLimit = 50
) => {
  let characterCount = 0
  let shortenText = messageText
    .split(' ')
    .filter((word) => {
      characterCount = characterCount + word.length
      if (characterCount > charCountLimit) return false
      return true
    })
    .join(' ')

  shortenText =
    messageText.length > shortenText.length ? shortenText + suffix : shortenText

  return shortenText
}

export const countHtmlChars = (value: string = ''): number => {
  return h2p(value).length
}
