import styles from './ThankYou.module.scss'
import MainLayout from 'Layouts/MainLayout'
import {ThankYouTranslations as ty} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import YellowLink from 'Components/YellowLink'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import YellowText from 'Components/YellowText'
import {Link} from 'react-router-dom'

export const ThankYou: React.ComponentType = () => {
  return (
    <MainLayout theme="employer" withoutLogo={true}>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <Link
            to={getRoute(AppRoute.HomePageEmployer)}
            className={styles.linkStyle}
          >
            <YellowText
              addCutPaintWidth={-25}
              className={styles.logoStyle}
              text="PREFERENCA"
            />
          </Link>
        </div>
        <div className={styles.containerUp}>
          <h1 className={styles.containerUpTitle}>
            <FormattedMessage id={ty.title} />
          </h1>
          <p className={styles.containerUpText}>
            <FormattedMessage id={ty.message} />
          </p>
        </div>
        <div className={styles.containerMiddle}>
          <p className={styles.text}>
            <FormattedMessage id={ty.description} />
          </p>
        </div>
        <div className={styles.containerBtn}>
          <YellowLink
            to={getRoute(AppRoute.CompanyProfile)}
            text={ty.button}
            className={styles.btn}
          />
        </div>
      </div>
    </MainLayout>
  )
}
