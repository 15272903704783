import classNames from 'classnames'
import {FC} from 'react'
import styles from './Avatar.module.scss'
import {ImageType} from 'Interfaces'

interface iAvatarProps {
  image?: ImageType
  firstName: string
  lastName: string
  className?: string
}

export const Avatar: FC<iAvatarProps> = ({
  image,
  firstName,
  lastName,
  className,
}: iAvatarProps) => {
  return (
    <div className={classNames(styles.avatar, className)}>
      {image ? (
        <img
          className={styles.image}
          src={image.url}
          alt={`${firstName} ${lastName}`}
        />
      ) : (
        <span>
          {firstName ? firstName.charAt(0) : ''}
          {lastName ? lastName.charAt(0) : ''}
        </span>
      )}
    </div>
  )
}
