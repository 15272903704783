import {FC, useEffect, useState} from 'react'
import styles from './PublicPagesHeader.module.scss'
import {Link, useHistory} from 'react-router-dom'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {HomeTranslations as home} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import YellowText from 'Components/YellowText'
import classNames from 'classnames'
import MenuIcon from '@mui/icons-material/Menu'
import CloseIcon from '@mui/icons-material/Close'

export interface PublicPagesHeaderType {
  text?: string
  page: string
}

export const PublicPagesHeader: FC<PublicPagesHeaderType> = ({
  page,
}: PublicPagesHeaderType) => {
  const [isMobileOpened, setIsMobileOpened] = useState(true)
  const history = useHistory()

  const isAboutUsPage = history.location.pathname === getRoute(AppRoute.AboutUs)

  useEffect(() => {
    setIsMobileOpened(false)
  }, [history.location])

  return (
    <>
      <div
        className={classNames(styles.headerPlaceholder, {
          [styles.mobileOpened]: isMobileOpened,
        })}
      ></div>
      <div
        className={classNames(styles.header, {
          [styles.mobileOpened]: isMobileOpened,
        })}
      >
        <div
          className={classNames(styles.logoContainer, {
            [styles.mobileOpened]: isMobileOpened,
          })}
        >
          <Link
            to={
              page === 'employer'
                ? getRoute(AppRoute.HomePageEmployer)
                : getRoute(AppRoute.HomePageCandidate)
            }
            className={styles.topLink}
          >
            <YellowText
              addCutPaintWidth={-25}
              className={styles.logoStyle}
              text={home.headerLogo}
            />
          </Link>

          <div
            onClick={() => setIsMobileOpened((old) => !old)}
            className={styles.hamburgerIconContainer}
          >
            <MenuIcon className={styles.hamburgerIcon} />
          </div>
        </div>
        <div
          onClick={() => setIsMobileOpened(false)}
          className={classNames(styles.mobileMenuClose, {
            [styles.mobileOpened]: isMobileOpened,
          })}
        >
          <CloseIcon />
        </div>
        <nav
          className={classNames(styles.optionContainer, {
            [styles.mobileOpened]: isMobileOpened,
          })}
        >
          <ul className={styles.linkContainer}>
            {isAboutUsPage ? (
              <>
                <li className={styles.optionStyle}>
                  <Link
                    to={getRoute(AppRoute.HomePageCandidate)}
                    className={styles.linkStyle}
                  >
                    <FormattedMessage id={home.headerLink1} />
                  </Link>
                </li>
                <li className={styles.optionStyle}>
                  <Link
                    to={getRoute(AppRoute.HomePageEmployer)}
                    className={styles.linkStyle}
                  >
                    <FormattedMessage id={home.headerLink2} />
                  </Link>
                </li>
              </>
            ) : (
              <>
                {page === 'employer' && (
                  <li className={styles.optionStyle}>
                    <Link
                      to={getRoute(AppRoute.HomePageCandidate)}
                      className={styles.linkStyle}
                    >
                      <FormattedMessage id={home.headerLink1} />
                    </Link>
                  </li>
                )}
                {page === 'candidate' && (
                  <li className={styles.optionStyle}>
                    <Link
                      to={getRoute(AppRoute.HomePageEmployer)}
                      className={styles.linkStyle}
                    >
                      <FormattedMessage id={home.headerLink2} />
                    </Link>
                  </li>
                )}
              </>
            )}
            {!isAboutUsPage && (
              <li className={styles.optionStyle}>
                <Link
                  to={{pathname: getRoute(AppRoute.AboutUs), state: page}}
                  className={styles.linkStyle}
                >
                  <FormattedMessage id={home.headerLink3} />
                </Link>
              </li>
            )}
            <li className={styles.optionStyle}>
              <Link
                to={
                  page === 'employer'
                    ? getRoute(AppRoute.EmployerLogin)
                    : getRoute(AppRoute.SeekerLogin)
                }
                className={styles.linkStyle}
              >
                <FormattedMessage id={home.headerLink4} />
              </Link>
            </li>
            {page === 'candidate' && !isAboutUsPage && (
              <li className={styles.optionStyle}>
                <Link
                  to={getRoute(AppRoute.Registration)}
                  className={styles.linkStyle}
                >
                  <FormattedMessage id={home.headerLink5} />
                </Link>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </>
  )
}
