import {FC, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {CheckCircle} from '@mui/icons-material'
import {FormattedMessage} from 'react-intl'

import {TextProps} from 'Utils/FormikProps'
import styles from './ResetPassword.module.scss'
import TextField from 'Components/Inputs/TextField'
import {Validation} from './Validation'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute, ResetPasswordTranslations} from 'Services/I18n/Constants'
import LoginLayout from 'Layouts/LoginLayout'
import {Title, Description} from 'Components/Login'
import YellowButton from 'Components/YellowButton'
import {useApiError, useTranslation} from 'Hooks'
import {AuthStateType, ResetPasswordType} from 'Interfaces'

interface ResetPasswordProps {
  auth: AuthStateType
  resetPassword: (data: ResetPasswordType) => void
  hideResetPasswordMessage: () => void
}

export const ResetPassword: FC<ResetPasswordProps> = ({
  auth,
  resetPassword,
  hideResetPasswordMessage,
}) => {
  const {token}: {token: string} = useParams()
  const emptyValues = {
    password: '',
    verifyPassword: '',
  }

  useEffect(() => {
    if (auth.displayMessage.resetPasswordSuccess) {
      formik.resetForm({
        values: emptyValues,
      })
      setTimeout(() => {
        hideResetPasswordMessage()
      }, 5000)
    }
  }, [auth.displayMessage.resetPasswordSuccess])

  const formik = useFormik({
    initialValues: emptyValues,
    validationSchema: Validation,
    onSubmit: (values) => {
      resetPassword({
        ...values,
        token: token,
      })
    },
  })

  const translation = useTranslation()

  useApiError(formik.setFieldError, auth.error, 'password')

  return (
    <LoginLayout>
      <div className={styles.formWrap}>
        <Title text={translation[ResetPasswordTranslations.title]} />
        <Description
          className={styles.description}
          text={translation[ResetPasswordTranslations.description]}
        />
        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <div className={styles.inputWrap}>
            <TextField
              name="password"
              type="password"
              label={translation[ResetPasswordTranslations.textLabelNewPass]}
              theme="minWidth"
              {...TextProps(formik, 'password')}
            />
            <TextField
              name="verifyPassword"
              type="password"
              label={
                translation[ResetPasswordTranslations.textLabelConfirmPass]
              }
              theme="minWidth"
              {...TextProps(formik, 'verifyPassword')}
            />
            {auth.displayMessage.resetPasswordSuccess && (
              <div className={styles.successMessage}>
                <CheckCircle />
                <FormattedMessage
                  id={ResetPasswordTranslations.successMessage}
                />
              </div>
            )}
            <YellowButton
              text={translation[ResetPasswordTranslations.buttonResetPassword]}
            />
            <Link to={getRoute(AppRoute.SeekerLogin)} className={styles.link}>
              {translation[ResetPasswordTranslations.backToLogIn]}
            </Link>
          </div>
        </form>
      </div>
    </LoginLayout>
  )
}
