export enum CompanyVideoTranslations {
  videoTitle = 'companyVideo.videoTitle',
  featuredVideoTitle = 'companyVideo.featuredVideoTitle',

  modalTitleEdit = 'companyVideo.modalTitleEdit',
  modalTitleAdd = 'companyVideo.modalTitleAdd',
  modalVideosTitle = 'companyVideo.modalVideosTitle',
  modalVideoPlaceholderText = 'companyVideo.modalVideoPlaceholderText',
  modalVideoTitleTitle = 'companyVideo.modalVideoTitleTitle',
  showVideo = 'companyVideo.showVideo',
}
