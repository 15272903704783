import {FC} from 'react'
import TextField from 'Components/Inputs/TextField'
import TextArea from 'Components/Inputs/TextArea'
import {Button} from 'Components/Button'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import Hr from 'Components/Hr'
import styles from './AddQuestionForm.module.scss'
import {FormikType, QuestionValue} from 'Interfaces'
import {FormikErrors} from 'formik'
import {CompanyFaqTranslations as cft} from 'Services/I18n/Constants'

interface AddQuestionFormProps {
  formik: FormikType<{companyQuestions: QuestionValue[]}>
  questionName: string
  answerName: string
  index: number
  remove: Function
}

export const AddQuestionForm: FC<AddQuestionFormProps> = ({
  formik,
  questionName,
  answerName,
  index = 0,
  remove,
}) => {
  const errors =
    formik.errors.companyQuestions &&
    (formik.errors.companyQuestions[index] as FormikErrors<QuestionValue>)
  const touched =
    formik.touched &&
    formik.touched?.companyQuestions &&
    formik.touched?.companyQuestions[index]

  const getQuestionError = () => {
    if (errors && errors.question) {
      return errors.question
    }
    return undefined
  }

  const getAnswerError = () => {
    if (errors && errors.answer) {
      return errors.answer
    }
    return undefined
  }
  return (
    <div className={styles.addQuestionFormWrapper}>
      <div className={styles.textFieldWrapper}>
        <TextField
          name={questionName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={
            formik.submitCount && touched && touched.question
              ? getQuestionError()
              : undefined
          }
          value={formik.values?.companyQuestions[index]?.question}
          className={styles.questionsTextField}
          placeholder={cft.enterQuestionPlaceholderText}
          horizontalLabel="Q:"
          theme="modal"
        />
      </div>

      <TextArea
        name={answerName}
        onChange={formik.handleChange}
        value={formik.values.companyQuestions[index]?.answer}
        type="letterCalculator"
        error={
          formik.submitCount && touched && touched.answer
            ? getAnswerError()
            : undefined
        }
        className={styles.questionsTextArea}
        maximumCharactersNumber={1000}
        placeholderText={cft.enterQuestionPlaceholderText}
        horizontalLabel="A:"
      />

      <div className={styles.deleteFormikItemButtonWrapper}>
        <Button
          onClick={() => {
            remove(index)
          }}
          text={cft.deleteText}
          className={styles.deleteFormikItemButton}
          icon={<DeleteOutlineOutlinedIcon />}
        />
      </div>

      <Hr className={styles.hr} />
    </div>
  )
}
