import {connect} from 'react-redux'

import {
  setSidebarClosed,
  setCandidateBasicData,
  setMyProjectCandidateReview,
  getPsychometricResultsStatements,
} from 'Store/Actions'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {CandidateCard} from './CandidateCard'
import {Candidate, SeekerReviewType} from 'Interfaces/CandidateType'
import {IncomingRequestSeekerType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  widgetBar: state.widgetBar.data,
  isRecruiter: state.auth.data?.role === 'recruiter',
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setCandidateBasicData: (
    candidate: Candidate | IncomingRequestSeekerType | null
  ) => dispatch(setCandidateBasicData(candidate)),
  setSidebarClosed: (sidebarClosed: boolean) =>
    dispatch(setSidebarClosed(sidebarClosed)),
  setMyProjectCandidateReview: (seekerReview: SeekerReviewType) =>
    dispatch(setMyProjectCandidateReview(seekerReview)),
  getPsychometricResultsStatements: (id: string) =>
    dispatch(getPsychometricResultsStatements(id)),
})

export const CandidateCardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateCard)
