import {useState, FC, useEffect, useMemo, useRef} from 'react'
import {useFormik} from 'formik'
import classnames from 'classnames'

import SectionHeader from 'Components/Profile/SectionHeader'
import SectionItem from 'Components/Profile/SectionItem'
import ItemHeader from 'Components/Profile/ItemHeader'
import Modal from 'Components/Modal'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import biziSi from 'Assets/Images/biziSi.png'

import Hr from 'Components/Hr'
import styles from './CompanyDescription.module.scss'
import Validation from './Validation'
import {CompanyStateType, CompanyDescriptionItem} from 'Interfaces'
import Title from 'Components/Title'
import {CompanyDescriptionTranslations as cdt} from 'Services/I18n/Constants'

import {useApiError, useTranslation, useWindowResize} from 'Hooks'
import ShowMoreContent from 'Components/ShowMoreContent'
import {FormattedMessage} from 'react-intl'
import {ErrorOutline} from '@mui/icons-material'
import {HTMLInput} from 'Components/Inputs/HTMLInput/HTMLInput'
import {HTMLText} from 'Components/HTMLText'

interface CompanyDescriptionProps {
  company: CompanyStateType
  saveCompanyDescription: (value: CompanyDescriptionItem) => void
  getCompanyDescriptionBiziSi: (value: string) => void
  canChange: boolean
}

export const CompanyDescription: FC<CompanyDescriptionProps> = ({
  company,
  saveCompanyDescription,
  getCompanyDescriptionBiziSi,
  canChange,
}) => {
  const [showLogo, setShowLogo] = useState(true)

  const FormikValues: CompanyDescriptionItem = {
    aboutCompany: company.data.aboutCompany || '',
    mainActivities: company.data.mainActivities || '',
    visionAndGoals: company.data.visionAndGoals || '',
    structureAndCulture: company.data.structureAndCulture || '',
    customCompanyDescription: company.data.customCompanyDescription || '',
  }
  let canAdd =
    (!company.data.aboutCompany || company.data.aboutCompany === '') &&
    (!company.data.mainActivities || company.data.mainActivities === '') &&
    (!company.data.visionAndGoals || company.data.visionAndGoals === '') &&
    (!company.data.structureAndCulture ||
      company.data.structureAndCulture === '') &&
    (!company.data.customCompanyDescription ||
      company.data.customCompanyDescription === '')

  const translation = useTranslation()

  const [modalOpen, setModalOpen] = useState(false)
  const [modalMode, setModalMode] = useState('Add')

  const openEdit = () => {
    setModalMode('Edit')

    formik.resetForm({
      values: FormikValues,
    })
    setModalOpen(true)
  }

  const openAdd = () => {
    setModalMode('Add')
    formik.resetForm({
      values: FormikValues,
    })
    setModalOpen(true)
  }

  const formik = useFormik({
    initialValues: FormikValues,
    validationSchema: Validation,
    onSubmit: (values) => {
      saveCompanyDescription(values)
    },
  })

  useApiError(formik.setFieldError, company.error)

  useEffect(() => {
    if (!company.loading && !company.error) {
      setModalOpen(false)
      if (company.data.id && !thereIsBiziSiData)
        getCompanyDescriptionBiziSi(company.data.id)
    }
  }, [company.loading, company.data.id])

  const [height, setHeight] = useState(
    document.body.clientWidth < 768 ? 273 : 141
  )

  const changeHeight = (clientWidth?: number) => {
    setHeight(clientWidth && clientWidth < 768 ? 273 : 141)
  }

  useWindowResize(changeHeight)

  const showAll =
    !company.data.aboutCompany &&
    !company.data.mainActivities &&
    !company.data.visionAndGoals &&
    !company.data.structureAndCulture &&
    !company.data.customCompanyDescription

  const descItems = [
    {
      prop: 'aboutCompany',
      title: cdt.basicDescriptionTitle,
      icon: ErrorOutline,
    },
    {
      prop: 'mainActivities',
      title: cdt.mainActivitiesTitle,
      icon: ErrorOutline,
    },
    {
      prop: 'visionAndGoals',
      title: cdt.visionAndGoalsTitle,
      icon: ErrorOutline,
    },
    {
      prop: 'structureAndCulture',
      title: cdt.structureAndCultureTitle,
      icon: ErrorOutline,
    },
    {
      prop: 'customCompanyDescription',
      title: cdt.customCompanyDescriptionTitle,
      icon: ErrorOutline,
    },
  ]

  const visibleInfoItems = useMemo(
    () =>
      descItems.filter(
        (item) => company.data[item.prop] && company.data[item.prop] !== ''
      ),
    [company.data]
  )
  const childRef = useRef(null)

  useEffect(() => {
    if (!company.loading && !company.error) {
      setModalOpen(false)
    }
  }, [company.loading])

  const thereIsBiziSiData =
    company.data.titleBiziSi ||
    company.data.addressBiziSi ||
    company.data.postalCodeBiziSi ||
    company.data.cityBiziSi ||
    company.data.industryBiziSi ||
    company.data.companySizeBiziSi

  return (
    <div className={styles.section}>
      {(company.data.aboutCompany || canChange) && (
        <SectionHeader
          onAdd={canAdd && canChange ? openAdd : undefined}
          onEdit={canChange && !canAdd ? openEdit : undefined}
          title={cdt.descriptionTitle}
          className={styles.header}
        />
      )}
      {visibleInfoItems.length || thereIsBiziSiData ? (
        <SectionItem className={styles.sectionItem}>
          <ShowMoreContent
            height={height}
            childRef={childRef}
            showAll={showAll || (canAdd && thereIsBiziSiData)}
            className={classnames(
              styles.showMoreContent,
              styles.showMoreContentStronger,
              {
                [styles.onlyBiziSi]:
                  thereIsBiziSiData && !visibleInfoItems.length,
              }
            )}
            HiddenComponent={
              company.data?.id && thereIsBiziSiData ? (
                <>
                  <Hr className={styles.hr} />
                  <div className={styles.biziSiContainer}>
                    <div className={styles.up}>
                      <div className={styles.left}>
                        {showLogo && (
                          <div className={styles.logo}>
                            <img
                              onError={() => setShowLogo(false)}
                              className={styles.logoCompany}
                              src={
                                company.data.logoUrlBiziSi
                                  ? company.data.logoUrlBiziSi
                                  : ''
                              }
                              alt={translation[cdt.companyLogoAlt]}
                            />
                          </div>
                        )}
                        <div className={styles.textLeft}>
                          <p className={styles.titleBizi}>
                            {company.data.titleBiziSi}
                          </p>
                          <p className={styles.titleBiziParagraph}>
                            {company.data.addressBiziSi}
                          </p>
                          <p className={styles.titleBiziParagraph}>
                            {company.data.postalCodeBiziSi}{' '}
                            {company.data.cityBiziSi}
                          </p>
                        </div>
                      </div>

                      <div className={styles.right}>
                        {company.data.industryBiziSi && (
                          <div className={styles.textRightFirst}>
                            <p className={styles.titleBiziLeft}>
                              <FormattedMessage id={cdt.biziSiIndustryTitle} />
                            </p>
                            <p className={styles.paragraphBiziLeft}>
                              {company.data.industryBiziSi}
                            </p>
                          </div>
                        )}
                        <div className={styles.textRight}>
                          {company.data.companySizeBiziSi && (
                            <>
                              <p className={styles.titleBiziLeft}>
                                <FormattedMessage
                                  id={cdt.biziSiNumberOfEmployersTitle}
                                />
                              </p>
                              <p className={styles.paragraphBiziLeft}>
                                {company.data.companySizeBiziSi}
                              </p>
                            </>
                          )}
                          {company.data.incomeBiziSi && (
                            <>
                              <p className={styles.titleBiziLeft}>
                                <FormattedMessage id={cdt.biziSiRevenues} />
                              </p>
                              <p className={styles.paragraphBiziLeft}>
                                {company.data.incomeBiziSi} €
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                    {company.data.companyLinkBiziSi && (
                      <div className={styles.down}>
                        <a
                          target="_blank"
                          href={`${company.data.companyLinkBiziSi}`}
                        >
                          <img
                            className={styles.logoBizi}
                            src={biziSi}
                            alt={translation[cdt.biziSiLogoAlt]}
                          />
                        </a>
                      </div>
                    )}
                  </div>
                </>
              ) : null
            }
          >
            <div className={styles.container}>
              {!canAdd &&
                visibleInfoItems.map((item, index) => (
                  <div
                    key={`${item.title}-${index}`}
                    ref={!index ? childRef : null}
                  >
                    {index !== 0 && <Hr className={styles.hr} />}
                    <ItemHeader
                      title={item.title}
                      icon={item.icon}
                      className={styles.itemHeader}
                    />
                    <div className={styles.text}>
                      <HTMLText htmlInputValue={company.data[item.prop]} />
                    </div>
                  </div>
                ))}
            </div>
          </ShowMoreContent>
        </SectionItem>
      ) : null}

      <Modal
        isOpen={modalOpen}
        onAfterClose={() => {
          setModalOpen(false)
        }}
        title={`${
          modalMode === 'Add'
            ? translation[cdt.modalAddText]
            : translation[cdt.modalEditText]
        } ${translation[cdt.modalTitle]}`}
        icon={ErrorOutline}
        ariaHideApp={false}
        className={styles.modal}
        theme="desktopFlex"
        Footer={
          <SubmitFooter
            onSubmit={formik.handleSubmit}
            onCancel={() => {
              setModalOpen(false)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <Title
          text={cdt.modalAboutTitle}
          className={classnames(styles.title, styles.titleStronger)}
          theme="modal"
        />
        <HTMLInput
          name="aboutCompany"
          value={formik.values.aboutCompany || ''}
          onChange={formik.setFieldValue}
          error={
            formik.errors.aboutCompany ? formik.errors.aboutCompany : undefined
          }
          setError={formik.setFieldError}
          charLimit={3000}
        />
        <Hr className={styles.hrModal} theme="modal" />
        <Title
          text={cdt.modalMainActivitiesTitle}
          className={classnames(styles.title, styles.titleStronger)}
          theme="modal"
        />
        <HTMLInput
          name="mainActivities"
          value={formik.values.mainActivities || ''}
          onChange={formik.setFieldValue}
          error={
            formik.errors.mainActivities
              ? formik.errors.mainActivities
              : undefined
          }
          setError={formik.setFieldError}
          charLimit={3000}
        />
        <Hr className={styles.hrModal} theme="modal" />
        <Title
          text={cdt.visionAndGoalsTitle}
          className={classnames(styles.title, styles.titleStronger)}
          theme="modal"
        />
        <HTMLInput
          name="visionAndGoals"
          value={formik.values.visionAndGoals || ''}
          onChange={formik.setFieldValue}
          error={
            formik.errors.visionAndGoals
              ? formik.errors.visionAndGoals
              : undefined
          }
          setError={formik.setFieldError}
          charLimit={3000}
        />
        <Hr className={styles.hrModal} theme="modal" />
        <Title
          text={cdt.modalStructureAndCultureTitle}
          className={classnames(styles.title, styles.titleStronger)}
          theme="modal"
        />
        <HTMLInput
          name="structureAndCulture"
          value={formik.values.structureAndCulture || ''}
          onChange={formik.setFieldValue}
          error={
            formik.errors.structureAndCulture
              ? formik.errors.structureAndCulture
              : undefined
          }
          setError={formik.setFieldError}
          charLimit={3000}
        />
        <Hr className={styles.hrModal} theme="modal" />
        <Title
          text={cdt.modalCustomCompanyDescriptionTitle}
          className={classnames(styles.title, styles.titleStronger)}
          theme="modal"
        />
        <HTMLInput
          name="customCompanyDescription"
          value={formik.values.customCompanyDescription || ''}
          onChange={formik.setFieldValue}
          error={
            formik.errors.customCompanyDescription
              ? formik.errors.customCompanyDescription
              : undefined
          }
          setError={formik.setFieldError}
          charLimit={3000}
        />
      </Modal>
    </div>
  )
}
