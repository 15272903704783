import {connect} from 'react-redux'
import {Comments} from './Comments'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {saveCandidateComment} from 'Store/Actions'
import {SaveCommentType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  candidateBasicData: state.widgetBar.data.candidateBasicData,
  sidebarClosed: state.widgetBar.data.sidebarClosed
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  saveCandidateComment: (candidateComment: SaveCommentType) =>
    dispatch(saveCandidateComment(candidateComment)),
})

export const CommentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Comments)
