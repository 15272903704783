import {FC, useEffect} from 'react'
import TextField from 'Components/Inputs/TextField'
import SearchIcon from '@mui/icons-material/Search'
import styles from './MessageCenterHeader.module.scss'
import Dropdown from 'Components/Inputs/Dropdown'
import {SetValueProps} from 'Utils/FormikProps'
import {useFormik} from 'formik'
import {Button} from 'Components/Button'
import {
  PARTICIPANTS_TYPE,
  ConversationsSearchParamsType,
  MESSAGE_TYPE,
} from 'Interfaces'
import _ from 'lodash'
import {useTranslation} from 'Hooks'
import {MessageCenterTranslations as mct} from 'Services/I18n/Constants/MessageCenter'
import {FormattedMessage} from 'react-intl'

type MessageCenterHeaderProps = {
  params: ConversationsSearchParamsType | undefined
  isTSA: boolean
  isEmployer: boolean
  specificParticipiantUnreadCount?: number
  searchConversations: (params?: ConversationsSearchParamsType) => void
  getUnreadConversationsCount: (participantsType?: string) => void
}

export const MessageCenterHeader: FC<MessageCenterHeaderProps> = ({
  params,
  isTSA,
  isEmployer,
  specificParticipiantUnreadCount,
  searchConversations,
  getUnreadConversationsCount,
}) => {
  const translation = useTranslation()

  const participantsType = isTSA
    ? PARTICIPANTS_TYPE.TSA
    : isEmployer
    ? PARTICIPANTS_TYPE.SEEKER
    : PARTICIPANTS_TYPE.COMPANY_USER

  const requestStatuses = [
    {
      id: MESSAGE_TYPE.ALL_MESSAGES,
      translation: translation[mct.inbox],
    },
    {
      id: MESSAGE_TYPE.ARCHIVED,
      translation: translation[mct[MESSAGE_TYPE.ARCHIVED]],
    },
    {
      id: MESSAGE_TYPE.UNREAD,
      translation: translation[mct[MESSAGE_TYPE.UNREAD]],
    },
  ]

  const defaultValues = {
    searchText: '',
    searchConversationStatus: MESSAGE_TYPE.ALL_MESSAGES,
  }

  const formik = useFormik({
    initialValues: defaultValues,
    onSubmit: (values) => {
      const searchParams: ConversationsSearchParamsType = {
        searchText: values.searchText,
        participantsType,
      }
      if (
        formik.values.searchConversationStatus !== MESSAGE_TYPE.ALL_MESSAGES
      ) {
        searchParams.searchConversationStatus =
          formik.values.searchConversationStatus
      }
      searchConversations(searchParams)
    },
  })
  // TODO: Add user type on reset search
  useEffect(() => {
    console.log('from header, params changed: ', params)
    if (params?.searchConversationStatus) {
      formik.setFieldValue(
        'searchConversationStatus',
        params.searchConversationStatus
      )
    } else {
      formik.setFieldValue(
        'searchConversationStatus',
        MESSAGE_TYPE.ALL_MESSAGES
      )
    }
    if (params) formik.setFieldValue('searchText', params.searchText)
    else formik.setFieldValue('searchText', '')
  }, [params])

  useEffect(() => {
    formik.submitForm()
  }, [])

  const resetSearch = () => {
    searchConversations()
    getUnreadConversationsCount(participantsType)
  }

  return (
    <div className={styles.header}>
      <h2 className={styles.subHeading}>
        <span>
          {isEmployer
            ? translation[mct.labelCandidates]
            : translation[mct.labelEmployers]}
        </span>
        {specificParticipiantUnreadCount === 0 && <>&nbsp;</>}
        {specificParticipiantUnreadCount && (
          <span className={styles.unreadCount}>
            {specificParticipiantUnreadCount}
          </span>
        )}
      </h2>
      <div className={styles.searchToolbarWrap}>
        <div className={styles.searchToolbar}>
          <div className={styles.searchFieldWrap}>
            <TextField
              name="searchText"
              placeholder={mct.searchConversationsLabel}
              className={styles.textField}
              value={formik.values.searchText}
              onChange={(e) =>
                formik.setFieldValue('searchText', e.currentTarget.value)
              }
              theme="white"
            />
            <div className={styles.searchIcon}>
              <SearchIcon />
            </div>
          </div>

          <Dropdown
            name="searchConversationStatus"
            items={requestStatuses}
            className={styles.filterDropdown}
            {...SetValueProps(formik, 'searchConversationStatus')}
          />

          <Button
            text={mct.searchBtn}
            theme="bright"
            onClick={() => {
              formik.submitForm()
            }}
            className={styles.searchSubmit}
          />
        </div>
        <div role="button" onClick={resetSearch} className={styles.resetSearch}>
          <FormattedMessage id={mct.resetSearchBtn} />
        </div>
      </div>
    </div>
  )
}
