export const changeemailsettings = {
  'changeemailsettings.title': 'My Account',
  'changeemailsettings.titleChangeEmail': 'CHANGE EMAIL ADDRESS',
  'changeemailsettings.newEmailAddress': 'New email address',
  'changeemailsettings.typeNewAddress': 'Type new address..',
  'changeemailsettings.cancel': 'Cancel',
  'changeemailsettings.saveChanges': 'Save changes',
  'changeemailsettings.successMessage':
    'Confirmation email sent. Check your email inbox.',
}
