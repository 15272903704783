import {InsertChartOutlined} from '@mui/icons-material'
import Dropdown from 'Components/Inputs/Dropdown'
import Modal, {SubmitFooter} from 'Components/Modal'
import {FC, useState} from 'react'
import styles from './InviteToTestingModal.module.scss'

type InviteToTestingModalProps = {
  modalOpen: boolean
  setModalOpen: (value: boolean) => void
  requestsIds: string[]
  inviteToTestingBulk: (ids: string[], type: string) => void
}

export const InviteToTestingModal: FC<InviteToTestingModalProps> = ({
  modalOpen,
  setModalOpen,
  requestsIds,
  inviteToTestingBulk,
}) => {
  const [testType, setTestType] = useState('Cognitive test')
  const onSubmit = () => {
    inviteToTestingBulk(requestsIds, testType)
    setModalOpen(false)
  }

  return (
    <Modal
      isOpen={modalOpen}
      onAfterClose={() => {
        setModalOpen(false)
      }}
      title="Invite to testing"
      icon={InsertChartOutlined}
      ariaHideApp={false}
      className={styles.modal}
      theme="white"
      Footer={
        <SubmitFooter
          onSubmit={onSubmit}
          onCancel={() => {
            setModalOpen(false)
          }}
          submitText="Invite to testing"
        />
      }
    >
      <div className={styles.contentWrap}>
        <div className={styles.contentHeading}>TEST TYPE</div>
        <Dropdown
          name="testType"
          items={[
            {id: '1', translation: 'Cognitive test'},
            {id: '2', translation: 'Pshychometric test'},
          ]}
          value={testType}
          setValue={(_name, value) => setTestType(value as string)}
          withRadio={true}
        />
      </div>
    </Modal>
  )
}
