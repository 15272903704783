export enum SupportTranslations {
  title = 'support.title',
  ctaTitle = 'support.ctaTitle',
  ctaText = 'support.ctaText',
  ctaTitle2 = 'support.ctaTitle2',
  ctaText2 = 'support.ctaText2',
  ctaTitle3 = 'support.ctaTitle3',
  ctaText3 = 'support.ctaText3',
  title2 = 'support.title2',
  cardTitle = 'support.cardTitle',
  cardQuestion1 = 'support.cardQuestion1',
  cardAnswer1 = 'support.cardAnswer1',
  cardAnswer1SecondPart = 'support.cardAnswer1SecondPart',
  cardQuestion2 = 'support.cardQuestion2',
  cardQuestion3 = 'support.cardQuestion3',
  cardAnswer3 = 'support.cardAnswer3',
  cardAnswer3SecondPart = 'support.cardAnswer3SecondPart',
  cardQuestion4 = 'support.cardQuestion4',
  cardAnswer4EmployersTitle = 'support.cardAnswer4EmployersTitle',
  cardAnswer4EmployersText = 'support.cardAnswer4EmployersText',
  cardAnswer4CandidatesTitle = 'support.cardAnswer4CandidatesTitle',
  cardAnswer4CandidatesText = 'support.cardAnswer4CandidatesText',
  card2Title = 'support.card2Title',
  card2Question1 = 'support.card2Question1',
  card2Question2 = 'support.card2Question2',
  card2Question3 = 'support.card2Question3',
  card3Title = 'support.card3Title',
  card3Question1 = 'support.card3Question1',
  card3Question2 = 'support.card3Question2',
  card3Question3 = 'support.card3Question3',
  viewAll = 'support.viewAll',
  card1 = 'support.card1',
  card2 = 'support.card2',
  more = 'support.more',
}
