import {FC, useMemo} from 'react'
import {FormattedMessage} from 'react-intl'
import {formatFullDate} from 'Utils/Date'
import styles from './RequestValues.module.scss'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'
import {ExpirationValuesInDays} from 'Components/ExpirationValuesInDays'
export const ExpirationValue: FC<{date: string | Date}> = ({date}) => {
  if (typeof date !== 'object') {
    date = new Date(date)
  }
  const today = new Date()
  const inDays = Math.floor(
    (date.getTime() - today.getTime()) / (1000 * 3600 * 24)
  )
  const expirationDay = date.getDate()
  const currentDay = today.getDate()
  const expirationMonth = date.getMonth()
  const currentMonth = date.getMonth()
  const expirationYear = date.getFullYear()
  const currentYear = date.getFullYear()

  const isExpirationToday = useMemo(
    () =>
      expirationDay === currentDay &&
      expirationMonth === currentMonth &&
      expirationYear === currentYear,
    [
      expirationDay,
      currentDay,
      expirationMonth,
      currentMonth,
      expirationYear,
      currentYear,
    ]
  )

  if (inDays > 7 || date < new Date()) {
    return <div className={styles.dateTextIn}>{formatFullDate(date)}</div>
  }
  if (isExpirationToday) {
    return (
      <div className={styles.dateTextSoon}>
        <FormattedMessage id={edt.expiresToday} />
      </div>
    )
  }

  return <ExpirationValuesInDays inDays={inDays} />
}
