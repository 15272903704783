import {connect} from 'react-redux'

import MainLayout from './MainLayout'
import {AppDispatch} from 'Store'
import {
  getUnreadConversationsCount,
  getUser,
  cleanSnackbars,
} from 'Store/Actions'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  snackbars: state.app.data.snackbars,
  sidebarClosed: state.widgetBar.data.sidebarClosed,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getUser: () => dispatch(getUser()),
  getUnreadConversationsCount: (participantsType?: string) =>
    dispatch(getUnreadConversationsCount(participantsType)),
  cleanSnackbars: () => dispatch(cleanSnackbars()),
})

export const MainLayoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainLayout)
