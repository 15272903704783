import {ChangeEvent, FC, useCallback, useEffect, useState} from 'react'
import styles from './CheckboxGroup.module.scss'
import classnames from 'classnames'
import {BaseTaxonomyType} from 'Interfaces'
import {CheckBoxIcon} from 'Components/Icons'
import {useWindowResize} from 'Hooks'
import TextError from 'Components/Error/TextError'

interface Props {
  name: string
  values?: string | string[]
  setValues: (name: string, value: string | string[]) => void
  items: BaseTaxonomyType[]
  className?: string
  error?: string
  multiple?: boolean
  setTouched?: (v: boolean) => void
  minRows?: number
  forceErrorState?: boolean
}

export const CheckboxGroup: FC<Props> = ({
  name,
  values = [],
  setValues,
  className,
  items,
  error,
  multiple = false,
  setTouched = () => {},
  minRows = 3,
  children,
  forceErrorState,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (multiple && typeof values !== 'string') {
      const index = values.findIndex((v) => v === e.currentTarget.value)
      if (index === -1) {
        const item = items.find((i) => i.id === e.currentTarget.value)
        if (item) setValues(name, [...values, item.id])
      } else {
        const newValues = [...values]
        newValues.splice(index, 1)
        setValues(name, newValues)
      }
    } else {
      setValues(name, e.currentTarget.value)
    }
  }

  const handleColumns = useCallback(() => {
    const itemsCount = children ? items.length + 2 : items.length
    if (window.innerWidth > 1023) {
      if (itemsCount > 2 * minRows) {
        setNumColumns(3)
      } else if (itemsCount > minRows) {
        setNumColumns(2)
      } else setNumColumns(1)
    } else if (window.innerWidth > 768) {
      if (itemsCount > minRows) {
        setNumColumns(2)
      } else setNumColumns(1)
    } else setNumColumns(1)
  }, [items])

  useEffect(handleColumns, [items])
  useWindowResize(handleColumns)

  const [numColumns, setNumColumns] = useState(3)
  const numRows = Math.ceil(
    (children ? items.length + 2 : items.length) / numColumns
  )

  const style = {
    gridTemplateRows: `repeat(${numRows}, 1fr)`,
    gridColumnRows: `repeat(${numColumns}, 1fr)`,
  }

  const handleTouched = useCallback(() => {
    setTouched(true)
  }, [setTouched])

  return (
    <div className={styles.container}>
      <div
        className={classnames(styles.checkBoxGroup, className, {
          [styles.error]: error,
        })}
        style={style}
      >
        {items.map((item, index) => (
          <label
            htmlFor={item.id}
            key={`${item.id}-${index}`}
            className={styles.checkBox}
          >
            <CheckBoxIcon
              checked={values.includes(item.id)}
              className={classnames(styles.checkIcon, {
                [styles.errorIcon]: error || forceErrorState,
              })}
            />
            <input
              className={styles.hidden}
              type="checkbox"
              id={item.id}
              value={item.id}
              name={name}
              checked={values.includes(item.id)}
              onChange={handleChange}
              multiple={multiple}
              onClick={handleTouched}
            />
            <div className={styles.checkBoxLabel}>{item.translation}</div>
          </label>
        ))}
        {children}
      </div>
      {error && <TextError text={error} className="checkBoxGroupError" />}
    </div>
  )
}
