import {CheckBoxOutlineBlank, CheckBoxTwoTone} from '@mui/icons-material'
import classnames from 'classnames'
import styles from './CheckBoxIcon.module.scss'

export interface CheckBoxIconProps {
  checked: boolean
  className?: string
}

export const CheckBoxIcon = ({
  checked,
  className,
}: CheckBoxIconProps): JSX.Element => {
  return checked ? (
    <CheckBoxTwoTone
      className={classnames(styles.checkbox, styles.checked, className)}
    />
  ) : (
    <CheckBoxOutlineBlank className={classnames(styles.checkbox, className)} />
  )
}
