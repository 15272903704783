import {useIntl} from 'react-intl'
import {DateTranslations as dt} from 'Services/I18n/Constants'

export const useMonths = () => {
  const {formatMessage} = useIntl()
  return [
    {
      id: `1`,
      translation: formatMessage({id: dt.january}),
    },
    {
      id: `2`,
      translation: formatMessage({id: dt.february}),
    },
    {
      id: `3`,
      translation: formatMessage({id: dt.march}),
    },
    {
      id: `4`,
      translation: formatMessage({id: dt.april}),
    },
    {
      id: `5`,
      translation: formatMessage({id: dt.may}),
    },
    {
      id: `6`,
      translation: formatMessage({id: dt.june}),
    },
    {
      id: `7`,
      translation: formatMessage({id: dt.july}),
    },
    {
      id: `8`,
      translation: formatMessage({id: dt.august}),
    },
    {
      id: `9`,
      translation: formatMessage({id: dt.september}),
    },
    {
      id: `10`,
      translation: formatMessage({id: dt.october}),
    },
    {
      id: `11`,
      translation: formatMessage({id: dt.november}),
    },
    {
      id: `12`,
      translation: formatMessage({id: dt.december}),
    },
  ]
}
