import {useMemo, MouseEvent, useState, useEffect, Fragment} from 'react'
import classnames from 'classnames'
import {Link} from 'react-router-dom'
import {useIntl, FormattedMessage} from 'react-intl'
import {Badge} from '@mui/material'
import {
  DescriptionOutlined,
  InsertChartOutlined,
  VideocamOutlined,
  CakeOutlined,
  LocationOnOutlined,
  LockOutlined,
  Update,
  AssignmentOutlined,
  MoreHorizOutlined,
  SchoolOutlined,
  ViewInArOutlined,
  CheckOutlined,
  AccessTimeOutlined,
} from '@mui/icons-material'

import styles from './CandidateCard.module.scss'
import {useIsMount} from 'Hooks'
import Checkbox from 'Components/Inputs/Checkbox'
import CardInfoItem from './CardInfoItem'
import {
  Candidate,
  CandidateSkill,
  EmployerProjectCandidatesItemType,
  OccupationExperience,
  SeekerReviewType,
} from 'Interfaces/CandidateType'
import RequestContact from 'Components/RequestContact'
import {
  IncomingRequestSeekerType,
  WidgetBarType,
  RjbProfileStatus,
} from 'Interfaces'
import MatchAvatar from './MatchAvatar'
import PopoverList from 'Components/PopoverList'
import {PopoverItemType} from 'Components/Popover'
import {ContactRequestStatus} from 'Components/EmployerDashboard/InterviewInvitationListItem/Constants'
import AddRemoveCandidateToProject from './AddRemoveCandidateToProject'
import {
  AppRoute,
  CandidateSearchTranslations as cs,
  ProfileTranslations as pt,
} from 'Services/I18n/Constants'
import {useTranslation} from 'Hooks'
import {getRoute} from 'Services/I18n/Utils'
import {ReactComponent as CognitiveIcon} from 'Assets/Images/cognitive-tests.svg'
import {ReactComponent as PsychometricIcon} from 'Assets/Images/psychometic-tests.svg'
import RateCandidate from '../../Widgets/RateCandidateMyProject'
import ReviewAvatar from './ReviewAvatarBorder'
import AllProjectsInfo from 'Components/CandidateMyProject/AllProjectsInfo'
import {getYearsOld} from 'Utils/Date'
import {SortCandidateSkills} from './CandidateSearchResults/SortCandidateSkills'
import {CandidateBadge} from 'Components/CandidateBadge'

type CandidateCardProps = {
  candidate: Candidate
  selected?: boolean
  page?: 'search' | 'myProject' | 'myProjects'
  handleCandidateCheck: (id: string) => void
  setSidebarClosed: (sidebarClosed: boolean) => void
  setCandidateBasicData: (
    candidate: Candidate | IncomingRequestSeekerType | null
  ) => void
  setMyProjectCandidateReview: (seekerReview: SeekerReviewType) => void
  widgetBar: WidgetBarType
  handleCommentFocus?: () => void
  setIsNoLogerActiiveCandidateModalOpen?: (v: boolean) => void
  isRecruiter?: boolean
}

export const CandidateCard = ({
  candidate,
  selected = false,
  page = 'search',
  handleCandidateCheck,
  widgetBar,
  setSidebarClosed,
  setCandidateBasicData,
  setMyProjectCandidateReview,
  handleCommentFocus,
  setIsNoLogerActiiveCandidateModalOpen,
  isRecruiter = false,
}: CandidateCardProps) => {
  const [reload, setReload] = useState<boolean>(true)
  const [review, setReview] = useState<boolean | undefined>(false)

  const isMount = useIsMount()
  const noLongerActiveCandidate =
    !candidate?.hasFinishedPlacementWithCurrentEmployer &&
    candidate?.rjbProfileStatus === RjbProfileStatus.CLOSED

  const contactRequest =
    candidate?.contactRequest || candidate?.companySeekerJourney?.contactRequest
  const {formatMessage} = useIntl()

  useEffect(() => {
    if (isMount) {
      setReload(true)
      setReview(candidate.companyUserSeekerProject?.seekerReview?.reviewed)
    } else {
      setReload(false)
      setReview(candidate.companyUserSeekerProject?.seekerReview?.reviewed)
    }
  }, [candidate.companyUserSeekerProject?.seekerReview?.reviewed])
  const isAccepted =
    contactRequest?.status === ContactRequestStatus.APPROVED || isRecruiter
  const hasInterviewRequests = candidate?.interviewRequests?.length

  const {candidateBasicData} = widgetBar

  const getSkills = (candidateSkills: CandidateSkill[]) => {
    const sortedSkills = SortCandidateSkills(candidateSkills)
    const skillValues = sortedSkills
      .slice(0, 5)
      .map((candidateSkill) => candidateSkill.skill.translation)
    return skillValues.join(', ')
  }

  const getLocation = (candidate: Candidate) =>
    `${
      contactRequest?.status !== 'approved' &&
      candidate.country?.translation === 'Slovenia'
        ? ''
        : `${candidate?.town?.translation},`
    } ${
      candidate.country?.translation === 'Slovenia'
        ? candidate.region?.translation || candidate?.town?.translation
        : candidate.country?.translation
    }`

  const renderExperiences = (occupationExperience: OccupationExperience) =>
    `${occupationExperience.occupation.translation} 
    (${occupationExperience.experience}${
      occupationExperience.experience === 10 ? '+' : ''
    } ${
      occupationExperience.experience === 1
        ? translation[cs.year]
        : translation[cs.years]
    })`

  const translation = useTranslation()
  const similarCandidateUrl = `${getRoute(
    AppRoute.CandidateSearch
  )}?similarCandidateUserId=${candidate.userId}`

  let moreActions = useMemo(() => {
    let actions: PopoverItemType[] = [
      {
        text: translation[cs.addNoteButton],
        onClick: (event: MouseEvent) => {
          event.stopPropagation()
          setSidebarClosed(false)
          setCandidateBasicData(candidate)
          if (handleCommentFocus) handleCommentFocus()
        },
      },
    ]
    if (page !== 'search') {
      if (page === 'myProject') {
        const text = candidate.companyUserSeekerProject?.seekerReview?.reviewed
          ? translation[cs.markAsUnreviewedButton]
          : translation[cs.markAsReviewedButton]
        const reviewActions = [
          {
            text,
            onClick: (event: MouseEvent) => {
              event.stopPropagation()
              setMyProjectCandidateReview({
                reviewed:
                  !candidate.companyUserSeekerProject?.seekerReview?.reviewed,
                id: candidate.id,
                reviewId: candidate.companyUserSeekerProject?.seekerReview?.id,
              })
            },
          },
        ]
        actions = [...actions, ...reviewActions]
      }

      const myProjectActions = [
        {
          text: translation[cs.findSimilarCandidate],
          route: similarCandidateUrl,
        },
      ]
      actions = [...actions, ...myProjectActions]
    }
    return actions
  }, [candidate])

  const profileUrl = getRoute(
    isAccepted
      ? AppRoute.EmployerCandidateProfileFull
      : AppRoute.EmployerCandidateProfilePublic
  ).replace(':id', candidate.userId)

  const renderBadge = {
    completed: (
      <div className={styles.badgeGreen}>
        <CheckOutlined />
      </div>
    ),
    results_pending: (
      <div className={styles.badgeYellow}>
        <AccessTimeOutlined />
      </div>
    ),
  }

  const redirectUrl = getRoute(AppRoute.PublicAssessments).replace(
    ':id',
    candidate.userId
  )

  const savedCandidatesProjects = useMemo(
    () =>
      candidate.companyUserSeekerProjects?.map((p) => {
        const {project, ...rest} = p
        return {...rest, companyUserProject: project}
      }),
    [candidate.companyUserSeekerProjects]
  )

  const approvedRjbProfileStatusUpdateDateParsed = new Date(
    candidate?.approvedRjbProfileStatusUpdateDate as string
  )
  const currentDate = new Date()
  const timeDifferenceMs =
    Number(currentDate) - Number(approvedRjbProfileStatusUpdateDateParsed)
  const timeDifferenceHours = timeDifferenceMs / (1000 * 60 * 60)
  const showBadge =
    candidate?.approvedRjbProfileStatusUpdateDate && timeDifferenceHours < 72

  const availableNow = candidate?.availability?.name === 'Immediately'

  return (
    <div
      className={classnames(styles.cardWrap, 'cardWrap', {
        [styles.openedCard]:
          candidateBasicData && candidateBasicData?.id === candidate.id,
        [styles.myProject]: page !== 'search',
      })}
      onClick={() => {
        setSidebarClosed(false)
        setCandidateBasicData(candidate)
      }}
    >
      <div
        className={classnames(styles.cardHeader, {
          [styles.headerSelected]: selected,
        })}
      >
        <div onClick={(e) => e.stopPropagation()}>
          <Checkbox
            className={classnames(styles.cardCheckInput)}
            name={candidate.id + 'resultCheck'}
            onChange={() => handleCandidateCheck(candidate.userId)}
            value={selected}
          />
        </div>
        <div className={styles.candidatePosition}>
          {candidate?.publicOccupation ? (
            <div>
              {noLongerActiveCandidate &&
              setIsNoLogerActiiveCandidateModalOpen ? (
                <button
                  type="button"
                  onClick={() => setIsNoLogerActiiveCandidateModalOpen(true)}
                  className={classnames(styles.profileUrl, {
                    [styles.candidateUnderline]: isAccepted,
                  })}
                >
                  {candidate.publicOccupation.translation}
                </button>
              ) : (
                <Link
                  to={profileUrl}
                  className={classnames(styles.profileUrl, {
                    [styles.candidateUnderline]: isAccepted,
                  })}
                >
                  {candidate.publicOccupation.translation}
                </Link>
              )}
            </div>
          ) : null}
          {isAccepted && candidate.firstName && candidate.lastName && (
            <div className={styles.fullNameWrap}>
              {noLongerActiveCandidate &&
              setIsNoLogerActiiveCandidateModalOpen ? (
                <button
                  type="button"
                  onClick={() => setIsNoLogerActiiveCandidateModalOpen(true)}
                >
                  <span className={styles.fullName}>
                    {`${candidate.firstName} ${candidate.lastName}`}
                  </span>
                  <span className={classnames(styles.fullName, styles.comma)}>
                    {`${
                      page !== 'search' && candidate.dateOfBirth ? ',' : ' '
                    }`}
                  </span>
                </button>
              ) : (
                <Link to={profileUrl}>
                  <span className={styles.fullName}>
                    {`${candidate.firstName} ${candidate.lastName}`}
                  </span>
                  <span className={classnames(styles.fullName, styles.comma)}>
                    {`${
                      page !== 'search' && candidate.dateOfBirth ? ',' : ' '
                    }`}
                  </span>
                </Link>
              )}

              {page !== 'search' && candidate.dateOfBirth && (
                <span className={styles.fullNameAdditionalText}>
                  {`${getYearsOld(candidate.dateOfBirth)}`}
                  <FormattedMessage id=" years old" />
                  <span className={styles.comma}>{`${','}`}</span>
                </span>
              )}
              {page !== 'search' && candidate.town && candidate.country && (
                <span className={styles.fullNameAdditionalText}>
                  {` ${candidate.town.translation}, ${candidate.country.translation}`}
                </span>
              )}
            </div>
          )}
          <div className={styles.badges}>
            {availableNow && <CandidateBadge type={'available'} />}
            {showBadge && <CandidateBadge type={'new'} />}
          </div>
        </div>

        <div className={styles.callsToAction}>
          <div className={styles.rateWrap}>
            {page === 'myProject' && (
              <RateCandidate
                placement={'card'}
                seekerEvaluation={
                  candidate.companyUserSeekerProject?.seekerEvaluation
                }
                seekerId={candidate.userId}
              />
            )}
          </div>
          <AddRemoveCandidateToProject
            firstName={candidate.firstName}
            lastName={candidate.lastName}
            candidate={candidate}
            candidateProjects={
              page === 'search'
                ? candidate.projects
                : (savedCandidatesProjects as EmployerProjectCandidatesItemType[])
            }
            seekerId={candidate.userId}
            withStar={page === 'search'}
          />

          {isAccepted ? (
            <div className={styles.notToClose}>
              <div className={styles.acceptedCtas}>
                {isAccepted && (
                  <div className={styles.descriptionIcon}>
                    <DescriptionOutlined />
                  </div>
                )}
                {hasInterviewRequests && (
                  <div className={styles.videoIcon}>
                    <VideocamOutlined />
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div onClick={(e) => e.stopPropagation()}>
              <RequestContact
                contactRequest={contactRequest}
                seekerId={candidate.userId}
              />
            </div>
          )}
          <div className={styles.popOverListContainer}>
            <PopoverList
              triggerIcon={<MoreHorizOutlined />}
              items={moreActions}
              disablePortal
              threeDotsBtn
            />
          </div>
        </div>

        <div className={styles.cardAvatarWrap}>
          {page === 'myProject' && (
            <ReviewAvatar review={review} reload={reload} />
          )}
          {noLongerActiveCandidate && setIsNoLogerActiiveCandidateModalOpen ? (
            <button
              type="button"
              onClick={() => setIsNoLogerActiiveCandidateModalOpen(true)}
            >
              <div
                className={classnames(styles.cardAvatar, {
                  [styles.noBackground]: isAccepted,
                })}
              >
                {isAccepted ? (
                  <MatchAvatar
                    match={{
                      experience: 0,
                      skills: 0,
                      total: 0,
                    }}
                    image={
                      candidate?.profilePicture
                        ? candidate?.profilePicture?.url
                        : ''
                    }
                    firstName={candidate.firstName || ''}
                    lastName={candidate.lastName || ''}
                  />
                ) : (
                  <LockOutlined />
                )}
              </div>
            </button>
          ) : (
            <Link to={profileUrl}>
              <div
                className={classnames(styles.cardAvatar, {
                  [styles.noBackground]: isAccepted,
                })}
              >
                {isAccepted ? (
                  <MatchAvatar
                    match={{
                      experience: 0,
                      skills: 0,
                      total: 0,
                    }}
                    image={
                      candidate?.profilePicture
                        ? candidate?.profilePicture?.url
                        : ''
                    }
                    firstName={candidate.firstName || ''}
                    lastName={candidate.lastName || ''}
                  />
                ) : (
                  <LockOutlined />
                )}
              </div>
            </Link>
          )}
        </div>
      </div>

      <div className={styles.cardContent}>
        {page === 'search' && (candidate.age || candidate.dateOfBirth) && (
          <CardInfoItem
            icon={<CakeOutlined />}
            label={translation[cs.ageLabel]}
            value={`${
              candidate.age || getYearsOld(candidate.dateOfBirth as string)
            } ${formatMessage({id: pt.yearsOld})}`}
          />
        )}
        {page === 'search' && candidate.town && candidate.country && (
          <CardInfoItem
            icon={<LocationOnOutlined />}
            label={translation[cs.homeLocation]}
            value={getLocation(candidate)}
          />
        )}
        {candidate?.careerLevel && (
          <CardInfoItem
            icon={<SchoolOutlined />}
            label={translation[cs.careerStatus]}
            // value={candidate.careerStatus}
            value={candidate.careerLevel.translation}
          />
        )}
        {candidate?.availability && (
          <CardInfoItem
            icon={<Update />}
            label={translation[cs.availabilityLabel]}
            value={candidate?.availability?.translation}
          />
        )}
        {candidate?.skills && candidate?.skills.length > 0 && (
          <CardInfoItem
            icon={<AssignmentOutlined />}
            label={translation[cs.topSkills]}
            value={getSkills(candidate.skills)}
          />
        )}
        {candidate.occupationExperiences && (
          <CardInfoItem
            icon={<ViewInArOutlined />}
            label={translation[cs.relevantYears]}
            value={`${candidate.occupationExperiences
              .slice(0, 5)
              .map((occupationExperience) =>
                renderExperiences(occupationExperience)
              )
              .join(', ')}`}
          />
        )}
      </div>
      {isAccepted &&
        candidate?.seekerAssessments &&
        candidate?.seekerAssessments?.length > 0 && (
          <div className={styles.testsWrap}>
            {candidate.seekerAssessments.map((assessment) => (
              <Fragment key={assessment.id}>
                {assessment.name === 'Psychometric test' ? (
                  assessment?.resultFile && (
                    <Link
                      to={getRoute(AppRoute.PsychometricTestResults).replace(
                        ':candidateId',
                        candidate.userId
                      )}
                      className={styles.testLink}
                    >
                      <Badge badgeContent={renderBadge[assessment.status]}>
                        <PsychometricIcon className={styles.chartIcon} />
                      </Badge>
                    </Link>
                  )
                ) : (
                  <Link
                    to={
                      assessment.status === 'completed'
                        ? {pathname: assessment.resultFile?.url}
                        : redirectUrl
                    }
                    className={styles.testLink}
                    target={
                      assessment.status === 'completed' ? '_blank' : undefined
                    }
                    key={assessment.id}
                  >
                    <Badge badgeContent={renderBadge[assessment.status]}>
                      <CognitiveIcon className={styles.chartIcon} />
                    </Badge>
                  </Link>
                )}
              </Fragment>
            ))}
            <Link to={redirectUrl} className={styles.testLink}>
              <InsertChartOutlined className={styles.chartIcon} />
            </Link>
          </div>
        )}
      {page !== 'search' && candidate.companyUserSeekerProjects && (
        <div className={classnames(styles.testsWrap, styles.allProjectsList)}>
          <AllProjectsInfo projects={candidate.companyUserSeekerProjects} />
        </div>
      )}
    </div>
  )
}
