import {connect} from 'react-redux'

import {EmployerDashboard} from './EmployerDashboard'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {
  getCompanyData,
  getUser,
  setSidebarClosed,
  getLatestEmployerRequests,
  setCandidateBasicData,
} from 'Store/Actions'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  company: state.company,
  candidateBasicData: state.widgetBar.data.candidateBasicData,
  sidebarClosed: state.widgetBar.data.sidebarClosed,
  requests: state.employer.data.allRequests.allRequests,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCompanyData: (id: string) => dispatch(getCompanyData(id)),
  getUser: () => dispatch(getUser()),
  setSidebarClosed: (sidebarClosed: boolean) =>
    dispatch(setSidebarClosed(sidebarClosed)),
  getLatestEmployerRequests: () => dispatch(getLatestEmployerRequests()),
  setCandidateBasicData: (candidate: any) =>
    dispatch(setCandidateBasicData(candidate)),
})

export const EmployerDashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerDashboard)
