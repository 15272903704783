import {connect} from 'react-redux'

import {SentRequests} from './SentRequests'
// import {RootState} from 'Store/Reducers'
// import {AppDispatch} from 'Store'

// const mapStateToProps = (state: RootState) => ({})

// const mapDispatchToProps = (dispatch: AppDispatch) => ({

//   setCandidateBasicData: (
//     candidate: Candidate | IncomingRequestSeekerType | null
//   ) => {
//     dispatch(setCandidateBasicData(candidate))
//     // TODO replace this id with real seeker id after this data get avaliable
//     getCandidateComments('b4de83aa-6a9f-464d-9211-ab256f7671a5')
//   },
//   setSidebarClosed: (sidebarClosed: boolean) =>
//     dispatch(setSidebarClosed(sidebarClosed)),
// })

export const SentRequestsContainer = connect(null, null)(SentRequests)
