export const employeronboarding = {
  'employeronboarding.statementTitlePartOne': 'Dejodajalec na Preferenca.si,',
  'employeronboarding.statementTxtPartOne':
    'ki nastopa kot upravljalec (v nadaljevanju “upravljalec”)',
  'employeronboarding.statementTxtPartTwo': 'in',
  'employeronboarding.statementBoldTxtPartOne':
    ' Styria digital marketplaces d.o.o.',
  'employeronboarding.statementAddress':
    ',Verovškova 55, 1000 Ljubljana, davčna št.: SI58257551, ki nastopa kot pogodbeni obdelovalec podatkov (v nadaljevanju: “pogodbeni obdelovalec”)',
  'employeronboarding.statementAddressInfo':
    '(v nadaljevanju “upravljalec” in “pogodbeni obdelovalec”, skupaj poimenovana “pogodbeni stranki”)',
  'employeronboarding.statementAddressInfoLast': ' sklepata naslednjo:',
  'employeronboarding.statementTitle': 'POGODBO O POGODBENI OBDELAVI PODATKOV',
  'employeronboarding.statementTitleTxt':
    ' v skladu s tretjim odstavkom 28. člena Splošne uredbe EU o varstvu podatkov',
  'employeronboarding.statementBoldTxtPartTwo': '1. Preambula/predmet pogodbe',
  'employeronboarding.statementBoldNumber': '1.1 ',
  'employeronboarding.statementBoldNumberTxtPartOne':
    ' V skladu s pogodbenimi ali pravnimi razmerji, ki so podrobneje navedena v Aneksu št. 1 (v nadaljevanju “Aneks”) pogodbeni obdelovalec za upravljalca izvaja storitve, ki so v celoti ali delno sestavljene iz obdelave osebnih podatkov v skladu z 2. točko 4. člena Splošne uredbe EU o varstvu podatkov (EU General Data Protection Regulation, v nadaljevanju “GDPR”). Ta Pogodba predstavlja podlago za takšno obdelovanje podatkov v skladu s tretjim odstavkom 28. člena GDPR. V tem oziru je upravljalec podatkov odgovoara oseba, pogodbeni obdelovalec pa pogodbeno obdeluje podatke.',
  'employeronboarding.statementBoldNumberSecond': '1.2',
  'employeronboarding.statementBoldNumberSecondTxt':
    ' V primeru dvomov ali nasprotij glede obdelovanja podatkov ima ta Pogodba v celoti prednost pred osnovno pogodbo oziroma naročilnoc (v nadaljevanju: “osnovna pogodba”)',
  'employeronboarding.statementBoldNumberThird': '1.3 ',
  'employeronboarding.statementBoldNumberThirdTxt':
    ' Ne glede na podatke in/ali definicije, ki so navedene v Aneksu, ta Pogodba pokriva vse osebne podatke, ki jih pogodbeni obdelovalec obdeluje z namenom izpolnitve osnovne pogodbe, v katerikoli obliki, oziroma do njih dostopa ali lahko dostopi.',
  'employeronboarding.statementBoldTxtPartThree':
    '2. Opis obdelovanja podatkov',
  'employeronboarding.statementBoldNumberFourht': '2.1 ',
  'employeronboarding.statementBoldNumberFourhtTxt':
    'Obdelovanje podatkov se izvaja v okviru obveznosti iz osnovne pogodbe, katere so natančneje opredeljene in navedene v Aneksu. Tam so opisani in/ali povzeti tudi predmet, trajanje, vrsta in nameni obdelovanja osebnih podatkov ter osebni podatki, ki so sporočeni ali dostopni pogodbenemu obdelovalcu za namen izpolnjevanja njegovih pogodbenih obveznosti in kategorije posameznikov, na katere se nanašajo osebni podatki.',
  'employeronboarding.statementBoldNumberFifth': '2.2 ',
  'employeronboarding.statementBoldNumberFifthTxt':
    ' Pogodbeni obdelovalec obdeluje osebne podatke izključno z namenom izpolnjevanja svojih pogodbenih obveznosti do upravljalca, kot je dogovorjeno v osnovni ali tej Pogodbi in Aneksu ali v skladu z navodili upravljalca. Pogodbeni obdelovalec bo upravljalca vnaprej in v dopustnem obsegu obveščal, o katerikoli drugih pravnih obveznostih v zvezi z obdelovanjem.',
  'employeronboarding.statementCheckBoxLabel':
    ' I declare that I am familiar with the content of the Agreement on Contractual Data Processing and fully accept it. By clicking on the button below, the contract is concluded, and the content is fully binding for the contracting parties.',
  'employeronboarding.step1Title': 'Company and contact info',
  'employeronboarding.step2Title': '12 month hiring plan',
  'employeronboarding.step3Title': 'Thank you',
  'employeronboarding.step1SidebarTitle': 'General data',
  'employeronboarding.step2SidebarTitle': 'Company and contact info',
  'employeronboarding.step3SidebarTitle': '12 month hiring plan',
  'employeronboarding.titleEmploymentPlan':
    'COMPANY EMPLOYMENT PLANS FOR NEXT 12 MONTHS',
  'employeronboarding.titleSalaryRange': 'Salary range',
  'employeronboarding.titleOccupationalFieldsCandidates':
    'OCCUPATIONAL FIELDS OF CANDIDATES',
  'employeronboarding.titleRegions': 'REGIONS OF EMPLOYMENT',
  'employeronboarding.titleYearsOfExp': 'YEARS OF EXPERIENCE PER POSITION',
  'employeronboarding.titleEducation': 'EDUCATION OF CANDIDATES',
  'employeronboarding.typeaheadCandidatesPos': 'Candidates position',
  'employeronboarding.typeaheadCandidatesPosPlaceholder':
    'Enter candidates position',
  'employeronboarding.positionYearsOfExp': 'Years of experience',
  'employeronboarding.positionSalaryRange': 'Salary range',
  'employeronboarding.typeaheadDegree': 'Degree level',
  'employeronboarding.typeaheadFieldOfStudy': 'Field of study',
  'employeronboarding.companyContactInfoDescription':
    'Please check the provided data and assign the contact person.',
  'employeronboarding.twelveMonthHiringPlanDescription':
    'Tell us your 12 month hiring plan, so we can help you find better candidates.',
  'employeronboarding.statementDescription':
    'Please read the Contractual Data Processing Agreement.You can make a declaration of the conclusion of the contract after looking at the whole text.',
  'employeronboarding.companyLongName': 'COMPANY LONG NAME',
  'employeronboarding.contactPersonTelephoneNumber':
    'CONTACT’S PERSON TELEPHONE NUMBER',
  'employeronboarding.contactPersonPosition':
    'CONTACT’S PERSON POSITION IN COMPANY',
  'employeronboarding.address': 'Address',
  'employeronboarding.postalCode': 'Postal Code',
  'employeronboarding.city': 'City',
  'employeronboarding.country': 'Country',
  'employeronboarding.selectValue': 'Select value',
  'employeronboarding.selectYourCountry': 'Select your country',
  'employeronboarding.phoneNumber': 'Phone number',
}
