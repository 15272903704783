export const CandidateFAQ = {
  'CandidateFAQ.yellowTitle': 'FREE',
  'CandidateFAQ.title': 'CAREER SUPPORT',
  'CandidateFAQ.titleFirstParagraph': 'How to create and set up your profile?',
  'CandidateFAQ.textFirstParagraph':
    'Creating a profile is quick and simple. The process involves several steps to obtain important information for our system to connect you with employers who are looking for candidates with your skills and preferences.',
  'CandidateFAQ.textItemOneFirstParagraph':
    'In the first step, fill in your email address, first and last name, and choose a password.',
  'CandidateFAQ.textItemTwoFirstParagraph':
    'You then have the option to upload your CV as a file (.pdf recommended) that allows you to automatically fill in your work experience, education and references segments. In some cases, these segments are not self-completed and the registration questionnaire later guides you to add your experience and education yourself.',
  'CandidateFAQ.textItemThreeFirstParagraph':
    'Indicate in your profile which professions you are currently most interested in, the type of job you are looking for, the location of your search, the salary you want and when you could start working at your new job.',
  'CandidateFAQ.textItemFourFirstParagraph':
    'Finally, mark which benefits mean a lot to you in the work place.',
  'CandidateFAQ.titleSecondParagraph':
    'How to improve your profile and get the best offers',
  'CandidateFAQ.textSecondParagraph':
    'Have you followed all the steps in creating your profile, but are still not getting the offers you would like? Make sure you take advantage of the following:',
  'CandidateFAQ.titleFirstSection': 'Preferred profession',
  'CandidateFAQ.paragraphFirstSection':
    'We recommend that you choose the position closest to your preferences. Choose several professions of interest and be as specific as possible. If you are interested in several different professions, we recommend that you explain in your introductory statement why you have listed these jobs and which jobs you prefer.',
  'CandidateFAQ.titleSecondSection': 'Skills and competencies',
  'CandidateFAQ.paragraphSecondSection':
    "When creating your profile, pay attention and emphasise your most developed skills and competencies that you would like to use in the workplace. Here, you can also identify skills and knowledge that you may not yet have much experience in, but want to improve. If you are an IT professional, we recommend that you support your skills by completing various skill tests, e.g. HTML, CSS, Android, etc. A high enough score earns you a badge that is visible on your profile, and it's also great practice for technical interviews. It has been proven that you are twice as likely to be invited for an interview if the employer sees this badge on your profile. It is one of the most recognisable signs of talent that employers are looking for.",
  'CandidateFAQ.titleThirdSection': 'Prior working experience',
  'CandidateFAQ.paragraphThirdSection':
    'Briefly describe your job duties and skills in two or three sentences for each past employment. This is very important as employers want to know which software, programming language or technology you have used in the past, while this is also a good indicator of your expertise in certain areas. If you have had many jobs in the past, we recommend that you focus on the last two or three jobs, and describe the previous ones briefly, in a more summarised way. For people with less experience, we recommend including all the jobs, projects or competitions done as a student that make sense in terms of the job you are looking for.',
  'CandidateFAQ.titleFourthSection': 'Education',
  'CandidateFAQ.paragraphFourthSection':
    "If you maybe don’t have much experience or have just finished your studies, it is very important to list your education. Specify the field of study and level of education. For those of you who may not yet have work experience, we recommend that you present projects, seminars and bachelor’s or master's theses here, and thus highlight the skills and competencies you have developed in the process. There are more and more professionals with no formal education and companies are more open about this, so in this segment, we offer the option of “self-taught” or “didn't finish” for all of you who started your studies but didn't finish them.",
  'CandidateFAQ.titleFifthSection': 'Introductory statement',
  'CandidateFAQ.paragraphFifthSection':
    "An introductory address is of the utmost importance, since this is the first thing the employer sees. The vast majority simply state their current job title, but it pays to use a few words to thoughtfully and creatively record your interest and contribution. The introduction should be clear, attractive and define your current position or what you want. For those who have just finished your studies, it is very important to define what kind of job you are looking for. For those with more experience, it is important to highlight your key skills and express your preferences for your future position. An experienced software engineer interested in developing websites and applications could say, for example: “Java engineer with 6+ years of experience interested in working in the field of website and app development.” This kind of introduction highlights the language used most often and recently (Java), how long you've been doing it (at least 6 years) and what you'd like to focus on (website and app development). A recent graduate who has just got a bachelor's degree in mechatronics would reflect his experience this way: “A young electrical-mechatronics engineer with a strong interest in pneumatics and hydraulics is looking for a job in the automotive industry.” Although you might be tempted to include personal interests, you only have one chance to make a great first impression on the employer, so only include the most important matters.",
  'CandidateFAQ.titleSixthSection': 'Location',
  'CandidateFAQ.paragraphSixthSection':
    'We recommend that you indicate on your profile that you are willing to work both in the office and from home. You thus show your flexibility and it will help you in your final negotiations. Some companies will offer the option of remote work even if they may not state it initially. The vast majority of employers prefer to see their employees work in the office, but there is a growing trend of working from home.',
  'CandidateFAQ.titleSeventhSection': 'Show your personality',
  'CandidateFAQ.paragraphSeventhSection':
    'Your profile can be more representative if you add your photo, your favourite project in the form of an additional file, and perhaps your hobbies and leisure time interests. Show your personality, but stay professional.',
  'CandidateFAQ.titleThirdParagraph':
    'How to improve your profile and get the best offers',
  'CandidateFAQ.textThirdParagraph':
    'Your CV was just interesting and informative enough for the employer to invite you for an interview. Now you have to prove that you are a good fit able to do the job. A job interview is a big challenge for everyone – not only for those who are facing it for the first time, but also for experienced job seekers. This is an experience often filled with trepidation and a fear of failure. You can regulate your concerns and uncertainty by being prepared for the interview in advance. Below we present five short tips for a successful interview preparation:',
  'CandidateFAQ.bottomParagraphtitleFirst':
    'Do some brief research about the company and the job:',
  'CandidateFAQ.bottomParagraphtextFirst':
    'Understanding the key information about the company, you will be visiting is an important part of the interview preparation. Research their website in detail, the company values, learn about their products and services, also check their social media profiles where you can get a very good sense of the company culture and the type of people working there. It is also important for you to understand all the requirements and responsibilities associated with the job. During the interview itself, you can additionally ask for possible explanations and details, which will later help you decide whether this position is right for you.',
  'CandidateFAQ.bottomParagraphtitleSecond':
    'Prove you are an expert in your field:',
  'CandidateFAQ.bottomParagraphtextSecond':
    "In your profile, you have listed certain skills and knowledge that you are most specialised in. Be prepared for more specific or technical questions from the employer in this area. When recruiting technical profiles, employers are using various professional/technical tasks more and more often in order to get an insight into candidates' practical knowledge.",
  'CandidateFAQ.bottomParagraphtitleThird':
    'Practise your answers to the most typical questions:',
  'CandidateFAQ.bottomParagraphtextThird':
    'Prepare your answers to the most typical questions from employers, which are repeated in more or less the same form in every interview. Review your past projects and make a list of your professional accomplishments. You can use the STAR method (Situation, Task, Action, Result) to help you structure the answers.',
  'CandidateFAQ.bottomParagraphtitleFourth': 'Prepare some questions:',
  'CandidateFAQ.bottomParagraphtextFourth':
    'This is your chance. Employers expect you to ask questions, they want to know if you are seriously considering working in their company. Ask questions that emphasise your knowledge of the company and its field of work, and your interest in a particular position. Try to get additional information that you can use to assess whether the position would suit you. The interview is a two-way communication channel, which means that you want to get just as much information about the employer as the employer wants to get about you.',
  'CandidateFAQ.bottomParagraphtitleFifth':
    'Keep calm and make a great first impression:',
  'CandidateFAQ.bottomParagraphtextFifth':
    'Showing a positive attitude with a smile and optimistic body language goes a long way. A more relaxed, yet constructive and professional interview atmosphere is certainly more desirable than stern looks and unfriendly faces. Do not forget, it is a matter of mutual interest, as the employer wants to fill a certain position, and you want to get the best offer for yourself. Every interview is slightly different, but the most important thing is to dig into it as confidently as possible and be ready to show the best version of yourself.',
  'CandidateFAQ.lastSectiontitle':
    'What the salary expectations should be according to your profile, work experience and skills',
  'CandidateFAQ.lastSectionFirstPart':
    'Salary expectations should be appropriate based on your education, skills, competencies and experience. It is also important not to underestimate yourself, as this can give the impression of incompetence or lack of confidence regarding your work competencies. We recommend that you read more on ',
  'CandidateFAQ.lastSectionlink': 'Placa.si.',
  'CandidateFAQ.lastSectionSecondPart':
    ' to get the best possible insight on the comparable salaries for your profile. Determine your value based on facts. Think about how much you could contribute to the company and determine your value. Express your preferences, but be ready for negotiations.',
}
