import {FC} from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import classnames from 'classnames'
import styles from './ShowMore.module.scss'
import {FormattedMessage} from 'react-intl'
interface ShowMoreProps {
  items?: any[]
  showMoreIndex: number
  setShowMoreIndex: (n: number) => void
  defaultShowMoreIndex?: number
  type?: string
  textFewer?: string
  textMore?: string
  icon?: boolean
  hr?: boolean
  classname?: string
}

export const ShowMore: FC<ShowMoreProps> = ({
  items,
  showMoreIndex,
  setShowMoreIndex,
  defaultShowMoreIndex = 1,
  textFewer = 'Show fewer',
  textMore = 'Show more',
  icon = true,
  hr = true,
  classname,
}) => {
  if (items && items.length && items.length > defaultShowMoreIndex) {
    const loadMore = () => {
      if (showMoreIndex === items.length) setShowMoreIndex(defaultShowMoreIndex)
      else setShowMoreIndex(items.length)
    }
    return (
      <div className={classnames(styles.container, classname)}>
        {hr && <hr />}
        <button className={styles.button} onClick={loadMore}>
          {icon && showMoreIndex === items?.length ? (
            <ArrowDropUpIcon />
          ) : (
            <ArrowDropDownIcon />
          )}
          <FormattedMessage
            id={showMoreIndex === items?.length ? textFewer : textMore}
          />
        </button>
        {hr && <hr />}
      </div>
    )
  }
  return null
}
