import {connect} from 'react-redux'

import {MyOffers} from './MyOffers'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {getEmployerPlacementConfirmedOffers} from 'Store/Actions'

const mapStateToProps = (state: RootState) => ({
  placementConfirmedOffers:
    state.employer.data.allRequests.placementConfirmedOffers,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getEmployerPlacementConfirmedOffers: () =>
    dispatch(getEmployerPlacementConfirmedOffers()),
})

export const MyOffersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyOffers)
