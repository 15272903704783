import {connect} from 'react-redux'

import {MyProjectCandidates} from './MyProjectCandidates'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {
  getMyProject,
  getMyProjectCandidates,
  loadMoreProjectCandidates,
  getOccupations,
  getSearchResultsSavedCandidates,
  sendContactRequestsBulk,
  setCandidateBasicData,
} from 'Store/Actions'
import {
  MyProjectCandidatesSearchParams,
  SearchMyProjectsParams,
} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  project: state.myProjects.project,
  candidates: state.myProjects.projectCandidates,
  candidateBasicData: state.widgetBar.data.candidateBasicData,
  occupations: state.taxonomies.occupations,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getMyProject: (id: string) => dispatch(getMyProject(id)),
  getMyProjectCandidates: (params: MyProjectCandidatesSearchParams) =>
    dispatch(getMyProjectCandidates(params)),
  loadMore: (id: string, params: SearchMyProjectsParams | null | undefined) =>
    dispatch(loadMoreProjectCandidates(id, params)),
  getOccupations: () => dispatch(getOccupations()),
  getSavedCandidatesSearch: (
    searchParams: SearchMyProjectsParams,
    projectId: string | undefined
  ) => dispatch(getSearchResultsSavedCandidates(searchParams, projectId)),
  sendContactRequestsBulk: (ids: string[]) =>
    dispatch(sendContactRequestsBulk(ids)),
  setCandidateBasicData: (candidate: any) =>
    dispatch(setCandidateBasicData(candidate)),
})

export const MyProjectCandidatesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyProjectCandidates)
