import Modal from 'Components/Modal'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import {FC} from 'react'
import styles from './DeleteModal.module.scss'
import {useIntl} from 'react-intl'
import {Warning, Report} from '@mui/icons-material'

interface DeleteModal {
  text: string
  isOpen: boolean
  setOpen: (e: boolean) => void
  deleteAction: (id: string) => void
  id: string
}

export const DeleteModal: FC<DeleteModal> = ({
  isOpen,
  text,
  setOpen,
  deleteAction,
  id,
}) => {
  const {formatMessage} = useIntl()
  return (
    <div>
      <Modal
        className={styles.modal}
        icon={Warning}
        isOpen={isOpen}
        title={formatMessage({id: 'warning'})}
        theme={'error'}
        onAfterClose={() => {
          setOpen(false)
        }}
        Footer={
          <SubmitFooter
            submitText={formatMessage({id: 'delete'})}
            onSubmit={() => {
              deleteAction(id)
            }}
            onCancel={() => {
              setOpen(false)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <div className={styles.deleteModal}>
          <Report className={styles.icon} />
          <p className={styles.text}>{text}</p>
        </div>
      </Modal>
    </div>
  )
}
