import * as Yup from 'yup'
import {MediumTitle, LongDescription, LongTitle} from 'Validations'

const Validation = Yup.object().shape({
  name: MediumTitle.required('Company name is required'),
  description: LongDescription.nullable().required(
    'Company description is required'
  ),
  industry: Yup.object().nullable().required('Industry is required'),
  town: Yup.object().nullable().required('City is required'),
  country: Yup.object().nullable().required('Country is required'),
  websites: Yup.array().of(LongTitle.nullable()),
})

export default Validation
