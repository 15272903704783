import {generalAssessmentsTranslation} from './GeneralAssessmentsTranslation/sl'
import {modalsAssessmentsTranslation} from './ModalsAssessmentsTranslation/sl'
import {cognitiveAssessmentsTranslation} from './CognitiveAssessmentsTranslation/sl'
import {psychometricAssessmentsTranslation} from './PsychometicAssessmentsTranslation/sl'

export const assessments = {
  ...generalAssessmentsTranslation,
  ...modalsAssessmentsTranslation,
  ...cognitiveAssessmentsTranslation,
  ...psychometricAssessmentsTranslation,
}
