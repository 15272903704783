export enum MessageCenterTranslations {
  heading = 'messageCenter.heading',
  labelCandidates = 'messageCenter.labelCandidates',
  labelEmployers = 'messageCenter.labelEmployers',
  tabLabelTSA = 'messageCenter.tabLabelTSA',
  all_messages = 'messageCenter.messageType_all_messages',
  inbox = 'messageCenter.messageType_inbox',
  archived = 'messageCenter.messageType_archived',
  unread = 'messageCenter.messageType_unread',
  searchBtn = 'messageCenter.searchBtn',
  searchConversationsLabel = 'messageCenter.searchConversationsLabel',
  resetSearchBtn = 'messageCenter.resetSearchBtn',
  manageConversations = 'messageCenter.manageConversations',
  setAwayMessage = 'messageCenter.setAwayMessage',
  selected = 'messageCenter.selected',
  clearAll = 'messageCenter.clearAll',
  selectAll = 'messageCenter.selectAll',
  heading_all_messages = 'messageCenter.heading_all_messages',
  heading_archived = 'messageCenter.heading_archived',
  heading_unread = 'messageCenter.heading_unread',
  today = 'messageCenter.today',
  newMessage = 'messageCenter.newMessage',
  typeAname = 'messageCenter.typeAname',
  writeAmessage = 'messageCenter.writeAmessage',
  send = 'messageCenter.send',
  delete = 'messageCenter.delete',
  markAsUnread = 'messageCenter.markAsUnread',
  archive = 'messageCenter.archive',
  you = 'messageCenter.you',
  deleteModalTitle = 'messageCenter.deleteModalTitle',
  deleteModalContent = 'messageCenter.deleteModalContent',
  closeManaging = 'messageCenter.closeManaging',
  deleteConversation = 'messageCenter.deleteConversation',
  employerPlaceholder = 'messageCenter.employerPlaceholder',
  candidatePlaceholder = 'messageCenter.candidatePlaceholder',
}
