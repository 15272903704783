  const orderOfFacets = {
    'regionFacets':1,
    'currentJobCategoryFacets':2,
    'occupationFacets':3,
    'educationLevelFacets':4,
    'totalWorkExperienceFacets':5,
    'skillFacets':6,
    'skillLevelFacets':7,
    'languageTaxonomyFacets':8,
    'languageLevelFacets':9,
    'drivingLicenseFacets':10,
}
  
  export const Sort = (a: string, b: string) => {
    if(!orderOfFacets[a]){
     return -1
    } 
    else if(!orderOfFacets[b]){
      return 1
    }
   return orderOfFacets[a] - orderOfFacets[b]
  }

