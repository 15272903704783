export const employerInfoLandingPage = {
  'employerInfoLandingPage.title': 'YOU KNOW BEST, DEAR EMPLOYERS, THAT',
  'employerInfoLandingPage.yellowTitleTxt': 'THERE IS A SHORTAGE',
  'employerInfoLandingPage.titleLastPart':
    ' OF GOOD EMPLOYEES AND THE PATH TO FINDING THEM IS NOT AN EASY ONE',
  'employerInfoLandingPage.paragraphFirstPart':
    'You know best, dear employers, that there is a shortage of good employees and the path to finding them is not an easy one.',
  'employerInfoLandingPage.paragraphSecondPart':
    'Market dynamics indicate an increasing shortage of talent and hiring the best experts from different fields is one of the main challenges that human resources departments and managers have to face today.',
  'employerInfoLandingPage.paragraphThirdPart':
    'Are you still relying on traditional methods of finding your staff?',
  'employerInfoLandingPage.paragraphFourthPart':
    'And just how successful are you in doing so? Probably not very - you wouldn`t be here otherwise',
  'employerInfoLandingPage.paragraphFifthPart':
    'It is time to forge a new path in the field of recruitment.',
  'employerInfoLandingPage.subtitle': 'NEW RULES OF THE GAME',
  'employerInfoLandingPage.subtitleParagraph':
    'Preferenca is a platform connecting talent and companies by having companies compete for candidates and not the other way around. This provides a completely new experience for the best candidates on the market while also enabling a quick and efficient employment process for your company.',
  'employerInfoLandingPage.subtitleParagraphSecond':
    'On the portal, previously screened candidates can introduce themselves through their profiles, where they state their experience, knowledge, and requirenments. This gives you access to a list of suitable candidates with whom you can conduct interviews and communicate directly with them.',
  'employerInfoLandingPage.titleSecond': 'SKIP STRAIGHT TO THE MOST QUALIFIED ',
  'employerInfoLandingPage.yellowTitleSecond': 'EXPERTS',
  'employerInfoLandingPage.subTitleSecond': 'Why should you use Preferenca?',
  'employerInfoLandingPage.yellowTxtFirst': 'Better',
  'employerInfoLandingPage.subParagraphFirst': 'candidate responsiveness',
  'employerInfoLandingPage.yellowTxtSecond': 'Efficient use of time',
  'employerInfoLandingPage.subParagraphSecond':
    'when searching and choosing candidates',
  'employerInfoLandingPage.yellowTxtThird': 'qualified talent',
  'employerInfoLandingPage.subParagraphThird':
    'The best solution for companies looking for the most',
  'employerInfoLandingPage.yellowTxtFourth': ' previously screened',
  'employerInfoLandingPage.subParagraphFourthFirstPart': 'Candidates are',
  'employerInfoLandingPage.subParagraphFourthSecondPart':
    ' through various tests and evaluated by an HR expert',
  'employerInfoLandingPage.yellowTxtFifth': 'can express their wishes',
  'employerInfoLandingPage.subParagraphFifthFirstPart': 'Candidates',
  'employerInfoLandingPage.subParagraphFifthSecondPart':
    'and expectations (including salary expectations) in advance',
  'employerInfoLandingPage. yellowTxtSixth': 'Shorter employment processes',
  'employerInfoLandingPage.yellowTxtSeventh':
    'The entire process takes place online',
  'employerInfoLandingPage.paragraphsWrapFirst':
    'Imagine just how much quicker the employment process could be if your ads would only be answered by the most qualified and thoroughly screened candidates who are ready for an interview with you?',
  'employerInfoLandingPage.paragraphsWrapSecond':
    'As companies, you now have the possibility to only choose between candidates whose requirements and interests match yours. This reduces the probability of having a candidate leave the process due to a simple preference mismatch. In addition, all candidates who have been accepted on the portal are open to new employment opportunities, meaning that they are much more responsive throughout the employment process.',
  'employerInfoLandingPage.yellowButton': 'FREE CAREER SUPPORT',
}
