import {
  BaseTaxonomyType,
  EmployerRequestsSearchParamsType,
  IncomingRequestResponseType,
  IncomingRequestsCompaniesType,
  IncomingRequestType,
} from 'Interfaces'

export const packIncomingRequests = (
  items: IncomingRequestResponseType[]
): IncomingRequestType[] =>
  items.map((item) => {
    const {status, ...other} = item.request

    return {type: item.type, ...other, status: status.toLowerCase()}
  })

export const packInterviewRequests = (
  items: IncomingRequestType[]
): IncomingRequestType[] =>
  items.map((item) => {
    const {status, ...other} = item

    return {...other, status: status.toLowerCase()}
  })

export const packInterviewRequestsParams = (
  params: EmployerRequestsSearchParamsType
): EmployerRequestsSearchParamsType => {
  const {statuses, ...otherParams} = params as EmployerRequestsSearchParamsType

  if (statuses)
    return {
      statuses: statuses.map((status) => (status as string).toUpperCase()),
      ...otherParams,
    }
  else return params
}

export const packIncomingRequestCompanies = (
  companies: IncomingRequestsCompaniesType[]
) =>
  companies.map((c) => ({
    id: c.id,
    translation: c.name,
  })) as BaseTaxonomyType[]
