export const companyEmploymentInformation = {
  'companyEmploymentInformation.employmentInformationTitle':
    'EMPLOYMENT INFORMATION',

  'companyEmploymentInformation.modalEmploymentInformationTitle':
    'Employment Information',
  'companyEmploymentInformation.whatDoWeExpectTitle': 'What do we expect?',
  'companyEmploymentInformation.whatDoWeOfferTitle': 'What do we offer?',
  'companyEmploymentInformation.customEmploymentInformationTitle':
    'Custom employment information',

  'companyEmploymentInformation.modalWhatDoWeExpectTitle': 'WHAT DO WE EXPECT?',
  'companyEmploymentInformation.modalWhatDoWeExpectPlaceholderText':
    'Example: We wish to hire someone who is ...',

  'companyEmploymentInformation.modalWhatDoWeOfferTitle': 'WHAT DO WE OFFER?',
  'companyEmploymentInformation.modalWhatDoWeOfferPlaceholderText':
    'Example: We have free coffee and personal growth ...',

  'companyEmploymentInformation.modalCustomEmploymentInformationTitle':
    'CUSTOM EMPLOYMENT INFORMATION',
  'companyEmploymentInformation.modalCustomEmploymentInformationPlaceholderText':
    'Enter custom employment information',

  'companyEmploymentInformation.modalAddText': 'Add',
  'companyEmploymentInformation.modalEditText': 'Edit',
}
