import * as type from 'Store/types'
import {ActionType, ConsentsStateType, ConsentType, ErrorType} from 'Interfaces'

const initialState = {
  data: {
    consents: [],
  },
  loading: false,
  error: undefined,
}

const ConsentsReducer = (
  state: ConsentsStateType = initialState,
  action: ActionType<ErrorType | ConsentType[]>
): ConsentsStateType => {
  switch (action.type) {
    case type.consents.getConsents.requested:
    case type.consents.changeConsentStatus.requested:
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case type.consents.changeConsentStatus.succeeded:
    case type.consents.getConsents.succeeded:
      return {
        data: {...state.data, consents: action.payload as ConsentType[]},
        loading: false,
        error: undefined,
      }
    case type.consents.getConsents.failed:
    case type.consents.changeConsentStatus.failed:
      return {
        ...state,
        loading: false,
        error: action.payload as ErrorType,
      }
    default:
      return state
  }
}

export default ConsentsReducer
