import {FC, useRef, useEffect} from 'react'
import {SidebarSectionTitle} from 'Components/CandidateSearch/SidebarSectionTitle'
import TextArea from 'Components/Inputs/TextArea'
import {useFormik} from 'formik'
import {Button} from 'Components/Button'
import styles from './Comments.module.scss'
import {Candidate, IncomingRequestSeekerType} from 'Interfaces'
import Validation from './Validation'
import {SaveCommentType} from 'Interfaces'
import {CandidateSearchTranslations as cs} from 'Services/I18n/Constants'
import {useTranslation} from 'Hooks'

interface CommentsProps {
  saveCandidateComment: (body: SaveCommentType) => void
  candidateBasicData: Candidate | IncomingRequestSeekerType | null
  sidebarClosed?: boolean
  page?: 'dashboard' | 'search' | 'myProject'
  focusTrigger?: number
}

export const Comments: FC<CommentsProps> = ({
  saveCandidateComment,
  candidateBasicData,
  sidebarClosed,
  page,
  focusTrigger,
}) => {
  const divRef = useRef<HTMLDivElement>(null)
  const FormikValues = {body: ''}
  const translation = useTranslation()
  const focusComment = () => {
    divRef.current?.querySelector('textarea')?.focus()
  }

  const formik = useFormik({
    initialValues: FormikValues,
    validationSchema: Validation,
    onSubmit: (values) => {
      saveCandidateComment({
        ...values,
        seekerUserId: candidateBasicData!.userId || candidateBasicData?.id,
      })
      formik.resetForm()
    },
  })

  useEffect(() => {
    if (focusTrigger) focusComment()
  }, [focusTrigger, formik.isSubmitting])

  useEffect(() => {
    if (sidebarClosed === false && page !== 'dashboard') {
      focusComment()
    }
  }, [sidebarClosed])

  return (
    <div className={styles.commentsContainer}>
      <SidebarSectionTitle title={translation[cs.commentsTitle]} />
      <div ref={divRef}>
        <TextArea
          name="body"
          placeholderText={translation[cs.typeLabel]}
          className={styles.textField}
          value={formik.values.body}
          onChange={(e) => formik.setFieldValue('body', e.target.value)}
          error={formik.errors.body}
          theme="white"
        />
      </div>
      {formik.errors.body && (
        <>
          <br />
          <br />
        </>
      )}
      <Button
        text={translation[cs.submitButton]}
        className={styles.submitButton}
        onClick={formik.handleSubmit}
      />
    </div>
  )
}
