import Modal from 'Components/Modal'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import styles from './ModalOfferDetailsEmp.module.scss'
import classnames from 'classnames'
import {IncomingRequestType, RjbProfileStatus} from 'Interfaces'
import {FC} from 'react'
import {DateTime} from 'luxon'
import {FormattedMessage} from 'react-intl'
import {
  EmployerDashboardTranslations as edt,
  CandidateDashboardTranslations as cdt,
} from 'Services/I18n/Constants'

import {
  InteviewRequestStatus,
  employerRequestStatusTranslationMap,
  CombinedRequestStatues,
} from '../../Constants'
import {
  HistoryEdu,
  ConnectWithoutContact,
  Notifications,
  AttachFile,
  Update,
  HourglassBottom,
  ViewInAr,
  DescriptionOutlined,
} from '@mui/icons-material'
import {formatDateLongMonth} from 'Utils/Date'

const ExpirationValue: FC<{date: string}> = ({date}) => {
  const now = DateTime.now()
  // TODO: Set locale:
  // const expiration = DateTime.fromISO(date).setLocale()
  const expiration = DateTime.fromISO(date)
  const diff = Math.floor(expiration.diff(now, ['days']).days)
  let dayOfTheWeek = expiration.toFormat('cccc')

  if (diff > 7 || expiration < now) {
    return (
      <p className={styles.expirationValueNormal}>
        <FormattedMessage id={dayOfTheWeek} />
        <span className={styles.date}>
          {expiration.toFormat(' (dd LLLL yyyy)')}
        </span>
      </p>
    )
  }
  if (now.day === expiration.day) {
    return (
      <div className={styles.expirationValueContainer}>
        <p className={styles.expirationValueTextRed}>
          <FormattedMessage id={edt.expiresToday} />
        </p>
      </div>
    )
  }
  return (
    <div className={styles.expirationValueContainer}>
      <p className={styles.expirationValueTextRed}>
        <FormattedMessage id={cdt.in} />
        {` ${diff} `}
        {diff === 1 ? (
          <FormattedMessage id={cdt.day} />
        ) : (
          <FormattedMessage id={cdt.days} />
        )}
      </p>
      <p className={styles.expirationValueText}>
        {expiration.toFormat('(dd LLLL yyyy)')}
      </p>
    </div>
  )
}
interface ModalOfferDetailsProps {
  request: IncomingRequestType
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}

export const ModalOfferDetailsEmp: FC<ModalOfferDetailsProps> = ({
  request,
  isOpen,
  setIsOpen,
}) => {
  const noLongerActiveCandidate =
    !request.seeker.hasFinishedPlacementWithCurrentEmployer &&
    request.seeker.rjbProfileStatus === RjbProfileStatus.CLOSED

  return (
    <>
      <Modal
        className={classnames(styles.modalDetails, {
          [styles.modalDetailsWithoutFooter]:
            request.status !== CombinedRequestStatues.ACCEPTED,
        })}
        icon={DescriptionOutlined}
        isOpen={isOpen}
        title={cdt.offerDetails}
        onAfterClose={() => {
          setIsOpen(false)
        }}
        shouldReturnFocusAfterClose={false}
        Footer={
          <>
            {request.status === CombinedRequestStatues.ACCEPTED && (
              <SubmitFooter
                onSubmit={() => setIsOpen(false)}
                onCancel={() => setIsOpen(false)}
                theme="modalProfileSubmit"
                submitText={cdt.confirmPlacement}
                disabled={noLongerActiveCandidate}
              />
            )}
          </>
        }
      >
        <div className={styles.details}>
          <div className={styles.titleContainer}>
            {request.seeker.profilePicture ? (
              <img
                className={styles.avatar}
                src={
                  request.seeker.profilePicture &&
                  request.seeker.profilePicture.url
                }
                alt={`${request.seeker.firstName} ${request.seeker.lastName}`}
                onError={(event) =>
                  ((event.target as HTMLInputElement).style.display = 'none')
                }
              />
            ) : (
              <div className={styles.avatarChar}>
                <span>
                  {request?.seeker?.firstName &&
                    request.seeker.firstName.charAt(0)}
                  {request?.seeker?.lastName &&
                    request.seeker.lastName.charAt(0)}
                </span>
              </div>
            )}
            <p className={styles.title}>
              {request.seeker?.firstName} {request.seeker?.lastName}
            </p>
          </div>

          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <ViewInAr className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={edt.position} />
              </p>
            </div>
            <div className={styles.itemRight}>
              <p className={styles.itemRightText}>
                {request.seeker.publicOccupation?.translation}
              </p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <ConnectWithoutContact className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.requestType} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              <p className={styles.offerText}>
                <FormattedMessage id={edt.offer} />
              </p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <Notifications className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.requestStatus} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              <p
                className={classnames(styles.pending, {
                  [styles.rejected]:
                    request.status === InteviewRequestStatus.REJECTED ||
                    request.status === InteviewRequestStatus.CANCELLED,
                  [styles.accepted]:
                    request.status === InteviewRequestStatus.ACCEPTED,
                  [styles.expired]:
                    request.status === InteviewRequestStatus.EXPIRED,
                  [styles.completed]:
                    request.status === InteviewRequestStatus.COMPLETED,
                  [styles.newTimeProposal]:
                    request.status === InteviewRequestStatus.NEW_TIME_PROPOSED,
                })}
              >
                <FormattedMessage
                  id={employerRequestStatusTranslationMap[request.status]}
                />
              </p>
            </div>
          </div>

          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <Update className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.dateOfRequestSent} />:
              </p>
            </div>

            <div className={styles.itemRight}>
              <p className={styles.itemRightText}>
                {request.sentDate && formatDateLongMonth(request.sentDate)}
              </p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <HourglassBottom className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.expirationDate} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              {request.expirationDate && (
                <ExpirationValue date={request.expirationDate} />
              )}
            </div>
          </div>

          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <HistoryEdu className={styles.icon} />
              <p className={styles.dateTimeLeft}>
                <p>
                  <FormattedMessage id={cdt.content} />:
                </p>
              </p>
            </div>
            <div className={styles.itemRight}>
              <div>
                <p className={styles.dateTime}>
                  {request.sentDate &&
                    DateTime.fromISO(request.sentDate).toFormat('d.L.yyyy')}
                  <p className={styles.line}>&nbsp;|&nbsp;</p>
                  {`${
                    request.sentDate &&
                    DateTime.fromISO(request.sentDate).toFormat('HH:mm')
                  }`}
                </p>
                <p className={styles.paragraphMessage}>{request.content}</p>
              </div>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <AttachFile className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.attachment} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              <a
                className={classnames(
                  styles.itemRightText,
                  styles.itemRightTextBlue
                )}
                href={request.attachment?.url}
                target="_blank"
              >
                {request.attachment?.originalName}
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
