export const employerFAQ = {
  'employerFAQ.titleYellow': 'FREE',
  'employerFAQ.mainTitle': 'career support',
  'employerFAQ.titleFirst': 'How to select candidates with confidence?',
  'employerFAQ.titleSecond':
    'How to present yourself and attract the right talent?',
  'employerFAQ.titleThird':
    'Which candidates would be the best match for you and your needs?',
  'employerFAQ.paragraphFirst':
    'With Preferenca, the rules of the recruitment game have changed. Find your ideal fit among available vetted candidates and make them an offer they cannot refuse. It`s important to find candidates that meet your requirements in terms of education, work experience, technical knowledge, and skill. You can obtain the key information on your very first search, where you will be able to view an anonymised list and select only the best candidates for your company.',
  'employerFAQ.paragraphSecond':
    'Employer branding is becoming increasingly important, as it represents the way you market your company to job seekers. You can do this by highlighting the unique benefits and presenting the cultural environment of your business.',
  'employerFAQ.paragraphThird':
    'There`s a lot of advice on how to assess soft skills in the process of selecting a suitable candidate. We have compiled for you a list of the most important qualities to look for in a candidate:',

  'employerFAQ.subTitleFirst': 'Teamwork',
  'employerFAQ.subTitleSecond': 'Willingness to learn',
  'employerFAQ.subTitleThird': 'Communication',
  'employerFAQ.subTitleFourth': 'Self-motivation',
  'employerFAQ.subTitleFifth': 'Matching the company culture',
  'employerFAQ.subParagraphFirst':
    'Most jobs require a certain level of collaboration with other people; even work that often seems rather solitary, like programming, often involves cooperation. It is therefore important to focus on candidates who can work well with others.',
  'employerFAQ.subParagraphSecond':
    'Lifelong learning is essential these days; technology and knowledge is advancing day by day, and organisations and systems are constantly changing. Willingness and, of course, the ability to learn are very important qualities for an employee to have.',
  'employerFAQ.subParagraphThird':
    'This does not mean that every employee needs to be proficient with words, or should have mastered the skills of public speaking, but it is important that employees know how to convey their message, while at the same time understanding others and knowing how to ask the right questions when they don`t understand. Difficulties in communication can have a profound impact on work performance and the working environment.',
  'employerFAQ.subParagraphFourth':
    'When employees do what makes them happy, they do their work well, regardless of the reward. These employees will usually take the initiative, are independent at work, and take responsibility for the tasks performed and persevere in the face of obstacles.',
  'employerFAQ.subParagraphFifth':
    'It is important that the employee understands and accepts the work environment and the company`s way of working – from workplace arrangements to dress code. The culture may shift, but above all it`s important that employees know the principles and values of the company.',
  'employerFAQ.subParagraphLast':
    'We have highlighted just some of the most important capabilities and qualities. Each company has its own requirements and values. Therefore, Preference provides insight into the competencies and general cognitive abilities of each candidate. These results are obtained using standardised and normalised psychometric questionnaires and cognitive tests, which offer you, the employer, valuable information about potential employees, information you can`t pick up from CVs or at interviews. The use of psychological tests is extremely important in the selection and development of potential future employees, as it helps ensure the specific development of an individual`s potential within the work process.',
}
