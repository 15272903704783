import {connect} from 'react-redux'

import {Benefits} from './Benefits'
import {AppDispatch} from 'Store'
import {
  saveStepBenefitsEducation,
  saveStepPartialBenefitsEducation,
  getAdditionalBenefits,
  getEducationLevels,
  getFieldsOfStudy,
  getSalaries,
  setOnboardingStep,
} from 'Store/Actions'
import {RootState} from 'Store/Reducers'
import {
  SaveStepBenefitsEducationType,
  SavePartialStepBenefitsEducationType,
} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  onboarding: state.onboarding,
  taxonomies: state.taxonomies,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  saveStepBenefitsEducation: (value: SaveStepBenefitsEducationType) =>
    dispatch(saveStepBenefitsEducation(value)),
  saveStepPartialBenefitsEducation: (
    values: SavePartialStepBenefitsEducationType
  ) => dispatch(saveStepPartialBenefitsEducation(values)),
  getTaxonomies: () => {
    dispatch(getAdditionalBenefits())
    dispatch(getEducationLevels())
    dispatch(getFieldsOfStudy())
    dispatch(getSalaries())
  },
  setOnboardingStep: (n: number) => dispatch(setOnboardingStep(n)),
})

export const BenefitsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Benefits)
