import {MessageConnector} from './MessageConnector'
import {startMessageChannel} from 'Store/Actions/MessageCenterActions'
import {AppDispatch} from './../../Store/index'
import {connect} from 'react-redux'

import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  authenticatedUserId: state?.auth?.data?.id,
  socket: state?.messageCenter.socket,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  startMessageChannel: () => dispatch(startMessageChannel()),
})

export const MessageConnectorContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageConnector)
