import {ApproveRejectModalRequestType} from 'Interfaces'
import {connect} from 'react-redux'

import {AppDispatch} from 'Store'
import {approveContactRequest} from 'Store/Actions'
import {ModalContactRequest} from './ModalContactRequest'

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  approveContactRequest: (payload: ApproveRejectModalRequestType) =>
    dispatch(approveContactRequest(payload)),
})

export const ModalContactRequestContainer = connect(
  null,
  mapDispatchToProps
)(ModalContactRequest)
