import {FC, useState} from 'react'

import {SidebarSectionTitle} from 'Components/CandidateSearch/SidebarSectionTitle'
import {
  ConversationMessageType,
  LoadMoreMessagesType,
  OpenedConversationType,
} from 'Interfaces'
import InfiniteScroll from 'react-infinite-scroll-component'
import {CircularProgress} from '@mui/material'
import {ContentItem} from '../ContentItem'

import styles from './MessagesList.module.scss'
import {CandidateSearchTranslations as cs} from 'Services/I18n/Constants'
import {useTranslation} from 'Hooks'
import {FormattedMessage} from 'react-intl'
interface ItemsProps {
  items?: ConversationMessageType[]
  loadMoreMessagesUrl: string
  loadMoreMessages: (data: LoadMoreMessagesType) => void
  openedConversation?: OpenedConversationType
}

export const MessagesList: FC<ItemsProps> = ({
  items,
  loadMoreMessagesUrl,
  loadMoreMessages,
  openedConversation,
}) => {
  const [itemsLength, setItemsLength] = useState(5)
  const translation = useTranslation()
  const onLoadMore = () => {
    setItemsLength((prevState) => prevState + 5)
    loadMoreMessages({
      url: loadMoreMessagesUrl,
      conversationId: openedConversation
        ? openedConversation.conversation.id
        : '',
    })
  }
  return (
    <div className={styles.messages}>
      <div className={styles.sidebarTitleWrapper}>
        <SidebarSectionTitle title={translation[cs.messagesTitle]} />
      </div>

      {items && items.length > 0 ? (
        <div id="scrollableDiv1" className={styles.messagesContainer}>
          <InfiniteScroll
            dataLength={itemsLength}
            next={() => onLoadMore()}
            hasMore={true}
            loader={
              <div className={styles.progressSpinner}>
                <CircularProgress />
              </div>
            }
            scrollableTarget="scrollableDiv1"
          >
            {items?.map((item, index) => {
              return (
                <div
                  key={`${item?.dateSent}-${index}`}
                  className={styles.contentItemContainer}
                >
                  <ContentItem {...item} />
                </div>
              )
            })}
          </InfiniteScroll>
        </div>
      ) : (
        <h3 className={styles.titleContainer}>
          <FormattedMessage id={cs.messageLabel} />
        </h3>
      )}
    </div>
  )
}
