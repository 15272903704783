import {ChangeEvent, FC, useState} from 'react'
import classnames from 'classnames'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'

import styles from './Dropdown.module.scss'
import {BaseTaxonomyType} from 'Interfaces'
import TextError from 'Components/Error/TextError'
import {FormattedMessage} from 'react-intl'

interface Props {
  name: string
  value?: BaseTaxonomyType
  items: BaseTaxonomyType[]
  setValue: (name: string, value?: BaseTaxonomyType) => void
  error?: string
  label?: string
  theme?: 'grey' | 'white' | 'modal'
  emptyValueLabel?: string
  setTouched?: (v: boolean) => void
  className?: string
}

export const Dropdown: FC<Props> = ({
  name,
  items,
  value,
  setValue,
  error,
  label,
  theme = 'grey',
  emptyValueLabel = 'Select from list',
  setTouched = () => {},
  className,
}) => {
  const [open, setOpen] = useState(false)

  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(
      name,
      items.find((o) => o.id === e.currentTarget.value)
    )
    setTouched(true)
  }

  return (
    <div
      className={classnames(styles.dropdownContainer, className, styles[theme])}
      tabIndex={0}
      onBlur={() => {
        setOpen(false)
      }}
    >
      <div
        onClick={() => {
          setOpen(!open)
        }}
        className={classnames(styles.dropdownBTN, {
          [styles.errorBorder]: error,
        })}
      >
        <div>
          {label && (
            <p
              className={classnames(styles.dropdownLabel, {
                [styles.error]: error,
              })}
            >
              {label}
            </p>
          )}
          {!value?.translation ? (
            <p className={styles.dropdown}>
              <FormattedMessage id={emptyValueLabel} />
            </p>
          ) : (
            <p className={styles.dropdown}>{value?.translation}</p>
          )}
        </div>
        <ArrowDropDownIcon
          className={classnames(styles.arrow, {
            [styles.rotate]: open,
          })}
        />
      </div>
      <div
        className={classnames(styles.dropdownList, {
          [styles.hidden]: !open,
        })}
      >
        {items.map((item, index) => (
          <div className={styles.container} key={`${item.id}-${index}`}>
            <label className={styles.label}>
              <input
                onClick={() => {
                  setOpen(!open)
                }}
                onChange={handleChange}
                className={styles.hidden}
                type="radio"
                name={name}
                value={item.id}
              />
              <span className={styles.translation}>{item.translation}</span>
            </label>
          </div>
        ))}
      </div>
      {error && <TextError text={error} />}
    </div>
  )
}
