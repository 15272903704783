import * as type from 'Store/types'
import {ActionType, ErrorType} from 'Interfaces'
import {
  BlacklistDropdownItemType,
  BlacklistedCompanyItemType,
  BlacklistStateType,
} from 'Interfaces/BlacklistType'

const initialState = {
  data: undefined,
  dropdownItems: undefined,
  loading: false,
  error: undefined,
}

const BlacklistReducer = (
  state: BlacklistStateType = initialState,
  action: ActionType<
    | BlacklistedCompanyItemType[]
    | BlacklistedCompanyItemType
    | BlacklistDropdownItemType[]
    | string
    | ErrorType
  >
): BlacklistStateType => {
  switch (action.type) {
    case type.blacklist.get.requested:
      return {
        ...state,
        loading: true,
      }
    case type.blacklist.get.succeeded:
      return {
        ...state,
        data: action?.payload as BlacklistedCompanyItemType[],
        loading: false,
        error: undefined,
      }
    case type.blacklist.create.succeeded:
      return {
        ...state,
        data: [
          ...(state.data as BlacklistedCompanyItemType[]),
          action?.payload as BlacklistedCompanyItemType,
        ],
        loading: false,
        error: undefined,
      }
    case type.blacklist.delete.succeeded:
      let items = state.data as BlacklistedCompanyItemType[]
      items = items.filter((item) => item.id !== (action.payload as string))
      return {
        ...state,
        data: items,
        loading: false,
        error: undefined,
      }
    case type.blacklist.getDropdownItems.succeeded:
      return {
        ...state,
        dropdownItems: action?.payload as BlacklistDropdownItemType[],
      }
    case type.blacklist.get.failed:
    case type.blacklist.getDropdownItems.failed:
      return {
        ...state,
        loading: false,
        error: action.payload as ErrorType,
      }
    default:
      return state
  }
}

export default BlacklistReducer
