import {call, takeLatest, all, put} from 'redux-saga/effects'
import {push} from 'connected-react-router'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'

import API from 'Services/API'
import * as type from 'Store/types'
import {ContactDataType, ActionType} from 'Interfaces'

function* contactData(action: ActionType<ContactDataType>) {
  const {response, error} = yield call(API.post, `/user/contact-data`, {
    ...action.payload,
  })

  if (response) {
    yield put(push(getRoute(AppRoute.Profile)))
  } else {
    yield put({type: type.CONTACT_DATA_FAILED, payload: error})
  }
}

export default function* ContactDataSaga(): Generator {
  yield all([takeLatest(type.CONTACT_DATA, contactData)])
}
