export const companyBenefits = {
  'companyBenefits.benefitsTitle': 'Ugodnosti',

  'companyBenefits.friendlyWorkEnvironmentTitle': 'Prijazno delovno okolje',
  'companyBenefits.otherTitle': 'Drugo',

  'companyBenefits.modalTitle': 'Uredite ugodnosti',
  'companyBenefits.modalBenefitsTextTitle': 'UGODNOSTI',
  'companyBenefits.modalBenefitsTextPlaceholderText':
    'Primer: Vedno so na voljo sveža kava in priložnosti za osebno rast ...',

  'companyBenefits.modalCompanyBenefitsTitle': 'Ugodnosti podjetja',
  'companyBenefits.modalCompanyBenefitsText': 'Prijazno delovno okolje',
  'companyBenefits.modalCustomBenefitsTitle': 'DODATNE UGODNOSTI',
  'companyBenefits.modalCustomBenefitsPlaceholderText':
    'Vnesite dodatne ugodnosti',
}
