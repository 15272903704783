import MainHeader from 'Components/MainHeader'
import styles from './HelpSupport.module.scss'
import {
  SendOutlined,
  PermPhoneMsgOutlined,
  TravelExplore,
} from '@mui/icons-material'
import {Link} from 'react-router-dom'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import cuple from 'Assets/Images/cuple.jpeg'
import laptop from 'Assets/Images/laptop.jpeg'
import background from 'Assets/Images/background.jpg'
import {HomePageFooter} from 'Components/HomePage'
import {FormattedMessage} from 'react-intl'
import {SupportTranslations as sp} from 'Services/I18n/Constants'
import Preferenca from 'Assets/Images/preferenca-logo-white.svg'

export const HelpSupport = () => {
  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: getRoute(AppRoute.HomePageCandidate)},
    {name: 'Support', link: getRoute(AppRoute.HelpSupport)},
    {name: 'Home & Support'},
  ]

  return (
    <>
      <div className={styles.container}>
        <img className={styles.background} src={background} alt="logo" />{' '}
        <div className={styles.contentLayout}>
          <div className={styles.logoContainer}>
            <img className={styles.logoStyle} src={Preferenca} alt="Logo" />
          </div>
          <MainHeader
            breadcrumbsArray={breadcrumbsArray}
            title={sp.title}
            theme="white"
          />
          <div className={styles.options}>
            <Link
              to={getRoute(AppRoute.FrequentlyAskedQuestions)}
              className={styles.option}
            >
              <TravelExplore className={styles.optionIcon} />
              <p className={styles.optionTitle}>
                <FormattedMessage id={sp.ctaTitle} />
              </p>
              <p className={styles.optionText}>
                <FormattedMessage id={sp.ctaText} />
              </p>
            </Link>
            <Link to={getRoute(AppRoute.AboutUs)} className={styles.option}>
              <SendOutlined className={styles.optionIcon} />
              <p className={styles.optionTitle}>
                <FormattedMessage id={sp.ctaTitle2} />
              </p>
              <p className={styles.optionText}>
                <FormattedMessage id={sp.ctaText2} />
              </p>
            </Link>
            <Link to={getRoute(AppRoute.AboutUs)} className={styles.option}>
              <PermPhoneMsgOutlined className={styles.optionIcon} />
              <p className={styles.optionTitle}>
                <FormattedMessage id={sp.ctaTitle3} />
              </p>
              <p className={styles.optionText}>
                <a href="tel:+386 1 420 16 40">+386 1 420 16 40</a> (
                <FormattedMessage id={sp.ctaText3} />: 8.00 - 17:00)
              </p>
            </Link>
          </div>
          <div className={styles.questions}>
            <div className={styles.questionUp}>
              <h2 className={styles.questionsTitle}>
                <FormattedMessage id={sp.title2} />
              </h2>
            </div>
            <div className={styles.questionDown}>
              <div className={styles.questionCard}>
                <h3 className={styles.questionCardTitle}>
                  <FormattedMessage id={sp.cardTitle} />
                </h3>
                <div className={styles.questionCardLink}>
                  <Link to={getRoute(AppRoute.AboutUs)}>
                    <p>
                      <FormattedMessage id={sp.cardQuestion1} />
                    </p>
                  </Link>
                </div>
                <div className={styles.questionCardLink}>
                  <Link to={getRoute(AppRoute.AboutUs)}>
                    <p>
                      <FormattedMessage id={sp.cardQuestion3} />
                    </p>
                  </Link>
                </div>
                <div className={styles.questionCardLink}>
                  <Link to={getRoute(AppRoute.AboutUs)}>
                    <p>
                      <FormattedMessage id={sp.cardQuestion4} />
                    </p>
                  </Link>
                </div>
                <div className={styles.questionCardLinkAll}>
                  <Link to={getRoute(AppRoute.FrequentlyAskedQuestions)}>
                    <p>
                      <FormattedMessage id={sp.viewAll} />
                    </p>
                  </Link>
                </div>
              </div>
              <div className={styles.questionCard}>
                <h3 className={styles.questionCardTitle}>
                  <FormattedMessage id={sp.card2Title} />
                </h3>
                <div className={styles.questionCardLink}>
                  <Link to={getRoute(AppRoute.AboutUs)}>
                    <p>
                      <FormattedMessage id={sp.card2Question1} />
                    </p>
                  </Link>
                </div>
                <div className={styles.questionCardLink}>
                  <Link to={getRoute(AppRoute.AboutUs)}>
                    <p>
                      <FormattedMessage id={sp.card2Question2} />
                    </p>
                  </Link>
                </div>
                <div className={styles.questionCardLink}>
                  <Link to={getRoute(AppRoute.AboutUs)}>
                    <p>
                      <FormattedMessage id={sp.card2Question3} />
                    </p>
                  </Link>
                </div>
                <div className={styles.questionCardLinkAll}>
                  <Link to={getRoute(AppRoute.FrequentlyAskedQuestions)}>
                    <p>
                      <FormattedMessage id={sp.viewAll} />
                    </p>
                  </Link>
                  {/* link temporary, replace once page is available with the example for FAQ above */}
                </div>
              </div>
              <div className={styles.questionCard}>
                <h3 className={styles.questionCardTitle}>
                  <FormattedMessage id={sp.card3Title} />
                </h3>
                <div className={styles.questionCardLink}>
                  <Link to={getRoute(AppRoute.AboutUs)}>
                    <p>
                      <FormattedMessage id={sp.card3Question1} />
                    </p>
                  </Link>
                </div>
                <div className={styles.questionCardLink}>
                  <Link to={getRoute(AppRoute.AboutUs)}>
                    <p>
                      <FormattedMessage id={sp.card3Question2} />
                    </p>
                  </Link>
                </div>
                <div className={styles.questionCardLink}>
                  <Link to={getRoute(AppRoute.AboutUs)}>
                    <p>
                      <FormattedMessage id={sp.card3Question3} />
                    </p>
                  </Link>
                </div>
                <div className={styles.questionCardLinkAll}>
                  <Link to={getRoute(AppRoute.FrequentlyAskedQuestions)}>
                    <p>
                      <FormattedMessage id={sp.viewAll} />
                    </p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.promoted}>
            <div className={styles.promotedCard}>
              <img className={styles.image} src={cuple} alt="" />

              <h3 className={styles.promotedCardTitle}>
                <FormattedMessage id={sp.card1} />
              </h3>
              <Link
                className={styles.promotedCardBtn}
                to={getRoute(AppRoute.EmployerInfoLanding)}
              >
                <p>
                  <FormattedMessage id={sp.more} />
                </p>
              </Link>
            </div>

            <div className={styles.promotedCard}>
              <img className={styles.image} src={laptop} alt="" />

              <h3 className={styles.promotedCardTitle}>
                <FormattedMessage id={sp.card2} />
              </h3>
              <Link
                className={styles.promotedCardBtn}
                to={getRoute(AppRoute.EmployerFAQ)}
              >
                <p>
                  <FormattedMessage id={sp.more} />
                </p>
              </Link>
            </div>
          </div>
        </div>
        <HomePageFooter />
      </div>
    </>
  )
}
