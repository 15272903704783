import {ChangeEvent, FC, useCallback, useEffect, useState} from 'react'
import classnames from 'classnames'

import styles from './RadioGroup.module.scss'
import {BaseTaxonomyType} from 'Interfaces'
import {RadioIcon} from 'Components/Icons'
import {useWindowResize} from 'Hooks'
import TextError from 'Components/Error/TextError'

interface Props {
  name: string
  className?: string
  value?: string
  setValue: (name: string, value?: string) => void
  items: BaseTaxonomyType[]
  error?: string
  setTouched?: (v: boolean) => void
  minRows?: number
}

export const RadioGroup: FC<Props> = ({
  name,
  value,
  setValue,
  items,
  error,
  className,
  setTouched = () => {},
  minRows = 3,
}) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>): void => {
    setValue(name, e.currentTarget.value)
  }

  const handleColumns = useCallback(() => {
    if (window.innerWidth > 1023) {
      if (items.length > minRows) {
        setNumColumns(3)
      } else setNumColumns(1)
    } else if (window.innerWidth > 768) {
      if (items.length > minRows) {
        setNumColumns(2)
      } else setNumColumns(1)
    } else setNumColumns(1)
  }, [items])

  useWindowResize(handleColumns)
  useEffect(handleColumns, [items])

  const [numColumns, setNumColumns] = useState(1)
  const numRows = Math.ceil(items.length / numColumns)

  const style = {
    gridTemplateRows: `repeat(${numRows}, 1fr)`,
    gridTemplateColumns: `repeat(${numColumns}, 1fr)`,
  }

  const handleTouched = useCallback(() => {
    setTouched(true)
  }, [setTouched])

  return (
    <div className={styles.container}>
      <div
        className={classnames(styles.radioBtnGroup, className, {
          [styles.error]: error,
        })}
        style={style}
      >
        {items.map((item, index) => {
          return (
            <div className={styles.wrap} key={`${item.id}-${index}`}>
              <label className={styles.label}>
                <RadioIcon
                  selected={value === item.id}
                  className={classnames({[styles.errorIcon]: error})}
                />
                <input
                  className={styles.hidden}
                  type="radio"
                  name={name}
                  value={item.id}
                  onChange={handleChange}
                  onClick={handleTouched}
                />
                <div className={styles.translation}>{item.translation}</div>
              </label>
            </div>
          )
        })}
      </div>
      {error && <TextError text={error} />}
    </div>
  )
}
