import {connect} from 'react-redux'

import {PublicProfileBioSection} from './PublicProfileBioSection'
import {AppDispatch} from 'Store'
import {
  getJobSearchStatuses,
  getOccupations,
  updatePublicBioData,
} from 'Store/Actions'
import {RootState} from 'Store/Reducers'
import {PublicUserUpdateBioType} from 'Interfaces'
import {canChangeProfile} from 'Utils/Profile'

const mapStateToProps = (state: RootState) => ({
  user: state.seeker.data?.public,
  profile: state.seeker,
  canChange: canChangeProfile(state.auth.data?.rjbProfileStatus),
  taxonomies: state.taxonomies,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updatePublicBioData: (value: PublicUserUpdateBioType) =>
    dispatch(updatePublicBioData(value)),
  getTaxonomies: () => {
    dispatch(getJobSearchStatuses())
    dispatch(getOccupations())
  },
})

export const PublicProfileBioSectionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicProfileBioSection)
