export const companyEmploymentInformation = {
  'companyEmploymentInformation.employmentInformationTitle':
    'INFORMACIJE O ZAPOSLITVI',

  'companyEmploymentInformation.modalEmploymentInformationTitle':
    'Informacije o zaposlitvi',
  'companyEmploymentInformation.whatDoWeExpectTitle': 'Kaj pričakujemo?',
  'companyEmploymentInformation.whatDoWeOfferTitle': 'Kaj nudimo?',
  'companyEmploymentInformation.customEmploymentInformationTitle':
    'Dodatne informacije o zaposlitvi',

  'companyEmploymentInformation.modalWhatDoWeExpectTitle': 'KAJ PRIČAKUJEMO?',
  'companyEmploymentInformation.modalWhatDoWeExpectPlaceholderText':
    'Primer: Želimo si zaposliti nekoga, ki je ...',

  'companyEmploymentInformation.modalWhatDoWeOfferTitle': 'KAJ NUDIMO?',
  'companyEmploymentInformation.modalWhatDoWeOfferPlaceholderText':
    'Primer: Vedno so na voljo sveža kava in priložnosti za osebno rast ...',

  'companyEmploymentInformation.modalCustomEmploymentInformationTitle':
    'DODATNE INFORMACIJE O ZAPOSLITVI',
  'companyEmploymentInformation.modalCustomEmploymentInformationPlaceholderText':
    'Vnesite dodatne informacije o zaposlitvi',

  'companyEmploymentInformation.modalAddText': 'Dodajte',
  'companyEmploymentInformation.modalEditText': 'Urejanje',
}
