import {RadioButtonUnchecked, RadioButtonChecked} from '@mui/icons-material'
import classnames from 'classnames'
import styles from './RadioIcon.module.scss'

interface RadioIconProps {
  selected: boolean
  className?: string
}

export const RadioIcon = ({
  selected,
  className,
}: RadioIconProps): JSX.Element => {
  return selected ? (
    <div className={classnames(styles.radioIcon, styles.selected)}>
      <div className={styles.background} />
      <RadioButtonChecked className={classnames(styles.selected, className)} />
    </div>
  ) : (
    <RadioButtonUnchecked className={classnames(styles.radioIcon, className)} />
  )
}
