import {FC} from 'react'
import classNames from 'classnames'
import {
  AppsOutlined,
  EuroSymbolOutlined,
  ConnectWithoutContactOutlined,
  DataObjectOutlined,
  CastForEducationOutlined,
} from '@mui/icons-material'
import styles from './ApplicationsMenu.module.scss'

interface ApplicationsMenuProps {
  isMenuOpen: boolean
  setIsMenuOpen: Function
  className?: string
}

export const ApplicationsMenu: FC<ApplicationsMenuProps> = ({
  isMenuOpen,
  setIsMenuOpen,
  className,
}) => {
  const closeSidebar = () => {
    setIsMenuOpen((old: boolean) => !old)
  }

  return (
    <div
      className={classNames(styles.wrap, className, {
        [styles.active]: isMenuOpen,
      })}
      onBlur={(event) => {
        if (!event.currentTarget.contains(event.relatedTarget)) {
          setIsMenuOpen(false)
        }
      }}
      tabIndex={0}
    >
      <AppsOutlined className={styles.trigger} onClick={closeSidebar} />
      <div
        className={classNames(styles.applicationsMenu, [
          {[styles.menuOpen]: isMenuOpen},
        ])}
        onClick={(e) => e.stopPropagation}
      >
        <div className={styles.item}>
          <a href="https://placa.si/" target={'_blank'}>
            <div className={styles.iconWrap}>
              <EuroSymbolOutlined />
            </div>
            <div className={styles.text}>Plača.si</div>
          </a>
        </div>
        <div className={styles.item}>
          <a href="https://kariernisejem.com/" target={'_blank'}>
            <div className={styles.iconWrap}>
              <ConnectWithoutContactOutlined />
            </div>
            <div className={styles.text}>Karierni sejmi</div>
          </a>
        </div>
        <div className={styles.item}>
          <a href="https://hekaton.mojedelo.com/" target={'_blank'}>
            <div className={styles.iconWrap}>
              <DataObjectOutlined />
            </div>
            <div className={styles.text}>Hekaton</div>
          </a>
        </div>
        <div className={styles.item}>
          <a
            href="https://www.mojedelo.com/izobrazevanja/vabljeni-na-webinarje-mojedelocom-4084"
            target={'_blank'}
          >
            <div className={styles.iconWrap}>
              <CastForEducationOutlined />
            </div>
            <div className={styles.text}>Webinars</div>
          </a>
        </div>
      </div>
    </div>
  )
}
