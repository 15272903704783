export enum EmployerDashboardTranslations {
  proposedDate = 'employerDashboard.proposedDate',
  newProposedDate = 'employerDashboard.newProposedDate',
  time = 'employerDashboard.time',
  message = 'employerDashboard.message',
  interviewInvDetmessage = 'employerDashboard.interviewInvDetmessage',
  videoInterview = 'employerDashboard.videoInterview',
  sendOffer = 'employerDashboard.sendOffer',
  proposeNewTime = 'employerDashboard.proposeNewTime',
  waitingForCandidate = 'employerDashboard.waitingForCandidate',
  title = 'employerDashboard.title',
  areYouSureApprovePlacement = 'employerDashboard.areYouSureApprovePlacement',
  whitThisActionApprovePlacement = 'employerDashboard.whitThisActionApprovePlacement',
  placementRejectAreYouSure = 'employerDashboard.placementRejectAreYouSure',
  placement = 'employerDashboard.placement',
  position = 'employerDashboard.position',
  invite = 'employerDashboard.invite',
  contactRequestDetails = 'employerDashboard.contactRequestDetails',
  all = 'employerDashboard.all',
  pending = 'employerDashboard.pending',
  pendingSmall = 'employerDashboard.pendingSmall',
  pendingInterviews = 'employerDashboard.pendingInterviews',
  approved = 'employerDashboard.approved',
  accepted = 'employerDashboard.accepted',
  acceptedInterviews = 'employerDashboard.acceptedInterviews',
  completed = 'employerDashboard.completed',
  completedInterviews = 'employerDashboard.completedInterviews',
  confirmed = 'employerDashboard.confirmed',
  action = 'employerDashboard.action',
  yourConfirmation = 'employerDashboard.yourConfirmation',
  candidatesConfirmation = 'employerDashboard.candidatesConfirmation',
  expire = 'employerDashboard.expire',
  candidate = 'employerDashboard.candidate',
  status = 'employerDashboard.status',
  noResults = 'employerDashboard.noResults',
  loadingText = 'employerDashboard.loadingText',
  searchCandidatesLabel = 'employerDashboard.searchCandidatesLabel',
  resetLabel = 'employerDashboard.resetLabel',
  search = 'employerDashboard.search',
  statusLabel = 'employerDashboard.statusLabel',
  confirmedByYou = 'employerDashboard.confirmedByYou',
  rejectedByYou = 'employerDashboard.rejectedByYou',
  waitingForYourAction = 'employerDashboard.waitingForYourAction',
  confirmedByCandidate = 'employerDashboard.confirmedByCandidate',
  waitingOnCandidatesAction = 'employerDashboard.waitingOnCandidatesAction',
  rejectedByCandidate = 'employerDashboard.rejectedByCandidate',
  confirmPlacement = 'employerDashboard.confirmPlacement',
  placementConfirmed = 'employerDashboard.placementConfirmed',
  placementPending = 'employerDashboard.placementPending',
  placementRejected = 'employerDashboard.placementRejected',
  sendMessage = 'employerDashboard.sendMessage',
  viewDetails = 'employerDashboard.viewDetails',
  cancelInterview = 'employerDashboard.cancelInterview',
  cancelConfirmation1 = 'employerDashboard.cancelConfirmation1',
  cancelConfirmation2 = 'employerDashboard.cancelConfirmation2',
  cancelReason = 'employerDashboard.cancelReason',
  sendNewOffer = 'employerDashboard.sendNewOffer',
  placementExpired = 'employerDashboard.placementExpired',
  startsIn = 'employerDashboard.startsIn',
  proposedTime = 'employerDashboard.proposedTime',
  performed = 'employerDashboard.performed',
  rating = 'employerDashboard.rating',
  offerFrom = 'employerDashboard.offerFrom',
  candidatesName = 'employerDashboard.candidatesName',
  offerDescription = 'employerDashboard.offerDescription',
  sendMessageTo = 'employerDashboard.sendMessageTo',
  body = 'employerDashboard.body',
  headerTitle = 'employerDashboard.headerTitle',
  headerTitleTxt = 'employerDashboard.headerTitleTxt',
  // Contact Requests:
  contactRequest = 'employerDashboard.contactRequest',
  contactRequests = 'employerDashboard.contactRequests',
  contactRequestPending = 'employerDashboard.contactRequestPending',
  contactRequestRejected = 'employerDashboard.contactRequestRejected',
  contactRequestExpired = 'employerDashboard.contactRequestExpired',
  contactRequestRequestType = 'employerDashboard.contactRequestRequestType',
  contactRequestInvitedToInterview = 'employerDashboard.contactRequestInvitedToInterview',
  offerAccepted = 'employerDashboard.offerAccepted',
  offerPending = 'employerDashboard.offerPending',
  offerExtended = 'employerDashboard.offerExtended',
  offerRejected = 'empoyerDahboard.offerRejected',
  offerSent = 'employerDashboard.offerSent',
  pendingOffers = 'empoyerDahboard.pendingOffers',
  acceptedOffers = 'empoyerDahboard.acceptedOffers',
  allOffers = 'empoyerDahboard.allOffers',
  employerOffers = 'empoyerDahboard.employerOffers',
  offerCancelled = 'empoyerDahboard.offerCancelled',
  offers = 'empoyerDahboard.offers',
  rejected = 'empoyerDahboard.rejected',
  expired = 'empoyerDahboard.expired',
  offerExpired = 'empoyerDahboard.offerExpired',
  placementConfirmation = 'employerDashboard.placementConfirmation',
  dashboard = 'employerDashboard.dashboard',
  interviewInvitation = 'employerDashboard.interviewInvitation',
  allinterviewInvitationsTitle = 'employerDashboard.allinterviewInvitationsTitle',
  pendinginterviewInvitationsTitle = 'employerDashboard.pendinginterviewInvitationsTitle',
  acceptedinterviewInvitationsTitle = 'employerDashboard.acceptedinterviewInvitationsTitle',
  completedinterviewInvitationsTitle = 'employerDashboard.completedinterviewInvitationsTitle',
  interviewInvitations = 'employerDashboard.interviewInvitations',
  videoInterviewInvitation = 'employerDashboard.videoInterviewInvitation',
  placements = 'employerDashboard.placements',
  allPlacements = 'employerDashboard.allPlacements',
  pendingPlacements = 'employerDashboard.pendingPlacements',
  confirmedPlacements = 'employerDashboard.confirmedPlacements',
  placementsTitle = 'employerDashboard.placementsTitle',
  requests = 'employerDashboard.requests',
  interviews = 'employerDashboard.interviews',
  allInterviews = 'employerDashboard.allInterviews',
  invitations = 'employerDashboard.invitations',
  viewAllSentContactRequests = 'employerDashboard.viewAllSentContactRequests',
  viewAllSentVideoRequests = 'employerDashboard.viewAllSentVideoRequests',
  viewAllOffers = 'employerDashboard.viewAllOffers',
  proposeButton = 'employerDashboard.proposeButton',
  sendButton = 'employerDashboard.sendButton',
  startVideoInterview = 'employerDashboard.startVideoInterview',
  offerSend = 'employerDashboard.offerSend',
  searchCandidates = 'employerDashboard.searchCandidates',
  manageCompanyProfile = 'employerDashboard.manageCompanyProfile',
  offer = 'employerDashboard.offer',
  acceptedOffer = 'employerDashboard.acceptedOffer',
  allOffersTitle = 'employerDashboard.allOffersTitle',
  pendingOffersTitle = 'employerDashboard.pendingOffersTitle',
  acceptedOffersTitle = 'employerDashboard.acceptedOffersTitle',
  sentRequests = 'employerDashboard.sentRequests',
  expiresToday = 'employerDashboard.expiresToday',
  allRequests = 'employerDashboard.allRequests',
  allRequestsTitle = 'employerDashboard.allRequestsTitle',
  pendingRequestsTitle = 'employerDashboard.pendingRequestsTitle',
  approvedRequestsTitle = 'employerDashboard.approvedRequestsTitle',
  pendingRequests = 'employerDashboard.pendingRequests',
  approvedRequests = 'employerDashboard.approvedRequests',
  candidateNoLongerActive = 'employerDashboard.candidateNoLongerActive',
  noLongerActiveCandidateModalPart1 = 'employerDashboard.noLongerActiveCandidateModalPart1',
  noLongerActiveCandidateModalPart2 = 'employerDashboard.noLongerActiveCandidateModalPart2',
  noLongerActiveCandidateModalPart3 = 'employerDashboard.noLongerActiveCandidateModalPart3',
  noLongerActiveCandidateModalPart4 = 'employerDashboard.noLongerActiveCandidateModalPart4',
  noLongerActiveCandidateTitle = 'employerDashboard.noLongerActiveCandidateTitle',
  noLongerActiveCandidateSubmitText = 'employerDashboard.noLongerActiveCandidateSubmitText',
  in = 'employerDashboard.in',
  minute = 'employerDashboard.minute',
  twoMinutes = 'employerDashboard.twoMinutes',
  threeAndFourMinutes = 'employerDashboard.threeAndFourMinutes',
  fiveAndMoreMinutes = 'employerDashboard.fiveAndMoreMinutes',
  hour = 'employerDashboard.hour',
  twoHours = 'employerDashboard.twoHours',
  threeAndFourHours = 'employerDashboard.threeAndFourHours',
  fiveAndMoreHours = 'employerDashboard.fiveAndMoreHours',
  day = 'employerDashboard.day',
  days = 'employerDashboard.days',
  second = 'employerDashboard.second',
  seconds = 'employerDashboard.seconds',
}
