import {
  ConsentStatus,
  GetConsentsParamsType,
  NewsletterConsent,
  StateType,
  UpdateNewsletterConsentType,
} from 'Interfaces'
import {FC, useEffect, useState} from 'react'
import styles from './NewsletterUnsubscribe.module.scss'
import Preferenca from 'Assets/Images/preferenca-logo-white.svg'
import YellowButton from 'Components/YellowButton'
import Checkbox from 'Components/Inputs/Checkbox'
import {useFormik} from 'formik'
import {useLocation} from 'react-router-dom'
import {cloneDeep, isEqual} from 'lodash'
import WarningIcon from '@mui/icons-material/Warning'
import {UnsubscribePage as up} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import {getQuerySearchParam} from 'Utils/Routes'

type NewsletterUnsubscribeProps = {
  consents: StateType<NewsletterConsent[]>
  updateGuestConsents: (data: UpdateNewsletterConsentType) => void
  getNewsletterConsents: (data: GetConsentsParamsType) => void
}

export const NewsletterUnsubscribe: FC<NewsletterUnsubscribeProps> = ({
  consents,
  updateGuestConsents,
  getNewsletterConsents,
}) => {
  const [consentsChanged, setConsentsChanged] = useState(false)
  let location = useLocation()
  const email = getQuerySearchParam(location.search, 'email')
  const hash = getQuerySearchParam(location.search, 'hash')

  useEffect(() => {
    if (email && hash) {
      getNewsletterConsents({
        email,
        hash,
      })
    }
  }, [])

  const FormikValues: NewsletterConsent[] = cloneDeep(consents.data)

  const formik = useFormik({
    initialValues: FormikValues,
    onSubmit: (values) => {
      const changedConsents: NewsletterConsent[] = []

      consents.data.forEach((consent) => {
        values.forEach((value) => {
          if (consent.id === value.id && consent?.status !== value.status) {
            changedConsents.push(value)
          }
        })
      })

      updateGuestConsents({
        email: email || '',
        hash: hash || '',
        consents: changedConsents,
      })
    },
  })

  useEffect(() => {
    if (!isEqual(formik.values, consents.data)) {
      setConsentsChanged(true)
    } else {
      setConsentsChanged(false)
    }
  }, [formik.values])

  useEffect(() => {
    const values = cloneDeep(consents.data)
    formik.setValues(values)
  }, [consents.data])

  const changeConsent = (id: string) => {
    const values = [...formik.values]
    values.forEach((c, idx) => {
      if (id === c.id) {
        values[idx].status =
          values[idx].status === ConsentStatus.GRANTED
            ? ConsentStatus.REVOKED
            : ConsentStatus.GRANTED
      }
    })
    formik.setValues(values)
  }

  const onSubmit = () => {
    if (consentsChanged) {
      formik.handleSubmit()
    }
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.card}>
        <div className={styles.header}>
          <img className={styles.logo} src={Preferenca} alt="Logo" />
        </div>
        <div className={styles.content}>
          <div className={styles.title}>
            <FormattedMessage id={up.title} />
          </div>
          <div className={styles.description}>
            <FormattedMessage id={up.description} />
          </div>
          <div className={styles.consentsWrap}>
            <p className={styles.subtitle}>
              <FormattedMessage id={up.subtitle} />
            </p>

            {formik.values.length > 0
              ? formik.values.map((consent) => (
                  <Checkbox
                    key={consent.id}
                    className={styles.checkbox}
                    name={`consent-${consent.id}`}
                    onChange={() => changeConsent(consent.id)}
                    value={consent?.status === ConsentStatus.GRANTED}
                  >
                    <span className={styles.checkboxContent}>
                      {consent.name}
                    </span>
                  </Checkbox>
                ))
              : !email && (
                  <div className={styles.warning}>
                    <WarningIcon />
                    <span>
                      <FormattedMessage id={up.warning} />
                    </span>
                  </div>
                )}

            <p className={styles.helpText}>
              <FormattedMessage id={up.helpTxt} />
            </p>
            <YellowButton
              className={styles.submitBtn}
              disabled={!consentsChanged}
              text={up.submit}
              onClick={onSubmit}
            />
          </div>
          <div className={styles.footer}>
            <FormattedMessage id={up.footer} />
          </div>
        </div>
      </div>
    </div>
  )
}
