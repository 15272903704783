import {FC} from 'react'

import {SectionContainer} from 'Components/Widgets/SectionContainer'
import {ContentContainer} from 'Components/Widgets/ContentContainer'
import {CandidateSearchTranslations as cs} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'

import {CommentType} from 'Interfaces'
import styles from './CommentsList.module.scss'

interface ItemsProps {
  items: CommentType[]
}

export const CommentsList: FC<ItemsProps> = ({items}) => {
  return (
    <div className={styles.comments}>
      <SectionContainer
        children={
          items?.length > 0 ? (
            <ContentContainer
              items={items.sort((a, b) =>
                Date.parse(a.dateCreated) < Date.parse(b.dateCreated) ? 1 : -1
              )}
            />
          ) : (
            <h3 className={styles.title}>
              <FormattedMessage id={cs.commLabel} />
            </h3>
          )
        }
      />
    </div>
  )
}
