import {FC, useEffect, useMemo, useState} from 'react'
import {useFormik} from 'formik'

import styles from './PublicJobPreferences.module.scss'
import {
  ProfileJobPreferenceFormikType,
  PublicProfileJobPreferenceFormikType,
  PublicSeekerProfileType,
  SeekerStateType,
  TaxonomiesStateType,
} from 'Interfaces'
import SectionHeader from 'Components/Profile/SectionHeader'
import SectionItem from 'Components/Profile/SectionItem'
import ItemTextWithIcon from 'Components/Profile/ItemTextWithIcon'
import Modal, {SubmitFooter} from 'Components/Modal'
import Title from 'Components/Title'
import {Validation} from './Validation'
import Hr from 'Components/Hr'
import DropdownMultiple from 'Components/Inputs/DropdownMultiple'
import Dropdown from 'Components/Inputs/Dropdown'
import {
  AutoAwesomeOutlined,
  BuildCircle,
  EditOutlined,
  ExploreOutlined,
  MapsHomeWorkOutlined,
  OtherHousesOutlined,
  SavingsOutlined,
  StarBorderOutlined,
  TimerOutlined,
} from '@mui/icons-material'
import {DropdownProps, SetValuesProps} from 'Utils/FormikProps'
import TypeAhead from 'Components/Inputs/TypeAhead'
import {useApiError} from 'Hooks'
import TextArea from 'Components/Inputs/TextArea'
import RadioGroup from 'Components/Inputs/RadioGroup'
import {
  OnboardingTranslations as ot,
  OnboardingTranslations as onb,
  ProfileTranslations as pt,
} from 'Services/I18n/Constants'

interface PublicJobPreferencesProps {
  seeker: SeekerStateType
  taxonomies: TaxonomiesStateType
  getTaxonomies: () => void
  profile?: PublicSeekerProfileType
  updatePublicJobPreferences: (values: ProfileJobPreferenceFormikType) => void
  canChange?: boolean
}

export const PublicJobPreferences: FC<PublicJobPreferencesProps> = ({
  seeker,
  taxonomies,
  getTaxonomies,
  profile,
  updatePublicJobPreferences,
  canChange = false,
}) => {
  const [modalOpen, setModalOpen] = useState(false)

  const regionsText = useMemo(() => {
    let text = ''
    profile?.desiredRegions?.forEach((d, i) => {
      text = `${text}${i > 0 ? ', ' : ''}${d.translation}`
    })
    return text
  }, [profile?.desiredRegions])

  const additionalBenefitsText = useMemo(() => {
    let text = ''
    profile?.additionalBenefits?.forEach((d, i) => {
      text = `${text}${i > 0 ? ', ' : ''}${d.translation}`
    })
    return text
  }, [profile?.additionalBenefits])

  const positionsText = useMemo(() => {
    let text = ''
    profile?.desiredOccupations?.forEach((d, i) => {
      text = `${text}${i > 0 ? ', ' : ''}${d.translation}`
    })
    return text
  }, [profile?.desiredOccupations])

  const defaultValues: PublicProfileJobPreferenceFormikType = {
    desiredRegions: profile?.desiredRegions || [],
    desiredOccupations: profile?.desiredOccupations || [],
    desiredSalary: profile?.desiredSalary,
    availability: profile?.availability,
    workFromHome: profile?.workFromHome,
    desiredWorkEnv: profile?.desiredWorkEnv || '',
    additionalBenefits: profile?.additionalBenefits || [],
  }

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: Validation,
    onSubmit: (values) => {
      updatePublicJobPreferences(values)
    },
  })

  const openEdit = () => {
    formik.resetForm({values: defaultValues})
    getTaxonomies()
    setModalOpen(true)
  }

  useEffect(() => {
    if (!seeker.loading && !seeker.error) setModalOpen(false)
  }, [seeker.loading])

  useApiError(formik.setFieldError, seeker.error)

  const setDropdownField = (name: string, value?: string | number) => {
    if (name === 'desiredSalary') {
      const salary = taxonomies.salaries.data.find((c) => {
        return typeof value === 'string' && c.id === value
      })
      formik.setFieldValue(name, salary)
    } else if (name === 'availability') {
      const availability = taxonomies.availabilities.data.find((a) => {
        return typeof value === 'string' && a.id === value
      })
      formik.setFieldValue(name, availability)
    } else if (name === 'workFromHome') {
      const workFromHome = taxonomies.workFromHome.data.find((a) => {
        return typeof value === 'string' && a.id === value
      })
      formik.setFieldValue(name, workFromHome)
    }
  }

  const setMultiDropdownField = (name: string, values?: string[]) => {
    if (values && values.length) {
      const newValues = values.map((v) => {
        if (name === 'desiredRegions') {
          return taxonomies.regions.data.find((r) => r.id === v)
        } else return taxonomies.additionalBenefits.data.find((r) => r.id === v)
      })
      formik.setFieldValue(name, newValues)
    } else formik.setFieldValue(name, [])
  }
  const regions = useMemo(
    () => formik.values.desiredRegions?.map((d) => d.id) || [],
    [formik.values.desiredRegions]
  )
  const additionalBenefits = useMemo(
    () => formik.values.additionalBenefits?.map((d) => d.id) || [],
    [formik.values.additionalBenefits]
  )

  const allOccupations = useMemo(() => {
    let newSkills = taxonomies.occupations.data.slice()
    profile?.desiredOccupations?.forEach((skill) => {
      if (newSkills.findIndex((n) => n.id === skill.id) === -1)
        newSkills = [...newSkills, skill]
    })
    return newSkills
  }, [taxonomies.occupations.data, profile?.desiredOccupations])

  return (
    <div>
      <div className={styles.section}>
        <div>
          <SectionHeader title={ot.titleJobPreferences} />
        </div>
        <SectionItem className={styles.sectionItem}>
          <div className={styles.content}>
            <ItemTextWithIcon
              primaryText={pt.preferredRegions}
              withColon
              icon={ExploreOutlined}
              secondaryText={regionsText}
            />

            <ItemTextWithIcon
              primaryText={ot.titleDesiredJobPositions}
              withColon
              icon={StarBorderOutlined}
              secondaryText={positionsText}
            />

            {profile?.desiredSalary && (
              <ItemTextWithIcon
                primaryText={ot.titleDesiredSalary}
                withColon
                icon={SavingsOutlined}
                secondaryText={profile?.desiredSalary?.translation}
              />
            )}

            {profile?.availability && (
              <ItemTextWithIcon
                primaryText={pt.available}
                withColon
                icon={TimerOutlined}
                secondaryText={profile?.availability?.translation}
              />
            )}

            {profile?.workFromHome && (
              <ItemTextWithIcon
                primaryText={pt.workFromHome}
                withColon
                icon={OtherHousesOutlined}
                secondaryText={
                  profile?.workFromHome?.translation || 'At least once a week'
                }
              />
            )}

            {profile?.desiredWorkEnv && (
              <ItemTextWithIcon
                primaryText={pt.desiredWorkEnv}
                withColon
                icon={MapsHomeWorkOutlined}
                secondaryText={profile?.desiredWorkEnv}
              />
            )}
            {profile?.additionalBenefits?.length ? (
              <ItemTextWithIcon
                primaryText={onb.titleAdditionalBenefits}
                withColon
                icon={AutoAwesomeOutlined}
                secondaryText={additionalBenefitsText}
              />
            ) : null}
          </div>
          <div>
            {canChange && (
              <button onClick={openEdit} className={styles.editButton}>
                <EditOutlined sx={{fontSize: 25}} />
              </button>
            )}
          </div>
        </SectionItem>
        <Modal
          isOpen={modalOpen}
          onAfterClose={() => {
            setModalOpen(false)
          }}
          title={pt.editJobPreferences}
          icon={BuildCircle}
          className={styles.modal}
          theme="desktopFlex"
          Footer={
            <SubmitFooter
              onSubmit={formik.handleSubmit}
              onCancel={() => {
                setModalOpen(false)
              }}
              theme="modalProfileSubmit"
            />
          }
        >
          <Title text={ot.titleDesiredRegions} theme="modal" />
          <DropdownMultiple
            items={taxonomies.regions.data}
            name="desiredRegions"
            theme="white"
            {...SetValuesProps(formik, 'desiredRegions')}
            setValues={setMultiDropdownField}
            values={regions}
          />
          <Hr theme="modal" />

          <Title text={ot.titleDesiredJobPositions} theme="modal" />
          <TypeAhead
            name="desiredOccupations"
            defaultValue={formik.values.desiredOccupations}
            items={allOccupations}
            placeholder={pt.desiredOccupationsPlaceholder}
            selectNonExisting
            maxNumSelections={3}
            theme="modal"
            {...DropdownProps(formik, 'desiredOccupations')}
          />
          <Hr theme="modal" />

          <Title text={ot.titleDesiredSalary} theme="modal" />
          <Dropdown
            name="desiredSalary"
            items={taxonomies.salaries.data}
            theme="white"
            value={formik.values['desiredSalary']?.id}
            setTouched={formik.getFieldHelpers('desiredSalary').setTouched}
            error={
              formik.submitCount && formik.touched['desiredSalary']
                ? formik.errors['desiredSalary']
                  ? formik.errors['desiredSalary']['id']
                  : undefined
                : undefined
            }
            setValue={setDropdownField}
          />
          <Hr theme="modal" />

          <Title text={pt.available} theme="modal" />
          <Dropdown
            name="availability"
            items={taxonomies.availabilities.data}
            theme="white"
            value={formik.values['availability']?.id}
            setTouched={formik.getFieldHelpers('availability').setTouched}
            error={
              formik.submitCount && formik.touched['availability']
                ? formik.errors['availability']
                  ? formik.errors['availability']['id']
                  : undefined
                : undefined
            }
            setValue={setDropdownField}
          />
          <Hr theme="modal" />

          <Title text={pt.workFromHome} theme="modal" />
          <RadioGroup
            items={taxonomies.workFromHome.data}
            name="workFromHome"
            value={formik.values['workFromHome']?.id}
            setTouched={formik.getFieldHelpers('workFromHome').setTouched}
            error={
              formik.submitCount && formik.touched['workFromHome']
                ? formik.errors['workFromHome']
                  ? formik.errors['workFromHome']['id']
                  : undefined
                : undefined
            }
            setValue={setDropdownField}
          />
          <Hr theme="modal" />

          <Title text={pt.desiredWorkEnv} theme="modal" />
          <TextArea
            name="desiredWorkEnv"
            value={`${formik.values.desiredWorkEnv}`}
            onChange={formik.handleChange}
            placeholderText={pt.placeholderDesiredWorkEnv}
            theme="modal"
            error={
              formik.touched.desiredWorkEnv ||
              formik.values.desiredWorkEnv !== ''
                ? formik.errors.desiredWorkEnv
                : undefined
            }
            maximumCharactersNumber={1000}
            type="letterCalculator"
          />
          <Hr theme="modal" />

          <Title text={onb.titleAdditionalBenefits} theme="modal" />
          <DropdownMultiple
            items={taxonomies.additionalBenefits.data}
            name="additionalBenefits"
            theme="white"
            {...SetValuesProps(formik, 'additionalBenefits')}
            setValues={setMultiDropdownField}
            values={additionalBenefits}
            listUp
          />
        </Modal>
      </div>
    </div>
  )
}
