import {useState} from 'react'

export const useHandleFocus = (): [undefined | number, () => void] => {
  const [commentFocusTrigger, setCommentFocusTrigger] = useState<
    undefined | number
  >()

  const handleCommentFocus = () => {
    setCommentFocusTrigger(Math.floor(Math.random() * 10000) + 1)
  }

  return [commentFocusTrigger, handleCommentFocus]
}
