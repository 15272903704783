import {connect} from 'react-redux'

import {EmailVerification} from './EmailVerification'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {
  getUser,
  hideEmailResentMessage,
  resendVerificationEmail,
  verifyEmail,
} from 'Store/Actions'

const mapStateToProps = (state: RootState) => ({
  emailVerify: state.auth.emailVerify,
  isEmailResent: state.auth.displayMessage.resendEmailVerification,
  auth: state.auth,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getUser: () => dispatch(getUser()),
  hideEmailResentMessage: () => dispatch(hideEmailResentMessage()),
  verifyEmail: (token: string) => dispatch(verifyEmail(token)),
  resendVerificationEmail: (email: string) =>
    dispatch(resendVerificationEmail(email)),
})

export const EmailVerificationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmailVerification)
