export enum MyProjectCandidatesTranslations {
  sortByTitle = 'myprojectcandidates.sortByTitle',
  sortOptionJobTitle = 'myprojectcandidates.sortOptionJobTitle',
  sortOptionFirstName = 'myprojectcandidates.sortOptionFirstName',
  sortOptionLastName = 'myprojectcandidates.sortOptionLastName',
  sortOptionDateAdded = 'myprojectcandidates.sortOptionDateAdded',
  sortOptionReviewed = 'myprojectcandidates.sortOptionReviewed',
  sortOptionUnreviewed = 'myprojectcandidates.sortOptionUnreviewed',
  actionSendContactRequest = 'myprojectcandidates.actionSendContactRequest',
  actionSendMessage = 'myprojectcandidates.actionSendMessage',
  actionInviteToTesting = 'myprojectcandidates.actionInviteToTesting',
  searchKeyWordLabel = 'myprojectcandidates.searchKeyWordLabel',
  searchKeyWordPlaceholder = 'myprojectcandidates.searchKeyWordPlaceholder',
  searchSubmitButton = 'myprojectcandidates.searchSubmitButton',
  selectAllLabel = 'myprojectcandidates.selectAllLabel',
  testingTitle = 'myprojectcandidates.testingTitle',
  testingOptionPsychometric = 'myprojectcandidates.testingOptionPsychometric',
  testingOptionCognitive = 'myprojectcandidates.testingOptionCognitive',
  actionsTitle = 'myprojectcandidates.actionsTitle',
}
