import {useMemo} from 'react'

export const useYears = (
  correction: number = 0,
  bottomLimit: number = 1970
) => {
  return useMemo(() => {
    const availableYears = []
    for (
      let i = new Date().getFullYear() + correction;
      i >= bottomLimit;
      i -= 1
    ) {
      availableYears.push(i)
    }
    return availableYears
  }, [])
}
