import {
  BaseType,
  ErrorType,
  File,
  ImageType,
  ValidationErrorType,
} from './BaseType'
import {BaseTaxonomyType} from './TaxonomiesType'
import {
  AdditionalBenefitType,
  AttachmentType,
  AwardsItem,
  CertificationProfileItemType,
  EducationProfileItemType,
  LanguageType,
  ProjectItem,
  PublicWorkExperienceItem,
  SkillType,
  WorkExperienceItem,
} from './ProfileType'
import {ReviewCandidateDataType} from './Employer'
import {ConsentType} from './ConsentsType'
import {TrainingTitleType} from './Assessments'
import {
  CompanyUserSeekerProjectType,
  SeekerEvaluationType,
} from './CandidateType'
import {
  EmployerRequestsStateType,
  IncomingRequestsStateType,
} from './IncomingRequestsType'

export enum UserType {
  SEEKER = 'seeker',
  EMPLOYER = 'company_user',
}

export enum CompanyUserRole {
  RECRUITER = 'recruiter',
  EMPLOYER = 'employer',
  COMPANY_OWNER = 'company_owner',
}

export interface MeType extends BaseType {
  type: UserType
  languageId: string
  contentLanguageId: string
  firstName: string
  lastName: string
  email: string
  rjbProfileStatus: RjbProfileStatus
  cbtAccountStatus: CbtAccountStatus
  onboardingStatus: SeekerOnboardingStatus
  phone: string
  profilePicture?: ImageType
  companyStructure?: {
    id: string
    company: {
      id: string
      companyOnboardingStatus:
        | 'companyAndContactInfo'
        | 'twelveMonthHiringPlan'
        | 'done'
      name: string
    }
  }
  userConsents?: ConsentType[]
  companyConsents?: ConsentType[]
  role?: CompanyUserRole
}
export interface CompanySeekerJourneysType {
  id: string
}

export interface SeekerProfileType extends BaseType {
  firstName: string
  lastName: string
  email: string
  profilePicture?: ImageType
  dateOfBirth?: string
  jobSearchStatus?: BaseTaxonomyType
  aboutMe?: string
  streetAddress?: string
  postalCode?: string
  city?: string
  countryId?: string
  phoneNumber?: string
  phone?: string
  jobTitle?: string
  homeTown?: BaseTaxonomyType
  homeCountry?: BaseTaxonomyType
  publicOccupation?: BaseTaxonomyType
  desiredRegions: BaseTaxonomyType[]
  desiredOccupations: BaseTaxonomyType[]
  desiredEmploymentTypes?: BaseTaxonomyType[]
  desiredSalary: BaseTaxonomyType
  currentSalary: BaseTaxonomyType
  workExperiences?: WorkExperienceItem[]
  educations?: EducationProfileItemType[]
  languages?: LanguageType[]
  drivingLicense: DrivingLicenseType
  trainings?: CertificationProfileItemType[]
  projects?: ProjectItem[]
  awards?: AwardsItem[]
  references?: []
  attachmentIds?: []
  attachments?: AttachmentType[]
  skills?: SkillType[]
  country: BaseTaxonomyType
  homeRegion: BaseTaxonomyType
  careerLevel: BaseTaxonomyType
  jobCategory: BaseTaxonomyType
  profileCompleteness: number
  cvId?: string
  hasFinishedPlacementWithCurrentEmployer?: boolean
  rjbProfileStatus?: RjbProfileStatus
  age?: number
  seekerTrainingTitles: TrainingTitleType[] | null
  companySeekerJourneys?: CompanySeekerJourneysType[]
}

export interface PublicSeekerProfileType extends BaseType {
  age?: number
  jobSearchStatus?: BaseTaxonomyType
  publicOccupation?: BaseTaxonomyType | null
  workFromHome?: BaseTaxonomyType
  shortDescription?: string
  desiredWorkEnv?: string
  availability?: BaseTaxonomyType
  additionalBenefits?: AdditionalBenefitType[]
  totalWorkExperience?: BaseTaxonomyType
  workExperiences?: PublicWorkExperienceItem[]
  skills?: SkillType[]
  careerLevel: BaseTaxonomyType
  jobCategory?: BaseTaxonomyType
  desiredRegions: BaseTaxonomyType[]
  desiredOccupations: BaseTaxonomyType[]
  desiredEmploymentTypes?: BaseTaxonomyType[]
  desiredSalary: BaseTaxonomyType
  profilePicture?: ImageType
  firstName?: string
  lastName?: string
  hasFinishedPlacementWithCurrentEmployer?: boolean
  rjbProfileStatus?: RjbProfileStatus
  companySeekerJourneys?: CompanySeekerJourneysType[]
}

export interface IncomingRequestSeekerType extends BaseType {
  userId: string
  firstName: string
  lastName: string
  dateOfBirth?: string
  town?: BaseTaxonomyType
  homeTown?: BaseTaxonomyType
  seekerEvaluations: SeekerEvaluationType[]
  relevance?: number
  publicOccupation?: BaseTaxonomyType
  profilePicture?: File
  contactRequest?: {
    expirationDate: string
    id: string
    status: string
  }
  companyUserSeekerProject?: CompanyUserSeekerProjectType
  companySeekerJourney?: {
    id: string
    contactRequest?: IncomingRequestsStateType | null
  }
  companySeekerJourneys?: CompanySeekerJourneysType[]
  country?: BaseTaxonomyType
  homeCountry?: BaseTaxonomyType
  hasFinishedPlacementWithCurrentEmployer?: boolean
  rjbProfileStatus?: RjbProfileStatus
}

export interface EmployerProfileType extends BaseType {
  firstName: string
  lastName: string
  email: string
  profilePicture?: ImageType
  dateOfBirth?: string
  streetAddress?: string
  postalCode?: string
  phone?: string
  homeTown?: BaseTaxonomyType
  homeCountry?: BaseTaxonomyType
  homeRegion: BaseTaxonomyType
}

export interface AllEmployerRequests {
  contactRequests: EmployerRequestsStateType
  interviewInvitations: EmployerRequestsStateType
  offers: EmployerRequestsStateType
  placements: EmployerRequestsStateType
  allRequests: EmployerRequestsStateType
  placementConfirmedOffers: EmployerRequestsStateType
}

export interface EmployerActiveRequests {
  contactRequests: number
  interviewInvitations: number
  offers: number
  placementConfirmedOffers: number
  placements: number
}

export interface CandidateDropdownOptionResponseType {
  id: string
  userId: string
  firstName: string
  lastName: string
  publicOccupation: BaseTaxonomyType
}

export interface CandidateDropdownOptionType {
  id: string
  label: string
}

export interface EmployerRequests {
  activeRequests?: EmployerActiveRequests
  allRequests: AllEmployerRequests
}

export interface PartialUserType {
  firstName: string
  lastName: string
  email: string
}

export interface AuthStateType {
  data?: MeType
  displayMessage: {
    emailChanged: boolean
    passwordChanged: boolean
    forgotPasswordEmailSent: boolean
    resetPasswordSuccess: boolean
    resendEmailVerification: boolean
  }
  emailVerify?: {
    data?: string
    loading: boolean
    error?: ErrorType | ValidationErrorType
    success: boolean
  }
  emailChangeVerify?: {
    data?: string
    loading: boolean
    error?: ErrorType | ValidationErrorType
    success: boolean
  }
  dirty?: boolean
  loading: boolean
  error?: ErrorType | ValidationErrorType
}

export interface SeekerSpectoProfileType {
  token?: string
  id?: string
}

export interface SeekerStateType {
  data?: {
    full?: SeekerProfileType
    public?: PublicSeekerProfileType
    specto?: SeekerSpectoProfileType
  }
  loading: boolean
  error?: ErrorType
}

export interface EmployerCandidateProfileDataType {
  type?: 'public' | 'full'
  profile?: SeekerProfileType | PublicSeekerProfileType
  contactRequestExists?: boolean
}

export interface EmployerCandidateProfileStateType {
  data: EmployerCandidateProfileDataType
  loading: boolean
  error?: ErrorType
}

export interface EmployerStateType {
  data: EmployerRequests & {candidate: EmployerCandidateProfileStateType}
  loading: boolean
  error?: ErrorType
  reviewData?: ReviewCandidateDataType
  fullData?: EmployerProfileType
}

export interface UserPasswordType {
  password: string
  verifyPassword: string
}

export interface UserConsentType {
  consent1B: boolean
  consent2: boolean
  consent3B: boolean
}

export type RegistrationType = PartialUserType &
  UserPasswordType &
  UserConsentType

export type LoginType = {
  identifier: string
  password: string
} & AuthRoutingType

export interface AuthRoutingType {
  nextRoute?: string
}

export interface ForgotPasswordType {
  email: string
  path: string
}

export interface ResetPasswordType {
  password: string
  verifyPassword: string
  token: string
}

export interface ChangePasswordType {
  oldPassword: string
  newPassword: string
  confirmNewPassword: string
}

export type DrivingLicenseType = {
  seekerDrivingLicenseCategories: {
    drivingLicenseCategory: {id: string; nameTranslation: string}
  }[]
  ownVehicle?: boolean
} | null

export type DrivingLicenseSaveType = DrivingLicenseType & {
  drivingLicenseCategoryIds: string[]
}

interface SeekerPersonalDataSaveBaseType {
  profilePictureId?: string
  firstName?: string
  lastName?: string
  dateOfBirth?: string
  streetAddress?: string
  postalCode?: string
  homeCountryId?: string
  phone?: string
}

interface SeekerPersonalDataSaveTownNameType
  extends SeekerPersonalDataSaveBaseType {
  homeTownName?: string
}

interface SeekerPersonalDataSaveTownIdType
  extends SeekerPersonalDataSaveBaseType {
  homeTownId?: string
}

interface SeekerPersonalDataSaveRegionNameType
  extends SeekerPersonalDataSaveBaseType {
  homeRegionName?: string
}

interface SeekerPersonalDataSaveRegionIdType
  extends SeekerPersonalDataSaveBaseType {
  homeRegionId?: string
}

export type PersonalDataSaveType = (
  | SeekerPersonalDataSaveTownNameType
  | SeekerPersonalDataSaveTownIdType
) &
  (SeekerPersonalDataSaveRegionNameType | SeekerPersonalDataSaveRegionIdType)

export type WorkExperiencesResponseType = {
  occupationExperiences: {
    id: string
    experience: number
    occupation: {
      id: string
      jobCategory: BaseTaxonomyType | null
      translation: string
    }
  }[]
  totalWorkExperience: BaseTaxonomyType
}

export enum RjbProfileStatus {
  DRAFT = 'draft',
  NEW = 'new',
  PENDING_APPROVAL = 'pending_approval',
  PENDING_REVIEW = 'pending_review',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  WITHDRAWN = 'withdrawn',
  CLOSED = 'closed',
  EXPIRED = 'expired',
  SUSPENDED = 'suspended',
  PENDING_DELETION = 'pending_deletion',
  DELETED = 'deleted',
  ARCHIVED = 'archived',
}

export enum CbtAccountStatus {
  NEW = 'new',
  UNVERIFIED = 'unverified',
  ACTIVE = 'active',
  REVOKED = 'revoked',
  LOCKED = 'locked',
  SUSPENDED = 'suspended',
  TERMINATED = 'terminated',
  DELETED = 'deleted',
  ARCHIVED = 'archived',
}
export enum SeekerOnboardingStatus {
  UPLOADCV = 'uploadCv',
  JOBPREFERENCE = 'jobPreference',
  WORKEXPERIENCE = 'workExperience',
  BENEFITSEDUCATIONS = 'benefitsEducation',
  FINISHED = 'finished',
}
export enum EmployerOnboardingStatus {
  COMPANYANDCONTACTINFO = 'companyAndContactInfo',
  TWELVEMONTHHIRINGPLAN = 'twelveMonthHiringPlan',
  DONE = 'done',
}
export interface SeekerLoginConsentsFormikValues {
  tcCheckbox: boolean
  privacyCheckbox: boolean
  thirdPartyCheckbox: boolean
  apiError: string
}
