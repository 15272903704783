export const employerDashboard = {
  'employerDashboard.proposedDate': 'Proposed date ',
  'employerDashboard.newProposedDate': 'New Proposed date',
  'employerDashboard.time': 'time',
  'employerDashboard.message': 'Message:',
  'employerDashboard.interviewInvDetmessage': `Sadly, I can not attend the video interview at proposed time. I’ve proposed new time.`,
  'employerDashboard.videoInterview': `Video Interview`,
  'employerDashboard.sendOffer': `Send offer`,
  'employerDashboard.proposeNewTime': 'Propose new time',
  'employerDashboard.waitingForCandidate': `Waiting for candidate`,
  'employerDashboard.title': `Title`,
  'employerDashboard.areYouSureApprovePlacement': `Are you sure you want to confirm placement for this candidate? `,
  'employerDashboard.whitThisActionApprovePlacement': `With this action you confirm that employment contract has been signed and you 
  oblige to employ this candidate.`,
  'employerDashboard.placementRejectAreYouSure': `Are you sure you want to reject placement for this candidate? `,
  'employerDashboard.position': `Position:`,
  'employerDashboard.invite': `Invite`,
  'employerDashboard.contactRequestDetails': `Contact request details`,
  'employerDashboard.all': 'All',
  'employerDashboard.allRequests': 'All requests',
  'employerDashboard.pendingRequests': 'Pending requests',
  'employerDashboard.approvedRequests': 'Approved requests',
  'employerDashboard.pending': 'Pending',
  'employerDashboard.pendingSmall': 'pending',
  'employerDashboard.approved': 'Approved',
  'employerDashboard.accepted': 'Accepted',
  'employerDashboard.acceptedInterviews': 'Accepted invitations',
  'employerDashboard.completedInterviews': 'Completed interviews',
  'employerDashboard.completed': 'Completed',
  'employerDashboard.confirmed': 'Confirmed',
  'employerDashboard.action': 'ACTION',
  'employerDashboard.yourConfirmation': 'YOUR CONFIRMATION',
  'employerDashboard.candidatesConfirmation': 'CANDIDATE’S CONFIRMATION',
  'employerDashboard.expire': 'EXPIRE',
  'employerDashboard.candidate': 'CANDIDATE',
  'employerDashboard.status': 'STATUS',
  'employerDashboard.noResults': 'No results.',
  'employerDashboard.loadingText': 'Just a moment, searching for data...',
  'employerDashboard.searchCandidatesLabel':
    'Search candidates (First name, Last name, Job title)',
  'employerDashboard.statusLabel': 'Status',
  'employerDashboard.search': 'Search',
  'employerDashboard.resetLabel': 'Reset search',
  'employerDashboard.confirmedByYou': 'Confirmed by you',
  'employerDashboard.rejectedByYou': 'Rejected by you',
  'employerDashboard.waitingForYourAction': 'Waiting for your action',
  'employerDashboard.confirmedByCandidate': 'Confirmed by candidate',
  'employerDashboard.waitingOnCandidatesAction':
    "Waiting on candidate's action",
  'employerDashboard.rejectedByCandidate': 'Rejected by candidate',
  'employerDashboard.confirmPlacement': 'Confirm placement',
  'employerDashboard.placementConfirmed': 'Placement confirmed',
  'employerDashboard.placementPending': 'Placement pending',
  'employerDashboard.placementRejected': 'Placement rejected',
  'employerDashboard.sendMessage': 'Send message',
  'employerDashboard.viewDetails': 'View details',
  'employerDashboard.cancelInterview': 'Cancel interview',
  'employerDashboard.cancelConfirmation1':
    'Are you sure you want to cancel video interview with ',
  'employerDashboard.cancelConfirmation2':
    'You will miss the opportunity to get in touch with this candidate.',
  'employerDashboard.cancelReason':
    "Enter cancelation reason. Example: I'm not interested... (Optional)",
  'employerDashboard.sendNewOffer': 'Send new offer',
  'employerDashboard.placementExpired': 'Placement expired',
  'employerDashboard.allPlacements': 'All Placements',
  'employerDashboard.pendingPlacements': 'Pending Placements',
  'employerDashboard.confirmedPlacements': 'Confirmed Placements',
  'employerDashboard.startsIn': 'STARTS IN',
  'employerDashboard.proposedTime': 'PROPOSED TIME',
  'employerDashboard.performed': 'PERFORMED',
  'employerDashboard.rating': 'RATING',
  'employerDashboard.offerFrom': `Offer from`,
  'employerDashboard.candidatesName': `Candidate's name`,
  'employerDashboard.offerDescription': `Offer description`,
  'employerDashboard.sendMessageTo': 'Send message to',
  'employerDashboard.body': 'Body',
  'employerDashboard.headerTitle': 'Hi',
  'employerDashboard.headerTitleTxt': ', welcome back!',
  // Contact Requests:
  'employerDashboard.contactRequest': 'Contact Request',
  'employerDashboard.contactRequestPending': 'Contact request pending',
  'employerDashboard.contactRequestRejected': 'Contact request rejected',
  'employerDashboard.contactRequestExpired': 'Contact request expired',
  'employerDashboard.contactRequestRequestType': 'REQUEST TYPE',
  'employerDashboard.contactRequestInvitedToInterview':
    'Invited to video interview',
  'employerDashboard.offerAccepted': 'OFFER ACCEPTED',
  'employerDashboard.offerExtended': 'Offer extended',
  'employerDashboard.offerPending': 'Offer pending',
  'employerDashboard.offerSent': 'OFFER SENT',
  'empoyerDahboard.pendingOffers': 'Pending offers',
  'empoyerDahboard.acceptedOffers': 'Accepted offers',
  'empoyerDahboard.allOffers': 'All offers',
  'empoyerDahboard.employerOffers': 'Employer offers',
  'empoyerDahboard.offerCancelled': 'Offer cancelled',
  'empoyerDahboard.offers': 'Offers',
  'empoyerDahboard.rejected': 'Rejected',
  'empoyerDahboard.expired': 'Expired',
  'empoyerDahboard.offerExpired': 'Offer expired',
  'empoyerDahboard.offerRejected': 'Offer rejected',
  'employerDashboard.placementConfirmation': 'Placement confirmation',
  'employerDashboard.dashboard': 'Dashboard',
  'employerDashboard.contactRequests': 'Contact requests',
  'employerDashboard.placements': 'Placements',
  'employerDashboard.placementsTitle': 'Placements',
  'employerDashboard.allinterviewInvitationsTitle':
    'All Video interview invitations',
  'employerDashboard.pendinginterviewInvitationsTitle':
    'Pending Video interview invitations',
  'employerDashboard.acceptedinterviewInvitationsTitle':
    'Accepted Video interview invitations',
  'employerDashboard.completedinterviewInvitationsTitle':
    'Completed interviews',
  'employerDashboard.interviewInvitation': 'Interview Invitation',
  'employerDashboard.interviewInvitations': 'Interview Invitations',
  'employerDashboard.videoInterviewInvitation': 'Video interview invitations',
  'employerDashboard.requests': 'Requests',
  'employerDashboard.allRequestsTitle': 'All Contact requests',
  'employerDashboard.pendingRequestsTitle': 'Pending Contact requests',
  'employerDashboard.approvedRequestsTitle': 'Approved Contact requests',
  'employerDashboard.interviews': 'Interviews',
  'employerDashboard.pendingInterviews': 'Pending invitations',
  'employerDashboard.allInterviews': 'All invitations',
  'employerDashboard.invitations': 'Invitations',
  'employerDashboard.viewAllSentContactRequests':
    'View all sent contact requests',
  'employerDashboard.viewAllSentVideoRequests':
    'View all sent video interview requests',
  'employerDashboard.viewAllOffers': 'View all offers',
  'employerDashboard.proposeButton': 'Propose new time',
  'employerDashboard.sendButton': 'Send offer',
  'employerDashboard.startVideoInterview': 'Start video interview',
  'employerDashboard.offerSend': 'Offer sent',
  'employerDashboard.searchCandidates': 'Search candidates',
  'employerDashboard.manageCompanyProfile': 'Manage company profile',
  'employerDashboard.offer': 'Offer',
  'employerDashboard.allOffersTitle': 'All Offers',
  'employerDashboard.pendingOffersTitle': 'Pending Offers',
  'employerDashboard.acceptedOffersTitle': 'Accepted Offers',
  'employerDashboard.acceptedOffer': 'Offer accepted',
  'employerDashboard.sentRequests': 'Sent requests',
  'employerDashboard.expiresToday': 'Expires today',
  'employerDashboard.candidateNoLongerActive':
    'This candidate is no longer active on the board.',
  'employerDashboard.noLongerActiveCandidateModalPart1':
    'Candidate is no longer active on the board due to one of the following reasons:',
  'employerDashboard.noLongerActiveCandidateModalPart2':
    'Candidate has been successfully placed with another employer',
  'employerDashboard.noLongerActiveCandidateModalPart3':
    'Candidate is no longer actively persuing new position',
  'employerDashboard.noLongerActiveCandidateModalPart4':
    'Candidate no longer matches our requirements',
  'employerDashboard.noLongerActiveCandidateTitle':
    'Candidate no longer active',
  'employerDashboard.noLongerActiveCandidateSubmitText': 'I understand',
  'employerDashboard.in': 'In',
  'employerDashboard.minute': 'minute',
  'employerDashboard.twoMinutes': 'minutes',
  'employerDashboard.threeAndFourMinutes': 'minutes',
  'employerDashboard.fiveAndMoreMinutes': 'minutes',
  'employerDashboard.hour': 'hour',
  'employerDashboard.twoHours': 'hours',
  'employerDashboard.threeAndFourHours': 'hours',
  'employerDashboard.fiveAndMoreHours': 'hours',
  'employerDashboard.day': 'day',
  'employerDashboard.days': 'days',
  'employerDashboard.second': 'second',
  'employerDashboard.seconds': 'seconds',
}
