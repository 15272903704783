import {connect} from 'react-redux'

import {Consents} from './Consents'
import {AppDispatch} from 'Store'
import {getConsents} from 'Store/Actions'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  allConsents: state.consents,
  auth: state.auth,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getConsents: () => dispatch(getConsents()),
})

export const ConsentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Consents)
