import {connect} from 'react-redux'

import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {
  getCompanyData,
  savePortfolio,
  saveEmploymentInformation,
  saveCompanyDescription,
  getCompanyDescriptionBiziSi,
  saveBenefits,
  getAdditionalBenefits,
  addImpressions,
  updateImpressions,
  addVideos,
  updateVideos,
} from 'Store/Actions'
import {
  EmploymentInformationItem,
  CompanyDescriptionItem,
  CompanyBenefitsSaveType,
  ImpressionsAddType,
  ImpressionsUpdateType,
  VideoType,
} from 'Interfaces'
import {CompanyProfile} from './CompanyProfile'

const mapStateToProps = (state: RootState) => ({
  taxonomies: state.taxonomies,
  company: state.company,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getTaxonomies: () => {
    dispatch(getAdditionalBenefits())
  },
  getCompanyData: (id: string) => dispatch(getCompanyData(id)),
  savePortfolio: (portfolio: string) => dispatch(savePortfolio(portfolio)),
  saveBenefits: (benefits: CompanyBenefitsSaveType) =>
    dispatch(saveBenefits(benefits)),
  saveCompanyDescription: (value: CompanyDescriptionItem) =>
    dispatch(saveCompanyDescription(value)),
  getCompanyDescriptionBiziSi: (value: string) =>
    dispatch(getCompanyDescriptionBiziSi(value)),
  saveEmploymentInformation: (value: EmploymentInformationItem) =>
    dispatch(saveEmploymentInformation(value)),
  addImpressions: (value: ImpressionsAddType[]) => {
    dispatch(addImpressions(value))
  },
  updateImpressions: (value: ImpressionsUpdateType[]) => {
    dispatch(updateImpressions(value))
  },
  addVideos: (value: VideoType[]) => {
    dispatch(addVideos(value))
  },
  updateVideos: (value: VideoType[]) => {
    dispatch(updateVideos(value))
  },
})

export const CompanyProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyProfile)
