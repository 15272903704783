import {
  ConsentType,
  EmployerConsentId,
  MeType,
  SeekerConsentId,
  UserType,
} from 'Interfaces'

export const mandatoryEmployerConsents = [
  EmployerConsentId.GeneralTermsOfUse,
  EmployerConsentId.PrivacyPolicy,
  EmployerConsentId.DataProtectionAgreement,
]

export const mandatorySeekerConsents = [
  SeekerConsentId.GeneralTermsOfUse,
  SeekerConsentId.TermsAndConditionsRjb,
  SeekerConsentId.PrivacyPolicy,
  SeekerConsentId.PrivacyPolicyRjbAnex,
]

export const hasGrantedMandatoryConsents = (userData?: MeType) => {
  if (userData) {
    let mandatoryConsents: ConsentType[] | undefined = []
    if (userData.type === UserType.SEEKER) {
      mandatoryConsents = userData.userConsents?.filter(
        (consent: ConsentType) =>
          [...mandatorySeekerConsents].includes(consent.id as SeekerConsentId)
      )
      return !mandatoryConsents?.filter(
        (consent: any) =>
          consent.consentVersions[0].userConsentsVersions.length === 0 ||
          consent.consentVersions[0].userConsentsVersions[0].status !==
            'granted'
      ).length
    }
    if (userData.type === UserType.EMPLOYER) {
      mandatoryConsents = userData.companyConsents?.filter(
        (consent: ConsentType) =>
          [...mandatoryEmployerConsents].includes(
            consent.id as EmployerConsentId
          )
      )
      return !mandatoryConsents?.filter(
        (consent: any) =>
          consent.consentVersions[0].companyConsentsVersions.length === 0 ||
          consent.consentVersions[0].companyConsentsVersions[0].status !==
            'granted'
      ).length
    }
  }
  return false
}
