import ReactPhoneInput from 'react-phone-input-2'
import {FC, FocusEvent, useRef, useState, useEffect} from 'react'
import {FormattedMessage} from 'react-intl'
import 'react-phone-input-2/lib/high-res.css'
import classnames from 'classnames'

import styles from './PhoneNumberField.module.scss'
import TextError from 'Components/Error/TextError'

interface PhoneNumberFieldProps {
  name: string
  value: string
  label?: string
  error?: string
  onBlur: (e: FocusEvent<HTMLInputElement>) => void
  placeholder?: string
  setValue: (name: string, value?: string | number) => void
  flagOnly?: boolean
}

export const PhoneNumberField: FC<PhoneNumberFieldProps> = ({
  value,
  name,
  label,
  error,
  onBlur,
  setValue,
  placeholder,
  flagOnly,
}) => {
  const widthContainer = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(widthContainer.current?.clientWidth)

  useEffect(() => {
    const resizeListener = () => {
      widthContainer.current && setWidth(widthContainer.current?.clientWidth)
    }
    setWidth(widthContainer.current?.clientWidth)
    window.addEventListener('resize', resizeListener)
    return () => {
      window.removeEventListener('resize', resizeListener)
    }
  }, [width])

  return (
    <div>
      <div ref={widthContainer} className={styles.container}>
        <div
          className={classnames({
            [styles.errorBorder]: error,
          })}
        >
          <ReactPhoneInput
            country={'si'}
            value={value}
            inputProps={{name: `${name}`}}
            preferredCountries={['si', 'at', 'ba', 'hr', 'de', 'rs']}
            searchPlaceholder={'+381'}
            inputClass={styles.input}
            placeholder={placeholder}
            buttonClass={styles.button}
            onBlur={onBlur}
            containerClass={styles.container}
            dropdownStyle={{width: width}}
            onChange={(value) => {
              setValue(name, `+${value}`)
            }}
            dropdownClass={styles.dropdown}
            autoFormat={flagOnly}
          />
        </div>
        <label
          className={classnames(styles.label, {
            [styles.error]: error,
          })}
        >
          {label && <FormattedMessage id={label} />}
        </label>
      </div>
      {error && <TextError text={error} />}
    </div>
  )
}
