import {connect} from 'react-redux'

import {EmployerOffers} from './EmployerOffers'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {
  getEmployerOffers,
  getSeekersFromOffers,
  loadMoreEmployerOffers,
} from 'Store/Actions'
import {EmployerRequestsSearchParamsType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  requests: state.employer.data.allRequests.offers,
  totalOfferRequests: state.employer.data.allRequests.offers.total,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getEmployerOffers: (params?: EmployerRequestsSearchParamsType) =>
    dispatch(getEmployerOffers(params)),
  loadMore: (url: string) => dispatch(loadMoreEmployerOffers(url)),
  getSeekersFromOffers: (activeTab?: string) =>
    dispatch(getSeekersFromOffers(activeTab)),
})

export const EmployerOffersContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerOffers)
