export const companyVideo = {
  'companyVideo.videoTitle': 'Videoposnetki',
  'companyVideo.featuredVideoTitle': 'Izpostavljen videoposnetek',

  'companyVideo.modalTitleEdit': 'Uredi videoposnetke',
  'companyVideo.modalTitleAdd': 'Dodajte Videoposnetke',
  'companyVideo.modalVideosTitle': 'VIDEOPOSNETKI',
  'companyVideo.modalVideoPlaceholderText':
    'Prilepite YouTube url povezavo ...',
  'companyVideo.modalVideoTitleTitle': 'IME VIDEOPOSNETKA',
  'companyVideo.showVideo': 'Dodaj video',
}
