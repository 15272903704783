import {connect} from 'react-redux'
import {AppDispatch} from 'Store'
import {
  addWelcomeMessage,
  editWelcomeMessage,
  getCompanyData,
  getJobCategories,
  getSeekerProfile,
  getSystemLanguages,
} from 'Store/Actions'
import {WelcomeMessage} from './WelcomeMessage'
import {WelcomeMessageDataFormikType, WelcomeMessageDataType} from 'Interfaces'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => {
  return {
    auth: state.auth,
    seekerJobCategory: state.seeker.data?.full?.jobCategory,
    company: state.company,
    taxonomies: state.taxonomies,
    welcomeMessages: state.company.data
      .welcomeMessages as WelcomeMessageDataType[],
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getTaxonomies: () => {
    dispatch(getJobCategories())
    dispatch(getSystemLanguages())
  },
  getCompanyData: () => dispatch(getCompanyData()),
  getSeekerProfile: () => dispatch(getSeekerProfile()),
  addWelcomeMessage: (value: WelcomeMessageDataFormikType) =>
    dispatch(addWelcomeMessage(value)),
  editWelcomeMessage: (value: WelcomeMessageDataFormikType) =>
    dispatch(editWelcomeMessage(value)),
})
export const WelcomeMessageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WelcomeMessage)
