import {connect} from 'react-redux'

import {AppDispatch} from 'Store'
import {reparseCv} from 'Store/Actions'
import {RootState} from 'Store/Reducers'
import {ParseCvModal} from './ParseCvModal'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  seeker: state.seeker,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  reparseCv: (fileId: string) => dispatch(reparseCv(fileId)),
})

export const ParseCvModalContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ParseCvModal)
