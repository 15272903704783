export const myProjects = {
  'myprojects.deleteProject': 'Ste prepričani, da želite izbrisati ta projekt?',
  'myprojects.createNewProject': 'Ustvari nov projekt',
  'myprojects.firstName': 'Ime',
  'myprojects.lastName': 'Priimek',
  'myprojects.jobTitle': 'Delovno mesto',
  'myprojects.resetSearch': 'Ponastavi iskanje',
  'myprojects.candidates': 'Kandidati',
  'myprojects.edit': 'Urejanje',
  'myprojects.archive': 'Arhiviraj',
  'myprojects.delete': 'Izbriši',
  'myprojects.save': 'Shrani',
  'myprojects.projectStatus': 'Status projekta',
  'myprojects.project': 'Projekt',
  'myprojects.open': 'Odpri',
  'myprojects.addNewProject': 'Dodaj nov projekt',
  'myprojects.projectName': 'Ime projekta',
  'myprojects.editProject': 'Urejanje Projektov',
  'myprojects.myProjectsTitle': 'Moji projekti',
  'myprojects.active': 'Aktiven',
  'myprojects.archived': 'Arhiviran',
  'myprojects.loading': 'Nalaganje...',
  'myprojects.searchCandidatesLink': 'Iskanje kandidatov',
}
