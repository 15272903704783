import {FC} from 'react'

import Rating from '@mui/material/Rating'
import StarIcon from '@mui/icons-material/StarRate'

import styles from './ContentItem.module.scss'
import {DateTime} from 'luxon'

interface ContentItemProps {
  dateCreated?: string
  body: string
  id: string
  evaluation?: number
  dateSent: string
  senderFirstName?: string
  senderLastName?: string
}

export const ContentItem: FC<ContentItemProps> = ({
  dateCreated,
  body,
  evaluation,
  dateSent,
  senderFirstName,
  senderLastName,
}) => {
  return (
    <div className={styles.contentItem}>
      <p>
        {`${DateTime.fromISO(!dateCreated ? dateSent : dateCreated).toFormat(
          'd.L.yyyy hh:mm'
        )} `}
        {senderFirstName && (
          <span className={styles.italicText}>
            by {`${senderFirstName} ${senderLastName}`}
          </span>
        )}
      </p>
      <p>{body}</p>
      {evaluation && (
        <div className={styles.evaluationContainer}>
          <Rating
            emptyIcon={<StarIcon className={styles.grayStarIcon} />}
            name="read-only"
            value={evaluation}
            readOnly
          />
        </div>
      )}
    </div>
  )
}
