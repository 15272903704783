import {FC, KeyboardEvent} from 'react'

import TextField from '@mui/material/TextField'
import {AdapterLuxon} from '@mui/x-date-pickers/AdapterLuxon'
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider'
import {DatePicker as DatePickerField} from '@mui/x-date-pickers/DatePicker/'
import TextError from 'Components/Error/TextError'
import classNames from 'classnames'
import styles from './DatePicker.module.scss'
import {DateTime} from 'luxon'

interface DatePickerProps {
  label?: string
  value?: string | null
  formik: any
  error?: string
  formikFieldName: string
}

export const DatePicker: FC<DatePickerProps> = ({
  label,
  value,
  formik,
  error,
  formikFieldName,
}: DatePickerProps) => {
  const onKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    event.preventDefault()
  }

  return (
    <div
      className={classNames(styles.datePickerContainer, {
        [styles.datePickerContainerError]: error,
      })}
    >
      <LocalizationProvider dateAdapter={AdapterLuxon}>
        <DatePickerField
          InputProps={{className: styles.datePicker}}
          label={label}
          value={value}
          onChange={(newValue: DateTime | null) => {
            const date = newValue?.toUTC().toISO()
            formik.setFieldValue(formikFieldName, date)
          }}
          renderInput={(params) => (
            <TextField
              onKeyDown={onKeyDown}
              {...params}
              className={styles.textField}
            />
          )}
          disableMaskedInput
          inputFormat="dd/MM/yyyy"
        />
      </LocalizationProvider>
      {error && <TextError text={error} />}
    </div>
  )
}
