export const onboarding = {
  'onboarding.step': 'Step',
  'onboarding.of': 'of',
  'onboarding.titleCV': 'Your CV',
  'onboarding.descriptionCV': 'Fill in your basic profile and interests.',
  'onboarding.titleOccupationalFields': 'Occupational fields',
  'onboarding.buttonNext': 'Next',
  'onboarding.buttonBack': 'Back',
  'onboarding.titleUploadCV': 'UPLOAD YOUR CV',
  'onboarding.cvDisclaimer':
    "You can upload your CV below. We will parse the data from it and enter it into your profile in a structured way. All data entered this way can be changed or deleted later. If you don't have a CV, you can upload it later and fill out your profile manually.",
  'onboarding.uploadFileFormats':
    'Supported file formats: .doc, .docx, .pdf. (Max size: 5 MB)',
  'onboarding.uploadFileFormatsImage':
    'Supported file formats: .jpg, .jpeg, .png, .gif. (Max size: 5 MB)',
  'onboarding.uploadDragAndDrop': 'Drag & Drop your files here',
  'onboarding.uploadOr': 'or',
  'onboarding.uploadBrowseFiles': 'Browse files',
  'onboarding.uploadGoogleDrive': 'Google Drive',
  'onboarding.uploadiCloud': 'iCloud',
  'onboarding.uploadDropbox': 'Dropbox',
  'onboarding.uploadOneDrive': 'OneDrive',
  'onboarding.uploadNoFile': 'No file',
  'onboarding.uploadRemove': 'Remove',
  'onboarding.titleJobPreferences': 'Job preferences',
  'onboarding.descriptionJobPreferences': 'What is your job preferences?',
  'onboarding.titleDesiredRegions': 'Desired regions',
  'onboarding.titleWorkFromHome': 'WORK FROM HOME',
  'onboarding.titleDesiredSalary': 'Desired salary',
  'onboarding.titleAvailability': 'AVAILABILITY TO START A NEW JOB',
  'onboarding.titleWorkExperience': 'Work Experience',
  'onboarding.titleTotalWorkExperience': 'Total work Experience',
  'onboarding.titleAddWorkExperience': 'Add Work Experience',
  'onboarding.titleEditWorkExperience': 'Edit Work Experience',
  'onboarding.descriptionWorkExperience':
    'What is your work experience & skills?',
  'onboarding.titleTotalYearsWorkExperience': 'TOTAL YEARS OF WORK EXPERIENCE',
  'onboarding.titleExperiencePerPosition':
    'RELEVANT YEARS OF EXPERIENCE PER POSITION',
  'onboarding.typeAheadEnterPosition': 'Enter your position ...',
  'onboarding.titleSkills': 'SKILLS',
  'onboarding.selectMultiValue': 'Select multi-value',
  'onboarding.selectTypeYourSkills': 'Type your skills',
  'onboarding.titleBenefitsAndEducation': 'Benefits & Education',
  'onboarding.descriptionBenefitsAndEducation':
    'What are your expectations and education?',
  'onboarding.titleWorkEnvironment': 'YOUR DESIRED WORK ENVIRONMENT',
  'onboarding.placeholderTextWorkEnvironment':
    'Example: I wish to work in a relaxed atmosphere, where there is a lot of team work...',
  'onboarding.titleAdditionalBenefits': 'Additional benefits of employers',
  'onboarding.titleEducation': 'Education',
  'onboarding.titleAddEducation': 'Add Education',
  'onboarding.titleEditEducation': 'Edit Education',
  'onboarding.dropDownSelectFromList': 'Select from list',
  'onboarding.titleFieldOfStudy': 'FIELD OF STUDY',
  'onboarding.titleCurrentSalary': 'Current salary',
  'onboarding.sideBarYourCV': 'Your CV',
  'onboarding.sideBarJobPreferences': 'Job preferences',
  'onboarding.sideBarWorkExperience': 'Work experience',
  'onboarding.sideBarBenefitsAndEducation': 'Benefits & Education',
  'onboarding.titleDesiredJobPositions': 'Desired job positions',
  'onboarding.titleJobSearchStatus': 'Job search status',
  'onboarding.titleAreYouSureModal': 'Warning',
  'onboarding.descriptionAreYouSureModal':
    'Are you sure you want to discard the changes you made?',
  'onboarding.modalSaveChanges': 'Save changes',
  'onboarding.modalDiscard': 'Discard',
  'onboarding.typeAheadSkills': 'Skills',
  'onboarding.typeAheadTypeYour': 'Type your',
  'onboarding.typeAheadTypeYourSkills': 'Type your Skills',
  'onboarding.apiErrorMessage':
    'Ooops, something went wrong! Please try again or proceed to the next step and enter information later.',
  'onboarding.continueWithoutParsedCV': 'Continue without parsed CV',
  'onboarding.tryAgain': 'Try again',
  'onboarding.cvParsing': 'CV parsing',
  'onboarding.other': 'Other',
  'onboarding.otherJobPositionPlaceholder': 'Enter desired job position',
  'onboarding.placeholderCurrentJobTitle': 'Please enter current job title',
  'onboarding.currentJobTitle': 'CURRENT JOB TITLE',
  'onboarding.agree': 'I agree to the ',
  'onboardging.generalTerms': 'General terms ',
  'onboardging.and': 'and ',
  'onboardging.termsAndConditions':
    'Terms and conditions of use on Preferenca.si ',
  'onboardging.privacyPolicy': 'Privacy and personal data protection policy ',
  'onboarding.thirdPartyAgreement':
    'I agree to the processing of my personal data for the creation of a user profile on the online portal & transactional email Preferenca.si in order to provide services intended for registered users. The Services of the web portal & transactional email also include e-mails related to the operation of the web portal & transactional emails.',
  'onboarding.fileTooLarge':
    "The file you've uploaded is too big. Please upload file smaller than",
}
