import {FC, MouseEventHandler, useEffect, useRef, useState} from 'react'
import {useMediaQuery} from 'react-responsive'
import qs from 'qs'

import MainHeader from 'Components/MainHeader'
import {TestMicrophoneCameraNotificationContent} from 'Components/Assessments/TestMicrophoneCameraNotificationContent'
import YellowButton from 'Components/YellowButton'
import {WhiteBoxWrapper} from 'Components/WhiteBoxWrapper'
import {
  AssessmentsStateType,
  ConsentType,
  MeType,
  SeekerConsentId,
  Test,
  UserConsentsVersionsType,
} from 'Interfaces'
import CognitiveIcon from 'Assets/Images/cognitive-tests.svg'
import {useTranslation} from 'Hooks'
import {
  AppRoute,
  CognitiveAssessmentsTranslations as cat,
  GeneralAssessmentsTranslations as gat,
  SidebarTranslations as st,
} from 'Services/I18n/Constants'
import {getRoute} from 'Services/I18n/Utils'
import {DownloadAndPrintHeader} from 'Components/DownloadAndPrintHeader'
import {MobileFooter} from 'Components/MobileFooter'
import FooterAssessments from 'Components/Footer'
import MainLayout from 'Layouts/MainLayout'
import {SidebarLink} from 'Components/SidebarLink'
import {getChannel} from 'Utils/CommonHelpers'
import {baseURL} from 'Services/API/API'
import {Link} from 'react-router-dom'

import styles from './StartCognitiveTest.module.scss'

interface StartCognitiveTestProps {
  getCognitiveTestResults: (value: string) => void
  downloadLink: {
    url: string
  }
  assessments: AssessmentsStateType
  getAssessments: () => void
  consents: ConsentType[]
  getConsents: Function
  user: MeType | undefined
  changeCognitiveTestStatus: (testId: string) => void
}

export const StartCognitiveTest: FC<StartCognitiveTestProps> = ({
  consents,
  getCognitiveTestResults,
  downloadLink,
  assessments,
  getAssessments,
  getConsents,
  user,
  changeCognitiveTestStatus,
}) => {
  const [resultButtonClicked, setResultButtonClicked] = useState(false)
  const cognitiveTest: Test = assessments.data.items.filter((test: Test) => {
    return test.name === 'Cognitive test'
  })[0]
  const [cognitiveConsent, setCognitiveConsent] = useState<
    undefined | UserConsentsVersionsType
  >()

  const isMobile = useMediaQuery({
    query: '(max-width: 900px)',
  })

  const params = qs.parse(location.search, {ignoreQueryPrefix: true})

  const translation = useTranslation()

  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: getRoute(AppRoute.HomePageCandidate)},
    {name: translation[gat.title], link: getRoute(AppRoute.Assessments)},
    {name: translation[cat.title]},
  ]

  const links = [
    {
      name: translation[st.editPublicProfile],
      route: AppRoute.PublicProfile,
    },
    {
      name: translation[st.editCv],
      route: AppRoute.Profile,
    },
  ]

  const Footer: FC = () => {
    return (
      <>{isMobile ? <MobileFooter links={links} /> : <FooterAssessments />}</>
    )
  }

  const linkRef = useRef<HTMLAnchorElement | null>(null)

  useEffect(() => {
    // download hr potentials test results
    if (
      linkRef &&
      linkRef.current &&
      downloadLink &&
      downloadLink.url &&
      resultButtonClicked
    ) {
      linkRef.current.click()
    }
  }, [downloadLink])

  useEffect(() => {
    getAssessments()
    getCognitiveTestResults('')
    getConsents()
  }, [])

  useEffect(() => {
    if (consents && consents.length > 0) {
      const cognitiveTest = consents.find((consent) => {
        return consent.id === SeekerConsentId.CognitiveTest
      })

      setCognitiveConsent(
        cognitiveTest?.consentVersions[0].userConsentsVersions.find(
          (cognitiveConsent) => {
            return cognitiveConsent?.company === null
          }
        )
      )
    }
  }, [consents])

  const handleAnchorOnClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.stopPropagation()
    changeCognitiveTestStatus(params.testId as string)
  }

  const buttonContent = (
    status: string | undefined,
    testId: string | undefined
  ) => {
    if (status === 'completed' && cognitiveConsent?.status === 'granted') {
      return (
        <Link to={AppRoute.CognitiveTest}>
          <YellowButton text={gat.viewResultsText} />
        </Link>
      )
    } else if (
      status === 'completed' &&
      cognitiveConsent?.status === 'revoked'
    ) {
      return
    } else if (status === 'results_pending') {
      return (
        <YellowButton
          text={translation[gat.pendingButtonText]}
          className={styles.pendingButton}
        />
      )
    } else {
      if (testId) {
        return (
          <a
            onClick={handleAnchorOnClick}
            href={`${baseURL}/assessment/start-hr-potentials-assessment/${
              params.testId
            }/${user?.id}?languageId=${getChannel().channelId}&tenantId=${
              getChannel().tenantId
            }&consentId=${params.consentId}&channelId=${
              getChannel().channelId
            }`}
            target="_blank"
          >
            <YellowButton text={translation[gat.startTestText]} />
          </a>
        )
      }
      return
    }
  }

  return (
    <MainLayout
      withoutLogo
      content={
        <div className={styles.linksWrap}>
          <SidebarLink
            text={st.editPublicProfile}
            routeName={AppRoute.PublicProfile}
          />
          <SidebarLink text={st.editCv} routeName={AppRoute.Profile} />
        </div>
      }
      Footer={<Footer />}
    >
      <div className={styles.testContainer}>
        <DownloadAndPrintHeader
          setResultButtonClicked={setResultButtonClicked}
          downloadLink={
            cognitiveTest?.status === 'completed' &&
            downloadLink.url &&
            cognitiveConsent?.status === 'granted'
              ? downloadLink.url
              : undefined
          }
        />
        <MainHeader
          breadcrumbsArray={breadcrumbsArray}
          title={translation[cat.title]}
          className={styles.cognitiveTestHeader}
          image={
            <img
              className={styles.cognitiveIcon}
              src={CognitiveIcon}
              alt={translation[cat.title]}
            />
          }
        />
        <h2 className={styles.title}>
          Thanks for granting the consent, you will be redirected to the test
          page.
        </h2>
        <div className={styles.testMicrophoneCameraNotificationWrapper}>
          <WhiteBoxWrapper
            children={<TestMicrophoneCameraNotificationContent />}
          />
        </div>
        <div className={styles.buttonWrapper}>
          <div className={styles.buttonContainer}>
            {buttonContent(cognitiveTest?.status, cognitiveTest?.id)}
          </div>
        </div>
      </div>
    </MainLayout>
  )
}
