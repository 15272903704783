import {connect} from 'react-redux'

import {ChangeEmail} from './ChangeEmail'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {changeEmail, hideChangeEmailMessage} from 'Store/Actions'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  changeEmail: (email: string) => dispatch(changeEmail(email)),
  hideChangeEmailMessage: () => dispatch(hideChangeEmailMessage()),
})

export const ChangeEmailContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeEmail)
