import {ChangeEvent, FC, useEffect, useState, FocusEvent} from 'react'
import classnames from 'classnames'
import {FormattedMessage} from 'react-intl'

import styles from './DropdownMultiple.module.scss'
import {BaseTaxonomyType} from 'Interfaces'
import {CheckBoxIcon} from 'Components/Icons/CheckBoxIcon'
import TextError from 'Components/Error/TextError'
import {ArrowDropDown} from '@mui/icons-material'
import {
  MessageCenterTranslations as mct,
  EmployerDashboardTranslations as edt,
} from 'Services/I18n/Constants'

interface Props {
  name: string
  values?: string[]
  items: BaseTaxonomyType[]
  setValues: (name: string, values: string[]) => void
  error?: string
  label?: string
  theme?: 'grey' | 'white' | 'blue' | 'blue-left' | 'white-right'
  className?: string
  emptyValueLabel?: string
  selectAll?: boolean
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  listUp?: boolean
  forceLabel?: boolean
  disabled?: boolean
  selectAllWithoutClear?: boolean
}

export const DropdownMultiple: FC<Props> = ({
  name,
  items,
  values,
  setValues,
  error,
  label,
  className,
  theme = 'grey',
  emptyValueLabel = 'Select from list',
  selectAll = false,
  onBlur,
  listUp,
  forceLabel = false,
  disabled = false,
  selectAllWithoutClear = false,
}) => {
  const [open, setOpen] = useState(false)
  const [localValues, setLocalValues] = useState(values || [])

  useEffect(() => {
    setLocalValues(values || [])
  }, [values])

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value
    const index = localValues.findIndex((l) => l === newValue)
    if (index > -1) {
      const newValues = [...localValues]
      newValues.splice(index, 1)
      setLocalValues(newValues)
      setValues(name, newValues)
    } else {
      const newValues = [...localValues, newValue]
      setLocalValues(newValues)
      setValues(name, newValues)
    }
  }

  const handleSelectAll = () => {
    setValues(
      name,
      items.map((i) => i.id)
    )
  }

  const handleClearAll = () => {
    setValues(name, [])
  }

  return (
    <div
      className={classnames(
        styles.dropdownContainer,
        styles[theme],
        {
          [styles.open]: open,
          [styles.disabled]: disabled,
        },
        className
      )}
      tabIndex={0}
      onBlur={() => {
        setOpen(false)
      }}
    >
      <div
        onClick={() => {
          setOpen(!open)
        }}
        className={classnames(styles.dropdownBTN, {
          [styles.errorBorder]: error,
        })}
      >
        <div className={styles.selected}>
          {label && (
            <p
              className={classnames(styles.dropdownLabel, {
                [styles.error]: error,
              })}
            >
              {label}
            </p>
          )}
          {!localValues?.length || forceLabel ? (
            <p className={styles.dropdownValues}>
              {emptyValueLabel && <FormattedMessage id={emptyValueLabel} />}
            </p>
          ) : (
            <p className={styles.dropdownValues}>
              {localValues.map((v, i) => {
                const index = items.findIndex((i) => i.id === v)
                return index > -1 ? (
                  <span key={items[index].id}>
                    {`${items[index].translation}${
                      i + 1 === localValues.length ? '' : ', '
                    }`}
                  </span>
                ) : null
              })}
            </p>
          )}
        </div>
        <ArrowDropDown
          className={classnames(styles.arrow, {
            [styles.rotate]: open,
          })}
        />
      </div>
      <div
        className={classnames(styles.dropdownList, {
          [styles.open]: open,
          [styles.selectAll]: selectAll,
          [styles.listUp]: listUp,
        })}
      >
        <div className={styles.dropdownListScrollWrap}>
          {selectAll && (
            <div className={styles.selectAllWrap}>
              <label className={styles.itemLabel}>
                <CheckBoxIcon checked={localValues.length === items.length} />
                <input
                  onChange={handleSelectAll}
                  className={styles.hidden}
                  type="checkbox"
                  name={`${name}SelectAll`}
                  checked={localValues.length === items.length}
                />

                <span className={styles.translation}>
                  <FormattedMessage
                    id={selectAllWithoutClear ? edt.all : mct.selectAll}
                  />
                </span>
              </label>
              {!selectAllWithoutClear && (
                <label className={styles.itemLabel}>
                  <CheckBoxIcon checked={localValues.length === 0} />
                  <input
                    onChange={handleClearAll}
                    className={styles.hidden}
                    type="checkbox"
                    name={`${name}ClearAll`}
                    checked={localValues.length === 0}
                  />

                  <span className={styles.translation}>
                    <FormattedMessage id={mct.clearAll} />
                  </span>
                </label>
              )}
            </div>
          )}
          {items.map((item, index) => (
            <div className={styles.container} key={`${item.id}-${index}`}>
              <label className={styles.itemLabel}>
                <CheckBoxIcon
                  checked={
                    !!localValues?.length &&
                    localValues.findIndex((v) => v === item.id) > -1
                  }
                />
                <input
                  onChange={handleChange}
                  className={styles.hidden}
                  type="checkbox"
                  name={name}
                  value={item.id}
                  multiple
                  onBlur={onBlur}
                />
                <span className={styles.translation}>{item.translation}</span>
              </label>
            </div>
          ))}
        </div>
      </div>
      {error && <TextError text={error} />}
    </div>
  )
}
