import {connect} from 'react-redux'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {getMyWidgetProjects} from 'Store/Actions'
import {MyProjects} from './MyProjects'

const mapStateToProps = (state: RootState) => ({
  projects: state.myProjects.widgetProjects.data,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getProjects: () => dispatch(getMyWidgetProjects()),
})

export const MyProjectsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyProjects)
