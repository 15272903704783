import Navigation from 'Components/CompanyProfile/Navigation'
// import {SocialIcons} from 'Components/SocialIcons/SocialIcons'
import {Link} from 'react-router-dom'
import {footerItems} from './default'

import styles from './Footer.module.scss'
import classNames from 'classnames'
interface INavigationItemType {
  pageName: string
  props: {
    to: string
    target?: string
    rel?: string
  }
}
interface IFooterProps {
  navigationItems?: INavigationItemType[]
  year?: string
  className?: string
}

const currentYear = new Date().getFullYear().toString()

export const Footer: React.FC<IFooterProps> = ({
  navigationItems = footerItems,
  year = currentYear,
  className,
}) => {
  const listContent = () => {
    return navigationItems.map((navigationItem, index) => {
      return (
        <Link {...navigationItem.props} key={index}>
          <li key={`${navigationItem.pageName}-${index}`}>
            {navigationItem.pageName}
            {index !== navigationItems.length - 1 && <span>|</span>}
          </li>
        </Link>
      )
    })
  }

  return (
    <div className={classNames(className, styles.footerContainer)}>
      <div className={styles.copyrightNavigationContainer}>
        <div className={styles.copyright}>©{year}, &nbsp;</div>
        <Navigation
          listContent={listContent}
          className={styles.footerNavigation}
        />
      </div>
      {/*<SocialIcons />*/}
    </div>
  )
}
