import {connect} from 'react-redux'

import {SettingsMenu} from './SettingsMenu'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  profile: state.seeker.data?.full,
})

export const SettingsMenuContainer = connect(mapStateToProps)(SettingsMenu)
