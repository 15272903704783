import {FC, ChangeEvent} from 'react'
import styles from './SwitchContainer.module.scss'

interface SwitchContainerProps {
  checked: boolean
  handleChecked: (e: ChangeEvent<HTMLInputElement>) => void
}

export const SwitchContainer: FC<SwitchContainerProps> = ({
  checked,
  handleChecked,
}) => {
  return (
    <div className={styles.switchContainer}>
      <label className={styles.switch}>
        <input onChange={handleChecked} type="checkbox" checked={checked} />
        <span className={`${styles.slider} ${styles.round}`}></span>
      </label>
    </div>
  )
}
