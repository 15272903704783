import {useMemo} from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'

export const useActiveRoute = (route: string) => {
  const location = useLocation()
  const {locale} = useIntl()
  const activeRoute = useMemo(() => route, [locale, route])
  const isActive = useMemo(
    () => location.pathname === activeRoute,
    [activeRoute, locale, location]
  )

  return {activeRoute, isActive}
}
