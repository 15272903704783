import {ApproveRejectModalRequestType} from 'Interfaces'
import {connect} from 'react-redux'

import {AppDispatch} from 'Store'
import {rejectPlacement} from 'Store/Actions'
import {ModalRejectPlacementConfirmation} from './ModalRejectPlacementConfirmation'
import {RootState} from 'Store/Reducers'

interface PlacementConfirmationProps {
  rejectModal: boolean
  setRejectModal: (modal: boolean) => void
}

const mapStateToProps = (
  state: RootState,
  ownProps: PlacementConfirmationProps
) => ({
  rejectModal: ownProps.rejectModal,
  setRejectModal: ownProps.setRejectModal,

  state: state,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  rejectPlacementConfirmation: (payload: ApproveRejectModalRequestType) =>
    dispatch(rejectPlacement(payload)),
})

export const ModalRejectPlacementConfirmationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalRejectPlacementConfirmation)
