export const support = {
  'support.title': 'Help & Support',
  'support.ctaTitle': 'Browse',
  'support.ctaText': 'Find answers to your questions.',
  'support.ctaTitle2': 'Send message',
  'support.ctaText2': 'We typically respond in 24 hours.',
  'support.ctaTitle3': 'Call us',
  'support.ctaText3': 'Workdays',
  'support.title2': 'Frequently asked questions',
  'support.cardTitle': 'General',
  'support.cardQuestion1': 'What is Preferenca?',
  'support.cardAnswer1':
    'Preferenca.si is a platform that connects talents and companies in a way that companies compete for candidates, not the other way around. This provides a completely new experience to the best candidates while also enabling a quick and efficient employment process for businesses.',
  'support.cardAnswer1SecondPart':
    'On the platform, pre- verified candidates present themselves with their profiles, where they show their experience, knowledge and aspirations. On the other end of the spectrum, companies receive access to a list of suitable candidates with whom they can communicate directly and conduct companies.',
  'support.cardQuestion2': 'Can I publish a job listing on your platform?',
  'support.cardQuestion3': 'How does Preferenca work?',
  'support.cardAnswer3':
    'Preferenca.si is a bilateral marketplace, designed to provide an unparalleled experience for the best candidates, while enabling companies to recruit quickly and efficiently. On the platform, pre-vertified candidates present themselves with their profiles, where they show their experience, knowledge and aspirations. Companies set out their employment criteria and receive access to a list of suitable candidates with whom they can communicate and interview directly.',
  'support.cardAnswer3SecondPart':
    'As the name suggests, this is a recruitment model in which companies apply for the profiles of the presented candidates, which replaces the traditional method where candidates apply for open positions.',
  'support.cardQuestion4': 'Prednosti portala Preferenca.si',
  'support.cardAnswer4EmployersTitle': 'Advantages for employers: ',
  'support.cardAnswer4EmployersText':
    "Having only the best candidates on the platform means that you don't have to waste time on unqualified or inadequate candidates. In addition, all candidates who have been accepted on the platform are actively seeking for their next employment opportunity, making them more responsive throughout the hiring process. This significantly reduces the time investment of recruiters; suitable candidates who are more responsive to interview requests can be found quicker.",
  'support.cardAnswer4CandidatesText':
    'The portal operates with complete anonymity and respects privacy until the moment you agree to publish your information. You collaborate only with verified and the best Slovenian employers who want to connect with you because of your skills and potential. You control your career and decide whether to disclose your contact information or not, while not jeopardizing your current job.',
  'support.cardAnswer4CandidatesTitle': 'Advantages for candidates: ',
  'support.card2Title': 'For candidates',
  'support.card2Question1': 'What resume formats does the platform support?',
  'support.card2Question2':
    "I completed my profile but didn't get 100%, what does this mean?",
  'support.card2Question3': 'Is my profile visible to my current employer?',
  'support.card3Title': 'For employers',
  'support.card3Question1': 'What is the most effective approach for talent...',
  'support.card3Question2': 'How to build employer branding on the ...',
  'support.card3Question3': 'What types of candidates would best ma...',
  'support.viewAll': 'View All',
  'support.card1': 'Change the rules of the employment game.',
  'support.card2': 'Why to employ with Preferenca?',
  'support.more': 'MORE',
}
