export const candidateSearch = {
  'savedSearch.modalTitle': 'Save or subscribe to search ',
  'savedSearch.editModalTitle': 'Edit Saved search',
  'savedSearch.saveUpdateTitle': 'Save or update existing search',
  'savedSearch.title': 'Saved search',
  'savedSearch.inputPlaceholder': 'Name your search...',
  'savedSearch.addToFavorites': 'Add to search favorites',
  'savedSearch.frequencyTitle': 'Frequency',
  'savedSearch.frequencyDescription':
    'Notify me via email when there are new results',
  'savedSearch.selectFrequency': 'Select frequency',
  'savedSearch.updateSearch': 'Update search',
  'savedSearch.saveSearch': 'Save search',
  'savedSearch.pageTitle': 'Saved resume searches',
  'savedSearch.columnHeaderName': 'Saved search',
  'candidateSearch.savedSearchDeleteModal':
    'Are you sure you want to delete this saved search?',
  'savedSearch.columnHeaderCreated': 'Created',
  'savedSearch.columnHeaderFavorites': 'Favorites',
  'savedSearch.columnHeaderNotifications': 'Notifications',
  'savedSearch.columnHeaderLastCSASent': 'Last CSA sent',
  'savedSearch.columnHeaderActions': 'Actions',
  'savedSearch.existingTitle': 'Select existing search',
  'savedSearch.overwriteWarning': 'This will overwrite existing search.',
  'savedSearch.newSaveSearch': 'Create new saved search',
  'savedSearch.updateSaveSearch': 'Update existing saved search',
  'candidateSearch.mainTitle': 'Candidate Search',
  'candidateSearch.mainPlaceholder': 'Type key words...',
  'candidateSearch.title': 'No candidates',
  'candidateSearch.text': '',
  'candidateSearch.optionFirst': 'Reset filters',
  'candidateSearch.optionSecond': ' Activate Candidate Search Agent',
  'candidateSearch.optionLast': 'View Popular Candidates',
  'candidateSearch.removeButton': 'REMOVE',
  'candidateSearch.modalMainTitle': 'Save to project',
  'candidateSearch.modalTitleSecond': 'CREATE NEW PROJECT',
  'candidateSearch.textPlaceholder': 'Project name',
  'candidateSearch.saveOrSubButton': 'Save or subscribe to search',
  'candidateSearch.narrowResults': 'Narrow results',
  'candidateSearch.sortFirst': 'Sort by relevance',
  'candidateSearch.sortSecond': 'Sort by Firstname',
  'candidateSearch.sortThird': 'Sort by Lastname',
  'candidateSearch.sortFourth': 'Sort by date modified',
  'candidateSearch.removeModalTitle': 'Remove from project',
  'candidateSearch.removeModalPlaceholder': 'Find your project ...',
  'candidateSearch.locationPlaceholder': 'Type location...',
  'candidateSearch.locationLabel': 'Location',
  'candidateSearch.mainLabel': 'Key words:',
  'candidateSearch.mainButton': 'Search',
  'candidateSearch.personaDropdown': 'Job category:',
  'candidateSearch.personaDropdownPlaceholder': 'Pick job category',
  'candidateSearch.positionDropdown': 'Job position',
  'candidateSearch.regionsDropdown': 'Region:',
  'candidateSearch.regionsDropdownPlaceholder': 'Choose region...',
  'candidateSearch.degreeDropdown': 'Education level',
  'candidateSearch.languageDropdown': 'Language',
  'candidateSearch.languageLvLDropdown': 'Language level',
  'candidateSearch.skillsDropdown': 'Skills',
  'candidateSearch.skillsLvlDropdown': 'Skill levels',
  'candidateSearch.carierDropdown': 'Career status',
  'candidateSearch.carierLvlDropdown': 'Career level',
  'candidateSearch.assessmentsDropdown': 'Assessments',
  'candidateSearch.dLDropdown': 'Driving license',
  'candidateSearch.mdrjbDropdown': 'Moje delo / RJB',
  'candidateSearch.currentFilter': 'Current search',
  'candidateSearch.clearForm': 'Clear form',
  'candidateSearch.minimizeSearch': 'Minimize search',
  'candidateSearch.maximizeSearch': 'Maximize search',
  'candidateSearch.selectButton': 'Select all',
  'candidateSearch.contactRequst': 'Send contact request',
  'candidateSearch.errorMsg': 'Error occured:',
  'candidateSearch.txtField':
    'Unfortunately, your search did not return any results.',
  'candidateSearch.addProject': 'ADD TO PROJECT',
  'candidateSearch.noProject': 'No projects for that search',
  'candidateSearch.removeProject': 'Remove from all projects',
  'candidateSearch.createTitle': 'CREATE NEW PROJECT',
  'candidateSearch.projectName': 'Project name',
  'candidateSearch.saveProject': 'Save to project',
  'candidateSearch.removeIndividuallProject': 'Remove from project',
  'candidateSearch.findProject': 'Find your project ...',
  'candidateSearch.careerStatus': 'Career level:',
  'candidateSearch.ageLabel': 'Age:',
  'candidateSearch.availabilityLabel': 'Availability:',
  'candidateSearch.contactButton': 'Contact',
  'candidateSearch.showMoreButton': 'Show more ...',
  'candidateSearch.showLessButton': 'Show less ...',
  'candidateSearch.occupationFacet': 'Job position',
  'candidateSearch.languagesFacet': 'Language',
  'candidateSearch.languageLevelFacets': 'Language level',
  'candidateSearch.skillsFacet': 'Skills',
  'candidateSearch.workExperienceFacet': 'Work experience',
  'candidateSearch.drivingLicenseFacet': 'Driving license',
  'candidateSearch.homeLocation': 'Home location:',
  'candidateSearch.topSkills': 'Top 5 skills:',
  'candidateSearch.skillLevelFacet': 'Skill levels',
  'candidateSearch.relevantYears': 'Relevant years of experiences:',
  'candidateSearch.searchButton': 'Search Results',
  'candidateSearch.addNoteButton': 'Add note',
  'candidateSearch.pendingRequest': 'Pending request',
  'candidateSearch.contactRequestSentButton': 'Contact request sent',
  'candidateSearch.contactRequestPopoverTitle': 'Contact request',
  'candidateSearch.contactRequestExpired': 'Already sent and expired on',
  'candidateSearch.contactRequestRejected': 'Already sent and rejected on',
  'candidateSearch.contactRequestRejectedWithoutDate':
    'Already sent and rejected',
  'candidateSearch.radiusButton': 'Radius:',
  'candidateSearch.removeTitleFirstPart': 'REMOVE',
  'candidateSearch.removeTitleSecondPart': 'FROM PROJECT',
  'candidateSearch.saveTitleFirstPart': 'SAVE',
  'candidateSearch.saveTitleSecondPart': 'TO PROJECT',
  'candidateSearch.typeLabel': 'Type...',
  'candidateSearch.commentsTitle': ' Comments',
  'candidateSearch.messagesTite': 'Messages',
  'candidateSearch.submitButton': 'Submit',
  'candidateSearch.commLabel': 'No comments at the moment.',
  'candidateSearch.messageLabel': 'No messages at the moment.',
  'candidateSearch.careerStatusFacets': 'Career status',
  'candidateSearch.availabilityFacets': 'Availability',
  'candidateSearch.currentJobCategoryFacets': 'Job category',
  'candidateSearch.educationLevelFacets': 'Education level',
  'candidateSearch.enterFirstName': 'Enter first name ...',
  'candidateSearch.enterLastName': 'Enter last name ...',
  'candidateSearch.match': 'Match',
  'candidateSearch.markAsReviewedButton': 'Mark as reviewed',
  'candidateSearch.markAsUnreviewedButton': 'Mark as unreviewed',
  'candidateSearch.loadingCandidatesText':
    'We are looking for the perfect fit...',
  'candidateSearch.years': 'years',
  'candidateSearch.year': 'year',
  'candidateSearch.myProjects': 'My projects',
  'candidateSearch.findYourProject': 'Find your project...',
  'candidateSearch.viewMoreProjects': 'View more projects',
  'candidateSearch.editCVsubscriptions': 'Edit CV subscriptions',
  'candidateSearch.candidateSearchLink': 'Search candidates',
  'candidateSearch.dashboardLink': 'Go to Dashboard',
  'candidateSearch.sendContactRequest': 'Send contact request',
  'candidateSearch.newCandidateBadgeFirstPart': 'NEW',
  'candidateSearch.newCandidateBadgeSecondPart': 'CANDIDATE',
  'candidateSearch.availableNowBadgeFirstPart': 'AVAILABLE',
  'candidateSearch.availableNowBadgeSecondPart': 'NOW',
  'candidateSearch.findSimilarCandidate': 'Find similar candidate',
}
