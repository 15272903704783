interface INavigationProps {
  listContent: Function
  className?: string
}

export const Navigation: React.FC<INavigationProps> = ({
  listContent,
  className,
}) => {
  return (
    <nav>
      <ul className={className}>{listContent()}</ul>
    </nav>
  )
}
