import EmployerJourneyTracker from 'Components/EmployerDashboard/EmployerJourneyTracker'
import MainLayout from 'Layouts/MainLayout'
import {FC, useEffect, useMemo} from 'react'
import styles from './EmployerDashboard.module.scss'
import {
  AuthStateType,
  Candidate,
  EmployerRequestsStateType,
  IncomingRequestSeekerType,
} from 'Interfaces'
import SidebarActions from 'Components/EmployerDashboard/Sidebar/SidebarActions'
import {WidgetBarContainer as WidgetBar} from 'Layouts/MainLayout/WidgetBar'
import MyOffers from 'Components/Widgets/MyOffers'
import {getRoute} from 'Services/I18n/Utils'
import {
  AppRoute,
  EmployerDashboardTranslations as edt,
} from 'Services/I18n/Constants'
import Breadcrumbs from 'Components/Breadcrumbs'
import EmployerDashboardLandingHeader from 'Components/EmployerDashboard/EmployerDashboardLandingHeader'
import SentRequests from 'Components/EmployerDashboard/SentRequests'
import CandidateSidebarContent from 'Components/EmployerDashboard/Sidebar/CandidateSidebarContent'
import HelpfulHint from 'Components/Widgets/HelpfulHint'

interface EmployerDashboardProps {
  auth: AuthStateType
  getUser: () => void
  sidebarClosed: boolean
  candidateBasicData: Candidate | IncomingRequestSeekerType | null
  getLatestEmployerRequests: () => void
  requests: EmployerRequestsStateType
  setCandidateBasicData: (candidate: any) => void
}

export const EmployerDashboard: FC<EmployerDashboardProps> = ({
  auth,
  getUser,
  candidateBasicData,
  getLatestEmployerRequests,
  requests,
  setCandidateBasicData,
}) => {
  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: getRoute(AppRoute.HomePageEmployer)},
    {
      name: auth.data?.companyStructure?.company?.name
        ? auth.data.companyStructure.company.name
        : '',
      link: getRoute(AppRoute.CompanyProfile),
    },
    {name: edt.dashboard},
  ]
  useEffect(() => {
    setCandidateBasicData(null)
  }, [])
  const SidebarContent = useMemo(() => {
    if (candidateBasicData) {
      return <CandidateSidebarContent />
    }
    return (
      <>
        <div className={styles.linksContainer}>
          <SidebarActions />
        </div>
        <HelpfulHint />
        <div className={styles.myOffersContainer}>
          <MyOffers />
        </div>
      </>
    )
  }, [candidateBasicData])

  useEffect(() => {
    getUser()
    getLatestEmployerRequests()
  }, [])

  return (
    <div className={styles.wrap}>
      <MainLayout
        fixedRight={false}
        rightSidebarContent={
          <WidgetBar
            containerClass=".requestItemWrap"
            sidebarContent={SidebarContent}
          />
        }
        SubNavigation={
          <div className={styles.breadcrumbsWrapper}>
            <Breadcrumbs breadcrumbs={breadcrumbsArray} />
          </div>
        }
      >
        <EmployerDashboardLandingHeader />
        <EmployerJourneyTracker />
        <SentRequests requests={requests} />
        {/* <div className={styles.minifeed}>
            <DashboardMiniFeed type="employer" />
          </div> */}
      </MainLayout>
    </div>
  )
}
