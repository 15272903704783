import {connect} from 'react-redux'

import {DrivingLicences} from './DrivingLicences'
import {AppDispatch} from 'Store'
import {
  addDrivingLicence,
  editDrivingLicence,
  getDrivingLicenseCategories,
} from 'Store/Actions'
import {RootState} from 'Store/Reducers'
import {DrivingLicenseSaveType} from 'Interfaces'
import {canChangeProfile} from 'Utils/Profile'

const mapStateToProps = (state: RootState) => ({
  user: state.seeker,
  canChange: canChangeProfile(state.auth.data?.rjbProfileStatus),
  drivingLicenseCategories: state.taxonomies.drivingLicenseCategories,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addDrivingLicence: (values: DrivingLicenseSaveType) =>
    dispatch(addDrivingLicence(values)),
  editDrivingLicence: (values: DrivingLicenseSaveType) =>
    dispatch(editDrivingLicence(values)),
  getDrivingLicenseCategories: () => {
    dispatch(getDrivingLicenseCategories())
  },
})

export const DrivingLicencesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(DrivingLicences)
