import styles from './HomePageFooter.module.scss'
import Preferenca from 'Assets/Images/preferenca-logo.svg'
import classnames from 'classnames'
import {Link} from 'react-router-dom'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {HomeTranslations as home} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'

export const HomePageFooter = (): JSX.Element => {
  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerLogo}>
        <Link to={getRoute(AppRoute.HomePageCandidate)}>
          <img
            src={Preferenca}
            alt="Preferenca Logo"
            className={styles.logoStyle}
          />
        </Link>
      </div>
      <div className={styles.linkAndIconContainer}>
        <nav className={styles.linkContainer}>
          <ul className={styles.linkList}>
            {/*<li>
              <Link
                to={getRoute(AppRoute.HelpSupportContactUs)}
                className={styles.linkStyle}
              >
                <FormattedMessage id={home.footerLink1} />
              </Link>
            </li>*/}

            {/* <li>
              <Link
                to="#"
                className={classnames(styles.linkStyle, styles.mobileHidden)}
              >
                <FormattedMessage id={home.footerLink2} />
              </Link>
            </li> */}

            {/* <li>
              <Link
                to={getRoute(AppRoute.HomePageEmployer)}
                className={classnames(styles.linkStyle, styles.mobileHidden)}
              >
                <FormattedMessage id={home.footerLink3} />
              </Link>
            </li> */}

            <li>
              <Link
                to={getRoute(AppRoute.AboutUs)}
                className={styles.linkStyle}
              >
                <FormattedMessage id={home.aboutUsLink} />
              </Link>
            </li>
            {/* <li>
              <Link
                to={getRoute(AppRoute.FrequentlyAskedQuestions)}
                className={classnames(styles.linkStyle, styles.mobileHidden)}
              >
                <FormattedMessage id={home.footerLink5} />
              </Link>
            </li> */}
            <li>
              <Link
                to={getRoute(AppRoute.GeneralTermsAndConditions)}
                target={'_blank'}
                rel={'noopener noreferrer'}
                className={classnames(styles.linkStyle, styles.mobileHidden)}
              >
                <FormattedMessage id={home.generalTermsAndConditionsLink} />
              </Link>
            </li>
            <li>
              <Link
                to={getRoute(AppRoute.TermsAndConditions)}
                target={'_blank'}
                rel={'noopener noreferrer'}
                className={classnames(styles.linkStyle, styles.mobileHidden)}
              >
                <FormattedMessage id={home.termsAndConditionsLink} />
              </Link>
            </li>

            <li>
              <Link
                to={getRoute(AppRoute.PrivacyPolicy)}
                target={'_blank'}
                rel={'noopener noreferrer'}
                className={classnames(styles.linkStyle, styles.mobileHidden)}
              >
                <FormattedMessage id={home.privacyPolicyLink} />
              </Link>
            </li>

            <li>
              <button
                className={classnames(styles.linkStyle, styles.mobileHidden)}
                onClick={() => {
                  if (window.Didomi) {
                    window.Didomi.preferences.show()
                  }
                }}
              >
                <FormattedMessage id={home.cookiesFooterLink} />
              </button>
            </li>
            <li>
              <a
                href="https://placa.si/"
                target={'_blank'}
                className={classnames(styles.linkStyle, styles.mobileHidden)}
              >
                <FormattedMessage id={home.footerLink9} />
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  )
}
