import * as Yup from 'yup'
import {LongTitle, LongDescription} from 'Validations'

const Validation = Yup.object().shape({
  companyQuestions: Yup.array().of(
    Yup.object().shape({
      question: LongTitle.required(
        'Please enter question. Example: How would you describe Company’s culture?'
      ),
      answer: LongDescription.required(
        'You must provide an answer for every question. Please enter your answer.'
      ),
      id: Yup.string(),
    })
  ),
})

export default Validation
