import * as Localizations from '../Localizations'
import {getChannel} from 'Utils/CommonHelpers'
import {history} from 'Store/history'
import {routesHelper} from 'Utils/Routes'
import {Socket} from 'socket.io-client'

const getIsDefaultLang = () => {
  const urlLang = window.location.pathname.slice(1, 3)
  return window.location.pathname.slice(0, 4) !== `/${urlLang}/`
}

const getDefaultLang = () => {
  return getChannel().defaultLanguage?.shortcode || 'sl'
}

export const getLang = (): string => {
  const urlLang = window.location.pathname.slice(1, 3)
  return getIsDefaultLang() ? getDefaultLang() : urlLang
}

export const getRoute = (route: string): string => {
  const isDefault = getIsDefaultLang()
  const lang = getLang()
  return `${isDefault ? '' : `/${lang}`}${Localizations[lang][route]}`
}

export const getRouteForLang = (route: string, lang: string): string => {
  const defaultLang = getDefaultLang()
  return `${lang === defaultLang ? '' : `/${lang}`}${
    Localizations[lang][route]
  }`
}

export const getRouteLanguageId = (): string => {
  const lang = getLang()
  return getChannel().languages[lang]
}

export const getRouteLang = getLang

export const getTranslation = (key: string): string => {
  const lang = getLang()
  return Localizations[lang][key]
}
export const getAllTranslations = () => {
  const lang = getLang()
  return Localizations[lang]
}

export const isRouteWithoutRedirect = () => {
  const {allPublicRoutes} = routesHelper[getLang()]
  return Object.keys(allPublicRoutes)
    .map((key) => getRoute(key))
    .includes(history.location.pathname)
}

export const isEmployerRoute = () => {
  const helper = routesHelper[getLang()]
  const employerPrivateRoutes = helper.employerPrivate
  const employerPrivateWithParamRegex = helper.employerPrivateWithParamRegex

  const isPrivateRoute = Object.keys(employerPrivateRoutes)
    .map((key) => getRoute(key))
    .includes(history.location.pathname)

  let isPrivateRouteWithParam = false
  if (!isPrivateRoute) {
    const lang = getLang()
    const pathname = history.location.pathname.replace(
      new RegExp(`^\\/${lang}\\/`),
      '/'
    )
    Object.keys(employerPrivateWithParamRegex)
      .map((key) => employerPrivateWithParamRegex[key])
      .forEach((regEx) => {
        if (!isPrivateRouteWithParam) {
          isPrivateRouteWithParam = !!regEx.exec(pathname)
        }
      })
  }

  return isPrivateRoute || isPrivateRouteWithParam
}

export const clearSocketEvents = (socket?: Socket) => {
  socket?.off('connect')
  socket?.off('disconnect')
  socket?.off('conversations joined')
  socket?.off('added to conversation')
  socket?.off('message created')
  socket?.off('message received')
  socket?.off('conversations read')
  socket?.off('conversations unread')
  socket?.off('unread conversations count')
  socket?.off('unread conversations count - seekers')
  socket?.off('unread conversations count - company users')
  socket?.off('unread conversations count - tsa')
  socket?.off('connect_failed')
}

export const disconnectSocket = (socket?: Socket) => {
  socket?.disconnect()
  clearSocketEvents(socket)
  window.app.chat.socket = undefined
}

export const ImmediatelyChipTranslation = {
  en: 'Available NOW',
  sl: 'Na voljo TAKOJ',
}
