import {FC} from 'react'
import {FormattedMessage} from 'react-intl'
import {DateTime} from 'luxon'
import classnames from 'classnames'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import {
  ViewInAr,
  ConnectWithoutContact,
  Notifications,
  Update,
  HourglassBottom,
  DescriptionOutlined,
} from '@mui/icons-material'

import Modal from 'Components/Modal'
import styles from './ModalContactRequestEmp.module.scss'

import {IncomingRequestType, RjbProfileStatus} from 'Interfaces'
import {
  AppRoute,
  CandidateDashboardTranslations as cdt,
} from 'Services/I18n/Constants'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'
import {formatDate} from 'Utils/Date'
import {
  CombinedRequestStatues,
  ContactRequestStatus,
  employerRequestStatusTranslationMap,
} from '../../Constants'
import {useHistory} from 'react-router-dom'
import {getRoute} from 'Services/I18n/Utils'
import classNames from 'classnames'

const ExpirationValue: FC<{date: string}> = ({date}) => {
  const now = DateTime.now()
  // TODO candidate-dashboard: Set locale:
  // const expiration = DateTime.fromISO(date).setLocale()
  const expiration = DateTime.fromISO(date)
  const diff = Math.floor(expiration.diff(now, ['days']).days)
  let dayOfTheWeek = expiration.toFormat('cccc')

  if (diff > 7 || expiration < now) {
    return (
      <p className={styles.expirationValueNormal}>
        <FormattedMessage id={dayOfTheWeek} />
        <span className={styles.date}>
          {expiration.toFormat(' (d.L.yyyy)')}
        </span>
      </p>
    )
  }
  if (now.day === expiration.day) {
    return (
      <div className={styles.expirationValueContainer}>
        <p className={styles.expirationValueTextRed}>
          <FormattedMessage id={edt.expiresToday} />
        </p>
      </div>
    )
  }
  return (
    <div className={styles.expirationValueContainer}>
      <p className={styles.expirationValueTextRed}>
        <FormattedMessage id={cdt.in} />
        {` ${diff} `}
        {diff === 1 ? (
          <FormattedMessage id={cdt.day} />
        ) : (
          <FormattedMessage id={cdt.days} />
        )}
      </p>
      <p className={styles.expirationValueText}>
        {expiration.toFormat('(d.L.yyyy)')}
      </p>
    </div>
  )
}

interface ModalContactRequestProps {
  request: IncomingRequestType
  isOpenContactRequest: boolean
  setIsOpenContactRequest: (open: boolean) => void
}

export const ModalContactRequestEmp: FC<ModalContactRequestProps> = ({
  request,
  isOpenContactRequest,
  setIsOpenContactRequest,
}) => {
  const history = useHistory()

  const onInterviewInvite = () => {
    history.push(
      getRoute(AppRoute.InviteCalendar).replace(
        ':candidateId',
        request.seeker.userId
      )
    )
  }
  const noLongerActiveCandidate =
    !request.seeker.hasFinishedPlacementWithCurrentEmployer &&
    request.seeker.rjbProfileStatus === RjbProfileStatus.CLOSED

  return (
    <>
      <Modal
        className={classnames(styles.modalDetails, {
          [styles.modalDetailsWithoutFooter]:
            request.status !== CombinedRequestStatues.APPROVED,
        })}
        icon={DescriptionOutlined}
        isOpen={isOpenContactRequest}
        title={edt.contactRequestDetails}
        onAfterClose={() => {
          setIsOpenContactRequest(false)
        }}
        shouldReturnFocusAfterClose={false}
        Footer={
          <>
            {request.status === CombinedRequestStatues.APPROVED && (
              <SubmitFooter
                onSubmit={onInterviewInvite}
                onCancel={() => setIsOpenContactRequest(false)}
                theme="modalProfileSubmit"
                submitText={edt.invite}
                disabled={noLongerActiveCandidate}
              />
            )}
          </>
        }
      >
        <div className={styles.containerInterviewDetails}>
          {request.status === CombinedRequestStatues.APPROVED ? (
            <div className={styles.titleContainer}>
              {request.seeker.profilePicture ? (
                <img
                  className={styles.avatar}
                  src={
                    request.seeker.profilePicture &&
                    request.seeker.profilePicture.url
                  }
                  alt={`${request.seeker.firstName} ${request.seeker.lastName}`}
                  onError={(event) =>
                    ((event.target as HTMLInputElement).style.display = 'none')
                  }
                />
              ) : (
                <div className={styles.avatarChar}>
                  <span>
                    {request.seeker.firstName.charAt(0)}
                    {request.seeker.lastName.charAt(0)}
                  </span>
                </div>
              )}
              <p className={styles.title}>
                {request.seeker.firstName} {request.seeker.lastName}
              </p>
            </div>
          ) : (
            <p className={styles.title}>
              <p className={classNames(styles.title, styles.titleWithBorder)}>
                {request.seeker?.publicOccupation &&
                  request.seeker.publicOccupation.translation}
              </p>
            </p>
          )}
          {request.status === CombinedRequestStatues.APPROVED && (
            <div className={styles.item}>
              <div className={styles.itemLeft}>
                <ViewInAr className={styles.icon} />
                <p className={styles.itemLeftText}>
                  <FormattedMessage id={edt.position} />
                </p>
              </div>
              <div className={styles.itemRight}>
                <p className={styles.itemRightText}>
                  {request.seeker.publicOccupation &&
                    request.seeker.publicOccupation.translation}
                </p>
              </div>
            </div>
          )}

          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <ConnectWithoutContact className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.requestType} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              <p className={styles.ContactRequestText}>
                <FormattedMessage id={cdt.contactRequest} />
              </p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <Notifications className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.requestStatus} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              <p
                className={classnames(styles.pending, {
                  [styles.rejectedOrCanceled]:
                    request.status === ContactRequestStatus.REJECTED ||
                    request.status === ContactRequestStatus.CANCELLED,
                  [styles.accepted]:
                    request.status === ContactRequestStatus.APPROVED,
                  [styles.expired]:
                    request.status === ContactRequestStatus.EXPIRED,
                })}
              >
                <FormattedMessage
                  id={employerRequestStatusTranslationMap[request.status]}
                />
              </p>
            </div>
          </div>

          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <Update className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.dateOfRequestSent} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              <p className={styles.itemRightText}>
                {request.sentDate && formatDate(request.sentDate)}
              </p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              {request.status === ContactRequestStatus.REJECTED ? (
                <>
                  <Update className={styles.icon} />
                  <p className={styles.itemLeftText}>
                    <FormattedMessage id={cdt.rejectionDate} />:
                  </p>
                </>
              ) : (
                <>
                  <HourglassBottom className={styles.icon} />
                  <p className={styles.itemLeftText}>
                    <FormattedMessage id={cdt.expirationDate} />:
                  </p>
                </>
              )}
            </div>
            {request.status === ContactRequestStatus.REJECTED ? (
              request.rejectionDate ? (
                <div className={styles.itemRight}>
                  <ExpirationValue date={request.rejectionDate} />
                </div>
              ) : (
                ''
              )
            ) : (
              <div className={styles.itemRight}>
                {request.expirationDate && (
                  <ExpirationValue date={request.expirationDate} />
                )}
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}
