import {connect} from 'react-redux'

import {SkillTests} from './SkillTests'
import {AppDispatch} from 'Store'
// TODO finish this after backend finishes
import {
  addSkillTest,
  editSkillTest,
  deleteSkillTest,
  getTrainingTitles,
} from 'Store/Actions'
import {RootState} from 'Store/Reducers'

import {
  TestGroupEnum,
  TrainingTitleAddType,
  TrainingTitleEditType,
} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  taxonomies: state.taxonomies,
  assessments: state.assessments,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getTaxonomies: () => dispatch(getTrainingTitles(TestGroupEnum.skillTest)),
  addSkillTest: (value: TrainingTitleAddType) => dispatch(addSkillTest(value)),
  editSkillTest: (value: TrainingTitleEditType) =>
    dispatch(editSkillTest(value)),
  deleteSkillTest: (id: string) => dispatch(deleteSkillTest(id)),
})

export const SkillTestsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SkillTests)
