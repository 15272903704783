import {FC, useEffect, useState} from 'react'
import StarIcon from '@mui/icons-material/StarRate'
import {
  Candidate,
  IncomingRequestSeekerType,
  SeekerEvaluationType,
} from 'Interfaces'
import Rating from '@mui/material/Rating'
import styles from './RateCandidate.module.scss'
import {SidebarTranslations as st} from 'Services/I18n/Constants'
import {useTranslation} from 'Hooks'

interface RateCandidateProps {
  addSeekerEvaluation: (seekerEvaluation: SeekerEvaluationType) => void
  editSeekerEvaluation: (seekerEvaluation: SeekerEvaluationType) => void
  candidateBasicData: Candidate | IncomingRequestSeekerType | null
  requestId: string
  seekerEvaluation: SeekerEvaluationType[]
  readOnly?: boolean
}

export const RateCandidate: FC<RateCandidateProps> = ({
  candidateBasicData,
  seekerEvaluation,
  addSeekerEvaluation,
  editSeekerEvaluation,
  readOnly = false,
}) => {
  const [star, setStar] = useState<number | null | undefined>(null)
  const [_, setHover] = useState(-1)
  const translation = useTranslation()

  useEffect(() => {
    if (
      seekerEvaluation &&
      seekerEvaluation[0] &&
      seekerEvaluation[0].evaluation
    ) {
      setStar(seekerEvaluation[0].evaluation)
    } else {
      setStar(null)
    }
  }, [seekerEvaluation, candidateBasicData])

  return (
    <div className={styles.rateCandidate}>
      <p>{translation[st.rate]}</p>
      <div className={styles.starsContainer}>
        <Rating
          readOnly={readOnly}
          name="hover-feedback"
          value={star}
          onChange={(_, newValue: number | null | undefined) => {
            if (!star && newValue) {
              addSeekerEvaluation({
                seekerUserId: candidateBasicData?.userId,
                evaluation: newValue,
              })
            } else if (star && newValue && newValue > 0) {
              editSeekerEvaluation({
                seekerUserId: candidateBasicData?.userId as string,
                evaluation: newValue,
              })
            }
            setStar(newValue)
          }}
          onChangeActive={(_, newHover) => {
            setHover(newHover)
          }}
          emptyIcon={<StarIcon className={styles.grayStarIcon} />}
        />
      </div>
    </div>
  )
}
