import {
  BaseTaxonomyType,
  DataType,
  SavedSearchType,
  SearchControlFormikValuesType,
  TaxonomiesStateType,
} from 'Interfaces'
import {Search} from 'history'
// import {radiusOptions} from 'Components/CandidateSearch/SearchControl/options'
import qs from 'qs'
// import {getTranslation} from 'Services/I18n/Utils'

export const urlToTaxonomies = (
  taxonomies: TaxonomiesStateType,
  searchKeywords: DataType<BaseTaxonomyType>,
  search: Search
): SearchControlFormikValuesType => {
  let newValues: SearchControlFormikValuesType = {}
  const params = qs.parse(search, {ignoreQueryPrefix: true})
  if (params.keyword) {
    const keyword = params.keyword as string
    newValues.keyword = searchKeywords.data.find(
      (sk) => sk.translation === keyword
    ) || {id: `newValue~${keyword}`, translation: keyword}
  }
  // if (params.locationId) {
  //   const country = taxonomies.countries.data.find(
  //     (c) => c.id === params.locationId
  //   )
  //   if (country)
  //     newValues.location = {...country, category: getTranslation('country')}
  //   else {
  //     const region = taxonomies.regions.data.find(
  //       (r) => r.id === params.locationId
  //     )
  //     if (region)
  //       newValues.location = {...region, category: getTranslation('region')}
  //     else {
  //       const town = taxonomies.towns.data.find(
  //         (t) => t.id === params.locationId
  //       )
  //       if (town)
  //         newValues.location = {...town, category: getTranslation('town')}
  //     }
  //   }
  // }
  // if (
  //   params.radius &&
  //   newValues.location?.category === getTranslation('town')
  // ) {
  //   newValues.radius = radiusOptions.find((r) => r.id === params.radius)
  // }
  if (params.availabilityIds) {
    const availabilityIds = (params.availabilityIds as string).split(',')
    newValues.availabilities = taxonomies.availabilities.data.filter((a) =>
      availabilityIds.includes(a.id)
    )
  }
  if (params.jobCategoryIds) {
    const jobCategoryIds = (params.jobCategoryIds as string).split(',')
    newValues.jobCategories = taxonomies.jobCategories.data.filter((jc) =>
      jobCategoryIds.includes(jc.id)
    )
  }
  if (params.skillIds) {
    const skillIds = (params.skillIds as string).split(',')
    newValues.skills = taxonomies.skills.data.filter((s) =>
      skillIds.includes(s.id)
    )
  }
  if (params.occupationIds) {
    const occupationIds = (params.occupationIds as string).split(',')
    newValues.occupations = taxonomies.occupations.data.filter((s) =>
      occupationIds.includes(s.id)
    )
  }
  if (params.regionIds) {
    const regionIds = (params.regionIds as string).split(',')
    newValues.regions = taxonomies.regions.data.filter((s) =>
      regionIds.includes(s.id)
    )
  }
  if (params.educationLevelIds) {
    const educationLevelIds = (params.educationLevelIds as string).split(',')
    newValues.educationLevels = taxonomies.educationLevels.data.filter((s) =>
      educationLevelIds.includes(s.id)
    )
  }
  if (params.skillLevelIds) {
    const skillLevelIds = (params.skillLevelIds as string).split(',')
    newValues.skillLevels = taxonomies.skillLevels.data.filter((s) =>
      skillLevelIds.includes(s.id)
    )
  }
  if (params.careerLevelIds) {
    const careerLevelIds = (params.careerLevelIds as string).split(',')
    newValues.careerLevels = taxonomies.careerLevels.data.filter((s) =>
      careerLevelIds.includes(s.id)
    )
  }
  if (params.careerStatusIds) {
    const careerStatusIds = (params.careerStatusIds as string).split(',')
    newValues.careerStatuses = taxonomies.jobSearchStatuses.data.filter((s) =>
      careerStatusIds.includes(s.id)
    )
  }
  if (params.languageIds) {
    const languageIds = (params.languageIds as string).split(',')
    newValues.languages = taxonomies.languages.data.filter((s) =>
      languageIds.includes(s.id)
    )
  }
  if (params.languageLevelIds) {
    const languageLevelIds = (params.languageLevelIds as string).split(',')
    newValues.languageLevels = taxonomies.languageLevels.data.filter((s) =>
      languageLevelIds.includes(s.id)
    )
  }
  if (params.workExperienceIds) {
    const workExperienceIds = (params.workExperienceIds as string).split(',')
    newValues.workExperiences = taxonomies.workExperiences.data.filter((s) =>
      workExperienceIds.includes(s.id)
    )
  }
  if (params.drivingLicenseCategoriesIds) {
    const drivingLicenseCategories = (
      params.drivingLicenseCategoriesIds as string
    ).split(',')
    newValues.drivingLicenseCategories =
      taxonomies.drivingLicenseCategories.data.filter((s) =>
        drivingLicenseCategories.includes(s.id)
      )
  }
  if (params.firstName) {
    newValues.firstName = params.firstName as string
  }
  if (params.lastName) {
    newValues.lastName = params.lastName as string
  }
  if (params.filtersType && params.filtersType === 'admin') {
    newValues.filtersType = params.filtersType
  }
  if (params.sortBy) {
    newValues.sortBy = params.sortBy as string
  }
  if (params.similarCandidateUserId) {
    newValues.similarCandidateUserId = params.similarCandidateUserId as string
  }
  return newValues
}

const packSearchTaxonomies = (values: BaseTaxonomyType[]): string => {
  let query = ''
  values.forEach((v, index) => {
    query = `${query}${v.id}${index === values.length - 1 ? '' : ','}`
  })
  return query
}

const packSearchIds = (values: string[]): string => {
  let query = ''
  values.forEach((v, index) => {
    query = `${query}${v}${index === values.length - 1 ? '' : ','}`
  })
  return query
}

export const taxonomiesToUrl = (
  values: SearchControlFormikValuesType
): string => {
  const keyword = values.keyword ? `keyword=${values.keyword.translation}` : ''
  // const location = values.location ? `locationId=${values.location.id}` : ''
  // const radius = values.radius ? `radius=${values.radius.id}` : ''
  const availabilityIds = values.availabilities?.length
    ? `availabilityIds=${packSearchTaxonomies(values.availabilities)}`
    : ''
  const jobCategoryIds = values.jobCategories?.length
    ? `jobCategoryIds=${packSearchTaxonomies(values.jobCategories)}`
    : ''
  const skillIds = values.skills?.length
    ? `skillIds=${packSearchTaxonomies(values.skills)}`
    : ''
  const skillLevelIds = values.skillLevels?.length
    ? `skillLevelIds=${packSearchTaxonomies(values.skillLevels)}`
    : ''
  const occupationIds = values.occupations?.length
    ? `occupationIds=${packSearchTaxonomies(values.occupations)}`
    : ''
  const regionIds = values.regions?.length
    ? `regionIds=${packSearchTaxonomies(values.regions)}`
    : ''
  const educationLevelIds = values.educationLevels?.length
    ? `educationLevelIds=${packSearchTaxonomies(values.educationLevels)}`
    : ''
  const languageIds = values.languages?.length
    ? `languageIds=${packSearchTaxonomies(values.languages)}`
    : ''
  const languageLevelIds = values.languageLevels?.length
    ? `languageLevelIds=${packSearchTaxonomies(values.languageLevels)}`
    : ''
  const workExperienceIds = values.workExperiences?.length
    ? `workExperienceIds=${packSearchTaxonomies(values.workExperiences)}`
    : ''
  const drivingLicenseCategories = values.drivingLicenseCategories?.length
    ? `drivingLicenseCategoriesIds=${packSearchTaxonomies(
        values.drivingLicenseCategories
      )}`
    : ''
  const careerLevelIds = values.careerLevels?.length
    ? `careerLevelIds=${packSearchTaxonomies(values.careerLevels)}`
    : ''
  const careerStatusIds = values.careerStatuses?.length
    ? `careerStatusIds=${packSearchTaxonomies(values.careerStatuses)}`
    : ''
  const filtersType =
    values.filtersType && values.filtersType === 'admin'
      ? 'filtersType=admin'
      : ''
  const firstName =
    values.firstName && values.firstName !== ''
      ? `firstName=${values.firstName}`
      : ''
  const lastName =
    values.lastName && values.lastName !== ''
      ? `lastName=${values.lastName}`
      : ''
  const similarCandidateUserId =
    values.similarCandidateUserId && values.similarCandidateUserId !== ''
      ? `similarCandidateUserId=${values.similarCandidateUserId}`
      : ''

  const sortBy = values.sortBy ? `sortBy=${values.sortBy}` : ''

  const pack1 =
    filtersType === ''
      ? [
          keyword,
          // location,
          // radius,
          availabilityIds,
          jobCategoryIds,
          skillIds,
          skillLevelIds,
          occupationIds,
          regionIds,
          educationLevelIds,
          languageIds,
          languageLevelIds,
          workExperienceIds,
          drivingLicenseCategories,
          careerLevelIds,
          careerStatusIds,
          sortBy,
          similarCandidateUserId,
        ]
      : [firstName, lastName, filtersType, sortBy]

  let pack2 = pack1.filter((p) => p !== '')
  let pack3 = ''

  pack2.forEach((p, index) => {
    pack3 = `${pack3}${!index ? '' : '&'}${p}`
  })

  return pack3
}

export const savedSearchToUrl = (
  value?: SavedSearchType
): string | undefined => {
  if (!value) return undefined
  const keyword = value.keyword ? `keyword=${value.keyword}` : ''
  // const location = value.location ? `locationId=${value.location}` : ''
  // const radius = value.radius ? `radius=${value.radius}` : ''
  const availabilityIds = value.availabilityIds?.length
    ? `availabilityIds=${packSearchIds(value.availabilityIds)}`
    : ''
  const jobCategoryIds = value.jobCategoryIds?.length
    ? `jobCategoryIds=${packSearchIds(value.jobCategoryIds)}`
    : ''
  const skillIds = value.skillIds?.length
    ? `skillIds=${packSearchIds(value.skillIds)}`
    : ''
  const skillLevelIds = value.skillLevelIds?.length
    ? `skillLevelIds=${packSearchIds(value.skillLevelIds)}`
    : ''
  const occupationIds = value.occupationIds?.length
    ? `occupationIds=${packSearchIds(value.occupationIds)}`
    : ''
  const regionIds = value.regionIds?.length
    ? `regionIds=${packSearchIds(value.regionIds)}`
    : ''
  const educationLevelIds = value.educationLevelIds?.length
    ? `educationLevelIds=${packSearchIds(value.educationLevelIds)}`
    : ''
  const languageIds = value.languageIds?.length
    ? `languageIds=${packSearchIds(value.languageIds)}`
    : ''
  const languageLevelIds = value.languageLevelIds?.length
    ? `languageLevelIds=${packSearchIds(value.languageLevelIds)}`
    : ''
  const workExperienceIds = value.workExperienceIds?.length
    ? `workExperienceIds=${packSearchIds(value.workExperienceIds)}`
    : ''
  const drivingLicenseCategoriesIds = value.drivingLicenseCategory?.length
    ? `drivingLicenseCategoriesIds=${packSearchIds(
        value.drivingLicenseCategory
      )}`
    : ''
  const careerLevelIds = value.careerLevelIds?.length
    ? `careerLevelIds=${packSearchIds(value.careerLevelIds)}`
    : ''
  const firstName =
    value.firstName && value.firstName !== ''
      ? `firstName=${value.firstName}`
      : ''
  const lastName =
    value.lastName && value.lastName !== '' ? `lastName=${value.lastName}` : ''

  const sortBy = value.sortBy ? `sortBy=${value.sortBy}` : ''

  const pack1 = [
    keyword,
    // location,
    // radius,
    availabilityIds,
    jobCategoryIds,
    skillIds,
    skillLevelIds,
    occupationIds,
    regionIds,
    educationLevelIds,
    languageIds,
    languageLevelIds,
    workExperienceIds,
    drivingLicenseCategoriesIds,
    careerLevelIds,
    firstName,
    lastName,
    sortBy,
  ]

  let pack2 = pack1.filter((p) => p !== '')
  let pack3 = ''

  pack2.forEach((p, index) => {
    pack3 = `${pack3}${!index ? '' : '&'}${p}`
  })

  return pack3
}

export const areAllTaxonomiesLoaded = (
  taxonomies: TaxonomiesStateType,
  searchKeywords: DataType<BaseTaxonomyType>
): boolean => {
  return !(
    !searchKeywords.data.length ||
    !taxonomies.availabilities.data.length ||
    !taxonomies.jobCategories.data.length ||
    !taxonomies.occupations.data.length ||
    !taxonomies.regions.data.length ||
    !taxonomies.educationLevels.data.length ||
    !taxonomies.languages.data.length ||
    !taxonomies.languageLevels.data.length ||
    !taxonomies.skills.data.length ||
    !taxonomies.skillLevels.data.length ||
    !taxonomies.jobSearchStatuses.data.length ||
    !taxonomies.careerLevels.data.length ||
    !taxonomies.towns.data.length ||
    !taxonomies.countries.data.length ||
    !taxonomies.drivingLicenseCategories.data.length ||
    !taxonomies.workExperiences.data.length ||
    !taxonomies.jobSearchStatuses.data.length
  )
}

export const initialUrlToTaxonomies = (
  search: Search
): SearchControlFormikValuesType => {
  let newValues: SearchControlFormikValuesType = {}
  const params = qs.parse(search, {ignoreQueryPrefix: true})
  if (params.keyword) {
    const keyword = params.keyword as string
    newValues.keyword = {id: `newValue~${keyword}`, translation: keyword}
  }
  // if (params.locationId) {
  //   const country = taxonomies.countries.data.find(
  //     (c) => c.id === params.locationId
  //   )
  //   if (country)
  //     newValues.location = {...country, category: getTranslation('country')}
  //   else {
  //     const region = taxonomies.regions.data.find(
  //       (r) => r.id === params.locationId
  //     )
  //     if (region)
  //       newValues.location = {...region, category: getTranslation('region')}
  //     else {
  //       const town = taxonomies.towns.data.find(
  //         (t) => t.id === params.locationId
  //       )
  //       if (town)
  //         newValues.location = {...town, category: getTranslation('town')}
  //     }
  //   }
  // }
  // if (
  //   params.radius &&
  //   newValues.location?.category === getTranslation('town')
  // ) {
  //   newValues.radius = radiusOptions.find((r) => r.id === params.radius)
  // }
  if (params.availabilityIds) {
    const availabilityIds = (params.availabilityIds as string).split(',')
    newValues.availabilities = availabilityIds.map((id) => ({
      id: id,
      translation: '',
    }))
  }
  if (params.jobCategoryIds) {
    const jobCategoryIds = (params.jobCategoryIds as string).split(',')
    newValues.jobCategories = jobCategoryIds.map((id) => ({
      id: id,
      translation: '',
    }))
  }
  if (params.skillIds) {
    const skillIds = (params.skillIds as string).split(',')
    newValues.skills = skillIds.map((id) => ({
      id,
      translation: '',
    }))
  }
  if (params.occupationIds) {
    const occupationIds = (params.occupationIds as string).split(',')
    newValues.occupations = occupationIds.map((o) => ({
      id: o,
      translation: '',
    }))
  }
  if (params.regionIds) {
    const regionIds = (params.regionIds as string).split(',')
    newValues.regions = regionIds.map((id) => ({
      id,
      translation: '',
    }))
  }
  if (params.educationLevelIds) {
    const educationLevelIds = (params.educationLevelIds as string).split(',')
    newValues.educationLevels = educationLevelIds.map((id) => ({
      id,
      translation: '',
    }))
  }
  if (params.skillLevelIds) {
    const skillLevelIds = (params.skillLevelIds as string).split(',')
    newValues.skillLevels = skillLevelIds.map((id) => ({
      id,
      translation: '',
    }))
  }
  if (params.careerLevelIds) {
    const careerLevelIds = (params.careerLevelIds as string).split(',')
    newValues.careerLevels = careerLevelIds.map((id) => ({
      id,
      translation: '',
    }))
  }
  if (params.careerStatusIds) {
    const careerStatusIds = (params.careerStatusIds as string).split(',')
    newValues.careerStatuses = careerStatusIds.map((id) => ({
      id,
      translation: '',
    }))
  }
  if (params.languageIds) {
    const languageIds = (params.languageIds as string).split(',')
    newValues.languages = languageIds.map((id) => ({
      id,
      translation: '',
    }))
  }
  if (params.languageLevelIds) {
    const languageLevelIds = (params.languageLevelIds as string).split(',')
    newValues.languageLevels = languageLevelIds.map((id) => ({
      id,
      translation: '',
    }))
  }
  if (params.workExperienceIds) {
    const workExperienceIds = (params.workExperienceIds as string).split(',')
    newValues.workExperiences = workExperienceIds.map((id) => ({
      id,
      translation: '',
    }))
  }
  if (params.drivingLicenseCategoriesIds) {
    const drivingLicenseCategories = (
      params.drivingLicenseCategoriesIds as string
    ).split(',')
    newValues.drivingLicenseCategories = drivingLicenseCategories.map((id) => ({
      id,
      translation: '',
    }))
  }
  if (params.firstName) {
    newValues.firstName = params.firstName as string
  }
  if (params.lastName) {
    newValues.lastName = params.lastName as string
  }
  if (params.filtersType && params.filtersType === 'admin') {
    newValues.filtersType = params.filtersType
  }
  if (params.sortBy) {
    newValues.sortBy = params.sortBy as string
  }
  if (params.similarCandidateUserId) {
    newValues.similarCandidateUserId = params.similarCandidateUserId as string
  }
  return newValues
}
