export enum EmployerFAQTranslations {
  titleYellow = 'employerFAQ.titleYellow',
  mainTitle = 'employerFAQ.mainTitle',
  titleFirst = 'employerFAQ.titleFirst',
  titleSecond = 'employerFAQ.titleSecond',
  titleThird = 'employerFAQ.titleThird',
  paragraphFirst = 'employerFAQ.paragraphFirst',
  paragraphSecond = 'employerFAQ.paragraphSecond',
  paragraphThird = 'employerFAQ.paragraphThird',

  subTitleFirst = 'employerFAQ.subTitleFirst',
  subTitleSecond = 'employerFAQ.subTitleSecond',
  subTitleThird = 'employerFAQ.subTitleThird',
  subTitleFourth = 'employerFAQ.subTitleFourth',
  subTitleFifth = 'employerFAQ.subTitleFifth',
  subParagraphFirst = 'employerFAQ.subParagraphFirst',
  subParagraphSecond = 'employerFAQ.subParagraphSecond',
  subParagraphThird = 'employerFAQ.subParagraphThird',
  subParagraphFourth = 'employerFAQ.subParagraphFourth',
  subParagraphFifth = 'employerFAQ.subParagraphFifth',
  subParagraphLast = 'employerFAQ.subParagraphLast',
}
