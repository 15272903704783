import {SystemLanguageType, TaxonomiesStateType} from 'Interfaces'
import {FC} from 'react'
import {getLettersUppercase} from '../Utils'
import styles from './LanguagesCards.module.scss'

interface LanguagesCardsProps {
  taxonomies: TaxonomiesStateType
  selectedLanguageState: SystemLanguageType
  handleSetLanguageState: (value: SystemLanguageType) => void
}

export const LanguagesCards: FC<LanguagesCardsProps> = ({
  taxonomies,
  selectedLanguageState,
  handleSetLanguageState,
}) => {
  return (
    <div className={styles.languageWrapper}>
      {/* Languages user can choose to add message for */}
      <div className={styles.buttonsWrapper}>
        {taxonomies.systemLanguages.data.map((language) => {
          return (
            <button
              key={language.id}
              onClick={() => handleSetLanguageState(language)}
              className={`${styles.languageBtn} ${
                // Taxonomy lang matches currently chosen lang
                language.id === selectedLanguageState?.id &&
                styles.activeLanguage
              }`}
            >
              {getLettersUppercase(language.name, 3)}
            </button>
          )
        })}
      </div>
    </div>
  )
}
