import styles from './CandidateLandingText.module.scss'
import {FC} from 'react'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import YellowText from 'Components/YellowText'
import {HomeTranslations as home} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import YellowLink from 'Components/YellowLink'

export interface CandidateLandingTextType {
  text?: string
}
export const CandidateLandingText: FC<CandidateLandingTextType> = () => {
  return (
    <>
      <div className={styles.mainContainer}>
        <h1 className={styles.title}>
          <FormattedMessage id={home.title_1} />
          <YellowText text={home.title_2} />
          <FormattedMessage id={home.title_3} />
        </h1>
        <p className={styles.textContainer}>
          <FormattedMessage id={home.paragraph} />
        </p>

        <div className={styles.buttonContainer}>
          <div className={styles.button}>
            <YellowLink
              to={getRoute(AppRoute.Registration)}
              text={home.btnCreate}
              className={styles.yellowButton}
            />
          </div>

          <div className={styles.button}>
            <YellowLink
              to={getRoute(AppRoute.CandidateInfo)}
              text={home.btnMore}
              className={styles.yellowButton}
            />
          </div>
        </div>
      </div>
    </>
  )
}
