import {FC} from 'react'
import {Button} from 'Components/Button'
import styles from './Page404.module.scss'
import {AppRoute} from 'Services/I18n/Constants'
import {getRoute} from 'Services/I18n/Utils'
import {Link} from 'react-router-dom'
import {useEffect} from 'react'
import {AuthStateType, EmployerStateType, SeekerStateType} from 'Interfaces'

interface Page404Props {
  auth: AuthStateType
  seeker: SeekerStateType
  employer: EmployerStateType
  getUser: () => void
}
export const Page404: FC<Page404Props> = ({auth, employer, getUser}) => {
  const companyUserRole =
    auth?.data?.role === 'employer' ||
    auth?.data?.role === 'company_owner' ||
    auth?.data?.role === 'recruiter'

  useEffect(() => {
    if (!auth?.data?.id) {
      getUser()
    }
  }, [])

  const routeFunction = () => {
    if (!auth.data?.id) {
      return getRoute(AppRoute.HomePageCandidate)
    } else if (!companyUserRole) {
      return getRoute(AppRoute.CandidateDashboard)
    } else if (employer) {
      return getRoute(AppRoute.EmployerDashboard)
    } else {
      return getRoute(AppRoute.HomePageCandidate)
    }
  }
  return (
    <>
      <div className={styles.main}>
        <h1 className={styles.title}>404</h1>
        <div className={styles.header}>Ooops!!</div>
        <div className={styles.txt}>
          THAT PAGE DOESN`T EXIST OR IS UNAVAILABLE.
        </div>
        <Link to={routeFunction()} target={'_blank'}>
          <Button className={styles.button}>Go back to Home</Button>
        </Link>
      </div>
    </>
  )
}
