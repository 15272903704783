import {getRoute, getTranslation} from 'Services/I18n/Utils'
import {AppRoute, HomeTranslations as ht} from 'Services/I18n/Constants'

export const footerItems = [
  {
    pageName: 'Preferenca.si',
    props: {to: getRoute(AppRoute.HomePageCandidate)},
  },
  {
    pageName: getTranslation(ht.generalTermsAndConditionsLink),
    props: {
      to: getRoute(AppRoute.GeneralTermsAndConditions),
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  },
  {
    pageName: getTranslation(ht.termsAndConditionsLink),
    props: {
      to: getRoute(AppRoute.TermsAndConditions),
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  },
  {
    pageName: getTranslation(ht.privacyPolicyLink),
    props: {
      to: getRoute(AppRoute.PrivacyPolicy),
      target: '_blank',
      rel: 'noopener noreferrer',
    },
  },
  {
    pageName: getTranslation(ht.cookiesLink),
    props: {to: `${getRoute(AppRoute.PrivacyPolicy)}#section5`},
  },
  {
    pageName: getTranslation(ht.aboutUsLink),
    props: {to: getRoute(AppRoute.AboutUs)},
  },
]
