export enum MyProjectsTranslations {
  deleteProject = 'myprojects.deleteProject',
  createNewProject = 'myprojects.createNewProject',
  firstName = 'myprojects.firstName',
  lastName = 'myprojects.lastName',
  jobTitle = 'myprojects.jobTitle',
  resetSearch = 'myprojects.resetSearch',
  candidates = 'myprojects.candidates',
  edit = 'myprojects.edit',
  archive = 'myprojects.archive',
  delete = 'myprojects.delete',
  save = 'myprojects.save',
  projectStatus = 'myprojects.projectStatus',
  project = 'myprojects.project',
  open = 'myprojects.open',
  addNewProject = 'myprojects.addNewProject',
  projectName = 'myprojects.projectName',
  editProject = 'myprojects.editProject',
  myProjectsTitle = 'myprojects.myProjectsTitle',
  active = 'myprojects.active',
  archived = 'myprojects.archived',
  loading = 'myprojects.loading',
  searchCandidatesLink = 'myprojects.searchCandidatesLink',
}
