import {put, takeLatest, all, call} from 'redux-saga/effects'
import API from 'Services/API'
import * as type from 'Store/types'
import TaxonomyUrl from 'Utils/TaxonomyUrl'
import {ActionType, TaxonomiesQueryType, TestGroupEnum} from 'Interfaces'

export function* fetchTaxonomy(
  action: ActionType<TaxonomiesQueryType | undefined>
) {
  const name = action.type.split('_')[0]
  let query = ''
  if (action.payload) {
    const keys = Object.keys(action.payload)
    keys.forEach((key, index) => {
      if (!index) query += '?'
      else query += '&'
      query += `${key}=${action.payload && action.payload[key]}`
    })
  }
  const {response, error} = yield call(API.get, `${TaxonomyUrl(name)}${query}`)
  if (response) {
    yield put({
      type: type[name].succeeded,
      payload: response.data.items,
      reqTaxonomyName: name,
    })
  } else yield put({type: type[name].failed, payload: error})
}

export function* fetchTrainingTitleTaxonomy(
  action: ActionType<TestGroupEnum | undefined>
) {
  const {response, error} = yield call(
    API.get,
    `/small-index/training-titles${
      action.payload ? `?testGroup=${action.payload}` : ''
    }`
  )
  if (response) {
    yield put({
      type: type.trainingTitles.succeeded,
      payload: response.data.items,
      reqTaxonomyName: 'trainingTitles',
    })
  } else yield put({type: type.trainingTitles.failed, payload: error})
}

export default function* TaxonomiesSaga(): Generator {
  yield all([
    takeLatest(type.educationLevels.requested, fetchTaxonomy),
    takeLatest(type.educationInstitutions.requested, fetchTaxonomy),
    takeLatest(type.countries.requested, fetchTaxonomy),
    takeLatest(type.employmentTypes.requested, fetchTaxonomy),
    takeLatest(type.jobCategory.requested, fetchTaxonomy),
    takeLatest(type.occupations.requested, fetchTaxonomy),
    takeLatest(type.employers.requested, fetchTaxonomy),
    takeLatest(type.skillLevels.requested, fetchTaxonomy),
    takeLatest(type.skills.requested, fetchTaxonomy),
    takeLatest(type.towns.requested, fetchTaxonomy),
    takeLatest(type.jobCategories.requested, fetchTaxonomy),
    takeLatest(type.regions.requested, fetchTaxonomy),
    takeLatest(type.numberOfRecruits.requested, fetchTaxonomy),
    takeLatest(type.workFromHome.requested, fetchTaxonomy),
    takeLatest(type.salaries.requested, fetchTaxonomy),
    takeLatest(type.availabilities.requested, fetchTaxonomy),
    takeLatest(type.workExperiences.requested, fetchTaxonomy),
    takeLatest(type.additionalBenefits.requested, fetchTaxonomy),
    takeLatest(type.fieldsOfStudy.requested, fetchTaxonomy),
    takeLatest(type.trainingTitles.requested, fetchTrainingTitleTaxonomy),
    takeLatest(type.languages.requested, fetchTaxonomy),
    takeLatest(type.languageLevels.requested, fetchTaxonomy),
    takeLatest(type.careerLevels.requested, fetchTaxonomy),
    takeLatest(type.systemLanguages.requested, fetchTaxonomy),
    takeLatest(type.jobSearchStatuses.requested, fetchTaxonomy),
    takeLatest(type.industry.requested, fetchTaxonomy),
    takeLatest(type.drivingLicenseCategories.requested, fetchTaxonomy),
  ])
}
