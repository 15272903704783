import {connect} from 'react-redux'
import {RootState} from 'Store/Reducers'
import {setSidebarClosed, setSelectedRequestWidget, getEmployerPlacements} from 'Store/Actions'
import {AppDispatch} from 'Store'
import {SentRequestListItem} from './SentRequestListItem'
import {EmployerRequestsSearchParamsType, IncomingRequestType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  placements: state.employer.data.allRequests.placements,
})
const mapDispatchToProps = (dispatch: AppDispatch) => ({  
  getEmployerPlacements: (params?: EmployerRequestsSearchParamsType) =>
    dispatch(getEmployerPlacements(params)),
  setSelectedRequestWidget: (request: IncomingRequestType) => {
    dispatch(setSelectedRequestWidget(request))
  },
  setSidebarClosed: (sidebarClosed: boolean) =>
    dispatch(setSidebarClosed(sidebarClosed)),
})

export const SentRequestListItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SentRequestListItem)
