import {FC, ReactNode, useState} from 'react'
import styles from './RightSidebar.module.scss'

import {AppRoute} from 'Services/I18n/Constants'
import {SidebarLink} from 'Components/SidebarLink'
import {ApplicationsMenu} from 'Layouts/MainLayout/ApplicationsMenu'
import SettingsMenu from 'Layouts/MainLayout/SettingsMenu'
import {SidebarTranslations as st} from 'Services/I18n/Constants'
import {isEmployerRoute} from 'Services/I18n/Utils'
interface iRightSidebarProps {
  Sidebar?: ReactNode
  content?: ReactNode
}

export const RightSidebar: FC<iRightSidebarProps> = ({Sidebar, content}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const isEmployer = isEmployerRoute()

  return (
    <div className={styles.sidebar} onClick={(e) => e.stopPropagation}>
      {/* Mobile Sidebar */}

      <div className={styles.contentLogoBackgroundWrap}></div>

      <div className={styles.avatarHolder}>
        <SettingsMenu />
      </div>

      {Sidebar && <div>{Sidebar}</div>}
      {/* Desktop Sidebar */}
      <div className={styles.desktopSidebar}>
        <div
          className={styles.settingsMenuWrap}
          onClick={(e) => e.stopPropagation}
        >
          {/* <AvatarContainer className={styles.rhsDesktopAvatar} /> */}
          <SettingsMenu />
        </div>
        <div className={styles.iconContainer}>
          <ApplicationsMenu
            setIsMenuOpen={setIsMenuOpen}
            isMenuOpen={isMenuOpen}
            className={styles.rightSidebarApplicationsMenu}
          />
        </div>

        <div className={styles.linksContainer}>
          {content ? (
            content
          ) : (
            <>
              {isEmployer ? (
                <>
                  <SidebarLink
                    routeName={AppRoute.CompanyProfile}
                    text={st.companyProfile}
                  />
                  <SidebarLink
                    routeName={AppRoute.CandidateSearch}
                    text={st.candidateSearch}
                  />
                  <SidebarLink
                    routeName={AppRoute.EmployerDashboard}
                    text={st.dashboard}
                  />
                  <SidebarLink routeName={AppRoute.Logout} text={st.logout} />
                </>
              ) : (
                <>
                  <SidebarLink
                    routeName={AppRoute.Profile}
                    text={st.candidateProfile}
                  />
                  <SidebarLink
                    routeName={AppRoute.PrivacyPolicy}
                    text={st.privacyPolicy}
                  />
                  <SidebarLink
                    routeName={AppRoute.ContactData}
                    text={st.contactData}
                  />
                  <SidebarLink routeName={AppRoute.Logout} text={st.logout} />{' '}
                </>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
