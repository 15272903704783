import {connect} from 'react-redux'

import {PersonalityTests} from './PersonalityTests'
import {AppDispatch} from 'Store'
import {
  changeShelbyMillsConsentStatus,
  getCognitiveTestResults,
  getPsychometricTestResults,
  getConsents,
  changeCognitiveTestStatus,
  changeCognitiveConsentStatus,
} from 'Store/Actions'
import {RootState} from 'Store/Reducers'
import {
  ChangeCognitiveConsentStatusType,
  ChangeSelbyMillsConsentStatusType,
} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  taxonomies: state.taxonomies,
  user: state.auth.data,
  downloadLink: state?.assessments?.cognitiveTestResults?.items[0]?.resultFile,
  psychometricDownloadLink:
    state.assessments.psychometricTestResults.resultFile,
  consents: state?.consents?.data.consents,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  changeCognitiveConsentStatus: (data: ChangeCognitiveConsentStatusType) =>
    dispatch(changeCognitiveConsentStatus(data)),
  changeShelbyMillsConsentStatus: (data: ChangeSelbyMillsConsentStatusType) =>
    dispatch(changeShelbyMillsConsentStatus(data)),
  getCognitiveTestResults: (value: string) =>
    dispatch(getCognitiveTestResults(value)),
  getPsychometricTestResults: (id: string) =>
    dispatch(getPsychometricTestResults(id)),
  getConsents: () => dispatch(getConsents()),
  changeCognitiveTestStatus: (value: string) =>
    dispatch(changeCognitiveTestStatus(value)),
})

export const PersonalityTestsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PersonalityTests)
