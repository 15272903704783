export const CandidateInfo = {
  'CandidateInfo.title': 'IZBERITE SLUŽBO, KI SI JO ',
  'CandidateInfo.titleYellow': 'ZASLUŽITE',
  'CandidateInfo.paragraphMain':
    'Zamenjali smo pravila igre in čas je, da daste svoje karierne želje in zahteve na prvo mesto. Naj zaposlitev najde vas in ne obratno.',
  'CandidateInfo.subtitle': 'Ustvarite profil',
  'CandidateInfo.paragraphFirstPart':
    'To je vaša priložnost, da poveste, kaj si želite in kaj cenite na delovnem mestu – vzelo vam bo manj kot 5 minut.',
  'CandidateInfo.paragraphSecondPart':
    'Izrazite svoje karierne želje, interese in tudi zahteve glede plače neodvisno od ponudb delodajalcev ter se zares lahko osredotočite na tisto, kar vam je pri iskanju zaposlitve najpomembnejše. In ker ste kandidati transparentni glede svojih preferenc, dobite le ustrezne zaposlitvene možnosti. ',
  'CandidateInfo.subtitleSecond': 'Podkrepite svoje znanje',
  'CandidateInfo.paragraphSecond':
    'Izpolnite psihometrični in kognitivni vprašalnik ter nadgradite svoj profil z rezultati, ki ocenjujejo vaše delovne kompetence in sposobnost učenja novih spretnosti.',
  'CandidateInfo.subtitleThird': 'Postanite vidni in odprti za nove izkušnje',
  'CandidateInfo.paragraphThird':
    'Delodajalci bodo lahko dostopali do vašega anonimiziranega profila in vas povabili na razgovor.',
  'CandidateInfo.paragraphThirdTitle': 'Imate popoln nadzor:',
  'CandidateInfo.paragraphThirdItemFirst':
    '• vaši podatki za stik so skriti rekruterjem,',
  'CandidateInfo.paragraphThirdItemSecond':
    '• trenutni delodajalec ne vidi vašega profila,',
  'CandidateInfo.paragraphThirdItemThird':
    '• vaš profil je viden le, če trenutno aktivno iščete delo.',
  'CandidateInfo.subtitleFourth': 'Izberite svoje priložnosti',
  'CandidateInfo.paragraphFourth':
    'Na enem mestu urejate svoje razgovore in urnike pogovorov. Izberite podjetja, s katerimi želite opraviti razgovore, in se odločite za tisto, ki vam najbolje ustreza.',
  'CandidateInfo.subtitleFifth': 'Prihranite čas',
  'CandidateInfo.paragraphFifth':
    'Prav tako vam Preferenca omogoča, da iskanju nove zaposlitve posvetite bistveno manj časa, kot ste vajeni. Pri registraciji vam je na voljo HR specialist, ki vam svetuje, kako lahko svoj profil izboljšate in privabite tiste delodajalce, ki jih res želite. Nato pa vas morajo delodajalci prepričati, da se pri njih zaposlite – vi se samo odločite, komu boste dali priložnost za intervju in čigavo vabilo boste zavrnili.',
  'CandidateInfo.subtitleSixth': 'Preverite svojo vrednost',
  'CandidateInfo.paragraphSixthFirstPart':
    'Preferenca pa je tudi za vse vas, ki aktivno ne iščete nove službe – na podlagi ponudb, ki jih boste dobili na tem portal, lahko spoznate svojo vrednost na trgu dela in to uporabite pri pogajanjih za višjo plačo pri svojem trenutnem delodajalcu.',
  'CandidateInfo.paragraphSixthSecondPart':
    'Celoten postopek – od ustvarjanja profila, komunikacije z delodajalci, upravljanja razgovorov do razgovorov – poteka prek spleta.',
  'CandidateInfo.yellowButton': 'BREZPLAČNA KARIERNA PODPORA',
}
