import * as type from 'Store/types'
import {
  ActionType,
  AttachmentType,
  AttachmentSaveType,
  AuthRoutingType,
  AwardsItem,
  CertificationProfileItemType,
  EducationSaveValueType,
  ErrorType,
  LoginType,
  MeType,
  RegistrationType,
  SkillType,
  WorkExperienceItem,
  ProjectItem,
  UserUpdateBioType,
  LanguageType,
  EducationProfileItemType,
  DrivingLicenseType,
  SeekerStateType,
  SeekerProfileType,
  PublicSeekerProfileType,
  PublicWorkExperienceItem,
  PublicUserBioType,
  WorkExperiencesResponseType,
} from 'Interfaces'

const initialState = {
  data: {
    full: undefined,
    public: undefined,
  },
  loading: false,
  error: undefined,
}

const SeekerReducer = (
  state: SeekerStateType = initialState,
  action: ActionType<
    | MeType
    | ErrorType
    | RegistrationType
    | LoginType
    | AuthRoutingType
    | AttachmentType
    | AttachmentSaveType
    | SkillType
    | UserUpdateBioType
    | EducationSaveValueType
  >
): SeekerStateType => {
  switch (action.type) {
    case type.seeker.register.requested:
    case type.profile.public.requested:
    case type.profile.full.requested:
    case type.seeker.updateJobPreferences.full.requested:
    case type.seeker.updateJobPreferences.public.requested:
    case type.seeker.addDrivingLicence.requested:
    case type.seeker.editDrivingLicence.requested:
    case type.seeker.workExperience.add.public.requested:
    case type.seeker.workExperience.update.public.requested:
    case type.seeker.personalData.get.requested:
    case type.seeker.personalData.save.requested:
    case type.seeker.education.add.requested:
    case type.seeker.education.update.requested:
    case type.seeker.education.delete.requested:
    case type.SEEKER_WORK_EXPERIENCE_ADD_REQUESTED:
    case type.SEEKER_WORK_EXPERIENCE_EDIT_REQUESTED:
    case type.SEEKER_WORK_EXPERIENCE_DELETE_REQUESTED:
    case type.SEEKER_AWARDS_ADD_REQUESTED:
    case type.SEEKER_AWARDS_EDIT_REQUESTED:
    case type.SEEKER_AWARDS_DELETE_REQUESTED:
    case type.SEEKER_CERTIFICATION_ADD_REQUESTED:
    case type.SEEKER_CERTIFICATION_EDIT_REQUESTED:
    case type.SEEKER_CERTIFICATION_DELETE_REQUESTED:
    case type.SEEKER_PROJECT_ADD_REQUESTED:
    case type.SEEKER_PROJECT_EDIT_REQUESTED:
    case type.SEEKER_PROJECT_DELETE_REQUESTED:
    case type.seeker.updateBioData.public.requested:
    case type.seeker.updateBioData.full.requested:
    case type.seeker.skills.add.full.requested:
    case type.seeker.skills.add.public.requested:
    case type.seeker.skills.update.full.requested:
    case type.seeker.skills.update.public.requested:
    case type.seeker.skills.delete.full.requested:
    case type.seeker.skills.delete.public.requested:
    case type.SEEKER_LANGUAGES_ADD_REQUESTED:
    case type.SEEKER_LANGUAGES_EDIT_REQUESTED:
    case type.SEEKER_ATTACHMENTS_ADD_REQUESTED:
    case type.SEEKER_ATTACHMENTS_DELETE_REQUESTED:
    case type.SEEKER_ATTACHMENTS_EDIT_REQUESTED:
    case type.seeker.reparseCv.requested:
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case type.profile.full.succeeded:
    case type.seeker.personalData.get.succeeded:
    case type.seeker.personalData.save.succeeded:
      return {
        data: {
          ...(state.data as SeekerProfileType),
          full: {
            ...state.data?.full,
            ...(action.payload as SeekerProfileType),
          },
        },
        loading: false,
        error: undefined,
      }

    case type.profile.public.succeeded:
      return {
        data: {
          ...(state.data as SeekerProfileType),
          public: action.payload as PublicSeekerProfileType,
        },
        loading: false,
        error: undefined,
      }

    case type.seeker.register.failed:
    case type.profile.full.failed:
    case type.profile.public.failed:
    case type.seeker.updateBioData.public.failed:
    case type.seeker.updateBioData.full.failed:
    case type.seeker.updateJobPreferences.full.failed:
    case type.seeker.updateJobPreferences.public.failed:
    case type.seeker.addDrivingLicence.failed:
    case type.seeker.editDrivingLicence.failed:
    case type.CONTACT_DATA_FAILED:
    case type.SEEKER_ATTACHMENTS_ADD_FAILED:
    case type.SEEKER_ATTACHMENTS_DELETE_FAILED:
    case type.SEEKER_ATTACHMENTS_EDIT_FAILED:
    case type.seeker.skills.add.full.failed:
    case type.seeker.skills.add.public.failed:
    case type.seeker.skills.update.full.failed:
    case type.seeker.skills.update.public.failed:
    case type.seeker.skills.delete.full.failed:
    case type.seeker.skills.delete.public.failed:
    case type.seeker.education.add.failed:
    case type.seeker.education.update.failed:
    case type.seeker.education.delete.failed:
    case type.seeker.personalData.get.failed:
    case type.seeker.personalData.save.failed:
    case type.SEEKER_CERTIFICATION_ADD_FAILED:
    case type.SEEKER_CERTIFICATION_EDIT_FAILED:
    case type.SEEKER_CERTIFICATION_DELETE_FAILED:
    case type.SEEKER_PROJECT_ADD_FAILED:
    case type.SEEKER_PROJECT_EDIT_FAILED:
    case type.SEEKER_PROJECT_DELETE_FAILED:
    case type.SEEKER_AWARDS_ADD_FAILED:
    case type.SEEKER_AWARDS_EDIT_FAILED:
    case type.SEEKER_AWARDS_DELETE_FAILED:
    case type.SEEKER_WORK_EXPERIENCE_ADD_FAILED:
    case type.SEEKER_WORK_EXPERIENCE_EDIT_FAILED:
    case type.SEEKER_WORK_EXPERIENCE_DELETE_FAILED:
    case type.seeker.workExperience.add.public.failed:
    case type.seeker.workExperience.update.public.failed:
    case type.SEEKER_LANGUAGES_ADD_FAILED:
    case type.SEEKER_LANGUAGES_EDIT_FAILED:
    case type.seeker.reparseCv.failed:
      return {
        ...state,
        loading: false,
        error: action.payload as ErrorType,
      }
    case type.seeker.register.succeeded:
      return {
        ...state,
        loading: false,
        error: undefined,
      }

    case type.seeker.updateBioData.full.succeeded:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            aboutMe: (action.payload as UserUpdateBioType)?.aboutMe,
            jobSearchStatus: (action.payload as UserUpdateBioType)
              ?.jobSearchStatus,
          },
        },
        loading: false,
        error: undefined,
      }

    case type.seeker.updateBioData.public.succeeded:
      return {
        data: {
          ...state.data,
          public: {
            ...(state.data?.public as PublicSeekerProfileType),
            shortDescription: (action.payload as PublicUserBioType)
              ?.shortDescription,
            publicOccupation: (action.payload as PublicUserBioType)
              ?.publicOccupation,
            jobSearchStatus: (action.payload as PublicUserBioType)
              ?.jobSearchStatus,
          },
        },
        loading: false,
        error: undefined,
      }

    case type.seeker.workExperience.add.public.succeeded:
      return {
        data: {
          ...state.data,
          public: {
            ...(state.data?.public as PublicSeekerProfileType),
            workExperiences: [
              ...(state.data?.public
                ?.workExperiences as PublicWorkExperienceItem[]),
              action.payload as PublicWorkExperienceItem,
            ],
          },
        },
        loading: false,
        error: undefined,
      }

    case type.seeker.workExperience.update.public.succeeded:
      return {
        data: {
          ...state.data,
          public: {
            ...(state.data?.public as PublicSeekerProfileType),
            workExperiences: [
              ...(action.payload as WorkExperiencesResponseType)
                .occupationExperiences,
            ],
            totalWorkExperience: {
              ...(action.payload as WorkExperiencesResponseType)
                .totalWorkExperience,
            },
          },
        },
        loading: false,
        error: undefined,
      }
    case type.seeker.education.add.succeeded:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            educations: [
              ...(state.data?.full?.educations as EducationProfileItemType[]),
              action.payload,
            ] as EducationProfileItemType[],
          },
        },
        loading: false,
        error: undefined,
      }
    case type.seeker.education.update.succeeded:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            educations: state.data?.full?.educations?.map(
              (edu: EducationProfileItemType) => {
                if (
                  edu.id !== (action.payload as EducationProfileItemType)?.id
                ) {
                  return edu
                }
                return action.payload as EducationProfileItemType
              }
            ) as EducationProfileItemType[],
          },
        },
        loading: false,
        error: undefined,
      }
    case type.seeker.education.delete.succeeded:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            educations: state.data?.full?.educations?.filter(
              (item) => item.id !== action.payload
            ) as EducationProfileItemType[],
          },
        },
        loading: false,
        error: undefined,
      }
    case type.SEEKER_WORK_EXPERIENCE_DELETE_SUCCEEDED:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            workExperiences: state.data?.full?.workExperiences?.filter(
              (item) => item.id !== action.payload
            ) as WorkExperienceItem[],
          },
        },
        loading: false,
        error: undefined,
      }
    case type.SEEKER_ATTACHMENTS_ADD_SUCCEEDED:
      const attachment = action.payload as AttachmentSaveType
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            attachments: [
              ...(state.data?.full?.attachments as AttachmentType[]),
              {
                id: attachment.id,
                name: attachment.name,
                type: attachment.type,
                file: {
                  id: attachment.fileId,
                  path: '#',
                },
              } as AttachmentType,
            ] as AttachmentType[],
          },
        },
        loading: false,
        error: undefined,
      }
    case type.SEEKER_ATTACHMENTS_EDIT_SUCCEEDED:
      const renamedAttachment = action.payload as AttachmentSaveType
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            attachments: (
              state.data?.full?.attachments as AttachmentType[]
            ).map((attachment) => {
              if (renamedAttachment.id === attachment.id) {
                return {
                  ...attachment,
                  name: renamedAttachment.name,
                }
              }
              return attachment
            }) as AttachmentType[],
          },
        },
        loading: false,
        error: undefined,
      }

    case type.SEEKER_LANGUAGES_ADD_SUCCEEDED:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            languages: state.data?.full?.languages
              ? (state.data?.full?.languages as LanguageType[]).concat([
                  action.payload,
                ] as LanguageType[])
              : ([action.payload] as LanguageType[]),
          },
        },
        loading: false,
        error: undefined,
      }

    case type.seeker.skills.add.full.succeeded:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            skills: state.data?.full?.skills
              ? (state.data?.full?.skills as SkillType[]).concat([
                  action.payload,
                ] as SkillType[])
              : ([action.payload] as SkillType[]),
          },
        },
        loading: false,
        error: undefined,
      }

    case type.seeker.skills.add.public.succeeded:
      return {
        data: {
          ...state.data,
          public: {
            ...(state.data?.public as PublicSeekerProfileType),
            skills: state.data?.public?.skills
              ? (state.data?.public?.skills as SkillType[]).concat([
                  action.payload,
                ] as SkillType[])
              : ([action.payload] as SkillType[]),
          },
        },
        loading: false,
        error: undefined,
      }

    case type.seeker.reparseCv.succeeded:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            cvId: action.payload as string,
          },
        },
        loading: false,
        error: undefined,
      }

    case type.SEEKER_AWARDS_ADD_SUCCEEDED:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            awards: state.data?.full?.awards
              ? (state.data?.full?.awards as AwardsItem[]).concat([
                  action.payload,
                ] as AwardsItem[])
              : ([action.payload] as AwardsItem[]),
          },
        },
        loading: false,
        error: undefined,
      }
    case type.SEEKER_AWARDS_EDIT_SUCCEEDED:
      const editedAwards = state.data?.full?.awards?.slice() || []
      const awardIndex = editedAwards.findIndex(
        (a) =>
          action.payload &&
          'id' in action.payload &&
          a.id === action.payload?.id
      )
      if (awardIndex > -1)
        editedAwards[awardIndex] = {...(action.payload as AwardsItem)}
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            awards: editedAwards,
          },
        },
        loading: false,
        error: undefined,
      }
    case type.SEEKER_AWARDS_DELETE_SUCCEEDED:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            awards: state.data?.full?.awards?.filter(
              (award) => award.id !== action.payload
            ) as AwardsItem[],
          },
        },
        loading: false,
        error: undefined,
      }

    case type.seeker.skills.update.full.succeeded:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            skills: state.data?.full?.skills?.map((skill: SkillType) => {
              if (skill.id === (action.payload as SkillType)?.id) {
                return {
                  ...skill,
                  skillLevel: {
                    id: (action.payload as SkillType)?.skillLevel.id,
                    level: (action.payload as SkillType)?.skillLevel.level,
                    translation: (action.payload as SkillType)?.skillLevel
                      .translation,
                  },
                }
              }
              return skill
            }) as SkillType[],
          },
          public: {
            ...(state.data?.public as PublicSeekerProfileType),
            skills: state.data?.public?.skills?.map((skill: SkillType) => {
              if (skill.id === (action.payload as SkillType)?.id) {
                skill.skillLevel = (action.payload as SkillType)?.skillLevel
              }
              return skill
            }) as SkillType[],
          },
        },
        loading: false,
        error: undefined,
      }

    case type.seeker.skills.update.public.succeeded:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            skills: state.data?.full?.skills?.map((skill: SkillType) => {
              if (skill.id === (action.payload as SkillType)?.id) {
                return {
                  ...skill,
                  skillLevel: {
                    id: (action.payload as SkillType)?.skillLevel.id,
                    level: (action.payload as SkillType)?.skillLevel.level,
                    translation: (action.payload as SkillType)?.skillLevel
                      .translation,
                  },
                }
              }
              return skill
            }) as SkillType[],
          },
          public: {
            ...(state.data?.public as PublicSeekerProfileType),
            skills: state.data?.public?.skills?.map((skill: SkillType) => {
              if (skill.id === (action.payload as SkillType)?.id) {
                skill.skillLevel = (action.payload as SkillType)?.skillLevel
              }
              return skill
            }) as SkillType[],
          },
        },
        loading: false,
        error: undefined,
      }

    case type.seeker.skills.delete.full.succeeded:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),

            skills: state.data?.full?.skills?.filter(
              (skill: SkillType) =>
                skill.id !== (action.payload as SkillType)?.id
            ) as SkillType[],
          },
        },
        loading: false,
        error: undefined,
      }

    case type.seeker.skills.delete.public.succeeded:
      return {
        data: {
          ...state.data,
          public: {
            ...(state.data?.public as PublicSeekerProfileType),

            skills: state.data?.public?.skills?.filter(
              (skill: SkillType) =>
                skill.id !== (action.payload as SkillType)?.id
            ) as SkillType[],
          },
        },
        loading: false,
        error: undefined,
      }
    case type.SEEKER_ATTACHMENTS_DELETE_SUCCEEDED:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            attachments: state.data?.full?.attachments?.filter(
              (attachment: AttachmentType) =>
                attachment.file.id !==
                (action.payload as AttachmentType)?.file.id
            ) as AttachmentType[],
          },
        },
        loading: false,
        error: undefined,
      }
    case type.SEEKER_CERTIFICATION_ADD_SUCCEEDED:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            trainings: [
              ...(state.data?.full
                ?.trainings as CertificationProfileItemType[]),
              action.payload,
            ] as CertificationProfileItemType[],
          },
        },
        loading: false,
        error: undefined,
      }
    case type.SEEKER_CERTIFICATION_EDIT_SUCCEEDED:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            trainings: state.data?.full?.trainings?.map((t) => {
              if (t.id === (action.payload as CertificationProfileItemType).id)
                return action.payload
              return t
            }) as CertificationProfileItemType[],
          },
        },
        loading: false,
        error: undefined,
      }
    case type.SEEKER_CERTIFICATION_DELETE_SUCCEEDED:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            trainings: state.data?.full?.trainings?.filter(
              (item) => item.id !== action.payload
            ) as CertificationProfileItemType[],
          },
        },
        loading: false,
        error: undefined,
      }
    case type.SEEKER_WORK_EXPERIENCE_ADD_SUCCEEDED:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            workExperiences: [
              ...(state.data?.full?.workExperiences as WorkExperienceItem[]),
              action.payload,
            ] as WorkExperienceItem[],
          },
        },
        loading: false,
        error: undefined,
      }
    case type.SEEKER_WORK_EXPERIENCE_EDIT_SUCCEEDED:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            workExperiences: state.data?.full?.workExperiences?.map((w) => {
              if (w.id === (action.payload as WorkExperienceItem).id)
                return action.payload
              return w
            }) as WorkExperienceItem[],
          },
        },
        loading: false,
        error: undefined,
      }
    case type.SEEKER_PROJECT_ADD_SUCCEEDED:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            projects: [
              ...(state.data?.full?.projects as ProjectItem[]),
              action.payload,
            ] as ProjectItem[],
          },
        },
        loading: false,
        error: undefined,
      }
    case type.SEEKER_PROJECT_EDIT_SUCCEEDED:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            projects: state.data?.full?.projects?.map((p) => {
              if (p.id !== (action.payload as ProjectItem).id) return p
              return action.payload
            }) as ProjectItem[],
          },
        },
        loading: false,
        error: undefined,
      }
    case type.SEEKER_PROJECT_DELETE_SUCCEEDED:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            projects: state.data?.full?.projects?.filter(
              (item) => item.id !== action.payload
            ) as ProjectItem[],
          },
        },
        loading: false,
        error: undefined,
      }
    case type.seeker.updateJobPreferences.full.succeeded:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            ...action.payload,
          },
        },
        loading: false,
        error: undefined,
      }
    case type.seeker.updateJobPreferences.public.succeeded:
      return {
        data: {
          ...state.data,
          public: {
            ...(state.data?.public as PublicSeekerProfileType),
            ...action.payload,
          },
        },
        loading: false,
        error: undefined,
      }
    case type.seeker.addDrivingLicence.succeeded:
    case type.seeker.editDrivingLicence.succeeded:
      return {
        data: {
          ...state.data,
          full: {
            ...(state.data?.full as SeekerProfileType),
            drivingLicense: action.payload as DrivingLicenseType,
          },
        },
        loading: false,
        error: undefined,
      }
    default:
      return state
  }
}

export default SeekerReducer
