import {connect} from 'react-redux'

import {setSidebarClosed, setSelectedRequestWidget} from 'Store/Actions'
import {AppDispatch} from 'Store'
import {ContactRequestsListItem} from './ContactRequestsListItem'
import {IncomingRequestType} from 'Interfaces'

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setSelectedRequestWidget: (request: IncomingRequestType) =>
    dispatch(setSelectedRequestWidget(request)),
  setSidebarClosed: (sidebarClosed: boolean) =>
    dispatch(setSidebarClosed(sidebarClosed)),
})

export const ContactRequestsListItemContainer = connect(
  null,
  mapDispatchToProps
)(ContactRequestsListItem)
