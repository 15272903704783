import registration from './Registration/en'
import onboarding from './Onboarding/en'
import profile from './Profile/en'
import company from './Company/en'

const validation = {
  ...registration,
  ...onboarding,
  ...profile,
  ...company,
  'Password is required.': 'Password is required',
  'Password must be 6-20 characters long. It may contain only latin alpha-numeric characters and must contain at least one numeric digit, one uppercase and one lowercase letter.':
    'Password must be 6-20 characters long. It may contain only latin alpha-numeric characters and must contain at least one numeric digit, one uppercase and one lowercase letter.',
  'Email is invalid': 'Email is invalid',
  'Email is required': 'Email is required',
  'First name is required.': 'First name is required.',
  'Last name is required.': 'Last name is required.',
  'Email is already taken': 'Email is already taken',
  'Forbidden access to Assessment': 'Forbidden access to Assessment',
  'Assessment not found': 'Assessment not found',
  'Seeker is not approved': 'Seeker is not approved',
  'Only to expired invitations or assessments user can be invited again':
    'Only to expired invitations or assessments user can be invited again',
  'Seeker Assessment combination already exists':
    'Seeker Assessment combination already exists',
  'You must accept consents before starting Assessment':
    'You must accept consents before starting Assessment',
  'Forbidden access to Seeker Assessment':
    'Forbidden access to Seeker Assessment',
  'Seeker Assessment not found': 'Seeker Assessment not found',
  'Client data is invalid': 'Client data is invalid',
  'Grant type is unsupported': 'Grant type is unsupported',
  'Scope is invalid or unsupported': 'Scope is invalid or unsupported',
  'Can`t change status - please log in with supplied email':
    'Can`t change status - please log in with supplied email',
  'Some of the consent change log ids are invalid':
    'Some of the consent change log ids are invalid',
  'Contact data already submitted': 'Contact data already submitted',
  'Contact data - onboarding not completed':
    'Contact data - onboarding not completed',
  'Contact request already approved': 'Contact request already approved',
  'Contact request not approved': 'Contact request not approved',
  'Contact request not pending or extended':
    'Contact request not pending or extended',
  'Employment start is greater than employment end':
    'Employment start is greater than employment end',
  'Language header missing': 'Language header missing',
  'Tenant header missing': 'Tenant header missing',
  'User header missing': 'User header missing',
  'Interview request expired': 'Interview request expired',
  'Interview request not pending or extended':
    'Interview request not pending or extended',
  'New time proposed not provided': 'New time proposed not provided',
  'Offer request expired': 'Offer request expired',
  'Offer request not confirmed': 'Offer request not confirmed',
  'Wrong onboarding status': 'Wrong onboarding status',
  'Placement already cancelled': 'Placement already cancelled',
  'Placement already confirmed': 'Placement already confirmed',
  'Placement already rejected': 'Placement already rejected',
  'Placement not confirmed by seeker': 'Placement not confirmed by seeker',
  'Placement request expired': 'Placement request expired',
  'Postal code - bad format': 'Postal code - bad format',
  'You can subscribe to up to ${data.limit} searches in your Search favorites.':
    'You can subscribe to up to ${data.limit} searches in your Search favorites.',
  'You can show up to ${data.limit} searches in your Search favorites.':
    'You can show up to ${data.limit} searches in your Search favorites.',
  'Query param seekerId missing': 'Query param seekerId missing',
  'Send email to friend wrong "from" email':
    'Send email to friend wrong "from" email',
  'Specto get calendar item bad request':
    'Specto get calendar item bad request',
  'Specto get token bad request': 'Specto get token bad request',
  'Company welcome message (companyId - jobCategoryId combination) already exists':
    'Company welcome message (companyId - jobCategoryId combination) already exists',
  'Company welcome message translation (Company welcome message - language combination) already exists':
    'Company welcome message translation (Company welcome message - language combination) already exists',
  'Contact request already exists': 'Contact request already exists',
  'Contact request expired': 'Contact request expired',
  'Contact request not expired': 'Contact request not expired',
  'Interview request already accepted': 'Interview request already accepted',
  'Interview request already exists': 'Interview request already exists',
  'Interview request already rejected': 'Interview request already rejected',
  'Can`t cancel Interview request that has the current status.':
    'Can`t cancel Interview request that has the current status.',
  'Can`t complete Interview request that has the current status.':
    'Can`t complete Interview request that has the current status.',
  'Can`t propose new time for Interview request that has the current status.':
    'Can`t propose new time for Interview request that has the current status.',
  'Interview request not expired': 'Interview request not expired',
  'Interview request is not pending approval':
    'Interview request is not pending approval',
  'Offer request already accepted': 'Offer request already accepted',
  'Offer request already exists': 'Offer request already exists',
  'Offer request already rejected': 'Offer request already rejected',
  'Seeker already has the given occupation':
    'Seeker already has the given occupation',
  'User has insufficient scope': 'User has insufficient scope',
  'Forbidden access to Company Question':
    'Forbidden access to Company Question',
  'Forbidden access to Company Video': 'Forbidden access to Company Video',
  'Company Video - Has too many videos': 'Company Video - Has too many videos',
  'Forbidden access to Company Welcome Message':
    'Forbidden access to Company Welcome Message',
  'Forbidden access to company user project':
    'Forbidden access to company user project',
  'Can not edit profile before approval!':
    'Can not edit profile before approval!',
  'Forbidden access to Saved Search': 'Forbidden access to Saved Search',
  'Too many attachments, delete some before adding more':
    'Too many attachments, delete some before adding more',
  'Forbidden access to seeker attachment':
    'Forbidden access to seeker attachment',
  'Forbidden access to seeker award': 'Forbidden access to seeker award',
  'Forbidden access to seeker DrivingLicense':
    'Forbidden access to seeker DrivingLicense',
  'Forbidden access to seeker education':
    'Forbidden access to seeker education',
  'Forbidden access to seeker language': 'Forbidden access to seeker language',
  'Forbidden access to seeker project': 'Forbidden access to seeker project',
  'Forbidden access to seeker skill': 'Forbidden access to seeker skill',
  'Forbidden access to seeker training': 'Forbidden access to seeker training',
  'Specto connect users forbidden': 'Specto connect users forbidden',
  'Specto delete user forbidden': 'Specto delete user forbidden',
  'Specto update user forbidden': 'Specto update user forbidden',
  'Forbidden resource for user.': 'Forbidden resource for user.',
  'Specto create user not acceptable': 'Specto create user not acceptable',
  'Specto get token not acceptable': 'Specto get token not acceptable',
  'Specto get user calendar not acceptable':
    'Specto get user calendar not acceptable',
  'Additional benefit not found': 'Additional benefit not found',
  'Availability not found': 'Availability not found',
  'Blacklist item not found': 'Blacklist item not found',
  'Career level not found': 'Career level not found',
  'Company Impression not found': 'Company Impression not found',
  "Company Impression can't have same file attached to impression":
    "Company Impression can't have same file attached to impression",
  'Company not found': 'Company not found',
  'Company question not found': 'Company question not found',
  'Company user not found': 'Company user not found',
  'Company Video not found': 'Company Video not found',
  'Company Welcome Message not found': 'Company Welcome Message not found',
  'Company seeker journey not found': 'Company seeker journey not found',
  'Company seeker journey contact request not found':
    'Company seeker journey contact request not found',
  'Company seeker journey offer request not found':
    'Company seeker journey offer request not found',
  'Company seeker journey placement request not found':
    'Company seeker journey placement request not found',
  'Company user project not found': 'Company user project not found',
  'Company user seeker project not found':
    'Company user seeker project not found',
  'Consent not found': 'Consent not found',
  'Contact request already rejected': 'Contact request already rejected',
  'Contact request not found': 'Contact request not found',
  'Country not found': 'Country not found',
  'Education institution not found': 'Education institution not found',
  'Education level not found': 'Education level not found',
  'Employer not found': 'Employer not found',
  'Employment Type not found': 'Employment Type not found',
  'Faq groups not found': 'Faq groups not found',
  'Faq items not found': 'Faq items not found',
  'Field of study not found': 'Field of study not found',
  'File not found': 'File not found',
  'Identity not found': 'Identity not found',
  'Interview request not found': 'Interview request not found',
  'Job category not found': 'Job category not found',
  'Job search status not found': 'Job search status not found',
  'Language level not found': 'Language level not found',
  'Language not found': 'Language not found',
  'Language taxonomy not found': 'Language taxonomy not found',
  'Occupation not found': 'Occupation not found',
  'Region not found': 'Region not found',
  'Request not found': 'Request not found',
  'Salary not found': 'Salary not found',
  'Saved Search not found': 'Saved Search not found',
  'Seeker attachment not found': 'Seeker attachment not found',
  'Seeker award not found': 'Seeker award not found',
  'Seeker Driving License not found': 'Seeker Driving License not found',
  'Seeker education not found': 'Seeker education not found',
  'Seeker language title not found': 'Seeker language title not found',
  'Seeker language test not found': 'Seeker language test not found',
  'Seeker not found': 'Seeker not found',
  'Seeker Occupation Experience not found':
    'Seeker Occupation Experience not found',
  'Seeker project not found': 'Seeker project not found',
  'Seeker skill not found': 'Seeker skill not found',
  'Seeker training not found': 'Seeker training not found',
  'Forbidden access to seeker work experience':
    'Forbidden access to seeker work experience',
  'Seeker work experience not found': 'Seeker work experience not found',
  'Seeker evaluation not found': 'Seeker evaluation not found',
  'Skill level not found': 'Skill level not found',
  'Skill not found': 'Skill not found',
  'Specto calendar item not found': 'Specto calendar item not found',
  'Specto token not found': 'Specto token not found',
  'Specto user calendar item not found': 'Specto user calendar item not found',
  'Specto user data not found': 'Specto user data not found',
  'Specto user not found': 'Specto user not found',
  'Language for tenant not found': 'Language for tenant not found',
  'Tenant not found': 'Tenant not found',
  'Testimonials not found': 'Testimonials not found',
  'Total work experience not found': 'Total work experience not found',
  'Town not found': 'Town not found',
  'Training title not found': 'Training title not found',
  'Transactional email not found': 'Transactional email not found',
  'User not found': 'User not found',
  'Work from home not found': 'Work from home not found',
  'Script tags found in HTML': 'Script tags found in HTML',
  'Access token is missing or invalid': 'Access token is missing or invalid',
  'User is not authorized': 'User is not authorized',
  'Wrong email or password': 'Wrong email or password',
  'Wrong password': 'Wrong password',
  'Your attachment is in a wrong format. Please use: docx, pdf, jpeg, gif':
    'Your attachment is in a wrong format. Please use: docx, pdf, jpeg, gif',
  'Your CV is not in correct format...': 'Your CV is not in correct format...',
  'Validation Exception': 'Validation Exception',
  'Supported file formats: .doc, .docx, .pdf. (Max size: 5 MB)':
    'Supported file formats: .doc, .docx, .pdf. (Max size: 5 MB)',
  'Supported file formats: .jpg, .jpeg, .png, .gif. (Max size: 5 MB)':
    'Supported file formats: .jpg, .jpeg, .png, .gif. (Max size: 5 MB)',
  "Employer doesn't exists.": "Employer doesn't exists.",
  "Seeker doesn't exists.": "Seeker doesn't exists.",
  'Test provider is required': 'Test provider is required',
  'Test name is required': 'Test name is required',
  'Test taken date is required': 'Test taken date is required',
  'Skill test date can not be set in the future':
    'Skill test date can not be set in the future',
  'Language test date can not be set in the future':
    'Language test date can not be set in the future',
  'Expiration date can not be before date of test':
    'Expiration date can not be before date of test',
  'Language name is required': 'Language name is required',
  'Language level is required': 'Language level is required',
  'Your attachment is in a wrong format. Please use: .doc, .docx, .pdf, .ppt, .pptx, .jpg, .jpeg, .png, .gif':
    'Your attachment is in a wrong format. Please use: .doc, .docx, .pdf, .ppt, .pptx, .jpg, .jpeg, .png, .gif',
  'Network error': 'Network error',
}

export default validation
