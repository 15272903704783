import {
  ActionType,
  ConversationType,
  ConversationsSearchParamsType,
  CreateMessageType,
  CreateConversationType,
  LoadMoreMessagesType,
  ConversationMessageType,
} from 'Interfaces'
import * as type from '../types'

export const searchConversations = (
  payload?: ConversationsSearchParamsType
): ActionType<ConversationsSearchParamsType> => {
  return {
    type: type.messageCenter.search.requested,
    payload: payload,
  }
}

export const getUnreadConversationsCount = (
  payload?: string
): ActionType<string> => {
  return {
    type: type.messageCenter.getUnreadConversationsCount.requested,
    payload: payload,
  }
}

export const getSpecificParticipiantUnreadConversationsCount = (
  payload?: string
): ActionType<string> => {
  return {
    type: type.messageCenter.getSpecificParticipiantUnreadConversationsCount
      .requested,
    payload: payload,
  }
}

export const loadMoreMessages = (
  data: LoadMoreMessagesType
): ActionType<LoadMoreMessagesType> => {
  return {
    type: type.messageCenter.loadMoreMessages.requested,
    payload: data,
  }
}

export const loadMoreConversations = (url: string): ActionType<string> => {
  return {
    type: type.messageCenter.loadMoreConversations.requested,
    payload: url,
  }
}

export const createConversation = (
  data: CreateConversationType
): ActionType<CreateConversationType> => {
  return {
    type: type.messageCenter.createConversation.requested,
    payload: data,
  }
}

export const createMessage = (
  data: CreateMessageType
): ActionType<CreateMessageType> => {
  return {
    type: type.messageCenter.createMessage.requested,
    payload: data,
  }
}

export const selectConversation = (
  conversation?: ConversationType
): ActionType<ConversationType> => {
  return {
    type: type.messageCenter.selectConversation.requested,
    payload: conversation,
  }
}

export const archiveConversations = (
  conversations: string[]
): ActionType<string[]> => {
  return {
    type: type.messageCenter.archiveConversation.requested,
    payload: conversations,
  }
}

export const getMessageRecipients = (): ActionType<null> => {
  return {
    type: type.messageCenter.getMessageRecipients.requested,
  }
}

export const startMessageCenterChannel = (): ActionType<null> => {
  return {
    type: type.messageCenter.socketIO.startMessageChannel,
  }
}

export const createMessageSocket = (
  payload: CreateMessageType
): ActionType<CreateMessageType> => {
  return {
    type: type.messageCenter.socketIO.events.createMessage,
    payload,
  }
}

export const messageCreated = (
  payload: ConversationMessageType
): ActionType<ConversationMessageType> => {
  return {
    type: type.messageCenter.socketIO.events.messageCreated,
    payload,
  }
}

export const createConversationSocket = (
  payload: CreateConversationType
): ActionType<CreateConversationType> => {
  return {
    type: type.messageCenter.socketIO.events.createConversation,
    payload,
  }
}

export const readConversationSocket = (
  payload: string[]
): ActionType<string[]> => {
  return {
    type: type.messageCenter.socketIO.events.readConversation,
    payload,
  }
}

export const unreadConversationSocket = (
  payload: string[]
): ActionType<string[]> => {
  return {
    type: type.messageCenter.socketIO.events.unreadConversation,
    payload,
  }
}

export const startMessageChannel = () => {
  return {
    type: type.messageCenter.socketIO.startMessageChannel,
  }
}

export const getConversationByUserIds = (
  payload: string
): ActionType<string> => {
  return {
    type: type.messageCenter.getConversationByUserIds.requested,
    payload,
  }
}

export const resetMessageCenterData = (): ActionType<string> => {
  return {
    type: type.messageCenter.resetMessageCenterData,
  }
}
