import {connect} from 'react-redux'

import {Attachments} from './Attachments'
import {AppDispatch} from 'Store'
import {addAttachment, editAttachment, removeAttachment} from 'Store/Actions'
import {RootState} from 'Store/Reducers'
import {AttachmentSaveType, AttachmentType} from 'Interfaces'
import {canChangeProfile} from 'Utils/Profile'

const mapStateToProps = (state: RootState) => ({
  attachments: state.seeker.data?.full?.attachments,
  profile: state.seeker,
  canChange: canChangeProfile(state.auth.data?.rjbProfileStatus),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addAttachment: (value: AttachmentSaveType) => dispatch(addAttachment(value)),
  removeAttachment: (value: AttachmentType) =>
    dispatch(removeAttachment(value)),
  editAttachment: (value: AttachmentSaveType) =>
    dispatch(editAttachment(value)),
})

export const AttachmentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Attachments)
