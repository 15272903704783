import {ChangeConsentStatusType} from 'Interfaces'
import {connect} from 'react-redux'

import {ConsentItem} from './ConsentItem'
import {AppDispatch} from 'Store'
import {changeConsentStatus} from 'Store/Actions'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  allConsents: state.consents,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  changeConsentStatus: (data: ChangeConsentStatusType) =>
    dispatch(changeConsentStatus(data)),
})

export const ConsentItemWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(ConsentItem)
