export const changepasswordsettings = {
  'changepasswordsettings.title': 'Moj račun',
  'changepasswordsettings.titleChangePassword': 'SPREMENI GESLO',
  'changepasswordsettings.oldPassword': 'Staro geslo',
  'changepasswordsettings.newPassword': 'Novo geslo',
  'changepasswordsettings.repeatNewPassword': 'Ponovi novo geslo',
  'changepasswordsettings.cancel': 'Prekličite',
  'changepasswordsettings.saveChanges': 'Shrani spremembe',
  'changepasswordsettings.successMessage': 'Uspešno ste spremenili svoje geslo.',
}
