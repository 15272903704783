import {FC, ReactNode} from 'react'
import classnames from 'classnames'
import styles from './EmployerLoginLayout.module.scss'
import Preferenca from 'Assets/Images/preferenca-logo-white.svg'
import {Link} from 'react-router-dom'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
interface LoginLayoutProps {
  children: ReactNode
  fullWidth?: boolean
}

export const EmployerLoginLayout: FC<LoginLayoutProps> = ({
  children,
  fullWidth = false,
}: LoginLayoutProps) => (
  <div className={styles.layout}>
    <div
      className={classnames(styles.container, {
        [styles.fullWidth]: fullWidth,
      })}
    >
      <div className={styles.logoContainer}>
        <Link to={getRoute(AppRoute.HomePageEmployer)}>
          <img className={styles.logo} src={Preferenca} alt="Logo" />
        </Link>
      </div>
      <div className={styles.childrenContainer}>{children}</div>
    </div>
  </div>
)
