import {BaseTaxonomyType} from 'Interfaces'
import {useMemo} from 'react'
import {getAllTranslations} from 'Services/I18n/Utils'

export const useTranslation = () => {
  return useMemo(() => getAllTranslations(), [getAllTranslations])
}

export const useTranslate = () => {
  const memo = useMemo(() => getAllTranslations(), [getAllTranslations])
  return (id?: string | null) => memo[id] || null
}

export const useTranslatedBaseTaxonomyArray = (items: BaseTaxonomyType[]) => {
  const translations = useTranslation()

  return useMemo(
    () =>
      items.map(({id, translation: translationId}) => ({
        id,
        translation: translations[translationId],
      })),
    [items]
  )
}
