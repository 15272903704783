import {ApproveRejectModalRequestType} from 'Interfaces'
import {connect} from 'react-redux'

import {AppDispatch} from 'Store'
import {approveOfferRequest} from 'Store/Actions'
import {ModalApproveOfferRequest} from './ModalApproveOfferRequest'

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  approveOfferRequest: (payload: ApproveRejectModalRequestType) =>
    dispatch(approveOfferRequest(payload)),
})

export const ModalApproveOfferRequestContainer = connect(
  null,
  mapDispatchToProps
)(ModalApproveOfferRequest)
