import {FC, useState} from 'react'
import {FormattedMessage} from 'react-intl'

import Modal from 'Components/Modal'
import {ThumbsFooter} from 'Components/Modal/ThumbsFooter'
import {IncomingRequestType, ApproveRejectModalRequestType} from 'Interfaces'
import styles from './ModalRejectContactRequest.module.scss'
import ModalContactRequest from 'Components/IncomingRequests/Modals/ModalContactRequest'
import {CandidateDashboardTranslations as cdt} from 'Services/I18n/Constants'
import {ForumOutlined} from '@mui/icons-material'

interface ModalRejectContactRequest {
  request: IncomingRequestType
  rejectModal: boolean
  setRejectModal: (rejecModal: boolean) => void
  showDetails?: boolean
  setIsOpenContactRequest?: (setIsOpenContactRequest: boolean) => void
  rejectContactRequest: (payload: ApproveRejectModalRequestType) => void
}

export const ModalRejectContactRequest: FC<ModalRejectContactRequest> = ({
  request,
  rejectModal,
  setRejectModal,
  rejectContactRequest,
  showDetails = true,
  setIsOpenContactRequest,
}) => {
  const [isOpenContactRequestDetails, setIsOpenContactRequestDetails] =
    useState(false)
  const [modalDetails, setModalDetails] = useState(false)
  return (
    <>
      {!modalDetails ? (
        <Modal
          className={styles.modal}
          icon={ForumOutlined}
          isOpen={rejectModal}
          title={cdt.rejectContactRequest}
          theme={'error'}
          onAfterClose={() => {
            setRejectModal(false)
          }}
          Footer={
            <ThumbsFooter
              rejectText={cdt.reject}
              theme={'thumbDown'}
              onReject={() => {
                rejectContactRequest({
                  id: request.id,
                })
                setRejectModal(false)
                setIsOpenContactRequest && setIsOpenContactRequest(false)
              }}
              onCancel={() => {
                setRejectModal(false)
              }}
            />
          }
        >
          <div className={styles.containerInterview}>
            <p className={styles.text}>
              <FormattedMessage id={cdt.modalRejectContactAreYouSure} />{' '}
              {request.companyName}?
            </p>
            <p className={styles.description}>
              <FormattedMessage id={cdt.modalRejectContactYouWillMiss} />
            </p>

            {showDetails && (
              <button
                onClick={() => {
                  setRejectModal(false)
                  setModalDetails(true)
                  setIsOpenContactRequestDetails(true)
                  setIsOpenContactRequest && setIsOpenContactRequest(false)
                }}
                className={styles.button}
              >
                <FormattedMessage id={cdt.viewDetails} />
              </button>
            )}
          </div>
        </Modal>
      ) : (
        <ModalContactRequest
          request={request}
          isOpenContactRequest={isOpenContactRequestDetails}
          setIsOpenContactRequest={setIsOpenContactRequestDetails}
          setModalDetails={setModalDetails}
          modalDetails={modalDetails}
        />
      )}
    </>
  )
}
