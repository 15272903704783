export const home = {
  'home.title': 'Preferenca',
  'home.sendToFriend': 'POŠLJI PRIJATELJU',
  'home.headerLogo': 'PREFERENCA',
  'home.headerLink1': 'ZA KANDIDATE',
  'home.headerLink2': 'ZA DELODAJALCE',
  'home.headerLink3': 'O nas',
  'home.headerLink4': 'Prijava',
  'home.headerLink5': 'Registracija',
  'home.title_1': 'Spremenite pravila ',
  'home.title_2': ' igre ',
  'home.title_3': 'zaposlovanja',
  'home.paragraph': `Pridružite se tistim, ki ne iščejo, temveč izbirajo. 
  Ustvarite profil in dovolite, da se podjetja prijavijo na intervju z vami.`,
  'home.btnCreate': 'Ustvari profil',
  'home.btnMore': 'IZVEDI VEČ',
  'home.companiesTitle_1': '150 podjetij zaposluje s ',
  'home.companiesTitle_2': 'PREFERENCO',
  'home.question_1': 'Kako ',
  'home.question_2': 'deluje?',
  'home.answer1': 'Ustvarite profil in nam zaupajte svoja pričakovanja',
  'home.answer2':
    'Prepustite prvi korak podjetjem – naj vas kontaktirajo s prošnjo za intervju',
  'home.answer3':
    'Odkrito se pogovorite o pričakovanjih glede delovnih pogojev, izzivov in plače',
  'home.answer4': 'Izberite najboljšo ponudbo',
  'home.testemonialsTitle_1': 'Pridružite se 10 odstotkom ',
  'home.testemonialsTitle_2': 'najboljših kandidatov',
  'home.testemonialsTitle_3': ' v Sloveniji še danes.',
  'home.ctaTitle1_1': 'Spoznajte  svojo',
  'home.ctaTitle1_2': ' vrednost',
  'home.ctaParagraph': `Prepričajte delodajalce in se izognite neprijetnim vprašanjem na intervjujih.`,
  'home.ctaParagraph1': `Primerjajte ponudbe delodajalcev in preverite plačne standarde na primerljivih delovnih mestih. Do službe brez ovinkarjenja in zapletenih pogajanj.`,
  'home.cta1': 'Preveri plačo',
  'home.ctaTitle2_1': `Brezplačna karierna`,
  'home.ctaTitle2_2': ` podpora`,
  'home.cta2': 'Izvedi več',
  'home.footerLink1': 'Kontakt',
  'home.footerLink2': 'KANDIDATI',
  'home.footerLink3': 'DELODAJALCI',
  'home.aboutUsLink': 'O nas',
  'home.footerLink5': 'FAQ',
  'home.termsAndConditionsLink': 'Pogoji uporabe',
  'home.generalTermsAndConditionsLink': 'Splošni pogoji uporabe',
  'home.privacyPolicyLink': 'Politika zasebnosti',
  'home.cookiesLink': 'Piškotki',
  'home.cookiesFooterLink': 'Nastavitve piškotkov',
  'home.footerLink9': 'PLAČA.SI',
  'home.dataProtectionAgreement': 'Pogodba o obdelavi osebnih podatkov',
}
