import * as type from 'Store/types'
import {
  ActionType,
  AttachmentType,
  AuthRoutingType,
  AuthStateType,
  CbtAccountStatus,
  ConsentType,
  EducationSaveValueType,
  EmployerProfileType,
  ErrorType,
  ImageType,
  LoginType,
  MeType,
  RegistrationType,
  ResetPasswordType,
  RjbProfileStatus,
  SeekerProfileType,
  SkillType,
  UserUpdateBioType,
} from 'Interfaces'

const initialState = {
  data: undefined,
  displayMessage: {
    emailChanged: false,
    passwordChanged: false,
    forgotPasswordEmailSent: false,
    resetPasswordSuccess: false,
    resendEmailVerification: false,
  },
  consents: [],
  seekerConsents: [],
  loading: false,
  error: undefined,
}

const AuthReducer = (
  state: AuthStateType = initialState,
  action: ActionType<
    | MeType
    | ErrorType
    | RegistrationType
    | LoginType
    | AuthRoutingType
    | AttachmentType
    | SkillType
    | UserUpdateBioType
    | EducationSaveValueType
    | SeekerProfileType
    | ResetPasswordType
    | ConsentType[]
    | RjbProfileStatus
    | CbtAccountStatus
  >
): AuthStateType => {
  switch (action.type) {
    case type.USER_FETCH_REQUESTED:
    case type.SEEKER_LOGIN_REQUESTED:
    case type.EMPLOYER_LOGIN_REQUESTED:
    case type.auth.changeEmail.requested:
    case type.auth.forgotPassword.requested:
    case type.auth.resetPassword.requested:
      return {
        ...state,
        loading: true,
        dirty: true,
        error: undefined,
      }
    case type.auth.resetEmployerPassword.requested:
      return {
        ...state,
        loading: true,
        dirty: true,
        error: undefined,
      }
    case type.auth.verifyEmail.requested:
      return {
        ...state,
        emailVerify: {
          data: undefined,
          loading: true,
          error: undefined,
          success: false,
        },
      }
    case type.auth.verifyChangeEmail.requested:
      return {
        ...state,
        emailChangeVerify: {
          data: undefined,
          loading: true,
          error: undefined,
          success: false,
        },
      }
    case type.auth.verifyEmail.succeeded:
      return {
        ...state,
        emailVerify: {
          loading: false,
          error: undefined,
          success: true,
        },
      }
    case type.auth.verifyChangeEmail.succeeded:
      return {
        ...state,
        emailChangeVerify: {
          loading: false,
          error: undefined,
          success: true,
        },
      }
    case type.seeker.getSeekerLoginConsents.requested:
    case type.seeker.acceptSeeekerLoginConsents.requested:
    case type.employer.getLoginConsents.requested:
    case type.employer.acceptLoginConsents.requested:
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case type.USER_FETCH_SUCCEEDED:
    case type.USER_LOGIN_SUCCEEDED:
    case type.seeker.register.succeeded:
      return {
        ...state,
        data: action.payload as MeType,
        loading: false,
        error: undefined,
      }
    case type.auth.rjbProfileStatus.update:
      return {
        ...state,
        data: {
          ...(state.data as MeType),
          rjbProfileStatus: action.payload as RjbProfileStatus,
        },
      }
    case type.auth.emailVerificationStatus.update:
      return {
        ...state,
        data: {
          ...(state.data as MeType),
          cbtAccountStatus: action.payload as CbtAccountStatus,
        },
      }
    case type.seeker.personalData.save.succeeded:
    case type.employer.personalData.save.succeeded:
      return {
        ...state,
        data: {
          ...(state.data as MeType),
          profilePicture: (action.payload as EmployerProfileType)
            ?.profilePicture as ImageType,
          firstName: (action.payload as SeekerProfileType)?.firstName as string,
          lastName: (action.payload as SeekerProfileType)?.lastName as string,
        },
        loading: false,
        error: undefined,
      }

    case type.systemLanguageUpdate.succeeded:
      return {
        ...state,
        data: {...(state.data as MeType), languageId: action.payload as string},
        loading: false,
        error: undefined,
      }
    case type.auth.changeEmail.succeeded:
      return {
        ...state,
        displayMessage: {
          ...state.displayMessage,
          emailChanged: true,
        },
        loading: false,
        error: undefined,
      }
    case type.auth.changeEmail.hideMessage:
      return {
        ...state,
        displayMessage: {
          ...state.displayMessage,
          emailChanged: false,
        },
      }
    case type.auth.changePassword.succeeded:
      return {
        ...state,
        displayMessage: {
          ...state.displayMessage,
          passwordChanged: true,
        },
        loading: false,
        error: undefined,
      }
    case type.auth.changePassword.hideMessage:
      return {
        ...state,
        displayMessage: {
          ...state.displayMessage,
          passwordChanged: false,
        },
      }
    case type.auth.resendVerifyEmail.succeeded:
      return {
        ...state,
        displayMessage: {
          ...state.displayMessage,
          resendEmailVerification: true,
        },
        loading: false,
        error: undefined,
      }
    case type.auth.resendVerifyEmail.hideMessage:
      return {
        ...state,
        displayMessage: {
          ...state.displayMessage,
          resendEmailVerification: false,
        },
        loading: false,
        error: undefined,
      }
    case type.auth.forgotPassword.succeeded:
      return {
        ...state,
        displayMessage: {
          ...state.displayMessage,
          forgotPasswordEmailSent: true,
        },
        loading: false,
        error: undefined,
      }
    case type.auth.forgotPassword.hideMessage:
      return {
        ...state,
        displayMessage: {
          ...state.displayMessage,
          forgotPasswordEmailSent: false,
        },
      }
    case type.auth.resetPassword.succeeded:
      return {
        ...state,
        displayMessage: {
          ...state.displayMessage,
          resetPasswordSuccess: true,
        },
        loading: false,
        error: undefined,
      }
    case type.auth.resetPassword.hideMessage:
      return {
        ...state,
        displayMessage: {
          ...state.displayMessage,
          resetPasswordSuccess: false,
        },
      }
    case type.contentLanguageUpdate.succeeded:
      return {
        ...state,
        data: {
          ...(state.data as MeType),
          contentLanguageId: action.payload as string,
        },
        loading: false,
        error: undefined,
      }
    case type.auth.confirmProfile.requested:
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case type.auth.confirmProfile.succeeded:
      return {
        ...state,
        data: {
          ...(state.data as MeType),
          rjbProfileStatus: action.payload as RjbProfileStatus,
        },
        loading: false,
        error: undefined,
      }
    case type.seeker.getSeekerLoginConsents.succeeded:
    case type.seeker.acceptSeeekerLoginConsents.succeeded:
      return {
        ...state,
        data: {
          ...(state.data as MeType),
          userConsents: action?.payload as ConsentType[],
        },
        loading: false,
        error: undefined,
      }
    case type.employer.getLoginConsents.succeeded:
    case type.employer.acceptLoginConsents.succeeded:
      return {
        ...state,
        data: {
          ...(state.data as MeType),
          companyConsents: action?.payload as ConsentType[],
        },
        loading: false,
        error: undefined,
      }
    case type.employerOnboarding.stepHiringPlan.succeeded:
      return {
        ...state,
        data: {
          ...state.data,
          companyStructure: {
            ...state.data?.companyStructure,
            company: {
              ...state.data?.companyStructure?.company,
              companyOnboardingStatus: 'done',
            },
          },
        } as MeType,
      }
    case type.auth.verifyEmail.failed:
      return {
        ...state,
        emailVerify: {
          data: undefined,
          loading: false,
          error: action.payload as ErrorType,
          success: false,
        },
      }
    case type.auth.verifyChangeEmail.failed:
      return {
        ...state,
        emailChangeVerify: {
          data: undefined,
          loading: false,
          error: action.payload as ErrorType,
          success: false,
        },
      }

    case type.auth.confirmProfile.failed:
    case type.auth.changeEmail.failed:
    case type.USER_FETCH_FAILED:
    case type.USER_LOGIN_FAILED:
    case type.systemLanguageUpdate.failed:
    case type.contentLanguageUpdate.failed:
    case type.auth.changePassword.failed:
    case type.auth.forgotPassword.failed:
    case type.auth.resetPassword.failed:
    case type.seeker.getSeekerLoginConsents.failed:
    case type.seeker.acceptSeeekerLoginConsents.failed:
    case type.employer.getLoginConsents.failed:
    case type.employer.acceptLoginConsents.failed:
      return {
        ...state,
        loading: false,
        error: action.payload as ErrorType,
      }
    case type.USER_LOGOUT_SUCCEEDED:
      return {
        ...initialState,
      }

    default:
      return state
  }
}

export default AuthReducer
