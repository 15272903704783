import {AppDispatch} from 'Store'
import {connect} from 'react-redux'
import {getConversationByUserIds} from 'Store/Actions'

import {RequestActions} from './RequestActions'

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getConversationByUserIds: (query: string) =>
    dispatch(getConversationByUserIds(query)),
})

export const RequestActionsContainer = connect(
  null,
  mapDispatchToProps
)(RequestActions)
