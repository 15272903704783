import {useMemo, useState, FC, useEffect} from 'react'
import {useFormik} from 'formik'
import SectionHeader from 'Components/Profile/SectionHeader'
import SectionItem from 'Components/Profile/SectionItem'
import ItemTextWithIcon from 'Components/Profile/ItemTextWithIcon'
import Modal from 'Components/Modal'
import ShowMore from 'Components/Profile/ShowMore'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import styles from './PublicWorkExperiences.module.scss'
import {AddValidation, EditValidation} from './Validation'
import {
  DeleteOutline,
  SupervisedUserCircleOutlined,
  Work,
  TimelapseOutlined,
  EditOutlined,
} from '@mui/icons-material'
import {
  BaseTaxonomyType,
  TaxonomiesStateType,
  SeekerStateType,
  PublicWorkExperienceItem,
  PublicWorkExperienceFormikValue,
  PublicWorkExperiencesUpdateFormikValue,
  PublicWorkExperiencesSaveValue,
} from 'Interfaces'
import {Sort} from './Sort'
import Title from 'Components/Title'
import Hr from 'Components/Hr'
import {useApiError} from 'Hooks'
import Dropdown from 'Components/Inputs/Dropdown'
import {Slider} from '@mui/material'
import {
  ProfileTranslations as pt,
  OnboardingTranslations as ot,
} from 'Services/I18n/Constants'
import {cloneDeep, remove} from 'lodash'
import TypeAheadImproved from 'Components/Inputs/TypeAheadImproved'

const sliderMarks = [
  {value: 2},
  {value: 3},
  {value: 4},
  {value: 5},
  {value: 6},
  {value: 7},
  {value: 8},
  {value: 9},
]

interface PublicWorkExperiencesProps {
  profile: SeekerStateType
  totalWorkExperience?: BaseTaxonomyType
  workExperiences?: PublicWorkExperienceItem[]
  taxonomies: TaxonomiesStateType
  canChange?: boolean
  getTaxonomies: () => void
  addPublicWorkExperience: (value: PublicWorkExperienceFormikValue) => void
  updatePublicWorkExperience: (value: PublicWorkExperiencesSaveValue) => void
}

export const PublicWorkExperiences: FC<PublicWorkExperiencesProps> = ({
  profile,
  totalWorkExperience,
  workExperiences = [],
  taxonomies,
  canChange = false,
  updatePublicWorkExperience,
  addPublicWorkExperience,
  getTaxonomies,
}) => {
  const addEmptyValues: PublicWorkExperienceFormikValue = {
    occupation: undefined,
    experience: 2,
  }
  const editEmptyValues: PublicWorkExperiencesUpdateFormikValue = {
    totalWorkExperience: totalWorkExperience,
    occupationExperiences: cloneDeep(workExperiences),
  }

  const [modalMode, setModalMode] = useState<'Add' | 'Edit' | null>(null)
  const [showMoreIndex, setShowMoreIndex] = useState(3)

  const localWorkExperiences = useMemo(() => {
    return workExperiences.sort(Sort).slice(0, showMoreIndex)
  }, [workExperiences, showMoreIndex])

  const packEditValues = (
    formikValues: PublicWorkExperiencesUpdateFormikValue
  ): PublicWorkExperiencesSaveValue => ({
    totalWorkExperienceId: formikValues.totalWorkExperience?.id,
    occupationExperiences: formikValues.occupationExperiences?.map(
      (occupationExperience) => ({
        id: occupationExperience.occupation.id,
        experience: occupationExperience.experience,
      })
    ),
  })

  const addFormik = useFormik({
    initialValues: addEmptyValues,
    validationSchema: AddValidation,

    onSubmit: (values) => {
      addPublicWorkExperience(values)
      setModalMode(null)
    },
  })

  const editFormik = useFormik({
    initialValues: editEmptyValues,
    validationSchema: EditValidation,

    onSubmit: (values) => {
      updatePublicWorkExperience(packEditValues(values))
      setModalMode(null)
    },
  })

  const availableJobTitles = useMemo(() => {
    const allItems = taxonomies.occupations?.data
    const selectedItems = workExperiences
    // filter out selected items
    if (allItems && selectedItems && selectedItems.length > 0) {
      return allItems.filter(
        (occupation) =>
          !selectedItems.some(
            (selected) => occupation.id === selected.occupation.id
          )
      )
    }
    return allItems
  }, [
    taxonomies.occupations?.data,
    editFormik.values.occupationExperiences,
    workExperiences,
  ])

  const openEdit = () => {
    setModalMode('Edit')
    editFormik.resetForm({
      values: editEmptyValues,
    })
    getTaxonomies()
  }

  const openAdd = () => {
    setModalMode('Add')
    addFormik.resetForm({
      values: addEmptyValues,
    })
    getTaxonomies()
  }

  const onDelete = (idx: number) => {
    let newValues = cloneDeep(
      editFormik.values.occupationExperiences
    ) as PublicWorkExperienceItem[]
    remove(newValues, (_, indx) => indx === idx)
    editFormik.setFieldValue(`occupationExperiences`, newValues)
  }

  const formik = addFormik
  const setTypeAheadField = (name: string, values: BaseTaxonomyType[]) => {
    formik.setFieldValue(name, values[0])
  }

  const setTotalWorkExperience = (name: string, value?: string | number) => {
    const totalWorkExperience = taxonomies.workExperiences?.data.find((we) => {
      return typeof value === 'string' && we.id === value
    })
    editFormik.setFieldValue(name, totalWorkExperience)
  }

  const formatLabelValue = (value: number) => {
    return value === 10 ? <div>{`${value}+`}</div> : value
  }

  const memoizedOccupationDropdown = useMemo(() => {
    return (
      <TypeAheadImproved
        name="occupation"
        defaultValue={
          addFormik.values.occupation ? [addFormik.values.occupation] : []
        }
        theme="modal"
        items={availableJobTitles}
        maxNumSelections={1}
        placeholder={pt.placeholderJobTitle}
        setValue={setTypeAheadField}
        setTouched={addFormik.getFieldHelpers('occupation').setTouched}
        error={
          formik.submitCount
            ? addFormik.errors.occupation
              ? addFormik.errors.occupation['id']
              : undefined
            : undefined
        }
        selectNonExisting
      />
    )
  }, [
    addFormik.values.occupation,
    availableJobTitles,
    addFormik.errors?.occupation,
    addFormik.submitCount,
  ])

  useApiError(formik.setFieldError, profile.error)

  useEffect(() => {
    if (!profile.loading && !profile.error) setModalMode(null)
  }, [profile.loading])

  return (
    <div className={styles.section}>
      <div>
        <SectionHeader
          onAdd={canChange ? openAdd : undefined}
          title={ot.titleWorkExperience}
        />
      </div>
      <SectionItem>
        {canChange && (
          <button onClick={openEdit} className={styles.editButton}>
            <EditOutlined sx={{fontSize: 25}} />
          </button>
        )}

        {totalWorkExperience?.translation && (
          <ItemTextWithIcon
            primaryText={pt.totalYearsOfExp}
            withColon
            secondaryText={totalWorkExperience.translation}
            icon={TimelapseOutlined}
          />
        )}

        {localWorkExperiences.length > 0 && (
          <ItemTextWithIcon
            primaryText={pt.workExpPerPosInYears}
            withColon
            icon={SupervisedUserCircleOutlined}
          />
        )}

        <div className={styles.workExperienceItemsWrap}>
          {localWorkExperiences.map((workItem, idx) => {
            return (
              <div key={idx}>
                <Hr theme="light" />
                <div className={styles.workExperienceItem}>
                  <span>{workItem.occupation.translation}</span>
                  <div className={styles.sliderWrap}>
                    <Slider
                      value={workItem.experience}
                      valueLabelDisplay="on"
                      valueLabelFormat={formatLabelValue}
                      step={1}
                      marks={sliderMarks}
                      min={1}
                      max={10}
                      disabled
                      sx={{
                        color: '#113a60',
                        '&.Mui-disabled': {
                          color: '#113a60',
                        },
                        '& .MuiSlider-valueLabel': {
                          backgroundColor: '#fbb015',
                          position: 'relative',
                          top: '28px',
                          padding: '0.25rem 0.3rem',
                          minWidth: '32px',
                        },
                        '& .MuiSlider-mark': {
                          color: '#fff',
                        },
                      }}
                    />
                  </div>
                </div>
                {idx + 1 === localWorkExperiences.length && (
                  <Hr theme="light" />
                )}
              </div>
            )
          })}
        </div>
      </SectionItem>

      <ShowMore
        items={workExperiences}
        showMoreIndex={showMoreIndex}
        setShowMoreIndex={setShowMoreIndex}
        defaultShowMoreIndex={3}
      />

      {/* Add modal */}
      <Modal
        isOpen={modalMode === 'Add'}
        onAfterClose={() => {
          setModalMode(null)
        }}
        title={ot.titleAddWorkExperience}
        icon={Work}
        ariaHideApp={false}
        className={styles.modal}
        theme="desktopFlex"
        Footer={
          <SubmitFooter
            onSubmit={addFormik.handleSubmit}
            onCancel={() => {
              setModalMode(null)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <Title text={pt.jobTitle} theme="modal" />
        {memoizedOccupationDropdown}
        <Hr theme="modal" />
        <Title text={pt.workExpPerPos} theme="modal" />
        <div className={styles.addSliderWrap}>
          <Slider
            onChange={(_, value) =>
              addFormik.setFieldValue('experience', value)
            }
            aria-label="Small steps"
            value={addFormik.values.experience}
            valueLabelDisplay="on"
            valueLabelFormat={formatLabelValue}
            marks={sliderMarks}
            step={1}
            min={1}
            max={10}
            sx={{
              color: '#113a60',
              '& .MuiSlider-valueLabel': {
                backgroundColor: '#fbb015',
                position: 'relative',
                top: '28px',
                padding: '0.25rem 0.3rem',
                minWidth: '32px',
              },
              '& .MuiSlider-mark': {
                color: '#fff',
              },
              '& .MuiSlider-thumb': {
                '&:hover, &.Mui-focusVisible, &.Mui-active': {
                  boxShadow: 'none',
                },
              },
            }}
          />
        </div>
      </Modal>

      {/* Edit modal */}
      <Modal
        isOpen={modalMode === 'Edit'}
        onAfterClose={() => {
          setModalMode(null)
        }}
        title={ot.titleEditWorkExperience}
        icon={Work}
        ariaHideApp={false}
        className={styles.modal}
        theme="desktopFlex"
        Footer={
          <SubmitFooter
            onSubmit={editFormik.handleSubmit}
            onCancel={() => {
              setModalMode(null)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <Title text={ot.titleTotalWorkExperience} theme="modal" />
        <Dropdown
          name="totalWorkExperience"
          items={taxonomies.workExperiences?.data}
          theme="white"
          value={editFormik.values.totalWorkExperience?.id}
          setValue={setTotalWorkExperience}
          setTouched={
            editFormik.getFieldHelpers('totalWorkExperience').setTouched
          }
          error={
            editFormik.submitCount && editFormik.touched['totalWorkExperience']
              ? editFormik.errors.totalWorkExperience
                ? editFormik.errors.totalWorkExperience['id']
                : undefined
              : undefined
          }
        />
        {editFormik.values.occupationExperiences && (
          <>
            <Hr theme="modal" />

            <Title text={pt.workExpPerPos} theme="modal" />
            <div>
              {editFormik.values.occupationExperiences.map((workItem, idx) => {
                return (
                  <div key={idx} className={styles.editWorkExperienceItem}>
                    <span className={styles.experienceTitle}>
                      <span>{workItem.occupation.translation}</span>
                      <button
                        onClick={() => onDelete(idx)}
                        className={styles.deleteAction}
                      >
                        <DeleteOutline sx={{fontSize: 25}} />
                      </button>
                    </span>
                    <div className={styles.sliderWrap}>
                      <Slider
                        onChange={(_, value) => {
                          const newValues = editFormik.values
                            .occupationExperiences as PublicWorkExperienceItem[]
                          newValues[idx].experience = value as number
                          editFormik.setFieldValue(
                            `occupationExperiences`,
                            newValues
                          )
                        }}
                        aria-label="Small steps"
                        value={
                          (
                            editFormik.values
                              .occupationExperiences as PublicWorkExperienceItem[]
                          )[idx].experience
                        }
                        valueLabelDisplay="on"
                        valueLabelFormat={formatLabelValue}
                        marks={sliderMarks}
                        step={1}
                        min={1}
                        max={10}
                        sx={{
                          color: '#113a60',
                          '& .MuiSlider-valueLabel': {
                            backgroundColor: '#fbb015',
                            position: 'relative',
                            top: '28px',
                            padding: '0.25rem 0.3rem',
                            minWidth: '32px',
                          },
                          '& .MuiSlider-mark': {
                            color: '#fff',
                          },
                          '& .MuiSlider-thumb': {
                            '&:hover, &.Mui-focusVisible, &.Mui-active': {
                              boxShadow: 'none',
                            },
                          },
                        }}
                      />
                    </div>
                    <button
                      onClick={() => onDelete(idx)}
                      className={styles.deleteActionDesktop}
                    >
                      <DeleteOutline sx={{fontSize: 25}} />
                    </button>
                  </div>
                )
              })}
            </div>
          </>
        )}
      </Modal>
    </div>
  )
}
