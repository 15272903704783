import {FC} from 'react'

import styles from './MessageParticipantBasicInformations.module.scss'

interface MessageParticipantBasicInformationsProps {
  firstName: string
  lastName: string
  publicOccupation: string
  dateOfBirth: string
  town: string
  companyName: string
}

export const MessageParticipantBasicInformations: FC<
  MessageParticipantBasicInformationsProps
> = ({
  firstName,
  lastName,
  publicOccupation,
  dateOfBirth,
  town,
  companyName,
}) => {
  return (
    <div className={styles.messageParticipantBasicInformations}>
      <div className={styles.content}>
        <p>
          <span className={styles.name}>{`${firstName} ${lastName}`}</span>
          <span className={styles.personalInformations}>
            {`${publicOccupation ? ', ' + publicOccupation : ''}`}
            {`${(dateOfBirth as string) ? ', ' + dateOfBirth : ''}`}
            {`${town ? ', ' + town : ''}`}
          </span>
        </p>
      </div>
      <p className={styles.companyName}>
        {`${companyName ? companyName : ''}`}
      </p>
    </div>
  )
}
