import {ApproveRejectModalRequestType} from 'Interfaces'
import {connect} from 'react-redux'

import {AppDispatch} from 'Store'
import {rejectOfferRequest} from 'Store/Actions'
import {ModalRejectOfferRequest} from './ModalRejectOfferRequest'
import {RootState} from 'Store/Reducers'

interface RequestProps {
  rejectModal: boolean
  setRejectModal: (modal: boolean) => void
  setDetails?: (details: boolean) => void
}

const mapStateToProps = (state: RootState, ownProps: RequestProps) => ({
  rejectModal: ownProps.rejectModal,
  setRejectModal: ownProps.setRejectModal,
  state: state,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  rejectOfferRequest: (payload: ApproveRejectModalRequestType) =>
    dispatch(rejectOfferRequest(payload)),
})

export const ModalRejectOfferRequestContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalRejectOfferRequest)
