import {Registration} from './Registration'
import {connect} from 'react-redux'
import {registerSeeker} from 'Store/Actions'
import {AppDispatch} from 'Store'
import {RegistrationType} from 'Interfaces'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  seeker: state.seeker,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  registerSeeker: (data: RegistrationType) => dispatch(registerSeeker(data)),
})

export const RegistrationContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Registration)
