export enum CandidateFAQ {
  yellowTitle = 'CandidateFAQ.yellowTitle',
  title = 'CandidateFAQ.title',

  titleFirstParagraph = 'CandidateFAQ.titleFirstParagraph',
  textFirstParagraph = 'CandidateFAQ.textFirstParagraph',
  textItemOneFirstParagraph = 'CandidateFAQ.textItemOneFirstParagraph',
  textItemTwoFirstParagraph = 'CandidateFAQ.textItemTwoFirstParagraph',
  textItemThreeFirstParagraph = 'CandidateFAQ.textItemThreeFirstParagraph',
  textItemFourFirstParagraph = 'CandidateFAQ.textItemFourFirstParagraph',

  titleSecondParagraph = 'CandidateFAQ.titleSecondParagraph',
  textSecondParagraph = 'CandidateFAQ.textSecondParagraph',

  titleFirstSection = 'CandidateFAQ.titleFirstSection',
  paragraphFirstSection = 'CandidateFAQ.paragraphFirstSection',
  titleSecondSection = 'CandidateFAQ.titleSecondSection',
  paragraphSecondSection = 'CandidateFAQ.paragraphSecondSection',
  titleThirdSection = 'CandidateFAQ.titleThirdSection',
  paragraphThirdSection = 'CandidateFAQ.paragraphThirdSection',
  titleFourthSection = 'CandidateFAQ.titleFourthSection',
  paragraphFourthSection = 'CandidateFAQ.paragraphFourthSection',
  titleFifthSection = 'CandidateFAQ.titleFifthSection',
  paragraphFifthSection = 'CandidateFAQ.paragraphFifthSection',
  titleSixthSection = 'CandidateFAQ.titleSixthSection',
  paragraphSixthSection = 'CandidateFAQ.paragraphSixthSection',
  titleSeventhSection = 'CandidateFAQ.titleSeventhSection',
  paragraphSeventhSection = 'CandidateFAQ.paragraphSeventhSection',

  titleThirdParagraph = 'CandidateFAQ.titleThirdParagraph',
  textThirdParagraph = 'CandidateFAQ.textThirdParagraph',

  bottomParagraphtitleFirst = 'CandidateFAQ.bottomParagraphtitleFirst',
  bottomParagraphtextFirst = 'CandidateFAQ.bottomParagraphtextFirst',
  bottomParagraphtitleSecond = 'CandidateFAQ.bottomParagraphtitleSecond',
  bottomParagraphtextSecond = 'CandidateFAQ.bottomParagraphtextSecond',
  bottomParagraphtitleThird = 'CandidateFAQ.bottomParagraphtitleThird',
  bottomParagraphtextThird = 'CandidateFAQ.bottomParagraphtextThird',
  bottomParagraphtitleFourth = 'CandidateFAQ.bottomParagraphtitleFourth',
  bottomParagraphtextFourth = 'CandidateFAQ.bottomParagraphtextFourth',
  bottomParagraphtitleFifth = 'CandidateFAQ.bottomParagraphtitleFifth',
  bottomParagraphtextFifth = 'CandidateFAQ.bottomParagraphtextFifth',

  lastSectiontitle = 'CandidateFAQ.lastSectiontitle',
  lastSectionFirstPart = 'CandidateFAQ.lastSectionFirstPart',
  lastSectionlink = 'CandidateFAQ.lastSectionlink',
  lastSectionSecondPart = 'CandidateFAQ.lastSectionSecondPart',
}
