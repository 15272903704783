import {FC, useEffect, useRef, useState} from 'react'

import ScoreTableItem from '../ScoreTableItem'
import LaunchIcon from '@mui/icons-material/Launch'

import styles from './ResultItem.module.scss'
import {PsychometricCompetenciesType} from 'Interfaces'
import {FullStatementBox} from '../FullStatementBox'
interface ResultItemProps {
  psychometricCompentencies: PsychometricCompetenciesType
}

export const ResultItem: FC<ResultItemProps> = ({
  psychometricCompentencies,
}) => {
  const tableValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  const [fullStatementBoxOpen, setFullStatementBoxOpen] = useState(false)
  const [overflowActive, setOverflowActive] = useState(false)
  const statementRef = useRef(null)

  const isOverflowActive = (event: any) => {
    return (
      event.offsetHeight < event.scrollHeight ||
      event.offsetWidth < event.scrollWidth
    )
  }

  useEffect(() => {
    if (isOverflowActive(statementRef.current)) {
      setOverflowActive(true)
      return
    }

    setOverflowActive(false)
  }, [isOverflowActive])

  return (
    <div className={styles.resultFileContainer}>
      <div className={styles.infomations}>
        <p className={styles.title}>{psychometricCompentencies.name}</p>
        <div className={styles.textIconWrapper}>
          <p className={styles.statement} ref={statementRef}>
            {psychometricCompentencies.psychometricCompetenceStatements[0].name}
          </p>
          {overflowActive && (
            <LaunchIcon
              onClick={() => {
                setFullStatementBoxOpen(true)
              }}
            />
          )}
          {fullStatementBoxOpen && (
            <FullStatementBox
              setFullStatementBoxOpen={setFullStatementBoxOpen}
              title={psychometricCompentencies.name}
              statement={
                psychometricCompentencies.psychometricCompetenceStatements[0]
                  .name
              }
            />
          )}
        </div>
      </div>
      <div className={styles.scoreTable}>
        {tableValues.map((number, index) => {
          return (
            <ScoreTableItem
              key={index}
              number={number}
              passingValue={1}
              activeItem={
                psychometricCompentencies.psychometricCompetenceStatements[0]
                  .score
              }
            />
          )
        })}
      </div>
    </div>
  )
}
