import {FC, useMemo} from 'react'
import {Link} from 'react-router-dom'
// import {AppRoute, SidebarTranslations as st} from 'Services/I18n/Constants'
import {AppRoute, SidebarTranslations as st} from 'Services/I18n/Constants'
import UpdateIcon from '@mui/icons-material/Update'

import {FormattedMessage} from 'react-intl'
import {getRoute} from 'Services/I18n/Utils'
import {TaxonomiesStateType} from 'Interfaces'
// import {getRoute} from 'Services/I18n/Utils'
interface EmployerCandidatesLinks {
  taxonomies: TaxonomiesStateType
}
export const EmployerCandidatesLinks: FC<EmployerCandidatesLinks> = ({
  taxonomies,
}) => {
  const availabilityId = useMemo(() => {
    const av = taxonomies.availabilities.data.find(
      (a) => a.name === 'Immediately'
    )
    return av?.id
  }, [taxonomies.availabilities.data])

  return (
    <>
      <h2>
        <FormattedMessage id={st.candidates} />
      </h2>
      <ul>
        <li>
          <Link to={getRoute(AppRoute.CandidateSearch)}>
            <FormattedMessage id={st.search} />
          </Link>
        </li>
        {availabilityId && (
          <li>
            <Link
              to={`${getRoute(
                AppRoute.CandidateSearch
              )}?availabilityIds=${availabilityId}`}
            >
              <span>
                <FormattedMessage id={st.availableImmediately} />
                <UpdateIcon />
              </span>
            </Link>
          </li>
        )}
        <li>
          <Link to={getRoute(AppRoute.MyProjects)}>
            <FormattedMessage id={st.projects} />
          </Link>
        </li>
        <li>
          <Link to={getRoute(AppRoute.SavedSearch)}>
            <FormattedMessage id={st.cvSubscriptions} />
          </Link>
        </li>
      </ul>
    </>
  )
}
