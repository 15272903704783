import ContactRequestActions from 'Components/EmployerDashboard/ContactRequestListItem/RequestActions'
import InterviewInvitationActions from 'Components/EmployerDashboard/InterviewInvitationListItem/RequestActions'
import OfferRequestActions from 'Components/EmployerDashboard/OfferListItem/RequestActions'
import PlacementRequestActions from 'Components/EmployerDashboard/PlacementsListItem/RequestActions'
import {IncomingRequestType} from 'Interfaces'
import {FC} from 'react'
import {RequestType} from '../Constants'

type RequestActionsProps = {
  request: IncomingRequestType
  placement?: IncomingRequestType[]
}

export const RequestActions: FC<RequestActionsProps> = ({
  request,
  placement,
}) => {
  if (request.type === RequestType.CONTACT_REQUEST) {
    return <ContactRequestActions request={request} />
  } else if (request.type === RequestType.INTERVIEW_REQUEST) {
    return <InterviewInvitationActions request={request} />
  } else if (request.type === RequestType.OFFER_REQUEST) {
    return <OfferRequestActions request={request} />
  }

  return <PlacementRequestActions request={request} placement={placement} />
}
