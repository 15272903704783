import {FC} from 'react'
import classNames from 'classnames'
import {useMediaQuery} from 'react-responsive'

import HowDoesItWorkSection from 'Components/HowDoesItWorkSection'
// import TopCompaniesBanner from 'Components/TopCompaniesBanner'
import {PublicPagesHeader} from 'Components/PublicPagesHeader'
import {EmployerLandingText} from 'Components/EmployerLandingText'
import {EmployerSteps} from 'Components/EmployerSteps'
import {HomePageFooter} from 'Components/HomePage'
import styles from './HomePageEmployer.module.scss'
// import HomepageTestimonials from 'Components/HomepageTestimonials'
// import {TestimonialsUserType} from 'Interfaces'

export const HomePageEmployer: FC = () => {
  const isMobile = useMediaQuery({
    query: '(max-width: 1159px)',
  })
  return (
    <div className={styles.main}>
      <PublicPagesHeader page="employer" />
      <div className={styles.content}>
        <div className={styles.EmployerLandingTextPosition}>
          <EmployerLandingText />
        </div>

        {/* Removed temporary on Maria Korosec request */}
        {/* <div className={styles.sectionWrapper}>
          <TopCompaniesBanner />
        </div> */}
      </div>
      <div
        className={classNames({
          [styles.gausianBlurMobileBackground]: isMobile,
        })}
      >
        <div className={styles.blueBackground}>
          <EmployerSteps />
          <HowDoesItWorkSection />
        </div>
      </div>

      {/* Removed temporary on Maria Korosec request */}
      {/* <div>
        <HomepageTestimonials type={TestimonialsUserType.COMPANY_USER} />
      </div> */}
      <HomePageFooter />
    </div>
  )
}
