import * as type from '../types'
import {
  ActionType,
  SavePartialStepPreference,
  SavePartialStepWorkExperience,
  SaveStepPreference,
  SaveStepUploadCvType,
  SaveStepWorkExperience,
  SaveStepBenefitsEducationType,
  SavePartialStepBenefitsEducationType,
} from 'Interfaces'

export const getOnboarding = (): ActionType<undefined> => {
  return {
    type: type.onboarding.requested,
  }
}

export const saveStepUploadCV = (
  values: SaveStepUploadCvType
): ActionType<SaveStepUploadCvType> => {
  return {
    type: type.stepUploadCv.requested,
    payload: values,
  }
}

export const saveStepJobPreferences = (
  values: SaveStepPreference
): ActionType<SaveStepPreference> => {
  return {
    type: type.stepJobPreferences.requested,
    payload: values,
  }
}

export const saveStepPartialJobPreferences = (
  values: SavePartialStepPreference
): ActionType<SavePartialStepPreference> => {
  return {
    type: type.stepPartialJobPreferences.requested,
    payload: values,
  }
}

export const saveStepWorkExperience = (
  values: SaveStepWorkExperience
): ActionType<SaveStepWorkExperience> => {
  return {
    type: type.stepWorkExperience.requested,
    payload: values,
  }
}
export const saveStepPartialWorkExperience = (
  values: SavePartialStepWorkExperience
): ActionType<SavePartialStepWorkExperience> => {
  return {
    type: type.stepPartialWorkExperience.requested,
    payload: values,
  }
}

export const saveStepBenefitsEducation = (
  value: SaveStepBenefitsEducationType
): ActionType<SaveStepBenefitsEducationType> => {
  return {
    type: type.stepBenefitsEducation.requested,
    payload: value,
  }
}

export const saveStepPartialBenefitsEducation = (
  benefitsData: SavePartialStepBenefitsEducationType
): ActionType<SavePartialStepBenefitsEducationType> => {
  return {
    type: type.stepPartialBenefitsEducation.requested,
    payload: {
      ...benefitsData,
    },
  }
}

export const setOnboardingStep = (value: number): ActionType<number> => {
  return {
    type: type.setStep,
    payload: value,
  }
}

export const clearOnboardingErrors = (): ActionType<undefined> => {
  return {
    type: type.onboarding.clearErrors,
  }
}
