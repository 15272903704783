import {support} from './Support/en'
import {privacyPolicy} from './PrivacyPolicy/en'
import {termsAndConditions} from './TermsAndConditions/en'
import {dataProtectionAgreement} from './DataProtectionAgreement/en'

export const publicPages = {
  ...support,
  ...privacyPolicy,
  ...termsAndConditions,
  ...dataProtectionAgreement,
}
