import {connect} from 'react-redux'

import {WorkExperience} from './WorkExperience'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {
  getTotalWorkExperiences,
  saveStepWorkExperience,
  saveStepPartialWorkExperience,
  getSkills,
  getOccupations,
  setOnboardingStep,
} from 'Store/Actions'
import {SaveStepWorkExperience, SavePartialStepWorkExperience} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  taxonomies: state.taxonomies,
  onboarding: state.onboarding,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getTaxonomies: (jobCategoryId?: string) => {
    dispatch(getTotalWorkExperiences())
    dispatch(getSkills())
    dispatch(
      getOccupations(jobCategoryId ? {jobCategoryId: jobCategoryId} : undefined)
    )
  },
  saveStepWorkExperience: (values: SaveStepWorkExperience) =>
    dispatch(saveStepWorkExperience(values)),
  saveStepPartialWorkExperience: (values: SavePartialStepWorkExperience) =>
    dispatch(saveStepPartialWorkExperience(values)),
  setOnboardingStep: (n: number) => dispatch(setOnboardingStep(n)),
})

export const WorkExperienceContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WorkExperience)
