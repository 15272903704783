import {FC, useState} from 'react'
import styles from './RequestActions.module.scss'
import PopoverList from 'Components/PopoverList'
import {IncomingRequestType, RjbProfileStatus} from 'Interfaces'
import classNames from 'classnames'
import {ContactRequestStatus} from 'Components/IncomingRequests/Constants'
import {FormattedMessage} from 'react-intl'
import {
  AppRoute,
  EmployerDashboardTranslations as edt,
} from 'Services/I18n/Constants'
import useRequestActionsArray from 'Components/IncomingRequests/hooks/useRequestActionsArray'
import ModalContactRequestsDetails from 'Components/EmployerDashboard/ContactRequestListItem/ModalContactRequestEmp/index'
import ModalSendMessageEmp from 'Components/EmployerDashboard/InterviewInvitationListItem/ModalSendMessageEmp/index'
import ModalSendOfferEmp from 'Components/EmployerDashboard/InterviewInvitationListItem/ModalSendOfferEmp/index'
import {useHistory} from 'react-router-dom'
import {getRoute} from 'Services/I18n/Utils'
import {Cancel, CheckCircle, MoreHoriz, Pending} from '@mui/icons-material'
type RequestActionsProps = {
  request: IncomingRequestType
  getConversationByUserIds?: (query: string) => void
}

const OptionalActions: FC<RequestActionsProps> = ({request}) => {
  const [hasAction] = useRequestActionsArray(request)
  const history = useHistory()
  const noLongerActiveCandidate =
    !request.seeker.hasFinishedPlacementWithCurrentEmployer &&
    request.seeker.rjbProfileStatus === RjbProfileStatus.CLOSED
  const onInterviewInvite = () => {
    history.push(
      getRoute(AppRoute.InviteCalendar).replace(
        ':candidateId',
        request.seeker.userId
      )
    )
  }

  const isSeekerInvitedForInterview = (request: IncomingRequestType) => {
    // If any interview req has any of these values - consider candidate invited
    const consideredInvitedValues = [
      'AVAILABLE',
      'INTERVIEW_CONFIRMED',
      'ACCEPTED',
      'PENDING',
      'PENDING_EXPIRING',
      'NEW_TIME_PROPOSED',
      'COMPLETED',
    ]
    return request.interviewRequests?.some((interviewRequest) =>
      consideredInvitedValues.includes(interviewRequest?.status)
    )
  }

  // Action card switch - static info icon / text
  if (request.status === ContactRequestStatus.PENDING) {
    return (
      <div className={classNames(styles.mainAction, styles.mainActionDisabled)}>
        <span className={classNames(styles.leftIcon, styles.yellowColor)}>
          <Pending />
        </span>
        <FormattedMessage id={edt.contactRequestPending} />
      </div>
    )
  } else if (hasAction('CONTACT_REQUEST_REJECTED')) {
    return (
      <div className={classNames(styles.mainAction, styles.mainActionDisabled)}>
        <span className={classNames(styles.leftIcon, styles.redColor)}>
          <Cancel />
        </span>
        <FormattedMessage id={edt.contactRequestRejected} />
      </div>
    )
  } else if (hasAction('CONTACT_REQUEST_EXPIRED')) {
    return (
      <div className={classNames(styles.mainAction, styles.mainActionDisabled)}>
        <span className={classNames(styles.leftIcon, styles.pinkColor)}>
          <Pending />
        </span>
        <FormattedMessage id={edt.contactRequestExpired} />
      </div>
    )
    // Contact Req Approved + interview req's array has 1+ 'invited value' -> lock action btn
  } else if (
    hasAction('CONTACT_REQUEST_APPROVED') &&
    isSeekerInvitedForInterview(request)
  ) {
    return (
      <div className={classNames(styles.mainAction, styles.mainActionDisabled)}>
        <span className={classNames(styles.leftIcon, styles.greenColor)}>
          <CheckCircle className={styles.greenColor} />
        </span>
        <span>
          <FormattedMessage id={edt.contactRequestInvitedToInterview} />
        </span>
      </div>
    )
    // Contact Req Approved & no interview invitations - invite btn
  } else if (
    hasAction('CONTACT_REQUEST_APPROVED') &&
    !isSeekerInvitedForInterview(request) &&
    !noLongerActiveCandidate
  ) {
    return (
      <button className={styles.mainAction} onClick={onInterviewInvite}>
        <span className={styles.mainActionEnabled}>
          <FormattedMessage id={edt.invite} />
        </span>
      </button>
    )
  }

  return null
}

const MoreActions: FC<RequestActionsProps> = ({
  request,
  getConversationByUserIds,
}) => {
  const [contactRequestDetails, setContactRequestDetails] = useState(false)
  const [sendMessageModal, setSendMessageModal] = useState(false)
  const [modalSendOfferEmp, setModalSendOfferEmp] = useState(false)
  const history = useHistory()
  const noLongerActiveCandidate =
    !request.seeker.hasFinishedPlacementWithCurrentEmployer &&
    request.seeker.rjbProfileStatus === RjbProfileStatus.CLOSED
  const onViewDetails = () => {
    setContactRequestDetails(true)
  }

  const onInvite = () => {
    history.push(
      getRoute(AppRoute.InviteCalendar).replace(
        ':candidateId',
        request.seeker.userId
      )
    )
  }

  const onSendOffer = () => {
    setModalSendOfferEmp(true)
  }

  const onSendMessage = () => {
    if (getConversationByUserIds) {
      getConversationByUserIds(`userIds=${request.seeker.userId}`)
    }

    setSendMessageModal(true)
  }

  let moreActions = [
    {
      text: edt.viewDetails,
      onClick: onViewDetails,
    },
  ]

  if (
    request.status === ContactRequestStatus.APPROVED &&
    !noLongerActiveCandidate
  ) {
    const approvedActions = [
      {
        text: edt.invite,
        onClick: onInvite,
      },
      {
        text: edt.sendOffer,
        onClick: onSendOffer,
      },
      {
        text: edt.sendMessage,
        onClick: onSendMessage,
      },
    ]
    moreActions = [...moreActions, ...approvedActions]
  }
  return (
    <div className={styles.moreAction} role="button">
      <PopoverList
        triggerIcon={<MoreHoriz />}
        items={moreActions}
        threeDotsBtn
      />
      <ModalContactRequestsDetails
        request={request}
        isOpenContactRequest={contactRequestDetails}
        setIsOpenContactRequest={setContactRequestDetails}
      />
      <ModalSendMessageEmp
        request={request}
        isOpen={sendMessageModal}
        setIsOpen={setSendMessageModal}
      />
      <ModalSendOfferEmp
        isOpen={modalSendOfferEmp}
        setIsOpen={setModalSendOfferEmp}
        requestId={request.journeyId}
      />
    </div>
  )
}

export const RequestActions: FC<RequestActionsProps> = ({
  request,
  getConversationByUserIds,
}) => (
  <div onClick={(e) => e.stopPropagation()} className={styles.actionWrap}>
    <OptionalActions request={request} />
    <MoreActions
      request={request}
      getConversationByUserIds={getConversationByUserIds}
    />
  </div>
)
