export const companyWelcomeMessage = {
  'companyWelcomeMessage.modalTitle': 'Naslovni Pozdrav',
  'companyWelcomeMessage.add': 'Dodajte',
  'companyWelcomeMessage.edit': 'Uredi',

  'companyWelcomeMessage.languageTitle': 'Jezik',

  'companyWelcomeMessage.personaTitle': 'Persona',
  'companyWelcomeMessage.addPersonaText': 'Dodajte persono',

  'companyWelcomeMessage.welcomeTitleTitle': 'Naslovni pozdrav',
  'companyWelcomeMessage.welcomeTitlePlaceholderText': 'Vnesite pozdrav ...',

  'companyWelcomeMessage.welcomeTextTitle': 'Naslovno besedilo',
  'companyWelcomeMessage.welcomeTextPlaceholderText':
    'Vnesite naslovno besedilo',

  'companyWelcomeMessage.deleteButtonText': 'Izbrišite',
}
