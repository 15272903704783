import {
  CandidateDropdownOptionResponseType,
  CandidateDropdownOptionType,
} from 'Interfaces'

export const packDropdownSeekerItems = (
  items: CandidateDropdownOptionResponseType[]
): CandidateDropdownOptionType[] =>
  items.map((item) => {
    let label = ''
    label += item?.firstName ? `${item?.firstName} ` : ''

    label += item?.lastName ? `${item?.lastName}` : ''

    if (label !== '' && item?.publicOccupation) {
      label += `, ${item.publicOccupation.translation}`
    } else if (item?.publicOccupation) {
      label += `${item.publicOccupation.translation}`
    }

    return {
      id: item.userId,
      label: label,
    }
  })
