import {connect} from 'react-redux'
import {Page404} from './Page404'
import {RootState} from 'Store/Reducers'
import {getUser} from 'Store/Actions'
import {AppDispatch} from 'Store'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  employer: state.employer,
  seeker: state.seeker,
})
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getUser: () => dispatch(getUser()),
})

export const Page404Container = connect(
  mapStateToProps,
  mapDispatchToProps
)(Page404)
