import {
  BaseTaxonomyType,
  DataType,
  SystemLanguageType,
  TaxonomiesStateType,
  WelcomeMessageDataType,
  WelcomeMessageTranslatedTextDataType,
} from 'Interfaces'

// - CURRENT LANGUAGE -

// Get {id, name} language from systemLanguage taxonomy with given languageId(current lang - auth)
export const getCurrentLanguage = (
  authLanguageId: string,
  systemLanguageTaxonomy: DataType<SystemLanguageType>
): SystemLanguageType => {
  const language = systemLanguageTaxonomy.data.find(
    (systemLanguageObject: SystemLanguageType) =>
      systemLanguageObject.id === authLanguageId
  )
  return language ? language : {id: '', name: ''}
}

export const handleChangeOfCurrentMessageLanguage = (
  authLanguageId: string | undefined,
  systemLanguages: DataType<SystemLanguageType>,
  setCurrentMessageLanguage: (value: SystemLanguageType) => void
) => {
  if (authLanguageId && systemLanguages) {
    const currentLanguage = getCurrentLanguage(authLanguageId, systemLanguages)
    setCurrentMessageLanguage(currentLanguage)
  }
}

// -  WELCOME MESSAGES FILTERS -

// Filter translatedTexts data by with(for) given (current) language
export const filterTranslatedTextByLanguage = (
  systemLangId: string | undefined,
  translatedTexts: WelcomeMessageTranslatedTextDataType[]
): WelcomeMessageTranslatedTextDataType | undefined => {
  const filteredData = translatedTexts.find(
    (translatedText) => translatedText.language.id === systemLangId
  )
  return filteredData
}

// Get Messages (translatedTexts within messages) that match given language
export const filterWelcomeMessagesByLanguage = (
  systemLangId: string | undefined,
  welcomeMessages: WelcomeMessageDataType[]
): WelcomeMessageDataType[] => {
  const filteredByTranslatedTextLanguage = welcomeMessages.map(
    (welcomeMessage) => {
      return {
        ...welcomeMessage,
        translatedTexts: welcomeMessage.translatedTexts.filter(
          (translatedText) => translatedText.language.id === systemLangId
        ),
      }
    }
  )
  // Return only messages that have translatedText
  return filteredByTranslatedTextLanguage.filter((welcomeMessage) => {
    if (welcomeMessage.translatedTexts[0]) return true
    return false
  })
}

// - TEXT UTILS -

// Get first 3 letters uppercase (SLO)
export const getLettersUppercase = (str: string, numOfLetters: number = 3) => {
  return str.slice(0, numOfLetters).toUpperCase()
}

// - DROPDOWN -

export const setDropdownField = (
  name: string, // jobCategory | language
  value: BaseTaxonomyType | undefined,
  formik: any,
  taxonomies: TaxonomiesStateType
) => {
  // Find Which taxonomy dropdown works with:
  let taxonomyName = ''
  if (name.includes('jobCategory')) taxonomyName = 'jobCategories'
  if (name.includes('language')) taxonomyName = 'systemLanguages'

  // Find chosen taxonomy option - eg. {id: 123, translation: programming..}
  const selectedOption = taxonomies?.[taxonomyName]?.data?.find(
    (taxValue: BaseTaxonomyType) => {
      return typeof value?.id === 'string' && taxValue.id === value.id
    }
  )

  // set value
  formik.setFieldValue(name, selectedOption)
}

// - LANGUAGE - JOB CATEGORIES OPTIONS

// Get available(unused ones) languages for specific category
export const getAvailableLanguagesForJobCategory = (
  welcomeMessage: WelcomeMessageDataType, // formik value
  languagesOptions: SystemLanguageType[] // taxonomy
): SystemLanguageType[] => {
  const jobCategoryLanguagesIds = welcomeMessage.translatedTexts.map(
    (translatedText) => translatedText.language.id
  )
  const newLanguagesOptions = languagesOptions.filter((languageOption) => {
    return !jobCategoryLanguagesIds.includes(languageOption.id)
  })
  return newLanguagesOptions
}

export interface AvailableCategoryWithAvailableLanguages {
  jobCategoryId?: string
  jobCategory: BaseTaxonomyType
  languages: SystemLanguageType[]
}
// Get available Categories with Languages available for it (only get ones that have 1+ languages available)
export const getAvailableJobCategoriesWithAvailableLanguages = (
  welcomeMessages: WelcomeMessageDataType[], // formik value
  languagesOptions: SystemLanguageType[], // taxonomy
  jobCategories: BaseTaxonomyType[] // taxonomy
): AvailableCategoryWithAvailableLanguages[] => {
  const usedJobCategoriesIds = welcomeMessages.map(
    (welcomeMessage) => welcomeMessage.jobCategory.id
  )
  const unusedJobCategories = jobCategories.filter(
    (jobCategory) => !usedJobCategoriesIds.includes(jobCategory.id)
  )
  const unusedJobCategoriesWithLanguages = unusedJobCategories.map(
    (jobCategory) => ({
      jobCategoryId: jobCategory.id,
      jobCategory,
      languages: languagesOptions,
    })
  )

  const availableJobCategoriesWithLanguages = welcomeMessages
    .map((welcomeMessage) => {
      const availableLanguages = getAvailableLanguagesForJobCategory(
        welcomeMessage,
        languagesOptions
      )
      return {
        jobCategoryId: welcomeMessage.jobCategory.id,
        jobCategory: welcomeMessage.jobCategory,
        languages: availableLanguages,
      }
    })
    .filter(
      (jobCategoryWithAvailableLanguages) =>
        jobCategoryWithAvailableLanguages.languages.length > 0
    )
  return [
    ...availableJobCategoriesWithLanguages,
    ...unusedJobCategoriesWithLanguages,
  ]
}

// JOB CATEGORY - LANGUAGE COMBO HELPERS

// FORM
// Determine starting values:
// -> Loop jobCategory taxonomy to create id key for the object
// -> Add languages taxonomy to each id(all langs)
// -> Loop welcomeMessages(job Category id) - translationText(language)
// -> Update jobCategoryLanguages array of languages - remove ones that exist in welcomeMessage
// -> welcomeMessage.jobCategory.id -> jobCategoryLanguages[id]
// -> welcomeMessage.translatedText.language.id => jobCategoryLanguages[id] (remove found)
export const setupJobCategoriesLanguages = (
  taxonomies: TaxonomiesStateType,
  welcomeMessages: WelcomeMessageDataType[]
) => {
  const jobCategoriesLanguages = {
    // '123bc - job-cat id': ['abc - lang id', 'cba - lang id'],
    // '124ab': ['abc'],
    // '125cb': [],
  }
  // Initial(full) jobCategoryLanguages
  taxonomies.jobCategories?.data.forEach((jobCategory) => {
    const languagesIds = taxonomies.systemLanguages.data.map(
      (language) => language.id
    )
    jobCategoriesLanguages[jobCategory.id] = languagesIds
  })
  // Find already used Languages for job Category & remove them
  welcomeMessages.forEach((welcomeMessage) => {
    const jobCategoryId = welcomeMessage.jobCategory.id
    const translatedTexts = welcomeMessage.translatedTexts
    const alreadyUsedLanguages: string[] = translatedTexts.map(
      (translatedText) => translatedText.language.id
    )

    const oldLanguages = jobCategoriesLanguages[jobCategoryId]
    const newLanguages = oldLanguages?.filter(
      (languageId: string) => !alreadyUsedLanguages.includes(languageId)
    )
    jobCategoriesLanguages[jobCategoryId] = newLanguages
  })
  return jobCategoriesLanguages
}

//FORM - JOB CATEGORIES OPTIONS
// Changes based on changes in jobCategoriesLanguages X
// If given jobCategory id is empty array - filter it out from options X
// When form is deleted - find job category id and and language and update jobCategoriesLanguages - TODO:
// Update jobCategory options based on jobCategory Languages
// Update on UseEffect(jobCategoriesLanguages) - only ones that have length 1+ X
// [
//   {id: '123bc - job-cat id', translation: 'Programming'}
// ]
export const setupJobCategoriesOptions = (
  taxonomies: TaxonomiesStateType,
  jobCategoriesLanguagesState?: any
) => {
  let jobCategoriesOptions: BaseTaxonomyType[] = taxonomies.jobCategories?.data
  // If categoriesLanguages passed, set accordingly, otherwise set default taxonomies
  if (jobCategoriesLanguagesState) {
    jobCategoriesOptions = jobCategoriesOptions.filter((jobCategoryOption) => {
      const jobCategoryId = jobCategoryOption.id
      const jobCategoryLanguages = jobCategoriesLanguagesState[jobCategoryId]
      return jobCategoryLanguages?.length > 0
    })
  }
  return jobCategoriesOptions
}
