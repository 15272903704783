import {FC, ReactNode} from 'react'
import classnames from 'classnames'
import styles from './SectionItem.module.scss'

interface SectionItemProps {
  children: ReactNode
  className?: string
}

export const SectionItem: FC<SectionItemProps> = ({
  children,
  className,
}: SectionItemProps) => {
  return <div className={classnames(styles.item, className)}>{children}</div>
}
