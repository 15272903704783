import {AppDispatch} from 'Store'
import {connect} from 'react-redux'
import {CognitiveTest} from './CognitiveTest'
import {RootState} from 'Store/Reducers'
import {
  getCognitiveTestResults,
  getAssessments,
  getConsents,
  changeCognitiveTestStatus,
  changeCognitiveConsentStatus,
} from 'Store/Actions'
import {ChangeCognitiveConsentStatusType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  user: state.auth.data,
  downloadLink: state?.assessments?.cognitiveTestResults?.items[0]?.resultFile,
  assessments: state.assessments,
  consents: state?.consents?.data.consents,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getAssessments: () => dispatch(getAssessments()),
  getCognitiveTestResults: (value: string) =>
    dispatch(getCognitiveTestResults(value)),
  getConsents: () => dispatch(getConsents()),
  changeCognitiveTestStatus: (value: string) =>
    dispatch(changeCognitiveTestStatus(value)),
  changeCognitiveConsentStatus: (value: ChangeCognitiveConsentStatusType) =>
    dispatch(changeCognitiveConsentStatus(value)),
})

export const CognitiveTestContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CognitiveTest)
