import {FC, useCallback, useEffect, useMemo} from 'react'
import {useFormik} from 'formik'
import {FormattedMessage} from 'react-intl'
import {Link, useHistory} from 'react-router-dom'
import classnames from 'classnames'

import {
  AuthStateType,
  ConsentToSend,
  ConsentType,
  EmployerConsentId,
} from 'Interfaces'
import styles from './EmployerLoginConsents.module.scss'
import {Validation} from './Validation'
import {Title} from 'Components/Login'
import YellowButton from 'Components/YellowButton'
import {EmployerLoginLayout} from 'Layouts/EmployerLoginLayout/EmployerLoginLayout'
import {
  AppRoute,
  ConsentsTranslations as ct,
  LoginTranslations as lt,
  RegistrationTranslations as rt,
} from 'Services/I18n/Constants'
import Checkbox from 'Components/Inputs/Checkbox'
import {getRoute} from 'Services/I18n/Utils'
import TextError from 'Components/Error/TextError'
import {hasGrantedMandatoryConsents} from 'Utils/Consents'

interface EmployerLoginProps {
  auth: AuthStateType
  getEmployerLoginConsents: () => void
  acceptEmployerLoginConsents: (values: ConsentToSend[]) => void
}

export const EmployerLoginConsents: FC<EmployerLoginProps> = ({
  getEmployerLoginConsents,
  auth,
  acceptEmployerLoginConsents,
}) => {
  const history = useHistory()
  const employerConsents = useMemo(() => {
    if (auth.data?.companyConsents?.length) {
      const generalTermsOfUse = auth.data.companyConsents.find(
        (consent: ConsentType) =>
          consent.id === EmployerConsentId.GeneralTermsOfUse
      )
      const privacyPolicy = auth.data.companyConsents.find(
        (consent: ConsentType) => consent.id === EmployerConsentId.PrivacyPolicy
      )
      const dataProtectionAgreement = auth.data.companyConsents.find(
        (consent: ConsentType) =>
          consent.id === EmployerConsentId.DataProtectionAgreement
      )
      return {
        generalTermsOfUse,
        privacyPolicy,
        dataProtectionAgreement,
      }
    } else return {}
  }, [auth.data, auth.data?.companyConsents])

  const packValues = useCallback(
    (values) => {
      let tc: ConsentToSend[] = []
      let pc: ConsentToSend[] = []
      if (values.tcCheckbox && employerConsents.generalTermsOfUse) {
        tc = [{id: employerConsents.generalTermsOfUse.id, status: 'granted'}]
      }
      if (
        values.privacyCheckbox &&
        employerConsents.privacyPolicy &&
        employerConsents.dataProtectionAgreement
      ) {
        pc = [
          {id: employerConsents.privacyPolicy.id, status: 'granted'},
          {id: employerConsents.dataProtectionAgreement.id, status: 'granted'},
        ]
      }
      return [...tc, ...pc]
    },
    [employerConsents]
  )

  const formik = useFormik({
    initialValues: {
      privacyCheckbox: false,
      tcCheckbox: false,
    },
    validationSchema: Validation,
    onSubmit: (values) => {
      const packedConsents = packValues(values)
      !auth.loading && acceptEmployerLoginConsents(packedConsents)
    },
  })

  useEffect(() => {
    if (auth.data && !auth.data.companyConsents?.length) {
      getEmployerLoginConsents()
    } else if (auth.data?.companyConsents?.length) {
      formik.setFieldValue(
        'tcCheckbox',
        employerConsents.generalTermsOfUse?.consentVersions[0]
          .companyConsentsVersions[0]?.status === 'granted'
      )
      formik.setFieldValue(
        'privacyCheckbox',
        employerConsents.privacyPolicy?.consentVersions[0]
          .companyConsentsVersions[0]?.status === 'granted' &&
          employerConsents.dataProtectionAgreement?.consentVersions[0]
            .companyConsentsVersions[0]?.status === 'granted'
      )
    }
  }, [employerConsents])

  useEffect(() => {
    const grantedConsents = hasGrantedMandatoryConsents(auth.data)
    if (auth.data && grantedConsents) {
      history.push(getRoute(AppRoute.EmployerDashboard))
    }
  }, [auth.data])

  return (
    <EmployerLoginLayout>
      <div className={styles.formWrap}>
        <Title text={lt.title} className={styles.title} />

        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <div className={styles.inputWrap}>
            <Checkbox
              className={classnames(styles.cardCheckInput)}
              name={'privacyCheckbox'}
              onChange={(e) =>
                formik.setFieldValue('privacyCheckbox', e.target.checked)
              }
              value={formik.values.privacyCheckbox}
              error={
                formik.submitCount ? formik.errors.privacyCheckbox : undefined
              }
            >
              <FormattedMessage id={rt.agree} />
              <Link
                to={getRoute(AppRoute.PrivacyPolicy)}
                target={'_blank'}
                rel={'noopener noreferrer'}
                className={styles.link}
              >
                <FormattedMessage id={rt.privacyPolicy} />
              </Link>
            </Checkbox>
            <Checkbox
              className={classnames(styles.cardCheckInput)}
              name={'tcCheckbox'}
              onChange={(e) =>
                formik.setFieldValue('tcCheckbox', e.target.checked)
              }
              value={formik.values.tcCheckbox}
              error={formik.submitCount ? formik.errors.tcCheckbox : undefined}
            >
              <FormattedMessage id={rt.agree} />
              <Link
                to={getRoute(AppRoute.GeneralTermsAndConditions)}
                target={'_blank'}
                rel={'noopener noreferrer'}
                className={styles.link}
              >
                <FormattedMessage id={rt.generalTermsOfService} />
              </Link>
              <FormattedMessage id={rt.and} />
              <Link
                to={getRoute(AppRoute.TermsAndConditions)}
                target={'_blank'}
                rel={'noopener noreferrer'}
                className={styles.link}
              >
                <FormattedMessage id={rt.termsOfService} />
              </Link>
            </Checkbox>
            <div className={styles.button}>
              <YellowButton text={ct.consentGrant} />
            </div>
            {auth.error && <TextError text={auth.error.message} />}
          </div>
        </form>
      </div>
    </EmployerLoginLayout>
  )
}
