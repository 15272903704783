import {connect} from 'react-redux'
import {ReviewCandidate} from './ReviewCandidate'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {
  getCanidateReviewData,
  addCandidateReview,
  editCandidateReview,
} from 'Store/Actions'
import {ReviewCandidateType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  reviewData: state.employer.reviewData,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCanidateReviewData: (id: string) => dispatch(getCanidateReviewData(id)),
  addCandidateReview: (values: ReviewCandidateType) =>
    dispatch(addCandidateReview(values)),
  editCandidateReview: (values: ReviewCandidateType) =>
    dispatch(editCandidateReview(values)),
})

export const ReviewCandidateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ReviewCandidate)
