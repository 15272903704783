import {FC, useEffect} from 'react'
import {
  IFrameEventType,
  SpectoProposeNewTimeParamsType,
  SpectoStateType,
} from 'Interfaces'
import MainLayout from 'Layouts/MainLayout'
import styles from '../Calendar/SpectoCalendar.module.scss'
import {WidgetBarContainer} from 'Layouts/MainLayout/WidgetBar'
import {SidebarLink} from 'Components/SidebarLink'
import {
  AppRoute,
  spectoCalendarTranslations as sct,
} from 'Services/I18n/Constants'
import {getChannel} from 'Utils/CommonHelpers'
import {useHistory} from 'react-router-dom'
import {getLang, getRoute} from 'Services/I18n/Utils'

interface EmployerProposeNewTimeCalendarProps {
  spectoState: SpectoStateType
  getSpectoToken: () => void
  getCandidateSpectoId: () => void
  setSpectoProposeNewTime: (values: SpectoProposeNewTimeParamsType) => void
}

export const EmployerProposeNewTimeCalendar: FC<
  EmployerProposeNewTimeCalendarProps
> = ({
  spectoState,
  getSpectoToken,
  getCandidateSpectoId,
  setSpectoProposeNewTime,
}) => {
  const history = useHistory()
  const handleIframeEvents = ({data}: IFrameEventType) => {
    if (data.type === 'close-candidate-calendar') {
      history.push(getRoute(AppRoute.EmployerDashboard))
    }
  }
  useEffect(() => {
    if (!spectoState.data.token) {
      getSpectoToken()
    }
    if (spectoState.data.candidateId) {
      getCandidateSpectoId()
    }
    window.addEventListener('message', handleIframeEvents)

    return () => {
      window.removeEventListener('message', handleIframeEvents)
      setSpectoProposeNewTime({
        requestId: undefined,
        candidateId: undefined,
        candidateSpectoId: undefined,
      })
    }
  }, [])

  const channel = getChannel()
  const spectoCalendarUrl = channel?.spectoCalendarUrl
  const lang = getLang()

  return (
    <MainLayout
      withoutLogo
      fixedRight={false}
      rightSidebarContent={
        <WidgetBarContainer
          sidebarContent={
            <div className={styles.linksContainer}>
              <SidebarLink
                routeName={AppRoute.EmployerDashboard}
                text={sct.linkEmployerDashboard}
              />
              <SidebarLink
                routeName={AppRoute.EmployerInterviewInvitations}
                text={sct.linkInterviewInvitation}
              />
              <SidebarLink
                routeName={AppRoute.CandidateSearch}
                text={sct.linkSearchCandidates}
              />
            </div>
          }
        />
      }
    >
      <div className={styles.iframeWrap}>
        {!spectoState.data.token ||
        !spectoState.data.candidateSpectoId ||
        !spectoState.data.proposeNewTimeId ? null : (
          <iframe
            src={`${spectoCalendarUrl}/?token=${spectoState.data.token}&candidate=${spectoState.data.candidateSpectoId}&propose_new_time=${spectoState.data.proposeNewTimeId}&lang=${lang}`}
          />
        )}
      </div>
    </MainLayout>
  )
}
