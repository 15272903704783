export enum CandidateSearchTranslations {
  savedSearchModalTitle = 'savedSearch.modalTitle',
  savedSearchEditModalTitle = 'savedSearch.editModalTitle',
  savedSearchSaveUpdateTitle = 'savedSearch.saveUpdateTitle',
  savedSearchTitle = 'savedSearch.title',
  savedSearchExistingTitle = 'savedSearch.existingTitle',
  savedSearchInputPlaceholder = 'savedSearch.inputPlaceholder',
  savedSearchAddToFavorites = 'savedSearch.addToFavorites',
  savedSearchFrequencyTitle = 'savedSearch.frequencyTitle',
  savedSearchFrequencyDescription = 'savedSearch.frequencyDescription',
  savedSearchSelectFrequency = 'savedSearch.selectFrequency',
  savedSearchUpdateSearch = 'savedSearch.updateSearch',
  savedSearchSaveSearch = 'savedSearch.saveSearch',
  savedSearchPageTitle = 'savedSearch.pageTitle',
  savedSearchColumnHeaderName = 'savedSearch.columnHeaderName',
  savedSearchColumnHeaderCreated = 'savedSearch.columnHeaderCreated',
  savedSearchColumnHeaderFavorites = 'savedSearch.columnHeaderFavorites',
  savedSearchColumnHeaderNotifications = 'savedSearch.columnHeaderNotifications',
  savedSearchColumnHeaderLastCSASent = 'savedSearch.columnHeaderLastCSASent',
  savedSearchColumnHeaderActions = 'savedSearch.columnHeaderActions',
  savedSearchOverwriteWarning = 'savedSearch.overwriteWarning',
  savedSearchNewSavedSearch = 'savedSearch.newSaveSearch',
  savedSearchUpdateSavedSearch = 'savedSearch.updateSaveSearch',
  savedSearchDeleteModal = 'candidateSearch.savedSearchDeleteModal',
  mainTitle = 'candidateSearch.mainTitle',
  mainPlaceholder = 'candidateSearch.mainPlaceholder',
  mainLabel = 'candidateSearch.mainLabel',
  mainButton = 'candidateSearch.mainButton',
  locationPlaceholder = 'candidateSearch.locationPlaceholder',
  locationLabel = 'candidateSearch.locationLabel',
  title = 'candidateSearch.title',
  text = 'candidateSearch.text',
  optionFirst = 'candidateSearch.optionFirst',
  optionSecond = 'candidateSearch.optionSecond',
  optionLast = 'candidateSearch.optionLast',
  removeButton = 'candidateSearch.removeButton',
  modalMainTitle = 'candidateSearch.modalMainTitle',
  modalTitleSecond = 'candidateSearch.modalTitleSecond',
  removeModalTitle = 'candidateSearch.removeModalTitle',
  removeModalPlaceholder = 'candidateSearch.removeModalPlaceholder',
  textPlaceholder = 'candidateSearch.textPlaceholder',
  narrowResults = 'candidateSearch.narrowResults',
  saveOrSubButton = 'candidateSearch.saveOrSubButton',
  sortFirst = 'candidateSearch.sortFirst',
  sortSecond = 'candidateSearch.sortSecond',
  sortThird = 'candidateSearch.sortThird',
  sortFourth = 'candidateSearch.sortFourth',
  personaDropdown = 'candidateSearch.personaDropdown',
  personaDropdownPlaceholder = 'candidateSearch.personaDropdownPlaceholder',
  positionDropdown = 'candidateSearch.positionDropdown',
  regionsDropdown = 'candidateSearch.regionsDropdown',
  regionsDropdownPlaceholder = 'candidateSearch.regionsDropdownPlaceholder',
  degreeDropdown = 'candidateSearch.degreeDropdown',
  languageDropdown = 'candidateSearch.languageDropdown',
  languageLvLDropdown = 'candidateSearch.languageLvLDropdown',
  skillsDropdown = 'candidateSearch.skillsDropdown',
  skillsLvLDropdown = 'candidateSearch.skillsLvlDropdown',
  carierDropdown = 'candidateSearch.carierDropdown',
  carierLvlDropdown = 'candidateSearch.carierLvlDropdown',
  assessmentsDropdown = 'candidateSearch.assessmentsDropdown',
  dLDropdown = 'candidateSearch.dLDropdown',
  mdrjbDropdown = 'candidateSearch.mdrjbDropdown',
  currentFilter = 'candidateSearch.currentFilter',
  clearForm = 'candidateSearch.clearForm',
  minimizeSearch = 'candidateSearch.minimizeSearch',
  maximizeSearch = 'candidateSearch.maximizeSearch',
  selectButton = 'candidateSearch.selectButton',
  contactRequst = 'candidateSearch.contactRequst',
  contactRequestPopoverTitle = 'candidateSearch.contactRequestPopoverTitle',
  contactRequestExpired = 'candidateSearch.contactRequestExpired',
  contactRequestRejected = 'candidateSearch.contactRequestRejected',
  contactRequestRejectedWithoutDate = 'candidateSearch.contactRequestRejectedWithoutDate',
  errorMsg = 'candidateSearch.errorMsg',
  txtField = 'candidateSearch.txtField',
  addProject = 'candidateSearch.addProject',
  noProject = 'candidateSearch.noProject',
  removeProject = 'candidateSearch.removeProject',
  createTitle = 'candidateSearch.createTitle',
  projectName = 'candidateSearch.projectName',
  saveProject = 'candidateSearch.saveProject',
  removeIndividuallProject = 'candidateSearch.removeIndividuallProject',
  findProject = 'candidateSearch.findProject',
  careerStatus = 'candidateSearch.careerStatus',
  ageLabel = 'candidateSearch.ageLabel',
  availabilityLabel = 'candidateSearch.availabilityLabel',
  contactButton = 'candidateSearch.contactButton',
  showMoreButton = 'candidateSearch.showMoreButton',
  showLessButton = 'candidateSearch.showLessButton',
  occupationFacet = 'candidateSearch.occupationFacet',
  languagesFacet = 'candidateSearch.languagesFacet',
  languageLevelFacets = 'candidateSearch.languageLevelFacets',
  skillsFacet = 'candidateSearch.skillsFacet',
  skillLevelsFacet = 'candidateSearch.skillLevelFacet',
  workExperienceFacet = 'candidateSearch.workExperienceFacet',
  drivingLicenseFacet = 'candidateSearch.drivingLicenseFacet',
  homeLocation = 'candidateSearch.homeLocation',
  topSkills = 'candidateSearch.topSkills',
  relevantYears = 'candidateSearch.relevantYears',
  searchButton = 'candidateSearch.searchButton',
  addNoteButton = 'candidateSearch.addNoteButton',
  pendingRequest = 'candidateSearch.pendingRequest',
  contactRequestSentButton = 'candidateSearch.contactRequestSentButton',
  radiusButton = 'candidateSearch.radiusButton',
  removeTitleFirstPart = 'candidateSearch.removeTitleFirstPart',
  removeTitleSecondPart = 'candidateSearch.removeTitleSecondPart',
  saveTitleFirstPart = 'candidateSearch.saveTitleFirstPart',
  saveTitleSecondPart = 'candidateSearch.saveTitleSecondPart',
  typeLabel = 'candidateSearch.typeLabel',
  commentsTitle = 'candidateSearch.commentsTitle',
  messagesTitle = 'candidateSearch.messagesTite',
  submitButton = 'candidateSearch.submitButton',
  commLabel = 'candidateSearch.commLabel',
  messageLabel = 'candidateSearch.messageLabel',
  careerStatusFacets = 'candidateSearch.careerStatusFacets',
  availabilityFacets = 'candidateSearch.availabilityFacets',
  currentJobCategoryFacets = 'candidateSearch.currentJobCategoryFacets',
  educationLevelFacets = 'candidateSearch.educationLevelFacets',
  enterFirstName = 'candidateSearch.enterFirstName',
  enterLastName = 'candidateSearch.enterLastName',
  match = 'candidateSearch.match',
  markAsReviewedButton = 'candidateSearch.markAsReviewedButton',
  markAsUnreviewedButton = 'candidateSearch.markAsUnreviewedButton',
  loadingCandidatesText = 'candidateSearch.loadingCandidatesText',
  years = 'candidateSearch.years',
  year = 'candidateSearch.year',
  myProjects = 'candidateSearch.myProjects',
  findYourProject = 'candidateSearch.findYourProject',
  viewMoreProjects = 'candidateSearch.viewMoreProjects',
  editCVSsubscriptions = 'candidateSearch.editCVsubscriptions',
  candidateSearchLink = 'candidateSearch.candidateSearchLink',
  dashboardLink = 'candidateSearch.dashboardLink',
  sendContactRequest = 'candidateSearch.sendContactRequest',
  newCandidateBadgeFirstPart = 'candidateSearch.newCandidateBadgeFirstPart',
  newCandidateBadgeSecondPart = 'candidateSearch.newCandidateBadgeSecondPart',
  availableNowBadgeFirstPart = 'candidateSearch.availableNowBadgeFirstPart',
  availableNowBadgeSecondPart = 'candidateSearch.availableNowBadgeSecondPart',
  findSimilarCandidate = 'candidateSearch.findSimilarCandidate',
}
