import {ChangeEvent, FC, useEffect, useMemo, useRef, useState} from 'react'
import styles from './TypeAhead.module.scss'
import classnames from 'classnames'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Chip from 'Components/Chip'
import {CheckBoxIcon} from 'Components/Icons/CheckBoxIcon'
import {BaseTaxonomyType} from 'Interfaces'
import TextError from 'Components/Error/TextError'
import {useTranslation} from 'Hooks'
import {OnboardingTranslations as onb} from 'Services/I18n/Constants'

interface ItemType extends BaseTaxonomyType {
  checked?: boolean
}

interface TypeAheadProps {
  name: string
  defaultValue?: ItemType[]
  items: ItemType[]
  setValue: (name: string, value: ItemType[]) => void
  selectNonExisting?: boolean
  maxNumSelections?: number
  label?: string
  error?: string
  placeholder?: string
  className?: string
  theme?: 'grey' | 'white' | 'modal'
  onBlur?: ({}) => void
  clearAfterSetValue?: boolean
  setTouched?: (v: boolean) => void
  withoutArrow?: boolean
  forceClear?: boolean
}

export const TypeAhead: FC<TypeAheadProps> = ({
  name,
  defaultValue,
  setValue,
  items,
  selectNonExisting = false,
  maxNumSelections = 0,
  label,
  error,
  placeholder,
  className,
  theme = 'grey',
  setTouched = () => {},
  clearAfterSetValue = false,
  withoutArrow = false,
  forceClear = false,
}) => {
  const [textInput, setTextInput] = useState('')
  const [open, setOpen] = useState(false)
  const [localItems, setLocalItems] = useState<ItemType[]>(items)
  const listRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const newItems = items.slice()
    defaultValue?.forEach((v) => {
      const newItem = newItems.find((i) => i.id === v.id)
      if (newItem) newItem.checked = true
    })
    setLocalItems(newItems)
  }, [items, forceClear])

  useEffect(() => {
    setTextInput('')
  }, [forceClear])

  useEffect(() => {
    if (!localItems.length) return
    const checkedItems = localItems.filter((i) => i.checked)

    setValue(name, checkedItems)
    if (
      checkedItems.length &&
      maxNumSelections === 1 &&
      textInput !== checkedItems[0]?.translation
    ) {
      setTextInput(checkedItems[0].translation)
    }
  }, [localItems])

  const translation = useTranslation()

  const filteredItems = useMemo(() => {
    return localItems.filter(
      (o) =>
        textInput === '' ||
        o.translation.toLowerCase().includes(textInput.toLowerCase())
    )
  }, [localItems, textInput])

  const checkedItems = useMemo(() => {
    return localItems.filter((c) => c.checked)
  }, [localItems])

  const onTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    setTextInput(value)
  }

  const isMaxNumSelected = (): boolean => {
    return !!(maxNumSelections && checkedItems.length >= maxNumSelections)
  }

  const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget?.value
    handleValue(value)
  }

  const handleValue = (value: string = textInput) => {
    let newLocalItems = localItems.slice()
    if (maxNumSelections === 1) {
      newLocalItems = newLocalItems.map((n) => ({
        id: n.id,
        translation: n.translation,
        checked: false,
      }))
    }
    const optionIndex = localItems.findIndex((i) => {
      return i.id === value || i.id === `newValue~${value}`
    })
    if (optionIndex > -1) {
      if (
        isMaxNumSelected() &&
        !localItems[optionIndex].checked &&
        maxNumSelections !== 1
      ) {
        return
      }

      newLocalItems[optionIndex] = {
        ...localItems[optionIndex],
        checked: !localItems[optionIndex].checked,
      }
      if (!clearAfterSetValue) {
        setLocalItems([...newLocalItems])
      } else {
        setValue(
          name,
          newLocalItems.filter((i) => i.checked)
        )
        setOpen(false)
      }
      if (maxNumSelections === 1) {
        setOpen(false)
        if (!localItems[optionIndex].checked && !clearAfterSetValue)
          setTextInput(localItems[optionIndex].translation)
        else setTextInput('')
      }
    } else {
      if (isMaxNumSelected() && maxNumSelections !== 1) return
      // if it doesn't exist create new option and add it to the proper place
      const newItem = {
        id: `newValue~${textInput}`,
        translation: textInput,
        checked: true,
      }
      const newLocalItems = [...localItems]
      newLocalItems.push(newItem)

      if (maxNumSelections === 1) {
        setLocalItems(
          newLocalItems.map((item) => ({
            ...item,
            checked: item.id === newItem.id,
          }))
        )
        setTextInput(textInput)
        setValue(name, [newItem])
        setOpen(false)
      } else if (!clearAfterSetValue) {
        setLocalItems(newLocalItems)
      } else {
        setValue(
          name,
          newLocalItems.filter((i) => i.checked)
        )
        setOpen(false)
      }
    }
    setTouched(true)
  }

  const handleChipRemove = (translation: string) => {
    const newLocalItems = localItems.slice()
    const localIndex = localItems.findIndex(
      (i) => i.translation === translation
    )
    handleValue(newLocalItems[localIndex].id)
  }

  const handleKeyup = (event: any) => {
    if (textInput === '') return
    if (selectNonExisting && event.code === 'Enter') {
      handleValue()
      setTextInput('')
      event.stopPropagation()
    } else if (event.code === 'ArrowDown') {
      const first = listRef.current?.querySelector(
        `.${styles.checkBoxWrap}`
      ) as HTMLLabelElement
      first.focus()
    }
  }

  const handleArrows = (event: any, value: string) => {
    if (event.code === 'ArrowDown') {
      event.currentTarget.nextSibling?.focus()
    } else if (event.code === 'ArrowUp') {
      event.currentTarget.previousSibling?.focus()
    } else if (event.code === 'Enter' || event.code == 'Space') {
      handleValue(value)
    }
  }

  const topLabelText = useMemo(() => {
    if (label) return translation[label] || label
    //TODO translation needed here
    else if (maxNumSelections === 1) return 'Select value'
    else if (maxNumSelections > 1)
      return `Select up to ${maxNumSelections} values`
    return translation[onb.selectMultiValue]
  }, [label, maxNumSelections])

  useEffect(() => {
    if (error) setOpen(false)
  }, [error])

  return (
    <div
      className={classnames(
        styles.typeAheadContainer,
        styles[theme],
        className
      )}
    >
      <div
        tabIndex={0}
        className={classnames(styles.inputMainContainer, {
          [styles.error]: error,
        })}
        onBlur={(e) => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            setOpen(false)
          }
        }}
        onFocus={() => {
          setOpen(true)
        }}
      >
        <div className={styles.inputContainer}>
          <div className={styles.inputWrap}>
            {(!placeholder || label) && (
              <div className={styles.inputTopPlaceholder}>{topLabelText}</div>
            )}
            <div className={styles.inputValues}>
              {maxNumSelections !== 1 &&
                checkedItems.map((chip, index: number) => (
                  <Chip
                    key={`${chip.translation}-${index}`}
                    text={chip.translation}
                    className={styles.inputChip}
                    onClose={() => {
                      handleChipRemove(chip.translation)
                    }}
                  />
                ))}
              {!maxNumSelections ||
              maxNumSelections === 1 ||
              !checkedItems.length ||
              (maxNumSelections > 1 &&
                maxNumSelections > checkedItems.length) ? (
                <input
                  type="text"
                  className={styles.input}
                  value={textInput}
                  onChange={onTextChange}
                  onKeyUp={handleKeyup}
                  placeholder={
                    translation[placeholder] ||
                    placeholder ||
                    `${translation[onb.typeAheadTypeYour]} ${
                      translation[name] || name
                    }`
                  }
                />
              ) : null}
            </div>
          </div>
          {!withoutArrow && (
            <div className={classnames(styles.arrowWrap, 'arrowWrap')}>
              <ArrowDropDownIcon
                className={classnames(styles.arrow, {
                  [styles.rotate]: localItems.length,
                })}
              />
            </div>
          )}
        </div>
        <div
          className={classnames(styles.dropdownContainer, {
            [styles.hidden]:
              !localItems.length || !open || !filteredItems.length,
          })}
          role="group"
          aria-labelledby={`${name}CheckBoxGroup`}
          ref={listRef}
        >
          {filteredItems.map((item, index) => {
            return (
              <label
                tabIndex={0}
                className={styles.checkBoxWrap}
                key={`${item.translation}-${index}`}
                onKeyDown={(e) => {
                  handleArrows(e, item.translation)
                }}
              >
                <input
                  type="checkbox"
                  onChange={handleChecked}
                  checked={!!item.checked}
                  value={item.id}
                  className={styles.hidden}
                  multiple={maxNumSelections > 1}
                  name={name}
                />
                {maxNumSelections !== 1 && (
                  <CheckBoxIcon
                    checked={!!item.checked}
                    className={styles.icon}
                  />
                )}
                <div className={styles.checkBoxText}>{item.translation}</div>
              </label>
            )
          })}
        </div>
      </div>
      {error && <TextError text={error} />}
    </div>
  )
}
