import {FC, useEffect, useMemo, useState} from 'react'

import SectionItem from 'Components/Profile/SectionItem'
import SectionHeader from 'Components/Profile/SectionHeader'
import {LabelsGroup} from 'Components/LabelsGroup'
import {
  AssessmentsStateType,
  TrainingTitleFormikType,
  TrainingTitleAddType,
  TrainingTitleType,
  TrainingTitleEditType,
} from 'Interfaces/Assessments'
import {useFormik} from 'formik'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import Title from 'Components/Title'
import Validation from './Validation'
import Modal, {DeleteModal} from 'Components/Modal'
import {OnChangeProps} from 'Utils/FormikProps'
import Hr from 'Components/Hr'
import TextField from 'Components/Inputs/TextField'
import {BaseTaxonomyType, TaxonomiesStateType} from 'Interfaces'
import TypeAheadImproved from 'Components/Inputs/TypeAheadImproved'
import {DatePicker} from 'Components/Inputs/DatePicker'
import classNames from 'classnames'
import {TestName} from '../TestName'
import {
  GeneralAssessmentsTranslations as gat,
  ModalsAssessmentsTranslations as mat,
} from 'Services/I18n/Constants'
import {useApiError, useTranslation} from 'Hooks'
import {TestField} from '../TestField'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

import styles from './LanguageTests.module.scss'
import {Language} from '@mui/icons-material'
import defaultCertIcon from 'Assets/Images/default-cert-icon.png'

export interface LanguageTestsProps {
  getTaxonomies: () => void
  addLanguageTest: (value: TrainingTitleAddType) => void
  editLanguageTest: (value: TrainingTitleEditType) => void
  deleteLanguageTest: (id: string) => void
  taxonomies: TaxonomiesStateType
  languageTests: TrainingTitleType[]
  typeOfTests: string
  isRecruiter: boolean
  assessments: AssessmentsStateType
  seekerUserId: string
}

export const LanguageTests: FC<LanguageTestsProps> = ({
  languageTests,
  typeOfTests,
  taxonomies,
  getTaxonomies,
  addLanguageTest,
  isRecruiter = false,
  editLanguageTest,
  deleteLanguageTest,
  assessments,
  seekerUserId,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')

  const translation = useTranslation()

  const packAddLanguageTestValues = (
    formikValues: TrainingTitleFormikType
  ): TrainingTitleAddType => {
    const {id, translation} = formikValues.trainingTitle as BaseTaxonomyType
    return {
      provider: formikValues.provider,
      dateOfTest: formikValues.dateOfTest,
      expirationDate:
        formikValues.expirationDate === '' ? null : formikValues.expirationDate,
      trainingTitle: id.includes('newValue~') ? {name: translation} : {id},
      seekerUserId,
    }
  }

  const packEditLanguageTestValues = (
    formikValues: TrainingTitleFormikType
  ): TrainingTitleEditType => {
    const {id} = formikValues.trainingTitle as BaseTaxonomyType
    return {
      id: formikValues.id,
      provider: formikValues.provider,
      dateOfTest: formikValues.dateOfTest,
      expirationDate:
        formikValues.expirationDate === '' ? null : formikValues.expirationDate,
      trainingTitle: {id},
    }
  }

  const languageTestsLabels = [
    translation[gat.testTypeLabel],
    translation[gat.testProviderLabel],
    translation[gat.testDateLabel],
    translation[gat.testExpirationDateLabel],
  ]

  const openAdd = () => {
    getTaxonomies()
    formik.resetForm({
      values: emptyValues,
    })
    setModalOpen(true)
  }

  const onEdit = (editValues: TrainingTitleFormikType) => {
    getTaxonomies()
    formikEdit.setValues(editValues)
    setModalEditOpen(true)
  }

  const emptyValues: TrainingTitleFormikType = {
    id: '',
    provider: '',
    dateOfTest: '',
    expirationDate: '',
    trainingTitle: undefined,
  }

  const formik = useFormik({
    initialValues: emptyValues,
    validationSchema: Validation,
    onSubmit: (values) => {
      addLanguageTest(packAddLanguageTestValues(values))
    },
  })

  const formikEdit = useFormik({
    initialValues: emptyValues,
    validationSchema: Validation,
    onSubmit: (values) => {
      editLanguageTest(packEditLanguageTestValues(values))
    },
  })

  useEffect(() => {
    if (!assessments.loading && !assessments.error) {
      setModalEditOpen(false)
      setDeleteModalOpen(false)
      setModalOpen(false)
    }
  }, [assessments.loading])

  const setTypeAheadField = (name: string, values: BaseTaxonomyType[]) => {
    formik.setFieldValue(name, values[0])
  }

  const onDelete = (id: string) => {
    deleteLanguageTest(id)
  }

  useApiError(formik.setFieldError, assessments.error, 'trainingTitle')
  useApiError(formikEdit.setFieldError, assessments.error, 'provider')

  const uncheckedLanguageTests = useMemo(() => {
    const taxonomySkills = taxonomies?.trainingTitles?.data
    const map = new Map()
    languageTests.forEach((s) => {
      map.set(s.trainingTitle.id, true)
    })
    return taxonomySkills.filter((t) => !map.has(t.id))
  }, [taxonomies.trainingTitles.data, languageTests])

  const checkedLanguageTests = useMemo(() => {
    return languageTests.map((s) => s.trainingTitle)
  }, [languageTests])

  return (
    <div className={styles.container}>
      {(languageTests.length > 0 || isRecruiter) && (
        <div className={styles.headerSectionContainer}>
          <SectionHeader
            title={`${typeOfTests} ${translation[gat.tests]}`}
            onAdd={isRecruiter ? openAdd : undefined}
            className={styles.assessmentsHeader}
          />
        </div>
      )}

      {languageTests.length > 0 && (
        <SectionItem className={styles.sectionItem}>
          <div className={styles.testsContainer}>
            <div className={styles.itemsHeader}>
              <LabelsGroup
                labels={languageTestsLabels}
                className={classNames(styles.rowLabels, styles.testsLabels)}
              />
            </div>

            {languageTests.map((test) => (
              <div className={styles.testContainer} key={test.id}>
                <div className={styles.testContent}>
                  <div className={styles.dynamicTestValues}>
                    <TestName
                      icon={
                        <img
                          src={test.trainingTitle.icon?.url || defaultCertIcon}
                          alt={test.trainingTitle.translation}
                          className={styles.psychologyIcon}
                        />
                      }
                      testName={test.trainingTitle.translation}
                    />

                    <div className={styles.testWrapper}>
                      <TestField
                        label={gat.testProviderLabel}
                        text={test.provider}
                      />
                    </div>
                    <div className={styles.testWrapper}>
                      <TestField
                        label={gat.testDateLabel}
                        text={
                          test.dateOfTest &&
                          new Date(test.dateOfTest).toLocaleDateString('sl-SI')
                        }
                        className={styles.testMobileDateWrapper}
                      />
                    </div>
                    <div className={styles.testWrapper}>
                      <TestField
                        label={gat.testExpirationDateLabel}
                        text={
                          test.expirationDate
                            ? new Date(test.expirationDate).toLocaleDateString(
                                'sl-SI'
                              )
                            : translation[gat.unlimited]
                        }
                      />
                    </div>
                    {isRecruiter && test.id ? (
                      <div className={styles.iconWrapper}>
                        <div className={styles.icon}>
                          <button
                            onClick={() =>
                              onEdit({
                                id: test.id,
                                provider: test.provider,
                                dateOfTest: test.dateOfTest,
                                expirationDate: test.expirationDate,
                                trainingTitle: test.trainingTitle,
                              })
                            }
                          >
                            <EditOutlinedIcon />
                          </button>
                        </div>
                        <div className={styles.icon}>
                          <button
                            onClick={() => {
                              setDeleteModalOpen(true)
                              setDeleteId(test.id)
                            }}
                          >
                            <DeleteOutlinedIcon />
                          </button>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </SectionItem>
      )}

      <Modal
        isOpen={modalOpen}
        onAfterClose={() => {
          setModalOpen(false)
        }}
        title={mat.addLanguageTest}
        icon={Language}
        ariaHideApp={false}
        className={styles.modal}
        Footer={
          <SubmitFooter
            onSubmit={formik.handleSubmit}
            onCancel={() => {
              setModalOpen(false)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <div className={styles.titleWrapper}>
          <Title text={mat.languageTestName} className={styles.title} />
        </div>
        <Hr />
        <TypeAheadImproved
          className={styles.typeahead}
          name="trainingTitle"
          defaultValue={
            formik.values.trainingTitle ? [formik.values.trainingTitle] : []
          }
          theme="white"
          items={uncheckedLanguageTests}
          maxNumSelections={1}
          placeholder={`${translation[mat.languageTestName]} ...`}
          setValue={setTypeAheadField}
          selectNonExisting
          invalidNonExisting={checkedLanguageTests}
          setTouched={formik.getFieldHelpers('trainingTitle').setTouched}
          error={
            formik.submitCount && formik.touched['trainingTitle']
              ? formik.errors.trainingTitle
                ? formik.errors.trainingTitle['id'] ||
                  formik.errors.trainingTitle
                : undefined
              : undefined
          }
        />
        <Hr />
        <div className={styles.titleWrapper}>
          <Title text={mat.languageTestProvider} className={styles.title} />
        </div>
        <TextField
          name="provider"
          placeholder={`${translation[mat.languageTestProvider]} ...`}
          {...OnChangeProps(formik, 'provider')}
        />
        <Hr />
        <div className={styles.titleWrapper}>
          <Title text={mat.languageTestTakenDate} className={styles.title} />
        </div>
        <DatePicker
          value={formik.values.dateOfTest}
          formik={formik}
          error={
            formik.submitCount && formik.touched['dateOfTest']
              ? formik.errors['dateOfTest']
              : undefined
          }
          formikFieldName="dateOfTest"
        />
        <Hr />
        <div className={styles.titleWrapper}>
          <Title
            text={mat.languageTestExpirationDate}
            className={styles.title}
          />
        </div>
        <DatePicker
          value={formik.values.expirationDate}
          formik={formik}
          error={
            formik.submitCount && formik.touched['expirationDate']
              ? formik.errors['expirationDate']
              : undefined
          }
          formikFieldName="expirationDate"
        />
      </Modal>
      <Modal
        isOpen={modalEditOpen}
        onAfterClose={() => {
          setModalEditOpen(false)
        }}
        title={mat.editLanguageTest}
        icon={Language}
        className={styles.modal}
        Footer={
          <SubmitFooter
            onSubmit={formikEdit.handleSubmit}
            onCancel={() => {
              setModalEditOpen(false)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <div className={styles.titleWrapper}>
          <Title
            text={formikEdit.values.trainingTitle?.translation || ''}
            className={styles.title}
          />
        </div>
        <Hr />
        <div className={styles.titleWrapper}>
          <Title text={mat.languageTestProvider} className={styles.title} />
        </div>
        <TextField
          name="provider"
          placeholder={`${translation[mat.languageTestProvider]} ...`}
          {...OnChangeProps(formikEdit, 'provider')}
        />
        <Hr />
        <div className={styles.titleWrapper}>
          <Title text={mat.languageTestTakenDate} className={styles.title} />
        </div>
        <DatePicker
          value={formikEdit.values.dateOfTest}
          formik={formikEdit}
          error={
            formikEdit.submitCount && formikEdit.touched['dateOfTest']
              ? formikEdit.errors['dateOfTest']
              : undefined
          }
          formikFieldName="dateOfTest"
        />
        <Hr />
        <div className={styles.titleWrapper}>
          <Title
            text={mat.languageTestExpirationDate}
            className={styles.title}
          />
        </div>
        <DatePicker
          value={formikEdit.values.expirationDate}
          formik={formikEdit}
          error={
            formikEdit.submitCount && formikEdit.touched['expirationDate']
              ? formikEdit.errors['expirationDate']
              : undefined
          }
          formikFieldName="expirationDate"
        />
      </Modal>
      <DeleteModal
        deleteAction={onDelete}
        isOpen={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        text={translation[mat.deleteLanguageTest]}
        id={deleteId}
      />
    </div>
  )
}
