export enum CandidateDashboardTranslations {
  candidate = 'candidateDashboard.candidate',
  dashboard = 'candidateDashboard.dashboard',
  title = 'candidateDashboard.title',
  paragraphTitle = 'candidateDashboard.paragraphTitle',
  paragraphTitleTxt = 'candidateDashboard.paragraphTitleTxt',
  cvProfileLinkPart1 = 'candidateDashboard.cvProfileLinkPart1',
  cvProfileLinkPart2 = 'candidateDashboard.cvProfileLinkPart2',
  cvProfileLinkPart3 = 'candidateDashboard.cvProfileLinkPart3',
  publicProfileLinkPart1 = 'candidateDashboard.publicProfileLinkPart1',
  publicProfileLinkPart2 = 'candidateDashboard.publicProfileLinkPart2',
  publicProfileLinkPart3 = 'candidateDashboard.publicProfileLinkPart3',
  psychometricTestLinkPart1 = 'candidateDashboard.psychometricTestLinkPart1',
  psychometricTestLinkPart2 = 'candidateDashboard.psychometricTestLinkPart2',
  psychometricTestLinkPart3 = 'candidateDashboard.psychometricTestLinkPart3',
  cognitiveTestLinkPart1 = 'candidateDashboard.cognitiveTestLinkPart1',
  cognitiveTestLinkPart2 = 'candidateDashboard.cognitiveTestLinkPart2',
  paragraphLast1 = 'candidateDashboard.paragraphLast1',
  paragraphLast2 = 'candidateDashboard.paragraphLast2',
  paragraphLast3 = 'candidateDashboard.paragraphLast3',
  headerTitle = 'candidateDashboard.headerTitle',
  headerTitleTxt = 'candidateDashboard.headerTitleTxt',
  headerCv = 'candidateDashboard.headerCv',
  prequalification = 'candidateDashboard.prequalification',
  scouting = 'candidateDashboard.scouting',
  interviewing = 'candidateDashboard.interviewing',
  negotiations = 'candidateDashboard.negotiations',
  placement = 'candidateDashboard.placement',
  placementSecond = 'candidateDashboard.placementSecond',
  placementRequest = 'candidateDashboard.placementRequest',
  placementDetails = 'candidateDashboard.placementDetails',
  requestStatus = 'candidateDashboard.requestStatus',
  requestType = 'candidateDashboard.requestType',
  dateOfRequestSent = 'candidateDashboard.dateOfRequestSent',
  placementApproveAreYouSure = 'candidateDashboard.placementApproveAreYouSure',
  placementApproveConfirmingThePlacement = 'candidateDashboard.placementApproveConfirmingThePlacement',
  placementApproveConfirm = 'candidateDashboard.placementApproveConfirm',
  confirmPlacement = 'candidateDashboard.confirmPlacement',
  placementRejectAreYouSure = 'candidateDashboard.placementRejectAreYouSure',
  placementRejectYouWillMiss = 'candidateDashboard.placementRejectYouWillMiss',
  rejectPlacement = 'candidateDashboard.rejectPlacement',
  reject = 'candidateDashboard.reject',
  confirm = 'candidateDashboard.confirm',
  expirationDate = 'candidateDashboard.expirationDate',
  placementWeAreHappyPart1 = 'candidateDashboard.placementWeAreHappyPart1',
  placementWeAreHappyPart2 = 'candidateDashboard.placementWeAreHappyPart2',
  placementWeAreHappyPart3 = 'candidateDashboard.placementWeAreHappyPart3',
  myIncomingRequests = 'candidateDashboard.myIncomingRequests',
  viewAllRequests = 'candidateDashboard.viewAllRequests',
  minifeed = 'candidateDashboard.minifeed',
  thereAreStillNoItems = 'candidateDashboard.thereAreStillNoItems',
  loading = 'candidateDashboard.loading',
  action = 'candidateDashboard.action',
  company = 'candidateDashboard.company',
  viewDetails = 'candidateDashboard.viewDetails',
  status = 'candidateDashboard.status',
  expire = 'candidateDashboard.expire',
  hasRejectedYourContactR = 'candidateDashboard.hasRejectedYourContactR',
  hasAcceptedYourInterviewI = 'candidateDashboard.hasAcceptedYourInterviewI',
  hasRejectedYourInvitationToV = 'candidateDashboard.hasRejectedYourInvitationToV',
  theCompany = 'candidateDashboard.theCompany',
  hasViewedYourR = 'candidateDashboard.hasViewedYourR',
  viewCompany = 'candidateDashboard.viewCompany',
  view = 'candidateDashboard.view',
  pending = 'candidateDashboard.pending',
  accepted = 'candidateDashboard.accepted',
  rejected = 'candidateDashboard.rejected',
  approved = 'candidateDashboard.approved',
  expired = 'candidateDashboard.expired',
  newTimeProposed = 'candidateDashboard.newTimeProposed',
  newTimeProposal = 'candidateDashboard.newTimeProposal',
  placementConfirmed = 'candidateDashboard.placementConfirmed',
  placementRejected = 'candidateDashboard.placementRejected',
  contactRequest = 'candidateDashboard.contactRequest',
  offer = 'candidateDashboard.offer',
  interviewInvitation = 'candidateDashboard.interviewInvitation',
  interviewInvitationDetails = 'candidateDashboard.interviewInvitationDetails',
  placementConfirmation = 'candidateDashboard.placementConfirmation',
  approve = 'candidateDashboard.approve',
  accept = 'candidateDashboard.accept',
  proposeNewTime = 'candidateDashboard.proposeNewTime',
  day = 'candidateDashboard.day',
  days = 'candidateDashboard.days',
  successfullyPlaced = 'candidateDashboard.successfullyPlaced',
  offerDetails = 'candidateDashboard.offerDetails',
  content = 'candidateDashboard.content',
  dear = 'candidateDashboard.dear',
  modalOfferWeSendYouOurP = 'candidateDashboard.modalOfferWeSendYouOurP',
  thankYou = 'candidateDashboard.thankYou',
  attachment = 'candidateDashboard.attachment',
  modalApproveOfferAreYouSure = 'candidateDashboard.modalApproveOfferAreYouSure',
  modalApproveOfferAcceptingTheO = 'candidateDashboard.modalApproveOfferAcceptingTheO',
  acceptOfferRequest = 'candidateDashboard.acceptOfferRequest',
  rejectOfferrequest = 'candidateDashboard.rejectOfferrequest',
  modalRejectOfferAreYouSure = 'candidateDashboard.modalRejectAreYouSure',
  modalRejectOfferYouWillMiss = 'candidateDashboard.modalRejectOfferYouWillMiss',
  modalPrposeNewTimePleaseEnterMessage = 'candidateDashboard.modalPrposeNewTimePleaseEnterMessage',
  modalPrposeNewTimEnterMessage = 'candidateDashboard.modalPrposeNewTimEnterMessage',
  message = 'candidateDashboard.message',
  modalContactAprroveAreYouSure = 'candidateDashboard.modalContactAprroveAreYouSure',
  modalContactAprroveWhitThisApprove = 'candidateDashboard.modalContactAprroveWhitThisApprove',
  modalRejectContactAreYouSure = 'candidateDashboard.modalRejectContactAreYouSure',
  modalRejectContactYouWillMiss = 'candidateDashboard.modalRejectContactYouWillMiss',
  modalApproveContactAreYouSure = 'candidateDashboard.modalApproveContactAreYouSure',
  modalApproveContactWhitThis = 'candidateDashboard.modalApproveContactWhitThis',
  approveContactRequest = 'candidateDashboard.approveContactRequest',
  rejectContactRequest = 'candidateDashboard.rejectContactRequest',
  time = 'candidateDashboard.time',
  InterviewDate = 'candidateDashboard.InterviewDate',
  modalInterviewInvRejectAreYouSure = 'candidateDashboard.modalInterviewInvRejectAreYouSure',
  modalInterviewInvRejectYouWillMiss = 'candidateDashboard.modalInterviewInvRejectYouWillMiss',
  modalInterviewInvRejectEnterYourReason = 'candidateDashboard.modalInterviewInvRejectEnterYourReason',
  completenessProfilePicture = 'candidateDashboard.completenessProfilePicture',
  completenessFirstName = 'candidateDashboard.completenessFirstName',
  completenessLastName = 'candidateDashboard.completenessLastName',
  completenessDateOfBirth = 'candidateDashboard.completenessDateOfBirth',
  completenessAboutMe = 'candidateDashboard.completenessAboutMe',
  completenessStreetAddress = 'candidateDashboard.completenessStreetAddress',
  completenessPostalCode = 'candidateDashboard.completenessPostalCode',
  completenessCity = 'candidateDashboard.completenessCity',
  completenessCountry = 'candidateDashboard.completenessCountry',
  completenessWorkExperiences = 'candidateDashboard.completenessWorkExperiences',
  completenessSkills = 'candidateDashboard.completenessSkills',
  completenessEducation = 'candidateDashboard.completenessEducation',
  completenessPsychometricTest = 'candidateDashboard.completenessPsychometricTest',
  completenessCognitiveTest = 'candidateDashboard.completenessCognitiveTest',
  completenessDrivingLicence = 'candidateDashboard.completenessDrivingLicence',
  completenessJobPrefs = 'candidateDashboard.completenessJobPrefs',
  completeness = 'candidateDashboard.completeness',
  in = 'candidateDashboard.in',
  offerProposal = 'candidateDashboard.offerProposal',
  completed = 'candidateDashboard.completed',
  cancelled = 'candidateDashboard.cancelled',
  notAttended = 'candidateDashboard.notAttended',
  extended = 'candidateDashboard.extended',
  available = 'candidateDashboard.available',
  unavailable = 'candidateDashboard.unavailable',
  interviewConfirmed = 'candidateDashboard.interviewConfirmed',
  pendingExpiring = 'candidateDashboard.pendingExpiring',
  newOfferProposed = 'candidateDashboard.newOfferProposed',
  waitingForBoth = 'candidateDashboard.waitingForBoth',
  rejectedByYou = 'candidateDashboard.rejectedByYou',
  waitingForEmployer = 'candidateDashboard.waitingForEmployer',
  waitingForYou = 'candidateDashboard.waitingForYou',
  waitingForCandidate = 'candidateDashboard.waitingForCandidate',
  confirmedByBothSides = 'candidateDashboard.confirmedByBothSides',
  rejectedByEmployer = 'candidateDashboard.rejectedByEmployer',
  rejectedByBoth = 'candidateDashboard.rejectedByBoth',
  rejectedByCandidate = 'candidateDashboard.rejectedByCandidate',
  allRequests = 'candidateDashboard.allRequests',
  search = 'candidateDashboard.search',
  searchCompany = 'candidateDashboard.searchCompany',
  position = 'candidateDashboard.position',
  modalContactRequestTitle = 'candidateDashboard.modalContactRequestTitle',
  submit = 'candidateDashboard.submit',
  startVideoInterview = 'candidateDashboard.startVideoInterview',
  rejectionDate = 'candidateDashboard.rejectionDate',
  expiresToday = 'candidateDashboard.expiresToday',
  cancelConfirmation1 = 'candidateDashboard.cancelConfirmation1',
  cancelConfirmation2 = 'candidateDashboard.cancelConfirmation2',
}
