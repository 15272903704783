export const myProjectCandidates = {
  'myprojectcandidates.sortByTitle': 'Sortiraj po',
  'myprojectcandidates.sortOptionJobTitle': 'Delovno mesto',
  'myprojectcandidates.sortOptionFirstName': 'Ime',
  'myprojectcandidates.sortOptionLastName': ' Priimek',
  'myprojectcandidates.sortOptionDateAdded': 'Datum dodajanja',
  'myprojectcandidates.sortOptionReviewed': 'Pregledan',
  'myprojectcandidates.sortOptionUnreviewed': 'Nepregledan',
  'myprojectcandidates.actionSendContactRequest': 'Pošlji prošnjo za stik',
  'myprojectcandidates.actionSendMessage': 'Pošlji sporočilo',
  'myprojectcandidates.actionInviteToTesting': 'Povabi na testiranje',
  'myprojectcandidates.searchKeyWordLabel': 'Najdi kandidate v tem projektu:',
  'myprojectcandidates.searchKeyWordPlaceholder': 'Vpiši ključno besedo ...',
  'myprojectcandidates.searchSubmitButton': 'Išči',
  'myprojectcandidates.selectAllLabel': 'Izberi vse',
  'myprojectcandidates.testingTitle': 'Povabi na testiranje',
  'myprojectcandidates.testingOptionPsychometric': 'Psihometrični test',
  'myprojectcandidates.testingOptionCognitive': 'Test kognitivnih sposobnosti',
  'myprojectcandidates.actionsTitle': 'Akcije',
}
