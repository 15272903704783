export const home = {
  'home.title': 'Preferenca',
  'home.sendToFriend': 'SEND TO FRIEND',
  'home.headerLogo': 'PREFERENCA',
  'home.headerLink1': 'For candidates',
  'home.headerLink2': 'For employers',
  'home.headerLink3': 'About us',
  'home.headerLink4': 'Log in',
  'home.headerLink5': 'Sign up',
  'home.title_1': 'Change the ',
  'home.title_2': 'rules',
  'home.title_3': 'of the employment game.',
  'home.paragraph':
    'Join to those who don’t search, but choose instead. Create profile and allow companies to apply to interview with you.',
  'home.btnCreate': 'Create a profile',
  'home.btnMore': 'More info',
  'home.companiesTitle_1': '150 companies employ with',
  'home.companiesTitle_2': 'Preferenca.',
  'home.question_1': 'How does it',
  'home.question_2': ' work?',
  'home.answer1': 'Create a profile and tell us your expectations',
  'home.answer2':
    'Successfully complete the onboarding process, decide how much to disclose and to whom, and prepare yourself for the best offers.',
  'home.answer3':
    'Wait for companies to contact you to ask for an interview (they clearly communicate expectations and salary levels).',
  'home.answer4':
    'Only get to know the companies that interest you and choose your ideal job.',
  'home.testemonialsTitle_1': 'Join the top 10%',
  'home.testemonialsTitle_2': ' of candidates ',
  'home.testemonialsTitle_3': 'in Slovenia today.',
  'home.ctaTitle1_1': 'Know your',
  'home.ctaTitle1_2': ' value',
  'home.ctaParagraph': `Charm the employers and avoid unpleasant questions while interviewing.`,
  'home.ctaParagraph1': `Compare employers offers and check salary standards in comparable workplaces. Get the job without talking in circles and complicated negotiations.`,
  'home.cta1': 'Check salary',
  'home.ctaTitle2_1': `Free career`,
  'home.ctaTitle2_2': ` support`,
  'home.cta2': 'More info',
  'home.footerLink1': 'Contact',
  'home.footerLink2': 'CANDIDATES',
  'home.footerLink3': 'EMPLOYERS',
  'home.aboutUsLink': 'About us',
  'home.footerLink5': 'FAQ',
  'home.termsAndConditionsLink': 'Terms And Conditions',
  'home.generalTermsAndConditionsLink': 'General terms and conditions',
  'home.privacyPolicyLink': 'Privacy Policy',
  'home.cookiesLink': 'Cookies',
  'home.cookiesFooterLink': 'Consent choices',
  'home.footerLink9': 'PLAČA.SI',
  'home.dataProtectionAgreement': 'Data Protection Agreement',
}
