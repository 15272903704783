import {connect} from 'react-redux'

import {EmployerLogin} from './EmployerLogin'
import {LoginType} from 'Interfaces'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {getHelpfulHintStatus, loginEmployer} from 'Store/Actions'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  login: (data: LoginType) => dispatch(loginEmployer(data)),
  getHelpfulHintStatus: (name: string) => dispatch(getHelpfulHintStatus(name)),
})

export const EmployerLoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerLogin)
