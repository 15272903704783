import {connect} from 'react-redux'

import {InviteToTestingModal} from './InviteToTestingModal'
import {inviteToTestingBulk} from 'Store/Actions'
import {AppDispatch} from 'Store'

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  inviteToTestingBulk: (ids: string[], type: string) =>
    dispatch(inviteToTestingBulk(ids, type)),
})

export const InviteToTestingModalContainer = connect(
  null,
  mapDispatchToProps
)(InviteToTestingModal)
