export const sidebar = {
  'sidebar.home': 'Domov',
  'sidebar.dashboard': 'Namizje',
  'sidebar.allRequests': 'Zahtevki',
  'sidebar.myProfile': 'Moj profil',
  'sidebar.publicProfile': 'Karierni profil',
  'sidebar.myCv': 'Življenjepis',
  'sidebar.assessments': 'Testiranja',
  'sidebar.calendar': 'Koledar',
  'sidebar.messageCenter': 'Center za sporočila',
  'sidebar.careerAdvice': 'Nasveti',
  'sidebar.companies': 'Podjetja',
  'sidebar.faq': 'Pogosta vprašanja',
  'sidebar.logout': 'Odjava',
  'sidebar.contactRequests': 'Prošnje za stik',
  'sidebar.videoInterviews': 'Video intervju',
  'sidebar.offers': 'Ponudbe',
  'sidebar.placements': 'Sodelovanja',
  'sidebar.candidates': 'Kandidati',
  'sidebar.search': 'Iskalnik',
  'sidebar.candidateSearch': 'Iskanje kandidatov',
  'sidebar.projects': 'Projekti',
  'sidebar.availableImmediately': 'Na voljo takoj',
  'sidebar.cvSubscriptions': 'CV opomniki',
  'sidebar.companyProfile': 'Profil podjetja',
  'sidebar.advice': 'Nasveti',
  'sidebar.editPublicProfile': 'Uredi Karierni profil',
  'sidebar.continueToTests': 'Nadaljujte na testiranja',
  'sidebar.editCv': 'Uredi CV',
  'sidebar.profileStatus': 'Status profila',
  'sidebar.waitingForApproval': 'Čaka na odobritev',
  'sidebar.waitingForApprovalMessage':
    'Zahvaljujemo se vam za registracijo. Vaš uporabniški račun mora potrditi naš HR svetovalec. Potrditveno povezavo vam bomo poslali v enem do dveh delovnih dneh.',
  'sidebar.privacyControl': 'Nastavitve zasebnosti',
  'sidebar.privacyControlMessage':
    'Polja označena s ključavnico, so vidna le podjetjem, ki ste jim odobrili prošnjo za stik.',
  'sidebar.careerProfile': 'Moj Karierni profil',
  'sidebar.careerProfileMessage':
    'Delodajalci na Preferenci vidijo vaš (anonimni) Karierni profil, na podlagi katerega vam lahko pošljejo prošnjo za stik. Če njihovo prošnjo za stik odobrite, jim omogočite dostop do vašega življenjepisa.',
  'sidebar.candidateProfile': 'Življenjepis',
  'sidebar.privacyPolicy': 'Politika zasebnosti',
  'sidebar.contactData': 'Kontaktni podatki',
  'sidebar.editPersonalData': 'Uredi svoje osebne podatke',
  'sidebar.changeEmailAddress': 'Spremeni elektronski naslov',
  'sidebar.changePassword': 'Spremeni Geslo',
  'sidebar.confirmHiringChoice':
    'Naslednji kandidati so sprejeli vaše ponudbe. Prosimo potrdite vašo zaposlitveno izbiro.',
  'sidebar.helpfulHint': 'Koristen namig',
  'sidebar.myOffers': 'Moje ponudbe',
  'sidebar.selectCandidate':
    'Če želite videti več možnosti, označite kandidata.',
  'sidebar.rate': 'Oceni:',
  'sidebar.viewCandidateProfile': 'Poglejte kandidatov profil',
  'sidebar.viewCandidatesAssessments': 'Poglej kandidatova testiranja',
  'sidebar.sendMessage': 'Pošljite sporočilo',
  'sidebar.linkInterviewInvitation': 'Povabi',
  'sidebar.sendOffer': 'Pošlji ponudbo',
}
