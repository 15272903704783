import {FC, useState} from 'react'
import styles from './RequestActions.module.scss'
import PopoverList from 'Components/PopoverList'
import {IncomingRequestType, RjbProfileStatus} from 'Interfaces'

import classNames from 'classnames'
import {PlacementRequestStatus} from 'Components/IncomingRequests/Constants'
import {
  isPlacementPendingByEmployer,
  isPlacementPendingBySeeker,
  isPlacementRejectedByEmployer,
  isPlacementRejectedBySeeker,
} from '../helpers'
import {FormattedMessage} from 'react-intl'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'
import {formatDate} from 'Utils/Date'
import useRequestActionsArray from 'Components/IncomingRequests/hooks/useRequestActionsArray'
import ModalPlacementRequestEmp from 'Components/EmployerDashboard/PlacementsListItem/ModalPlacementRequestEmp'
import ModalPlacementRequestEmpConfirm from 'Components/EmployerDashboard/PlacementsListItem/ModalPlacementRequestEmp/ModalApprovePlacementConfirmationEmp'
import {CircularProgress} from '@mui/material'
import ModalSendMessageEmp from 'Components/EmployerDashboard/InterviewInvitationListItem/ModalSendMessageEmp'
import {
  Cancel,
  CheckCircle,
  Info,
  MoreHoriz,
  Pending,
} from '@mui/icons-material'

type RequestActionsProps = {
  request: IncomingRequestType
  placement?: IncomingRequestType[]
}

const OptionalActions: FC<RequestActionsProps> = ({request, placement}) => {
  let confirmedPlacementDateEmployer = placement
    ? placement[0]?.confirmedByCompanyUserDate
    : request?.confirmedByCompanyUserDate

  let confirmedPlacementDateSeeker = placement
    ? placement[0]?.confirmedBySeekerDate
    : request?.confirmedBySeekerDate

  const [hasAction] = useRequestActionsArray(request)
  const [isOpen, setIsOpen] = useState(false)
  const [isOpenConfirmPlacementModal, setIsOpenConfirmPlacementModal] =
    useState(false)
  const [placementDetails, setPlacementDetails] = useState(false)

  const MoreInfoPopover = () => {
    let statusTitleTranslation = {
      title: edt.placementConfirmed,
      color: styles.yellowText,
    }
    if (hasAction('PLACEMENT_REJECTED'))
      statusTitleTranslation = {
        title: edt.placementRejected,
        color: styles.yellowText,
      }
    if (hasAction('PLACEMENT_PENDING'))
      statusTitleTranslation = {
        title: edt.placementPending,
        color: styles.yellowText,
      }
    if (hasAction('PLACEMENT_EXPIRED'))
      statusTitleTranslation = {
        title: edt.placementExpired,
        color: styles.yellowText,
      }
    const hasInfoData =
      isPlacementRejectedByEmployer(request.status) ||
      isPlacementPendingByEmployer(request.status) ||
      isPlacementRejectedBySeeker(request.status) ||
      isPlacementPendingBySeeker(request.status) ||
      confirmedPlacementDateEmployer ||
      confirmedPlacementDateSeeker

    if (isOpen)
      return (
        <div className={styles.infoPopoverWrap}>
          <div
            className={classNames(
              styles.infoPopoverTitle,
              statusTitleTranslation.color
            )}
          >
            <FormattedMessage id={statusTitleTranslation.title} />
          </div>
          <div className={styles.candidateStatus}>
            {!hasInfoData && (
              <div className={styles.progressSpinner}>
                <CircularProgress />
              </div>
            )}
            {confirmedPlacementDateEmployer ? (
              <>
                <span className={styles.greenColor}>
                  <CheckCircle />
                </span>
                <span>
                  <FormattedMessage id={edt.confirmedByYou} /> -{' '}
                  {formatDate(confirmedPlacementDateEmployer)}
                </span>
              </>
            ) : isPlacementRejectedByEmployer(request.status) ? (
              <>
                <span className={styles.redColor}>
                  <Cancel />
                </span>
                <span>
                  <FormattedMessage id={edt.rejectedByYou} />
                </span>
              </>
            ) : (
              isPlacementPendingByEmployer(request.status) && (
                <>
                  <span className={styles.purpleColor}>
                    <Pending />
                  </span>
                  <span>
                    <FormattedMessage id={edt.waitingForYourAction} />
                  </span>
                </>
              )
            )}
          </div>

          <div className={styles.seekerStatus}>
            {confirmedPlacementDateSeeker ? (
              <>
                <span className={styles.greenColor}>
                  <CheckCircle />
                </span>
                <span>
                  <FormattedMessage id={edt.confirmedByCandidate} /> -{' '}
                  {formatDate(confirmedPlacementDateSeeker)}
                </span>
              </>
            ) : isPlacementRejectedBySeeker(request.status) ? (
              <>
                <span className={styles.redColor}>
                  <CheckCircle />
                </span>
                <span>
                  <FormattedMessage id={edt.rejectedByCandidate} />
                </span>
              </>
            ) : (
              isPlacementPendingBySeeker(request.status) && (
                <>
                  <span className={styles.purpleColor}>
                    <Pending />
                  </span>
                  <span>
                    <FormattedMessage id={edt.waitingOnCandidatesAction} />
                  </span>
                </>
              )
            )}
          </div>
        </div>
      )

    return null
  }
  const onConfirmPlacementModal = () => {
    setIsOpenConfirmPlacementModal(true)
  }

  const MoreInfoTrigger = () => (
    <div>
      <button
        className={styles.rightIcon}
        onMouseOver={() => setIsOpen(true)}
        onMouseLeave={() => setIsOpen(false)}
      >
        <MoreInfoPopover />
        <Info />
      </button>
    </div>
  )

  if (
    request.status ===
    PlacementRequestStatus.COMPANY_USER_CONFIRMED_SEEKER_PENDING
  ) {
    return (
      <div className={classNames(styles.mainAction, styles.mainActionDisabled)}>
        <span className={classNames(styles.leftIcon, styles.purpleColor)}>
          <Pending />
        </span>
        <FormattedMessage id={edt.placementPending} />
        <MoreInfoTrigger />
      </div>
    )
  } else if (hasAction('PLACEMENT_CONFIRMED')) {
    return (
      <div
        className={classNames(styles.mainAction, styles.mainActionDisabled, {
          [styles.transparentBackground]:
            request.status ===
            PlacementRequestStatus.COMPANY_USER_CONFIRMED_SEEKER_CONFIRMED,
        })}
      >
        <span className={classNames(styles.leftIcon, styles.yellowColor)}>
          <CheckCircle />
        </span>
        <span>
          <FormattedMessage id={edt.placementConfirmed} />
        </span>
        <MoreInfoTrigger />
      </div>
    )
  } else if (hasAction('PLACEMENT_REJECTED')) {
    return (
      <div className={classNames(styles.mainAction, styles.mainActionDisabled)}>
        <span className={classNames(styles.leftIcon, styles.redColor)}>
          <Cancel />
        </span>
        <FormattedMessage id={edt.placementRejected} />
        <MoreInfoTrigger />
      </div>
    )
  } else if (hasAction('PLACEMENT_EXPIRED')) {
    return (
      <div className={classNames(styles.mainAction, styles.mainActionDisabled)}>
        <span className={classNames(styles.leftIcon, styles.blackText)}>
          <Pending />
        </span>
        <FormattedMessage id={edt.placementExpired} />
        {(confirmedPlacementDateEmployer || confirmedPlacementDateSeeker) && (
          <MoreInfoTrigger />
        )}
      </div>
    )
  } else if (hasAction('PLACEMENT_PENDING_CONFIRMATION')) {
    return (
      <>
        <button className={styles.mainAction} onClick={onConfirmPlacementModal}>
          <span>
            <FormattedMessage id={edt.confirmPlacement} />
          </span>
        </button>
        <ModalPlacementRequestEmpConfirm
          request={request}
          setApproveModal={setIsOpenConfirmPlacementModal}
          approveModal={isOpenConfirmPlacementModal}
          setIsOpenPlacementModal={setPlacementDetails}
        />
        <ModalPlacementRequestEmp
          isOpenPlacementModal={placementDetails}
          setIsOpenPlacementModal={setPlacementDetails}
          request={request}
        />
      </>
    )
  }

  return null
}

const MoreActions: FC<RequestActionsProps> = ({request}) => {
  const [placementDetails, setPlacementDetails] = useState(false)
  const [sendMessageModal, setSendMessageModal] = useState(false)

  const onViewDetails = () => {
    setPlacementDetails(true)
  }

  const onSendMessage = () => {
    setSendMessageModal(true)
  }
  const noLongerActiveCandidate =
    !request.seeker.hasFinishedPlacementWithCurrentEmployer &&
    request.seeker.rjbProfileStatus === RjbProfileStatus.CLOSED
  const moreActions = [
    {
      text: edt.viewDetails,
      onClick: onViewDetails,
    },
  ]

  if (
    (request.status ===
      PlacementRequestStatus.COMPANY_USER_PENDING_SEEKER_PENDING ||
      request.status ===
        PlacementRequestStatus.COMPANY_USER_PENDING_SEEKER_CONFIRMED) &&
    !noLongerActiveCandidate
  ) {
    moreActions.push({
      text: edt.sendMessage,
      onClick: onSendMessage,
    })
  }

  return (
    <div className={styles.moreAction} role="button">
      <PopoverList
        triggerIcon={<MoreHoriz />}
        items={moreActions}
        threeDotsBtn
      />
      <ModalSendMessageEmp
        request={request}
        isOpen={sendMessageModal}
        setIsOpen={setSendMessageModal}
      />
      <ModalPlacementRequestEmp
        isOpenPlacementModal={placementDetails}
        setIsOpenPlacementModal={setPlacementDetails}
        request={request}
      />
    </div>
  )
}

export const RequestActions: FC<RequestActionsProps> = ({
  request,
  placement,
}) => (
  <div className={styles.actionWrap} onClick={(e) => e.stopPropagation()}>
    <OptionalActions request={request} placement={placement} />
    <MoreActions request={request} />
  </div>
)
