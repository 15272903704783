import {FC, useEffect, useState} from 'react'
import styles from './Benefits.module.scss'
import {useFormik} from 'formik'
import TextArea from 'Components/Inputs/TextArea'
import CheckboxGroup from 'Components/Inputs/CheckboxGroup'
// import RadioGroup from 'Components/Inputs/RadioGroup'
import Dropdown from 'Components/Inputs/Dropdown'
import {validateBack, validateNext} from './Validation'
import {
  OnboardingStateType,
  SavePartialStepBenefitsEducationType,
  SaveStepBenefitsEducationType,
  SeekerOnboardingStatus,
  TaxonomiesStateType,
} from 'Interfaces'

import Buttons from 'Components/Onboarding/Buttons'
import Title from 'Components/Title'
import Hr from 'Components/Hr'
import {
  CheckboxGroupProps,
  DropdownProps,
  OnChangeProps,
  SetValueProps,
} from 'Utils/FormikProps'
import {useApiError} from 'Hooks'
import AreYouSureModal from 'Components/Onboarding/AreYouSureModal'
import {OnboardingTranslations as onb} from 'Services/I18n/Constants'
import {LoadingModal} from 'Components/LoadingModal'
import TextError from 'Components/Error/TextError'

interface BenefitsProps {
  saveStepBenefitsEducation: (value: SaveStepBenefitsEducationType) => void //When adding back current salary remember to un-comment it in the SaveStepBenefitsEducationType as well
  saveStepPartialBenefitsEducation: (
    values: SavePartialStepBenefitsEducationType //here as well
  ) => void
  setOnboardingStep: (id: number) => void
  onboarding: OnboardingStateType
  taxonomies: TaxonomiesStateType
  getTaxonomies: () => void
}

interface BenefitsFormikType {
  desiredWorkEnv: string
  educationLevelId: string
  fieldOfStudyId: string
  additionalBenefitIds: string[]
  apiError?: null | string
}

export const Benefits: FC<BenefitsProps> = ({
  saveStepBenefitsEducation,
  saveStepPartialBenefitsEducation,
  taxonomies,
  getTaxonomies,
  onboarding,
  setOnboardingStep,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  useEffect(() => {
    if (!onboarding.loading) getTaxonomies()
  }, [onboarding.loading])

  const defaultValues: BenefitsFormikType = {
    desiredWorkEnv: onboarding.data?.desiredWorkEnv || '',
    // currentSalaryId: onboarding.data?.currentSalary?.id || '',
    educationLevelId: onboarding.data?.educationLevel?.id || '',
    fieldOfStudyId: onboarding.data?.fieldOfStudy?.id || '',
    additionalBenefitIds: onboarding.data?.additionalBenefits
      ? onboarding.data?.additionalBenefits.map((a) => a.id)
      : [],
  }

  const formikNext = useFormik({
    initialValues: defaultValues,
    validationSchema: validateNext,
    onSubmit: (values) => {
      saveStepBenefitsEducation({values, step: 4})
    },
  })

  const formikBack = useFormik({
    initialValues: defaultValues,
    validationSchema: validateBack,
    onSubmit: (values) => {
      saveStepPartialBenefitsEducation(values)
    },
  })

  const stepBack = () => {
    if (
      formikNext.dirty &&
      onboarding.data?.onboardingStatus ===
        SeekerOnboardingStatus.BENEFITSEDUCATIONS
    ) {
      formikBack.validateForm(formikNext.values).then((errors) => {
        if (Object.keys(errors).length) {
          formikNext.setErrors(errors)
        } else {
          const newValues = {...formikNext.values}
          Object.keys(newValues).forEach((key) => {
            if (
              newValues[key] === '' ||
              (typeof newValues[key] !== 'string' && !newValues[key].length)
            ) {
              delete newValues[key]
            }
          })
          saveStepPartialBenefitsEducation(newValues)
        }
      })
    } else {
      //check changes if there are none, just set step
      if (formikNext.dirty) {
        setModalOpen(true)
      } else {
        setOnboardingStep(3)
      }
    }
  }

  const handleModalSubmit = () => {
    setOnboardingStep(3)
  }

  const handleModalCancel = () => {
    formikBack.validateForm(formikNext.values).then((errors) => {
      if (Object.keys(errors).length) {
        formikNext.setErrors(errors)
      } else {
        saveStepBenefitsEducation({values: formikNext.values, step: 3})
      }
    })

    setModalOpen(false)
  }

  useApiError(formikNext.setFieldError, onboarding.error, 'apiError')

  return (
    <div className={styles.container}>
      <LoadingModal isOpen={onboarding.loading} />
      <form onSubmit={formikNext.handleSubmit}>
        <div className={styles.line}>
          <Title
            text={onb.titleWorkEnvironment}
            onboarding
            className={styles.firstTitle}
          />
          <TextArea
            name="desiredWorkEnv"
            placeholderText={onb.placeholderTextWorkEnvironment}
            type="letterCalculatorDark"
            maximumCharactersNumber={1000}
            {...OnChangeProps(formikNext, 'desiredWorkEnv')}
            error={
              formikNext.touched.desiredWorkEnv ||
              formikNext.values.desiredWorkEnv !== ''
                ? formikNext.errors.desiredWorkEnv
                : undefined
            }
          />
          <Hr className={styles.hr} />
        </div>
        <div>
          <Title text={onb.titleAdditionalBenefits} onboarding />
          <CheckboxGroup
            name="additionalBenefitIds"
            items={taxonomies.additionalBenefits.data}
            multiple
            {...CheckboxGroupProps(formikNext, 'additionalBenefitIds')}
          />
          <Hr className={styles.hr} />
        </div>
        <div>
          <Title text={onb.titleEducation} onboarding />
          <Dropdown
            name="educationLevelId"
            items={taxonomies.educationLevels.data}
            withRadio
            {...SetValueProps(formikNext, 'educationLevelId')}
          />
          <Hr className={styles.hr} />
        </div>
        <div className={styles.line}>
          <Title text={onb.titleFieldOfStudy} onboarding />
          <Dropdown
            name="fieldOfStudyId"
            items={taxonomies.fieldsOfStudy.data}
            withRadio
            {...DropdownProps(formikNext, 'fieldOfStudyId')}
          />
          <Hr className={styles.hr} />
        </div>
        {/* <div>
          <Title text={onb.titleCurrentSalary} onboarding />
          <RadioGroup
            name="currentSalaryId"
            items={taxonomies.salaries.data}
            {...RadioGroupProps(formikNext, 'currentSalaryId')}
          />
          <Hr className={styles.hr} />
        </div> */}
        {formikNext.errors.apiError && (
          <TextError text={formikNext.errors.apiError} />
        )}
        <Buttons onBack={stepBack} />
      </form>
      <AreYouSureModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleModalSubmit={handleModalSubmit}
        handleModalCancel={handleModalCancel}
      />
    </div>
  )
}
