import {FC, useState} from 'react'
import {
  CompanyProfileHeaderSaveValue,
  CompanyProfileHeaderType,
  CompanyStateType,
  ImageType,
  TaxonomiesStateType,
} from 'Interfaces'
import {FieldArray, FormikProvider, useFormik} from 'formik'
import Modal from 'Components/Modal'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import styles from './ProfileHeader.module.scss'
import Validation from 'Components/CompanyProfile/ProfileHeader/Validation'
import Title from 'Components/Title'
import Hr from 'Components/Hr'
import {OnChangeProps} from 'Utils/FormikProps'
import FileUploadCombo from 'Components/Inputs/AttachmentFileUpload'
import TextField from 'Components/Inputs/TextField'
import TextArea from 'Components/Inputs/TextArea'
import Dropdown from 'Components/Inputs/Dropdown'
import {
  CompanyHeaderTranslations as cht,
  ContactDataTranslations as cd,
  OnboardingTranslations as onb,
  ProfileTranslations as pt,
} from 'Services/I18n/Constants'
import classnames from 'classnames'
import defaultCover from 'Assets/Images/company-profile/default-cover.png'
import LogotypeSl from 'Assets/Images/company-profile/Logotip-sl.png'
import LogotypeEn from 'Assets/Images/company-profile/Logotip-en.png'

import {getLang} from 'Services/I18n/Utils'
import {webSiteUrlFormatter} from 'Utils/CommonHelpers'
import {
  AddAPhotoOutlined,
  BusinessCenterOutlined,
  FmdGoodOutlined,
  InsertPhotoOutlined,
  LanguageOutlined,
  ModeEditOutlineOutlined,
} from '@mui/icons-material'
import ShowMoreContent from 'Components/ShowMoreContent'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import AddIcon from '@mui/icons-material/Add'
import {TypeAheadFetch} from '../../Inputs/TypeAheadFetch'

interface ProfileHeaderProps {
  editCompanyProfileHeader: (value: CompanyProfileHeaderSaveValue) => void
  saveLogo: (fileId: string) => void
  saveCover: (fileId: string) => void
  getTaxonomies: () => void
  taxonomies: TaxonomiesStateType
  canChange?: boolean
  company: CompanyStateType
}

export const ProfileHeader: FC<ProfileHeaderProps> = ({
  company,
  taxonomies,
  editCompanyProfileHeader,
  saveLogo,
  saveCover,
  canChange = false,
  getTaxonomies,
}: ProfileHeaderProps) => {
  const companyData = company.data

  const companyValues = {
    name: companyData.name,
    description: companyData.description,
    industry: companyData.industry,
    town: companyData.town,
    country: companyData.country,
    websites: companyData.websites?.map(
      (website: {id: string; url: string}) => website.url
    ),
  }

  const lang = getLang()
  const logotype = {en: LogotypeEn, sl: LogotypeSl}

  const [logoModalOpen, setLogoModalOpen] = useState(false)
  const [backgroundModalOpen, setBackgroundModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)

  const openEdit = () => {
    getTaxonomies()
    editFormik.resetForm({
      values: companyValues,
    })
    setEditModalOpen(true)
  }
  const packHeaderValues = (
    formikValues: CompanyProfileHeaderType
  ): CompanyProfileHeaderSaveValue => {
    const value = {
      description: formikValues.description,
      industryId: formikValues.industry.id,
      websites: formikValues.websites,
    }

    return value
  }

  const editFormik = useFormik({
    initialValues: companyValues,
    validationSchema: Validation,
    onSubmit: (values) => {
      editCompanyProfileHeader(
        packHeaderValues(values as CompanyProfileHeaderType)
      )
      setEditModalOpen(false)
    },
  })

  const logoFormik = useFormik<{file?: ImageType}>({
    initialValues: {},
    onSubmit: (values) => {
      /// TODO handle errors
      if (values.file) {
        saveLogo(values.file.id)
      }
      setLogoModalOpen(false)
    },
  })

  const addCoverImg = () => {
    coverFormik.resetForm({
      values: {},
    })
    setBackgroundModalOpen(true)
  }

  const coverFormik = useFormik<{file?: ImageType}>({
    initialValues: {},
    onSubmit: (values) => {
      if (values.file) {
        saveCover(values.file.id)
      }
      setBackgroundModalOpen(false)
    },
  })

  const setDropdownField = (name: string, value?: string | number) => {
    if (name === 'country') {
      const country = taxonomies.countries.data.find((c) => {
        return typeof value === 'string' && c.id === value
      })
      editFormik.setFieldValue(name, country)
    }
    if (name === 'industry') {
      const industry = taxonomies.industry.data.find((c) => {
        return typeof value === 'string' && c.id === value
      })
      editFormik.setFieldValue(name, industry)
    }
  }

  return (
    <>
      {(companyData.name || canChange) && (
        // CONTAINER
        <div className={styles.container}>
          {/* COVER */}
          <div className={styles.coverWrap}>
            <div
              className={styles.coverImageBackground}
              style={
                companyData.coverImage
                  ? {backgroundImage: `url(${companyData.coverImage.url})`}
                  : {backgroundImage: `url(${defaultCover})`}
              }
            >
              {canChange && (
                <button
                  onClick={canChange ? addCoverImg : undefined}
                  className={classnames(
                    styles.addPhotoBtn,
                    styles.addCoverPhotoBtn
                  )}
                >
                  <AddAPhotoOutlined color="disabled" />
                </button>
              )}
            </div>
          </div>

          <div className={styles.mainContent}>
            <div className={styles.avatarAndEditWrap}>
              {/* AVATAR */}
              {(canChange || companyData.logotype) && (
                <div className={styles.avatarWrap}>
                  {companyData.logotype ? (
                    <img
                      src={companyData.logotype.url}
                      alt={companyData.firstName}
                    />
                  ) : (
                    <img src={logotype[lang]} alt={companyData.firstName} />
                  )}
                  {canChange && (
                    <button
                      onClick={
                        canChange
                          ? () => {
                              setLogoModalOpen(true)
                            }
                          : undefined
                      }
                      className={classnames(
                        styles.addPhotoBtn,
                        styles.addLogotypePhotoBtn
                      )}
                    >
                      <AddAPhotoOutlined color="disabled" />
                    </button>
                  )}
                </div>
              )}

              {/* EDIT BTN */}
              {canChange && (
                <button
                  onClick={
                    canChange
                      ? () => {
                          openEdit()
                        }
                      : undefined
                  }
                  className={styles.editBtn}
                >
                  <ModeEditOutlineOutlined />
                </button>
              )}
            </div>

            <div className={styles.textualContentsWrap}>
              <h1 className={styles.title}>{companyData.name}</h1>
              <div className={styles.description}>
                <div className={styles.leftContent}>
                  <div className={styles.paragraph}>
                    <ShowMoreContent
                      horizontalLine={false}
                      height={70}
                      mobileHeight={70}
                      noMargin={true}
                    >
                      <p>{companyData.description}</p>
                    </ShowMoreContent>
                  </div>
                </div>
                <div className={styles.rightContent}>
                  <div className={styles.rightParagraph}>
                    {company.data.industry?.translation && (
                      <div className={styles.rightContentItemWrap}>
                        <BusinessCenterOutlined color="disabled" />
                        <p className={styles.rightContentItemText}>
                          {company.data.industry?.translation}
                        </p>
                      </div>
                    )}
                    {(company.data.country?.translation ||
                      company.data.town?.translation) && (
                      <div className={styles.rightContentItemWrap}>
                        <FmdGoodOutlined color="disabled" />
                        <p className={styles.rightContentItemText}>
                          {company.data.town?.translation}
                          {company.data.town?.translation &&
                          company.data.country?.translation
                            ? ', '
                            : ''}
                          {company.data.country?.translation}
                        </p>
                      </div>
                    )}
                    {company.data.websites?.length > 0 ? (
                      <div className={styles.rightContentItemWrap}>
                        <LanguageOutlined
                          color="disabled"
                          className={styles.languageIcon}
                        />
                        <div className={styles.websiteLinks}>
                          {company.data.websites.map(
                            (
                              website: {id: string; url: string},
                              index: number
                            ) => (
                              <p
                                className={styles.websiteParagraph}
                                key={website.id}
                              >
                                <a
                                  href={webSiteUrlFormatter(website.url)}
                                  target="_blank"
                                  className={styles.webLink}
                                >
                                  {website.url}
                                  {index !==
                                    company.data.websites.length - 1 && (
                                    <span>,&nbsp;</span>
                                  )}
                                </a>
                              </p>
                            )
                          )}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* COVER MODAL*/}
          <Modal
            isOpen={backgroundModalOpen}
            onAfterClose={() => {
              setBackgroundModalOpen(false)
            }}
            title={cht.coverImageModalTitle}
            icon={InsertPhotoOutlined}
            ariaHideApp={false}
            className={styles.modal}
            Footer={
              <SubmitFooter
                onSubmit={coverFormik.handleSubmit}
                onCancel={() => {
                  setBackgroundModalOpen(false)
                }}
                theme="modalProfileSubmit"
              />
            }
          >
            <Title text={cht.coverImageTitle} />
            <FileUploadCombo
              name="file"
              setValue={coverFormik.setFieldValue}
              endpoint="/upload-file/picture"
              accept=".jpg,.jpeg,.png,.gif"
              supportedFilesMessage={onb.uploadFileFormatsImage}
            />
          </Modal>

          {/* LOGOTYPE MODAL */}
          <Modal
            isOpen={logoModalOpen}
            onAfterClose={() => {
              setLogoModalOpen(false)
            }}
            title={cht.logotypeModalTitle}
            icon={InsertPhotoOutlined}
            ariaHideApp={false}
            className={styles.modal}
            Footer={
              <SubmitFooter
                onSubmit={logoFormik.handleSubmit}
                onCancel={() => {
                  setLogoModalOpen(false)
                }}
                theme="modalProfileSubmit"
              />
            }
          >
            <Title text={cht.logotypeTitle} className={styles.abc} />
            <FileUploadCombo
              name="file"
              setValue={logoFormik.setFieldValue}
              endpoint="/upload-file/picture"
              accept=".jpg,.jpeg,.png,.gif"
              supportedFilesMessage={onb.uploadFileFormatsImage}
            />
          </Modal>

          {/* EDIT MODAL*/}
          <Modal
            isOpen={editModalOpen}
            onAfterClose={() => {
              setEditModalOpen(false)
            }}
            title={cht.headerModalTitle}
            icon={ModeEditOutlineOutlined}
            ariaHideApp={false}
            className={styles.modal}
            theme="desktopFlex"
            Footer={
              <SubmitFooter
                onSubmit={editFormik.handleSubmit}
                onCancel={() => {
                  setEditModalOpen(false)
                }}
                theme="modalProfileSubmit"
              />
            }
          >
            <FormikProvider value={editFormik}>
              <div className={styles.editModal}>
                <Title
                  text={cht.headerCompanyNameTitle}
                  className={classnames(
                    styles.mainTitle,
                    styles.mainTitleStronger
                  )}
                  theme="modal"
                />
                <TextField
                  name="name"
                  placeholder={cht.headerCompanyNamePlaceholderText}
                  {...OnChangeProps(editFormik, 'name')}
                  className={classnames(
                    styles.textField,
                    styles.companyNameField
                  )}
                  theme="modal"
                  disabled
                />
                <Hr
                  className={classnames(styles.hr, styles.hrStronger)}
                  theme="modalSmall"
                />
                <Title
                  text={cht.headerDescriptionTitle}
                  className={classnames(
                    styles.mainTitle,
                    styles.mainTitleStronger
                  )}
                  theme="modal"
                />
                <TextArea
                  name="description"
                  value={editFormik.values.description || ''}
                  onChange={editFormik.handleChange}
                  placeholderText={cht.headerDescriptionPlaceholderText}
                  theme="modal"
                  className={styles.textField}
                  error={
                    editFormik.touched.description ||
                    editFormik.values.description !== null
                      ? (editFormik.errors.description as string)
                      : undefined
                  }
                  maximumCharactersNumber={1000}
                  type="letterCalculator"
                />
                <Hr
                  className={classnames(styles.hr, styles.hrStronger)}
                  theme="modalSmall"
                />
                <Title
                  text={cht.headerJobCategoryTitle}
                  className={classnames(
                    styles.mainTitle,
                    styles.mainTitleStronger
                  )}
                  theme="modal"
                />
                <Dropdown
                  name="industry"
                  items={taxonomies.industry.data}
                  theme="white"
                  className={styles.dropdown}
                  value={editFormik.values.industry?.id}
                  setTouched={editFormik.getFieldHelpers('industry').setTouched}
                  setValue={setDropdownField}
                  error={
                    editFormik.submitCount && editFormik.touched.industry
                      ? (editFormik.errors.industry as string)
                      : undefined
                  }
                />
                <Hr
                  className={classnames(styles.hr, styles.hrStronger)}
                  theme="modalSmall"
                />

                <Title
                  text={cht.headerCountryTitle}
                  className={classnames(
                    styles.mainTitle,
                    styles.mainTitleStronger
                  )}
                  theme="modal"
                />
                <div className={styles.locationContainer}>
                  <div className={styles.typeaheadOne}>
                    <TypeAheadFetch
                      name="town"
                      value={editFormik.values.town || null}
                      options={[]}
                      setValue={editFormik.setFieldValue}
                      label={cd.city}
                      placeholder={pt.placeholderTown}
                      error={
                        editFormik.submitCount && editFormik.errors?.town
                          ? editFormik.errors.town
                          : undefined
                      }
                      autoComplete
                      themes={['white', 'withMargin']}
                      disabled
                    />
                  </div>
                  <div className={styles.typeaheadTwo}>
                    <TypeAheadFetch
                      name="country"
                      value={editFormik.values.country || null}
                      options={[]}
                      setValue={editFormik.setFieldValue}
                      label={cht.countryLabel}
                      error={
                        editFormik.submitCount && editFormik.errors?.country
                          ? editFormik.errors.country
                          : undefined
                      }
                      autoComplete
                      themes={['white', 'withMargin']}
                      disabled
                    />
                  </div>
                </div>
                <Hr
                  className={classnames(styles.hr, styles.hrStronger)}
                  theme="modalSmall"
                />
                <FieldArray name="websites">
                  {({push, remove}) => (
                    <>
                      <div className={styles.titleWrapper}>
                        <Title
                          text={cht.headerWebsiteTitle}
                          className={classnames(
                            styles.mainTitle,
                            styles.mainTitleStronger
                          )}
                          theme="modal"
                        />
                        <button
                          onClick={() => {
                            editFormik.values.websites.length < 5 && push('')
                          }}
                        >
                          <AddIcon />
                        </button>
                      </div>
                      {editFormik.values.websites.map(
                        (_: string, index: number) => (
                          <div className={styles.textFieldWrapper} key={index}>
                            <TextField
                              name={`websites[${index}]`}
                              placeholder={cht.headerWebsitePlaceholderText}
                              onChange={editFormik.handleChange}
                              onBlur={editFormik.handleBlur}
                              value={editFormik.values.websites[index]}
                              error={
                                editFormik.submitCount &&
                                editFormik.touched &&
                                editFormik.touched.websites &&
                                editFormik.errors.websites
                                  ? editFormik.errors.websites[index]
                                  : undefined
                              }
                              className={styles.textFieldWebsite}
                              theme="modal"
                            />
                            <button onClick={() => remove(index)}>
                              <DeleteOutlineIcon />
                            </button>
                          </div>
                        )
                      )}
                    </>
                  )}
                </FieldArray>
              </div>
            </FormikProvider>
          </Modal>
        </div>
      )}
    </>
  )
}
