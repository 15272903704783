export const companyDescription = {
  'companyDescription.descriptionTitle': 'COMPANY DESCRIPTION',

  'companyDescription.basicDescriptionTitle': 'Basic description',
  'companyDescription.mainActivitiesTitle': 'Main activities',
  'companyDescription.visionAndGoalsTitle': 'Vision and goals',
  'companyDescription.structureAndCultureTitle':
    'Organizational structure and culture',
  'companyDescription.customCompanyDescriptionTitle':
    'Custom company description',

  'companyDescription.companyLogoAlt': 'company logo',

  'companyDescription.biziSiIndustryTitle': 'INDUSTRY:',
  'companyDescription.biziSiNumberOfEmployersTitle': ' NUMBER OF EMPLOYEES:',
  'companyDescription.biziSiRevenues': 'REVENUE (LAST AVAILABLE DATA):',

  'companyDescription.biziSiLogoAlt': 'bizi.si logotype',

  'companyDescription.modalTitle': 'Company Description',
  'companyDescription.modalAboutTitle': 'About company',
  'companyDescription.modalAboutPlaceholderText': 'Enter about company...',
  'companyDescription.modalMainActivitiesTitle': 'Main activities',
  'companyDescription.modalMainActivitiesPlaceholderText':
    'Enter main activities...',
  'companyDescription.modalVisionAndGoalsTitle': 'Vision and goals',
  'companyDescription.modalVisionAndGoalsPlaceholderText':
    'Enter vision and goals...',
  'companyDescription.modalStructureAndCultureTitle':
    'Organizational structure and culture',
  'companyDescription.modalStructureAndCulturePlaceholderText':
    'Enter organizational structure and culture...',
  'companyDescription.modalCustomCompanyDescriptionTitle':
    'Custom company description',
  'companyDescription.modalCustomCompanyDescriptionPlaceholderText':
    'Enter custom company description...',

  'companyDescription.modalAddText': 'Add',
  'companyDescription.modalEditText': 'Edit',
}
