const company = {
  'Company name is required': 'Company name is required',
  'Company description is required': 'Company description is required',
  'Industry is required': 'Industry is required',
  'Portfolio is required.': 'Portfolio is required.',
  'Persona is required.': 'Persona is required.',
  'Welcome Message title is required.': 'Welcome Message title is required.',
  'Welcome Message description is required.':
    'Welcome Message description is required.',
  'Project name is too long': 'Project name is too long',
  'Project name is required': 'Project name is required',
}

export default company
