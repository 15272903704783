import {useState, FC, useCallback} from 'react'
import {Editor} from 'react-draft-wysiwyg'
import {EditorState, ContentState, convertToRaw} from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import classnames from 'classnames'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

import styles from './HTMLInput.module.scss'
import TextError from 'Components/Error/TextError'
import {countHtmlChars} from 'Utils/String'

interface HTMLInputProps {
  name: string
  value: string
  onChange: (name: string, e: string | null) => void
  className?: string
  error?: string
  setError?: (name: string, value: string | undefined) => void
  charLimit?: number
}

export const HTMLInput: FC<HTMLInputProps> = ({
  name,
  value,
  onChange,
  className,
  error,
  setError,
  charLimit = 1000,
}) => {
  const contentBlock = htmlToDraft(value || '')
  let localValue = EditorState.createEmpty()
  if (contentBlock) {
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks
    )
    localValue = EditorState.createWithContent(contentState)
  }
  const [editorState, setEditorState] = useState(localValue)
  const [charactersNumber, setCharactersNumber] = useState(
    countHtmlChars(value)
  )

  const handleChange = useCallback(
    (newState: EditorState) => {
      const html = draftToHtml(convertToRaw(newState.getCurrentContent()))
      const numChar = countHtmlChars(html)
      setCharactersNumber(numChar)
      setEditorState(newState)
      onChange(name, numChar ? html : null)
      if (charLimit > numChar && setError) {
        setError(name, 'Description is too long.')
      }
    },
    [name, onChange, setError]
  )

  return (
    <div>
      <div
        className={classnames(styles.container, {
          [styles.inputError]: error,
        })}
      >
        <Editor
          editorState={editorState}
          toolbarClassName={styles.toolbar}
          wrapperClassName={styles.wrapper}
          editorClassName={classnames(styles.editor, className)}
          onEditorStateChange={handleChange}
          toolbar={{
            options: ['inline', 'blockType', 'textAlign', 'list', 'remove'],
            blockType: {
              options: ['H1', 'Normal'],
            },
          }}
        />
        {setError && charLimit && (
          <div className={styles.charactersCalculator}>
            {charactersNumber} / {charLimit}
          </div>
        )}
      </div>
      <div>{error && <TextError text={error} />}</div>
    </div>
  )
}
