import {ConsentStatus, UpdateNewsletterConsentType} from 'Interfaces'
import {FC, useEffect} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {getQuerySearchParam} from 'Utils/Routes'

type NewsletterUnsubscribeSingleProps = {
  updateGuestConsents: (data: UpdateNewsletterConsentType) => void
}

export const NewsletterUnsubscribeSingle: FC<
  NewsletterUnsubscribeSingleProps
> = ({updateGuestConsents}) => {
  let location = useLocation()
  let history = useHistory()
  const email = getQuerySearchParam(location.search, 'email')
  const hash = getQuerySearchParam(location.search, 'hash')
  const consentId = getQuerySearchParam(location.search, 'consentId')

  useEffect(() => {
    if (!consentId || !email || !hash) {
      history.push(getRoute(AppRoute.NewsletterUnsubscribe))
    } else {
      updateGuestConsents({
        email,
        hash,
        consents: [
          {
            id: consentId,
            status: ConsentStatus.REVOKED,
          },
        ],
      })
    }
  }, [])

  return null
}
