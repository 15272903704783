import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import * as type from '../types'
import API from 'Services/API'
import {RootState} from 'Store/Reducers'

function* getSpectoUserToken() {
  const {response, error} = yield call(
    API.get,
    '/specto-web-api-integration/user-token'
  )

  if (response) {
    yield put({
      type: type.specto.token.succeeded,
      payload: response.data.token,
    })
  } else {
    yield put({type: type.specto.token.failed, payload: error})
  }
}

function* getSpectoCandidateId() {
  const candidateId: string = yield select(
    (state: RootState) => state.specto.data.candidateId
  )
  if (candidateId) {
    const {response, error} = yield call(
      API.get,
      `/specto-web-api-integration/user-data/${candidateId}`
    )
    if (response) {
      yield put({
        type: type.specto.candidateSpectoId.succeeded,
        payload: response.data.id,
      })
    } else {
      yield put({
        type: type.specto.candidateSpectoId.failed,
        payload: error,
      })
    }
  }
}

export default function* Specto(): Generator {
  yield all([
    takeLatest(type.specto.token.requested, getSpectoUserToken),
    takeLatest(type.specto.candidateSpectoId.requested, getSpectoCandidateId),
    takeLatest(type.specto.candidateId.set, getSpectoCandidateId),
  ])
}
