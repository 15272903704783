import {connect} from 'react-redux'

import {EmployerPlacements} from './EmployerPlacements'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {
  getEmployerPlacements,
  getSeekersFromPlacements,
  loadMoreEmployerPlacements,
} from 'Store/Actions'
import {EmployerRequestsSearchParamsType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  candidateBasicData: state.widgetBar.data.candidateBasicData,
  requests: state.employer.data.allRequests.placements,
  totalPlacementRequests: state.employer.data.allRequests.placements.total,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getEmployerPlacements: (params?: EmployerRequestsSearchParamsType) =>
    dispatch(getEmployerPlacements(params)),

  loadMore: (url: string) => dispatch(loadMoreEmployerPlacements(url)),
  getSeekersFromPlacements: (activeTab?: string) =>
    dispatch(getSeekersFromPlacements(activeTab)),
})

export const EmployerPlacementsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerPlacements)
