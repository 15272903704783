import {FC, useEffect} from 'react'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {TextProps} from 'Utils/FormikProps'
import classnames from 'classnames'
import {LoginType, AuthStateType} from 'Interfaces'
import TextField from 'Components/Inputs/TextField'
import styles from './EmployerLogin.module.scss'
import {Validation} from './Validation'
import {Title} from 'Components/Login'
import {getRoute} from 'Services/I18n/Utils'
import YellowButton from 'Components/YellowButton'
import {useApiError, useTranslation} from 'Hooks'
import {EmployerLoginLayout} from 'Layouts/EmployerLoginLayout/EmployerLoginLayout'
import {FormattedMessage} from 'react-intl'
import {
  AppRoute,
  LoginTranslations as lt,
  GeneralTranslations as gt,
  ForgotPasswordTranslations as ft,
} from 'Services/I18n/Constants'
import PasswordInput from 'Components/Inputs/PasswordInput'

interface EmployerLoginProps {
  auth: AuthStateType
  login: (data: LoginType) => void
  getHelpfulHintStatus: (name: string) => void
}

export const EmployerLogin: FC<EmployerLoginProps> = ({
  login,
  auth,
  getHelpfulHintStatus,
}) => {
  const formik = useFormik({
    initialValues: {
      identifier: '',
      password: '',
    },
    validationSchema: Validation,
    onSubmit: (values) => {
      login({...values})
    },
  })

  useEffect(() => {
    if (auth.data) {
      getHelpfulHintStatus('helpfulHint')
    }
  }, [auth.data])

  const translation = useTranslation()

  useApiError(formik.setFieldError, auth.error, 'password')

  const employerForgotPasswordLink = getRoute(AppRoute.EmployerForgotPassword)

  return (
    <>
      <EmployerLoginLayout>
        <div className={styles.formWrap}>
          <Title text={lt.title} className={styles.title} />

          <form className={styles.form} onSubmit={formik.handleSubmit}>
            <div className={styles.inputWrap}>
              <TextField
                className={styles.usernameField}
                name="identifier"
                type="text"
                label={gt.emailAddress}
                theme="minWidth"
                {...TextProps(formik, 'identifier')}
              />
              <PasswordInput
                name="password"
                label={translation[gt.password]}
                className={styles.passField}
                onChange={(e) =>
                  formik.setFieldValue('password', e.currentTarget.value)
                }
                value={formik.values.password}
                error={
                  formik.submitCount && formik.touched['password']
                    ? formik.errors.password
                    : undefined
                }
              />
              <div className={styles.button}>
                <YellowButton text={lt.login} />
              </div>
              <div className={styles.forgotLink}>
                <Link
                  to={employerForgotPasswordLink}
                  className={classnames(styles.link, styles.forgotPassword)}
                >
                  <div className={styles.linktxt}>
                    <FormattedMessage id={ft.title} />
                  </div>
                </Link>
              </div>
            </div>
          </form>
        </div>
      </EmployerLoginLayout>
    </>
  )
}
