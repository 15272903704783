import * as Yup from 'yup'
import {LongDescription} from 'Validations'

export const validateNext = Yup.object({
  desiredWorkEnv: LongDescription.required(
    'Desired work environment description is required'
  ),
  additionalBenefitIds: Yup.array()
    .min(1, 'Additional benefits field is required')
    .required('Additional benefits field is required'),
  educationLevelId: Yup.string().required('Education is required'),
  fieldOfStudyId: Yup.string().required('Field of study is required'),
  // currentSalaryId: Yup.string().required('Current salary is required'),
})

export const validateBack = Yup.object({
  desiredWorkEnv: LongDescription,
  additionalBenefitIds: Yup.array(),
  educationLevelId: Yup.string(),
  fieldOfStudyId: Yup.string(),
  // currentSalaryId: Yup.string(),
})
