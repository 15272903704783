import {FC, useState} from 'react'
import {FormattedMessage} from 'react-intl'

import Modal from 'Components/Modal'
import {ThumbsFooter} from 'Components/Modal/ThumbsFooter'
import styles from './ModalApproveOfferRequest.module.scss'
import ProposeNewTimeModal from 'Components/IncomingRequests/RequestActions/ProposeNewTimeModal'
import {CandidateDashboardTranslations as cdt} from 'Services/I18n/Constants'
import {IncomingRequestType, ApproveRejectModalRequestType} from 'Interfaces'
import ModalOfferRequest from 'Components/IncomingRequests/Modals/ModalOfferRequest'
import {ForumOutlined} from '@mui/icons-material'

interface ModalApproveOfferRequestProps {
  request: IncomingRequestType
  approveModal: boolean
  setApproveModal: (approveModal: boolean) => void
  showDetails?: boolean
  setIsOpenOfferRequest?: (setIsOpenOfferRequest: boolean) => void
  approveOfferRequest: (payload: ApproveRejectModalRequestType) => void
}

export const ModalApproveOfferRequest: FC<ModalApproveOfferRequestProps> = ({
  request,
  approveModal,
  setApproveModal,
  showDetails = true,
  setIsOpenOfferRequest,
  approveOfferRequest,
}) => {
  const [isOpenOfferRequestDetails, setIsOpenOfferRequestDetails] =
    useState(false)
  const [modalDetails, setModalDetails] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      {!modalDetails ? (
        <Modal
          className={styles.modal}
          icon={ForumOutlined}
          isOpen={approveModal}
          title={cdt.acceptOfferRequest}
          onAfterClose={() => {
            setApproveModal(false)
          }}
          Footer={
            request.status === 'pending' && (
              <ThumbsFooter
                approveText={cdt.accept}
                theme={'thumbUp'}
                onApprove={() => {
                  approveOfferRequest({
                    id: request.id,
                  })
                  setApproveModal(false)
                  setIsOpenOfferRequest && setIsOpenOfferRequest(false)
                }}
                onCancel={() => {
                  setApproveModal(false)
                }}
              />
            )
          }
        >
          <div className={styles.containerInterview}>
            <p className={styles.text}>
              <FormattedMessage id={cdt.modalApproveOfferAreYouSure} />{' '}
              {request.companyName}?
            </p>
            <p className={styles.paragraph}>
              <FormattedMessage id={cdt.modalApproveOfferAcceptingTheO} />{' '}
            </p>

            <div className={styles.buttons}>
              {showDetails && (
                <button
                  onClick={() => {
                    setApproveModal(false)
                    setModalDetails(true)
                    setIsOpenOfferRequestDetails(true)
                    setIsOpenOfferRequest && setIsOpenOfferRequest(false)
                  }}
                  className={styles.button}
                >
                  <FormattedMessage id={cdt.viewDetails} />
                </button>
              )}
            </div>
          </div>
        </Modal>
      ) : (
        <ModalOfferRequest
          request={request}
          isOpenOfferRequest={isOpenOfferRequestDetails}
          setIsOpenOfferRequest={setIsOpenOfferRequestDetails}
          setModalDetails={setModalDetails}
          modalDetails={modalDetails}
        />
      )}

      <ProposeNewTimeModal
        request={request}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  )
}
