import MainHeader from 'Components/MainHeader'
import styles from './HelpSupportContactUs.module.scss'
import {OnChangeProps, TextProps} from 'Utils/FormikProps'
import {useFormik} from 'formik'
import Validation from './Validation'
import TextField from 'Components/Inputs/TextField'
import PhoneNumberField from 'Components/Inputs/PhoneNumberField'
import TextArea from 'Components/Inputs/TextArea'
import YellowButton from 'Components/YellowButton'
import Dropdown from 'Components/Inputs/Dropdown'
import {Button} from 'Components/Button'
import {AuthStateType, ContactUsType} from 'Interfaces'
import {FC, useEffect} from 'react'
import {useHistory} from 'react-router-dom'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'

interface HelpSupportContactUsProps {
  auth: AuthStateType
  getSeekerProfile: () => void
  sendContactUs: (data: ContactUsType) => void
}

export const HelpSupportContactUs: FC<HelpSupportContactUsProps> = ({
  auth,
  getSeekerProfile,
  sendContactUs,
}) => {
  const history = useHistory()
  useEffect(() => {
    if (auth.data) {
      getSeekerProfile()
    }
  }, [auth.data, getSeekerProfile])

  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: getRoute(AppRoute.HomePageCandidate)},
    {name: 'Support', link: getRoute(AppRoute.HelpSupport)},
    {name: 'Home & Support', link: getRoute(AppRoute.HelpSupport)},
    {name: 'Contact Us', link: '/'},
  ]

  const subjectsArray = [
    {
      id: 'Login & Registration issues',
      translation: 'Login & Registration issues',
    },
    {id: 'Employer inquiry', translation: 'Employer inquiry'},
    {id: 'Candidate inquiry', translation: 'Candidate inquiry'},
    {id: 'Report a bug', translation: 'Report a bug'},
    {
      id: 'Custom (user can enter free text – Short title)',
      translation: 'Custom (user can enter free text – Short title)',
    },
  ]
  const defaultValues = {
    firstAndLastName: auth.data?.firstName
      ? `${auth.data?.firstName} ${auth.data?.lastName}`
      : '',
    company: '',
    email: auth.data?.email ? auth.data?.email : '',
    phone: auth.data?.phone ? auth.data?.phone : '+386',
    subject: '',
    message: '',
  }
  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: Validation,
    onSubmit: (values) => {
      sendContactUs(values)
    },
  })
  const onCancel = () => {
    formik.setValues(defaultValues)
    history.goBack()
  }

  return (
    <div className={styles.contactUsContainer}>
      <div className={styles.mainHeder}>
        <MainHeader
          breadcrumbsArray={breadcrumbsArray}
          title="Help & Support"
        />
      </div>

      <div className={styles.background}>
        <h1 className={styles.title}>CONTACT US</h1>

        <div className={styles.container}>
          <form onSubmit={formik.handleSubmit} className={styles.form}>
            <div className={styles.formContainer}>
              <div className={styles.inputs}>
                <TextField
                  label="First & Last name"
                  name="firstAndLastName"
                  type="text"
                  {...TextProps(formik, 'firstAndLastName')}
                  theme="grey"
                  className={styles.input}
                />

                <TextField
                  label="Company"
                  name="company"
                  type="text"
                  {...TextProps(formik, 'company')}
                  theme="grey"
                />
              </div>
              <div className={styles.inputs}>
                <TextField
                  label="Email address"
                  name="email"
                  type="text"
                  {...TextProps(formik, 'email')}
                  theme="grey"
                />
                <PhoneNumberField
                  label="Phone number"
                  name="phone"
                  {...TextProps(formik, 'phone')}
                  placeholder="+386"
                  setValue={formik.setFieldValue}
                />
              </div>

              <Dropdown
                name="subject"
                items={subjectsArray}
                setValue={formik.setFieldValue}
                {...TextProps(formik, 'subject')}
                emptyValueLabel={'Select subject'}
                label={'Subject'}
                className={styles.dropdownContact}
              />
              <TextArea
                name="message"
                placeholderText="Type here..."
                verticalLabel="Message"
                className={styles.textArea}
                {...OnChangeProps(formik, 'message')}
                theme="grey"
              />
            </div>
            <div className={styles.buttons}>
              <div className={styles.cancelButton}>
                <Button
                  text={'Cancel'}
                  type="button"
                  theme="back"
                  className={styles.cancelBtn}
                  onClick={onCancel}
                />
              </div>

              <YellowButton
                text="Send"
                type="submit"
                className={styles.YellowButton}
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}
