import {FC, useEffect, useMemo, useState} from 'react'
import {useFormik} from 'formik'

import styles from './JobPreferences.module.scss'
import {
  ProfileJobPreferenceFormikType,
  SeekerProfileType,
  SeekerStateType,
  TaxonomiesStateType,
} from 'Interfaces'
import SectionHeader from 'Components/Profile/SectionHeader'
import SectionItem from 'Components/Profile/SectionItem'
import ItemTextWithIcon from 'Components/Profile/ItemTextWithIcon'
import Modal, {SubmitFooter} from 'Components/Modal'
import Title from 'Components/Title'
import {Validation} from './Validation'
import Hr from 'Components/Hr'
import DropdownMultiple from 'Components/Inputs/DropdownMultiple'
import Dropdown from 'Components/Inputs/Dropdown'
import {
  BarChart,
  BuildCircle,
  EditOutlined,
  ExploreOutlined,
  SavingsOutlined,
  StarBorderOutlined,
  TimerOutlined,
  WorkOutline,
} from '@mui/icons-material'
import {DropdownProps, SetValuesProps} from 'Utils/FormikProps'
import TypeAhead from 'Components/Inputs/TypeAhead'
import {useApiError} from 'Hooks'
import {
  ProfileTranslations as pt,
  OnboardingTranslations as ot,
} from 'Services/I18n/Constants'

interface JobPreferencesProps {
  seeker: SeekerStateType
  taxonomies: TaxonomiesStateType
  getTaxonomies: () => void
  profile?: SeekerProfileType
  updateJobPreferences: (values: ProfileJobPreferenceFormikType) => void
  canChange?: boolean
}

export const JobPreferences: FC<JobPreferencesProps> = ({
  seeker,
  taxonomies,
  getTaxonomies,
  profile,
  updateJobPreferences,
  canChange = false,
}) => {
  const [modalOpen, setModalOpen] = useState(false)

  const regionsText = useMemo(() => {
    let text = ''
    profile?.desiredRegions?.forEach((d, i) => {
      text = `${text}${i > 0 ? ', ' : ''}${d.translation}`
    })
    return text
  }, [profile?.desiredRegions])

  const desiredEmploymentTypesText = useMemo(() => {
    let text = ''
    profile?.desiredEmploymentTypes?.forEach((d, i) => {
      text = `${text}${i > 0 ? ', ' : ''}${d.translation}`
    })
    return text
  }, [profile?.desiredEmploymentTypes])

  const positionsText = useMemo(() => {
    let text = ''
    profile?.desiredOccupations?.forEach((d, i) => {
      text = `${text}${i > 0 ? ', ' : ''}${d.translation}`
    })
    return text
  }, [profile?.desiredOccupations])

  const defaultValues: ProfileJobPreferenceFormikType = {
    careerLevel: profile?.careerLevel,
    desiredRegions: profile?.desiredRegions || [],
    desiredOccupations: profile?.desiredOccupations || [],
    desiredEmploymentTypes: profile?.desiredEmploymentTypes || [],
    desiredSalary: profile?.desiredSalary,
    currentSalary: profile?.currentSalary,
  }

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: Validation,
    onSubmit: (values) => {
      updateJobPreferences(values)
    },
  })

  const openEdit = () => {
    formik.resetForm({values: defaultValues})
    getTaxonomies()
    setModalOpen(true)
  }

  useEffect(() => {
    if (!seeker.loading && !seeker.error) setModalOpen(false)
  }, [seeker.loading])

  useApiError(formik.setFieldError, seeker.error)

  const setDropdownField = (name: string, value?: string | number) => {
    if (name === 'currentSalary' || name === 'desiredSalary') {
      const salary = taxonomies.salaries.data.find((c) => {
        return typeof value === 'string' && c.id === value
      })
      formik.setFieldValue(name, salary)
    } else if (name === 'careerLevel') {
      const careerLevel = taxonomies.careerLevels.data.find((c) => {
        return typeof value === 'string' && c.id === value
      })
      formik.setFieldValue(name, careerLevel)
    }
  }

  const setMultiDropdownField = (name: string, values?: string[]) => {
    if (values && values.length) {
      const newValues = values.map((v) => {
        if (name === 'desiredRegions') {
          return taxonomies.regions.data.find((r) => r.id === v)
        } else return taxonomies.employmentTypes.data.find((r) => r.id === v)
      })
      formik.setFieldValue(name, newValues)
    } else formik.setFieldValue(name, [])
  }
  const regions = useMemo(
    () => formik.values.desiredRegions?.map((d) => d.id) || [],
    [formik.values.desiredRegions]
  )

  const allOccupations = useMemo(() => {
    let newSkills = taxonomies.occupations.data.slice()
    profile?.desiredOccupations?.forEach((skill) => {
      if (newSkills.findIndex((n) => n.id === skill.id) === -1)
        newSkills = [...newSkills, skill]
    })
    return newSkills
  }, [taxonomies.occupations.data, profile?.desiredOccupations])

  const employmentTypes = useMemo(
    () => formik.values.desiredEmploymentTypes?.map((de) => de.id) || [],
    [formik.values.desiredEmploymentTypes]
  )

  return (
    <div className={styles.section}>
      <div>
        <SectionHeader title={ot.titleJobPreferences} />
      </div>
      <SectionItem className={styles.sectionItem}>
        <div className={styles.content}>
          <ItemTextWithIcon
            primaryText={pt.careerLevel}
            icon={BarChart}
            secondaryText={
              profile?.careerLevel ? profile.careerLevel.translation : ''
            }
            withColon
          />
          {profile?.jobCategory && (
            <ItemTextWithIcon
              primaryText={ot.titleOccupationalFields}
              icon={WorkOutline}
              secondaryText={profile?.jobCategory?.translation}
              withColon
            />
          )}
          <ItemTextWithIcon
            primaryText={ot.titleDesiredRegions}
            icon={ExploreOutlined}
            secondaryText={regionsText}
            withColon
          />
          <ItemTextWithIcon
            primaryText={ot.titleDesiredJobPositions}
            icon={StarBorderOutlined}
            secondaryText={positionsText}
            withColon
          />
          <ItemTextWithIcon
            primaryText={pt.desiredEmploymentType}
            icon={TimerOutlined}
            secondaryText={
              profile?.desiredEmploymentTypes?.length
                ? desiredEmploymentTypesText
                : ''
            }
            withColon
          />
          {profile?.desiredSalary && (
            <ItemTextWithIcon
              primaryText={ot.titleDesiredSalary}
              icon={SavingsOutlined}
              secondaryText={profile?.desiredSalary?.translation}
              withColon
            />
          )}
        </div>
        <div>
          {canChange && (
            <button onClick={openEdit} className={styles.editButton}>
              <EditOutlined sx={{fontSize: 25}} />
            </button>
          )}
        </div>
      </SectionItem>
      <Modal
        isOpen={modalOpen}
        onAfterClose={() => {
          setModalOpen(false)
        }}
        title={pt.editJobPreferences}
        icon={BuildCircle}
        className={styles.modal}
        theme="desktopFlex"
        Footer={
          <SubmitFooter
            onSubmit={formik.handleSubmit}
            onCancel={() => {
              setModalOpen(false)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <Title text={pt.careerLevel} className={styles.title} theme="modal" />
        <Dropdown
          name="careerLevel"
          items={taxonomies.careerLevels.data}
          theme="white"
          value={formik.values['careerLevel']?.id}
          setTouched={formik.getFieldHelpers('careerLevel').setTouched}
          error={
            formik.submitCount && formik.errors?.careerLevel
              ? formik.errors.careerLevel
              : undefined
          }
          setValue={setDropdownField}
        />
        <Hr theme="modal" />
        <Title
          text={ot.titleDesiredRegions}
          className={styles.title}
          theme="modal"
        />
        <DropdownMultiple
          items={taxonomies.regions.data}
          name="desiredRegions"
          theme="white"
          {...SetValuesProps(formik, 'desiredRegions')}
          setValues={setMultiDropdownField}
          values={regions}
        />
        <Hr theme="modal" />
        <Title
          text={ot.titleDesiredJobPositions}
          className={styles.title}
          theme="modal"
        />
        <TypeAhead
          name="desiredOccupations"
          defaultValue={formik.values.desiredOccupations}
          items={allOccupations}
          placeholder={pt.desiredOccupationsPlaceholder}
          selectNonExisting
          maxNumSelections={3}
          theme="modal"
          withoutArrow
          {...DropdownProps(formik, 'desiredOccupations')}
        />
        <Hr theme="modal" />
        <Title
          text={pt.desiredEmploymentType}
          className={styles.title}
          theme="modal"
        />
        <DropdownMultiple
          name="desiredEmploymentTypes"
          {...SetValuesProps(formik, 'desiredEmploymentTypes')}
          values={employmentTypes}
          setValues={setMultiDropdownField}
          items={taxonomies.employmentTypes.data}
          theme="white"
          error={
            formik.submitCount && formik.errors?.desiredEmploymentTypes
              ? formik.errors.desiredEmploymentTypes
              : undefined
          }
        />
        <Hr theme="modal" />
        <Title
          text={ot.titleDesiredSalary}
          className={styles.title}
          theme="modal"
        />
        <Dropdown
          name="desiredSalary"
          items={taxonomies.salaries.data}
          theme="white"
          value={formik.values['desiredSalary']?.id}
          setTouched={formik.getFieldHelpers('desiredSalary').setTouched}
          error={
            formik.submitCount && formik.errors?.desiredSalary
              ? formik.errors.desiredSalary
              : undefined
          }
          setValue={setDropdownField}
          listUp
        />
      </Modal>
    </div>
  )
}
