import {connect} from 'react-redux'
import {ProjectsEndDate} from './ProjectsEndDate'
import {AppDispatch} from 'Store'
import {addProject, editProject, deleteProject} from 'Store/Actions'
import {RootState} from 'Store/Reducers'
import {ProjectFormikValue} from 'Interfaces'
import {canChangeProfile} from 'Utils/Profile'

const mapStateToProps = (state: RootState) => ({
  projects: state.seeker.data?.full?.projects,
  profile: state.seeker,
  canChange: canChangeProfile(state.auth.data?.rjbProfileStatus),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addProject: (value: ProjectFormikValue) => dispatch(addProject(value)),
  editProject: (value: ProjectFormikValue) => dispatch(editProject(value)),
  deleteProject: (id: string) => dispatch(deleteProject(id)),
})

export const ProjectsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectsEndDate)
