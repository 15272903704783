import {FC, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {DateTime} from 'luxon'

import {CompanyUserSeekerProjectType} from 'Interfaces'
import styles from './AllProjectsInfo.module.scss'

interface AllProjectInfoProps {
  projects: CompanyUserSeekerProjectType[]
}

const MoreInfoPopover: FC<AllProjectInfoProps> = ({projects}) => {
  return (
    <div className={styles.infoPopoverWrap}>
      <div className={styles.title}>
        <FormattedMessage id="You can find this candidate in following projects:" />
      </div>
      {projects.map((p, i) => (
        <div className={styles.projectInfoWrap} key={p.id || i}>
          <div>{`- ${p.project?.title}`}</div>
          <div className={styles.dateAdded}>{`added: ${DateTime.fromJSDate(
            new Date(p.dateCreated as string)
          ).toFormat('dd. LL. yyyy')}`}</div>
        </div>
      ))}
    </div>
  )
}

export const AllProjectsInfo: FC<AllProjectInfoProps> = ({projects}) => {
  const [isMoreInfoOpen, setIsMoreInfoOpen] = useState(false)

  return (
    <div
      className={styles.container}
      onMouseEnter={() => setIsMoreInfoOpen(true)}
      onMouseLeave={() => setIsMoreInfoOpen(false)}
    >
      {`In: 
            ${projects
              .slice(0, 2)
              .map((p, i) => `${i !== 0 ? ', ' : ''}${p.project?.title}`)
              .join('')}${
        projects.length > 2 ? ` and ${projects.length - 2} more` : ''
      }`}
      {isMoreInfoOpen && <MoreInfoPopover projects={projects} />}
    </div>
  )
}
