import {ApproveRejectModalRequestType} from 'Interfaces'
import {connect} from 'react-redux'
import {AppDispatch} from 'Store'
import {approveInterviewInvitation} from 'Store/Actions'
import {RequestActions} from './RequestActions'

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  approveInterviewInvitation: (payload: ApproveRejectModalRequestType) =>
    dispatch(approveInterviewInvitation(payload)),
})

export const RequestActionsContainer = connect(
  null,
  mapDispatchToProps
)(RequestActions)
