export enum CompanyBenefitsTranslations {
  benefitsTitle = 'companyBenefits.benefitsTitle',

  friendlyWorkEnvironmentTitle = 'companyBenefits.friendlyWorkEnvironmentTitle',
  otherTitle = 'companyBenefits.otherTitle',
  
  modalTitle = 'companyBenefits.modalTitle',
  modalBenefitsTextTitle = 'companyBenefits.modalBenefitsTextTitle',
  modalBenefitsTextPlaceholderText = 'companyBenefits.modalBenefitsTextPlaceholderText',

  modalCompanyBenefitsTitle = 'companyBenefits.modalCompanyBenefitsTitle',
  modalCompanyBenefitsText = 'companyBenefits.modalCompanyBenefitsText',
  modalCustomBenefitsTitle = 'companyBenefits.modalCustomBenefitsTitle',
  modalCustomBenefitsPlaceholderText = 'companyBenefits.logotypeModalTitle',
}
