import {connect} from 'react-redux'

import LeftSidebar from './LeftSidebar'
import {AppDispatch} from 'Store'
import {getUser, getAvailabilities} from 'Store/Actions'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  unreadCount: state.messageCenter.unreadCount,
  taxonomies: state.taxonomies,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getUser: () => dispatch(getUser()),
  getTaxonomies: () => {
    dispatch(getAvailabilities())
  },
})

export const LeftSidebarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeftSidebar)
