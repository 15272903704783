import {connect} from 'react-redux'
import {AppDispatch} from 'Store'
import {UpdateNewsletterConsentType} from 'Interfaces'
import {NewsletterUnsubscribeSingle} from './NewsletterUnsubscribeSingle'
import {updateGuestConsents} from 'Store/Actions'

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateGuestConsents: (data: UpdateNewsletterConsentType) =>
    dispatch(updateGuestConsents(data)),
})

export const NewsletterUnsubscribeSingleContainer = connect(
  null,
  mapDispatchToProps
)(NewsletterUnsubscribeSingle)
