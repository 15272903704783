import Checkbox from 'Components/Inputs/Checkbox'
import PopoverList from 'Components/PopoverList'
import {FC, useState} from 'react'
import styles from './SearchHeader.module.scss'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import classNames from 'classnames'
import InviteToTestingModal from 'Components/RequestModals/InviteToTestingModal'
import {CandidateSearchTranslations as cs} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import {useTranslation} from 'Hooks'
import SaveSearchModal from 'Components/CandidateSearch/SavedSearch/SaveSearchModal'
import {
  BaseTaxonomyType,
  DataType,
  SavedSearchType,
  TaxonomiesStateType,
  SavedSearchDataType,
  CandidateSearchFiltersType,
} from 'Interfaces'

const SearchPopoverTitle = ({title}: {title: string}) => (
  <div className={styles.popoverTitleWrap}>
    <span>{title}</span>
    <ArrowDropDownIcon
      style={{
        opacity: 0.3,
      }}
    />
  </div>
)

const sortMapEmployer = {
  relevance: cs.sortFirst,
  dateModified: cs.sortFourth,
}

const sortMapAdmin = {
  firstName: cs.sortSecond,
  lastName: cs.sortThird,
}

type SearchHeaderProps = {
  resultsCount: number
  selectedResults: string[]
  toggleSelectAll: () => void
  sendContactRequestsBulk: (ids: string[]) => void
  sortCandidatesSearchResults: (sortBy: string) => void
  taxonomies: TaxonomiesStateType
  searchKeywords: DataType<BaseTaxonomyType>
  saveCandidateSearch: (sortBy: SavedSearchType) => void
  updateSavedSearch: (value: SavedSearchType) => void
  savedSearch: SavedSearchDataType
  filtersType?: CandidateSearchFiltersType
}

export const SearchHeader: FC<SearchHeaderProps> = ({
  resultsCount,
  selectedResults,
  toggleSelectAll,
  sendContactRequestsBulk,
  sortCandidatesSearchResults,
  taxonomies,
  searchKeywords,
  saveCandidateSearch,
  updateSavedSearch,
  savedSearch,
  filtersType,
}) => {
  const isAdmin = filtersType === 'admin'
  const [sortValue, setSortValue] = useState(
    isAdmin ? 'firstName' : 'relevance'
  )
  const [isTestingModalOpen, setIsTestingModalOpen] = useState(false)
  const [isSaveSearchModalOpen, setIsSaveSearchModalOpen] = useState(false)

  const translation = useTranslation()
  const onSendContactRequests = () => {
    sendContactRequestsBulk(selectedResults)
  }

  const updateSortValue = (value: string) => {
    setSortValue(value)
    sortCandidatesSearchResults(value)
  }

  const sortActionsEmployer = [
    {
      text: cs.sortFirst,
      onClick: () => updateSortValue('relevance'),
    },
    // {
    //   text: cs.sortFourth,
    //   onClick: () => updateSortValue('dateModified'),
    // },
  ]
  const sortActionsAdmin = [
    {
      text: cs.sortSecond,
      onClick: () => updateSortValue('firstName'),
    },
    {
      text: cs.sortThird,
      onClick: () => updateSortValue('lastName'),
    },
  ]

  return (
    <div className={styles.searchHeaderWrap}>
      <div className={styles.heading}>
        <span>{`${resultsCount > 100 ? '100+' : resultsCount} ${
          translation[cs.searchButton]
        }`}</span>
        <div
          role="button"
          className={classNames(styles.actionBtn, styles.subscribeBtn)}
          onClick={() => {
            setIsSaveSearchModalOpen(true)
          }}
        >
          <FormattedMessage id={cs.saveOrSubButton} />
        </div>
      </div>
      <div className={styles.actionsWrap}>
        <div className={styles.title}>
          <FormattedMessage id={cs.narrowResults} />
        </div>
        <div className={styles.actions}>
          <div className={styles.actionsBulk}>
            <div className={styles.selectAllWrap}>
              <Checkbox
                name="selectAllResults"
                onChange={toggleSelectAll}
                value={selectedResults.length > 0}
                className={styles.selectAll}
              />
              <label
                className={styles.selectAllLabel}
                htmlFor="selectAllResults"
              >
                <FormattedMessage id={cs.selectButton} />
              </label>
            </div>
            {selectedResults.length > 0 && (
              <>
                <div
                  role="button"
                  className={styles.actionBtn}
                  onClick={onSendContactRequests}
                >
                  <FormattedMessage id={cs.contactRequst} />
                </div>
                {/* <div
                  role="button"
                  className={classNames(styles.actionBtn, styles.testingBtn)}
                  onClick={() => setIsTestingModalOpen(true)}
                >
                  Invite to testing
                </div> */}
              </>
            )}
          </div>

          {isAdmin ? (
            <div className={styles.sortWrap}>
              <PopoverList
                triggerIcon={
                  <SearchPopoverTitle
                    title={
                      isAdmin
                        ? translation[sortMapAdmin[sortValue]]
                        : translation[sortMapEmployer[sortValue]]
                    }
                  />
                }
                items={isAdmin ? sortActionsAdmin : sortActionsEmployer}
                theme="fixedLeftTwo"
              />
            </div>
          ) : null}
          <InviteToTestingModal
            modalOpen={isTestingModalOpen}
            setModalOpen={setIsTestingModalOpen}
            requestsIds={selectedResults}
          />
          <SaveSearchModal
            isOpen={isSaveSearchModalOpen}
            setOpen={setIsSaveSearchModalOpen}
            taxonomies={taxonomies}
            searchKeywords={searchKeywords}
            saveCandidateSearch={saveCandidateSearch}
            savedSearch={savedSearch}
            updateSavedSearch={updateSavedSearch}
          />
        </div>
      </div>
    </div>
  )
}
