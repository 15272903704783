import * as type from 'Store/types'
import {
  ActionType,
  ErrorType,
  OnboardingType,
  OnboardingStateType,
} from 'Interfaces'

const initialState = {
  data: undefined,
  loading: false,
  error: undefined,
}

const OnboardingReducer = (
  state: OnboardingStateType = initialState,
  action: ActionType<OnboardingType | ErrorType | number>
): OnboardingStateType => {
  switch (action.type) {
    case type.onboarding.requested:
    case type.stepUploadCv.requested:
    case type.stepJobPreferences.requested:
    case type.stepPartialJobPreferences.requested:
    case type.stepWorkExperience.requested:
    case type.stepPartialWorkExperience.requested:
    case type.stepBenefitsEducation.requested:
    case type.stepPartialBenefitsEducation.requested:
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case type.onboarding.succeeded:
    case type.stepUploadCv.succeeded:
    case type.stepJobPreferences.succeeded:
    case type.stepPartialJobPreferences.succeeded:
    case type.stepWorkExperience.succeeded:
    case type.stepPartialWorkExperience.succeeded:
    case type.stepBenefitsEducation.succeeded:
    case type.stepPartialBenefitsEducation.succeeded:
      return {
        data: {...state.data, ...(action.payload as OnboardingType)},
        loading: false,
        error: undefined,
      }
    case type.onboarding.failed:
    case type.stepUploadCv.failed:
    case type.stepJobPreferences.failed:
    case type.stepPartialJobPreferences.failed:
    case type.stepWorkExperience.failed:
    case type.stepPartialWorkExperience.failed:
    case type.stepBenefitsEducation.failed:
    case type.stepPartialBenefitsEducation.failed:
      return {
        ...state,
        loading: false,
        error: action.payload as ErrorType,
      }
    case type.onboarding.clearErrors:
      return {
        ...state,
        error: undefined,
      }
    case type.setStep:
      return {
        ...state,
        // @ts-ignore
        data: {...state.data, onboardingStep: action.payload as number},
      }

    default:
      return state
  }
}

export default OnboardingReducer
