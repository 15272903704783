import {FC, useEffect, useState} from 'react'
import {useTranslation} from 'Hooks'

import PublicJobPreferencesContainer from 'Components/Profile/PublicJobPreferences'
import MainLayout from 'Layouts/MainLayout'
import {AuthStateType, SeekerStateType} from 'Interfaces'
import {PublicProfileBioSectionContainer} from 'Components/Profile/PublicProfileBioSection'
import {PublicWorkExperiencesContainer} from 'Components/Profile/PublicWorkExperiences'
import {PublicSkillsContainer} from 'Components/Profile/Skills/PublicSkillsContainer'
import {AppRoute, SidebarTranslations as st} from 'Services/I18n/Constants'
import {SidebarLink} from 'Components/SidebarLink'
import {PublicProfileSidebarText} from 'Components/Profile/PublicProfileSidebarText'
import {LoadingModal} from 'Components/LoadingModal'
import styles from './PublicProfile.module.scss'
import {AdminPanelSettingsOutlined} from '@mui/icons-material'

interface PublicProfileProps {
  auth: AuthStateType
  user: SeekerStateType
  getSeekerPublicProfile: (id: string) => void
}

export const PublicProfile: FC<PublicProfileProps> = ({
  auth,
  user,
  getSeekerPublicProfile,
}: PublicProfileProps) => {
  const translation = useTranslation()
  const [loadingCounter, setLoadingCounter] = useState(0)

  useEffect(() => {
    if (auth.data) {
      getSeekerPublicProfile(auth.data.id)
    }
  }, [auth.data, getSeekerPublicProfile])

  useEffect(() => {
    if (user.loading) {
      setLoadingCounter(loadingCounter + 1)
    }
  }, [user.loading])

  return (
    <MainLayout
      content={
        <div className={styles.linksWrap}>
          <SidebarLink text={st.editCv} routeName={AppRoute.Profile} />
          <SidebarLink
            text={st.continueToTests}
            routeName={AppRoute.Assessments}
          />
          <PublicProfileSidebarText
            title={translation[st.careerProfile]}
            text={translation[st.careerProfileMessage]}
            icon={AdminPanelSettingsOutlined}
          />
        </div>
      }
    >
      <div className={styles.profileContainer}>
        <LoadingModal
          isOpen={
            loadingCounter === 0 || (loadingCounter === 1 && user.loading)
          }
        />
        {user.data && (
          <>
            <PublicProfileBioSectionContainer />
            <PublicJobPreferencesContainer />
            <PublicWorkExperiencesContainer />
            <PublicSkillsContainer />
          </>
        )}
      </div>
    </MainLayout>
  )
}
