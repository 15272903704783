import {DateTime} from 'luxon'
import {FC} from 'react'
import {FormattedMessage} from 'react-intl'
import {useTimeUntil} from 'Utils/Date/Hooks/useTimeUntil'
import styles from './RequestValues.module.scss'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'
import {ExpirationValuesInDays} from 'Components/ExpirationValuesInDays'

export const StartsInValue: FC<{date: string | Date}> = ({date}) => {
  if (typeof date !== 'object') {
    date = new Date(date)
  }
  const today = new Date()
  const inDays = Math.floor(
    (date.getTime() - today.getTime()) / (1000 * 3600 * 24)
  )

  if (date < new Date() || inDays > 7) {
    return null
  }

  const [startsSoon, timeUntilString] = useTimeUntil(date)

  return (
    <div
      className={startsSoon ? styles.dateTextSoon : styles.dateTextIn}
    >{`In ${timeUntilString}`}</div>
  )
}

export const ExpirationValue: FC<{date: string}> = ({date}) => {
  const expiration = DateTime.fromISO(date)
  const now = DateTime.now()
  const inDays = Math.floor(expiration.diff(now, ['days']).days)

  if (inDays > 7 || expiration < now) {
    return (
      <div className={styles.dateTextIn}>{expiration.toFormat('d.L.yyyy')}</div>
    )
  }
  if (now.day === expiration.day || inDays === 0) {
    return (
      <div className={styles.dateTextSoon}>
        <FormattedMessage id={edt.expiresToday} />
      </div>
    )
  }
  return <ExpirationValuesInDays inDays={inDays} />
}
