import {home} from './Home/en'
import {login} from './Login/en'
import {registration} from './Registration/en'
import {onboarding} from './Onboarding/en'
import {forgotPassword} from './ForgotPassword/en'
import {resetPassword} from './ResetPassword/en'
import {contactData} from './ContactData/en'
import {consents} from './Consents/en'
import {profile} from './Profile/en'
import {candidateDashboard} from './Dashboard/en'
import {CandidateInfo} from './CandidateInfo/en'
import {CandidateFAQ} from './CandidateFAQ/en'

export const seeker = {
  ...home,
  ...login,
  ...registration,
  ...onboarding,
  ...forgotPassword,
  ...resetPassword,
  ...contactData,
  ...consents,
  ...profile,
  ...candidateDashboard,
  ...CandidateInfo,
  ...CandidateFAQ,
}
