import {FC} from 'react'
import styles from './Title.module.scss'
import classnames from 'classnames'
import {FormattedMessage} from 'react-intl'

interface TitleProps {
  text: string
  className?: string
  weight?: number
  onboarding?: boolean
  theme?: 'title' | 'modal' | 'modalRegular'
}

export const Title: FC<TitleProps> = ({
  text,
  className,
  weight,
  onboarding = false,
  theme = 'title',
}: TitleProps) => {
  return (
    <h2
      className={classnames(styles.title, className, {
        [styles.regular]: weight === 400,
        [styles.onboarding]: onboarding,
        [styles[theme]]: theme,
      })}
    >
      <FormattedMessage id={text} />
    </h2>
  )
}
