import * as Yup from 'yup'
import {PhoneNumber, PostalCode} from 'Validations'
const includesNumber = /^.*\d.*$/

const Validation = Yup.object().shape({
  profilePicture: Yup.object()
    .nullable()
    .required('Profile picture is required'),
  firstName: Yup.string()
    .max(50, 'First name is too long')
    .required('First name is required'),
  lastName: Yup.string()
    .max(50, 'Last name is too long')
    .required('Last name is required'),
  day: Yup.number()
    .required('Birthdate is required')
    .min(1, 'Birthdate is required'),
  month: Yup.number()
    .required('Birthdate is required')
    .min(1, 'Birthdate is required'),
  year: Yup.number()
    .required('Birthdate is required')
    .min(1, 'Birthdate is required'),
  streetAddress: Yup.string()
    .typeError('Include street number')
    .matches(includesNumber, 'Include street number')
    .max(50, 'Must be 50 characters or less')
    .required('Street and number are required'),
  postalCode: PostalCode,
  homeTown: Yup.object().nullable().required('City is required'),
  homeRegion: Yup.object().nullable().required('Region is required'),
  homeCountry: Yup.object().nullable().required('Country is required'),
  phone: PhoneNumber.required('Phone number is required'),
})

export default Validation
