import Modal from 'Components/Modal'
import {ThumbsFooter} from 'Components/Modal/ThumbsFooter'
import styles from './ModalApprovePlacementConfirmationEmp.module.scss'

import {IncomingRequestType} from 'Interfaces'
import {FC, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {CandidateDashboardTranslations as cdt} from 'Services/I18n/Constants'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'
import ModalRejectPlacementConfirmationEmp from '../ModalRejectPlacementConfirmationEmp'
import {HistoryEdu} from '@mui/icons-material'

interface ModalApprovePlacementConfirmationEmpProps {
  request: IncomingRequestType
  approveModal: boolean
  setApproveModal: (approveModal: boolean) => void
  showDetails?: boolean
  setIsOpenPlacementModal?: (value: boolean) => void
  approvePlacementConfirmation: (payload: string) => void
}

export const ModalApprovePlacementConfirmationEmp: FC<
  ModalApprovePlacementConfirmationEmpProps
> = ({
  request,
  approveModal,
  setApproveModal,
  setIsOpenPlacementModal,
  approvePlacementConfirmation,
}) => {
  const [rejectModal, setRejectModal] = useState(false)

  return (
    <>
      <Modal
        className={styles.modal}
        icon={HistoryEdu}
        isOpen={approveModal}
        title={cdt.confirmPlacement}
        onAfterClose={() => {
          setApproveModal(false)
        }}
        Footer={
          <ThumbsFooter
            approveText={cdt.confirm}
            rejectText={cdt.reject}
            theme={'thumbs'}
            onApprove={() => {
              approvePlacementConfirmation(request.id)
              setApproveModal(false)
              setIsOpenPlacementModal && setIsOpenPlacementModal(false)
            }}
            onReject={() => {
              setRejectModal(true)
              setApproveModal(false)
            }}
            onCancel={() => {
              setApproveModal(false)
            }}
          />
        }
      >
        <div className={styles.containerInterview}>
          <p className={styles.text}>
            <FormattedMessage id={edt.areYouSureApprovePlacement} />
          </p>
          <p className={styles.paragraph}>
            <FormattedMessage id={edt.whitThisActionApprovePlacement} />
          </p>
          <button
            onClick={() => {
              setIsOpenPlacementModal && setIsOpenPlacementModal(true)
            }}
            className={styles.button}
          >
            <FormattedMessage id={cdt.viewDetails} />
          </button>
        </div>
      </Modal>
      <ModalRejectPlacementConfirmationEmp
        request={request}
        rejectModal={rejectModal}
        setRejectModal={setRejectModal}
      />
    </>
  )
}
