import {all, call, put, takeLatest} from 'redux-saga/effects'
import {push} from 'connected-react-router'
import {getRoute, getRouteForLang, getRouteLang} from 'Services/I18n/Utils'
import API, {clearUserIds} from 'Services/API'
import * as type from 'Store/types'
import {
  ActionType,
  CbtAccountStatus,
  ConsentToSend,
  ForgotPasswordType,
  LoginType,
  MeType,
  ResetPasswordType,
  RjbProfileStatus,
  SeekerOnboardingStatus,
  UserType,
} from 'Interfaces'
import {AppRoute} from 'Services/I18n/Constants'
import {getChannel} from 'Utils/CommonHelpers'
import {initAPIHeaders} from 'Services/API/API'
import {hasGrantedMandatoryConsents} from 'Utils/Consents/Consents'

function* loginSeeker(action: ActionType<LoginType>) {
  clearUserIds()

  const {response, error} = yield call(API.post, `/auth/login`, {
    ...action.payload,
    rememberMe: true,
  })
  if (response) {
    const userData = response.data.items.find(
      (user: any) => user.type === UserType.SEEKER
    )
    if (userData) {
      localStorage.setItem('userId', userData.id)
      initAPIHeaders()
      yield put({
        type: type.USER_LOGIN_SUCCEEDED,
        payload: userData,
      })

      const grantedConsents = hasGrantedMandatoryConsents(userData)

      let NextRoute: string
      if (userData.cbtAccountStatus === CbtAccountStatus.UNVERIFIED) {
        NextRoute = getRoute(AppRoute.EmailVerification)
      } else if (!grantedConsents) {
        NextRoute = getRoute(AppRoute.SeekerLoginConsents)
      } else if (
        (userData.rjbProfileStatus === RjbProfileStatus.DRAFT &&
          userData.onboardingStatus !== SeekerOnboardingStatus.FINISHED) ||
        (!userData.rjbProfileStatus && !userData.onboardingStatus)
      ) {
        NextRoute = getRoute(AppRoute.Onboarding)
      } else if (!userData.contactDataSubmitted) {
        NextRoute = getRoute(AppRoute.ContactData)
      } else {
        NextRoute = getRoute(AppRoute.CandidateDashboard)
      }
      yield put(push(NextRoute))
    } else
      yield put({
        type: type.USER_LOGIN_FAILED,
        payload: {
          name: 'password',
          message: "Seeker doesn't exists.",
        },
      })
  } else yield put({type: type.USER_LOGIN_FAILED, payload: error})
}

function* loginEmployer(action: ActionType<LoginType>) {
  clearUserIds()
  const {response, error} = yield call(API.post, `/auth/login`, {
    identifier: action?.payload?.identifier,
    password: action?.payload?.password,
    rememberMe: true,
  })
  if (response) {
    const userData = response.data.items.find(
      (user: MeType) => user.type === UserType.EMPLOYER
    )

    if (userData) {
      localStorage.setItem('userId', userData.id)
      initAPIHeaders()
      yield put({
        type: type.USER_LOGIN_SUCCEEDED,
        payload: userData,
      })
      const grantedConsents = hasGrantedMandatoryConsents(userData)

      let NextRoute: string
      if (!grantedConsents) {
        NextRoute = getRoute(AppRoute.EmployerLoginConsents)
        /* } else if (
        // left both conditions in case we need it again
        userData.role === 'company_owner' &&
        userData.companyStructure?.company?.companyOnboardingStatus !== 'done'
      ) {
        NextRoute = getRoute(AppRoute.EmployerOnboarding) */
      } else {
        NextRoute = getRoute(AppRoute.EmployerDashboard)
      }

      yield put(push(NextRoute))
    } else
      yield put({
        type: type.USER_LOGIN_FAILED,
        payload: {
          name: 'password',
          message: "Employer doesn't exists.",
        },
      })
  } else yield put({type: type.USER_LOGIN_FAILED, payload: error})
}

function* logoutUser(action: ActionType<UserType>) {
  yield call(API.post, `/auth/logout`, {})
  clearUserIds()
  yield put({
    type: type.USER_LOGOUT_SUCCEEDED,
    payload: null,
  })
  if (action.payload === UserType.EMPLOYER)
    yield put(push(getRoute(AppRoute.EmployerLogin)))
  else yield put(push(getRoute(AppRoute.SeekerLogin)))
}
function* getLoginConsents() {
  const {response, error} = yield call(API.get, `/consents`)
  if (response) {
    yield put({
      type: type.employer.getLoginConsents.succeeded,
      payload: response.data.items,
    })
  } else
    yield put({type: type.employer.getLoginConsents.failed, payload: error})
}
function* acceptEmployerLoginConsents(action: ActionType<ConsentToSend[]>) {
  const {response, error} = yield call(API.patch, `/consent/company-consents`, {
    consents: action.payload,
  })
  if (response) {
    yield put({
      type: type.employer.acceptLoginConsents.succeeded,
      payload: response.data.items,
    })
  } else
    yield put({type: type.employer.acceptLoginConsents.failed, payload: error})
}
function* getSeekerLoginConsents() {
  const {response, error} = yield call(API.get, `/consent/user-consents`)

  if (response) {
    yield put({
      type: type.seeker.getSeekerLoginConsents.succeeded,
      payload: response.data.items,
    })
  } else
    yield put({
      type: type.seeker.getSeekerLoginConsents.failed,
      payload: error,
    })
}
function* acceptSeekerLoginConsents(action: ActionType<ConsentToSend[]>) {
  const {response, error} = yield call(API.patch, `/consent/user-consents`, {
    consents: action.payload,
  })
  if (response) {
    yield put({
      type: type.seeker.acceptSeeekerLoginConsents.succeeded,
      payload: response.data.items,
    })
  } else
    yield put({
      type: type.seeker.acceptSeeekerLoginConsents.failed,
      payload: error,
    })
}
function* forgotPassword(action: ActionType<ForgotPasswordType>) {
  const {response, error} = yield call(API.post, `/auth/forgot-password`, {
    ...action.payload,
  })
  if (response) {
    yield put({
      type: type.auth.forgotPassword.succeeded,
    })
  } else yield put({type: type.auth.forgotPassword.failed, payload: error})
}
function* resetPassword(action: ActionType<ResetPasswordType>) {
  const {response, error} = yield call(API.post, `/auth/reset-password`, {
    ...action.payload,
  })
  if (response) {
    yield put({
      type: type.auth.resetPassword.succeeded,
    })
    yield put(push(getRoute(AppRoute.SeekerLogin)))
  } else yield put({type: type.auth.resetPassword.failed, payload: error})
}

function* resetEmployerPassword(action: ActionType<ResetPasswordType>) {
  const {response, error} = yield call(API.post, `/auth/reset-password`, {
    ...action.payload,
  })
  if (response) {
    yield put({
      type: type.auth.resetPassword.succeeded,
    })
    yield put(push(getRoute(AppRoute.EmployerLogin)))
  } else yield put({type: type.auth.resetPassword.failed, payload: error})
}

function* changePassword(action: ActionType<ForgotPasswordType>) {
  const {response, error} = yield call(API.post, `/auth/change-password`, {
    ...action.payload,
  })
  if (response) {
    yield put({
      type: type.auth.changePassword.succeeded,
    })
  } else yield put({type: type.auth.changePassword.failed, payload: error})
}

function* changeEmail(action: ActionType<string>) {
  const {response, error} = yield call(API.post, `/auth/change-email`, {
    newEmail: action.payload,
  })

  if (response) {
    yield put({
      type: type.auth.changeEmail.succeeded,
    })
  } else yield put({type: type.auth.changeEmail.failed, payload: error})
}

function* updateContentLanguage(action: ActionType<string>) {
  const {response, error} = yield call(
    API.patch,
    `/user/content-language?contentLanguageId=${action.payload}`,
    null
  )

  if (response) {
    yield put({
      type: type.contentLanguageUpdate.succeeded,
      payload: response.data.id,
    })
  } else yield put({type: type.contentLanguageUpdate.failed, payload: error})
}

function* updateSystemLanguage(action: ActionType<string>) {
  const {response, error} = yield call(
    API.patch,
    `/user/system-language?systemLanguageId=${action.payload}`,
    null
  )
  const {pathname} = window.location
  const isEmployer = pathname === getRoute(AppRoute.EmployerLanguageSettings)

  if (response) {
    yield put({
      type: type.systemLanguageUpdate.succeeded,
      payload: response.data.id,
    })

    const channelLanguages = getChannel().languages
    const userPreferedLangId = action.payload
    let langShortCode = getRouteLang()
    if (channelLanguages[langShortCode] !== userPreferedLangId) {
      for (const langKey in channelLanguages) {
        if (channelLanguages[langKey] === userPreferedLangId) {
          langShortCode = langKey
        }
      }
    }
    yield put(
      push(
        getRouteForLang(
          isEmployer
            ? AppRoute.EmployerLanguageSettings
            : AppRoute.LanguageSettings,
          langShortCode
        )
      )
    )
    yield put({type: type.systemLanguages.requested})
  } else yield put({type: type.systemLanguageUpdate.failed, payload: error})
}

function* confirmProfile() {
  const {response, error} = yield call(API.post, '/user/profile', undefined)
  if (response) {
    yield put({
      type: type.auth.confirmProfile.succeeded,
      payload: response.data.rjbProfileStatus,
    })
  } else {
    yield put({type: type.auth.confirmProfile.failed, payload: error})
  }
}

function* fetchUser() {
  const {response, error} = yield call(API.get, '/user/me')
  if (response) {
    yield put({
      type: type.USER_FETCH_SUCCEEDED,
      payload: response.data,
    })
    // if (isEmployerRoute() && !isEmployerUser(response.data.type)) {
    //   yield put(push('/404'))
    // }
    // if (!isEmployerRoute() && isSeekerUser(response.data.type)) {
    //   yield put(push('/404'))
    // }
  } else {
    yield put({type: type.USER_FETCH_FAILED, payload: error})
  }
}

function* pingUser() {
  const {response, error} = yield call(API.get, '/user/ping')
  if (response) {
    yield put({
      type: type.auth.ping.succeeded,
    })
  } else {
    yield put({type: type.auth.ping.failed, payload: error})
  }
}

function* verifyEmail(action: ActionType<string>) {
  const {response, error} = yield call(API.post, `/auth/verify-email`, {
    token: action.payload,
  })

  if (response) {
    const userData = response.data.items[0]
    localStorage.setItem('userId', userData.id)
    yield put({
      type: type.auth.verifyEmail.succeeded,
    })
    yield put({
      type: type.USER_LOGIN_SUCCEEDED,
      payload: userData,
    })
    initAPIHeaders()

    const grantedConsents = hasGrantedMandatoryConsents(userData)
    let NextRoute: string
    if (!grantedConsents) {
      NextRoute = getRoute(AppRoute.SeekerLoginConsents)
    } else if (
      (userData.rjbProfileStatus === RjbProfileStatus.DRAFT &&
        userData.onboardingStatus !== SeekerOnboardingStatus.FINISHED) ||
      (!userData.rjbProfileStatus && !userData.onboardingStatus)
    ) {
      NextRoute = getRoute(AppRoute.Onboarding)
    } else if (!userData.contactDataSubmitted) {
      NextRoute = getRoute(AppRoute.ContactData)
    } else {
      NextRoute = getRoute(AppRoute.CandidateDashboard)
    }
    yield put(push(NextRoute))
  } else {
    yield put({type: type.auth.verifyEmail.failed, payload: error})
  }
}

function* verifyChangeEmail(action: ActionType<string>) {
  const {response, error} = yield call(
    API.post,
    `/auth/confirm-change-email?token=${action.payload}`,
    undefined
  )

  if (response) {
    yield put({
      type: type.auth.verifyChangeEmail.succeeded,
    })
  } else {
    yield put({type: type.auth.verifyChangeEmail.failed, payload: error})
  }
}

function* resendVerificationverifyEmail(action: ActionType<string>) {
  const {response} = yield call(API.post, `/auth/resend-email-verification`, {
    email: action.payload,
  })

  if (response) {
    yield put({
      type: type.auth.resendVerifyEmail.succeeded,
    })
  } else {
    yield put({
      type: type.auth.resendVerifyEmail.succeeded,
    })
  }
}

export default function* UserSaga(): Generator {
  yield all([
    takeLatest(type.SEEKER_LOGIN_REQUESTED, loginSeeker),
    takeLatest(type.EMPLOYER_LOGIN_REQUESTED, loginEmployer),
    takeLatest(type.USER_LOGOUT_REQUESTED, logoutUser),

    takeLatest(type.contentLanguageUpdate.requested, updateContentLanguage),
    takeLatest(type.systemLanguageUpdate.requested, updateSystemLanguage),

    takeLatest(type.auth.forgotPassword.requested, forgotPassword),
    takeLatest(type.auth.resetPassword.requested, resetPassword),
    takeLatest(
      type.auth.resetEmployerPassword.requested,
      resetEmployerPassword
    ),
    takeLatest(type.auth.changePassword.requested, changePassword),
    takeLatest(type.auth.changeEmail.requested, changeEmail),

    takeLatest(type.auth.verifyEmail.requested, verifyEmail),
    takeLatest(type.auth.verifyChangeEmail.requested, verifyChangeEmail),
    takeLatest(
      type.auth.resendVerifyEmail.requested,
      resendVerificationverifyEmail
    ),

    takeLatest(type.auth.confirmProfile.requested, confirmProfile),

    takeLatest(type.USER_FETCH_REQUESTED, fetchUser),
    takeLatest(type.auth.ping.requested, pingUser),
    takeLatest(type.employer.getLoginConsents.requested, getLoginConsents),
    takeLatest(
      type.employer.acceptLoginConsents.requested,
      acceptEmployerLoginConsents
    ),
    takeLatest(
      type.seeker.getSeekerLoginConsents.requested,
      getSeekerLoginConsents
    ),
    takeLatest(
      type.seeker.acceptSeeekerLoginConsents.requested,
      acceptSeekerLoginConsents
    ),
  ])
}
