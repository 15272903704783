import * as Yup from 'yup'
import {countHtmlChars} from 'Utils/String'
import {isValidPhoneNumber} from 'libphonenumber-js'

export const ShortTitle = Yup.string().max(25, 'Title is too long.')

export const MediumTitle = Yup.string().max(50, 'Title is too long.')

export const LongTitle = Yup.string().max(75, 'Title is too long.')

export const ShortDescription = Yup.string().max(
  500,
  'Description is too long.'
)

export const MediumDescription = Yup.string().max(
  750,
  'Description is too long.'
)

export const LongDescription = Yup.string().max(
  1000,
  'Description is too long.'
)

const passwordValidationText =
  'Password must be 6-20 characters long. It may contain only latin alpha-numeric characters and must contain at least one numeric digit, one uppercase and one lowercase letter.'
export const Password = Yup.string()
  .required('Password is required.')
  .min(6, passwordValidationText)
  .max(20, passwordValidationText)
  .test({
    name: 'password',
    message: passwordValidationText,
    test: (value = ''): boolean =>
      !!value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,20}$/g),
  })

const employerLoginValidationText = 'Wrong email or password'

export const EmployerLoginPassword = Yup.string()
  .required('Password is required.')
  .min(6, employerLoginValidationText)
  .max(20, employerLoginValidationText)
  .test({
    name: 'password',
    message: employerLoginValidationText,
    test: (value = ''): boolean =>
      !!value.match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,20}$/g),
  })

export const Email = Yup.string()
  .email('Email is invalid')
  .required('Email is required')

export const Identifier = Yup.string().required('Email or Username is required')

export const FirstName = Yup.string()
  .required('Enter your first name.')
  .max(50, 'Enter your first name.')
  .test({
    name: 'firstName',
    message: 'Enter your first name.',
    test: (value = ''): boolean => {
      const containsOnlyAlphanumericSpaceDash = value.match(
        /^[\p{Ll}\p{Lu}\d -]+$/u
      )
        ? 1
        : 0

      return !!containsOnlyAlphanumericSpaceDash
    },
  })

export const LastName = Yup.string()
  .required('Enter your last name.')
  .max(50, 'Enter your last name.')
  .test({
    name: 'lastName',
    message: 'Enter your last name.',
    test: (value = ''): boolean => {
      const containsOnlyAlphanumericSpaceDash = value.match(
        /^[\p{Ll}\p{Lu}\d -]+$/u
      )
        ? 1
        : 0
      return !!containsOnlyAlphanumericSpaceDash
    },
  })

const numbersRegExp = /^[0-9]*$/
export const PostalCode = Yup.string()
  .matches(numbersRegExp, 'Enter a valid postal code')
  .min(4, 'Must be longer than or equal to 4 characters')
  .max(6, 'Must be shorter than or equal to 6 characters')
  .typeError('Enter a valid postal code.')
  .required('Zip code is required')
  .nullable()

export const ExtraLongHTML = Yup.string()
  .nullable()
  .test({
    name: 'HTML',
    message: 'Description is too long.',
    test: (value = ''): boolean => countHtmlChars(value || '') <= 3000,
  })

export const LongHTML = Yup.string()
  .nullable()
  .test({
    name: 'HTML',
    message: 'Description is too long.',
    test: (value = ''): boolean => countHtmlChars(value || '') <= 1000,
  })

export const MediumHTML = Yup.string()
  .nullable()
  .test({
    name: 'HTML',
    message: 'Description is too long.',
    test: (value = ''): boolean => countHtmlChars(value || '') <= 750,
  })

export const PhoneNumber = Yup.string().test({
  name: 'isPhoneValid',
  message: 'Phone number must be a valid international phone number.',
  test: (value = '') => {
    if (!value) return true

    return isValidPhoneNumber(value)
  },
})
