import {
  ActionType,
  ErrorType,
  IncomingRequestsSearchParamsType,
  IncomingRequestsSearchResultsType,
  IncomingRequestsStateType,
  IncomingRequestsCompaniesType,
  IncomingRequestType,
  JourneyTrackerDataType,
} from 'Interfaces'
import * as type from '../types'

const initialState: IncomingRequestsStateType = {
  dashboardRequests: {
    data: [],
    loading: false,
    error: undefined,
  },
  companies: {
    data: [],
    loading: false,
    error: undefined,
  },
  searchResults: {
    data: [],
    loading: false,
    error: undefined,
    loadMore: undefined,
    loadMoreProgress: false,
    params: {},
  },
  journeyTracker: {
    data: {
      data: {
        journeyStatus: '',
        activeInterviewsCount: undefined,
        activeOffersCount: undefined,
      },
    },
    loading: false,
    error: undefined,
  },
}

const IncomingRequestsReducer = (
  state = initialState,
  action: ActionType<
    | IncomingRequestsSearchParamsType
    | IncomingRequestsSearchResultsType
    | IncomingRequestType[]
    | IncomingRequestsCompaniesType[]
    | ErrorType
    | string
    | undefined
  >
): IncomingRequestsStateType => {
  switch (action.type) {
    case type.incomingRequests.journey.requested:
      return {
        ...state,
        journeyTracker: {
          ...state.journeyTracker,
          loading: true,
        },
      }
    case type.incomingRequests.getCompanies.requested:
      return {
        ...state,
        companies: {
          ...state.companies,
          loading: true,
        },
      }
    case type.incomingRequests.search.requested:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          loading: true,
          params: {
            ...state.searchResults.params,
            ...(action.payload as IncomingRequestsSearchParamsType),
          },
        },
      }
    case type.incomingRequests.loadMore.requested:
      return {
        ...state,
        searchResults: {...state.searchResults, loadMoreProgress: true},
      }
    case type.incomingRequests.dashboard.requested:
      return {
        ...state,
        dashboardRequests: {...state.dashboardRequests, loading: true},
      }

    case type.incomingRequests.search.succeeded:
      const searchPayload = action.payload as IncomingRequestsSearchResultsType
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          data: searchPayload.data,
          loading: false,
          error: undefined,
          loadMore: searchPayload.loadMore,
          loadMoreProgress: false,
        },
      }

    case type.incomingRequests.journey.succeeded:
      return {
        ...state,
        journeyTracker: {
          ...state.journeyTracker,
          data: action.payload as JourneyTrackerDataType,
          loading: true,
          error: undefined,
        },
      }

    case type.incomingRequests.update.approveContactRequest.succeeded:
    case type.incomingRequests.update.rejectContactRequest.succeeded:
    case type.incomingRequests.update.approveOfferRequest.succeeded:
    case type.incomingRequests.update.rejectOfferRequest.succeeded:
    case type.incomingRequests.update.rejectPlacement.succeeded:
    case type.incomingRequests.update.confirmPlacement.succeeded:
    case type.incomingRequests.update.approveInterviewInvitation.succeeded:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          loading: false,
          data: state.searchResults.data.map((item) =>
            item.id === (action.payload as IncomingRequestType).id
              ? {
                  ...item,
                  status: (action.payload as IncomingRequestType).status,
                  videoUrl: (action.payload as IncomingRequestType).videoUrl,
                }
              : item
          ),
        },
        dashboardRequests: {
          ...state.dashboardRequests,
          loading: false,
          data: state.dashboardRequests.data.map((item) =>
            item.id === (action.payload as IncomingRequestType).id
              ? {
                  ...item,
                  status: (action.payload as IncomingRequestType).status,
                  videoUrl: (action.payload as IncomingRequestType).videoUrl,
                }
              : item
          ),
        },
      }
    case type.incomingRequests.update.rejectInterviewInvitation.succeeded:
    case type.incomingRequests.update.cancelInterviewInvitation.succeeded:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          loading: false,
          data: state.searchResults.data.map((item) =>
            item.id === (action.payload as IncomingRequestType).id
              ? {
                  ...item,
                  status: (action.payload as IncomingRequestType).status,
                  seekerReason: (action.payload as IncomingRequestType)
                    .seekerReason,
                }
              : item
          ),
        },
        dashboardRequests: {
          ...state.dashboardRequests,
          loading: false,
          data: state.dashboardRequests.data.map((item) =>
            item.id === (action.payload as IncomingRequestType).id
              ? {
                  ...item,
                  status: (action.payload as IncomingRequestType).status,
                  seekerReason: (action.payload as IncomingRequestType)
                    .seekerReason,
                }
              : item
          ),
        },
      }
    case type.incomingRequests.update.proposeNewTime.succeeded:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          loading: false,
          data: state.searchResults.data.map((item) =>
            item.id === (action.payload as IncomingRequestType).id
              ? {
                  ...item,
                  ...(action.payload as IncomingRequestType),
                }
              : item
          ),
        },
        dashboardRequests: {
          ...state.dashboardRequests,
          loading: false,
          data: state.dashboardRequests.data.map((item) =>
            item.id === (action.payload as IncomingRequestType).id
              ? {
                  ...item,
                  ...(action.payload as IncomingRequestType),
                }
              : item
          ),
        },
      }
    case type.incomingRequests.journey.failed:
    case type.incomingRequests.update.approveContactRequest.failed:
    case type.incomingRequests.update.rejectContactRequest.failed:
    case type.incomingRequests.update.approveOfferRequest.failed:
    case type.incomingRequests.update.rejectOfferRequest.failed:
    case type.incomingRequests.update.rejectPlacement.failed:
    case type.incomingRequests.update.confirmPlacement.failed:
    case type.incomingRequests.update.approveInterviewInvitation.failed:
    case type.incomingRequests.update.rejectInterviewInvitation.failed:
    case type.incomingRequests.update.proposeNewTime.failed:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          loading: false,
          error: action.payload as ErrorType,
        },
        dashboardRequests: {
          ...state.dashboardRequests,
          loading: false,
          error: action.payload as ErrorType,
        },
        journeyTracker: {
          ...state.journeyTracker,
          loading: false,
          error: action.payload as ErrorType,
        },
      }

    case type.incomingRequests.dashboard.succeeded:
      const dashboardRequestsPayload = action.payload as IncomingRequestType[]
      return {
        ...state,
        dashboardRequests: {
          ...state.dashboardRequests,
          data: dashboardRequestsPayload,
          loading: false,
        },
      }
    case type.incomingRequests.loadMore.succeeded:
      const loadMorePayload =
        action.payload as IncomingRequestsSearchResultsType
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          data: [...state.searchResults.data, ...loadMorePayload.data],
          loading: false,
          error: undefined,
          loadMore: loadMorePayload.loadMore,
          loadMoreProgress: false,
        },
      }
    case type.incomingRequests.getCompanies.succeeded:
      return {
        ...state,
        companies: {
          data: action.payload as IncomingRequestsCompaniesType[],
          loading: false,
          error: undefined,
        },
      }
    case type.incomingRequests.search.failed:
    case type.incomingRequests.loadMore.failed:
    case type.incomingRequests.getCompanies.failed:
      return {
        ...state,
        companies: {
          ...state.companies,
          loading: false,
          error: action.payload as ErrorType,
        },
      }
    case type.incomingRequests.dashboard.failed:
      return {
        ...state,
        dashboardRequests: {
          ...state.dashboardRequests,
          loading: false,
          error: action.payload as ErrorType,
        },
      }
    default:
      return state
  }
}

export default IncomingRequestsReducer
