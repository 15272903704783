import * as type from '../types'
import {ActionType, ContactDataType} from 'Interfaces'

export const contactData = (
  contactData: ContactDataType
): ActionType<ContactDataType> => {
  return {
    type: type.CONTACT_DATA,
    payload: {
      ...contactData,
    },
  }
}
