import {IncomingRequestType} from 'Interfaces'
import {useMemo} from 'react'
import {CombinedRequestStatues, RequestType} from '../Constants'

export type RequestActionTypes =
  | 'VIEW_DETAILS'
  // CONTACT REQUESTS
  // | 'INVITE'
  // | 'SEND_MESSAGE'
  // | 'SEND_OFFER'
  | 'CONTACT_REQUEST_PENDING'
  | 'CONTACT_REQUEST_APPROVED'
  | 'CONTACT_REQUEST_REJECTED'
  | 'CONTACT_REQUEST_EXPIRED'
  | 'ACCEPT_REJECT_INTERVIEW'
  | 'PROPOSE_NEW_TIME'
  | 'ACCEPT_REJECT_OFFER'
  | 'PLACEMENT_PENDING'
  | 'PLACEMENT_CONFIRMED'
  | 'PLACEMENT_REJECTED'
  | 'PLACEMENT_EXPIRED'
  | 'OFFER_REJECTED'
  | 'OFFER_PENDING'
  | 'OFFER_EXTENDED'
  | 'NEW_OFFER_PROPOSED'
  | 'OFFER_CANCELLED'
  | 'OFFER_EXPIRED'
  | 'OFFER_ACCEPTED'
  | 'PLACEMENT_PENDING_CONFIRMATION'
  | 'START_VIDEO_INTERVIEW'

const getActionsArray = (request: IncomingRequestType) => {
  const actions: RequestActionTypes[] = ['VIEW_DETAILS']

  // CONTACT REQUESTS
  // Todo: Only approved has action other than View Details..?
  if (
    request.type === RequestType.CONTACT_REQUEST &&
    request.status === CombinedRequestStatues.PENDING
  ) {
    actions.push('CONTACT_REQUEST_PENDING')
  }
  if (
    request.type === RequestType.CONTACT_REQUEST &&
    request.status === CombinedRequestStatues.APPROVED
  ) {
    actions.push('CONTACT_REQUEST_APPROVED')
    // actions.push('INVITE')
    // actions.push('SEND_MESSAGE')
    // actions.push('SEND_OFFER')
  }
  if (
    request.type === RequestType.CONTACT_REQUEST &&
    request.status === CombinedRequestStatues.REJECTED
  ) {
    actions.push('CONTACT_REQUEST_REJECTED')
  }
  if (
    request.type === RequestType.CONTACT_REQUEST &&
    request.status === CombinedRequestStatues.EXPIRED
  ) {
    actions.push('CONTACT_REQUEST_EXPIRED')
  }

  if (
    request.type === RequestType.INTERVIEW_REQUEST &&
    request.status === CombinedRequestStatues.PENDING
  ) {
    actions.push('ACCEPT_REJECT_INTERVIEW')
    actions.push('PROPOSE_NEW_TIME')
  }
  if (
    request.type === RequestType.INTERVIEW_REQUEST &&
    request.status === CombinedRequestStatues.INTERVIEW_CONFIRMED
  ) {
    actions.push('START_VIDEO_INTERVIEW')
  }
  if (
    request.type === RequestType.OFFER_REQUEST &&
    request.status === CombinedRequestStatues.PENDING
  ) {
    actions.push('ACCEPT_REJECT_OFFER')
  }

  if (
    request.type === RequestType.PLACEMENT_REQUEST &&
    (request.status ===
      CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_PENDING ||
      request.status ===
        CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_PENDING ||
      request.status ===
        CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_PENDING)
  ) {
    actions.push('PLACEMENT_PENDING')
  }
  if (
    request.type === RequestType.PLACEMENT_REQUEST &&
    request.status ===
      CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_CONFIRMED
  ) {
    actions.push('PLACEMENT_CONFIRMED')
  }
  if (
    request.type === RequestType.PLACEMENT_REQUEST &&
    request.status === CombinedRequestStatues.EXPIRED
  ) {
    actions.push('PLACEMENT_EXPIRED')
  }
  if (
    request.type === RequestType.PLACEMENT_REQUEST &&
    (request.status ===
      CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_CONFIRMED ||
      request.status ===
        CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_PENDING ||
      request.status ===
        CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_REJECTED ||
      request.status ===
        CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_REJECTED ||
      request.status ===
        CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_REJECTED)
  ) {
    actions.push('PLACEMENT_REJECTED')
  }
  if (
    request.type === RequestType.OFFER_REQUEST &&
    request.status === CombinedRequestStatues.PENDING
  ) {
    actions.push('OFFER_PENDING')
  }
  if (
    request.type === RequestType.OFFER_REQUEST &&
    request.status === CombinedRequestStatues.EXPIRED
  ) {
    actions.push('OFFER_EXPIRED')
  }
  if (
    request.type === RequestType.OFFER_REQUEST &&
    request.status === CombinedRequestStatues.CANCELLED
  ) {
    actions.push('OFFER_CANCELLED')
  }
  if (
    request.type === RequestType.OFFER_REQUEST &&
    request.status === CombinedRequestStatues.ACCEPTED
  ) {
    actions.push('OFFER_ACCEPTED')
  }
  if (
    request.type === RequestType.OFFER_REQUEST &&
    request.status === CombinedRequestStatues.EXTENDED
  ) {
    actions.push('OFFER_EXTENDED')
  }
  if (
    request.type === RequestType.OFFER_REQUEST &&
    request.status === CombinedRequestStatues.REJECTED
  ) {
    actions.push('OFFER_REJECTED')
  }
  if (
    request.type === RequestType.OFFER_REQUEST &&
    request.status === CombinedRequestStatues.NEW_OFFER_PROPOSED
  ) {
    actions.push('NEW_OFFER_PROPOSED')
  }
  if (
    request.type === RequestType.PLACEMENT_REQUEST &&
    (request.status ===
      CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_CONFIRMED ||
      request.status ===
        CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_PENDING)
  ) {
    actions.push('PLACEMENT_PENDING_CONFIRMATION')
  }
  return actions
}

const useRequestActionsArray = (
  request: IncomingRequestType
): [(action: RequestActionTypes) => boolean, RequestActionTypes[]] => {
  const actions = useMemo(() => getActionsArray(request), [request])
  const hasAction = (action: RequestActionTypes) => actions.includes(action)
  return [hasAction, actions]
}

export default useRequestActionsArray
