import * as type from '../types'
import {all, call, put, select, takeLatest} from 'redux-saga/effects'
import {
  ActionType,
  ApproveRejectModalRequestType,
  CancelInterviewType,
  IncomingRequestsSearchParamsType,
  NewInterviewTimePayloadType,
} from 'Interfaces'
import {RootState} from 'Store/Reducers'
import {generateURL} from 'Utils/CommonHelpers'
import API from 'Services/API'
import {
  packIncomingRequestCompanies,
  packIncomingRequests,
} from 'Store/Packing/IncomingRequests'

const searchIncomingRequestsBaseURL = '/company-seeker-journey/seeker/requests'

const journeyApiEndpoints = {
  contactRequest: '/company-seeker-journey/seeker/contact-request',
}

function* getDashboardIncomingRequests() {
  const {response, error} = yield call(
    API.get,
    '/company-seeker-journey/seeker/incoming-requests?pageSize=5'
  )

  if (response) {
    yield put({
      type: type.incomingRequests.dashboard.succeeded,
      payload: packIncomingRequests(response.data.items),
    })
  } else {
    yield put({type: type.incomingRequests.dashboard.failed, payload: error})
  }
}

function* searchIncomingRequests(
  action: ActionType<IncomingRequestsSearchParamsType>
) {
  const urlWithParams = generateURL(
    searchIncomingRequestsBaseURL,
    action.payload as IncomingRequestsSearchParamsType
  )
  const {response, error} = yield call(API.get, urlWithParams)

  if (response) {
    // Generate load more URL
    const itemsFetched = response.data.items.length
    const total = response.data.total
    const params = action.payload as IncomingRequestsSearchParamsType
    let loadMore = undefined
    if (total > itemsFetched) {
      loadMore = generateURL(searchIncomingRequestsBaseURL, params)
    }

    yield put({
      type: type.incomingRequests.search.succeeded,
      payload: {
        data: packIncomingRequests(response.data.items),
        loadMore,
      },
    })
  } else {
    yield put({type: type.incomingRequests.search.failed, payload: error})
  }
}

function* loadMoreIncomingRequests() {
  const oldLoadMore: string = yield select(
    (state: RootState) => state.incomingRequests?.searchResults?.loadMore
  )
  const {response, error} = yield call(API.get, oldLoadMore)

  if (response) {
    // Generate new load more URL
    const itemsFetched: number = yield select(
      (state: RootState) => state.incomingRequests?.searchResults?.data.length
    )
    const params: IncomingRequestsSearchParamsType = yield select(
      (state: RootState) => state.incomingRequests?.searchResults?.params
    )
    const total = response.data.total
    let loadMore = undefined
    if (total > itemsFetched) {
      loadMore = generateURL(
        searchIncomingRequestsBaseURL,
        params,
        itemsFetched
      )
    }

    yield put({
      type: type.incomingRequests.loadMore.succeeded,
      payload: {
        data: packIncomingRequests(response.data.items),
        loadMore,
      },
    })
  } else {
    yield put({type: type.incomingRequests.loadMore.failed, payload: error})
  }
}

function* getCompanies() {
  const {response, error} = yield call(
    API.get,
    '/company-seeker-journey/seeker/companies'
  )

  if (response) {
    yield put({
      type: type.incomingRequests.getCompanies.succeeded,
      payload: packIncomingRequestCompanies(response.data.items),
    })
  } else {
    yield put({type: type.incomingRequests.getCompanies.failed, payload: error})
  }
}

// TODO candidate-dashboard: Test all following request update flows
function* proposeNewTime(action: ActionType<NewInterviewTimePayloadType>) {
  const {response, error} = yield call(
    API.patch,
    '/company-seeker-journey/seeker/interview-request/propose',
    {id: action.payload?.id, reason: action.payload?.reason}
  )

  if (response) {
    yield put({
      type: type.incomingRequests.update.proposeNewTime.succeeded,
      payload: {
        status: response.data.status.toLowerCase(),
        id: action.payload?.id,
        seekerReason: action.payload?.reason,
      },
    })
  } else {
    yield put({
      type: type.incomingRequests.update.proposeNewTime.failed,
      payload: error,
    })
  }
}
function* approveInterviewInvitation(
  action: ActionType<ApproveRejectModalRequestType>
) {
  const {response, error} = yield call(
    API.patch,
    '/company-seeker-journey/seeker/interview-request/approve',
    {id: action.payload?.id}
  )

  if (response) {
    yield put({
      type: type.incomingRequests.update.approveInterviewInvitation.succeeded,
      payload: {
        id: action.payload?.id,
        status: response.data.status.toLowerCase(),
        videoUrl: response.data.videoUrl,
      },
    })
  } else {
    yield put({
      type: type.incomingRequests.update.approveInterviewInvitation.failed,
      payload: error,
    })
  }
}

function* rejectInterviewInvitation(
  action: ActionType<ApproveRejectModalRequestType>
) {
  const {response, error} = yield call(
    API.patch,
    '/company-seeker-journey/seeker/interview-request/reject',
    {id: action.payload?.id, reason: action.payload?.reason}
  )

  if (response) {
    yield put({
      type: type.incomingRequests.update.rejectInterviewInvitation.succeeded,
      payload: {
        id: action.payload?.id,
        status: response.data.status.toLowerCase(),
      },
    })
  } else {
    yield put({
      type: type.incomingRequests.update.rejectInterviewInvitation.failed,
      payload: error,
    })
  }
}

function* cancelInterviewInvitation(action: ActionType<CancelInterviewType>) {
  const {response, error} = yield call(
    API.patch,
    '/company-seeker-journey/seeker/interview-request/cancel',
    {id: action.payload?.id, reason: action.payload?.reason}
  )

  if (response) {
    yield put({
      type: type.incomingRequests.update.cancelInterviewInvitation.succeeded,
      payload: {
        status: response.data.status.toLowerCase(),
        id: response.data.id,
        seekerReason: action.payload?.reason,
      },
    })
  } else {
    yield put({
      type: type.incomingRequests.update.cancelInterviewInvitation.failed,
      payload: error,
    })
  }
}

function* approveContactRequest(
  action: ActionType<ApproveRejectModalRequestType>
) {
  const {response, error} = yield call(
    API.patch,
    journeyApiEndpoints.contactRequest,
    {requestId: action.payload?.id, contactRequestStatus: 'approved'}
  )

  if (response) {
    yield put({
      type: type.incomingRequests.update.approveContactRequest.succeeded,
      payload: {
        id: response.data.id,
        status: response.data.status,
      },
    })
  } else {
    yield put({
      type: type.incomingRequests.update.approveContactRequest.failed,
      payload: error,
    })
  }
}

function* rejectContactRequest(
  action: ActionType<ApproveRejectModalRequestType>
) {
  const {response, error} = yield call(
    API.patch,
    journeyApiEndpoints.contactRequest,
    {requestId: action.payload?.id, contactRequestStatus: 'rejected'}
  )
  if (response) {
    yield put({
      type: type.incomingRequests.update.rejectContactRequest.succeeded,
      payload: {
        id: action.payload?.id,
        status: response.data.status,
      },
    })
  } else {
    yield put({
      type: type.incomingRequests.update.rejectContactRequest.failed,
      payload: error,
    })
  }
}

function* approveOfferRequest(
  action: ActionType<ApproveRejectModalRequestType>
) {
  const {response, error} = yield call(
    API.patch,
    '/company-seeker-journey/seeker/offer-request',
    {requestId: action.payload?.id, offerRequestStatus: 'accepted'}
  )

  if (response) {
    yield put({
      type: type.incomingRequests.update.approveOfferRequest.succeeded,
      payload: {
        id: action.payload?.id,
        status: response.data.status,
      },
    })
  } else {
    yield put({
      type: type.incomingRequests.update.approveContactRequest.failed,
      payload: error,
    })
  }
}

function* rejectOfferRequest(
  action: ActionType<ApproveRejectModalRequestType>
) {
  const {response, error} = yield call(
    API.patch,
    '/company-seeker-journey/seeker/offer-request',
    {requestId: action.payload?.id, offerRequestStatus: 'rejected'}
  )
  if (response) {
    yield put({
      type: type.incomingRequests.update.rejectContactRequest.succeeded,
      payload: {
        id: action.payload?.id,
        status: response.data.status,
      },
    })
  } else {
    yield put({
      type: type.incomingRequests.update.rejectContactRequest.failed,
      payload: error,
    })
  }
}

function* confirmPlacement(action: ActionType<ApproveRejectModalRequestType>) {
  const {response, error} = yield call(
    API.patch,
    '/company-seeker-journey/seeker/placement-request',
    {
      requestId: action.payload?.id,
      placementConfirmationStatus: 'confirmed',
    }
  )

  if (response) {
    yield put({
      type: type.incomingRequests.update.confirmPlacement.succeeded,
      payload: {
        status: response.data.status,
        id: action.payload?.id,
      },
    })
  } else {
    yield put({
      type: type.incomingRequests.update.confirmPlacement.failed,
      payload: error,
    })
  }
}

function* rejectPlacement(action: ActionType<ApproveRejectModalRequestType>) {
  const {response, error} = yield call(
    API.patch,
    '/company-seeker-journey/seeker/placement-request',
    {
      requestId: action.payload?.id,
      placementConfirmationStatus: 'rejected',
    }
  )

  if (response) {
    yield put({
      type: type.incomingRequests.update.rejectPlacement.succeeded,
      payload: {
        status: response.data.status,
        id: action.payload?.id,
      },
    })
  } else {
    yield put({
      type: type.incomingRequests.update.rejectPlacement.failed,
      payload: error,
    })
  }
}

function* getJourneyStep() {
  const {response, error} = yield call(
    API.get,
    '/company-seeker-journey/seeker/journey-tracker'
  )

  if (response) {
    yield put({
      type: type.incomingRequests.journey.succeeded,
      payload: response,
    })
  } else {
    yield put({
      type: type.incomingRequests.journey.failed,
      payload: error,
    })
  }
}

export default function* CandidateSearchSaga(): Generator {
  yield all([
    takeLatest(
      type.incomingRequests.dashboard.requested,
      getDashboardIncomingRequests
    ),
    takeLatest(type.incomingRequests.journey.requested, getJourneyStep),
    takeLatest(type.incomingRequests.search.requested, searchIncomingRequests),
    takeLatest(
      type.incomingRequests.loadMore.requested,
      loadMoreIncomingRequests
    ),
    takeLatest(type.incomingRequests.getCompanies.requested, getCompanies),
    takeLatest(
      type.incomingRequests.update.proposeNewTime.requested,
      proposeNewTime
    ),
    takeLatest(
      type.incomingRequests.update.approveInterviewInvitation.requested,
      approveInterviewInvitation
    ),
    takeLatest(
      type.incomingRequests.update.rejectInterviewInvitation.requested,
      rejectInterviewInvitation
    ),
    takeLatest(
      type.incomingRequests.update.cancelInterviewInvitation.requested,
      cancelInterviewInvitation
    ),
    takeLatest(
      type.incomingRequests.update.approveContactRequest.requested,
      approveContactRequest
    ),
    takeLatest(
      type.incomingRequests.update.rejectContactRequest.requested,
      rejectContactRequest
    ),
    takeLatest(
      type.incomingRequests.update.approveOfferRequest.requested,
      approveOfferRequest
    ),
    takeLatest(
      type.incomingRequests.update.rejectOfferRequest.requested,
      rejectOfferRequest
    ),
    takeLatest(
      type.incomingRequests.update.confirmPlacement.requested,
      confirmPlacement
    ),
    takeLatest(
      type.incomingRequests.update.rejectPlacement.requested,
      rejectPlacement
    ),
  ])
}
