import qs from 'qs'
import {
  employerPublic as employerPublicEn,
  seekerPublic as seekerPublicEn,
  publicRoutes as publicRoutesEn,
  employerPrivate as employerPrivateEn,
  employerPrivateWithParam as employerPrivateWithParamEn,
} from 'Services/I18n/Localizations/Routes/en'
import {
  employerPublic as employerPublicSl,
  seekerPublic as seekerPublicSl,
  publicRoutes as publicRoutesSl,
  employerPrivate as employerPrivateSl,
  employerPrivateWithParam as employerPrivateWithParamSl,
} from 'Services/I18n/Localizations/Routes/sl'

export const uuidRegex =
  '[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}'

export const getQuerySearchParam = (querystring: string, item: string) =>
  qs.parse(querystring, {ignoreQueryPrefix: true})[item] as string | undefined

export const routesHelper = {
  en: {
    allPublicRoutes: {
      ...seekerPublicEn,
      ...employerPublicEn,
      ...publicRoutesEn,
    },
    employerPrivate: employerPrivateEn,
    employerPrivateWithParam: employerPrivateWithParamEn,
    employerPrivateWithParamRegex: {
      'routes.publicassessments': new RegExp(
        `^\\/candidate\\/${uuidRegex}\\/assessments$`
      ),
      'routes.invitecalendar': new RegExp(
        `^\\/candidate\\/invite\\/${uuidRegex}$`
      ),
      'routes.impersonateEmployer': new RegExp(
        `^\\/impersonate\\/employer\\/${uuidRegex}$`
      ),
      'routes.employerCandidateProfilePublic': new RegExp(
        `^\\/candidate\\/public\\/${uuidRegex}$`
      ),
      'routes.employerCandidateProfileFull': new RegExp(
        `^\\/candidate\\/cv\\/${uuidRegex}$`
      ),
    },
  },
  sl: {
    allPublicRoutes: {
      ...seekerPublicSl,
      ...employerPublicSl,
      ...publicRoutesSl,
    },
    employerPrivate: employerPrivateSl,
    employerPrivateWithParam: employerPrivateWithParamSl,
    employerPrivateWithParamRegex: {
      'routes.publicassessments': new RegExp(
        `^\\/kandidat\\/${uuidRegex}\\/testi$`
      ),
      'routes.invitecalendar': new RegExp(
        `^\\/koledar\\/invite\\/${uuidRegex}$`
      ),
      'routes.impersonateEmployer': new RegExp(
        `^\\/impersonate\\/employer\\/${uuidRegex}$`
      ),
      'routes.employerCandidateProfilePublic': new RegExp(
        `^\\/kandidat\\/profil\\/${uuidRegex}$`
      ),
      'routes.employerCandidateProfileFull': new RegExp(
        `^\\/kandidat\\/cv\\/${uuidRegex}$`
      ),
    },
  },
}
