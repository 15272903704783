export const mockMessages = [
  {
    dateCreated: '12.04.2022 13:33',
    body: 'Message one',
    id: '33333434343434343434343',
  },
  {
    dateCreated: '12.04.2022 13:33',
    body: 'How are you?',
    id: '33333434343434343434343',
  },
  {
    dateCreated: '12.04.2022 13:33',
    body: 'Do you like sun?',
    id: '33333434343434343434343',
  },
  {
    dateCreated: '12.04.2022 13:33',
    body: 'How are you?',
    id: '33333434343434343434343',
  },
  {
    dateCreated: '12.04.2022 13:33',
    body: 'Do you like sun?',
    id: '33333434343434343434343',
  },
  {
    dateCreated: '12.04.2022 13:33',
    body: 'How are you?',
    id: '33333434343434343434343',
  },
  {
    dateCreated: '12.04.2022 13:33',
    body: 'Do you like sun?',
    id: '33333434343434343434343',
  },
  {
    dateCreated: '12.04.2022 13:33',
    body: 'How are you?',
    id: '33333434343434343434343',
  },
  {
    dateCreated: '12.04.2022 13:33',
    body: 'Do you like sun?',
    id: '33333434343434343434343',
  },
  {
    dateCreated: '12.04.2022 13:33',
    body: 'How are you?',
    id: '33333434343434343434343',
  },
  {
    dateCreated: '12.04.2022 13:33',
    body: 'Do you like sun?',
    id: '33333434343434343434343',
  },
  {
    dateCreated: '12.04.2022 13:33',
    body: 'How are you?',
    id: '33333434343434343434343',
  },
]

export const mockHistory = [
  {
    dateCreated: '12.04.2022 13:33',
    body: 'Profile Checked',
    id: '33333434343434343434343',
    interviewedBy: 'Teja Zaplotnik',
    evaluation: 5,
  },
  {
    dateCreated: '12.04.2022 13:33',
    body: 'Internal discussion needed.',
    id: '33333434343434343434343',
  },
]


export const mockComments = [
  {
    body: 'SavedCandidate Comment 1',
    dateCreated: "2022-12-02T13:24:15.464Z",
    id: '1',
    seekerId: 'seeker-1',
    username: 'MangartCompany'
  },
  {
    body: 'SavedCandidate Comment 2',
    dateCreated: "2022-12-03T13:24:15.464Z",
    id: '2',
    seekerId: 'seeker-1',
    username: 'MangartCompany'
  }
]
