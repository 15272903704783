export const companyBenefits = {
  'companyBenefits.benefitsTitle': 'Benefits',

  'companyBenefits.friendlyWorkEnvironmentTitle': 'Friendly work environment',
  'companyBenefits.otherTitle': 'Other',

  'companyBenefits.modalTitle': 'Edit benefits',
  'companyBenefits.modalBenefitsTextTitle': 'BENEFITS',
  'companyBenefits.modalBenefitsTextPlaceholderText':
    'Example: We offer fresh coffee and personal growth ...',

  'companyBenefits.modalCompanyBenefitsTitle': 'Company benefits',
  'companyBenefits.modalCompanyBenefitsText': 'Friendly work environment',
  'companyBenefits.modalCustomBenefitsTitle': 'ADDITIONAL BENEFITS',
  'companyBenefits.modalCustomBenefitsPlaceholderText':
    'Enter additional benefits',
}