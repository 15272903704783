import * as Yup from 'yup'
import {ShortTitle} from 'Validations'

const Validation = Yup.object({
  name: Yup.string().when('newOrUpdate', {
    is: (newOrUpdate: 'new' | 'update' | undefined) =>
      newOrUpdate === 'new' || !newOrUpdate,
    then: ShortTitle.required('Field required'),
  }),
  savedSearchId: Yup.string().when('newOrUpdate', {
    is: (newOrUpdate: 'new' | 'update' | undefined) => newOrUpdate !== 'new',
    then: Yup.string().required('Field required'),
  }),
})

export default Validation
