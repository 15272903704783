import {FC} from 'react'
import styles from './ItemTextWithIcon.module.scss'
import classNames from 'classnames'
import {FormattedMessage} from 'react-intl'
import {OverridableComponent} from '@mui/material/OverridableComponent'
import {SvgIconTypeMap} from '@mui/material'

interface ItemTextWithIconProps {
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string
  }
  primaryText?: string | null
  secondaryText?: string | null
  hidePrimaryOnMobile?: boolean
  withColon?: boolean
}

export const ItemTextWithIcon: FC<ItemTextWithIconProps> = ({
  icon: icon,
  primaryText,
  secondaryText,
  hidePrimaryOnMobile = false,
  withColon = false,
}: ItemTextWithIconProps) => {
  const ItemIcon = icon

  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        {ItemIcon && <ItemIcon sx={{fontSize: 20, color: '#949494'}} />}
      </div>
      <div className={styles.text}>
        <span
          className={classNames(styles.primaryText, {
            [styles.hideOnMobile]: hidePrimaryOnMobile,
          })}
        >
          {withColon
            ? primaryText && (
                <>
                  <FormattedMessage id={primaryText} />:
                </>
              )
            : primaryText && <FormattedMessage id={primaryText} />}
        </span>
        <span className={styles.secondaryText}>{secondaryText}</span>
      </div>
    </div>
  )
}
