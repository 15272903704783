import {connect} from 'react-redux'

import {AboutMe} from './AboutMe'
import {AppDispatch} from 'Store'
import {getJobSearchStatuses, updateBioData} from 'Store/Actions'
import {RootState} from 'Store/Reducers'
import {UserUpdateBioType} from 'Interfaces'
import {canChangeProfile} from 'Utils/Profile'

const mapStateToProps = (state: RootState) => ({
  user: state.seeker.data?.full,
  profile: state.seeker,
  canChange: canChangeProfile(state.auth.data?.rjbProfileStatus),
  taxonomies: state.taxonomies,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateBioData: (value: UserUpdateBioType) => dispatch(updateBioData(value)),
  getJobStatuses: () => dispatch(getJobSearchStatuses()),
})

export const AboutMeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AboutMe)
