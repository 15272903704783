import {FC, useCallback, useEffect, useMemo, useState} from 'react'
import {useFormik} from 'formik'
import classnames from 'classnames'
import {FormattedMessage} from 'react-intl'
import qs from 'qs'
import {Link, useHistory, useLocation} from 'react-router-dom'
import {isEqual} from 'lodash'
import {Chip, ButtonGroup, Button} from '@mui/material'
import {HighlightOff, Replay, FilterList} from '@mui/icons-material'

import styles from './SearchControl.module.scss'
import preferencaLogo from 'Assets/Images/preferenca-logo-white.svg'
import Hr from 'Components/Hr'
import {
  TypeAheadKeyword,
  TypeAheadSearch,
} from 'Components/Inputs/TypeAheadImproved'
import Dropdown from 'Components/Inputs/Dropdown'
import DropdownTextInput from 'Components/Inputs/DropdownTextInput'
import DropdownMultiple from 'Components/Inputs/DropdownMultiple'
import YellowButton from 'Components/YellowButton'
// import {radiusOptions} from './options'
import {
  BaseTaxonomyType,
  TaxonomiesStateType,
  TaxonomyWithCategoryType,
  SearchControlFormikValuesType,
  DataType,
  SavedSearchType,
  SavedSearchDataType,
  CandidateSearchFiltersType,
} from 'Interfaces'
import {useWindowResize} from 'Hooks'
import {
  getLang,
  getRoute,
  ImmediatelyChipTranslation,
} from 'Services/I18n/Utils'
import {
  AppRoute,
  CandidateSearchTranslations as cs,
} from 'Services/I18n/Constants'
import Breadcrumbs from 'Components/Breadcrumbs'
import {
  areAllTaxonomiesLoaded,
  taxonomiesToUrl,
  urlToTaxonomies,
  savedSearchToUrl,
  initialUrlToTaxonomies,
} from 'Utils/Search'
import {useTranslation} from 'Hooks'

interface SearchControlProps {
  getSearchResults: (values: SearchControlFormikValuesType) => void
  // getFirstSearchResults: (values: SearchControlFormikValuesType) => void
  getSearchKeywords: () => void
  getTaxonomies: () => void
  taxonomies: TaxonomiesStateType
  searchKeywords: DataType<BaseTaxonomyType>
  savedSearch: SavedSearchDataType
  getSavedSearch: () => void
  sortBy?: string
  filtersType: CandidateSearchFiltersType
  setFiltersType: (filterType: CandidateSearchFiltersType) => void
  clearSearch: () => void
}

export const SearchControl: FC<SearchControlProps> = ({
  getSearchResults,
  getTaxonomies,
  taxonomies,
  getSearchKeywords,
  searchKeywords,
  savedSearch,
  getSavedSearch,
  sortBy,
  filtersType,
  setFiltersType,
  clearSearch,
}) => {
  const [initialSearch, setInitialSearch] = useState(true)
  const [collapsed, setCollapsed] = useState(false)
  const [resetTextInput, setResetTextInput] = useState(true)
  const [enter, setEnter] = useState('')
  const history = useHistory()
  const location = useLocation()
  const params = qs.parse(location.search, {ignoreQueryPrefix: true})
  const defaultFormikValues: SearchControlFormikValuesType = {
    keyword: null,
    location: null,
    radius: null,
    availabilities: [],
    jobCategories: [],
    occupations: [],
    regions: [],
    educationLevels: [],
    languages: [],
    languageLevels: [],
    skills: [],
    skillLevels: [],
    careerStatuses: [],
    careerLevels: [],
    drivingLicenseCategories: [],
    mdrjb: [],
    firstName: '',
    lastName: '',
    sortBy: sortBy,
    filtersType:
      (params.filtersType as CandidateSearchFiltersType) === 'admin'
        ? 'admin'
        : undefined,
    similarCandidateUserId: '',
  }

  const translation = useTranslation()
  const formik = useFormik({
    initialValues: defaultFormikValues,
    onSubmit: (values) => {
      history.push(
        `${getRoute(AppRoute.CandidateSearch)}?${taxonomiesToUrl(values)}`
      )
    },
  })
  const [selectedSavedSearch, setSelectedSavedSearch] = useState<
    SavedSearchType | undefined
  >(undefined)

  const savedSearchList = useMemo(() => {
    return savedSearch.data.reduce((r: BaseTaxonomyType[], s) => {
      if (s.isFavorite)
        r.push({
          id: s.id as string,
          translation: s.name,
        })
      return r
    }, [])
  }, [savedSearch.data])

  const handleScroll = () => {
    if (window.scrollY === 0) {
      setCollapsed(false)
    }
  }

  useEffect(() => {
    formik.setFieldValue(
      'filtersType',
      filtersType === 'admin' ? 'admin' : undefined
    )
  }, [filtersType])

  useEffect(() => {
    setFiltersType(
      params.filtersType && params.filtersType === 'admin'
        ? 'admin'
        : 'employer'
    )
  }, [params.filtersType])

  useEffect(() => {
    const initValues = initialUrlToTaxonomies(location.search)
    getSearchResults(initValues)
    getTaxonomies()
    getSearchKeywords()
    getSavedSearch()
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      clearSearch()
    }
  }, [])

  useEffect(() => {
    const newValues = location.search
      ? urlToTaxonomies(taxonomies, searchKeywords, location.search)
      : defaultFormikValues
    const allLoaded = areAllTaxonomiesLoaded(taxonomies, searchKeywords)
    if (allLoaded) {
      if (!initialSearch) {
        if (!isEqual(newValues, formik.values)) {
          formik.setValues(newValues)
          getSearchResults(newValues)
          window.scrollTo(0, 1)
        } else {
          getSearchResults(newValues)
          window.scrollTo(0, 1)
        }
      } else {
        if (!isEqual(newValues, formik.values)) {
          formik.setValues(newValues)
        }
        setInitialSearch(false)
      }
    }
  }, [location.search, taxonomies, searchKeywords])

  useEffect(() => {
    if (sortBy) {
      history.push(
        `${getRoute(AppRoute.CandidateSearch)}?${taxonomiesToUrl({
          ...formik.values,
          sortBy,
        })}`
      )
    }
  }, [sortBy])

  const mdrjbTaxonomy = [
    {id: 'md', translation: 'MD'},
    {id: 'rjb', translation: 'RJB'},
  ]
  //
  // const setDropdownField = useCallback(
  //   (name: string, value?: string | number) => {
  //     const selectedRadius = radiusOptions.find((c) => {
  //       return typeof value === 'string' && c.id === value
  //     })
  //     formik.setFieldValue(name, selectedRadius)
  //   },
  //   [formik.setFieldValue]
  // )

  const setTypeAheadField = useCallback(
    (name: string, values: BaseTaxonomyType[]) => {
      // if (name === 'location') {
      //   if (
      //     !values[0] ||
      //     (values[0] as TaxonomyWithCategoryType).category !==
      //       translation['town']
      //   ) {
      //     formik.setFieldValue('radius', null)
      //   }
      //   if (values[0]) {
      //     formik.setFieldValue(name, values[0])
      //     if (!formik.values.radius) {
      //       formik.setFieldValue('radius', radiusOptions[0])
      //     }
      //   } else {
      //     formik.setFieldValue(name, null)
      //     formik.setFieldValue('radius', null)
      //   }
      // } else
      if (name === 'keyword') {
        if (values[0]) {
          formik.setFieldValue(name, values[0])
        } else formik.setFieldValue(name, null)
      } else {
        formik.setFieldValue(name, values)
      }
    },
    // [formik.values.radius]
    []
  )
  //
  // const allLocations = useMemo(() => {
  //   return [
  //     ...taxonomies.towns.data.map((t) => ({
  //       ...t,
  //       category: translation['town'],
  //     })),
  //     ...taxonomies.regions.data.map((r) => ({
  //       ...r,
  //       category: translation['region'],
  //     })),
  //     ...taxonomies.countries.data.map((c) => ({
  //       ...c,
  //       category: translation['country'],
  //     })),
  //   ]
  // }, [
  //   taxonomies.towns.data,
  //   taxonomies.countries.data,
  //   taxonomies.regions.data,
  // ])

  const isAdmin = !!localStorage.getItem('impersonatedUserId')

  // const memoizedLocation = useMemo(
  //   () => (
  //     <TypeAheadKeyword
  //       name="location"
  //       value={formik.values.location ? [formik.values.location] : []}
  //       items={allLocations}
  //       maxNumSelections={1}
  //       label={cs.locationLabel}
  //       placeholder={cs.locationPlaceholder}
  //       withoutArrow
  //       className={styles.locationInput}
  //       theme="keyword"
  //       setValue={setTypeAheadField}
  //       resetTextInput={resetTextInput}
  //     />
  //   ),
  //   [allLocations, formik.values.location]
  // )

  const setMultiDropdown = useCallback(
    (name: string, values: string[]) => {
      let newValues: TaxonomyWithCategoryType[] = []

      if (name === 'mdrjb') {
        newValues = mdrjbTaxonomy.filter((m) => values.includes(m.id))
      } else if (name === 'drivingLicenseCategories') {
        newValues = taxonomies['drivingLicenseCategories'].data.filter((t) =>
          values.includes(t.id)
        )
        formik.setFieldValue('drivingLicenseCategories', newValues)
      } else if (name === 'careerStatuses') {
        newValues = taxonomies.jobSearchStatuses.data.filter((d) =>
          values.includes(d.id)
        )
        formik.setFieldValue('careerStatuses', newValues)
      } else {
        newValues = taxonomies[name].data.filter((t) => values.includes(t.id))
        formik.setFieldValue(name, newValues)
      }
    },
    [taxonomies]
  )

  const [mobile, setMobile] = useState(false)
  const [tablet, setTablet] = useState(false)

  useWindowResize((width) => {
    if (width && width < 768) {
      setMobile(true)
      setTablet(false)
    } else if (width && width > 991) {
      setMobile(false)
      setTablet(false)
    } else {
      setMobile(false)
      setTablet(true)
    }
  })

  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: getRoute(AppRoute.HomePageEmployer)},
    {name: cs.mainTitle},
  ]

  useEffect(() => {
    const newSearch = savedSearchToUrl(selectedSavedSearch)
    if (location.search !== newSearch) {
      if (newSearch !== undefined) {
        const newLocation = `${getRoute(AppRoute.CandidateSearch)}?${newSearch}`
        history.push(newLocation)
      }
    }
  }, [selectedSavedSearch])

  const setSavedSearch = (_: string, id: string | number | undefined) => {
    const chosenSavedSearch = savedSearch.data.find((s) => s.id === id)
    setSelectedSavedSearch(chosenSavedSearch)
  }

  useEffect(() => {
    if (enter !== '') {
      history.push(
        `${getRoute(AppRoute.CandidateSearch)}?${taxonomiesToUrl(
          formik.values
        )}`
      )
    }
  }, [enter])

  return (
    <div className={styles.container}>
      <div className={styles.logoAndCollapseWrap}>
        <div className={styles.logoWrap}>
          <Link to={getRoute(AppRoute.HomePageEmployer)}>
            <img src={preferencaLogo} alt="Preferenca" />
          </Link>
        </div>
      </div>
      <div className={styles.headerWrap}>
        <div className={styles.mainTitleWrap}>
          <div>
            <h1 className={styles.title}>
              <FormattedMessage id={cs.mainTitle} />
            </h1>
          </div>
          <div className={styles.breadcrumbWrap}>
            <Breadcrumbs breadcrumbs={breadcrumbsArray} theme="white" />
          </div>
        </div>
        <div className={styles.filterAndSavedSearchWrap}>
          {isAdmin && (
            <div className={styles.filterTypeWrap}>
              <ButtonGroup>
                <Button
                  className={classnames(styles.filterTypeButton, {
                    [styles.filterTypeButtonActive]: filtersType === 'employer',
                  })}
                  onClick={() => {
                    setFiltersType('employer')
                  }}
                >
                  Jobiqo
                </Button>
                <Button
                  className={classnames(styles.filterTypeButton, {
                    [styles.filterTypeButtonActive]: filtersType === 'admin',
                  })}
                  onClick={() => {
                    setFiltersType('admin')
                  }}
                >
                  CBT
                </Button>
              </ButtonGroup>
            </div>
          )}
          {!mobile && !tablet && savedSearchList.length ? (
            <div className={styles.savedSearchWrap}>
              <Dropdown
                name="savedSearch"
                emptyValueLabel={cs.savedSearchTitle}
                value={selectedSavedSearch?.id || undefined}
                items={savedSearchList}
                className={styles.savedSearchInput}
                setValue={setSavedSearch}
                theme="withoutShadow"
              />
            </div>
          ) : null}
        </div>
      </div>
      <Hr className={styles.hr} />
      <div className={styles.basicSearch}>
        <div className={styles.keywords}>
          <TypeAheadKeyword
            name="keyword"
            value={formik.values.keyword ? [formik.values.keyword] : []}
            items={searchKeywords.data}
            maxNumSelections={1}
            label={cs.mainLabel}
            placeholder={cs.mainPlaceholder}
            withoutArrow
            selectNonExisting
            setValue={setTypeAheadField}
            theme="keyword"
            noNeedToClick
            resetTextInput={resetTextInput}
            setEnter={(v) => setEnter(v)}
          />
        </div>
        <div className={styles.jobCategories}>
          <DropdownMultiple
            name="jobCategories"
            items={taxonomies.jobCategories.data}
            values={
              formik.values.jobCategories
                ? formik.values.jobCategories.map((jc) => jc.id)
                : undefined
            }
            selectAll
            setValues={setMultiDropdown}
            label={translation[cs.personaDropdown]}
            emptyValueLabel={cs.personaDropdownPlaceholder}
            forceLabel
            disabled={filtersType === 'admin'}
            theme="white-right"
          />
        </div>
        <div className={styles.regions}>
          <DropdownMultiple
            name="regions"
            items={taxonomies.regions.data}
            values={
              formik.values.regions
                ? formik.values.regions.map((r) => r.id)
                : undefined
            }
            selectAll
            setValues={setMultiDropdown}
            label={translation[cs.regionsDropdown]}
            emptyValueLabel={cs.regionsDropdownPlaceholder}
            forceLabel
            disabled={filtersType === 'admin'}
            theme="white-right"
          />
        </div>
        {/*<div className={classnames(styles.location, styles.locationWithTown)}>*/}
        {/*  {memoizedLocation}*/}
        {/*  <Dropdown*/}
        {/*    label={cs.radiusButton}*/}
        {/*    name="radius"*/}
        {/*    value={formik.values.radius?.id}*/}
        {/*    items={radiusOptions}*/}
        {/*    className={styles.radiusInput}*/}
        {/*    setValue={setDropdownField}*/}
        {/*    theme="withoutShadow"*/}
        {/*    disabled={formik.values.location?.category !== translation['town']}*/}
        {/*  />*/}
        {/*</div>*/}
        {!mobile && !tablet ? (
          <div className={styles.searchButton}>
            <YellowButton text={cs.mainButton} onClick={formik.submitForm} />
          </div>
        ) : null}
      </div>
      <div className={styles.searchFilters}>
        <div
          className={classnames(styles.advancedFilters, {
            [styles.advancedFiltersOpen]: collapsed,
          })}
        >
          <Hr />
          <div className={styles.filters}>
            <div className={styles.filter}>
              <TypeAheadSearch
                name="occupations"
                value={formik.values.occupations}
                items={taxonomies.occupations.data}
                maxNumSelections={999}
                placeholder={cs.positionDropdown}
                label=""
                selectNonExisting
                setValue={setTypeAheadField}
                theme={mobile ? 'blue-left' : 'blue'}
                withChips={false}
                forceLabel
                disabled={filtersType === 'admin'}
              />
            </div>
            <div className={styles.filter}>
              <DropdownMultiple
                name="educationLevels"
                items={taxonomies.educationLevels.data}
                values={
                  formik.values.educationLevels
                    ? formik.values.educationLevels.map((e) => e.id)
                    : undefined
                }
                selectAll
                setValues={setMultiDropdown}
                theme={mobile ? 'blue-left' : 'blue'}
                emptyValueLabel={cs.degreeDropdown}
                forceLabel
                disabled={filtersType === 'admin'}
              />
            </div>
            <div className={styles.filter}>
              <DropdownMultiple
                name="languages"
                items={taxonomies.languages.data}
                values={
                  formik.values.languages
                    ? formik.values.languages.map((l) => l.id)
                    : undefined
                }
                selectAll
                setValues={setMultiDropdown}
                theme="blue"
                emptyValueLabel={cs.languageDropdown}
                forceLabel
                disabled={filtersType === 'admin'}
              />
            </div>
            <div className={styles.filter}>
              <DropdownMultiple
                name="languageLevels"
                items={taxonomies.languageLevels?.data || []}
                values={
                  formik.values.languageLevels
                    ? formik.values.languageLevels.map((l) => l.id)
                    : undefined
                }
                selectAll
                setValues={setMultiDropdown}
                theme="blue-left"
                emptyValueLabel={cs.languageLvLDropdown}
                forceLabel
                disabled={filtersType === 'admin'}
              />
            </div>
            <div className={styles.filter}>
              <DropdownMultiple
                name="skills"
                items={taxonomies.skills.data}
                values={
                  formik.values.skills
                    ? formik.values.skills.map((jc) => jc.id)
                    : undefined
                }
                selectAll
                setValues={setMultiDropdown}
                theme="blue"
                emptyValueLabel={cs.skillsDropdown}
                forceLabel
                disabled={filtersType === 'admin'}
              />
            </div>
            <div className={styles.filter}>
              <DropdownMultiple
                name="skillLevels"
                items={taxonomies.skillLevels.data}
                values={
                  formik.values.skillLevels
                    ? formik.values.skillLevels.map((jc) => jc.id)
                    : undefined
                }
                selectAll
                setValues={setMultiDropdown}
                theme={mobile ? 'blue-left' : 'blue'}
                emptyValueLabel={cs.skillsLvLDropdown}
                forceLabel
                disabled={filtersType === 'admin'}
              />
            </div>
            <div className={styles.filter}>
              <DropdownMultiple
                name="careerStatuses"
                items={taxonomies.jobSearchStatuses.data}
                values={
                  formik.values.careerStatuses
                    ? formik.values.careerStatuses.map((cl) => cl.id)
                    : undefined
                }
                selectAll
                setValues={setMultiDropdown}
                theme={tablet ? 'blue-left' : 'blue'}
                emptyValueLabel={cs.carierDropdown}
                forceLabel
                disabled={filtersType === 'admin'}
              />
            </div>
            <div className={styles.filter}>
              <DropdownMultiple
                name="careerLevels"
                items={taxonomies.careerLevels.data}
                values={
                  formik.values.careerLevels
                    ? formik.values.careerLevels.map((cl) => cl.id)
                    : undefined
                }
                selectAll
                setValues={setMultiDropdown}
                theme={mobile ? 'blue-left' : 'blue'}
                emptyValueLabel={cs.carierLvlDropdown}
                forceLabel
                disabled={filtersType === 'admin'}
              />
            </div>
            <div className={styles.filter}>
              <DropdownMultiple
                name="drivingLicenseCategories"
                items={taxonomies.drivingLicenseCategories.data}
                values={
                  formik.values.drivingLicenseCategories
                    ? formik.values.drivingLicenseCategories.map((cl) => cl.id)
                    : undefined
                }
                selectAll
                setValues={setMultiDropdown}
                theme="blue"
                emptyValueLabel={cs.dLDropdown}
                forceLabel
                disabled={filtersType === 'admin'}
              />
            </div>
            {isAdmin && (
              <div className={styles.filter}>
                <DropdownMultiple
                  name="mdrjb"
                  items={mdrjbTaxonomy}
                  values={
                    formik.values.mdrjb
                      ? formik.values.mdrjb.map((m) => m.id)
                      : undefined
                  }
                  selectAll
                  setValues={setMultiDropdown}
                  theme="blue-left"
                  emptyValueLabel={cs.mdrjbDropdown}
                  forceLabel
                  disabled={filtersType === 'admin'}
                />
              </div>
            )}
            {isAdmin && (
              <div className={styles.filter}>
                <DropdownTextInput
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  disabled={filtersType === 'employer'}
                  label="First name"
                  placeholder={cs.enterFirstName}
                />
              </div>
            )}
            {isAdmin && (
              <div className={styles.filter}>
                <DropdownTextInput
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  disabled={filtersType === 'employer'}
                  theme="left"
                  label="Last name"
                  placeholder={cs.enterLastName}
                />
              </div>
            )}
          </div>
        </div>
        <button
          className={classnames(styles.advancedCollapseButton, {
            [styles.advancedCollapseButtonOpen]: collapsed,
          })}
          onClick={() => setCollapsed(!collapsed)}
        >
          <FilterList />
          {collapsed
            ? translation[cs.minimizeSearch]
            : translation[cs.maximizeSearch]}
        </button>
        {!mobile && !tablet ? (
          <>
            <Hr className={styles.advancedHr} />
            <div className={styles.currentFilters}>
              <div className={styles.currentFiltersLabel}>
                <FormattedMessage id={cs.currentFilter} />
              </div>
              <div className={styles.currentFiltersChips}>
                {Object.keys(formik.values).map((key, i) => {
                  if (
                    [
                      'sortBy',
                      'filtersType',
                      'firstName',
                      'lastName',
                      'similarCandidateUserId',
                    ].includes(key)
                  )
                    return null
                  return formik.values[key] && formik.values[key].length ? (
                    formik.values[key].map(
                      (item: BaseTaxonomyType, index: number) => {
                        const handleDelete = () => {
                          formik.setFieldValue(
                            key,
                            formik.values[key].filter(
                              (i: BaseTaxonomyType) => i.id !== item.id
                            )
                          )
                        }
                        return (
                          <Chip
                            key={`${key}-${index}`}
                            label={
                              item.name === 'Immediately'
                                ? ImmediatelyChipTranslation[getLang()]
                                : item.translation
                            }
                            variant="outlined"
                            onDelete={handleDelete}
                            deleteIcon={<HighlightOff />}
                            className={classnames(styles.chip, {
                              [styles.chipYellow]: item.name === 'Immediately',
                            })}
                          />
                        )
                      }
                    )
                  ) : formik.values[key] &&
                    formik.values[key].length === undefined &&
                    formik.values[key] !== '' ? (
                    <Chip
                      key={`${key}-${i}`}
                      label={
                        formik.values[key].translation || formik.values[key]
                      }
                      variant="outlined"
                      onDelete={() => {
                        formik.setFieldValue(
                          key,
                          formik.values[key].translation ? null : ''
                        )
                        // if (key === 'location') {
                        //   formik.setFieldValue('radius', null)
                        // }
                      }}
                      deleteIcon={<HighlightOff />}
                      className={styles.chip}
                    />
                  ) : null
                })}
              </div>
              {!mobile && !tablet ? (
                <div className={styles.resetSearchButtonWrap}>
                  <button
                    className={styles.resetSearchButton}
                    onClick={() => {
                      formik.setValues(defaultFormikValues)
                      setResetTextInput(!resetTextInput)
                    }}
                  >
                    <Replay className={styles.resetSearchIcon} />
                    <span className={styles.resetSearchText}>
                      <FormattedMessage id={cs.clearForm} />
                    </span>
                  </button>
                </div>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
      {mobile || tablet ? (
        <div>
          {collapsed && <Hr className={styles.hr2} />}
          <div className={styles.resetMinMaxWrap}>
            <div className={styles.collapseWrap}>
              <button
                className={classnames(styles.advancedCollapseButton, {
                  [styles.advancedCollapseButtonOpen]: collapsed,
                })}
                onClick={() => setCollapsed(!collapsed)}
              >
                <FilterList />
                {collapsed
                  ? translation[cs.minimizeSearch]
                  : translation[cs.maximizeSearch]}
              </button>
            </div>
            <div className={styles.resetSearchButtonWrap}>
              <button
                className={styles.resetSearchButton}
                onClick={() => formik.setValues(defaultFormikValues)}
              >
                <Replay className={styles.resetSearchIcon} />
                <span className={styles.resetSearchText}>
                  <FormattedMessage id={cs.clearForm} />
                </span>
              </button>
            </div>
          </div>
        </div>
      ) : null}
      {mobile || tablet ? (
        <div
          className={classnames(styles.searchButton, styles.searchButtonMobile)}
        >
          <YellowButton
            text={cs.mainButton}
            onClick={formik.submitForm}
            className={styles.yellowButton}
          />
        </div>
      ) : null}
    </div>
  )
}
