import {FC, useEffect, useMemo} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {useFormik} from 'formik'

import {TextProps} from 'Utils/FormikProps'
import {
  AuthStateType,
  CbtAccountStatus,
  RjbProfileStatus,
  StateTypeOptional,
} from 'Interfaces'
import TextField from 'Components/Inputs/TextField'
import styles from './EmailVerification.module.scss'
import {Validation} from './Validation'
import LoginLayout from 'Layouts/LoginLayout'
import {Description, Title} from 'Components/Login'
import {AppRoute, EmailVerification as ev} from 'Services/I18n/Constants'
import YellowButton from 'Components/YellowButton'
import {useApiError} from 'Hooks'
import {getRoute} from 'Services/I18n/Utils'
import {getQuerySearchParam} from 'Utils/Routes'
import progress from 'Assets/Images/green-dots-loading.gif'
import Hr from 'Components/Hr'
import {hasGrantedMandatoryConsents} from 'Utils/Consents/Consents'

interface EmailVerificationProps {
  auth: AuthStateType
  emailVerify?: StateTypeOptional<string | undefined> & {success: boolean}
  verifyEmail: (token: string) => void
  hideEmailResentMessage: () => void
  getUser: () => void
  resendVerificationEmail: (email: string) => void
}

export const EmailVerification: FC<EmailVerificationProps> = ({
  auth,
  emailVerify,
  verifyEmail,
  getUser,
  resendVerificationEmail,
}) => {
  let location = useLocation()
  let history = useHistory()
  const token = getQuerySearchParam(location.search, 'token')

  useEffect(() => {
    if (!auth.dirty && !auth.data?.id) {
      getUser()
    }
  }, [emailVerify])

  useEffect(() => {
    if (auth.dirty && !auth.loading && !auth.data?.id && !token) {
      history.push(getRoute(AppRoute.SeekerLogin))
    }
  }, [auth, token])

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: Validation,
    onSubmit: (values) => {
      resendVerificationEmail(values.email)
    },
  })

  useEffect(() => {
    if (token) {
      verifyEmail(token)
    }
  }, [token])

  useEffect(() => {
    if (auth.data?.cbtAccountStatus !== CbtAccountStatus.UNVERIFIED) {
      const grantedConsents = hasGrantedMandatoryConsents(auth.data)
      if (!grantedConsents) {
        history.push(getRoute(AppRoute.SeekerLoginConsents))
      } else if (
        auth.data &&
        auth.data?.rjbProfileStatus === RjbProfileStatus.DRAFT &&
        (auth.data.cbtAccountStatus === CbtAccountStatus.ACTIVE ||
          auth.data.cbtAccountStatus === CbtAccountStatus.NEW)
      ) {
        history.push(getRoute(AppRoute.Onboarding))
      }
    }
  }, [auth.data])

  const pt = useMemo(() => {
    let title = ev.verifyYourAccount
    let description = ev.verifyYourAccountDescription

    if (emailVerify) {
      if (emailVerify.loading) {
        title = ev.pending
        description = ev.pleaseWaitText
      }
      if (emailVerify.error) {
        title = ev.verificationFailed
        description = ev.verificationFailedDescription
      }
    }
    if (auth.displayMessage.resendEmailVerification) {
      title = ev.emailResentTitle
      description = ev.emailResentDescription
    }
    return {
      title,
      description,
    }
  }, [token, emailVerify?.loading, location, auth.displayMessage])

  useApiError(formik.setFieldError, emailVerify?.error, 'email')

  return (
    <LoginLayout>
      <div className={styles.formWrap}>
        {auth.displayMessage.resendEmailVerification ? (
          <>
            <span className={styles.emailVerificationTitleWrap}>
              <Title text={pt.title} className={styles.emailVerificationTitle}>
                {auth.displayMessage.resendEmailVerification && (
                  <img
                    className={styles.loadingDots}
                    src={progress}
                    alt="progress"
                  />
                )}
              </Title>
            </span>
            <p className={styles.description}>
              <Description text={pt.description} />
            </p>
          </>
        ) : (
          <>
            <span className={styles.emailVerificationTitleWrap}>
              <Title text={pt.title} className={styles.emailVerificationTitle}>
                {emailVerify?.loading && (
                  <img
                    className={styles.loadingDots}
                    src={progress}
                    alt="progress"
                  />
                )}
              </Title>
            </span>
            <p className={styles.description}>
              <Description text={pt.description} />
            </p>
            <Hr className={styles.emailVerificationHr} />
            <form className={styles.form} onSubmit={formik.handleSubmit}>
              <div className={styles.inputWrap}>
                <TextField
                  name="email"
                  type="email"
                  label={ev.email}
                  theme="minWidth"
                  {...TextProps(formik, 'email')}
                />

                <YellowButton
                  className={styles.resendButton}
                  text={ev.verificationResend}
                />
              </div>
            </form>
          </>
        )}
      </div>
    </LoginLayout>
  )
}
