import Modal from 'Components/Modal'
import {useFormik} from 'formik'
import styles from './ModalSendOfferEmp.module.scss'
import AttachmentFileUpload from 'Components/Inputs/AttachmentFileUpload'
import TextArea from 'Components/Inputs/TextArea'
import {FC} from 'react'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import {FormattedMessage} from 'react-intl'
import {CandidateDashboardTranslations as cdt} from 'Services/I18n/Constants'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'
import {OnChangeProps} from 'Utils/FormikProps'
import Hr from 'Components/Hr'
import Title from 'Components/Title'
import {sendOfferModalEmpType} from 'Interfaces'
import {HistoryEdu} from '@mui/icons-material'

interface ModalSendOfferEmpProps {
  requestId: string
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  sendOffer: (payload: sendOfferModalEmpType) => void
  parentSelector?(): HTMLElement
}

export const ModalSendOfferEmp: FC<ModalSendOfferEmpProps> = ({
  requestId,
  sendOffer,
  isOpen,
  setIsOpen,
  parentSelector,
}) => {
  const formik = useFormik({
    initialValues: {
      file: {id: ''},
      content: '',
    },

    onSubmit: (values) => {
      sendOffer({
        requestId,
        attachmentId: values.file?.id || undefined,
        content: values.content,
      })
      setIsOpen(false)
      formik.resetForm()
    },
  })

  return (
    <>
      <Modal
        className={styles.modal}
        parentSelector={parentSelector}
        icon={HistoryEdu}
        isOpen={isOpen}
        title={edt.sendOffer}
        onAfterClose={() => {
          setIsOpen(false)
        }}
        shouldReturnFocusAfterClose={false}
        theme="desktopFlex"
        Footer={
          <>
            {
              <SubmitFooter
                onSubmit={formik.handleSubmit}
                onCancel={() => setIsOpen(false)}
                theme="modalProfileSubmit"
                submitText={edt.sendOffer}
              />
            }
          </>
        }
      >
        <Title text={edt.offerDescription} theme="modalRegular" />
        <TextArea
          name="content"
          {...OnChangeProps(formik, 'content')}
          theme="modal"
          className={styles.textArea}
          type={'letterCalculator'}
          maximumCharactersNumber={1000}
        />
        <Hr theme="modal" />
        <div className={styles.attachmentWrap}>
          <p className={styles.attachmentTitle}>
            <FormattedMessage id={cdt.attachment} />
          </p>
          <AttachmentFileUpload
            name="file"
            setValue={formik.setFieldValue}
            integratedUploads={false}
            accept=".doc,.docx,.pdf"
          />
        </div>
      </Modal>
    </>
  )
}
