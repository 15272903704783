import * as type from 'Store/types'
import {
  AssessmentsStateType,
  TrainingTitleType,
  Test,
  CognitiveTestResults,
  PsychometricResultType,
  PsychometricResultsStatementsType,
} from 'Interfaces/Assessments'
import {ActionType, ErrorType} from 'Interfaces'

const initialState: AssessmentsStateType = {
  data: {
    items: [],
  },
  cognitiveTestResults: {
    items: [
      {
        resultFile: {
          id: '',
          url: '',
          originalName: '',
        },
      },
    ],
  },
  psychometricTestResults: {
    resultFile: {id: '', url: '', originalName: ''},
    results: [],
  },
  psychometricResultsStatements: [],
  languageTests: [],
  skillTests: [],
  loading: true,
  error: undefined,
}

interface startTestType {
  url: string
  testId: string
}

interface TestDataType {
  items: Test[]
}

const AssessmentsReducer = (
  state = initialState,
  action: ActionType<
    | TrainingTitleType
    | string
    | ErrorType
    | undefined
    | startTestType
    | TestDataType
    | CognitiveTestResults
    | PsychometricResultType
    | TrainingTitleType[]
    | PsychometricResultsStatementsType[]
  >
): AssessmentsStateType => {
  switch (action.type) {
    case type.assessments.getSeekerAssessments.succeeded:
    case type.assessments.getAssessments.succeeded:
      return {
        ...state,
        data: action.payload as TestDataType,
        loading: false,
        error: undefined,
      }
    case type.assessments.startAssessment.succeeded:
      return {
        ...state,
        data: {
          ...state.data,
          items: state.data?.items?.map((test: Test) => {
            if (test.id !== (action.payload as startTestType)?.testId) {
              return test
            }

            test.status = 'results_pending'

            return test
          }),
        },
      }
    case type.assessments.getCognitiveTestResults.succeeded:
      return {
        ...state,
        cognitiveTestResults: action.payload as CognitiveTestResults,
        loading: false,
        error: undefined,
      }
    case type.assessments.getPsychometricTestResults.succeeded:
      return {
        ...state,
        psychometricTestResults: action.payload as PsychometricResultType,
        loading: false,
        error: undefined,
      }

    case type.assessments.getPsychometricResultsStatements.succeeded:
      return {
        ...state,
        psychometricResultsStatements:
          action.payload as PsychometricResultsStatementsType[],
        loading: false,
        error: undefined,
      }
    case type.assessments.addLanguageTest.succeeded:
      return {
        ...state,
        languageTests: [
          ...(state.languageTests as TrainingTitleType[]),
          ...[action.payload as TrainingTitleType],
        ],
        loading: false,
        error: undefined,
      }
    case type.assessments.editLanguageTest.succeeded:
      return {
        ...state,
        languageTests: state.languageTests.map((languageTest) => {
          if (languageTest.id === (action.payload as TrainingTitleType).id) {
            return {
              ...languageTest,
              ...(action.payload as TrainingTitleType),
            }
          } else {
            return languageTest
          }
        }),
        loading: false,
        error: undefined,
      }
    case type.assessments.deleteLanguageTest.succeeded:
      return {
        ...state,
        languageTests: state.languageTests.filter(
          (languageTest) => languageTest.id !== (action.payload as string)
        ),
        loading: false,
        error: undefined,
      }
    case type.assessments.getLanguageTests.succeeded:
      return {
        ...state,
        languageTests: action.payload as TrainingTitleType[],
        loading: false,
        error: undefined,
      }
    case type.assessments.addSkillTest.succeeded:
      return {
        ...state,
        skillTests: [
          ...(state.skillTests as TrainingTitleType[]),
          ...[action.payload as TrainingTitleType],
        ],
        loading: false,
        error: undefined,
      }
    case type.assessments.editSkillTest.succeeded:
      return {
        ...state,
        skillTests: state.skillTests.map((skillTest) => {
          if (skillTest.id === (action.payload as TrainingTitleType).id) {
            return {
              ...skillTest,
              ...(action.payload as TrainingTitleType),
            }
          } else {
            return skillTest
          }
        }),
        loading: false,
        error: undefined,
      }
    case type.assessments.deleteSkillTest.succeeded:
      return {
        ...state,
        skillTests: state.skillTests.filter(
          (skillTest) => skillTest.id !== (action.payload as string)
        ),
        loading: false,
        error: undefined,
      }
    case type.assessments.getSkillTests.succeeded:
      return {
        ...state,
        skillTests: action.payload as TrainingTitleType[],
        loading: false,
        error: undefined,
      }
    case type.assessments.changeCognitiveTestStatus.requested:
      return {
        ...state,
        data: {
          ...state.data,
          items: state.data?.items?.map((test: Test) => {
            if (test.id !== action.payload) {
              return test
            }

            test.status = 'results_pending'

            return test
          }),
        },
      }
    case type.assessments.addLanguageTest.requested:
    case type.assessments.editLanguageTest.requested:
    case type.assessments.deleteLanguageTest.requested:
    case type.assessments.addSkillTest.requested:
    case type.assessments.editSkillTest.requested:
    case type.assessments.deleteSkillTest.requested:
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case type.assessments.addLanguageTest.failed:
    case type.assessments.editLanguageTest.failed:
    case type.assessments.deleteLanguageTest.failed:
    case type.assessments.addSkillTest.failed:
    case type.assessments.editSkillTest.failed:
    case type.assessments.deleteSkillTest.failed:
      return {
        ...state,
        loading: false,
        error: action.payload as ErrorType,
      }
    default:
      return state
  }
}

export default AssessmentsReducer
