import {EmployerForgotPassword} from './EmployerForgotPassword'
import {connect} from 'react-redux'
import {forgotPassword, hideForgotPasswordMessage} from 'Store/Actions'
import {AppDispatch} from 'Store'
import {ForgotPasswordType} from 'Interfaces'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  forgotPassword: (data: ForgotPasswordType) => dispatch(forgotPassword(data)),
  hideForgotPasswordMessage: () => dispatch(hideForgotPasswordMessage()),
})

export const EmployerForgotPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerForgotPassword)
