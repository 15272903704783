import {connect} from 'react-redux'

import {TermsAndConditions} from './TermsAndConditions'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
})

export const TermsAndConditionsContainer = connect(
  mapStateToProps,
  null
)(TermsAndConditions)
