import {connect} from 'react-redux'

import {setSidebarClosed} from 'Store/Actions'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {RequestListItem} from './RequestListItem'

const mapStateToProps = (state: RootState) => {
  return {
    widgetBar: state.widgetBar?.data,
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setSidebarClosed: (sidebarClosed: boolean) =>
    dispatch(setSidebarClosed(sidebarClosed)),
})

export const RequestListItemContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RequestListItem)
