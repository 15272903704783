import {FC} from 'react'

import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {FormattedMessage, useIntl} from 'react-intl'
import {HomeTranslations as home} from 'Services/I18n/Constants'
import Breadcrumbs from 'Components/Breadcrumbs'
import {DownloadAndPrintHeader} from 'Components/DownloadAndPrintHeader'
import Signature from 'Assets/Images/signature.png'
import {DataProtectionAgreementTranslations as ppt} from 'Services/I18n/Constants'

import './DataProtectionAgreement.module.scss'

export const DataProtectionAgreement: FC = () => {
  const {formatMessage} = useIntl()

  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: getRoute(AppRoute.HomePageCandidate)},
    {name: home.dataProtectionAgreement},
  ]

  const dataProtectionAgreementHtml = formatMessage({
    id: ppt.dataProtectionAgreementHtml,
  }).replaceAll('#signature-image', Signature)

  return (
    <div className="dataProtectionAgreementContainer">
      <DownloadAndPrintHeader
        downloadLink={
          process.env.PUBLIC_URL +
          '/files/pogodba_o_obdelavi_osebnih_podatkov_01.02.2023.pdf'
        }
      />
      <h2 className="mainTitle">
        <FormattedMessage id={home.dataProtectionAgreement} />
      </h2>
      <div className="breadcrumbsWrapper">
        <Breadcrumbs breadcrumbs={breadcrumbsArray} />
      </div>
      <div
        dangerouslySetInnerHTML={{
          __html: dataProtectionAgreementHtml,
        }}
      />
    </div>
  )
}
