export const generalAssessmentsTranslation = {
  'generalassessmentstranslations.title': 'Moja testiranja',
  'generalassessmentstranslations.personalityTestTitle': 'Osebnostni testi',
  'generalassessmentstranslations.languageTestTitle': 'Jezikovni testi',
  'generalassessmentstranslations.skillsTestTitle': 'Testi znanj in spretnosti',
  'generalassessmentstranslations.testTypeLabel': 'VRSTA TESTA',
  'generalassessmentstranslations.testProviderLabel': 'PONUDNIK',
  'generalassessmentstranslations.testDateLabel': 'DATUM TESTA',
  'generalassessmentstranslations.testExpirationDateLabel':
    'DATUM POTEKA VELJAVNOSTI',
  'generalassessmentstranslations.testInfoLabel': 'INFO',
  'generalassessmentstranslations.testActionLabel': 'AKCIJA',
  'generalassessmentstranslations.startTestText': 'Začnite test',
  'generalassessmentstranslations.viewResultsText': 'Ogled rezultatov',
  'generalassessmentstranslations.assessments': 'Testiranja',
  'generalassessmentstranslations.pendingButtonText': 'V teku',
  'generalassessmentstranslations.termsOfUse': 'Pogoji uporabe',
  'generalassessmentstranslations.privacyPolicy': 'Politika zasebnosti',
  'generalassessmentstranslations.cookies': 'Piškotki',
  'generalassessmentstranslations.authors': 'Avtorji',
  'generalassessmentstranslations.assessmentsSuccess': 'Testiranje zaključeno',
  'generalassessmentstranslations.assessmentsSuccessMessage':
    'Psihometrično testiranje ste uspešno dokončali. Ko bodo rezultati testa na voljo, vas bomo o tem obvestili preko elektronske pošte.',
  'generalassessmentstranslations.tests': 'testi',
  'generalassessmentstranslations.personality': 'Osebnostni',
  'generalassessmentstranslations.language': 'Jezikovni',
  'generalassessmentstranslations.assessmentConsent':
    'Soglasje za testiranje kognitivnih sposobnosti',
  'generalassessmentstranslations.unlimited': 'Neomejeno',
  'generalassessmentstranslations.download': 'Prenesi',
  'generalassessmentstranslations.print': 'Natisni',
  'generalassessmentstranslations.resultsValidUntil':
    'Rezultati so veljavni do',
  'generalassessmentstranslations.backToAssessments':
    'Nazaj na Moja testiranja',
  'generalassessmentstranslations.generalProfile': 'Splošni profil',
}
