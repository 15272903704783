import {FC} from 'react'
import styles from './HTMLText.module.scss'

interface HTMLTextProps {
  htmlInputValue?: string
}

export const HTMLText: FC<HTMLTextProps> = ({htmlInputValue}) => {
  return htmlInputValue ? (
    <div
      className={styles.container}
      dangerouslySetInnerHTML={{
        __html: htmlInputValue,
      }}
    />
  ) : null
}
