import {useEffect} from 'react'

export const useWindowResize = (
  callback: (clientWidth?: number) => void,
  breakpoints?: string[]
): void => {
  useEffect(() => {
    const handleResize = () => {
      callback(document.body.clientWidth)
    }
    window.addEventListener('resize', handleResize)
    if (breakpoints && breakpoints.length) {
      breakpoints.forEach((breakpoint) => {
        switch (breakpoint) {
          case 'mobile':
            if (document.body.clientWidth < 768) handleResize()
            break
          case 'tablet':
            if (
              document.body.clientWidth > 767 &&
              document.body.clientWidth < 1024
            )
              handleResize()
            break
          case 'desktop':
          default:
            if (document.body.clientWidth > 1024) handleResize()
            break
        }
      })
    } else handleResize()
    return () => window.removeEventListener('resize', handleResize)
  }, [callback])
}
