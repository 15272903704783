export const companyWelcomeMessage = {
  'companyWelcomeMessage.modalTitle': 'Welcome Address',
  'companyWelcomeMessage.add': 'Add',
  'companyWelcomeMessage.edit': 'Edit',

  'companyWelcomeMessage.languageTitle': 'Language',

  'companyWelcomeMessage.personaTitle': 'Persona',
  'companyWelcomeMessage.addPersonaText': 'Add persona',

  'companyWelcomeMessage.welcomeTitleTitle': 'Welcome Title',
  'companyWelcomeMessage.welcomeTitlePlaceholderText':
    'Enter welcome title ...',

  'companyWelcomeMessage.welcomeTextTitle': 'Welcome Text',
  'companyWelcomeMessage.welcomeTextPlaceholderText': 'Enter welcome text',

  'companyWelcomeMessage.deleteButtonText': 'Delete',
}
