import {FC} from 'react'
import {FormattedMessage} from 'react-intl'

interface TextProps {
  children?: string
  text?: string
}

export const Translation: FC<TextProps> = ({children, text}) => {
  return children || text ? <FormattedMessage id={children || text} /> : null
}
