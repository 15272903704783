import {FC, useEffect, useMemo, useState} from 'react'

import RateCandidate from 'Components/Widgets/RateCandidateSearch'
import Hr from 'Components/Hr'
import AboutCandidate from 'Components/CandidateSearch/AboutCandidate'
import {LinksContainer} from 'Components/LinksContainer'
import {
  AppRoute,
  CandidateSearchTranslations as cs,
  EmployerDashboardTranslations as edt,
  SidebarTranslations as st,
} from 'Services/I18n/Constants'
import VideocamIcon from '@mui/icons-material/Videocam'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import CircularProgressBar from 'Components/Widgets/CircularProgressBar'
import Comments from 'Components/Widgets/Comments'
import CommentsList from 'Components/Widgets/CommentsList'
import {Candidate, SendRequestContactType} from 'Interfaces/CandidateType'

import styles from './CandidateSearchSidebarContent.module.scss'
import {
  CommentType,
  ConversationMessageType,
  ConversationType,
  HistoryType,
  ImageType,
  IncomingRequestSeekerType,
  IncomingRequestType,
  LinkType,
} from 'Interfaces'
import {getRoute} from 'Services/I18n/Utils'
import {MessagesListContainer} from 'Components/Widgets/MessagesList'
import ModalSendMessageEmp from 'Components/EmployerDashboard/InterviewInvitationListItem/ModalSendMessageEmp'
import ModalSendOfferEmp from 'Components/EmployerDashboard/InterviewInvitationListItem/ModalSendOfferEmp/index'
import {HistoryEdu} from '@mui/icons-material'
import classnames from 'classnames'
import {Avatar} from 'Components/Widgets/Avatar'
import {SidebarLink} from 'Components/SidebarLink'
import {useTranslation} from 'Hooks'
import {useParams} from 'react-router-dom'

interface CandidateSearchSidebarContentProps {
  candidateBasicData: Candidate | IncomingRequestSeekerType | null
  history: HistoryType[]
  comments: {items: CommentType[]}
  getCandidateHistory: (id: string) => void
  getCandidateComments: (id: string) => void
  getConversationByUserIds: (query: string) => void
  openedConversation: ConversationType
  selectConversation: (conversation?: ConversationType) => void
  messages?: ConversationMessageType[]
  commentFocusTrigger?: number
  request?: IncomingRequestType | null
  isRecruiter?: boolean
  isFromCandidateProfile?: boolean
  isFull?: boolean
  sendRequestContact: (value: SendRequestContactType) => void
  hasContactRequest?: boolean
  isFromSearch?: boolean
}

export const CandidateSearchSidebarContent: FC<
  CandidateSearchSidebarContentProps
> = ({
  candidateBasicData,
  comments,
  getCandidateComments,
  // history,
  // getCandidateHistory,
  openedConversation,
  getConversationByUserIds,
  selectConversation,
  messages,
  commentFocusTrigger,
  isRecruiter = false,
  isFromCandidateProfile = false,
  isFull,
  sendRequestContact,
  hasContactRequest = false,
  isFromSearch = false,
}) => {
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false)
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false)
  const [removedContactRequestCta, setRemovedContactRequestCta] =
    useState(false)

  let {id} = useParams<{id: string}>()
  let candidateId = isFromSearch
    ? candidateBasicData?.userId
    : isFromCandidateProfile
    ? id
    : candidateBasicData?.id
  const linksWithoutSendMessage: LinkType[] = useMemo(
    () => [
      {
        name: edt.invite,
        route: `${getRoute(AppRoute.InviteCalendar).replace(
          ':candidateId',
          candidateId as string
        )}`,
        icon: !isFromCandidateProfile && <VideocamIcon />,
      },
      {
        name: edt.sendOffer,
        onClick: () => setIsOfferModalOpen(true),
        icon: !isFromCandidateProfile && <HistoryEdu />,
      },
      {
        name: st.viewCandidatesAssessments,
        route: `${getRoute(AppRoute.PublicAssessments).replace(
          ':id',
          candidateId as string
        )}`,
      },
    ],
    [candidateBasicData]
  )
  const links: LinkType[] = useMemo(
    () => [
      {
        name: edt.sendMessage,
        onClick: () => setIsMessageModalOpen(true),
        icon: !isFromCandidateProfile && <MailOutlineIcon />,
      },
      {
        name: edt.invite,
        route: `${getRoute(AppRoute.InviteCalendar).replace(
          ':candidateId',
          candidateId as string
        )}`,
        icon: !isFromCandidateProfile && <VideocamIcon />,
      },
      {
        name: edt.sendOffer,
        onClick: () => setIsOfferModalOpen(true),
        icon: !isFromCandidateProfile && <HistoryEdu />,
      },
      {
        name: st.viewCandidatesAssessments,
        route: `${getRoute(AppRoute.PublicAssessments).replace(
          ':id',
          candidateId as string
        )}`,
      },

      // TODO employer-dashboard: Add when bookmarking gets implemented
      // {
      //   name: 'Bookmark',
      //   route: AppRoute.Profile,
      //   icon: <BookmarkBorderIcon />,
      // },
    ],
    [candidateBasicData]
  )

  useEffect(() => {
    if (candidateBasicData && candidateBasicData!.id) {
      getCandidateComments(candidateBasicData?.userId || candidateBasicData.id)

      isFromCandidateProfile ||
        (isFromSearch &&
          getConversationByUserIds(
            `userIds=${
              id || candidateBasicData?.userId || candidateBasicData?.id
            }`
          ))
      // getCandidateHistory(candidateBasicData!.id)
    }
  }, [candidateBasicData, isFromCandidateProfile, isFromSearch])

  useEffect(() => {
    if ((isFromCandidateProfile || isFromSearch) && openedConversation)
      selectConversation(openedConversation)
  }, [openedConversation])

  if (!candidateBasicData) return null

  const {firstName, lastName, seekerEvaluations, contactRequest} =
    candidateBasicData

  const isAccepted =
    contactRequest?.status === 'approved' || isRecruiter || isFull

  const companySeekerJourneyId = candidateBasicData?.companySeekerJourneys
    ? candidateBasicData?.companySeekerJourneys[0]?.id
    : candidateBasicData?.companySeekerJourney?.id &&
      candidateBasicData?.companySeekerJourney?.id

  const translation = useTranslation()

  useEffect(() => {
    if (candidateBasicData?.companySeekerJourney?.contactRequest?.status) {
      setRemovedContactRequestCta(true)
    }
  }, [candidateBasicData?.companySeekerJourney?.contactRequest?.status])

  const sideBar = useMemo(() => {
    if (candidateBasicData) {
      return (
        <div className={styles.sidebar}>
          {isAccepted ? (
            <>
              <div className={styles.avatarImageContainer}>
                <div className={styles.hrWrapper}>
                  <Hr className={styles.darkGrayHr} />
                </div>
                {isFromSearch ? (
                  <CircularProgressBar
                    strokeWidth={5}
                    sqSize={145}
                    percentage={
                      candidateBasicData.relevance
                        ? parseInt(`${candidateBasicData.relevance}`)
                        : 0
                    }
                    image={candidateBasicData?.profilePicture}
                    firstName={candidateBasicData.firstName}
                    lastName={candidateBasicData.lastName}
                  />
                ) : (
                  <Avatar
                    firstName={candidateBasicData.firstName as string}
                    lastName={candidateBasicData.lastName as string}
                    image={candidateBasicData?.profilePicture as ImageType}
                  />
                )}

                <div className={styles.hrWrapper}>
                  <Hr className={styles.darkGrayHr} />
                </div>
              </div>
            </>
          ) : (
            <div
              className={classnames({
                [styles.hrWrapp]: !isFromCandidateProfile,
              })}
            >
              {(candidateBasicData?.publicOccupation ||
                candidateBasicData?.town ||
                candidateBasicData?.country) && <Hr />}
            </div>
          )}
          {!candidateBasicData?.town?.translation ? (
            <div className={styles.aboutCandidate}>
              <AboutCandidate
                name={firstName && lastName ? firstName + ' ' + lastName : ''}
                proffession={
                  candidateBasicData?.publicOccupation?.translation || ''
                }
                town={
                  candidateBasicData?.town?.translation ||
                  candidateBasicData?.homeTown?.translation ||
                  ''
                }
                country={
                  candidateBasicData?.country?.translation ||
                  candidateBasicData?.homeCountry?.translation ||
                  ''
                }
                isAccepted={isAccepted}
              />
            </div>
          ) : (
            <AboutCandidate
              name={firstName && lastName ? firstName + ' ' + lastName : ''}
              proffession={
                candidateBasicData?.publicOccupation?.translation || ''
              }
              town={
                candidateBasicData?.town?.translation ||
                candidateBasicData?.homeTown?.translation ||
                ''
              }
              country={
                candidateBasicData?.country?.translation ||
                candidateBasicData?.homeCountry?.translation ||
                ''
              }
              isAccepted={isAccepted}
            />
          )}

          <div className={styles.hrWrapper}>
            <Hr className={styles.darkGrayHr} />
          </div>
          <RateCandidate seekerEvaluation={seekerEvaluations} />
          {isAccepted && (
            <>
              <div className={styles.hrWrapper}>
                <Hr className={styles.darkGrayHr} />
              </div>
              <LinksContainer
                links={
                  isFromCandidateProfile || isFromSearch
                    ? links
                    : linksWithoutSendMessage
                }
                hash
              />
            </>
          )}

          <div className={styles.hrWrapper}>
            <Hr className={styles.darkGrayHr} />
          </div>

          {contactRequest?.status !== 'approved' &&
            contactRequest?.status !== 'pending' &&
            !removedContactRequestCta &&
            !hasContactRequest && (
              <>
                <SidebarLink
                  text={cs.sendContactRequest}
                  onClick={() => {
                    sendRequestContact({
                      id: candidateBasicData.userId || id,
                      snackbar: {
                        message: translation[cs.contactRequestSentButton],
                        duration: 1500,
                      },
                    })
                  }}
                  withMarginBottom={false}
                />
                <div className={styles.hrWrapper}>
                  <Hr className={styles.darkGrayHr} />
                </div>
              </>
            )}

          <Comments page="search" focusTrigger={commentFocusTrigger} />
          <CommentsList items={comments.items} />
          {isAccepted && candidateBasicData.firstName && (
            <MessagesListContainer items={messages && messages} />
          )}
          {isAccepted && (
            <ModalSendMessageEmp
              request={
                candidateBasicData as Candidate | IncomingRequestSeekerType
              }
              isOpen={isMessageModalOpen}
              setIsOpen={setIsMessageModalOpen}
              parentSelector={() =>
                document.querySelector(
                  `.${styles.avatarImageContainer}`
                ) as HTMLElement
              }
            />
          )}

          {isAccepted && companySeekerJourneyId && (
            <ModalSendOfferEmp
              isOpen={isOfferModalOpen}
              setIsOpen={setIsOfferModalOpen}
              requestId={companySeekerJourneyId}
              parentSelector={() =>
                document.querySelector(
                  `.${styles.avatarImageContainer}`
                ) as HTMLElement
              }
            />
          )}

          {/*
      <HistoryList items={history} /> */}
        </div>
      )
    } else return <div />
  }, [
    candidateBasicData,
    hasContactRequest,
    removedContactRequestCta,
    isMessageModalOpen,
    isOfferModalOpen,
    comments,
    messages,
  ])
  return sideBar
}
