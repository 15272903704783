export enum HomeTranslations {
  headerLogo = 'home.headerLogo',
  headerLink1 = 'home.headerLink1',
  headerLink2 = 'home.headerLink2',
  headerLink3 = 'home.headerLink3',
  headerLink4 = 'home.headerLink4',
  headerLink5 = 'home.headerLink5',
  title_1 = 'home.title_1',
  title_2 = 'home.title_2',
  title_3 = 'home.title_3',
  paragraph = 'home.paragraph',
  btnCreate = 'home.btnCreate',
  btnMore = 'home.btnMore',
  companiesTitle_1 = 'home.companiesTitle_1',
  companiesTitle_2 = 'home.companiesTitle_2',
  question_1 = 'home.question_1',
  question_2 = 'home.question_2',
  answer1 = 'home.answer1',
  answer2 = 'home.answer2',
  answer3 = 'home.answer3',
  answer4 = 'home.answer4',
  testemonialsTitle_1 = 'home.testemonialsTitle_1',
  testemonialsTitle_2 = 'home.testemonialsTitle_2',
  testemonialsTitle_3 = 'home.testemonialsTitle_3',
  ctaTitle1_1 = 'home.ctaTitle1_1',
  ctaTitle1_2 = 'home.ctaTitle1_2',
  ctaParagraph1 = 'home.ctaParagraph1',
  ctaParagraph = 'home.ctaParagraph',
  cta1 = 'home.cta1',
  ctaTitle2_1 = 'home.ctaTitle2_1',
  ctaTitle2_2 = 'home.ctaTitle2_2',
  cta2 = 'home.cta2',
  footerLink1 = 'home.footerLink1',
  footerLink2 = 'home.footerLink2',
  footerLink3 = 'home.footerLink3',
  aboutUsLink = 'home.aboutUsLink',
  footerLink5 = 'home.footerLink5',
  termsAndConditionsLink = 'home.termsAndConditionsLink',
  generalTermsAndConditionsLink = 'home.generalTermsAndConditionsLink',
  privacyPolicyLink = 'home.privacyPolicyLink',
  cookiesLink = 'home.cookiesLink',
  cookiesFooterLink = 'home.cookiesFooterLink',
  footerLink9 = 'home.footerLink9',
  dataProtectionAgreement = 'home.dataProtectionAgreement',
}
