import {useEffect} from 'react'

import {AuthStateType} from '../../Interfaces'
import Layout from 'Layouts/NoAuth'
import MainLayout from 'Layouts/MainLayout'
import {SidebarLink} from 'Components/SidebarLink'
import {
  AppRoute,
  HomeTranslations as home,
  SidebarTranslations as st,
} from 'Services/I18n/Constants'

export interface ProtectedRouteProps {
  auth?: AuthStateType
  component: any
  getUser: () => void
  theme?: string
  withoutLogo?: boolean
  sidebarLinks?: boolean
}

export const ProtectedRouteComponent = ({
  auth,
  component: Component,
  getUser,
  theme,
  withoutLogo,
  sidebarLinks,
}: ProtectedRouteProps): JSX.Element => {
  useEffect(() => {
    if (!auth?.data?.id) {
      getUser()
    }
  }, [])

  const consentsPagesSidebarLinks = () => {
    return (
      <div>
        <SidebarLink
          routeName={AppRoute.DataProtectionAgreement}
          text={home.dataProtectionAgreement}
        />
        <SidebarLink
          routeName={AppRoute.PrivacyPolicy}
          text={st.privacyPolicy}
        />
        <SidebarLink
          routeName={AppRoute.TermsAndConditions}
          text={home.termsAndConditionsLink}
        />
      </div>
    )
  }

  return (
    <>
      {auth?.data ? (
        <MainLayout
          theme={theme}
          withoutLogo={withoutLogo}
          content={sidebarLinks && consentsPagesSidebarLinks()}
        >
          <Component />
        </MainLayout>
      ) : (
        <Layout>
          <Component />
        </Layout>
      )}
    </>
  )
}
