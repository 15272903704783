import {
  ActionType,
  CandidateDropdownOptionType,
  EmployerActiveRequests,
  EmployerProfileType,
  EmployerCandidateProfileDataType,
  EmployerRequestsResponseType,
  EmployerRequestsStateType,
  EmployerStateType,
  ErrorType,
  GetOffersType,
  IncomingRequestType,
  ReviewCandidateDataType,
  DashboardSeekerEvaluationType,
} from 'Interfaces'
import * as type from '../types'

const requestsInitialState: EmployerRequestsStateType = {
  searchResults: [],
  candidatesOptions: [],
  loading: false,
  error: undefined,
  loadMore: undefined,
  loadMoreProgress: false,
  params: {},
  total: undefined,
}

const initialState: EmployerStateType = {
  // TODO employer-dashboard: Remove data
  data: {
    allRequests: {
      allRequests: requestsInitialState,
      contactRequests: requestsInitialState,
      interviewInvitations: requestsInitialState,
      offers: requestsInitialState,
      placements: requestsInitialState,
      placementConfirmedOffers: requestsInitialState,
    },
    candidate: {
      data: {},
      loading: false,
      error: undefined,
    },
  },
  loading: false,
  error: undefined,
  reviewData: {seekerId: '', name: '', date: '', interviewedBy: ''},
  fullData: undefined,
}

const EmployerReducer = (
  state: EmployerStateType = initialState,
  action: ActionType<
    | ReviewCandidateDataType
    | EmployerRequestsResponseType
    | EmployerActiveRequests
    | IncomingRequestType[]
    | IncomingRequestType
    | DashboardSeekerEvaluationType
    | CandidateDropdownOptionType[]
    | ErrorType
    | undefined
    | GetOffersType
    | EmployerProfileType
  >
): EmployerStateType => {
  switch (action.type) {
    case type.employer.reviewData.get.requested:
    case type.employer.personalData.get.requested:
    case type.employer.personalData.save.requested:
      return {
        ...state,
        loading: true,
        error: undefined,
      }

    case type.employer.reviewData.get.succeeded:
      return {
        ...state,
        reviewData: action.payload as ReviewCandidateDataType,
        loading: false,
        error: undefined,
      }
    case type.employer.allRequests.latest.requested:
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            allRequests: {
              ...state.data.allRequests.allRequests,
              loading: true,
            },
          },
        },
      }
    case type.employer.allRequests.latest.succeeded:
      const allRequestsPayload = action.payload as EmployerRequestsResponseType
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            allRequests: {
              ...state.data.allRequests.allRequests,
              searchResults: allRequestsPayload.searchResults,
              candidatesOptions: [],
              loadMore: allRequestsPayload.loadMore,
              loading: false,
            },
          },
        },
      }

    // CONTACT REQUESTS:
    case type.employer.allRequests.contactRequests.requested:
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            contactRequests: {
              ...state.data.allRequests.contactRequests,
              loadMore: undefined,
              loading: true,
            },
          },
        },
      }
    case type.employer.allRequests.contactRequests.succeeded:
      const contactRequestsPayload =
        action.payload as EmployerRequestsResponseType
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            contactRequests: {
              ...state.data.allRequests.contactRequests,
              searchResults:
                contactRequestsPayload.searchResults as IncomingRequestType[],
              loadMore: contactRequestsPayload.loadMore,
              loading: false,
              params: contactRequestsPayload.params,
              total: contactRequestsPayload.total,
            },
          },
        },
      }
    case type.employer.allRequests.contactRequests.loadmore.succeeded:
      const contactRequestsLoadMorePayload =
        action.payload as EmployerRequestsResponseType
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            contactRequests: {
              ...state.data.allRequests.contactRequests,
              searchResults: [
                ...state.data.allRequests.contactRequests.searchResults,
                ...(contactRequestsLoadMorePayload.searchResults as IncomingRequestType[]),
              ],
              loadMore: contactRequestsLoadMorePayload.loadMore,
              loadMoreProgress: false,
            },
          },
        },
      }
    case type.employer.allRequests.interviewInvitations.loadmore.succeeded:
      const interviewRequestsLoadMorePayload =
        action.payload as EmployerRequestsResponseType
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            interviewInvitations: {
              ...state.data.allRequests.interviewInvitations,
              searchResults: [
                ...state.data.allRequests.interviewInvitations.searchResults,
                ...interviewRequestsLoadMorePayload.searchResults,
              ],
              loadMore: interviewRequestsLoadMorePayload.loadMore,
              loadMoreProgress: false,
            },
          },
        },
      }
    case type.employer.seekersFrom.contactRequests.succeeded:
      const seekersFromContactRequests =
        action.payload as CandidateDropdownOptionType[]
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            contactRequests: {
              ...state.data.allRequests.contactRequests,
              candidatesOptions: seekersFromContactRequests,
            },
          },
        },
      }
    case type.employer.allRequests.contactRequests.loadmore.requested:
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            contactRequests: {
              ...state.data.allRequests.contactRequests,
              loadMoreProgress: true,
            },
          },
        },
      }
    case type.employer.allRequests.interviewInvitations.loadmore.requested:
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            interviewInvitations: {
              ...state.data.allRequests.interviewInvitations,
              loadMoreProgress: true,
            },
          },
        },
      }
    case type.employer.personalData.get.succeeded:
    case type.employer.personalData.save.succeeded:
      return {
        ...state,
        fullData: action.payload as EmployerProfileType,
        loading: false,
        error: undefined,
      }
    case type.employer.allRequests.contactRequests.failed:
    case type.employer.allRequests.contactRequests.loadmore.failed:
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            contactRequests: {
              ...state.data.allRequests.contactRequests,
              loadMoreProgress: false,
              error: action.payload as ErrorType,
            },
          },
        },
      }

    case type.employer.allRequests.interviewInvitations.requested:
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            interviewInvitations: {
              ...state.data.allRequests.interviewInvitations,
              loadMore: undefined,
              loading: true,
            },
          },
        },
      }
    case type.employer.allRequests.interviewInvitations.succeeded:
      const interviewInvitationsPayload =
        action.payload as EmployerRequestsResponseType
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            interviewInvitations: {
              ...state.data.allRequests.interviewInvitations,
              searchResults:
                interviewInvitationsPayload.searchResults as IncomingRequestType[],
              loadMore: interviewInvitationsPayload.loadMore,
              loading: false,
              params: interviewInvitationsPayload.params,
              total: interviewInvitationsPayload.total,
              loadMoreProgress: false,
            },
          },
        },
      }
    case type.employer.allRequests.interviewInvitations
      .interviewInvitationsCancel.succeeded:
      const cancelInterviewPayload = action.payload as IncomingRequestType
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            allRequests: {
              ...state.data.allRequests.allRequests,
              searchResults:
                state.data.allRequests.allRequests.searchResults.map(
                  (result) => {
                    if (result.id === cancelInterviewPayload.id) {
                      return {
                        ...result,
                        status: cancelInterviewPayload.status,
                        companyUserReason:
                          cancelInterviewPayload.companyUserReason,
                      }
                    } else {
                      return result
                    }
                  }
                ),
            },
          },
        },
      }
    case type.employer.seekersFrom.interviewInvitations.succeeded:
      const seekersFromInterviewRequests =
        action.payload as CandidateDropdownOptionType[]
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            interviewInvitations: {
              ...state.data.allRequests.interviewInvitations,
              candidatesOptions: seekersFromInterviewRequests,
            },
          },
        },
      }
    case type.employer.allRequests.placements.requested:
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            placements: {
              ...state.data.allRequests.placements,
              loading: true,
            },
          },
        },
      }
    case type.employer.allRequests.placements.succeeded:
      const placementsPayload = action.payload as EmployerRequestsResponseType
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            placements: {
              ...state.data.allRequests.placements,
              searchResults:
                placementsPayload.searchResults as IncomingRequestType[],
              loadMore: placementsPayload.loadMore,
              loading: false,
              params: placementsPayload.params,
              total: placementsPayload.total,
            },
          },
        },
      }
    case type.employer.seekersFrom.placements.succeeded:
      const seekersFromPlacements =
        action.payload as CandidateDropdownOptionType[]
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            placements: {
              ...state.data.allRequests.placements,
              candidatesOptions: seekersFromPlacements,
            },
          },
        },
      }
    case type.employer.allRequests.placements.loadmore.requested:
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            placements: {
              ...state.data.allRequests.placements,
              loadMoreProgress: true,
            },
          },
        },
      }
    case type.employer.allRequests.placements.loadmore.succeeded:
      const morePlacementsPayload =
        action.payload as EmployerRequestsResponseType
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            placements: {
              ...state.data.allRequests.placements,
              searchResults: [
                ...state.data.allRequests.placements.searchResults,
                ...(morePlacementsPayload.searchResults as IncomingRequestType[]),
              ],
              loadMoreProgress: false,
              loadMore: morePlacementsPayload.loadMore,
            },
          },
        },
      }
    case type.employer.allRequests.placements.failed:
    case type.employer.allRequests.placements.loadmore.failed:
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            placements: {
              ...state.data.allRequests.placements,
              loadMoreProgress: false,
              error: action.payload as ErrorType,
            },
          },
        },
      }

    case type.employer.allRequests.placements.requested:
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            placements: {
              ...state.data.allRequests.placements,
              loading: true,
            },
          },
        },
      }
    case type.employer.allRequests.offers.requested:
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            offers: {
              ...state.data.allRequests.offers,
              loading: true,
            },
          },
        },
      }
    case type.employer.allRequests.offers.succeeded:
      const offersPayload = action.payload as EmployerRequestsResponseType
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            offers: {
              ...state.data.allRequests.offers,
              searchResults:
                offersPayload.searchResults as IncomingRequestType[],
              loadMore: offersPayload.loadMore,
              loading: false,
              params: offersPayload.params,
              total: offersPayload.total,
            },
          },
        },
      }
    case type.employer.allRequests.offers.sendOffer.succeeded:
      const sendOfferPayload = action.payload as IncomingRequestType
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            allRequests: {
              ...state.data.allRequests.allRequests,
              searchResults:
                state.data.allRequests.allRequests.searchResults.map(
                  (result) => {
                    if (result.journeyId === sendOfferPayload.id) {
                      return {
                        ...result,
                        companySeekerJourneyOfferRequest: {
                          id: sendOfferPayload.id,
                          status: sendOfferPayload.status,
                        },
                      }
                    } else {
                      return result
                    }
                  }
                ),
            },
            interviewInvitations: {
              ...state.data.allRequests.interviewInvitations,
              searchResults:
                state.data.allRequests.interviewInvitations.searchResults.map(
                  (result) => {
                    if (result.journeyId === sendOfferPayload.id) {
                      return {
                        ...result,
                        companySeekerJourneyOfferRequest: {
                          id: sendOfferPayload.id,
                          status: sendOfferPayload.status,
                        },
                      }
                    } else {
                      return result
                    }
                  }
                ),
            },
          },
        },
      }
    case type.employer.seekersFrom.offers.succeeded:
      const seekersFromOffers = action.payload as CandidateDropdownOptionType[]
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            offers: {
              ...state.data.allRequests.offers,
              candidatesOptions: seekersFromOffers,
            },
          },
        },
      }
    case type.employer.allRequests.offers.loadmore.requested:
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            offers: {
              ...state.data.allRequests.offers,
              loadMoreProgress: true,
            },
          },
        },
      }
    case type.employer.allRequests.offers.loadmore.succeeded:
      const moreOffersPayload = action.payload as EmployerRequestsResponseType
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            offers: {
              ...state.data.allRequests.offers,
              searchResults: [
                ...state.data.allRequests.offers.searchResults,
                ...(moreOffersPayload.searchResults as IncomingRequestType[]),
              ],
              loadMore: moreOffersPayload.loadMore,
              loadMoreProgress: false,
            },
          },
        },
      }
    case type.employer.allRequests.offers.failed:
    case type.employer.allRequests.offers.loadmore.failed:
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            offers: {
              ...state.data.allRequests.offers,
              loadMoreProgress: false,
              error: action.payload as ErrorType,
            },
          },
        },
      }
    case type.employer.allRequests.placementConfirmedOffers.succeeded:
      const placementConfirmedOffersPayload = action.payload as GetOffersType
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            placementConfirmedOffers: {
              ...state.data.allRequests.placementConfirmedOffers,
              searchResults: placementConfirmedOffersPayload.data.items,
            },
          },
        },
      }
    case type.employer.activeRequests.succeeded:
      return {
        data: {
          ...state.data,
          activeRequests: action.payload as EmployerActiveRequests,
        },
        loading: false,
        error: undefined,
      }
    case type.employer.allRequests.placements.confirmed.succeeded:
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            placements: {
              ...state.data.allRequests.placements,
              searchResults:
                state.data.allRequests.placements.searchResults.map((item) =>
                  item.id === (action.payload as IncomingRequestType).id
                    ? {
                        ...item,
                        ...(action.payload as IncomingRequestType),
                      }
                    : item
                ),
            },
            allRequests: {
              ...state.data.allRequests.allRequests,
              searchResults:
                state.data.allRequests.allRequests.searchResults.map((item) =>
                  item.id === (action.payload as IncomingRequestType).id
                    ? {
                        ...item,
                        ...(action.payload as IncomingRequestType),
                      }
                    : item
                ),
            },
          },
        },
      }

    case type.employer.allRequests.rateCandidate.add.succeeded:
      const addRatePayload = action.payload as DashboardSeekerEvaluationType
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            contactRequests: {
              ...state.data.allRequests.contactRequests,
              searchResults:
                state.data.allRequests.contactRequests.searchResults.map(
                  (item) =>
                    item.seeker.userId === addRatePayload.requestId
                      ? {
                          ...item,
                          seeker: {
                            ...item.seeker,
                            seekerEvaluations: [
                              {
                                evaluation: addRatePayload.evaluation,
                              },
                            ],
                          },
                        }
                      : item
                ),
            },
            interviewInvitations: {
              ...state.data.allRequests.interviewInvitations,
              searchResults:
                state.data.allRequests.interviewInvitations.searchResults.map(
                  (item) =>
                    item.seeker.userId === addRatePayload.requestId
                      ? {
                          ...item,
                          seeker: {
                            ...item.seeker,
                            seekerEvaluations: [
                              {
                                evaluation: addRatePayload.evaluation,
                              },
                            ],
                          },
                        }
                      : item
                ),
            },
            placements: {
              ...state.data.allRequests.placements,
              searchResults:
                state.data.allRequests.placements.searchResults.map((item) =>
                  item.seeker.userId === addRatePayload.requestId
                    ? {
                        ...item,
                        seeker: {
                          ...item.seeker,
                          seekerEvaluations: [
                            {
                              evaluation: addRatePayload.evaluation,
                            },
                          ],
                        },
                      }
                    : item
                ),
            },
            offers: {
              ...state.data.allRequests.offers,
              searchResults: state.data.allRequests.offers.searchResults.map(
                (item) =>
                  item.seeker.userId === addRatePayload.requestId
                    ? {
                        ...item,
                        seeker: {
                          ...item.seeker,
                          seekerEvaluations: [
                            {
                              evaluation: addRatePayload.evaluation,
                            },
                          ],
                        },
                      }
                    : item
              ),
            },
            allRequests: {
              ...state.data.allRequests.allRequests,
              searchResults:
                state.data.allRequests.allRequests.searchResults.map((item) =>
                  item.seeker.userId === addRatePayload.requestId
                    ? {
                        ...item,
                        seeker: {
                          ...item.seeker,
                          seekerEvaluations: [
                            {
                              evaluation: addRatePayload.evaluation,
                            },
                          ],
                        },
                      }
                    : item
                ),
            },
          },
        },
      }
    case type.employer.allRequests.rateCandidate.edit.succeeded:
      const rateCandidatePayload =
        action.payload as DashboardSeekerEvaluationType
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            contactRequests: {
              ...state.data.allRequests.contactRequests,
              searchResults:
                state.data.allRequests.contactRequests.searchResults.map(
                  (item) =>
                    item.seeker.userId === rateCandidatePayload.requestId
                      ? {
                          ...item,
                          seeker: {
                            ...item.seeker,
                            seekerEvaluations: [
                              {
                                evaluation: rateCandidatePayload.evaluation,
                              },
                            ],
                          },
                        }
                      : item
                ),
            },
            interviewInvitations: {
              ...state.data.allRequests.interviewInvitations,
              searchResults:
                state.data.allRequests.interviewInvitations.searchResults.map(
                  (item) =>
                    item.seeker.userId === rateCandidatePayload.requestId
                      ? {
                          ...item,
                          seeker: {
                            ...item.seeker,
                            seekerEvaluations: [
                              {
                                evaluation: rateCandidatePayload.evaluation,
                              },
                            ],
                          },
                        }
                      : item
                ),
            },
            placements: {
              ...state.data.allRequests.placements,
              searchResults:
                state.data.allRequests.placements.searchResults.map((item) =>
                  item.seeker.userId === rateCandidatePayload.requestId
                    ? {
                        ...item,
                        seeker: {
                          ...item.seeker,
                          seekerEvaluations: [
                            {
                              evaluation: rateCandidatePayload.evaluation,
                            },
                          ],
                        },
                      }
                    : item
                ),
            },
            offers: {
              ...state.data.allRequests.offers,
              searchResults: state.data.allRequests.offers.searchResults.map(
                (item) =>
                  item.seeker.userId === rateCandidatePayload.requestId
                    ? {
                        ...item,
                        seeker: {
                          ...item.seeker,
                          seekerEvaluations: [
                            {
                              evaluation: rateCandidatePayload.evaluation,
                            },
                          ],
                        },
                      }
                    : item
              ),
            },
            allRequests: {
              ...state.data.allRequests.allRequests,
              searchResults:
                state.data.allRequests.allRequests.searchResults.map((item) =>
                  item.seeker.userId === rateCandidatePayload.requestId
                    ? {
                        ...item,
                        seeker: {
                          ...item.seeker,
                          seekerEvaluations: [
                            {
                              evaluation: rateCandidatePayload.evaluation,
                            },
                          ],
                        },
                      }
                    : item
                ),
            },
          },
        },
      }

    case type.employer.activeRequests.failed:
    case type.employer.personalData.save.failed:
      return {...state, loading: false, error: action.payload as ErrorType}
    case type.employer.allRequests.interviewInvitations.failed:
    case type.employer.allRequests.interviewInvitations.loadmore.failed:
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            ...state.data.allRequests,
            interviewInvitations: {
              ...state.data.allRequests.interviewInvitations,
              searchResults: [],
              loading: false,
              error: action.payload as ErrorType,
            },
          },
        },
      }
    case type.employer.getCandidateProfile.requested:
    case type.employer.contactCandidate.requested:
      return {
        ...state,
        data: {
          ...state.data,
          candidate: {
            ...state.data.candidate,
            loading: true,
            error: undefined,
          },
        },
      }
    case type.employer.clearCandidateProfile.requested:
      return {
        ...state,
        data: {
          ...state.data,
          candidate: {
            data: {},
            loading: false,
            error: undefined,
          },
        },
      }
    case type.employer.clearCandidateRequests.requested:
      return {
        ...state,
        data: {
          ...state.data,
          allRequests: {
            allRequests: requestsInitialState,
            contactRequests: requestsInitialState,
            interviewInvitations: requestsInitialState,
            offers: requestsInitialState,
            placements: requestsInitialState,
            placementConfirmedOffers: requestsInitialState,
          },
        },
      }
    case type.employer.getCandidateProfile.succeeded:
      return {
        ...state,
        data: {
          ...state.data,
          candidate: {
            data: action.payload as EmployerCandidateProfileDataType,
            loading: false,
            error: undefined,
          },
        },
      }
    case type.employer.contactCandidate.succeeded:
      return {
        ...state,
        data: {
          ...state.data,
          candidate: {
            data: {
              ...state.data.candidate.data,
              contactRequestExists: true,
            },
            loading: false,
            error: undefined,
          },
        },
      }
    case type.employer.getCandidateProfile.failed:
    case type.employer.contactCandidate.failed:
      return {
        ...state,
        data: {
          ...state.data,
          candidate: {
            ...state.data.candidate,
            loading: false,
            error: action.payload as ErrorType,
          },
        },
      }
    default:
      return state
  }
}

export default EmployerReducer
