export enum GeneralAssessmentsTranslations {
  title = 'generalassessmentstranslations.title',
  personalityTestTitle = 'generalassessmentstranslations.personalityTestTitle',
  languageTestTitle = 'generalassessmentstranslations.languageTestTitle',
  skillsTestTitle = 'generalassessmentstranslations.skillsTestTitle',
  testTypeLabel = 'generalassessmentstranslations.testTypeLabel',
  testProviderLabel = 'generalassessmentstranslations.testProviderLabel',
  testDateLabel = 'generalassessmentstranslations.testDateLabel',
  testExpirationDateLabel = 'generalassessmentstranslations.testExpirationDateLabel',
  testInfoLabel = 'generalassessmentstranslations.testInfoLabel',
  testActionLabel = 'generalassessmentstranslations.testActionLabel',
  startTestText = 'generalassessmentstranslations.startTestText',
  viewResultsText = 'generalassessmentstranslations.viewResultsText',
  assessments = 'generalassessmentstranslations.assessments',
  pendingButtonText = 'generalassessmentstranslations.pendingButtonText',
  termsOfUse = 'generalassessmentstranslations.termsOfUse',
  privacyPolicy = 'generalassessmentstranslations.privacyPolicy',
  cookies = 'generalassessmentstranslations.cookies',
  authors = 'generalassessmentstranslations.authors',
  assessmentsSuccess = 'generalassessmentstranslations.assessmentsSuccess',
  assessmentsSuccessMessage = 'generalassessmentstranslations.assessmentsSuccessMessage',
  tests = 'generalassessmentstranslations.tests',
  personality = 'generalassessmentstranslations.personality',
  language = 'generalassessmentstranslations.language',
  assessmentConsent = 'generalassessmentstranslations.assessmentConsent',
  unlimited = 'generalassessmentstranslations.unlimited',
  download = 'generalassessmentstranslations.download',
  print = 'generalassessmentstranslations.print',
  resultsValidUntil = 'generalassessmentstranslations.resultsValidUntil',
  backToAssessments = 'generalassessmentstranslations.backToAssessments',
  generalProfile = 'generalassessmentstranslations.generalProfile',
}
