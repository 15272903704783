import InfiniteScroll from 'Components/InfiniteScroll'
import {
  BaseTaxonomyType,
  CandidateSearchResultsStateType,
  DataType,
  SearchControlFormikValuesType,
  TaxonomiesStateType,
} from 'Interfaces'
import SearchHeader from 'Components/CandidateSearch/SearchHeader'
import {FC, useCallback, useState} from 'react'
import {CandidateCardContainer} from 'Components/CandidateSearch/CandidateCard'
import Facet from 'Components/CandidateSearch/Facet'
import styles from './CandidateSearchResults.module.scss'
import {useMediaQuery} from 'react-responsive'
import {Sort} from './Sort'

type CandidateSearchResultsProps = {
  loadMoreCandidates: () => void
  searchResults: CandidateSearchResultsStateType
  getSearchResults: (values: SearchControlFormikValuesType) => void
  taxonomies: TaxonomiesStateType
  searchKeywords: DataType<BaseTaxonomyType>
  handleCommentFocus?: () => void
}

export const CandidateSearchResults: FC<CandidateSearchResultsProps> = ({
  searchResults,
  loadMoreCandidates,
  getSearchResults,
  taxonomies,
  searchKeywords,
  handleCommentFocus,
}) => {
  const [selectedResults, setSelectedResults] = useState<string[]>([])

  const toggleSelectAll = useCallback(() => {
    if (selectedResults.length === 0) {
      setSelectedResults(searchResults.data.map((result) => result.userId))
    } else {
      setSelectedResults([])
    }
  }, [setSelectedResults, selectedResults, searchResults.data])

  const isMobile = useMediaQuery({
    query: '(max-width: 991px)',
  })

  const onLoadMore = () => {
    if (searchResults.loadMore) {
      loadMoreCandidates()
    }
  }

  const selectCandidate = (id: string) => {
    const selected = [...selectedResults]
    const idx = selected.indexOf(id)
    if (idx === -1) {
      selected.push(id)
    } else {
      selected.splice(idx, 1)
    }
    setSelectedResults(selected)
  }

  return (
    <InfiniteScroll
      loading={searchResults.loadMoreProgress}
      hasMore={!!searchResults.loadMore}
      loadMore={onLoadMore}
    >
      <>
        <SearchHeader
          resultsCount={searchResults.total}
          toggleSelectAll={toggleSelectAll}
          selectedResults={selectedResults}
        />
        <div className={styles.facetCandidateCardContainer}>
          {!isMobile ? (
            <div className={styles.facetContainer}>
              {Object.keys(searchResults.facets)
                .sort(Sort)
                .map((key, index) => {
                  return searchResults.facets[key].length ? (
                    <Facet
                      categoryName={key}
                      key={index}
                      items={searchResults.facets[key]}
                      getSearchResults={getSearchResults}
                      taxonomies={taxonomies}
                      searchKeywords={searchKeywords}
                    />
                  ) : null
                })}
            </div>
          ) : null}

          <div className={styles.cardsWrap}>
            {searchResults.data.length &&
              searchResults.data.map((candidate, idx) => (
                <CandidateCardContainer
                  key={candidate.id + idx}
                  candidate={candidate}
                  selected={selectedResults.includes(candidate.userId)}
                  handleCandidateCheck={selectCandidate}
                  handleCommentFocus={handleCommentFocus}
                />
              ))}
          </div>
        </div>
      </>
    </InfiniteScroll>
  )
}
