export const CandidateFAQ = {
  'CandidateFAQ.yellowTitle': 'BREZPLAČNA',
  'CandidateFAQ.title': 'KARIERNA PODPORA',
  'CandidateFAQ.titleFirstParagraph':
    'Kako ustvariti in postaviti svoj profil?',
  'CandidateFAQ.textFirstParagraph':
    'Ustvarjanje profila je hitro in enostavno. Postopek vključuje nekaj korakov za pridobivanje pomembnih informacij, prek katerih vas bo naš sistem najlažje povezal z delodajalci, ki iščejo kandidate z vašimi sposobnostmi in preferencami.',
  'CandidateFAQ.textItemOneFirstParagraph':
    'V prvem koraku vnesete svoj elektronski naslov, ime in priimek ter izberete geslo.',
  'CandidateFAQ.textItemTwoFirstParagraph':
    'Nato imate možnost prenosa svojega življenjepisa v obliki datoteke (priporočeno .pdf), ki omogoča samodejno zapolnjevanje segmentov vaših delovnih izkušenj, izobrazbe in referenc. V nekaterih primerih se ti segmenti ne izpišejo sami, temveč vas registracijski vprašalnik pozneje vodi, da sami dodate svoje izkušnje in doseženo izobrazbo.',
  'CandidateFAQ.textItemThreeFirstParagraph':
    'Na profilu označite, kateri poklici vas trenutno najbolj zanimajo, vrsto zaposlitve, ki jo iščete, in lokacijo, kjer jo iščete, kakšno plačo želite ter kdaj bi lahko začeli delati na novem delovnem mestu.',
  'CandidateFAQ.textItemFourFirstParagraph':
    'Na koncu označite še, katere ugodnosti vam veliko pomenijo na delovnem mestu.',
  'CandidateFAQ.titleSecondParagraph':
    'Kako izboljšati svoj profil in pridobiti čim boljše ponudbe',
  'CandidateFAQ.textSecondParagraph':
    'Ste sledili vsem korakom pri ustvarjanju profila, a kljub temu ne dobite ponudb, ki si jih želite? Preverite, ali ste izkoristili ključne vsebine svojega profila, ki so pomembne za delodajalce:',
  'CandidateFAQ.titleFirstSection': 'Želeni poklic',
  'CandidateFAQ.paragraphFirstSection':
    'Priporočamo, da izberete delovno mesto, ki je najbližje vašim željam. Izberite več poklicev, ki vas zanimajo, pri tem pa bodite čim konkretnejši. Če vas zanima več različnih poklicev, vam priporočamo, da že pri uvodnem nagovoru razložite, zakaj ste navedli prav ta delovna mesta in katerim delovnim mestom dajete prednost.',
  'CandidateFAQ.titleSecondSection': 'Spretnosti in sposobnosti',
  'CandidateFAQ.paragraphSecondSection':
    'Pri ustvarjanju profila bodite pozorni in poudarite svoje najbolj razvite sposobnosti in sposobnosti, ki bi si jih želeli v nadaljevanju uporabljati na delovnem mestu. Na tem mestu lahko opredelite tudi spretnosti in znanja, v katerih morda še niste tako izkušeni, a si jih želite nadgraditi. Če ste IT-strokovnjak, priporočamo, da podkrepite svoje navedbe spretnosti z reševanjem različnih testov sposobnosti, npr. HTML, CSS, Android itd. Z dovolj visokim rezultatom pridobite značko, ki je vidna na vašem profilu, hkrati pa je to odlična vaja za tehnične intervjuje. Dokazano je, da boste imeli dvakrat večjo možnost za povabilo na razgovor, če bo delodajalec na vašem profilu videl omenjeno značko. Gre za enega izmed najbolj prepoznavnih znakov talenta, ki ga iščejo delodajalci.',
  'CandidateFAQ.titleThirdSection': 'Delovne izkušnje',
  'CandidateFAQ.paragraphThirdSection':
    'Na kratko, v dveh ali treh stavkih, opišite svoje delovne naloge in uporabljena znanja za vsako preteklo zaposlitev. To je zelo pomembno, saj delodajalce zanima, katero programsko opremo, jezike oz. Tehnologijo ste uporabljali v preteklosti, in je dober pokazatelj vaše strokovnosti na določenem področju. Če imate za seboj že veliko delovnih mest, priporočamo, da se osredotočite na zadnji dve oz. Tri zaposlitve, prejšnje pa opišete krajše, bolj strnjeno, tistim z manj izkušenj pa priporočamo, da v ta segment vključite vsa študentska dela, projekte ali natečaje, ki so smiselni glede na delo, ki ga iščete.',
  'CandidateFAQ.titleFourthSection': 'Izobrazba',
  'CandidateFAQ.paragraphFourthSection':
    'Če morda še nimate veliko izkušenj ali pa ste ravno zaključili študij, je zelo pomembno, da navedete svojo izobrazbo. Opredelite smer študija in stopnjo izobrazbe. Tistim, ki morda še nimate delovnih izkušenj, priporočamo, da tu predstavite projekte, seminarje, diplomske ali magistrske naloge in tako izpostavite spretnosti in sposobnosti, ki ste jih pri tem razvili. Danes je vse več strokovnjakov brez formalne izobrazbe in podjetja so glede tega vse bolj odprta, zato vam v tem segmentu ponujamo možnost »samoučenjak« ali »nisem dokončal« za vse, ki ste študij začeli, a ga niste zaključili.',
  'CandidateFAQ.titleFifthSection': 'Uvodni nagovor',
  'CandidateFAQ.paragraphFifthSection':
    'Uvodni nagovor je izrednega pomena, saj je prva stvar, ki jo delodajalec vidi. Velika večina zgolj navede trenutni naziv delovnega mesta, vendar se splača v nekaj besedah kar se da premišljeno in ustvarjalno zapisati vaš interes in prispevek. Nagovor naj bo jasen, atraktiven in opredeljuje vaše trenutno delovno mesto oz. To, česa si želite. Za tiste, ki ste ravno zaključili študij, je zelo pomembno, da opredelite, kakšno službo iščete. Za tiste bolj izkušene pa je pomembno, da poudarite svoje ključne veščine in hkrati izrazite svoje želje za prihodnje delovno mesto. Za izkušenega programskega inženirja, ki ga zanima razvoj spletnih mest in aplikacij, bi lahko recimo navedli: »Java inženir s 6+ letnimi izkušnjami, ki ga zanima delo na področju razvoja spletnih mest in aplikacij.« S takim nagovorom izpostavimo jezik, s katerim smo najpogosteje in v zadnjem času delali (Java), kako dolgo to počnemo (vsaj 6 let) in na kaj bi se želeli osredotočiti (razvoj spletnih mest in aplikacij). Nedavni diplomant, ki je pravkar pridobil diplomo iz mehatronike, bi imel naslov, ki odraža to izkušnjo: »Mlad elektroinženir – mehatronik z velikim zanimanjem za pnevmatiko in hidravliko, ki išče zaposlitev v avtomobilski industriji.« Čeprav bi vas morda zamikalo, da bi vključili osebne interese, imate samo eno možnost, da naredite odličen prvi vtis na zaposlovalca, zato vključite le najpomembnejše.',
  'CandidateFAQ.titleSixthSection': 'Lokacija',
  'CandidateFAQ.paragraphSixthSection':
    'Priporočamo, da na profilu nakažete, da ste pripravljeni delati tako v pisarni kot tudi od doma. S tem pokažete prilagodljivost, kar vam bo pripomoglo tudi pri končnih pogajanjih. Nekatera podjetja vam bodo namreč ponudila možnost dela od doma, tudi če morda tega ne bodo navedla na začetku. Velika večina delodajalcev raje vidi, da zaposleni delajo v pisarnah, vendar pa opažamo vse večji trend dela od doma.',
  'CandidateFAQ.titleSeventhSection': 'Pokažite svojo osebnost',
  'CandidateFAQ.paragraphSeventhSection':
    'Vaš profil vas lahko veliko močneje predstavlja, če mu dodate svojo sliko, predstavite svoj najljubši projekt v obliki dodatne datoteke in morda svoje hobije in zanimanja v prostem času. Pokažite svojo osebnost, vendar ostanite profesionalni.',
  'CandidateFAQ.titleThirdParagraph':
    'Kako izboljšati svoj profil in pridobiti čim boljše ponudbe',
  'CandidateFAQ.textThirdParagraph':
    'Vaš življenjepis je bil ravno dovolj zanimiv in informativen, da vas je delodajalec povabil na razgovor. Sedaj morate dokazati, da ste primerni in sposobni opravljati delo. Zaposlitveni razgovor je za vsakogar velik izziv – ne zgolj za tiste, ki se s tem soočate prvič, temveč tudi za že izkušene iskalce. Gre namreč za izkušnjo, kjer sta velikokrat prisotna trema in strah pred neuspehom. Pomisleke in lastno negotovost pa lahko uravnavamo tako, da se na razgovor vnaprej pripravimo. V nadaljevanju predstavljamo pet kratkih nasvetov za boljšo pripravo na razgovor:',
  'CandidateFAQ.bottomParagraphtitleFirst':
    'Naredite kratko raziskavo o podjetju in delovnem mestu:',
  'CandidateFAQ.bottomParagraphtextFirst':
    'Razumevanje ključnih informacij o podjetju, ki ga boste obiskali, je pomemben del priprave na razgovor. Podrobno raziščite njihovo spletno mesto, vrednote podjetja, spoznajte njihove izdelke in storitve, prav tako pa preglejte njihove družbene profile, kjer lahko dobite zelo dober občutek za kulturo podjetja in tip ljudi, ki so v njem zaposleni. Prav tako je pomembno, da razumete vse zahteve in odgovornosti, povezane s samim delovnim mestom. Med samim razgovorom lahko še dodatno prosite za morebitna pojasnila in podrobnosti, kar vam bo pozneje pomagalo pri odločitvi, ali je to delovno mesto pravo za vas ali ne.',
  'CandidateFAQ.bottomParagraphtitleSecond':
    'Dokažite, da ste strokovnjak na svojem področju:',
  'CandidateFAQ.bottomParagraphtextSecond':
    'Na svojem profilu ste navedli določene spretnosti in znanja, za katera ste najbolj specializirani. Bodite pripravljeni na to, da vam bo morda delodajalec postavil bolj specifična oziroma tehnična vprašanja s tega področja. Pri zaposlovanju tehničnih profilov se delodajalci vse pogosteje poslužujejo različnih strokovnih/tehničnih nalog, ki jim omogočijo vpogled v praktično znanje kandidatov.',
  'CandidateFAQ.bottomParagraphtitleThird':
    'Vadite svoje odgovore na najbolj tipična vprašanja:',
  'CandidateFAQ.bottomParagraphtextThird':
    'Pripravite svoje odgovore na najbolj tipična vprašanja delodajalcev, ki se od razgovora do razgovora ponavljajo v bolj ali manj enaki obliki. Preglejte svoje pretekle projekte in pripravite seznam svojih poklicnih dosežkov. Pri odgovorih lahko uporabite metodo STAR (situacija, naloga, dejanje, rezultat), ki vam pomaga strukturirati odgovore.',
  'CandidateFAQ.bottomParagraphtitleFourth': 'Pripravite vprašanja:',
  'CandidateFAQ.bottomParagraphtextFourth':
    'To je vaša priložnost. Delodajalci pričakujejo, da boste postavljali vprašanja, želijo vedeti, ali resno razmišljate o tem, kako bi bilo delati v njihovem podjetju. Postavljajte vprašanja, ki poudarjajo vaše poznavanje podjetja in njegovega področja dela ter vaš interes za določeno delovno mesto. Poskušajte pridobiti dodatne informacije, s katerimi lahko presodite, ali bi vam delovno mesto ustrezalo. Razgovor je obojestranski komunikacijski kanal, kar pomeni, da želite pridobiti ravno toliko informacij o delodajalcu, kot si jih želi delodajalec pridobiti o vas.',
  'CandidateFAQ.bottomParagraphtitleFifth':
    'Ohranite mirnost in naredite odličen prvi vtis:',
  'CandidateFAQ.bottomParagraphtextFifth':
    'Izkazovanje pozitivne naravnanosti z nasmehom in optimistično govorico telesa veliko pripomore k lahkotnosti in konstruktivnosti razgovora. Gre namreč za recipročni interes, saj si delodajalec želi zapolniti določeno delovno mesto, vi pa želite najti najboljšo ponudbo zase. Vsak razgovor je nekoliko drugačen, vendar pa je najpomembneje, da se ga lotite čim samozavestnejši in pripravljeni pokazati najboljšo različico sebe.',
  'CandidateFAQ.lastSectiontitle':
    'Kakšna bi morala biti pričakovanja glede plače v skladu z vašim profilom, delovnimi izkušnjami, znanji',
  'CandidateFAQ.lastSectionFirstPart':
    'Pričakovanja plače naj bodo primerna glede na vašo izobrazbo, znanja, sposobnosti in izkušnje. Ob tem je pomembno, da se pri tem ne podcenjujete, saj lahko s tem daste vtis nekompetentnosti oziroma nezaupanja v svoje sposobnosti. Priporočamo, da za čim boljšo predstavo, kakšne so primerljive plače za vaš profil, preberete več informacij na ',
  'CandidateFAQ.lastSectionlink': 'Plača.si.',
  'CandidateFAQ.lastSectionSecondPart':
    ' Svojo vrednost določite na podlagi dejstev. Premislite, koliko prispevate k podjetju, in ugotovili boste svojo vrednost. Povejte svoje želje, a bodite pripravljeni na pogajanja.',
}
