import {FC} from 'react'
import classnames from 'classnames'
import styles from './Description.module.scss'
import {FormattedMessage} from 'react-intl'

interface DescriptionProps {
  text: string
  className?: string
}

export const Description: FC<DescriptionProps> = ({
  text,
  className,
}: DescriptionProps) => (
  <span className={classnames(styles.description, className)}>
    <FormattedMessage id={text} />
  </span>
)
