import {connect} from 'react-redux'

import {AwardsEndDate} from './AwardsEndDate'
import {AppDispatch} from 'Store'
import {addAwards, editAwards, deleteAwards} from 'Store/Actions'
import {RootState} from 'Store/Reducers'
import {AwardsSaveValue} from 'Interfaces'
import {canChangeProfile} from 'Utils/Profile'

const mapStateToProps = (state: RootState) => ({
  awards: state.seeker.data?.full?.awards,
  profile: state.seeker,
  canChange: canChangeProfile(state.auth.data?.rjbProfileStatus),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addAwards: (value: AwardsSaveValue) => dispatch(addAwards(value)),
  editAwards: (value: AwardsSaveValue) => dispatch(editAwards(value)),
  deleteAward: (id: string) => dispatch(deleteAwards(id)),
})

export const AwardsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AwardsEndDate)
