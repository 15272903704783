export const forgotPassword = {
  'forgotPassword.title': 'Forgot password',
  'forgotPassword.description':
    'Tell us your email address, and we’ll get you back on track in no time.',
  'forgotPassword.buttonResetPassword': 'Reset Password',
  'forgotPassword.backToLogIn': 'Back to log in',
  'forgotPassword.textLabel': 'Email Address',
  'forgotPassword.successMessage':
    'We have just sent a password reset link to your email address. Please check your inbox and follow the instructions on the link.',
  'Already used reset link': 'Already used reset link',
}
