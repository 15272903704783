import {SocketType} from 'Interfaces'
import {FC, useEffect, useState} from 'react'

interface MessageConnectorProps {
  authenticatedUserId?: string
  startMessageChannel: () => void
  socket?: SocketType
}

export const MessageConnector: FC<MessageConnectorProps> = ({
  authenticatedUserId,
  startMessageChannel,
  socket,
}) => {
  const [connectionTries, setConnectionTries] = useState(0)

  useEffect(() => {
    if (
      authenticatedUserId &&
      socket?.connectionStatus !== 'ON' &&
      connectionTries < 1
    ) {
      console.log('messageCenter log - startMessageChannel')
      setConnectionTries(connectionTries + 1)
      // open socket channel
      startMessageChannel()
    } else if (!authenticatedUserId) {
      console.log('messageCenter log - reset counter')
      setConnectionTries(0)
    }
  }, [authenticatedUserId, socket, connectionTries])

  useEffect(() => {
    return () => {
      socket?.client?.off('connect')
      socket?.client?.off('disconnect')
      socket?.client?.off('conversations joined')
      socket?.client?.off('added to conversation')
      socket?.client?.off('message created')
      socket?.client?.off('message received')
      socket?.client?.off('conversations read')
      socket?.client?.off('conversations unread')
      socket?.client?.off('unread conversations count')
      socket?.client?.off('unread conversations count - seekers')
      socket?.client?.off('unread conversations count - company users')
      socket?.client?.off('unread conversations count - tsa')
      socket?.client?.off('connect_failed')
    }
  }, [])

  return null
}
