export const seekerPublic = {
  'routes.homePageCandidate': '/',
  'routes.seekerlogin': '/prijava',
  'routes.forgotpassword': '/pozabljeno-geslo',
  'routes.registration': '/registracija',
  'routes.resetpassword': '/reset-geslo/:token',
  'routes.candidateInfo': '/candidate-info',
  'routes.frequentlyAskedQuestions': '/pogosta-vprasanja',
  'routes.candidateFAQ': '/pogosta-vprasanja/kandidati',
  'routes.candidateAdvice': '/nasveti/kandidati',
}

const seekerPrivate = {
  'routes.onboarding': '/registracijski-vprasalnik',
  'routes.contactdata': '/registracija-uspesna',
  'routes.workexperience': '/radnoiskustvo',
  'routes.profile': '/uredi-cv',
  'routes.publicprofile': '/uredi-moj-profil',
  'routes.assessments': '/testi',
  'routes.cognitivetest': '/testi/kognitivni',
  'routes.psychometrictest': '/testi/psihometricni',
  'routes.assessmentssuccess': '/testi/psihometricni/zakljucek',
  'routes.candidatedashboard': '/namizje',
  'routes.myAccount': '/moj-racun',
  'routes.changepassword': '/moj-racun/spremeni-geslo',
  'routes.changeemail': '/moj-racun/spremeni-e-naslov',
  'routes.consents': '/moj-racun/soglasja',
  'routes.languagesettings': '/nastavitve/jezikovne-nastavitve',
  'routes.employerblacklist': '/nastavitve/blokirani-delodajalci',
  'routes.newsletterunsubscribe': '/newsletter/unsubscribe',
  'routes.newsletterunsubscribesingle': '/newsletter/unsubscribe-single',
  'routes.newsletterunsubscribereason': '/newsletter/unsubscribe-reason',
  'routes.needToVerifyEmail': '/auth/verify-email-notice',
  'routes.emailverification': '/auth/verify-email',
  'routes.allincomingrequests': '/namizje/vse-zahtevki',
  'routes.impersonateCandidate':
    '/impersonate/candidate/:userId/:impersonatedUserId',
  'routes.changeemailconfirm': '/auth/change-email',
  'routes.candidateMessages': '/sporočila/kandidati',
  'routes.candidateCalendar': '/koledar/kandidati',
  'routes.publiccompanyprofile': '/podjetje/profil/:id',
  'routes.candidateCompanies': '/podjetja/kandidati',
  'routes.employerMessages': '/sporočila/delodajalci',
  'routes.seekerpsychometrictestresults': '/testi/psihometricni-rezultati',
  'routes.startCognitiveTest': '/testi/začni-test',
  'routes.seekerLoginConsents': '/seeker-login-consents',
}

export const employerPublic = {
  'routes.homePageEmployer': '/delodajalci',
  'routes.employerforgotpassword': '/delodajalec-pozabljeno-geslo',
  'routes.employerresetpassword': '/delodajalec-reset-geslo/:token',
  'routes.employerlogin': '/prijava-delodajalci',
  'routes.employerinfolanding': '/landing/employer-info',
  'routes.employerFAQ': '/pogosta-vprasanja/delodajalci',
  'routes.employerAdvice': '/nasveti/delodajalci',
}

export const employerPrivate = {
  'routes.employerLoginConsents': '/employer-login-consents',
  'routes.employeronboarding': '/employer-onboarding',
  'routes.companyprofile': '/podjetje/profil',
  'routes.candidatesearch': '/candidate-search',
  'routes.employermyaccount': '/uporabniski-racun',
  'routes.employerchangepassword': '/uporabniski-racun/spremeni-geslo',
  'routes.employerchangeemail': '/uporabniski-racun/spremeni e-naslov',
  'routes.employerconsents': '/uporabniski-racun/soglasja',
  'routes.employerlanguagesettings':
    '/uporabniske-nastavitve/jezikovne-nastavitve',
  'routes.proposenewtimecalendar': '/koledar/propose-new-time',
  'routes.reviewcandidate': '/review-candidate',
  'routes.psychometrictestresults':
    '/testi/psihometricni-rezultati/:candidateId',
  // EMPLOYER DASHBOARD:
  'routes.employerdashboard': '/delodajalci-namizje',
  'routes.employercontactrequests': '/delodajalci-namizje/kontaktni-zahtevki', // Todo: Check for correct translations
  'routes.employercontactrequestsAll':
    '/delodajalci-namizje/kontaktni-zahtevki/vsa',
  'routes.employercontactrequestsPending':
    '/delodajalci-namizje/kontaktni-zahtevki/na-čakanju',
  'routes.employercontactrequestsApproved':
    '/delodajalci-namizje/kontaktni-zahtevki/sprejeto',
  'routes.employerinterviewinvitations': '/delodajalci-namizje/intervju-vabilo', // Todo: Check for correct translations
  'routes.employerinterviewinvitationsAll':
    '/delodajalci-namizje/intervju-vabilo/vsa',
  'routes.employerinterviewinvitationsPending':
    '/delodajalci-namizje/intervju-vabilo/na-čakanju',
  'routes.employerinterviewinvitationsAccepted':
    '/delodajalci-namizje/intervju-vabilo/sprejeto',
  'routes.employerinterviewinvitationsCompleted':
    '/delodajalci-namizje/intervju-vabilo/zaključeno',
  'routes.employeroffers': '/delodajalci-namizje/ponudbe', // Todo: Check for correct translations
  'routes.employeroffersAll': '/delodajalci-namizje/ponudbe/vsa',
  'routes.employeroffersPending': '/delodajalci-namizje/ponudbe/na-čakanju',
  'routes.employeroffersAccepted': '/delodajalci-namizje/ponudbe/sprejeto',
  'routes.employerplacements': '/delodajalci-namizje/zaposlitve', // Todo: Check for correct translations
  'routes.employerplacementsAll': '/delodajalci-namizje/zaposlitve/vsa',
  'routes.employerplacementsPending':
    '/delodajalci-namizje/zaposlitve/na-čakanju',
  'routes.employerplacementsConfirmed':
    '/delodajalci-namizje/zaposlitve/potrjena',
  'routes.employerthankyou': '/thank-you',
  'routes.candidateMessages': '/messages/candidates',
  'routes.employerMessagesTSA': '/messages/tsa',
  'routes.employerCalendar': '/koledar/delodajalci',
  'routes.savedSearch': '/cv-opomnik',
  'routes.myprojects': '/my-projects',
  'routes.myprojectcandidates': '/my-projects/:id',
}

export const employerPrivateWithParam = {
  'routes.publicassessments': '/kandidat/:id/testi',
  'routes.invitecalendar': '/koledar/invite/:candidateId',
  'routes.impersonateEmployer':
    '/impersonate/employer/:userId/:impersonatedUserId',
  'routes.employerCandidateProfilePublic': '/kandidat/profil/:id',
  'routes.employerCandidateProfileFull': '/kandidat/cv/:id',
}

export const publicRoutes = {
  'routes.helpsupport': '/podpora',
  'routes.helpsupportcontactus': '/podpora/kontakt',
  'routes.privacypolicy': '/varstvo-osebnih-podatkov',
  'routes.termsandconditions': '/pogoji-uporabe',
  'routes.generalTermsAndConditions': '/splosni-pogoji-uporabe',
  'routes.logout': '/odjava',
  'routes.aboutus': '/o-nas',
  'routes.page404': '/page/404',
  'routes.dataProtectionAgreement': '/pogodba-o-obdelavi-osebnih-podatkov',
}

const routes = {
  ...seekerPublic,
  ...seekerPrivate,
  ...employerPublic,
  ...employerPrivate,
  ...employerPrivateWithParam,
  ...publicRoutes,
}

export default routes
