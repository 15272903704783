import {FC, useEffect, useRef, useState} from 'react'
import classnames from 'classnames'
import {FormattedMessage} from 'react-intl'
import {useFormik} from 'formik'

import styles from './Benefits.module.scss'
import {
  CompanyStateType,
  TaxonomiesStateType,
  CompanyBenefitsSaveType,
  CompanyBenefitsType,
  BenefitType,
} from 'Interfaces'
import SectionHeader from 'Components/Profile/SectionHeader'
import SectionItem from 'Components/Profile/SectionItem'
import ItemHeader from 'Components/Profile/ItemHeader'
import Modal, {SubmitFooter} from 'Components/Modal'
import {Validation} from './Validation'
import Hr from 'Components/Hr'
import {useApiError, useWindowResize} from 'Hooks'
import {CheckboxGroupProps} from 'Utils/FormikProps'
import CheckboxGroup from 'Components/Inputs/CheckboxGroup'
import {packBenefitsData} from 'Store/Packing/Company'
import ShowMoreContent from 'Components/ShowMoreContent'
import {CompanyBenefitsTranslations as cbt} from 'Services/I18n/Constants'
import HTMLInput from 'Components/Inputs/HTMLInput'
import Title from 'Components/Title'
import {
  CardGiftcard,
  HealthAndSafetyOutlined,
  LocalOfferOutlined,
  RocketLaunchOutlined,
  SentimentSatisfiedAlt,
} from '@mui/icons-material'
import {HTMLText} from 'Components/HTMLText'

interface BenefitsProps {
  taxonomies: TaxonomiesStateType
  company: CompanyStateType
  saveBenefits: (benefit: CompanyBenefitsSaveType) => void
  getTaxonomies: () => void
  canChange: boolean
}

export const Benefits: FC<BenefitsProps> = ({
  taxonomies,
  company,
  saveBenefits,
  getTaxonomies,
  canChange,
}) => {
  let canAdd = false

  if (
    !company.data.benefitsText &&
    (!company.data.additionalBenefits ||
      !company.data.additionalBenefits.length) &&
    !company.data.customBenefitsTextHtml
  ) {
    canAdd = true
  }
  const [modalOpen, setModalOpen] = useState(false)

  const openEdit = () => {
    formik.resetForm({
      values: {
        benefitsText: company.data.benefitsText || '',
        additionalBenefits: company.data.additionalBenefits,
        customBenefitsTextHtml: company.data.customBenefitsTextHtml || '',
      },
    })

    setModalOpen(true)
  }

  useEffect(() => {
    if (!company.loading && !company.error) {
      setModalOpen(false)
      getTaxonomies()
    }
  }, [company.loading])

  const additionalBenefitsMain = taxonomies.additionalBenefits.data.filter(
    (data) => data.other === false
  )
  const additionalBenefitsOther = taxonomies.additionalBenefits.data.filter(
    (data) => data.other === true
  )

  const formik = useFormik({
    initialValues: {
      benefitsText: company.data.benefitsText || '',
      additionalBenefits: company.data.additionalBenefits || [],
      customBenefitsTextHtml: company.data.customBenefitsTextHtml || '',
    } as CompanyBenefitsType,
    validationSchema: Validation,
    onSubmit: (values) => {
      saveBenefits(packBenefitsData(values))
    },
  })

  const [height, setHeight] = useState(
    document.body.clientWidth < 768 ? 88 : 88
  )

  const changeHeight = (clientWidth?: number) => {
    setHeight(clientWidth && clientWidth < 768 ? 88 : 88)
  }

  useWindowResize(changeHeight)
  useApiError(formik.setFieldError, company.error)

  const childRef = useRef(null)

  const hasBenefitsText =
    company.data.benefitsText && company.data.benefitsText !== ''
  const hasCustomBenefitsHtml =
    company.data.customBenefitsTextHtml &&
    company.data.customBenefitsTextHtml !== ''
  const hasShowMore = hasBenefitsText || hasCustomBenefitsHtml

  return (
    <div className={styles.container}>
      {(company.data.additionalBenefits?.length > 0 || canChange) && (
        <SectionHeader
          title={cbt.benefitsTitle}
          onAdd={canChange && canAdd ? () => setModalOpen(true) : undefined}
          className={styles.header}
        />
      )}
      {!canAdd && (
        <SectionItem className={styles.sectionItem}>
          <ItemHeader
            title={cbt.benefitsTitle}
            icon={CardGiftcard}
            onEdit={
              canChange && !canAdd
                ? () => {
                    openEdit()
                  }
                : undefined
            }
            className={styles.itemHeader}
          />
          <ShowMoreContent
            childRef={childRef}
            height={height}
            maxHeight={999}
            theme="shorterLine"
            className={classnames(
              styles.showMoreContent,
              styles.showMoreContentStronger
            )}
            showAll={!hasShowMore}
            forceChildHeight={!hasBenefitsText}
            withMargins={!hasBenefitsText}
            HiddenComponent={
              hasCustomBenefitsHtml ? (
                <div className={styles.customBenefitsWrap}>
                  <ItemHeader
                    title={cbt.benefitsTitle}
                    icon={RocketLaunchOutlined}
                    className={styles.itemHeader}
                  />
                  <div className={styles.content}>
                    <HTMLText
                      htmlInputValue={company.data.customBenefitsTextHtml}
                    />
                  </div>
                </div>
              ) : null
            }
          >
            <div
              className={styles.content}
              ref={!hasBenefitsText ? childRef : null}
            >
              {hasBenefitsText ? (
                <div ref={hasBenefitsText ? childRef : null}>
                  <div className={styles.benefitsText}>
                    <HTMLText htmlInputValue={company.data.benefitsText} />
                  </div>
                </div>
              ) : null}
              {company.data.additionalBenefits?.length > 0 && (
                <div className={styles.sideBySideContainer}>
                  <div className={styles.itemWithBorder}>
                    <ItemHeader
                      title={cbt.friendlyWorkEnvironmentTitle}
                      icon={SentimentSatisfiedAlt}
                      className={styles.itemHeader}
                    />
                    <div className={styles.text}>
                      <ul>
                        {company.data.additionalBenefits
                          .filter((benefit: BenefitType) => !benefit.other)
                          .map((benefit: BenefitType) => (
                            <div key={benefit.id}>
                              <li>{benefit.translation}</li>
                            </div>
                          ))}
                      </ul>
                    </div>
                  </div>
                  <div className={styles.itemWithoutBorder}>
                    <ItemHeader
                      title={cbt.otherTitle}
                      icon={HealthAndSafetyOutlined}
                      className={styles.itemHeader}
                    />
                    <div className={styles.text}>
                      <ul>
                        {company.data.additionalBenefits
                          .filter((benefit: BenefitType) => benefit.other)
                          .map((benefit: BenefitType) => (
                            <div key={benefit.id}>
                              <li>{benefit.translation}</li>
                            </div>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </ShowMoreContent>
        </SectionItem>
      )}
      <Modal
        isOpen={modalOpen}
        onAfterClose={() => {
          setModalOpen(false)
        }}
        title={cbt.modalTitle}
        icon={LocalOfferOutlined}
        ariaHideApp={false}
        className={styles.modal}
        theme="desktopFlex"
        Footer={
          <SubmitFooter
            onSubmit={formik.handleSubmit}
            onCancel={() => {
              setModalOpen(false)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <div className={styles.modalContent}>
          <Title
            text={cbt.modalBenefitsTextTitle}
            className={classnames(styles.title, styles.titleStronger)}
            theme="modal"
          />
          <HTMLInput
            name="benefitsText"
            value={formik.values.benefitsText || ''}
            onChange={formik.setFieldValue}
            error={
              formik.errors.benefitsText
                ? formik.errors.benefitsText
                : undefined
            }
            setError={formik.setFieldError}
            charLimit={3000}
          />

          <Hr
            className={classnames(styles.hr, styles.hrStronger)}
            theme="modal"
          />
          <Title
            text={cbt.modalCompanyBenefitsTitle}
            className={classnames(
              styles.benefitsSectionTitle,
              styles.titleStronger
            )}
            theme="modal"
          />
          <div className={styles.sideBySideContainer}>
            <div className={styles.itemWithBorder}>
              <p className={styles.benefitsSubtitle}>
                <FormattedMessage id={cbt.modalCompanyBenefitsText} />
              </p>
              <CheckboxGroup
                className={styles.checkBoxGroup}
                name="additionalBenefits"
                items={additionalBenefitsMain}
                multiple
                {...CheckboxGroupProps(formik, 'additionalBenefits')}
                error={
                  formik.submitCount && formik.errors.additionalBenefits
                    ? formik.errors.additionalBenefits?.toString()
                    : undefined
                }
                values={
                  formik.values.additionalBenefits
                    ? formik.values.additionalBenefits
                        .filter((a) => !a.other)
                        .map((ab) => ab.id)
                    : []
                }
                setValues={(name, values) => {
                  formik.setFieldValue(name, [
                    ...formik.values.additionalBenefits.filter((a) => a.other),
                    ...additionalBenefitsMain.filter((ab) =>
                      values.includes(ab.id)
                    ),
                  ])
                }}
              />
            </div>
            <div className={styles.itemWithoutBorder}>
              <p className={styles.benefitsSubtitle}>
                <FormattedMessage id={cbt.otherTitle} />
              </p>
              <CheckboxGroup
                forceErrorState={
                  !!formik.submitCount && !!formik.errors.additionalBenefits
                }
                className={styles.checkBoxGroup}
                name="additionalBenefits"
                items={additionalBenefitsOther}
                multiple
                setTouched={
                  formik.getFieldHelpers('additionalBenefits').setTouched
                }
                values={
                  formik.values.additionalBenefits
                    ? formik.values.additionalBenefits
                        .filter((a) => a.other)
                        .map((ab) => ab.id)
                    : []
                }
                setValues={(name, values) => {
                  formik.setFieldValue(name, [
                    ...formik.values.additionalBenefits.filter((a) => !a.other),
                    ...additionalBenefitsOther.filter((ab) =>
                      values.includes(ab.id)
                    ),
                  ])
                }}
              />
            </div>
          </div>
          <Hr
            className={classnames(styles.hr, styles.hrStronger2)}
            theme="modal"
          />
          <Title
            text={cbt.modalCustomBenefitsTitle}
            className={classnames(styles.title, styles.titleStronger)}
            theme="modal"
          />
          <HTMLInput
            name="customBenefitsTextHtml"
            onChange={formik.setFieldValue}
            value={formik.values.customBenefitsTextHtml || ''}
            error={
              formik.errors.customBenefitsTextHtml
                ? formik.errors.customBenefitsTextHtml
                : undefined
            }
            setError={formik.setFieldError}
            charLimit={3000}
          />
        </div>
      </Modal>
    </div>
  )
}
