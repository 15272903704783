import {ContactData} from './ContactData'
import {connect} from 'react-redux'
import {AppDispatch} from 'Store'
import {ContactDataType} from 'Interfaces'
import {contactData, getCountries, getSeekerProfile} from 'Store/Actions'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  taxonomies: state.taxonomies,
  seeker: state.seeker,
  auth: state.auth,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  contactData: (data: ContactDataType) => dispatch(contactData(data)),
  getCountries: () => dispatch(getCountries()),
  getSeekerProfile: () => dispatch(getSeekerProfile()),
})

export const ContactDataContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContactData)
