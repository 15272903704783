import {connect} from 'react-redux'

import {AppDispatch} from 'Store'
import {rejectPlacementEmp} from 'Store/Actions'
import {ModalRejectPlacementConfirmationEmp} from './ModalRejectPlacementConfirmationEmp'

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  rejectPlacementEmp: (payload: string) =>
    dispatch(rejectPlacementEmp(payload)),
})

export const ModalRejectPlacementConfirmationEmpContainer = connect(
  null,
  mapDispatchToProps
)(ModalRejectPlacementConfirmationEmp)
