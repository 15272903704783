import {ActionType, SpectoProposeNewTimeParamsType} from 'Interfaces'
import * as type from '../types'

export const getSpectoToken = (): ActionType<undefined> => {
  return {
    type: type.specto.token.requested,
  }
}

export const getCandidateSpectoId = (): ActionType<undefined> => {
  return {
    type: type.specto.candidateSpectoId.requested,
  }
}

export const setSpectoCandidateId = (id: string): ActionType<string> => {
  return {
    type: type.specto.candidateId.set,
    payload: id,
  }
}

export const setSpectoProposeNewTime = (
  params: SpectoProposeNewTimeParamsType
): ActionType<SpectoProposeNewTimeParamsType> => {
  return {
    type: type.specto.proposeNewTimeId.set,
    payload: params,
  }
}
