import {FC, useEffect, useMemo, useRef, useState} from 'react'
import styles from './AttachmentFileUpload.module.scss'
import classNames from 'classnames'
import FileUpload from '../FileUpload'
import {Grid} from '@mui/material'
import googleDrive from 'Assets/Images/googleDrive.png'
import iCloud from 'Assets/Images/iCloud.png'
import dropBox from 'Assets/Images/dropBox.png'
import oneDrive from 'Assets/Images/oneDrive.png'
import {
  CheckCircle,
  DeleteOutline,
  DriveFolderUploadOutlined,
  Warning,
  DescriptionOutlined,
} from '@mui/icons-material'
import {OnboardingTranslations as onb} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import {GeneralErrorType, ImageType} from 'Interfaces'
import TextError from 'Components/Error/TextError'
import {useTranslate} from 'Hooks'

interface iFile {
  id: string
  url: string
  originalName: string
}

interface FileType {
  uid: string
  lastModified: number
  lastModifiedDate: any
  name: string
  size: number
  type: string
}

interface AttachmentFileUploadProps {
  name: string
  setValue: (name: string, value?: iFile | ImageType) => void
  endpoint?: string
  className?: string
  theme?: string
  integratedUploads?: boolean
  accept?: string
  clearAttachmentFileValidationError?: () => void
  isSubmitted?: boolean
  setIsSubmitted?: Function
  removeAttachmentFile?: boolean
  setRemoveAttachmentFile?: (value: boolean) => void
  supportedFilesMessage?: string
  shouldFocusOnError?: boolean
}

// todo: max filesize, allowed files, etc

export const AttachmentFileUpload: FC<AttachmentFileUploadProps> = ({
  name,
  setValue,
  endpoint,
  className,
  theme = 'default',
  integratedUploads = false,
  accept = '*',
  clearAttachmentFileValidationError,
  isSubmitted,
  setIsSubmitted,
  removeAttachmentFile,
  setRemoveAttachmentFile,
  supportedFilesMessage = onb.uploadFileFormats,
  shouldFocusOnError = false,
}: AttachmentFileUploadProps) => {
  const initialFilaValue: FileType = {
    uid: '',
    lastModified: 0,
    lastModifiedDate: '',
    name: '',
    size: 0,
    type: '',
  }
  const [fileUploadStarted, setFileUploadStarted] = useState(false)
  const [fileUploadCompleted, setFileUploadCompleted] = useState(false)
  const [fileUploadDone, setFileUploadDone] = useState(false)
  const [file, setFile] = useState(initialFilaValue)
  const [error, setError] = useState<GeneralErrorType>()
  const [fileUrl, setFileUrl] = useState('')
  const [fileProgress, setFileProgress] = useState(0)
  const fileRef = useRef<HTMLDivElement | null>(null)

  const onFileUploadStart = (file: FileType) => {
    setFileUploadCompleted(false)
    setFileUploadDone(false)
    setError(undefined)
    setFileUploadStarted(true)
    setFileProgress(0)
    setFile(file)
    setIsSubmitted && setIsSubmitted(false)
  }
  const onFileUploadSuccess = (response: any) => {
    setFileUrl(response.data.url)
    setFileUploadCompleted(true)
    setError(undefined)
    setValue(name, response.data)
    setTimeout(() => {
      setFileUploadDone(true)
    }, 3000)
  }
  const onFileUploadError = (error: any, data: any) => {
    setFileUploadStarted(false)
    setFileUploadCompleted(false)
    setFileUploadDone(false)
    setFile(initialFilaValue)
    setFileProgress(0)
    setValue(name, {
      id: '',
      url: '',
      originalName: '',
    })
    console.error(error)
    // Clear 'Attachment is required' error from parent component
    clearAttachmentFileValidationError && clearAttachmentFileValidationError()
    setError(data.error)
  }
  const removeFile = () => {
    setFileUploadStarted(false)
    setFileUploadCompleted(false)
    setFileUploadDone(false)
    setFile(initialFilaValue)
    setFileProgress(0)
    setValue(name, {
      id: '',
      url: '',
      originalName: '',
    })
  }
  const onFileProgress = (event: {percent: number}) => {
    setFileProgress(event.percent)
  }

  // Whenever submit happens - clear FileUpload error (file too large)
  useEffect(() => {
    if (isSubmitted) {
      setError(undefined)
    }
  }, [isSubmitted])

  useEffect(() => {
    if (removeAttachmentFile && setRemoveAttachmentFile) {
      removeFile()
      setRemoveAttachmentFile(false)
    }
  }, [removeAttachmentFile, setRemoveAttachmentFile])

  useEffect(() => {
    if (shouldFocusOnError && error) {
      fileRef.current?.scrollIntoView({behavior: 'smooth', block: 'center'})
    }
  }, [shouldFocusOnError, error])
  const translate = useTranslate()
  const errorMessage = useMemo<string | null>(() => {
    if (!error) return null
    if (error.message.startsWith('File too large.')) {
      const fileSize = error.message.replace('File too large. Max size: ', '')
      return `${translate(onb.fileTooLarge)} ${fileSize}.`
    }
    return error.message
  }, [error])

  return (
    <div
      className={classNames(styles.AttachmentFileUpload, className, {
        [styles.whiteTheme]: theme === 'white',
      })}
    >
      <div className={styles.supportedFiles}>
        <FormattedMessage id={supportedFilesMessage} />
      </div>

      <Grid container spacing={5}>
        <Grid item md={integratedUploads ? 6 : 12} xs={12}>
          <FileUpload
            name={name}
            endpoint={endpoint || '/upload-file/attachment'}
            onStart={onFileUploadStart}
            onSuccess={onFileUploadSuccess}
            onError={onFileUploadError}
            onProgress={onFileProgress}
            dropable={true}
            theme={theme}
            accept={accept}
          >
            <div className={styles.upload}>
              <div className={styles.uploadContainer}>
                <DriveFolderUploadOutlined
                  sx={{fontSize: 50}}
                  color="disabled"
                  className={styles.driveFolderUploadOutlinedIcon}
                />
                <div className={styles.middleText}>
                  <FormattedMessage id={onb.uploadDragAndDrop} />
                </div>
                <div className={styles.middleText}>
                  <FormattedMessage id={onb.uploadOr} />
                </div>
                <div className={styles.uploadFile}>
                  <span className={styles.uploadText}>
                    <FormattedMessage id={onb.uploadBrowseFiles} />
                  </span>
                </div>
              </div>
            </div>
          </FileUpload>
        </Grid>
        {integratedUploads && (
          <Grid item md={6} xs={12}>
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <div className={styles.integIconHolder}>
                  <p>
                    <FormattedMessage id={onb.uploadGoogleDrive} />
                  </p>
                  <div>
                    <img src={googleDrive} alt="Logo" />
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className={styles.integIconHolder}>
                  <p>
                    <FormattedMessage id={onb.uploadiCloud} />
                  </p>
                  <div>
                    <img src={iCloud} alt="Logo" />
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className={styles.integIconHolder}>
                  <p>
                    <FormattedMessage id={onb.uploadDropbox} />
                  </p>
                  <div>
                    <img src={dropBox} alt="Logo" />
                  </div>
                </div>
              </Grid>

              <Grid item xs={6}>
                <div className={styles.integIconHolder}>
                  <p>
                    <FormattedMessage id={onb.uploadOneDrive} />
                  </p>
                  <div>
                    <img src={oneDrive} alt="Logo" />
                  </div>
                </div>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
      <div className={styles.gridWrapper}>
        <Grid container>
          <Grid item className={styles.statusLeft}>
            <div className={styles.descriptionContainer}>
              <DescriptionOutlined />
            </div>
          </Grid>
          <Grid item style={{flexGrow: '1'}}>
            {!fileUploadDone && (
              <>
                <div className={styles.fileInfoProgressWrap}>
                  <div className={styles.fileNameInProgress}>
                    {fileUploadStarted && <>{file.name}</>}
                    {!fileUploadStarted && (
                      <>
                        <FormattedMessage id={onb.uploadNoFile} />
                      </>
                    )}
                  </div>
                  <div className={styles.fileProgressPercent}>
                    {Math.ceil(fileProgress) + ' %'}
                  </div>
                </div>
                {!fileUploadCompleted && (
                  <div className={styles.progressBar}>
                    {fileUploadStarted && (
                      <div
                        className={styles.progressBarProgress}
                        style={{width: Math.ceil(fileProgress) + '%'}}
                      ></div>
                    )}
                  </div>
                )}
                {fileUploadStarted && fileUploadCompleted && (
                  <div className={styles.progressBarDone}></div>
                )}
              </>
            )}
            {fileUploadDone && (
              <div className={styles.fileNameDone}>
                {fileUrl ? (
                  <a href={fileUrl} target="_blank">
                    {file.name}
                  </a>
                ) : (
                  <span>{file.name}</span>
                )}
              </div>
            )}
          </Grid>
          <Grid
            item
            xs={0}
            className={
              fileUploadDone ? styles.statusRightLong : styles.statusRight
            }
          >
            {!fileUploadCompleted && <div className={styles.iconEmpty}></div>}
            {fileUploadCompleted && !fileUploadDone && (
              <div className={styles.iconDone}>
                <CheckCircle />
              </div>
            )}
            {fileUploadDone && (
              <div className={styles.iconDelete}>
                <button type="button" onClick={removeFile}>
                  <DeleteOutline />
                  <FormattedMessage id={onb.uploadRemove} />
                </button>
              </div>
            )}
          </Grid>
        </Grid>
        {error && errorMessage && (
          <div className={styles.error} ref={fileRef}>
            <Warning />
            <TextError text={errorMessage} />
          </div>
        )}
      </div>
    </div>
  )
}
