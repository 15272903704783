import {connect} from 'react-redux'

import {EmployerOnboarding} from './EmployerOnboarding'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {getEmployerOnboarding} from 'Store/Actions'

const mapStateToProps = (state: RootState) => ({
  employerOnboarding: state.employerOnboarding,
  auth: state.auth,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getEmployerOnboarding: (companyId: string | undefined) =>
    dispatch(getEmployerOnboarding(companyId)),
})

export const EmployerOnboardingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerOnboarding)
