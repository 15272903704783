import {ApproveRejectModalRequestType} from 'Interfaces'
import {connect} from 'react-redux'

import {AppDispatch} from 'Store'
import {approveContactRequest, getAssessments} from 'Store/Actions'
import {ModalApproveContactRequest} from './ModalApproveContactRequest'

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  approveContactRequest: (payload: ApproveRejectModalRequestType) =>
    dispatch(approveContactRequest(payload)),
  getAssessments: () => dispatch(getAssessments()),
})

export const ModalApproveContactRequestContainer = connect(
  null,
  mapDispatchToProps
)(ModalApproveContactRequest)
