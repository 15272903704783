const registration = {
  'Enter your first name.': 'Enter your first name.',
  'Enter your last name.': 'Enter your last name.',
  'Passwords must match': 'Passwords must match',
  'Accepting Terms of service is required':
    'Accepting Terms of service is required',
  'Accepting Privacy policy is required':
    'Accepting Privacy policy is required',
}

export default registration
