import {
  ActionType,
  CandidateSearchFiltersType,
  SavedSearchType,
  SearchControlFormikValuesType,
} from 'Interfaces'
import {TestingInvitePayloadType} from 'Interfaces/CandidateType'
import * as type from '../types'
import {
  AddProjectForCandidateType,
  SendRequestContactType,
  SeekerEvaluationType,
} from 'Interfaces'

export const searchCandidates = (
  values: SearchControlFormikValuesType
): ActionType<SearchControlFormikValuesType> => {
  return {
    type: type.searchCandidates.search.requested,
    payload: values,
  }
}

export const loadMoreCandidates = (): ActionType<undefined> => {
  return {
    type: type.searchCandidates.loadMore.requested,
  }
}

export const sendRequestContact = (
  value: SendRequestContactType
): ActionType<SendRequestContactType> => {
  return {
    type: type.candidateSearch.requestContact.save.requested,
    payload: value,
  }
}

export const sendContactRequestsBulk = (
  ids: string[]
): ActionType<string[]> => {
  return {
    type: type.searchCandidates.sendContactRequestsBulk.requested,
    payload: ids,
  }
}

export const inviteToTestingBulk = (
  ids: string[],
  testType: string
): ActionType<TestingInvitePayloadType> => {
  return {
    type: type.searchCandidates.inviteToTestingBulk.requested,
    payload: {ids, testType},
  }
}

export const addProjectForCandidate = (
  value: AddProjectForCandidateType
): ActionType<AddProjectForCandidateType> => {
  return {
    type: type.candidateSearch.CandidateToProject.addProjectForCandidate
      .requested,
    payload: value,
  }
}
export const addSearchSeekerEvaluation = (
  seekerEvaluation: SeekerEvaluationType
): ActionType<SeekerEvaluationType> => {
  return {
    type: type.searchCandidates.seekerEvaluation.add.requested,
    payload: seekerEvaluation,
  }
}

export const editSearchSeekerEvaluation = (
  seekerEvaluation: SeekerEvaluationType
): ActionType<SeekerEvaluationType> => {
  return {
    type: type.searchCandidates.seekerEvaluation.edit.requested,
    payload: seekerEvaluation,
  }
}

export const getSearchKeywords = (): ActionType<undefined> => {
  return {
    type: type.searchKeywords.requested,
  }
}

export const sortCandidatesSearchResults = (
  sortBy: string
): ActionType<string> => {
  return {
    type: type.searchCandidates.sortResults.requested,
    payload: sortBy,
  }
}

export const saveCandidateSearch = (
  values: SavedSearchType
): ActionType<SavedSearchType> => {
  return {
    type: type.searchCandidates.saveSearch.requested,
    payload: values,
  }
}
export const updateSavedSearch = (
  values: SavedSearchType
): ActionType<SavedSearchType> => {
  return {
    type: type.searchCandidates.updateSavedSearch.requested,
    payload: values,
  }
}

export const getSavedSearch = (): ActionType<undefined> => {
  return {
    type: type.searchCandidates.getSavedSearch.requested,
  }
}

export const clearSavedSearch = (): ActionType<undefined> => {
  return {
    type: type.searchCandidates.clearSavedSearch.requested,
  }
}
export const clearSearch = (): ActionType<undefined> => {
  return {
    type: type.searchCandidates.clearSearch.requested,
  }
}
export const deleteSavedSearch = (id: string): ActionType<string> => {
  return {
    type: type.searchCandidates.deleteSavedSearch.requested,
    payload: id,
  }
}

export const getSavedSearchFavorites = (): ActionType<undefined> => {
  return {
    type: type.searchCandidates.getSavedSearchFavorites.requested,
  }
}

export const setCandidateSearchFiltersType = (
  filtersType: CandidateSearchFiltersType
): ActionType<CandidateSearchFiltersType> => {
  return {
    type: type.searchCandidates.setFiltersType.requested,
    payload: filtersType,
  }
}

export const getPopularCandidates = (): ActionType<undefined> => {
  return {
    type: type.searchCandidates.getPopularCandidates.requested,
  }
}
