import React from 'react'
import {IntlProvider} from 'react-intl'
import {Route, Redirect} from 'react-router-dom'
import {
  InvalidConfigError,
  MessageFormatError,
  MissingDataError,
  MissingTranslationError,
  UnsupportedFormatterError,
} from '@formatjs/intl/src/error'
import {FormatError} from 'intl-messageformat'

import {AppLanguage} from 'Services/I18n/Constants'
import {LanguageStringsType} from '../Localizations/LanguageStringsType'

interface Props {
  languages: {[k: number]: string}
  appStrings: {[prop: string]: LanguageStringsType}
  defaultLanguage: AppLanguage
}

export const LocalizedRouter: React.FC<Props> = ({
  children,
  appStrings,
  defaultLanguage,
}) => (
  <Route path="/:lang([a-zA-Z]{2})">
    {({match, location}) => {
      /**
       * Get current language
       * Set default locale to sl if base path is used without a language
       */
      let lang = match?.params?.lang || defaultLanguage
      const {pathname} = location
      if (!pathname.includes(`/${lang}/`)) {
        lang = defaultLanguage
      }

      /**
       * If language path matches default language redirect to path without lang
       */
      if (match?.params?.lang === defaultLanguage) {
        return <Redirect to={`${pathname.slice(3, pathname.length)}`} />
      }

      /**
       * Return Intl provider with default language set
       */
      const handleError = (
        err:
          | MissingTranslationError
          | MessageFormatError
          | MissingDataError
          | InvalidConfigError
          | UnsupportedFormatterError
          | FormatError
      ) => {
        if (process.env.REACT_APP_LANGUAGE_DEBUG_MODE === 'true') {
          console.log('language error', err)
        }
      }
      return (
        <IntlProvider
          locale={lang}
          messages={appStrings[lang]}
          onError={handleError}
        >
          {children}
        </IntlProvider>
      )
    }}
  </Route>
)
