export enum CompanyImpressionsTranslations {
  impressionsTitle = 'companyImpressions.impressionsTitle',
  featuredTitle = 'companyImpressions.featuredTitle',
  modalTitle = 'companyImpressions.modalTitle',
  modalImpressionsTitle = 'companyImpressions.modalImpressionsTitle',
  modalImpressionsTitleTitle = 'companyImpressions.modalImpressionsTitleTitle',
  filesNumberLimit = 'companyImpressions.filesNumberLimit',
  supportedFileFormats = 'companyImpressions.supportedFileFormats',
  apiError = 'companyImpressions.apiError',
}
