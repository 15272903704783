// @ts-nocheck
import ReactGallery from 'reactive-blueimp-gallery'
import {ChangeEvent, FC, useEffect, useMemo, useRef, useState} from 'react'
import {useFormik} from 'formik'
import {
  DeleteOutline,
  DragIndicator,
  PlayCircle,
  VideocamOutlined,
} from '@mui/icons-material'
import {cloneDeep} from 'lodash'

import styles from './Video.module.scss'
import Modal, {SubmitFooter} from 'Components/Modal'
import SectionHeader from 'Components/Profile/SectionHeader'
import SectionItem from 'Components/Profile/SectionItem'
import ItemHeader from 'Components/Profile/ItemHeader'
import Hr from 'Components/Hr'
import Title from 'Components/Title'
import {CompanyStateType, VideoFormikType, VideoType} from 'Interfaces'
import ShowMoreContent from 'Components/ShowMoreContent'
import {useApiError} from 'Hooks'
import TextField from 'Components/Inputs/TextField'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import {reorder} from 'Utils/Array'
import classnames from 'classnames'
import {Button} from 'Components/Button'
import {CompanyVideoTranslations as cvt} from 'Services/I18n/Constants'
import Validation from './Validation'

interface VideosProps {
  canChange?: boolean
  company: CompanyStateType
  addVideos: (videos: VideoType[]) => void
  updateVideos: (videos: VideoType[]) => void
}

export const Video: FC<VideosProps> = ({
  canChange,
  company,
  addVideos,
  updateVideos,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalState, setModalState] = useState<'Add' | 'Edit'>('Add')

  const defaultValues = {
    videos: company.data.videos,
    titles: company.data.videos.map((video) => video.title || ''),
  }

  const formik = useFormik<VideoFormikType>({
    initialValues: {...defaultValues},
    validationSchema: Validation,
    onSubmit: (values) => {
      const saveValues = values.videos.map((video, index) => ({
        type: 'youtube',
        url: video.url,
        title: values.titles[index],
        order: company.data.videos.length,
      }))
      if (modalState === 'Add') addVideos(saveValues)
      else updateVideos(saveValues)
    },
  })

  const openEdit = () => {
    setModalState('Edit')
    formik.resetForm({
      values: defaultValues,
    })
    setYtVideo('')
    setModalOpen(true)
  }

  const openAdd = () => {
    setModalState('Add')
    formik.resetForm({
      values: {
        videos: [],
        titles: [],
      },
    })
    setYtVideo('')
    setModalOpen(true)
  }

  const handleAddVideo = () => {
    if (youtube_parser(ytVideo)) {
      formik.setFieldValue('videos', [
        ...formik.values.videos,
        {
          url: ytVideo,
          order: formik.values.videos.length,
        },
      ])
      setYtVideo('')
    }
  }

  useApiError(formik.setFieldError, company.error)

  const handleDelete = (index: number) => {
    formik.setFieldValue(
      'videos',
      formik.values.videos.filter((_, i) => i !== index)
    )
  }

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const newItems = reorder(
      formik.values.videos,
      result.source.index,
      result.destination.index
    )

    const newTitles = reorder(
      formik.values.titles,
      result.source.index,
      result.destination.index
    )

    formik.setFieldValue('videos', newItems)
    formik.setFieldValue('titles', newTitles)
  }

  const handleTitleChange = (
    e: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const titles = cloneDeep(formik.values.titles)
    titles[index] = e.currentTarget.value
    formik.setFieldValue('titles', titles)
  }

  const youtube_parser = (url) => {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    const match = url.match(regExp)
    return match && match[7].length == 11 ? match[7] : false
  }

  const videos = useMemo(() => {
    return company.data?.videos.map((video) => {
      const ytId = youtube_parser(video.url)
      return {
        ...video,
        type: 'text/html',
        href: video.url,
        'data-youtube': ytId,
        poster: `https://img.youtube.com/vi/${ytId}/hqdefault.jpg`,
      }
    })
  }, [company.data?.videos])

  const childRef = useRef()

  const [ytVideo, setYtVideo] = useState('')

  useEffect(() => {
    if (!company.loading && !company.error) setModalOpen(false)
  }, [company.loading])

  return (
    <div className={styles.section}>
      {(company.data.videos.length || canChange) && (
        <div>
          <SectionHeader
            title={cvt.videoTitle}
            onAdd={canChange ? openAdd : undefined}
            className={styles.header}
          />
        </div>
      )}
      {company.data.videos.length ? (
        <SectionItem className={styles.sectionItem}>
          <ItemHeader
            title={cvt.featuredVideoTitle}
            icon={VideocamOutlined}
            onEdit={
              canChange && company.data.videos.length ? openEdit : undefined
            }
            className={styles.itemHeader}
          />
          <ShowMoreContent
            childRef={childRef}
            className={classnames(
              styles.showMoreContent,
              styles.showMoreContentStronger
            )}
            forceChildHeight
            withMargins
          >
            <div className={styles.galleryWrap}>
              <ReactGallery
                withControls
                key={2}
                options={{youTubeClickToPlay: false}}
              >
                {videos.map((item, index) => {
                  return (
                    <div
                      ref={!index ? childRef : null}
                      key={item.id || index}
                      className={styles.singleVideoWrap}
                    >
                      <div className={styles.slideWrap}>
                        <ReactGallery.Slide {...item} />
                        <PlayCircle className={styles.playCircle} />
                      </div>
                      <div className={styles.galleryItemTitle}>
                        {item.title}
                      </div>
                      <div>
                        {item.time} {item.views ? `(${item.views} views)` : ''}
                      </div>
                    </div>
                  )
                })}
              </ReactGallery>
            </div>
          </ShowMoreContent>
        </SectionItem>
      ) : null}

      <Modal
        isOpen={modalOpen}
        onAfterClose={() => {
          setModalOpen(false)
        }}
        title={modalState === 'Add' ? cvt.modalTitleAdd : cvt.modalTitleEdit}
        icon={VideocamOutlined}
        ariaHideApp={false}
        className={styles.modal}
        theme="desktopFlex"
        Footer={
          <SubmitFooter
            onSubmit={formik.handleSubmit}
            onCancel={() => {
              setModalOpen(false)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <Title
          text={modalState === 'Add' ? cvt.modalTitleAdd : cvt.modalTitleEdit}
          theme="modal"
        />
        {formik.values.videos.length < 12 && (
          <div className={styles.addYtWrap}>
            <TextField
              name="ytVideo"
              placeholder={cvt.modalVideoPlaceholderText}
              value={ytVideo}
              onChange={(e) => setYtVideo(e.currentTarget.value)}
              className={styles.textInput}
              theme="modal"
            />
            <div className={styles.buttonContainer}>
              <Button
                text={cvt.showVideo}
                theme="bright"
                className={styles.button}
                onClick={handleAddVideo}
              />
            </div>
          </div>
        )}

        {formik.values.videos.length > 0 && (
          <>
            {modalState === 'Add' && formik.values.videos[0].url ? (
              <div className={styles.addContainer}>
                <div className={styles.bigVideo}>
                  <img
                    src={`https://img.youtube.com/vi/${youtube_parser(
                      formik.values.videos[0].url
                    )}/maxresdefault.jpg`}
                  />
                  <PlayCircle className={styles.playCircleBig} />
                </div>
                <Hr theme="modalBig" />
                <Title
                  text={cvt.modalVideoTitleTitle}
                  className={classnames(styles.title, styles.titleStronger)}
                  theme="modal"
                />
                <TextField
                  name="imageTitle-0"
                  placeholder={formik.values.videos[0].originalName}
                  value={formik.values.titles[0] || ''}
                  onChange={(e) => {
                    handleTitleChange(e, 0)
                  }}
                  theme="modal"
                  className={classnames({
                    [styles.textField]:
                      formik.errors.titles && formik.errors.titles[0],
                  })}
                  error={
                    formik.errors.titles ? formik.errors.titles[0] : undefined
                  }
                />
              </div>
            ) : null}

            {modalState === 'Edit' && (
              <>
                <Hr theme="modalBig" />
                <Title
                  text={cvt.modalTitleEdit}
                  className={classnames(styles.title, styles.titleStrongerEdit)}
                  theme="modal"
                />
                <div className={styles.dndContainer}>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable">
                      {(droppableProvided) => (
                        <div
                          ref={droppableProvided.innerRef}
                          {...droppableProvided.droppableProps}
                          className={styles.list}
                        >
                          {formik.values.videos.map((item, index) => (
                            <Draggable
                              key={item.id || `${item.url}-${index}`}
                              draggableId={item.id || `${item.url}-${index}`}
                              index={index}
                            >
                              {(draggableProvided, draggableSnapshot) => (
                                <div
                                  className={classnames(styles.item, {
                                    itemDragging: draggableSnapshot.isDragging,
                                  })}
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                >
                                  <div className={styles.firstRow}>
                                    <div
                                      {...draggableProvided.dragHandleProps}
                                      className={styles.handle}
                                    >
                                      <DragIndicator />
                                    </div>
                                    <div className={styles.imageItemContainer}>
                                      <div>
                                        <img
                                          className={styles.image}
                                          src={`https://img.youtube.com/vi/${youtube_parser(
                                            item.url
                                          )}/default.jpg`}
                                          alt={item.originalName}
                                        />
                                      </div>
                                      <div className={styles.desktopOnly}>
                                        <TextField
                                          name={`imageTitle-${index}`}
                                          placeholder={item.originalName}
                                          value={
                                            formik.values.titles[index] || ''
                                          }
                                          onChange={(e) => {
                                            handleTitleChange(e, index)
                                          }}
                                          className={classnames({
                                            [styles.textField]:
                                              formik.errors.titles &&
                                              formik.errors.titles[index],
                                          })}
                                          error={
                                            formik.errors.titles
                                              ? formik.errors.titles[index]
                                              : undefined
                                          }
                                        />
                                      </div>
                                      <div>
                                        <DeleteOutline
                                          onClick={() => {
                                            handleDelete(index)
                                          }}
                                          className={styles.mobileDeleteIcon}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className={styles.mobileOnly}>
                                    <TextField
                                      name={`imageTitle-${index}`}
                                      placeholder={item.originalName}
                                      value={formik.values.titles[index] || ''}
                                      onChange={(e) => {
                                        handleTitleChange(e, index)
                                      }}
                                      error={
                                        formik.errors?.titles
                                          ? formik.errors.titles[index]
                                          : undefined
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {droppableProvided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </div>
              </>
            )}
          </>
        )}
      </Modal>
    </div>
  )
}
