interface TownAndCountryProps {
  town?: {
    translation: string
  }
  country?: {
    translation: string
  }
}

export const TownAndCountry = ({
  town,
  country,
}: TownAndCountryProps): string => {
  return `${town?.translation ? town?.translation : ''}${
    town?.translation && country?.translation ? ', ' : ''
  }${country?.translation ? country?.translation : ''}`
}
