import {connect} from 'react-redux'

import {HelpfulHint} from './HelpfulHint'
import {AppDispatch} from 'Store'
import {getHelpfulHintStatus, setHelpfulHintStatus} from 'Store/Actions'
import {RootState} from 'Store/Reducers'
import { HelpfulHintType } from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  helpfullHintClosed: state.widgetBar.data.helpfullHintValue.active,
})
const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setHelpfulHintStatus: (helpfulHintValue: HelpfulHintType) =>
    dispatch(setHelpfulHintStatus(helpfulHintValue)),
  getHelpfulHintStatus: (name: string) => 
    dispatch(getHelpfulHintStatus(name)),

})

export const HelpfulHintContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HelpfulHint)
