export const support = {
  'support.title': 'Pomoč in podpora',
  'support.ctaTitle': 'Brskajte',
  'support.ctaText': 'Najdite odgovore na svoja vprašanja',
  'support.ctaTitle2': 'Pošljite sporočilo',
  'support.ctaText2': 'Običajno se odzovemo v 24 urah',
  'support.ctaTitle3': 'Pokličite nas',
  'support.ctaText3': 'Delovniki',
  'support.title2': 'Pogosto zastavljena vprašanja',
  'support.cardTitle': 'Splošno',
  'support.cardQuestion1': 'Kaj je Preferenca?',
  'support.cardAnswer1':
    'Preferenca.si je platforma, ki povezuje talente in podjetja na način, da se podjetja potegujejo za kandidate in ne obratno. Tako zagotavlja popolnoma novo izkušnjo najboljšim kandidatom, hkrati pa podjetjem omogoča hiter in učinkovit postopek zaposlovanja.',
  'support.cardAnswer1SecondPart':
    'Na portalu se predhodno verificirani kandidati predstavijo s svojimi profili, kjer prikažejo svoje izkušnje, znanja in želje. Podjetja pa prejmejo dostope do seznama ustreznih kandidatov, s katerimi lahko neposredno komunicirajo in opravijo razgovore.',
  'support.cardQuestion2': 'Ali lahko objavim oglas na Preferenci?',
  'support.cardQuestion3': 'Kako deluje Preferenca?',
  'support.cardAnswer3':
    'Preferenca.si je namreč dvostranska tržnica, zasnovana na način, da zagotavlja neprimerljivo izkušnjo najboljšim kandidatom, hkrati pa podjetjem omogoča hiter in učinkovit postopek zaposlovanja. Na portalu se predhodno verificirani kandidati predstavijo s svojimi profili in prikazujejo svoje izkušnje, znanja in želje. Podjetja določijo svoja merila za novo zaposlitev in prejmejo dostop do seznama ustreznih kandidatov, s katerimi lahko neposredno komunicirajo in opravijo razgovore.',
  'support.cardAnswer3SecondPart':
    'Kot že samo ime pove, je to model zaposlovanja, pri katerem se podjetja prijavijo na profile predstavljenih kandidatov, in zamenjuje dosedanji način, kjer so se kandidati prijavljali na prosta delovna mesta.',
  'support.cardQuestion4': 'Prednosti portala Preferenca.si ',
  'support.cardAnswer4EmployersTitle': 'Prednosti za delodajalce: ',
  'support.cardAnswer4EmployersText':
    'Imeti samo najboljše kandidate na portalu pomeni, da vam ni treba izgubljati časa za nekvalificirane ali neustrezne kandidate. Poleg tega sprejeti kandidati na portalu precej aktivno iščejo svojo naslednjo priložnost za zaposlitev, zato so odzivnejši v celotnem procesu zaposlovanja. To bistveno skrajša čas kadrovikov; hitreje najdejo ustrezne kandidate, ki so odzivni na prošnje za razgovor.',
  'support.cardAnswer4CandidatesText':
    'Portal deluje popolnoma anonimno ter spoštuje zasebnost do trenutka, ko se strinjate z objavo svojih podatkov. Sodelujete le s preverjenimi in najboljšimi slovenskimi delodajalci, ki se z vami želijo povezati zaradi vaših sposobnosti in potenciala. Sami nadzorujete svojo kariero ter odločate, ali boste razkrili svoje podatke za stik ali ne, obenem pa ne ogrožate svoje trenutne zaposlitve.',
  'support.cardAnswer4CandidatesTitle': 'Prednosti za kandidate: ',
  'support.card2Title': 'Za kandidate',
  'support.card2Question1': 'Katere oblike življenjepisov podpira platforma?',
  'support.card2Question2':
    'Izpolnil sem profil, vendar nisem dosegel 100 %, kaj to pomeni?',
  'support.card2Question3': 'Ali je moj profil viden trenutnemu delodajalcu?',
  'support.card3Title': 'Za delodajalce',
  'support.card3Question1': 'Kako najučinkoviteje pristopiti k talentom...',
  'support.card3Question2': 'Kako postaviti lastno blagovno znamko d...',
  'support.card3Question3': 'Kateri kandidati bi se najbolje ujemali z...',
  'support.viewAll': 'Pokažite vse',
  'support.card1': 'Spremenite pravila igre zaposlovanja!',
  'support.card2': 'Zakaj zaposlovati s Preferenco?',
  'support.more': 'VEČ',
}
