import {PsychometricTestResults} from './PsychometricTestResults'
import {AppDispatch} from 'Store'
import {connect} from 'react-redux'
import {RootState} from 'Store/Reducers'
import {
  getAssessments,
  getCandidateProfile,
  getPsychometricResultsStatements,
  getPsychometricTestResults,
  getSeekerAssessments,
  getSeekerPersonalData,
} from 'Store/Actions'

const mapStateToProps = (state: RootState) => ({
  candidate: state.employer.data.candidate.data.profile,
  psychometricResultsStatements:
    state.assessments.psychometricResultsStatements,
  seeker: state.seeker,
  auth: state.auth,
  assessments: state.assessments.data as unknown as [],
  psychometricDownloadLink:
    state.assessments.psychometricTestResults.resultFile,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getPsychometricTestResults: (id: string) =>
    dispatch(getPsychometricTestResults(id)),
  getPsychometricResultsStatements: (id: string) =>
    dispatch(getPsychometricResultsStatements(id)),
  getCandidateProfile: (id: string) => dispatch(getCandidateProfile(id)),
  getSeekerData: () => {
    dispatch(getSeekerPersonalData())
  },
  getSeekerAssessments: (seekerId: string) =>
    dispatch(getSeekerAssessments(seekerId)),
  getAssessments: () => dispatch(getAssessments()),
})

export const PsychometricTestResultsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PsychometricTestResults)
