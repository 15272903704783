export const psychometricAssessmentsTranslation = {
  'psychometricassessmentstranslations.psychometricTestTitle':
    'Psihometrični test',
  'psychometricassessmentstranslations.psychometricTestResults':
    'Rezultati psihometričnega testiranja',
  'psychometricassessmentstranslations.psychometricTestResultsUpperCase':
    'Rezultati Psihometričnega Testiranja',
  'psychometricassessmentstranslations.psychometricTestNoConsentBoxValue':
    'Rezultatov psihometričnega testiranja vam ne moremo prikazati, dokler ne podate ustreznega soglasja. To lahko storite v',
}
