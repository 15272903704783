import {FC} from 'react'
import {Link} from 'react-router-dom'
import {FormattedMessage} from 'react-intl'

import Hr from 'Components/Hr'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'

import styles from './PublicProfileSidebarText.module.scss'
import {AccountCircleOutlined} from '@mui/icons-material'
import {OverridableComponent} from '@mui/material/OverridableComponent'
import {SvgIconTypeMap} from '@mui/material'

type PublicProfileSidebarTextProps = {
  title: string
  text: string
  publicProfile?: boolean
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string
  }
  linkText?: string
}

export const PublicProfileSidebarText: FC<PublicProfileSidebarTextProps> = ({
  title,
  text,
  icon = AccountCircleOutlined,
  linkText,
}) => {
  const Icon = icon
  return (
    <>
      <div className={styles.titleWrap}>
        {Icon && <Icon className={styles.icon} />}
        <div className={styles.title}>{title}</div>
      </div>
      <Hr className={styles.hr} />
      <div className={styles.text}>
        {text}{' '}
        {linkText && (
          <Link to={getRoute(AppRoute.PublicProfile)}>
            <FormattedMessage id={linkText} />
          </Link>
        )}
      </div>
    </>
  )
}
