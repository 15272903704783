import {FC, useEffect, useState} from 'react'
import {
  TaxonomiesStateType,
  ErrorType,
  WelcomeMessageDataType,
  AuthStateType,
  BaseTaxonomyType,
} from 'Interfaces'
import styles from './WelcomeMessagePublic.module.scss'
import {FavoriteBorder} from '@mui/icons-material'
import {HTMLText} from 'Components/HTMLText'

interface WelcomeMessagePublicProps {
  getTaxonomies: () => void
  getCompanyData: () => void
  getSeekerProfile: () => void
  auth: AuthStateType
  seekerJobCategory?: BaseTaxonomyType
  taxonomies: TaxonomiesStateType
  welcomeMessages: WelcomeMessageDataType[]
  canChange?: boolean
  apiError?: ErrorType
}

export const WelcomeMessagePublic: FC<WelcomeMessagePublicProps> = ({
  seekerJobCategory,
  welcomeMessages,
  getSeekerProfile,
  getCompanyData,
}) => {
  // Filter messages by language:
  const [filteredWelcomeMessages, setFilteredWelcomeMessages] =
    useState(welcomeMessages)

  // Mounting - Fetch company data (welcomeMessages):
  useEffect(() => {
    getCompanyData()
    getSeekerProfile()
  }, [])

  useEffect(() => {
    let filteredMessagesDraft = welcomeMessages.filter(
      (message) => message.jobCategory.id === seekerJobCategory?.id
    )

    // Filter Messages by Visibility status:
    filteredMessagesDraft = filteredMessagesDraft.filter((message) => {
      const filteredTranslatedTexts = message.translatedTexts.filter(
        (translatedTxt) => {
          return translatedTxt.visibility === true
        }
      )
      return filteredTranslatedTexts.length > 0 ? true : false
    })

    setFilteredWelcomeMessages(filteredMessagesDraft)
  }, [welcomeMessages])

  if (filteredWelcomeMessages.length === 0) return null

  return (
    <div className={styles.mainContent}>
      <div className={styles.leftContent}>
        <FavoriteBorder className={styles.heartIcon} fontSize="large" />
      </div>
      <div className={styles.breakLine}></div>

      <div className={styles.rightContent}>
        <h1 className={styles.title}>
          {filteredWelcomeMessages[0]?.translatedTexts[0]?.welcomeTitle
            ? filteredWelcomeMessages[0]?.translatedTexts[0]?.welcomeTitle
            : ''}
        </h1>
        <div className={styles.paragraph}>
          <HTMLText
            htmlInputValue={
              filteredWelcomeMessages[0]?.translatedTexts[0]?.welcomeText
            }
          />
        </div>
      </div>
    </div>
  )
}
