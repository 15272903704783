export enum CompanyFaqTranslations {
  faqTitle = 'companyFaq.faqTitle',

  basicInformation = 'companyFaq.basicInformation',

  moreText = 'companyFaq.moreText',
  lessText = 'companyFaq.lessText',

  modalFaqFullTitle = 'companyFaq.modalFaqFullTitle',
  modalAddQuestion = 'companyFaq.modalAddQuestion',

  enterQuestionPlaceholderText = 'companyFaq.enterQuestionPlaceholderText',

  deleteText = 'companyFaq.deleteText',

  showMoreText = 'companyFaq.showMoreText',
  showLessText = 'companyFaq.showLessText',

  modalAddText = 'companyFaq.modalAddText',
  modalEditText = 'companyFaq.modalEditText',

  questionForCandidate1 = 'companyFaq.questionForCandidate1',
  questionForCandidate2 = 'companyFaq.questionForCandidate2',
  questionForCandidate3 = 'companyFaq.questionForCandidate3',
  questionForCandidate4 = 'companyFaq.questionForCandidate4',
  questionForCandidate5 = 'companyFaq.questionForCandidate5',
  questionForCandidate6 = 'companyFaq.questionForCandidate6',
  questionForCandidate7 = 'companyFaq.questionForCandidate7',
  questionForCandidate8 = 'companyFaq.questionForCandidate8',

  answer1ForCandidate1 = 'companyFaq.answerForCandidate1',
  answer1ForCandidate2 = 'companyFaq.answerForCandidate2',
  answer1ForCandidate3 = 'companyFaq.answerForCandidate3',
  answer1ForCandidate4 = 'companyFaq.answerForCandidate4',
  answer1ForCandidate5 = 'companyFaq.answerForCandidate5',
  answer1ForCandidate6 = 'companyFaq.answerForCandidate6',
  answer1ForCandidate7 = 'companyFaq.answerForCandidate7',
  answer1ForCandidate8 = 'companyFaq.answerForCandidate8',

  questionForEmployer1 = 'companyFaq.questionForEmployer1',
  answerForEmployer1 = 'companyFaq.answerForEmployer1',
  questionForEmployer2 = 'companyFaq.questionForEmployer2',
  answerForEmployer2PartOne = 'companyFaq.answerForEmployer2PartOne',
  answerForEmployer2PartTwo = 'companyFaq.answerForEmployer2PartTwo',
  questionForEmployer3 = 'companyFaq.questionForEmployer3',
  answerForEmployer3 = 'companyFaq.answerForEmployer3',
  answerForEmployer3FirstPart = 'companyFaq.answerForEmployer3FirstPart',
  answerForEmployer3FirstPartTitle = 'companyFaq.answerForEmployer3FirstPartTitle',
  answerForEmployer3SecondPartTitle = 'companyFaq.answerForEmployer3SecondPartTitle',
  answerForEmployer3SecondPart = 'companyFaq.answerForEmployer3SecondPart',
  answerForEmployer3ThirdPartTitle = 'companyFaq.answerForEmployer3ThirdPartTitle',
  answerForEmployer3ThirdPart = 'companyFaq.answerForEmployer3ThirdPart',
  answerForEmployer3FourthPartTitle = 'companyFaq.answerForEmployer3FourthPartTitle',
  answerForEmployer3FourthPart = 'companyFaq.answerForEmployer3FourthPart',
  answerForEmployer3FifthPartTitle = 'companyFaq.answerForEmployer3FifthPartTitle',
  answerForEmployer3FifthPart = 'companyFaq.answerForEmployer3FifthPart',
  answerForEmployer3FifthPart2 = 'companyFaq.answerForEmployer3FifthPart2',
  questionForEmployer4 = 'companyFaq.questionForEmployer4',
  answerForEmployer4 = 'companyFaq.answerForEmployer4',
  questionForEmployer5 = 'companyFaq.questionForEmployer5',
  answerForEmployer5 = 'companyFaq.answerForEmployer5',
  questionForEmployer6 = 'companyFaq.questionForEmployer6',
  answerForEmployer6 = 'companyFaq.answerForEmployer6',
  questionForEmployer7 = 'companyFaq.questionForEmployer7',
  answerForEmployer7 = 'companyFaq.answerForEmployer7',
  questionForEmployer8 = 'companyFaq.questionForEmployer8',
  answerForEmployer8 = 'companyFaq.answerForEmployer8',
  questionForEmployer9 = 'companyFaq.questionForEmployer9',
  answerForEmployer9 = 'companyFaq.answerForEmployer9',
  questionForEmployer10 = 'companyFaq.questionForEmployer10',
  answerForEmployer10 = 'companyFaq.answerForEmployer10',
  questionForEmployer11 = 'companyFaq.questionForEmployer11',
  answerForEmployer11 = 'companyFaq.answerForEmployer11',
  questionForEmployer12 = 'companyFaq.questionForEmployer12',
  answerForEmployer12 = 'companyFaq.answerForEmployer12',
  questionForEmployer13 = 'companyFaq.questionForEmployer13',
  answerForEmployer13Title = 'companyFaq.answerForEmployer13Title',
  answerForEmployer13 = 'companyFaq.answerForEmployer13',
  answerForEmployer13SecondPartTitle = 'companyFaq.answerForEmployer13SecondPartTitle',
  answerForEmployer13SecondPart = 'companyFaq.answerForEmployer13SecondPart',
  answerForEmployer13ThirdPartTitle = 'companyFaq.answerForEmployer13ThirdPartTitle',
  answerForEmployer13ThirdPart = 'companyFaq.answerForEmployer13ThirdPart',
  answerForEmployer13FourthPartTitle = 'companyFaq.answerForEmployer13FourthPartTitle',
  answerForEmployer13FourthPart = 'companyFaq.answerForEmployer13FourthPart',
  answerForEmployer13FifthPartTitle = 'companyFaq.answerForEmployer13FifthPartTitle',
  answerForEmployer13FifthPart = 'companyFaq.answerForEmployer13FifthPart',

  questionForEmployer14 = 'companyFaq.questionForEmployer14',
  answerForEmployer14 = 'companyFaq.answerForEmployer14',

  forCandidate = 'companyFaq.forCandidate',
  support = 'companyFaq.support',
  faq = 'companyFaq.faq',
}
