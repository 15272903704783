import {useState} from 'react'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium'

import {TrainingTitleType} from 'Interfaces'
import {TestName} from '../TestName'
import classNames from 'classnames'
import {TestField} from '../TestField'
import {GeneralAssessmentsTranslations as gat} from 'Services/I18n/Constants'
import {useTranslation} from 'Hooks'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

import styles from './SelectDropdownTests.module.scss'
import defaultCertIcon from 'Assets/Images/default-cert-icon.png'

interface SelectDropdownTestsProps {
  skillTests: TrainingTitleType[]
  testCategory: string
  onEdit: (skill: TrainingTitleType) => void
  setDeleteModalOpen: (open: boolean) => void
  setDeleteId: (id: string) => void
  isRecruiter?: boolean
}

export const SelectDropdownTests = ({
  skillTests,
  testCategory,
  onEdit,
  setDeleteModalOpen,
  setDeleteId,
  isRecruiter = false,
}: SelectDropdownTestsProps): JSX.Element => {
  const [isActive, setActive] = useState(true)

  const translation = useTranslation()

  return (
    <>
      <div className={styles.dropdownBtn} onClick={() => setActive(!isActive)}>
        <div className={styles.dropdownTitleWrapper}>
          <div className={styles.iconWrapper}>
            <WorkspacePremiumIcon className={styles.medalIcon} />
          </div>
          <div className={styles.testCategoryTitle}>{testCategory}</div>
        </div>
        <div>
          <ArrowDropDownIcon
            className={classNames(styles.arrow)}
            style={{
              transform: isActive ? 'rotate(180deg)' : 'rotate(0deg)',
              transition: 'transform 0.2s ease',
            }}
          />
        </div>
      </div>
      {isActive && (
        <div className={styles.dropdownContent}>
          {skillTests.map((test) => (
            <div className={styles.testContainer} key={test.id}>
              <div className={styles.testContent}>
                <div className={styles.dynamicTestValues}>
                  <TestName
                    testName={test.trainingTitle.translation}
                    className={styles.skillTestsName}
                    icon={
                      <img
                        src={test.trainingTitle.icon?.url || defaultCertIcon}
                        alt={test.trainingTitle.translation}
                        className={styles.trainingIcon}
                      />
                    }
                  />
                  <div className={classNames(styles.testWrapper)}>
                    <TestField
                      label={gat.testProviderLabel}
                      text={test.provider}
                    />
                  </div>
                  <div className={styles.testWrapper}>
                    <TestField
                      label={gat.testDateLabel}
                      text={
                        test.dateOfTest &&
                        new Date(test.dateOfTest).toLocaleDateString('sl-SI')
                      }
                      className={styles.testMobileDateWrapper}
                    />
                  </div>
                  <div className={styles.testWrapper}>
                    <TestField
                      label={gat.testExpirationDateLabel}
                      text={
                        test.expirationDate
                          ? new Date(test.expirationDate).toLocaleDateString(
                              'sl-SI'
                            )
                          : translation[gat.unlimited]
                      }
                      className={styles.testMobileExpirationDateWrapper}
                    />
                  </div>
                  {isRecruiter && (
                    <div className={styles.iconWrapper}>
                      <div className={styles.icon}>
                        <button
                          onClick={() =>
                            onEdit({
                              id: test.id,
                              provider: test.provider,
                              dateOfTest: test.dateOfTest,
                              expirationDate: test.expirationDate,
                              trainingTitle: test.trainingTitle,
                            })
                          }
                        >
                          <EditOutlinedIcon />
                        </button>
                      </div>
                      <div className={styles.icon}>
                        <button
                          onClick={() => {
                            setDeleteModalOpen(true)
                            setDeleteId(test.id)
                          }}
                        >
                          <DeleteOutlinedIcon />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  )
}
