import {connect} from 'react-redux'
import {AppDispatch} from 'Store'
import {getSeekerProfile, contactUs} from 'Store/Actions'
import {RootState} from 'Store/Reducers'
import {HelpSupportContactUs} from './HelpSupportContactUs'
import {ContactUsType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  seeker: state.seeker,
  auth: state.auth,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  sendContactUs: (data: ContactUsType) => dispatch(contactUs(data)),
  getSeekerProfile: () => dispatch(getSeekerProfile()),
})

export const HelpSupportContactUsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HelpSupportContactUs)
