import * as Yup from 'yup'
import {Email, LongDescription, PhoneNumber} from 'Validations'
const Validation = Yup.object().shape({
  firstAndLastName: Yup.string()
    .max(50, 'Must be 50 characters or less')
    .required('First and last name are required'),
  company: Yup.string()
    .max(50, 'Must be 50 characters or less')
    .required('Company is required'),
  email: Email,
  subject: Yup.string().required('Street and number are required'),
  phone: PhoneNumber.required('Phone number is required'),
  message: LongDescription.required('Message is required'),
})

export default Validation
