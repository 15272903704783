import {FC, useEffect, useMemo, useState} from 'react'
import MainLayout from 'Layouts/MainLayout'

import CandidateSearchSidebarContentContainer from 'Components/CandidateSearch/CandidateSearchSidebarContent'
import SearchControl from 'Components/CandidateSearch/SearchControl'
import MyProjects from 'Components/Widgets/MyProjects'
import {
  BaseTaxonomyType,
  Candidate,
  CandidateSearchResultsStateType,
  CompanyDataType,
  DataType,
  IncomingRequestSeekerType,
  SavedSearchDataType,
  StateType,
  TaxonomiesStateType,
  CandidateSearchFiltersType,
  SearchControlFormikValuesType,
  PopularCandidateType,
  SavedSearchType,
} from 'Interfaces'
import styles from './CandidateSearch.module.scss'
import CandidateSearchResults from 'Components/CandidateSearch/CandidateCard/CandidateSearchResults'
import {WidgetBarContainer} from 'Layouts/MainLayout/WidgetBar'
import NoCandidate from 'Components/CandidateSearch/NoResultPage/NoCandidate'
import MiniCards from 'Components/CandidateSearch/NoResultPage/NoCandidate/MiniCards'
import {areAllTaxonomiesLoaded} from 'Utils/Search'
import {FormattedMessage} from 'react-intl'
import {CandidateSearchTranslations as cs} from 'Services/I18n/Constants/Employer/Search'
import {useHandleFocus} from 'Components/Employer/Hooks'
import Loader from 'Components/Fallbacks/Loader'
import {SidebarLink} from 'Components/SidebarLink'
import {AppRoute} from 'Services/I18n/Constants'
interface CompanyProfileProps {
  company: StateType<CompanyDataType>
  getCompanyData: () => void
  getTaxonomies: () => void
  getSearchResults: (values: SearchControlFormikValuesType) => void
  getSearchKeywords: () => void
  taxonomies: TaxonomiesStateType
  sidebarClosed: boolean
  candidateBasicData: Candidate | IncomingRequestSeekerType | null
  searchKeywords: DataType<BaseTaxonomyType>
  loadMoreCandidates: () => void
  searchResults: CandidateSearchResultsStateType
  savedSearch: SavedSearchDataType
  getSavedSearch: () => void
  clearSearch: () => void
  filtersType: CandidateSearchFiltersType
  setFiltersType: (filterType: CandidateSearchFiltersType) => void
  popularCandidates: DataType<PopularCandidateType>
  getPopularCandidates: () => void
  updateSavedSearch: (value: SavedSearchType) => void
  saveCandidateSearch: (value: SavedSearchType) => void
  setCandidateBasicData: (
    candidate: Candidate | IncomingRequestSeekerType | null
  ) => void
}

export const CandidateSearch: FC<CompanyProfileProps> = ({
  // company,
  getCompanyData,
  candidateBasicData,
  taxonomies,
  getTaxonomies,
  getSearchResults,
  getSearchKeywords,
  searchKeywords,
  loadMoreCandidates,
  searchResults,
  savedSearch,
  getSavedSearch,
  clearSearch,
  filtersType,
  setFiltersType,
  popularCandidates,
  getPopularCandidates,
  saveCandidateSearch,
  updateSavedSearch,
  setCandidateBasicData,
}) => {
  const [commentFocusTrigger, handleCommentFocus] = useHandleFocus()
  useEffect(() => {
    //closes the widget bar of a certain candidate if a user comes from EmployerCandidateProfile page:
    setCandidateBasicData(null)
  }, [])
  const setSidebarContent = () => {
    if (candidateBasicData) {
      return (
        <CandidateSearchSidebarContentContainer
          commentFocusTrigger={commentFocusTrigger}
          isFromSearch
        />
      )
    }
    return (
      <div>
        <div className={styles.sideContentWrap}>
          <SidebarLink
            text={cs.editCVSsubscriptions}
            routeName={AppRoute.SavedSearch}
          />
        </div>
        <MyProjects />
      </div>
    )
  }

  useEffect(() => {
    getCompanyData()
  }, [])

  const loadedTaxonomies = useMemo(() => {
    return areAllTaxonomiesLoaded(taxonomies, searchKeywords)
  }, [taxonomies, searchKeywords])

  const [showPopularCandidates, setShowPopularCandidates] = useState(false)

  return (
    <MainLayout
      fixedRight={false}
      withoutLogo
      theme="blue"
      rightSidebarContent={
        <WidgetBarContainer
          sidebarContent={setSidebarContent()}
          containerClass=".cardWrap"
        />
      }
    >
      <SearchControl
        taxonomies={taxonomies}
        getTaxonomies={getTaxonomies}
        getSearchResults={(values) => {
          getSearchResults(values)
          setShowPopularCandidates(false)
        }}
        getSearchKeywords={getSearchKeywords}
        searchKeywords={searchKeywords}
        savedSearch={savedSearch}
        getSavedSearch={getSavedSearch}
        sortBy={searchResults.sortBy}
        filtersType={filtersType}
        setFiltersType={setFiltersType}
        clearSearch={clearSearch}
      />
      <div className={styles.mailCandidateSearchWrap}>
        {searchResults.loading || !loadedTaxonomies ? (
          <Loader
            text={cs.loadingCandidatesText}
            className={styles.searchLoader}
          />
        ) : searchResults.error ? (
          <div>
            <FormattedMessage id={cs.errorMsg} />{' '}
            {searchResults.error.message || 'Error'}
          </div>
        ) : searchResults.data.length ? (
          <CandidateSearchResults
            searchResults={searchResults}
            loadMoreCandidates={loadMoreCandidates}
            taxonomies={taxonomies}
            searchKeywords={searchKeywords}
            getSearchResults={getSearchResults}
            handleCommentFocus={handleCommentFocus}
          />
        ) : showPopularCandidates ? (
          <MiniCards
            getPopularCandidates={getPopularCandidates}
            popularCandidates={popularCandidates}
            setShowPopularCandidates={setShowPopularCandidates}
          />
        ) : (
          <NoCandidate
            showPopularCandidates={() => {
              setShowPopularCandidates(true)
            }}
            taxonomies={taxonomies}
            searchKeywords={searchKeywords}
            savedSearch={savedSearch}
            saveCandidateSearch={saveCandidateSearch}
            updateSavedSearch={updateSavedSearch}
          />
        )}
      </div>
    </MainLayout>
  )
}
