import {connect} from 'react-redux'

import {EmployerInterviewInvitations} from './EmployerInterviewInvitations'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {
  getEmployerInterviews,
  getSeekersFromInterviews,
  loadMoreEmployerInterviews,
} from 'Store/Actions'
import {EmployerRequestsSearchParamsType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  requests: state.employer.data.allRequests.interviewInvitations,
  totalInterviewRequests:
    state.employer.data.allRequests.interviewInvitations.total,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getEmployerInterviews: (params?: EmployerRequestsSearchParamsType) =>
    dispatch(getEmployerInterviews(params)),
  loadMore: (url: string) => dispatch(loadMoreEmployerInterviews(url)),
  getSeekersFromInterviews: (activeTab?: string) =>
    dispatch(getSeekersFromInterviews(activeTab)),
})

export const EmployerInterviewInvitationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerInterviewInvitations)
