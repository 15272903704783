export const registration = {
  'registration.title': 'Ustvarite uporabniški račun',
  'registration.description':
    'Ustvarite brezplačen uporabniški račun in pridobite takojšen dostop do aplikacije.',
  'registration.firstName': 'Ime',
  'registration.lastName': 'Priimek',
  'registration.email': 'Elektronski naslov',
  'registration.password': 'Geslo',
  'registration.repeatPassword': 'Ponovite geslo',
  'registration.readMore': 'Preberite več',
  'registration.privacyPolicy':
    'Politiko zasebnosti in varovanja osebnih podatkov',
  'registration.agree': 'Soglašam s ',
  'registration.and': ' ter ',
  'registration.generalTermsOfService': 'Splošnimi pogoji uporabe',
  'registration.termsOfService': 'Pravili in pogoji uporabe Preferenca.si',
  'registration.nextButton': 'Naprej',
  'registration.sideBarTitle': 'Ne iščite, temveč izberite',
  'registration.sideBarJobOffers': 'Ustvarite top življenjepis',
  'registration.sideBarCreateCv': 'Posvetujte se z osebnim HR svetovalcem',
  'registration.sideBarHrSpecialist': 'Prejmite zanimive ponudbe za delo',
  'registration.sideBarContactCompany': 'Nadgradite svojo kariero',
  'registration.sideBarFindMatch': 'Izberite najboljšo ponudbo',
  'registration.validationPassword': 'Passwords must match',
  'registration.validationTermsAndConditions':
    'Accept Terms & Conditions is required',
  'registration.validationTermsOfService': 'Terms of service is required',
  'registration.loaderText': 'Ustvarjamo vaš račun...',
}
