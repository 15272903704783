import styles from './ReviewAvatarBorder.module.scss'
import classNames from 'classnames'
import {FC} from 'react'

interface ReviewAvatarBorderProps {
  review?: boolean
  reload?: boolean
}

export const ReviewAvatarBorder: FC<ReviewAvatarBorderProps> = ({
  review,
  reload,
}) => {
  return (
    <div
      className={classNames(styles.loadingWrap, {
        [styles.showCircle]: reload && review,
        [styles.hideCircle]: reload && !review,
      })}
    >
      <div
        className={classNames(styles.loading, {
          [styles.unreview]: !review,
        })}
      >
        <div className={classNames(styles.hold, styles.left)}>
          <div className={styles.fill}></div>
        </div>
        <div className={classNames(styles.hold, styles.right)}>
          <div className={styles.fill}></div>
        </div>
      </div>
      <div className={styles.whiteCircle} />
    </div>
  )
}
