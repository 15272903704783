import {FC, useEffect, useMemo} from 'react'
import {Link, useLocation} from 'react-router-dom'

import {AuthStateType, StateTypeOptional} from 'Interfaces'
import styles from './EmailChangeVerification.module.scss'
import LoginLayout from 'Layouts/LoginLayout'
import {Title, Description} from 'Components/Login'
import {AppRoute, EmailChangeVerification as ecv} from 'Services/I18n/Constants'
import YellowButton from 'Components/YellowButton'
import {getRoute} from 'Services/I18n/Utils'
import {getQuerySearchParam} from 'Utils/Routes'

interface EmailVerificationProps {
  auth: AuthStateType
  emailChangeVerify?: StateTypeOptional<string | undefined> & {success: boolean}
  verifyChangeEmail: (token: string) => void
  getUser: () => void
}

export const EmailChangeVerification: FC<EmailVerificationProps> = ({
  auth,
  emailChangeVerify,
  verifyChangeEmail,
  getUser,
}) => {
  let location = useLocation()
  const token = getQuerySearchParam(location.search, 'token')

  useEffect(() => {
    if (emailChangeVerify?.success && !auth.data?.id) {
      getUser()
    }
  }, [emailChangeVerify])

  useEffect(() => {
    if (token) {
      verifyChangeEmail(token)
    }
  }, [token])

  const pt = useMemo(() => {
    let title = ecv.pending
    let description = ecv.pleaseWaitText

    if (emailChangeVerify) {
      if (emailChangeVerify.success) {
        title = ecv.successTitle
        description = ecv.successLinkText
      }
      if (emailChangeVerify.error) {
        title = ecv.verificationFailed
        description = ecv.verificationFailedDescription
      }
    }
    return {
      title,
      description,
    }
  }, [token, emailChangeVerify?.loading, location, auth.displayMessage])

  return (
    <LoginLayout>
      <div className={styles.formWrap}>
        <Title text={pt.title} className={styles.emailVerificationTitle} />
        {emailChangeVerify?.success ? (
          <Link
            to={getRoute(AppRoute.CandidateDashboard)}
            className={styles.link}
          >
            <YellowButton
              className={styles.resendButton}
              text={pt.description}
            />
          </Link>
        ) : (
          <p className={styles.description}>
            <Description text={pt.description} />
          </p>
        )}
      </div>
    </LoginLayout>
  )
}
