export enum CompanyWelcomeMessageTranslations {
  modalTitle = 'companyWelcomeMessage.modalTitle',
  add = 'companyWelcomeMessage.add',
  edit = 'companyWelcomeMessage.edit',

  languageTitle = 'companyWelcomeMessage.languageTitle',

  personaTitle = 'companyWelcomeMessage.personaTitle',
  addPersonaText = 'companyWelcomeMessage.addPersonaText',

  welcomeTitleTitle = 'companyWelcomeMessage.welcomeTitleTitle',
  welcomeTitlePlaceholderText = 'companyWelcomeMessage.welcomeTitlePlaceholderText',

  welcomeTextTitle = 'companyWelcomeMessage.welcomeTextTitle',
  welcomeTextPlaceholderText = 'companyWelcomeMessage.welcomeTextPlaceholderText',

  deleteButtonText = 'companyWelcomeMessage.deleteButtonText',
}
