// @ts-nocheck
import ReactGallery from 'reactive-blueimp-gallery'
import {ChangeEvent, FC, useEffect, useMemo, useRef, useState} from 'react'
import {useFormik} from 'formik'
import {
  DeleteOutline,
  DragIndicator,
  InsertPhotoOutlined,
} from '@mui/icons-material'

import styles from './Impressions.module.scss'
import Modal, {SubmitFooter} from 'Components/Modal'
import SectionHeader from 'Components/Profile/SectionHeader'
import SectionItem from 'Components/Profile/SectionItem'
import ItemHeader from 'Components/Profile/ItemHeader'
import Hr from 'Components/Hr'
import Title from 'Components/Title'
import {
  CompanyStateType,
  ImageType,
  ImpressionFormikType,
  ImpressionsAddType,
  ImpressionsUpdateType,
} from 'Interfaces'
import ShowMoreContent from 'Components/ShowMoreContent'
import {useApiError, useTranslate, useWindowResize} from 'Hooks'
import FileUploadCombo from 'Components/Inputs/AttachmentFileUpload'
import TextField from 'Components/Inputs/TextField'
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd'
import {reorder} from 'Utils/Array'
import classnames from 'classnames'
import {CompanyImpressionsTranslations as cit} from 'Services/I18n/Constants'
import {useMediaQuery} from 'react-responsive'
import Validation from './Validation'
import TextError from 'Components/Error/TextError'

interface ImpressionsProps {
  canChange?: boolean
  company: CompanyStateType
  addImpressions: (value: ImpressionsAddType[]) => void
  updateImpressions: (values: ImpressionsUpdateType[]) => void
}

export const Impressions: FC<ImpressionsProps> = ({
  canChange,
  company,
  addImpressions,
  updateImpressions,
}) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [modalState, setModalState] = useState<'Add' | 'Edit'>('Add')
  const [pixelRatio, setPixelRatio] = useState(window.devicePixelRatio)
  const [width, setWidth] = useState(window.innerWidth)
  const notDesktop = useMediaQuery({
    query: '(max-width: 1024px)',
  })

  useWindowResize(() => {
    setPixelRatio(window.devicePixelRatio)
    setWidth(
      window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
    )
  })

  let defaultTitles = {}
  company.data.impressions.forEach((impr) => {
    defaultTitles[impr.file.id] = impr.title || ''
  })

  const defaultValues = {
    images: company.data.impressions.map((impr) => impr.file),
    titles: defaultTitles,
    apiErrorField: '',
  }

  const formik = useFormik<ImpressionFormikType>({
    initialValues: {...defaultValues},
    validationSchema: Validation,
    onSubmit: (values) => {
      const saveValues = values.images.map((image) => {
        const impression = company.data.impressions.find(
          (impr) => impr.file.id === image.id
        )
        if (impression) {
          return {
            id: impression.id,
            fileId: image.id,
            title: formik.values.titles[image.id],
          }
        } else {
          return {
            fileId: image.id,
            title: formik.values.titles[image.id],
          }
        }
      })
      if (modalState === 'Add') addImpressions(saveValues)
      else updateImpressions(saveValues)
    },
  })

  const openEdit = () => {
    formik.resetForm({
      values: defaultValues,
    })
    setModalState('Edit')
    setModalOpen(true)
  }

  const openAdd = () => {
    formik.resetForm({
      values: defaultValues,
    })
    setModalState('Add')
    setModalOpen(true)
  }

  useApiError(formik.setFieldError, company.error, 'apiErrorField')

  const addFile = (_: string, image?: ImageType) => {
    if (image && image.id) {
      formik.setFieldValue('images', [...formik.values.images, image])
      formik.setFieldValue('titles', {
        ...formik.values.titles,
        [image.id]: '',
      })
    }
  }

  const handleDelete = (index: number) => {
    formik.setFieldValue(
      'images',
      formik.values.images.filter((_, i) => i !== index)
    )
  }

  const onDragEnd = (result: DropResult) => {
    // dropped outside the list
    if (!result.destination) {
      return
    }

    const newItems = reorder(
      formik.values.images,
      result.source.index,
      result.destination.index
    )

    formik.setFieldValue('images', newItems)
  }

  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    const newTitles = {
      ...formik.values.titles,
      [id]: e.currentTarget.value,
    }
    formik.setFieldValue('titles', newTitles)
  }

  const images = useMemo(() => {
    const imageWidth = notDesktop
      ? (width - 40) * pixelRatio
      : ((width - 300) / 4 - 59.5) * pixelRatio

    let thumbnailSize = 'file820x546'

    if (imageWidth > 205) {
      if (imageWidth > 281) {
        if (imageWidth > 435) {
          thumbnailSize = 'file820x546'
        } else {
          thumbnailSize = 'file435x290'
        }
      } else {
        thumbnailSize = 'file281x187'
      }
    } else {
      thumbnailSize = 'file205x136'
    }

    return company.data?.impressions.map((impr) => ({
      source: impr.file.url,
      thumbnail: impr[thumbnailSize].url,
    }))
  }, [company.data?.impressions, pixelRatio, width])

  useEffect(() => {
    if (!company.loading && !company.error && modalOpen) {
      setModalOpen(false)
    }
  }, [company.loading])

  const childRef = useRef(null)
  const translate = useTranslate()
  const errorMessage = useMemo<string | null>(() => {
    if (!formik.errors.apiErrorField) return null
    if (
      formik.errors.apiErrorField.match(
        'Number of company impressions limit reached'
      )
    ) {
      return `${translate(cit.filesNumberLimit)}`
    }

    return `${translate(cit.apiError)}`
  }, [formik.errors.apiErrorField])
  return (
    <div className={styles.section}>
      {(company.data.videos.impressions || canChange) && (
        <div>
          <SectionHeader
            title={cit.impressionsTitle}
            onAdd={
              canChange && !company.data.impressions.length
                ? openAdd
                : undefined
            }
            className={styles.header}
          />
        </div>
      )}
      {company.data.impressions.length ? (
        <SectionItem className={styles.sectionItem}>
          <ItemHeader
            title={cit.featuredTitle}
            icon={InsertPhotoOutlined}
            onEdit={
              canChange && company.data.impressions.length
                ? openEdit
                : undefined
            }
            className={styles.itemHeader}
          />
          <ShowMoreContent
            className={classnames(
              styles.showMoreContent,
              styles.showMoreContentStronger
            )}
            childRef={childRef}
            forceChildHeight
            withMargins
          >
            <div className={styles.galleryWrap}>
              <ReactGallery withControls key={1}>
                {images.map((item, index) => {
                  return (
                    <div
                      key={item.id || index}
                      ref={!index ? childRef : null}
                      className={styles.singleImageWrap}
                    >
                      <ReactGallery.Slide {...item} />
                    </div>
                  )
                })}
              </ReactGallery>
            </div>
          </ShowMoreContent>
        </SectionItem>
      ) : null}

      <Modal
        isOpen={modalOpen}
        onAfterClose={() => {
          setModalOpen(false)
        }}
        title={cit.modalTitle}
        icon={InsertPhotoOutlined}
        ariaHideApp={false}
        className={styles.modal}
        theme="desktopFlex"
        Footer={
          <SubmitFooter
            onSubmit={formik.handleSubmit}
            onCancel={() => {
              setModalOpen(false)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        {formik.values.images?.length > 23 ? null : (
          <>
            <Title
              text={cit.modalImpressionsTitle}
              theme="modal"
              className={styles.firstTitle}
            />
            <FileUploadCombo
              name="file"
              setValue={addFile}
              endpoint="/upload-file/impression-picture"
              supportedFilesMessage={cit.supportedFileFormats}
              accept=".jpg,.jpeg,.png,.gif"
              shouldFocusOnError={formik.isSubmitting}
            />
          </>
        )}
        {formik.values.images.length > 0 && (
          <>
            <Hr theme="modal" />
            <Title
              text={cit.modalImpressionsTitleTitle}
              theme="modal"
              className={styles.secondTitle}
            />
            <div className={classnames(styles.dndContainer)}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(droppableProvided) => (
                    <div
                      ref={droppableProvided.innerRef}
                      {...droppableProvided.droppableProps}
                      className={styles.list}
                    >
                      {formik.values.images.map(
                        (item, index) =>
                          item.id && (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(draggableProvided, draggableSnapshot) => (
                                <div
                                  className={classnames(styles.item, {
                                    itemDragging: draggableSnapshot.isDragging,
                                  })}
                                  ref={draggableProvided.innerRef}
                                  {...draggableProvided.draggableProps}
                                >
                                  <div className={styles.firstRow}>
                                    <div
                                      {...draggableProvided.dragHandleProps}
                                      className={styles.handle}
                                    >
                                      <DragIndicator />
                                    </div>
                                    <div className={styles.imageItemContainer}>
                                      <div>
                                        <img
                                          className={styles.image}
                                          src={item.url}
                                          alt={item.originalName}
                                        />
                                      </div>
                                      <div className={styles.desktopOnly}>
                                        <TextField
                                          name={`imageTitle-${index}`}
                                          placeholder={item.originalName}
                                          className={classnames({
                                            [styles.textField]:
                                              formik.errors.titles &&
                                              formik.errors.titles[item.id],
                                          })}
                                          value={
                                            formik.values.titles[item.id] || ''
                                          }
                                          onChange={(e) => {
                                            handleTitleChange(e, item.id)
                                          }}
                                          error={
                                            formik.errors.titles &&
                                            formik.errors.titles[item.id]
                                          }
                                        />
                                      </div>
                                      <div>
                                        <DeleteOutline
                                          onClick={() => {
                                            handleDelete(index)
                                          }}
                                          className={styles.mobileDeleteIcon}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className={styles.mobileOnly}>
                                    <TextField
                                      name={`imageTitle-${index}`}
                                      placeholder={item.originalName}
                                      value={
                                        formik.values.titles[item.id] || ''
                                      }
                                      onChange={(e) => {
                                        handleTitleChange(e, item.id)
                                      }}
                                      error={
                                        formik.errors.titles &&
                                        formik.errors.titles[item.id]
                                      }
                                    />
                                  </div>
                                </div>
                              )}
                            </Draggable>
                          )
                      )}
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
            {errorMessage && (
              <TextError text={errorMessage} className={styles.apiErrorField} />
            )}
          </>
        )}
      </Modal>
    </div>
  )
}
