import {FC, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {useHistory} from 'react-router-dom'

import styles from './NoCandidate.module.scss'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {HrIcon} from 'Components/HrIcon/HrIcon'
import {CandidateSearchTranslations as cs} from 'Services/I18n/Constants'
import SaveSearchModal from 'Components/CandidateSearch/SavedSearch/SaveSearchModal'
import {
  BaseTaxonomyType,
  DataType,
  SavedSearchDataType,
  SavedSearchType,
  TaxonomiesStateType,
} from 'Interfaces'

interface NoCandidateProps {
  showPopularCandidates: () => void
  taxonomies: TaxonomiesStateType
  searchKeywords: DataType<BaseTaxonomyType>
  saveCandidateSearch: (sortBy: SavedSearchType) => void
  updateSavedSearch: (value: SavedSearchType) => void
  savedSearch: SavedSearchDataType
}

export const NoCandidate: FC<NoCandidateProps> = ({
  showPopularCandidates,
  taxonomies,
  saveCandidateSearch,
  updateSavedSearch,
  savedSearch,
  searchKeywords,
}) => {
  const history = useHistory()
  const [modalOpen, setModalOpen] = useState(false)
  return (
    <>
      <div className={styles.title}>
        <h1 className={styles.title}>
          <FormattedMessage id={cs.title} />
        </h1>
      </div>
      <HrIcon />

      <div className={styles.text}>
        <FormattedMessage id={cs.txtField} />
      </div>
      <div className={styles.optionContainer}>
        <button
          onClick={() => {
            history.push(getRoute(AppRoute.CandidateSearch))
          }}
          className={styles.option}
        >
          <FormattedMessage id={cs.optionFirst} />
        </button>
        <button
          className={styles.option}
          onClick={() => {
            setModalOpen(true)
          }}
        >
          <FormattedMessage id={cs.optionSecond} />
        </button>
        <button onClick={showPopularCandidates} className={styles.option}>
          <FormattedMessage id={cs.optionLast} />
        </button>
        <SaveSearchModal
          isOpen={modalOpen}
          setOpen={setModalOpen}
          taxonomies={taxonomies}
          searchKeywords={searchKeywords}
          saveCandidateSearch={saveCandidateSearch}
          savedSearch={savedSearch}
          updateSavedSearch={updateSavedSearch}
        />
      </div>
    </>
  )
}
