import * as type from '../types'
import {
  ActionType,
  AddCandidateToProjectType,
  ArchiveMyProjectType,
  EditMyProjectType,
  MyProjectCandidatesSearchParams,
  RemoveCandidateFromProjectsType,
  SearchMyProjectsParams,
  SeekerEvaluationType,
  SeekerReviewType,
} from 'Interfaces'

export const getMyWidgetProjects = (input?: string): ActionType<string> => {
  return {
    type: type.myProjects.widget.get.requested,
    payload: input,
  }
}

export const getAllMyProjects = (
  payload?: SearchMyProjectsParams
): ActionType<SearchMyProjectsParams> => {
  return {
    type: type.myProjects.all.get.requested,
    payload,
  }
}

export const getMyProject = (id: string): ActionType<string> => {
  return {
    type: type.myProjects.project.get.requested,
    payload: id,
  }
}

export const getMyProjectCandidates = (
  params: MyProjectCandidatesSearchParams
): ActionType<MyProjectCandidatesSearchParams> => {
  return {
    type: type.myProjects.projectCandidates.get.requested,
    payload: params,
  }
}

export const archiveMyProject = (
  value: ArchiveMyProjectType
): ActionType<ArchiveMyProjectType> => {
  return {
    type: type.myProjects.archive.requested,
    payload: value,
  }
}

export const deleteMyProject = (value: string): ActionType<string> => {
  return {
    type: type.myProjects.delete.requested,
    payload: value,
  }
}

export const addCandidateToNewProject = (
  value: AddCandidateToProjectType
): ActionType<AddCandidateToProjectType> => {
  return {
    type: type.myProjects.addToNewProject.requested,
    payload: value,
  }
}

export const addCandidateToProjects = (
  value: AddCandidateToProjectType
): ActionType<AddCandidateToProjectType> => {
  return {
    type: type.myProjects.addToProject.requested,
    payload: value,
  }
}

export const removeCandidateFromProjects = (
  value: RemoveCandidateFromProjectsType
): ActionType<RemoveCandidateFromProjectsType> => {
  return {
    type: type.myProjects.removeFromProjects.requested,
    payload: value,
  }
}
export const removeCandidateFromAllProjects = (
  value: RemoveCandidateFromProjectsType
): ActionType<RemoveCandidateFromProjectsType> => {
  return {
    type: type.myProjects.removeFromAllProjects.requested,
    payload: value,
  }
}

export const createNewProject = (value: string): ActionType<string> => {
  return {
    type: type.myProjects.addNewProject.requested,
    payload: value,
  }
}

export const editMyProject = (
  value: EditMyProjectType
): ActionType<EditMyProjectType> => {
  return {
    type: type.myProjects.editProject.requested,
    payload: value,
  }
}

export const loadMoreProjects = (url: string): ActionType<string> => {
  return {
    type: type.myProjects.loadMore.requested,
    payload: url,
  }
}

export const loadMoreProjectCandidates = (
  id: string,
  params: SearchMyProjectsParams | null | undefined
): ActionType<{
  id: string
  params: SearchMyProjectsParams | null | undefined
}> => {
  return {
    type: type.myProjects.projectCandidates.loadMore.requested,
    payload: {id, params},
  }
}

export const addMyProjectCandidateEvaluation = (
  seekerEvaluation: SeekerEvaluationType
): ActionType<SeekerEvaluationType> => {
  return {
    type: type.myProjects.rateCandidate.add.requested,
    payload: seekerEvaluation,
  }
}

export const editMyProjectCandidateEvaluation = (
  seekerEvaluation: SeekerEvaluationType
): ActionType<SeekerEvaluationType> => {
  return {
    type: type.myProjects.rateCandidate.edit.requested,
    payload: seekerEvaluation,
  }
}

export const deleteMyProjectCandidateEvaluation = (
  seekerEvaluation: SeekerEvaluationType
): ActionType<SeekerEvaluationType> => {
  return {
    type: type.myProjects.rateCandidate.delete.requested,
    payload: seekerEvaluation,
  }
}

export const searchMyProjects = (
  payload?: SearchMyProjectsParams
): ActionType<SearchMyProjectsParams> => {
  return {
    type: type.myProjects.search.requested,
    payload,
  }
}

export const clearMyProjectsSearch = (): ActionType<string> => {
  return {
    type: type.myProjects.search.clear,
  }
}

export const loadMoreSearchProjects = (url: string): ActionType<string> => {
  return {
    type: type.myProjects.loadMoreSearch.requested,
    payload: url,
  }
}
export const setMyProjectCandidateReview = (
  seekerReview: SeekerReviewType
): ActionType<SeekerReviewType> => {
  return {
    type: type.myProjects.reviewCandidate.edit.requested,
    payload: seekerReview,
  }
}

export const getSearchResultsSavedCandidates = (
  params: SearchMyProjectsParams,
  projectId: string | undefined
): ActionType<{
  params: SearchMyProjectsParams
  projectId: string | undefined
}> => {
  return {
    type: type.myProjects.searchSavedCandidates.requested,
    payload: {params, projectId},
  }
}
