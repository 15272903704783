import {FC, useEffect, useMemo, useState} from 'react'
import {useFormik} from 'formik'
import nl2br from 'react-nl2br'
import {useIntl} from 'react-intl'

import styles from './Certifications.module.scss'
import Modal, {DeleteModal} from 'Components/Modal'
import SectionHeader from 'Components/Profile/SectionHeader'
import SectionItem from 'Components/Profile/SectionItem'
import ItemHeader from 'Components/Profile/ItemHeader'
import ItemTextWithIcon from 'Components/Profile/ItemTextWithIcon'
import Hr from 'Components/Hr'
import Validation from './Validation'
import {
  BaseTaxonomyType,
  CertificationFormikValue,
  CertificationProfileItemType,
  SeekerStateType,
  TaxonomiesStateType,
} from 'Interfaces'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import Dropdown from 'Components/Inputs/Dropdown'
import TextArea from 'Components/Inputs/TextArea'
import {DropdownProps} from 'Utils/FormikProps'
import {useApiError, useYears} from 'Hooks'
import ShowMore from 'Components/Profile/ShowMore'
import {ProfileTranslations as pt} from 'Services/I18n/Constants'
import Title from 'Components/Title'
import TypeAheadImproved from 'Components/Inputs/TypeAheadImproved'
import {AccountBalanceOutlined, EmojiEvents, Update} from '@mui/icons-material'

interface CertificationEndDateProps {
  profile: SeekerStateType
  certifications?: CertificationProfileItemType[]
  addCertification: (value: CertificationFormikValue) => void
  editCertification: (value: CertificationFormikValue) => void
  taxonomies: TaxonomiesStateType
  getTaxonomies: () => void
  canChange?: boolean
  deleteCertification: (id: string) => void
}

export const CertificationsEndDate: FC<CertificationEndDateProps> = ({
  profile,
  certifications = [],
  taxonomies,
  getTaxonomies,
  addCertification,
  editCertification,
  deleteCertification,
  canChange = false,
}) => {
  const emptyValues: CertificationFormikValue = {
    id: null,
    trainingTitle: undefined,
    endYear: '',
    educationInstitution: undefined,
    description: '',
  }

  const [modalOpen, setModalOpen] = useState(false)
  const [modalMode, setModalMode] = useState('Add')
  const [showMoreIndex, setShowMoreIndex] = useState(1)
  const [deleteModal, setDeleteModal] = useState<boolean | number>(false)
  const {formatMessage} = useIntl()

  const filteredCertificationsTitles = useMemo(() => {
    if (modalMode === 'Add' && certifications?.length) {
      return taxonomies.trainingTitles.data.filter(
        (title) =>
          !certifications.some(
            (certification) => title.id === certification.trainingTitle?.id
          )
      )
    }
    return taxonomies.trainingTitles.data
  }, [certifications, taxonomies, modalMode])

  const localCertifications = useMemo(() => {
    if (showMoreIndex > 1 && certifications.length !== showMoreIndex) {
      setShowMoreIndex(certifications.length)
    }
    return certifications.slice(0, showMoreIndex)
  }, [certifications, showMoreIndex])

  const openEdit = (id?: string) => {
    setModalMode('Edit')
    getTaxonomies()
    const certificationItem = certifications.find(
      (e) => e.id === id
    ) as CertificationProfileItemType
    formik.resetForm({
      values: {
        id: id,
        trainingTitle: certificationItem.trainingTitle,
        endYear: certificationItem.endYear,
        educationInstitution: {...certificationItem.educationInstitution},
        description: certificationItem.description,
      },
    })
    setModalOpen(true)
  }

  const openAdd = () => {
    setModalMode('Add')
    formik.resetForm({
      values: emptyValues,
    })
    getTaxonomies()
    setModalOpen(true)
  }

  const setTypeAheadField = (name: string, values: BaseTaxonomyType[]) => {
    formik.setFieldValue(name, values[0])
  }

  const endYears = useYears()

  const formik = useFormik({
    initialValues: emptyValues,
    validationSchema: Validation,
    onSubmit: (values) => {
      if (modalMode === 'Edit') {
        editCertification(values)
      } else addCertification(values)
    },
  })

  const memoizedEduInstitutionTypeahead = useMemo(() => {
    return (
      <TypeAheadImproved
        name="educationInstitution"
        theme="modal"
        items={taxonomies.educationInstitutions.data}
        defaultValue={
          formik.values.educationInstitution
            ? [formik.values.educationInstitution]
            : undefined
        }
        setValue={setTypeAheadField}
        setTouched={formik.getFieldHelpers('educationInstitution').setTouched}
        placeholder={pt.placeholderEducationalInstitution}
        maxNumSelections={1}
        selectNonExisting
        error={
          (formik.submitCount && formik.errors?.educationInstitution) ||
          undefined
        }
      />
    )
  }, [
    formik.values.educationInstitution,
    taxonomies.educationInstitutions.data,
    formik.errors?.educationInstitution,
    formik.submitCount,
  ])
  const memoizedTrainingTitleTypeahead = useMemo(() => {
    return (
      <TypeAheadImproved
        name="trainingTitle"
        theme="modal"
        items={filteredCertificationsTitles}
        defaultValue={
          formik.values.trainingTitle
            ? [formik.values.trainingTitle]
            : undefined
        }
        setValue={setTypeAheadField}
        setTouched={formik.getFieldHelpers('trainingTitle').setTouched}
        placeholder={pt.placeholderTrainingTitle}
        maxNumSelections={1}
        selectNonExisting
        error={
          (formik.submitCount && formik.errors?.trainingTitle) || undefined
        }
      />
    )
  }, [
    formik.values.trainingTitle,
    filteredCertificationsTitles,
    formik.errors?.trainingTitle,
    formik.submitCount,
  ])

  useApiError(formik.setFieldError, profile.error)
  useEffect(() => {
    if (!profile.loading && !profile.error) {
      setModalOpen(false)
      setDeleteModal(false)
    }
  }, [profile.loading])

  return (
    <div className={styles.section}>
      <div>
        <SectionHeader
          onAdd={canChange ? openAdd : undefined}
          title={pt.certificationsTitle}
        />
      </div>
      {localCertifications.map((certification: any, index: number) => {
        return (
          <SectionItem key={index}>
            <ItemHeader
              title={certification.trainingTitle?.translation || ''}
              icon={EmojiEvents}
              onEdit={
                canChange
                  ? () => {
                      openEdit(certification.id)
                    }
                  : undefined
              }
              onDelete={
                canChange
                  ? () => {
                      setDeleteModal(index)
                    }
                  : undefined
              }
            />

            <div className={styles.content}>
              {certification.endYear ? (
                <ItemTextWithIcon
                  primaryText={pt.trainingYear}
                  secondaryText={` ${
                    certification.endYear ? certification.endYear : ''
                  }`}
                  icon={Update}
                  withColon
                />
              ) : null}

              <ItemTextWithIcon
                primaryText={pt.institutionName}
                secondaryText={`${certification.educationInstitution.translation}`}
                icon={AccountBalanceOutlined}
                withColon
              />
              {certification.description && (
                <p className={styles.textContainer}>
                  {nl2br(certification.description)}
                </p>
              )}
            </div>
          </SectionItem>
        )
      })}

      <ShowMore
        items={certifications}
        showMoreIndex={showMoreIndex}
        setShowMoreIndex={setShowMoreIndex}
      />

      <Modal
        isOpen={modalOpen}
        onAfterClose={() => {
          setModalOpen(false)
        }}
        ariaHideApp={false}
        title={`${formatMessage({
          id:
            modalMode === 'Add'
              ? pt.addAdditionalTraining
              : pt.editAdditionalTraining,
        })}`}
        icon={EmojiEvents}
        theme="desktopFlex"
        Footer={
          <SubmitFooter
            onSubmit={formik.handleSubmit}
            onCancel={() => {
              setModalOpen(false)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <div className={styles.modalContent}>
          <Title text={pt.trainingTitle} theme="modal" />
          {memoizedTrainingTitleTypeahead}
          <Hr theme="modal" />
          <Title text={pt.trainingDate} theme="modal" />
          <div className={styles.dropdownsWrapper}>
            <Dropdown
              name="endYear"
              items={endYears}
              theme="white"
              className={styles.date}
              {...DropdownProps(formik, 'endYear')}
            />
          </div>
          <Hr theme="modal" />
          <Title text={pt.institutionName} theme="modal" />
          {memoizedEduInstitutionTypeahead}
          <Hr theme="modal" />
          <Title text={pt.trainingDescription} theme="modal" />
          <TextArea
            name="description"
            value={`${formik.values.description}`}
            onChange={formik.handleChange}
            placeholderText={pt.placeholderTrainingDescription}
            theme="mediumBorderRadiusTextArea"
            error={
              formik.touched.description || formik.values.description !== ''
                ? formik.errors.description
                : undefined
            }
            maximumCharactersNumber={1000}
            type="letterCalculator"
          />
        </div>
      </Modal>
      <DeleteModal
        deleteAction={deleteCertification}
        isOpen={!!deleteModal || deleteModal === 0}
        setOpen={setDeleteModal}
        text={`${formatMessage({id: pt.deletePartOne})}${
          localCertifications?.find((_, i) => i === deleteModal)?.trainingTitle
            ?.translation || ''
        }${formatMessage({id: pt.deletePartTwo})}`}
        id={localCertifications?.find((_, i) => i === deleteModal)?.id || ''}
      />
    </div>
  )
}
