import {FC} from 'react'
import classnames from 'classnames'
import {Link} from 'react-router-dom'

import styles from './MiniCard.module.scss'
import {PopularCandidateType} from 'Interfaces'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {taxonomiesToUrl} from 'Utils/Search'
import {
  Face,
  SchoolOutlined,
  ViewInArOutlined,
  AssignmentOutlined,
  LanguageOutlined,
} from '@mui/icons-material'

export interface MiniCardType {
  text?: string
  candidate: PopularCandidateType
  theme?: string
  icon?: string
  MiniCardIcon: typeof Face
  setShowPopularCandidates: (s: boolean) => void
}
export const MiniCard: FC<MiniCardType> = ({
  candidate,
  theme,
  MiniCardIcon = Face,
  setShowPopularCandidates,
}: MiniCardType) => {
  const urlValues = {
    occupations: [candidate.occupation, ...candidate.desiredOccupations],
    skills: candidate.skills.map((s) => s.skill),
    languages: candidate.languages.map((l) => l.languageTaxonomy),
    languageLevelIds: candidate.languages.map((l) => l.languageLevel),
    workExperiences: [candidate.totalWorkExperience],
  }
  const similarCandidateUrl = `${getRoute(
    AppRoute.CandidateSearch
  )}?${taxonomiesToUrl(urlValues)}`

  return (
    <>
      <div className={styles.mainContainer}>
        <div className={styles.titleContainer}>
          <div className={styles.icon}>
            {MiniCardIcon && (
              <MiniCardIcon
                sx={{fontSize: 60}}
                className={classnames(styles.header, {
                  [styles.headerGrey]: theme === 'grey',
                })}
              />
            )}
          </div>
          <div
            className={classnames(styles.cardTitle, {
              [styles.cardTitleGrey]: theme === 'grey',
            })}
          >
            {candidate.occupation.translation}
          </div>
        </div>
        <div className={styles.description}>
          <div className={styles.descriptionSpec}>
            <SchoolOutlined className={styles.descriptionIcon} />
            <span className={styles.descriptionText}>Expertise:</span>
            {candidate.desiredOccupations.map(
              (d, i) =>
                `${d.translation}${
                  i + 1 < candidate.desiredOccupations.length ? ', ' : ''
                }`
            )}
          </div>
          <div className={styles.descriptionSpec}>
            <ViewInArOutlined className={styles.descriptionIcon} />
            <span className={styles.descriptionText}>Experience:</span>
            {candidate.totalWorkExperience.translation}
          </div>
          <div className={styles.descriptionSpec}>
            <LanguageOutlined className={styles.descriptionIcon} />
            <span className={styles.descriptionText}>Languages:</span>
            {candidate.languages.map(
              (l, i) =>
                `${l.languageTaxonomy.translation} ${
                  l.languageLevel.translation
                }${i + 1 < candidate.languages.length ? ', ' : ''}`
            )}
          </div>
          <div
            className={classnames(
              styles.descriptionSpec,
              styles.descriptionSpecLast
            )}
          >
            <AssignmentOutlined className={styles.descriptionIcon} />
            <span className={styles.descriptionText}>Skills:</span>
            {candidate.skills.map(
              (s, i) =>
                `${s.skill.translation}${
                  i + 1 < candidate.skills.length ? ', ' : ''
                }`
            )}
          </div>
          <div
            className={styles.viewButton}
            onClick={() => setShowPopularCandidates(false)}
          >
            <Link className={styles.link} to={similarCandidateUrl}>
              View similar candidates
            </Link>
          </div>
        </div>
      </div>
    </>
  )
}
