import {connect} from 'react-redux'

import {MessageCenterConversations} from './MessageCenterConversations'
import {AppDispatch} from 'Store'
import {
  archiveConversations,
  loadMoreConversations,
  searchConversations,
  selectConversation,
  readConversationSocket,
  unreadConversationSocket,
} from 'Store/Actions/MessageCenterActions'
import {RootState} from 'Store/Reducers'
import {ConversationType} from 'Interfaces'
import {getCandidateProfile, setSidebarClosed} from 'Store/Actions'

const mapStateToProps = (state: RootState) => ({
  conversations: state.messageCenter.data,
  searchConversationStatus:
    state.messageCenter?.params?.searchConversationStatus,
  loadMoreConversationsUrl: state.messageCenter.loadMoreConversations,
  loadMoreConversationsProgress:
    state.messageCenter.loadMoreConversationsProgress,
  openedConversation: state.messageCenter.openedConversation,
  auth: state.auth,
  candidate: state.employer.data.candidate,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  loadMoreConversations: (url: string) => dispatch(loadMoreConversations(url)),
  selectConversation: (conversation?: ConversationType) =>
    dispatch(selectConversation(conversation)),
  resetSearch: () => dispatch(searchConversations()),
  archiveConversations: (ids: string[]) => dispatch(archiveConversations(ids)),
  readConversation: (data: string[]) => dispatch(readConversationSocket(data)),
  unreadConversation: (data: string[]) =>
    dispatch(unreadConversationSocket(data)),
  getCandidateProfile: (id: string) => dispatch(getCandidateProfile(id)),
  setSidebarClosed: (sidebarClosed: boolean) =>
    dispatch(setSidebarClosed(sidebarClosed)),
})

export const MessageCenterConversationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageCenterConversations)
