import {ConsentItemContainer} from 'Components/Consents/ConsentItemContainer'
import SectionHeader from 'Components/Profile/SectionHeader'
import SectionItem from 'Components/Profile/SectionItem'
import {LabelsGroup} from 'Components/LabelsGroup'
import Hr from 'Components/Hr'
import {ConsentsArray} from 'Interfaces/ConsentsType'
import classNames from 'classnames'
import {ConsentsTranslations as ct} from 'Services/I18n/Constants/Seeker/ConsentsTranslations'
import {useTranslation} from 'Hooks'
import {FC} from 'react'

import styles from './ConsentItemsContainer.module.scss'

export const ConsentItemsContainer: FC<ConsentsArray> = ({consents}) => {
  const translation = useTranslation()

  const basicsTestLabels = [
    translation[ct.labelTitle],
    translation[ct.labelStatus],
    translation[ct.labelDate],
    translation[ct.labelContent],
    translation[ct.labelAction],
  ]

  const sectionId = (key: string) => {
    switch (key) {
      case translation[ct.basicConsents]:
        return 'basicConsents'
      case translation[ct.consentsForNotifications]:
        return 'notificationConsents'
      default:
        return 'testsConsents'
    }
  }

  return (
    <div className={styles.consentItemsContainer}>
      {Object.keys(consents)
        .sort()
        .map((key, index) => (
          <div key={index} id={sectionId(key)}>
            <SectionHeader
              title={key}
              theme="noPaddingX"
              className={styles.consentsHeader}
            />
            <SectionItem
              className={classNames(
                styles.consentsSectionItem,
                styles.basicSectionItem
              )}
            >
              <div className={styles.hrWrapper}>
                <Hr className={styles.consentSectionsHr} />
              </div>
              <div className={styles.labelsContainer}>
                <LabelsGroup
                  labels={basicsTestLabels}
                  className={classNames(
                    styles.rowLabels,
                    styles.basicConsentsLabel
                  )}
                />
              </div>
              <ConsentItemContainer
                type={key}
                className={styles.basicConsents}
                consentsArray={consents[key]}
              />
            </SectionItem>
          </div>
        ))}
    </div>
  )
}
