import {useMediaQuery} from 'react-responsive'

import {AvatarContainer} from 'Components/Avatar'
import {PublicSeekerProfileType, SeekerProfileType} from 'Interfaces'

import styles from './YellowHeader.module.scss'
import {BasicCandidateInformations} from '../BasicCandidateInformations'

interface YellowHeaderProps {
  candidate?: SeekerProfileType | PublicSeekerProfileType
}

export const YellowHeader: React.FC<YellowHeaderProps> = ({candidate}) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 900px)',
  })

  let imageOrInitials =
    candidate?.profilePicture === null
      ? `${candidate?.firstName?.charAt(0)} ${candidate?.lastName?.charAt(0)}`
      : candidate?.profilePicture

  return (
    <div className={styles.yellowHeaderContainer}>
      {/* Avatar section */}
      {isMobile && candidate?.profilePicture?.url && (
        <div className={styles.avatarContainerWrapper}>
          <AvatarContainer
            className={styles.yellowHeaderAvatar}
            imageOrInitials={imageOrInitials}
          />
        </div>
      )}
      <div className={styles.yellowHeaderWrapper}>
        {!isMobile && candidate?.profilePicture?.url && (
          <div className={styles.avatarContainerWrapper}>
            <AvatarContainer
              className={styles.yellowHeaderAvatar}
              imageOrInitials={imageOrInitials}
            />
          </div>
        )}
        {/*Basic informations data section*/}
        <div className={styles.basicUserInformationsWrapper}>
          <BasicCandidateInformations
            candidate={candidate as SeekerProfileType}
          />
        </div>
      </div>
    </div>
  )
}
