import {
  EducationFormikValue,
  EducationSaveValueType,
  LogGuestConsentChangeType,
  ProfileJobPreferenceFormikType,
  ProfileJobPreferenceSaveType,
  ProjectFormikValue,
  ProjectSaveValue,
  PublicProfileJobPreferenceFormikType,
  PublicProfileJobPreferenceSaveType,
  UnsubscribeReason,
  UpdateNewsletterConsentResponseType,
  WorkExperienceFormikValue,
  WorkExperienceSaveValue,
} from 'Interfaces'

export const packProjectValues = (
  formikValues: ProjectFormikValue
): ProjectSaveValue => {
  return {
    title: formikValues.title,
    endYear: parseInt(formikValues.endYear),
    description: formikValues.description,
  }
}

export const packWorkExperiencesValues = (
  formikValues: WorkExperienceFormikValue
): WorkExperienceSaveValue => {
  let baseInfo = {
    currentEmployer: formikValues.currentEmployer,
    startMonth: parseInt(formikValues.startMonth),
    startYear: parseInt(formikValues.startYear),
    endMonth: parseInt(formikValues.endMonth),
    endYear: parseInt(formikValues.endYear),
    employmentTypeId: formikValues.employmentType?.id,
    workDescriptionAndAchievements: formikValues.workDescriptionAndAchievements,
    countryId: formikValues.country?.id,
    jobTitle: formikValues.jobTitle,
  }

  if (formikValues.town?.id.includes('newValue~')) {
    baseInfo['townName'] = formikValues.town.translation
  } else baseInfo['townId'] = formikValues.town?.id

  if (formikValues.occupation?.id.includes('newValue~')) {
    baseInfo['jobClassificationName'] = formikValues.occupation.translation
  } else baseInfo['jobClassificationId'] = formikValues.occupation?.id

  if (formikValues.employer?.id.includes('newValue~')) {
    baseInfo['employerName'] = formikValues.employer.translation
  } else baseInfo['employerId'] = formikValues.employer?.id

  return {
    ...baseInfo,
  }
}

export const packEduValues = (
  formikValues: EducationFormikValue
): EducationSaveValueType => {
  // @ts-ignore
  let baseInfo = {
    title: formikValues.title,
    educationLevelId: formikValues.educationLevel?.id || '',
    countryId: formikValues.country?.id || '',
    endYear: parseInt(formikValues.endYear),
    inProgress: formikValues.inProgress,
    description: formikValues.description,
  }

  let educationInstitution
  if (formikValues.educationInstitution?.id.includes('newValue~')) {
    educationInstitution = {
      educationInstitutionName:
        formikValues.educationInstitution?.translation || '',
    }
  } else
    educationInstitution = {
      educationInstitutionId: formikValues.educationInstitution?.id || '',
    }

  let town
  if (formikValues.town?.id.includes('newValue~')) {
    town = {
      townName: formikValues.town?.translation || '',
    }
  } else
    town = {
      townId: formikValues.town?.id || '',
    }

  return {
    ...baseInfo,
    ...educationInstitution,
    ...town,
  }
}

export const packJobPreferenceValues = (
  formikValues: ProfileJobPreferenceFormikType
): ProfileJobPreferenceSaveType => {
  return {
    careerLevelId: formikValues.careerLevel?.id,
    desiredRegions: formikValues.desiredRegions?.map((d) => {
      if (d.id.includes('newValue~')) {
        return {
          name: d.translation,
        }
      }
      return {
        id: d.id,
      }
    }),
    desiredOccupations: formikValues.desiredOccupations?.map((d) => {
      if (d.id.includes('newValue~')) {
        return {
          name: d.translation,
        }
      }
      return {
        id: d.id,
      }
    }),
    desiredEmploymentTypeIds: formikValues.desiredEmploymentTypes?.map(
      (d) => d.id
    ),
    desiredSalaryId: formikValues.desiredSalary?.id,
    // currentSalaryId: formikValues.currentSalary?.id,
  }
}

export const packPublicJobPreferenceValues = (
  formikValues: PublicProfileJobPreferenceFormikType
): PublicProfileJobPreferenceSaveType => {
  return {
    desiredRegions: formikValues.desiredRegions?.map((d) => {
      if (d.id.includes('newValue~')) {
        return {
          name: d.translation,
        }
      }
      return {
        id: d.id,
      }
    }),
    desiredOccupations: formikValues.desiredOccupations?.map((d) => {
      if (d.id.includes('newValue~')) {
        return {
          name: d.translation,
        }
      }
      return {
        id: d.id,
      }
    }),
    desiredSalaryId: formikValues.desiredSalary?.id,
    availabilityId: formikValues.availability?.id,
    workFromHomeId: formikValues.workFromHome?.id,
    desiredWorkEnv: formikValues.desiredWorkEnv,
    additionalBenefitIds:
      formikValues.additionalBenefits?.map((d) => d.id) || undefined,
  }
}

export const packConsentUnsubscribeReasons = (
  formikValues: LogGuestConsentChangeType
): LogGuestConsentChangeType => {
  const reasons: UnsubscribeReason[] = []
  formikValues.reasons.forEach(({checked, ...rest}) => {
    if (checked) {
      reasons.push(rest)
    }
  })
  return {
    consentChangeLogIds: formikValues.consentChangeLogIds,
    reasons,
  }
}

export const packConsentUnsubscribeResponse = (
  values: UpdateNewsletterConsentResponseType
): string[] => {
  let logIds: string[] = []

  values.forEach((log) => {
    log.consentChangeLogs.map((reason) => {
      logIds.push(reason.id)
    })
  })

  return logIds
}
