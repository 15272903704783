import classnames from 'classnames'
import styles from './Chip.module.scss'
import CancelIcon from '@mui/icons-material/Cancel'
export interface ChipProps {
  text: string
  className?: string
  onClose: () => void
}

export const Chip = ({text, className, onClose}: ChipProps): JSX.Element => {
  return (
    <div className={classnames(styles.chip, className)}>
      <div className={styles.text}>{text}</div>
      <div className={styles.iconWrap} onClick={onClose}>
        <CancelIcon className={styles.icon} sx={{fontSize: 16}} />
      </div>
    </div>
  )
}
