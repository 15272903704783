import {
  ActionType,
  ErrorType,
  ConversationsSearchParamsType,
  MessageCenterStateType,
  MessagesSearchResultsType,
  ConversationType,
  BaseTaxonomyType,
  ItemType,
  OpenedConversationType,
  ConversationMessageType,
  SearchConversationsType,
} from 'Interfaces'
import {isEmpty, isNil} from 'lodash'
import * as type from '../types'

const initialState: MessageCenterStateType = {
  data: [],
  params: {},
  loading: false,
  error: undefined,
  loadMoreConversations: undefined,
  loadMoreConversationsProgress: false,
  loadMoreMessages: '',
  loadMoreMessagesProgress: false,
  openedConversation: undefined,
  messageRecipients: [],
  unreadCount: undefined,
  specificParticipiantUnreadCount: undefined,
  socket: {
    connectionStatus: 'OFF',
    client: undefined,
  },
}

const MessageCenterReducer = (
  state = initialState,
  action: ActionType<
    | ConversationType[]
    | ConversationsSearchParamsType
    | MessagesSearchResultsType
    | ConversationType
    | OpenedConversationType
    | ErrorType
    | BaseTaxonomyType[]
    | string
    | undefined
    | any
  >
): MessageCenterStateType => {
  switch (action.type) {
    case type.messageCenter.search.requested:
    case type.messageCenter.loadMoreConversations.requested:
      return {
        ...state,
        loadMoreConversationsProgress: true,
      }
    case type.messageCenter.loadMoreMessages.requested:
      return {
        ...state,
        loadMoreMessagesProgress: true,
      }
    case type.messageCenter.search.succeeded:
      const searchPayload = action.payload as SearchConversationsType

      return {
        ...state,
        data: searchPayload.response.data.items,
        loading: false,
        error: undefined,
        loadMoreConversations: searchPayload.loadMoreConversations,
        loadMoreMessages: searchPayload.loadMoreMessages,
        loadMoreConversationsProgress: false,
        loadMoreMessagesProgress: false,
      }
    case type.messageCenter.loadMoreConversations.succeeded:
      const loadMorePayload = action.payload as SearchConversationsType

      if (loadMorePayload) {
        return {
          ...state,
          data: [...state.data, ...loadMorePayload.response.data.items],
          loadMoreConversations: loadMorePayload.loadMoreConversations,
          loadMoreConversationsProgress: false,
          loading: false,
          error: undefined,
        }
      }
      return {...state}
    case type.messageCenter.loadMoreMessages.succeeded:
      const loadMoreMessagesPayload = action?.payload

      if (
        loadMoreMessagesPayload.response.data.items &&
        state.openedConversation
      ) {
        return {
          ...state,
          openedConversation: {
            ...state.openedConversation,
            messages: [
              ...state.openedConversation.messages,
              ...loadMoreMessagesPayload.response.data.items,
            ],
          },
          loadMoreMessages: loadMoreMessagesPayload.loadMoreMessages,
          loadMoreMessagesProgress: false,
          loading: false,
          error: undefined,
        }
      }
      return state

    case type.messageCenter.archiveConversation.succeeded:
      const markConvArchived = action.payload as ConversationType[]
      const changedConvsIds = markConvArchived.map((conv) => conv.id)

      return {
        ...state,
        data: state.data.map((conv) => {
          if (changedConvsIds.includes(conv.id)) {
            const archived = markConvArchived.find(
              (arch) => arch.id === conv.id
            )
            if (archived) return archived
          }
          return conv
        }),
      }
    case type.messageCenter.getMessageRecipients.succeeded:
      return {
        ...state,
        messageRecipients: action.payload as ItemType[],
      }
    case type.messageCenter.selectConversation.succeeded:
      return {
        ...state,
        openedConversation: action.payload as OpenedConversationType,
        loadMoreMessages: action.payload.loadMoreMessages,
      }
    case type.messageCenter.getUnreadConversationsCount.succeeded:
    case type.messageCenter.socketIO.events.unreadConversationsCount:
      return {
        ...state,
        unreadCount: action.payload as number,
      }
    case type.messageCenter.getSpecificParticipiantUnreadConversationsCount
      .succeeded:
      return {
        ...state,
        specificParticipiantUnreadCount: action.payload as number,
      }
    case type.messageCenter.socketIO.events.unreadConversationsCountSeekers:
    case type.messageCenter.socketIO.events
      .unreadConversationsCountCompanyUsers:
    case type.messageCenter.socketIO.events.unreadConversationsCountTSA:
      return {
        ...state,
        specificParticipiantUnreadCount: action.payload as number,
      }
    case type.messageCenter.search.failed:
    case type.messageCenter.loadMoreMessages.failed:
    case type.messageCenter.loadMoreConversations.failed:
    case type.messageCenter.getUnreadConversationsCount.failed:
      return {
        ...state,
        data: state.data,
        loading: false,
        error: action.payload as ErrorType,
        loadMoreConversations: undefined,
        loadMoreMessages: '',
        loadMoreConversationsProgress: false,
        loadMoreMessagesProgress: false,
      }

    case type.messageCenter.socketIO.events.conversationsUnread:
      const markConvUnread = action.payload
      let conversationIds: string[] = []
      markConvUnread.forEach((conversation: ConversationMessageType) => {
        conversationIds.push(conversation.id)
      })

      return {
        ...state,
        data: state.data.map((conv) => ({
          ...conv,
          conversationUsers: conv.conversationUsers.map((convUsr) => ({
            ...convUsr,
            conversationReadStatus: conversationIds.includes(conv.id)
              ? 'unread'
              : convUsr.conversationReadStatus,
          })),
        })),
      }
    case type.messageCenter.socketIO.events.conversationsRead:
      const markConvRead = action.payload
      let readConversationIds: string[] = []
      markConvRead.forEach((conversation: ConversationMessageType) => {
        readConversationIds.push(conversation.id)
      })

      return {
        ...state,
        data: state.data.map((conv) => ({
          ...conv,
          conversationUsers: conv.conversationUsers.map((convUsr) => ({
            ...convUsr,
            conversationReadStatus: readConversationIds.includes(conv.id)
              ? 'read'
              : convUsr.conversationReadStatus,
          })),
        })),
      }
    case type.messageCenter.socketIO.events.messageReceived:
      const messageReceivedPayload = action.payload

      return {
        ...state,
        openedConversation:
          state.openedConversation &&
          state?.openedConversation?.conversation?.id ===
            messageReceivedPayload.conversationId
            ? ({
                conversation: {
                  ...state.openedConversation?.conversation,
                  message: {
                    ...state.openedConversation?.conversation?.message,
                    ...messageReceivedPayload,
                  },
                },
                messages: [
                  messageReceivedPayload,
                  ...(state.openedConversation
                    ?.messages as ConversationMessageType[]),
                ],
              } as OpenedConversationType)
            : state.openedConversation,
        data: state.data.map((conversation) => {
          if (conversation?.id === messageReceivedPayload?.conversationId) {
            return {
              ...conversation,
              message: messageReceivedPayload,
              conversationUsers: conversation.conversationUsers.map(
                (conversationUser) => {
                  return {
                    ...conversationUser,
                    conversationReadStatus: 'unread',
                  }
                }
              ),
            }
          }
          return conversation
        }),
      }
    case type.messageCenter.socketIO.events.messageCreated:
      const messageCreatedPayload = action.payload as ConversationMessageType

      const buildMessage = () => {
        let message = {}

        if (!isNil(state.openedConversation?.conversation?.message)) {
          message = {
            ...message,
            ...state.openedConversation?.conversation?.message,
          }
        }

        if (!isNil(messageCreatedPayload)) {
          message = {...message, ...messageCreatedPayload}
        }

        if (isEmpty(message)) {
          return message
        }

        return []
      }

      const buildOpenedConversation = ():
        | OpenedConversationType
        | undefined => {
        if (!isNil(state.openedConversation)) {
          const buildConversation = () => {
            if (!isNil(state.openedConversation?.conversation)) {
              return {
                ...state.openedConversation?.conversation,
                message: buildMessage(),
              }
            }
            return []
          }

          const buildMessages = () => {
            if (!isNil(state.openedConversation?.messages)) {
              return [
                messageCreatedPayload,
                ...(state.openedConversation
                  ?.messages as ConversationMessageType[]),
              ]
            }
            return []
          }

          return {
            conversation: buildConversation(),
            messages:
              state.openedConversation.conversation.id ===
              messageCreatedPayload.conversationId
                ? buildMessages()
                : state.openedConversation.messages,
          } as OpenedConversationType
        }
        return undefined
      }

      return {
        ...state,
        data: state.data.map((conversation) => {
          if (conversation.id === messageCreatedPayload.conversationId) {
            return {
              ...conversation,
              message: messageCreatedPayload,
            }
          }
          return conversation
        }),
        openedConversation: buildOpenedConversation(),
      }
    case type.messageCenter.socketIO.events.conversationCreated:
      // check conversation existance in store
      const newConversationExistsInData = state.data.find((conversation) => {
        return action.payload && conversation.id === action?.payload?.id
      })

      if (!newConversationExistsInData) {
        const newConversation = action.payload
        return {
          ...state,
          data: [newConversation, ...state.data],
        }
      } else {
        return state
      }
    case type.messageCenter.socketIO.events.addedToConversation:
      // check conversation existance in store
      const addedConversationExistsInData = state.data.find((conversation) => {
        return action.payload && conversation.id === action?.payload?.id
      })
      const newConversation = action.payload

      if (!addedConversationExistsInData) {
        return {
          ...state,
          data: [newConversation, ...state.data],
        }
      } else {
        return {
          ...state,
          data: state.data.map((conv) => {
            if (conv.id === action.payload.id) {
              return action.payload
            }
            return conv
          }),
          openedConversation:
            state.openedConversation &&
            ({
              conversation: {
                ...state.openedConversation?.conversation,
                message: {
                  ...state.openedConversation?.conversation?.message,
                  ...newConversation.message,
                },
              },
              messages: [
                newConversation.message,
                ...(state.openedConversation
                  ?.messages as ConversationMessageType[]),
              ],
            } as OpenedConversationType),
        }
      }
    case type.messageCenter.conversationRecieved.succeeded:
      const newConversationRecieved = action.payload
      return {
        ...state,
        data: [newConversationRecieved, ...state.data],
      }

    case type.messageCenter.getConversationByUserIds.succeeded:
      return {
        ...state,
        data: [action.payload.data],
      }
    case type.messageCenter.getConversationByUserIds.failed:
      return {
        ...state,
        data: [],
        loadMoreMessages: '',
        openedConversation: undefined,
      }
    case type.messageCenter.resetMessageCenterData:
      return {
        ...state,
        data: [],
        loadMoreMessages: '',
        openedConversation: undefined,
      }
    case type.messageCenter.socketIO.setSocket:
      return {
        ...state,
        socket: action.payload,
      }
    default:
      return state
  }
}

export default MessageCenterReducer
