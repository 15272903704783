import {connect} from 'react-redux'

import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {CandidateDashboard} from './CandidateDashboard'
import {
  getSeekerProfile,
  getDashboardIncomingRequests,
  getCandidateCompleteness,
  getCandidateJourneyStep,
} from 'Store/Actions'

const mapStateToProps = (state: RootState) => ({
  journeyTracker: state.incomingRequests.journeyTracker?.data,
  dashboardRequests: state.incomingRequests.dashboardRequests,
  fullProfile: state.seeker.data?.full,
  profileCompleteness: state.candidateProfileCompleteness.data,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getFullCandidateData: () => {
    dispatch(getSeekerProfile())
    dispatch(getCandidateCompleteness())
    dispatch(getDashboardIncomingRequests())
    dispatch(getCandidateJourneyStep())
  },
})

export const CandidateDashboardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateDashboard)
