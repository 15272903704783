import {FC} from 'react'

import {ItemType} from 'Interfaces'
import {getRecipientType} from 'Utils/GetRecipientType'

import styles from './NewMessageRecipient.module.scss'
import {getAge} from 'Utils/Date'
import {MessageParticipantPicture} from '../MessageParticipantPicture'
import {MessageParticipantBasicInformations} from '../MessageParticipantBasicInformations'

interface NewMessageRecipientProps {
  item: ItemType
  onClick: () => void
}

export const NewMessageRecipient: FC<NewMessageRecipientProps> = ({
  item,
  onClick,
}) => {
  return (
    <div onClick={onClick} className={styles.newMessageRecipientContainer}>
      <div className={styles.messageParticipantPictureWrap}>
        <MessageParticipantPicture
          profilePicture={item[getRecipientType(item.type)]?.profilePicture}
          firstName={item[getRecipientType(item.type)]?.firstName}
          lastName={item[getRecipientType(item.type)]?.lastName}
        />
      </div>

      <MessageParticipantBasicInformations
        firstName={item[getRecipientType(item.type)]?.firstName}
        lastName={item[getRecipientType(item.type)]?.lastName}
        publicOccupation={
          item[getRecipientType(item.type)]?.publicOccupation?.translation
        }
        dateOfBirth={
          item[getRecipientType(item.type)]?.dateOfBirth &&
          getAge(item[getRecipientType(item.type)]?.dateOfBirth)
        }
        town={item[getRecipientType(item.type)]?.town?.translation}
        companyName={item[getRecipientType(item.type)]?.company?.name}
      />
    </div>
  )
}
