import {FC} from 'react'

import {IncomingRequestType, RjbProfileStatus} from 'Interfaces'
import {getAge} from 'Utils/Date'

import styles from './SeekerProfile.module.scss'
import {Link} from 'react-router-dom'
import {AppRoute} from 'Services/I18n/Constants'
import {getRoute} from 'Services/I18n/Utils'
import {OfferRequestStatus} from 'Components/IncomingRequests/Constants'

type SeekerProfileProps = {
  request: IncomingRequestType
  setIsModalOpen: (v: boolean) => void
}

export const SeekerProfile: FC<SeekerProfileProps> = ({
  request,
  setIsModalOpen,
}) => {
  const {seeker, status} = request

  const isLocked = status !== OfferRequestStatus.ACCEPTED

  const noLongerActiveCandidate =
    !seeker.hasFinishedPlacementWithCurrentEmployer &&
    seeker.rjbProfileStatus === RjbProfileStatus.CLOSED

  const profileUrl = isLocked
    ? getRoute(AppRoute.EmployerCandidateProfilePublic).replace(
        ':id',
        seeker.userId
      )
    : getRoute(AppRoute.EmployerCandidateProfileFull).replace(
        ':id',
        seeker.userId
      )

  return (
    <div className={styles.seekerProfileWrap}>
      <div
        className={styles.avatarWrap}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {seeker.profilePicture ? (
          noLongerActiveCandidate ? (
            <button
              type="button"
              onClick={() => setIsModalOpen(true)}
              className={styles.avatarImg}
            >
              <img
                className={styles.avatarImg}
                src={seeker.profilePicture.url}
                alt={`${seeker.firstName} ${seeker.lastName}`}
              />
            </button>
          ) : (
            <Link to={profileUrl}>
              <img
                className={styles.avatarImg}
                src={seeker.profilePicture.url}
                alt={`${seeker.firstName} ${seeker.lastName}`}
              />
            </Link>
          )
        ) : (
          <div className={styles.avatarLocked}>
            {noLongerActiveCandidate ? (
              <button
                type="button"
                onClick={() => setIsModalOpen(true)}
                className={styles.avatarLocked}
              >
                {seeker.firstName && seeker.lastName
                  ? seeker.firstName[0] + seeker.lastName[0]
                  : ''}
              </button>
            ) : (
              <Link to={profileUrl}>
                {seeker.firstName && seeker.lastName
                  ? seeker.firstName[0] + seeker.lastName[0]
                  : ''}
              </Link>
            )}
          </div>
        )}
      </div>
      <div className={styles.seekerInfo}>
        {seeker.publicOccupation &&
          (noLongerActiveCandidate ? (
            <button type="button" onClick={() => setIsModalOpen(true)}>
              <div className={styles.jobTitle}>
                <p>{seeker.publicOccupation.translation}</p>
              </div>
            </button>
          ) : (
            <Link to={profileUrl}>
              <div className={styles.jobTitle}>
                <p>{seeker.publicOccupation.translation}</p>
              </div>
            </Link>
          ))}
        <div>
          {noLongerActiveCandidate ? (
            <button type="button" onClick={() => setIsModalOpen(true)}>
              <span className={styles.fullName}>
                {`${seeker.firstName} ${seeker.lastName}`}
              </span>
            </button>
          ) : (
            <Link to={profileUrl}>
              <span className={styles.fullName}>
                {`${seeker.firstName} ${seeker.lastName}`}
              </span>
            </Link>
          )}

          {seeker.dateOfBirth &&
            (noLongerActiveCandidate ? (
              <button type="button" onClick={() => setIsModalOpen(true)}>
                <span className={styles.age}>
                  {`, ${getAge(seeker.dateOfBirth as string)}`}
                </span>
              </button>
            ) : (
              <Link to={profileUrl}>
                <span className={styles.age}>
                  {`, ${getAge(seeker.dateOfBirth as string)}`}
                </span>
              </Link>
            ))}
          {seeker?.town &&
            (noLongerActiveCandidate ? (
              <button
                type="button"
                onClick={() => setIsModalOpen(true)}
                className={styles.location}
              >{`, ${seeker.town.translation}`}</button>
            ) : (
              <span className={styles.location}>
                <Link to={profileUrl}>{`, ${seeker.town.translation}`}</Link>
              </span>
            ))}
        </div>
      </div>
    </div>
  )
}
