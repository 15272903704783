import * as Yup from 'yup'

export const ValidationNext = Yup.object().shape({
  regions: Yup.array().of(Yup.string()).min(1, 'Regions are required'),
  workFromHomeId: Yup.string().required('Work from home field is required'),
  salaryId: Yup.string().required('Salary is required'),
  jobSearchStatusId: Yup.string().required('Job search status is required'),
  availabilityId: Yup.string().required('Availability is required'),
  occupations: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        translation: Yup.string(),
      })
    )
    .min(1, 'Desired job positions are required')
    .max(3, 'Select max of 3 job positions'),
})
export const ValidationBack = Yup.object().shape({
  regions: Yup.array().of(Yup.string()),
  workFromHomeId: Yup.string(),
  salaryId: Yup.string(),
  jobSearchStatusId: Yup.string(),
  availabilityId: Yup.string(),
  occupationsIds: Yup.array().of(Yup.string()),
})
