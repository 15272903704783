import {FC} from 'react'

import Modal from 'Components/Modal'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import styles from './ModalSendMessageEmp.module.scss'
import {
  ConversationType,
  CreateConversationType,
  CreateMessageType,
  IncomingRequestSeekerType,
  IncomingRequestType,
} from 'Interfaces'
import {Candidate} from 'Interfaces/CandidateType'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'
import Hr from 'Components/Hr'
import Title from 'Components/Title'
import {useFormik} from 'formik'
import {OnChangeProps} from 'Utils/FormikProps'
import TextArea from 'Components/Inputs/TextArea'
import {Validation} from './Validation'
import {MailOutline} from '@mui/icons-material'
interface ModalSendMessageEmpProps {
  request?: IncomingRequestSeekerType | Candidate | IncomingRequestType | any
  isOpen: boolean
  setIsOpen: (open: boolean) => void
  conversation: ConversationType
  createMessage: (data: CreateMessageType) => void
  createConversation: (data: CreateConversationType) => void
  parentSelector?(): HTMLElement
}

export const ModalSendMessageEmp: FC<ModalSendMessageEmpProps> = ({
  request,
  isOpen,
  setIsOpen,
  conversation,
  createConversation,
  createMessage,
  parentSelector,
}) => {
  let otherParticipantsUserIds = request?.seeker
    ? request.seeker.userId
    : request?.userId

  const source = request?.seeker
    ? request?.seeker?.profilePicture?.url
    : request?.profilePicture?.url

  const firstName = request?.seeker
    ? request.seeker.firstName
    : request?.firstName

  const lastName = request?.seeker ? request.seeker.lastName : request?.lastName

  const formik = useFormik({
    validationSchema: Validation,
    initialValues: {
      messageBody: '',
    },

    onSubmit: (values) => {
      if (!conversation && otherParticipantsUserIds) {
        createConversation({
          messageBody: values.messageBody,
          otherParticipantsUserIds: [otherParticipantsUserIds],
        })
        setIsOpen(false)
      } else {
        createMessage({
          body: values.messageBody,
          conversationId: conversation.id,
        })
        setIsOpen(false)
      }
      formik.setFieldValue('messageBody', '')
    },
  })

  return (
    <>
      <Modal
        className={styles.modalDetails}
        parentSelector={parentSelector}
        icon={MailOutline}
        isOpen={isOpen}
        title={edt.sendMessage}
        onAfterClose={() => {
          setIsOpen(false)
        }}
        shouldReturnFocusAfterClose={false}
        theme="desktopFlex"
        Footer={
          <>
            <SubmitFooter
              onSubmit={() => {
                formik.handleSubmit()
              }}
              onCancel={() => setIsOpen(false)}
              theme="modalProfileSubmit"
              submitText={edt.sendMessage}
            />
          </>
        }
      >
        <div className={styles.containerInterviewDetails}>
          <Title text={edt.sendMessageTo} theme="modalRegular" />
          <div className={styles.titleContainer}>
            <img
              className={styles.avatar}
              //to do src change link
              src={source ? source : ''}
              alt={`${firstName} ${lastName}`}
              onError={(event) =>
                ((event.target as HTMLInputElement).style.display = 'none')
              }
            />
            <p className={styles.titleAvatar}>
              {firstName} {lastName}
            </p>
          </div>
          <Hr theme="modal" className={styles.hr} />
          <Title
            text={edt.body}
            theme="modalRegular"
            className={styles.bodyTitle}
          />
          <div className={styles.container}>
            <TextArea
              name="messageBody"
              {...OnChangeProps(formik, 'messageBody')}
              theme="modal"
              className={styles.textArea}
            />
          </div>
        </div>
      </Modal>
    </>
  )
}
