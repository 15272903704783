import {FC} from 'react'
import {LogGuestConsentChangeType, StateType} from 'Interfaces'
import styles from './NewsletterUnsubscribeReason.module.scss'
import Preferenca from 'Assets/Images/preferenca-logo-white.svg'
import YellowButton from 'Components/YellowButton'
import Checkbox from 'Components/Inputs/Checkbox'
import {useFormik} from 'formik'
import WarningIcon from '@mui/icons-material/Warning'
import TextArea from 'Components/Inputs/TextArea'
import {UnsubscribeReason as ur} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import {useTranslation} from 'Hooks'

type NewsletterUnsubscribeReasonProps = {
  consentsReasonLog: StateType<string[] | undefined>
  logGuestConsentChangeReason: (data: LogGuestConsentChangeType) => void
}

export const NewsletterUnsubscribeReason: FC<
  NewsletterUnsubscribeReasonProps
> = ({consentsReasonLog, logGuestConsentChangeReason}) => {
  const translation = useTranslation()

  const unsubscribeReasons = [
    {
      reason: translation[ur.checkBoxLabel1],
      checked: false,
    },
    {
      reason: translation[ur.checkBoxLabel2],
      checked: false,
    },
    {
      reason: translation[ur.checkBoxLabel3],
      checked: false,
    },
    {
      reason: translation[ur.checkBoxLabel4],
      checked: false,
      text: '',
    },
  ]

  const FormikValues: LogGuestConsentChangeType = {
    consentChangeLogIds: consentsReasonLog.data || [],
    reasons: unsubscribeReasons,
  }

  const formik = useFormik({
    initialValues: FormikValues,
    onSubmit: (values) => {
      logGuestConsentChangeReason(values)
    },
  })

  const toggleReason = (reasonIdx: number) => {
    const values = formik.values.reasons
    values.forEach((_, idx) => {
      if (reasonIdx === idx) {
        values[idx].checked = values[idx].checked ? false : true
      }
    })
    formik.setFieldValue('reasons', values)
  }

  return (
    <div className={styles.wrap}>
      <div className={styles.card}>
        <div className={styles.header}>
          <img className={styles.logo} src={Preferenca} alt="Logo" />
        </div>
        {consentsReasonLog.success ? (
          <div className={styles.content}>
            <div className={styles.title}>
              <FormattedMessage id={ur.reasonSuccess} />
            </div>
          </div>
        ) : (
          <div className={styles.content}>
            <div className={styles.title}>
              <FormattedMessage id={ur.title} />
            </div>
            <div className={styles.description}>
              <FormattedMessage id={ur.description} />
            </div>
            <div className={styles.consentsWrap}>
              {!consentsReasonLog.data ? (
                <div className={styles.warning}>
                  <WarningIcon />
                  <span>
                    <FormattedMessage id={ur.warning} />
                  </span>
                </div>
              ) : (
                formik.values.reasons.map((reason, idx) => (
                  <Checkbox
                    key={idx}
                    className={styles.checkbox}
                    name={`reason-${idx}`}
                    onChange={() => toggleReason(idx)}
                    value={!!reason.checked}
                  >
                    <span className={styles.checkboxContent}>
                      {reason.reason}
                    </span>
                  </Checkbox>
                ))
              )}

              {formik.values.reasons[3].checked && (
                <TextArea
                  name="reasons[3].text"
                  value={`${formik.values.reasons[3].text}`}
                  onChange={formik.handleChange}
                  placeholderText="Vpišite razlog za odjavo od novic ..."
                  className={styles.editAboutMeTxt}
                />
              )}

              <YellowButton
                onClick={formik.handleSubmit}
                className={styles.submitBtn}
                text={translation[ur.button]}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}
