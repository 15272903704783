export enum EmployerHomePageTranslations {
  buttonLandingTxt = 'employerHomePage.buttonLandingTxt',
  titleLandingTxt = 'employerHomePage.titleLandingTxt',
  yellowLandingTxt = 'employerHomePage.yellowLandingTxt',
  paragraphFirst = 'employerHomePage.paragraphFirst',
  paragraphSecond = 'employerHomePage.paragraphSecond',

  titleSteps = 'employerHomePage.titleSteps',
  yellowTxtSteps = 'employerHomePage.yellowTxtSteps',
  subTitleStepsFirst = 'employerHomePage.subTitleStepsFirst',
  subTitleStepsBreakFirst = 'employerHomePage.subTitleStepsBreakFirst',
  subTitleStepsFirstParagraph = 'employerHomePage.subTitleStepsFirstParagraph',

  subTitleStepsSecond = 'employerHomePage.subTitleStepsSecond',
  subTitleStepsBreakSecond = 'employerHomePage.subTitleStepsBreakSecond',
  subTitleStepsSecondParagraph = 'employerHomePage.subTitleStepsSecondParagraph',

  subTitleStepsThird = 'employerHomePage.subTitleStepsThird',
  subTitleStepsThirdParagraph = 'employerHomePage.subTitleStepsThirdParagraph',

  howDoesItWorkTitlePartOne = 'employerHomePage.howDoesItWorkTitlePartOne',
  howDoesItWorkTitlePartTwo = 'employerHomePage.howDoesItWorkTitlePartTwo',
  howDoesItWorksubTitleFirst = 'employerHomePage.howDoesItWorksubTitleFirst',
  howDoesItWorksubTitleSecond = 'employerHomePage.howDoesItWorksubTitleSecond',
  howDoesItWorksubTitleThird = 'employerHomePage.howDoesItWorksubTitleThird',
  howDoesItWorksubTitleFourth = 'employerHomePage.howDoesItWorksubTitleFourth',

  howDoesItWorkParagraphFirst = 'employerHomePage.howDoesItWorkParagraphFirst',
  howDoesItWorkParagraphSecond = 'employerHomePage.howDoesItWorkParagraphSecond',
  howDoesItWorkParagraphThird = 'employerHomePage.howDoesItWorkParagraphThird',
  howDoesItWorkParagraphFourth = 'employerHomePage.howDoesItWorkParagraphFourth',
}
