import {FC, ReactNode} from 'react'
import {useMediaQuery} from 'react-responsive'

import {dateOrTime} from 'Utils/Date/DateOrTime'
import {ConversationMessageType} from 'Interfaces'

import styles from './MessageMessagesSection.module.scss'
import ConditionalWrapper from 'Components/ConditionalWrapper'

interface MessageMessagesSectionProps {
  message: ConversationMessageType
}

export const MessageMessagesSection: FC<MessageMessagesSectionProps> = ({
  message: {
    senderFirstName,
    senderLastName,
    dateSent,
    body,
    // TODO: Implement message title when needed
    // title = '',
    senderProfilePicture,
  },
}) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 900px)',
  })

  return (
    <ConditionalWrapper
      condition={isMobile}
      wrapper={(children: ReactNode) => (
        <div className={styles.mobileContainer}>{children}</div>
      )}
    >
      <div className={styles.container}>
        {senderProfilePicture && senderProfilePicture.url ? (
          <div className={styles.avatar}>
            <img
              className={styles.senderProfilePicture}
              src={senderProfilePicture.url}
              alt={`${senderFirstName} ${senderLastName}`}
            />
          </div>
        ) : (
          <div className={styles.avatarLetters}>
            <p>
              {senderFirstName?.[0]?.toUpperCase()}
              {senderLastName?.[0]?.toUpperCase()}
            </p>
          </div>
        )}

        <div className={styles.content}>
          <div className={styles.up}>
            <p className={styles.nameUser}>
              {senderFirstName} {senderLastName}
            </p>
            <p className={styles.dateSent}> {dateOrTime(dateSent, true)}</p>
          </div>
          {/* TODO: Implement message title when needed */}
          {/* {title && <div className={styles.message}>{title}</div>} */}
          {!isMobile && <div className={styles.message}>{body}</div>}
        </div>
      </div>
      {isMobile && <div className={styles.message}>{body}</div>}
    </ConditionalWrapper>
  )
}
