import CloseIcon from '@mui/icons-material/Close'
import classnames from 'classnames'
import styles from './CloseIcon.module.scss'

export interface IconCloseProps {
  className?: string
}

export const IconClose = ({className}: IconCloseProps): JSX.Element => {
  return <CloseIcon className={classnames(styles.closeIcon, className)} />
}
