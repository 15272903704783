export const registration = {
  'registration.title': 'Create an account',
  'registration.description':
    'Create your free account and get immediate access to the app. It’s free!',
  'registration.firstName': 'First Name',
  'registration.lastName': 'Last Name',
  'registration.email': 'Email',
  'registration.password': 'Password',
  'registration.repeatPassword': 'Repeat Password',
  'registration.readMore': 'Read more',
  'registration.privacyPolicy': 'Privacy and personal data protection policy',
  'registration.agree': 'I consent to the ',
  'registration.and': ' and ',
  'registration.generalTermsOfService': 'General terms of use',
  'registration.termsOfService': 'Terms and conditions of use on Preferenca.si',
  'registration.nextButton': 'Next',
  'registration.sideBarTitle': 'Don’t search, choose',
  'registration.sideBarJobOffers': 'Create a great resume',
  'registration.sideBarCreateCv': 'Consult a personal HR consultant',
  'registration.sideBarHrSpecialist': 'Receive interesting job offers',
  'registration.sideBarContactCompany': 'Upgrade your career',
  'registration.sideBarFindMatch': 'Choose the best offer',
  'registration.validationPassword': 'Passwords must match',
  'registration.validationTermsAndConditions':
    'Accept Terms & Conditions is required',
  'registration.validationTermsOfService': 'Terms of service is required',
  'registration.loaderText': 'We are creating your account...',
}
