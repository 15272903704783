import * as type from 'Store/types'

import {
  ActionType,
  ErrorType,
  MyProjectsStateType,
  AddEmployerProjectPayloadType,
  EditMyProjectType,
  EmployerProjectItemType,
  MyProjectCandidatesItemType,
  SeekerEvaluationType,
  // EmployerProjectCandidatesItemType,
  AllProjectsResponseType,
  AllProjectsStateType,
  SeekerReviewType,
  ProjectCandidatesResponseType,
  MyProjectCandidatesStateType,
  RemoveCandidateFromProjectsType,
  SendContactRequestResponseType,
} from 'Interfaces'

const initialState = {
  widgetProjects: {
    data: [],
    loading: false,
    error: undefined,
  },
  allProjects: {
    data: [],
    loadMore: undefined,
    loadMoreProgress: false,
    total: undefined,
    loading: false,
    error: undefined,
  },
  project: {
    data: null,
    loading: false,
    error: undefined,
  },
  projectCandidates: {
    data: [],
    loadMore: undefined,
    loadMoreProgress: false,
    total: undefined,
    loading: false,
    error: undefined,
    params: undefined,
  },
  searchResults: {
    data: [],
    loading: false,
    error: undefined,
    loadMore: undefined,
    loadMoreProgress: false,
    total: undefined,
    params: null,
  },
}

const MyProjectsReducer = (
  state: MyProjectsStateType = initialState,
  action: ActionType<
    | string
    | EmployerProjectItemType[]
    | EditMyProjectType
    | AddEmployerProjectPayloadType
    | EmployerProjectItemType
    | MyProjectCandidatesItemType[]
    | ErrorType
    | SeekerEvaluationType
    | AllProjectsResponseType
  >
): MyProjectsStateType => {
  switch (action.type) {
    case type.myProjects.widget.get.requested:
      return {
        ...state,
        widgetProjects: {
          ...state.widgetProjects,
          loading: true,
        },
      }
    case type.myProjects.widget.get.succeeded:
      return {
        ...state,
        widgetProjects: {
          ...state.widgetProjects,
          data: action.payload as EmployerProjectItemType[],
          loading: false,
          error: undefined,
        },
      }
    case type.myProjects.widget.get.failed:
      return {
        ...state,
        widgetProjects: {
          ...state.widgetProjects,
          loading: false,
          error: action.payload as ErrorType,
        },
      }
    case type.myProjects.all.get.requested:
      return {
        ...state,
        allProjects: {
          ...state.allProjects,
          loading: true,
        },
      }
    case type.myProjects.all.get.succeeded:
      const allProjectsResponse = action.payload as AllProjectsResponseType
      return {
        ...state,
        allProjects: {
          ...state.allProjects,
          data: allProjectsResponse.data,
          total: allProjectsResponse.total,
          loading: false,
          error: undefined,
        },
      }
    case type.myProjects.loadMore.requested:
      return {
        ...state,
        allProjects: {
          ...state.allProjects,
          loadMoreProgress: true,
        },
      }
    case type.myProjects.loadMore.succeeded:
      const loadMoreResponse = action.payload as AllProjectsStateType
      return {
        ...state,
        allProjects: {
          ...state.allProjects,
          data: [...state.allProjects.data, ...loadMoreResponse.data],
          loadMore: loadMoreResponse.loadMore,
          loadMoreProgress: false,
        },
      }
    case type.myProjects.loadMore.failed:
      return {
        ...state,
        allProjects: {
          ...state.allProjects,
          loadMoreProgress: false,
          error: action.payload as ErrorType,
        },
      }
    case type.myProjects.search.requested:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          loading: true,
          params: {
            ...state.searchResults.params,
            ...(action.payload as any),
          },
        },
      }
    case type.myProjects.search.succeeded:
      const searchPayload = action.payload as MyProjectCandidatesStateType
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          data: searchPayload.data,
          total: searchPayload.total,
          loading: false,
          params: searchPayload.params,
        },
      }
    case type.myProjects.search.clear:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          data: [],
        },
      }
    case type.myProjects.search.failed:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          loading: false,
          error: action.payload as ErrorType,
        },
      }
    case type.myProjects.loadMoreSearch.requested:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          loadMoreProgress: true,
        },
      }
    case type.myProjects.loadMoreSearch.succeeded:
      const loadMoreSearchPayload =
        action.payload as MyProjectCandidatesStateType
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          data: [...state.searchResults.data, ...loadMoreSearchPayload.data],
          loadMore: loadMoreSearchPayload.loadMore,
          loadMoreProgress: false,
        },
      }
    case type.myProjects.loadMoreSearch.failed:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          loadMoreProgress: false,
          error: action.payload as ErrorType,
        },
      }
    case type.myProjects.all.get.failed:
      return {
        ...state,
        allProjects: {
          ...state.allProjects,
          loading: false,
          error: action.payload as ErrorType,
        },
      }
    case type.myProjects.addNewProject.succeeded:
      const addNewProjectResponse =
        action.payload as AddEmployerProjectPayloadType
      const allProjectsLoadMore = state.allProjects.loadMore
      const allProjectTotal = state.allProjects.total
      const isAllFetched = allProjectTotal === state.allProjects.data.length
      return {
        ...state,
        widgetProjects: {
          ...state.widgetProjects,
          data: [...state.widgetProjects.data, addNewProjectResponse.project],
        },
        allProjects: {
          ...state.allProjects,
          data:
            !allProjectsLoadMore || (allProjectsLoadMore && isAllFetched)
              ? [...state.allProjects.data, addNewProjectResponse.project]
              : [...state.allProjects.data],
        },
      }
    case type.myProjects.delete.succeeded:
      return {
        ...state,
        widgetProjects: {
          ...state.widgetProjects,
          data: state.widgetProjects.data.filter(
            (project) => project.id !== action.payload
          ),
        },
        allProjects: {
          ...state.allProjects,
          data: state.allProjects.data.filter(
            (project) => project.id !== action.payload
          ),
        },
      }
    case type.myProjects.editProject.succeeded:
      const editProjectPayload = action.payload as EditMyProjectType
      return {
        ...state,
        widgetProjects: {
          ...state.widgetProjects,
          data: state.widgetProjects.data.map((project) => {
            if (project.id === editProjectPayload.id) {
              return {
                ...project,
                title: editProjectPayload.name,
              }
            }
            return project
          }),
        },
        allProjects: {
          ...state.allProjects,
          data: state.allProjects.data.map((project) => {
            if (project.id === editProjectPayload.id) {
              return {
                ...project,
                title: editProjectPayload.name,
              }
            }
            return project
          }),
        },
      }
    case type.myProjects.archive.requested:
      return {
        ...state,
        allProjects: {
          ...state.allProjects,
          loading: true,
        },
      }
    case type.myProjects.archive.succeeded:
      const archiveProjectPayload = action.payload as EmployerProjectItemType
      return {
        ...state,
        allProjects: {
          ...state.allProjects,
          data: state.allProjects.data.map((project) => {
            if (project.id === archiveProjectPayload.id) {
              return {...project, status: archiveProjectPayload.status}
            } else {
              return project
            }
          }),
          loading: false,
        },
      }
    case type.myProjects.project.get.requested:
      return {
        ...state,
        project: {
          ...state.project,
          loading: true,
        },
      }
    case type.myProjects.project.get.succeeded:
      return {
        ...state,
        project: {
          ...state.project,
          data: action.payload as EmployerProjectItemType,
          loading: false,
        },
      }
    case type.myProjects.project.get.failed:
      return {
        ...state,
        project: {
          ...state.project,
          loading: false,
          error: action.payload as ErrorType,
        },
      }
    case type.myProjects.projectCandidates.get.requested:
      return {
        ...state,
        projectCandidates: {
          ...state.projectCandidates,
          loading: true,
        },
      }
    case type.myProjects.projectCandidates.get.succeeded:
      const projectCandidatesPayload =
        action.payload as ProjectCandidatesResponseType
      return {
        ...state,
        projectCandidates: {
          ...state.projectCandidates,
          data: projectCandidatesPayload.data,
          total: projectCandidatesPayload.total,
          loading: false,
        },
      }
    case type.myProjects.projectCandidates.loadMore.requested:
      return {
        ...state,
        projectCandidates: {
          ...state.projectCandidates,
          loadMoreProgress: true,
        },
      }
    case type.myProjects.projectCandidates.loadMore.succeeded:
      const loadMorePayload = action.payload as MyProjectCandidatesStateType
      return {
        ...state,
        projectCandidates: {
          ...state.projectCandidates,
          data: [...state.projectCandidates.data, ...loadMorePayload.data],
          loadMore: loadMorePayload.loadMore,
          loadMoreProgress: false,
        },
      }
    case type.myProjects.removeFromProjects.succeeded:
      const removeFromProjectPayload =
        action.payload as RemoveCandidateFromProjectsType
      const newData = state.projectCandidates.data.filter(
        (projectCandidate) => {
          if (action.payload) {
            return projectCandidate.userId !== removeFromProjectPayload.seekerId
          }
          return state.projectCandidates
        }
      )
      return {
        ...state,
        projectCandidates: {
          ...state.projectCandidates,
          data: newData,
        },
      }
    case type.candidateSearch.requestContact.save.succeeded:
      const sendContactRequestResponse =
        action.payload as SendContactRequestResponseType
      const newProjectCandidatesData = state.projectCandidates.data.map(
        (projectCandidate) => {
          if (
            action.payload &&
            projectCandidate.userId ===
              sendContactRequestResponse.contactRequest.seekerUserId
          ) {
            return {
              ...projectCandidate,
              companySeekerJourney: action.payload,
            }
          }
          return projectCandidate
        }
      )
      return {
        ...state,
        projectCandidates: {
          ...state.projectCandidates,
          data: newProjectCandidatesData as [],
        },
      }

    case type.myProjects.projectCandidates.loadMore.failed:
      return {
        ...state,
        projectCandidates: {
          ...state.projectCandidates,
          loadMoreProgress: false,
          error: action.payload as ErrorType,
        },
      }
    case type.myProjects.projectCandidates.get.failed:
      return {
        ...state,
        projectCandidates: {
          ...state.projectCandidates,
          loading: false,
          error: action.payload as ErrorType,
        },
      }
    case type.myProjects.addToNewProject.succeeded:
      return {
        ...state,
        // TODO: Add candidate to project
        // allProjects: action.payload as EmployerProjectItemType[],
      }
    case type.myProjects.addToNewProject.failed:
      return {
        ...state,
        // TODO: Add candidate to project
        // allProjects: action.payload as EmployerProjectItemType[],
      }
    case type.myProjects.searchSavedCandidates.succeeded:
      const searchCandidatesPayload =
        action.payload as ProjectCandidatesResponseType
      return {
        ...state,
        projectCandidates: {
          ...state.projectCandidates,
          data: searchCandidatesPayload.data,
          total: searchCandidatesPayload.total,
          loading: false,
          params: searchCandidatesPayload?.params,
        },
      }
    case type.myProjects.reviewCandidate.edit.succeeded:
      const reviewPayload = action.payload as SeekerReviewType
      return {
        ...state,
        projectCandidates: {
          ...state.projectCandidates,
          data: state.projectCandidates.data?.map((candidate) => {
            if (candidate.id === reviewPayload.id) {
              return {
                ...candidate,
                companyUserSeekerProject: {
                  ...candidate.companyUserSeekerProject,
                  seekerReview: {
                    ...candidate?.companyUserSeekerProject?.seekerReview,
                    reviewed: reviewPayload.reviewed,
                  },
                },
              }
            }
            return candidate
          }),
        },
      }
    case type.myProjects.reviewCandidate.edit.failed:
      return {
        ...state,
        projectCandidates: {
          ...state.projectCandidates,
          loading: false,
          error: action.payload as ErrorType,
        },
      }
    case type.myProjects.rateCandidate.add.succeeded:
      const addRatePayload = action.payload as SeekerEvaluationType
      return {
        ...state,
        projectCandidates: {
          ...state.projectCandidates,
          data: state.projectCandidates.data?.map((candidate) => {
            if (candidate.userId === addRatePayload.seekerId) {
              return {
                ...candidate,
                companyUserSeekerProject: {
                  ...candidate.companyUserSeekerProject,
                  seekerEvaluation: {
                    ...candidate.companyUserSeekerProject?.seekerEvaluation,
                    evaluation: addRatePayload.evaluation,
                  },
                },
              }
            }
            return candidate
          }),
        },
      }
    case type.myProjects.rateCandidate.edit.succeeded:
      const editRatePayload = action.payload as SeekerEvaluationType
      return {
        ...state,
        projectCandidates: {
          ...state.projectCandidates,
          data: state.projectCandidates.data?.map((candidate) => {
            if (candidate.userId === editRatePayload.seekerId) {
              return {
                ...candidate,
                companyUserSeekerProject: {
                  ...candidate.companyUserSeekerProject,
                  seekerEvaluation: {
                    ...candidate.companyUserSeekerProject?.seekerEvaluation,
                    evaluation: editRatePayload.evaluation,
                  },
                },
              }
            }
            return candidate
          }),
        },
      }
    case type.myProjects.rateCandidate.delete.succeeded:
      const deleteRatePayload = action.payload as SeekerEvaluationType
      return {
        ...state,
        projectCandidates: {
          ...state.projectCandidates,
          data: state.projectCandidates.data?.map((candidate) => {
            if (candidate.userId === deleteRatePayload.seekerId) {
              return {
                ...candidate,
                companyUserSeekerProject: {
                  ...candidate.companyUserSeekerProject,
                  seekerEvaluation: null,
                },
              }
            }
            return candidate
          }),
        },
      }

    case type.myProjects.rateCandidate.add.failed:
    case type.myProjects.rateCandidate.edit.failed:
    case type.myProjects.rateCandidate.delete.failed:
      return {
        ...state,
        projectCandidates: {
          ...state.projectCandidates,
          error: action.payload as ErrorType,
        },
      }
    default:
      return state
  }
}

export default MyProjectsReducer
