// import {langucationItem} from 'Interfaces'

import {LanguageType} from 'Interfaces'

export const Sort = (lang1: LanguageType, lang2: LanguageType): -1 | 1 => {
  if (
    lang1.languageTaxonomy?.translation < lang2.languageTaxonomy?.translation
  ) {
    return -1
  }
  if (
    lang1.languageTaxonomy?.translation > lang2.languageTaxonomy?.translation
  ) {
    return 1
  }
  return 1
}
