import {
  BaseTaxonomyType,
  OccupationExperienceType,
  OccupationExperiencesSaveType,
  SkillsSaveType,
} from 'Interfaces'

export const packSkills = (skills: BaseTaxonomyType[]): SkillsSaveType[] =>
  skills.map((skill) => {
    if (skill?.id.includes('newValue~')) {
      return {
        name: skill.translation,
      }
    }
    return {
      id: skill.id,
    }
  })

export const packOccupationExperiences = (
  oes: OccupationExperienceType[]
): OccupationExperiencesSaveType[] =>
  oes.map((oe) => {
    if (oe.occupation.id.includes('newValue~')) {
      return {
        name: oe.occupation.translation,
        experience: oe.experience,
      }
    }
    return {
      id: oe.occupation.id,
      experience: oe.experience,
    }
  })
