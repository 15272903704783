import ReactModal from 'react-modal'
import styles from './LoadingModal.module.scss'
import {FC} from 'react'
import loader from 'Assets/Images/orange-loading.gif'
import {FormattedMessage} from 'react-intl'

export interface LoadingModalType extends ReactModal.Props {
  text?: string
  isOpen: boolean
}
export const LoadingModal: FC<LoadingModalType> = ({text, isOpen}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      className={styles.modal}
      style={{
        overlay: {
          backgroundColor: 'rgba(0,0,0,0.85)',
          zIndex: 15,
        },
      }}
      ariaHideApp={false}
    >
      <div className={styles.wrapper}>
        <div>
          <img src={loader} alt="loader" />
        </div>
        {text && (
          <div className={styles.text}>
            <FormattedMessage id={text} />
          </div>
        )}
      </div>
    </ReactModal>
  )
}
