import Tooltip from '@mui/material/Tooltip'
import styles from './BasicTooltip.module.scss'
import {FC} from 'react'
import classnames from 'classnames'

interface BasicTooltipProps {
  text: string
  children: JSX.Element
  className?: string
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
}

export const BasicTooltip: FC<BasicTooltipProps> = ({
  text,
  children,
  placement,
  className,
}) => {
  return (
    <Tooltip
      title={text}
      placement={placement}
      classes={{popper: classnames(styles.tooltipStyle, className)}}
    >
      {children}
    </Tooltip>
  )
}
