import classNames from 'classnames'
import {FC} from 'react'
import styles from './Avatar.module.scss'
import {MeType, SeekerProfileType, ImageType} from 'Interfaces'

interface iAvatarProps {
  me?: MeType
  profile?: SeekerProfileType
  className?: string
  imageOrInitials?: ImageType | string
}

export const Avatar: FC<iAvatarProps> = ({
  me,
  profile,
  className,
  imageOrInitials,
}) => {
  let profileImage

  if (imageOrInitials && typeof imageOrInitials === 'string') {
    profileImage = <span>{imageOrInitials}</span>
  } else if (imageOrInitials) {
    profileImage = (
      <img
        className={styles.image}
        src={(imageOrInitials as ImageType).url}
        alt={`${profile?.firstName} ${profile?.lastName}`}
      />
    )
  }

  return (
    <div className={classNames(styles.avatar, className)}>
      {imageOrInitials ? (
        profileImage
      ) : me?.profilePicture ? (
        <img
          className={styles.image}
          src={me.profilePicture.url}
          alt={`${me.firstName} ${me.lastName}`}
        />
      ) : (
        <span>
          {profile?.firstName.charAt(0) || me?.firstName.charAt(0)}
          {profile?.lastName.charAt(0) || me?.lastName.charAt(0)}
        </span>
      )}
    </div>
  )
}
