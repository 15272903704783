export enum EmployerInfoLandingPageTranslations {
  title = 'employerInfoLandingPage.title',
  yellowTitleTxt = 'employerInfoLandingPage.yellowTitleTxt',
  titleLastPart = 'employerInfoLandingPage.titleLastPart',
  paragraphFirstPart = 'employerInfoLandingPage.paragraphFirstPart',
  paragraphSecondPart = 'employerInfoLandingPage.paragraphSecondPart',
  paragraphThirdPart = 'employerInfoLandingPage.paragraphThirdPart',
  paragraphFourthPart = 'employerInfoLandingPage.paragraphFourthPart',
  paragraphFifthPart = 'employerInfoLandingPage.paragraphFifthPart',
  subtitle = 'employerInfoLandingPage.subtitle',
  subtitleParagraph = 'employerInfoLandingPage.subtitleParagraph',
  subtitleParagraphSecond = 'employerInfoLandingPage.subtitleParagraphSecond',
  titleSecond = 'employerInfoLandingPage.titleSecond',
  yellowTitleSecond = 'employerInfoLandingPage.yellowTitleSecond',
  subTitleSecond = 'employerInfoLandingPage.subTitleSecond',
  yellowTxtFirst = 'employerInfoLandingPage.yellowTxtFirst',
  subParagraphFirst = 'employerInfoLandingPage.subParagraphFirst',
  yellowTxtSecond = 'employerInfoLandingPage.yellowTxtSecond',
  subParagraphSecond = 'employerInfoLandingPage.subParagraphSecond',
  yellowTxtThird = 'employerInfoLandingPage.yellowTxtThird',
  subParagraphThird = 'employerInfoLandingPage.subParagraphThird',
  yellowTxtFourth = 'employerInfoLandingPage.yellowTxtFourth',
  subParagraphFourthFirstPart = 'employerInfoLandingPage.subParagraphFourthFirstPart',
  subParagraphFourthSecondPart = 'employerInfoLandingPage.subParagraphFourthSecondPart',
  yellowTxtFifth = 'employerInfoLandingPage.yellowTxtFifth',
  subParagraphFifthFirstPart = 'employerInfoLandingPage.subParagraphFifthFirstPart',
  subParagraphFifthSecondPart = 'employerInfoLandingPage.subParagraphFifthSecondPart',
  yellowTxtSixth = 'employerInfoLandingPage. yellowTxtSixth',
  yellowTxtSeventh = 'employerInfoLandingPage.yellowTxtSeventh',
  paragraphsWrapFirst = 'employerInfoLandingPage.paragraphsWrapFirst',
  paragraphsWrapSecond = 'employerInfoLandingPage.paragraphsWrapSecond',
  yellowButton = 'employerInfoLandingPage.yellowButton',
}
