import {connect} from 'react-redux'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {LogGuestConsentChangeType} from 'Interfaces'
import {NewsletterUnsubscribeReason} from './NewsletterUnsubscribeReason'
import {logGuestConsentChangeReason} from 'Store/Actions'

const mapStateToProps = (state: RootState) => ({
  consentsReasonLog: state.homepage.consentsReasonLog,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  logGuestConsentChangeReason: (data: LogGuestConsentChangeType) =>
    dispatch(logGuestConsentChangeReason(data)),
})

export const NewsletterUnsubscribeReasonContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsletterUnsubscribeReason)
