import {connect} from 'react-redux'

import {PsychometricTest} from './PsychometricTest'

import {RootState} from 'Store/Reducers'
import {ChangeSelbyMillsConsentStatusType} from 'Interfaces'
import {AppDispatch} from 'Store'
import {
  changeShelbyMillsConsentStatus,
  getAssessments,
  getPsychometricTestResults,
  getConsents,
} from 'Store/Actions'

const mapStateToProps = (state: RootState) => ({
  user: state.auth.data,
  assessments: state.assessments,
  psychometricDownloadLink:
    state.assessments.psychometricTestResults.resultFile,
  consents: state?.consents?.data.consents,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  changeShelbyMillsConsentStatus: (data: ChangeSelbyMillsConsentStatusType) =>
    dispatch(changeShelbyMillsConsentStatus(data)),
  getAssessments: () => dispatch(getAssessments()),
  getPsychometricTestResults: (id: string) =>
    dispatch(getPsychometricTestResults(id)),
  getConsents: () => dispatch(getConsents()),
})

export const PsychometricTestContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PsychometricTest)
