import {FC, useEffect, useMemo, useRef, useState} from 'react'
import {Link, useParams} from 'react-router-dom'

import SectionItem from 'Components/Profile/SectionItem'
import SectionHeader from 'Components/Profile/SectionHeader'
import {Button} from 'Components/Button'
import {LabelsGroup} from 'Components/LabelsGroup'
import {Test} from 'Interfaces/Assessments'
import {
  AppRoute,
  CognitiveAssessmentsTranslations as cat,
  ConsentsTranslations as ct,
  GeneralAssessmentsTranslations as gat,
  ModalsAssessmentsTranslations as mat,
  PsychometricAssessmentsTranslations as pat,
} from 'Services/I18n/Constants'
import {getRoute} from 'Services/I18n/Utils'
import classnames from 'classnames'
import classNames from 'classnames'
import Modal, {AssessmentsSubmitFooter} from 'Components/Modal'
import progress from 'Assets/Images/contact-request-proggres.gif'
import CognitiveIcon from 'Assets/Images/cognitive-tests.svg'
import PsychometricIcon from 'Assets/Images/psychometic-tests.svg'
import CheckBox from 'Components/Inputs/Checkbox'
import {
  ChangeCognitiveConsentStatusType,
  ChangeSelbyMillsConsentStatusType,
  ConsentType,
  MeType,
  SeekerConsentId,
  UserConsentsVersionsType,
} from 'Interfaces'
import {TestName} from '../TestName'
import {TestField} from '../TestField'
import {useTranslation} from 'Hooks'
import {FormattedMessage} from 'react-intl'

import styles from './PersonalityTests.module.scss'
import {HistoryEduOutlined, InsertChartOutlined} from '@mui/icons-material'

export interface PersonalityTestsProps {
  personalityTests: Test[]
  typeOfTests: string
  user: MeType | undefined
  changeCognitiveConsentStatus: (
    value: ChangeCognitiveConsentStatusType
  ) => void
  changeShelbyMillsConsentStatus: (
    value: ChangeSelbyMillsConsentStatusType
  ) => void
  getCognitiveTestResults: (value: string) => void
  downloadLink: {
    url: string
  }
  getPsychometricTestResults: (id: string) => void
  psychometricDownloadLink: {
    url: string
  }
  consents: ConsentType[]
  getConsents: Function
}

export const PersonalityTests: FC<PersonalityTestsProps> = ({
  personalityTests,
  typeOfTests,
  user,
  changeShelbyMillsConsentStatus,
  getCognitiveTestResults,
  downloadLink,
  getPsychometricTestResults,
  psychometricDownloadLink,
  consents,
  getConsents,
  changeCognitiveConsentStatus,
}) => {
  let {id} = useParams<{id: string}>()
  const [testName, setTestName] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [checkboxValue, setCheckboxValue] = useState(false)
  const [testId, setTestId] = useState('')
  const [resultButtonClicked, setResultButtonClicked] = useState(false)
  const [cognitiveConsent, setCognitiveConsent] = useState<
    undefined | UserConsentsVersionsType
  >()
  const [psychometricConsentId, setPsychometricConsentId] = useState<
    string | undefined
  >(undefined)
  const [cognitiveConsentId, setCognitiveConsentId] = useState<
    string | undefined
  >(undefined)

  const translation = useTranslation()

  const companyUserRole =
    user?.role === 'employer' ||
    user?.role === 'company_owner' ||
    user?.role === 'recruiter'

  const testsLabels = [
    translation[gat.testTypeLabel],
    translation[gat.testProviderLabel],
    translation[gat.testDateLabel],
    translation[gat.testExpirationDateLabel],
    !companyUserRole && translation[gat.testInfoLabel],
    (!companyUserRole ||
      personalityTests[0].status === 'completed' ||
      personalityTests[1].status === 'completed') &&
      translation[gat.testActionLabel],
  ]

  const linkRef = useRef<HTMLAnchorElement | null>(null)

  let icons = (testName: string, testStatus?: string) => {
    if (testName === 'Cognitive test') {
      return (
        <img
          className={classnames(styles.cognitiveIcon, {
            [styles.completedTestIcon]: testStatus === 'completed',
          })}
          src={CognitiveIcon}
          alt={translation[cat.title]}
        />
      )
    }

    return (
      <img
        className={classnames(styles.psychometicIcon, {
          [styles.completedTestIcon]: testStatus === 'completed',
        })}
        src={PsychometricIcon}
        alt={translation[pat.psychometricTestTitle]}
      />
    )
  }

  const getStatusText = (status: string) => {
    switch (status) {
      case 'results_pending':
        return <img src={progress} alt={translation[gat.pendingButtonText]} />
      case 'completed':
        return <FormattedMessage id={gat.viewResultsText} />
      default:
        return <FormattedMessage id={gat.startTestText} />
    }
  }
  useEffect(() => {
    getConsents()
  }, [])

  useEffect(() => {
    if (consents && consents.length > 0) {
      const cognitiveTest = consents.find((consent) => {
        return consent.id === SeekerConsentId.CognitiveTest
      })
      const psychometricTest = consents.find((consent) => {
        return consent.id === SeekerConsentId.PsychometricTest
      })
      setCognitiveConsent(
        cognitiveTest?.consentVersions[0].userConsentsVersions.find(
          (cognitiveConsent) => {
            return cognitiveConsent?.company === null
          }
        )
      )
      setCognitiveConsentId(cognitiveTest?.id)
      setPsychometricConsentId(psychometricTest?.id)
    }
  }, [consents])

  useEffect(() => {
    // download hr potentials test results
    if (
      linkRef &&
      linkRef.current &&
      downloadLink &&
      downloadLink.url &&
      resultButtonClicked
    ) {
      linkRef.current.click()
    }
  }, [downloadLink])

  useEffect(() => {
    // download hr potentials test results
    if (
      linkRef &&
      linkRef.current &&
      psychometricDownloadLink &&
      psychometricDownloadLink.url &&
      resultButtonClicked
    ) {
      linkRef.current.click()
    }
  }, [psychometricDownloadLink])

  const consentModalText = useMemo(() => {
    const psychometicTestContent = (
      <p className={styles.modalText}>
        <FormattedMessage id={mat.psychometricTestTextFirstPart} />{' '}
        <Link to={getRoute(AppRoute.HomePageCandidate)}>Preferenca.si</Link>
        . <FormattedMessage id={mat.psychometricTestTextSecondPart} />{' '}
        <Link
          to={getRoute(AppRoute.PrivacyPolicy)}
          target={'_blank'}
          rel={'noopener noreferrer'}
        >
          <FormattedMessage id={mat.privacyPolicy} />.
        </Link>
      </p>
    )

    const cognitiveTestContent = (
      <p className={styles.modalText}>
        <FormattedMessage id={mat.cognitiveTestTextFirstPart} />{' '}
        <Link to={getRoute(AppRoute.HomePageCandidate)}>Preferenca.si</Link>
        . <FormattedMessage id={mat.cognitiveTestTextSecondPart} />{' '}
        <Link
          to={getRoute(AppRoute.PrivacyPolicy)}
          target={'_blank'}
          rel={'noopener noreferrer'}
        >
          <FormattedMessage id={mat.privacyPolicy} />.
        </Link>
      </p>
    )

    return {
      'Psychometric test': psychometicTestContent,
      'Cognitive test': cognitiveTestContent,
    }[testName]
  }, [testName])

  const consentModalTitle = useMemo(() => {
    return {
      'Psychometric test': <FormattedMessage id={mat.psychometricTestTitle} />,
      'Cognitive test': <FormattedMessage id={mat.cognitiveTestTitle} />,
    }[testName]
  }, [testName])

  const getHref = () => {
    if (testName === 'Psychometric test') {
      return psychometricDownloadLink?.url
    } else if (testName === 'Cognitive test') {
      return downloadLink?.url
    }
    return '#'
  }

  return (
    <div className={styles.container}>
      <div className={styles.headerSectionContainer}>
        <SectionHeader
          title={`${typeOfTests} ${translation[gat.tests]}`}
          theme="noPaddingX"
          className={styles.assessmentsHeader}
        />
      </div>

      <SectionItem className={styles.sectionItem}>
        <div className={styles.testsContainer}>
          <LabelsGroup
            labels={testsLabels}
            className={classnames(styles.rowLabels, styles.testsLabels)}
          />

          {personalityTests.map((test) => (
            <div
              className={classnames(styles.testContainer, {
                [styles.completedTestContainer]: test.status === 'completed',
              })}
              key={test.id}
            >
              <div className={styles.testContent}>
                <div className={styles.dynamicTestValues}>
                  <TestName
                    icon={icons(test.name, test.status)}
                    testName={
                      test.name === 'Cognitive test'
                        ? cat.title
                        : pat.psychometricTestTitle
                    }
                  />

                  <div className={classNames(styles.testWrapper)}>
                    <TestField
                      label={gat.testProviderLabel}
                      text={test.providerName}
                    />
                  </div>
                  <div className={styles.testWrapper}>
                    <TestField
                      label={gat.testDateLabel}
                      text={
                        test.startDate &&
                        new Date(test.startDate).toLocaleDateString('sl-SI')
                      }
                      className={styles.testMobileDateWrapper}
                    />
                  </div>
                  <div className={styles.testWrapper}>
                    <TestField
                      label={gat.testExpirationDateLabel}
                      text={
                        test.expirationDate &&
                        test.expirationDate !== translation[gat.unlimited]
                          ? new Date(test.expirationDate).toLocaleDateString(
                              'sl-SI'
                            )
                          : test.expirationDate
                      }
                    />
                  </div>

                  <>
                    {!companyUserRole && (
                      <div className={classNames(styles.infoWrapper)}>
                        <p
                          className={classNames(
                            styles.testMobileLabel,
                            styles.testMobileInfoWrapper
                          )}
                        >
                          <FormattedMessage id={gat.testInfoLabel} />
                        </p>
                        <Link
                          to={
                            test.name === 'Cognitive test'
                              ? getRoute(AppRoute.CognitiveTest)
                              : getRoute(AppRoute.PsychometricTest)
                          }
                        >
                          <Button
                            theme="buttonWithIcon"
                            icon={
                              <InsertChartOutlined
                                className={styles.chartIcon}
                              />
                            }
                            className={styles.infoButton}
                          />
                        </Link>
                      </div>
                    )}
                    {(!companyUserRole || test?.status === 'completed') &&
                      (test.name !== 'Psychometric test' ||
                        test.status !== 'completed') && (
                        <div className={styles.dynamicAction}>
                          <Button
                            theme="modalSubmit"
                            onClick={() => {
                              setTestName(test.name)
                              setTestId(test.id)
                              setCheckboxValue(false)

                              if (test?.status === 'invited') {
                                setModalOpen(true)
                              } else if (test?.status === 'completed') {
                                // TODO this is a quick fix for triggering results download when going from page to page, it would be better to set results with action to an empty array
                                if (test.name === 'Psychometric test') {
                                  getPsychometricTestResults(test.id)
                                  setResultButtonClicked(true)
                                } else if (test.name === 'Cognitive test') {
                                  if (!companyUserRole) {
                                    getCognitiveTestResults('')
                                  } else {
                                    getCognitiveTestResults(
                                      `&seekerUserId=${id}`
                                    )
                                  }
                                  setResultButtonClicked(true)
                                }
                              }
                            }}
                            className={classnames(styles.button, {
                              [styles.pendingButton]:
                                test.status === 'results_pending',
                              [styles.removeViewResultsButton]:
                                test.status === 'completed' &&
                                test.resultFile === null,
                            })}
                          >
                            {test.status && getStatusText(test.status)}
                          </Button>
                        </div>
                      )}
                    {test.name === 'Psychometric test' &&
                      test.status === 'completed' && (
                        <div className={styles.dynamicAction}>
                          <Link
                            to={
                              id
                                ? getRoute(
                                    AppRoute.PsychometricTestResults
                                  ).replace(':candidateId', id)
                                : getRoute(
                                    AppRoute.SeekerPsychometricTestResults
                                  )
                            }
                            className={classnames(
                              styles.button,
                              styles.viewResultsLink,
                              {
                                [styles.removeViewResultsButton]:
                                  test.status === 'completed' &&
                                  test.resultFile === null,
                              }
                            )}
                          >
                            {test.status && getStatusText(test.status)}
                          </Link>
                        </div>
                      )}
                    <Modal
                      isOpen={modalOpen}
                      onAfterClose={() => {
                        setModalOpen(false)
                      }}
                      shouldReturnFocusAfterClose={false}
                      title={
                        testName === 'Cognitive test'
                          ? mat.cognitiveTestTitle
                          : mat.psychometricTestTitle
                      }
                      icon={HistoryEduOutlined}
                      ariaHideApp={false}
                      className={styles.modal}
                      Footer={
                        <AssessmentsSubmitFooter
                          disabled={!checkboxValue}
                          userId={user ? user.id : ''}
                          consentId={cognitiveConsent?.id}
                          onSubmit={() => {
                            if (testName === 'Psychometric test') {
                              if (checkboxValue) {
                                changeShelbyMillsConsentStatus({
                                  firstName: user ? user.firstName : '',
                                  lastName: user ? user.lastName : '',
                                  email: user ? user.email : '',
                                  initials: '',
                                  title: '',
                                  id: testId,
                                  testId,
                                  consentId: psychometricConsentId as string,
                                })
                              }
                            } else
                              changeCognitiveConsentStatus({
                                consentId:
                                  cognitiveConsentId as SeekerConsentId,
                                consentStatus: 'granted',
                                testId: testId,
                              })
                            setModalOpen(false)
                          }}
                          onCancel={() => {
                            setModalOpen(false)
                          }}
                          submitText={translation[ct.consentGrant]}
                        />
                      }
                    >
                      {/* TODO this is temporary solution, write this again after finished api*/}
                      <div className={styles.modalTestsContainer}>
                        <h2 className={styles.modalTitle}>
                          {consentModalTitle}
                        </h2>
                        <div className={styles.checkboxTextWrapper}>
                          <CheckBox
                            name="consent1B"
                            value={checkboxValue ? checkboxValue : false}
                            onChange={() => {
                              setCheckboxValue &&
                                setCheckboxValue(!checkboxValue)
                            }}
                            topAlign
                          />
                          {consentModalText}
                        </div>
                      </div>
                    </Modal>
                  </>
                </div>
              </div>
            </div>
          ))}
        </div>
      </SectionItem>
      <a
        href={getHref()}
        className={styles.invisibleLink}
        ref={linkRef}
        download
      ></a>
    </div>
  )
}
