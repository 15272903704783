import {
  AppType,
  ActionType,
  AppStateType,
  SnackbarControlerType,
} from 'Interfaces'

import * as type from '../types'

const initialState = {
  data: {
    snackbars: [],
  },
  loading: false,
  error: undefined,
}

const AppReducer = (
  state: AppStateType = initialState,
  action: ActionType<AppType>
): AppStateType => {
  switch (action.type) {
    case type.app.snackbar.show:
      return {
        data: {
          ...state.data,
          snackbars: state.data.snackbars.concat(
            action.payload?.snackbars as SnackbarControlerType[]
          ),
        },
        loading: false,
        error: undefined,
      }

    case type.app.snackbar.hide:
      return {
        data: {
          ...state.data,
          snackbars: state.data.snackbars.filter(
            (item) => item.id !== action.payload?.id
          ),
        },
        loading: false,
        error: undefined,
      }
    case type.app.snackbar.clean:
      return {
        data: {
          ...state.data,
          snackbars: [],
        },
        loading: false,
        error: undefined,
      }
    default:
      return state
  }
}

export default AppReducer
