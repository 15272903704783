import * as Yup from 'yup'
import {ExtraLongHTML} from 'Validations'

const Validation = Yup.object().shape({
  employmentInformationWhatWeExpect: ExtraLongHTML,
  employmentInformationWhatWeOffer: ExtraLongHTML,
  employmentInformationCustomTextHtml: ExtraLongHTML,
})

export default Validation
