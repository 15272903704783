import {DateTime} from 'luxon'
import {MessageCenterTranslations as mct} from 'Services/I18n/Constants/MessageCenter'
import {useTranslation} from 'Hooks'

export const dateOrTime = (date: string | Date, today: boolean = false) => {
  const translation = useTranslation()

  if (typeof date !== 'object') {
    date = new Date(date)
  }

  let dateFull = DateTime.fromJSDate(date).toFormat('dd. LL. yyyy')
  let dateTime = DateTime.fromJSDate(date).toFormat('HH:mm')
  let dateFullNow = DateTime.now().toFormat('dd. LL. yyyy')

  if (dateFull === dateFullNow) {
    return today ? `${translation[mct.today]}, ${dateTime}` : dateTime
  }

  return dateFull
}
