import {connect} from 'react-redux'

import {Onboarding} from './Onboarding'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {getOnboarding} from 'Store/Actions'

const mapStateToProps = (state: RootState) => ({
  onboarding: state.onboarding,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getOnboarding: () => dispatch(getOnboarding()),
})

export const OnboardingContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Onboarding)
