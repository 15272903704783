export const contactData = {
  'contactData.title': 'Hvala!',
  'contactData.message': `Uspešno ste zaključili registracijski vprašalnik.`,
  'contactData.description': `
  Preferenca je poseben zaposlitveni portal, kjer ima vsak kandidat dobre reference. Pravkar ste zaključili registracijski vprašalnik, kaj lahko zdaj pričakujete? Naši karierni svetovalci bodo pregledali vaše podatke ter vam pomagali, da se kar najbolje pripravite na potencialne delodajalce. Medtem prosimo preglejte vaš življenjepis in rešite zahtevana testiranja.`,
  'contactData.checkMessage': `Preverite vaše kontaktne podatke in zagotovite, da vas naši svetovalci lahko kontaktirajo.`,
  'contactData.phoneNumber': `Telefonska številka`,
  'contactData.email': `E-poštni naslov`,
  'contactData.streetAndNumber': `Naslov`,
  'contactData.zipCode': `Poštna številka`,
  'contactData.city': `Mesto`,
  'contactData.country': `Država`,
  'contactData.confirmMessage': `Potrdite vaše kontaktne podatke`,
  'contactData.selectYourCountry': 'Izberite svojo državo',
  'contactData.selectYourRegion': 'Izberite regijo',
  'contactData.selectAddress': 'Vnesite naslov',
  'contactData.selectPostNumber': 'Vnesite poštno številko',
}
