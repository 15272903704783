import {FC, useEffect, useMemo, useState} from 'react'
import styles from './MyProjects.module.scss'
import MainLayout from 'Layouts/MainLayout'

import {
  Search,
  FolderOpenOutlined,
  UnarchiveOutlined,
  ArchiveOutlined,
  DeleteOutlineOutlined,
  EditOutlined,
  Star,
  Extension,
  ArrowDropDown,
} from '@mui/icons-material'
import {
  AllProjectsStateType,
  ArchiveMyProjectType,
  BaseTaxonomyType,
  Candidate,
  DataType,
  EditMyProjectType,
  MyProjectCandidatesStateType,
  SearchFormikValues,
  SearchMyProjectsParams,
} from 'Interfaces'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import MainHeader from 'Components/MainHeader'
import Modal, {DeleteModal, SubmitFooter} from 'Components/Modal'
import {useFormik} from 'formik'
import Title from 'Components/Title'
import TextField from 'Components/Inputs/TextField'
import {Validation} from './Validation'
import InfiniteScroll from 'Components/InfiniteScroll'
import {
  MyProjectsTranslations as mpt,
  MyProjectCandidatesTranslations as mpct,
} from 'Services/I18n/Constants'
import {useTranslation} from 'Hooks'
import {Link} from 'react-router-dom'
import TypeAhead from 'Components/Inputs/TypeAhead'
import {Button} from 'Components/Button'
import {WidgetBarContainer} from 'Layouts/MainLayout/WidgetBar'
import CandidateSearchSidebarContentContainer from 'Components/CandidateSearch/CandidateSearchSidebarContent'
import Hr from 'Components/Hr'
import {DropdownProps, OnChangeProps} from 'Utils/FormikProps'
import {CandidateCardContainer} from 'Components/CandidateSearch/CandidateCard'
import {FormattedMessage} from 'react-intl'
import {useHandleFocus} from 'Components/Employer/Hooks'
import classnames from 'classnames'
import {CheckBoxIcon} from 'Components/Icons'
import {BasicTooltip} from 'Components/BasicTooltip'
import {SidebarLink} from 'Components/SidebarLink'

type MyProjectsProps = {
  allProjects: AllProjectsStateType
  getAllMyProjects: (values?: SearchMyProjectsParams) => void
  deleteProject: (id: string) => void
  editProject: (values: EditMyProjectType) => void
  createNewProject: (name: string) => void
  setArchiveStatus: (values: ArchiveMyProjectType) => void
  loadMore: (url: string) => void
  getOccupations: () => void
  occupations: DataType<BaseTaxonomyType>
  searchMyProjects: (values: SearchMyProjectsParams) => void
  searchResults: MyProjectCandidatesStateType
  loadMoreSearchResults: (url: string) => void
  clearSearch: () => void
}

export const MyProjects: FC<MyProjectsProps> = ({
  allProjects,
  createNewProject,
  editProject,
  deleteProject,
  getAllMyProjects,
  setArchiveStatus,
  loadMore,
  getOccupations,
  occupations,
  searchMyProjects,
  searchResults,
  loadMoreSearchResults,
  clearSearch,
}) => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)
  const [deleteId, setDeleteId] = useState('')
  const [commentFocusTrigger, handleCommentFocus] = useHandleFocus()
  const [searchMode, setSearchMode] = useState(false)
  const [activeProjects, setActiveProjects] = useState(true)
  const [archivedProjects, setArchivedProjects] = useState(false)
  const [order, setOrder] = useState('DESC')
  const [sort, setSort] = useState('title')

  const handleClearSearch = () => {
    clearSearch()
    setSearchMode(false)
  }

  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: '/'},
    {
      name: mpt.myProjectsTitle,
      action: handleClearSearch,
    },
  ]

  const initialSearchValues: SearchFormikValues = {
    firstName: '',
    lastName: '',
    occupations: [],
    projectStatuses: ['active'],
  }

  const formikAdd = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: Validation,
    onSubmit: (values) => {
      createNewProject(values.name)

      formikAdd.resetForm()
      setIsCreateModalOpen(false)
    },
  })
  const translation = useTranslation()

  const formikEdit = useFormik({
    initialValues: {
      name: '',
      id: '',
    },
    validationSchema: Validation,
    onSubmit: (values) => {
      editProject({
        id: values.id,
        name: values.name,
      })

      formikEdit.resetForm()
      setIsEditModalOpen(false)
    },
  })

  const formikSearch = useFormik({
    initialValues: initialSearchValues,
    onSubmit: (values) => {
      searchMyProjects({
        firstName: values.firstName || undefined,
        lastName: values.lastName || undefined,
        publicOccupationId: values.occupations.length
          ? values.occupations[0].id
          : undefined,
        projectStatus,
      })
      setSearchMode(true)
    },
  })

  const projectStatus = useMemo(() => {
    if (activeProjects && !archivedProjects) {
      return 'active'
    } else if (archivedProjects && !activeProjects) {
      return 'archived'
    } else return
  }, [activeProjects, archivedProjects])

  useEffect(() => {
    getOccupations()
    clearSearch()
    setSearchMode(false)
  }, [])

  useEffect(() => {
    getAllMyProjects({projectStatus, order, sort})
  }, [projectStatus, order, sort])

  useEffect(() => {
    if (!allProjects.loading && !allProjects.error) {
      setDeleteModalOpen(false)
    }
  }, [allProjects])

  const onEditProject = (id: string, name: string) => {
    formikEdit.setFieldValue('id', id)
    formikEdit.setFieldValue('name', name)
    setIsEditModalOpen(true)
  }
  const onDeleteProject = (id: string) => {
    deleteProject(id)
  }
  const onCreateProject = () => {
    setIsCreateModalOpen(true)
  }

  return (
    <MainLayout
      fixedRight={false}
      myProjectsLogo={true}
      rightSidebarContent={
        <WidgetBarContainer
          sidebarContent={
            searchResults.data.length ? (
              <CandidateSearchSidebarContentContainer
                commentFocusTrigger={commentFocusTrigger}
              />
            ) : (
              <div className={styles.searchCandidatesLink}>
                <SidebarLink
                  routeName={AppRoute.CandidateSearch}
                  text={mpt.searchCandidatesLink}
                />
              </div>
            )
          }
          containerClass=".cardWrap"
        />
      }
    >
      <div className={styles.headerWrap}>
        <MainHeader
          breadCrumbsWrapClassName={styles.breadcrumbsWrapper}
          breadcrumbsArray={breadcrumbsArray}
          title={mpt.myProjectsTitle}
        >
          <div
            onClick={onCreateProject}
            className={styles.newProjectBtnBreadcrumbs}
          >
            <FormattedMessage id={mpt.createNewProject} />
          </div>
        </MainHeader>
      </div>
      <div className={styles.newProjectBtnWrap}>
        <div onClick={onCreateProject} className={styles.newProjectBtn}>
          <FormattedMessage id={mpt.createNewProject} />
        </div>
      </div>
      <div className={styles.searchToolbar}>
        <div className={styles.searchFieldWrap}>
          <TextField
            name="firstName"
            placeholder={mpt.firstName}
            {...OnChangeProps(formikSearch, 'firstName')}
            className={styles.textField}
          />
          <div className={styles.searchIcon}>
            <Search />
          </div>
        </div>
        <div className={styles.searchFieldWrap}>
          <TextField
            name="lastName"
            placeholder={mpt.lastName}
            {...OnChangeProps(formikSearch, 'lastName')}
            className={styles.textField}
          />
          <div className={styles.searchIcon}>
            <Search />
          </div>
        </div>
        <div className={styles.searchFieldWrap}>
          <TypeAhead
            name="occupations"
            items={occupations.data}
            maxNumSelections={1}
            withoutArrow
            placeholder={mpt.jobTitle}
            className={styles.typeahead}
            {...DropdownProps(formikSearch, 'occupations')}
          />
          <div className={styles.searchIcon}>
            <Search />
          </div>
        </div>
        <Button
          text={mpct.searchSubmitButton}
          theme="bright"
          onClick={() => formikSearch.submitForm()}
          className={styles.filterDropdown}
        />
      </div>
      <div
        className={styles.resetSearchBtn}
        onClick={() => {
          formikSearch.resetForm()
          handleClearSearch()
        }}
      >
        <FormattedMessage id={mpt.resetSearch} />
      </div>
      <Hr className={styles.thickHr} />
      {searchResults.loading ? (
        <div>{translation[mpt.loading]}</div>
      ) : searchResults.error ? (
        <div>There has been an error</div>
      ) : searchResults.data.length ? (
        <div className={styles.searchContent}>
          <InfiniteScroll
            loading={searchResults.loadMoreProgress}
            hasMore={
              !!(
                searchResults.total &&
                searchResults.total > searchResults.data.length
              )
            }
            loadMore={() =>
              loadMoreSearchResults(searchResults.loadMore as string)
            }
          >
            {(searchResults.data as Candidate[]).map((candidate) => (
              <CandidateCardContainer
                key={candidate.id}
                candidate={candidate}
                handleCandidateCheck={() => {}}
                page="myProjects"
                handleCommentFocus={handleCommentFocus}
              />
            ))}
          </InfiniteScroll>
        </div>
      ) : searchMode ? (
        <div>No results</div>
      ) : (
        <div className={styles.mainContent}>
          <div className={styles.desktopHeader}>
            <div className={styles.projectsHeader}>
              <button
                className={classnames(styles.candidatesDesktopHeaderItem, {
                  [styles.ascending]: order === 'ASC' && sort === 'title',
                })}
                onClick={() => {
                  setOrder((prev) => (prev === 'ASC' ? 'DESC' : 'ASC'))
                  setSort('title')
                }}
              >
                {translation[mpt.project]}
                <ArrowDropDown />
              </button>
              <div className={styles.candidatesDesktopHeaderItem}>
                <button
                  onClick={() => {
                    setActiveProjects(!activeProjects)
                  }}
                >
                  <CheckBoxIcon checked={activeProjects} />
                </button>
                <span className={styles.checkboxLabel}>
                  {translation[mpt.active]}
                </span>
              </div>
              <div className={styles.candidatesDesktopHeaderItem}>
                <button onClick={() => setArchivedProjects(!archivedProjects)}>
                  <CheckBoxIcon checked={archivedProjects} />
                </button>
                <span className={styles.checkboxLabel}>
                  {translation[mpt.archived]}
                </span>
              </div>
            </div>
            <button
              className={classnames(styles.candidatesDesktopHeaderItem, {
                [styles.ascending]:
                  order === 'ASC' && sort === 'numberOfSeekers',
              })}
              onClick={() => {
                setOrder((prev) => (prev === 'ASC' ? 'DESC' : 'ASC'))
                setSort('numberOfSeekers')
              }}
            >
              {translation[mpt.candidates]}
              <ArrowDropDown />
            </button>
            <span className={styles.desktopHeaderItem}>
              {translation[mpt.open]}
            </span>
            <span className={styles.desktopHeaderItem}>
              {translation[mpt.edit]}
            </span>
            <span className={styles.desktopHeaderItem}>
              {translation[mpt.archive]}
            </span>
            <span className={styles.desktopHeaderItem}>
              {translation[mpt.delete]}
            </span>
          </div>
          <div className={styles.break} />
          <InfiniteScroll
            loading={allProjects.loadMoreProgress}
            hasMore={
              !!(
                allProjects.total && allProjects.total > allProjects.data.length
              )
            }
            loadMore={() => loadMore(allProjects.loadMore as string)}
          >
            {allProjects.loading ? (
              <div className={styles.temporary}>{translation[mpt.loading]}</div>
            ) : allProjects.error ? (
              <div className={styles.temporary}>There has been an error</div>
            ) : (
              allProjects &&
              allProjects.data.length > 0 &&
              allProjects.data.map((project) => (
                <div
                  key={`my-project-${project.id}`}
                  className={styles.optionBox}
                >
                  <div className={styles.option}>
                    <Star className={styles.icon} />
                    <div className={styles.title}>{project.title}</div>
                  </div>
                  <div className={styles.candidatesBox}>
                    <span className={styles.candidateTitle}>
                      {translation[mpt.candidates]}
                    </span>
                    <span className={styles.candidatesCount}>
                      {project.numberOfSeekers || 0}
                    </span>
                  </div>
                  <div className={styles.optionCard}>
                    <span className={styles.optionTxt}>
                      {translation[mpt.open]}
                    </span>
                    <span className={styles.optionTxt}>
                      {translation[mpt.edit]}
                    </span>
                    <span className={styles.optionTxt}>
                      {translation[mpt.archive]}
                    </span>
                    <span className={styles.optionTxt}>
                      {translation[mpt.delete]}
                    </span>
                  </div>
                  <div className={styles.iconsBox}>
                    <Link
                      to={getRoute(AppRoute.MyProjectCandidates).replace(
                        ':id',
                        project.id
                      )}
                      className={styles.optionLink}
                    >
                      <FolderOpenOutlined className={styles.optionIcon} />
                    </Link>
                    <EditOutlined
                      onClick={() => onEditProject(project.id, project.title)}
                      className={styles.optionIcon}
                    />
                    <BasicTooltip
                      text={
                        project.status === 'active' ? 'Archive' : 'Unarchive'
                      }
                      placement="right"
                      className={styles.tooltipStyle}
                    >
                      <button
                        onClick={() => {
                          setArchiveStatus({
                            id: project.id,
                            status:
                              project.status === 'active'
                                ? 'archived'
                                : 'active',
                            projectsByStatus: projectStatus,
                          })
                        }}
                        className={styles.optionButton}
                      >
                        {project.status === 'active' ? (
                          <ArchiveOutlined className={styles.optionIcon} />
                        ) : (
                          <UnarchiveOutlined className={styles.optionIcon} />
                        )}
                      </button>
                    </BasicTooltip>
                    <DeleteOutlineOutlined
                      onClick={() => {
                        setDeleteModalOpen(true)
                        setDeleteId(project.id)
                      }}
                      className={styles.optionIcon}
                    />
                  </div>
                  <div className={styles.mobileBreak} />
                </div>
              ))
            )}
          </InfiniteScroll>
          <div className={styles.break} />
        </div>
      )}
      <Modal
        isOpen={isCreateModalOpen}
        onAfterClose={() => {
          setIsCreateModalOpen(false)
          formikAdd.resetForm()
        }}
        title={mpt.addNewProject}
        icon={Extension}
        ariaHideApp={false}
        Footer={
          <SubmitFooter
            onSubmit={() => {
              formikAdd.handleSubmit()
            }}
            submitText={mpt.save}
            onCancel={() => {
              setIsCreateModalOpen(false)
              formikAdd.resetForm()
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <Title text={translation[mpt.createNewProject]} />
        <TextField
          name="name"
          placeholder={mpt.projectName}
          error={formikAdd.errors.name}
          className={styles.modalTextInput}
          value={`${formikAdd.values.name}`}
          onChange={formikAdd.handleChange}
        />
      </Modal>
      <Modal
        isOpen={isEditModalOpen}
        onAfterClose={() => {
          setIsEditModalOpen(false)
          formikEdit.resetForm()
        }}
        title={mpt.editProject}
        icon={Extension}
        ariaHideApp={false}
        Footer={
          <SubmitFooter
            onSubmit={() => {
              formikEdit.handleSubmit()
            }}
            submitText={mpt.save}
            onCancel={() => {
              setIsEditModalOpen(false)
              formikEdit.resetForm()
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <Title text={translation[mpt.editProject]} />
        <TextField
          name="name"
          placeholder={mpt.projectName}
          error={formikEdit.errors.name}
          className={styles.modalTextInput}
          value={`${formikEdit.values.name}`}
          onChange={formikEdit.handleChange}
        />
      </Modal>
      <DeleteModal
        deleteAction={onDeleteProject}
        isOpen={deleteModalOpen}
        setOpen={setDeleteModalOpen}
        text={translation[mpt.deleteProject]}
        id={deleteId}
      />
    </MainLayout>
  )
}
