export const modalsAssessmentsTranslation = {
  'modalsassessmentstranslations.psychometricTestTitle':
    'Psychometric test consent',
  'modalsassessmentstranslations.cognitiveTestTitle': 'Cognitive test consent',
  'modalsassessmentstranslations.psychometricTestTextFirstPart':
    'Soglašam z obdelavo svojih osebnih podatkov za pripravo mojega poročila psihometričnega testiranja na strani družbe Styria digital marketplaces, d.o.o., ki ga bom prejel/a v uporabniški profil na spletnem portalu',
  'modalsassessmentstranslations.psychometricTestTextSecondPart':
    'z namenom zagotavljanja prilagojenih ponudb novih zaposlitvenih možnosti. Več informacij o obdelavi vaših osebnih podatkov si preberite v',
  'modalsassessmentstranslations.cognitiveTestTextFirstPart':
    'Soglašam z obdelavo svojih osebnih podatkov, posredovanih na podlagi sledečega vprašalnika, z namenom priprave mojega poročila testiranja kognitivnih sposobnosti na spletnem portalu',
  'modalsassessmentstranslations.cognitiveTestTextSecondPart':
    'ki ga bom prejel/a v svoj uporabniški profil. Priprava poročila temelji na avtomatizirani obdelavi posredovanih podatkov, več informacij o obdelavi podatkov si preberite v',
  'modalsassessmentstranslations.privacyPolicy': 'Privacy Policy',
  'modalsassessmentstranslations.startTest': 'Start test',
  'modalsassessmentstranslations.addLanguageTest': 'Add language test',
  'modalassessments.editLanguageTest': 'Edit language test',
  'modalassessments.deleteLanguageTest':
    'Are you sure you want to delete this language test?',
  'modalsassessmentstranslations.testTakenDateValidation':
    'Test taken date is required',
  'modalsassessmentstranslations.testProviderValidation':
    'Test provider is required',
  'modalsassessmentstranslations.testNameValidation': 'Test name is required',
  'modalsassessmentstranslations.skillTestExpirationDate':
    'Skill test expiration date',
  'modalsassessmentstranslations.skillTestName': 'Skill test name',
  'modalsassessmentstranslations.skillTestProvider': 'Skill test provider name',
  'modalsassessmentstranslations.skillTestTakenDate': 'Skill test taken date',
  'modalsassessmentstranslations.languageTestLevel': 'Language test level',
  'modalsassessmentstranslations.languageTestTakenDate':
    'Language test taken date',
  'modalsassessmentstranslations.languageTestProvider':
    'Language test provider name',
  'modalsassessmentstranslations.languageTestExpirationDate':
    'Language test expiration date',
  'modalsassessmentstranslations.languageTestName': 'Language test name',
  'modalsassessmentstranslations.addSkillTest': 'Add skill test',
  'modalassessments.editSkillTest': 'Edit skill test',
  'modalassessments.deleteSkillTest':
    'Are you sure you want to delete this skill test?',
}
