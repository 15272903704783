import {useIntl} from 'react-intl'
import {DateTranslations as dt} from 'Services/I18n/Constants/General/Date'

export const useGetMonth = (): string[] => {
  const {formatMessage} = useIntl()
  const Months = [
    '0',
    formatMessage({id: dt.january}),
    formatMessage({id: dt.february}),
    formatMessage({id: dt.march}),
    formatMessage({id: dt.april}),
    formatMessage({id: dt.may}),
    formatMessage({id: dt.june}),
    formatMessage({id: dt.july}),
    formatMessage({id: dt.august}),
    formatMessage({id: dt.september}),
    formatMessage({id: dt.october}),
    formatMessage({id: dt.november}),
    formatMessage({id: dt.december}),
  ]

  return Months
}
