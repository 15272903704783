export enum EmployerOnboardingTranslations {
  statementTitlePartOne = 'employeronboarding.statementTitlePartOne',
  statementTxtPartOne = 'employeronboarding.statementTxtPartOne',
  statementTxtPartTwo = 'employeronboarding.statementTxtPartTwo',
  statementBoldTxtPartOne = 'employeronboarding.statementBoldTxtPartOne',
  statementAddress = 'employeronboarding.statementAddress',
  statementAddressInfo = 'employeronboarding.statementAddressInfo',
  statementAddressInfoLast = 'employeronboarding.statementAddressInfoLast',
  statementTitle = 'employeronboarding.statementTitle',
  statementTitleTxt = 'employeronboarding.statementTitleTxt',
  statementBoldTxtPartTwo = 'employeronboarding.statementBoldTxtPartTwo',
  statementBoldNumber = 'employeronboarding.statementBoldNumber',
  statementBoldNumberTxtPartOne = 'employeronboarding.statementBoldNumberTxtPartOne',
  statementBoldNumberSecond = 'employeronboarding.statementBoldNumberSecond',
  statementBoldNumberSecondTxt = 'employeronboarding.statementBoldNumberSecondTxt',
  statementBoldNumberThird = 'employeronboarding.statementBoldNumberThird',
  statementBoldNumberThirdTxt = 'employeronboarding.statementBoldNumberThirdTxt',
  statementBoldTxtPartThree = 'employeronboarding.statementBoldTxtPartThree',
  statementBoldNumberFourth = 'employeronboarding.statementBoldNumberFourht',
  statementBoldNumberFourthTxt = 'employeronboarding.statementBoldNumberFourhtTxt',
  statementBoldNumberFifth = 'employeronboarding.statementBoldNumberFifth',
  statementBoldNumberFifthTxt = 'employeronboarding.statementBoldNumberFifthTxt',
  companyContactInfoDescription = 'employeronboarding.companyContactInfoDescription',
  twelveMonthHiringPlanDescription = 'employeronboarding.twelveMonthHiringPlanDescription',
  statementDescription = 'employeronboarding.statementDescription',
  step1Title = 'employeronboarding.step1Title',
  step2Title = 'employeronboarding.step2Title',
  step3Title = 'employeronboarding.step3Title',
  step1SidebarTitle = 'employeronboarding.step1SidebarTitle',
  step2SidebarTitle = 'employeronboarding.step2SidebarTitle',
  step3SidebarTitle = 'employeronboarding.step3SidebarTitle',
  titleEmploymentPlan = 'employeronboarding.titleEmploymentPlan',
  titleSalaryRange = 'employeronboarding.titleSalaryRange',
  titleOccupationalFieldsCandidates = 'employeronboarding.titleOccupationalFieldsCandidates',
  titleRegions = 'employeronboarding.titleRegions',
  titleYearsOfExp = 'employeronboarding.titleYearsOfExp',
  titleEducation = 'employeronboarding.titleEducation',
  typeaheadCandidatesPos = 'employeronboarding.typeaheadCandidatesPos',
  typeaheadCandidatesPosPlaceholder = 'employeronboarding.typeaheadCandidatesPosPlaceholder',
  positionYearsOfExp = 'employeronboarding.positionYearsOfExp',
  positionSalaryRange = 'employeronboarding.positionSalaryRange',
  typeaheadDegree = 'employeronboarding.typeaheadDegree',
  typeaheadFieldOfStudy = 'employeronboarding.typeaheadFieldOfStudy',
  companyLongName = 'employeronboarding.companyLongName',
  contactPersonTelephoneNumber = 'employeronboarding.contactPersonTelephoneNumber',
  contactPersonPosition = 'employeronboarding.contactPersonPosition',
  address = 'employeronboarding.address',
  postalCode = 'employeronboarding.postalCode',
  city = 'employeronboarding.city',
  country = 'employeronboarding.country',
  selectValue = 'employeronboarding.selectValue',
  phoneNumber = 'employeronboarding.phoneNumber',
  selectYourCountry = 'employeronboarding.selectYourCountry',
}
