export const sidebar = {
  'sidebar.home': 'Home',
  'sidebar.dashboard': 'Dashboard',
  'sidebar.allRequests': 'All requests',
  'sidebar.myProfile': 'My profile',
  'sidebar.publicProfile': 'Public profile',
  'sidebar.myCv': 'My CV',
  'sidebar.assessments': 'Assessments',
  'sidebar.calendar': 'Calendar',
  'sidebar.messageCenter': 'Message Center',
  'sidebar.careerAdvice': 'Career Advice',
  'sidebar.companies': 'Companies',
  'sidebar.faq': 'FAQ',
  'sidebar.logout': 'Logout',
  'sidebar.contactRequests': 'Contact requests',
  'sidebar.videoInterviews': 'Interview invitations',
  'sidebar.offers': 'Offers',
  'sidebar.placements': 'Placements',
  'sidebar.candidates': 'Candidates',
  'sidebar.search': 'Search',
  'sidebar.candidateSearch': 'Candidate search',
  'sidebar.projects': 'Projects',
  'sidebar.availableImmediately': 'Available immediately',
  'sidebar.cvSubscriptions': 'CV Subscriptions',
  'sidebar.companyProfile': 'Company profile',
  'sidebar.advice': 'Advice',
  'sidebar.editPublicProfile': 'Edit public profile',
  'sidebar.continueToTests': 'Continue to Tests',
  'sidebar.editCv': 'Edit CV',
  'sidebar.profileStatus': 'Profile status',
  'sidebar.waitingForApproval': 'Waiting for approval',
  'sidebar.waitingForApprovalMessage':
    'Thank you for registering your account. Your account need approval by our HR specialist. We will send you confirmation link to your email in 1-2 business days.',
  'sidebar.privacyControl': 'Privacy control',
  'sidebar.privacyControlMessage':
    'Fields marked with the lock icon are only visible to companies you applied to, or whose contact request you accepted.',
  'sidebar.careerProfile': 'My Career Profile',
  'sidebar.careerProfileMessage':
    'Employers on Preferenca see your (anonymous) Career profile on the basis of which they can send you contact request. If you approve their contact request, you give them access to your CV.',
  'sidebar.candidateProfile': 'Candidate profile',
  'sidebar.privacyPolicy': 'Privacy policy',
  'sidebar.contactData': 'Contact data',
  'sidebar.editPersonalData': 'Edit your personal data',
  'sidebar.changeEmailAddress': 'Change email address',
  'sidebar.changePassword': 'Change Password',
  'sidebar.confirmHiringChoice':
    'You have accepted offers from the following candidates. Please confirm your hiring choices.',
  'sidebar.helpfulHint': 'Helpful Hint',
  'sidebar.myOffers': 'My offers',
  'sidebar.selectCandidate':
    'If you want to see more options, select a candidate.',
  'sidebar.rate': 'Rate:',
  'sidebar.viewCandidateProfile': 'View candidate profile',
  'sidebar.viewCandidatesAssessments': "View Candidate's Assessments",
  'sidebar.sendMessage': 'Send message',
  'sidebar.linkInterviewInvitation': 'Invite to interview',
  'sidebar.sendOffer': 'Send offer',
}
