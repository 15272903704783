import {connect} from 'react-redux'

import {TrainingTitles} from './TrainingTitles'

import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  trainingTitles: state.seeker.data?.full?.seekerTrainingTitles,
})

export const TrainingTitlesContainer = connect(
  mapStateToProps,
  null
)(TrainingTitles)
