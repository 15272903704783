import {connect} from 'react-redux'

import {Logout} from './Logout'
import {AppDispatch} from 'Store'
import {logoutUser} from 'Store/Actions'
import {RootState} from 'Store/Reducers'
import {UserType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  role: state.auth.data?.type || UserType.SEEKER,
  socketClient: state?.messageCenter?.socket?.client,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  logoutUser: (role?: UserType) => dispatch(logoutUser(role)),
})

export const LogoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout)
