import {FC, useEffect} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {AppRoute} from 'Services/I18n/Constants'
import {getRoute} from 'Services/I18n/Utils'

export const ImpresonateCandidate: FC = () => {
  const {userId, impersonatedUserId}: any = useParams()
  const history = useHistory()

  useEffect(() => {
    console.log('Impresonating', impersonatedUserId)
    localStorage.setItem('userId', userId)
    localStorage.setItem('impersonatedUserId', impersonatedUserId)
    history.push(getRoute(AppRoute.Profile))
  }, [])

  return <>Loading...</>
}