import {FC, useEffect, useState} from 'react'
import Upload from 'rc-upload'
import styles from './FileUpload.module.scss'
import classNames from 'classnames'
import {getChannel} from 'Utils/CommonHelpers'
import {getRouteLanguageId} from 'Services/I18n/Utils'

interface FileUploadProps {
  name: string
  endpoint: string
  className?: string
  onStart: (data: any) => void
  onSuccess: (data: any) => void
  onError: (data: any, body: any) => void
  onProgress?: (event: {percent: number}) => void
  dropable?: boolean
  children: any
  theme?: string
  accept?: string
}

export const FileUpload: FC<FileUploadProps> = ({
  name,
  endpoint,
  className,
  onStart,
  onSuccess,
  onError,
  onProgress,
  dropable,
  theme = 'default',
  children,
  accept = '*',
}: FileUploadProps) => {
  const [dropzone, setDropzone] = useState(false)

  const dragenter = () => {
    setDropzone(true)
  }

  const dragleave = () => {
    setDropzone(false)
  }

  const bindDropRelatedEvents = () => {
    const ref = document.getElementsByClassName('fileUploadRef')[0]
    if (ref) {
      ref.addEventListener('dragenter', dragenter, false)
      ref.addEventListener('dragover', dragenter, false)
      ref.addEventListener('dragleave', dragleave, false)
      ref.addEventListener('drop', dragleave, false)
    }
  }
  const unbindDropRelatedEvents = () => {
    const ref = document.getElementsByClassName('fileUploadRef')[0]
    if (ref) {
      ref.removeEventListener('dragenter', dragenter)
      ref.removeEventListener('dragover', dragenter)
      ref.removeEventListener('dragleave', dragleave)
      ref.removeEventListener('drop', dragleave)
    }
  }

  useEffect(() => {
    if (dropable) bindDropRelatedEvents()

    return () => {
      if (dropable) unbindDropRelatedEvents()
    }
  })

  let languageId = getRouteLanguageId() || getChannel().defaultLanguage?.id
  const userId = localStorage.getItem('userId') || null

  const props: any = {
    name: name,
    action: process.env.REACT_APP_API_BASE_URL + endpoint,
    method: 'POST',
    headers: {
      tenantId: getChannel().tenantId as string,
      channelId: getChannel().channelId as string,
      languageId,
      userId,
    },
    withCredentials: true,
    onStart(file: any) {
      onStart(file)
    },
    onSuccess(ret: any) {
      onSuccess(ret)
    },
    onError(err: any, body: any) {
      onError(err, body)
    },
    onProgress(event: {percent: number}) {
      onProgress && onProgress(event)
    },
  }

  if (dropable) {
    props.type = 'drop'
  }

  return (
    <div
      className={classNames(styles.fileUpload, 'fileUploadRef', className, {
        [styles.dropzone]: dropzone === true,
        [styles.whiteTheme]: theme === 'white',
        [styles.profileImageTheme]: theme === 'profileImage',
      })}
    >
      <Upload accept={accept} {...props}>
        {children}
      </Upload>
    </div>
  )
}
