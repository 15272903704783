export enum MyAccountTranslations {
  myAccount = 'myAccount.myAccount',
  personalData = 'myAccount.personalData',
  uploadPhoto = 'myAccount.uploadPhoto',
  day = 'myAccount.day',
  month = 'myAccount.month',
  year = 'myAccount.year',
  birthdate = 'myAccount.birthdate',
  streetAddress = 'myAccount.streetAddress',
  postalCode = 'myAccount.postalCode',
  region = 'myAccount.region',
  contactData = 'myAccount.contactData',
}
