import {FC} from 'react'

import {AuthStateType, ConversationType} from 'Interfaces'
import {
  CheckBoxOutlineBlank,
  CheckBoxTwoTone,
  Unarchive,
} from '@mui/icons-material'
import classnames from 'classnames'
import {dateOrTime} from 'Utils/Date/DateOrTime'
import {MessageCenterTranslations as mct} from 'Services/I18n/Constants/MessageCenter'
import {useTranslation} from 'Hooks'

import styles from './ConversationListItem.module.scss'

export interface CheckBoxIconProps {
  checked: boolean
  onSelect: () => void
}

const CheckBox: FC<CheckBoxIconProps> = ({checked, onSelect}) => {
  return checked ? (
    <CheckBoxTwoTone
      onClick={onSelect}
      className={classnames(styles.checkbox, styles.checked)}
    />
  ) : (
    <CheckBoxOutlineBlank onClick={onSelect} className={styles.checkbox} />
  )
}

type ConversationListItemProps = {
  conversation: ConversationType
  auth: AuthStateType
  onClick: () => void
  onSelect: () => void
  selected: boolean
  opened: boolean
  manage: boolean
}

export const ConversationListItem: FC<ConversationListItemProps> = ({
  conversation,
  auth,
  onClick,
  onSelect,
  selected,
  opened,
  manage,
}) => {
  const translation = useTranslation()

  // Find currently logged user from conversation
  const currentUserId = auth.data?.id
  const currentUserMessageConversationUser =
    conversation.conversationUsers?.find(
      (conversationUser) => conversationUser.userId === currentUserId
    )
  // Assumes there is only 2 users in chat
  const otherUserMessageConversationUser =
    conversation?.conversationUsers?.find(
      (conversationUser) => conversationUser.userId !== currentUserId
    )

  const lastSenderDisplayName =
    conversation.message.senderUserId === currentUserId
      ? translation[mct.you]
      : conversation.message.senderFirstName || null

  const date = dateOrTime(conversation.message?.dateSent)

  return (
    <div
      onClick={onClick}
      className={classnames(styles.wrap, {[styles.opened]: opened})}
    >
      <div className={styles.conversation}>
        <div className={styles.avatar}>
          {manage ? (
            <CheckBox onSelect={onSelect} checked={selected} />
          ) : otherUserMessageConversationUser?.profilePicture?.url ? (
            <img src={otherUserMessageConversationUser?.profilePicture?.url} />
          ) : (
            <div className={styles.avatarLetters}>
              <p>
                {otherUserMessageConversationUser?.firstName?.[0]?.toUpperCase()}
                {otherUserMessageConversationUser?.lastName?.[0]?.toUpperCase()}
              </p>
            </div>
          )}
        </div>
        <div className={styles.content}>
          <div className={styles.senderWrap}>
            <span className={styles.senderName}>
              {otherUserMessageConversationUser?.firstName &&
              otherUserMessageConversationUser?.lastName
                ? otherUserMessageConversationUser?.firstName +
                  ' ' +
                  otherUserMessageConversationUser?.lastName
                : null}
            </span>
            {currentUserMessageConversationUser?.conversationLifeStatus !==
            'archived' ? (
              <span className={styles.createdAt}>{date}</span>
            ) : (
              <div className={styles.unarchiveIconContainer}>
                <Unarchive />
              </div>
            )}
          </div>
          <div
            className={classnames(
              styles.messageWrap,
              currentUserMessageConversationUser?.conversationReadStatus ===
                'unread' && styles.messageBold // Todo: Recheck when read status is implemented/used (looking at you own msg is marked unread atm..)
            )}
          >
            {lastSenderDisplayName ? (
              <span className={styles.lastSender}>
                {lastSenderDisplayName}:
              </span>
            ) : null}
            <span className={styles.ellipsisStyle}>
              {conversation.message.body}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}
