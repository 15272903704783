export enum DateTranslations {
  selectDay = 'date.selectDay',
  selectMonth = 'date.selectMonth',
  selectYear = 'date.selectYear',
  startMonth = 'date.startMonth',
  endMonth = 'date.endMonth',
  startYear = 'date.startYear',
  endYear = 'date.endYear',
  yearOfCompletion = 'date.yearOfCompletion',
  projectEndYear = 'date.projectEndYear',
  january = 'date.january',
  february = 'date.february',
  march = 'date.march',
  april = 'date.april',
  may = 'date.may',
  june = 'date.june',
  july = 'date.july',
  august = 'date.august',
  september = 'date.september',
  october = 'date.october',
  november = 'date.november',
  december = 'date.december',
}
