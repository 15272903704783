import {FC} from 'react'
import {TrainingTitleType} from 'Interfaces'
import styles from './TrainingTitles.module.scss'
import {ProfileTranslations as pt} from 'Services/I18n/Constants'
import ItemHeader from 'Components/Profile/ItemHeader'
import SectionItem from 'Components/Profile//SectionItem'
import {AutoStories} from '@mui/icons-material'
import defaultCertIcon from 'Assets/Images/default-cert-icon.png'

interface TrainingTitlesProps {
  trainingTitles?: TrainingTitleType[] | null
}
export const TrainingTitles: FC<TrainingTitlesProps> = ({trainingTitles}) => {
  return trainingTitles && trainingTitles.length > 0 ? (
    <SectionItem className={styles.sectionItem}>
      <ItemHeader title={pt.trainingTitlesTitle || ''} icon={AutoStories} />
      <div className={styles.trainingTitlesContainer}>
        {trainingTitles.map((test) => (
          <div className={styles.trainingTitle} key={test.id}>
            <img
              className={styles.trainingTitleIcon}
              src={test.trainingTitle.icon?.url || defaultCertIcon}
              alt={test.trainingTitle.translation}
            />
            <div className={styles.trainingTitleName}>
              {test.trainingTitle.translation}
            </div>
            <div className={styles.trainingTitleDate}>
              {new Date(test.dateOfTest).toLocaleDateString('sl-SI')}
            </div>
          </div>
        ))}
      </div>
    </SectionItem>
  ) : null
}
