export const companyHeader = {
  'companyHeader.logotypeModalTitle': 'Dodajte logotip',
  'companyHeader.logotypeTitle': 'LOGOTIP',

  'companyHeader.coverImageModalTitle': 'Dodajte naslovno fotografijo',
  'companyHeader.coverImageTitle': 'NASLOVNA FOTOGRAFIJA',

  'companyHeader.headerModalTitle': 'Uredite glavo profila',

  'companyHeader.headerCompanyNameTitle': 'IME PODJETJA',
  'companyHeader.headerCompanyNamePlaceholderText': 'Ime podjetja ...',
  'companyHeader.headerDescriptionTitle': 'OPIS',
  'companyHeader.headerDescriptionPlaceholderText':
    'Naše podjetje je vodilni ponudnik tehnoloških rešitev za podjetja vseh velikosti …',
  'companyHeader.headerJobCategoryTitle': 'DEJAVNOST',
  'companyHeader.headerCountryTitle': 'LOKACIJA',
  'companyHeader.headerWebsiteTitle': 'SPLETNA STRAN',
  'companyHeader.headerWebsitePlaceholderText': 'spletna stran...',

  'companyHeader.townInputPlaceholder': 'Vnesite mesto ...',
  'companyHeader.countryLabel': 'Država',
  'companyHeader.cityLabel': 'Mesto',
}
