import {LongDescription} from 'Validations'
import * as Yup from 'yup'

export const Validation = Yup.object({
  desiredRegions: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        translation: Yup.string(),
      })
    )
    .min(1, 'Desired regions are required'),
  desiredOccupations: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        translation: Yup.string(),
      })
    )
    .min(1, 'Desired job position is required'),
  desiredSalary: Yup.object().nullable().required('Desired salary is required'),
  availability: Yup.object().nullable().required('Availability is required'),
  workFromHome: Yup.object()
    .nullable()
    .required('Work from home is required field.'),
  desiredWorkEnv: LongDescription.required(
    'Desired work environment description is required'
  ),
  additionalBenefits: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        translation: Yup.string(),
      })
    )
    .min(1, 'Additional benefits field is required'),
})
