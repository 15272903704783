import {FC, useState} from 'react'

import {
  AddComment,
  Cancel,
  Close,
  Inventory2Outlined,
  MailOutline,
  MoreHoriz,
} from '@mui/icons-material'
import {
  AuthStateType,
  ConversationType,
  EmployerCandidateProfileStateType,
  MESSAGE_TYPE,
  OpenedConversationType,
} from 'Interfaces'
import _ from 'lodash'
import {useTranslation} from 'Hooks'
import {MessageCenterTranslations as mct} from 'Services/I18n/Constants/MessageCenter'
import {FormattedMessage} from 'react-intl'
import {ConversationListItem} from './ConversationListItem'
import PopoverList from 'Components/PopoverList'
import InfiniteScroll from 'Components/InfiniteScroll'

import styles from './MessageCenterConversations.module.scss'
import {BasicTooltip} from 'Components/BasicTooltip'

type MessageCenterConversationsProps = {
  searchConversationStatus: MESSAGE_TYPE | undefined
  conversations: ConversationType[]
  openedConversation?: OpenedConversationType
  selectConversation: (conversation?: ConversationType) => void
  resetSearch: () => void
  loadMoreConversationsProgress: boolean
  loadMoreConversationsUrl: string | undefined
  loadMoreConversations: (url: string) => void
  archiveConversations: (ids: string[]) => void
  setShowRecipientHeader: Function
  auth: AuthStateType
  readConversation: (data: string[]) => void
  setModalOpen: Function
  unreadConversation: (data: string[]) => void
  getCandidateProfile: (id: string) => void
  setSidebarClosed: (sidebarClosed: boolean) => void
  candidate: EmployerCandidateProfileStateType
}

export const MessageCenterConversations: FC<
  MessageCenterConversationsProps
> = ({
  searchConversationStatus,
  conversations,
  openedConversation,
  selectConversation,
  resetSearch,
  loadMoreConversationsProgress,
  loadMoreConversationsUrl,
  loadMoreConversations,
  archiveConversations,
  setShowRecipientHeader,
  auth,
  setModalOpen,
  readConversation,
  unreadConversation,
  getCandidateProfile,
  setSidebarClosed,
}) => {
  const [manageConversations, setManageConversations] = useState(false)
  const [selected, setSelected] = useState<ConversationType[]>([])
  const translation = useTranslation()

  const onLoadMore = () => {
    if (loadMoreConversationsUrl) {
      loadMoreConversations(loadMoreConversationsUrl)
    }
  }

  const selectAll = () => {
    if (selected.length > 0) {
      setSelected([])
    } else if (selected.length !== conversations.length) {
      setSelected(_.cloneDeep(conversations))
    }
  }

  const markSelectedAsUnread = () => {
    unreadConversation(selected.map((c) => c.id))
  }

  const archiveSelected = () => {
    archiveConversations(selected.map((c) => c.id))
  }

  const onItemClick = (conversation: ConversationType) => {
    let candidateId = conversation.conversationUsers.find(
      (u) => u.userId !== auth.data?.id
    )?.userId
    if (candidateId) {
      getCandidateProfile(candidateId)
      setSidebarClosed(false)
      if (!manageConversations) {
        readConversation([conversation.id])
        selectConversation(conversation)
      }
    }
  }

  const onConversationSelect = (conversation: ConversationType) => {
    let selectedItems = _.cloneDeep(selected)
    const idx = selectedItems.findIndex((s) => s.id === conversation.id)
    if (idx >= 0) {
      _.remove(selectedItems, (_s, indx) => indx === idx)
    } else {
      selectedItems = [...selectedItems, conversation]
    }
    setSelected(selectedItems)
  }

  const actions = [
    {
      text: translation[mct.manageConversations],
      onClick: () => setManageConversations((old) => !old),
    },
  ]

  const displayHeaderCloseBtn =
    searchConversationStatus === MESSAGE_TYPE.ARCHIVED ||
    searchConversationStatus === MESSAGE_TYPE.UNREAD
  return (
    <div className={styles.conversationsWrap}>
      <div className={styles.headerWrap}>
        <div className={styles.header}>
          <span className={styles.heading}>
            {
              <FormattedMessage
                id={
                  mct[
                    `heading_${
                      searchConversationStatus || MESSAGE_TYPE.ALL_MESSAGES
                    }`
                  ]
                }
              />
            }
          </span>
          {displayHeaderCloseBtn ? (
            <button
              onClick={() => resetSearch()}
              className={styles.resetFilterButton}
            >
              <Close />
            </button>
          ) : (
            <>
              <PopoverList
                triggerIcon={
                  <BasicTooltip
                    text={translation[mct.manageConversations]}
                    placement="top"
                  >
                    <MoreHoriz className={styles.moreIconBtn} />
                  </BasicTooltip>
                }
                items={actions}
              />
              <BasicTooltip text={translation[mct.newMessage]} placement="top">
                <button
                  className={styles.newMessageBtn}
                  onClick={() => {
                    setShowRecipientHeader(false)
                    selectConversation()
                    setModalOpen(true)
                  }}
                >
                  <AddComment />
                </button>
              </BasicTooltip>
            </>
          )}
        </div>
      </div>
      {manageConversations && (
        <div className={styles.subHeaderWrap}>
          <div className={styles.subHeader}>
            <span className={styles.heading}>
              <BasicTooltip text={translation[mct.closeManaging]}>
                <button
                  className={styles.cancelManageBtn}
                  onClick={() => {
                    setSelected([])
                    setManageConversations((old) => !old)
                  }}
                >
                  <Cancel />
                </button>
              </BasicTooltip>
              <span>{`${selected.length} `}</span>
              <span className={styles.selectedLabel}>
                <FormattedMessage id={mct.selected} />
              </span>
            </span>
            <button onClick={() => selectAll()} className={styles.selectAllBtn}>
              <FormattedMessage
                id={selected.length > 0 ? mct.clearAll : mct.selectAll}
              />
            </button>
            <BasicTooltip text={translation[mct.markAsUnread]}>
              <button
                onClick={markSelectedAsUnread}
                className={styles.selectedAction}
              >
                <MailOutline />
              </button>
            </BasicTooltip>
            <BasicTooltip text={translation[mct.archive]}>
              <button
                onClick={archiveSelected}
                className={styles.selectedAction}
              >
                <Inventory2Outlined />
              </button>
            </BasicTooltip>
          </div>
        </div>
      )}
      <div className={styles.listItemsWrap}>
        <InfiniteScroll
          loading={loadMoreConversationsProgress}
          hasMore={!!loadMoreConversationsUrl}
          loadMore={onLoadMore}
        >
          {conversations.map((conversation, index) => {
            const currentUserId = auth.data?.id
            const currentUserMessageConversationUser =
              conversation.conversationUsers?.find(
                (conversationUser) => conversationUser.userId === currentUserId
              )
            return (
              currentUserMessageConversationUser?.conversationLifeStatus !==
                'archived' && (
                <ConversationListItem
                  key={index}
                  conversation={conversation}
                  auth={auth}
                  manage={manageConversations}
                  opened={
                    openedConversation?.conversation
                      ? openedConversation?.conversation?.id ===
                        conversation?.id
                      : false
                  }
                  selected={selected.some((s) => s.id === conversation.id)}
                  onClick={() => onItemClick(conversation)}
                  onSelect={() => onConversationSelect(conversation)}
                />
              )
            )
          })}
        </InfiniteScroll>
      </div>
    </div>
  )
}
