import {connect} from 'react-redux'

import {EmployerContactRequests} from './EmployerContactRequests'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {
  getEmployerContactRequests,
  getSeekersFromContactRequests,
  loadMoreEmployerContactRequests,
} from 'Store/Actions'
import {EmployerRequestsSearchParamsType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  candidateBasicData: state.widgetBar.data.candidateBasicData,
  requests: state.employer.data.allRequests.contactRequests,
  totalContactRequests: state.employer.data.allRequests.contactRequests.total,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getEmployerContactRequests: (params?: EmployerRequestsSearchParamsType) =>
    dispatch(getEmployerContactRequests(params)),
  getSeekersFromContactRequests: (activeTab?: string) =>
    dispatch(getSeekersFromContactRequests(activeTab)),
  loadMore: (url: string) => dispatch(loadMoreEmployerContactRequests(url)),
})

export const EmployerContactRequestsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerContactRequests)
