import {LongDescription} from 'Validations'
import * as Yup from 'yup'

const Validation = Yup.object({
  publicOccupation: Yup.object()
    .nullable()
    .required('Job title is required field'),
  jobSearchStatusId: Yup.string().required('Field required'),
  shortDescription: LongDescription.optional(),
})

export default Validation
