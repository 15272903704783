import {useIntl} from 'react-intl'
import {DateTranslations as dt} from 'Services/I18n/Constants/General/Date'

export const useMonthTranslation = (month: any): any => {
  const {formatMessage} = useIntl()

  switch (month) {
    case 0:
      return formatMessage({id: dt.january})
    case 1:
      return formatMessage({id: dt.february})
    case 2:
      return formatMessage({id: dt.march})
    case 3:
      return formatMessage({id: dt.april})
    case 4:
      return formatMessage({id: dt.may})
    case 5:
      return formatMessage({id: dt.june})
    case 6:
      return formatMessage({id: dt.july})
    case 7:
      return formatMessage({id: dt.august})
    case 8:
      return formatMessage({id: dt.september})
    case 9:
      return formatMessage({id: dt.october})
    case 10:
      return formatMessage({id: dt.november})
    case 11:
      return formatMessage({id: dt.december})
  }
}
