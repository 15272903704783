import {FC} from 'react'

import CloseIcon from '@mui/icons-material/Close'
import {Button} from 'Components/Button'

import styles from './FullStatementBox.module.scss'

interface FullStatementBoxProps {
  setFullStatementBoxOpen: (fullStatementBoxOpen: boolean) => void
  title: string
  statement: string
}

export const FullStatementBox: FC<FullStatementBoxProps> = ({
  setFullStatementBoxOpen,
  title,
  statement,
}) => {
  return (
    <div className={styles.fullStatementBox}>
      <Button
        theme="buttonWithIcon"
        icon={<CloseIcon className={styles.chartIcon} />}
        className={styles.closeButton}
        onClick={() => setFullStatementBoxOpen(false)}
      />
      <div className={styles.textContainer}>
        <h3>{title}</h3>
        <p>{statement}</p>
      </div>
    </div>
  )
}
