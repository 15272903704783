import {connect} from 'react-redux'

import {SeekerLogin} from './SeekerLogin'
import {LoginType} from 'Interfaces'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {loginSeeker} from 'Store/Actions/AuthActions'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  login: (data: LoginType) => dispatch(loginSeeker(data)),
})

export const SeekerLoginContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SeekerLogin)
