import routes from './Routes/sl'
import {seeker} from './Seeker/sl'
import {publicPages} from './PublicPages/sl'
import {settings} from './Settings/sl'
import validation from './Validation/sl'
import {general} from './General/sl'
import {layout} from './Layout/sl'
import {employer} from './Employer/sl'
import {assessments} from './Assessments/sl'
import messageCenter from './MessageCenter/sl'
import {company} from './Company/sl'
import {unsubscribe} from './Unsubscribe/sl'
import {emailverification} from './EmailVerification/sl'
import {emailchangeverification} from './EmailChangeVerification/sl'
import {aboutUs} from './AboutUs/sl'

export const sl = {
  ...routes,
  ...seeker,
  ...settings,
  ...validation,
  ...publicPages,
  ...general,
  ...layout,
  ...employer,
  ...assessments,
  ...messageCenter,
  ...company,
  ...unsubscribe,
  ...emailverification,
  ...emailchangeverification,
  ...aboutUs,
  emailAddress: 'Elektronski naslov',
  password: 'Geslo',
  next: 'Naprej',
  back: 'Nazaj',
  skills: 'Skills',
  saveChanges: 'Shrani spremembe',
  cancel: 'Prekličite',
  preview: 'Predogled',
  add: 'Dodajte',
  edit: 'Urejanje',
  employer: 'Delodajalec',
  address: 'Naslov',
  'Postal code': 'Poštna številka',
  City: 'Mesto',
  Country: 'Država',
  'Select your country': 'Izberite svojo državo',
  'Phone number': 'Telefonska številka',
  yes: 'Da',
  no: 'Ne',
  'Save changes': 'Shrani spremembe',
  Cancel: 'Prekličite',
  delete: 'Izbriši',
  warning: 'Opozorilo!',
  undefinedInput: ' ',
  'Show fewer': 'Prikaži manj',
  'Show more': 'Prikaži več',
  'Preferenca.com': 'Preferenca.si',
  'Preferenca.si': 'Preferenca.si',
  'Select from list': 'Izberite s seznama',
  'Select value': 'Izberite vrednost',
  'No entry found': 'Noben zapis ne ustreza iskanju',
  Other: 'Ostalo',
  /** Page content */
  'home.title': 'Početna',
  'home.content': 'Početna',
  'First name': 'Ime',
  'Last name': 'Priimek',

  'assessments.title': 'Procene',
  'assessmentssuccess.title': 'Procene uspeha',

  'publicHome.title': 'Početna',
  'homePage.title': 'Početna strana',
  'homePageEmployer.title': 'Zaposleni',
  'homePageCandidate.title': 'Kandidat',
  'modal.modal': 'Modal',

  'summary.title': 'Strana',
  'summary.content': 'Strana tekst',

  'error.title': 'Greska',
  'error.content': 'Greska',

  'alerts.title': 'Alert',
  'lazy.title': 'Lenjo',

  'seekerlogin.title': 'Ulaz',
  'employerlogin.title': 'Ulaz',

  'formikexample.title': 'Formik Primer',

  'onboarding.title': 'Onboarding',

  'uploadtest.title': 'Upload Test',

  'forgotpassword.title': 'Zaboravio Sifru',

  'resetpassword.title': 'Promeni Sifru',

  'jobpreferences.title': 'Pogodnosti Posla',

  'consents.title': 'Moja Soglasja',

  'companyprofile.title': 'soglasje',
  'termsndconditions.title': 'Določila In Pogoje',

  'workexperience.title': 'Radno Iskustvo',
  'profile.title': 'Profil',
  'publicprofile.title': 'Profil',

  'homepagecandidate.title': 'Home Page Candidate',

  'frequentlyaskedquestions.title': 'Frequently Asked Questions',

  /** Component content */
  'checkbox-error-img.alt': 'ErrorIcon-sl',

  /** Links */
  'links.medium':
    'https://medium.com/prototyped/multi-language-routing-in-react-d7eb7a0688e9',
  'links.dev.to': 'https://dev.to/prototyp/multi-language-routing-in-react-k9l',

  'dragndrop.title': 'dragndrop example',
  'gallery.title': 'gallery example',
  'employerdashboard.title': 'Employer Dashboard',

  'languagesettings.title': 'Nastavitve jezika',
  'logout.title': 'Odjava',
  'calendar.title': 'Kalendar',
  'employercontactrequests.title': 'Employer Contact Requests',
  'employerinterviewinvitations.title': 'Employer Interview Invitations',
  'employeroffers.title': 'Employer Offers',
  'employerplacements.title': 'Employer Placements',
  'reviewcandidate.title': 'Review Candidate',
  Monday: 'Ponedeljek',
  Tuesday: 'Torek',
  Wednesday: 'Sreda',
  Thursday: 'Četrtek',
  Friday: 'Petek',
  Saturday: 'Sobota',
  Sunday: 'Nedelja',
  Daily: 'Dnevno',
  Monthly: 'Mesečno',
  Disabled: 'Onemogočeno',
  monday: 'Ponedeljek',
  tuesday: 'Torek',
  wednesday: 'Sreda',
  thursday: 'Četrtek',
  friday: 'Petek',
  saturday: 'Sobota',
  sunday: 'Nedelja',
  daily: 'Dnevno',
  monthly: 'Mesečno',
  disabled: 'Onemogočeno',
  or: 'ali',
  'Log out warning': 'Log out warning',
  'You will be logged out in one minute. If you want to stay logged in, please move the mouse or click anywhere on the page.':
    'Čez eno minuto boste odjavljeni iz sistema. Če tega ne želite, premaknite miško ali kliknite kamorkoli na strani.',
  ' years old': ' let',
  'You can find this candidate in following projects:':
    'You can find this candidate in following projects:',
  Occupation: 'Delovno mesto',
  'Job category': 'Področje dela',
  Employer: 'Delodajalec',
  Skill: 'Znanje',
  town: 'mesto',
  region: 'regija',
  country: 'država',
  'Preferenca is currently down for maintenance.':
    'Preferenca je trenutno nedostopna zaradi vzdrževanja.',
  'We expect to be back shortly. Thank you for your patience.':
    'Pričakujemo, da se kmalu vrnemo. Hvala za vaše potrpljenje.',
  'Refresh page and check again': 'Osvežite stran in preverite znova',
  Keyword: 'Ključna beseda',
  'Company user login forbidden, company owner action needed':
    'Company user login forbidden, company owner action needed',
  'Wrong identifier or password':'Napačen elektronski naslov ali geslo.',
  'Company is inactive': 'To podjetje je neaktivno'
}
