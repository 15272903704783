import {FC, useState} from 'react'
import styles from './RequestContact.module.scss'
import progress from 'Assets/Images/contact-request-proggres.gif'
import {Button} from 'Components/Button'
import classNames from 'classnames'
import {IncomingRequestType, SendRequestContactType} from 'Interfaces'
import {InterviewRequestStatus} from 'Components/IncomingRequests/Constants'
import {CandidateSearchTranslations as cs} from 'Services/I18n/Constants'
import {useTranslation} from 'Hooks'
import {FormattedMessage} from 'react-intl'
import {Pending, Cancel} from '@mui/icons-material'
import {DateTime} from 'luxon'
// import {CompanyDataType, StateType} from 'Interfaces'
interface RequestContactProps {
  sendRequestContact: (value: SendRequestContactType) => void
  contactRequest?: IncomingRequestType | null
  seekerId: string
}

export const RequestContact: FC<RequestContactProps> = ({
  contactRequest,
  sendRequestContact,
  seekerId,
}) => {
  const translation = useTranslation()
  const [isMoreInfoOpen, setIsMoreInfoOpen] = useState(false)

  let rejectionDate = contactRequest?.rejectionDate
  let expirationDate = contactRequest?.expirationDate
  let rejection: DateTime
  let expiration: DateTime

  if (rejectionDate) {
    rejection = DateTime.fromISO(rejectionDate)
  }

  if (expirationDate) {
    expiration = DateTime.fromISO(expirationDate)
  }
  const isPending = contactRequest?.status === InterviewRequestStatus.PENDING
  const isRejected = contactRequest?.status === InterviewRequestStatus.REJECTED
  const isExpired = contactRequest?.status === InterviewRequestStatus.EXPIRED

  const MoreInfoPopover = () => {
    return (
      <div className={styles.infoPopoverWrap}>
        <div className={classNames(styles.infoPopoverTitle)}>
          <FormattedMessage id={cs.contactRequestPopoverTitle} />
        </div>
        <div className={styles.candidateStatus}>
          {isExpired && (
            <>
              <span className={styles.expiredIcon}>
                <Pending />
              </span>
              <span className={styles.infoDetailslWrap}>
                <span>
                  <FormattedMessage id={cs.contactRequestExpired} />
                </span>
                <span>{expiration.toFormat('d.L.yyyy')}</span>
              </span>
            </>
          )}
          {isRejected && (
            <>
              <span className={styles.rejectedIcon}>
                <Cancel />
              </span>
              <span className={styles.infoDetailslWrap}>
                {rejection ? (
                  <>
                    <span>
                      <FormattedMessage id={cs.contactRequestRejected} />
                    </span>
                    {rejection && <span>{rejection.toFormat('d.L.yyyy')}</span>}
                  </>
                ) : (
                  <span>
                    <FormattedMessage
                      id={cs.contactRequestRejectedWithoutDate}
                    />
                  </span>
                )}
              </span>
            </>
          )}
        </div>
      </div>
    )
  }
  return (
    <>
      <div className={styles.container}>
        <Button
          text={translation[cs.contactButton]}
          theme={'acceptRevoke'}
          onClick={() =>
            sendRequestContact({
              id: seekerId,
              snackbar: {
                message: translation[cs.contactRequestSentButton],
                duration: 1500,
              },
            })
          }
          className={classNames(styles.btn, {
            [styles.hidden]: isPending,
          })}
        />
        {isRejected && (
          <span
            className={styles.redIcon}
            onMouseEnter={() => setIsMoreInfoOpen(true)}
            onMouseLeave={() => setIsMoreInfoOpen(false)}
          >
            <Cancel />
          </span>
        )}
        {isExpired && (
          <span
            className={styles.purpleIcon}
            onMouseEnter={() => setIsMoreInfoOpen(true)}
            onMouseLeave={() => setIsMoreInfoOpen(false)}
          >
            <Pending />
          </span>
        )}
        <img
          className={classNames(styles.progress, {
            [styles.hidden]: !isPending,
          })}
          src={progress}
          alt="progress"
        />
        <span
          className={classNames(styles.progressTitle, {
            [styles.hidden]: !isPending,
          })}
        >
          <FormattedMessage id={cs.pendingRequest} />
        </span>
      </div>
      <div>{isMoreInfoOpen && <MoreInfoPopover />}</div>
    </>
  )
}
