export const companyVideo = {
  'companyVideo.videoTitle': 'Videos',
  'companyVideo.featuredVideoTitle': 'Featured video',

  'companyVideo.modalTitleEdit': 'Edit Videos',
  'companyVideo.modalTitleAdd': 'Add Videos',
  'companyVideo.modalVideosTitle': 'VIDEOS',
  'companyVideo.modalVideoPlaceholderText': 'Paste your YouTube url here ...',
  'companyVideo.modalVideoTitleTitle': 'VIDEO TITLE',
  'companyVideo.showVideo': 'Add video',
}
