import * as type from '../types'
import {ActionType, ContactUsType} from 'Interfaces'

export const contactUs = (
  contactUs: ContactUsType
): ActionType<ContactUsType> => {
  return {
    type: type.homepageHelpFaq.contactUs.send.requested,
    payload: {
      ...contactUs,
    },
  }
}
