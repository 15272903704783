export const myProjectCandidates = {
  'myprojectcandidates.sortByTitle': 'Sort by',
  'myprojectcandidates.sortOptionJobTitle': 'Job title',
  'myprojectcandidates.sortOptionFirstName': 'First name',
  'myprojectcandidates.sortOptionLastName': 'Last name',
  'myprojectcandidates.sortOptionDateAdded': 'Date added',
  'myprojectcandidates.sortOptionReviewed': 'Reviewed',
  'myprojectcandidates.sortOptionUnreviewed': 'Unreviewed',
  'myprojectcandidates.actionSendContactRequest': 'Send contact request',
  'myprojectcandidates.actionSendMessage': 'Send message',
  'myprojectcandidates.actionInviteToTesting': 'Invite to testing',
  'myprojectcandidates.searchKeyWordLabel': 'Find candidates in this project:',
  'myprojectcandidates.searchKeyWordPlaceholder': 'Type key words..',
  'myprojectcandidates.searchSubmitButton': 'Search',
  'myprojectcandidates.selectAllLabel': 'Select all',
  'myprojectcandidates.testingTitle': 'Invite to testing',
  'myprojectcandidates.testingOptionPsychometric': 'Psychometric',
  'myprojectcandidates.testingOptionCognitive': 'Cognitive',
  'myprojectcandidates.actionsTitle': 'Actions',
}
