import {
  BaseTaxonomyType,
  CandidateSearchFiltersType,
  SearchControlFormikValuesType,
  SearchControlPackedValuesType,
} from 'Interfaces'

const packApiTaxonomies = (
  values: BaseTaxonomyType[],
  name: string
): string => {
  let query = ''
  values.forEach((v, index) => {
    query = `${query}${!index ? '' : '&'}${name}=${v.id}`
  })
  return query
}

export const packSearchAPIQuery = (
  values: SearchControlFormikValuesType,
  filtersType?: CandidateSearchFiltersType
): string => {
  let pack1 = []
  if (filtersType === 'employer') {
    const keyword = values.keyword
      ? `keyword=${values.keyword.translation}`
      : ''
    const similarCandidateUserId = values.similarCandidateUserId
      ? `similarCandidateUserId=${values.similarCandidateUserId}`
      : ''
    const location = values.location ? `locationId=${values.location.id}` : ''
    const radius = values.radius ? `radius=${values.radius.id}` : ''
    const availabilityIds = values.availabilities?.length
      ? packApiTaxonomies(values.availabilities, 'availabilityIds')
      : ''
    const jobCategoryIds = values.jobCategories?.length
      ? packApiTaxonomies(values.jobCategories, 'jobCategoryIds')
      : ''
    const skillIds = values.skills?.length
      ? packApiTaxonomies(values.skills, 'skillIds')
      : ''
    const skillLevelIds = values.skillLevels?.length
      ? packApiTaxonomies(values.skillLevels, 'skillLevelIds')
      : ''
    const occupationIds = values.occupations?.length
      ? packApiTaxonomies(values.occupations, 'occupationIds')
      : ''
    const regionIds = values.regions?.length
      ? packApiTaxonomies(values.regions, 'regionIds')
      : ''
    const educationLevelIds = values.educationLevels?.length
      ? packApiTaxonomies(values.educationLevels, 'educationLevelIds')
      : ''
    const languageIds = values.languages?.length
      ? packApiTaxonomies(values.languages, 'languageIds')
      : ''
    const languageLevelIds = values.languageLevels?.length
      ? packApiTaxonomies(values.languageLevels, 'languageLevelIds')
      : ''
    const workExperienceIds = values.workExperiences?.length
      ? packApiTaxonomies(values.workExperiences, 'totalWorkExperienceIds')
      : ''
    const careerStatusIds = values.careerStatuses?.length
      ? packApiTaxonomies(values.careerStatuses, 'careerStatusIds')
      : ''
    const drivingLicenseCategories = values.drivingLicenseCategories?.length
      ? packApiTaxonomies(
          values.drivingLicenseCategories,
          'drivingLicenseCategoriesIds'
        )
      : ''
    const careerLevelIds = values.careerLevels?.length
      ? packApiTaxonomies(values.careerLevels, 'careerLevelIds')
      : ''
    const trainingTitleIds = values.trainingTitles?.length
      ? packApiTaxonomies(values.trainingTitles, 'trainingTitleIds')
      : ''
    const sortBy =
      values.sortBy && values.sortBy !== '' ? `sortBy=${values.sortBy}` : ''

    pack1 = [
      keyword,
      similarCandidateUserId,
      location,
      radius,
      availabilityIds,
      jobCategoryIds,
      skillIds,
      skillLevelIds,
      occupationIds,
      regionIds,
      educationLevelIds,
      languageIds,
      languageLevelIds,
      careerStatusIds,
      workExperienceIds,
      drivingLicenseCategories,
      careerLevelIds,
      trainingTitleIds,
      sortBy,
    ]
  } else {
    const firstName =
      values.firstName && values.firstName !== ''
        ? `firstName=${values.firstName}`
        : ''
    const lastName =
      values.lastName && values.lastName !== ''
        ? `lastName=${values.lastName}`
        : ''
    pack1 = [firstName, lastName]
  }

  let pack2 = pack1.filter((p) => p !== '')
  let pack3 = ''

  pack2.forEach((p, index) => {
    pack3 = `${pack3}${!index ? '' : '&'}${p}`
  })

  return pack3
}

export const packSavedSearch = (
  values: SearchControlFormikValuesType,
  update?: boolean
): SearchControlPackedValuesType => {
  const emptyArray = update ? [] : undefined
  const emptyValue = update ? null : undefined
  const availabilityIds = values.availabilities
    ? values.availabilities.map((a) => a.id)
    : emptyArray
  const jobCategoryIds = values.jobCategories
    ? values.jobCategories.map((jc) => jc.id)
    : emptyArray
  const skillIds = values.skills ? values.skills.map((s) => s.id) : emptyArray
  const skillLevelIds = values.skillLevels
    ? values.skillLevels.map((s) => s.id)
    : emptyArray
  const occupationIds = values.occupations
    ? values.occupations.map((s) => s.id)
    : emptyArray
  const regionIds = values.regions
    ? values.regions.map((s) => s.id)
    : emptyArray
  const educationLevelIds = values.educationLevels
    ? values.educationLevels.map((s) => s.id)
    : emptyArray
  const languageIds = values.languages
    ? values.languages.map((s) => s.id)
    : emptyArray
  const languageLevelIds = values.languageLevels
    ? values.languageLevels.map((s) => s.id)
    : emptyArray
  const drivingLicenseCategory = values.drivingLicenseCategories
    ? values.drivingLicenseCategories.map((s) => s.id)
    : emptyArray
  const careerLevelIds = values.careerLevels
    ? values.careerLevels.map((s) => s.id)
    : emptyArray
  const trainingTitleIds = values.trainingTitles
    ? values.trainingTitles.map((s) => s.id)
    : emptyArray

  return {
    keyword: values.keyword?.translation || emptyValue,
    locationId: values.location?.id || emptyValue,
    radius: values.radius?.id || emptyValue,
    firstName: values.firstName || emptyValue,
    lastName: values.lastName || emptyValue,
    availabilityIds,
    jobCategoryIds,
    skillIds,
    skillLevelIds,
    occupationIds,
    regionIds,
    educationLevelIds,
    languageIds,
    languageLevelIds,
    drivingLicenseCategory,
    careerLevelIds,
    trainingTitleIds,
  }
}
