import triglavLogo from 'Assets/Images/candidate-dashboard/triglav-logo.jpg'
import appleLogo from 'Assets/Images/candidate-dashboard/apple-logo.jpg'

export const FullCandidateData = {
  data: {
    id: '02cbd88f-229b-4958-bc5f-84a745203d9c',
    firstName: '',
    lastName: '',
    proffession: '',
    cvProfileLevelCompleteness: '',
    desiredOccupations: [],
    country: {
      countryCode: 'SL',
      id: '719e0ba7-8fd7-4b5f-b6e5-74ae56d12d5b',
      translation: 'Slovenia',
    },
    town: {
      id: '',
      translation: '',
    },
    streetAddress: '',
    postalCode: 0,
    email: '',
    phoneNumber: '',
    profileStep: 'Scouting',
    incomingRequests: [
      {
        id: 'd45e3b4a-849b-42e0-9450-aa4c0fec7934',
        company: 'Intra Lighting d.o.o.',
        type: 'Contact request',
        status: 'Pending',
        date: '2022-05-19T05:35:19.269Z',
        expiration: '2022-06-29T05:35:19.269Z',
      },
      {
        id: '120374d4-e2b9-46c2-9d24-33af2734d375',
        company: 'Ikea Slovenija d.o.o.',
        type: 'Offer',
        status: 'Accepted',
        date: '2022-05-16T05:35:19.269Z',
        expiration: '2022-06-15T05:35:19.269Z',
        attachment: {
          title: 'Ikea - Preliminary offer - Mark Hočevar.pdf',
          url: 'http://www.africau.edu/images/default/sample.pdf',
        },
      },
      {
        id: 'a98ef642-48fe-4aae-bc49-321fc9bf6348',
        company: 'Microsoft',
        type: 'Interview invitation',
        status: 'Accepted',
        date: '2022-05-16T05:35:19.269Z',
        expiration: '2022-06-16T05:35:19.269Z',
      },
      {
        id: 'b98ef642-48fe-4aae-bc49-321fc9bf6348',
        company: 'Microsoft',
        type: 'Interview invitation',
        status: 'Pending',
        date: '2022-05-16T05:35:19.269Z',
        expiration: '2022-06-15T05:35:19.269Z',
      },
      {
        id: '3ce539b4-81fc-4839-9546-98eebd0a2770',
        company: 'Microsoft Slovenija d.o.o.',
        type: 'Contact request',
        status: 'Rejected',
        date: '2022-05-16T05:35:19.269Z',
        expiration: '2022-06-15T05:35:19.269Z',
      },
      {
        id: 'ef701e93-c9fc-40a6-ba9a-374c78482f82',
        company: 'Microsoft Slovenija d.o.o.',
        type: 'Contact request',
        status: 'Approved',
        date: '2022-05-16T05:35:19.269Z',
        expiration: '2022-06-15T05:35:19.269Z',
      },
      {
        id: 'be881b5a-5cea-4f14-b016-323e285bc14b',
        company: 'Microsoft Slovenija d.o.o.',
        type: 'Contact request',
        status: 'Expired',
        date: '2022-05-16T05:35:19.269Z',
        expiration: '2022-06-15T05:35:19.269Z',
      },
      {
        id: 'a08f6f8b-96d2-4f9f-8822-36285b5b9c63',
        company: 'Microsoft',
        type: 'Offer',
        status: 'Pending',
        date: '2022-05-16T05:35:19.269Z',
        expiration: '2022-06-15T05:35:19.269Z',
        appointmentStartDate: '2022-06-15T05:35:19.269Z',
        appointmentEndDate: '2022-06-15T05:35:19.269Z',
      },
      {
        id: '526c0b38-b434-43a4-ba24-b3fb931c3327',
        company: 'Microsoft',
        type: 'Offer',
        status: 'Accepted',
        date: '2022-05-16T05:35:19.269Z',
        expiration: '2022-06-15T05:35:19.269Z',
        appointmentStartDate: '2022-06-15T05:35:19.269Z',
        appointmentEndDate: '2022-06-15T05:35:19.269Z',
      },
      {
        id: 'e678b946-0200-4348-8c3b-7df433b57457',
        company: 'Microsoft',
        type: 'Offer',
        status: 'Expired',
        date: '2022-05-16T05:35:19.269Z',
        expiration: '2022-06-15T05:35:19.269Z',
        appointmentStartDate: '2022-06-15T05:35:19.269Z',
        appointmentEndDate: '2022-06-15T05:35:19.269Z',
      },
      {
        id: '2967e0fb-7175-4b58-9d6b-6ca78939710f',
        company: 'Microsoft',
        type: 'Video interview',
        status: 'Pending',
        date: '2022-05-16T05:35:19.269Z',
        expiration: '2022-06-15T05:35:19.269Z',
        appointmentStartDate: '2022-06-15T05:35:19.269Z',
        appointmentEndDate: '2022-06-15T05:35:19.269Z',
      },
      {
        id: '2967e0fb-7175-4b58-9d6b-6ca78939710f',
        company: 'Microsoft',
        type: 'Offer',
        status: 'Expired',
        date: '2022-05-16T05:35:19.269Z',
        appointmentStartDate: '',
        appointmentEndDate: '',
      },
      {
        id: '5b612d09-251b-4a48-8342-2e987b60df50',
        company: 'Microsoft',
        type: 'Interview invitation',
        status: 'New time proposed',
        date: '2022-05-16T05:35:19.269Z',
        expiration: '2022-06-15T05:35:19.269Z',
        appointmentStartDate: '2022-06-15T05:35:19.269Z',
        appointmentEndDate: '2022-06-15T05:35:19.269Z',
      },
      {
        id: '7ebc0498-e879-40ac-a387-e1555e8e9ce6',
        company: 'Microsoft',
        type: 'Video interview',
        status: 'New time proposal',
        date: '2022-05-16T05:35:19.269Z',
        expiration: '2022-06-15T05:35:19.269Z',
        appointmentStartDate: '2022-06-15T05:35:19.269Z',
        appointmentEndDate: '2022-06-15T05:35:19.269Z',
      },
      {
        id: '86a0c800-234b-46fb-a3b3-0050aeb1a0da',
        company: 'Ikea Slovenija d.o.o.',
        type: 'Offer',
        status: 'Placement confirmed',
        content:
          'Dear John, We send you our preliminary offer to your email address. We kindly ask you to review it and decide whether to accept or reject it.Thank you',
        date: '2022-05-16T05:35:19.269Z',
        expiration: '2022-06-15T05:35:19.269Z',
        attachment: {
          title: 'Ikea - Preliminary offer - Mark Hočevar.pdf',
          url: 'http://www.africau.edu/images/default/sample.pdf',
        },
      },
      {
        id: 'd6c414c1-b4cb-4d84-85f7-8db1f709a8f1',
        company: 'Ikea Slovenija d.o.o.',
        type: 'Offer',
        status: 'Accepted',
        content:
          'Dear John, We send you our preliminary offer to your email address. We kindly ask you to review it and decide whether to accept or reject it.Thank you',
        date: '2022-05-16T05:35:19.269Z',
        expiration: '2022-06-15T05:35:19.269Z',
        attachment: {
          title: 'Ikea - Preliminary offer - Mark Hočevar.pdf',
          url: 'http://www.africau.edu/images/default/sample.pdf',
        },
      },
      {
        id: 'e5bca3e6-ef70-458f-8a62-00d1e850ab4c',
        company: 'Ikea Slovenija d.o.o.',
        type: 'Offer',
        status: 'Rejected',
        content:
          'Dear John, We send you our preliminary offer to your email address. We kindly ask you to review it and decide whether to accept or reject it.Thank you',
        date: '2022-05-16T05:35:19.269Z',
        expiration: '2022-06-15T05:35:19.269Z',
        attachment: {
          title: 'Ikea - Preliminary offer - Mark Hočevar.pdf',
          url: 'http://www.africau.edu/images/default/sample.pdf',
        },
      },
    ],
  },
}

export const MinifeedData = [
  {
    id: '18cbd88f-229b-4958-ac5f-84a745203d9c',
    title: 'Zavarovalnica Triglav d.d.',
    type: 'Basic',
    logo: triglavLogo,
    createdAt: '2022-03-20T03:24:00',
    url: 'https://mangart.xyz/en/',
  },
  {
    id: '91cbd88f-229b-4958-bc5f-84a745203d9c',
    title: 'Apple-Ireland',
    type: 'Basic',
    logo: appleLogo,
    createdAt: '2022-03-28T03:24:00',
    url: 'https://mangart.xyz/en/',
  },
  {
    id: '18cbd88f-229b-4958-ac5f-84a745203d9c',
    title: 'Zavarovalnica Triglav d.d.',
    type: 'Basic',
    createdAt: '2022-03-20T03:24:00',
    url: 'https://mangart.xyz/en/',
  },
  {
    id: '91cbd88f-229b-4958-bc5f-84a745203d9c',
    title: 'Apple-Ireland',
    type: 'Basic',
    logo: appleLogo,
    createdAt: '2022-03-28T03:24:00',
    url: 'https://mangart.xyz/en/',
  },
]
