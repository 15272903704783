import {FC} from 'react'

import {SidebarLink} from 'Components/SidebarLink'
import styles from './SidebarActions.module.scss'
import {AppRoute, EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'

export const SidebarActions: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.linksWrap}>
        <SidebarLink
          text={edt.manageCompanyProfile}
          routeName={AppRoute.CompanyProfile}
        />
        <SidebarLink
          text={edt.searchCandidates}
          routeName={AppRoute.CandidateSearch}
        />
      </div>
    </div>
  )
}
