import {FC, useState} from 'react'

import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact'
import {Button} from 'Components/Button'
import {CandidateDashboardTranslations as cdt} from 'Services/I18n/Constants'

import styles from './OfferItem.module.scss'
import {IncomingRequestType} from 'Interfaces'
import ModalPlacementRequestEmp from 'Components/EmployerDashboard/PlacementsListItem/ModalPlacementRequestEmp'

interface OfferItemProps {
  request: IncomingRequestType
}

export const OfferItem: FC<OfferItemProps> = ({request}) => {
  const [isOpenPlacementModal, setIsOpenPlacementModal] = useState(false)
  return (
    <div className={styles.offerItemContainer}>
      <div className={styles.titleContainer}>
        <ConnectWithoutContactIcon />
        <h2>
          {request.seeker.firstName} {request.seeker.lastName}
        </h2>
      </div>
      <div className={styles.buttonsWrapper}>
        <Button
          text={cdt.confirmPlacement}
          onClick={() => setIsOpenPlacementModal(true)}
          className={styles.confirmPlacementButton}
        />
        <Button
          text={cdt.reject}
          onClick={() => setIsOpenPlacementModal(true)}
          className={styles.rejectPlacementButton}
        />
      </div>
      <ModalPlacementRequestEmp
        isOpenPlacementModal={isOpenPlacementModal}
        setIsOpenPlacementModal={setIsOpenPlacementModal}
        request={request}
      />
    </div>
  )
}
