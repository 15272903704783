const messageCenter = {
  'messageCenter.heading': 'Center za sporočila',
  'messageCenter.labelCandidates': 'Kandidati',
  'messageCenter.labelEmployers': 'Delodajalci',
  'messageCenter.tabLabelTSA': 'Talent success advisor',
  'messageCenter.messageType_all_messages': 'Vsa sporočila',
  'messageCenter.messageType_inbox': 'Prejeto',
  'messageCenter.messageType_archived': 'Arhivirana',
  'messageCenter.messageType_unread': 'Neprebrano',
  'messageCenter.searchBtn': 'Išči',
  'messageCenter.searchConversationsLabel': 'Išči po sporočilih',
  'messageCenter.resetSearchBtn': 'Ponastavi iskanje',
  'messageCenter.manageConversations': 'Upravljaj pogovor',
  'messageCenter.setAwayMessage': 'Nastavi samodejno sporočilo',
  'messageCenter.selected': 'Izbrani',
  'messageCenter.clearAll': 'Odznači vse',
  'messageCenter.selectAll': 'Označi vse',
  'messageCenter.heading_all_messages': 'Sporočila',
  'messageCenter.heading_archived': 'Arhivirana sporočila',
  'messageCenter.heading_unread': 'Neprebrana sporočila',
  'messageCenter.today': 'Danes',
  'messageCenter.newMessage': 'Novo sporočilo',
  'messageCenter.typeAname': 'Vpišite ime',
  'messageCenter.writeAmessage': 'Napiši sporočilo…',
  'messageCenter.send': 'Pošlji',
  'messageCenter.delete': 'Izbriši',
  'messageCenter.markAsUnread': 'Označi kot neprebrano',
  'messageCenter.archive': 'Arhiviraj',
  'messageCenter.you': 'Ti',
  'messageCenter.deleteModalTitle': 'Ste prepričani, da želite izbrisati?',
  'messageCenter.deleteModalContent':
    'Označeni pogovori, vključno z zgodovino, bodo trajno izbrisani s Preference. Tega dejanja ni mogoče preklicati.',
  'messageCenter.closeManaging': 'Zapri upravljanje',
  'messageCenter.deleteConversation': 'Izbriši pogovor',
  'messageCenter.employerPlaceholder':
    'Izberite kandidata, ki mu želite poslati sporočilo',
  'messageCenter.candidatePlaceholder':
    'Izberite podjetje, ki mu želite poslati sporočilo',
}

export default messageCenter
