import {FC, useState} from 'react'
import styles from './RequestActions.module.scss'
import {ClickAwayListener} from '@mui/material'
import PopoverList from 'Components/PopoverList'
import {IncomingRequestType, RjbProfileStatus} from 'Interfaces'
import classNames from 'classnames'
import {PlacementRequestStatus} from 'Components/IncomingRequests/Constants'
import {FormattedMessage} from 'react-intl'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'
import useRequestActionsArray from 'Components/IncomingRequests/hooks/useRequestActionsArray'
import ModalOfferRequestDetails from 'Components/EmployerDashboard/OfferListItem/ModalOfferDetailsEmp'
import ModalSendMessageEmp from 'Components/EmployerDashboard/InterviewInvitationListItem/ModalSendMessageEmp/index'
import {OfferRequestStatus} from 'Components/EmployerDashboard/Constants'
import ModalSendOfferEmp from 'Components/EmployerDashboard/InterviewInvitationListItem/ModalSendOfferEmp'
import ModalSendOfferDetailsEmp from 'Components/EmployerDashboard/InterviewInvitationListItem/ModalSendOfferDetailsEmp'
import {
  Cancel,
  CheckCircle,
  Info,
  MoreHoriz,
  Pending,
} from '@mui/icons-material'

type RequestActionsProps = {
  request: IncomingRequestType
}

const OptionalActions: FC<RequestActionsProps> = ({request}) => {
  const noLongerActiveCandidate =
    !request.seeker.hasFinishedPlacementWithCurrentEmployer &&
    request.seeker.rjbProfileStatus === RjbProfileStatus.CLOSED
  const [hasAction] = useRequestActionsArray(request)
  // const [isOpen, setIsOpen] = useState(false)
  const toggleMoreInfoOpen = () => {
    // setIsOpen((old) => !old)
  }

  const MoreInfoPopover = () => {
    // let statusTitleTranslation = {
    //   title: edt.placementConfirmed,
    //   color: styles.greenText,
    // }

    // if (isOpen)
    //   return (
    //     <div className={styles.infoPopoverWrap}>
    //       <div
    //         className={classNames(
    //           styles.infoPopoverTitle,
    //           statusTitleTranslation.color
    //         )}
    //       >
    //         <FormattedMessage id={statusTitleTranslation.title} />
    //       </div>
    //       {/*TODO add placement confirmed content here */}
    //       <div className={styles.candidateStatus}>
    //         Placement confirmed content
    //       </div>
    //     </div>
    //   )

    return null
  }
  const isOfferSent = request.offerRequestDateCreated

  const onSendOffer = () => {
    if (!isOfferSent) {
      setModalSendOfferEmp(true)
    }
  }
  const [modalSendOfferDetailsEmp, setModalSendOfferDetailsEmp] =
    useState(false)
  const [modalSendOfferEmp, setModalSendOfferEmp] = useState(false)

  const SendOffer = () => {
    return (
      <>
        <ModalSendOfferDetailsEmp
          isOpen={modalSendOfferDetailsEmp}
          setIsOpen={setModalSendOfferDetailsEmp}
          request={request}
        />
        <ModalSendOfferEmp
          isOpen={modalSendOfferEmp}
          setIsOpen={setModalSendOfferEmp}
          requestId={request.journeyId}
        />

        <div
          role="button"
          className={classNames(styles.button, {
            [styles.buttonDisabled]: isOfferSent,
          })}
        >
          <span>
            <FormattedMessage id={edt.sendNewOffer} />
          </span>
        </div>
      </>
    )
  }

  const MoreInfoTrigger = () => (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      // onClickAway={() => setIsOpen(false)}
      onClickAway={() => {}}
    >
      <button className={styles.rightIcon} onClick={toggleMoreInfoOpen}>
        <MoreInfoPopover />
        <Info />
      </button>
    </ClickAwayListener>
  )

  if (request.status === 'pending') {
    return (
      <div className={classNames(styles.mainAction, styles.mainActionDisabled)}>
        <span className={classNames(styles.leftIcon, styles.yellowColor)}>
          <Pending />
        </span>
        <FormattedMessage id={edt.offerPending} />
      </div>
    )
  } else if (hasAction('OFFER_ACCEPTED')) {
    return (
      <div className={classNames(styles.mainAction, styles.mainActionDisabled)}>
        <span className={classNames(styles.leftIcon, styles.greenColor)}>
          <CheckCircle />
        </span>
        <span>
          <FormattedMessage id={edt.acceptedOffer} />
        </span>
        <MoreInfoTrigger />
      </div>
    )
  } else if (hasAction('OFFER_REJECTED')) {
    return (
      <div className={classNames(styles.mainAction, styles.mainActionDisabled)}>
        <span className={classNames(styles.leftIcon, styles.redColor)}>
          <Cancel />
        </span>
        <FormattedMessage id={edt.offerRejected} />
      </div>
    )
  } else if (hasAction('OFFER_EXPIRED') && !noLongerActiveCandidate) {
    return (
      <div className={classNames(styles.mainAction)} onClick={onSendOffer}>
        <SendOffer />
      </div>
    )
  } else if (hasAction('OFFER_EXTENDED')) {
    return (
      <div className={classNames(styles.mainAction, styles.mainActionDisabled)}>
        <span className={classNames(styles.leftIcon, styles.yellowColor)}>
          <Pending />
        </span>
        <FormattedMessage id={edt.offerExtended} />
      </div>
    )
  }

  return null
}

const MoreActions: FC<RequestActionsProps> = ({request}) => {
  const [modalOfferDitails, setModalOfferDetails] = useState(false)
  const [sendMessageModal, setSendMessageModal] = useState(false)
  const noLongerActiveCandidate =
    !request.seeker.hasFinishedPlacementWithCurrentEmployer &&
    request.seeker.rjbProfileStatus === RjbProfileStatus.CLOSED
  const onViewDetails = () => {
    setModalOfferDetails(true)
  }

  const onSendMessage = () => {
    setSendMessageModal(true)
  }

  const moreActions = [
    {
      text: edt.viewDetails,
      onClick: onViewDetails,
    },
  ]

  if (
    (request.status ===
      PlacementRequestStatus.COMPANY_USER_PENDING_SEEKER_PENDING ||
      request.status ===
        PlacementRequestStatus.COMPANY_USER_PENDING_SEEKER_CONFIRMED ||
      request.status === OfferRequestStatus.PENDING) &&
    !noLongerActiveCandidate
  ) {
    moreActions.push({
      text: edt.sendMessage,
      onClick: onSendMessage,
    })
  }

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={styles.moreAction}
      role="button"
    >
      <PopoverList
        triggerIcon={<MoreHoriz />}
        items={moreActions}
        threeDotsBtn
      />
      <ModalOfferRequestDetails
        request={request}
        isOpen={modalOfferDitails}
        setIsOpen={setModalOfferDetails}
      />
      <ModalSendMessageEmp
        request={request}
        isOpen={sendMessageModal}
        setIsOpen={setSendMessageModal}
      />
    </div>
  )
}

export const RequestActions: FC<RequestActionsProps> = ({request}) => {
  return (
    <div onClick={(e) => e.stopPropagation()} className={styles.actionWrap}>
      <OptionalActions request={request} />
      <MoreActions request={request} />
    </div>
  )
}
