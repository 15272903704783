import {connect} from 'react-redux'

import {EmployerDashboardLandingHeader} from './EmployerDashboardLandingHeader'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {getCompanyData} from 'Store/Actions'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  company: state.company,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCompanyData: (id: string) => dispatch(getCompanyData(id)),
})

export const EmployerDashboardLandingHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerDashboardLandingHeader)
