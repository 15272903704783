import {FC, useEffect, useMemo} from 'react'
import MainLayout from 'Layouts/MainLayout'
import Breadcrumbs from 'Components/Breadcrumbs'
import CompanyProfileFooter from 'Components/Footer'
import {CandidateProfileCompletenessDataType} from 'Interfaces/CandidateType'
import {JourneyTracker} from 'Components/JourneyTracker'
import styles from './CandidateDashboard.module.scss'
import IncomingRequests from 'Components/IncomingRequests'
import {Link} from 'react-router-dom'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {CandidateDashboardTranslations as cdt} from 'Services/I18n/Constants'
import {CandidateDashboardHeader} from 'Components/CandidateDashboardHeader'
import {EmptyCandidateDashboard} from 'Components/Dashboard/EmptyCandidateDashboard/EmptyCandidateDashboard'
import {useTranslation} from 'Hooks'
import {FormattedMessage} from 'react-intl'
import {
  DashboardRequestsStateType,
  JourneyTrackerDataType,
  SeekerProfileType,
} from 'Interfaces'

interface CandidateDashboardProps {
  getFullCandidateData: () => void
  dashboardRequests: DashboardRequestsStateType
  journeyTracker: JourneyTrackerDataType
  fullProfile: SeekerProfileType | undefined
  profileCompleteness: CandidateProfileCompletenessDataType
}

export const CandidateDashboard: FC<CandidateDashboardProps> = ({
  getFullCandidateData,
  fullProfile,
  dashboardRequests,
  journeyTracker,
  profileCompleteness,
}) => {
  useEffect(() => {
    getFullCandidateData()
  }, [])

  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: getRoute(AppRoute.HomePageCandidate)},
    {
      name: `${fullProfile?.firstName} ${fullProfile?.lastName}`,
      link: getRoute(AppRoute.Profile),
    },
    {name: cdt.dashboard},
  ]

  const Footer: FC = () => {
    return (
      <div>
        <CompanyProfileFooter />
      </div>
    )
  }
  const translation = useTranslation()
  const activeJourneyStep = useMemo(() => {
    let currentStep
    if (journeyTracker.data.journeyStatus === 'prequalification') {
      currentStep = cdt.prequalification
    } else if (journeyTracker.data.journeyStatus === 'scouting') {
      currentStep = cdt.scouting
    } else if (journeyTracker.data.journeyStatus === 'interviewing') {
      currentStep = cdt.interviewing
    } else if (journeyTracker.data.journeyStatus === 'negotiations') {
      currentStep = cdt.negotiations
    } else if (journeyTracker.data.journeyStatus === 'placement ') {
      currentStep = cdt.placement
    }
    return currentStep
  }, [journeyTracker])

  const journeySteps = [
    {translation: translation[cdt.prequalification]},
    {translation: translation[cdt.scouting]},
    {
      translation: translation[cdt.interviewing],
      count: journeyTracker.data.activeInterviewsCount,
    },
    {
      translation: translation[cdt.negotiations],
      count: journeyTracker.data.activeOffersCount,
    },
    {translation: translation[cdt.placement]},
  ]

  return (
    <MainLayout
      SubNavigation={
        <div className={styles.breadcrumbsWrapper}>
          <Breadcrumbs breadcrumbs={breadcrumbsArray} />
        </div>
      }
      Footer={<Footer />}
    >
      <div className={styles.mainContentWrapper}>
        {fullProfile && (
          <CandidateDashboardHeader
            fullProfile={fullProfile}
            profileCompleteness={profileCompleteness}
          />
        )}
        <JourneyTracker
          steps={journeySteps}
          activeStep={translation[activeJourneyStep]}
        />

        {!dashboardRequests.loading ? (
          dashboardRequests.data.length > 0 ? (
            <div className={styles.myRequestsWrap}>
              <div className={styles.heading}>
                <FormattedMessage id={cdt.myIncomingRequests} />
              </div>
              <IncomingRequests requests={dashboardRequests.data} />
              <div className={styles.linkWrap}>
                <Link
                  to={getRoute(AppRoute.AllIncomingRequests)}
                  className={styles.viewMoreLink}
                >
                  <FormattedMessage id={cdt.viewAllRequests} />
                </Link>
              </div>
            </div>
          ) : (
            <EmptyCandidateDashboard />
          )
        ) : null}
      </div>
    </MainLayout>
  )
}
