import {login} from './Login/sl'
import {employeronboarding} from './Onboarding/sl'
import {employerDashboard} from './Dashboard/sl'
import {thankYou} from './ThankYou/sl'
import {candidateSearch} from './Search/sl'
import {spectoCalendarTranslations} from './Calendar/sl'
import {myProject} from './MyProject/sl'
import {employerHomePage} from './EmployerHomePage/sl'
import {employerFAQ} from './EmployerFAQ/sl'
import {employerInfoLandingPage} from './EmployerInfoLandingPage/sl'
import {companyProfile} from './CompanyProfile/sl'

export const employer = {
  ...login,
  ...employeronboarding,
  ...thankYou,
  ...employerDashboard,
  ...employeronboarding,
  ...candidateSearch,
  ...spectoCalendarTranslations,
  ...myProject,
  ...employerHomePage,
  ...employerFAQ,
  ...employerInfoLandingPage,
  ...companyProfile,
}
