import {FC, useEffect, useMemo, useState} from 'react'
import RateCandidate from 'Components/Widgets/RateCandidateMyProject'
import Hr from 'Components/Hr'
import AboutCandidate from 'Components/CandidateSearch/AboutCandidate'
import {LinksContainer} from 'Components/LinksContainer'
import {
  AppRoute,
  CandidateSearchTranslations as cs,
  EmployerDashboardTranslations as edt,
  MyProjectCandidatesTranslations as mpct,
  SidebarTranslations as st,
} from 'Services/I18n/Constants'
import VideocamIcon from '@mui/icons-material/Videocam'
import Comments from 'Components/Widgets/Comments'
import CommentsList from 'Components/Widgets/CommentsList'
import {
  Candidate,
  RemoveCandidateFromProjectsType,
  SendRequestContactType,
} from 'Interfaces/CandidateType'

import styles from './CandidateMyProjectSidebarContent.module.scss'
import {
  CommentType,
  ConversationMessageType,
  ConversationType,
  HistoryType,
  ImageType,
  IncomingRequestSeekerType,
  LinkType,
  RjbProfileStatus,
} from 'Interfaces'
import {getRoute} from 'Services/I18n/Utils'
import {MessagesListContainer} from 'Components/Widgets/MessagesList'
import {SidebarLink} from 'Components/SidebarLink'
import {useTranslation} from 'Hooks'
import classNames from 'classnames'
import MailOutlineIcon from '@mui/icons-material/MailOutline'
import ModalSendMessageEmp from 'Components/EmployerDashboard/InterviewInvitationListItem/ModalSendMessageEmp'
import ModalSendOfferEmp from 'Components/EmployerDashboard/InterviewInvitationListItem/ModalSendOfferEmp'
import {HistoryEdu} from '@mui/icons-material'
import {FormattedMessage} from 'react-intl'
import {Avatar} from 'Components/Widgets/Avatar'

interface CandidateSearchSidebarContentProps {
  candidateBasicData: Candidate | IncomingRequestSeekerType | null
  history: HistoryType[]
  comments: {items: CommentType[]}
  getCandidateHistory: (id: string) => void
  getCandidateComments: (id: string) => void
  getConversationByUserIds: (query: string) => void
  openedConversation: ConversationType
  selectConversation: (conversation?: ConversationType) => void
  messages?: ConversationMessageType[]
  resetMessageCenterData: () => void
  sendRequestContact: (value: SendRequestContactType) => void
  removeCandidateFromProjects: (value: RemoveCandidateFromProjectsType) => void
  commentFocusTrigger?: number
}

export const CandidateMyProjectSidebarContent: FC<
  CandidateSearchSidebarContentProps
> = ({
  candidateBasicData,
  comments,
  getCandidateComments,
  openedConversation,
  getConversationByUserIds,
  selectConversation,
  messages,
  resetMessageCenterData,
  sendRequestContact,
  commentFocusTrigger,
}) => {
  const translation = useTranslation()
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false)
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false)

  const noLongerActiveCandidate =
    !candidateBasicData?.hasFinishedPlacementWithCurrentEmployer &&
    candidateBasicData?.rjbProfileStatus === RjbProfileStatus.CLOSED

  const links: LinkType[] = useMemo(
    () => [
      {
        name: edt.sendMessage,
        onClick: () => setIsMessageModalOpen(true),
        icon: <MailOutlineIcon />,
      },
      {
        name: edt.invite,
        route: `${getRoute(AppRoute.InviteCalendar).replace(
          ':candidateId',
          candidateBasicData?.userId as string
        )}`,
        icon: <VideocamIcon />,
      },
      {
        name: edt.sendOffer,
        onClick: () => setIsOfferModalOpen(true),
        icon: <HistoryEdu />,
      },
      {
        name: st.viewCandidatesAssessments,
        route: `${getRoute(AppRoute.PublicAssessments).replace(
          ':id',
          candidateBasicData?.userId as string
        )}`,
      },
      // TODO employer-dashboard: Add when bookmarking gets implemented
      // {
      //   name: 'Bookmark',
      //   route: AppRoute.Profile,
      //   icon: <BookmarkBorderIcon />,
      // },
    ],
    [candidateBasicData]
  )

  useEffect(() => {
    if (candidateBasicData && candidateBasicData!.id) {
      getCandidateComments(candidateBasicData?.userId || candidateBasicData.id)
      getConversationByUserIds(`userIds=${candidateBasicData?.userId}`)
    }
  }, [candidateBasicData])

  useEffect(() => {
    if (openedConversation) selectConversation(openedConversation)
  }, [openedConversation])

  useEffect(() => {
    return () => {
      resetMessageCenterData()
    }
  }, [])

  if (!candidateBasicData) return null

  const {firstName, lastName, userId, companySeekerJourney} = candidateBasicData
  const contactRequest = companySeekerJourney?.contactRequest
  return (
    <div className={styles.sidebar}>
      <div className={styles.searchCandidatesWrapper}>
        <SidebarLink
          text={edt.searchCandidates}
          routeName={AppRoute.CandidateSearch}
        />
      </div>
      <div className={styles.hrWrapper}>
        <Hr className={styles.darkGrayHr} />
      </div>
      {contactRequest?.status === 'approved' && (
        <div className={styles.avatarImageContainer}>
          <div className={styles.hrWrapper}>
            <Hr className={styles.darkGrayHr} />
          </div>
          <Avatar
            firstName={candidateBasicData.firstName as string}
            lastName={candidateBasicData.lastName as string}
            image={candidateBasicData?.profilePicture as ImageType}
          />
          <div className={styles.hrWrapper}>
            <Hr className={styles.darkGrayHr} />
          </div>
        </div>
      )}
      {contactRequest?.status !== 'approved' &&
        (candidateBasicData?.publicOccupation ||
          candidateBasicData?.town ||
          candidateBasicData?.country) && (
          <div className={styles.firstHrWrap}>
            <Hr className={styles.darkGrayHr} />
          </div>
        )}
      <AboutCandidate
        name={firstName && lastName ? firstName + ' ' + lastName : ''}
        proffession={candidateBasicData?.publicOccupation?.translation || ''}
        town={candidateBasicData?.town?.translation || ''}
        country={candidateBasicData?.country?.translation || ''}
        isAccepted={contactRequest?.status === 'approved'}
        image={!!candidateBasicData?.profilePicture?.url}
      />
      {noLongerActiveCandidate && (
        <div className={styles.noLongerActiveCandidateMessage}>
          <FormattedMessage id={edt.candidateNoLongerActive} />
        </div>
      )}
      <div
        className={classNames(styles.hrWrapper, {
          [styles.firstHrWrap]:
            !candidateBasicData?.publicOccupation &&
            !candidateBasicData?.town &&
            !candidateBasicData?.country,
        })}
      >
        <Hr className={styles.darkGrayHr} />
      </div>
      <RateCandidate
        placement={'widget'}
        seekerEvaluation={
          candidateBasicData.companyUserSeekerProject?.seekerEvaluation
        }
        seekerId={userId}
        readOnly={noLongerActiveCandidate}
      />
      {contactRequest?.status === 'approved' && (
        <>
          <div className={styles.hrWrapper}>
            <Hr className={styles.darkGrayHr} />
          </div>
          {!noLongerActiveCandidate && (
            <>
              <LinksContainer links={links} hash />
              <div className={styles.hrWrapper}>
                <Hr className={styles.darkGrayHr} />
              </div>
            </>
          )}
        </>
      )}
      {contactRequest?.status !== 'approved' && (
        <div className={styles.hrWrapper}>
          <Hr className={styles.darkGrayHr} />
        </div>
      )}
      {!companySeekerJourney && (
        <>
          <SidebarLink
            text={mpct.actionSendContactRequest}
            onClick={() =>
              sendRequestContact({
                id: candidateBasicData.userId,
                snackbar: {
                  message: translation[cs.contactRequestSentButton],
                  duration: 1500,
                },
              })
            }
            withMarginBottom={false}
          />
          <div className={styles.hrWrapper}>
            <Hr className={styles.darkGrayHr} />
          </div>
        </>
      )}
      <ModalSendMessageEmp
        request={candidateBasicData as Candidate | IncomingRequestSeekerType}
        isOpen={isMessageModalOpen}
        setIsOpen={setIsMessageModalOpen}
        parentSelector={() =>
          document.querySelector(`.${styles.sidebar}`) as HTMLElement
        }
      />

      {candidateBasicData?.companySeekerJourney?.id && (
        <ModalSendOfferEmp
          isOpen={isOfferModalOpen}
          setIsOpen={setIsOfferModalOpen}
          requestId={candidateBasicData.companySeekerJourney.id}
          parentSelector={() =>
            document.querySelector(`.${styles.sidebar}`) as HTMLElement
          }
        />
      )}
      {/* <SidebarLink
        text={cs.removeModalTitle}
        onClick={() =>
          removeCandidateFromProjects({
            seekerId: candidateBasicData.userId,
            projectIds: [
              candidateBasicData.companyUserSeekerProject?.project
                ?.id as string,
            ],
          })
        }
      /> */}

      <Comments page="myProject" focusTrigger={commentFocusTrigger} />
      <CommentsList items={comments.items} />
      {candidateBasicData?.contactRequest?.status === 'approved' && (
        <MessagesListContainer items={messages && messages} />
      )}
    </div>
  )
}
