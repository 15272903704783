import {UserType} from 'Interfaces'
import {AuthGuardContainer} from './AuthGuardContainer'
import {AnyGuardContainer} from './AnyGuardContainer'

interface GuardProps {
  children?: JSX.Element | JSX.Element[]
}

export const SeekerGuard = ({children}: GuardProps): JSX.Element => (
  <AuthGuardContainer role={UserType.SEEKER}>{children}</AuthGuardContainer>
)

export const EmployerGuard = ({children}: GuardProps): JSX.Element => (
  <AuthGuardContainer role={UserType.EMPLOYER}>{children}</AuthGuardContainer>
)

export const AnyGuard = ({children}: GuardProps): JSX.Element => (
  <AnyGuardContainer>{children}</AnyGuardContainer>
)
