import {connect} from 'react-redux'

import {MessageCenterHeader} from './MessageCenterHeader'
import {AppDispatch} from 'Store'
import {
  getUnreadConversationsCount,
  searchConversations,
} from 'Store/Actions/MessageCenterActions'
import {ConversationsSearchParamsType} from 'Interfaces'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  params: state.messageCenter?.params,
  specificParticipiantUnreadCount:
    state.messageCenter?.specificParticipiantUnreadCount,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  searchConversations: (params?: ConversationsSearchParamsType) =>
    dispatch(searchConversations(params)),
  getUnreadConversationsCount: (participantsType?: string) =>
    dispatch(getUnreadConversationsCount(participantsType)),
})

export const MessageCenterHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageCenterHeader)
