export const candidateSearch = {
  'savedSearch.modalTitle': 'Shranite ali se naročite na iskanje',
  'savedSearch.editModalTitle': 'Uredi shranjena iskanja',
  'savedSearch.saveUpdateTitle': 'Shrani ali posodobi obstoječe iskanje',
  'savedSearch.title': 'Shranjena iskanja',
  'savedSearch.inputPlaceholder': 'Poimenujte iskanje...',
  'savedSearch.addToFavorites': 'Dodaj med priljubljena iskanja',
  'savedSearch.frequencyTitle': 'Perioda',
  'savedSearch.frequencyDescription':
    'Obvesti me preko e-pošte, ko bodo na voljo novi rezultati',
  'savedSearch.selectFrequency': 'Izberite periodo',
  'savedSearch.updateSearch': 'Posodobi iskanje',
  'savedSearch.saveSearch': 'Shrani iskanje',
  'savedSearch.pageTitle': 'Shranjena iskanja življenjepisov',
  'savedSearch.columnHeaderName': 'Shranjeno iskanje',
  'candidateSearch.savedSearchDeleteModal':
    'Ali ste prepričani, da želite izbrisati shranjeno iskanje življenjepisov?',
  'savedSearch.columnHeaderCreated': 'Ustvarjeno',
  'savedSearch.columnHeaderFavorites': 'Priljubljeni',
  'savedSearch.columnHeaderNotifications': 'Obvestila',
  'savedSearch.columnHeaderLastCSASent': 'Zadnji poslan opomnik',
  'savedSearch.columnHeaderActions': 'Akcije',
  'savedSearch.existingTitle': 'Izberi obstoječe iskanje',
  'savedSearch.overwriteWarning': 'Obstoječe iskanje se bo prepisalo.',
  'savedSearch.newSaveSearch': 'Ustvari novo iskanje',
  'savedSearch.updateSaveSearch': 'Posodobi obstoječe iskanje',
  'candidateSearch.mainTitle': 'Iskanje kandidatov',
  'candidateSearch.mainPlaceholder': 'Vpiši ključno besedo ...',
  'candidateSearch.title': 'Ni kandidatov',
  'candidateSearch.text': '',
  'candidateSearch.optionFirst': 'Ponastavi filtre',
  'candidateSearch.optionSecond': 'Aktiviraj opomnik',
  'candidateSearch.optionLast': 'Poglej popularne kandidate',
  'candidateSearch.removeButton': 'ODSTRANI',
  'candidateSearch.modalMainTitle': 'Save to project',
  'candidateSearch.modalTitleSecond': 'CREATE NEW PROJECT',
  'candidateSearch.textPlaceholder': 'Project name',
  'candidateSearch.saveOrSubButton': 'Shranite ali se naročite na iskanje',
  'candidateSearch.narrowResults': 'Dodatno filtriranje',
  'candidateSearch.sortFirst': 'Sortiraj po pomembnosti',
  'candidateSearch.sortSecond': 'Sortiraj po imenu',
  'candidateSearch.sortThird': 'Sortiraj po priimku',
  'candidateSearch.sortFourth': 'Sortiraj po datumu spremembe',
  'candidateSearch.removeModalTitle': 'Remove from project',
  'candidateSearch.removeModalPlaceholder': 'Find your project ...',
  'candidateSearch.locationPlaceholder': 'Vpiši lokacijo ...',
  'candidateSearch.locationLabel': 'Lokacija:',
  'candidateSearch.mainLabel': 'Ključne besede:',
  'candidateSearch.mainButton': 'Iskanje',
  'candidateSearch.personaDropdown': 'Področje dela',
  'candidateSearch.personaDropdownPlaceholder': 'Izberi področje dela',
  'candidateSearch.positionDropdown': 'Delovno mesto',
  'candidateSearch.regionsDropdown': 'Regija',
  'candidateSearch.regionsDropdownPlaceholder': 'Izberi regijo...',
  'candidateSearch.degreeDropdown': 'Stopnja izobrazbe',
  'candidateSearch.languageDropdown': 'Jezik',
  'candidateSearch.languageLvLDropdown': 'Nivo jezika',
  'candidateSearch.skillsDropdown': 'Znanja in spretnosti',
  'candidateSearch.skillsLvlDropdown': 'Nivo znanja',
  'candidateSearch.carierDropdown': 'Karierni status',
  'candidateSearch.carierLvlDropdown': 'Karierni nivo',
  'candidateSearch.assessmentsDropdown': 'Assessments',
  'candidateSearch.dLDropdown': 'Vozniško dovoljenje',
  'candidateSearch.mdrjbDropdown': 'Moje delo / RJB',
  'candidateSearch.currentFilter': 'Trenutno iskanje',
  'candidateSearch.clearForm': 'Počisti vsebino',
  'candidateSearch.minimizeSearch': 'Enostavno iskanje',
  'candidateSearch.maximizeSearch': 'Napredno iskanje',
  'candidateSearch.selectButton': 'Izberi vse',
  'candidateSearch.contactRequst': 'Pošlji prošnjo za stik',
  'candidateSearch.errorMsg': 'Napaka:',
  'candidateSearch.txtField': 'Žal vaše iskanje ni vrnilo rezultatov.',
  'candidateSearch.addProject': 'DODAJ V PROJEKT',
  'candidateSearch.noProject': 'Ni projekta za to iskanje',
  'candidateSearch.removeProject': 'Odstrani iz vseh projektov',
  'candidateSearch.createTitle': 'USTVARI NOV PROJEKT',
  'candidateSearch.projectName': 'Ime projekta',
  'candidateSearch.saveProject': 'Shrani v projekt',
  'candidateSearch.removeIndividuallProject': 'Odstrani iz projekta',
  'candidateSearch.findProject': 'Najdi projekt ...',
  'candidateSearch.careerStatus': 'Karierni status:',
  'candidateSearch.ageLabel': 'Starost:',
  'candidateSearch.availabilityLabel': 'Razpoložljivost:',
  'candidateSearch.contactButton': 'Kontakt',
  'candidateSearch.showMoreButton': 'Prikaži več ...',
  'candidateSearch.showLessButton': 'Prikaži manj ...',
  'candidateSearch.occupationFacet': 'Delovno mesto',
  'candidateSearch.languagesFacet': 'Jezik',
  'candidateSearch.languageLevelFacets': 'Nivo jezika',
  'candidateSearch.skillsFacet': 'Znanja in spretnosti',
  'candidateSearch.workExperienceFacet': 'Delovne izkušnje',
  'candidateSearch.drivingLicenseFacet': 'Vozniško dovoljenje',
  'candidateSearch.homeLocation': 'Lokacija:',
  'candidateSearch.topSkills': 'Najboljših 5 znanj in spretnosti:',
  'candidateSearch.skillLevelFacet': 'Nivo znanja',
  'candidateSearch.relevantYears': 'Relevantna leta delovnih izkušenj:',
  'candidateSearch.searchButton': 'Rezultati iskanja',
  'candidateSearch.addNoteButton': 'Dodajanje zapiskov',
  'candidateSearch.pendingRequest': 'Zahtevek na čakanju',
  'candidateSearch.contactRequestSentButton': 'Prošnja za stik poslana',
  'candidateSearch.contactRequestPopoverTitle': 'Prošnja za stik',
  'candidateSearch.contactRequestExpired': 'Že poslano, poteklo',
  'candidateSearch.contactRequestRejected': 'Že poslano, zavrnjeno',
  'candidateSearch.contactRequestRejectedWithoutDate': 'Že poslano, zavrnjeno',
  'candidateSearch.radiusButton': 'Radij',
  'candidateSearch.removeTitleFirstPart': 'Odstrani',
  'candidateSearch.removeTitleSecondPart': 'IZ PROJEKTA',
  'candidateSearch.saveTitleFirstPart': 'SHRANI',
  'candidateSearch.saveTitleSecondPart': 'v PROJEKT',
  'candidateSearch.typeLabel': 'Vpišite...',
  'candidateSearch.commentsTitle': ' Komentarji',
  'candidateSearch.messagesTite': 'Sporočila',
  'candidateSearch.submitButton': 'Shrani',
  'candidateSearch.commLabel': 'Trenutno ni komentarjev.',
  'candidateSearch.messageLabel': 'Trenutno ni sporočil.',
  'candidateSearch.careerStatusFacets': 'Karierni status',
  'candidateSearch.availabilityFacets': 'Razpoložljivost',
  'candidateSearch.currentJobCategoryFacets': 'Področje dela',
  'candidateSearch.educationLevelFacets': 'Stopnja izobrazbe',
  'candidateSearch.enterFirstName': 'Vnesite ime ...',
  'candidateSearch.enterLastName': 'Vnesite priimek ...',
  'candidateSearch.match': 'Ujemanje',
  'candidateSearch.markAsReviewedButton': 'Označi kot pregledano',
  'candidateSearch.markAsUnreviewedButton': 'Označi kot nepregledano',
  'candidateSearch.loadingCandidatesText': 'Iščemo najboljše ujemanje...',
  'candidateSearch.years': 'let',
  'candidateSearch.year': 'leto',
  'candidateSearch.myProjects': 'Moji projekti',
  'candidateSearch.findYourProject': 'Najdi projekt …',
  'candidateSearch.viewMoreProjects': 'Več projektov',
  'candidateSearch.editCVsubscriptions': 'Uredi CV opomnike',
  'candidateSearch.candidateSearchLink': 'Iskanje kandidatov',
  'candidateSearch.dashboardLink': 'Namizje',
  'candidateSearch.sendContactRequest': 'Pošlji prošnjo za stik',
  'candidateSearch.newCandidateBadgeFirstPart': 'NOV',
  'candidateSearch.newCandidateBadgeSecondPart': 'KANDIDAT',
  'candidateSearch.availableNowBadgeFirstPart': 'NA VOLJO',
  'candidateSearch.availableNowBadgeSecondPart': 'TAKOJ',
  'candidateSearch.findSimilarCandidate': 'Poišči podobne kandidate',
}
