import {PlacementRequestStatus} from 'Components/IncomingRequests/Constants'

export const isPlacementRejectedBySeeker = (status: string) =>
  status === PlacementRequestStatus.COMPANY_USER_PENDING_SEEKER_REJECTED ||
  status === PlacementRequestStatus.COMPANY_USER_CONFIRMED_SEEKER_REJECTED ||
  status === PlacementRequestStatus.COMPANY_USER_REJECTED_SEEKER_REJECTED

export const isPlacementRejectedByEmployer = (status: string) =>
  status === PlacementRequestStatus.COMPANY_USER_REJECTED_SEEKER_CONFIRMED ||
  status === PlacementRequestStatus.COMPANY_USER_REJECTED_SEEKER_PENDING ||
  status === PlacementRequestStatus.COMPANY_USER_REJECTED_SEEKER_REJECTED

export const isPlacementPendingByEmployer = (status: string) =>
  status === PlacementRequestStatus.COMPANY_USER_PENDING_SEEKER_CONFIRMED ||
  status === PlacementRequestStatus.COMPANY_USER_PENDING_SEEKER_PENDING ||
  status === PlacementRequestStatus.COMPANY_USER_PENDING_SEEKER_REJECTED

export const isPlacementPendingBySeeker = (status: string) =>
  status === PlacementRequestStatus.COMPANY_USER_PENDING_SEEKER_PENDING ||
  status === PlacementRequestStatus.COMPANY_USER_REJECTED_SEEKER_PENDING ||
  status === PlacementRequestStatus.COMPANY_USER_CONFIRMED_SEEKER_PENDING
