export const CandidateInfo = {
  'CandidateInfo.title': 'CHOOSE THE JOB YOU ',
  'CandidateInfo.titleYellow': 'DESERVE',
  'CandidateInfo.paragraphMain':
    "We've changed the rules of the game and it's time to put your career preferences and requirements first. Let the job find you,not the other way around.",
  'CandidateInfo.subtitle': 'Create a profile',
  'CandidateInfo.paragraphFirstPart':
    'This is your chance to say what you want and value in the workplace – it will take you less than 5 minutes.',
  'CandidateInfo.paragraphSecondPart':
    "Express your career preferences, interests and salary requirements independently of employers' offers. You can really focus on what is most important to you in your job search. And by being a candidate who is transparent about your preferences, you will only get relevant job opportunities.",
  'CandidateInfo.subtitleSecond': 'Support your knowledge',
  'CandidateInfo.paragraphSecond':
    'Complete a psychometric and cognitive questionnaire and upgrade your profile with results that assess your work competencies and ability to learn new skills.',
  'CandidateInfo.subtitleThird': 'Become visible and open to new experiences',
  'CandidateInfo.paragraphThird':
    'Employers will be able to access your anonymised profile and invite you for an interview.',
  'CandidateInfo.paragraphThirdTitle': 'You have complete control:',
  'CandidateInfo.paragraphThirdItemFirst':
    '• Your contact details are hidden from the recruiters',
  'CandidateInfo.paragraphThirdItemSecond':
    '• Your current employer does not see your profile',
  'CandidateInfo.paragraphThirdItemThird':
    '• Your profile is only visible if you are currently actively looking for a job',
  'CandidateInfo.subtitleFourth': 'Choose your opportunities',
  'CandidateInfo.paragraphFourth':
    'Edit your interviews and interview schedules in one place. Choose the companies you want to do the interview with and choose the one that suits you best.',
  'CandidateInfo.subtitleFifth': 'Save time',
  'CandidateInfo.paragraphFifth':
    'Preferenca also allows you to devote much less time to finding new employment than you are used to. When registering, an HR specialist is available to advise you on how to improve your profile and attract those employers you really want. Then, the employers need to convince you to work with them – all you need to decide is who to give an opportunity for an interview and whose invitation to reject.',
  'CandidateInfo.subtitleSixth': 'Check your value',
  'CandidateInfo.paragraphSixthFirstPart':
    'Preferenca is also for all of you who are not actively looking for a new job – based on the offers you will get on this portal, you can learn your value on the labour market and use it when negotiating for a higher salary with your current employer.',
  'CandidateInfo.paragraphSixthSecondPart':
    'The entire process – from creating a profile, communicating with employers and managing interviews to the interviews themselves – takes place online.',
  'CandidateInfo.yellowButton': 'FREE CAREER SUPPORT',
}
