import * as Yup from 'yup'
import {MediumTitle, MediumHTML} from 'Validations'

const Validation = Yup.object().shape({
  welcomeMessages: Yup.array().of(
    Yup.object().shape({
      id: Yup.string(),
      jobCategory: Yup.object().shape({
        id: Yup.string().required('Persona is required.'),
        translation: Yup.string(),
      }),
      translatedTexts: Yup.array().of(
        Yup.object().shape({
          id: Yup.string(),
          visibility: Yup.bool(),
          welcomeTitle: MediumTitle.required(
            'Welcome Message title is required.'
          ),
          welcomeText: MediumHTML,
          language: Yup.object().shape({
            id: Yup.string().required('Language is required'),
            name: Yup.string(),
          }),
        })
      ),
    })
  ),
})

export default Validation
