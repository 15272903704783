export const candidateDashboard = {
  'candidateDashboard.candidate': 'Candidate',
  'candidateDashboard.dashboard': 'Dashboard',
  'candidateDashboard.title': 'My Dashboard',
  'candidateDashboard.paragraphTitle':
    ' Great, you have completed the onboarding process!',
  'candidateDashboard.paragraphTitleTxt':
    ' Currently, you have no incoming requests. When an employer sends you a request, it will be shown here. In the meantime, you can:',

  'candidateDashboard.cvProfileLinkPart1': '- Check your ',
  'candidateDashboard.cvProfileLinkPart2': 'CV profile.',
  'candidateDashboard.cvProfileLinkPart3':
    ' We parsed the data from your CV, but suggest you go through it once again, to see if all’s fine.',

  'candidateDashboard.publicProfileLinkPart1': '- Edit your ',
  'candidateDashboard.publicProfileLinkPart2': 'public profile.',
  'candidateDashboard.publicProfileLinkPart3':
    ' Employers will only be able to see your public profile until you accept their contact request.',

  'candidateDashboard.psychometricTestLinkPart1': '- Test your ',
  'candidateDashboard.psychometricTestLinkPart2': 'Psychometric',
  'candidateDashboard.psychometricTestLinkPart3': ' and ',
  'candidateDashboard.cognitiveTestLinkPart1': 'Cognitive',
  'candidateDashboard.cognitiveTestLinkPart2': ' skills.',

  'candidateDashboard.paragraphLast1':
    '- If you haven’t yet, send your profile to our HR experts, so they can review it. You can do it at the bottom of',
  'candidateDashboard.paragraphLast2': ' this',
  'candidateDashboard.paragraphLast3': ' page.',

  'candidateDashboard.headerTitle': 'Hi',
  'candidateDashboard.headerTitleTxt': ', welcome back!',
  'candidateDashboard.headerCv': 'Cv profile completeness:',
  'candidateDashboard.prequalification': 'Prequalification',
  'candidateDashboard.scouting': 'Scouting',
  'candidateDashboard.interviewing': 'Interviewing',
  'candidateDashboard.negotiations': 'Negotiations',
  'candidateDashboard.placement': 'Placement',
  'candidateDashboard.placementSecond': 'Placement',
  'candidateDashboard.placementDetails': 'Placement details',
  'candidateDashboard.requestStatus': 'Request status',
  'candidateDashboard.requestType': 'Request type',
  'candidateDashboard.dateOfRequestSent': 'Date of request sent',
  'candidateDashboard.placementApproveAreYouSure':
    'Are you sure you want to confirm placement with  company ',
  'candidateDashboard.placementApproveConfirmingThePlacement':
    'Confirming the placement means your profile will not be visible to other employers for the next two years.  ',
  'candidateDashboard.confirmPlacement': 'Confirm placement',
  'candidateDashboard.placementRejectAreYouSure':
    'Are you sure you want to reject placement with company',
  'candidateDashboard.placementRejectYouWillMiss':
    'You will miss the opportunity to be placed with this company.',
  'candidateDashboard.rejectPlacement': 'Reject placement',
  'candidateDashboard.reject': 'Reject',
  'candidateDashboard.confirm': 'Confirm',
  'candidateDashboard.expirationDate': 'Expiration date',
  'candidateDashboard.placementWeAreHappyPart1':
    'We’re happy to see you’ve decided to join ',
  'candidateDashboard.placementWeAreHappyPart2':
    ' and we wish you a lot of success in your new position. ',
  'candidateDashboard.placementWeAreHappyPart3':
    'With a click on the Confirm placement button below, please confirm that your hiring process has been completed.',
  'candidateDashboard.myIncomingRequests': 'My incoming requests',
  'candidateDashboard.viewAllRequests': 'View all requests',
  'candidateDashboard.minifeed': 'Minifeed',
  'candidateDashboard.thereAreStillNoItems': 'There are still no items in feed',
  'candidateDashboard.loading': 'loading...',
  'candidateDashboard.action': 'ACTION',
  'candidateDashboard.company': 'Company',
  'candidateDashboard.viewDetails': 'View details',
  'candidateDashboard.status': 'STATUS',
  'candidateDashboard.expire': 'EXPIRE',
  'candidateDashboard.hasRejectedYourContactR':
    ' has rejected your contact request.',
  'candidateDashboard.hasAcceptedYourInterviewI':
    ' has accepted your Interview invitation.',
  'candidateDashboard.hasRejectedYourInvitationToV':
    ' has rejected your Invitation to Video interview.',
  'candidateDashboard.theCompany': 'The Company ',
  'candidateDashboard.hasViewedYourR': ' has viewed your resume.',
  'candidateDashboard.viewCompany': 'View company',
  'candidateDashboard.view': 'View',
  'candidateDashboard.pending': 'Pending',
  'candidateDashboard.accepted': 'Accepted',
  'candidateDashboard.rejected': 'Rejected',
  'candidateDashboard.approved': 'Approved',
  'candidateDashboard.expired': 'Expired',
  'candidateDashboard.completed': 'Completed',
  'candidateDashboard.cancelled': 'Cancelled',
  'candidateDashboard.notAttended': 'Not attended',
  'candidateDashboard.extended': 'Extended',
  'candidateDashboard.available': 'Available',
  'candidateDashboard.unavailable': 'Unavailable',
  'candidateDashboard.interviewConfirmed': 'Accepted',
  'candidateDashboard.pendingExpiring': 'Pending Expiring',
  'candidateDashboard.newOfferProposed': 'New Offer Proposed',
  'candidateDashboard.waitingForBoth': 'Waiting for both',
  'candidateDashboard.rejectedByYou': 'Rejected by you',
  'candidateDashboard.waitingForEmployer': 'Waiting for employer',
  'candidateDashboard.waitingForYou': 'Waiting for your action',
  'candidateDashboard.waitingForCandidate': 'Waiting for candidate',
  'candidateDashboard.confirmedByBothSides': 'Confirmed by both sides',
  'candidateDashboard.rejectedByEmployer': 'Rejected by employer',
  'candidateDashboard.rejectedByBoth': 'Rejected by both',
  'candidateDashboard.rejectedByCandidate': 'Rejected by candidate',
  'candidateDashboard.newTimeProposed': 'New time proposed',
  'candidateDashboard.newTimeProposal': 'New time proposal',
  'candidateDashboard.placementConfirmed': 'Placement confirmed',
  'candidateDashboard.placementRejected': 'Placement rejected',
  'candidateDashboard.contactRequest': 'Contact request',
  'candidateDashboard.offer': 'Offer',
  'candidateDashboard.offerProposal': 'Offer proposal',
  'candidateDashboard.interviewInvitation': 'Interview invitation',
  'candidateDashboard.interviewInvitationDetails':
    'Interview invitation details',
  'candidateDashboard.placementRequest': 'Placement request',
  'candidateDashboard.placementConfirmation': 'Placement confirmation',
  'candidateDashboard.approve': 'Approve',
  'candidateDashboard.accept': 'Accept',
  'candidateDashboard.proposeNewTime': 'Propose new time',
  'candidateDashboard.in': 'in',
  'candidateDashboard.day': 'day',
  'candidateDashboard.days': 'days',
  'candidateDashboard.successfullyPlaced': 'Successfully placed',
  'candidateDashboard.offerDetails': 'Offer details',
  'candidateDashboard.content': 'Content',
  'candidateDashboard.dear': 'Dear',
  'candidateDashboard.thankYou': 'Thank you',
  'candidateDashboard.attachment': 'Attachment',
  'candidateDashboard.modalOfferWeSendYouOurP':
    ' We send you our preliminary offer to your email address. We kindly ask you to review it it and decide whether to accept or reject it.',
  'candidateDashboard.modalApproveOfferAreYouSure':
    '  Are you sure you want to accept recieved offer from company',
  'candidateDashboard.modalApproveOfferAcceptingTheO':
    'Accepting the offer means that you agree with the offered working conditions and thus give the company the initiative to prepare an employment contract.',
  'candidateDashboard.acceptOfferRequest': 'Accept offer request',
  'candidateDashboard.rejectOfferrequest': 'Reject offer request',
  'candidateDashboard.modalRejectAreYouSure':
    'Are you sure you want to reject recieved offer from company',
  'candidateDashboard.modalRejectOfferYouWillMiss':
    'You will miss the opportunity to get in touch with this company',
  'candidateDashboard.modalPrposeNewTimePleaseEnterMessage':
    'Please enter message to employer',
  'candidateDashboard.modalPrposeNewTimEnterMessage':
    'Enter message, proposing new time to employer.',
  'candidateDashboard.message': 'Message',
  'candidateDashboard.modalContactAprroveAreYouSure':
    'Are you sure you want to approve contact request from company',
  'candidateDashboard.modalContactAprroveWhitThisApprove':
    'With this approve, you give employer consent to view your full CV profile, including psychometric, cognitive and other tests results.',
  'candidateDashboard.modalRejectContactAreYouSure':
    'Are you sure you want to reject contact request from company',
  'candidateDashboard.modalRejectContactYouWillMiss':
    'You will miss the opportunity to get in touch with this company.',
  'candidateDashboard.modalApproveContactAreYouSure':
    'Are you sure you want to approve contact request from company ',
  'candidateDashboard.modalApproveContactWhitThis':
    'With this approve, you give employer consent to view your full CV profile, including psychometric, cognitive and other tests results.',
  'candidateDashboard.approveContactRequest': 'Approve contact request',
  'candidateDashboard.rejectContactRequest': 'Reject contact request',
  'candidateDashboard.time': 'time',
  'candidateDashboard.InterviewDate': 'Interview date',
  'candidateDashboard.modalInterviewInvRejectAreYouSure':
    'Are you sure you want to reject video interview invitation from company ',
  'candidateDashboard.modalInterviewInvRejectYouWillMiss':
    'You will miss the opportunity to get in touch with this company',
  'candidateDashboard.modalInterviewInvRejectEnterYourReason':
    "Enter rejection reason. Example: I'm not interested ... (Optional)",
  'candidateDashboard.completeness': 'Profile completeness:',
  'candidateDashboard.completenessProfilePicture': 'Profile picture',
  'candidateDashboard.completenessFirstName': 'First name',
  'candidateDashboard.completenessLastName': 'Last name',
  'candidateDashboard.completenessDateOfBirth': 'Date of birth',
  'candidateDashboard.completenessAboutMe': 'About me (description)',
  'candidateDashboard.completenessStreetAddress': 'Street address',
  'candidateDashboard.completenessPostalCode': 'Postal code',
  'candidateDashboard.completenessCity': 'City',
  'candidateDashboard.completenessCountry': 'Country',
  'candidateDashboard.completenessWorkExperiences': 'Work experiences',
  'candidateDashboard.completenessSkills': 'Skills',
  'candidateDashboard.completenessEducation': 'Education',
  'candidateDashboard.completenessPsychometricTest': 'Psychometric test',
  'candidateDashboard.completenessCognitiveTest': 'Cognitive test',
  'candidateDashboard.completenessDrivingLicence': 'Driving licence',
  'candidateDashboard.completenessJobPrefs': 'Job preferences',
  'candidateDashboard.allRequests': 'All requests',
  'candidateDashboard.search': 'Search',
  'candidateDashboard.searchCompany': 'Search company...',
  'candidateDashboard.modalContactRequestTitle': 'Request details',
  'candidateDashboard.submit': 'Submit',
  'candidateDashboard.startVideoInterview': 'Start Video Interview',
  'candidateDashboard.rejectionDate': 'Rejection date',
  'candidateDashboard.expiresToday': 'Expires today',
  'candidateDashboard.cancelConfirmation1':
    'Are you sure you want to cancel video interview with company ',
  'candidateDashboard.cancelConfirmation2':
    'You will miss the opportunity to get in touch with this company.',
}
