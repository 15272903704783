export const myAccount = {
  'myAccount.myAccount': 'Moj račun',
  'myAccount.personalData': 'Osebni podatki',
  'myAccount.uploadPhoto': 'Naloži fotografijo',
  'myAccount.day': 'Dan',
  'myAccount.month': 'Mesec',
  'myAccount.year': 'Leto',
  'myAccount.birthdate': 'Datum rojstva',
  'myAccount.streetAddress': 'Naslov',
  'myAccount.postalCode': 'Poštna številka',
  'myAccount.region': 'Regija',
  'myAccount.contactData': 'Kontaktni podatki',
}
