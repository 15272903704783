import * as Yup from 'yup'

export const Validation = Yup.object({
  careerLevel: Yup.object().nullable().required('Career level is required'),
  desiredRegions: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        translation: Yup.string(),
      })
    )
    .min(1, 'Desired regions are required'),
  desiredOccupations: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        translation: Yup.string(),
      })
    )
    .min(1, 'Desired job position is required'),
  desiredEmploymentTypes: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.string(),
        translation: Yup.string(),
      })
    )
    .min(1, 'Desired employment is required'),
  desiredSalary: Yup.object().nullable().required('Desired salary is required'),
  // currentSalary: Yup.object().nullable().required('Current salary is required'),
})
