import {FC} from 'react'

import styles from './AreYouSureModal.module.scss'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import Modal from 'Components/Modal'

import {OnboardingTranslations as onb} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import {Warning, Error} from '@mui/icons-material'

interface AreYouSureModalProps {
  modalOpen: boolean
  setModalOpen: (v: boolean) => void
  handleModalSubmit: () => void
  handleModalCancel: () => void
  text?: string
  submitText?: string
  cancelText?: string
}

export const AreYouSureModal: FC<AreYouSureModalProps> = ({
  modalOpen,
  setModalOpen,
  handleModalSubmit,
  handleModalCancel,
  text = onb.descriptionAreYouSureModal,
  submitText = onb.modalDiscard,
  cancelText = onb.modalSaveChanges,
}) => {
  return (
    <Modal
      isOpen={modalOpen}
      title={onb.titleAreYouSureModal}
      theme="error"
      icon={Warning}
      className={styles.modal}
      Footer={
        <SubmitFooter
          onSubmit={handleModalSubmit}
          onCancel={handleModalCancel}
          submitText={submitText}
          cancelText={cancelText}
          theme="spaceBetween"
        />
      }
      onAfterClose={() => {
        setModalOpen(false)
      }}
    >
      <div className={styles.modalContent}>
        <div className={styles.icon}>
          <Error sx={{fontSize: 40}} />
        </div>
        <div className={styles.text}>
          <FormattedMessage id={text} />
        </div>
      </div>
    </Modal>
  )
}
