import {FC} from 'react'
import {
  WelcomeMessageDataFormikType,
  TaxonomiesStateType,
  ErrorType,
  WelcomeMessageDataType,
  AuthStateType,
  BaseTaxonomyType,
} from 'Interfaces'
import {WelcomeMessageEditable} from './WelcomeMessageEditable'
import {WelcomeMessagePublic} from './WelcomeMessagePublic'

interface WelcomeMessageProps {
  addWelcomeMessage: (value: WelcomeMessageDataFormikType) => void
  editWelcomeMessage: (value: WelcomeMessageDataFormikType) => void
  getTaxonomies: () => void
  getCompanyData: () => void
  getSeekerProfile: () => void
  auth: AuthStateType
  seekerJobCategory?: BaseTaxonomyType
  taxonomies: TaxonomiesStateType
  welcomeMessages: WelcomeMessageDataType[]
  canChange?: boolean
  apiError?: ErrorType
}

export const WelcomeMessage: FC<WelcomeMessageProps> = ({
  addWelcomeMessage,
  editWelcomeMessage,
  getTaxonomies,
  getCompanyData,
  getSeekerProfile,
  auth,
  seekerJobCategory,
  taxonomies,
  welcomeMessages,
  canChange,
}: WelcomeMessageProps) => {
  const canEdit = auth.data?.type === 'company_user' && canChange

  if (canEdit)
    return (
      <WelcomeMessageEditable
        addWelcomeMessage={addWelcomeMessage}
        editWelcomeMessage={editWelcomeMessage}
        getTaxonomies={getTaxonomies}
        getCompanyData={getCompanyData}
        auth={auth}
        seekerJobCategory={seekerJobCategory}
        taxonomies={taxonomies}
        welcomeMessages={welcomeMessages}
      />
    )

  return (
    <WelcomeMessagePublic
      getTaxonomies={getTaxonomies}
      getCompanyData={getCompanyData}
      getSeekerProfile={getSeekerProfile}
      auth={auth}
      seekerJobCategory={seekerJobCategory}
      taxonomies={taxonomies}
      welcomeMessages={welcomeMessages}
    />
  )
}
