import {FC} from 'react'
import classnames from 'classnames'
import styles from './HrWithIcon.module.scss'
import {OverridableComponent} from '@mui/material/OverridableComponent'
import {SvgIconTypeMap} from '@mui/material'

interface HrProps {
  className?: string
  theme?: string
  icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string
  }
}

export const HrWithIcon: FC<HrProps> = ({className, theme = 'hr', icon}) => {
  const HrIcon = icon

  return (
    <div className={styles.hrWrap}>
      <hr
        className={classnames(styles.hr, className, {
          [styles[theme]]: theme,
        })}
      />
      <div className={styles.iconWrap}>
        <HrIcon />
      </div>
    </div>
  )
}
