import {FC} from 'react'

import {ConsentItemContainerProps, ConsentType} from 'Interfaces/ConsentsType'
import {ConsentVersion} from '../ConsentVersion'

enum ConsentID {
  Newsletter = 'bda2aa8f-7b9b-4920-84dd-3229cb4ed6ad',
}

export const isNewsletter = (consentId: string) => {
  return consentId === ConsentID.Newsletter
}

export const ConsentItemContainer: FC<ConsentItemContainerProps> = ({
  type,
  className,
  consentsArray,
}) => {
  const renderConsents = () => {
    return (
      <div>
        {consentsArray?.map((consent: ConsentType, index) => {
          return (
            <ConsentVersion
              key={index}
              consentVersion={consent.consentVersions[0]}
              type={type}
              className={className}
              consentId={consent.id}
              consentCategory={consent.consentCategory.id}
            />
          )
        })}
      </div>
    )
  }

  return <div>{renderConsents()}</div>
}
