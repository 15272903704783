import {connect} from 'react-redux'

import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {RateCandidate} from './RateCandidate'
import {
  addMyProjectCandidateEvaluation,
  editMyProjectCandidateEvaluation,
  deleteMyProjectCandidateEvaluation
} from 'Store/Actions'
import {SeekerEvaluationType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  company: state.company,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addSeekerEvaluation: (seekerEvaluation: SeekerEvaluationType) =>
    dispatch(addMyProjectCandidateEvaluation(seekerEvaluation)),
  editSeekerEvaluation: (seekerEvaluation: SeekerEvaluationType) =>
    dispatch(editMyProjectCandidateEvaluation(seekerEvaluation)),
  deleteSeekerEvaluation: (seekerEvaluation: SeekerEvaluationType) =>
    dispatch(deleteMyProjectCandidateEvaluation(seekerEvaluation)),
})

export const RateCandidateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RateCandidate)
