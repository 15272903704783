import {companyHeader} from './CompanyHeader/en'
import {companyWelcomeMessage} from './CompanyWelcomeMessage/en'
import {companyDescription} from './CompanyDescription/en'
import {companyBenefits} from './CompanyBenefits/en'
import {companyImpressions} from './CompanyImpressions/en'
import {companyEmploymentInformation} from './CompanyEmploymentInformation/en'
import {companyPortfolio} from './CompanyPortfolio/en'
import {companyVideo} from './CompanyVideo/en'
import {companyFaq} from './CompanyFaq/en'

export const company = {
  ...companyHeader,
  ...companyWelcomeMessage,
  ...companyDescription,
  ...companyBenefits,
  ...companyImpressions,
  ...companyEmploymentInformation,
  ...companyPortfolio,
  ...companyVideo,
  ...companyFaq,
}
