import {FC, useState} from 'react'
import StarIcon from '@mui/icons-material/StarRate'
import {
  Candidate,
  IncomingRequestSeekerType,
  SeekerEvaluationType,
} from 'Interfaces'
import Rating from '@mui/material/Rating'
import styles from './RateCandidate.module.scss'
import {SidebarTranslations as st} from 'Services/I18n/Constants'
import {useTranslation} from 'Hooks'

interface RateCandidateProps {
  addSeekerEvaluation: (seekerEvaluation: SeekerEvaluationType) => void
  editSeekerEvaluation: (seekerEvaluation: SeekerEvaluationType) => void
  candidateBasicData: Candidate | IncomingRequestSeekerType | null
  seekerEvaluation: SeekerEvaluationType[]
}

export const RateCandidate: FC<RateCandidateProps> = ({
  candidateBasicData,
  seekerEvaluation,
  addSeekerEvaluation,
  editSeekerEvaluation,
}) => {
  const [_, setHover] = useState(-1)
  const translation = useTranslation()

  return (
    <div className={styles.rateCandidate}>
      <p>{translation[st.rate]}</p>
      <div className={styles.starsContainer}>
        <Rating
          name="hover-feedback"
          value={(seekerEvaluation && seekerEvaluation[0]?.evaluation) || null}
          onChange={(_, newValue: number | null | undefined) => {
            if (!seekerEvaluation[0]?.evaluation && newValue) {
              addSeekerEvaluation({
                seekerUserId: candidateBasicData?.id as string,
                evaluation: newValue,
              })
            } else if (
              seekerEvaluation[0].evaluation &&
              newValue &&
              newValue > 0
            ) {
              editSeekerEvaluation({
                seekerUserId: candidateBasicData?.id,
                evaluation: newValue,
              })
            }
          }}
          onChangeActive={(_, newHover) => {
            setHover(newHover)
          }}
          emptyIcon={<StarIcon className={styles.grayStarIcon} />}
        />
      </div>
    </div>
  )
}
