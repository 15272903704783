import {FC, ReactNode, useState} from 'react'
import styles from './PlacementsListItem.module.scss'
import {IncomingRequestType} from 'Interfaces'
import classNames from 'classnames'
import {formatFullDate} from 'Utils/Date'
import RequestActions from './RequestActions'
import {ExpirationValue} from './RequestValues'
import {
  CombinedRequestStatues,
  employerRequestStatusTranslationMap,
  RequestType,
} from 'Components/IncomingRequests/Constants'
import {SeekerProfile} from './SeekerProfile'
import {FormattedMessage} from 'react-intl'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'

const ItemDetail: FC<{
  label?: string
  value: ReactNode
  className?: string
}> = ({label, value, className}) => {
  return (
    <div className={`${styles.detailWrap} ${className}`}>
      {label && label !== edt.candidate && (
        <div className={styles.label}>
          <FormattedMessage id={label} />
        </div>
      )}
      {value}
    </div>
  )
}

// TODO candidate-dashboard: centralize request colors?
const statusColors = {
  [CombinedRequestStatues.AVAILABLE]: {color: styles.greenText},
  [CombinedRequestStatues.UNAVAILABLE]: {color: styles.pinkText},
  [CombinedRequestStatues.INTERVIEW_CONFIRMED]: {color: styles.greenText},
  [CombinedRequestStatues.ACCEPTED]: {color: styles.greenText},
  [CombinedRequestStatues.PENDING]: {color: styles.yellowText},
  [CombinedRequestStatues.PENDING_EXPIRING]: {color: styles.yellowText},
  [CombinedRequestStatues.NEW_TIME_PROPOSED]: {color: styles.yellowText},
  [CombinedRequestStatues.REJECTED]: {color: styles.redCinnabarText},
  [CombinedRequestStatues.EXPIRED]: {color: styles.expired},
  [CombinedRequestStatues.COMPLETED]: {color: styles.greenText},
  [CombinedRequestStatues.NEW_OFFER_PROPOSED]: {color: styles.yellowText},
  [CombinedRequestStatues.CANCELLED]: {color: styles.redCinnabarText},
  [CombinedRequestStatues.APPROVED]: {color: styles.greenText},
  [CombinedRequestStatues.EXTENDED]: {color: styles.greenText},

  [CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_PENDING]: {
    color: styles.purpleText,
  },
  [CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_REJECTED]: {
    color: styles.red2Text,
  },
  [CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_CONFIRMED]: {
    color: styles.purpleText,
  },
  [CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_PENDING]: {
    color: styles.purpleText,
  },
  [CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_REJECTED]: {
    color: styles.red2Text,
  },
  [CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_CONFIRMED]: {
    color: styles.green2Text,
  },
  [CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_PENDING]: {
    color: styles.red2Text,
  },
  [CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_REJECTED]: {
    color: styles.red2Text,
  },
  [CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_CONFIRMED]: {
    color: styles.red2Text,
  },
}

export type PlacementsDisplayType = 'all' | 'pending' | 'confirmed'
type PlacementsListItemProps = {
  request: IncomingRequestType
  displayType: PlacementsDisplayType
  setSidebarClosed: (sidebarClosed: boolean) => void
  setSelectedRequestWidget: (request: IncomingRequestType) => void
  setIsModalOpen: (v: boolean) => void
}

export const PlacementsListItem: FC<PlacementsListItemProps> = ({
  request,
  displayType = 'all',
  setSidebarClosed,
  setSelectedRequestWidget,
  setIsModalOpen,
}) => {
  const [isCandidateSelected, setIsCandidateSelected] = useState(false)

  const isConfirmedByBoth =
    request.status ===
    CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_CONFIRMED

  const showNoExperationDate =
    request.status ===
      CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_CONFIRMED ||
    request.status ===
      CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_REJECTED ||
    request.status ===
      CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_REJECTED ||
    request.status ===
      CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_REJECTED

  return (
    <div
      className={classNames({
        [styles.selectedCandidateRow]: isCandidateSelected,
        [styles.greenBackground]: isConfirmedByBoth,
      })}
      onClick={() => {
        setSidebarClosed(false)
        setSelectedRequestWidget({
          ...request,
          type: RequestType.PLACEMENT_REQUEST,
        })
        setIsCandidateSelected(true)
      }}
      tabIndex={0}
      onBlur={() => {
        setIsCandidateSelected(false)
      }}
    >
      <div
        className={classNames(styles.requestItemWrap, 'requestItemWrap', {
          [styles.all]: displayType === 'all' || displayType === 'pending',
          [styles.candidateRow]: !isCandidateSelected,
        })}
      >
        <ItemDetail
          label={edt.candidate}
          value={
            <SeekerProfile
              seeker={request.seeker}
              setIsModalOpen={setIsModalOpen}
            />
          }
          className={styles.flexContainer}
        />
        <ItemDetail
          label={edt.status}
          value={
            <div
              className={classNames(
                styles.statusValue,
                statusColors[request.status].color
              )}
            >
              <FormattedMessage
                id={employerRequestStatusTranslationMap[request.status]}
              />
            </div>
          }
        />
        {(displayType === 'all' || displayType === 'pending') &&
          (request.expirationDate && !showNoExperationDate ? (
            <ItemDetail
              label={edt.expire}
              value={<ExpirationValue date={request.expirationDate} />}
            />
          ) : (
            <div />
          ))}
        {/* TODO: Change date */}
        {displayType === 'confirmed' &&
          (request.confirmedBySeekerDate ? (
            <ItemDetail
              label={edt.candidatesConfirmation}
              value={
                <div className={styles.date}>
                  {formatFullDate(request.confirmedBySeekerDate)}
                </div>
              }
            />
          ) : (
            <div />
          ))}
        {displayType === 'confirmed' &&
          (request.confirmedByCompanyUserDate ? (
            <ItemDetail
              label={edt.yourConfirmation}
              value={
                <div className={styles.date}>
                  {formatFullDate(request.confirmedByCompanyUserDate)}
                </div>
              }
            />
          ) : (
            <div />
          ))}
        <ItemDetail
          className={styles.justifyEnd}
          value={
            <RequestActions
              request={{...request, type: RequestType.PLACEMENT_REQUEST}}
            />
          }
        />
      </div>
    </div>
  )
}
