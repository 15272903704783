import {companyHeader} from './CompanyHeader/sl'
import {companyWelcomeMessage} from './CompanyWelcomeMessage/sl'
import {companyDescription} from './CompanyDescription/sl'
import {companyBenefits} from './CompanyBenefits/sl'
import {companyImpressions} from './CompanyImpressions/sl'
import {companyEmploymentInformation} from './CompanyEmploymentInformation/sl'
import {companyPortfolio} from './CompanyPortfolio/sl'
import {companyVideo} from './CompanyVideo/sl'
import {companyFaq} from './CompanyFaq/sl'

export const company = {
  ...companyHeader,
  ...companyWelcomeMessage,
  ...companyDescription,
  ...companyBenefits,
  ...companyImpressions,
  ...companyEmploymentInformation,
  ...companyPortfolio,
  ...companyVideo,
  ...companyFaq,
}
