import {FC, useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {OnboardingTranslations as onb} from 'Services/I18n/Constants'

import styles from './YourCV.module.scss'
import {
  BaseTaxonomyType,
  DataType,
  OnboardingStateType,
  SaveStepUploadCvType,
} from 'Interfaces'
import AttachmentFileUpload from 'Components/Inputs/AttachmentFileUpload'
import Hr from 'Components/Hr'
import YellowButton from 'Components/YellowButton'
import Title from 'Components/Title'
import {useApiError} from 'Hooks'
import {FormattedMessage} from 'react-intl'
import {LoadingModal} from 'Components/LoadingModal'
import Modal, {SubmitFooter} from 'Components/Modal'
import {Warning} from '@mui/icons-material'
import TypeAheadImproved from 'Components/Inputs/TypeAheadImproved'
import {getTranslation} from 'Services/I18n/Utils'
import TextError from 'Components/Error/TextError'

interface YourCvProps {
  onboarding: OnboardingStateType
  jobCategories: DataType<BaseTaxonomyType>
  getJobCategories: () => void
  saveStepUploadCV: (values: SaveStepUploadCvType) => void
  clearOnboardingErrors: () => void
}

interface YourCvFormikType {
  jobCategory?: BaseTaxonomyType | null
  file?: {
    id: string
    path: string
  }
  apiError?: null | string
}

export const YourCV: FC<YourCvProps> = ({
  onboarding,
  jobCategories,
  getJobCategories,
  saveStepUploadCV,
  clearOnboardingErrors,
}) => {
  const [isContinueModalOpen, setIsContinueModalOpen] = useState(false)
  const [removeAttachmentFile, setRemoveAttachmentFile] = useState(false)
  const validate = Yup.object({
    jobCategory: Yup.object().required('onboarding.jobCategory.required'),
  })

  useEffect(() => {
    getJobCategories()
  }, [])

  const formik = useFormik<YourCvFormikType>({
    initialValues: {
      jobCategory: onboarding.data?.jobCategory,
      file: onboarding.data?.file,
    },
    validationSchema: validate,
    onSubmit: (values) => {
      saveStepUploadCV({
        jobCategoryId: values?.jobCategory?.id as string,
        uploadFileId: values.file?.id || undefined,
      })
    },
  })

  useEffect(() => {
    formik.setValues({
      jobCategory: onboarding.data?.jobCategory as BaseTaxonomyType,
      file: onboarding.data?.file,
    })
  }, [onboarding.data])

  useEffect(() => {
    if (onboarding.error && onboarding.error.statusCode === 422) {
      setIsContinueModalOpen(true)
    } else {
      setIsContinueModalOpen(false)
    }
  }, [onboarding.error])

  useApiError(formik.setFieldError, onboarding.error, 'apiError')

  return (
    <div className={styles.container}>
      <LoadingModal isOpen={onboarding.loading} />
      <form onSubmit={formik.handleSubmit}>
        <Title
          text={onb.titleOccupationalFields}
          weight={400}
          className={styles.title}
        />
        <TypeAheadImproved
          className={styles.typeahead}
          name="jobCategory"
          defaultValue={[]}
          theme="white"
          items={jobCategories.data || []}
          maxNumSelections={1}
          placeholder={getTranslation('onboarding.typeAheadTypeYour')}
          setValue={(name, values) => {
            formik.setFieldValue(name, values[0])
          }}
          selectNonExisting={false}
          invalidNonExisting={undefined}
          setTouched={formik.getFieldHelpers('jobCategory').setTouched}
          error={
            formik.touched.jobCategory && formik.errors.jobCategory
              ? formik.errors.jobCategory
              : ''
          }
        />
        <Hr />
        <Title text={onb.titleUploadCV} weight={400} className={styles.title} />
        <p className={styles.disclaimerText}>
          <FormattedMessage id={onb.cvDisclaimer} />
        </p>
        <div className={styles.line}>
          <AttachmentFileUpload
            name="file"
            setValue={formik.setFieldValue}
            removeAttachmentFile={removeAttachmentFile}
            setRemoveAttachmentFile={setRemoveAttachmentFile}
            theme="white"
            integratedUploads={false}
            accept=".doc,.docx,.pdf"
          />
        </div>
        <Hr />
        {formik.errors.apiError && <TextError text={formik.errors.apiError} />}
        <div className={styles.buttonWrap}>
          <div className={styles.nextButton}>
            <YellowButton text={onb.buttonNext} />
          </div>
        </div>
      </form>
      <Modal
        isOpen={isContinueModalOpen}
        onAfterClose={() => {
          setIsContinueModalOpen(false)
        }}
        title={onb.cvParsing}
        ariaHideApp={false}
        icon={Warning}
        theme="error"
        Footer={
          <SubmitFooter
            submitText={onb.tryAgain}
            onSubmit={() => {
              clearOnboardingErrors()
              setRemoveAttachmentFile(true)
              formik.setFieldValue('file', undefined)
              setIsContinueModalOpen(false)
            }}
            cancelText={onb.continueWithoutParsedCV}
            onCancel={() => {
              saveStepUploadCV({
                jobCategoryId: formik.values?.jobCategory?.id as string,
                uploadFileId: undefined,
              })
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        {onboarding.error && onboarding.error.message && (
          <div className={styles.modalErrorText}>
            <FormattedMessage id={onb.apiErrorMessage} />
          </div>
        )}
      </Modal>
    </div>
  )
}
