import Hr from 'Components/Hr'
import MainLayout from 'Layouts/MainLayout'
import {FC, useEffect, useMemo, useState} from 'react'
import styles from './EmployerBlacklist.module.scss'
import Dropdown from 'Components/Inputs/Dropdown'
import triglavLogo from 'Assets/Images/candidate-dashboard/triglav-logo.jpg'
import {
  BlacklistedCompanyItemType,
  BlacklistStateType,
} from 'Interfaces/BlacklistType'
import SectionItem from 'Components/Profile/SectionItem'
import Modal, {SubmitFooter} from 'Components/Modal'
import {Delete, Warning} from '@mui/icons-material'
import {BlacklistTranslations as bt} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import Title from 'Components/Title'

type EmployerBlacklistProps = {
  getData: () => void
  createBlacklistItem: (companyId: string) => void
  deleteBlacklistItem: (id: string) => void
  blacklist: BlacklistStateType
}

export const EmployerBlacklist: FC<EmployerBlacklistProps> = ({
  getData,
  createBlacklistItem,
  deleteBlacklistItem,
  blacklist,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [companyToRemove, setCompanyToRemove] =
    useState<BlacklistedCompanyItemType | null>(null)
  useEffect(() => {
    getData()
  }, [])

  const dropdownOptions = useMemo(() => {
    if (blacklist.dropdownItems) {
      const allOptions = blacklist.dropdownItems.map((item) => {
        let title = item.name

        if (item.town) title += ', ' + item.town.translation

        return {
          id: item.id,
          translation: title,
        }
      })
      const blacklistedItems = blacklist?.data
      if (blacklistedItems && blacklistedItems.length > 0) {
        return allOptions.filter(
          (option) =>
            !blacklistedItems.some((item) => item.company.id === option.id)
        )
      }
      return allOptions
    } else {
      return []
    }
  }, [blacklist.dropdownItems, blacklist?.data])

  const addCompanyToBlacklist = (
    _props: string,
    value?: string | number | undefined
  ) => {
    createBlacklistItem(value as string)
  }

  const removeCompanyFromBlacklist = () => {
    if (companyToRemove) deleteBlacklistItem(companyToRemove.id)
  }

  return (
    <MainLayout>
      <div className={styles.pageWrap}>
        <div className={styles.mainTitle}>
          <FormattedMessage id={bt.title} />
        </div>

        <Hr className={styles.title} />

        <Title text={bt.titleBlockedCompanies} className={styles.title} />
        <SectionItem className={styles.sectionItem}>
          <div className={styles.content}>
            <div className={styles.info}>
              <FormattedMessage id={bt.descriptionBlockedCompanies} />
            </div>
            <Hr theme="light" />
            <Dropdown
              name="companies"
              emptyValueLabel={bt.enterCompanyName}
              items={dropdownOptions}
              setValue={addCompanyToBlacklist}
            />
          </div>

          <div className={styles.itemsWrap}>
            {blacklist.data && blacklist.data.length > 0 ? (
              blacklist.data.map((blacklistItem, idx) => (
                <div key={`blacklist-item-${idx}`} className={styles.itemWrap}>
                  <div className={styles.companyLogo}>
                    {/* TODO: use this when serving files gets implemented on backend */}
                    {/* {blacklistItem.company.logotype ? (
                        (<div className={styles.img}>
                          <img src={blacklistItem.company.logotype.path} />
                          </div>):(
                            <div className={styles.imgplaceholder}>
                            </div>)
                          )} */}
                    <div className={styles.img}>
                      <img src={triglavLogo} />
                    </div>
                  </div>

                  <div className={styles.companyName}>
                    {blacklistItem.company.name}
                  </div>
                  <div className={styles.companyDescription}>
                    {blacklistItem.company.description}
                  </div>
                  <div
                    className={styles.actionWrap}
                    onClick={() => {
                      setCompanyToRemove(blacklistItem)
                      setIsModalOpen(true)
                    }}
                  >
                    <Delete />
                  </div>
                </div>
              ))
            ) : (
              <div className={styles.noData}>
                <FormattedMessage id={bt.noData} />
              </div>
            )}
          </div>
        </SectionItem>

        <Modal
          isOpen={isModalOpen}
          onAfterClose={() => {
            setIsModalOpen(false)
          }}
          title={bt.removeCompany}
          icon={Warning}
          ariaHideApp={false}
          theme="error"
          Footer={
            <SubmitFooter
              submitText={bt.yesImSure}
              onSubmit={() => {
                removeCompanyFromBlacklist()
                setIsModalOpen(false)
              }}
              onCancel={() => {
                setIsModalOpen(false)
              }}
              theme="modalProfileSubmit"
            />
          }
        >
          <div className={styles.modalContent}>
            <FormattedMessage id={bt.areYouSure} />{' '}
            <span className={styles.companyTitle}>
              {companyToRemove?.company.name}
            </span>{' '}
            <FormattedMessage id={bt.fromBlocked} />
          </div>
        </Modal>
      </div>
    </MainLayout>
  )
}
