import {FC, useEffect, useState} from 'react'

import {FormattedMessage} from 'react-intl'

import styles from './RequestActions.module.scss'
import useRequestActionsArray from '../hooks/useRequestActionsArray'
import PopoverList from 'Components/PopoverList'
import ProposeNewTimeModal from './ProposeNewTimeModal'
import {
  IncomingRequestType,
  ApproveRejectModalRequestType,
  RjbProfileStatus,
} from 'Interfaces'
import ModalRejectContactRequest from 'Components/IncomingRequests/Modals/ModalContactRequest/ModalRejectContactRequest'
import ModalInterviewInvitationReject from 'Components/IncomingRequests/Modals/ModalInterviewInvitation/ModalRejectInterviewInvitation'
import ModalInterviewInvitation from 'Components/IncomingRequests/Modals/ModalInterviewInvitation'
import ModalApproveOfferRequest from 'Components/IncomingRequests/Modals/ModalOfferRequest/ModalApproveOfferRequest'
import ModalRejectOfferRequest from 'Components/IncomingRequests/Modals/ModalOfferRequest/ModalRejectOfferRequest'
import {ButtonThumb} from 'Components/Button'
import ModalOfferRequest from 'Components/IncomingRequests/Modals/ModalOfferRequest'
import ModalContactRequest from 'Components/IncomingRequests/Modals/ModalContactRequest'
import {
  CandidateDashboardTranslations as cdt,
  EmployerDashboardTranslations as edt,
} from 'Services/I18n/Constants'
import {useTranslation} from 'Hooks'
import ModalPlacementRequest from '../Modals/ModalPlacementRequest'
import {InterviewRequestStatus, RequestType} from '../Constants'
import classNames from 'classnames'
import {isInLessThan10min} from 'Utils/Date'
import ModalApproveContactRequest from '../Modals/ModalContactRequest/ModalApproveContactRequest'
import {Check, MoreHoriz, VideocamOutlined} from '@mui/icons-material'
import {AppRoute} from 'Services/I18n/Constants'
import {getRoute} from 'Services/I18n/Utils'
import ModalCancelInterview from 'Components/IncomingRequests/Modals/ModalInterviewInvitation/ModalCancelInterview'

type RequestActionsProps = {
  request: IncomingRequestType
  approveInterviewInvitation?: (payload: ApproveRejectModalRequestType) => void
  approveContactRequest?: (payload: ApproveRejectModalRequestType) => void
  isOpenContactRequestDetails?: boolean
  setIsOpenContactRequestDetails?: (
    isOpenContactRequestDetails: boolean
  ) => void
  approveModal?: boolean
  setApproveModal?: (approveModal: boolean) => void
}

const OptionalActions: FC<RequestActionsProps> = ({
  request,
  approveInterviewInvitation,
  approveModal,
  setApproveModal,
  isOpenContactRequestDetails,
  setIsOpenContactRequestDetails,
}) => {
  const [hasAction] = useRequestActionsArray(request)

  const [rejectModalContactRequest, setRejectModalContactRequest] =
    useState(false)
  const [rejectModalInterviewInvitation, setRejectModalInterviewInvitation] =
    useState(false)
  const [isOpenInterviewInvitation, setIsOpenInterviewInvitation] =
    useState(false)
  const [approveModalOfferRequest, setApproveModalOfferRequest] =
    useState(false)
  const [rejectModalOfferRequest, setRejectModalOfferRequest] = useState(false)
  const [placementRequestModal, setPlacementRequestModal] = useState(false)

  const onApproveContactRequest = () => {
    setApproveModal && setApproveModal(true)
  }

  const onRejectContactRequest = () => {
    setRejectModalContactRequest(true)
  }

  const onAcceptOffer = () => {
    setApproveModalOfferRequest(true)
  }
  const onRejectOffer = () => {
    setRejectModalOfferRequest(true)
  }

  const onPlacementModalOpen = () => {
    setPlacementRequestModal(true)
  }

  const onApproveInterviewInvitation = () => {
    approveInterviewInvitation &&
      approveInterviewInvitation({
        id: request.id,
      })
  }
  const onRejectInterviewInvitation = () => {
    setRejectModalInterviewInvitation(true)
  }

  const PlacementConfirmation = () => (
    <div
      onClick={onPlacementModalOpen}
      className={`${styles.confirmationAction} ${styles.button}`}
    >
      <FormattedMessage id={cdt.placementConfirmation} />
    </div>
  )
  const ApproveRejectRequest = () => (
    <>
      <ButtonThumb onClick={onApproveContactRequest} theme="thumbUp">
        {cdt.approve}
      </ButtonThumb>

      <ButtonThumb onClick={onRejectContactRequest} theme="thumbDown">
        {cdt.reject}
      </ButtonThumb>
    </>
  )
  const ApproveRejectInvitation = () => (
    <>
      <ButtonThumb onClick={onApproveInterviewInvitation} theme="thumbUp">
        {cdt.approve}
      </ButtonThumb>

      <ButtonThumb onClick={onRejectInterviewInvitation} theme="thumbDown">
        {cdt.reject}
      </ButtonThumb>
    </>
  )

  const StartVideoInterview = () => {
    const translation = useTranslation()
    const startDate = request.startDate
    const endDate = request.endDate
    const [isSoon, setIsSoon] = useState(isInLessThan10min(startDate, endDate))
    const spectoUrl = `${request.videoUrl}&redirectionUrl=${
      window.location.origin
    }${getRoute(AppRoute.CandidateDashboard)}`

    useEffect(() => {
      const id = setInterval(() => {
        setIsSoon(isInLessThan10min(startDate, endDate))
      }, 10000)

      return () => clearInterval(id)
    }, [])

    return (
      <div
        className={classNames(styles.startInterviewBtn, {
          [styles.startInterviewBtnDisabled]: !isSoon,
        })}
      >
        {isSoon ? (
          <a href={spectoUrl} target={'_blank'} rel={'noopener noreferrer'}>
            <VideocamOutlined />
            <span>{translation[cdt.startVideoInterview]}</span>
          </a>
        ) : (
          <>
            <VideocamOutlined />
            <span>{translation[cdt.startVideoInterview]}</span>
          </>
        )}
      </div>
    )
  }

  const AcceptRejectOffer = () => (
    <>
      <ButtonThumb onClick={onAcceptOffer} theme="thumbUp">
        {cdt.accept}
      </ButtonThumb>

      <ButtonThumb onClick={onRejectOffer} theme="thumbDown">
        {cdt.reject}
      </ButtonThumb>
    </>
  )

  const PlacementConfirmed = () => (
    <div className={styles.confirmedMessage}>
      <Check />
      <span>
        <FormattedMessage id={cdt.placementConfirmed} />
      </span>
    </div>
  )

  return (
    <>
      {hasAction('CONTACT_REQUEST_PENDING') && <ApproveRejectRequest />}
      {hasAction('ACCEPT_REJECT_OFFER') && <AcceptRejectOffer />}
      {hasAction('ACCEPT_REJECT_INTERVIEW') && <ApproveRejectInvitation />}
      {hasAction('START_VIDEO_INTERVIEW') && <StartVideoInterview />}
      {hasAction('PLACEMENT_PENDING') && <PlacementConfirmation />}
      {hasAction('PLACEMENT_CONFIRMED') && <PlacementConfirmed />}

      <ModalApproveContactRequest
        request={request}
        approveModal={approveModal}
        setApproveModal={setApproveModal}
        isOpenContactRequestDetails={isOpenContactRequestDetails}
        setIsOpenContactRequestDetails={setIsOpenContactRequestDetails}
      />
      <ModalRejectContactRequest
        request={request}
        rejectModal={rejectModalContactRequest}
        setRejectModal={setRejectModalContactRequest}
      />
      <ModalInterviewInvitationReject
        request={request}
        rejecModal={rejectModalInterviewInvitation}
        setRejectModal={setRejectModalInterviewInvitation}
        showDetails={true}
      />
      <ModalInterviewInvitation
        request={request}
        isOpenInterviewInvitation={isOpenInterviewInvitation}
        setIsOpenInterviewInvitation={setIsOpenInterviewInvitation}
      />
      <ModalApproveOfferRequest
        approveModal={approveModalOfferRequest}
        setApproveModal={setApproveModalOfferRequest}
        request={request}
      />
      <ModalRejectOfferRequest
        rejectModal={rejectModalOfferRequest}
        setRejectModal={setRejectModalOfferRequest}
        request={request}
      />
      <ModalPlacementRequest
        isOpenPlacementModal={placementRequestModal}
        setIsOpenPlacementModal={setPlacementRequestModal}
        request={request}
      />
    </>
  )
}

const MoreActions: FC<RequestActionsProps> = ({
  request,
  approveModal,
  setApproveModal,
}) => {
  const translation = useTranslation()
  const [isProposeNewTimeModalOpen, setIsProposeNewTimeModalOpen] =
    useState(false)
  const [hasAction] = useRequestActionsArray(request)
  const [isOpenInterviewInvitation, setIsOpenInterviewInvitation] =
    useState(false)
  const [isOpenContactRequest, setIsOpenContactRequest] = useState(false)
  const [isOpenOffer, setIsOpenOffer] = useState(false)
  const [isOpenPlacementModal, setIsOpenPlacementModal] = useState(false)
  const [cancelInterviewModal, setCancelInterviewModal] = useState(false)

  const onViewDetails = () => {
    request.type === RequestType.INTERVIEW_REQUEST &&
      setIsOpenInterviewInvitation(true)
    request.type === RequestType.CONTACT_REQUEST &&
      setIsOpenContactRequest(true)
    request.type === RequestType.OFFER_REQUEST && setIsOpenOffer(true)
    request.type === RequestType.PLACEMENT_REQUEST &&
      setIsOpenPlacementModal(true)
  }

  const onProposeNewTime = () => {
    setIsProposeNewTimeModalOpen(true)
  }

  const moreActions = [
    {
      text: translation[cdt.viewDetails],
      onClick: onViewDetails,
    },
  ]

  const noLongerActiveCandidate =
    !request.hasFinishedPlacementWithThisEmployer &&
    request.seekerRjbProfileStatus === RjbProfileStatus.CLOSED

  if (hasAction('PROPOSE_NEW_TIME') && !noLongerActiveCandidate) {
    moreActions.push({
      text: translation[cdt.proposeNewTime],
      onClick: onProposeNewTime,
    })
  }

  if (request.status === InterviewRequestStatus.INTERVIEW_CONFIRMED) {
    moreActions.unshift({
      text: edt.cancelInterview,
      onClick: () => {
        setCancelInterviewModal(true)
      },
    })
  }

  return (
    <div className={styles.moreAction} role="button">
      <PopoverList
        triggerIcon={<MoreHoriz />}
        items={moreActions}
        threeDotsBtn
      />
      <ProposeNewTimeModal
        request={request}
        isOpen={isProposeNewTimeModalOpen}
        setIsOpen={setIsProposeNewTimeModalOpen}
      />
      <ModalInterviewInvitation
        request={request}
        isOpenInterviewInvitation={isOpenInterviewInvitation}
        setIsOpenInterviewInvitation={setIsOpenInterviewInvitation}
        setCancelModal={setCancelInterviewModal}
      />
      <ModalContactRequest
        request={request}
        isOpenContactRequest={isOpenContactRequest}
        setIsOpenContactRequest={setIsOpenContactRequest}
        modalDetails={true}
        approveModal={approveModal}
        setApproveModal={setApproveModal}
      />
      <ModalOfferRequest
        request={request}
        isOpenOfferRequest={isOpenOffer}
        setIsOpenOfferRequest={setIsOpenOffer}
        modalDetails={true}
      />
      <ModalPlacementRequest
        request={request}
        isOpenPlacementModal={isOpenPlacementModal}
        setIsOpenPlacementModal={setIsOpenPlacementModal}
      />
      <ModalCancelInterview
        request={request}
        cancelModal={cancelInterviewModal}
        setCancelModal={setCancelInterviewModal}
        isOpenInterviewInvitation={isOpenInterviewInvitation}
        setIsOpenInterviewInvitation={setIsOpenInterviewInvitation}
      />
    </div>
  )
}

export const RequestActions: FC<RequestActionsProps> = ({
  request,
  approveInterviewInvitation,
  approveContactRequest,
}) => {
  const [approveModalContactRequest, setApproveModalContactRequest] =
    useState(false)
  const [isOpenContactRequestDetails, setIsOpenContactRequestDetails] =
    useState(false)
  return (
    <div className={styles.actionWrap}>
      <OptionalActions
        request={request}
        approveInterviewInvitation={approveInterviewInvitation}
        approveContactRequest={approveContactRequest}
        approveModal={approveModalContactRequest}
        setApproveModal={setApproveModalContactRequest}
        isOpenContactRequestDetails={isOpenContactRequestDetails}
        setIsOpenContactRequestDetails={setIsOpenContactRequestDetails}
      />
      <MoreActions
        request={request}
        approveModal={approveModalContactRequest}
        setApproveModal={setApproveModalContactRequest}
        isOpenContactRequestDetails={isOpenContactRequestDetails}
        setIsOpenContactRequestDetails={setIsOpenContactRequestDetails}
      />
    </div>
  )
}
