import {FC, useRef} from 'react'
import styles from './CandidateFAQ.module.scss'
import ShowMoreContent from 'Components/ShowMoreContent'
import {PublicPagesHeader} from 'Components/PublicPagesHeader'
import subpage1 from 'Assets/Images/homepage/subpage1.jpg'
import subpage2 from 'Assets/Images/homepage/subpage2.jpg'
import subpage3 from 'Assets/Images/homepage/subpage3.jpg'
import subpage4 from 'Assets/Images/homepage/subpage4.jpg'
import YellowText from 'Components/YellowText'
import {FormattedMessage} from 'react-intl'
import {useTranslation} from 'Hooks'
import {CandidateFAQ as cf} from 'Services/I18n/Constants/Seeker/CandidateFAQ'

export const CandidateFAQ: FC = () => {
  const thirdRef = useRef(null)
  const firstRef = useRef(null)
  const translation = useTranslation()

  return (
    <div>
      <div className={styles.layout}>
        <div className={styles.container}>
          <PublicPagesHeader page="candidate" />
          <div className={styles.titleContainer}>
            <h1 className={styles.title}>
              <YellowText text={translation[cf.yellowTitle]} />{' '}
              <span>
                <FormattedMessage id={cf.title} />
              </span>
            </h1>
          </div>
          <div className={styles.section}>
            <img
              className={styles.image}
              src={subpage1}
              alt="Person Looking at Laptop"
            />
            <div className={styles.sectionLeft}>
              <p className={styles.paragraphTitle}>
                <FormattedMessage id={cf.titleFirstParagraph} />
              </p>
              <div className={styles.paragraph}>
                <p className={styles.paragraphStart}>
                  <FormattedMessage id={cf.textFirstParagraph} />
                </p>
                <ol className={styles.ol}>
                  <li>
                    <FormattedMessage id={cf.textItemOneFirstParagraph} />
                  </li>
                </ol>
                <ShowMoreContent
                  className={styles.showMore}
                  theme="whiteIcon"
                  childRef={firstRef}
                  height={1}
                  mobileHeight={1}
                >
                  <div ref={firstRef}>
                    <ol start={2} className={styles.ol}>
                      <li>
                        <FormattedMessage id={cf.textItemTwoFirstParagraph} />
                      </li>
                      <li>
                        <FormattedMessage id={cf.textItemThreeFirstParagraph} />
                      </li>
                      <li>
                        <FormattedMessage id={cf.textItemFourFirstParagraph} />
                      </li>
                    </ol>
                  </div>
                </ShowMoreContent>
              </div>
            </div>
          </div>

          <div className={styles.sectionBig}>
            <div className={styles.sectionUp}>
              <img
                className={styles.image}
                src={subpage2}
                alt="Person typing on laptop"
              />
              <div className={styles.sectionLeft}>
                <p className={styles.paragraphTitle}>
                  <FormattedMessage id={cf.titleSecondParagraph} />
                </p>
                <p className={styles.paragraph}>
                  <FormattedMessage id={cf.textSecondParagraph} />
                </p>
              </div>
            </div>
            <ShowMoreContent
              height={300}
              mobileHeight={1}
              className={styles.showMore}
              theme="whiteIcon"
            >
              <div className={styles.grid}>
                <div>
                  <p className={styles.gridNumber}>1</p>
                  <p className={styles.paragraphTitleGrid}>
                    {' '}
                    <FormattedMessage id={cf.titleFirstSection} />
                  </p>
                  <p className={styles.paragraph}>
                    <FormattedMessage id={cf.paragraphFirstSection} />
                  </p>
                </div>
                <div>
                  <p className={styles.gridNumber}>2</p>
                  <p className={styles.paragraphTitleGrid}>
                    <FormattedMessage id={cf.titleSecondSection} />
                  </p>
                  <p className={styles.paragraph}>
                    <FormattedMessage id={cf.paragraphSecondSection} />
                  </p>
                </div>
                <div>
                  <p className={styles.gridNumber}>3</p>
                  <p className={styles.paragraphTitleGrid}>
                    {' '}
                    <FormattedMessage id={cf.titleThirdSection} />
                  </p>
                  <p className={styles.paragraph}>
                    <FormattedMessage id={cf.paragraphThirdSection} />
                  </p>
                </div>
                <div>
                  <p className={styles.gridNumber}>4</p>
                  <p className={styles.paragraphTitleGrid}>
                    <FormattedMessage id={cf.titleFourthSection} />
                  </p>
                  <p className={styles.paragraph}>
                    <FormattedMessage id={cf.paragraphFourthSection} />
                  </p>
                </div>
                <div>
                  <p className={styles.gridNumber}>5</p>
                  <p className={styles.paragraphTitleGrid}>
                    {' '}
                    <FormattedMessage id={cf.titleFifthSection} />
                  </p>
                  <p className={styles.paragraph}>
                    <FormattedMessage id={cf.paragraphFifthSection} />
                  </p>
                </div>
                <div>
                  <p className={styles.gridNumber}>6</p>
                  <p className={styles.paragraphTitleGrid}>
                    {' '}
                    <FormattedMessage id={cf.titleSixthSection} />
                  </p>
                  <p className={styles.paragraph}>
                    <FormattedMessage id={cf.paragraphSixthSection} />
                  </p>
                </div>
                <div>
                  <p className={styles.gridNumber}>7</p>
                  <p className={styles.paragraphTitleGrid}>
                    <FormattedMessage id={cf.titleSeventhSection} />
                  </p>
                  <p className={styles.paragraph}>
                    <FormattedMessage id={cf.paragraphSeventhSection} />
                  </p>
                </div>
              </div>
            </ShowMoreContent>
          </div>

          <div className={styles.sectionBig}>
            <div className={styles.sectionUp}>
              <img
                className={styles.image}
                src={subpage3}
                alt="Person sitting at a computer"
              />
              <div className={styles.sectionLeft}>
                <p className={styles.paragraphTitle}>
                  <FormattedMessage id={cf.titleThirdParagraph} />
                </p>
                <p className={styles.paragraph}>
                  <FormattedMessage id={cf.textThirdParagraph} />
                </p>
              </div>
            </div>
            <ShowMoreContent
              height={300}
              mobileHeight={1}
              className={styles.showMore}
              theme="whiteIcon"
              childRef={thirdRef}
            >
              <div className={styles.grid} ref={thirdRef}>
                <div>
                  <p className={styles.gridNumber}>1</p>
                  <p className={styles.paragraphTitleGrid}>
                    <FormattedMessage id={cf.bottomParagraphtitleFirst} />
                  </p>
                  <p className={styles.paragraph}>
                    <FormattedMessage id={cf.bottomParagraphtextFirst} />
                  </p>
                </div>
                <div>
                  <p className={styles.gridNumber}>2</p>
                  <p className={styles.paragraphTitleGrid}>
                    <FormattedMessage id={cf.bottomParagraphtitleSecond} />
                  </p>
                  <p className={styles.paragraph}>
                    <FormattedMessage id={cf.bottomParagraphtextSecond} />
                  </p>
                </div>
                <div>
                  <p className={styles.gridNumber}>3</p>
                  <p className={styles.paragraphTitleGrid}>
                    <FormattedMessage id={cf.bottomParagraphtitleThird} />
                  </p>
                  <p className={styles.paragraph}>
                    <FormattedMessage id={cf.bottomParagraphtextThird} />
                  </p>
                </div>
                <div>
                  <p className={styles.gridNumber}>4</p>
                  <p className={styles.paragraphTitleGrid}>
                    <FormattedMessage id={cf.bottomParagraphtitleFourth} />
                  </p>
                  <p className={styles.paragraph}>
                    <FormattedMessage id={cf.bottomParagraphtextFourth} />
                  </p>
                </div>
                <div>
                  <p className={styles.gridNumber}>5</p>
                  <p className={styles.paragraphTitleGrid}>
                    <FormattedMessage id={cf.bottomParagraphtitleFifth} />
                  </p>
                  <p className={styles.paragraph}>
                    <FormattedMessage id={cf.bottomParagraphtextFifth} />
                  </p>
                </div>
              </div>
            </ShowMoreContent>
          </div>
          <div className={styles.section}>
            <img
              className={styles.image}
              src={subpage4}
              alt="Person looking at laptop"
            />
            <div className={styles.sectionLeft}>
              <p className={styles.paragraphTitle}>
                <FormattedMessage id={cf.lastSectiontitle} />
              </p>
              <ShowMoreContent
                height={48}
                mobileHeight={48}
                className={styles.showMore}
                theme="whiteIcon"
              >
                <div className={styles.paragraph}>
                  <p>
                    <FormattedMessage id={cf.lastSectionFirstPart} />
                    <a
                      className={styles.link}
                      href="https://placa.si"
                      target={'_blank'}
                    >
                      <FormattedMessage id={cf.lastSectionlink} />
                    </a>
                    <FormattedMessage id={cf.lastSectionSecondPart} />
                  </p>
                </div>
              </ShowMoreContent>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
