import Breadcrumbs from 'Components/Breadcrumbs'
import Hr from 'Components/Hr'
import styles from './MainHeader.module.scss'
import classnames from 'classnames'
import {FormattedMessage} from 'react-intl'
import classNames from 'classnames'

interface MainHeaderProps {
  title: string
  breadcrumbsArray: {name: string; link?: string; action?: () => void}[]
  theme?: string
  image?: JSX.Element
  className?: string
  breadCrumbsWrapClassName?: string
}

export const MainHeader: React.FC<MainHeaderProps> = ({
  title,
  breadcrumbsArray,
  theme = 'basic',
  image,
  className,
  breadCrumbsWrapClassName,
  children,
}) => {
  return (
    <div className={styles.mainHeaderContainer}>
      <div className={styles.topHeaderPartWrapper}>{/*<TopHeaderPart />*/}</div>
      <div className={styles.titleWrapper}>
        {image}
        <h1 className={classNames(styles.title, className)}>
          <FormattedMessage id={title} />
        </h1>
      </div>
      <div
        className={classnames(
          styles.breadcrumbsWrapper,
          breadCrumbsWrapClassName,
          styles[theme] ? styles[theme] : ''
        )}
      >
        <Breadcrumbs breadcrumbs={breadcrumbsArray} theme={theme} />
        {children}
      </div>
      <div className={styles.thickHrContainer}>
        <Hr className={styles.thickHr} />
      </div>
    </div>
  )
}
