import {connect} from 'react-redux'

import {EmployerBlacklist} from './EmployerBlacklist'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {
  getBlacklistItems,
  getBlacklist,
  deleteBlacklist,
  createBlacklist,
} from 'Store/Actions'

const mapStateToProps = (state: RootState) => ({
  blacklist: state.blacklist,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getData: () => {
    dispatch(getBlacklistItems())
    dispatch(getBlacklist())
  },
  createBlacklistItem: (companyId: string) =>
    dispatch(createBlacklist(companyId)),
  deleteBlacklistItem: (id: string) => dispatch(deleteBlacklist(id)),
})

export const EmployerBlacklistContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerBlacklist)
