import {useMemo, useState, FC, useEffect} from 'react'
import {useFormik} from 'formik'

import SectionHeader from 'Components/Profile/SectionHeader'
import SectionItem from 'Components/Profile/SectionItem'
import ItemHeader from 'Components/Profile/ItemHeader'
import ItemTextWithIcon from 'Components/Profile/ItemTextWithIcon'
import TextField from 'Components/Inputs/TextField'
import TextArea from 'Components/Inputs/TextArea'
import Modal, {DeleteModal} from 'Components/Modal'
import ShowMore from 'Components/Profile/ShowMore'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import Dropdown from 'Components/Inputs/Dropdown'
import styles from './Projects.module.scss'
import Validation from './Validation'
import {ProjectItem, ProjectFormikValue, SeekerStateType} from 'Interfaces'
import {Sort} from './Sort'
import Title from 'Components/Title'
import Hr from 'Components/Hr'
import {useYears, useApiError} from 'Hooks'
import {DropdownProps, OnChangeProps} from 'Utils/FormikProps'

import {
  ProfileTranslations as pt,
  DateTranslations as dt,
} from 'Services/I18n/Constants'
import {useIntl} from 'react-intl'
import nl2br from 'react-nl2br'
import {Extension, UpdateOutlined} from '@mui/icons-material'

interface ProjectsEndDateProps {
  profile: SeekerStateType
  projects?: ProjectItem[]
  addProject: (value: ProjectFormikValue) => void
  editProject: (value: ProjectFormikValue) => void
  canChange?: boolean
  deleteProject: (id: string) => void
}

export const ProjectsEndDate: FC<ProjectsEndDateProps> = ({
  profile,
  projects = [],
  addProject,
  editProject,
  canChange = false,
  deleteProject,
}) => {
  const emptyValues: ProjectFormikValue = {
    title: '',
    endYear: '',
    description: '',
  }

  const [modalOpen, setModalOpen] = useState(false)
  const [modalMode, setModalMode] = useState('Add')
  const [showMoreIndex, setShowMoreIndex] = useState(1)
  const [deleteModal, setDeleteModal] = useState<boolean | number>(false)

  const localProjects = useMemo(() => {
    return projects.sort(Sort).slice(0, showMoreIndex)
  }, [projects, showMoreIndex])
  const {formatMessage} = useIntl()

  const openEdit = (id?: string) => {
    setModalMode('Edit')
    const projectItem = projects.find((e) => e.id === id) as ProjectItem
    formik.resetForm({
      values: {
        id: projectItem.id,
        title: projectItem.title,
        endYear: projectItem.endYear ? projectItem.endYear.toString() : '',
        description: projectItem.description,
      },
    })
    setModalOpen(true)
  }

  const openAdd = () => {
    setModalMode('Add')
    formik.resetForm({
      values: emptyValues,
    })
    setModalOpen(true)
  }

  const formik = useFormik({
    initialValues: emptyValues,
    validationSchema: Validation,
    onSubmit: (values) => {
      if (modalMode === 'Edit') {
        editProject(values)
      } else addProject(values)
    },
  })

  const years = useYears()

  useApiError(formik.setFieldError, profile.error)
  useEffect(() => {
    if (!profile.loading && !profile.error) {
      setModalOpen(false)
      setDeleteModal(false)
    }
  }, [profile.loading])

  const dateText = (projectItem: ProjectFormikValue) => {
    let text = ''
    if (projectItem.endYear) text = `${projectItem.endYear}`
    return text !== '' ? (
      <ItemTextWithIcon
        primaryText={pt.projectYear}
        secondaryText={text}
        icon={UpdateOutlined}
        withColon
      />
    ) : null
  }

  return (
    <div className={styles.section}>
      <div>
        <SectionHeader
          onAdd={canChange ? openAdd : undefined}
          title={pt.projectsTitle}
        />
      </div>
      {localProjects.map((projectItem, index) => {
        return (
          <SectionItem key={projectItem.id}>
            <ItemHeader
              title={`${projectItem.title}`}
              icon={Extension}
              onEdit={
                canChange
                  ? () => {
                      openEdit(projectItem.id)
                    }
                  : undefined
              }
              onDelete={
                canChange
                  ? () => {
                      setDeleteModal(index)
                    }
                  : undefined
              }
            />
            <div className={styles.content}>
              {dateText(projectItem)}

              {projectItem?.description && projectItem?.description !== '' ? (
                <p className={styles.text}>{nl2br(projectItem.description)}</p>
              ) : null}
            </div>
          </SectionItem>
        )
      })}

      <ShowMore
        items={projects}
        showMoreIndex={showMoreIndex}
        setShowMoreIndex={setShowMoreIndex}
      />

      <Modal
        isOpen={modalOpen}
        onAfterClose={() => {
          setModalOpen(false)
        }}
        title={`${formatMessage({
          id: modalMode === 'Add' ? pt.addProjects : pt.editProject,
        })}`}
        icon={Extension}
        ariaHideApp={false}
        className={styles.modal}
        theme="desktopFlex"
        Footer={
          <SubmitFooter
            onSubmit={formik.handleSubmit}
            onCancel={() => {
              setModalOpen(false)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <Title text={pt.projectsTitle} theme="modal" />
        <TextField
          name="title"
          placeholder={pt.placeholderProjectTitle}
          className={styles.textField}
          {...OnChangeProps(formik, 'title')}
          theme="modal"
        />
        <Hr theme="modal" />
        <Title text={pt.projectDate} theme="modal" />
        <div className={styles.employmentDateDown}>
          <Dropdown
            name="endYear"
            items={years}
            theme="white"
            label={dt.projectEndYear}
            {...DropdownProps(formik, 'endYear')}
          />
        </div>

        <Hr theme="modal" />

        <Title text={pt.projectDescription} theme="modal" />
        <TextArea
          name="description"
          value={`${formik.values.description}`}
          onChange={formik.handleChange}
          error={
            formik.touched.description || formik.values.description !== ''
              ? formik.errors.description
              : undefined
          }
          placeholderText={pt.placeholderProjectDescription}
          theme="modal"
          maximumCharactersNumber={1000}
          type="letterCalculator"
        />
      </Modal>
      <DeleteModal
        deleteAction={deleteProject}
        isOpen={!!deleteModal || deleteModal === 0}
        setOpen={setDeleteModal}
        text={`${formatMessage({id: pt.deletePartOne})}${
          localProjects?.find((_, i) => i === deleteModal)?.title || ''
        }${formatMessage({id: pt.deletePartTwo})}`}
        id={localProjects?.find((_, i) => i === deleteModal)?.id || ''}
      />
    </div>
  )
}
