import {FC, ChangeEvent, FocusEvent} from 'react'
import styles from './TextField.module.scss'
import classnames from 'classnames'
import TextError from 'Components/Error/TextError'
import WarningIcon from '@mui/icons-material/Warning'
import classNames from 'classnames'
import {FormattedMessage} from 'react-intl'
import {useTranslation} from 'Hooks'
interface TextFieldProps {
  name: string
  label?: string
  value: string
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  error?: string
  disabled?: boolean
  theme?:
    | 'white'
    | 'disabledStrong'
    | 'grey'
    | 'disabledTransparent'
    | 'minWidth'
    | 'mobileGrey'
    | 'styledPlaceholderTextField'
    | 'modal'
  className?: string
  placeholder?: string
  type?: string
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void
  horizontalLabel?: string
}

export const TextField: FC<TextFieldProps> = ({
  name,
  label,
  value,
  onChange,
  error,
  disabled = false,
  theme = 'white',
  className,
  placeholder,
  type = 'text',
  onBlur,
  horizontalLabel,
}: TextFieldProps) => {
  const translation = useTranslation()
  return (
    <div
      className={classnames(
        styles.textField,
        styles[theme] ? styles[theme] : '',
        {[styles.disabled]: disabled},
        className
      )}
    >
      {label ? (
        <label
          className={classnames(styles.textLabel, {
            [styles.error]: error,
          })}
          htmlFor={name}
        >
          <FormattedMessage id={label} />
        </label>
      ) : null}
      <div
        className={classNames({
          [styles.horizontalLabelInputWrapper]: horizontalLabel,
          [styles.inputError]: error && theme === 'styledPlaceholderTextField',
          [styles.withError]: horizontalLabel && error,
        })}
      >
        {horizontalLabel && (
          <>
            <label
              className={classnames(styles.horizontalLabel, {
                [styles.error]: error,
              })}
              htmlFor={name}
            >
              <FormattedMessage id={horizontalLabel} />
            </label>
            &nbsp;
          </>
        )}
        <input
          className={classnames(styles.textInput, {
            [styles.inputError]: error && !horizontalLabel,
            [styles.withLabel]: label,
            [styles.horizontalLabelInput]: horizontalLabel,
          })}
          name={name}
          onChange={disabled ? undefined : onChange}
          value={value}
          autoComplete="off"
          type={type}
          placeholder={translation[placeholder] || placeholder}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={() => {}}
        />
      </div>
      {error && <WarningIcon className={styles.errorIcon} />}
      {error && <TextError text={error} />}
    </div>
  )
}
