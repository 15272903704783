import {FC} from 'react'
import styles from './ExpirationValuesInDays.module.scss'
import {useTranslation} from 'Hooks'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'

interface ExpirationValuesInDaysProps {
  inDays: number
}
export const ExpirationValuesInDays: FC<ExpirationValuesInDaysProps> = ({
  inDays,
}) => {
  const translation = useTranslation()
  return (
    <div className={inDays < 3 ? styles.dateTextSoon : styles.dateTextIn}>{`${
      translation[edt.in]
    } ${inDays} ${
      inDays === 1 ? translation[edt.day] : translation[edt.days]
    }`}</div>
  )
}
