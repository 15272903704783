import {FC} from 'react'
import {useLocation} from 'react-router-dom'
import styles from './AboutUs.module.scss'

import {PublicPagesHeader} from 'Components/PublicPagesHeader'
import {HomePageFooter} from 'Components/HomePage'
import YellowText from 'Components/YellowText'
import {AboutUs as as} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'

type AboutUsProps = {}

export const AboutUs: FC<AboutUsProps> = ({}) => {
  const location = useLocation()

  return (
    <>
      <div className={styles.main}>
        <div className={styles.mainContainer}>
          <div className={styles.overflow}></div>
          <PublicPagesHeader
            page={location.state === 'employer' ? 'employer' : 'candidate'}
          />
          <div className={styles.content}>
            <h1>
              <YellowText className={styles.title} text={as.mainTitle} />
            </h1>
            <p>
              <FormattedMessage id={as.paragraphFirst} />
            </p>
            <h2>
              <FormattedMessage id={as.title} />
            </h2>
            <p>
              <FormattedMessage id={as.paragraphSecond} />
            </p>
            <p>
              <FormattedMessage id={as.label} />
            </p>
          </div>
          <HomePageFooter />
        </div>
      </div>
    </>
  )
}
