import {ReactNode} from 'react'

import {Link} from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home'
import {BreadcrumbsType} from 'Interfaces/BaseType'
import classnames from 'classnames'
import styles from './Breadcrumbs.module.scss'
import classNames from 'classnames'
import ConditionalWrapper from 'Components/ConditionalWrapper'
import {FormattedMessage} from 'react-intl'

export const Breadcrumbs: React.FC<BreadcrumbsType> = ({
  breadcrumbs,
  className,
  theme = 'basic',
}) => {
  return (
    <div className={classnames(styles.breadcrumbsContainer, className)}>
      <nav
        className={classnames(styles.breadcrumbsNav, {
          [styles[theme]]: [theme],
        })}
      >
        {breadcrumbs.map((breadcrumb, index) => {
          const {name, link, action} = breadcrumb
          return (
            <ConditionalWrapper
              key={name}
              condition={link || !!action}
              wrapper={(children: ReactNode) =>
                link ? (
                  <Link
                    to={link ? link : '#'}
                    className={styles.navigationItem}
                  >
                    {children}
                  </Link>
                ) : (
                  <button onClick={action} className={styles.navigationItem}>
                    {children}
                  </button>
                )
              }
            >
              {index > 0 && <p>&nbsp; {'>'}</p>}
              <li
                className={classNames({
                  [styles.blackStyleNavigationItem]:
                    index === breadcrumbs.length - 1,
                  [styles.underlinedElements]:
                    index !== breadcrumbs.length - 1 && theme === 'white',
                  [styles.noPointerElement]:
                    index === breadcrumbs.length - 1 && !action,
                })}
              >
                {index === 0 && (
                  <HomeIcon
                    className={classnames(styles.icon, {
                      [styles[theme]]: [theme],
                    })}
                  />
                )}
                <FormattedMessage id={breadcrumb.name} />
              </li>
            </ConditionalWrapper>
          )
        })}
      </nav>
    </div>
  )
}
