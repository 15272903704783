import {connect} from 'react-redux'
import {MyProjects} from './MyProjects'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {
  createNewProject,
  deleteMyProject,
  editMyProject,
  getAllMyProjects,
  archiveMyProject,
  loadMoreProjects,
  getOccupations,
  searchMyProjects,
  loadMoreSearchProjects,
  clearMyProjectsSearch,
} from 'Store/Actions'
import {
  ArchiveMyProjectType,
  EditMyProjectType,
  SearchMyProjectsParams,
} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  allProjects: state.myProjects.allProjects,
  occupations: state.taxonomies.occupations,
  searchResults: state.myProjects.searchResults,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getAllMyProjects: (values?: SearchMyProjectsParams) =>
    dispatch(getAllMyProjects(values)),
  setArchiveStatus: (values: ArchiveMyProjectType) =>
    dispatch(archiveMyProject(values)),
  deleteProject: (id: string) => dispatch(deleteMyProject(id)),
  editProject: (values: EditMyProjectType) => dispatch(editMyProject(values)),
  createNewProject: (id: string) => dispatch(createNewProject(id)),
  loadMore: (url: string) => dispatch(loadMoreProjects(url)),
  getOccupations: () => dispatch(getOccupations()),
  searchMyProjects: (values: SearchMyProjectsParams) =>
    dispatch(searchMyProjects(values)),
  loadMoreSearchResults: (url: string) => dispatch(loadMoreSearchProjects(url)),
  clearSearch: () => dispatch(clearMyProjectsSearch()),
})

export const MyProjectsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyProjects)
