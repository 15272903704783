import {FC, useEffect} from 'react'
import {useHistory} from 'react-router-dom'

import {
  SpectoProposeNewTimeParamsType,
  SpectoStateType,
  IFrameEventType,
} from 'Interfaces'
import MainLayout from 'Layouts/MainLayout'
import styles from './SpectoCalendar.module.scss'
import {WidgetBarContainer} from 'Layouts/MainLayout/WidgetBar'
import {SidebarLink} from 'Components/SidebarLink'
import {AppRoute} from 'Services/I18n/Constants'
import {spectoCalendarTranslations as sct} from 'Services/I18n/Constants'
import {getLang, getRoute} from 'Services/I18n/Utils'
import {getChannel} from 'Utils/CommonHelpers'

interface SpectoCalendarProps {
  spectoState: SpectoStateType
  getSpectoToken: () => void
  setSpectoProposeNewTime: (values: SpectoProposeNewTimeParamsType) => void
}

export const EmployerCalendar: FC<SpectoCalendarProps> = ({
  spectoState,
  getSpectoToken,
  setSpectoProposeNewTime,
}) => {
  const history = useHistory()
  const handleIframeEvents = ({data}: IFrameEventType) => {
    if (data.type === 'propose-new-time') {
      setSpectoProposeNewTime({
        requestId: data.payload.event_id,
        candidateId: undefined,
        candidateSpectoId: data.payload.candidate_id,
      })
      history.push(getRoute(AppRoute.ProposeNewTimeCalendar))
    }
  }

  useEffect(() => {
    if (!spectoState.data.token) {
      getSpectoToken()
    }
    window.addEventListener('message', handleIframeEvents)
    return () => {
      window.removeEventListener('message', handleIframeEvents)
    }
  }, [])
  const lang = getLang()
  const channel = getChannel()
  const spectoCalendarUrl = channel?.spectoCalendarUrl

  return (
    <MainLayout
      withoutLogo
      fixedRight={false}
      rightSidebarContent={
        <WidgetBarContainer
          sidebarContent={
            <div className={styles.linksContainer}>
              <SidebarLink
                routeName={AppRoute.EmployerDashboard}
                text={sct.linkEmployerDashboard}
              />
              <SidebarLink
                routeName={AppRoute.EmployerInterviewInvitations}
                text={sct.linkInterviewInvitation}
              />
              <SidebarLink
                routeName={AppRoute.CandidateSearch}
                text={sct.linkSearchCandidates}
              />
            </div>
          }
        />
      }
    >
      <div className={styles.iframeWrap}>
        {spectoState.data.token && (
          <iframe
            src={`${spectoCalendarUrl}/?token=${spectoState.data.token}&lang=${lang}`}
          />
        )}
      </div>
    </MainLayout>
  )
}
