import {ApproveRejectModalRequestType} from 'Interfaces'
import {connect} from 'react-redux'

import {AppDispatch} from 'Store'
import {rejectContactRequest} from 'Store/Actions'
import {ModalRejectContactRequest} from './ModalRejectContactRequest'
import {RootState} from 'Store/Reducers'

interface RequestProps {
  rejectModal: boolean
  setRejectModal: (modal: boolean) => void
  setDetails?: (details: boolean) => void
}

const mapStateToProps = (state: RootState, ownProps: RequestProps) => ({
  rejectModal: ownProps.rejectModal,
  setRejectModal: ownProps.setRejectModal,

  state: state,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  rejectContactRequest: (payload: ApproveRejectModalRequestType) =>
    dispatch(rejectContactRequest(payload)),
})

export const ModalRejectContactRequestContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalRejectContactRequest)
