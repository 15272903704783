const onboarding = {
  'onboarding.jobCategory.required': 'Polje Področje dela je obvezno',
  'Job category is required.': 'Polje Regije so zahtevane',
  'Regions are required': 'Polje želene regije je obvezno',
  'Work from home field is required': 'Polje delo od doma je obvezno',
  'Salary is required': 'Polje Plača je zahtevana',
  'Job search status is required': 'Polje Status iskanja zaposlitve je obvezno',
  'Availability is required': 'Polje razpoložljivost je obvezno',
  'Desired job positions are required': 'Polje Želena delovna mesta je obvezno',
  'Select max of 3 job positions': 'Izberite največ 3 delovna mesta',
  'Current job title field is required':
    'Naziv trenutnega delovnega mesta je obvezno polje',
  'Total years of experience field is required':
    'Polje Skupno let delovnih izkušenj je obvezno',
  'Job position is required': 'Polje Delovno mesto je obvezno',
  'Skills are required': 'Polje znanja in spretnosti je obvezno',
  'Desired work environment description is required':
    'Polje Želeno delovno okolje je obvezno',
  'Additional benefits field is required': 'Polje Dodatne ugodnosti je obvezno',
  'Education is required': 'Polje Izobrazba je obvezno',
  'Field of study is required': 'Polje Področje izobrazbe je obvezno',
  'Current salary is required': 'Polje Trenutna plača je obvezno',
  'Description is too long.': 'Opis je predolg',
  'Title is too long.': 'Naslov je predolg',
  'Phone number is required': 'Polje Telefonska številka je obvezno',
  'Phone number must be a valid international phone number.':
    'Vnesite veljavno telefonsko številko.',
  'Street and number are required': 'Polje Naslov je obvezno',
  'Must be 50 characters or less': 'Lahko vsebuje 50 znakov ali manj',
  'Other job category value must be 50 characters or less':
    'Vrednost druge kategorije delovnega mesta mora vsebovati 50 znakov ali manj',
  'Job position entered under “Other“ can not contain delimiter symbols ( , ; | )':
    'Delovno mesto vneseno pod “Drugo“ ne sme vsebovati ločilnih znakov (, ; |)',
  'Enter a valid postal code': 'Neveljavna poštna številka',
  'Zip code is required': 'Poštna številka je zahtevana',
  'Town is required': 'Polje mesto je obvezno',
  'Country is required': 'Polje Država je obvezno',
  'Must be longer than or equal to 4 characters':
    'Polje mora vsebovati 4 ali več znakov',
  'Must be shorter than or equal to 6 characters':
    'Polje mora vsebovati do 6 znakov',
  'Postal code must have 4 digits for Slovenia':
    'Poštna številka za Slovenijo mora vsebovati 4 znake',
  'Attachment is required': 'Priponka je obvezna',
  'File too large': 'Datoteka je prevelika',
  'Contact’s Person Position in Company is required':
    'Delovno mesto kontaktne osebe je obvezno',
  'Address is required': 'Address is required',
  'Company long name is required': 'Dolgo ime podjetja je obvezno',
  'Field is required': 'Polje je obvezno',
  'Accepting Privacy policy is required':
    'Sprejetje Politike zasebnosti je obvezno',
  'Number of recruits field is required':
    'Polje Število rekrutirancev je obvezno',
  'Salary range is required': 'Kaj',
  'Fill education level field': 'Izpolnite polje Stopnja izobrazbe',
  'Fill field of study field': 'Izpolnite polje Področje študija',
  'Fill occupation field': 'Izpolnite polje Področje dela',
  'Fill salary field': 'Izpolnite polje Plača',
  'Fill work experience field': 'Izpolnite polje Delovne izkušnje',
}

export default onboarding
