export const companyHeader = {
  'companyHeader.logotypeModalTitle': 'Add Logotype',
  'companyHeader.logotypeTitle': 'LOGOTYPE',

  'companyHeader.coverImageModalTitle': 'Add Cover Image',
  'companyHeader.coverImageTitle': 'COVER IMAGE',

  'companyHeader.headerModalTitle': 'Edit Header',

  'companyHeader.headerCompanyNameTitle': 'COMPANY NAME',
  'companyHeader.headerCompanyNamePlaceholderText': 'Company name ...',
  'companyHeader.headerDescriptionTitle': 'DESCRIPTION',
  'companyHeader.headerDescriptionPlaceholderText':
    'Our company is a leading provider of technology solutions for businesses of all sizes …',
  'companyHeader.headerJobCategoryTitle': 'INDUSTRY',
  'companyHeader.headerCountryTitle': 'LOCATION',
  'companyHeader.headerWebsiteTitle': 'WEBSITE',
  'companyHeader.headerWebsitePlaceholderText': 'website...',

  'companyHeader.townInputPlaceholder': 'Enter city...',
  'companyHeader.countryLabel': 'Country',
  'companyHeader.cityLabel': 'City',
}
