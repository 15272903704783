import {FC, useEffect} from 'react'

import MiniCard from '../MiniCard'
import styles from './MiniCards.module.scss'
import HrWithIcon from 'Components/HrWithIcon'
import {DataType, PopularCandidateType} from 'Interfaces'
import {Face, FaceOutlined, FavoriteBorderOutlined} from '@mui/icons-material'

export interface MiniCardsType {
  getPopularCandidates: () => void
  popularCandidates: DataType<PopularCandidateType>
  setShowPopularCandidates: (s: boolean) => void
}

export const MiniCards: FC<MiniCardsType> = ({
  getPopularCandidates,
  popularCandidates,
  setShowPopularCandidates,
}: MiniCardsType) => {
  useEffect(() => {
    getPopularCandidates()
  }, [])

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h1>Popular candidates</h1>
      </div>
      <div className={styles.break}>
        <HrWithIcon icon={FavoriteBorderOutlined} />
      </div>

      <div className={styles.cardsWrapper}>
        {popularCandidates.data.map((candidate, idx) => {
          return (
            <MiniCard
              key={candidate.id}
              candidate={candidate}
              theme={idx % 2 ? 'grey' : ''}
              MiniCardIcon={idx % 2 ? Face : FaceOutlined}
              setShowPopularCandidates={setShowPopularCandidates}
            />
          )
        })}
      </div>
    </div>
  )
}
