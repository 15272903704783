import {connect} from 'react-redux'

import AuthGuard from './AuthGuard'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {getUser} from 'Store/Actions'
import {UserType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  role: state.auth.data?.type || UserType.SEEKER,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getUser: () => dispatch(getUser()),
})

export const AnyGuardContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthGuard)
