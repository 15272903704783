import {MessagesList} from './MessagesList'
import {loadMoreMessages} from 'Store/Actions/MessageCenterActions'
import {connect} from 'react-redux'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {LoadMoreMessagesType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  loadMoreMessagesUrl: state.messageCenter.loadMoreMessages,
  openedConversation: state.messageCenter.openedConversation,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  loadMoreMessages: (data: LoadMoreMessagesType) =>
    dispatch(loadMoreMessages(data)),
})

export const MessagesListContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesList)
