export const contactData = {
  'contactData.title': 'Thank you!',
  'contactData.message': `You have successfully completed 
  Pre-Qualification survey.`,
  'contactData.description': `RJB is a special job board where each candidate comes with strong references. You have just completed pre-qualification 
  process. What to expect now? Our recruiters will review your information and will work with you to best prepare you for potential 
  employers. In the meanwhile please review your CV and complete required assessments.`,
  'contactData.checkMessage': `Check your contact data to ensure 
  our recruiters can reach you.`,
  'contactData.phoneNumber': `Phone number`,
  'contactData.email': `
  E-poštni naslov`,
  'contactData.streetAndNumber': `Street and number`,
  'contactData.zipCode': `Zip code`,
  'contactData.city': `City`,
  'contactData.country': `Country`,
  'contactData.confirmMessage': `Confirm your contact data`,
  'contactData.selectYourCountry': 'Select your country',
  'contactData.selectYourRegion': 'Choose region',
  'contactData.selectAddress': 'Enter address',
  'contactData.selectPostNumber': 'Enter post number',
}
