import {FC} from 'react'
import {LinksContainer} from 'Components/LinksContainer'
import {LinkType} from 'Interfaces'
import styles from './MobileFooter.module.scss'

interface MobileFooterProps {
  links: LinkType[]
  hash?: boolean
}

export const MobileFooter: FC<MobileFooterProps> = ({links, hash}) => {
  return (
    <div className={styles.container}>
      <div className={styles.linksContainerWrapper}>
        <LinksContainer links={links} hash={hash} />
      </div>
    </div>
  )
}
