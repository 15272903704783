export const unsubscribePage = {
  'unsubscribePage.title': 'We are sorry to see you leaving.',
  'unsubscribePage.subtitle':
    'I want to recieve notifications about the chosen topics:',
  'unsubscribePage.description':
    'We are here for you and it is important to us to provide relevant content. Before we leave, please select the topics that are still of interest to you from the list below.',
  'unsubscribePage.warning': 'There has been a mistake. Please try again. ',
  'unsubscribePage.helpTxt':
    'If you want to unsubscribe from all the newsletter, uncheck all the boxes. ',
  'unsubscribePage.footer':
    'Your preferences will be updated shortly. In the meantime, you may still receive our notifications that are already in the process of being sent out, so please be understanding.',
  'unsubscribePage.submit': 'Save',
}
