import {FC, useState} from 'react'
import classNames from 'classnames'
import {FormattedMessage} from 'react-intl'

import styles from './EmployerPlacements.module.scss'
import EmployerRequestsLayout from 'Layouts/EmployerRequestsLayout'
import PlacementsListItemContainer from 'Components/EmployerDashboard/PlacementsListItem'
import {PlacementsDisplayType} from 'Components/EmployerDashboard/PlacementsListItem/PlacementsListItem'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'
import {NewPlacementRequestStatuses} from 'Components/IncomingRequests/Constants'
import InfiniteScroll from 'Components/InfiniteScroll'
import {useMatchingRoute} from 'Services/I18n/Hooks/useMatchingRoute'
import {
  EmployerRequestsSearchParamsType,
  EmployerRequestsStateType,
} from 'Interfaces'
import {ArrowDropDown} from '@mui/icons-material'
import Loader from 'Components/Fallbacks/Loader'
import {NoLongerActiveCandidateModal} from 'Components/Modal/NoLongerActiveCandidateModal'

const getRouteTab = (routeKey: string | undefined): string => {
  switch (routeKey) {
    case 'routes.employerplacementsPending':
      return 'pending'
    case 'routes.employerplacementsConfirmed':
      return 'confirmed'
    default:
      return 'all'
  }
}

type EmployerPlacementsProps = {
  requests: EmployerRequestsStateType
  loadMore: (url: string) => void
  getEmployerPlacements: (params?: EmployerRequestsSearchParamsType) => void
  getSeekersFromPlacements: (activeTab?: string) => void
  totalPlacementRequests?: number
}

export const EmployerPlacements: FC<EmployerPlacementsProps> = ({
  requests,
  getEmployerPlacements,
  loadMore,
  getSeekersFromPlacements,
  totalPlacementRequests,
}) => {
  const matchingRoute = useMatchingRoute()
  const routeTab = getRouteTab(matchingRoute.routeKey)
  const [order, setOrder] = useState('DESC')
  const [sort, setSort] = useState('status')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const onSort = (value: string) => {
    if (sort === value) {
      setOrder((old) => (old === 'ASC' ? 'DESC' : 'ASC'))
    } else {
      setSort(value)
      setOrder('ASC')
    }
    getEmployerPlacements({...requests.params, sort, order})
  }

  let itemDisplayType: PlacementsDisplayType = 'all'
  if (routeTab === 'pending') itemDisplayType = 'pending'
  if (routeTab === 'confirmed') itemDisplayType = 'confirmed'

  const HeaderTitle = ({value, title}: {value: string; title: string}) => {
    const className = classNames(styles.sortBtn, {
      [styles.active]: value === sort,
      [styles.desc]: order === 'DESC',
    })

    return (
      <div onClick={() => onSort(value)} className={className}>
        <FormattedMessage id={title} />
        <ArrowDropDown />
      </div>
    )
  }

  return (
    <div className={styles.wrap}>
      <EmployerRequestsLayout
        onSearch={(params) => {
          let statuses: string[] | undefined = []
          if (routeTab !== 'all') {
            statuses = params.statuses || requests?.params?.statuses
          }
          getEmployerPlacements({...requests.params, statuses, ...params})
        }}
        getSeekers={getSeekersFromPlacements}
        tabs={['all', 'pending', 'confirmed']}
        activeTab={routeTab}
        type="placements"
        statusOptions={Object.values(NewPlacementRequestStatuses)}
        candidatesOptions={requests.candidatesOptions}
        isFromPlacements
      >
        <div
          className={classNames(styles.contentHeader, {
            [styles.allAndPending]:
              itemDisplayType === 'all' || itemDisplayType === 'pending',
          })}
        >
          <HeaderTitle value="seekerName" title={edt.candidate} />
          <HeaderTitle value="status" title={edt.status} />
          {(itemDisplayType === 'all' || itemDisplayType === 'pending') && (
            <HeaderTitle value="expiration" title={edt.expire} />
          )}
          {itemDisplayType === 'confirmed' && (
            <HeaderTitle
              value="confirmedBySeekerDate"
              title={edt.candidatesConfirmation}
            />
          )}
          {itemDisplayType === 'confirmed' && (
            <HeaderTitle
              value="confirmedByCompanyUserDate"
              title={edt.yourConfirmation}
            />
          )}
          <div className={styles.leftMargin}>
            <FormattedMessage id={edt.action} />
          </div>
        </div>

        <InfiniteScroll
          loading={requests.loadMoreProgress}
          hasMore={
            totalPlacementRequests &&
            totalPlacementRequests > requests.searchResults.length
              ? true
              : false
          }
          loadMore={() => loadMore(requests.loadMore as string)}
        >
          <div className={styles.allPlacementsItems}>
            {requests.loading ? (
              <div className={styles.loaderContainer}>
                <Loader />
                <span className={styles.loadingText}>
                  <FormattedMessage id={edt.loadingText} />
                </span>
              </div>
            ) : requests.error ? (
              <div>{requests.error.message || 'Error'}</div>
            ) : requests.searchResults.length > 0 ? (
              requests.searchResults.map((request, idx) => (
                <PlacementsListItemContainer
                  request={request}
                  key={`placement-${idx}`}
                  displayType={itemDisplayType}
                  setIsModalOpen={setIsModalOpen}
                />
              ))
            ) : (
              <div className={styles.noResults}>
                <FormattedMessage id={edt.noResults} />
              </div>
            )}
          </div>
        </InfiniteScroll>
      </EmployerRequestsLayout>
      <NoLongerActiveCandidateModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
      />
    </div>
  )
}
