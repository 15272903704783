import {connect} from 'react-redux'

import {SeekerCalendar} from './SeekerCalendar'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {getSpectoToken} from 'Store/Actions'

const mapStateToProps = (state: RootState) => ({
  spectoState: state.specto,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSpectoToken: () => dispatch(getSpectoToken()),
})

export const SeekerCalendarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SeekerCalendar)
