import styles from './HeaderMessagesSection.module.scss'
import {FC} from 'react'
import PopoverList from 'Components/PopoverList'
import {useTranslation} from 'Hooks'
import {MessageCenterTranslations as mct} from 'Services/I18n/Constants/MessageCenter'
import {ProfilePictureType} from 'Interfaces'
import {Close, MoreHoriz} from '@mui/icons-material'
import {BasicTooltip} from 'Components/BasicTooltip'

interface HeaderMessagesSectionProps {
  id: string
  firstName: string | undefined
  lastName: string | undefined
  occupation: string
  company?: string
  avatar?: ProfilePictureType | null
  showClose?: boolean
  onClose?: () => void
  age: string
  city: string
  archiveConversations: (ids: string[]) => void
  unreadConversation: (data: string[]) => void
}

export const HeaderMessagesSection: FC<HeaderMessagesSectionProps> = ({
  id,
  firstName,
  lastName,
  occupation,
  company,
  avatar,
  showClose = false,
  onClose,
  age,
  city,
  archiveConversations,
  unreadConversation,
}) => {
  const translation = useTranslation()

  const actions = [
    {
      text: translation[mct.archive],
      onClick: () => archiveConversations([id]),
    },
    {
      text: translation[mct.markAsUnread],
      onClick: () => unreadConversation([id]),
    },
  ]

  return (
    <div className={styles.container}>
      {avatar && avatar.url ? (
        <img
          className={styles.avatar}
          src={avatar?.url}
          alt={`${firstName} ${lastName}`}
        />
      ) : (
        <>
          {(firstName || lastName) && (
            <div className={styles.avatarLetters}>
              <p>
                {firstName?.[0]?.toUpperCase()}
                {lastName?.[0]?.toUpperCase()}
              </p>
            </div>
          )}
        </>
      )}
      <div className={styles.containerText}>
        <div className={styles.content}>
          <p>
            <span className={styles.nameUser}>
              {firstName && lastName ? (
                <>
                  {firstName} {lastName}
                </>
              ) : null}
            </span>
            <span className={styles.occupation}>
              {`${occupation ? ', ' + occupation : ''}`}
              {`${age ? ', ' + age : ''}`}
              {`${city ? ', ' + city : ''}`}
            </span>
          </p>
        </div>
        <p className={styles.companyName}>{company && company}</p>
      </div>
      {showClose ? (
        <div onClick={onClose} className={styles.closeButtonContainer}>
          <div className={styles.closeButton}>
            <Close />
          </div>
        </div>
      ) : (
        <div className={styles.moreIconBtn}>
          <PopoverList
            triggerIcon={
              <BasicTooltip
                text={translation[mct.manageConversations]}
                placement="top"
              >
                <MoreHoriz />
              </BasicTooltip>
            }
            items={actions}
          />
        </div>
      )}
    </div>
  )
}
