export const employerInfoLandingPage = {
  'employerInfoLandingPage.title': 'Saj sami veste, dragi zaposlovalci, da',
  'employerInfoLandingPage.yellowTitleTxt': 'dobrih kadrov',
  'employerInfoLandingPage.titleLastPart':
    'NI NA PRETEK IN DA JE POT DO NJIH KAR ZAPLETENA',
  'employerInfoLandingPage.paragraphFirstPart':
    'Saj sami veste, dragi zaposlovalci, da dobrih kadrov ni na pretek in da je pot do njih kar zapletena.',
  'employerInfoLandingPage.paragraphSecondPart':
    'Dinamika trga nakazuje na vse večje pomanjkanje talentov in kaže, da je zaposlovanje najboljših strokovnjakov z različnih področij eden glavnih izzivov, s katerimi se morate kadroviki in vodje dandanes soočati.',
  'employerInfoLandingPage.paragraphThirdPart':
    'Se še vedno zanašate na tradicionalne metode iskanja kadrov, ki najpogosteje vključujejo oglaševanje ali poskuse aktivnega pridobivanja pasivnih kandidatov prek določenih družbenih omrežij?',
  'employerInfoLandingPage.paragraphFourthPart':
    'In kako uspešni ste pri tem? Verjetno ne najbolj, saj se drugače ne bi znašli tukaj',
  'employerInfoLandingPage.paragraphFifthPart':
    'Čas je torej, da spišemo scenarij zaposlovanja na novo.',
  'employerInfoLandingPage.subtitle': 'NOVA PRAVILA IGRE',
  'employerInfoLandingPage.subtitleParagraph':
    'Preferenca je platforma, ki povezuje talente in podjetja tako, da se podjetja potegujete za kandidate, ne obratno. Tako zagotavlja popolnoma novo izkušnjo najboljšim kandidatom, hkrati pa omogoča hiter in učinkovit postopek zaposlovanja za vaše podjetje.',
  'employerInfoLandingPage.subtitleParagraphSecond':
    'Na portalu se predhodno preverjeni kandidati predstavijo prek svojih profilov, kjer predstavijo svoje izkušnje, znanja in želje, vi pa dobite dostop do seznama ustreznih kandidatov, s katerimi lahko neposredno komunicirate in opravite razgovore.',
  'employerInfoLandingPage.titleSecond':
    'PRESKOČITE NARAVNOST NA NAJBOLJ KVALIFICIRANE',
  'employerInfoLandingPage.yellowTitleSecond': 'STROKOVNJAKE',
  'employerInfoLandingPage.subTitleSecond': 'Zakaj uporabiti Preferenco?',
  'employerInfoLandingPage.yellowTxtFirst': 'Boljša',
  'employerInfoLandingPage.subParagraphFirst': 'odzivnost kandidatov',
  'employerInfoLandingPage.yellowTxtSecond': 'Učinkovita izraba časa',
  'employerInfoLandingPage.subParagraphSecond': 'pri iskanju in selekciji',
  'employerInfoLandingPage.yellowTxtThird': 'kvalificirane talente',
  'employerInfoLandingPage.subParagraphThird':
    'Najboljša rešitev za podjetja, ki iščete najbolj',
  'employerInfoLandingPage.yellowTxtFourth': 'predhodno preverjeni',
  'employerInfoLandingPage.subParagraphFourthFirstPart': 'Kandidati so',
  'employerInfoLandingPage.subParagraphFourthSecondPart':
    ' prek različnih testiranj in ocene kadrovskega strokovnjaka',
  'employerInfoLandingPage.yellowTxtFifth': 'vnaprej izrazijo svoje želje',
  'employerInfoLandingPage.subParagraphFifthFirstPart': 'Kandidati',
  'employerInfoLandingPage.subParagraphFifthSecondPart':
    'in pričakovanja, tudi glede plače',
  'employerInfoLandingPage. yellowTxtSixth': 'Krajši postopek zaposlovanja',
  'employerInfoLandingPage.yellowTxtSeventh':
    'Celoten postopek poteka po spletu',
  'employerInfoLandingPage.paragraphsWrapFirst':
    'Si predstavljate, za koliko bi skrajšali postopek zaposlovanja, če bi se na vaše oglase prijavili le najbolj kvalificirani in preverjeni kandidati, ki so pripravljeni na razgovor z vami?',
  'employerInfoLandingPage.paragraphsWrapSecond':
    'Podjetja si lahko zagotovite izbiranje samo med kandidati, s katerimi so vaše želje in interesi združljivi. S tem se zmanjša verjetnost, da kandidat izstopi iz postopka zaradi preproste neusklajenosti preferenc. Poleg tega vsi sprejeti kandidati na portalu precej aktivneje iščejo svojo naslednjo priložnost za zaposlitev in so zato veliko odzivnejši v celotnem procesu zaposlovanja.',
  'employerInfoLandingPage.yellowButton': 'BREZPLAČNA KARIERNA PODPORA',
}
