export const myProjects = {
  'myprojects.deleteProject': 'Are you sure you want to delete this project?',
  'myprojects.createNewProject': 'Create new project',
  'myprojects.firstName': 'First name',
  'myprojects.lastName': 'Last name',
  'myprojects.jobTitle': 'Job title',
  'myprojects.resetSearch': 'Reset search',
  'myprojects.candidates': 'Candidates',
  'myprojects.edit': 'Edit',
  'myprojects.archive': 'Archive',
  'myprojects.delete': 'Delete',
  'myprojects.save': 'Save',
  'myprojects.projectStatus': 'Project status',
  'myprojects.project': 'Project',
  'myprojects.open': 'Open',
  'myprojects.addNewProject': 'Add new project',
  'myprojects.projectName': 'Project name',
  'myprojects.editProject': 'Edit project',
  'myprojects.myProjectsTitle': 'My projects',
  'myprojects.active': 'Active',
  'myprojects.archived': 'Archived',
  'myprojects.loading': 'Loading...',
  'myprojects.searchCandidatesLink': 'Search candidates',
}
