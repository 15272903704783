import {FC, ReactNode} from 'react'
import classnames from 'classnames'
import {useMeasure} from '@react-hookz/web'
import {FormattedMessage} from 'react-intl'

import styles from './YellowText.module.scss'
import YellowPaint from 'Components/YellowPaint'

interface YellowTextType {
  text?: string
  className?: string
  children?: ReactNode | null
  addCutPaintWidth?: number
  addCutPaintHeight?: number
}

export const YellowText: FC<YellowTextType> = ({
  text,
  className,
  children = null,
  addCutPaintWidth = 0,
  addCutPaintHeight = 0,
}) => {
  const [size, ref] = useMeasure<HTMLSpanElement>()
  const padding = 10

  return (
    <span className={classnames(styles.wrap, className)}>
      <YellowPaint
        width={size ? size.width + addCutPaintWidth + padding : 0}
        height={size ? size.height * 0.5 + addCutPaintHeight : 0}
        className={styles.svg}
      />
      <span ref={ref} className={styles.text}>
        {text ? <FormattedMessage id={text} /> : children}
      </span>
    </span>
  )
}
