import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import classnames from 'classnames'
import styles from './ExpiredIcon.module.scss'

export interface ExpiredIconProps {
  className?: string
}

export const ExpiredIcon = ({className}: ExpiredIconProps): JSX.Element => {
  return (
    <ReportProblemOutlinedIcon
      className={classnames(styles.expiredIcon, className)}
    />
  )
}
