import {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import classnames from 'classnames'
import {useLocation, useHistory} from 'react-router-dom'
import {ArrowDropUp, ArrowDropDown} from '@mui/icons-material'

import styles from './Facet.module.scss'
import Checkbox from 'Components/Inputs/Checkbox'
import {
  BaseTaxonomyType,
  DataType,
  FacetType,
  SearchControlFormikValuesType,
  TaxonomiesStateType,
} from 'Interfaces'
import {getFacetName} from './getFacetName'
import {getFacetProp} from './getFacetProp'
import {CandidateSearchTranslations as cs} from 'Services/I18n/Constants'
import {useTranslation} from 'Hooks'
import {taxonomiesToUrl, urlToTaxonomies} from 'Utils/Search'
import {orderBy} from 'lodash'
import {ItemType} from 'Components/Inputs/TypeAheadImproved'
interface FacetsProps {
  categoryName: string
  items: FacetType[]
  getSearchResults: (values: SearchControlFormikValuesType) => void
  taxonomies: TaxonomiesStateType
  searchKeywords: DataType<BaseTaxonomyType>
}

export const Facet: FC<FacetsProps> = ({
  categoryName,
  items,
  taxonomies,
  searchKeywords,
}) => {
  const [open, setOpen] = useState(true)
  const [more, setMore] = useState(false)
  const translation = useTranslation()
  const ref = useRef(null)
  const location = useLocation()
  const history = useHistory()
  const [checkboxes, setCheckboxes] = useState({})

  const handleCheck = useCallback(
    (index: number, f: FacetType) => {
      const newValues = urlToTaxonomies(
        taxonomies,
        searchKeywords,
        location.search
      )
      const catName = getFacetProp(categoryName)
      if (checkboxes[index]) {
        newValues[catName] = newValues[catName].filter(
          (v: ItemType) => v.id !== f.facet.id
        )
      } else {
        if (!newValues[catName]) {
          newValues[catName] = []
        }
        newValues[catName] = [
          ...newValues[catName],
          taxonomies[catName].data.find((t) => t.id === f.facet.id),
        ]
      }

      setCheckboxes({...checkboxes, [index]: !checkboxes[index]})
      history.push(`${location.pathname}?${taxonomiesToUrl(newValues)}`)
    },
    [taxonomies, searchKeywords, categoryName, checkboxes]
  )

  const name = getFacetName(categoryName)

  const sortedItems = useMemo(() => {
    let sortedItems = orderBy(
      items,
      [(item) => item.count > 0, 'count'],
      ['asc', 'desc']
    )
    return sortedItems
  }, [items])

  useEffect(() => {
    setCheckboxes(
      Object.assign(
        {},
        sortedItems.map((item) => item.count === -1)
      )
    )
  }, [sortedItems])

  return name !== '' ? (
    <div className={styles.FacetWrapper}>
      <div className={classnames(styles.FacetContainer, {[styles.open]: open})}>
        <div
          className={styles.title}
          onClick={() => {
            setOpen(!open)
          }}
          ref={ref}
        >
          <div className={styles.titleTxt}>{name}</div>
          {open ? (
            <ArrowDropUp className={styles.arrowIcon} />
          ) : (
            <ArrowDropDown className={styles.arrowIcon} />
          )}
        </div>
        {sortedItems.slice(0, more ? sortedItems.length : 5).map((f, index) => {
          return (
            <div className={styles.CheckboxContainer} key={index}>
              <Checkbox
                value={!!checkboxes[index]}
                onChange={(_): void => {
                  handleCheck(index, f)
                }}
                name={`${categoryName}-${index}`}
                className={styles.checkboxComponent}
              >
                <span className={styles.labelTxt}>{f.facet.translation}</span>
                {f.count > 0 && (
                  <span className={styles.number}>&nbsp;({f.count})</span>
                )}
              </Checkbox>
            </div>
          )
        })}
        {sortedItems.length > 5 ? (
          <div className={styles.showMore}>
            <label
              className={styles.showMoreTxt}
              onClick={() => {
                setMore(!more)
              }}
            >
              {more
                ? translation[cs.showLessButton]
                : translation[cs.showMoreButton]}
            </label>
          </div>
        ) : null}
      </div>
    </div>
  ) : null
}
