import {StateType, ImageType, BaseType} from './BaseType'

export enum TestimonialsUserType {
  SEEKER = 'seeker_testimonial',
  COMPANY_USER = 'company_user_testimonial',
}

export enum ConsentStatus {
  GRANTED = 'granted',
  REVOKED = 'revoked',
  EXPIRED = 'expired',
}

export type InviteFriendFormikType = {
  from: string
  to: string
  subject: string
  message: string
  newslettersConsent: boolean
  thirdPartyMarketingConsent: boolean
}

export type GetConsentsParamsType = {
  email: string
  hash: string
}

export type TestimonialType = {
  id: string
  text: string
  name: string
  type: TestimonialsUserType
  image: ImageType
}

export type TopCompaniesType = {
  id: string
  name: string
  homepageDisplaySort: number
  blackAndWhiteLogotype: ImageType
}

export type NewsletterConsent = {
  id: string
  name?: string
  email?: string
  status: ConsentStatus
}

export type UpdateNewsletterConsentType = {
  email: string
  hash: string
  consents: NewsletterConsent[]
}

export type UpdateNewsletterConsentResponseType = {
  id: string
  name?: string
  status?: ConsentStatus
  consentChangeLogs: BaseType[]
}[]

export type UnsubscribeReason = {
  reason: string
  checked?: boolean
  text?: string
}

export type LogGuestConsentChangeType = {
  consentChangeLogIds: string[]
  reasons: UnsubscribeReason[]
}

export type HomepageStateType = {
  inviteFriend: StateType<boolean | undefined>
  consents: StateType<NewsletterConsent[]>
  consentsReasonLog: StateType<string[] | undefined>
  testimonials: StateType<TestimonialType[]>
  topCompanies: StateType<TopCompaniesType[]>
}
