import {FC} from 'react'
import styles from './ItemTextWithStars.module.scss'
import StarIcon from '@mui/icons-material/Star'
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined'

import classNames from 'classnames'

interface ItemTextWithIconStars {
  skill: any
}

export const ItemTextWithStars: FC<ItemTextWithIconStars> = ({skill}) => {
  const levelCnt = parseInt(skill.skillLevel?.translation.substring(0, 1))
  const stars = []
  for (let i = 0; i < 5; i++) {
    if (i < levelCnt) {
      stars[i] = <StarIcon key={i} sx={{fontSize: 24, color: '#000000'}} />
    } else {
      stars[i] = (
        <StarBorderOutlinedIcon key={i} sx={{fontSize: 24, color: '#949494'}} />
      )
    }
  }

  return (
    <>
      <div className={classNames(styles.container)}>
        <div className={styles.containerRight}>
          <div className={styles.icon}>{stars}</div>

          <p className={styles.textIcon}>
            {skill.skillLevel?.translation.split('-')[1].trim()}
          </p>
        </div>
      </div>
    </>
  )
}
