import * as Yup from 'yup'
import {PhoneNumber, PostalCode} from 'Validations'
const includesNumber = /^.*\d.*$/

const Validation = Yup.object().shape({
  phoneNumber: PhoneNumber.required('Phone number is required'),
  email: Yup.string().email('Email is invalid'),
  streetAddress: Yup.string()
    .matches(includesNumber, 'Include street number')
    .max(50, 'Must be 50 characters or less')
    .required('Street and number are required'),
  postalCode: PostalCode,
  town: Yup.object().nullable().required('Town is required'),
  country: Yup.object().nullable().required('Country is required'),
})

export default Validation
