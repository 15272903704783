export const generalAssessmentsTranslation = {
  'generalassessmentstranslations.title': 'My Assessments',
  'generalassessmentstranslations.personalityTestTitle': 'Personality tests',
  'generalassessmentstranslations.languageTestTitle': 'Language tests',
  'generalassessmentstranslations.skillsTestTitle': 'Skills tests',
  'generalassessmentstranslations.testTypeLabel': 'TEST TYPE',
  'generalassessmentstranslations.testProviderLabel': 'PROVIDER',
  'generalassessmentstranslations.testDateLabel': 'DATE OF TEST',
  'generalassessmentstranslations.testExpirationDateLabel': 'EXPIRATION DATE',
  'generalassessmentstranslations.testInfoLabel': 'INFO',
  'generalassessmentstranslations.testActionLabel': 'ACTION',
  'generalassessmentstranslations.startTestText': 'Start test',
  'generalassessmentstranslations.viewResultsText': 'View results',
  'generalassessmentstranslations.assessments': 'Assessments',
  'generalassessmentstranslations.pendingButtonText': 'Pending',
  'generalassessmentstranslations.termsOfUse': 'Terms of use',
  'generalassessmentstranslations.privacyPolicy': 'Privacy Policy',
  'generalassessmentstranslations.cookies': 'Cookies',
  'generalassessmentstranslations.authors': 'Authors',
  'generalassessmentstranslations.assessmentsSuccess': 'Assessment success',
  'generalassessmentstranslations.assessmentsSuccessMessage':
    'You have successfully completed Psychometric test. When results are ready you will be notified by email.',
  'generalassessmentstranslations.tests': 'tests',
  'generalassessmentstranslations.personality': 'Personality',
  'generalassessmentstranslations.language': 'Language',
  'generalassessmentstranslations.assessmentConsent': 'Assessment consent',
  'generalassessmentstranslations.unlimited': 'Unlimited',
  'generalassessmentstranslations.download': 'Download',
  'generalassessmentstranslations.print': 'Print',
  'generalassessmentstranslations.resultsValidUntil':
    'The results are valid until',
  'generalassessmentstranslations.backToAssessments': 'Back to Assessments',
  'generalassessmentstranslations.generalProfile': 'General profile',
}
