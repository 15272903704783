import {connect} from 'react-redux'

import {Skills} from './Skills'

import {RootState} from 'Store/Reducers'
import {SkillType, AddSkillType} from 'Interfaces'
import {
  addSkill,
  deleteSkill,
  editSkill,
  getSkillLevels,
  getSkills,
} from 'Store/Actions'
import {AppDispatch} from 'Store'
import {canChangeProfile} from 'Utils/Profile'

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addSkill: (value: AddSkillType) => dispatch(addSkill(value)),
  editSkill: (value: SkillType) => dispatch(editSkill(value)),
  deleteSkill: (value: SkillType) => dispatch(deleteSkill(value)),
  getTaxonomies: () => {
    dispatch(getSkillLevels())
    dispatch(getSkills())
  },
})

const mapStateToProps = (state: RootState) => ({
  profile: state.seeker,
  skills: state.seeker.data?.full?.skills,
  taxonomies: state.taxonomies,
  canChange: canChangeProfile(state.auth.data?.rjbProfileStatus),
})

export const SkillsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Skills)
