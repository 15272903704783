import {Socket} from 'socket.io-client'
import {ErrorType} from './BaseType'

export enum PARTICIPANTS_TYPE {
  COMPANY_USER = 'company_user',
  SEEKER = 'seeker',
  TSA = 'tsa',
}

export enum MESSAGE_TYPE {
  ALL_MESSAGES = 'all_messages',
  ARCHIVED = 'archived',
  UNREAD = 'unread',
}
export interface RecipientType {
  id: string
  firstName: string
  lastName: string
  dateOfBirth: string | Date
  publicOccupation: {
    translation: string
  }
  town: {
    translation: string
  }
  profilePicture?: ProfilePictureType
}
export interface ItemType {
  id: string
  type: string
  seeker?: RecipientType | null
  companyUser?: RecipientType | null
  admin: RecipientType | null
}

export interface ConversationMessageType {
  body: string
  id: string
  senderFirstName: string
  senderLastName: string
  senderProfilePicture: ProfilePictureType
  senderUserId: string
  title: string
  dateSent: string
  conversationId?: string
}

export interface ConversationType {
  id: string
  conversationUsers: MessageUserType[]
  message: ConversationMessageType
}

export interface OpenedConversationType {
  messages: ConversationMessageType[]
  total: number
  conversation: ConversationType
}

export interface ProfilePictureType {
  id: string
  url: string
  originalName: string
}

export interface MessageUserType {
  conversationLifeStatus: string
  conversationReadStatus: string
  firstName: string
  id: string
  lastName: string
  profilePicture: ProfilePictureType
  userId: string
}

export interface ConversationsSearchParamsType {
  searchText?: string
  searchConversationStatus?: MESSAGE_TYPE
  participantsType?: PARTICIPANTS_TYPE
}

export interface MessagesSearchParamsType {
  conversationId: string
  searchText?: string
  searchMessageStatus?: string
}

export interface MessageCenterStateType {
  data: ConversationType[]
  params?: ConversationsSearchParamsType
  loading: boolean
  error?: ErrorType
  loadMoreConversations: string | undefined
  loadMoreConversationsProgress: boolean
  loadMoreMessages: string
  loadMoreMessagesProgress: boolean
  openedConversation?: OpenedConversationType
  messageRecipients: ItemType[]
  unreadCount?: number
  specificParticipiantUnreadCount?: number
  socket?: SocketType
}

export type MessagesSearchResultsType = {
  data: Array<ConversationType>
  loadMoreConversations: string | undefined
  loadMoreMessages: string
}

export type MessagesConversationsSearchResultsType = {
  data: {items: Array<ConversationType>}
  loadMoreConversations: string | undefined
  loadMoreMessages: string
}

export type CreateMessageType = {
  body: string
  conversationId: string
  title?: string
}

export type CreateConversationType = {
  title?: string
  messageBody: string
  otherParticipantsUserIds: string[]
}

export type CreateMessageSocketType = {
  createMessageRequest: CreateMessageType
  userId: string
}

export type SearchConversationsType = {
  loadMoreConversations: string
  loadMoreMessages: string
  response: {
    data: {items: Array<ConversationType>}
  }
}

export type LoadMoreMessagesType = {
  url: string
  conversationId: string
}

export type SocketType = {
  connectionStatus: string
  client?: Socket
}
