import {clearSocketEvents} from 'Services/I18n/Utils/Utils'
import {OpenedConversationType} from 'Interfaces/MessageCenterType'
import {all, put, takeLatest, call, select} from 'redux-saga/effects'
import * as type from '../types'
import {
  ActionType,
  ConversationsSearchParamsType,
  CreateMessageType,
  ConversationType,
  CreateConversationType,
  MessageCenterStateType,
  LoadMoreMessagesType,
  ConversationMessageType,
} from 'Interfaces'
import API from 'Services/API'
import qs from 'qs'
import {RootState} from 'Store/Reducers'
import {generateURL} from 'Utils/CommonHelpers'
import {END, eventChannel, EventChannel} from 'redux-saga'
import io, {Socket} from 'socket.io-client'
import store from 'Store'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {PARTICIPANTS_TYPE} from 'Interfaces'

const searchConversationsURL = '/message-center/conversations'

export let socket: Socket

const allMessagesSelector = (state: RootState) => state.messageCenter.data
const openedConversationSelector = (state: RootState) =>
  state.messageCenter.openedConversation
const {pathname} = window.location
const isTSA = pathname === '/messages/tsa'
const isEmployer = pathname === getRoute(AppRoute.CandidateMessages)
const participantsType = isTSA
  ? PARTICIPANTS_TYPE.TSA
  : isEmployer
  ? PARTICIPANTS_TYPE.SEEKER
  : PARTICIPANTS_TYPE.COMPANY_USER

const recievedData = (socket: Socket) => {
  socket.on('disconnect', () => {
    //socket.connect()
    console.log('messageCenter log - socket disconnected')
    clearSocketEvents(socket)
    store.dispatch({
      type: type.messageCenter.socketIO.setSocket,
      payload: {
        connectionStatus: 'OFF',
        client: socket,
      },
    })
    window.app.chat.socket = undefined
  })
  return eventChannel((emitter) => {
    socket.on('connect', () => {
      console.log('messageCenter log - connection established')
      store.dispatch({
        type: type.messageCenter.socketIO.setSocket,
        payload: {
          connectionStatus: 'ON',
          client: socket,
        },
      })
      window.app.chat.socket = socket
      socket.emit('join conversations')
    })

    socket.on('conversations joined', (data) => {
      console.log('joined conversations', data)
    })
    socket.on('conversation created', (data) => {
      console.log('conversation created', data)
      store.dispatch({
        type: type.messageCenter.selectConversation.requested,
        payload: data,
      })
    })
    socket.on('added to conversation', (data) => {
      console.log('added to conversation', data)
      store.dispatch({
        type: type.messageCenter.socketIO.events.addedToConversation,
        payload: data,
      })
    })
    socket.on('message created', (data) => {
      console.log('message created', data)
      store.dispatch({
        type: type.messageCenter.socketIO.events.messageCreated,
        payload: data,
      })
      //return emitter(messageCreated(data)) // this is not working because that we used store.dispatch
    })
    socket.on('message received', (data) => {
      console.log('message recieved', data)
      store.dispatch({
        type: type.messageCenter.conversationRecieved.requested,
        payload: data,
      })
    })
    socket.on('conversations read', (data) => {
      console.log('conversation read', data)
      store.dispatch({
        type: type.messageCenter.socketIO.events.conversationsRead,
        payload: data,
      })
    })
    socket.on('conversations unread', (data) => {
      console.log('conversation unread', data)
      store.dispatch({
        type: type.messageCenter.socketIO.events.conversationsUnread,
        payload: data,
      })
    })
    socket.on('unread conversations count', (data) => {
      console.log('unread conversations count', data)
      store.dispatch({
        type: type.messageCenter.socketIO.events.unreadConversationsCount,
        payload: data?.conversationsUnreadCount,
      })
    })
    if (participantsType === 'seeker') {
      socket.on('unread conversations count - seekers', (data) => {
        console.log('unread conversations count - seekers', data)
        store.dispatch({
          type: type.messageCenter.socketIO.events
            .unreadConversationsCountSeekers,
          payload: data?.conversationsUnreadCount,
        })
      })
    } else if (participantsType === 'company_user') {
      socket.on('unread conversations count - company users', (data) => {
        console.log('unread conversations count - company users', data)
        store.dispatch({
          type: type.messageCenter.socketIO.events
            .unreadConversationsCountCompanyUsers,
          payload: data?.conversationsUnreadCount,
        })
      })
    } else {
      socket.on('unread conversations count - tsa', (data) => {
        console.log('unread conversations count - tsa', data)
        store.dispatch({
          type: type.messageCenter.socketIO.events
            .unreadConversationsCountCompanyUsers,
          payload: data?.conversationsUnreadCount,
        })
      })
    }

    socket.on('connect_failed', () => {
      console.log('messageCenter log - connection issue')
    })

    socket.on('exception', (e) => {
      console.log('messageCenter log - exception', e)
    })

    let numberOfErrors = 0

    socket.on('connect_error', (e) => {
      console.log(
        'messageCenter log - connection error',
        numberOfErrors,
        JSON.stringify(e)
      )
      if (socket.io.engine.transport.name === 'polling') {
        numberOfErrors += 1
        if (numberOfErrors > 5) {
          console.log(
            'messageCenter log - close connection because stuck in the polling'
          )
          socket.close()
        }
      }
    })

    return () => {
      emitter(END)
    }
  })
}

function* startMessageChannel() {
  const baseURL = process.env.REACT_APP_API_BASE_URL
  const socketServerURL = `${baseURL}/message-center`
  let socketHeaders = {}

  if (socketHeaders) {
    // const userid = localStorage.getItem('userId') || null
    const userid: string = yield select(
      (state: RootState) => state.auth.data?.id
    )

    if (userid) {
      socketHeaders['userid'] = userid
    } else if (socketHeaders['userid']) {
      delete socketHeaders['userid']
    }
  }

  const socketClientOptions = {
    extraHeaders: socketHeaders,
    withCredentials: true,
    secure: true,
    reconnection: true,
  }

  socket = io(socketServerURL, socketClientOptions)
  const socketChannel: EventChannel<unknown> = yield call(recievedData, socket)

  console.log(socketChannel, 'socket channel')
}

function* searchConversations(
  action: ActionType<ConversationsSearchParamsType>
) {
  const searchConversationsURL = '/message-center/conversations'
  const urlWithParams = generateURL(searchConversationsURL, action.payload)

  const {response, error} = yield call(API.get, urlWithParams)

  if (response) {
    // Generate load more URL
    const itemsFetched = response.data.items.length
    const total = response.data.total
    const params = action.payload
    let loadMoreConversations = undefined
    if (total > itemsFetched) {
      loadMoreConversations = generateURL(searchConversationsURL, params, 20)
    }

    yield put({
      type: type.messageCenter.search.succeeded,
      payload: {
        response,
        loadMoreConversations,
      },
    })
  } else {
    yield put({type: type.messageCenter.search.failed, payload: error})
  }
}

function* selectConversation(action: ActionType<ConversationType>) {
  if (!action.payload?.id) {
    yield put({
      type: type.messageCenter.selectConversation.succeeded,
      payload: {},
    })
  } else {
    const searchParams: ConversationsSearchParamsType = yield select(
      (state: RootState) => state.messageCenter.params
    )
    const params = qs.stringify(
      {
        pageSize: 5,
        startFrom: 0,
        searchText: searchParams?.searchText,
        conversationId: action.payload?.id,
        ...(searchParams?.searchConversationStatus === 'archived' && {
          searchMessageStatus: 'archived',
        }),
      },
      {skipNulls: true}
    )

    //const urlWithParams = generateURL(searchAndFilterMessagesUrl, params)
    const {response, error} = yield call(
      API.get,
      `/message-center/search-and-filter-messages?${params}`
    )

    if (response) {
      // Generate load more URL
      const newParams = qs.stringify(
        {
          pageSize: 5,
          startFrom: 5,
          searchText: searchParams?.searchText,
          conversationId: action.payload?.id,
          ...(searchParams?.searchConversationStatus === 'archived' && {
            searchMessageStatus: 'archived',
          }),
        },
        {skipNulls: true}
      )
      const itemsFetched = response.data.items.length
      const total = response.data.total
      let loadMoreMessages = undefined
      if (total > itemsFetched) {
        loadMoreMessages = `/message-center/search-and-filter-messages?${newParams}`
      }

      yield put({
        type: type.messageCenter.selectConversation.succeeded,
        payload: {
          messages: response.data.items,
          total: response.data.total,
          conversation: action.payload,
          loadMoreMessages,
        },
      })

      store.dispatch({
        type: type.messageCenter.socketIO.events.conversationCreated,
        payload: action.payload,
      })
    } else {
      yield put({
        type: type.messageCenter.selectConversation.failed,
        payload: error,
      })
    }
  }
}

function* getMessageRecipients() {
  const {response, error} = yield call(API.get, '/message-center/recipients')

  if (response) {
    yield put({
      type: type.messageCenter.getMessageRecipients.succeeded,
      payload: response.data,
    })
  } else {
    yield put({
      type: type.messageCenter.getMessageRecipients.failed,
      payload: error,
    })
  }
}

function* archiveConversations(action: ActionType<string[]>) {
  const {response, error} = yield call(
    API.patch,
    `/message-center/conversations-archive`,
    {
      ids: action.payload,
    }
  )

  if (response) {
    yield put({
      type: type.messageCenter.archiveConversation.succeeded,
      payload: response.data,
    })
  } else
    yield put({
      type: type.messageCenter.archiveConversation.failed,
      payload: error,
    })
}

function* getUnreadConversationsCount() {
  const {response, error} = yield call(
    API.get,
    `/message-center/unread-conversations-count`
  )

  if (response) {
    yield put({
      type: type.messageCenter.getUnreadConversationsCount.succeeded,
      payload: response.data.conversationsUnreadCount,
    })
  } else {
    yield put({
      type: type.messageCenter.getUnreadConversationsCount.failed,
      payload: error,
    })
  }
}

function* getSpecificParticipiantUnreadConversationsCount(
  action: ActionType<string>
) {
  const {response, error} = yield call(
    API.get,
    `/message-center/unread-conversations-count${
      action.payload ? '?participantsType=' + action.payload : ''
    }`
  )

  if (response) {
    yield put({
      type: type.messageCenter.getSpecificParticipiantUnreadConversationsCount
        .succeeded,
      payload: response.data.conversationsUnreadCount,
    })
  } else {
    yield put({
      type: type.messageCenter.getSpecificParticipiantUnreadConversationsCount
        .failed,
      payload: error,
    })
  }
}

function* loadMoreMessages(action: ActionType<LoadMoreMessagesType>) {
  const {response, error} = yield call(API.get, action?.payload?.url as string)

  if (response) {
    // Generate new load more URL
    const total = response.data.total
    const allMessages: MessageCenterStateType = yield select(
      (state: RootState) => state.messageCenter
    )
    const totalItemsFetched =
      response?.data?.items?.length +
      allMessages?.openedConversation?.messages.length

    const searchParams: ConversationsSearchParamsType = yield select(
      (state: RootState) => state.messageCenter.params
    )

    const newParams = qs.stringify(
      {
        pageSize: 5,
        startFrom: totalItemsFetched,
        searchText: searchParams?.searchText,
        conversationId: action.payload?.conversationId,
      },
      {skipNulls: true}
    )

    let loadMoreMessages = undefined
    if (total > totalItemsFetched) {
      loadMoreMessages = `/message-center/search-and-filter-messages?${newParams}`
    }

    yield put({
      type: type.messageCenter.loadMoreMessages.succeeded,
      payload: {response, loadMoreMessages},
    })
  } else {
    yield put({
      type: type.messageCenter.loadMoreMessages.failed,
      payload: error,
    })
  }
}

function* loadMoreConversations(action: ActionType<string>) {
  const {response, error} = yield call(API.get, action.payload as string)

  if (response) {
    // Generate new load more URL
    const total = response.data.total
    const allMessages: MessageCenterStateType = yield select(
      (state: RootState) => state.messageCenter
    )
    const totalItemsFetched =
      response.data.items.length + allMessages.data.length

    let loadMoreConversations = undefined
    if (total > totalItemsFetched) {
      loadMoreConversations = generateURL(
        searchConversationsURL,
        undefined,
        totalItemsFetched
      )
    }

    yield put({
      type: type.messageCenter.loadMoreConversations.succeeded,
      payload: {
        response,
        loadMoreConversations,
      },
    })
  } else {
    yield put({
      type: type.messageCenter.loadMoreConversations.failed,
      payload: error,
    })
  }
}

function* getConversationByUserIds(action: ActionType<string>) {
  const {response, error} = yield call(
    API.get,
    `/message-center/conversation-by-user-ids?${action.payload}`
  )

  if (response) {
    yield put({
      type: type.messageCenter.getConversationByUserIds.succeeded,
      payload: response,
    })
  } else {
    yield put({
      type: type.messageCenter.getConversationByUserIds.failed,
      payload: error,
    })
  }
}

function* createMessageSocket(action: ActionType<CreateMessageType>) {
  console.log('messageCenter log - create message')
  socket.emit('create message', action.payload)
}

function* readConversationSocket(action: ActionType<string[]>) {
  console.log('messageCenter log - read conversations', action.payload)
  socket.emit('read conversations', {ids: action.payload})
}

function* unreadConversationSocket(action: ActionType<string[]>) {
  console.log('messageCenter log - unread conversations')
  socket.emit('unread conversations', {ids: action.payload})
}

function* createConversationSocket(action: ActionType<CreateConversationType>) {
  console.log('messageCenter log - create conversation')
  socket.emit('create conversation', action.payload)
}

function* conversationRecieved(action: ActionType<ConversationMessageType>) {
  const allConversations: ConversationType[] = yield select(allMessagesSelector)
  const openedConversation: OpenedConversationType = yield select(
    openedConversationSelector
  )

  // find do conversation already exists in data
  const newConversationExistsInData = allConversations.find((conversation) => {
    return conversation.id === action.payload?.conversationId
  })

  // use this case if conversation already exists
  if (newConversationExistsInData) {
    yield put({
      type: type.messageCenter.socketIO.events.messageReceived,
      payload: action.payload,
    })

    // if conversation is opened and message is recieved dispatch read socket action
    if (
      openedConversation?.conversation?.id === action.payload?.conversationId
    ) {
      yield put({
        type: type.messageCenter.socketIO.events.readConversation,
        payload: [action.payload.conversationId],
      })
    }
  } else {
    // use this cases for newly created conversation, we first need to get one specific message from this api and then push it to reducer, if user dont have conversation
    const {response, error} = yield call(
      API.get,
      `/message-center/conversations/${action.payload?.conversationId}`
    )

    if (response) {
      yield put({
        type: type.messageCenter.conversationRecieved.succeeded,
        payload: response.data,
      })
    } else {
      yield put({
        type: type.messageCenter.conversationRecieved.failed,
        payload: error,
      })
    }
  }
}

export default function* MessageCenterSaga(): Generator {
  yield all([
    yield takeLatest(
      type.messageCenter.socketIO.startMessageChannel,
      startMessageChannel
    ),
    takeLatest(type.messageCenter.search.requested, searchConversations),
    takeLatest(
      type.messageCenter.selectConversation.requested,
      selectConversation
    ),
    takeLatest(type.messageCenter.loadMoreMessages.requested, loadMoreMessages),
    takeLatest(
      type.messageCenter.loadMoreConversations.requested,
      loadMoreConversations
    ),
    takeLatest(
      type.messageCenter.archiveConversation.requested,
      archiveConversations
    ),
    takeLatest(
      type.messageCenter.socketIO.events.createMessage,
      createMessageSocket
    ),
    takeLatest(
      type.messageCenter.socketIO.events.createConversation,
      createConversationSocket
    ),
    takeLatest(
      type.messageCenter.getMessageRecipients.requested,
      getMessageRecipients
    ),
    takeLatest(
      type.messageCenter.getUnreadConversationsCount.requested,
      getUnreadConversationsCount
    ),
    takeLatest(
      type.messageCenter.getSpecificParticipiantUnreadConversationsCount
        .requested,
      getSpecificParticipiantUnreadConversationsCount
    ),
    takeLatest(
      type.messageCenter.socketIO.events.readConversation,
      readConversationSocket
    ),
    takeLatest(
      type.messageCenter.socketIO.events.unreadConversation,
      unreadConversationSocket
    ),
    takeLatest(
      type.messageCenter.conversationRecieved.requested,
      conversationRecieved
    ),
    takeLatest(
      type.messageCenter.getConversationByUserIds.requested,
      getConversationByUserIds
    ),
  ])
}
