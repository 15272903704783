import {FC} from 'react'
import classnames from 'classnames'
import styles from './HrWithText.module.scss'
import {FormattedMessage} from 'react-intl'

interface HrProps {
  className?: string
  theme?: string
  text?: string
}

export const HrWithText: FC<HrProps> = ({className, theme = 'hr', text}) => {
  return (
    <div className={styles.hrWrap}>
      <hr
        className={classnames(styles.hr, className, {
          [styles[theme]]: theme,
        })}
      />
      {text && (
        <div className={styles.textWrap}>
          <FormattedMessage id={text} />
        </div>
      )}
    </div>
  )
}
