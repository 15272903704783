import {FC, useEffect, useMemo, useState} from 'react'
import Modal from 'Components/Modal'
import SectionHeader from 'Components/Profile/SectionHeader'
import SectionItem from 'Components/Profile/SectionItem'
import ItemHeader from 'Components/Profile/ItemHeader'
import styles from './Languages.module.scss'
import {CommentBubble} from 'Components/CommentBubble/CommentBubble'
import Hr from 'Components/Hr'
import Validation from './Validation'
import Title from 'Components/Title'
import {LanguageType, SeekerStateType, TaxonomiesStateType} from 'Interfaces'
import {useFormik} from 'formik'
import Dropdown from 'Components/Inputs/Dropdown'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import {Grid} from '@mui/material'
import {Sort} from './Sort'
import {ProfileTranslations as pt} from 'Services/I18n/Constants'
import ShowMore from '../ShowMore'
import {Language, DeleteOutline} from '@mui/icons-material'
import {cloneDeep} from 'lodash'

interface iLanguageProps {
  languages: LanguageType[]
  profile: SeekerStateType
  taxonomies: TaxonomiesStateType
  addLanguage: (value: LanguageType) => void
  editLanguage: (value: LanguageType) => void
  deleteLanguage: (value: LanguageType) => void
  getTaxonomies: () => void
  canChange?: boolean
}

export const Languages: FC<iLanguageProps> = ({
  languages = [],
  profile,
  taxonomies,
  addLanguage,
  editLanguage,
  deleteLanguage,
  getTaxonomies,
  canChange = false,
}: iLanguageProps) => {
  const defLengForEdt: any[] = []
  const [modalAddOpen, setModalAddOpen] = useState(false)
  const [modalEditOpen, setModalEditOpen] = useState(false)
  const [languagesForEdit, setLanguagesForEdit] = useState(defLengForEdt)
  const [showMoreIndex, setShowMoreIndex] = useState(5)

  const localLanguages = useMemo(() => {
    return languages.sort(Sort).slice(0, showMoreIndex)
  }, [languages, showMoreIndex])

  const dropdownLanguages = useMemo(
    () =>
      taxonomies.languageLevels.data.map((languageLevel) => {
        return {
          id: languageLevel.id,
          translation: `${languageLevel.level} - ${languageLevel.translation}`,
        }
      }),
    [taxonomies.languageLevels.data]
  )

  const openEdit = () => {
    getTaxonomies()
    setModalEditOpen(true)
    setLanguagesForEdit([...languages])
  }

  const onRemoveLang = (id: string) => {
    const langsAfterRemove = languagesForEdit.map((lang) => {
      if (lang.id === id) {
        lang.deleted = true
      }
      return {...lang}
    })
    setLanguagesForEdit([...langsAfterRemove])
  }

  const onChangeLangLevel = (id: string, levelId?: string) => {
    const langs = cloneDeep(languagesForEdit).map((lang) => {
      if (lang.id === id) {
        if (lang.languageLevel) {
          lang.languageLevel.id = levelId
        } else
          lang['languageLevel'] = {
            id: levelId,
            translation:
              taxonomies.languageLevels?.data.find((l) => {
                l.id === levelId
              })?.translation || '',
          }
        lang.changed = true
      }
      return {...lang}
    })
    setLanguagesForEdit([...langs])
  }

  const updateLanguages = () => {
    languagesForEdit.map((lang) => {
      if (lang.changed) {
        editLanguage({
          id: lang.id,
          languageTaxonomyId: lang.languageTaxonomy.id,
          languageLevelId: lang.languageLevel.id,
        })
      }

      if (lang.deleted) {
        deleteLanguage({
          id: lang.id,
        })
      }
    })
  }

  const openAdd = () => {
    getTaxonomies()
    formikAdd.resetForm({
      values: emptyAddLangValues,
    })
    setModalAddOpen(true)
  }

  const emptyAddLangValues: LanguageType = {
    languageTaxonomyId: '',
    languageLevelId: '',
  }

  const formikAdd = useFormik({
    initialValues: emptyAddLangValues,
    validationSchema: Validation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      addLanguage(values)
    },
  })

  useEffect(() => {
    if (!profile.loading && !profile.error) {
      setModalAddOpen(false)
      setModalEditOpen(false)
    }
  }, [profile.loading])

  return (
    <div className={styles.section}>
      <div>
        <SectionHeader
          onAdd={canChange ? openAdd : undefined}
          title={pt.languagesTitle}
        />
      </div>
      {languages.length > 0 && (
        <SectionItem>
          <ItemHeader
            title={pt.languageSkills}
            icon={Language}
            onEdit={canChange ? openEdit : undefined}
          />
          <div className={styles.content}>
            {localLanguages.map((language: any, index: number) => {
              return (
                <div key={index}>
                  <Hr />

                  <Grid container spacing={2}>
                    <Grid item xs={4} sm={2} md={2} lg={2}>
                      <CommentBubble
                        text={language.languageTaxonomy.isoLanguageCode}
                        className={styles.langBubble}
                      />
                      {language.languageLevel && (
                        <CommentBubble
                          text={language.languageLevel.level
                            .split('-')[0]
                            .trim()}
                          className={styles.levelBubble}
                        />
                      )}
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={9}
                      md={4}
                      lg={5}
                      className={styles.langText}
                    >
                      {language.languageTaxonomy.translation}
                      {/* <span className={styles.spanText}></span> */}
                    </Grid>
                    {language.languageLevel && (
                      <Grid
                        item
                        xs={2}
                        sm={1}
                        md={6}
                        lg={5}
                        className={styles.levelText}
                      >
                        <div className={styles.longLangLevel}>
                          {language.languageLevel.level || ''} -{' '}
                          {language.languageLevel.translation || ''}
                        </div>
                        <div className={styles.shortLangLevel}>
                          {language.languageLevel.translation
                            .split('-')[0]
                            .trim()}
                        </div>
                      </Grid>
                    )}
                  </Grid>
                </div>
              )
            })}
          </div>
        </SectionItem>
      )}
      <ShowMore
        items={languages}
        showMoreIndex={showMoreIndex}
        defaultShowMoreIndex={5}
        setShowMoreIndex={setShowMoreIndex}
      />

      <Modal
        isOpen={modalEditOpen}
        onAfterClose={() => {
          setModalEditOpen(false)
        }}
        title={pt.languageSkills}
        icon={Language}
        className={styles.modal}
        Footer={
          <SubmitFooter
            onSubmit={updateLanguages}
            onCancel={() => {
              setModalEditOpen(false)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <div className={styles.languagesModalContent}>
          {languagesForEdit
            .filter((l) => !l.deleted)
            .sort(Sort)
            .map((language: any, index: number) => {
              return (
                <div key={index}>
                  {index !== 0 && <Hr />}
                  <Grid container spacing={2} className={styles.editModal}>
                    <Grid item xs={9} md={4} className={styles.langText}>
                      {language.languageTaxonomy.translation}
                    </Grid>
                    <Grid item xs={3} className={styles.deleteBoxSmall}>
                      <button
                        type="button"
                        onClick={() => onRemoveLang(language.id)}
                      >
                        <DeleteOutline />
                      </button>
                    </Grid>
                    <Grid item xs={12} md={7}>
                      <Dropdown
                        name="languageLevelId"
                        items={dropdownLanguages}
                        value={language.languageLevel?.id}
                        setValue={(_name, languageLevelId) => {
                          onChangeLangLevel(
                            language.id,
                            languageLevelId as string
                          )
                        }}
                        theme="whiteShortList"
                        listUp={index > 0}
                      />
                    </Grid>
                    <Grid item xs={3} md={1} className={styles.deleteBoxBig}>
                      <button
                        type="button"
                        onClick={() => onRemoveLang(language.id)}
                      >
                        <DeleteOutline />
                      </button>
                    </Grid>
                  </Grid>
                </div>
              )

              return null
            })}
        </div>
      </Modal>

      <Modal
        isOpen={modalAddOpen}
        onAfterClose={() => {
          setModalAddOpen(false)
        }}
        title={pt.addLanguage}
        icon={Language}
        className={styles.modal}
        theme="desktopFlex"
        Footer={
          <SubmitFooter
            onSubmit={formikAdd.handleSubmit}
            onCancel={() => {
              setModalAddOpen(false)
            }}
            theme="modalProfileSubmit"
            disabled={profile.loading}
          />
        }
      >
        <Title text={pt.language} theme="modal" />
        <Dropdown
          name="languageTaxonomyId"
          items={taxonomies.languages?.data.filter(
            (lang) =>
              !languages.find(
                (chosenLang) => chosenLang.languageTaxonomy.id === lang.id
              )
          )}
          value={formikAdd.values.languageTaxonomyId}
          error={formikAdd.errors.languageTaxonomyId}
          setValue={formikAdd.setFieldValue}
          theme="white"
        />
        <Hr theme="modal" />

        <Title text={pt.languageLevel} theme="modal" />
        <Dropdown
          name="languageLevelId"
          items={dropdownLanguages}
          value={formikAdd.values.languageLevelId}
          error={formikAdd.errors.languageLevelId}
          setValue={formikAdd.setFieldValue}
          theme="white"
          listUp
        />
      </Modal>
    </div>
  )
}
