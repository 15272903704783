const profileValidation = {
  'Field required': 'Field required',
  'Attachment is required': 'Attachment is required',
  'Attachment type is required': 'Attachment type is required',
  'Attachment name is required': 'Attachment name is required',
  'Title is required': 'Title is required',
  'Education institution is required': 'Education institution is required',
  'Training title is required': 'Training title is required',
  'Education level is required': 'Education level is required',
  'City is required': 'City is required',
  'Start year is required': 'Start year is required',
  'Enter valid start year': 'Enter valid start year',
  'End year is in the future, must select in progress':
    'End year is in the future, must select in progress',
  'End year is in the past, must deselect in progress':
    'End year is in the past, must deselect in progress',
  'End year cannot be before start year':
    'End year cannot be before start year',
  'End year is required if not in progress':
    'End year is required if not in progress',
  'End year is required': 'End year is required',
  'Career level is required': 'Career level is required',
  'Desired regions are required': 'Desired regions are required',
  'Desired job position is required': 'Desired job position is required',
  'Desired employment is required': 'Desired employment is required',
  'Desired salary is required': 'Desired salary is required',
  'Language is required': 'Language is required',
  'Language level is required': 'Language level is required',
  'Start month is required': 'Start month is required',
  'End month is required': 'End month is required',
  'Availability is required field': 'Availability is required field',
  'Work from home is required field': 'Work from home is required field',
  'Job title is required field': 'Job title is required field',
  'Job classification is required field':
    'Job classification is required field',
  'Experience is required field': 'Experience is required field',
  'Total work experience is required field':
    'Total work experience is required field',
  'Skill is required': 'Skill is required',
  'Skill level is required': 'Skill level is required',
  'Employer is required': 'Employer is required',
  'Employment type is required': 'Employment type is required',
  'Job description type is required': 'Job description type is required',
  'Job description is required': 'Job description is required',
  'Birthdate is required': 'Birthdate is required',
  'Include street number': 'Include street number',
  'Region is required': 'Region is required',
  'Last name is required': 'Last name is required',
  'First name is required': 'First name is required',
  'Profile picture is required': 'Profile picture is required',
  "Can't end before it started": "Can't end before it started",
  'CV parsing failed. Please check the file and try again or choose another file':
    'CV parsing failed. Please check the file and try again or choose another file.',
}

export default profileValidation
