import {FC, useEffect, useState} from 'react'
import styles from './RequestActions.module.scss'
import PopoverList from 'Components/PopoverList'
import {
  IncomingRequestType,
  SpectoProposeNewTimeParamsType,
  RjbProfileStatus,
} from 'Interfaces'
import {isInLessThan10min} from 'Utils/Date'
import classNames from 'classnames'
import {VideocamOutlined, MoreHoriz, CheckCircle} from '@mui/icons-material'
import {InterviewRequestStatus} from '../Constants'
import ModalSendOfferDetailsEmp from 'Components/EmployerDashboard/InterviewInvitationListItem/ModalSendOfferDetailsEmp/index'
import ModalSendOfferEmp from 'Components/EmployerDashboard/InterviewInvitationListItem/ModalSendOfferEmp/index'
import ModalInterviewInvEmp from 'Components/EmployerDashboard/InterviewInvitationListItem/ModalInterviewInvitationEmp/index'
import ModalSendMessageEmp from 'Components/EmployerDashboard/InterviewInvitationListItem/ModalSendMessageEmp/index'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import {AppRoute} from 'Services/I18n/Constants'
import {getRoute} from 'Services/I18n/Utils'
import {Link, useHistory} from 'react-router-dom'
import ModalEmployerCancelInterview from 'Components/EmployerDashboard/InterviewInvitationListItem/ModalEmployerCancelInterview/index'
import {PopoverItemType} from 'Components/Popover'

const getRedirectionUrl = (request: IncomingRequestType) =>
  getRoute(AppRoute.InviteCalendar).replace(
    ':candidateId',
    request.seeker.userId
  )

type RequestActionsProps = {
  request: IncomingRequestType
  setSpectoProposeNewTime: (values: SpectoProposeNewTimeParamsType) => {}
}

const OptionalActions: FC<RequestActionsProps> = ({
  request,
  setSpectoProposeNewTime,
}) => {
  const history = useHistory()
  const noLongerActiveCandidate =
    !request.seeker.hasFinishedPlacementWithCurrentEmployer &&
    request.seeker.rjbProfileStatus === RjbProfileStatus.CLOSED
  const StartVideoInterview = () => {
    const startDate = request.startDate
    const endDate = request.endDate
    const [isSoon, setIsSoon] = useState(isInLessThan10min(startDate, endDate))
    const spectoUrl = `${request.videoUrl}&redirectionUrl=${
      window.location.origin
    }${getRoute(AppRoute.EmployerDashboard)}`

    useEffect(() => {
      const id = setInterval(() => {
        setIsSoon(isInLessThan10min(startDate, endDate))
      }, 10000)

      return () => clearInterval(id)
    }, [])
    return (
      <div
        className={classNames(styles.startInterviewBtn, {
          [styles.startInterviewBtnDisabled]: !isSoon,
        })}
      >
        {isSoon ? (
          <a href={spectoUrl} target={'_blank'} rel={'noopener noreferrer'}>
            <VideocamOutlined />
            <span>
              <FormattedMessage id={edt.startVideoInterview} />
            </span>
          </a>
        ) : (
          <>
            <VideocamOutlined />
            <span>
              <FormattedMessage id={edt.startVideoInterview} />
            </span>
          </>
        )}
      </div>
    )
  }

  const ProposeNewTime = () => {
    const onProposeNewTime = () => {
      setSpectoProposeNewTime({
        requestId: request.id,
        candidateId: request.seeker.userId,
      })
      history.push(getRoute(AppRoute.ProposeNewTimeCalendar))
    }
    return (
      <div onClick={onProposeNewTime} role="button" className={styles.button}>
        <FormattedMessage id={edt.proposeButton} />
      </div>
    )
  }
  const [modalSendOfferDetailsEmp, setModalSendOfferDetailsEmp] =
    useState(false)
  const [modalSendOfferEmp, setModalSendOfferEmp] = useState(false)

  const SendOffer = () => {
    const isOfferSent = request.offerRequestDateCreated

    const onOfferSent = () => {
      if (isOfferSent) {
        setModalSendOfferDetailsEmp(true)
      }
    }
    const onSendOffer = () => {
      if (!isOfferSent) {
        setModalSendOfferEmp(true)
      }
    }

    return (
      <>
        <ModalSendOfferDetailsEmp
          isOpen={modalSendOfferDetailsEmp}
          setIsOpen={setModalSendOfferDetailsEmp}
          request={request}
        />
        <ModalSendOfferEmp
          isOpen={modalSendOfferEmp}
          setIsOpen={setModalSendOfferEmp}
          requestId={request.journeyId}
        />
        {request.companySeekerJourneyOfferRequest ? (
          <div
            onClick={onOfferSent}
            role="button"
            className={classNames(styles.button, {
              [styles.buttonDisabled]: request.companySeekerJourneyOfferRequest,
            })}
          >
            <span className={styles.offerCheck}>
              <CheckCircle />
              <FormattedMessage id={edt.offerSend} />
            </span>
          </div>
        ) : (
          <div
            onClick={onSendOffer}
            role="button"
            className={classNames(styles.button, {
              [styles.buttonDisabled]: isOfferSent,
            })}
          >
            <span>
              <FormattedMessage id={edt.sendButton} />
            </span>
          </div>
        )}
      </>
    )
  }

  if (
    (request.status === InterviewRequestStatus.PENDING ||
      request.status === InterviewRequestStatus.NEW_TIME_PROPOSED) &&
    !noLongerActiveCandidate
  )
    return <ProposeNewTime />
  if (
    request.status === InterviewRequestStatus.INTERVIEW_CONFIRMED &&
    !noLongerActiveCandidate
  )
    return <StartVideoInterview />
  if (
    request.status === InterviewRequestStatus.COMPLETED &&
    !noLongerActiveCandidate
  )
    return <SendOffer />
  if (
    request.status === InterviewRequestStatus.CANCELLED &&
    !noLongerActiveCandidate
  )
    return (
      <Link to={getRedirectionUrl(request)} className={styles.button}>
        <FormattedMessage id={edt.invite} />
      </Link>
    )

  return null
}

const MoreActions: FC<RequestActionsProps> = ({
  request,
  setSpectoProposeNewTime,
}) => {
  const noLongerActiveCandidate =
    !request.seeker.hasFinishedPlacementWithCurrentEmployer &&
    request.seeker.rjbProfileStatus === RjbProfileStatus.CLOSED
  const history = useHistory()
  const [interviewDetailsModal, setInterviewDetailsModal] = useState(false)
  const [sendMessageModal, setSendMessageModal] = useState(false)
  const [cancelInterviewModal, setCancelInterviewModal] = useState(false)

  const onViewDetails = () => {
    setInterviewDetailsModal(true)
  }

  const onSendMessage = () => {
    setSendMessageModal(true)
  }

  const onProposeNewTime = () => {
    setSpectoProposeNewTime({
      requestId: request.id,
      candidateId: request.seeker.userId,
    })
    history.push(getRoute(AppRoute.ProposeNewTimeCalendar))
  }

  const moreActions: PopoverItemType[] = [
    {
      text: edt.viewDetails,
      onClick: onViewDetails,
    },
  ]

  if (
    request.status !== InterviewRequestStatus.CANCELLED &&
    !noLongerActiveCandidate
  ) {
    moreActions.push({
      text: edt.invite,
      route: getRedirectionUrl(request),
    })
  }

  if (
    request.status !== InterviewRequestStatus.EXPIRED &&
    request.status !== InterviewRequestStatus.NOT_ATTENDED &&
    !noLongerActiveCandidate
  ) {
    moreActions.push({
      text: edt.sendMessage,
      onClick: onSendMessage,
    })
  }

  if (
    request.status === InterviewRequestStatus.INTERVIEW_CONFIRMED &&
    !noLongerActiveCandidate
  ) {
    moreActions.push({
      text: edt.proposeButton,
      onClick: onProposeNewTime,
    })
  }
  if (
    request.status === InterviewRequestStatus.PENDING ||
    request.status === InterviewRequestStatus.INTERVIEW_CONFIRMED
  ) {
    moreActions.unshift({
      text: edt.cancelInterview,
      onClick: () => {
        setCancelInterviewModal(true)
      },
    })
  }

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className={styles.moreAction}
      role="button"
    >
      <PopoverList
        triggerIcon={<MoreHoriz />}
        items={moreActions}
        threeDotsBtn
      />
      <ModalInterviewInvEmp
        request={request}
        isOpenInterviewInvitation={interviewDetailsModal}
        setIsOpenInterviewInvitation={setInterviewDetailsModal}
        setCancelModal={setCancelInterviewModal}
      />
      <ModalSendMessageEmp
        request={request}
        isOpen={sendMessageModal}
        setIsOpen={setSendMessageModal}
      />
      <ModalEmployerCancelInterview
        request={request}
        cancelModal={cancelInterviewModal}
        setCancelModal={setCancelInterviewModal}
        isOpenInterviewInvitation={interviewDetailsModal}
        setIsOpenInterviewInvitation={setInterviewDetailsModal}
      />
    </div>
  )
}

export const RequestActions: FC<RequestActionsProps> = ({
  request,
  setSpectoProposeNewTime,
}) => (
  <div className={styles.actionWrap}>
    <OptionalActions
      request={request}
      setSpectoProposeNewTime={setSpectoProposeNewTime}
    />
    <MoreActions
      request={request}
      setSpectoProposeNewTime={setSpectoProposeNewTime}
    />
  </div>
)
