import {FC} from 'react'
import {ContentItem} from 'Components/Widgets/ContentItem'
import styles from './ContentContainer.module.scss'
import {CommentType, HistoryType, MessageType} from 'Interfaces'
interface ContentContainerProps {
  items: CommentType[] | MessageType[] | HistoryType[]
}
export const ContentContainer: FC<ContentContainerProps> = ({items}) => {
  return (
    <div className={styles.contentContainer}>
      {items.map((item, index) => {
        return (
          <div
            key={`${item.dateCreated}-${index}`}
            className={styles.contentItemContainer}
          >
            <ContentItem {...item} />
          </div>
        )
      })}
    </div>
  )
}
