import * as type from '../types'
import {ActionType, TestGroupEnum} from 'Interfaces'

export const getJobCategories = (): ActionType<undefined> => {
  return {
    type: type.jobCategories.requested,
  }
}

export const getIndustry = (): ActionType<undefined> => {
  return {
    type: type.industry.requested,
  }
}
export const getOccupations = (value?: {
  jobCategoryId: string
}): ActionType<
  | {
      jobCategoryId: string
    }
  | undefined
> => {
  return {
    type: type.occupations.requested,
    payload: value,
  }
}

export const getEducationLevels = (): ActionType<undefined> => {
  return {
    type: type.educationLevels.requested,
  }
}

export const getEducationInstitutions = (): ActionType<undefined> => {
  return {
    type: type.educationInstitutions.requested,
  }
}

export const getCountries = (): ActionType<undefined> => {
  return {
    type: type.countries.requested,
  }
}
export const getTowns = (): ActionType<undefined> => {
  return {
    type: type.towns.requested,
  }
}

export const getWorkFromHome = (): ActionType<undefined> => {
  return {
    type: type.workFromHome.requested,
  }
}
export const getSalaries = (): ActionType<undefined> => {
  return {
    type: type.salaries.requested,
  }
}
export const getRegions = (): ActionType<undefined> => {
  return {
    type: type.regions.requested,
  }
}
export const getNumberOfRecruits = (): ActionType<undefined> => {
  return {
    type: type.numberOfRecruits.requested,
  }
}
export const getAvailabilities = (): ActionType<undefined> => {
  return {
    type: type.availabilities.requested,
  }
}
export const getAdditionalBenefits = (): ActionType<undefined> => {
  return {
    type: type.additionalBenefits.requested,
  }
}

export const getEmploymentTypes = (): ActionType<undefined> => {
  return {
    type: type.employmentTypes.requested,
  }
}
export const getJobCategory = (): ActionType<undefined> => {
  return {
    type: type.jobCategory.requested,
  }
}
export const getEmployers = (): ActionType<undefined> => {
  return {
    type: type.employers.requested,
  }
}

export const getSkillLevels = (): ActionType<undefined> => {
  return {
    type: type.skillLevels.requested,
  }
}

export const getSkills = (): ActionType<undefined> => {
  return {
    type: type.skills.requested,
  }
}

export const getTotalWorkExperiences = (): ActionType<undefined> => {
  return {
    type: type.workExperiences.requested,
  }
}

export const getFieldsOfStudy = (): ActionType<undefined> => {
  return {
    type: type.fieldsOfStudy.requested,
  }
}
export const getTrainingTitles = (
  testType?: TestGroupEnum
): ActionType<TestGroupEnum | undefined> => {
  return {
    type: type.trainingTitles.requested,
    payload: testType,
  }
}

export const getLanguages = (): ActionType<undefined> => {
  return {
    type: type.languages.requested,
  }
}
export const getDrivingLicenseCategories = (): ActionType<undefined> => {
  return {
    type: type.drivingLicenseCategories.requested,
  }
}

export const getSystemLanguages = (): ActionType<undefined> => {
  return {
    type: type.systemLanguages.requested,
  }
}

export const updateSystemLanguage = (id: string): ActionType<string> => {
  return {
    type: type.systemLanguageUpdate.requested,
    payload: id,
  }
}

export const updateContentLanguage = (id: string): ActionType<string> => {
  return {
    type: type.contentLanguageUpdate.requested,
    payload: id,
  }
}

export const getLanguageLevels = (): ActionType<undefined> => {
  return {
    type: type.languageLevels.requested,
  }
}
export const getCareerLevels = (): ActionType<undefined> => {
  return {
    type: type.careerLevels.requested,
  }
}
export const getJobSearchStatuses = (): ActionType<undefined> => {
  return {
    type: type.jobSearchStatuses.requested,
  }
}
