import {CandidateDashboardTranslations as cdt} from 'Services/I18n/Constants'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'

// ENUMS
export enum RequestType {
  CONTACT_REQUEST = 'contact_request',
  INTERVIEW_REQUEST = 'interview_request',
  OFFER_REQUEST = 'offer_request',
  PLACEMENT_REQUEST = 'placement_request',
}
export enum InterviewRequestStatus {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
  INTERVIEW_CONFIRMED = 'interview_confirmed',
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  PENDING_EXPIRING = 'pending_expiring',
  NEW_TIME_PROPOSED = 'new_time_proposed',
  REJECTED = 'rejected',
  EXPIRED = 'expired',
  CANCELLED = 'cancelled',
  NOT_ATTENDED = 'not_attended',
  COMPLETED = 'completed',
}
export enum OfferRequestStatus {
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  REJECTED = 'rejected',
  EXPIRED = 'expired',
  NEW_OFFER_PROPOSED = 'new_offer_proposed',
  CANCELLED = 'cancelled',
  EXTENDED = 'extended',
}
export enum ContactRequestStatus {
  APPROVED = 'approved',
  PENDING = 'pending',
  REJECTED = 'rejected',
  EXPIRED = 'expired',
  CANCELLED = 'cancelled',
  EXTENDED = 'extended',
}
export enum PlacementRequestStatus {
  COMPANY_USER_PENDING_SEEKER_PENDING = 'company_user_pending_seeker_pending',
  COMPANY_USER_PENDING_SEEKER_REJECTED = 'company_user_pending_seeker_rejected',
  COMPANY_USER_PENDING_SEEKER_CONFIRMED = 'company_user_pending_seeker_confirmed',
  COMPANY_USER_CONFIRMED_SEEKER_PENDING = 'company_user_confirmed_seeker_pending',
  COMPANY_USER_CONFIRMED_SEEKER_REJECTED = 'company_user_confirmed_seeker_rejected',
  COMPANY_USER_CONFIRMED_SEEKER_CONFIRMED = 'company_user_confirmed_seeker_confirmed',
  COMPANY_USER_REJECTED_SEEKER_PENDING = 'company_user_rejected_seeker_pending',
  COMPANY_USER_REJECTED_SEEKER_REJECTED = 'company_user_rejected_seeker_rejected',
  COMPANY_USER_REJECTED_SEEKER_CONFIRMED = 'company_user_rejected_seeker_confirmed',
  CANCELLED = 'cancelled',
}

export enum CombinedRequestStatues {
  AVAILABLE = 'available',
  UNAVAILABLE = 'unavailable',
  INTERVIEW_CONFIRMED = 'interview_confirmed',
  ACCEPTED = 'accepted',
  PENDING = 'pending',
  PENDING_EXPIRING = 'pending_expiring',
  NEW_TIME_PROPOSED = 'new_time_proposed',
  REJECTED = 'rejected',
  EXPIRED = 'expired',
  COMPLETED = 'completed',
  NEW_OFFER_PROPOSED = 'new_offer_proposed',
  CANCELLED = 'cancelled',
  NOT_ATTENDED = 'not_attended',
  APPROVED = 'approved',
  EXTENDED = 'extended',
  COMPANY_USER_PENDING_SEEKER_PENDING = 'company_user_pending_seeker_pending',
  COMPANY_USER_PENDING_SEEKER_REJECTED = 'company_user_pending_seeker_rejected',
  COMPANY_USER_PENDING_SEEKER_CONFIRMED = 'company_user_pending_seeker_confirmed',
  COMPANY_USER_CONFIRMED_SEEKER_PENDING = 'company_user_confirmed_seeker_pending',
  COMPANY_USER_CONFIRMED_SEEKER_REJECTED = 'company_user_confirmed_seeker_rejected',
  COMPANY_USER_CONFIRMED_SEEKER_CONFIRMED = 'company_user_confirmed_seeker_confirmed',
  COMPANY_USER_REJECTED_SEEKER_PENDING = 'company_user_rejected_seeker_pending',
  COMPANY_USER_REJECTED_SEEKER_REJECTED = 'company_user_rejected_seeker_rejected',
  COMPANY_USER_REJECTED_SEEKER_CONFIRMED = 'company_user_rejected_seeker_confirmed',
}

// CONSTANTS
export const requestTypesDropdownItems = [
  {id: RequestType.CONTACT_REQUEST, translation: cdt.contactRequest},
  {id: RequestType.INTERVIEW_REQUEST, translation: cdt.interviewInvitation},
  {id: RequestType.OFFER_REQUEST, translation: cdt.offerProposal},
  {id: RequestType.PLACEMENT_REQUEST, translation: cdt.placementRequest},
]

export const requestTypeTranslationMap = {
  [RequestType.CONTACT_REQUEST]: cdt.contactRequest,
  [RequestType.INTERVIEW_REQUEST]: cdt.interviewInvitation,
  [RequestType.OFFER_REQUEST]: cdt.offerProposal,
  [RequestType.PLACEMENT_REQUEST]: cdt.placementRequest,
}

export const typeColors = {
  [RequestType.CONTACT_REQUEST]: {backgroundColor: '#d9ecfb'},
  [RequestType.INTERVIEW_REQUEST]: {backgroundColor: '#e9ebea'},
  [RequestType.OFFER_REQUEST]: {
    color: 'white',
    backgroundColor: '#8bc651',
    borderColor: '#dddddd',
  },
  [RequestType.PLACEMENT_REQUEST]: {
    color: 'white',
    backgroundColor: '#8bc651',
    borderColor: '#dddddd',
  },
}

const comonRequestStatusesDropdownItems = [
  {id: CombinedRequestStatues.APPROVED, translation: cdt.approved},
  {id: CombinedRequestStatues.PENDING, translation: cdt.pending},
  {id: CombinedRequestStatues.REJECTED, translation: cdt.rejected},
  {id: CombinedRequestStatues.EXPIRED, translation: cdt.expired},
  {id: CombinedRequestStatues.CANCELLED, translation: cdt.cancelled},
  {id: CombinedRequestStatues.NOT_ATTENDED, translation: cdt.notAttended},
  {id: CombinedRequestStatues.EXTENDED, translation: cdt.extended},
  {id: CombinedRequestStatues.AVAILABLE, translation: cdt.available},
  {id: CombinedRequestStatues.UNAVAILABLE, translation: cdt.unavailable},
  {
    id: CombinedRequestStatues.INTERVIEW_CONFIRMED,
    translation: cdt.interviewConfirmed,
  },
  {id: CombinedRequestStatues.ACCEPTED, translation: cdt.accepted},
  {
    id: CombinedRequestStatues.PENDING_EXPIRING,
    translation: cdt.pendingExpiring,
  },
  {
    id: CombinedRequestStatues.NEW_TIME_PROPOSED,
    translation: cdt.newTimeProposed,
  },
  {id: CombinedRequestStatues.COMPLETED, translation: cdt.completed},
  {
    id: CombinedRequestStatues.NEW_OFFER_PROPOSED,
    translation: cdt.newOfferProposed,
  },
]

export const seekerRequestStatusesDropdownItems = [
  ...comonRequestStatusesDropdownItems,
  {
    id: CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_PENDING,
    translation: cdt.waitingForBoth,
  },
  {
    id: CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_REJECTED,
    translation: cdt.rejectedByYou,
  },
  {
    id: CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_CONFIRMED,
    translation: cdt.waitingForEmployer,
  },
  {
    id: CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_PENDING,
    translation: cdt.waitingForYou,
  },
  {
    id: CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_REJECTED,
    translation: cdt.rejectedByYou,
  },
  {
    id: CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_CONFIRMED,
    translation: cdt.confirmedByBothSides,
  },
  {
    id: CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_PENDING,
    translation: cdt.rejectedByEmployer,
  },
  {
    id: CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_REJECTED,
    translation: cdt.rejectedByBoth,
  },
  {
    id: CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_CONFIRMED,
    translation: cdt.rejectedByEmployer,
  },
]

export const employerRequestStatusesDropdownItems = [
  ...comonRequestStatusesDropdownItems,
  {
    id: CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_PENDING,
    translation: cdt.waitingForBoth,
  },
  {
    id: CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_REJECTED,
    translation: cdt.rejectedByCandidate,
  },
  {
    id: CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_CONFIRMED,
    translation: cdt.waitingForYou,
  },
  {
    id: CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_PENDING,
    translation: cdt.waitingForEmployer,
  },
  {
    id: CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_REJECTED,
    translation: cdt.rejectedByCandidate,
  },
  {
    id: CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_CONFIRMED,
    translation: cdt.confirmedByBothSides,
  },
  {
    id: CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_PENDING,
    translation: cdt.rejectedByYou,
  },
  {
    id: CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_REJECTED,
    translation: cdt.rejectedByBoth,
  },
  {
    id: CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_CONFIRMED,
    translation: cdt.rejectedByYou,
  },
]

export const employerRequestStatusTranslationMap = {
  [CombinedRequestStatues.AVAILABLE]: cdt.available,
  [CombinedRequestStatues.UNAVAILABLE]: cdt.unavailable,
  [CombinedRequestStatues.INTERVIEW_CONFIRMED]: cdt.interviewConfirmed,
  [CombinedRequestStatues.ACCEPTED]: cdt.accepted,
  [CombinedRequestStatues.PENDING]: cdt.pending,
  [CombinedRequestStatues.PENDING_EXPIRING]: cdt.pendingExpiring,
  [CombinedRequestStatues.NEW_TIME_PROPOSED]: cdt.newTimeProposed,
  [CombinedRequestStatues.REJECTED]: cdt.rejected,
  [CombinedRequestStatues.EXPIRED]: cdt.expired,
  [CombinedRequestStatues.COMPLETED]: cdt.completed,
  [CombinedRequestStatues.NEW_OFFER_PROPOSED]: cdt.newOfferProposed,
  [CombinedRequestStatues.CANCELLED]: cdt.cancelled,
  [CombinedRequestStatues.NOT_ATTENDED]: cdt.notAttended,
  [CombinedRequestStatues.APPROVED]: cdt.approved,
  [CombinedRequestStatues.EXTENDED]: cdt.extended,

  [CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_PENDING]:
    cdt.waitingForBoth,
  [CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_REJECTED]:
    cdt.rejectedByCandidate,
  [CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_CONFIRMED]:
    edt.waitingForYourAction,
  [CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_PENDING]:
    edt.waitingForCandidate,
  [CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_REJECTED]:
    cdt.rejectedByCandidate,
  [CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_CONFIRMED]:
    cdt.confirmedByBothSides,
  [CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_PENDING]:
    cdt.rejectedByYou,
  [CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_REJECTED]:
    cdt.rejectedByBoth,
  [CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_CONFIRMED]:
    cdt.rejectedByYou,
}
