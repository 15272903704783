import {FC, useEffect, useMemo, useState} from 'react'
import {useFormik} from 'formik'
import Slider from '@mui/material/Slider'
import classnames from 'classnames'

import styles from './WorkExperience.module.scss'
import Title from 'Components/Title'
import Hr from 'Components/Hr'
import RadioGroup from 'Components/Inputs/RadioGroup'
import {
  BaseTaxonomyType,
  OccupationExperienceType,
  OnboardingStateType,
  SavePartialStepWorkExperience,
  SaveStepWorkExperience,
  SeekerOnboardingStatus,
  TaxonomiesStateType,
} from 'Interfaces'
import {OnChangeProps, RadioGroupProps} from 'Utils/FormikProps'
import Buttons from 'Components/Onboarding/Buttons'
import {ValidationBack, ValidationNext} from './Validation'
import TypeAheadImproved, {
  TypeAheadWithCategory,
} from 'Components/Inputs/TypeAheadImproved'
import {useApiError} from 'Hooks'
import {packOccupationExperiences, packSkills} from './utils'
import AreYouSureModal from 'Components/Onboarding/AreYouSureModal'
import TextError from 'Components/Error/TextError'
import {OnboardingTranslations as onb} from 'Services/I18n/Constants'
import {LoadingModal} from 'Components/LoadingModal'
import TextField from 'Components/Inputs/TextField'
import {DeleteOutline} from '@mui/icons-material'

interface WorkExperienceProps {
  onboarding: OnboardingStateType
  taxonomies: TaxonomiesStateType
  getTaxonomies: (jobCategoryId?: string) => void
  saveStepWorkExperience: (values: SaveStepWorkExperience) => void
  saveStepPartialWorkExperience: (values: SavePartialStepWorkExperience) => void
  setOnboardingStep: (step: number) => void
}

interface WorkExperienceFormikType {
  totalWorkExperienceId: string
  occupationExperiences: OccupationExperienceType[]
  skills: BaseTaxonomyType[]
  publicOccupation: string
  apiError?: null | string
}

export const WorkExperience: FC<WorkExperienceProps> = ({
  onboarding,
  taxonomies,
  getTaxonomies,
  saveStepWorkExperience,
  saveStepPartialWorkExperience,
  setOnboardingStep,
}) => {
  const [modalOpen, setModalOpen] = useState(false)

  useEffect(() => {
    if (!onboarding.loading) getTaxonomies(onboarding.data?.jobCategory?.id)
  }, [onboarding.loading])

  const allOccupations = useMemo(() => {
    let newOccupations = taxonomies.occupations.data.slice()
    onboarding.data?.occupationExperiences?.forEach((position) => {
      if (
        'occupation' in position &&
        newOccupations.findIndex((n) => n.id === position.occupation.id) === -1
      )
        newOccupations = [...newOccupations, position.occupation]
    })
    return newOccupations
  }, [taxonomies.occupations.data, onboarding.data?.occupationExperiences])

  const defaultValues: WorkExperienceFormikType = useMemo(
    () => ({
      totalWorkExperienceId: onboarding.data?.totalWorkExperience?.id || '',
      occupationExperiences: onboarding.data?.occupationExperiences || [],
      skills: onboarding?.data?.skills?.map((skill) => skill.skill) || [],
      publicOccupation: onboarding.data?.publicOccupation?.translation || '',
      apiError: null,
    }),
    [onboarding.data]
  )
  const formikNext = useFormik({
    initialValues: defaultValues,
    validationSchema: ValidationNext,
    onSubmit: (values) => {
      saveStepWorkExperience({
        values: {
          totalWorkExperienceId: values.totalWorkExperienceId,
          occupationExperiences: packOccupationExperiences(
            values.occupationExperiences
          ),
          skills: packSkills(values.skills),
          publicOccupation: values.publicOccupation,
        },
        step: 4,
      })
    },
  })
  const formikBack = useFormik({
    initialValues: defaultValues,
    validationSchema: ValidationBack,
    onSubmit: (values) => {
      saveStepPartialWorkExperience({
        totalWorkExperienceId: values.totalWorkExperienceId,
        occupationExperiences: packOccupationExperiences(
          values.occupationExperiences
        ),
        skills: packSkills(values.skills),
        publicOccupation: values.publicOccupation,
      })
    },
  })

  const handleModalSubmit = () => {
    setOnboardingStep(2)
  }

  const handleModalCancel = () => {
    formikBack.validateForm(formikNext.values).then((errors) => {
      if (Object.keys(errors).length) {
        formikNext.setErrors(errors)
      } else {
        saveStepWorkExperience({
          values: {
            totalWorkExperienceId: formikNext.values.totalWorkExperienceId,
            occupationExperiences: packOccupationExperiences(
              formikNext.values.occupationExperiences
            ),
            skills: packSkills(formikNext.values.skills),
            publicOccupation: formikNext.values.publicOccupation,
          },
          step: 2,
        })
      }
    })
    setModalOpen(false)
  }
  const stepBack = () => {
    if (
      formikNext.dirty &&
      onboarding.data?.onboardingStatus ===
        SeekerOnboardingStatus.WORKEXPERIENCE
    ) {
      formikBack.validateForm(formikNext.values).then((errors) => {
        if (Object.keys(errors).length) {
          formikNext.setErrors(errors)
        } else {
          const packedValues = {
            totalWorkExperienceId: formikNext.values.totalWorkExperienceId,
            occupationExperiences: packOccupationExperiences(
              formikNext.values.occupationExperiences
            ),
            skills: packSkills(formikNext.values.skills),
            publicOccupation: formikNext.values.publicOccupation,
          }
          const newValues = {...packedValues}
          Object.keys(newValues).forEach((key) => {
            if (
              newValues[key] === '' ||
              (typeof newValues[key] !== 'string' && !newValues[key].length)
            ) {
              delete newValues[key]
            }
          })
          saveStepPartialWorkExperience(newValues)
        }
      })
    } else {
      //check changes if there are none, just set step
      if (formikNext.dirty) {
        setModalOpen(true)
      } else {
        setOnboardingStep(2)
      }
    }
  }

  useApiError(formikNext.setFieldError, onboarding.error, 'apiError')

  const [insertSliderValue, setInsertSliderValue] = useState(1)

  const insertOccupation = (_: string, value: BaseTaxonomyType[]) => {
    if (
      !value.length ||
      formikNext.values.occupationExperiences.findIndex(
        (oe) => oe.occupation.id === value[0].id
      ) > -1
    )
      return
    formikNext.setFieldValue('occupationExperiences', [
      ...formikNext.values.occupationExperiences,
      {occupation: value[0], experience: insertSliderValue},
    ])
    setInsertSliderValue(1)
  }

  const handleSliderChange = (index: number, value: number) => {
    const newArray = JSON.parse(
      JSON.stringify(formikNext.values.occupationExperiences)
    )
    newArray[index].experience = value
    formikNext.setFieldValue('occupationExperiences', newArray)
  }

  const formatLabelValue = (value: number) => {
    return value === 10 ? <div>{`${value}+`}</div> : value
  }

  const handleDelete = (index: number) => {
    formikNext.setFieldValue(
      'occupationExperiences',
      formikNext.values.occupationExperiences.filter((_, i) => i !== index)
    )
  }

  const occupations = useMemo(
    () =>
      formikNext.values.occupationExperiences.map((occupationExp, index) => {
        if (!occupationExp?.occupation) return

        return (
          <div className={styles.occupation} key={index}>
            <div className={styles.occupationText}>
              {occupationExp.occupation.translation}
            </div>
            <button type="button" onClick={() => handleDelete(index)}>
              <DeleteOutline className={styles.deleteIcon} />
            </button>
            <div className={styles.sliderContainer}>
              <div className={styles.sliderWrap}>
                <Slider
                  aria-label={occupationExp.occupation.translation}
                  onChange={(_, value) =>
                    handleSliderChange(index, value as number)
                  }
                  defaultValue={occupationExp.experience}
                  value={
                    formikNext.values.occupationExperiences[index].experience
                  }
                  valueLabelDisplay="on"
                  valueLabelFormat={formatLabelValue}
                  step={1}
                  marks
                  min={1}
                  max={10}
                  sx={{
                    color: '#113a60',
                    '& .MuiSlider-rail': {
                      color: '#c2c2c2',
                    },
                    '& .MuiSlider-valueLabel': {
                      backgroundColor: '#fbb015',
                      padding: '0.25rem 0.3rem',
                      minWidth: '32px',
                    },
                    '& .MuiSlider-mark': {
                      color: '#fff',
                    },
                  }}
                />
              </div>
            </div>
          </div>
        )
      }),
    [formikNext.values.occupationExperiences, allOccupations]
  )

  return (
    <div className={styles.container}>
      <LoadingModal isOpen={onboarding.loading} />
      <form
        className={styles.form}
        onSubmit={formikNext.handleSubmit}
        onKeyDown={(event) => {
          if (event?.code === 'Enter') event.preventDefault()
        }}
      >
        <div>
          <Title
            text={onb.currentJobTitle}
            onboarding
            className={styles.firstTitle}
          />
          <TextField
            name="publicOccupation"
            placeholder={onb.placeholderCurrentJobTitle}
            {...OnChangeProps(formikNext, 'publicOccupation')}
          />
          <Hr className={styles.hr} />
        </div>
        <div>
          <Title
            text={onb.titleTotalYearsWorkExperience}
            onboarding
            className={styles.firstTitle}
          />
          <RadioGroup
            name="totalWorkExperienceId"
            items={taxonomies.workExperiences.data}
            {...RadioGroupProps(formikNext, 'totalWorkExperienceId')}
          />
          <Hr className={styles.hr} />
        </div>
        <div>
          <Title text={onb.titleExperiencePerPosition} onboarding />
          <div
            className={classnames({
              [styles.errorBorder]:
                formikNext.submitCount &&
                formikNext.errors.occupationExperiences,
            })}
          >
            <div className={styles.occupations}>{occupations}</div>
            <div className={styles.occupation}>
              <TypeAheadImproved
                name="newOccupation"
                setValue={insertOccupation}
                items={allOccupations}
                className={styles.enterOccupationInput}
                selectNonExisting
                maxNumSelections={1}
                clearAfterSetValue
                placeholder={onb.typeAheadEnterPosition}
              />
              <div className={styles.sliderContainer}>
                <div className={styles.sliderWrap}>
                  <Slider
                    aria-label="Temperature"
                    defaultValue={1}
                    onChange={(_, value) => {
                      setInsertSliderValue(value as number)
                    }}
                    value={insertSliderValue}
                    valueLabelDisplay="on"
                    valueLabelFormat={formatLabelValue}
                    step={1}
                    marks
                    min={1}
                    max={10}
                    sx={{
                      color: '#113a60',
                      '& .MuiSlider-rail': {
                        color: '#c2c2c2',
                      },
                      '& .MuiSlider-valueLabel': {
                        backgroundColor: '#fbb015',
                      },
                      '& .MuiSlider-mark': {
                        color: '#fff',
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {formikNext.submitCount && formikNext.errors.occupationExperiences ? (
            <TextError
              text={formikNext.errors.occupationExperiences as string}
            />
          ) : null}
          <Hr className={styles.hr} />
        </div>
        <div>
          <Title text={onb.titleSkills} onboarding />
          <TypeAheadWithCategory
            name="skills"
            items={taxonomies.skills.data}
            label={onb.selectMultiValue}
            selectNonExisting
            clearAfterSetValue
            value={formikNext.values.skills}
            setValue={formikNext.setFieldValue}
            placeholder={onb.typeAheadTypeYourSkills}
            error={
              formikNext.submitCount && formikNext.errors.skills
                ? (formikNext.errors.skills as string)
                : undefined
            }
            categoryPropName="skillCategory"
          />
          <Hr className={styles.hr} />
        </div>
        {formikNext.errors.apiError && (
          <TextError text={formikNext.errors.apiError} />
        )}
        <Buttons onBack={stepBack} />
      </form>
      <AreYouSureModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        handleModalSubmit={handleModalSubmit}
        handleModalCancel={handleModalCancel}
      />
    </div>
  )
}
