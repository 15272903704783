import Modal from 'Components/Modal'
import {ThumbsFooter} from 'Components/Modal/ThumbsFooter'
import {IncomingRequestType} from 'Interfaces'
import {FC} from 'react'
import styles from './ModalRejectPlacementConfirmationEmp.module.scss'
import {CandidateDashboardTranslations as cdt} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'
import {ForumOutlined} from '@mui/icons-material'

interface ModalRejectPlacementConfirmationEmp {
  request: IncomingRequestType
  rejectModal: boolean
  setRejectModal: (rejecModal: boolean) => void
  setIsOpenPlacementModal?: (value: boolean) => void
  rejectPlacementEmp: (payload: string) => void
}

export const ModalRejectPlacementConfirmationEmp: FC<
  ModalRejectPlacementConfirmationEmp
> = ({
  request,
  rejectModal,
  setRejectModal,
  rejectPlacementEmp,
  setIsOpenPlacementModal,
}) => {
  return (
    <>
      <Modal
        className={styles.modal}
        icon={ForumOutlined}
        isOpen={rejectModal}
        title={cdt.rejectPlacement}
        theme={'error'}
        onAfterClose={() => {
          setRejectModal(false)
        }}
        Footer={
          <ThumbsFooter
            rejectText={cdt.reject}
            theme={'thumbDown'}
            onReject={() => {
              rejectPlacementEmp(request.id)
              setRejectModal(false)
              setIsOpenPlacementModal && setIsOpenPlacementModal(false)
            }}
            onCancel={() => {
              setRejectModal(false)
            }}
          />
        }
      >
        <div className={styles.containerInterview}>
          <p className={styles.text}>
            <FormattedMessage id={edt.placementRejectAreYouSure} />
          </p>
        </div>
      </Modal>
    </>
  )
}
