import {FC} from 'react'
import {Link} from 'react-router-dom'
import classNames from 'classnames'

import styles from './MessageCenterTab.module.scss'
import {getRoute} from 'Services/I18n/Utils'
interface MessageCenterTabProps {
  label: string
  active: boolean
  specificParticipiantUnreadCount?: number
  route?: any
}

export const MessageCenterTab: FC<MessageCenterTabProps> = ({
  label,
  active,
  specificParticipiantUnreadCount,
  route = getRoute,
}) => {
  return (
    <Link
      to={route}
      className={classNames({
        [styles.tab]: true,
        [styles.tabActive]: active,
      })}
    >
      {label}
      {specificParticipiantUnreadCount === 0 && <>&nbsp;</>}
      {specificParticipiantUnreadCount &&
        specificParticipiantUnreadCount > 0 && (
          <span className={styles.newMessagesIndicator}>
            {specificParticipiantUnreadCount}
          </span>
        )}
    </Link>
  )
}
