import {FC, useEffect, useRef, useState} from 'react'
import {useMediaQuery} from 'react-responsive'

import MainHeader from 'Components/MainHeader'
import YellowButton from 'Components/YellowButton'
import Modal, {SubmitFooter} from 'Components/Modal'
import {WhiteBoxWrapper} from 'Components/WhiteBoxWrapper'
import {TestResultsWhiteBoxContent} from 'Components/Assessments/TestResultsWhiteBoxContent'
import PsychometricIcon from 'Assets/Images/psychometic-tests.svg'
import CheckBox from 'Components/Inputs/Checkbox'
import {useTranslation} from 'Hooks'
import {getRoute} from 'Services/I18n/Utils'
import {
  AppRoute,
  ConsentsTranslations as ct,
  GeneralAssessmentsTranslations as gat,
  ModalsAssessmentsTranslations as mat,
  PsychometricAssessmentsTranslations as pat,
  SidebarTranslations as st,
} from 'Services/I18n/Constants'
import {
  AssessmentsStateType,
  ChangeSelbyMillsConsentStatusType,
  ConsentType,
  MeType,
  SeekerConsentId,
  Test,
  UserConsentsVersionsType,
} from 'Interfaces'
import {FormattedMessage} from 'react-intl'
import {SidebarLink} from 'Components/SidebarLink'
import FooterAssessments from 'Components/Footer'
import {Link} from 'react-router-dom'
import {DownloadAndPrintHeader} from 'Components/DownloadAndPrintHeader'
import {MobileFooter} from 'Components/MobileFooter'
import MainLayout from 'Layouts/MainLayout'
import {HistoryEduOutlined} from '@mui/icons-material'

import styles from './PsychometricTest.module.scss'

interface PsychometicTestProps {
  user: MeType | undefined
  changeShelbyMillsConsentStatus: (
    value: ChangeSelbyMillsConsentStatusType
  ) => void
  assessments: AssessmentsStateType
  getAssessments: () => void
  getPsychometricTestResults: (id: string) => void
  psychometricDownloadLink: {
    url: string
  }
  consents: ConsentType[]
  getConsents: Function
}

export const PsychometricTest: FC<PsychometicTestProps> = ({
  user,
  changeShelbyMillsConsentStatus,
  assessments,
  getAssessments,
  getPsychometricTestResults,
  psychometricDownloadLink,
  consents,
  getConsents,
}) => {
  const isMobile = useMediaQuery({
    query: '(max-width: 900px)',
  })

  const [modalOpen, setModalOpen] = useState(false)
  const [checkboxValue, setCheckboxValue] = useState(false)
  const psychometricTest: Test = assessments.data.items.filter((test: Test) => {
    return test.name === 'Psychometric test'
  })[0]
  const [resultButtonClicked, setResultButtonClicked] = useState(false)
  const [psychometricConsent, setPsychometricConsent] = useState<
    undefined | UserConsentsVersionsType
  >()
  const [psychometricConsentId, setPsychometricConsentId] = useState<
    string | undefined
  >(undefined)

  const linkRef = useRef<HTMLAnchorElement | null>(null)

  const translation = useTranslation()

  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: getRoute(AppRoute.HomePageCandidate)},
    {name: translation[gat.title], link: getRoute(AppRoute.Assessments)},
    {name: translation[pat.psychometricTestTitle]},
  ]

  const links = [
    {
      name: translation[st.editPublicProfile],
      route: AppRoute.PublicProfile,
    },
    {
      name: translation[st.editCv],
      route: AppRoute.Profile,
    },
  ]

  const Footer: FC = () => {
    return (
      <>{isMobile ? <MobileFooter links={links} /> : <FooterAssessments />}</>
    )
  }

  useEffect(() => {
    getAssessments()
    getConsents()
  }, [])

  useEffect(() => {
    if (!psychometricDownloadLink.url) {
      getPsychometricTestResults(psychometricTest?.id)
    }
  }, [assessments])

  useEffect(() => {
    if (consents && consents.length > 0) {
      const psychometricTest = consents.find((consent) => {
        return consent.id === SeekerConsentId.PsychometricTest
      })

      setPsychometricConsent(
        psychometricTest?.consentVersions[0].userConsentsVersions.find(
          (psychometricConsent) => {
            return psychometricConsent?.company === null
          }
        )
      )

      setPsychometricConsentId(psychometricTest?.id)
    }
  }, [consents])

  useEffect(() => {
    // download hr potentials test results
    if (
      linkRef &&
      linkRef.current &&
      psychometricDownloadLink &&
      psychometricDownloadLink.url &&
      resultButtonClicked
    ) {
      linkRef.current.click()
    }
  }, [psychometricDownloadLink])

  const buttonContent = (
    status: string | undefined,
    testId: string | undefined
  ) => {
    if (status === 'completed' && psychometricConsent?.status === 'granted') {
      return (
        <Link to={getRoute(AppRoute.SeekerPsychometricTestResults)}>
          <YellowButton text={gat.viewResultsText} />
        </Link>
      )
    } else if (
      status === 'completed' &&
      psychometricConsent?.status === 'revoked'
    ) {
      return
    } else if (status === 'results_pending') {
      return (
        <YellowButton
          text={translation[gat.pendingButtonText]}
          className={styles.pendingButton}
        />
      )
    } else {
      if (testId) {
        return (
          <YellowButton
            onClick={() => {
              setModalOpen(true)
            }}
            text={translation[gat.startTestText]}
          />
        )
      }
      return
    }
  }
  return (
    <MainLayout
      withoutLogo
      content={
        <div className={styles.linksWrap}>
          <SidebarLink
            text={st.editPublicProfile}
            routeName={AppRoute.PublicProfile}
          />
          <SidebarLink text={st.editCv} routeName={AppRoute.Profile} />
        </div>
      }
      Footer={<Footer />}
    >
      <div className={styles.testContainer}>
        <DownloadAndPrintHeader
          setResultButtonClicked={setResultButtonClicked}
          downloadLink={
            psychometricTest?.status === 'completed' &&
            psychometricDownloadLink &&
            psychometricDownloadLink.url &&
            psychometricConsent?.status === 'granted'
              ? psychometricDownloadLink.url
              : undefined
          }
        />
        <MainHeader
          breadcrumbsArray={breadcrumbsArray}
          title={translation[pat.psychometricTestTitle]}
          className={styles.psychometricTestHeader}
          image={
            <img
              className={styles.psychometricIcon}
              src={PsychometricIcon}
              alt={translation[pat.psychometricTestTitle]}
            />
          }
        />

        <div className={styles.testContent}>
          <h2 className={styles.title}>
            Na podlagi rezultatov psihometričnih testiranj sodelavcev se veliko
            delodajalcev odloči, ali bodo kandidata zaposlili ali ne. Vas
            zanima, kaj piše v vašem psihometričnem poročilu?
          </h2>
          <p className={styles.text}>
            Ste na kakšnem zaposlitvenem intervjuju že doživeli, da ste morali
            na željo delodajalca rešiti psihometrični test? Verjetno rezultatov
            tega testa niste nikoli videli, delodajalec pa se je tudi na podlagi
            teh rezultatov odločil, ali vas bodo zaposlili ali ne. Kaj
            delodajalec vidi iz rezultatov vašega testa?
          </p>
          <p className={styles.text}>
            V sodelovanju s podjetjem Selby&Mills smo tako za iskalce, kot za
            delodajalce, omogočili možnost reševanja psihometričnih testov prek
            spleta, rezultate pa prejmete na vaš elektronski naslov.
          </p>
          <p className={styles.text}>
            Vprašalnik je sestavljen iz 190 vprašanj, na podlagi katerih lahko
            delodajalec poglobljeno spozna kandidata, saj po opravljenem
            testiranju dobi pregled 130 kompetenc, ki pokrivajo večino delovnih
            aktivnosti. Z odgovori na vprašanja boste o vas podali naslednje
            informacije:
          </p>
          <ul>
            <li>Kakšne so vaše osebnostne lastnosti;</li>
            <li>Katere so vaše najbolj oziroma najmanj izražene kompetence;</li>
            <li>Kakšno je vaše želeno delovno okolje;</li>
          </ul>
          <p className={styles.text}>
            Za vaše lažje razumevanje, pa boste vi prejeli povratno informacijo
            o testiranju v obliki strukturiranih poročil. Tako boste prejeli
            poročila, ki vam bodo omogočila vpogled v:
          </p>
          <ul>
            <li>
              želen osebni stil delovnega mesta – vpogled v vaš stil odločanja,
              razporejanja delovnih nalog in kakšen način dela vam najbolj
              ustreza;
            </li>
            <li>
              odnose na delovnem mestu – kakšne odnose ustvarite na delovnem
              mestu in kako delujete znotraj delovnih timov;
            </li>
            <li>osebnost – povzetek vaših osebnostih lastnosti;</li>
            <li>
              kompetence – katere so vaše najbolj razvite kompetence, prednosti
              in katere kompetence bi lahko razvijali še naprej;
            </li>
            <li>
              primerna delovna mesta/delovna področja – po skrbnem pregledu vseh
              zgoraj opisanih lastnosti, prejmete nekaj predlogov katera delovna
              področja oziroma delovna mesta, bi vam najbolj ustrezala.
            </li>
          </ul>
          <p className={styles.text}>
            Pri reševanju ni pravih ali napačnih odgovorov in testiranje ni
            časovno omejeno, vendar vas prosimo, da odgovarjate čim hitreje in
            čimbolj iskreno.
          </p>
          {psychometricTest?.status === 'completed' && (
            <div className={styles.testMicrophoneCameraNotificationWrapper}>
              <WhiteBoxWrapper
                children={
                  <TestResultsWhiteBoxContent
                    children={
                      psychometricConsent?.status === 'granted' ? (
                        <>
                          <h3>
                            {psychometricTest?.name === 'Psychometric test' ? (
                              <FormattedMessage
                                id={pat.psychometricTestResults}
                              />
                            ) : (
                              <FormattedMessage
                                id={pat.psychometricTestResults}
                              />
                            )}
                          </h3>
                          <p>
                            <FormattedMessage id={gat.resultsValidUntil} />{' '}
                            {psychometricTest?.expirationDate &&
                              new Date(
                                psychometricTest?.expirationDate
                              ).toLocaleDateString('sl-SI')}
                          </p>
                        </>
                      ) : (
                        <p>
                          <FormattedMessage
                            id={pat.psychometricTestNoConsentBoxValue}
                          />{' '}
                          <Link to={getRoute(AppRoute.Consents)}>
                            <FormattedMessage id={ct.title} />
                          </Link>
                        </p>
                      )
                    }
                  />
                }
              />
            </div>
          )}
          <div className={styles.buttonWrapper}>
            <div className={styles.buttonContainer}>
              {psychometricTest?.status &&
                buttonContent(psychometricTest?.status, psychometricTest?.id)}
            </div>
          </div>
        </div>
        <Modal
          isOpen={modalOpen}
          onAfterClose={() => {
            setModalOpen(false)
          }}
          title={mat.psychometricTestTitle}
          icon={HistoryEduOutlined}
          ariaHideApp={false}
          className={styles.modal}
          Footer={
            <SubmitFooter
              onSubmit={() => {
                if (checkboxValue) {
                  changeShelbyMillsConsentStatus({
                    firstName: user ? user.firstName : '',
                    lastName: user ? user.lastName : '',
                    email: user ? user.email : '',
                    initials: '',
                    title: '',
                    id: psychometricTest?.id ? psychometricTest?.id : '',
                    testId: psychometricTest?.id,
                    consentId: psychometricConsentId as string,
                  })
                }

                setModalOpen(false)
              }}
              onCancel={() => {
                setModalOpen(false)
              }}
              submitText={translation[ct.consentGrant]}
            />
          }
        >
          {/* TODO this is temporary solution, write this again after finished api*/}
          <div className={styles.modalPsychometricTestContainer}>
            <h2 className={styles.modalTitle}>
              <FormattedMessage id={mat.psychometricTestTitle} />
            </h2>
            <div className={styles.checkboxTextWrapper}>
              <CheckBox
                name="consent1B"
                value={checkboxValue ? checkboxValue : false}
                onChange={() => {
                  setCheckboxValue && setCheckboxValue(!checkboxValue)
                }}
                topAlign
              />

              <p className={styles.modalText}>
                <FormattedMessage id={mat.psychometricTestTextFirstPart} />{' '}
                <Link to={getRoute(AppRoute.HomePageCandidate)}>
                  Preferenca.si
                </Link>
                . <FormattedMessage id={mat.psychometricTestTextSecondPart} />{' '}
                <Link
                  to={getRoute(AppRoute.PrivacyPolicy)}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                >
                  <FormattedMessage id={mat.privacyPolicy} />.
                </Link>
              </p>
            </div>
          </div>
        </Modal>
      </div>
      <a
        href={
          psychometricTest?.status === 'completed' &&
          psychometricDownloadLink &&
          psychometricDownloadLink.url &&
          psychometricConsent?.status === 'granted'
            ? psychometricDownloadLink.url
            : undefined
        }
        className={styles.invisibleLink}
        ref={linkRef}
        download
      ></a>
    </MainLayout>
  )
}
