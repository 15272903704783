import {connect} from 'react-redux'
import {ProfileHeader} from './ProfileHeader'
import {AppDispatch} from 'Store'
import {CompanyProfileHeaderSaveValue} from 'Interfaces'
import {
  editCompanyProfileHeader,
  getCountries,
  getTowns,
  saveLogo,
  saveCover,
  getIndustry,
} from 'Store/Actions'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  taxonomies: state.taxonomies,
  company: state.company,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getTaxonomies: () => {
    dispatch(getCountries())
    dispatch(getTowns())
    dispatch(getIndustry())
  },
  saveLogo: (fileId: string) => {
    dispatch(saveLogo(fileId))
  },
  saveCover: (fileId: string) => {
    dispatch(saveCover(fileId))
  },
  editCompanyProfileHeader: (value: CompanyProfileHeaderSaveValue) =>
    dispatch(editCompanyProfileHeader(value)),
})
export const ProfileHeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileHeader)
