import {connect} from 'react-redux'

import {EmployerRequestsLayout} from './EmployerRequestsLayout'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {clearCandidateRequests} from 'Store/Actions'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  company: state.company,
  candidateBasicData: state.widgetBar.data.candidateBasicData,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  clearRequests: () => dispatch(clearCandidateRequests()),
})

export const EmployerRequestsLayoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerRequestsLayout)
