import {ChangeEvent, FC, useEffect, useMemo, useRef, useState} from 'react'
import styles from './TypeAhead.module.scss'
import classnames from 'classnames'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import Chip from 'Components/Chip'
import {CheckBoxIcon} from 'Components/Icons/CheckBoxIcon'
import {TaxonomyWithCategoryType} from 'Interfaces'
import TextError from 'Components/Error/TextError'
import {OnboardingTranslations as onb} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import {isEqual, cloneDeep} from 'lodash'
import {useTranslate} from 'Hooks'

export interface KeywordItemType extends TaxonomyWithCategoryType {
  checked?: boolean
}

interface TypeAheadProps {
  name: string
  value?: KeywordItemType[]
  items: KeywordItemType[]
  setValue: (name: string, value: KeywordItemType[]) => void
  selectNonExisting?: boolean
  maxNumSelections?: number
  label?: string
  error?: string
  placeholder?: string
  className?: string
  theme?: 'grey' | 'white' | 'modal' | 'keyword'
  onBlur?: ({}) => void
  clearAfterSetValue?: boolean
  setTouched?: (v: boolean) => void
  withoutArrow?: boolean
  invalidNonExisting?: KeywordItemType[]
  withChips?: boolean
  disabled?: boolean
  noNeedToClick?: boolean
  resetTextInput?: boolean
  setEnter: (v: string) => void
}

export const TypeAheadKeyword: FC<TypeAheadProps> = ({
  name,
  value,
  setValue,
  items,
  selectNonExisting = false,
  maxNumSelections = 0,
  label,
  error,
  placeholder,
  className,
  theme = 'grey',
  setTouched = () => {},
  clearAfterSetValue = false,
  withoutArrow = false,
  invalidNonExisting = [],
  withChips = true,
  noNeedToClick = false,
  resetTextInput,
  setEnter,
}) => {
  const [textInput, setTextInput] = useState('')
  const [open, setOpen] = useState(false)
  const [localItems, setLocalItems] = useState<KeywordItemType[]>(items)
  const listRef = useRef<HTMLDivElement>(null)
  const translate = useTranslate()

  useEffect(() => {
    let newItems = cloneDeep(items)
    newItems.forEach((item: KeywordItemType) => {
      item.checked = !!value?.find((v) => v.id === item.id)
    })
    const newValues = value
      ?.filter((v) => v.id.includes('newValue~'))
      .map((v) => ({...v, checked: true, category: translate['Keyword']}))
    if (newValues?.length) {
      newItems = [...newItems, ...newValues]
    }
    if (!isEqual(newItems, localItems)) {
      setLocalItems(newItems)
    }
  }, [items, value])

  const checkedItems = useMemo(() => {
    return localItems.filter((c) => c.checked)
  }, [localItems])

  useEffect(() => {
    if (!checkedItems.length && maxNumSelections !== 1) {
      setTextInput('')
    } else if (
      maxNumSelections === 1 &&
      checkedItems[0] &&
      textInput !== checkedItems[0].translation &&
      !clearAfterSetValue
    ) {
      setTextInput(checkedItems[0].translation)
    }
  }, [checkedItems])

  const filteredItems = useMemo(() => {
    return localItems.filter(
      (o) =>
        (textInput === '' ||
          o.translation.toLowerCase().includes(textInput.toLowerCase())) &&
        o.category !== translate['Keyword']
    )
  }, [localItems, textInput])

  const onTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    setTextInput(value)
    if (maxNumSelections === 1 && checkedItems[0]?.translation !== value) {
      setValue(name, [])
    }
  }

  const isMaxNumSelected = (): boolean => {
    return !!(maxNumSelections && checkedItems.length >= maxNumSelections)
  }

  const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget?.value
    handleValue(value)
  }

  const handleValue = (value: string = textInput) => {
    let newLocalItems = cloneDeep(localItems)
    if (maxNumSelections === 1) {
      newLocalItems = newLocalItems.map((n) => ({
        ...n,
        checked: false,
      }))
    }
    const optionIndex = localItems.findIndex((i) => {
      return i.id === value || i.id === `newValue~${value}`
    })
    if (optionIndex > -1) {
      if (
        isMaxNumSelected() &&
        !localItems[optionIndex].checked &&
        maxNumSelections !== 1
      ) {
        return
      }

      newLocalItems[optionIndex] = {
        ...localItems[optionIndex],
        checked:
          maxNumSelections !== 1 ? !localItems[optionIndex].checked : true,
      }

      setValue(
        name,
        newLocalItems.filter((c) => c.checked)
      )
      if (maxNumSelections === 1) {
        setOpen(false)
        if (newLocalItems[optionIndex].checked && !clearAfterSetValue) {
          setTextInput(newLocalItems[optionIndex].translation)
        } else {
          setTextInput('')
        }
      }
    } else {
      if (isMaxNumSelected() && maxNumSelections !== 1) return
      // if it doesn't exist create new option and add it to the proper place
      const newItem = {
        id: `newValue~${textInput}`,
        translation: textInput,
        checked: true,
      }
      const newLocalItems = [...localItems]
      newLocalItems.push(newItem)

      if (maxNumSelections === 1) {
        setValue(name, [newItem])
        setOpen(false)
        setTextInput(textInput)
      } else {
        setValue(name, newLocalItems)
      }
    }
    setTouched(true)
  }

  const handleChipRemove = (translation: string) => {
    const newLocalItems = localItems.slice()
    const localIndex = localItems.findIndex(
      (i) => i.translation === translation
    )
    handleValue(newLocalItems[localIndex].id)
  }

  const handleKeyup = (event: any) => {
    if (textInput === '') {
      return
    }
    event.stopPropagation()
    event.preventDefault()
    if (selectNonExisting && event.code === 'Enter') {
      handleValue()
      setEnter(textInput)
      setTextInput('')
    } else if (event.code === 'ArrowDown') {
      const first = listRef.current?.querySelector(
        `.${styles.checkBoxWrap}`
      ) as HTMLLabelElement
      first.focus()
    }
    if (!open) setOpen(true)
  }

  const handleArrows = (event: any, value?: KeywordItemType) => {
    event.stopPropagation()
    event.preventDefault()
    if (event.code === 'ArrowDown') {
      event.currentTarget.nextSibling?.focus()
    } else if (event.code === 'ArrowUp') {
      event.currentTarget.previousSibling?.focus()
    } else if (event.code === 'Enter' || event.code == 'Space') {
      handleValue(value?.id)
      setEnter(value?.translation || textInput)
    }
  }

  const topLabelText = useMemo(() => {
    if (label) return translate(label) || label
    //TODO translation needed here
    else if (maxNumSelections === 1) return 'Select value'
    else if (maxNumSelections > 1)
      return `Select up to ${maxNumSelections} values`
    return translate(onb.selectMultiValue)
  }, [label, maxNumSelections])

  useEffect(() => {
    if (error) setOpen(false)
  }, [error])

  const keyword = useMemo(
    () =>
      selectNonExisting &&
      maxNumSelections === 1 &&
      textInput !== '' &&
      filteredItems.findIndex((f) => f.translation === textInput) === -1,
    [selectNonExisting, maxNumSelections, textInput, filteredItems]
  )

  useEffect(() => {
    setTextInput('')
  }, [resetTextInput])

  return (
    <div
      className={classnames(
        styles.typeAheadContainer,
        {
          [styles.collapsed]: !localItems.length || !open,
        },
        styles[theme],
        className
      )}
    >
      <div
        tabIndex={0}
        className={classnames(styles.inputMainContainer, {
          [styles.error]: error,
        })}
        onBlur={(e) => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            setOpen(false)
            if (noNeedToClick && textInput) {
              handleValue(textInput)
            }
          }
        }}
        onFocus={() => {
          setOpen(true)
        }}
      >
        <div className={styles.inputContainer}>
          <div className={styles.inputWrap}>
            {(!placeholder || label) && (
              <div className={styles.inputTopPlaceholder}>{topLabelText}</div>
            )}
            <div className={styles.inputValues}>
              {maxNumSelections !== 1 &&
                checkedItems.map((chip, index: number) =>
                  withChips ? (
                    <Chip
                      key={`${chip.translation}-${index}`}
                      text={chip.translation}
                      className={styles.inputChip}
                      onClose={() => {
                        handleChipRemove(chip.translation)
                      }}
                    />
                  ) : (
                    <span
                      key={index}
                      className={styles.input}
                    >{`${chip.translation}, `}</span>
                  )
                )}
              {maxNumSelections !== 1 && !checkedItems.length && !withChips ? (
                <span className={styles.input}>
                  {translate(placeholder) ||
                    placeholder ||
                    `${translate(onb.typeAheadTypeYour)} ${translate(name)}`}
                </span>
              ) : null}
              {withChips && (
                <input
                  type="text"
                  className={styles.input}
                  value={textInput}
                  onChange={onTextChange}
                  onKeyUp={handleKeyup}
                  placeholder={
                    translate(placeholder) ||
                    placeholder ||
                    `${translate(onb.typeAheadTypeYour)} ${translate(name)}`
                  }
                />
              )}
            </div>
          </div>
          {!withoutArrow && (
            <div className={classnames(styles.arrowWrap, 'arrowWrap')}>
              <ArrowDropDownIcon
                className={classnames(styles.arrow, {
                  [styles.rotate]: localItems.length,
                })}
              />
            </div>
          )}
        </div>
        <div
          className={classnames(styles.dropdownContainer, {
            [styles.hidden]: !localItems.length || !open,
          })}
          role="group"
          aria-labelledby={`${name}CheckBoxGroup`}
          ref={listRef}
        >
          {!withChips && maxNumSelections !== 1 ? (
            <input
              type="text"
              className={styles.input}
              value={textInput}
              onChange={onTextChange}
              onKeyUp={handleKeyup}
              placeholder={
                translate(placeholder) ||
                placeholder ||
                `${translate(onb.typeAheadTypeYour)} ${translate(name)}`
              }
            />
          ) : null}
          <label
            tabIndex={0}
            className={styles.checkBoxWrap}
            onKeyDown={(e) => {
              handleArrows(e)
            }}
          >
            {keyword ? (
              !invalidNonExisting?.length ||
              invalidNonExisting.findIndex(
                (f) => f.translation === textInput
              ) === -1 ? (
                <>
                  <input
                    type="checkbox"
                    onChange={handleChecked}
                    value={textInput}
                    className={styles.hidden}
                    name={name}
                  />
                  <div
                    className={classnames(
                      styles.checkBoxText,
                      styles.checkBoxTextWithCategory
                    )}
                  >
                    <div
                      className={styles.checkBoxTextTranslation}
                    >{`${textInput} `}</div>
                    <div className={styles.checkBoxTextCategory}>
                      <FormattedMessage id="Keyword" />
                    </div>
                  </div>
                </>
              ) : (
                <div className={styles.checkBoxText}>
                  <FormattedMessage id="Entry already added" />
                </div>
              )
            ) : null}
            {!filteredItems.length && !selectNonExisting ? (
              <div className={styles.checkBoxText}>
                <FormattedMessage id="No entry found" />
              </div>
            ) : null}
          </label>
          {filteredItems.map((item, index) => {
            return (
              <label
                tabIndex={0}
                className={styles.checkBoxWrap}
                key={`${item.translation}-${index}`}
                onKeyDown={(e) => {
                  handleArrows(e, item)
                }}
              >
                <input
                  type="checkbox"
                  onChange={handleChecked}
                  checked={!!item.checked}
                  value={item.id}
                  className={styles.hidden}
                  multiple={maxNumSelections > 1}
                  name={name}
                />
                {maxNumSelections !== 1 && (
                  <CheckBoxIcon
                    checked={!!item.checked}
                    className={styles.icon}
                  />
                )}
                <div
                  className={classnames(
                    styles.checkBoxText,
                    styles.checkBoxTextWithCategory
                  )}
                >
                  <div className={styles.checkBoxTextTranslation}>
                    {item.translation}
                  </div>
                  <div className={styles.checkBoxTextCategory}>
                    {item.category}
                  </div>
                </div>
              </label>
            )
          })}
        </div>
      </div>
      {error && <TextError text={error} />}
    </div>
  )
}
