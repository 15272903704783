import {FC, useCallback, useEffect, useMemo} from 'react'
import {useFormik} from 'formik'
import Logo from 'Assets/Images/preferenca-logo.svg'
import {FormattedMessage} from 'react-intl'
import {
  ConsentsTranslations as ct,
  OnboardingTranslations as ot,
} from 'Services/I18n/Constants/Seeker'
import classnames from 'classnames'
import {
  AuthStateType,
  ConsentToSend,
  ConsentType,
  SeekerConsentId,
  SeekerLoginConsentsFormikValues,
  SeekerOnboardingStatus,
} from 'Interfaces'
import styles from './SeekerLoginConsents.module.scss'
import {Validation} from './Validation'
import Hr from 'Components/Hr'
import {Checkbox} from 'Components/Inputs/Checkbox/Checkbox'
import {Link, useHistory} from 'react-router-dom'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import TextError from 'Components/Error/TextError'
import YellowButton from 'Components/YellowButton'
import {useApiError} from 'Hooks'
import {hasGrantedMandatoryConsents} from 'Utils/Consents'

interface SeekerLoginConsentsProps {
  auth: AuthStateType
  getSeekerLoginConsents: () => void
  acceptSeekerLoginConsents: (values: ConsentToSend[]) => void
}

export const SeekerLoginConsents: FC<SeekerLoginConsentsProps> = ({
  getSeekerLoginConsents,
  auth,
  acceptSeekerLoginConsents,
}) => {
  const history = useHistory()
  const seekerConsents = useMemo(() => {
    if (auth.data?.userConsents?.length) {
      const generalTermsOfUse = auth.data.userConsents.find(
        (consent: ConsentType) =>
          consent.id === SeekerConsentId.GeneralTermsOfUse
      )
      const termsAndConditionsRjb = auth.data.userConsents.find(
        (consent: ConsentType) =>
          consent.id === SeekerConsentId.TermsAndConditionsRjb
      )
      const privacyPolicy = auth.data.userConsents.find(
        (consent: ConsentType) => consent.id === SeekerConsentId.PrivacyPolicy
      )
      const privacyPolicyRjb = auth.data.userConsents.find(
        (consent: ConsentType) =>
          consent.id === SeekerConsentId.PrivacyPolicyRjbAnex
      )
      const thirdPartyMarketing = auth.data.userConsents.find(
        (consent: ConsentType) =>
          consent.id === SeekerConsentId.ThirdPartyMarketing
      )
      return {
        generalTermsOfUse,
        termsAndConditionsRjb,
        privacyPolicy,
        privacyPolicyRjb,
        thirdPartyMarketing,
      }
    } else return {}
  }, [auth.data, auth.data?.userConsents])

  const packValues = useCallback(
    (values: SeekerLoginConsentsFormikValues) => {
      let tc: ConsentToSend[] = []
      let pc: ConsentToSend[] = []
      let tpc: ConsentToSend[] = []
      if (
        values.tcCheckbox &&
        seekerConsents.generalTermsOfUse &&
        seekerConsents.termsAndConditionsRjb
      ) {
        tc = [
          {id: seekerConsents.generalTermsOfUse.id, status: 'granted'},
          {id: seekerConsents.termsAndConditionsRjb.id, status: 'granted'},
        ]
      }
      if (
        values.privacyCheckbox &&
        seekerConsents.privacyPolicy &&
        seekerConsents.privacyPolicyRjb
      ) {
        pc = [
          {id: seekerConsents.privacyPolicy.id, status: 'granted'},
          {id: seekerConsents.privacyPolicyRjb.id, status: 'granted'},
        ]
      }
      if (values.thirdPartyCheckbox && seekerConsents.thirdPartyMarketing) {
        tpc = [{id: seekerConsents.thirdPartyMarketing.id, status: 'granted'}]
      }

      return [...tc, ...pc, ...tpc]
    },
    [seekerConsents]
  )

  const formik = useFormik<SeekerLoginConsentsFormikValues>({
    initialValues: {
      tcCheckbox: false,
      privacyCheckbox: false,
      thirdPartyCheckbox: false,
      apiError: '',
    },
    validationSchema: Validation,
    onSubmit: (values) => {
      const packedConsents = packValues(values)
      !auth.loading && acceptSeekerLoginConsents(packedConsents)
    },
  })

  useApiError(formik.setFieldError, auth.error, 'apiError')

  useEffect(() => {
    if (auth.data && !auth.data.userConsents?.length) {
      getSeekerLoginConsents()
    } else if (auth.data?.userConsents?.length) {
      formik.setFieldValue(
        'tcCheckbox',
        seekerConsents.generalTermsOfUse?.consentVersions[0]
          .userConsentsVersions[0]?.status === 'granted' &&
          seekerConsents.termsAndConditionsRjb?.consentVersions[0]
            .userConsentsVersions[0]?.status === 'granted'
      )
      formik.setFieldValue(
        'privacyCheckbox',
        seekerConsents.privacyPolicy?.consentVersions[0].userConsentsVersions[0]
          ?.status === 'granted' &&
          seekerConsents.privacyPolicyRjb?.consentVersions[0]
            .userConsentsVersions[0]?.status === 'granted'
      )
      formik.setFieldValue(
        'thirdPartyCheckbox',
        seekerConsents.thirdPartyMarketing?.consentVersions[0]
          .userConsentsVersions[0]?.status === 'granted'
      )
    }
  }, [seekerConsents])

  useEffect(() => {
    const grantedConsents = hasGrantedMandatoryConsents(auth.data)
    if (auth.data && grantedConsents) {
      if (auth.data.onboardingStatus === SeekerOnboardingStatus.FINISHED) {
        history.push(getRoute(AppRoute.CandidateDashboard))
      } else history.push(getRoute(AppRoute.Onboarding))
    }
  }, [auth.data])

  return (
    <div className={styles.onboarding}>
      <div className={styles.main}>
        <div className={styles.logo}>
          <img src={Logo} alt={'logo'} />
        </div>
        <div className={styles.inner}>
          <div className={styles.title}>
            <FormattedMessage id={ct.mandatoryConsentsTitle} />
          </div>
          <div className={styles.description}>
            <FormattedMessage id={ct.mandatoryConsentsDescription} />
          </div>
          <Hr />

          <form className={styles.form} onSubmit={formik.handleSubmit}>
            <div className={styles.inputWrap}>
              <Checkbox
                className={classnames(styles.cardCheckInput)}
                name={'tcCheckbox'}
                onChange={(e) =>
                  formik.setFieldValue('tcCheckbox', e.target.checked)
                }
                value={formik.values.tcCheckbox}
                error={
                  formik.submitCount ? formik?.errors?.tcCheckbox : undefined
                }
              >
                <FormattedMessage id={ot.agree} />
                <Link
                  to={getRoute(AppRoute.GeneralTermsAndConditions)}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                  className={styles.link}
                >
                  <FormattedMessage id={ot.generalTerms} />
                </Link>
                <FormattedMessage id={ot.and} />
                <Link
                  to={getRoute(AppRoute.TermsAndConditions)}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                  className={styles.link}
                >
                  <FormattedMessage id={ot.termsAndConditions} />
                </Link>
                *
              </Checkbox>
              <Checkbox
                className={classnames(styles.cardCheckInput)}
                name={'privacyCheckbox'}
                onChange={(e) =>
                  formik.setFieldValue('privacyCheckbox', e.target.checked)
                }
                value={formik.values.privacyCheckbox}
                error={
                  formik.submitCount
                    ? formik?.errors?.privacyCheckbox
                    : undefined
                }
              >
                <FormattedMessage id={ot.agree} />
                <Link
                  to={getRoute(AppRoute.PrivacyPolicy)}
                  target={'_blank'}
                  rel={'noopener noreferrer'}
                  className={styles.link}
                >
                  <FormattedMessage id={ot.privacyPolicy} />
                </Link>
                *
              </Checkbox>
              <Checkbox
                className={classnames(styles.cardCheckInput)}
                name={'thirdPartyCheckbox'}
                onChange={(e) =>
                  formik.setFieldValue('thirdPartyCheckbox', e.target.checked)
                }
                value={formik.values.thirdPartyCheckbox}
              >
                <FormattedMessage id={ot.thirdPartyAgreement} />
              </Checkbox>
              <div className={styles.footer}>
                <Link
                  to={getRoute(AppRoute.SeekerLogin)}
                  className={styles.link}
                >
                  <FormattedMessage id={ot.buttonBack} />
                </Link>
                <div className={styles.button}>
                  <YellowButton text={'Next'} />
                </div>
              </div>
              {formik.errors.apiError && (
                <TextError text={formik.errors.apiError as string} />
              )}
            </div>
          </form>
        </div>
      </div>
      <div className={styles.rightColumn}>
        <div className={styles.desktopSteps}>
          <div className={classnames(styles.desktopStep, styles.filled)}>
            <div className={styles.stepCnt}>{1}</div>
            <div className={styles.stepName}>
              <FormattedMessage id={ct.mandatoryConsentsText} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
