import {connect} from 'react-redux'

import {MessageCenter} from './MessageCenter'
import {AppDispatch} from 'Store'
import {
  getSpecificParticipiantUnreadConversationsCount,
  resetMessageCenterData,
  setCandidateBasicData,
  setSidebarClosed,
} from 'Store/Actions'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  specificParticipiantUnreadCount: state.messageCenter.unreadCount,
  candidate: state.employer.data.candidate,
  candidateBasicData: state.widgetBar.data.candidateBasicData,
  sidebarClosed: state.widgetBar.data.sidebarClosed,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSpecificParticipiantUnreadConversationsCount: (
    participantsType?: string
  ) =>
    dispatch(getSpecificParticipiantUnreadConversationsCount(participantsType)),
  resetMessageCenterData: () => dispatch(resetMessageCenterData()),
  setCandidateBasicData: (candidate: any) =>
    dispatch(setCandidateBasicData(candidate)),
  setSidebarClosed: (sidebarClosed: boolean) =>
    dispatch(setSidebarClosed(sidebarClosed)),
})

export const MessageCenterContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageCenter)
