import * as type from '../types'
import {
  ActionType,
  AuthRoutingType,
  ChangePasswordType,
  ConsentToSend,
  ForgotPasswordType,
  LoginType,
  ResetPasswordType,
  UserType,
} from 'Interfaces'

export const loginSeeker = (loginData: LoginType): ActionType<LoginType> => {
  return {
    type: type.SEEKER_LOGIN_REQUESTED,
    payload: {
      ...loginData,
    },
  }
}

export const loginEmployer = (loginData: LoginType): ActionType<LoginType> => {
  return {
    type: type.EMPLOYER_LOGIN_REQUESTED,
    payload: {
      ...loginData,
    },
  }
}

export const getEmployerLoginConsents = (): ActionType<undefined> => {
  return {
    type: type.employer.getLoginConsents.requested,
  }
}
export const getSeekerLoginConsents = (): ActionType<undefined> => {
  return {
    type: type.seeker.getSeekerLoginConsents.requested,
  }
}

export const acceptEmployerLoginConsents = (
  values: ConsentToSend[]
): ActionType<ConsentToSend[]> => {
  return {
    type: type.employer.acceptLoginConsents.requested,
    payload: values,
  }
}
export const acceptSeekerLoginConsents = (
  values: ConsentToSend[]
): ActionType<ConsentToSend[]> => {
  return {
    type: type.seeker.acceptSeeekerLoginConsents.requested,
    payload: values,
  }
}

export const logoutUser = (role?: UserType): ActionType<UserType> => {
  return {
    type: type.USER_LOGOUT_REQUESTED,
    payload: role || UserType.SEEKER,
  }
}

export const getUser = (
  nextRoute?: AuthRoutingType,
  withoutRedirect?: AuthRoutingType
): ActionType<AuthRoutingType> => {
  return {
    type: type.USER_FETCH_REQUESTED,
    payload: {
      ...nextRoute,
      ...withoutRedirect,
    },
  }
}

export const forgotPassword = (
  value: ForgotPasswordType
): ActionType<ForgotPasswordType> => {
  return {
    type: type.auth.forgotPassword.requested,
    payload: value,
  }
}
export const resetPassword = (
  value: ResetPasswordType
): ActionType<ResetPasswordType> => {
  return {
    type: type.auth.resetPassword.requested,
    payload: value,
  }
}

export const resetEmployerPassword = (
  value: ResetPasswordType
): ActionType<ResetPasswordType> => {
  return {
    type: type.auth.resetEmployerPassword.requested,
    payload: value,
  }
}

export const changePassword = (
  values: ChangePasswordType
): ActionType<ChangePasswordType> => {
  return {
    type: type.auth.changePassword.requested,
    payload: values,
  }
}

export const changeEmail = (email: string): ActionType<string> => {
  return {
    type: type.auth.changeEmail.requested,
    payload: email,
  }
}

export const verifyEmail = (token: string): ActionType<string> => {
  return {
    type: type.auth.verifyEmail.requested,
    payload: token,
  }
}

export const verifyChangeEmail = (token: string): ActionType<string> => {
  return {
    type: type.auth.verifyChangeEmail.requested,
    payload: token,
  }
}

export const resendVerificationEmail = (email: string): ActionType<string> => {
  return {
    type: type.auth.resendVerifyEmail.requested,
    payload: email,
  }
}

export const confirmProfile = (): ActionType<undefined> => {
  return {
    type: type.auth.confirmProfile.requested,
  }
}

export const hideChangeEmailMessage = (): ActionType<undefined> => {
  return {
    type: type.auth.changeEmail.hideMessage,
  }
}

export const hideChangePasswordMessage = (): ActionType<undefined> => {
  return {
    type: type.auth.changePassword.hideMessage,
  }
}

export const hideForgotPasswordMessage = (): ActionType<undefined> => {
  return {
    type: type.auth.forgotPassword.hideMessage,
  }
}

export const hideResetPasswordMessage = (): ActionType<undefined> => {
  return {
    type: type.auth.resetPassword.hideMessage,
  }
}

export const hideEmailResentMessage = (): ActionType<undefined> => {
  return {
    type: type.auth.resendVerifyEmail.hideMessage,
  }
}

export const pingAuth = (): ActionType<undefined> => {
  return {
    type: type.auth.ping.requested,
  }
}
