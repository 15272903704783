import {FC} from 'react'
import classnames from 'classnames'
import styles from './Hr.module.scss'

interface HrProps {
  className?: string
  theme?:
    | 'modal'
    | 'widget'
    | 'light'
    | 'hrMargin'
    | 'hrNoMarginMobileOnly'
    | 'modalSmall'
    | 'modalBig'
}

export const Hr: FC<HrProps> = ({className, theme = 'hr'}) => {
  return (
    <hr
      className={classnames(styles.hr, className, {
        [styles[theme]]: theme,
      })}
    />
  )
}
