import {OnboardingTranslations as ot} from 'Services/I18n/Constants/Seeker/OnboardingTranslations'

export const OnboardingSteps: any = {
  1: {
    key: 'your-cv',
    title: ot.titleCV,
    description: ot.descriptionCV,
    step: 2,
  },
  2: {
    key: 'job-preferences',
    title: ot.titleJobPreferences,
    description: ot.descriptionJobPreferences,
    step: 3,
  },
  3: {
    key: 'work-experience',
    title: ot.titleWorkExperience,
    description: ot.descriptionWorkExperience,
    step: 4,
  },
  4: {
    key: 'benefits-education',
    title: ot.titleBenefitsAndEducation,
    description: ot.descriptionBenefitsAndEducation,
    step: 5,
  },
}
