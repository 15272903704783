import {LongDescription} from 'Validations'
import * as Yup from 'yup'

const Validation = Yup.object().shape({
  title: Yup.string()
    .max(50, 'Must be 50 characters or less')
    .required('Title is required'),
  educationLevel: Yup.object()
    .nullable()
    .required('Education level is required'),
  educationInstitution: Yup.object()
    .nullable()
    .required('Education institution is required'),
  town: Yup.object().nullable().required('Town is required'),
  country: Yup.object().nullable().required('Country is required'),
  inProgress: Yup.boolean()
    .test({
      name: 'inProgress',
      message: 'End year is in the future, must select in progress',
      test: (inProgress, context): boolean =>
        !(!inProgress && context.parent.endYear > new Date().getFullYear()),
    })
    .test({
      name: 'inProgress',
      message: 'End year is in the past, must deselect in progress',
      test: (inProgress, context): boolean =>
        !(inProgress && context.parent.endYear < new Date().getFullYear()),
    }),
  endYear: Yup.number().test({
    name: 'endYear',
    message: 'End year is required if not in progress',
    test: (endYear, context): boolean =>
      !((endYear || 0) < 1970 && !context.parent.inProgress),
  }),
  description: LongDescription,
})

// Old approach, not used at moment
// const Validation = Yup.object().shape({
//   title: Yup.string()
//     .max(50, 'Must be 50 characters or less')
//     .required('Title is required.'),
//   educationLevel: Yup.object()
//     .nullable()
//     .required('Education institution is required'),
//   educationInstitution: Yup.object()
//     .nullable()
//     .required('Education institution is required'),
//   town: Yup.object().nullable().required('Town is required'),
//   country: Yup.object().nullable().required('Country is required'),
//   startMonth: Yup.number(),
//   startYear: Yup.number()
//     .min(1970, 'Start year is required')
//     .max(new Date().getFullYear(), 'Enter valid start year')
//     .required('Start year is required'),
//   endMonth: Yup.number().test(DateTest.endMonth),
//   inProgress: Yup.boolean()
//     .test({
//       name: 'inProgress',
//       message: 'End year is in the future, must select in progress',
//       test: (inProgress, context): boolean =>
//         !(!inProgress && context.parent.endYear > new Date().getFullYear()),
//     })
//     .test({
//       name: 'inProgress',
//       message: 'End year is in the past, must deselect in progress',
//       test: (inProgress, context): boolean =>
//         !(inProgress && context.parent.endYear < new Date().getFullYear()),
//     }),
//   endYear: Yup.number()
//     .when('startYear', (started, yup) =>
//       yup.min(started, 'End year cannot be before start year')
//     )
//     .test({
//       name: 'endYear',
//       message: 'End year is required if not in progress',
//       test: (endYear, context): boolean =>
//         !((endYear || 0) < 1970 && !context.parent.inProgress),
//     }),
//   description: LongDescription,
// })

export default Validation
