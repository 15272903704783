import {FC} from 'react'
import {ConsentItemWrapper} from '../ConsentItem/ConsentItemWrapper'
import {UserConsentsVersionsType} from 'Interfaces'

export const ConsentVersion: FC<any> = ({
  consentVersion,
  type,
  className,
  consentId,
  consentCategory,
}) => {
  const renderConsents = () => {
    if (consentVersion?.companyConsentsVersions) {
      if (consentVersion.companyConsentsVersions.length > 0) {
        return (
          <ConsentItemWrapper
            type={type}
            consent={consentVersion.companyConsentsVersions[0]}
            className={className}
            consentName={consentVersion.name}
            consentId={consentId}
            consentCategory={consentCategory}
          />
        )
      } else {
        return ''
      }
    } else if (consentVersion?.userConsentsVersions?.length <= 1) {
      return (
        <ConsentItemWrapper
          type={type}
          consent={consentVersion.userConsentsVersions[0]}
          className={className}
          consentName={consentVersion.name}
          consentId={consentId}
          consentCategory={consentCategory}
        />
      )
    } else {
      let sortedConsents = consentVersion.userConsentsVersions.sort(
        (consent: UserConsentsVersionsType) => {
          if (!consent?.company?.id) {
            return -1
          }
          return 1
        }
      )

      return sortedConsents.map(
        (consent: UserConsentsVersionsType, index: number) => {
          return (
            <ConsentItemWrapper
              key={index}
              type={type}
              consent={consent}
              className={className}
              consentName={consentVersion.name}
              switchConsent={consent?.company?.id}
              consentId={consentId}
              consentCategory={consentCategory}
            />
          )
        }
      )
    }
  }
  return <div>{renderConsents()}</div>
}
