import {connect} from 'react-redux'

import {MyAccount} from './MyAccount'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {
  getCountries,
  getTowns,
  getSeekerPersonalData,
  getRegions,
  saveSeekerPersonalData,
  getEmployerPersonalData,
  saveEmployerPersonalData,
} from 'Store/Actions'
import {PersonalDataSaveType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  seeker: state.seeker,
  employerFullData: state.employer.fullData,
  employer: state.employer,
  auth: state.auth,
  taxonomies: state.taxonomies,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  saveSeekerPersonalData: (values: PersonalDataSaveType) =>
    dispatch(saveSeekerPersonalData(values)),
  saveEmployerPersonalData: (values: PersonalDataSaveType) =>
    dispatch(saveEmployerPersonalData(values)),
  getTaxonomies: () => {
    dispatch(getCountries())
    dispatch(getTowns())
    dispatch(getRegions())
  },
  getSeekerData: () => {
    dispatch(getSeekerPersonalData())
  },
  getEmployerData: () => {
    dispatch(getEmployerPersonalData())
  },
})

export const MyAccountContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MyAccount)
