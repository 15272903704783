import {FC, useState} from 'react'
import {ArrowDropDown, ArrowDropUp} from '@mui/icons-material'
import {QuestionValue} from 'Interfaces'
import styles from './Question.module.scss'

interface QuestionProps {
  question: QuestionValue
}

export const Question: FC<QuestionProps> = ({question}) => {
  const [openedAnswer, setOpenedAnswer] = useState(false)
  const openAnswer = () => {
    setOpenedAnswer((prevState: boolean) => !prevState)
  }

  return (
    <div className={styles.questionContainer}>
      <div onClick={openAnswer} className={styles.questionWrapper}>
        <p className={styles.question}>Q: {question.question}</p>
        {openedAnswer ? (
          <ArrowDropUp className={styles.arrowIcon} />
        ) : (
          <ArrowDropDown className={styles.arrowIcon} />
        )}
      </div>
      {openedAnswer && (
        <div className={styles.answerWrapper}>
          <p>A: {question.answer}</p>
        </div>
      )}
    </div>
  )
}
