import {ActionType} from 'Interfaces'
import * as type from '../types'

export const getBlacklistItems = (): ActionType<undefined> => {
  return {
    type: type.blacklist.getDropdownItems.requested,
  }
}

export const getBlacklist = (): ActionType<undefined> => {
  return {
    type: type.blacklist.get.requested,
  }
}

export const createBlacklist = (companyId: string): ActionType<string> => {
  return {
    type: type.blacklist.create.requested,
    payload: companyId,
  }
}

export const deleteBlacklist = (id: string): ActionType<string> => {
  return {
    type: type.blacklist.delete.requested,
    payload: id,
  }
}
