import {EmployerResetPassword} from './EmployerResetPassword'
import {connect} from 'react-redux'
import {resetEmployerPassword, hideResetPasswordMessage} from 'Store/Actions'
import {AppDispatch} from 'Store'
import {ResetPasswordType} from 'Interfaces'
import {RootState} from 'Store/Reducers'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  resetEmployerPassword: (data: ResetPasswordType) =>
    dispatch(resetEmployerPassword(data)),
  hideResetPasswordMessage: () => dispatch(hideResetPasswordMessage()),
})

export const EmployerResetPasswordContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerResetPassword)
