import {FC} from 'react'
import styles from './MatchAvatar.module.scss'
// import {CandidateSearchTranslations as cst} from 'Services/I18n/Constants'
// import {useTranslation} from 'Hooks'

interface MatchAvatarProps {
  match?: {
    experience: number
    skills: number
    total: number
  }
  image?: string
  firstName: string
  lastName: string
  sqSize?: number
  strokeWidth?: number
}

export const MatchAvatar: FC<MatchAvatarProps> = ({
  match = {
    experience: 100,
    skills: 80,
    total: 90,
  },
  image,
  firstName = 'A',
  lastName = 'A',
  sqSize = 120,
  strokeWidth = 5,
}) => {
  // Size of the enclosing square
  const enclosingSqSize = sqSize

  // SVG centers the stroke width on the radius, subtract out so circle fits in square
  const radius = (enclosingSqSize - strokeWidth) / 2

  //Enclose cicle in a circumscribing square
  const viewBox = `0 0 ${enclosingSqSize} ${enclosingSqSize}`

  // Arc length at 100% coverage is the circle circumference
  const dashArray = radius * Math.PI * 2

  // Scale 100% coverage overlay with given percent
  const dashOffset = dashArray - (dashArray * match.total) / 100

  // const translation = useTranslation()

  return (
    <div className={styles.circleProgressBarContainer}>
      {image ? (
        <img src={image} alt="doge" className={styles.image} />
      ) : (
        <span className={styles.avatarInitials}>{`${
          firstName ? firstName[0] : 'A'
        }${lastName ? lastName[0] : 'A'}`}</span>
      )}
      {/*<p className={styles.circleText}>*/}
      {/*  <span>{translation[cst.match]}:</span>*/}
      {/*  <span className={styles.percentage}>{`${match.total}%`}</span>*/}
      {/*</p>*/}
      <svg
        className={styles.circleSvg}
        width={enclosingSqSize}
        height={enclosingSqSize}
        viewBox={viewBox}
      >
        <circle
          className={styles.circleProgress}
          cx={enclosingSqSize / 2}
          cy={enclosingSqSize / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          // Progress is starting at 12 clock
          transform={`rotate(-90 ${enclosingSqSize / 2} ${
            enclosingSqSize / 2
          })`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
        />
      </svg>
    </div>
  )
}
