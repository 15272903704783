import {connect} from 'react-redux'

import {AppDispatch} from 'Store'
import {confirmPlacementEmp} from 'Store/Actions'
import {ModalApprovePlacementConfirmationEmp} from './ModalApprovePlacementConfirmationEmp'

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  approvePlacementConfirmation: (payload: string) =>
    dispatch(confirmPlacementEmp(payload)),
})

export const ModalApprovePlacementConfirmationEmpContainer = connect(
  null,
  mapDispatchToProps
)(ModalApprovePlacementConfirmationEmp)
