import styles from './Loader.module.scss'
import progress from 'Assets/Images/contact-request-proggres.gif'
import {FormattedMessage} from 'react-intl'
import classnames from 'classnames'

type LoaderProps = {
  text?: string
  className?: string
}

export const Loader = ({text, className}: LoaderProps) => (
  <div className={classnames(styles.loading, className)}>
    <img className={styles.progress} src={progress} alt="progress" />
    {text && (
      <span className={styles.loadingText}>
        <FormattedMessage id={text} />
      </span>
    )}
  </div>
)
