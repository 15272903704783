import {useEffect, useMemo} from 'react'
import SectionHeader from 'Components/Profile/SectionHeader'
import SectionItem from 'Components/Profile/SectionItem'
import ItemHeader from 'Components/Profile/ItemHeader'
import TextError from 'Components/Error/TextError'
import styles from './DrivingLicences.module.scss'
import classnames from 'classnames'
import {
  BaseTaxonomyType,
  DataType,
  DrivingLicenseSaveType,
  SeekerStateType,
} from 'Interfaces'
import {useFormik} from 'formik'
import Hr from 'Components/Hr'
import {ProfileTranslations as pt} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import {Button, ButtonGroup} from '@mui/material'
import {DirectionsCar} from '@mui/icons-material'

interface DrivingLicencesProps {
  user: SeekerStateType
  addDrivingLicence: (value: DrivingLicenseSaveType) => void
  editDrivingLicence: (value: DrivingLicenseSaveType) => void
  canChange?: boolean
  drivingLicenseCategories: DataType<BaseTaxonomyType>
  getDrivingLicenseCategories: () => void
}

export const DrivingLicences = ({
  user,
  addDrivingLicence,
  editDrivingLicence,
  canChange = false,
  drivingLicenseCategories,
  getDrivingLicenseCategories,
}: DrivingLicencesProps) => {
  const alignedLicences = useMemo(() => {
    return drivingLicenseCategories.data.map((l) => ({
      ...l,
      selected:
        !!user.data?.full?.drivingLicense?.seekerDrivingLicenseCategories?.find(
          (d) => d.drivingLicenseCategory.id === l.id
        ) || false,
    }))
  }, [
    user.data?.full?.drivingLicense?.seekerDrivingLicenseCategories,
    drivingLicenseCategories,
  ])

  const formik = useFormik({
    initialValues: {
      drivingLicenseCategory: alignedLicences.map((a) => ({...a})),
      ownVehicle: !!user.data?.full?.drivingLicense?.ownVehicle,
    },
    onSubmit: (values) => {
      const categoriesToSave = values.drivingLicenseCategory
        .filter((v) => v.selected)
        .map((d) => ({
          drivingLicenseCategory: {id: d.id, nameTranslation: d.translation},
        }))
      const saveValue = {
        drivingLicenseCategoryIds: categoriesToSave.map(
          (d) => d.drivingLicenseCategory.id
        ),
        ownVehicle: values.ownVehicle,
        seekerDrivingLicenseCategories: categoriesToSave,
      }

      if (
        user.data?.full?.drivingLicense?.seekerDrivingLicenseCategories?.length
      ) {
        editDrivingLicence(saveValue)
      } else addDrivingLicence(saveValue)
    },
  })

  const handleChangeCategory = (index: number) => {
    const newOptions = [...formik.values.drivingLicenseCategory]
    if (index === drivingLicenseCategories.data.length - 1) {
      newOptions.forEach((_, i) => {
        newOptions[i].selected = false
      })
      newOptions[drivingLicenseCategories.data.length - 1].selected = true
    } else {
      newOptions[index].selected = !newOptions[index].selected
      newOptions[drivingLicenseCategories.data.length - 1].selected = false
    }

    formik.setValues({...formik.values, drivingLicenseCategory: newOptions})
    formik.handleSubmit()
  }
  const handleChangeOwnVehicle = (value: boolean) => {
    formik.setValues({...formik.values, ownVehicle: value})
    formik.handleSubmit()
  }
  useEffect(() => {
    formik.resetForm({
      values: {
        drivingLicenseCategory: alignedLicences.map((a) => ({...a})),
        ownVehicle: !!user.data?.full?.drivingLicense?.ownVehicle,
      },
    })
  }, [
    user.data?.full?.drivingLicense?.seekerDrivingLicenseCategories,
    user.data?.full?.drivingLicense?.ownVehicle,
  ])

  useEffect(() => {
    getDrivingLicenseCategories()
  }, [])

  return (
    <div className={styles.section}>
      <div>
        <SectionHeader title={pt.drivingLicenceTitle} />
      </div>
      <SectionItem>
        <ItemHeader
          title={canChange ? pt.yourCategories : pt.categories}
          icon={DirectionsCar}
        />
        <div className={styles.content}>
          <div className={styles.mainContent}>
            {canChange
              ? formik.values.drivingLicenseCategory.map((licence, index) => (
                  <label key={licence.id}>
                    <input
                      checked={licence.selected}
                      type="checkbox"
                      name="drivingLicences"
                      className={styles.hidden}
                      onChange={() => handleChangeCategory(index)}
                      value={licence.id}
                      multiple
                    />
                    <div
                      className={classnames(styles.item, {
                        [styles.selected]: licence.selected,
                        [styles.withPointer]: canChange,
                      })}
                    >
                      {licence.translation}
                    </div>
                  </label>
                ))
              : alignedLicences.map((licence, index) => (
                  <div
                    key={`${licence.id}-${index}`}
                    className={classnames(styles.item, {
                      [styles.selected]: licence.selected,
                    })}
                  >
                    {licence.translation}
                  </div>
                ))}
          </div>
          <Hr className={styles.hr} />
          {canChange ? (
            <div className={styles.paragraphMain}>
              <FormattedMessage id={pt.ownMeansOfTransport} />
              <div className={styles.filterTypeWrap}>
                <ButtonGroup>
                  <Button
                    className={classnames(styles.filterTypeButton, {
                      [styles.filterTypeButtonActive]:
                        !formik.values.ownVehicle,
                    })}
                    onClick={() => {
                      handleChangeOwnVehicle(false)
                    }}
                  >
                    <FormattedMessage id="no" />
                  </Button>
                  <Button
                    className={classnames(styles.filterTypeButton, {
                      [styles.filterTypeButtonActive]: formik.values.ownVehicle,
                    })}
                    onClick={() => {
                      handleChangeOwnVehicle(true)
                    }}
                  >
                    <FormattedMessage id="yes" />
                  </Button>
                </ButtonGroup>
              </div>
            </div>
          ) : (
            <div className={styles.paragraphMain}>
              <p className={styles.paragraphContent}>
                <FormattedMessage id={pt.ownMeansOfTransport} />
              </p>
              <span className={styles.uppercase}>
                <FormattedMessage
                  id={
                    user.data?.full?.drivingLicense?.ownVehicle ? 'yes' : 'no'
                  }
                />
              </span>
            </div>
          )}
        </div>
        {user.error && <TextError text={user.error.message} />}
      </SectionItem>
    </div>
  )
}
