import * as type from 'Store/types'
import {ActionType, ErrorType, SpectoProposeNewTimeParamsType} from 'Interfaces'
import {SpectoStateType} from 'Interfaces'

const initialState = {
  data: {
    token: undefined,
    candidateSpectoId: undefined,
    candidateId: undefined,
    proposeNewTimeId: undefined,
  },
  loading: false,
  error: undefined,
}

const SpectoReducer = (
  state: SpectoStateType = initialState,
  action: ActionType<string | SpectoProposeNewTimeParamsType | ErrorType>
): SpectoStateType => {
  switch (action.type) {
    case type.specto.token.requested:
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case type.specto.token.succeeded:
      return {
        data: {
          ...state.data,
          token: action.payload as string,
        },
        loading: false,
        error: undefined,
      }
    case type.specto.token.failed:
      return {
        ...state,
        loading: false,
        error: action.payload as ErrorType,
      }

    case type.specto.candidateId.set:
      return {
        ...state,
        data: {
          ...state.data,
          candidateId: action.payload as string,
        },
      }

    case type.specto.proposeNewTimeId.set:
      const proposeNewTimePayload =
        action.payload as SpectoProposeNewTimeParamsType
      return {
        ...state,
        data: {
          ...state.data,
          proposeNewTimeId: proposeNewTimePayload.requestId,
          candidateId: proposeNewTimePayload.candidateId,
          candidateSpectoId: proposeNewTimePayload.candidateSpectoId,
        },
      }

    case type.specto.candidateSpectoId.requested:
      return {
        ...state,
        loading: true,
        error: undefined,
      }
    case type.specto.candidateSpectoId.succeeded:
      return {
        data: {
          ...state.data,
          candidateSpectoId: action.payload as string,
        },
        loading: false,
        error: undefined,
      }
    case type.specto.candidateSpectoId.failed:
      return {
        ...state,
        loading: false,
        error: action.payload as ErrorType,
      }

    default:
      return state
  }
}

export default SpectoReducer
