import {connect} from 'react-redux'
import {getPsychometricTestResults} from 'Store/Actions'
import {AssessmentsSuccess} from './AssessmentsSuccess'

const mapDispatchToProps = (dispatch: any) => ({
  getResults: (id: string) => dispatch(getPsychometricTestResults(id)),
})

export const AssessmentsSuccessContainer = connect(
  null,
  mapDispatchToProps
)(AssessmentsSuccess)
