import classNames from 'classnames'
import {FC} from 'react'
import styles from './TestField.module.scss'
import {FormattedMessage} from 'react-intl'

interface TestFieldProps {
  label?: string
  text?: string
  className?: string
}

export const TestField: FC<TestFieldProps> = ({label, text, className}) => {
  return (
    <>
      <p
        className={classNames(
          styles.testMobileLabel,
          styles.testMobileProviderWrapper,
          className
        )}
      >
        {label && <FormattedMessage id={label} />}
      </p>
      <p className={styles.testName}>
        {text && <FormattedMessage id={text} />}
      </p>
    </>
  )
}
