import {push} from 'connected-react-router'
import {all, put, takeLatest, call} from 'redux-saga/effects'
import {AppRoute} from 'Services/I18n/Constants'
import {getRoute} from 'Services/I18n/Utils'
import * as type from '../types'
import API from 'Services/API'

function* fetchCandidateCompleteness() {
  const {response, error} = yield call(
    API.get,
    '/user/seeker-profile-completeness'
  )

  if (response) {
    yield put({
      type: type.candidate.completeness.succeeded,
      payload: {
        value: response.data.profileCompleteness,
        fields: response.data.profileCompletenessFields,
      },
    })
  } else {
    yield put({
      type: type.candidate.completeness.failed,
      payload: error,
    })
    yield put(push(getRoute(AppRoute.SeekerLogin)))
  }
}

export default function* CandidateProfileCompletenessSaga(): Generator {
  yield all([
    takeLatest(
      type.candidate.completeness.requested,
      fetchCandidateCompleteness
    ),
  ])
}
