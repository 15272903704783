export const aboutUs = {
  'aboutUs.mainTitle': 'O NAS',
  'aboutUs.paragraphFirst':
    'Preferenca je zaživela v 2022. Kljub mladosti, nam ne manjka prodornosti in izkušenj. Našo ekipo sestavlja skupina inovativnih HR strokovnjakov, ki uporablja napredno tehnologijo in empatičen pristop, da povežejo ambiciozne posameznike z uspešnimi podjetji.',
  'aboutUs.title': 'Kaj je Preferenca?',
  'aboutUs.paragraphSecond':
    'Preferenca je prva zaposlitvena platforma v Sloveniji osredotočena na iskalce zaposlitve, predvsem razvijalce, strojnike in elektrotehnike. Pomaga jim izbrati zaposlitev, ki jim je res pisana na kožo – najsi bo na podlagi najsodobnejše tehnologije, navdihujoče ekipe ali samo na podlagi dobre stare plače. Na Preferenci so namreč pravila igre zamenjana, podjetja so tista, ki pošiljajo prijave, iskalci pa le izbirajo.',
  'aboutUs.label':
    'Preferenca je produktna znamka podjetja Styria digital marketplaces, d.o.o.',
}
