export enum ModalsAssessmentsTranslations {
  psychometricTestTitle = 'modalsassessmentstranslations.psychometricTestTitle',
  cognitiveTestTitle = 'modalsassessmentstranslations.cognitiveTestTitle',
  psychometricTestTextFirstPart = 'modalsassessmentstranslations.psychometricTestTextFirstPart',
  psychometricTestTextSecondPart = 'modalsassessmentstranslations.psychometricTestTextSecondPart',
  cognitiveTestTextFirstPart = 'modalsassessmentstranslations.cognitiveTestTextFirstPart',
  cognitiveTestTextSecondPart = 'modalsassessmentstranslations.cognitiveTestTextSecondPart',
  privacyPolicy = 'modalsassessmentstranslations.privacyPolicy',
  addLanguageTest = 'modalsassessmentstranslations.addLanguageTest',
  editLanguageTest = 'modalassessments.editLanguageTest',
  deleteLanguageTest = 'modalassessments.deleteLanguageTest',
  testTakenDateValidation = 'modalsassessmentstranslations.testTakenDateValidation',
  testProviderValidation = 'modalsassessmentstranslations.testProviderValidation',
  testNameValidation = 'modalsassessmentstranslations.testNameValidation',
  skillTestExpirationDate = 'modalsassessmentstranslations.skillTestExpirationDate',
  skillTestName = 'modalsassessmentstranslations.skillTestName',
  skillTestProvider = 'modalsassessmentstranslations.skillTestProvider',
  skillTestTakenDate = 'modalsassessmentstranslations.skillTestTakenDate',
  languageTestLevel = 'modalsassessmentstranslations.languageTestLevel',
  languageTestTakenDate = 'modalsassessmentstranslations.languageTestTakenDate',
  languageTestProvider = 'modalsassessmentstranslations.languageTestProvider',
  languageTestExpirationDate = 'modalsassessmentstranslations.languageTestExpirationDate',
  languageTestName = 'modalsassessmentstranslations.languageTestName',
  addSkillTest = 'modalsassessmentstranslations.addSkillTest',
  editSkillTest = 'modalassessments.editSkillTest',
  deleteSkillTest = 'modalassessments.deleteSkillTest',
}
