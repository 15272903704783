import styles from './EmployerLandingText.module.scss'
import {FC} from 'react'
import YellowText from 'Components/YellowText'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {EmployerHomePageTranslations as el} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import YellowLink from 'Components/YellowLink'
export interface EmployerLandingTextType {
  text?: string
}
export const EmployerLandingText: FC<EmployerLandingTextType> = () => {
  return (
    <>
      <div className={styles.mainContainer}>
        <h1 className={styles.title}>
          <FormattedMessage id={el.titleLandingTxt} />
          <YellowText text={el.yellowLandingTxt} />
        </h1>
        <p className={styles.textContainer}>
          <FormattedMessage id={el.paragraphFirst} />
        </p>
        <p className={styles.textContainerNoBold}>
          <FormattedMessage id={el.paragraphSecond} />
        </p>
        <div className={styles.buttonContainer}>
          <YellowLink
            to={getRoute(AppRoute.HelpSupport)}
            text={el.buttonLandingTxt}
            className={styles.button}
          />
        </div>
      </div>
    </>
  )
}
