import {CombinedState, combineReducers, Reducer} from 'redux'
import {connectRouter, RouterState} from 'connected-react-router'
import {History} from 'history'
import * as type from 'Store/types'
import {
  AppStateType,
  TaxonomiesStateType,
  AuthStateType,
  OnboardingStateType,
  StateType,
  CompanyDataType,
  AssessmentsStateType,
  MyProjectsStateType,
  WidgetBarStateType,
  SeekerStateType,
  EmployerStateType,
  ConsentsStateType,
  SpectoStateType,
  HomepageStateType,
  CandidateStateType,
  IncomingRequestsStateType,
  MessageCenterStateType,
  EmployerOnboardingStateType,
  CandidateProfileCompletenessStateType,
  CandidateSearchStateType,
  BlacklistStateType,
} from 'Interfaces'

import TaxonomiesReducer from './TaxonomiesReducer'
import AssessmentsReducer from './AssessmentsReducer'
import ConsentsReducer from './ConsentsReducer'
import OnboardingReducer from './OnboardingReducer'
import CompanyReducer from './CompanyReducer'
import CandidateSearchReducer from './CandidateSearchReducer'
import AppReducer from './AppReducer'
import MyProjectsReducer from './MyProjectsReducer'
import WidgetBarReducer from './WidgetBarReducer'
import SeekerReducer from './SeekerReducer'
import EmployerReducer from './EmployerReducer'
import AuthReducer from './AuthReducer'
import BlacklistReducer from './BlacklistReducer'
import SpectoReducer from './SpectoReducer'
import HomepageReducer from './HomepageReducer'
import CandidateReducer from './CandidateReducer'
import IncomingRequestsReducer from './IncomingRequestsReducer'
import MessageCenterReducer from './MessageCenterReducer'
import CandidateProfileCompletenessReducer from './CandidateProfileCompletenessReducer'
import EmployerOnboardingReducer from './EmployerOnboardingReducer'

export type RootState = {
  router: RouterState
  auth: AuthStateType
  seeker: SeekerStateType
  employer: EmployerStateType
  assessments: AssessmentsStateType
  consents: ConsentsStateType
  taxonomies: TaxonomiesStateType //rename to state type
  onboarding: OnboardingStateType
  employerOnboarding: EmployerOnboardingStateType
  company: StateType<CompanyDataType>
  searchCandidates: CandidateSearchStateType
  app: AppStateType
  myProjects: MyProjectsStateType
  widgetBar: WidgetBarStateType
  blacklist: BlacklistStateType
  specto: SpectoStateType
  homepage: HomepageStateType
  candidate: CandidateStateType
  incomingRequests: IncomingRequestsStateType
  messageCenter: MessageCenterStateType
  candidateProfileCompleteness: CandidateProfileCompletenessStateType
}

const RootReducer =
  (history: History): Reducer<CombinedState<RootState>> =>
  (state, action) => {
    if (action.type === type.USER_LOGOUT_SUCCEEDED) {
      state = undefined
    }

    const appReducer = combineReducers<RootState>({
      router: connectRouter(history),
      auth: AuthReducer,
      seeker: SeekerReducer,
      employer: EmployerReducer,
      taxonomies: TaxonomiesReducer,
      assessments: AssessmentsReducer,
      consents: ConsentsReducer,
      onboarding: OnboardingReducer,
      employerOnboarding: EmployerOnboardingReducer,
      company: CompanyReducer,
      searchCandidates: CandidateSearchReducer,
      app: AppReducer,
      myProjects: MyProjectsReducer,
      widgetBar: WidgetBarReducer,
      blacklist: BlacklistReducer,
      specto: SpectoReducer,
      homepage: HomepageReducer,
      candidate: CandidateReducer,
      incomingRequests: IncomingRequestsReducer,
      messageCenter: MessageCenterReducer,
      candidateProfileCompleteness: CandidateProfileCompletenessReducer,
    })

    return appReducer(state, action)
  }


export default RootReducer
