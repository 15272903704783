import {connect} from 'react-redux'

import {
  loadMoreIncomingRequests,
  searchIncomingRequests,
  getCompaniesIncomingRequests,
  getFullCandidateData,
  getSeekerProfile,
} from 'Store/Actions'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {AllIncomingRequests} from './AllIncomingRequests'
import {IncomingRequestsSearchParamsType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  searchResults: state.incomingRequests.searchResults,
  fullName: {
    firstName:
      state.seeker.data?.full?.firstName ||
      state.candidate.data.fullCandidateData.firstName,
    lastName:
      state.seeker.data?.full?.lastName ||
      state.candidate.data.fullCandidateData.lastName,
  },
  companies: state.incomingRequests.companies,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  searchIncomingRequests: (params?: IncomingRequestsSearchParamsType) =>
    dispatch(searchIncomingRequests(params)),
  loadMoreIncomingRequests: (loadMoreUrl: string) =>
    dispatch(loadMoreIncomingRequests(loadMoreUrl)),
  getCompanies: () => dispatch(getCompaniesIncomingRequests()),
  getFullCandidateData: () => {
    dispatch(getFullCandidateData())
    dispatch(getSeekerProfile())
  },
})

export const AllIncomingRequestsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(AllIncomingRequests)
