export enum RegistrationTranslations {
  title = 'registration.title',
  description = 'registration.description',
  firstName = 'registration.firstName',
  lastName = 'registration.lastName',
  repeatPassword = 'registration.repeatPassword',
  privacyPolicy = 'registration.privacyPolicy',
  readMore = 'registration.readMore',
  agree = 'registration.agree',
  and = 'registration.and',
  generalTermsOfService = 'registration.generalTermsOfService',
  termsOfService = 'registration.termsOfService',
  nextButton = 'registration.nextButton',
  sideBarTitle = 'registration.sideBarTitle',
  sideBarJobOffers = 'registration.sideBarJobOffers',
  sideBarCreateCv = 'registration.sideBarCreateCv',
  sideBarContactCompany = 'registration.sideBarContactCompany',
  sideBarFindMatch = 'registration.sideBarFindMatch',
  sideBarHrSpecialist = 'registration.sideBarHrSpecialist',
  loaderText = 'registration.loaderText',
}
