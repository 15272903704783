import { SvgIconProps } from '@mui/material';
import { ReactElement } from 'react';
import style from './CardInfoItem.module.scss'

type CardInfoItemProps = {
    icon: ReactElement<SvgIconProps>;
    label: string;
    value: string;
}

export const CardInfoItem = ({
    icon,
    label,
    value
}: CardInfoItemProps) => {
    return (
        <div className={style.candidateInfoWrap}>
            <div className={style.infoIcon}>{icon}</div>
            <div className={style.textWrap}>
                <span className={style.infoLabel}>{label}</span>
                <span className={style.infoValue}>{value}</span>
            </div>
        </div>
    )
}