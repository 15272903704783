import {FC} from 'react'
import styles from './EmptyCandidateDashboard.module.scss'
import {Title} from 'Components/Login'
import {Hr} from 'Components/Hr/Hr'
import {FormattedMessage} from 'react-intl'
import {CandidateDashboardTranslations as cdt} from 'Services/I18n/Constants'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {Link} from 'react-router-dom'

export const EmptyCandidateDashboard: FC = () => {
  return (
    <div className={styles.myDashboard}>
      <Title text={cdt.title} className={styles.title} />
      <Hr />
      <p className={styles.paragraph}>
        <FormattedMessage id={cdt.paragraphTitle} />
      </p>
      <p className={styles.paragraphTitleTxt}>
        <FormattedMessage id={cdt.paragraphTitleTxt} />
      </p>
      <p className={styles.paragraph}>
        <FormattedMessage id={cdt.cvProfileLinkPart1} />
        <Link to={getRoute(AppRoute.Profile)} className={styles.link}>
          <FormattedMessage id={cdt.cvProfileLinkPart2} />
        </Link>
        <FormattedMessage id={cdt.cvProfileLinkPart3} />
      </p>
      <p className={styles.paragraph}>
        <FormattedMessage id={cdt.publicProfileLinkPart1} />
        <Link to={getRoute(AppRoute.PublicProfile)} className={styles.link}>
          <FormattedMessage id={cdt.publicProfileLinkPart2} />
        </Link>
        <FormattedMessage id={cdt.publicProfileLinkPart3} />
      </p>
      <p className={styles.paragraph}>
        <FormattedMessage id={cdt.psychometricTestLinkPart1} />
        <Link to={getRoute(AppRoute.PsychometricTest)} className={styles.link}>
          <FormattedMessage id={cdt.psychometricTestLinkPart2} />
        </Link>
        <FormattedMessage id={cdt.psychometricTestLinkPart3} />
        <Link to={getRoute(AppRoute.CognitiveTest)} className={styles.link}>
          <FormattedMessage id={cdt.cognitiveTestLinkPart1} />
        </Link>
        <FormattedMessage id={cdt.cognitiveTestLinkPart2} />
      </p>
      <p className={styles.paragraph}>
        <FormattedMessage id={cdt.paragraphLast1} />
        <Link to={getRoute(AppRoute.Profile)} className={styles.link}>
          <FormattedMessage id={cdt.paragraphLast2} />
        </Link>
        <FormattedMessage id={cdt.paragraphLast3} />
      </p>
    </div>
  )
}
