import {FC, useEffect, useMemo, useState} from 'react'

import RateCandidate from 'Components/Widgets/RateCandidateDashboard'
import Hr from 'Components/Hr'
import AboutCandidate from 'Components/CandidateSearch/AboutCandidate'
import {LinksContainer} from 'Components/LinksContainer'
import {
  AppRoute,
  EmployerDashboardTranslations as edt,
} from 'Services/I18n/Constants'
import {HistoryEdu, MailOutline, Videocam} from '@mui/icons-material'
import Comments from 'Components/Widgets/Comments'
import CommentsList from 'Components/Widgets/CommentsList'
import {Candidate} from 'Interfaces/CandidateType'
import {
  CommentType,
  ConversationMessageType,
  ConversationType,
  HistoryType,
  ImageType,
  IncomingRequestSeekerType,
  IncomingRequestType,
  LinkType,
  RjbProfileStatus,
} from 'Interfaces'
import {Avatar} from 'Components/Widgets/Avatar'
import {
  ContactRequestStatus,
  RequestType,
} from 'Components/IncomingRequests/Constants'
import ModalSendMessageEmp from 'Components/EmployerDashboard/InterviewInvitationListItem/ModalSendMessageEmp'
import {MessagesListContainer} from 'Components/Widgets/MessagesList'

import styles from './CandidateSidebarContent.module.scss'
import {getRoute} from 'Services/I18n/Utils'
import ModalSendOfferEmp from 'Components/EmployerDashboard/InterviewInvitationListItem/ModalSendOfferEmp'
import {FormattedMessage} from 'react-intl'

interface CandidateSidebarContentProps {
  candidateBasicData: Candidate | IncomingRequestSeekerType | null
  request: IncomingRequestType | null
  messages?: ConversationMessageType[]
  history: HistoryType[]
  comments: {items: CommentType[]}
  getCandidateHistory: (id: string) => void
  getCandidateComments: (id: string) => void
  getConversationByUserIds: (query: string) => void
  openedConversation: ConversationType
  selectConversation: (conversation?: ConversationType) => void
  resetMessageCenterData: () => void
}

export const CandidateSidebarContent: FC<CandidateSidebarContentProps> = ({
  candidateBasicData,
  request,
  comments,
  getCandidateComments,
  messages,
  // history,
  // getCandidateHistory,
  getConversationByUserIds,
  openedConversation,
  selectConversation,
  resetMessageCenterData,
}) => {
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false)
  const [isOfferModalOpen, setIsOfferModalOpen] = useState(false)

  const isAvatarAllowed =
    (request?.type === RequestType.CONTACT_REQUEST &&
      request?.status === ContactRequestStatus.APPROVED) ||
    request?.type === RequestType.INTERVIEW_REQUEST ||
    request?.type === RequestType.OFFER_REQUEST ||
    request?.type === RequestType.PLACEMENT_REQUEST

  const isApproved =
    request?.type === RequestType.CONTACT_REQUEST &&
    request?.status === ContactRequestStatus.APPROVED

  const noLongerActiveCandidate =
    !candidateBasicData?.hasFinishedPlacementWithCurrentEmployer &&
    candidateBasicData?.rjbProfileStatus === RjbProfileStatus.CLOSED

  const links: LinkType[] = useMemo(() => {
    if (isApproved) {
      return [
        {
          name: edt.sendMessage,
          onClick: () => setIsMessageModalOpen(true),
          icon: <MailOutline />,
        },
        {
          name: edt.invite,
          route: `${getRoute(AppRoute.InviteCalendar).replace(
            ':candidateId',
            candidateBasicData?.userId as string
          )}`,
          icon: <Videocam />,
        },
        {
          name: edt.sendOffer,
          onClick: () => setIsOfferModalOpen(true),
          icon: <HistoryEdu />,
        },
        // TODO employer-dashboard: Add when bookmarking gets implemented
        // {
        //   name: 'Bookmark',
        //   route: AppRoute.Profile,
        //   icon: <BookmarkBorderIcon />,
        // },
      ]
    }
    return []
  }, [isApproved, candidateBasicData, request])

  useEffect(() => {
    if (candidateBasicData) {
      getCandidateComments(candidateBasicData?.userId || candidateBasicData.id)
      getConversationByUserIds(`userIds=${candidateBasicData?.userId}`)
      // getCandidateHistory(candidateBasicData!.id)
    }
  }, [candidateBasicData])

  useEffect(() => {
    selectConversation(openedConversation)
  }, [openedConversation])

  useEffect(() => {
    return () => {
      resetMessageCenterData()
    }
  }, [])

  if (!candidateBasicData) return null

  const {firstName, lastName, seekerEvaluations} = candidateBasicData
  return (
    <div className={styles.sidebar}>
      {isAvatarAllowed && (
        <div className={styles.avatarImageContainer}>
          <div className={styles.hrWrapper}>
            <Hr className={styles.darkGrayHr} />
          </div>

          <Avatar
            firstName={candidateBasicData.firstName as string}
            lastName={candidateBasicData.lastName as string}
            image={candidateBasicData?.profilePicture as ImageType}
          />

          <div className={styles.hrWrapper}>
            <Hr className={styles.darkGrayHr} />
          </div>
        </div>
      )}
      {!isApproved &&
        !isAvatarAllowed &&
        (candidateBasicData?.publicOccupation ||
          candidateBasicData?.town ||
          candidateBasicData?.country) && (
          <div className={styles.firstHrWrap}>
            <Hr className={styles.darkGrayHr} />
          </div>
        )}
      {!candidateBasicData?.publicOccupation?.translation && (
        <div className={styles.noPublicOccupation}></div>
      )}
      <AboutCandidate
        name={firstName && lastName ? firstName + ' ' + lastName : ''}
        proffession={candidateBasicData?.publicOccupation?.translation || ''}
        isAccepted={isApproved || isAvatarAllowed}
      />
      {noLongerActiveCandidate && (
        <div className={styles.noLongerActiveCandidateMessage}>
          <FormattedMessage id={edt.candidateNoLongerActive} />
        </div>
      )}
      <div className={styles.hrWrapper}>
        <Hr className={styles.darkGrayHr} />
      </div>
      {request && (
        <RateCandidate
          requestId={request.id}
          seekerEvaluation={seekerEvaluations}
          readOnly={noLongerActiveCandidate}
        />
      )}
      <div className={styles.hrWrapper}>
        <Hr className={styles.darkGrayHr} />
      </div>
      {links.length > 0 && !noLongerActiveCandidate && (
        <>
          <LinksContainer links={links} />
          <div className={styles.hrWrapper}>
            <Hr className={styles.darkGrayHr} />
          </div>
        </>
      )}
      <Comments page="dashboard" />
      <CommentsList items={comments.items} />
      {candidateBasicData.firstName && (
        <MessagesListContainer items={messages && messages} />
      )}

      {/*<HistoryList items={history} /> */}
      <ModalSendMessageEmp
        isOpen={isMessageModalOpen}
        setIsOpen={setIsMessageModalOpen}
        request={request as IncomingRequestType}
        parentSelector={() =>
          document.querySelector(`.${styles.sidebar}`) as HTMLElement
        }
      />
      {request && (
        <ModalSendOfferEmp
          isOpen={isOfferModalOpen}
          setIsOpen={setIsOfferModalOpen}
          requestId={request?.journeyId}
          parentSelector={() =>
            document.querySelector(`.${styles.sidebar}`) as HTMLElement
          }
        />
      )}
    </div>
  )
}
