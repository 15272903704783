import {DateTime} from 'luxon'

// TODO: Replace with luxon
const defaultDateFormat: Intl.DateTimeFormatOptions = {
  weekday: 'long',
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}

export const formatDate = (date: string) =>
  DateTime.fromISO(date).toFormat('d.L.yyyy')

export const formatDateLongMonth = (date: string) =>
  DateTime.fromISO(date).toFormat('dd LLLL yyyy')

export const timeSince = (date: Date | string) => {
  if (typeof date !== 'object') {
    date = new Date(date)
  }

  let seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000)
  let intervalType

  let interval = Math.floor(seconds / 31536000)
  if (interval >= 1) {
    intervalType = 'year'
  } else {
    interval = Math.floor(seconds / 2592000)
    if (interval >= 1) {
      intervalType = 'month'
    } else {
      interval = Math.floor(seconds / 86400)
      if (interval >= 1) {
        intervalType = 'day'
      } else {
        interval = Math.floor(seconds / 3600)
        if (interval >= 1) {
          intervalType = 'hour'
        } else {
          interval = Math.floor(seconds / 60)
          if (interval >= 1) {
            intervalType = 'minute'
          } else {
            interval = seconds
            intervalType = 'second'
          }
        }
      }
    }
  }

  if (interval > 1 || interval === 0) {
    intervalType += 's'
  }

  return interval + ' ' + intervalType + ' ago'
}

export const timeSince7days = (
  date: Date | string,
  locale: string = 'en-US',
  format: Intl.DateTimeFormatOptions = defaultDateFormat
) => {
  if (typeof date !== 'object') {
    date = new Date(date)
  }

  let seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000)
  const days8 = 691200
  if (Math.floor(seconds / days8) < 1) {
    return timeSince(date)
  } else {
    return date.toLocaleDateString(locale, format)
  }
}

export const getRestDaysOfMonth = (month: number, year: number) => {
  const today = new Date()
  const lastDayOfMonth = new Date(
    year || today.getFullYear(),
    month,
    0
  ).getDate()

  if (month === today.getMonth() + 1 && year === today.getFullYear()) {
    return Array.from(Array(lastDayOfMonth).keys())
      .map((key) => key + 1)
      .slice(today.getDate() - 1)
  }

  return Array.from(Array(lastDayOfMonth).keys()).map((key) => key + 1)
}

export const getRestMonths = (year: number) => {
  const today = new Date()
  const currentMonth = today.getMonth()
  const monthKeys = Array.from(Array(12).keys()).map((m) => m + 1)

  if (year === today.getFullYear()) {
    return monthKeys.slice(currentMonth)
  }
  return monthKeys
}

export const getRestYears = () => {
  const today = new Date()
  const currentYear = today.getFullYear()

  return [currentYear, currentYear + 1, currentYear + 2]
}

export const getRestHours = (day: number, month: number) => {
  const today = new Date()
  const currentHour = today.getHours()
  if (day === today.getDate() && month === today.getMonth() + 1) {
    return Array.from(Array(24).keys()).slice(currentHour + 1)
  }
  return Array.from(Array(24).keys())
}

export const getYearsOld = (birthday:string) => {
  return Math.floor(Math.abs(DateTime.fromISO(birthday).diffNow('years').years))
}
