import {Email, Password, FirstName, LastName} from 'Validations'
import * as Yup from 'yup'

const Validation = Yup.object({
  firstName: FirstName,
  lastName: LastName,
  email: Email,
  password: Password,
  verifyPassword: Yup.string()
    .required('Password is required.')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
  consent1B: Yup.bool().oneOf([true], 'Accepting Privacy policy is required'),
  consent2: Yup.bool().oneOf([true], 'Accepting Terms of service is required'),
})

export default Validation
