export enum EmailChangeVerification {
  successTitle = 'emailchangeverification.successTitle',

  successLinkText = 'emailchangeverification.successLinkText',

  pending = 'emailchangeverification.pending',
  pleaseWaitText = 'emailchangeverification.pleaseWaitText',

  verificationFailed = 'emailchangeverification.verificationFailed',
  verificationFailedDescription = 'emailchangeverification.verificationFailedDescription',
}
