import Breadcrumbs from 'Components/Breadcrumbs'
import {
  BaseTaxonomyType,
  IncomingRequestCompaniesType,
  IncomingRequestsSearchParamsType,
  IncomingRequestsSearchResultsStateType,
  // companyParamsType,
} from 'Interfaces'
import CompanyProfileFooter from 'Components/Footer'
import MainLayout from 'Layouts/MainLayout'
import {FC, useEffect, useState} from 'react'
import styles from './AllIncomingRequests.module.scss'
import IncomingRequests from 'Components/IncomingRequests'
import SearchIcon from '@mui/icons-material/Search'
import {useFormik} from 'formik'
import Dropdown from 'Components/Inputs/Dropdown'
import {SetValueProps} from 'Utils/FormikProps'
import InfiniteScroll from 'Components/InfiniteScroll'
import {Button} from 'Components/Button'
import {getRoute} from 'Services/I18n/Utils'
import {
  AppRoute,
  CandidateDashboardTranslations as cdr,
  EmployerDashboardTranslations as edt,
} from 'Services/I18n/Constants'
import TypeAhead from 'Components/Inputs/TypeAhead'
import {DropdownProps} from 'Utils/FormikProps'
import {
  seekerRequestStatusesDropdownItems,
  requestTypesDropdownItems,
} from 'Components/IncomingRequests/Constants'
import {useTranslatedBaseTaxonomyArray} from 'Hooks'
import {FormattedMessage} from 'react-intl'

type DefaultFormikValuesType = {
  requestType?: string
  status?: string
  company: BaseTaxonomyType[]
}

type AllIncomingRequestsProps = {
  companies: IncomingRequestCompaniesType
  searchResults: IncomingRequestsSearchResultsStateType
  searchIncomingRequests: (params?: IncomingRequestsSearchParamsType) => void
  loadMoreIncomingRequests: (url: string) => void
  getCompanies: () => void
  getFullCandidateData: () => void
  fullName: {firstName: string; lastName: string}
}

export const AllIncomingRequests: FC<AllIncomingRequestsProps> = ({
  companies,
  searchResults,
  searchIncomingRequests,
  loadMoreIncomingRequests,
  getCompanies,
  getFullCandidateData,
  fullName,
}) => {
  const [clearTypeahead, setClearTypeahead] = useState(false)
  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: getRoute(AppRoute.HomePageEmployer)},
    {
      name: `${fullName.firstName} ${fullName.lastName}`,
      link: getRoute(AppRoute.Profile),
    },
    {name: cdr.dashboard, link: getRoute(AppRoute.CandidateDashboard)},
    {name: cdr.allRequests},
  ]

  useEffect(() => {
    searchIncomingRequests()
    getCompanies()
    getFullCandidateData()
  }, [])

  const dropdownStatusItems = useTranslatedBaseTaxonomyArray(
    seekerRequestStatusesDropdownItems
  )
  const dropdownTypeItems = useTranslatedBaseTaxonomyArray(
    requestTypesDropdownItems
  )

  const onSortResults = (sort: string, order: string) => {
    searchIncomingRequests({
      ...searchResults?.params,
      sort,
      order,
    })
  }

  const defaultValues: DefaultFormikValuesType = {
    requestType: undefined,
    status: undefined,
    company: [],
  }

  const formik = useFormik({
    initialValues: defaultValues,
    onSubmit: (values) => {
      const {company, ...restValues} = values
      searchIncomingRequests({
        ...searchResults.params,
        ...restValues,
        companyId: company.length > 0 ? company[0].id : undefined,
      })
    },
  })

  const resetSearch = () => {
    formik.resetForm()
    setClearTypeahead(!clearTypeahead)
    const {company, ...restValues} = defaultValues
    searchIncomingRequests({
      ...searchResults.params,
      ...restValues,
      companyId: undefined,
    })
  }

  return (
    <MainLayout
      SubNavigation={
        <div className={styles.breadcrumbsWrapper}>
          <Breadcrumbs breadcrumbs={breadcrumbsArray} />
        </div>
      }
      Footer={
        <div>
          <CompanyProfileFooter />
        </div>
      }
    >
      <div className={styles.pageWrap}>
        <div className={styles.requestsWrap}>
          <div className={styles.heading}>
            <FormattedMessage id={cdr.allRequests} />
          </div>
          <div className={styles.searchToolbar}>
            <div className={styles.searchFieldWrap}>
              <TypeAhead
                name="company"
                items={companies.data}
                maxNumSelections={1}
                withoutArrow
                placeholder={cdr.searchCompany}
                className={styles.typeahead}
                forceClear={clearTypeahead}
                {...DropdownProps(formik, 'company')}
              />
              <div className={styles.searchIcon}>
                <SearchIcon />
              </div>
            </div>
            <Dropdown
              name="requestType"
              emptyValueLabel={cdr.requestType}
              items={dropdownTypeItems}
              className={styles.filterDropdown}
              {...SetValueProps(formik, 'requestType')}
            />
            <Dropdown
              name="status"
              emptyValueLabel={cdr.requestStatus}
              items={dropdownStatusItems}
              className={styles.filterDropdown}
              {...SetValueProps(formik, 'status')}
            />
            <Button
              text={cdr.search}
              theme="bright"
              onClick={() => {
                formik.submitForm()
              }}
              className={styles.filterDropdown}
            />
          </div>
          <div className={styles.resetSearchBtn} onClick={resetSearch}>
            <FormattedMessage id={edt.resetLabel} />
          </div>
          <InfiniteScroll
            hasMore={!!searchResults.loadMore}
            loading={searchResults.loadMoreProgress}
            loadMore={() =>
              loadMoreIncomingRequests(searchResults.loadMore as string)
            }
          >
            <IncomingRequests
              sortIncomingRequests={onSortResults}
              requests={searchResults.data}
            />
          </InfiniteScroll>
        </div>
      </div>
    </MainLayout>
  )
}
