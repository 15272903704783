export const modalsAssessmentsTranslation = {
  'modalsassessmentstranslations.psychometricTestTitle':
    'Soglasje za Psihometrično testiranje',
  'modalsassessmentstranslations.cognitiveTestTitle':
    'Soglasje za testiranje kognitivnih sposobnosti',
  'modalsassessmentstranslations.psychometricTestTextFirstPart':
    'Soglašam z obdelavo svojih osebnih podatkov za pripravo mojega poročila psihometričnega testiranja na strani družbe Styria digital marketplaces, d.o.o., ki ga bom prejel/a v uporabniški profil na spletnem portalu',
  'modalsassessmentstranslations.psychometricTestTextSecondPart':
    'z namenom zagotavljanja prilagojenih ponudb novih zaposlitvenih možnosti. Več informacij o obdelavi vaših osebnih podatkov si preberite v',
  'modalsassessmentstranslations.cognitiveTestTextFirstPart':
    'Soglašam z obdelavo svojih osebnih podatkov, posredovanih na podlagi sledečega vprašalnika, z namenom priprave mojega poročila testiranja kognitivnih sposobnosti na spletnem portalu',
  'modalsassessmentstranslations.cognitiveTestTextSecondPart':
    'ki ga bom prejel/a v svoj uporabniški profil. Priprava poročila temelji na avtomatizirani obdelavi posredovanih podatkov, več informacij o obdelavi podatkov si preberite v',
  'modalsassessmentstranslations.privacyPolicy': 'Politiki zasebnosti.',
  'modalsassessmentstranslations.startTest': 'Začnite test',

  'modalsassessmentstranslations.addLanguageTest': 'Dodaj jezikovni test',
  'modalassessments.editLanguageTest': 'Uredi jezikovni test',
  'modalassessments.deleteLanguageTest':
    'Ali ste prepričani, da želite izbrisati ta jezikovni test?',
  'modalsassessmentstranslations.testTakenDateValidation':
    'Datum opravljanja testa je obvezen',
  'modalsassessmentstranslations.testProviderValidation':
    'Datum opravljanja testa je obvezen',
  'modalsassessmentstranslations.testNameValidation': 'Naziv testa je obvezen',
  'modalsassessmentstranslations.skillTestExpirationDate':
    'Datum poteka veljavnosti testa',
  'modalsassessmentstranslations.skillTestName': 'Naziv testa',
  'modalsassessmentstranslations.skillTestProvider': 'Naziv ponudnika testa',
  'modalsassessmentstranslations.skillTestTakenDate': 'Datum testa',
  'modalsassessmentstranslations.languageTestLevel': 'Nivo jezikovnega testa',
  'modalsassessmentstranslations.languageTestTakenDate':
    'Datum opravljanja testa',
  'modalsassessmentstranslations.languageTestProvider': 'Naziv ponudnika testa',
  'modalsassessmentstranslations.languageTestExpirationDate':
    'Datum poteka veljavnosti testa',
  'modalsassessmentstranslations.languageTestName': 'Naziv jezikovnega testa',
  'modalsassessmentstranslations.addSkillTest': 'Dodaj test veščin',
  'modalassessments.editSkillTest': 'Uredi test veščin',
  'modalassessments.deleteSkillTest':
    'Ali ste prepričani, da želite izbrisati ta test veščin?',
}
