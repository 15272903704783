import {EmployerCandidateProfile} from './EmployerCandidateProfile'
import {connect} from 'react-redux'
import {
  getCandidateProfile,
  contactCandidate,
  clearCandidateProfile,
  setCandidateBasicData,
  setSidebarClosed,
  getDrivingLicenseCategories,
} from 'Store/Actions'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {PublicSeekerProfileType, SeekerProfileType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  candidate: state.employer.data.candidate,
  taxonomies: state.taxonomies,
  auth: state.auth,
  candidateBasicData: state.widgetBar.data.candidateBasicData,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getCandidateProfile: (id: string) => dispatch(getCandidateProfile(id)),
  contactCandidate: (id: string) => dispatch(contactCandidate(id)),
  clearCandidateProfile: () => dispatch(clearCandidateProfile()),
  setCandidateBasicData: (
    candidate: SeekerProfileType | PublicSeekerProfileType | null
  ) => dispatch(setCandidateBasicData(candidate)),
  setSidebarClosed: (sidebarClosed: boolean) =>
    dispatch(setSidebarClosed(sidebarClosed)),
  getDrivingLicenseCategories: () => dispatch(getDrivingLicenseCategories()),
})

export const EmployerCandidateProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerCandidateProfile)
