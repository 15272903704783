import {FC, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'

import styles from './SentRequests.module.scss'
import {
  AppRoute,
  EmployerDashboardTranslations as edt,
} from 'Services/I18n/Constants'
import SentRequestListItem from './SentRequestListItem'

import {getRoute} from 'Services/I18n/Utils'
import Hr from 'Components/Hr'
import {EmployerRequestsStateType} from 'Interfaces'
import Loader from 'Components/Fallbacks/Loader'
import {NoLongerActiveCandidateModal} from 'Components/Modal/NoLongerActiveCandidateModal'

type SentRequestsProps = {
  requests: EmployerRequestsStateType
}

export const SentRequests: FC<SentRequestsProps> = ({requests}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  return (
    <div className={styles.wrap}>
      <div className={styles.mainTitleWrap}>
        <span className={styles.mainTitle}>
          <FormattedMessage id={edt.sentRequests} />
        </span>
        <Hr className={styles.hr} />
      </div>
      <div className={styles.contentHeader}>
        <div className={styles.headerTitle}>
          <FormattedMessage id={edt.candidate} />
        </div>
        <div className={styles.headerTitle}>
          <FormattedMessage id={edt.contactRequestRequestType} />
        </div>
        <div className={styles.headerTitle}>
          <FormattedMessage id={edt.status} />
        </div>
        <div className={styles.headerTitle}>
          <FormattedMessage id={edt.expire} />
        </div>
        <div className={styles.leftMargin}>
          <FormattedMessage id={edt.action} />
        </div>
      </div>
      {requests.loading ? (
        <div className={styles.loaderContainer}>
          <Loader />
          <span className={styles.loadingText}>
            <FormattedMessage id={edt.loadingText} />
          </span>
        </div>
      ) : requests.error ? (
        <div>{requests.error.message || 'Error'}</div>
      ) : requests.searchResults.length > 0 ? (
        requests.searchResults.map((request, idx) => (
          <SentRequestListItem
            request={request}
            key={`sent-request-${idx}`}
            setIsModalOpen={setIsModalOpen}
          />
        ))
      ) : (
        <div className={styles.noResults}>
          <FormattedMessage id={edt.noResults} />
        </div>
      )}
      <div className={styles.linkWrap}>
        <Link
          to={getRoute(AppRoute.EmployerContactRequests)}
          className={styles.viewMoreLink}
        >
          <FormattedMessage id={edt.viewAllSentContactRequests} />
        </Link>
        <span className={styles.viewMoreLinkHr} />
        <Link
          to={getRoute(AppRoute.EmployerInterviewInvitations)}
          className={styles.viewMoreLink}
        >
          <FormattedMessage id={edt.viewAllSentVideoRequests} />
        </Link>
        <span className={styles.viewMoreLinkHr} />
        <Link
          to={getRoute(AppRoute.EmployerOffers)}
          className={styles.viewMoreLink}
        >
          <FormattedMessage id={edt.viewAllOffers} />
        </Link>
      </div>
      <NoLongerActiveCandidateModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
      />
    </div>
  )
}
