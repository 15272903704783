import {
  PsychometricCompetenciesType,
  PsychometricResultsStatementsType,
} from 'Interfaces'
import {FC} from 'react'
import ResultItem from '../ResultItem'
import TitleSection from '../TitleSection'
interface ResultGroupSectionProps {
  psychometricResultStatement: PsychometricResultsStatementsType
}

export const ResultGroupSection: FC<ResultGroupSectionProps> = ({
  psychometricResultStatement,
}) => {
  return (
    <div>
      <TitleSection title={psychometricResultStatement.name} />

      {psychometricResultStatement.psychometricCompetencies?.map(
        (psychometricCompentencies: PsychometricCompetenciesType) => {
          return (
            <ResultItem
              psychometricCompentencies={psychometricCompentencies}
              key={psychometricCompentencies.id}
            />
          )
        }
      )}
    </div>
  )
}
