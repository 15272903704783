import {mockHistory} from './../MockedData/MockedWidgetBar'
import {all, call, put, takeEvery, takeLatest} from 'redux-saga/effects'
import API from 'Services/API'
import * as type from 'Store/types'
import {ActionType, HelpfulHintType} from 'Interfaces'

function* getCandidateComments(action: ActionType<string>) {
  const {response, error} = yield call(
    API.get,
    `/user/seeker-comments?seekerUserId=${action.payload}`
  )

  if (response) {
    yield put({
      type: type.widgetBar.comments.get.succeeded,
      payload: response.data,
    })
  } else {
    yield put({type: type.widgetBar.comments.get.failed, payload: error})
  }
}

function* getCandidateHistory() {
  // TODO finish this after this routes and click on candidate card gets avaliable, replace mocked data with real data
  /*const {response, error} = yield call(
    API.get,
    `/user/seeker-comments?seekerId=${action.payload}`
  )*/

  const response = {data: mockHistory}
  const error = {}

  if (response) {
    yield put({
      type: type.widgetBar.history.get.succeeded,
      payload: response.data,
    })
  } else {
    yield put({type: type.widgetBar.history.get.failed, payload: error})
  }
}

function* saveCandidateComment(action: ActionType<string>) {
  const {response, error} = yield call(
    API.post,
    '/user/seeker-comment',
    action.payload
  )

  if (response) {
    yield put({
      type: type.widgetBar.comments.save.succeeded,
      payload: response.data,
    })
  } else {
    yield put({type: type.widgetBar.comments.save.failed, payload: error})
  }
}
function* setHelpfulHintStatus(action: ActionType<HelpfulHintType>) {
  const {response, error} = yield call(API.put, `/user/feature-flag`, {
    name: action?.payload?.name,
    active: action?.payload?.active,
  })
  if (response) {
    yield put({
      type: type.widgetBar.helpfulHintClosed.setStatus.succeeded,
      payload: response.data,
    })
  } else {
    yield put({
      type: type.widgetBar.helpfulHintClosed.setStatus.failed,
      payload: error,
    })
  }
}

function* getHelpfulHintStatus(action: ActionType<string>) {
  const {response, error} = yield call(
    API.get,
    `/user/feature-flag?name=${action.payload}`
  )
  if (response) {
    yield put({
      type: type.widgetBar.helpfulHintClosed.getStatus.succeeded,
      payload: response.data,
    })
  } else {
    yield put({
      type: type.widgetBar.helpfulHintClosed.getStatus.failed,
      payload: error,
    })
  }
}

export default function* WidgetBarSaga(): Generator {
  yield all([
    takeEvery(type.widgetBar.comments.get.requested, getCandidateComments),
    takeLatest(type.widgetBar.comments.save.requested, saveCandidateComment),
    takeLatest(type.widgetBar.history.get.requested, getCandidateHistory),
    takeLatest(
      type.widgetBar.helpfulHintClosed.setStatus.requested,
      setHelpfulHintStatus
    ),
    takeLatest(
      type.widgetBar.helpfulHintClosed.getStatus.requested,
      getHelpfulHintStatus
    ),
  ])
}
