import {connect} from 'react-redux'

import {JobPreferences} from './JobPreferences'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {
  getOccupations,
  getSalaries,
  getRegions,
  getCareerLevels,
  getEmploymentTypes,
  updateJobPreferences,
} from 'Store/Actions'
import {ProfileJobPreferenceFormikType} from 'Interfaces'
import {canChangeProfile} from 'Utils/Profile'

const mapStateToProps = (state: RootState) => ({
  taxonomies: state.taxonomies,
  profile: state.seeker.data?.full,
  seeker: state.seeker,
  canChange: canChangeProfile(state.auth.data?.rjbProfileStatus),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getTaxonomies: () => {
    dispatch(getCareerLevels())
    dispatch(getRegions())
    dispatch(getOccupations())
    dispatch(getEmploymentTypes())
    dispatch(getSalaries())
  },
  updateJobPreferences: (values: ProfileJobPreferenceFormikType) =>
    dispatch(updateJobPreferences(values)),
})

export const JobPreferencesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JobPreferences)
