import {FC, useEffect} from 'react'
import {useHistory, useParams} from 'react-router-dom'

import {SpectoStateType, IFrameEventType} from 'Interfaces'
import MainLayout from 'Layouts/MainLayout'
import styles from './EmployerSeekerSpectoCalendar.module.scss'
import {getChannel} from 'Utils/CommonHelpers'
import {getLang, getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'

interface EmployerSeekerSpectoCalendarProps {
  spectoState: SpectoStateType
  getSpectoToken: () => void
  setSpectoCandidateId: (id: string) => void
}

export const EmployerSeekerSpectoCalendar: FC<
  EmployerSeekerSpectoCalendarProps
> = ({spectoState, getSpectoToken, setSpectoCandidateId}) => {
  let {candidateId} = useParams<{candidateId: string}>()
  const history = useHistory()

  useEffect(() => {
    if (candidateId) {
      setSpectoCandidateId(candidateId)
      getSpectoToken()
    }
  }, [candidateId])

  const handleIframeEvents = ({data}: IFrameEventType) => {
    if (data.type === 'close-candidate-calendar') {
      history.push(getRoute(AppRoute.EmployerDashboard))
    }
  }

  useEffect(() => {
    if (!spectoState.data.token) {
      getSpectoToken()
    }
    window.addEventListener('message', handleIframeEvents)
    return () => {
      window.removeEventListener('message', handleIframeEvents)
    }
  }, [])

  if (
    !spectoState.data.token ||
    !spectoState.data.candidateId ||
    !spectoState.data.candidateSpectoId
  )
    return null

  const channel = getChannel()
  const spectoCalendarUrl = channel?.spectoCalendarUrl
  const lang = getLang()

  return (
    <MainLayout withoutLogo>
      <div className={styles.iframeWrap}>
        <iframe
          src={`${spectoCalendarUrl}/?token=${spectoState.data.token}&candidate=${spectoState.data.candidateSpectoId}&lang=${lang}`}
        />
      </div>
    </MainLayout>
  )
}
