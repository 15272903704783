import Modal from 'Components/Modal'
import {ThumbsFooter} from 'Components/Modal/ThumbsFooter'
import styles from './ModalPlacementRequestEmp.module.scss'
import {DateTime} from 'luxon'

import ModalRejectPlacementConfirmationEmp from './ModalRejectPlacementConfirmationEmp'
import ModalApprovePlacementConfirmationEmp from './ModalApprovePlacementConfirmationEmp'
import {FormattedMessage} from 'react-intl'
import {CandidateDashboardTranslations as cdt} from 'Services/I18n/Constants'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'

import classnames from 'classnames'
import {IncomingRequestType} from 'Interfaces'
import {FC, useState} from 'react'
import {
  Face,
  Notifications,
  Update,
  HourglassBottom,
  WorkOutline,
  DescriptionOutlined,
} from '@mui/icons-material'
import {formatDate} from 'Utils/Date'
import {
  employerRequestStatusTranslationMap,
  PlacementRequestStatus,
} from '../../Constants'

const ExpirationValue: FC<{date: string}> = ({date}) => {
  const now = DateTime.now()
  const expiration = DateTime.fromISO(date)
  const diff = Math.floor(expiration.diff(now, ['days']).days)
  let dayOfTheWeek = expiration.toFormat('cccc')

  if (diff > 7 || expiration < now) {
    return (
      <p className={styles.expirationValueNormal}>
        <FormattedMessage id={dayOfTheWeek} />
        <span className={styles.date}>
          {expiration.toFormat(' (d.L.yyyy)')}
        </span>
      </p>
    )
  }
  if (now.day === expiration.day) {
    return (
      <div className={styles.expirationValueContainer}>
        <p className={styles.expirationValueTextRed}>
          <FormattedMessage id={edt.expiresToday} />
        </p>
      </div>
    )
  }
  return (
    <div className={styles.expirationValueContainer}>
      <p className={styles.expirationValueTextRed}>
        <FormattedMessage id={cdt.in} />
        {` ${diff} `}
        {diff === 1 ? (
          <FormattedMessage id={cdt.day} />
        ) : (
          <FormattedMessage id={cdt.days} />
        )}
      </p>
      <p className={styles.expirationValueText}>
        {expiration.toFormat('(d.L.yyyy)')}
      </p>
    </div>
  )
}

interface ModalPlacementRequestEmpProps {
  request: IncomingRequestType
  isOpenPlacementModal: boolean
  setIsOpenPlacementModal: (open: boolean) => void
  modalDetails?: boolean
  setModalDetails?: (modalDetails: boolean) => void
}

export const ModalPlacementRequestEmp: FC<ModalPlacementRequestEmpProps> = ({
  request,
  isOpenPlacementModal,
  setIsOpenPlacementModal,
}) => {
  const [rejectModal, setRejectModal] = useState(false)
  const [approveModal, setApproveModal] = useState(false)

  const isPending =
    request.status ===
      PlacementRequestStatus.COMPANY_USER_PENDING_SEEKER_CONFIRMED ||
    request.status ===
      PlacementRequestStatus.COMPANY_USER_PENDING_SEEKER_PENDING ||
    request.status ===
      PlacementRequestStatus.COMPANY_USER_CONFIRMED_SEEKER_PENDING

  const isRejectedOrCancelled =
    request.status ===
      PlacementRequestStatus.COMPANY_USER_CONFIRMED_SEEKER_REJECTED ||
    request.status ===
      PlacementRequestStatus.COMPANY_USER_PENDING_SEEKER_REJECTED ||
    request.status ===
      PlacementRequestStatus.COMPANY_USER_REJECTED_SEEKER_REJECTED ||
    request.status ===
      PlacementRequestStatus.COMPANY_USER_REJECTED_SEEKER_PENDING ||
    request.status ===
      PlacementRequestStatus.COMPANY_USER_PENDING_SEEKER_REJECTED ||
    request.status ===
      PlacementRequestStatus.COMPANY_USER_REJECTED_SEEKER_CONFIRMED ||
    request.status === PlacementRequestStatus.CANCELLED

  const isConfirmedByBoth =
    request.status ===
    PlacementRequestStatus.COMPANY_USER_CONFIRMED_SEEKER_CONFIRMED

  const isExpired = request.status === PlacementRequestStatus.EXPIRED

  return (
    <>
      <Modal
        className={styles.modal}
        icon={DescriptionOutlined}
        isOpen={isOpenPlacementModal}
        title={cdt.placementDetails}
        onAfterClose={() => {
          setIsOpenPlacementModal(false)
        }}
        shouldReturnFocusAfterClose={false}
        Footer={
          isPending && (
            <ThumbsFooter
              theme={'thumbs'}
              onApprove={() => {
                setApproveModal(true)
              }}
              approveText={cdt.confirmPlacement}
              rejectText={cdt.rejectPlacement}
              onReject={() => {
                setRejectModal(true)
              }}
              onCancel={() => {
                setIsOpenPlacementModal(false)
              }}
            />
          )
        }
      >
        <div className={styles.containerInterviewDetails}>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <Face className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.candidate} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              <p className={styles.itemRightText}>
                {request.seeker.firstName} {request.seeker.lastName}
              </p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <WorkOutline className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={edt.title} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              <p className={styles.itemRightText}>
                {request.seeker?.publicOccupation &&
                  request.seeker.publicOccupation.translation}
              </p>
            </div>
          </div>

          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <Notifications className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.requestStatus} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              <p
                className={classnames(styles.statusText, {
                  [styles.rejected]: isRejectedOrCancelled,
                  [styles.accepted]: isConfirmedByBoth,
                  [styles.expired]: isExpired,
                  [styles.pending]: isPending,
                })}
              >
                <FormattedMessage
                  id={employerRequestStatusTranslationMap[request.status]}
                />
              </p>
            </div>
          </div>

          {request.sentDate && (
            <div className={styles.item}>
              <div className={styles.itemLeft}>
                <Update className={styles.icon} />
                <p className={styles.itemLeftText}>
                  <FormattedMessage id={cdt.dateOfRequestSent} />:
                </p>
              </div>
              <div className={styles.itemRight}>
                <p className={styles.itemRightText}>
                  {formatDate(request.sentDate)}
                </p>
              </div>
            </div>
          )}
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <HourglassBottom className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.expirationDate} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              {request.expirationDate && (
                <ExpirationValue date={request.expirationDate} />
              )}
            </div>
          </div>
        </div>
      </Modal>

      <ModalApprovePlacementConfirmationEmp
        request={request}
        approveModal={approveModal}
        setApproveModal={setApproveModal}
        setIsOpenPlacementModal={setIsOpenPlacementModal}
      />

      <ModalRejectPlacementConfirmationEmp
        request={request}
        rejectModal={rejectModal}
        setRejectModal={setRejectModal}
        setIsOpenPlacementModal={setIsOpenPlacementModal}
      />
    </>
  )
}
