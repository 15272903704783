import {FC, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {useFormik} from 'formik'

import Modal from 'Components/Modal'
import {ThumbsFooter} from 'Components/Modal/ThumbsFooter'
import {IncomingRequestType, ApproveRejectModalRequestType} from 'Interfaces'
import styles from './ModalInterviewInvitationReject.module.scss'
import ModalInterviewInvitation from 'Components/IncomingRequests/Modals/ModalInterviewInvitation'
import TextArea from 'Components/Inputs/TextArea'
import {OnChangeProps} from 'Utils/FormikProps'
import {CandidateDashboardTranslations as cdt} from 'Services/I18n/Constants'
import {ForumOutlined} from '@mui/icons-material'

interface ModalInterviewInvitationReject {
  request: IncomingRequestType
  rejecModal: boolean
  setRejectModal: (rejecModal: boolean) => void
  setDetails?: (e: boolean) => void
  setIsOpenInterviewInvitation?: (e: boolean) => void
  rejectInterviewInvitation: (payload: ApproveRejectModalRequestType) => void
  showDetails?: boolean
}

export const ModalInterviewInvitationReject: FC<
  ModalInterviewInvitationReject
> = ({
  request,
  rejecModal,
  setRejectModal,
  rejectInterviewInvitation,

  setIsOpenInterviewInvitation,
  showDetails = true,
}) => {
  const [modalDetails, setModalDetails] = useState(false)
  const [
    isOpenInterviewInvitationDetails,
    setIsOpenInterviewInvitationDetails,
  ] = useState(false)

  const formik = useFormik({
    initialValues: {
      message: '',
    },

    onSubmit: () => {},
  })
  return (
    <>
      {!modalDetails ? (
        <Modal
          className={styles.modal}
          icon={ForumOutlined}
          isOpen={rejecModal}
          title={cdt.interviewInvitation}
          theme={'error'}
          onAfterClose={() => {
            setRejectModal(false)
          }}
          Footer={
            <ThumbsFooter
              rejectText={cdt.reject}
              theme={'thumbDown'}
              onReject={() => {
                rejectInterviewInvitation({
                  id: request.id,
                  reason: formik.values.message,
                })
                setRejectModal(false)
                setIsOpenInterviewInvitation &&
                  setIsOpenInterviewInvitation(false)
              }}
              onCancel={() => {
                setRejectModal(false)
              }}
            />
          }
        >
          <div className={styles.containerInterview}>
            <p className={styles.text}>
              <FormattedMessage id={cdt.modalInterviewInvRejectAreYouSure} />
              {request.companyName}?
            </p>
            <p className={styles.description}>
              <FormattedMessage id={cdt.modalInterviewInvRejectYouWillMiss} />
            </p>
            <TextArea
              name="message"
              placeholderText={cdt.modalInterviewInvRejectEnterYourReason}
              {...OnChangeProps(formik, 'message')}
              theme="white"
              className={styles.textArea}
            />
            {showDetails && (
              <button
                onClick={() => {
                  setRejectModal(false)
                  setModalDetails(true)
                  setIsOpenInterviewInvitationDetails(true)
                  setIsOpenInterviewInvitation &&
                    setIsOpenInterviewInvitation(false)
                }}
                className={styles.button}
              >
                <FormattedMessage id={cdt.viewDetails} />
              </button>
            )}
          </div>
        </Modal>
      ) : (
        <ModalInterviewInvitation
          request={request}
          isOpenInterviewInvitation={isOpenInterviewInvitationDetails}
          setIsOpenInterviewInvitation={setIsOpenInterviewInvitationDetails}
          setModalDetails={setModalDetails}
          modalDetails={modalDetails}
        />
      )}
    </>
  )
}
