export const candidateDashboard = {
  'candidateDashboard.candidate': 'Kandidat',
  'candidateDashboard.dashboard': 'Namizje',
  'candidateDashboard.title': 'Moje namizje',
  'candidateDashboard.paragraphTitle':
    'Odlično, dokončali ste postopek prijave!',
  'candidateDashboard.paragraphTitleTxt':
    'Trenutno nimate nobenih dohodnih zahtevkov. Ko vam delodajalec pošlje zahtevek, bo prikazan tukaj. Med tem pa lahko:',

  'candidateDashboard.cvProfileLinkPart1': ' - Preverite svoj ',
  'candidateDashboard.cvProfileLinkPart2': 'CV profil.',
  'candidateDashboard.cvProfileLinkPart3':
    ' Uvozili smo podatke iz vašega CV-ja in predlagamo, da jih še enkrat pregledate in preverite, ali je vse kot mora biti. ',

  'candidateDashboard.publicProfileLinkPart1': '- Uredite svoj ',
  'candidateDashboard.publicProfileLinkPart2': 'karierni profil.',
  'candidateDashboard.publicProfileLinkPart3':
    ' Delodajalci bodo lahko videli vaš karierni profil šele, ko boste sprejeli njihov zahtevek za kontakt.',

  'candidateDashboard.psychometricTestLinkPart1': '- Opravite ',
  'candidateDashboard.psychometricTestLinkPart2': 'psihometrični',
  'candidateDashboard.psychometricTestLinkPart3': ' in ',
  'candidateDashboard.cognitiveTestLinkPart1': 'kognitivni',
  'candidateDashboard.cognitiveTestLinkPart2': ' test.',

  'candidateDashboard.paragraphLast1':
    '- Če še niste, potrdite svoj profil, da ga lahko pregledajo naši kadrovski strokovnjaki. To lahko storite na dnu ',
  'candidateDashboard.paragraphLast2': ' te',
  'candidateDashboard.paragraphLast3': ' strani.',

  'candidateDashboard.headerTitle': 'Pozdravljeni,',
  'candidateDashboard.headerTitleTxt': ' !',
  'candidateDashboard.headerCv': 'Vaš karierni profil je izpolnjen',

  'candidateDashboard.prequalification': 'Predkvalifikacije',
  'candidateDashboard.scouting': 'Selekcija',
  'candidateDashboard.interviewing': 'Razgovori',
  'candidateDashboard.negotiations': 'Pogajanja',
  'candidateDashboard.placementDetails': 'Podrobnosti sodelovanja',
  'candidateDashboard.placement': 'Sodelovanja',
  'candidateDashboard.placementSecond': 'Sodelovanje',
  'candidateDashboard.requestStatus': 'Status zahtevka',
  'candidateDashboard.requestType': 'Vrsta zahtevka',
  'candidateDashboard.dateOfRequestSent': 'Datum poslanega zahtevka',
  'candidateDashboard.placementApproveAreYouSure':
    'Ste prepričani, da želite potrditi sodelovanje s podjetjem ',
  'candidateDashboard.placementApproveConfirmingThePlacement':
    'Če potrdite sodelovanje, vaš profil eno leto ne bo viden drugim delodajalcem.',
  'candidateDashboard.confirmPlacement': 'Potrdite sodelovanje',
  'candidateDashboard.placementRejectAreYouSure':
    'Ste prepričani, da želite zavrniti sodelovanje s podjetjem',
  'candidateDashboard.placementRejectYouWillMiss':
    'Zamudili boste priložnost za sodelovanje s tem podjetjem.',
  'candidateDashboard.rejectPlacement': 'Zavrnite sodelovanje',
  'candidateDashboard.reject': 'Zavrnite',
  'candidateDashboard.confirm': 'Potrdite',
  'candidateDashboard.expirationDate': 'Datum poteka veljavnosti',
  'candidateDashboard.placementWeAreHappyPart1':
    'Veseli smo, da ste sprejeli ponudbo podjetja ',
  'candidateDashboard.placementWeAreHappyPart2': '. Želimo vam veliko uspeha. ',
  'candidateDashboard.placementWeAreHappyPart3':
    'Za uspešen zaključek procesa prosimo potrdite sodelovanje.',
  'candidateDashboard.myIncomingRequests': 'Moji dohodni zahtevki',
  'candidateDashboard.viewAllRequests': 'Poglejte vse zahtevke',
  'candidateDashboard.minifeed': 'Nedavni dogodki',
  'candidateDashboard.thereAreStillNoItems': 'Nimate nedavnih dogodkov',
  'candidateDashboard.loading': 'Nalaganje ...',
  'candidateDashboard.action': 'AKCIJA',
  'candidateDashboard.viewDetails': 'Poglejte podrobnosti',
  'candidateDashboard.hasRejectedYourContactR':
    ' je zavrnil/a vaš zahtevek za stik.',
  'candidateDashboard.hasAcceptedYourInterviewI':
    ' je sprejel/a vaše povabilo na intervju.',
  'candidateDashboard.hasRejectedYourInvitationToV':
    ' je zavrnil/a vaše povabilo na intervju.',
  'candidateDashboard.company': 'Podjetje',
  'candidateDashboard.theCompany': 'Podjetje ',
  'candidateDashboard.hasViewedYourR': ' si je ogledalo vaš življenjepis.',
  'candidateDashboard.viewCompany': 'Prikažite podjetje',
  'candidateDashboard.view': 'Prikažite',
  'candidateDashboard.status': 'STATUS',
  'candidateDashboard.expire': 'POTEČE',
  'candidateDashboard.pending': 'Na čakanju',
  'candidateDashboard.accepted': 'Sprejeto',
  'candidateDashboard.rejected': 'Zavrnjeno',
  'candidateDashboard.approved': 'Sprejeto',
  'candidateDashboard.expired': 'Poteklo',
  'candidateDashboard.newTimeProposed': 'Nov predlagan termin',
  'candidateDashboard.newTimeProposal': 'Nov predlagan termin',
  'candidateDashboard.placementConfirmed': 'Sodelovanje potrjeno',
  'candidateDashboard.placementRejected': 'Zaposlitev zavrnjena',
  'candidateDashboard.contactRequest': 'Prošnja za stik',
  'candidateDashboard.offer': 'Ponudba',
  'candidateDashboard.interviewInvitation': 'Vabilo na intervju',
  'candidateDashboard.interviewInvitationDetails':
    'Podrobnosti vabila na intervju',
  'candidateDashboard.placementConfirmation': 'Potrditev sodelovanja',
  'candidateDashboard.approve': 'Sprejmite',
  'candidateDashboard.accept': 'Sprejmite',
  'candidateDashboard.proposeNewTime': 'Predlagajte nov čas',
  'candidateDashboard.day': 'dan',
  'candidateDashboard.days': 'dni',
  'candidateDashboard.successfullyPlaced': 'Uspešno zaposlen',
  'candidateDashboard.offerDetails': 'Podrobnosti ponudbe',
  'candidateDashboard.content': 'Vsebina',
  'candidateDashboard.dear': 'Dragi',
  'candidateDashboard.thankYou': 'Hvala',
  'candidateDashboard.attachment': 'Priponka',
  'candidateDashboard.modalOfferWeSendYouOurP':
    'Na vaš elektronski naslov smo poslali ponudbo. Naprošamo vas, da jo preverite in se odločite, ali jo boste sprejeli ali zavrnili.',
  'candidateDashboard.modalApproveOfferAreYouSure':
    'Ste prepričani, da želite sprejeti ponudbo podjetja',
  'candidateDashboard.modalApproveOfferAcceptingTheO':
    'Sprejem ponudbe pomeni, da se strinjate s ponujenimi delovnimi pogoji. Podjetju s tem dajete pobudo za pripravo pogodbe o sodelovanju.',
  'candidateDashboard.acceptOfferRequest': 'Sprejmite ponudbo',
  'candidateDashboard.rejectOfferrequest': 'Zavrnite ponudbo',
  'candidateDashboard.modalRejectAreYouSure':
    'Ste prepričani, da želite zavrniti ponudbo podjetja',
  'candidateDashboard.modalRejectOfferYouWillMiss':
    'Zamudili boste priložnost, da stopite v stik s tem podjetjem',
  'candidateDashboard.modalPrposeNewTimePleaseEnterMessage':
    'Vnesite sporočilo za delodajalca',
  'candidateDashboard.modalPrposeNewTimEnterMessage':
    'Vnesite sporočilo s predlogom za nov termin.',
  'candidateDashboard.message': 'Sporočilo',
  'candidateDashboard.modalContactAprroveAreYouSure':
    'Ste prepričani, da želite sprejeti zahtevek za stik podjetja',
  'candidateDashboard.modalApproveContactWhitThis':
    'S sprejetjem soglašate, da si bo delodajalec lahko ogledal vaš življenjepis, vključno z rezultati psihometričnih, kognitivnih ter drugih testiranj.',
  'candidateDashboard.modalRejectContactAreYouSure':
    'Ste prepričani, da želite zavrniti zahtevek za stik podjetja',
  'candidateDashboard.modalRejectContactYouWillMiss':
    'Zamudili boste priložnost, da stopite v stik s tem podjetjem.',
  'candidateDashboard.modalApproveContactAreYouSure':
    'Ste prepričani, da želite sprejeti prošnjo za stik podjetja ',
  'candidateDashboard.approveContactRequest': 'Sprejmite prošnjo za stik',
  'candidateDashboard.rejectContactRequest': 'Zavrnite zahtevek za stik',
  'candidateDashboard.time': 'čas',
  'candidateDashboard.InterviewDate': 'Datum intervjuja',
  'candidateDashboard.modalInterviewInvRejectAreYouSure':
    'Ste prepričani, da želite zavrniti povabilo na intervju podjetja ',
  'candidateDashboard.modalInterviewInvRejectYouWillMiss':
    'Zamudili boste priložnost, da stopite v stik s tem podjetjem.',
  'candidateDashboard.completenessProfilePicture': 'Profilna slika',
  'candidateDashboard.completenessFirstName': 'Ime',
  'candidateDashboard.completenessLastName': 'Priimek',
  'candidateDashboard.completenessDateOfBirth': 'Datum rojstva',
  'candidateDashboard.completenessAboutMe': 'O meni (opis)',
  'candidateDashboard.completenessStreetAddress': 'Naslov',
  'candidateDashboard.completenessPostalCode': 'Poštna številka',
  'candidateDashboard.completenessCity': 'Mesto',
  'candidateDashboard.completenessCountry': 'Država',
  'candidateDashboard.completenessWorkExperiences': 'Delovne izkušnje',
  'candidateDashboard.completenessSkills': 'Znanja in spretnosti',
  'candidateDashboard.completenessEducation': 'Izobrazba',
  'candidateDashboard.completenessPsychometricTest': 'Psihometrični test',
  'candidateDashboard.completenessCognitiveTest':
    'Test kognitivnih sposobnosti',
  'candidateDashboard.completenessDrivingLicence': 'Vozniško dovoljenje',
  'candidateDashboard.completenessJobPrefs': 'Delovne preference',
  'candidateDashboard.completeness': 'Izpolnitev profila:',
  'candidateDashboard.modalInterviewInvRejectEnterYourReason':
    'Vnesite razlog zavrnitve. Primer: Žal nisem zainteresiran ... (Opcijsko)',
  'candidateDashboard.completed': 'Zaključeno',
  'candidateDashboard.cancelled': 'Preklicano',
  'candidateDashboard.notAttended': 'Brez udeležbe',
  'candidateDashboard.extended': 'Podaljšano',
  'candidateDashboard.available': 'Na voljo',
  'candidateDashboard.unavailable': 'Ni na voljo',
  'candidateDashboard.interviewConfirmed': 'Sprejeto',
  'candidateDashboard.pendingExpiring': 'Čaka na iztek',
  'candidateDashboard.newOfferProposed': 'Predlagana nova ponudba',
  'candidateDashboard.waitingForBoth': 'Čaka na oba',
  'candidateDashboard.rejectedByYou': 'Zavrnjeno z vaše strani',
  'candidateDashboard.waitingForEmployer': 'Čaka na delodajalca',
  'candidateDashboard.waitingForYou': 'Čaka na vas',
  'candidateDashboard.waitingForCandidate': 'Čaka na kandidata',
  'candidateDashboard.confirmedByBothSides': 'Potrjeno z obeh strani',
  'candidateDashboard.rejectedByEmployer': 'Zavrnjeno s strani delodajalca',
  'candidateDashboard.rejectedByBoth': 'Zavrnjeno z obeh strani',
  'candidateDashboard.rejectedByCandidate': 'Zavrnjeno s strani kandidata',
  'candidateDashboard.placementRequest': 'Zahtevek za sodelovanje',
  'candidateDashboard.offerProposal': 'Predlog ponudbe',
  'candidateDashboard.in': 'Čez',
  'candidateDashboard.allRequests': 'Vsi zahtevki',
  'candidateDashboard.search': 'Iskanje',
  'candidateDashboard.searchCompany': 'Iskanje podjetij...',
  'candidateDashboard.startVideoInterview': 'Prični video intervju',
  'candidateDashboard.modalContactRequestTitle': 'Podrobnosti prošnje za stik',
  'candidateDashboard.submit': 'Shrani',
  'candidateDashboard.rejectionDate': 'Datum zavrnitve',
  'candidateDashboard.expiresToday': 'Danes poteče',
  'candidateDashboard.cancelConfirmation1':
    'Ali ste prepričani, da želite preklicati video intervju s podjetjem ',
  'candidateDashboard.cancelConfirmation2':
    'Zamudili boste priložnost, da stopite v stik s tem podjetjem.',
}
