import * as Yup from 'yup'

const Validation = Yup.object().shape({
  trainingTitle: Yup.object().shape({
    id: Yup.string().required('Test name is required'),
  }),
  provider: Yup.string().required('Test provider is required'),
  dateOfTest: Yup.string()
    .required('Test taken date is required')
    .test({
      name: 'dateOfTest',
      message: 'Skill test date can not be set in the future',
      test: (dateOfTest) => new Date(dateOfTest as string) < new Date(),
    }),
  expirationDate: Yup.string()
    .nullable()
    .test({
      name: 'expirationDate',
      message: 'Expiration date can not be before date of test',
      test: (expirationDate, context) => {
        if (expirationDate) {
          return new Date(expirationDate) > new Date(context.parent.dateOfTest)
        } else return true
      },
    }),
})

export const EditValidation = Yup.object().shape({
  provider: Yup.string().required('Test provider is required'),
  dateOfTest: Yup.string()
    .required('Test taken date is required')
    .test({
      name: 'dateOfTest',
      message: 'Skill test date can not be set in the future',
      test: (dateOfTest) => new Date(dateOfTest as string) < new Date(),
    }),
  expirationDate: Yup.string()
    .nullable()
    .test({
      name: 'expirationDate',
      message: 'Expiration date can not be before date of test',
      test: (expirationDate, context) => {
        if (expirationDate) {
          return new Date(expirationDate) > new Date(context.parent.dateOfTest)
        } else return true
      },
    }),
})

export default Validation
