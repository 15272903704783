import {connect} from 'react-redux'

import {CompanyContactInfo} from './CompanyContactInfo'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {getCountries, saveStepCompanyContactInfo} from 'Store/Actions'
import {CompanyContactInfoSaveType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  taxonomies: state.taxonomies,
  employerOnboarding: state.employerOnboarding,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getTaxonomies: () => {
    dispatch(getCountries())
  },
  saveStepCompanyContactInfo: (saveValues: CompanyContactInfoSaveType) =>
    dispatch(saveStepCompanyContactInfo(saveValues)),
})

export const CompanyContactInfoContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyContactInfo)
