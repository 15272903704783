import {employerDashboard as engTranslations} from './en'

export const employerDashboard: typeof engTranslations = {
  'employerDashboard.proposedDate': 'Proposed date ',
  'employerDashboard.newProposedDate': 'New Proposed date',
  'employerDashboard.time': 'čas',
  'employerDashboard.message': 'Sporočilo:',
  'employerDashboard.interviewInvDetmessage': `Na žalost se v predlaganem datumu video intervjuja ne morem udeležiti. Predlagal/a sem nov datum.`,
  'employerDashboard.videoInterview': `Video intervju`,
  'employerDashboard.sendOffer': `Pošlji ponudbo`,
  'employerDashboard.proposeNewTime': 'Predlagajte nov čas',
  'employerDashboard.waitingForCandidate': `Čaka na kandidata`,
  'employerDashboard.title': `Naziv`,
  'employerDashboard.areYouSureApprovePlacement': `Ste prepričani, da želite potrditi sodelovanje s tem kandidatom? `,
  'employerDashboard.whitThisActionApprovePlacement':
    'S to akcijo potrjujete, da je bila pogodba o sodelovanju podpisana. ',
  'employerDashboard.placementRejectAreYouSure': `Ste prepričani, da želite zavrniti sodelovanje s tem kandidatom? `,
  'employerDashboard.allPlacements': 'Vsa sodelovanja',
  'employerDashboard.pendingPlacements': 'Sodelovanja na čakanju',
  'employerDashboard.confirmedPlacements': 'Potrjena sodelovanja',
  'employerDashboard.position': `Pozicija:`,
  'employerDashboard.invite': 'Povabi',
  'employerDashboard.contactRequestDetails': `Podrobnosti zahtevka za kontakt`,
  'employerDashboard.allRequests': 'Vse prošnje za stik',
  'employerDashboard.allRequestsTitle': 'Vse prošnje za stik',
  'employerDashboard.pendingRequestsTitle': 'Prošnje na čakanju',
  'employerDashboard.approvedRequestsTitle': 'Sprejete prošnje za stik',
  'employerDashboard.pendingRequests': 'Prošnje na čakanju',
  'employerDashboard.approvedRequests': 'Sprejete prošnje za stik',
  'employerDashboard.all': 'Vsa',
  'employerDashboard.pending': 'Na čakanju',
  'employerDashboard.pendingSmall': 'na čakanju',
  'employerDashboard.approved': 'Sprejeto',
  'employerDashboard.accepted': 'Sprejeto',
  'employerDashboard.allinterviewInvitationsTitle': 'Vsa vabila',
  'employerDashboard.pendinginterviewInvitationsTitle': 'Vabila na čakanju',
  'employerDashboard.acceptedinterviewInvitationsTitle': 'Sprejeta vabila',
  'employerDashboard.completedinterviewInvitationsTitle':
    'Zaključeni intervjuji',
  'employerDashboard.acceptedInterviews': 'Sprejeta vabila',
  'employerDashboard.completed': 'Zaključeno',
  'employerDashboard.completedInterviews': ' Zaključeni intervjuji',
  'employerDashboard.confirmed': 'Potrjena',
  'employerDashboard.action': 'AKCIJA',
  'employerDashboard.yourConfirmation': 'VAŠA POTRDITEV',
  'employerDashboard.candidatesConfirmation': 'KANDIDATOVA POTRDITEV',
  'employerDashboard.expire': 'POTEČE',
  'employerDashboard.candidate': 'KANDIDATI',
  'employerDashboard.status': 'STATUS',
  'employerDashboard.noResults': 'Ni rezultatov.',
  'employerDashboard.loadingText': 'Samo trenutek, iskanje podatkov...',
  'employerDashboard.searchCandidatesLabel':
    'Iskanje kandidatov (Ime, Priimek, Delovno mesto)',
  'employerDashboard.statusLabel': 'Status',
  'employerDashboard.search': 'Iskanje',
  'employerDashboard.resetLabel': 'Ponastavi iskanje',
  'employerDashboard.confirmedByYou': 'Potrjeno z vaše strani',
  'employerDashboard.rejectedByYou': 'Zavrnjeno z vaše strani',
  'employerDashboard.waitingForYourAction': 'Čaka na vaš odziv',
  'employerDashboard.confirmedByCandidate': 'Potrjeno s strani kandidata',
  'employerDashboard.waitingOnCandidatesAction': 'Čaka na kandidatov odziv',
  'employerDashboard.rejectedByCandidate': 'Zavrnjeno s strani kandidata',
  'employerDashboard.confirmPlacement': 'Potrdi sodelovanje',
  'employerDashboard.placementConfirmed': 'Sodelovanje potrjeno',
  'employerDashboard.placementPending': 'Sodelovanje na čakanju',
  'employerDashboard.placementRejected': 'Sodelovanje zavrnjeno',
  'employerDashboard.sendMessage': 'Pošljite sporočilo',
  'employerDashboard.viewDetails': 'Poglejte podrobnosti',
  'employerDashboard.cancelInterview': 'Prekliči video intervju',
  'employerDashboard.cancelConfirmation1':
    'Ste prepričani, da želite preklicati video intervju z osebo ',
  'employerDashboard.cancelConfirmation2':
    'Zamudili boste priložnost za stik s tem kandidatom.',
  'employerDashboard.cancelReason':
    'Vnesite razlog preklica. Primer: Nisem zainteresiran... (opcijsko)',
  'employerDashboard.sendNewOffer': 'Pošlji novo ponudbo',
  'employerDashboard.placementExpired': 'Sodelovanje je poteklo',
  'employerDashboard.startsIn': 'PRIČETEK',
  'employerDashboard.proposedTime': 'PREDLAGAN DATUM',
  'employerDashboard.performed': 'NASTOPIL',
  'employerDashboard.rating': 'OCENA',
  'employerDashboard.offerFrom': `Offer from`,
  'employerDashboard.candidatesName': `Candidate's name`,
  'employerDashboard.offerDescription': `Opis ponudbe`,
  'employerDashboard.sendMessageTo': 'Pošljite sporočilo na',
  'employerDashboard.body': 'Vsebina',
  'employerDashboard.headerTitle': 'Pozdravljeni',
  'employerDashboard.headerTitleTxt': '!',
  // Contact Requests:
  'employerDashboard.contactRequest': 'Prošnja za stik',
  'employerDashboard.contactRequestPending': 'Prošnja za stik je na čakanju',
  'employerDashboard.contactRequestRejected': 'Prošnja za stik je zavrnjena',
  'employerDashboard.contactRequestExpired': 'Prošnja za stik je potekla',
  'employerDashboard.contactRequestRequestType': 'VRSTA ZAHTEVKA',
  'employerDashboard.contactRequestInvitedToInterview':
    'Povabljen na video intervju',
  'employerDashboard.offerAccepted': 'PONUDBA SPREJETA',
  'employerDashboard.offerExtended': 'Ponudba podaljšana',
  'employerDashboard.offerPending': 'Ponudba na čakanju',
  'employerDashboard.offerSent': 'PONUDBA POSLANA',
  'empoyerDahboard.pendingOffers': 'Ponudbe na čakanju',
  'empoyerDahboard.acceptedOffers': 'Sprejete ponudbe',
  'empoyerDahboard.allOffers': 'Vse ponudbe',
  'employerDashboard.allOffersTitle': 'Vse ponudbe',
  'employerDashboard.pendingOffersTitle': 'Ponudbe na čakanju',
  'employerDashboard.acceptedOffersTitle': 'Sprejete ponudbe',
  'empoyerDahboard.employerOffers': 'Employer offers',
  'empoyerDahboard.offerCancelled': 'Ponudba je preklicana',
  'empoyerDahboard.offers': 'Ponudbe',
  'empoyerDahboard.rejected': 'Zavrnjena',
  'empoyerDahboard.expired': 'Potekla',
  'empoyerDahboard.offerExpired': 'Ponudba je potekla',
  'empoyerDahboard.offerRejected': 'Ponudba zavrnjena',
  'employerDashboard.placementConfirmation': 'Potrditev sodelovanja',
  'employerDashboard.dashboard': 'Namizje',
  'employerDashboard.contactRequests': 'Prošnje za stik',
  'employerDashboard.interviewInvitation': 'Vabila na intervjuje',
  'employerDashboard.interviewInvitations': 'Vabila na intervjuje',
  'employerDashboard.videoInterviewInvitation': 'Vabilo na video intervju', // Todo: Check for correct translations
  'employerDashboard.placements': 'sodelovanja', // Todo: Check for correct translations
  'employerDashboard.placementsTitle': 'Sodelovanja',
  'employerDashboard.requests': 'Zahtevki', // Todo: Check for correct translations
  'employerDashboard.interviews': 'Intervjuji', // Todo: Check for correct translations
  'employerDashboard.allInterviews': 'Vsa vabila',
  'employerDashboard.pendingInterviews': 'Vabila na čakanju',
  'employerDashboard.invitations': 'Vabilo',
  'employerDashboard.viewAllSentContactRequests':
    'Poglej vse poslane prošnje za stik',
  'employerDashboard.viewAllSentVideoRequests':
    'Poglej vsa poslana vabila na video intervju',
  'employerDashboard.viewAllOffers': 'Poglej vse poslane ponudbe',
  'employerDashboard.proposeButton': 'Predlagajte nov čas',
  'employerDashboard.sendButton': 'Pošlji ponudbo',
  'employerDashboard.startVideoInterview': 'Prični video intervju',
  'employerDashboard.offerSend': 'Ponudba poslana',
  'employerDashboard.searchCandidates': 'Iskanje kandidatov',
  'employerDashboard.manageCompanyProfile': 'Uredi profil podjetja',
  'employerDashboard.offer': 'Ponudba',
  'employerDashboard.acceptedOffer': 'Ponudba sprejeta',
  'employerDashboard.sentRequests': 'Poslani zahtevki',
  'employerDashboard.expiresToday': 'Danes poteče',
  'employerDashboard.candidateNoLongerActive':
    'Kandidat ni več aktiven na platformi Preferenca.si.',
  'employerDashboard.noLongerActiveCandidateModalPart1':
    'Kandidat ni več aktiven na platformi Preferenca.si zaradi enega od spodnjih razlogov:',
  'employerDashboard.noLongerActiveCandidateModalPart2':
    'Kandidat se je uspešno zaposlil pri drugem delodajalcu',
  'employerDashboard.noLongerActiveCandidateModalPart3':
    'Kandidat ni več aktiven iskalec zaposlitve',
  'employerDashboard.noLongerActiveCandidateModalPart4':
    'Kandidat ne ustreza več našim zahtevam',
  'employerDashboard.noLongerActiveCandidateTitle': 'Kandidat ni več aktiven',
  'employerDashboard.noLongerActiveCandidateSubmitText': 'Razumem',
  'employerDashboard.in': 'Čez',
  'employerDashboard.minute': 'minuto',
  'employerDashboard.twoMinutes': 'minuti',
  'employerDashboard.threeAndFourMinutes': 'minute',
  'employerDashboard.fiveAndMoreMinutes': 'minut',
  'employerDashboard.hour': 'uro',
  'employerDashboard.twoHours': 'uri',
  'employerDashboard.threeAndFourHours': 'ure',
  'employerDashboard.fiveAndMoreHours': 'ur',
  'employerDashboard.day': 'dan',
  'employerDashboard.days': 'dni',
  'employerDashboard.second': 'sekundo',
  'employerDashboard.seconds': 'sekund',
}
