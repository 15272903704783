export const privacyPolicy = {
  'privacyPolicy.privacyPolicyHtml': `<div class="privacyPolicyContainerDown">
  <p class="privacyPolicyContainerDownText">
    Družba Styria digital marketplaces, d.o.o., Verovškova ulica 55, Ljubljana,
    kot upravljavec spletnih mest na domenah www.mojedelo.com,
    www.preferenca.si, kariernisejem.com, in plača.si ter vseh njihovih
    poddomenah (skupno »Spletna mesta«), veliko pozornosti posveča zaščiti vaših
    osebnih podatkov. Z vašimi podatki ravnamo z največjo možno skrbnostjo in
    varnostjo ter skladno z veljavnimi predpisi v Republiki Sloveniji s področja
    varstva osebnih podatkov, predvsem pa skladno z Zakonom o varstvu osebnih
    podatkov (Ur. l. RS, št. 94/07 s spremembami in Ur. l. RS, št. 163/22) in
    Splošno uredbo EU o varstvu podatkov (Uredba (EU) 2016/679 Evropskega
    parlamenta in Sveta z dne 27. aprila 2016 o varstvu posameznikov pri
    obdelavi osebnih podatkov in o prostem pretoku takih podatkov ter o
    razveljavitvi Direktive 95/46/ES). Obdelavo podatkov izvajajo le za to
    pooblaščene osebe in naši skrbno izbrani pogodbeni obdelovalci. Politika
    zasebnosti in varovanja osebnih podatkov ureja zbiranje in obdelavo osebnih
    podatkov, ki jih pridobiva družba Sytria digital marketplaces, d.o.o. ob
    vašem dostopanju do vseh Spletnih mest oziroma pri vaši uporabi storitev
    Spletnih mest. Obdelava osebnih podatkov vselej sloni na eni izmed pravnih
    podlag iz 6. člena Splošne uredbe o varstvu podatkov.
  </p>
  <h2 class="privacyPolicyContainerDownTitle">
    V okviru te Politike zasebnosti in varovanja osebnih podatkov vas seznanjamo
    s sledečimi informacijami:
  </h2>
  <ol class="privacyPolicyOl">
    <li class="privacyPolicyContainerDownText">
      <a class="privacyPolicyAnchor" href="#section1">
        Pooblaščena oseba za varstvo podatkov
      </a>
    </li>
    <li class="privacyPolicyContainerDownText">
      <a class="privacyPolicyAnchor" href="#section2">
        Pridobitev in nameni obdelave osebnih podatkov
      </a>
      <ol>
        <li class="privacyPolicyContainerDownText">
          <a class="privacyPolicyAnchor" href="#section2.1">
            Starostna omejitev storitev informacijske družbe
          </a>
        </li>
      </ol>
    </li>
    <li class="privacyPolicyContainerDownText">
      <a class="privacyPolicyAnchor" href="#section3">
        Posredovanje osebnih podatkov tretjim osebam
      </a>
      <ol>
        <li class="privacyPolicyContainerDownText">
          <a class="privacyPolicyAnchor" href="#section3.1">
            Posredovanje osebnih podatkov v tretje države
          </a>
        </li>
      </ol>
    </li>
    <li class="privacyPolicyContainerDownText">
      <a class="privacyPolicyAnchor" href="#section4"> Družbena omrežja </a>
    </li>
    <li class="privacyPolicyContainerDownText">
      <a class="privacyPolicyAnchor" href="#section5"> Piškotki </a>
    </li>
    <li class="privacyPolicyContainerDownText">
      <a class="privacyPolicyAnchor" href="#section6"> Varnost </a>
    </li>
    <li class="privacyPolicyContainerDownText">
      <a class="privacyPolicyAnchor" href="#section7"> Rok hrambe </a>
    </li>
    <li class="privacyPolicyContainerDownText">
      <a class="privacyPolicyAnchor" href="#section8">
        Profiliranje in avtomatizirana obdelava podatkov
      </a>
    </li>
    <li class="privacyPolicyContainerDownText">
      <a class="privacyPolicyAnchor" href="#section9"> Pravice uporabnikov </a>
    </li>
    <li class="privacyPolicyContainerDownText">
      <a class="privacyPolicyAnchor" href="#section10">
        Povezave na druge spletne strani in uporaba tretjih storitev
      </a>
    </li>

    <li class="privacyPolicyContainerDownText">
      <a class="privacyPolicyAnchor" href="#section11">
        Veljavnost spremembe
      </a>
    </li>
  </ol>
  <h2 id="section1" class="privacyPolicyContainerDownTitle">
    1. Pooblaščena oseba za varstvo podatkov
  </h2>
  <p class="privacyPolicyContainerDownText">
    Če imate kakršna koli vprašanja glede naše Politike zasebnosti in varovanja
    osebnih podatkov ali obdelave vaših osebnih podatkov, se lahko kadarkoli
    obrnete na našo pooblaščeno osebo za varstvo podatkov, in sicer prek
    elektronskega naslova dpo_styria-slo@styria.com.
  </p>
  <h2 id="section2" class="privacyPolicyContainerDownTitle">
    2. Pridobitev in namen obdelave osebnih podatkov
  </h2>
  <p class="privacyPolicyContainerDownText">
    Osebni podatki so vsi podatki, ki posameznika določajo ali ga delajo
    določljivega, kot na primer ime in priimek, naslov, elektronski naslov itd..
    Varstvo osebnih podatkov uživajo posamezniki, zato se ta Politika zasebnosti
    in varovanja osebnih podatkov ne nanaša na obdelavo osebnih podatkov
    fizičnih oseb, ki na trgu opravljajo dejavnost, in na podatke pravnih oseb.
    <br />
    <br />
    Vaše osebne podatke pridobivamo in naprej obdelujemo z namenom zagotavljanja
    storitev na Spletnih mestih, izboljšanja vaše uporabniške izkušnje kot tudi
    naših storitev ter z namenom komuniciranja in informiranja z uporabo
    različnih komunikacijskih sredstev (elektronske pošte, telefonske ali GSM
    številke) ter izpolnjevanja zakonskih obveznosti.
  </p>
  <hr class="privacyPolicyHr" />
  <p class="privacyPolicyContainerDownText">
    I. Podatke pridobimo ob vaši registraciji na katerem koli izmed Spletnih
    mest. Ob registraciji sprejmete Splošne pogoje uporabe, s čimer pridobite
    položaj registriranega uporabnika. Osebni podatki, potrebni za registracijo
    (za osnovno identifikacijo uporabnika in kreiranje vašega Uporabniškega
    računa) so:
  </p>
  <ol class="privacyPolicyOlParagraph">
    <li>ime in priimek</li>
    <li>kontaktni podatki (elektronski naslov)</li>
  </ol>
  <p class="privacyPolicyContainerDownText">
    Zaradi narave storitev spletnega mesta MojeDelo.com in Preferenca.si ter z
    namenom kreiranja Uporabniškega profila na navedenih spletnih mestih boste v
    nadaljnjih korakih uporabe MojeDelo.com in Preferenca.si pozvani k vnosu
    dodatnih podatkov (za kreiranje vašega življenjepisa), kot so:
  </p>
  <ol class="privacyPolicyOlLine">
    <li>
      osnovni podatki (stopnja izobrazbe, datum rojstva, naziv delovnega mesta),
    </li>

    <li>
      kontaktni podatki (telefonska številka, naslov prebivališča, mesto dela,
      drugi naslovi),
    </li>
    <li>delovne izkušnje,</li>
    <li>
      potrdila o delovnih izkušnjah in pridobljenih
      znanjih/licencah/certifikatih, priporočila, dokazila o uspešnosti in druga
      potrdila,
    </li>
    <li>
      znanja in spretnosti (znanje jezikov, vozniško dovoljenje, tehnična znanja
      in druge kompetence),
    </li>
    <li>podatki o vaši trenutni zaposlitvi,</li>
    <li>vaše delovne preference,</li>
    <li>
      ostali podatki, ki se prosto vnašajo v življenjepis (slika, državljanstvo,
      poklic in drugo).
    </li>
  </ol>
  <p class="privacyPolicyContainerDownText">
    Ti podatki se obdelujejo na podlagi točke b 6/I člena Splošne uredbe o
    varstvu podatkov (izvajanje pogodbe).
  </p>
  <br />
  <p class="privacyPolicyContainerDownText">
    Če želite, lahko tekom dostopanja do Spletnih mest dodate še:
  </p>
  <ol class="privacyPolicyOlParagraph">
    <li>fotografijo v Uporabniškem profilu;</li>
    <li>vaše delovne preference za kreiranje e-opomnika na MojeDelo.com;</li>
    <li>
      prikazno ime, ki bo javno vidno drugim uporabnikom, kadar se odločite za
      sodelovanje na dogodkih poddomene hekaton.mojedelo.com.
    </li>
  </ol>

  <p class="privacyPolicyContainerDownText">
    Ti podatki se obdelujejo na podlagi točke a 6/I člena Splošne uredbe o
    varstvu podatkov (vaši privolitvi).
  </p>
  <br />
  <p class="privacyPolicyContainerDownText">
    II. Podatke, ki ste jih posredovali ob registraciji oziroma katere ste nam
    posredovali v nadaljnjih korakih z namenom uporabe naših storitev, lahko
    obdelujemo tudi v primerih zagotavljanja podpore pri dostopanju do vsebin in
    storitev Spletnih mest, za potrebe sistemskega in tehničnega obveščanja o
    spremembah in delovanju Spletnih mest ter komunikacije z drugimi
    registriranimi uporabniki (npr. delodajalci) na Spletnih mestih. V vseh teh
    primerih gre za obdelave osebnih podatkov povezanih z izvajanjem storitev
    Spletnih mest (pravni temelj iz točke b 6/I člena Splošne uredbe o varstvu
    podatkov – izvajanje pogodbe).
    <br />
    <br />
    III. Podatek o vašem elektronskem naslovu bomo uporabili tudi za namene
    e-obveščanja o novostih in sorodnih storitvah Spletnih mest (e-novice
    Spletnih mest), če prejemanje tovrstnih vsebin niste preklicali v katerem
    koli trenutku po opravljeni registraciji. E-novice so namenjene le
    registriranim uporabnikom, zato bomo v tem primeru obdelovali tudi podatek o
    tem, da ste registrirani uporabnik najmanj enega izmed Spletnih mest.
    Obdelava podatkov za namen pošiljanja e-novic Spletnih mest sloni na točki f
    6/I člena Splošne uredbe o varstvu podatkov – zakoniti interes upravljavca –
    oziroma 2. odstavku 226. člena Zakona o elektronskih komunikacijah.

    <br />
    <br />
    IV. Na Spletnih mestih se lahko naročite tudi na prejemanje raznovrstnih
    trženjskih sporočil. Tovrstna sporočila so namenjena neregistriranim
    uporabnikom in registriranim uporabnikom, ki želijo prejemati sporočila, za
    pošiljanje katerih ne razpolagamo z drugo pravno podlago. V primerih vašega
    naročila na prejemanje tovrstnih e-obvestil, bomo uporabili podatek o vašem
    elektronskem naslovu (neregistrirani uporabniki ga posredujejo ob podaji
    privolitve), podani privolitvi in podatek o tipu uporabnika (neregistrirani
    ali registrirani uporabnik): obdelava osebnih podatkov za namen pošiljanja
    navedenih e-obvestil sloni na točki a 6/I člena Splošne uredbe o varstvu
    podatkov (vaši privolitvi).
    <br />
    <br />
    V. V primerih reševanja psihometričnih testov in testov kognitivnih
    sposobnosti posredujete tudi posebne vrste osebnih podatkov v okviru skrbno
    pripravljenih vprašalnikov, vse z namenom priprave vaših rezultatov
    tovrstnih testov. Podatke posredujete našim pogodbenih izvajalcem, ki jih
    obdelujejo z namenom zagotavljanja storitev na teh spletnih straneh. Na
    podlagi posredovanja vaše ocene svojih osebnih lastnosti, miselnih
    sposobnosti in lastnih preferenc oziroma na podlagi odgovorov na druga
    vprašanja v vprašalniku, se pripravijo prvi avtomatizirani rezultati vaših
    osebnostnih lastnosti in kognitivnih sposobnosti, ki omogočajo preverbo
    ujemanja posameznika z delovnim okoljem in družbo, za čigar delovno mesto
    posameznik kandidira oziroma bo kandidiral. Rezultati vsebujejo oceno
    stopnje ujemanja posameznika z določenim delovnim okoljem kot tudi pisno
    pojasnilo posameznih rezultatov. Obdelava osebnih podatkov z namenom
    priprave vaših rezultatov psihometričnih testov in testov kognitivnih
    sposobnosti sloni na točki 6/I člena Splošne uredbe o varstvu podatkov (vaši
    privolitvi).

    <br />
    <br />
    VI. Ob vaši uporabi storitev na Spletnih mestih se lahko sistemsko beležijo
    tudi drugi podatki oziroma informacije, ki jih avtomatizirano obdelujemo za
    potrebe internih analiz, da bi izboljšali naše storitve, za statistične
    obdelave in iz varnostnih razlogov. Podatki, ki jih obdelujemo za opisane
    namene so:
  </p>
  <ol class="privacyPolicyOlLine">
    <li>piškotki,</li>
    <li>
      podatki o uporabi storitev (spletni brskalnik in trenuten IP naslov
      naprave, čas in trajanje dostopanja do teh spletnih strani, spletne
      strani, s katerih se klika na te spletne strani),
    </li>
    <li>
      podatke o telefonskih in spletnih pogovorih (uporabniško ime (opcijsko),
      čas in trajanje).
    </li>
  </ol>
  <p class="privacyPolicyContainerDownText">
    V primeru iz 1. točke se vaši osebni podatki obdelujejo na podlagi določb
    Zakona o elektronskih komunikacijah (Ur. l. RS, št. 130/22 s spremembami).
    Razen obdelave osebnih podatkov s piškotki, ki so nujni za delovanje
    Spletnih mest, se ostali piškotki namestijo le ob predhodni privolitvi
    posameznika (pravni temelj iz točke a 6/I člena Splošne uredbe o varstvu
    podatkov).
    <br />
    <br />
    V primeru iz 2. in 3. točke naslanjamo obdelavo osebnih podatkov na točko f
    6/I člena Splošne uredbe o varstvu podatkov (na zakonite interese
    upravljavca, da zagotovi uporabnikom prijazne in njihovim potrebam čim bolj
    prilagojene storitve, zmanjša tveganje zlorab, njihovo ugotavljanje ter
    zagotovi varnost svojega omrežja in informacij).
    <br />
    <br />
    Kadar lahko dosežemo zasledovani namen, se v statističnih obdelavah
    uporabijo anonimizirani podatki. Anonimiziranih podatkov ni več možno
    povezati z uporabnikom, ki je takšne osebne podatke posredoval, zato se pri
    njihovi obdelavi ne uporablja ta Politika zasebnosti in varovanja osebnih
    podatkov.
    <br />
    <br />
    VII. Vaše podatke lahko pridobimo tudi s strani drugih uporabnikov Spletnih
    mest (npr. preko prijave zlorab, kršitev). V navedenem primeru vaše osebne
    podatke pridobimo in naprej obdelujemo na podlagi točke f 6/I člena Splošne
    uredbe o varstvu podatkov (zakoniti interes upravljavca za zmanjševanje
    tveganja zlorab in njihovo ugotavljanje).
    <br />
    <br />
    VIII. Osebne podatke, ki smo jih pridobili pri izvajanju storitev Spletnih
    mest oziroma v zvezi z izvajanjem storitev smo dolžni uporabiti tudi za
    namen izvajanja zakonskih obveznosti (na primer za računovodske namene na
    podlagi davčne zakonodaje ob naročilu plačljivih storitev, kadar so takšne
    storitve na voljo). V teh primerih obdelava vaših osebnih podatkov sloni na
    točki c 6/I člena Splošne uredbe o varstvu podatkov (izvajanje zakonske
    obveznosti).
  </p>

  <h2 id="section2.1" class="privacyPolicyContainerDownTitle">
    2.1 Starostna omejitev storitev informacijske družbe
  </h2>
  <p class="privacyPolicyContainerDownText">
    Storitve Spletnih mest so namenjene osebam starejšim od 16 let, saj že
    registracija na portalu predstavlja vstop v pogodbeni odnos, za katerega je
    potrebna vsaj omejena poslovna sposobnost. Podatek o starosti ni pogoj za
    registracijo na Spletnih mestih in nismo zavezani k preverjanju, kateri
    podatki se nanašajo na osebe mlajše od 16 let. Staršem in zakonitim
    zastopnikom otrok mlajših od 16 let svetujemo, da o varni uporabi spleta in
    posredovanju svojih osebnih podatkov ustrezno poučijo otroke mlajše od 16
    let. Vsa tveganja, ki bi izhajala iz nesposobnosti mladoletne osebe, da
    prevzame veljavne zaveze, so na strani njihovih staršev in skrbnikov.
  </p>
  <h2 id="section3" class="privacyPolicyContainerDownTitle">
    3. Posredovanje osebnih podatkov tretjim osebam
  </h2>
  <p class="privacyPolicyContainerDownText">
    Seznanjamo vas, da so vaši osebni podatki lahko dostopni tudi:
  </p>
  <ol class="privacyPolicyOlLine">
    <li>
      drugim registriranim uporabnikom (npr. delodajalcem) Spletnih mest, kot
      samostojnim upravljavcem vaših osebnih podatkov, v obsegu, ki ga sami
      določite oz. v primerih, ko podatke prostovoljno posredujete drugim
      uporabnikom ali pa se prijavite na dogodke Spletnih mest (kot na primer
      nagradne igre, hekatone) (na podlagi točke a 6/I člena Splošne uredbe o
      varstvu podatkov; vaše privolitve),
    </li>
    <li>
      ostalim družbam znotraj skupine Styria Media Group, katere del je
      upravljavec (na podlagi točke f 6/I člena Splošne uredbe o varstvu
      podatkov; zakonitega interesa družb v skupini, za notranje upravne namene
      analitike, statistike),
    </li>
    <li>
      našim preverjenim pogodbenim obdelovalcem, ki nam omogočajo razvoj in
      vzdrževanje Spletnih mest, hrambo podatkovnih baz, pretvorbo posredovanih
      podatkov v strukturirano obliko (vaš življenjepis), elektronsko
      obveščanje, pripravo rezultatov psihometričnih testov in testov
      kognitivnih sposobnosti, izvedbo nagradnih iger, hekatonov, anket in
      različnih vprašalnikov, kadar je to mogoče, nam tovrstne storitve
      zagotavljajo družbe znotraj skupine Styria Media Group (na podlagi
      sklenjene pogodbe o storitvi in dogovora po 28. členu Splošne uredbe o
      varstvu podatkov),
    </li>
    <li>
      pooblaščenim osebam in pristojnim državnim organom, ki za pridobivanje in
      obdelavo podatkov razpolagajo z ustrezno pravno podlago v svojih zakonskih
      predpisih (na podlagi točke c 6/I člena Splošne uredbe o varstvu podatkov
      – izvajanja zakonske obveznosti).
    </li>
  </ol>
  <p class="privacyPolicyContainerDownText">
    Pri vsakem posredovanju vaših osebnih podatkov poskrbimo za ustrezne
    tehnične in organizacijske ukrepe z namenom zagotavljanja varnosti vaših
    osebnih podatkov, k enakim ukrepom pa so zavezani tudi vsi prejemniki vaših
    podatkov.
  </p>

  <h2 id="section3.1" class="privacyPolicyContainerDownTitle">
    3.1 Posredovanje osebnih podatkov v tretje države
  </h2>
  <p class="privacyPolicyContainerDownText">
    Podatke posredujemo v tretje države (izven EU in EGP) samo v primeru, da za
    tak prenos razpolagamo z vašim izrecnim soglasjem in kadar je to nujno
    potrebno za izpolnjevanje naših pogodbenih in zakonskih obveznosti. V
    primeru izvoza vaših osebnih podatkov, slednjega izvedemo v minimalnem
    obsegu potrebnem za zagotavljanje storitev na Spletnih mestih.
    <br />
    <br />
    Vaši podatki se lahko prenašajo:
  </p>
  <ol class="privacyPolicyOlLine">
    <li>
      v ZDA v primeru vašega soglasja za prikazovanje prilagojenih oglasov na
      teh spletnih straneh (npr. družbi Google, Inc.), pri posredovanju
      e-obvestil (družbi Salesforce, Inc.), pri vnosu vaših osebnih podatkov v
      okviru anket in različnih vprašalnikov, preko katerih Spletna mesta
      komunicirajo s svojimi uporabniki (družbi Momentive Inc. oziroma Momentive
      Europe), z namenom zagotavljanja varne hrambe podatkovnih baz (družbi
      Microsoft Ireland Operations Ltd.) in pri komuniciranju z družbenimi
      omrežji (več si preberite v poglavju Družbena omrežja);
    </li>
    <li>
      v Združeno kraljestvo Velike Britanije in Severne Irske v primeru priprave
      rezultatov psihometričnih testov (družbi Selby & Mills Ltd.) in v primeru
      dostopanja do e-izobraževanj na teh spletnih straneh (družbi JollyDeck
      Ltd).
    </li>
  </ol>
  <p class="privacyPolicyContainerDownText">
    Pri prenosu vaših osebnih podatkov v tretje države, poleg ustrezne pravne
    podlage za tak prenos, zagotovimo tudi dodatne ukrepe z namenom ohranjanja
    ustrezne ravni varnosti vaših podatkov tekom prenosa, pri tem se naslanjamo
    na načela V. poglavja Splošne uredbe o varstvu podatkov. Prenos vaših
    podatkov v tretje države vršimo na podlagi:
  </p>
  <ol class="privacyPolicyOlLine">
    <li>
      izdanega sklepa o zagotavljanju ustrezne ravni varstva osebnih podatkov s
      strani Evropske komisije ali Informacijskega pooblaščenca RS (npr. prenosi
      podatkov v Združeno kraljestvo Velike Britanije in Severne Irske);
    </li>
    <li>
      standardnih pogodbenih klavzul, ki jih sprejme Evropska komisija in
      Informacijski pooblaščenec RS in jih odobri Evropska komisija (npr.
      prenosi podatkov v ZDA).
    </li>
  </ol>

  <h2 id="section4" class="privacyPolicyContainerDownTitle">
    4. Družbena omrežja
  </h2>
  <p class="privacyPolicyContainerDownText">
    Za potrebe komunikacije in posredovanja zanimivih vsebin uporabnikom
    Spletnih mest uporabljamo tudi poslovne profile na sledečih družbenih
    omrežjih:
  </p>
  <ul class="privacyPolicyOlLine">
    <li>Meta Platforms, Inc., ki upravlja z omrežji Facebook in Instagram;</li>
    <li>LinkedIn Ireland Unltd. Co., ki upravlja z omrežjem LinkedIn;</li>
    <li>Google, Inc., ki upravlja z omrežjem YouTube in</li>
    <li>Twitter, Inc., ki upravlja z istoimenskim omrežjem Twitter.</li>
  </ul>
  <p class="privacyPolicyContainerDownText">
    V navedenih primerih lahko pridobivamo in obdelujemo vaše podatke, pri čemer
    jih ne prenašamo v naše interne baze Spletnih mest. Pri uporabi navedenih
    poslovnih profilov imajo pooblaščene osebe upravljavca dostop do vaših
    zasebnih sporočil in javnih objav. S strani družbenih omrežij pa pridobivamo
    statistična poročila o obisku naših profilov, splošnih interesih
    obiskovalcev in demografske podatke. Navedena poročila ne vsebujejo osebnih
    podatkov in so nam le v pomoč pri zagotavljanju zanimivih vsebin uporabnikom
    naših storitev.
    <br />
    <br />
    V primeru uporabe storitev navedenih družbenih omrežij in njihove
    interakcije s temi spletnimi stranmi, se podatki prenašajo v ZDA, pri čemer
    družbe, ki upravljajo s posameznim omrežjem, samostojno upravljajo tudi s
    prejetimi osebnimi podatki, kar pomeni, da določajo vrste osebnih podatkov,
    ki jih obdelujejo, ter namene in pravne podlage, na katerih se obdelava
    osebnih podatkov izvaja, ravno tako pa v celoti samostojno upravljajo s
    piškotki na svojih spletnih straneh in določajo njihove namene uporabe.
    <br />
    <br />
    Pozivamo vas, da se, v primeru interakcije z družbenimi omrežji iz prvega
    odstavka te točke, seznanitev z njihovimi politikami zasebnosti, ki so na
    voljo na spodnjih povezavah:
  </p>
  <ul class="privacyPolicyOlLine">
    <li><a href="https://www.facebook.com/policy.php">Facebook</a></li>
    <li><a href="https://help.instagram.com/519522125107875/?maybe_redirect_pol=0">Instagram</a></li>
    <li><a href="https://www.linkedin.com/legal/privacy-policy?trk=homepage-basic_footer-privacy-policy">LinkedIn</a></li>
    <li><a href="https://policies.google.com/privacy?hl=sl">Youtube</a></li>
    <li><a href="https://twitter.com/en/privacy">Twitter</a></li>
  </ul>
  <h2 id="section5" class="privacyPolicyContainerDownTitle">5. Piškotki</h2>
  <p class="privacyPolicyContainerDownText">
    Spletna mesta uporabljajo različne vrste piškotkov. Piškotki so
    alfanumerični identifikatorji, ki se naložijo na uporabnikov trdi disk in
    omogočajo prepoznavanje uporabnika ob naslednjem obisku. Tako vam ni
    potrebno vnašati istih podatkov pri prijavi in uporabi storitev Spletnih
    mestih. Za upravljanje piškotkov uporabljamo aplikacijo Didomi, ki
    uporabnikom omogoča informiranje o naloženih piškotkih ter upravljanje s
    soglasji za namestitev posameznih piškotkov na uporabnikovo napravo. Več
    informacij o piškotkih, ki jih uporablja posamezno Spletno mesto, si lahko
    preberete v dokumentu Nastavitve piškotkov, dostopnem na vsakem Spletnem
    mestu, ki omogoča uporabo piškotkov.
  </p>
  <h2 id="section6" class="privacyPolicyContainerDownTitle">6. Varnost</h2>
  <p class="privacyPolicyContainerDownText">
    Družba Styria digital marketplaces, d.o.o. veliko pozornosti posveča zaščiti
    vaših podatkov, zato pri vnosu podatkov na naših Spletnih mestih uporabljamo
    t.i. SSL (Secure Socets Layer) tehnologijo, ki je de facto standard v svetu
    in preprečuje možnost dostopa tujih oseb do podatkov, ki ste jih vnesli na
    svoji napravi. Vaši podatki so zaščiteni pred izgubo, uničenjem,
    ponarejanjem, manipulacijo in nepooblaščenim dostopom s strani tretjih oseb.
    Ravno tako so naši zaposleni zavezani k varovanju vaših osebnih podatkov,
    spoštovanju veljavnih predpisov in internih pravilnikov družbe Styria
    digital marketplaces, d.o.o.. Redno preverjamo sprejete zaščitne ukrepe in
    jih dopolnjujemo. Skrbno in odgovorno izbiramo naše poslovne partnerje.
    Visok nivo zaščite pa dosegamo tudi z ukrepi psevdonimiziranja in
    anonimiziranja podatkov, ko takšni podatki še vedno dopuščajo ustrezno
    zagotavljanje storitve.
    <br />
    <br />
    Posebno pozornost posvečamo varnosti posebnih vrst osebnih podatkov, zato se
    vaši odgovori in podatki, ki jih posredujete z namenom priprave rezultatov
    psihometričnih testov in testov kognitivnih sposobnosti, obdelujejo preko
    varne povezave, pri čemer pogodbeni izvajalci tovrstnih testov anonimno
    obdelujejo vaše osebne podatke, kadar takšna obdelava dopušča nemoteno
    izvedbo takšnih storitev. Po pripravi rezultatov se vaši odgovori trajno
    uničijo ob upoštevanju vseh ukrepov za varno uničenje. Styria digital
    marketplaces, d.o.o. nima dostopa do posameznikovih odgovorov na vprašanja
    testov niti jih ne posreduje tretjim osebam (na primer delodajalcem, kadar
    naročajo storitve na Spletnih mestih). Dostop do končnih rezultatov je
    varovan in omogočen le pooblaščenim osebam upravljavca oziroma drugim
    registriranim uporabnikom (na primer delodajalcem), če ste predhodno podali
    vašo izrecno privolitev za takšno posredovanje. Posredovanje rezultatov
    psihometričnih testov in testov kognitivnih sposobnosti je možno le v okviru
    funkcionalnosti, ki so na voljo na teh spletnih straneh, in dodeljenih
    pravic registriranim uporabnikom.
  </p>
  <h2 id="section7" class="privacyPolicyContainerDownTitle">7. Rok hrambe</h2>
  <p class="privacyPolicyContainerDownText">
    Vaše osebne podatke hranimo skladno s predpisanimi roki hrambe oziroma do
    izpolnitve namena, za katerega so bili pridobljeni, in sicer:
  </p>
  <ol class="privacyPolicyOlLine">
    <li>
      Osebne podatke pridobljene na podlagi sklenjene pogodbe obdelujemo do
      izteka zastaralnih rokov po splošnih pravilih civilnega prava. To velja
      tudi v primeru izbrisa uporabniškega profila na Spletnem mestu oziroma
      ukinitve uporabniškega profila v skladu s <a href="#general-terms-and-conditions" target="_blank">Splošnimi pogoju uporabe</a> in
      drugimi pravili in pogoji posameznega Spletnega mesta, razen če daljši rok
      hrambe ni predpisan z veljavno zakonodajo.
    </li>
    <li>
      Osebne podatke pridobljene za namene pošiljanja e-novic Spletnih mest
      hranimo do prejema vaše odjave od prejemanja tovrstnih obvestil.
    </li>
    <li>
      Osebne podatke pridobljene na podlagi vašega soglasja hranimo do preklica,
      če ni drugačen rok predviden v podanem soglasju. Spletna mesta lahko, v
      primeru novih funkcionalnosti in kadar je to potrebno za nemoteno in
      zakonito zagotavljanje storitev, pripravijo nove obrazce za pridobitev
      soglasja ali pa prenehajo s pridobivanjem posameznega soglasja. Takšna
      sprememba ne vpliva na veljavnost pred tem pridobljenih soglasij. Z danimi
      soglasji registrirani uporabnik upravlja (jih poda ali prekliče) sam, prek
      enotnega uporabniškega profila Spletnih mest ali prek pisne zahteve
      naslovljene na upravljavca.
    </li>
    <li>
      Posebne vrste osebnih podatkov, pridobljene na podlagi vašega soglasja,
      obdelujemo do izpolnitve namena, za katerega so pridobljeni. Podatki
      pridobljeni z namenom priprave rezultatov psihometričnih testov in testov
      kognitivnih sposobnosti (vaši odgovori na vprašanja) se po pripravi
      tovrstnih rezultatov trajno brišejo z vseh nosilcev. Daljša hramba
      podatkov velja le za rezultate, pripravljene na podlagi izvedenih testov,
      slednji se hranijo še 3 leta od njihovega nastanka oz. do vašega preklica
      privolitve (kar nastopi prej).
    </li>
    <li>
      Osebne podatke, pridobljene za namen varovanja omrežja in preprečevanja
      zlorab hranimo 2 leti od njihovega nastanka.
    </li>
    <li>
      Osebne podatke potrebne za uveljavljanje, izvajanje in obrambo pravnih
      zahtevkov hranimo še 5 let od pravnomočnosti sodbe, poravnave ali dogovora
      o izvensodni rešitvi spora.
    </li>
  </ol>

  <h2 id="section8" class="privacyPolicyContainerDownTitle">
    8. Profiliranje in avtomatizirana obdelava podatkov
  </h2>
  <p class="privacyPolicyContainerDownText">
    V okviru storitev teh spletnih strani se lahko oblikujejo različni profili
    posameznikov, zlasti z uporabo sredstev za avtomatizirano obdelavo podatkov.
    Profile ustvarjamo z namenom priprave vam prilagojenih ponudb v sklopu
    trženjskega obveščanja, za ponovno obveščanje ter v okviru priprave
    rezultatov psihometričnih testov in testov kognitivnih sposobnosti.
  </p>
  <h2 id="section9" class="privacyPolicyContainerDownTitle">
    9. Pravice uporabnikov
  </h2>
  <p class="privacyPolicyContainerDownText">
    Posamezniki oziroma uporabniki Spletnih mest sami vnašate in posredujete
    vaše osebne podatke ter v okviru vaših pravic odločate o njihovi obdelavi.
    <br />
    <br />
    Seznanjamo vas, da imate pravico do dostopa, dopolnitve, popravka, izbrisa
    (pravica do pozabe), omejitve obdelave, prenosljivosti vaših osebnih
    podatkov in ugovora zoper obdelavo, ki temelji na točki f) 1. odstavka 6.
    člena Splošne uredbe EU o varstvu podatkov ali za namene neposrednega
    trženja in v primeru avtomatiziranega sprejemanja odločitev razen, ko je
    takšna odločitev nujno potrebna za preprečevanje zlorab in utaj na podlagi
    predpisov EU in nacionalne zakonodaje, za sklepanje in izvajanje pogodb med
    posameznikom in družbo Styria digital marketplaces, d.o.o. ali temelji na
    vaši izrecni privolitvi - vse v obsegu in pod pogoji, kot jih določa Splošna
    uredba o varstvu podatkov.
  </p>
  <hr class="privacyPolicyHr" />
  <p class="privacyPolicyContainerDownText">
    Pravico do dostopa do vaših podatkov uveljavite tudi tako, da samostojno in
    kadarkoli dostopate do vaših podatkov v okviru vašega uporabniškega profila.
    Zaradi zagotavljanja točnosti in ažurnosti vaših osebnih podatkov lahko na
    tem mestu vnesete tudi morebitne spremembe vaših podatkov, s čimer se ognete
    posebni zahtevi za popravek podatkov. V primeru dodatnih vprašanj ali
    nemožnosti vnosa sprememb in odprave napak v posredovanih osebnih podatkih,
    nas o tem nemudoma obvestite na elektronski naslov podpora@preferenca.si.
  </p>

  <hr class="privacyPolicyHr" />
  <p class="privacyPolicyContainerDownText">Obveščamo vas, da lahko:</p>
  <ol class="privacyPolicyOlLine">
    <li>
      uporabo vašega elektronskega naslova za namene pošiljanja e-novic Spletnih
      mest kadarkoli omejite,
    </li>
    <li>vse druge dane privolitve pa kadarkoli prekličete</li>
  </ol>
  <p class="privacyPolicyContainerDownText">
    na podlagi pisnega zahtevka posredovanega upravljavcu ali prek za to
    predvidene funkcionalnosti v vašem uporabniškem profilu.
    <br />
    <br />
    Odjava od prejemanja e-novic Spletnih mest in preklic privolitve za obdelavo
    osebnih podatkov za druge namene e-obveščanja je zaradi specifike mogoče
    urediti tudi preko spletne povezave, ki je na voljo v vsakokratnem
    elektronskem sporočilu, ki ga prejmete.
    <br />
    <br />
    Pojasnjujemo, da je proces brisanja možno ustaviti v roku 24 ur, po tem roku
    prekinitev ni več možna. Izbris podatkov je nepovraten, njihova naknadna
    pridobitev pa ni možna.
    <br />
    <br />
    Preklic soglasja in izbris ne vplivata na zakonitost obdelave, izvedene v
    skladu s soglasjem pred preklicem oziroma izbrisom vaših podatkov.
  </p>
  <hr class="privacyPolicyHr" />
  <p class="privacyPolicyContainerDownText">
    Posamezniki, kot imetniki podatkov, imate pravico zahtevati prenos vaših
    podatkov k drugemu upravljavcu. Iz tehničnih razlogov prenos podatkov ni
    možno izvesti samostojno prek vašega uporabniškega profila. V navedenih
    primerih se upravljavcu Spletnih mest posreduje pisna zahteva za prenos
    vaših podatkov.
  </p>
  <hr class="privacyPolicyHr" />
  <p class="privacyPolicyContainerDownText">
    Vsi zahtevki se posredujejo v pisni obliki na naslov Styria digital
    marketplaces, d.o.o., Verovškova 55, SI-1000 Ljubljana ali elektronski
    naslov dpo_styria-slo@styria.com. O naši odločitvi vas bomo ravno tako pisno
    obvestili, v roku kot ga določa Splošna uredba o varstvu podatkov.
    <br />
    <br />
    Seznanjamo vas, da vaše zgoraj navedene pravice niso absolutne in jih moramo
    izpolniti zgolj v določenih primerih. Ob uveljavljanju vaših pravic bomo
    najprej preverili vašo identiteto, saj navedene pravice lahko uveljavlja
    samo posameznik, na katerega se osebni podatki nanašajo.
  </p>
  <hr class="privacyPolicyHr" />
  <p class="privacyPolicyContainerDownText">
    V primeru suma, da se vaši podatki ne obdelujejo skladno z veljavnimi
    predpisi s področja varstva osebnih podatkov in v primeru, da vaši zahtevi
    za uveljavitev pravice ne ugodimo, imate pravico do pritožbe pri
    Informacijskem pooblaščencu RS, Dunajska cesta 22, SI – 1000 Ljubljana.
  </p>

  <h2 id="section10" class="privacyPolicyContainerDownTitle">
    10. Povezave na druge spletne strani in uporaba tretjih storitev
  </h2>
  <p class="privacyPolicyContainerDownText">
    Ta politika zasebnosti se nanaša na Spletna mesta upravljavca. Naše spletne
    strani lahko vsebujejo tudi povezave na druge spletne strani ali storitve
    (npr. nagradne igre), vendar nikoli na nobeno z zakonsko nedovoljeno
    vsebino. Ob kliku na povezave drugih spletnih strani in ob podaji vaših
    osebnih podatkov v okviru storitev, za katere ta politika zasebnosti ne
    velja (tudi v primeru posredovanja podatkov drugim registriranim uporabnikom
    (delodajalcem), kot samostojnim upravljavcem vaših podatkov), se seznanite z
    njihovimi politikami zasebnosti. Če ni drugače izrecno dogovorjeno, družba
    Styria digital marketplaces, d.o.o. ni odgovorna za politiko zasebnosti in
    piškotkov ter vsebine povezanih spletnih strani in drugih storitev.
  </p>
  <h2 id="section11" class="privacyPolicyContainerDownTitle">
    11. Veljavnost in spremembe
  </h2>
  <p class="privacyPolicyContainerDownText">
    Ta politika zasebnosti stopi v veljavo z dnem objave. Upravljavec si
    pridržuje pravico do sprememb brez predhodnega obvestila, obvestilo o
    spremenjeni Politiki zasebnosti in varovanja osebnih podatkov pa bo vidno
    objavljeno na vstopni spletni strani Spletnih mest 7 dni od dneva objave
    prenovljene politike. Vse spremembe bodo objavljene na Spletnih mestih in
    stopijo v veljavo z dnem njihove objave.
    <br />
    <br />
    Upravljavec vaših podatkov je družba: <br />
    Styria digital marketplaces, d.o.o. <br />
    Verovškova 55 <br />
    SI – 1000 Ljubljana <br />
    e-naslov: dpo_styria-slo@styria.com
  </p>
</div>
<p class="privacyPolicyDateText">Ljubljana, 31. 01. 2023</p>`,
}
