import {FC, useEffect} from 'react'
import {FormattedMessage} from 'react-intl'
import Modal from 'Components/Modal'
import styles from './TimeoutModal.module.scss'
import {Warning} from '@mui/icons-material'

interface TimeoutModalProps {
  open: boolean
  setOpen: (open: boolean) => void
  authPing: () => void
}

export const TimeoutModal: FC<TimeoutModalProps> = ({
  open,
  setOpen,
  authPing,
}) => {
  const handlePing = () => {
    authPing()
    setOpen(false)
  }
  useEffect(() => {
    window.addEventListener('mousemove', handlePing)
    return () => {
      window.removeEventListener('mousemove', handlePing)
    }
  }, [])

  return (
    <Modal
      className={styles.timeoutModal}
      icon={Warning}
      title="Log out warning"
      isOpen={open}
      onAfterClose={() => {
        setOpen(false)
      }}
      onRequestClose={handlePing}
    >
      <FormattedMessage id="You will be logged out in one minute. If you want to stay logged in, please move the mouse or click anywhere on the page." />
    </Modal>
  )
}
