import {
  ActionType,
  CompanyDataType,
  ErrorType,
  StateType,
  CompanyDescriptionItem,
  EmploymentInformationItem,
  QuestionValue,
  CompanyBenefitsType,
  CompanyBenefitsSaveType,
  WelcomeMessageDataType,
  ImageType,
  ImpressionType,
  VideoType,
} from 'Interfaces'
import * as type from '../types'

const initialState = {
  data: {
    id: '',
    logotype: null,
    coverImage: null,
    welcomeMessages: [],
    aboutCompany: '',
    mainActivities: '',
    visionAndGoals: '',
    structureAndCulture: '',
    customCompanyDescription: '',
    portfolio: null,
    titleBiziSi: '',
    addressBiziSi: '',
    cityBiziSi: '',
    postalCodeBiziSi: '',
    vatNumberBiziSi: '',
    registrationNumberBiziSi: '',
    incomeBiziSi: '',
    companySizeBiziSi: '',
    industryBiziSi: '',
    companyLinkBiziSi: '',
    logoUrlBiziSi: '',
    employmentInformationWhatWeExpect: '',
    employmentInformationWhatWeOffer: '',
    employmentInformationCustomTextHtml: '',
    name: null,
    companyQuestions: [],
    benefitsText: null,
    customBenefitsTextHtml: null,
    additionalBenefits: [],
    benefits: null,
    impressions: [],
    videos: [],
    jobCategory: {
      id: '',
      translation: '',
    },
  },
  loading: false,
  error: undefined,
}

const CompanyReducer = (
  state: StateType<CompanyDataType> = initialState,
  action: ActionType<
    | CompanyDataType
    | ErrorType
    | QuestionValue[]
    | CompanyBenefitsType
    | string
    | undefined
    | []
    | CompanyBenefitsSaveType
    | WelcomeMessageDataType
    | ImageType
  >
): StateType<CompanyDataType> => {
  switch (action.type) {
    case type.company.data.requested:
    case type.company.header.edit.requested:
    case type.company.logotype.save.requested:
    case type.company.coverImage.save.requested:
    case type.company.companyDescription.save.requested:
    case type.company.benefits.save.requested:
    case type.company.impressions.add.requested:
    case type.company.impressions.update.requested:
    case type.company.employmentInformation.save.requested:
    case type.company.portfolio.save.requested:
    case type.company.videos.add.requested:
    case type.company.videos.update.requested:
    case type.company.questions.save.requested:
      return {...state, loading: true, error: undefined}
    case type.company.data.succeeded:
      return {
        data: action.payload as CompanyDataType,
        loading: false,
        error: undefined,
      }
    case type.company.header.edit.succeeded:
      return {
        data: {
          ...state.data,
          ...(action.payload as CompanyDataType),
        },
        loading: false,
        error: undefined,
      }
    case type.company.portfolio.save.succeeded:
      return {
        data: {
          ...state.data,
          portfolio: action.payload as string,
        },
        loading: false,
        error: undefined,
      }
    case type.company.welcomeMessage.add.succeeded:
      return {
        data: {
          ...state.data,
          welcomeMessages: [...(action.payload as WelcomeMessageDataType[])],
        },
        loading: false,
        error: undefined,
      }
    case type.company.welcomeMessage.edit.succeeded:
      return {
        data: {
          ...state.data,
          welcomeMessages: [...(action.payload as WelcomeMessageDataType[])],
        },
        loading: false,
        error: undefined,
      }
    case type.company.benefits.save.succeeded:
      return {
        data: {
          ...state.data,
          ...(action.payload as CompanyBenefitsSaveType),
        },
        loading: false,
        error: undefined,
      }

    case type.company.companyDescription.save.succeeded:
      return {
        data: {
          ...state.data,
          ...(action.payload as CompanyDescriptionItem),
        },
        loading: false,
        error: undefined,
      }
    case type.company.companyDescription.biziSi.succeeded:
      return {
        data: {
          ...state.data,
          ...(action.payload as CompanyDataType),
        },
        loading: false,
        error: undefined,
      }
    case type.company.employmentInformation.save.succeeded:
      return {
        data: {
          ...state.data,
          ...(action.payload as EmploymentInformationItem),
        },
        loading: false,
        error: undefined,
      }
    case type.company.questions.save.succeeded:
      return {
        data: {
          ...state.data,
          companyQuestions: action.payload as QuestionValue[],
        },
        loading: false,
        error: undefined,
      }
    case type.company.logotype.save.succeeded:
      return {
        data: {
          ...state.data,
          logotype: action.payload as ImageType,
        },
        loading: false,
        error: undefined,
      }
    case type.company.coverImage.save.succeeded:
      return {
        data: {
          ...state.data,
          coverImage: action.payload as ImageType,
        },
        loading: false,
        error: undefined,
      }
    case type.company.impressions.add.succeeded:
    case type.company.impressions.update.succeeded:
      return {
        data: {
          ...state.data,
          impressions: action.payload as ImpressionType[],
        },
        loading: false,
        error: undefined,
      }
    case type.company.videos.add.succeeded:
    case type.company.videos.update.succeeded:
      return {
        data: {
          ...state.data,
          videos: action.payload as VideoType[],
        },
        loading: false,
        error: undefined,
      }
    case type.company.data.failed:
    case type.company.header.edit.failed:
    case type.company.logotype.save.failed:
    case type.company.coverImage.save.failed:
    case type.company.companyDescription.save.failed:
    case type.company.benefits.save.failed:
    case type.company.impressions.add.failed:
    case type.company.impressions.update.failed:
    case type.company.employmentInformation.save.failed:
    case type.company.portfolio.save.failed:
    case type.company.videos.add.failed:
    case type.company.videos.update.failed:
    case type.company.questions.save.failed:
      return {...state, loading: false, error: action.payload as ErrorType}

    default:
      return state
  }
}

export default CompanyReducer
