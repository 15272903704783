import {connect} from 'react-redux'

import {EmployerCalendar} from './EmployerCalendar'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {getSpectoToken, setSpectoProposeNewTime} from 'Store/Actions'
import {SpectoProposeNewTimeParamsType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  spectoState: state.specto,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSpectoToken: () => dispatch(getSpectoToken()),
  setSpectoProposeNewTime: (values: SpectoProposeNewTimeParamsType) =>
    dispatch(setSpectoProposeNewTime(values)),
})

export const EmployerCalendarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerCalendar)
