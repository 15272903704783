import {EducationProfileItemType} from 'Interfaces'
import {orderBy} from 'lodash'

type EducationItem = EducationProfileItemType & {endYear: number}

export const sort = (
  items: EducationProfileItemType[]
): EducationProfileItemType[] =>
  orderBy(
    items,
    [
      (o) => o.inProgress === true,
      (o) => o.startYear,
      (o) => o.startMonth,
      (o) => (o as EducationItem).endYear,
      (o) => o.endMonth,
    ],
    ['desc', 'desc', 'desc', 'desc', 'desc']
  )
