import {FC, useState} from 'react'
import classNames from 'classnames'
import {FormattedMessage} from 'react-intl'

import styles from './EmployerInterviewInvitations.module.scss'
import EmployerRequestsLayout from 'Layouts/EmployerRequestsLayout'
import InterviewInvitationListItem from 'Components/EmployerDashboard/InterviewInvitationListItem'
import {InterviewRequestStatus} from 'Components/IncomingRequests/Constants'
import InfiniteScroll from 'Components/InfiniteScroll'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'
import {useMatchingRoute} from 'Services/I18n/Hooks/useMatchingRoute'
import {
  EmployerRequestsSearchParamsType,
  EmployerRequestsStateType,
} from 'Interfaces'
import {ArrowDropDown} from '@mui/icons-material'
import Loader from 'Components/Fallbacks/Loader'
import {NoLongerActiveCandidateModal} from 'Components/Modal/NoLongerActiveCandidateModal'

const getRouteTab = (routeKey: string | undefined): string => {
  switch (routeKey) {
    case 'routes.employerinterviewinvitationsPending':
      return 'pending'
    case 'routes.employerinterviewinvitationsAccepted':
      return 'accepted'
    case 'routes.employerinterviewinvitationsCompleted':
      return 'completed'
    default:
      return 'all'
  }
}

export type InterviewItemDisplayType = 'starts_in' | 'expire' | 'performed'
export type SortOptionsType = 'candidate' | 'status' | 'expire' | 'startDate'

type EmployerInterviewInvitationsProps = {
  requests: EmployerRequestsStateType
  loadMore: (url: string) => void
  getEmployerInterviews: (params?: EmployerRequestsSearchParamsType) => void
  getSeekersFromInterviews: (activeTab?: string) => void
  totalInterviewRequests?: number
}

export const EmployerInterviewInvitations: FC<
  EmployerInterviewInvitationsProps
> = ({requests, getEmployerInterviews, loadMore, getSeekersFromInterviews}) => {
  const matchingRoute = useMatchingRoute()
  const routeTab = getRouteTab(matchingRoute.routeKey)
  const [order, setOrder] = useState('DESC')
  const [sort, setSort] = useState('status')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const onSort = (value: string) => {
    if (sort === value) {
      setOrder((old) => (old === 'ASC' ? 'DESC' : 'ASC'))
    } else {
      setSort(value)
      setOrder('ASC')
    }
    getEmployerInterviews({...requests.params, sort, order})
  }

  let itemDisplayType: InterviewItemDisplayType = 'starts_in'
  if (routeTab === 'pending') itemDisplayType = 'expire'
  if (routeTab === 'completed') itemDisplayType = 'performed'

  const HeaderTitle = ({value, title}: {value: string; title: string}) => {
    const className = classNames(styles.sortBtn, {
      [styles.active]: value === sort,
      [styles.desc]: order === 'DESC',
    })

    return (
      <div onClick={() => onSort(value)} className={className}>
        <FormattedMessage id={title} />
        <ArrowDropDown />
      </div>
    )
  }

  return (
    <div className={styles.pageWrap}>
      <EmployerRequestsLayout
        tabs={['all', 'pending', 'accepted', 'completed']}
        activeTab={routeTab}
        onSearch={(params) => {
          getEmployerInterviews(params)
        }}
        getSeekers={getSeekersFromInterviews}
        type="interviews"
        statusOptions={Object.values(InterviewRequestStatus)}
        candidatesOptions={requests.candidatesOptions}
      >
        <div className={styles.contentHeader}>
          <HeaderTitle value="seekerName" title={edt.candidate} />
          <HeaderTitle value="status" title={edt.status} />
          {itemDisplayType === 'starts_in' && (
            <HeaderTitle value="startDate" title={edt.startsIn} />
          )}
          {itemDisplayType === 'expire' && (
            <HeaderTitle value="expire" title={edt.expire} />
          )}
          {(itemDisplayType === 'starts_in' ||
            itemDisplayType === 'expire') && (
            <HeaderTitle value="startDate" title={edt.proposedTime} />
          )}
          {itemDisplayType === 'performed' && (
            <>
              <HeaderTitle value="performed" title={edt.performed} />
              <HeaderTitle value="rating" title={edt.rating} />
            </>
          )}
          <div className={styles.leftMargin}>
            <FormattedMessage id={edt.action} />
          </div>
        </div>
        <InfiniteScroll
          loading={requests.loadMoreProgress}
          hasMore={
            !!requests.loadMore &&
            !!requests.total &&
            requests.total > requests.searchResults.length
          }
          loadMore={() => loadMore(requests.loadMore as string)}
        >
          {requests.loading ? (
            <div className={styles.loaderContainer}>
              <Loader />
              <span className={styles.loadingText}>
                <FormattedMessage id={edt.loadingText} />
              </span>
            </div>
          ) : requests.error ? (
            <div>{requests.error.message || 'Error'}</div>
          ) : requests.searchResults.length > 0 ? (
            requests.searchResults.map((request, idx) => (
              <InterviewInvitationListItem
                request={request}
                key={`interview-invitation-${idx}`}
                displayType={itemDisplayType}
                setIsModalOpen={setIsModalOpen}
              />
            ))
          ) : (
            <div className={styles.noResults}>
              <FormattedMessage id={edt.noResults} />
            </div>
          )}
        </InfiniteScroll>
      </EmployerRequestsLayout>
      <NoLongerActiveCandidateModal
        open={isModalOpen}
        setOpen={setIsModalOpen}
      />
    </div>
  )
}
