export const psychometricAssessmentsTranslation = {
  'psychometricassessmentstranslations.psychometricTestTitle':
    'Psychometric test',
  'psychometricassessmentstranslations.psychometricTestResults':
    'Psychometric test results',
  'psychometricassessmentstranslations.psychometricTestResultsUpperCase':
    'Psychometric Test Results',
  'psychometricassessmentstranslations.psychometricTestNoConsentBoxValue':
    'We cannot show you the results of the psychometric testing until you have given your consent. You can do this in',
}
