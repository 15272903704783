export enum ContactDataTranslations {
  title = 'contactData.title',
  message = 'contactData.message',
  description = 'contactData.description',
  checkMessage = 'contactData.checkMessage',
  phoneNumber = 'contactData.phoneNumber',
  email = 'contactData.email',
  streetAndNumber = 'contactData.streetAndNumber',
  zipCode = 'contactData.zipCode',
  city = 'contactData.city',
  country = 'contactData.country',
  confirmMessage = 'contactData.confirmMessage',
  selectYourCountry = 'contactData.selectYourCountry',
  selectYourRegion = 'contactData.selectYourRegion',
  selectAddress = 'contactData.selectAddress',
  selectPostNumber = 'contactData.selectPostNumber',
}
