import HistoryEduIcon from '@mui/icons-material/HistoryEdu'
import classnames from 'classnames'
import styles from './FeatherIcon.module.scss'

export interface FeatherIconProps {
  className?: string
}

export const FeatherIcon = ({className}: FeatherIconProps): JSX.Element => {
  return (
    <HistoryEduIcon className={classnames(styles.featherIcon, className)} />
  )
}
