import {ChangeEvent, FC, useEffect, useMemo, useRef, useState} from 'react'
import styles from './TypeAhead.module.scss'
import classnames from 'classnames'
import {ArrowDropDown, ArrowDropUp} from '@mui/icons-material'
import Chip from 'Components/Chip'
import {CheckBoxIcon} from 'Components/Icons/CheckBoxIcon'
import {TaxonomyWithCategoryType} from 'Interfaces'
import TextError from 'Components/Error/TextError'
import {OnboardingTranslations as onb} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import {cloneDeep, groupBy, isEqual, orderBy} from 'lodash'
import {useTranslate} from 'Hooks'

export interface TaxonomyWithCategoryCheckedType
  extends TaxonomyWithCategoryType {
  checked?: boolean
  itemsIndex?: number
}

interface allCategories {
  [name: string]: TaxonomyWithCategoryCheckedType[]
}

interface TypeAheadProps {
  name: string
  value?: TaxonomyWithCategoryType[]
  items: TaxonomyWithCategoryType[]
  setValue: (name: string, value: TaxonomyWithCategoryCheckedType[]) => void
  selectNonExisting?: boolean
  maxNumSelections?: number
  label?: string
  error?: string
  placeholder?: string
  className?: string
  theme?: 'grey' | 'white' | 'modal'
  onBlur?: ({}) => void
  clearAfterSetValue?: boolean
  setTouched?: (v: boolean) => void
  withoutArrow?: boolean
  invalidNonExisting?: TaxonomyWithCategoryCheckedType[]
  withChips?: boolean
  categoryPropName?: string
  categoryTranslationName?: string
  categoryOpenOnTyping?: boolean
}

export const TypeAheadWithCategory: FC<TypeAheadProps> = ({
  name,
  value,
  setValue,
  items,
  selectNonExisting = false,
  maxNumSelections = 0,
  label,
  error,
  placeholder,
  className,
  theme = 'grey',
  setTouched = () => {},
  clearAfterSetValue = false,
  withoutArrow = false,
  invalidNonExisting = [],
  withChips = true,
  categoryPropName = 'category',
  categoryTranslationName = 'translation',
  categoryOpenOnTyping = false,
}) => {
  const [textInput, setTextInput] = useState('')
  const [open, setOpen] = useState(false)
  const [localItems, setLocalItems] = useState<allCategories>({})
  const listRef = useRef<HTMLDivElement>(null)
  const translate = useTranslate()
  const [openCategories, setOpenCategories] = useState<{
    [name: string]: boolean
  }>({})

  const Other = translate('Other')

  useEffect(() => {
    let newItems = cloneDeep(items)
    newItems.forEach((item: TaxonomyWithCategoryCheckedType) => {
      item.checked = !!value?.find((v) => v.id === item.id)
    })
    const newValues = value
      ?.filter((v) => v.id.includes('newValue~'))
      .map((v) => ({...v, checked: true, category: Other}))
    if (newValues?.length) {
      newItems = [...newItems, ...newValues]
    }
    const categories: allCategories = groupBy(newItems, (item) =>
      item[categoryPropName] && item[categoryPropName][categoryTranslationName]
        ? item[categoryPropName][categoryTranslationName]
        : Other
    )

    if (!isEqual(categories, localItems)) {
      setLocalItems(categories)
    }
  }, [items, value])

  const checkedItems = useMemo(() => {
    let allItems: TaxonomyWithCategoryCheckedType[] = []
    Object.keys(localItems).forEach((key) => {
      allItems = [...allItems, ...localItems[key].filter((c) => c.checked)]
    })
    return allItems
  }, [localItems])

  useEffect(() => {
    if (!checkedItems.length) return
    if (
      maxNumSelections === 1 &&
      textInput !== checkedItems[0]?.translation &&
      !clearAfterSetValue
    ) {
      setTextInput(checkedItems[0].translation)
    }
  }, [checkedItems])

  const filteredItems = useMemo(() => {
    const tempFiltered: allCategories = cloneDeep(localItems)
    Object.keys(localItems).forEach((key) => {
      const filtred = localItems[key].filter(
        (o) =>
          textInput === '' ||
          o.translation.toLowerCase().includes(textInput.toLowerCase())
      )
      if (filtred.length) {
        tempFiltered[key] = filtred
      } else delete tempFiltered[key]
    })
    return tempFiltered
  }, [localItems, textInput])

  const onTextChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    setTextInput(value)
    if (maxNumSelections === 1 && checkedItems[0]?.translation !== value) {
      setValue(name, [])
    }
    if (categoryOpenOnTyping) {
      if (value !== '') {
        const newOpenCategories = cloneDeep(openCategories)
        Object.keys(filteredItems).forEach((key) => {
          newOpenCategories[key] = true
        })
        newOpenCategories[Other] = true
        setOpenCategories(newOpenCategories)
      } else {
        setOpenCategories({})
      }
    } else {
      if (selectNonExisting && value !== '') {
        setOpenCategories({...openCategories, [Other]: true})
      } else setOpenCategories({...openCategories, [Other]: false})
    }
  }

  const isMaxNumSelected = (): boolean => {
    return !!(maxNumSelections && checkedItems.length >= maxNumSelections)
  }

  const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget?.value
    handleValue(value)
  }

  const handleValue = (value: string = textInput) => {
    let newLocalItems = cloneDeep(localItems)
    if (maxNumSelections === 1) {
      Object.keys(newLocalItems).forEach((key) => {
        newLocalItems[key] = newLocalItems[key].map((n) => ({
          ...n,
          checked: false,
        }))
      })
    }
    let optionIndex = -1
    let optionKey = ''
    Object.keys(newLocalItems).forEach((key) => {
      let tempIndex = newLocalItems[key].findIndex((i) => {
        return i.id === value || i.id === `newValue~${value}`
      })
      if (tempIndex > -1) {
        optionIndex = tempIndex
        optionKey = key
      }
    })
    if (optionIndex > -1 && optionKey !== '') {
      if (
        isMaxNumSelected() &&
        !localItems[optionKey][optionIndex].checked &&
        maxNumSelections !== 1
      ) {
        return
      }

      newLocalItems[optionKey][optionIndex] = {
        ...localItems[optionKey][optionIndex],
        checked: !localItems[optionKey][optionIndex].checked,
      }
      let tempChecked: TaxonomyWithCategoryCheckedType[] = []
      Object.keys(newLocalItems).forEach((key) => {
        tempChecked = [
          ...tempChecked,
          ...newLocalItems[key].filter((c) => c.checked),
        ]
      })
      if (!clearAfterSetValue) {
        setValue(name, tempChecked)
      } else {
        setValue(name, tempChecked)
      }
      if (maxNumSelections === 1) {
        setOpen(false)
        if (!localItems[optionKey][optionIndex].checked && !clearAfterSetValue)
          setTextInput(localItems[optionKey][optionIndex].translation)
        else setTextInput('')
      }
    } else {
      if (isMaxNumSelected() && maxNumSelections !== 1) return
      // if it doesn't exist create new option and add it to the proper place
      const newItem = {
        id: `newValue~${textInput}`,
        translation: textInput,
        checked: true,
        [categoryPropName]: Other,
      }
      const newLocalItems = cloneDeep(localItems)
      if (newLocalItems[Other]) {
        newLocalItems[Other].push(newItem)
      } else newLocalItems[Other] = [newItem]

      if (maxNumSelections === 1) {
        setValue(name, [newItem])
        setOpen(false)
        if (clearAfterSetValue) {
          setTextInput('')
        } else {
          setTextInput(textInput)
        }
      } else if (!clearAfterSetValue) {
        let tempChecked: TaxonomyWithCategoryCheckedType[] = []
        Object.keys(newLocalItems).forEach((key) => {
          tempChecked = [
            ...tempChecked,
            ...newLocalItems[key].filter((c) => c.checked),
          ]
        })
        setValue(name, tempChecked)
      } else {
        let tempChecked: TaxonomyWithCategoryCheckedType[] = []
        Object.keys(newLocalItems).forEach((key) => {
          tempChecked = [
            ...tempChecked,
            ...newLocalItems[key].filter((c) => c.checked),
          ]
        })
        setValue(name, tempChecked)
        setOpen(false)
        setTextInput('')
      }
    }
    setTouched(true)
  }

  const handleChipRemove = (translation: string) => {
    const localIndex = checkedItems.findIndex(
      (i) => i.translation === translation
    )
    handleValue(checkedItems[localIndex].id)
  }

  const handleKeyup = (event: any) => {
    if (textInput === '') return
    if (selectNonExisting && event.code === 'Enter') {
      handleValue()
      setTextInput('')
      event.stopPropagation()
    } else if (event.code === 'ArrowDown') {
      const first = listRef.current?.querySelector(
        `.${styles.checkBoxWrap}`
      ) as HTMLLabelElement
      first.focus()
    }
    if (!open) setOpen(true)
  }

  const handleArrows = (event: any, value: string) => {
    if (event.code === 'ArrowDown') {
      event.currentTarget.nextSibling?.focus()
    } else if (event.code === 'ArrowUp') {
      event.currentTarget.previousSibling?.focus()
    } else if (event.code === 'Enter' || event.code == 'Space') {
      handleValue(value)
    }
  }

  const topLabelText = useMemo(() => {
    if (label) return translate(label) || label
    //TODO translation needed here
    else if (maxNumSelections === 1) return 'Select value'
    else if (maxNumSelections > 1)
      return `Select up to ${maxNumSelections} values`
    return translate(onb.selectMultiValue)
  }, [label, maxNumSelections])

  useEffect(() => {
    if (error) setOpen(false)
  }, [error])

  const hasFilteredItems = useMemo(
    () =>
      Object.keys(filteredItems).length ||
      (textInput !== '' && selectNonExisting),
    [filteredItems]
  )

  const hasAdd = useMemo(() => {
    return (
      selectNonExisting &&
      textInput !== '' &&
      items.findIndex((f) => f.translation === textInput) === -1 &&
      checkedItems.findIndex((f) => f.translation === textInput) === -1
    )
  }, [selectNonExisting, textInput, items, checkedItems])

  return (
    <div
      className={classnames(
        styles.typeAheadContainer,
        styles.typeAheadContainerWithCategory,
        {
          [styles.withoutChips]: !withChips,
          [styles.collapsed]: !hasFilteredItems || !open,
        },
        styles[theme],
        className
      )}
    >
      <div
        tabIndex={0}
        className={classnames(styles.inputMainContainer)}
        onBlur={(e) => {
          if (!e.currentTarget.contains(e.relatedTarget)) {
            setOpen(false)
          }
        }}
        onFocus={() => {
          setOpen(true)
        }}
      >
        <div
          className={classnames(styles.inputContainer, {[styles.error]: error})}
        >
          <div className={styles.inputWrap}>
            {(!placeholder || label) && (
              <div className={styles.inputTopPlaceholder}>{topLabelText}</div>
            )}
            <div className={styles.inputValues}>
              {maxNumSelections !== 1 &&
                checkedItems.map((chip, index: number) =>
                  withChips ? (
                    <Chip
                      key={`${chip.translation}-${index}`}
                      text={chip.translation}
                      className={styles.inputChip}
                      onClose={() => {
                        handleChipRemove(chip.translation)
                      }}
                    />
                  ) : (
                    <span
                      key={index}
                      className={styles.input}
                    >{`${chip.translation}, `}</span>
                  )
                )}
              {maxNumSelections !== 1 && !checkedItems.length && !withChips ? (
                <span className={styles.input}>
                  {translate(placeholder) ||
                    placeholder ||
                    `${translate(onb.typeAheadTypeYour)} ${translate(name)}`}
                </span>
              ) : null}
              {withChips && (
                <input
                  type="text"
                  className={styles.input}
                  value={textInput}
                  onChange={onTextChange}
                  onKeyUp={handleKeyup}
                  placeholder={
                    translate(placeholder) ||
                    placeholder ||
                    `${translate(onb.typeAheadTypeYour)} ${translate(name)}`
                  }
                />
              )}
            </div>
          </div>
          {!withoutArrow && (
            <div className={classnames(styles.arrowWrap, 'arrowWrap')}>
              <ArrowDropDown
                className={classnames(styles.arrow, {
                  [styles.rotate]: hasFilteredItems,
                })}
              />
            </div>
          )}
        </div>
        <div
          className={classnames(styles.dropdownContainer, {
            [styles.hidden]: !hasFilteredItems || !open,
          })}
          role="group"
          aria-labelledby={`${name}CheckBoxGroup`}
          ref={listRef}
        >
          {!withChips && maxNumSelections !== 1 ? (
            <input
              type="text"
              className={styles.input}
              value={textInput}
              onChange={onTextChange}
              onKeyUp={handleKeyup}
              placeholder={
                translate(placeholder) ||
                placeholder ||
                `${translate(onb.typeAheadTypeYour)} ${translate(name)}`
              }
            />
          ) : null}
          {Object.keys(filteredItems)
            .sort()
            .map((key, index) => {
              if (key === Other) return null
              else
                return (
                  <div className={styles.categoryWrap} key={index}>
                    <div
                      className={styles.categoryNameWrap}
                      onClick={() => {
                        setOpenCategories({
                          ...openCategories,
                          [key]: !openCategories[key],
                        })
                      }}
                    >
                      <div className={styles.categoryNameIcon}>
                        {openCategories[key] ? (
                          <ArrowDropUp />
                        ) : (
                          <ArrowDropDown />
                        )}{' '}
                      </div>
                      <div className={styles.categoryName}>
                        <FormattedMessage id={key} />
                      </div>
                    </div>
                    <div
                      className={classnames(styles.categories, {
                        [styles.categoriesClosed]: !openCategories[key],
                      })}
                    >
                      {orderBy(filteredItems[key], 'translation').map(
                        (item, index) => {
                          return (
                            <label
                              tabIndex={0}
                              className={styles.checkBoxWrap}
                              key={`${item.translation}-${index}`}
                              onKeyDown={(e) => {
                                handleArrows(e, item.translation)
                              }}
                            >
                              <input
                                type={
                                  maxNumSelections === 1 ? 'radio' : 'checkbox'
                                }
                                onChange={handleChecked}
                                checked={!!item.checked}
                                value={item.id}
                                className={styles.hidden}
                                multiple={maxNumSelections > 1}
                                name={name}
                              />
                              {maxNumSelections !== 1 && (
                                <CheckBoxIcon
                                  checked={!!item.checked}
                                  className={styles.icon}
                                />
                              )}
                              <div className={styles.checkBoxText}>
                                {item.translation}
                              </div>
                            </label>
                          )
                        }
                      )}
                    </div>
                  </div>
                )
            })}
          {hasAdd || (filteredItems[Other] && filteredItems[Other].length) ? (
            <div className={styles.categoryWrap}>
              <div
                className={styles.categoryNameWrap}
                onClick={() => {
                  setOpenCategories({
                    ...openCategories,
                    [Other]: !openCategories[Other],
                  })
                }}
              >
                <div className={styles.categoryNameIcon}>
                  {openCategories[Other] ? <ArrowDropUp /> : <ArrowDropDown />}
                </div>
                <div className={styles.categoryName}>
                  <FormattedMessage id={Other} />
                </div>
              </div>
              <div
                className={classnames(styles.categories, {
                  [styles.categoriesClosed]: !openCategories[Other],
                })}
              >
                {orderBy(filteredItems[Other], 'translation').map(
                  (item, index) => {
                    return (
                      <label
                        tabIndex={0}
                        className={styles.checkBoxWrap}
                        key={`${item.translation}-${index}`}
                        onKeyDown={(e) => {
                          handleArrows(e, item.translation)
                        }}
                      >
                        <input
                          type={maxNumSelections === 1 ? 'radio' : 'checkbox'}
                          onChange={handleChecked}
                          checked={!!item.checked}
                          value={item.id}
                          className={styles.hidden}
                          multiple={maxNumSelections > 1}
                          name={name}
                        />
                        {maxNumSelections !== 1 && (
                          <CheckBoxIcon
                            checked={!!item.checked}
                            className={styles.icon}
                          />
                        )}
                        <div className={styles.checkBoxText}>
                          {item.translation}
                        </div>
                      </label>
                    )
                  }
                )}
                {hasAdd ? (
                  <label
                    tabIndex={0}
                    className={styles.checkBoxWrap}
                    onKeyDown={(e) => {
                      handleArrows(e, textInput)
                    }}
                  >
                    {!invalidNonExisting?.length ||
                    invalidNonExisting.findIndex(
                      (f) => f.translation === textInput
                    ) === -1 ? (
                      <>
                        <input
                          type="checkbox"
                          onChange={handleChecked}
                          value={textInput}
                          className={styles.hidden}
                          name={name}
                        />
                        <div className={styles.checkBoxText}>{`${translate(
                          'add'
                        )} ${textInput}`}</div>
                      </>
                    ) : (
                      <div className={styles.checkBoxText}>
                        <FormattedMessage id="Entry already added" />
                      </div>
                    )}
                  </label>
                ) : null}
              </div>
            </div>
          ) : null}

          {!hasFilteredItems && !selectNonExisting ? (
            <div className={styles.checkBoxWrap}>
              <div className={styles.checkBoxText}>
                <FormattedMessage id="No entry found" />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      {error && <TextError text={error} />}
    </div>
  )
}
