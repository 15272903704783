import * as type from '../types'
import {ActionType} from 'Interfaces'

export const getFullCandidateData = (): ActionType<string> => {
  return {
    type: type.candidate.fullCandidateData.get.requested,
  }
}

export const getMinifeed = (): ActionType<string> => {
  return {
    type: type.candidate.minifeeds.get.requested,
  }
}
