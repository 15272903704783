const messageCenter = {
  'messageCenter.heading': 'Message center',
  'messageCenter.labelCandidates': 'Candidates',
  'messageCenter.labelEmployers': 'Employers',
  'messageCenter.tabLabelTSA': 'Talent success advisor',
  'messageCenter.messageType_all_messages': 'All messages',
  'messageCenter.messageType_inbox': 'Inbox',
  'messageCenter.messageType_archived': 'Archived',
  'messageCenter.messageType_unread': 'Unread',
  'messageCenter.searchBtn': 'Search',
  'messageCenter.searchConversationsLabel': 'Search messages',
  'messageCenter.resetSearchBtn': 'Reset search',
  'messageCenter.manageConversations': 'Manage conversation',
  'messageCenter.setAwayMessage': 'Set away message',
  'messageCenter.selected': 'Selected',
  'messageCenter.clearAll': 'Clear all',
  'messageCenter.selectAll': 'Select all',
  'messageCenter.heading_all_messages': 'Messages',
  'messageCenter.heading_archived': 'Archived messages',
  'messageCenter.heading_unread': 'Unread messages',
  'messageCenter.today': 'Today',
  'messageCenter.newMessage': 'New message',
  'messageCenter.typeAname': 'Type a name',
  'messageCenter.writeAmessage': 'Write a message …',
  'messageCenter.send': 'Send',
  'messageCenter.delete': 'Delete',
  'messageCenter.markAsUnread': 'Mark as unread',
  'messageCenter.archive': 'Archive',
  'messageCenter.you': 'You',
  'messageCenter.deleteModalTitle': 'Sure you want to delete?',
  'messageCenter.deleteModalContent':
    'All marked conversations, including all history will be permanently removed from Preferenca. This action cannot be undone.',
  'messageCenter.closeManaging': 'Close managing',
  'messageCenter.deleteConversation': 'Delete conversation',
  'messageCenter.employerPlaceholder': 'Select a candidate you want to message',
  'messageCenter.candidatePlaceholder':
    'Select an employer you want to message',
}

export default messageCenter
