import {FC, useEffect, useMemo} from 'react'
import styles from './Onboarding.module.scss'
import classnames from 'classnames'
import {StepCircles} from './StepCircles'
import {OnboardingSteps} from './steps'
import Logo from 'Assets/Images/preferenca-logo.svg'
import {FormattedMessage} from 'react-intl'
import {
  BenefitsContainer,
  JobPreferencesContainer,
  WorkExperienceContainer,
  YourCVContainer,
} from 'Components/Onboarding'
import {OnboardingStateType, RjbProfileStatus} from 'Interfaces'

interface OnboardingProps {
  onboarding: OnboardingStateType
  getOnboarding: () => void
}

export const Onboarding: FC<OnboardingProps> = ({
  onboarding,
  getOnboarding,
}) => {
  useEffect(() => {
    getOnboarding()
  }, [])

  const StepDisplay = useMemo(() => {
    const onboardingStep = onboarding.data?.onboardingStep
    if (onboardingStep === 2) {
      return <JobPreferencesContainer />
    } else if (onboardingStep === 3) {
      return <WorkExperienceContainer />
    } else if (onboardingStep === 4) {
      return <BenefitsContainer />
    } else return <YourCVContainer />
  }, [onboarding.data?.onboardingStep])

  useEffect(() => {
    window.scrollTo({top: 0})
  }, [onboarding.data?.onboardingStep])

  const step = onboarding.data?.onboardingStep || 1

  return onboarding.data?.onboardingStep &&
    onboarding.data?.rjbProfileStatus === RjbProfileStatus.DRAFT ? (
    <div className={styles.onboarding}>
      <div className={styles.main}>
        <div className={styles.logo}>
          <img src={Logo} alt={'logo'} />
        </div>
        <div className={styles.inner}>
          <div className={styles.title}>
            <FormattedMessage id={OnboardingSteps[step].title} />
          </div>
          <div className={styles.description}>
            <FormattedMessage id={OnboardingSteps[step].description} />
          </div>
          <StepCircles step={step + 1} total={5} />
          <div className="body">{StepDisplay}</div>
        </div>
      </div>
      <div className={styles.rightColumn}>
        <div className={styles.desktopSteps}>
          {Array.from({length: 4}, (_, i) => (
            <div
              key={i}
              className={classnames(styles.desktopStep, {
                [styles.filled]: step - 1 === i,
                [styles.done]: step - 1 > i,
              })}
            >
              <div className={styles.stepCnt}>{i + 1}</div>
              <div className={styles.stepName}>
                <FormattedMessage id={OnboardingSteps[i + 1].title} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : null
}
