import Modal from 'Components/Modal'

import styles from './ModalSendOfferDetailsEmp.module.scss'
import classnames from 'classnames'
import {IncomingRequestType} from 'Interfaces'
import {FC} from 'react'
import {DateTime} from 'luxon'
import {FormattedMessage} from 'react-intl'
import {CandidateDashboardTranslations as cdt} from 'Services/I18n/Constants'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'

import {
  HistoryEdu,
  AttachFile,
  Update,
  Business,
  Face,
  HourglassBottom,
} from '@mui/icons-material'
import {formatDate} from 'Utils/Date'

const ExpirationValue: FC<{date: string}> = ({date}) => {
  const now = DateTime.now()
  // TODO: Set locale:
  // const expiration = DateTime.fromISO(date).setLocale()
  const expiration = DateTime.fromISO(date)
  const diff = Math.floor(expiration.diff(now, ['days']).days)
  let dayOfTheWeek = expiration.toFormat('cccc')

  if (diff > 7 || expiration < now) {
    return (
      <p className={styles.expirationValueNormal}>
        <FormattedMessage id={dayOfTheWeek} />
        <span className={styles.date}>
          {expiration.toFormat(' (d.L.yyyy)')}
        </span>
      </p>
    )
  }

  return (
    <div className={styles.expirationValueContainer}>
      <p className={styles.expirationValueTextRed}>
        <FormattedMessage id={cdt.in} />
        {` ${diff} `}
        {diff === 1 ? (
          <FormattedMessage id={cdt.day} />
        ) : (
          <FormattedMessage id={cdt.days} />
        )}
      </p>
      <p className={styles.expirationValueText}>
        {expiration.toFormat('(d.L.yyyy)')}
      </p>
    </div>
  )
}
interface ModalSendOfferProps {
  request: IncomingRequestType
  isOpen: boolean
  setIsOpen: (open: boolean) => void
}

export const ModalSendOfferDetailsEmp: FC<ModalSendOfferProps> = ({
  request,
  isOpen,
  setIsOpen,
}) => {
  return (
    <>
      <Modal
        className={styles.modalDetails}
        icon={HistoryEdu}
        isOpen={isOpen}
        title={edt.sendOffer}
        onAfterClose={() => {
          setIsOpen(false)
        }}
      >
        <div className={styles.details}>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <Business className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={edt.offerFrom} />
              </p>
            </div>
            <div className={styles.itemRight}>
              <p className={styles.itemRightText}>{request.companyName}</p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <Face className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={edt.candidatesName} />
              </p>
            </div>
            <div className={styles.itemRight}>
              <p className={styles.itemRightText}>
                {request.seeker.firstName} {request.seeker.lastName}
              </p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <HistoryEdu className={styles.icon} />
              <p className={styles.dateTimeLeft}>
                <p>
                  <FormattedMessage id={edt.offerDescription} />
                </p>
              </p>
            </div>
            <div className={styles.itemRight}>
              <p className={styles.paragraphMessage}>{request.content}</p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <Update className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.dateOfRequestSent} />
              </p>
            </div>

            <div className={styles.itemRight}>
              <p className={styles.itemRightText}>
                {request.sentDate && formatDate(request.sentDate)}
              </p>
            </div>
          </div>
          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <HourglassBottom className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.expirationDate} />
              </p>
            </div>
            <div className={styles.itemRight}>
              {request.expirationDate && (
                <ExpirationValue date={request.expirationDate} />
              )}
            </div>
          </div>

          <div className={styles.item}>
            <div className={styles.itemLeft}>
              <AttachFile className={styles.icon} />
              <p className={styles.itemLeftText}>
                <FormattedMessage id={cdt.attachment} />:
              </p>
            </div>
            <div className={styles.itemRight}>
              <a
                className={classnames(
                  styles.itemRightText,
                  styles.itemRightTextBlue
                )}
                href={request.attachment?.url}
                target="_blank"
              >
                {request.attachment?.originalName}
              </a>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}
