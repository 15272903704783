import {FC, useEffect, useMemo, useState} from 'react'
import {useFormik} from 'formik'
import classnames from 'classnames'
import {useIntl} from 'react-intl'
import nl2br from 'react-nl2br'

import styles from './Awards.module.scss'
import Modal, {DeleteModal} from 'Components/Modal'
import SectionHeader from 'Components/Profile/SectionHeader'
import SectionItem from 'Components/Profile/SectionItem'
import ItemHeader from 'Components/Profile/ItemHeader'
import ItemTextWithIcon from 'Components/Profile/ItemTextWithIcon'
import Hr from 'Components/Hr'
import {Validation} from './Validation'
import {Sort} from './Sort'
import {AwardsItem, AwardsSaveValue, SeekerStateType} from 'Interfaces'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import TextField from 'Components/Inputs/TextField'
import ShowMore from 'Components/Profile/ShowMore'
import Dropdown from 'Components/Inputs/Dropdown'
import TextArea from 'Components/Inputs/TextArea'
import {useApiError, useYears} from 'Hooks'
import {ProfileTranslations as pt} from 'Services/I18n/Constants'
import Title from 'Components/Title'
import {EmojiEvents, Update} from '@mui/icons-material'

interface AwardsEndDateProps {
  profile: SeekerStateType
  awards?: AwardsItem[]
  addAwards: (value: AwardsSaveValue) => void
  editAwards: (value: AwardsSaveValue) => void
  deleteAward: (id: string) => void
  canChange?: boolean
}

export const AwardsEndDate: FC<AwardsEndDateProps> = ({
  profile,
  awards = [],
  addAwards,
  editAwards,
  deleteAward,
  canChange = false,
}) => {
  const emptyValues: AwardsSaveValue = {
    title: '',
    description: '',
    year: '',
  }

  const [modalOpen, setModalOpen] = useState(false)
  const [modalMode, setModalMode] = useState('Add')
  const [deleteModal, setDeleteModal] = useState<boolean | number>(false)
  const [showMoreIndex, setShowMoreIndex] = useState(1)
  const {formatMessage} = useIntl()
  const localAwards = useMemo(() => {
    return Sort(awards).slice(0, showMoreIndex)
  }, [awards, showMoreIndex, Sort])

  const openEdit = (id: string) => {
    setModalMode('Edit')
    const awardItem = awards.find((e) => e.id === id) as AwardsItem
    formik.resetForm({
      values: {
        id: id,
        title: awardItem.title,
        description: awardItem.description,
        year: awardItem.year ? awardItem.year.toString() : '',
      },
    })
    setModalOpen(true)
  }

  const openAdd = () => {
    setModalMode('Add')
    formik.resetForm({
      values: emptyValues,
    })
    setModalOpen(true)
  }

  const formik = useFormik({
    initialValues: emptyValues,
    validationSchema: Validation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      if (modalMode === 'Edit') {
        editAwards(values)
      } else addAwards(values)
    },
  })

  const years = useYears()

  useApiError(formik.setFieldError, profile.error)
  useEffect(() => {
    if (!profile.loading && !profile.error) {
      setModalOpen(false)
      setDeleteModal(false)
    }
  }, [profile.loading])

  return (
    <div className={styles.section}>
      <div>
        <SectionHeader
          onAdd={canChange ? openAdd : undefined}
          title={pt.awardsTitle}
        />
      </div>
      {localAwards.map((award: AwardsItem, index: number) => {
        return (
          <SectionItem key={index}>
            <ItemHeader
              title={award.title}
              icon={EmojiEvents}
              onEdit={
                canChange
                  ? () => {
                      openEdit(award.id)
                    }
                  : undefined
              }
              onDelete={
                canChange
                  ? () => {
                      setDeleteModal(index)
                    }
                  : undefined
              }
            />
            <div className={styles.content}>
              {(award.year || award.month) && (
                <ItemTextWithIcon
                  primaryText={pt.awardDateYear}
                  secondaryText={`${award.year ? award.year : ''}`}
                  icon={Update}
                  withColon
                />
              )}
              {award.description && (
                <p className={styles.textContainer}>
                  {nl2br(award.description)}
                </p>
              )}
            </div>
          </SectionItem>
        )
      })}
      <ShowMore
        items={awards}
        showMoreIndex={showMoreIndex}
        setShowMoreIndex={setShowMoreIndex}
      />
      <Modal
        isOpen={modalOpen}
        onAfterClose={() => {
          setModalOpen(false)
        }}
        ariaHideApp={false}
        title={`${formatMessage({
          id: modalMode === 'Add' ? pt.addAward : pt.editAward,
        })}`}
        icon={EmojiEvents}
        theme="desktopFlex"
        Footer={
          <SubmitFooter
            onSubmit={formik.handleSubmit}
            onCancel={() => {
              setModalOpen(false)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <div className={styles.modalContent}>
          <Title
            text={pt.awardsTitle}
            theme="modal"
            className={classnames(styles.firstTitle, styles.firstTitleStrong)}
          />
          <TextField
            name="title"
            onChange={formik.handleChange}
            value={formik.values.title}
            error={formik.errors.title}
            placeholder={pt.placeholderAwardTitle}
            theme="modal"
          />
          <Hr theme="modalSmall" />
          <Title
            text={pt.awardDate}
            theme="modal"
            className={classnames(styles.title, styles.titleStrong)}
          />
          <div className={styles.dropdownWrap}>
            <Dropdown
              label={pt.awardDateYear}
              name="year"
              items={years}
              setValue={formik.setFieldValue}
              value={formik.values.year}
              theme="white"
              error={
                formik.submitCount && formik.errors.year
                  ? formik.errors.year
                  : undefined
              }
            />
          </div>
          <Hr theme="modal" />
          <Title
            text={pt.awardDescription}
            theme="modal"
            className={classnames(styles.title, styles.titleStrong)}
          />
          <TextArea
            name="description"
            value={formik.values.description}
            error={
              formik.touched.description || formik.values.description !== ''
                ? formik.errors.description
                : undefined
            }
            onChange={formik.handleChange}
            placeholderText={pt.placeholderAwardDescription}
            theme="modal"
            maximumCharactersNumber={1000}
            type="letterCalculator"
          />
        </div>
      </Modal>
      <DeleteModal
        deleteAction={deleteAward}
        isOpen={!!deleteModal || deleteModal === 0}
        setOpen={setDeleteModal}
        text={`${formatMessage({id: pt.deletePartOne})}${
          localAwards?.find((_, i) => i === deleteModal)?.title || ''
        }${formatMessage({id: pt.deletePartTwo})}`}
        id={localAwards?.find((_, i) => i === deleteModal)?.id || ''}
      />
    </div>
  )
}
