import {FC} from 'react'
import {Link} from 'react-router-dom'
import {AppRoute, SidebarTranslations as st} from 'Services/I18n/Constants'

import {FormattedMessage} from 'react-intl'
import {getRoute} from 'Services/I18n/Utils'

export const SeekerDashboardLinks: FC = () => {
  return (
    <>
      <h2>
        <FormattedMessage id={st.dashboard} />
      </h2>
      <ul>
        <li>
          <Link to={getRoute(AppRoute.CandidateDashboard)}>
            <FormattedMessage id={st.dashboard} />
          </Link>
        </li>
        <li>
          <Link to={getRoute(AppRoute.AllIncomingRequests)}>
            <FormattedMessage id={st.allRequests} />
          </Link>
        </li>
      </ul>
    </>
  )
}
