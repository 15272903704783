import {all, call, put, takeLatest} from 'redux-saga/effects'

import API from 'Services/API'
import {AppRoute} from 'Services/I18n/Constants'
import * as type from 'Store/types'
import {
  ActionType,
  RjbProfileStatus,
  SavePartialStepBenefitsEducationType,
  SavePartialStepPreference,
  SavePartialStepWorkExperience,
  SaveStepBenefitsEducationType,
  SaveStepPreference,
  SaveStepUploadCvType,
  SaveStepWorkExperience,
  SeekerOnboardingStatus,
} from 'Interfaces'
import {push} from 'connected-react-router'
import {getRoute} from 'Services/I18n/Utils'

const getOnboardingStep = (onboardingStatus: SeekerOnboardingStatus) => {
  if (onboardingStatus === SeekerOnboardingStatus.UPLOADCV) {
    return 1
  } else if (onboardingStatus === SeekerOnboardingStatus.JOBPREFERENCE) {
    return 2
  } else if (onboardingStatus === SeekerOnboardingStatus.WORKEXPERIENCE) {
    return 3
  } else if (onboardingStatus === SeekerOnboardingStatus.BENEFITSEDUCATIONS) {
    return 4
  } else return 0
}

function* getOnboarding() {
  const {response, error} = yield call(API.get, '/onboarding')
  if (response) {
    if (
      response.data.rjbProfileStatus === RjbProfileStatus.DRAFT &&
      response.data.onboardingStatus !== SeekerOnboardingStatus.FINISHED
    ) {
      yield put({
        type: type.onboarding.succeeded,
        payload: {
          ...response.data,
          onboardingStep: getOnboardingStep(response.data.onboardingStatus),
        },
      })
    } else if (!response.data.contactDataSubmitted) {
      yield put(push(getRoute(AppRoute.ContactData)))
    } else {
      yield put(push(getRoute(AppRoute.CandidateDashboard)))
    }
  } else yield put({type: type.onboarding.failed, payload: error})
}

function* saveStepUploadCv(action: ActionType<SaveStepUploadCvType>) {
  const {response, error} = yield call(
    API.post,
    '/onboarding/step-upload-cv',
    action.payload
  )
  if (response) {
    yield put({
      type: type.stepUploadCv.succeeded,
      payload: {...response.data, onboardingStep: 2},
    })
  } else yield put({type: type.stepUploadCv.failed, payload: error})
}

function* saveStepJobPreferences(action: ActionType<SaveStepPreference>) {
  const {response, error} = yield call(
    API.post,
    '/onboarding/step-job-preference',
    action.payload?.values
  )
  if (response) {
    yield put({
      type: type.stepJobPreferences.succeeded,
      payload: {...response.data, onboardingStep: action.payload?.step},
    })
  } else yield put({type: type.stepJobPreferences.failed, payload: error})
}

function* savePartialStepJobPreferences(
  action: ActionType<SavePartialStepPreference>
) {
  const {response, error} = yield call(
    API.post,
    '/onboarding/save-partial-step-job-preference',
    action.payload
  )
  if (response) {
    yield put({
      type: type.stepPartialJobPreferences.succeeded,
      payload: {...response.data, onboardingStep: 1},
    })
  } else
    yield put({type: type.stepPartialJobPreferences.failed, payload: error})
}

function* saveStepWorkExperience(action: ActionType<SaveStepWorkExperience>) {
  const {response, error} = yield call(
    API.post,
    '/onboarding/save-step-work-experience',
    action.payload?.values
  )
  if (response) {
    yield put({
      type: type.stepWorkExperience.succeeded,
      payload: {...response.data, onboardingStep: action.payload?.step},
    })
  } else yield put({type: type.stepWorkExperience.failed, payload: error})
}

function* savePartialStepWorkExperience(
  action: ActionType<SavePartialStepWorkExperience>
) {
  const {response, error} = yield call(
    API.post,
    '/onboarding/save-partial-step-work-experience',
    action.payload
  )
  if (response) {
    yield put({
      type: type.stepPartialWorkExperience.succeeded,
      payload: {...response.data, onboardingStep: 2},
    })
  } else
    yield put({type: type.stepPartialWorkExperience.failed, payload: error})
}

function* saveStepBenefits(action: ActionType<SaveStepBenefitsEducationType>) {
  const {response, error} = yield call(
    API.post,
    '/onboarding/save-step-benefits-education',
    action.payload?.values
  )
  if (response) {
    yield put({
      type: type.stepBenefitsEducation.succeeded,
      payload: {...response.data, onboardingStep: action.payload?.step},
    })
    yield put({
      type: type.auth.rjbProfileStatus.update,
      payload: response.data.rjbProfileStatus,
    })
    if (action.payload?.step === 4) {
      yield put(push(getRoute(AppRoute.ContactData)))
    }
  } else yield put({type: type.stepBenefitsEducation.failed, payload: error})
}

function* savePartialStepBenefits(
  action: ActionType<SavePartialStepBenefitsEducationType>
) {
  const {response, error} = yield call(
    API.post,
    '/onboarding/save-partial-step-benefits-education',
    action.payload
  )
  if (response) {
    yield put({
      type: type.stepPartialBenefitsEducation.succeeded,
      payload: {...response.data, onboardingStep: 3},
    })
  } else
    yield put({type: type.stepPartialBenefitsEducation.failed, payload: error})
}

export default function* OnboardingSaga(): Generator {
  yield all([
    takeLatest(type.onboarding.requested, getOnboarding),
    takeLatest(type.stepUploadCv.requested, saveStepUploadCv),
    takeLatest(type.stepJobPreferences.requested, saveStepJobPreferences),
    takeLatest(
      type.stepPartialJobPreferences.requested,
      savePartialStepJobPreferences
    ),
    takeLatest(type.stepWorkExperience.requested, saveStepWorkExperience),
    takeLatest(
      type.stepPartialWorkExperience.requested,
      savePartialStepWorkExperience
    ),
    takeLatest(type.stepBenefitsEducation.requested, saveStepBenefits),
    takeLatest(
      type.stepPartialBenefitsEducation.requested,
      savePartialStepBenefits
    ),
  ])
}
