import classNames from 'classnames'
import {FC} from 'react'

import styles from './ScoreTableItem.module.scss'

interface ScoreTableItemProps {
  number?: number
  activeItem?: number
  passingValue?: number
}

export const ScoreTableItem: FC<ScoreTableItemProps> = ({
  number,
  activeItem,
  passingValue,
}) => {
  return (
    <div
      className={classNames(styles.scoreTableItemContainer, {
        [styles.borderedScoreTableItem]: activeItem,
        [styles.activeGreenItem]:
          activeItem &&
          passingValue &&
          activeItem === number &&
          activeItem >= passingValue,
        [styles.activeRedItem]:
          activeItem &&
          passingValue &&
          activeItem === number &&
          activeItem <= passingValue,
        [styles.passingFields]:
          number &&
          passingValue &&
          number &&
          number !== activeItem &&
          number >= passingValue,
      })}
    >
      <p>{activeItem && activeItem === number && number}</p>
      <p>{!passingValue && number}</p>
    </div>
  )
}
