import {Password} from 'Validations'
import * as Yup from 'yup'

const Validation = Yup.object({
  oldPassword: Password,
  newPassword: Password,
  confirmNewPassword: Password,
})

export default Validation
