export enum SidebarTranslations {
  home = 'sidebar.home',
  dashboard = 'sidebar.dashboard',
  allRequests = 'sidebar.allRequests',
  myProfile = 'sidebar.myProfile',
  publicProfile = 'sidebar.publicProfile',
  myCv = 'sidebar.myCv',
  assessments = 'sidebar.assessments',
  calendar = 'sidebar.calendar',
  messageCenter = 'sidebar.messageCenter',
  careerAdvice = 'sidebar.careerAdvice',
  companies = 'sidebar.companies',
  faq = 'sidebar.faq',
  logout = 'sidebar.logout',
  contactRequests = 'sidebar.contactRequests',
  videoInterviews = 'sidebar.videoInterviews',
  offers = 'sidebar.offers',
  placements = 'sidebar.placements',
  candidates = 'sidebar.candidates',
  search = 'sidebar.search',
  candidateSearch = 'sidebar.candidateSearch',
  projects = 'sidebar.projects',
  availableImmediately = 'sidebar.availableImmediately',
  cvSubscriptions = 'sidebar.cvSubscriptions',
  companyProfile = 'sidebar.companyProfile',
  advice = 'sidebar.advice',
  editCv = 'sidebar.editCv',
  editPublicProfile = 'sidebar.editPublicProfile',
  continueToTests = 'sidebar.continueToTests',
  profileStatus = 'sidebar.profileStatus',
  waitingForApproval = 'sidebar.waitingForApproval',
  waitingForApprovalMessage = 'sidebar.waitingForApprovalMessage',
  privacyControl = 'sidebar.privacyControl',
  privacyControlMessage = 'sidebar.privacyControlMessage',
  careerProfile = 'sidebar.careerProfile',
  careerProfileMessage = 'sidebar.careerProfileMessage',
  candidateProfile = 'sidebar.candidateProfile',
  privacyPolicy = 'sidebar.privacyPolicy',
  contactData = 'sidebar.contactData',
  editPersonalData = 'sidebar.editPersonalData',
  changeEmailAddress = 'sidebar.changeEmailAddress',
  changePassword = 'sidebar.changePassword',
  confirmHiringChoice = 'sidebar.confirmHiringChoice',
  helpfulHint = 'sidebar.helpfulHint',
  myOffers = 'sidebar.myOffers',
  selectCandidate = 'sidebar.selectCandidate',
  rate = 'sidebar.rate',
  viewCandidateProfile = 'sidebar.viewCandidateProfile',
  viewCandidatesAssessments = 'sidebar.viewCandidatesAssessments',
  sendMessage = 'sidebar.sendMessage',
  linkInterviewInvitation = 'sidebar.linkInterviewInvitation',
  sendOffer = 'sidebar.sendOffer',
}
