import {FC} from 'react'
import CheckIcon from '@mui/icons-material/Check'
import WarningIcon from '@mui/icons-material/Warning'
import classNames from 'classnames'
import styles from './TooltipElement.module.scss'
import {FormattedMessage} from 'react-intl'

export interface TooltipElementProps {
  conditionCheck: boolean | undefined
  elementText: string
}

export const TooltipElement: FC<TooltipElementProps> = ({
  conditionCheck,
  elementText,
}) => {
  return (
    <div
      className={classNames(
        styles.completenessElement,
        conditionCheck ? styles.completenessDone : styles.completenessNotDone
      )}
    >
      {conditionCheck ? (
        <CheckIcon classes={{root: styles.iconStyle}} />
      ) : (
        <WarningIcon classes={{root: styles.iconStyle}} />
      )}
      <span className={styles.completenessElementText}>
        <FormattedMessage id={elementText} />
      </span>
    </div>
  )
}
