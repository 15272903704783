export const emailchangeverification = {
  'emailchangeverification.successTitle':
    'Vaš elektronski naslov je bil uspešno spremenjen',
  'emailchangeverification.successLinkText': 'Nazaj na Mojo Preferenco',

  'emailchangeverification.pending': 'Verifikacija poteka...',
  'emailchangeverification.pleaseWaitText':
    'Prosimo počakajte trenutek. Samodejno boste preusmerjeni.',

  'emailchangeverification.verificationFailed':
    'Sprememba elektronskega naslova žal ni bila uspešna.',
  'emailchangeverification.verificationFailedDescription':
    'Prijavite se v svoj račun in poskusite ponovno.',
}
