import React from 'react'
import styles from './Onboarding.module.scss'
import classnames from 'classnames'
import {FormattedMessage} from 'react-intl'
import {OnboardingTranslations as ot} from 'Services/I18n/Constants'

interface iStepCircleProps {
  step: number
  total: number
  theme?: string
}

export const StepCircles: React.FC<iStepCircleProps> = ({
  step,
  total,
  theme = 'regular',
}) => {
  return (
    <div
      className={classnames(
        styles.cicleSteps,
        styles[theme] ? styles[theme] : ''
      )}
    >
      <div className={styles.circles}>
        {Array.from({length: total}, (_, i) => (
          <div
            key={i}
            className={classnames(styles.circle, {[styles.filled]: step > i})}
          />
        ))}
      </div>
      <div className={styles.legend}>
        <span className={styles.brackets}>(</span>
        <span className={styles.nonBrackets}>
          <FormattedMessage id={ot.step} />{' '}
        </span>
        {step}
        <span className={styles.nonBrackets}>
          {' '}
          <FormattedMessage id={ot.of} />{' '}
        </span>
        <span className={styles.brackets}>/</span>
        {total}
        <span className={styles.brackets}>)</span>
      </div>
    </div>
  )
}
