import {loadMoreMessages} from 'Store/Actions/MessageCenterActions'
import {connect} from 'react-redux'
import {AppDispatch} from 'Store'
import {
  selectConversation,
  getMessageRecipients,
  createMessageSocket,
  createConversationSocket,
} from 'Store/Actions'
import {RootState} from 'Store/Reducers'
import {MessagesSection} from './MessagesSection'
import {
  ConversationType,
  CreateMessageType,
  CreateConversationType,
  LoadMoreMessagesType,
} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  conversation: state.messageCenter.openedConversation,
  messageRecipients: state.messageCenter.messageRecipients,
  conversations: state.messageCenter.data,
  openedConversation: state.messageCenter.openedConversation,
  auth: state.auth,
  loadMoreMessagesUrl: state.messageCenter.loadMoreMessages,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  createConversation: (data: CreateConversationType) =>
    dispatch(createConversationSocket(data)),
  createMessage: (data: CreateMessageType) =>
    dispatch(createMessageSocket(data)),
  getMessageRecipients: () => dispatch(getMessageRecipients()),
  selectConversation: (conversation?: ConversationType) =>
    dispatch(selectConversation(conversation)),
  loadMoreMessages: (data: LoadMoreMessagesType) =>
    dispatch(loadMoreMessages(data)),
})

export const MessagesSectionContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessagesSection)
