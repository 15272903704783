export enum CompanyEmploymentInformationTranslations {
  employmentInformationTitle = 'companyEmploymentInformation.employmentInformationTitle',

  modalEmploymentInformationTitle = 'companyEmploymentInformation.modalEmploymentInformationTitle',
  whatDoWeExpectTitle = 'companyEmploymentInformation.whatDoWeExpectTitle',
  whatDoWeOfferTitle = 'companyEmploymentInformation.whatDoWeOfferTitle',
  customEmploymentInformationTitle = 'companyEmploymentInformation.customEmploymentInformationTitle',

  modalWhatDoWeExpectTitle = 'companyEmploymentInformation.modalWhatDoWeExpectTitle',
  modalWhatDoWeExpectPlaceholderText = 'companyEmploymentInformation.modalWhatDoWeExpectPlaceholderText',

  modalWhatDoWeOfferTitle = 'companyEmploymentInformation.modalWhatDoWeOfferTitle',
  modalWhatDoWeOfferPlaceholderText = 'companyEmploymentInformation.modalWhatDoWeOfferPlaceholderText',

  modalCustomEmploymentInformationTitle = 'companyEmploymentInformation.modalCustomEmploymentInformationTitle',
  modalCustomEmploymentInformationPlaceholderText = 'companyEmploymentInformation.modalCustomEmploymentInformationPlaceholderText',

  modalAddText = 'companyEmploymentInformation.modalAddText',
  modalEditText = 'companyEmploymentInformation.modalEditText',
}
