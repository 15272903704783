import {FC} from 'react'
import Breadcrumbs from 'Components/Breadcrumbs'
import {AuthStateType} from 'Interfaces'
import './TermsAndConditions.module.scss'
import {getRoute} from 'Services/I18n/Utils'
import {
  AppRoute,
  GeneralAssessmentsTranslations as gat,
} from 'Services/I18n/Constants'
import {DownloadAndPrintHeader} from 'Components/DownloadAndPrintHeader'
import {useIntl} from 'react-intl'
import {TermsAndConditionsTranslations as tct} from 'Services/I18n/Constants'

interface TermsAndConditionsProps {
  auth: AuthStateType
}

export const TermsAndConditions: FC<TermsAndConditionsProps> = ({auth}) => {
  const {formatMessage} = useIntl()

  const termsHtml = formatMessage({id: tct.termsAndConditionsHtml})
    .replaceAll('#privacy-policy', getRoute(AppRoute.PrivacyPolicy))
    .replaceAll('#terms-and-conditions', getRoute(AppRoute.TermsAndConditions))
    .replaceAll(
      '#general-terms-and-conditions',
      getRoute(AppRoute.GeneralTermsAndConditions)
    )
    .replaceAll(
      '#data-protection-agreement',
      getRoute(AppRoute.DataProtectionAgreement)
    )

  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: getRoute(AppRoute.HomePageCandidate)},
    {name: gat.termsOfUse},
  ]
  return (
    <div className="termsAndConditionsContainer">
      <DownloadAndPrintHeader
        downloadLink={
          process.env.PUBLIC_URL + '/files/pogoji_uporabe_31.01.2023.pdf'
        }
      />
      {auth.data && (
        <h1 className="termsAndConditionsTermsAndConditionsTitle">
          Pravila in pogoji uporabe Preferenca.si
        </h1>
      )}
      {!auth.data && (
        <div className="termsAndConditionsContainerUp">
          <div className="termsAndConditionsContainerUpTitle">
            <div className="termsAndConditionsTitleWrapper">
              <h1 className="termsAndConditionsTermsAndConditionsTitle">
                Pravila in pogoji uporabe Preferenca.si
              </h1>
            </div>
          </div>
        </div>
      )}
      <div className="termsAndConditionsContainerMiddle">
        <Breadcrumbs
          className="termsAndConditionsSubNavigation"
          breadcrumbs={breadcrumbsArray}
        />
      </div>

      <div
        dangerouslySetInnerHTML={{
          __html: termsHtml,
        }}
      />
    </div>
  )
}
