export const companyFaq = {
  'companyFaq.faqTitle': 'FAQ',

  'companyFaq.basicInformation': 'Osnovne informacije',

  'companyFaq.moreText': 'Več',
  'companyFaq.lessText': 'Manj',

  'companyFaq.modalFaqFullTitle': 'POGOSTO ZASTAVLJENA VPRAŠANJA',
  'companyFaq.modalAddQuestion': 'Dodajte vprašanje',

  'companyFaq.enterQuestionPlaceholderText': 'Vnesite vprašanje...',

  'companyFaq.deleteText': 'Izbrišite',

  'companyFaq.showMoreText': 'Prikaži več ...',
  'companyFaq.showLessText': 'Prikaži manj ...',

  'companyFaq.modalAddText': 'Dodajte',
  'companyFaq.modalEditText': 'Urejanje',

  'companyFaq.questionForCandidate1':
    'Katere oblike življenjepisov podpira platforma?',
  'companyFaq.questionForCandidate2':
    'Izpolnil sem profil, vendar nisem dosegel 100 %, kaj to pomeni?',
  'companyFaq.questionForCandidate3':
    'Ali je moj profil viden trenutnemu delodajalcu?',
  'companyFaq.questionForCandidate4':
    'Kaj je razlika med kariernim profilom in življenjepisom/CV profilom?',
  'companyFaq.questionForCandidate5':
    'Zakaj moram rešiti psihometrično in kognitivno testiranje?',
  'companyFaq.questionForCandidate6':
    'Katere podatke naj vsebuje rubrika Kratek opis?',
  'companyFaq.questionForCandidate7': 'Zakaj ne morem urejati svojega profila?',
  'companyFaq.questionForCandidate8':
    'Koliko časa specialist HR pregleduje profil?',

  'companyFaq.answerForCandidate1':
    'Platforma podpira naslednje oblike datotek: .doc, .docx, .pdf (največja velikost je 5 MB).',
  'companyFaq.answerForCandidate2':
    'Odstotek izpolnjenega profila vidite na svojem namizju, če se z miško postavite nanj. Rdeče označeni razdelki so manjkajoči in jih je treba dopolniti.',
  'companyFaq.answerForCandidate3':
    'Ne, trenutni delodajalec nima dostopa do informacij na vašem profilu.',
  'companyFaq.answerForCandidate4':
    'Vaš karierni profil je anonimen in vsebuje zgolj informacije, na podlagi katerih vas delodajalec ne more identificirati. Vaš življenjepis/CV profil pa bo viden delodajalcem ob vašem dovoljenju. Ob prošnji za stik delodajalca se lahko odločite, ali boste prošnjo za stik odobrili in tako razkrili več informacij o sebi ali ne.',
  'companyFaq.answerForCandidate5':
    'To je morda najdaljši korak ustvarjanja profila, vendar pomemben, saj osebnostne lastnosti in socialne kompetence postajajo pomemben dejavnik pri zaposlovanju. Rezultati psihometričnega vprašalnika omogočajo vpogled v lastne osebnostne karakteristike in kompetence, ki zajemajo večino delovnih aktivnosti. S kognitivnim testiranjem pa izvemo več o hitrosti in učinkovitosti učenja novih spretnosti ter o sposobnosti analiziranja in integracije informacij.',
  'companyFaq.answerForCandidate6':
    'Karierni profil: Tukaj se predstavite delodajalcem, vendar ne z informacijami, na podlagi katerih bi vas lahko prepoznali.Življenjepis/CV profil: Tukaj lahko vnesete poljubne informacije, saj bo ta opis viden le delodajalcem, ki jim boste odobrili stik.',
  'companyFaq.answerForCandidate7':
    'Profila ne morete urejati, ko svoj profil potrdite, saj ga s tem pošljete v pregled specialistom HR. Šele po pregledu bo profil za urejanje ponovno dostopen tudi vam.',
  'companyFaq.answerForCandidate8':
    'Trudimo se, da je ta proces pregleda opravljen čim hitreje. Predvidoma je pregled opravljen v 1–2 delovnih dni. ',

  'companyFaq.questionForEmployer1':
    'Kako najučinkoviteje pristopiti k talentom/kandidatom?',
  'companyFaq.answerForEmployer1':
    'Preferenca.si predstavlja obratno smer zaposlovanja, kjer se kot podjetje prijavljate na profile predstavljenih kandidatov. Pri tem je pomembno, da poiščete kandidate, ki ustrezajo vašim zahtevam glede izobrazbe, delovnih izkušenj, tehničnih znanj in spretnosti. Te informacije bodo na voljo že pri prvem iskanju, kjer boste lahko videli anonimiziran seznam kandidatov in njihove kratke opise. Če boste o določenem kandidatu želeli pridobiti več informacij, mu boste poslali prošnjo za razkritje celotnega profila. Pred tem pa priporočamo, da temeljito izdelate lasten profil na portalu in talentom ponudite dovolj informacij o podjetju, viziji in delu (preberite Kako postaviti lastno blagovno znamko delodajalca ter profil na sami platformi, da pritegnete prave talente). Če bo kandidat izkazal interes za delo, boste prejeli vpogled v kandidatov življenjepis ter njegove želje in zahteve glede prihodnje zaposlitve. Po pregledu teh informacij lahko stopite v stik s kandidatom neposredno preko portala tako, da ga povabite na video razgovor in vzpostavite osebni stik. Če bo razgovor uspešen, lahko kandidatu pošljete ponudbo in se nato dogovorite o sklepanju pogodbe o zaposlitvi.',
  'companyFaq.questionForEmployer2':
    'Kako postaviti lastno blagovno znamko delodajalca ter profil na sami platformi, da pritegnete prave talente?',
  'companyFaq.answerForEmployer2PartOne':
    'Postavljanje blagovne znamke delodajalca je vedno bolj pomembno, saj predstavlja način trženja vašega podjetja želenim iskalcem zaposlitve. To lahko storite tako, da poudarite edinstvene bonitete in kulturne značilnosti podjetja, s katerimi si prizadevate vzbuditi zanimanje za bodoče iskalce, in se predstavite kot zaželeno delovno okolje. Osredotočite se z opredelitvijo esence podjetja, ki vključuje vašo vizijo in cilje, in poudarite tisto, zaradi česar ste edinstveni. S tem sporočate, da ste ugledni delodajalec in imate vzpostavljeno odlično delovno okolje, kar pripomore k lažjemu zaposlovanju in zadrževanju trenutnih zaposlenih.',
  'companyFaq.answerForEmployer2PartTwo':
    'Pri ustvarjanju profila na Preferenci vam pomaga registracijski vprašalnik. Najprej napišete kratek opis, kjer poudarite osnove značilnosti podjetja. Že pri tem delu poudarite tisto, s čimer izstopate. Ne pozabite na logotip in naslovno sliko, ki predstavljata vašo blagovno znamko. Nato imate priložnost, da talentom predstavite, kakšne kandidate iščete, predvsem katera znanja in sposobnosti so vam najbolj pomembna. Ob tem pa poudarite, katere ugodnosti ponujate svojim zaposlenim; to so lahko prilagodljivi delovni čas, delo od doma, varstvo otrok, dodatna izobraževanja, sadje in kava na delovnem mestu in podobno. Profil lahko obogatite z dodajanjem slik in video posnetkov, ki podrobneje predstavijo podobo podjetja.',
  'companyFaq.questionForEmployer3':
    'Kateri kandidati bi se najbolje ujemali z vami in vašimi zahtevami?',
  'companyFaq.answerForEmployer3':
    'Obstaja veliko nasvetov o tem, kako oceniti mehke veščine v procesu izbire primernega kandidata. Za vas smo pripravili seznam lastnosti, ki jih je treba iskati pri kandidatih, in jih strnili na pet bistvenih:',
  'companyFaq.answerForEmployer3FirstPartTitle': 'Ekipno delo ',
  'companyFaq.answerForEmployer3FirstPart':
    '– večina delovnih mest zahteva določeno stopnjo sodelovanja z drugimi ljudmi. Tudi delo, ki je pogosto videti precej individualno, kot je programiranje, lahko vključuje prispevke drugih. Pomembno je torej, da se osredotočite na kandidate, ki lahko dobro sodelujejo z drugimi.',
  'companyFaq.answerForEmployer3SecondPartTitle': 'Pripravljenost na učenje ',
  'companyFaq.answerForEmployer3SecondPart':
    '– vseživljenjsko učenje je danes nujno. Nova tehnologija in znanje se pojavljata vsak trenutek, organizacije in sistemi pa se spreminjajo. Pripravljenost in ne nazadnje tudi sposobnost učenja sta zelo pomembni lastnosti zaposlenih – ne le za učenje novih veščin, ampak tudi za rast in razvoj kot strokovnjak in kot oseba.',
  'companyFaq.answerForEmployer3ThirdPartTitle': 'Komunikacija ',
  'companyFaq.answerForEmployer3ThirdPart':
    '– ne pomeni, da mora biti vsak zaposleni spreten z besedami ali vešč javnega nastopanja, pomeni pa, da mora vsak zaposleni prenesti svoje sporočilo v ustnem ali pisnem načinu in obenem biti sposoben razumeti pomen drugih – zlasti s postavljanjem pravih vprašanj. Težave v komunikaciji namreč lahko drastično vplivajo na delovno uspešnost.',
  'companyFaq.answerForEmployer3FourthPartTitle': 'Samomotivacija ',
  'companyFaq.answerForEmployer3FourthPart':
    'pomeni, da je zaposlenim všeč tisto, kar delajo, in to tako, da želijo opraviti kakovostno delo ne glede na zunanjo nagrado. Ti zaposleni so navadno tudi samoiniciativni in samostojni pri delu, sposobni so prevzeti odgovornost za opravljene naloge in pri njih vztrajati kljub oviram.',
  'companyFaq.answerForEmployer3FifthPartTitle': 'Ustrezanje kulturi podjetja ',
  'companyFaq.answerForEmployer3FifthPart':
    '– v ospredju je predvsem to, kako zaposleni razume in sprejme delovno mesto in način dela, od postavitve delovnega mesta do kodeksa oblačenja. Kulturna prilagoditev se lahko spreminja, pomembno pa je predvsem, da vsi zaposleni poznajo določene lastnosti in vrednote podjetja.',
  'companyFaq.answerForEmployer3FifthPart2':
    'To je zgolj nekaj kompetenc in lastnosti, ki smo jih poudarili, vsako podjetje pa ima svoje zahteve in prioritete. Za ta namen Preferenca.si omogoča pregled delovnih kompetenc in splošnih kognitivnih sposobnosti vsakega kandidata. Rezultati, ki so pridobljeni z uporabo standardiziranih in normiranih psihometričnih vprašalnikov in kognitivnih testov, vam kot delodajalcem nudijo dragocene informacije o potencialnem kadru, ki jih ne morete pridobiti niti iz življenjepisov niti iz razgovorov. Uporaba psiholoških testiranj je pri selekciji in pri razvoju kadra vedno bolj pogosta, saj predstavlja enega najboljših napovednikov delovne uspešnosti zaposlenih.',
  'companyFaq.questionForEmployer4':
    'S katerih področij so kandidati na Preferenca.si?',
  'companyFaq.answerForEmployer4':
    'Trenutno so na platformi zbrani kandidati s področja stojništva, elektrotehnike in računalništva. V prihodnje pa bodo dodani tudi kandidati iz drugih področij dela.',
  'companyFaq.questionForEmployer5':
    'Na kakšen način so kandidati na Preferenci preverjeni?',
  'companyFaq.answerForEmployer5':
    'Kandidati so pregledani s strani HR specialistov in v kolikor le ti ustrezajo danim pogojem, so sprejeti na platformo.',
  'companyFaq.questionForEmployer6':
    'Kaj se zgodi v primeru, da se kandidat istočasno dogovarja z več delodajalci?',
  'companyFaq.answerForEmployer6':
    'Kandidat se lahko do potrditve ponudbe dogovarja z več delodajalci. Po potrditvi zaposlitve pa kanidat ni več viden/dostopen ostalim.',
  'companyFaq.questionForEmployer7': 'Ali se video klici snemajo?',
  'companyFaq.answerForEmployer7':
    'Ne, video klici se ne snemajo in ne shranjujejo na platformi.',
  'companyFaq.questionForEmployer8':
    'Kaj lahko storim če informacijski sistem ne podpira video klica preko platforme?',
  'companyFaq.answerForEmployer8':
    'Puščamo vam možnost, da video intervjuje opravite preko svoje interne platforme, ki jo uporabljate v podjetju in kasneje ta korak in vse naslednje zabeležite na Preferenca.si. ',
  'companyFaq.questionForEmployer9':
    'Ali se lahko video klicu pridruži tudi oseba, ki ni uporabnik platforme Preferenca.si?',
  'companyFaq.answerForEmployer9':
    'Seveda, povezavo video klica lahko delite z ostalimi udeleženci. 10 minut pred samim video razgovorom prejmete opomnik in povezavo lahko delite.',
  'companyFaq.questionForEmployer10':
    'Zakaj kandidat ne sprejme prošnje za stik?',
  'companyFaq.answerForEmployer10':
    'Kandidat se lahko prostovoljno odloča katere prošnje za stik bo sprejel. Zato predlagamo, da svoj profil podjetja skrbno in premišljeno uredite.',
  'companyFaq.questionForEmployer11':
    'Kaj vse naj napišem pod profil podjetja?',
  'companyFaq.answerForEmployer11':
    'Pomembno je da se predstavite kot podjetje in se osredotčite predvsem na nagovor vaših potencialnih kandidatov. Lahko izpostavite edinstvene bonitete vašega podjetja, dodate ugodnosti, utrinke, informacije o zaposlitvi, pretekle projekte itd.',
  'companyFaq.questionForEmployer12':
    'Kandidat je sprejel prošnjo za stik, vendar se je prenehal odzivati',
  'companyFaq.answerForEmployer12':
    'Kandidata lahko kontaktirate tudi preko centra za sporočila. V primeru, da tudi tam ni odziva, se obrnite na naše HR specialiste.',
  'companyFaq.questionForEmployer13': 'Kaj pomenijo posamezni statusi?',
  'companyFaq.answerForEmployer13Title': '·     sprejeto',
  'companyFaq.answerForEmployer13': ', če kandidat sprejme prošnjo za stik,',
  'companyFaq.answerForEmployer13SecondPartTitle': '·       na čakanju',
  'companyFaq.answerForEmployer13SecondPart':
    ', če kandidat prošnje za stik ni niti sprejel niti zavrnil,',
  'companyFaq.answerForEmployer13ThirdPartTitle': '·       zavrnjeno',
  'companyFaq.answerForEmployer13ThirdPart':
    ', če kandidatu očitno ne ustrezate in posledično ne želi deliti z vami življenjepisa,',
  'companyFaq.answerForEmployer13FourthPartTitle': '·       poteklo',
  'companyFaq.answerForEmployer13FourthPart':
    ', kadar je preteklo več kot 14 dni odkar ste kandidatu poslali prošnjo za stik (mu pa vseeno prošnjo za stik lahko pošljete še enkrat), in',
  'companyFaq.answerForEmployer13FifthPartTitle': '·       preklicano',
  'companyFaq.answerForEmployer13FifthPart':
    ', če se je kandidat vmes že zaposlil pri drugem podjetju in se posledično vsi zahtevki avtomatsko prekličejo.',
  'companyFaq.questionForEmployer14':
    'Koliko sodelavcev lahko istočasno uporablja platformo Preferenca.si?',
  'companyFaq.answerForEmployer14':
    'Platformo lahko uporablja več sodelavcev v istem podjetju, vsak s svojim uporabniškim računom.',
  'companyFaq.forCandidate': 'ZA KANDIDATA',
  'companyFaq.support': 'Podpora',
  'companyFaq.faq': 'Pogosto zastavljena vprašanja',
}
