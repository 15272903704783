import {FC, ReactNode, useEffect, useRef, useState} from 'react'
import styles from './SentRequestListItem.module.scss'
import {
  EmployerRequestsSearchParamsType,
  EmployerRequestsStateType,
  IncomingRequestType,
} from 'Interfaces'
import classNames from 'classnames'
import SeekerProfile from './SeekerProfile'
import RequestActions from './RequestActions'
import {ExpirationValue} from './RequestValues'
import {CombinedRequestStatues} from 'Components/IncomingRequests/Constants'
import {FormattedMessage} from 'react-intl'
import {
  employerRequestStatusTranslationMap,
  RequestType,
  requestTypeTranslationMap,
  typeColors,
} from './Constants'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'
import ModalInterviewInvitationEmp from 'Components/EmployerDashboard/InterviewInvitationListItem/ModalInterviewInvitationEmp'
import ModalContactRequestEmp from 'Components/EmployerDashboard/ContactRequestListItem/ModalContactRequestEmp'
import ModalPlacementRequestEmp from 'Components/EmployerDashboard/PlacementsListItem/ModalPlacementRequestEmp'
import ModalOfferDetailsEmp from 'Components/EmployerDashboard/OfferListItem/ModalOfferDetailsEmp'
import ModalEmployerCancelInterview from 'Components/EmployerDashboard/InterviewInvitationListItem/ModalEmployerCancelInterview'

const ItemDetail: FC<{
  label?: string
  value: ReactNode
  className?: string
}> = ({label, value, className}) => {
  return (
    <div className={`${styles.detailWrap} ${className}`}>
      {label && label !== edt.candidate && (
        <div className={styles.label}>
          <FormattedMessage id={label} />
        </div>
      )}

      {value}
    </div>
  )
}

// TODO candidate-dashboard: centralize request colors?
const statusColors = {
  [CombinedRequestStatues.AVAILABLE]: styles.greenText,
  [CombinedRequestStatues.UNAVAILABLE]: styles.pinkText,
  [CombinedRequestStatues.INTERVIEW_CONFIRMED]: styles.greenText,
  [CombinedRequestStatues.ACCEPTED]: styles.greenText,
  [CombinedRequestStatues.PENDING]: styles.purpleText,
  [CombinedRequestStatues.PENDING_EXPIRING]: styles.yellowText,
  [CombinedRequestStatues.NEW_TIME_PROPOSED]: styles.brownText,
  [CombinedRequestStatues.REJECTED]: styles.redCinnabarText,
  [CombinedRequestStatues.EXPIRED]: styles.blackText,
  [CombinedRequestStatues.COMPLETED]: styles.greenText,
  [CombinedRequestStatues.NEW_OFFER_PROPOSED]: styles.brownText,
  [CombinedRequestStatues.CANCELLED]: styles.redCinnabarText,
  [CombinedRequestStatues.NOT_ATTENDED]: styles.redCinnabarText,
  [CombinedRequestStatues.APPROVED]: styles.greenText,
  [CombinedRequestStatues.EXTENDED]: styles.greenText,

  [CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_PENDING]:
    styles.purpleText,
  [CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_REJECTED]:
    styles.red2Text,
  [CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_CONFIRMED]:
    styles.purpleText,
  [CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_PENDING]:
    styles.purpleText,
  [CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_REJECTED]:
    styles.red2Text,
  [CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_CONFIRMED]:
    styles.green2Text,
  [CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_PENDING]:
    styles.red2Text,
  [CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_REJECTED]:
    styles.red2Text,
  [CombinedRequestStatues.COMPANY_USER_REJECTED_SEEKER_CONFIRMED]:
    styles.red2Text,
}

type SentRequestListItemProps = {
  request: IncomingRequestType
  setSidebarClosed: (sidebarClosed: boolean) => void
  setSelectedRequestWidget: (request: IncomingRequestType) => void
  placements: EmployerRequestsStateType
  getEmployerPlacements: (params?: EmployerRequestsSearchParamsType) => void
  setIsModalOpen: (v: boolean) => void
}

export const SentRequestListItem: FC<SentRequestListItemProps> = ({
  request,
  setSidebarClosed,
  setSelectedRequestWidget,
  placements,
  getEmployerPlacements,
  setIsModalOpen,
}) => {
  const [modalDetails, setModalDetails] = useState<string | null>(null)
  const [isCandidateSelected, setIsCandidateSelected] = useState(false)
  const [cancelInterviewModal, setCancelInterviewModal] = useState(false)
  const itemRef = useRef<HTMLDivElement>(null)

  const onViewRequestDetails = () => {
    setModalDetails(request.type)
  }

  const setFocus = (value: boolean) => {
    if (!value && itemRef.current) {
      itemRef.current.focus()
    }
  }

  useEffect(() => {
    getEmployerPlacements({...placements.params})
  }, [])

  const placement = placements.searchResults.filter(
    (placement) => placement.id === request.id && placement
  )

  const isConfirmedByBoth =
    request.status ===
    CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_CONFIRMED
  return (
    <div
      ref={itemRef}
      onClick={() => {
        setSidebarClosed(false)
        setSelectedRequestWidget(request)
        setIsCandidateSelected(true)
      }}
      tabIndex={0}
      onBlur={() => {
        setIsCandidateSelected(false)
      }}
      className={classNames({
        [styles.selectedCandidateRow]: isCandidateSelected,
        [styles.greenBackground]: isConfirmedByBoth,
      })}
    >
      <div
        className={classNames(styles.requestItemWrap, 'requestItemWrap', {
          [styles.candidateRow]: !isCandidateSelected,
        })}
      >
        {/* REQUEST CANDIDATE */}
        <ItemDetail
          label={edt.candidate}
          value={
            <SeekerProfile request={request} setIsModalOpen={setIsModalOpen} />
          }
          className={styles.flexContainer}
        />

        {/* REQUEST TYPE */}
        <ItemDetail
          label={edt.contactRequestRequestType}
          value={
            <div
              onClick={(e) => {
                e.stopPropagation()
                onViewRequestDetails()
              }}
              className={classNames(styles.detailsValue, styles.requestType)}
              style={typeColors[request.type]}
            >
              <FormattedMessage id={requestTypeTranslationMap[request.type]} />
            </div>
          }
        />

        {/* REQUEST STATUS */}
        <ItemDetail
          label={edt.status}
          value={
            <div
              className={classNames(
                styles.statusValue,
                statusColors[request.status]
              )}
            >
              <FormattedMessage
                id={employerRequestStatusTranslationMap[request.status]}
              />
            </div>
          }
        />

        {/* REQUEST ACTIONS */}
        {request.expirationDate ? (
          <ItemDetail
            label={edt.expire}
            value={
              request.status !== 'approved' &&
              request.status !== 'rejected' && (
                <ExpirationValue date={request.expirationDate} />
              )
            }
          />
        ) : (
          <div />
        )}

        <ItemDetail
          className={classNames(styles.justifyEnd)}
          value={
            placement ? (
              <RequestActions request={request} placement={placement} />
            ) : (
              <RequestActions request={request} />
            )
          }
        />

        {/* DETAILS MODALS */}
        <ModalInterviewInvitationEmp
          request={request}
          isOpenInterviewInvitation={
            modalDetails === RequestType.INTERVIEW_REQUEST
          }
          setIsOpenInterviewInvitation={(value) => {
            setFocus(value)
            setModalDetails(value ? request.type : null)
          }}
          setCancelModal={setCancelInterviewModal}
        />
        <ModalContactRequestEmp
          request={request}
          isOpenContactRequest={modalDetails === RequestType.CONTACT_REQUEST}
          setIsOpenContactRequest={(value) => {
            setFocus(value)
            setModalDetails(value ? request.type : null)
          }}
        />
        <ModalPlacementRequestEmp
          request={request}
          isOpenPlacementModal={modalDetails === RequestType.PLACEMENT_REQUEST}
          setIsOpenPlacementModal={(value) => {
            setFocus(value)
            setModalDetails(value ? request.type : null)
          }}
        />
        <ModalOfferDetailsEmp
          request={request}
          isOpen={modalDetails === RequestType.OFFER_REQUEST}
          setIsOpen={(value) => {
            setFocus(value)
            setModalDetails(value ? request.type : null)
          }}
        />
        <ModalEmployerCancelInterview
          request={request}
          cancelModal={cancelInterviewModal}
          setCancelModal={setCancelInterviewModal}
          isOpenInterviewInvitation={
            modalDetails === RequestType.INTERVIEW_REQUEST
          }
          setIsOpenInterviewInvitation={(value) => {
            setModalDetails(value ? request.type : null)
          }}
        />
      </div>
    </div>
  )
}
