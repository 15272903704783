import {FC, useState} from 'react'
import styles from './ProgressBar.module.scss'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Tooltip from '@mui/material/Tooltip'
import {useWindowResize} from 'Hooks'

export interface ProgressBarType {
  progress: number
  tooltipBody: NonNullable<React.ReactNode>
  tooltipPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top'
  tooltipArrow?: boolean
}

const MobileCheck = ({
  width,
  children,
  handleTooltipClose,
  handleTooltipOpen,
}: any) =>
  width < 991 ? (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div className={styles.wrap} onClick={handleTooltipOpen}>
        {children}
      </div>
    </ClickAwayListener>
  ) : (
    <div className={styles.wrap}>{children}</div>
  )

export const ProgressBar: FC<ProgressBarType> = ({
  progress,
  tooltipBody,
  tooltipPlacement = 'right',
  tooltipArrow = true,
}: ProgressBarType) => {
  const progressPercentage = parseInt(`${progress * 100}`)

  const [width, setWidth] = useState(document.body.clientWidth)
  useWindowResize((newWidth) => setWidth(newWidth || 0))

  const [open, setOpen] = useState(false)

  const handleTooltipClose = () => {
    setOpen(false)
  }

  const handleTooltipOpen = () => {
    setOpen(true)
  }

  return (
    <MobileCheck
      handleTooltipClose={handleTooltipClose}
      handleTooltipOpen={handleTooltipOpen}
      width={width}
    >
      <Tooltip
        title={tooltipBody}
        placement={tooltipPlacement}
        arrow={tooltipArrow}
        classes={{popper: styles.tooltipStyle}}
        {...(width < 991
          ? {
              disableFocusListener: true,
              disableHoverListener: true,
              disableTouchListener: true,
              open: open,
              onClose: handleTooltipClose,
              PopperProps: {
                disablePortal: true,
              },
            }
          : null)}
      >
        <div className={styles.background}>
          <div
            className={styles.progressBackground}
            style={{
              width: `${progressPercentage}%`,
            }}
          ></div>
          <div className={styles.percent}>{`${progressPercentage}%`}</div>
        </div>
      </Tooltip>
    </MobileCheck>
  )
}
