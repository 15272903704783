import {FC, useMemo} from 'react'
// import TopCompaniesBanner from 'Components/TopCompaniesBanner'
import {PublicPagesHeader} from 'Components/PublicPagesHeader'
import {CandidateLandingText} from 'Components/CandidateLandingText'
import {HomeCallToActions} from 'Components/HomeCallToActions'
import {HomePageFooter} from 'Components/HomePage'
import {HomePageSteps} from 'Components/HomePageSteps'
import MaintenanceMessage from 'Components/MaintenanceMessage'
// import HomepageTestimonials from 'Components/HomepageTestimonials'
// import SocialNetworks from 'Components/SocialNetworks'
import styles from './HomePageCandidate.module.scss'

export const HomePageCandidate: FC = () => {
  const maintenanceMessage = useMemo(() => {
    if (process.env.REACT_APP_MAINTENANCE_MESSAGE === 'true') {
      return <MaintenanceMessage />
    } else return null
  }, [])

  return (
    <div>
      {maintenanceMessage}
      <div className={styles.main}>
        <PublicPagesHeader page="candidate" />
        <div className={styles.content}>
          <CandidateLandingText />
          {/* Removed temporary on Maria Korosec request */}
          {/* <SocialNetworks /> */}
          {/* <TopCompaniesBanner /> */}
          <HomePageSteps />
        </div>
        {/* Removed temporary on Maria Korosec request */}
        {/* <HomepageTestimonials /> */}
        <HomeCallToActions />
        <HomePageFooter />
      </div>
    </div>
  )
}
