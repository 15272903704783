import {FC, useState, useEffect, useMemo} from 'react'
import {useMediaQuery} from 'react-responsive'
import {useRouteMatch} from 'react-router-dom'
import {FormattedMessage} from 'react-intl'

import MainLayout from 'Layouts/MainLayout'
import {getRoute} from 'Services/I18n/Utils'
import Breadcrumbs from 'Components/Breadcrumbs'
import {MessagesSectionContainer} from 'Components/MessageCenter/MessagesSection'
import {AppRoute} from 'Services/I18n/Constants'
import MessageCenterHeader from 'Components/MessageCenter/Header'
import {
  MessageCenterTranslations as mct,
  SidebarTranslations as st,
} from 'Services/I18n/Constants'
import {useTranslation} from 'Hooks'
import MessageCenterConversations from 'Components/MessageCenter/Conversations'
import {MessagesSectionMobileFooter} from 'Components/MessageCenter/MessagesSection/MessagesSectionMobileFooter'
import {
  Candidate,
  EmployerCandidateProfileStateType,
  IncomingRequestSeekerType,
  PARTICIPANTS_TYPE,
} from 'Interfaces'
import {MessageCenterTab} from 'Components/MessageCenter/MessageCentarTab'
// import SidebarActions from 'Components/EmployerDashboard/Sidebar/SidebarActions'

import styles from './MessageCenter.module.scss'
// import {WidgetBarContainer} from 'Layouts/MainLayout/WidgetBar'
// import CandidateSearchSidebarContentContainer from 'Components/CandidateSearch/CandidateSearchSidebarContent'
import SidebarActions from 'Components/EmployerDashboard/Sidebar/SidebarActions'
import {WidgetBarContainer} from 'Layouts/MainLayout/WidgetBar'
import CandidateSearchSidebarContentContainer from 'Components/CandidateSearch/CandidateSearchSidebarContent'

type MessageCenterProps = {
  specificParticipiantUnreadCount?: number
  getSpecificParticipiantUnreadConversationsCount: (
    participantsType?: string
  ) => void
  resetMessageCenterData: () => void
  candidate: EmployerCandidateProfileStateType
  setCandidateBasicData: (candidate: any) => void
  candidateBasicData: Candidate | IncomingRequestSeekerType | null
  sidebarClosed: boolean
  setSidebarClosed: (sidebarClosed: boolean) => void
}

export const MessageCenter: FC<MessageCenterProps> = ({
  specificParticipiantUnreadCount,
  getSpecificParticipiantUnreadConversationsCount,
  resetMessageCenterData,
  setCandidateBasicData,
  candidate,
  candidateBasicData,
  sidebarClosed,
  setSidebarClosed,
}) => {
  const [showRecipientHeader, setShowRecipientHeader] = useState(false)
  const [modalOpen, setModalOpen] = useState(false)

  const {path} = useRouteMatch()
  const translation = useTranslation()
  const isMobile = useMediaQuery({
    query: '(max-width: 900px)',
  })
  useEffect(() => {
    setSidebarClosed(true)
  }, [])
  const isTSA = path === '/messages/tsa'
  const isEmployer = path === getRoute(AppRoute.CandidateMessages)

  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: getRoute(AppRoute.HomePageCandidate)},
    {
      name: translation[st.messageCenter],
      link: isEmployer
        ? getRoute(AppRoute.CandidateMessages)
        : getRoute(AppRoute.EmployerMessages),
    },
    {
      name: !isEmployer
        ? translation[mct.labelEmployers]
        : translation[mct.labelCandidates],
    },
  ]

  const participantsType = isTSA
    ? PARTICIPANTS_TYPE.TSA
    : isEmployer
    ? PARTICIPANTS_TYPE.SEEKER
    : PARTICIPANTS_TYPE.COMPANY_USER

  useEffect(() => {
    getSpecificParticipiantUnreadConversationsCount(participantsType)

    return () => {
      resetMessageCenterData()
    }
  }, [])

  useEffect(() => {
    setCandidateBasicData(candidate.data.profile)
  }, [candidate])

  const setSidebarContent = () => {
    return <CandidateSearchSidebarContentContainer isFull hasContactRequest />
  }

  const sideBar = useMemo(() => {
    if (!sidebarClosed) {
      return (
        <WidgetBarContainer
          sidebarContent={candidateBasicData && setSidebarContent()}
        />
      )
    } else return <SidebarActions />
  }, [candidateBasicData, sidebarClosed])

  return (
    <MainLayout content={isEmployer && sideBar}>
      <div className={styles.messageCenterContent}>
        <Breadcrumbs
          breadcrumbs={breadcrumbsArray}
          className={styles.breadcrumbsDesktop}
        />
        <div className={styles.messageCenterWrap}>
          <div className={styles.messageCenterHeaderWrap}>
            <h1 className={styles.heading}>
              <FormattedMessage id={mct.heading} />
            </h1>
            <Breadcrumbs
              breadcrumbs={breadcrumbsArray}
              className={styles.breadcrumbsMobile}
            />
            <div className={styles.tabsWrap}>
              <MessageCenterTab
                route={
                  isEmployer
                    ? getRoute(AppRoute.CandidateMessages)
                    : getRoute(AppRoute.EmployerMessages)
                }
                label={
                  !isEmployer
                    ? translation[mct.labelEmployers]
                    : translation[mct.labelCandidates]
                }
                specificParticipiantUnreadCount={
                  specificParticipiantUnreadCount
                }
                active={!isTSA}
              />
              {
                // TODO uncomment this part after messaging for Talent Success Advisor gets avaliable
                /*<MessageCenterTab
                // route={getRoute(AppRoute.MessagesTSA)}
                route={path}
                label={translation[mct.tabLabelTSA]}
                active={isTSA}
              />*/
              }
            </div>
            <MessageCenterHeader isTSA={isTSA} isEmployer={isEmployer} />
          </div>
          <div className={styles.content}>
            {/* Message center components goes here */}
            <MessageCenterConversations
              setShowRecipientHeader={setShowRecipientHeader}
              setModalOpen={setModalOpen}
            />
            <MessagesSectionContainer
              showRecipientHeader={showRecipientHeader}
              setShowRecipientHeader={setShowRecipientHeader}
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
            />
          </div>
        </div>
        {isMobile && <MessagesSectionMobileFooter />}
      </div>
    </MainLayout>
  )
}
