import {FC} from 'react'
import {FormattedMessage} from 'react-intl'
import {useFormik} from 'formik'

import Modal from 'Components/Modal'
import {ThumbsFooter} from 'Components/Modal/ThumbsFooter'
import {IncomingRequestType, CancelInterviewType} from 'Interfaces'
import styles from './ModalCancelInterview.module.scss'
import TextArea from 'Components/Inputs/TextArea'
import {OnChangeProps} from 'Utils/FormikProps'
import {
  CandidateDashboardTranslations as cdt,
  EmployerDashboardTranslations as edt,
} from 'Services/I18n/Constants'
import {ForumOutlined} from '@mui/icons-material'

interface ModalCancelInterviewProps {
  request: IncomingRequestType
  cancelModal: boolean
  setCancelModal: (rejecModal: boolean) => void
  cancelInterviewInvitation: (payload: CancelInterviewType) => void
  isOpenInterviewInvitation: boolean
  setIsOpenInterviewInvitation: (open: boolean) => void
}

export const ModalCancelInterview: FC<ModalCancelInterviewProps> = ({
  request,
  cancelModal,
  setCancelModal,
  cancelInterviewInvitation,
  isOpenInterviewInvitation,
  setIsOpenInterviewInvitation,
}) => {
  const formik = useFormik({
    initialValues: {
      reason: '',
    },
    onSubmit: () => {
      cancelInterviewInvitation({
        id: request.id,
        reason: formik.values.reason,
      })
      formik.resetForm()
    },
  })

  return (
    <>
      {!isOpenInterviewInvitation && (
        <Modal
          className={styles.modal}
          icon={ForumOutlined}
          isOpen={cancelModal}
          title={edt.cancelInterview}
          theme={'error'}
          onAfterClose={() => {
            setCancelModal(false)
          }}
          Footer={
            <ThumbsFooter
              rejectText={edt.cancelInterview}
              theme={'thumbDown'}
              onReject={() => {
                formik.handleSubmit()
                setCancelModal(false)
              }}
              onCancel={() => {
                setCancelModal(false)
              }}
              noThumbIcon
            />
          }
        >
          <div className={styles.containerInterview}>
            <p className={styles.text}>
              <FormattedMessage id={cdt.cancelConfirmation1} />
              {request.companyName}?
            </p>
            <p className={styles.description}>
              <FormattedMessage id={cdt.cancelConfirmation2} />
            </p>
            <TextArea
              name="reason"
              placeholderText={edt.cancelReason}
              {...OnChangeProps(formik, 'reason')}
              theme="white"
              className={styles.textArea}
            />
            <button
              onClick={() => {
                setCancelModal(false)
                setIsOpenInterviewInvitation(true)
              }}
              className={styles.button}
            >
              <FormattedMessage id={edt.viewDetails} />
            </button>
          </div>
        </Modal>
      )}
    </>
  )
}
