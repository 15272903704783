import * as Yup from 'yup'
import {MediumTitle, LongDescription} from 'Validations'

const Validation = Yup.object().shape({
  title: MediumTitle.required('Title is required'),
  endYear: Yup.number().required('End year is required'),
  description: LongDescription,
})

// Old approach, not used at the moment
// const Validation = Yup.object().shape({
//   title: MediumTitle.required('Title is required'),
//   startMonth: Yup.number().required('Start month is required'),
//   startYear: Yup.number()
//     .required('Start year is required')
//     .min(1970, 'Enter valid start year')
//     .max(new Date().getFullYear(), 'Enter valid start year'),
//   endMonth: Yup.number()
//     .required('End month is required')
//     .test(DateTest.endMonth),
//   endYear: Yup.number()
//     .when('startYear', (started, yup) =>
//       yup.min(started, 'End year cannot be before start year')
//     )
//     .required('End year is required'),
//   description: LongDescription,
// })

export default Validation
