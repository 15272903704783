import {FC, useMemo} from 'react'
import {useFormik} from 'formik'
import {FormattedMessage} from 'react-intl'
import {cloneDeep} from 'lodash'
import classnames from 'classnames'
import {MailOutline} from '@mui/icons-material'

import Modal, {SubmitFooter} from 'Components/Modal'
import Title from 'Components/Title'
import styles from '../SavedSearchModal.module.scss'
import {CandidateSearchTranslations as cst} from 'Services/I18n/Constants'
import TextField from 'Components/Inputs/TextField'
import Validation from '../Validation'
import {
  SaveSearchModalFormikType,
  SavedSearchType,
  SavedSearchDataType,
} from 'Interfaces'
import Checkbox from 'Components/Inputs/Checkbox'
import Hr from 'Components/Hr'
import Dropdown from 'Components/Inputs/Dropdown'
import {frequencyOptions} from '../frequency'
import {useApiError} from 'Hooks'

interface EditSavedSearchModalProps {
  isOpen: boolean
  setOpen: (value: boolean) => void
  savedSearchToEdit: SavedSearchType
  editSavedSearch: (values: SavedSearchType) => void
  savedSearchState: SavedSearchDataType
}

export const EditSavedSearchModal: FC<EditSavedSearchModalProps> = ({
  isOpen,
  setOpen,
  savedSearchToEdit,
  editSavedSearch,
  savedSearchState,
}) => {
  const {name, isFavorite, frequency, id} = cloneDeep(savedSearchToEdit)
  const formikDefaults: SaveSearchModalFormikType = {
    name,
    isFavorite,
    frequency,
    savedSearchId: id,
  }

  const formik = useFormik({
    initialValues: formikDefaults,
    validationSchema: Validation,
    onSubmit: (values) => {
      const {savedSearchId, ...rest} = values
      editSavedSearch({
        id: savedSearchId,
        ...savedSearchToEdit,
        ...rest,
        frequency: values.frequency || 'daily',
      })
    },
  })

  const canAddToFavorite = useMemo(
    () =>
      savedSearchState.data.filter(
        (s) => s.isFavorite && s.id !== savedSearchToEdit.id
      ).length < 5,
    [savedSearchState.data]
  )

  useApiError(formik.setFieldError, savedSearchState.error, 'name')

  return (
    <Modal
      isOpen={isOpen}
      onAfterClose={() => {
        formik.resetForm({values: formikDefaults})
        setOpen(false)
      }}
      title={cst.savedSearchModalTitle}
      icon={MailOutline}
      className={styles.modal}
      theme="desktopFlex"
      Footer={
        <SubmitFooter
          onSubmit={formik.handleSubmit}
          onCancel={() => {
            setOpen(false)
          }}
          theme="modalProfileSubmit"
          submitText={cst.savedSearchUpdateSearch}
        />
      }
    >
      <Title text={cst.savedSearchTitle} theme="modal" />
      <TextField
        name="name"
        value={formik.values.name}
        onChange={formik.handleChange}
        placeholder={cst.savedSearchInputPlaceholder}
        error={
          formik.submitCount && formik.errors.name
            ? formik.errors.name
            : undefined
        }
        className={styles.name}
      />
      <Checkbox
        name="isFavorite"
        onChange={formik.handleChange}
        value={formik.values.isFavorite}
        disabled={!canAddToFavorite}
        className={classnames(styles.checkbox, {
          [styles.disabledCheckbox]: !canAddToFavorite,
        })}
      >
        <FormattedMessage id={cst.savedSearchAddToFavorites} />
      </Checkbox>
      <Hr theme="modal" />
      <Title text={cst.savedSearchFrequencyTitle} theme="modal" />
      <div className={styles.description}>
        <FormattedMessage id={cst.savedSearchFrequencyDescription} />
      </div>
      <Dropdown
        emptyValueLabel={cst.savedSearchSelectFrequency}
        name="frequency"
        value={formik.values.frequency ? formik.values.frequency : undefined}
        items={frequencyOptions}
        className={styles.radiusInput}
        setValue={formik.setFieldValue}
        theme="white"
        listUp
        error={formik.errors?.frequency}
      />
    </Modal>
  )
}
