import * as type from '../types'
import {
  ActionType,
  CompanyContactInfoSaveType,
  HiringPlanSaveType,
  SavePartialHiringPlanType,
} from 'Interfaces'

export const getEmployerOnboarding = (
  companyId: string | undefined
): ActionType<string> => {
  return {
    type: type.employerOnboarding.getEmployerOnboarding.requested,
    payload: companyId,
  }
}

export const saveStepCompanyContactInfo = (
  values: CompanyContactInfoSaveType
): ActionType<CompanyContactInfoSaveType> => {
  return {
    type: type.employerOnboarding.stepCompanyContactInfo.requested,
    payload: values,
  }
}

export const saveStepHiringPlan = (
  values: HiringPlanSaveType,
  step?: number
): ActionType<{values: HiringPlanSaveType; step?: number}> => {
  return {
    type: type.employerOnboarding.stepHiringPlan.requested,
    payload: {values, step},
  }
}
export const saveStepPartialHiringPlan = (
  values: SavePartialHiringPlanType
): ActionType<SavePartialHiringPlanType> => {
  return {
    type: type.employerOnboarding.stepPartialHiringPlan.requested,
    payload: values,
  }
}

export const setEmployerOnboardingStep = (
  value: number
): ActionType<number> => {
  return {
    type: type.employerOnboarding.setEmployerStep.requested,
    payload: value,
  }
}
