import {FC} from 'react'

import styles from './TestResultsWhiteBoxContent.module.scss'

interface TestResultsWhiteBoxContent {
  children: JSX.Element
}

export const TestResultsWhiteBoxContent: FC<TestResultsWhiteBoxContent> = ({
  children,
}) => {
  return <div className={styles.testResultsContainer}>{children}</div>
}
