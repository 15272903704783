import {FC} from 'react'
import {Link} from 'react-router-dom'

import {DownloadForOffline, Print} from '@mui/icons-material'
import Preferenca from 'Assets/Images/preferenca-logo.svg'
import {AppRoute} from 'Services/I18n/Constants'
import {getRoute} from 'Services/I18n/Utils'
import {FormattedMessage} from 'react-intl'
import {GeneralAssessmentsTranslations as gat} from 'Services/I18n/Constants/'
import classNames from 'classnames'

import styles from './DownloadAndPrintHeader.module.scss'
interface DownloadAndPrintHeaderProps {
  downloadLink?: string
  setResultButtonClicked?: Function
  className?: string
}

export const DownloadAndPrintHeader: FC<DownloadAndPrintHeaderProps> = ({
  downloadLink,
  setResultButtonClicked,
  className,
}) => {
  return (
    <div
      className={classNames(styles.downloadAndPrintHeaderContainer, className)}
    >
      <div className={styles.contentLogoWrap}>
        <Link to={getRoute(AppRoute.HomePageCandidate)}>
          <img className={styles.logo} src={Preferenca} alt="Logo" />
        </Link>
      </div>

      <div className={styles.iconsWrapper}>
        {downloadLink && (
          <div className={styles.iconTextWrapper}>
            <a
              onClick={() => {
                if (setResultButtonClicked) {
                  setResultButtonClicked(true)
                }
              }}
              download
              href={downloadLink}
              target="_blank"
            >
              <DownloadForOffline className={styles.icon} />
              <p className={styles.iconTitle}>
                <FormattedMessage id={gat.download} />
              </p>
            </a>
          </div>
        )}
        <div
          onClick={() => {
            window.print()
          }}
          className={styles.iconTextWrapper}
        >
          <Print className={styles.icon} />
          <p className={styles.iconTitle}>
            <FormattedMessage id={gat.print} />
          </p>
        </div>
      </div>
    </div>
  )
}
