import {FC} from 'react'

import ScoreTableItem from '../ScoreTableItem'

import styles from './TitleSection.module.scss'

interface TitleSectionProps {
  title: string
}

export const TitleSection: FC<TitleSectionProps> = ({title}) => {
  const tableValues = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
  return (
    <div className={styles.titleSectionContainer}>
      <div className={styles.titleContainer}>
        <h2>{title}</h2>
      </div>
      <div className={styles.scoreTable}>
        {tableValues.map((number, index) => {
          return <ScoreTableItem number={number} key={index} />
        })}
      </div>
    </div>
  )
}
