import {FC, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import {useFormik} from 'formik'

import styles from './EmployerResetPassword.module.scss'
import {Validation} from './Validation'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {Title, Description} from 'Components/Login'
import YellowButton from 'Components/YellowButton'
import {useApiError, useTranslation} from 'Hooks'
import {ResetPasswordTranslations} from 'Services/I18n/Constants'
import {EmployerLoginLayout} from 'Layouts/EmployerLoginLayout/EmployerLoginLayout'
import {AuthStateType, ResetPasswordType} from 'Interfaces'
import {CheckCircle} from '@mui/icons-material'
import {FormattedMessage} from 'react-intl'
import PasswordInput from 'Components/Inputs/PasswordInput'

interface EmployerResetPasswordProps {
  auth: AuthStateType
  resetEmployerPassword: (data: ResetPasswordType) => void
  hideResetPasswordMessage: () => void
}

export const EmployerResetPassword: FC<EmployerResetPasswordProps> = ({
  auth,
  resetEmployerPassword,
  hideResetPasswordMessage,
}) => {
  const {token}: {token: string} = useParams()

  const emptyValues = {
    password: '',
    verifyPassword: '',
  }

  useEffect(() => {
    if (auth.displayMessage.resetPasswordSuccess) {
      formik.resetForm({
        values: emptyValues,
      })
      setTimeout(() => {
        hideResetPasswordMessage()
      }, 5000)
    }
  }, [auth.displayMessage.resetPasswordSuccess])

  const formik = useFormik({
    initialValues: emptyValues,
    validationSchema: Validation,
    onSubmit: (values) => {
      resetEmployerPassword({
        ...values,
        token,
      })
    },
  })

  const translation = useTranslation()

  useApiError(formik.setFieldError, auth.error, 'password')

  return (
    <EmployerLoginLayout>
      <div className={styles.formWrap}>
        <Title
          text={translation[ResetPasswordTranslations.title]}
          className={styles.title}
        />
        <Description
          className={styles.description}
          text={translation[ResetPasswordTranslations.description]}
        />
        <form className={styles.form} onSubmit={formik.handleSubmit}>
          <div className={styles.inputWrap}>
            <PasswordInput
              name="password"
              label={translation[ResetPasswordTranslations.textLabelNewPass]}
              className={styles.passField}
              onChange={(e) =>
                formik.setFieldValue('password', e.currentTarget.value)
              }
              value={formik.values.password}
              error={
                formik.submitCount && formik.touched['password']
                  ? formik.errors.password
                  : undefined
              }
            />
            <PasswordInput
              name="verifyPassword"
              label={
                translation[ResetPasswordTranslations.textLabelConfirmPass]
              }
              className={styles.passField}
              onChange={(e) =>
                formik.setFieldValue('verifyPassword', e.currentTarget.value)
              }
              value={formik.values.verifyPassword}
              error={
                formik.submitCount && formik.touched['verifyPassword']
                  ? formik.errors.verifyPassword
                  : undefined
              }
            />
            {auth.displayMessage.resetPasswordSuccess && (
              <div className={styles.successMessage}>
                <CheckCircle />
                <FormattedMessage
                  id={ResetPasswordTranslations.successMessage}
                />
              </div>
            )}
            <div className={styles.resetButton}>
              <YellowButton
                text={
                  translation[ResetPasswordTranslations.buttonResetPassword]
                }
              />
            </div>

            <Link to={getRoute(AppRoute.EmployerLogin)} className={styles.link}>
              <div className={styles.linkTxt}>
                {translation[ResetPasswordTranslations.backToLogIn]}
              </div>
            </Link>
          </div>
        </form>
      </div>
    </EmployerLoginLayout>
  )
}
