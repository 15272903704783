import {useTranslation} from 'Hooks'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'

export const getTranslatedTime = (
  timeUntilString: string | boolean
): string => {
  const translation = useTranslation()

  const timeValue = timeUntilString.toString().substring(0, 2)

  const timeString =
    +timeValue > 9
      ? timeUntilString.toString().substring(3)
      : timeUntilString.toString().substring(2)

  let translatedTime
  switch (timeString) {
    case 'seconds':
      switch (+timeValue) {
        case 1:
          translatedTime = translation[edt.second]
          break
        default:
          translatedTime = translation[edt.seconds]
      }
      break
    case 'minute':
      translatedTime = translation[edt.minute]
      break
    case 'minutes':
      switch (+timeValue) {
        case 2:
          translatedTime = translation[edt.twoMinutes]
          break
        case 3:
          translatedTime = translation[edt.threeAndFourMinutes]
          break
        case 4:
          translatedTime = translation[edt.threeAndFourMinutes]
          break
        default:
          translatedTime = translation[edt.fiveAndMoreMinutes]
      }
      break
    case 'hour':
      translatedTime = translation[edt.hour]

      break
    case 'hours':
      switch (+timeValue) {
        case 2:
          translatedTime = translation[edt.twoHours]
          break
        case 3:
          translatedTime = translation[edt.threeAndFourHours]
          break
        case 4:
          translatedTime = translation[edt.threeAndFourHours]
          break
        default:
          translatedTime = translation[edt.fiveAndMoreHours]
      }
      break
    case 'day':
      translatedTime = translation[edt.day]
      break
    case 'days':
      translatedTime = translation[edt.days]
  }
  return translatedTime
}
