import {FC} from 'react'

import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined'
import MicOutlinedIcon from '@mui/icons-material/MicOutlined'

import styles from './TestMicrophoneCameraNotificationContent.module.scss'

export const TestMicrophoneCameraNotificationContent: FC = ({}) => {
  return (
    <>
      <div className={styles.iconsContainer}>
        <div className={styles.iconContainer}>
          <VideocamOutlinedIcon className={styles.icon} />
        </div>
        <div className={styles.iconContainer}>
          <MicOutlinedIcon className={styles.icon} />
        </div>
      </div>
      <p className={styles.textMicrophoneNotifications}>
        Pri reševanju potrebujete vklopljen mikrofon in kamero.
        <br /> Prosimo, da pred začetkom testa preverite njuno delovanje!
      </p>
    </>
  )
}
