import {connect} from 'react-redux'

import WidgetBar from './WidgetBar'
import {AppDispatch} from 'Store'
import {setCandidateBasicData, setSidebarClosed} from 'Store/Actions'
import {RootState} from 'Store/Reducers'
import {Candidate, IncomingRequestSeekerType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  sidebarClosed: state.widgetBar.data.sidebarClosed,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setSidebarClosed: (sidebarClosed: boolean) =>
    dispatch(setSidebarClosed(sidebarClosed)),
  setCandidateBasicData: (
    candidate: Candidate | IncomingRequestSeekerType | null
  ) => dispatch(setCandidateBasicData(candidate)),
})

export const WidgetBarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(WidgetBar)
