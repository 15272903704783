import * as type from '../types'
import {
  ActionType,
  InviteFriendFormikType,
  TestimonialsUserType,
  LogGuestConsentChangeType,
  UpdateNewsletterConsentType,
  GetConsentsParamsType,
} from 'Interfaces'

export const inviteFriend = (
  values: InviteFriendFormikType
): ActionType<InviteFriendFormikType> => {
  return {
    type: type.homepage.inviteFriend.requested,
    payload: values,
  }
}

export const getTestimonials = (
  userType: TestimonialsUserType
): ActionType<TestimonialsUserType> => {
  return {
    type: type.homepage.testimonials.requested,
    payload: userType,
  }
}

export const getTopCompanies = (): ActionType<undefined> => {
  return {
    type: type.homepage.topCompanies.requested,
  }
}

export const updateGuestConsents = (
  values: UpdateNewsletterConsentType
): ActionType<UpdateNewsletterConsentType> => {
  return {
    type: type.homepage.updateConsents.requested,
    payload: values,
  }
}

export const getNewsletterConsents = (
  values: GetConsentsParamsType
): ActionType<GetConsentsParamsType> => {
  return {
    type: type.homepage.consents.requested,
    payload: values,
  }
}

export const logGuestConsentChangeReason = (
  values: LogGuestConsentChangeType
): ActionType<LogGuestConsentChangeType> => {
  return {
    type: type.homepage.logConsentReason.requested,
    payload: values,
  }
}
