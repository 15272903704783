import {connect} from 'react-redux'

import {PublicJobPreferences} from './PublicJobPreferences'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {
  getOccupations,
  getSalaries,
  getRegions,
  getAvailabilities,
  getEmploymentTypes,
  updatePublicJobPreferences,
  getWorkFromHome,
  getAdditionalBenefits,
} from 'Store/Actions'
import {PublicProfileJobPreferenceFormikType} from 'Interfaces'
import {canChangeProfile} from 'Utils/Profile'

const mapStateToProps = (state: RootState) => ({
  taxonomies: state.taxonomies,
  profile: state.seeker.data?.public,
  seeker: state.seeker,
  canChange: canChangeProfile(state.auth.data?.rjbProfileStatus),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getTaxonomies: () => {
    dispatch(getAvailabilities())
    dispatch(getRegions())
    dispatch(getOccupations())
    dispatch(getEmploymentTypes())
    dispatch(getWorkFromHome())
    dispatch(getSalaries())
    dispatch(getAdditionalBenefits())
  },
  updatePublicJobPreferences: (values: PublicProfileJobPreferenceFormikType) =>
    dispatch(updatePublicJobPreferences(values)),
})

export const PublicJobPreferencesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicJobPreferences)
