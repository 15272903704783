import * as type from '../types'
import {
  ActionType,
  CompanyProfileHeaderSaveValue,
  WelcomeMessageDataFormikType,
  CompanyDescriptionItem,
  EmploymentInformationItem,
  ImpressionsAddType,
  QuestionValue,
  CompanyBenefitsSaveType,
  ImpressionsUpdateType,
  VideoType,
} from 'Interfaces'

export const editCompanyProfileHeader = (
  value: CompanyProfileHeaderSaveValue
): ActionType<CompanyProfileHeaderSaveValue> => {
  return {
    type: type.company.header.edit.requested,
    payload: value,
  }
}
export const saveLogo = (value: string): ActionType<string> => {
  return {
    type: type.company.logotype.save.requested,
    payload: value,
  }
}
export const saveCover = (value: string): ActionType<string> => {
  return {
    type: type.company.coverImage.save.requested,
    payload: value,
  }
}

export const getCompanyData = (id?: string): ActionType<string> => {
  return {
    type: type.company.data.requested,
    payload: id,
  }
}

export const addWelcomeMessage = (
  value: WelcomeMessageDataFormikType
): ActionType<WelcomeMessageDataFormikType> => {
  return {
    type: type.company.welcomeMessage.add.requested,
    payload: value,
  }
}
export const sendQuestions = (
  value: QuestionValue[]
): ActionType<QuestionValue[]> => {
  return {
    type: type.company.questions.save.requested,
    payload: value,
  }
}

export const editWelcomeMessage = (
  value: WelcomeMessageDataFormikType
): ActionType<WelcomeMessageDataFormikType> => {
  return {
    type: type.company.welcomeMessage.edit.requested,
    payload: value,
  }
}
export const savePortfolio = (portfolio: string): ActionType<string> => {
  return {
    type: type.company.portfolio.save.requested,
    payload: portfolio,
  }
}

export const saveBenefits = (
  benefits: CompanyBenefitsSaveType
): ActionType<CompanyBenefitsSaveType> => {
  return {
    type: type.company.benefits.save.requested,
    payload: {
      ...benefits,
    },
  }
}

export const saveEmploymentInformation = (
  value: EmploymentInformationItem
): ActionType<EmploymentInformationItem> => {
  return {
    type: type.company.employmentInformation.save.requested,
    payload: value,
  }
}

export const saveCompanyDescription = (
  value: CompanyDescriptionItem
): ActionType<CompanyDescriptionItem> => {
  return {
    type: type.company.companyDescription.save.requested,
    payload: value,
  }
}

export const getCompanyDescriptionBiziSi = (
  value: string
): ActionType<string> => {
  return {
    type: type.company.companyDescription.biziSi.requested,
    payload: value,
  }
}

export const getImpressions = (): ActionType<unknown> => {
  return {
    type: type.impressions.requested,
  }
}

export const updateImpressions = (
  value: ImpressionsUpdateType[]
): ActionType<ImpressionsUpdateType[]> => {
  return {
    type: type.company.impressions.update.requested,
    payload: value,
  }
}

export const addImpressions = (
  value: ImpressionsAddType[]
): ActionType<ImpressionsAddType[]> => {
  return {
    type: type.company.impressions.add.requested,
    payload: value,
  }
}

export const addVideos = (value: VideoType[]): ActionType<VideoType[]> => {
  return {
    type: type.company.videos.add.requested,
    payload: value,
  }
}
export const updateVideos = (value: VideoType[]): ActionType<VideoType[]> => {
  return {
    type: type.company.videos.update.requested,
    payload: value,
  }
}

export const getCandidateProfile = (value: string): ActionType<string> => {
  return {
    type: type.employer.getCandidateProfile.requested,
    payload: value,
  }
}

export const contactCandidate = (value: string): ActionType<string> => {
  return {
    type: type.employer.contactCandidate.requested,
    payload: value,
  }
}
export const clearCandidateProfile = (): ActionType<undefined> => {
  return {
    type: type.employer.clearCandidateProfile.requested,
  }
}
