import {FC, useEffect, useState} from 'react'

import MainLayout from 'Layouts/MainLayout'
import Breadcrumbs from 'Components/Breadcrumbs'
import Title from 'Components/Title'
import Hr from 'Components/Hr'
import TextArea from 'Components/Inputs/TextArea'
import {useFormik} from 'formik'
import {OnChangeProps} from 'Utils/FormikProps'
import Validation from './Validation'
import {AvatarContainer} from 'Components/Avatar'
import {ReviewCandidateDataType, ReviewCandidateType} from 'Interfaces'
import Rating from '@mui/material/Rating'
import StarIcon from '@mui/icons-material/Star'
import {useMediaQuery} from 'react-responsive'
import styles from './ReviewCandidate.module.scss'
import YellowLink from 'Components/YellowLink'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'

type ReviewCandidateProps = {
  getCanidateReviewData: (id: string) => void
  reviewData?: ReviewCandidateDataType
  addCandidateReview: (values: ReviewCandidateType) => void
}

const breadcrumbsArray = [
  {name: 'Preferenca.si', link: '/'},
  {name: 'Review candidate', link: '/review-candidate'},
]

export const ReviewCandidate: FC<ReviewCandidateProps> = ({
  getCanidateReviewData,
  reviewData,
  addCandidateReview,
}) => {
  const [star, setStar] = useState<number | null>(0)

  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 992px)',
  })

  const formRows = [
    {
      title: 'Name',
      value: reviewData?.name,
      avatar: (
        <div className={styles.avatarHolder}>
          <AvatarContainer className={styles.profileAvatar} />
        </div>
      ),
    },
    {
      title: 'Interviewed on',
      value: (
        <p>
          {reviewData?.date}
          &nbsp;
          <span className={styles.grayText}>by</span>
          &nbsp;
          {reviewData?.interviewedBy}
        </p>
      ),
    },
    {
      title: 'Evaluation',
      value: (
        <Rating
          name="simple-controlled"
          value={star}
          onChange={(_, newValue: number | null) => {
            setStar(newValue)
          }}
          emptyIcon={<StarIcon style={{color: 'black'}} fontSize="inherit" />}
        />
      ),
    },
    {title: 'Additional comments'},
  ]

  const emptyValues: ReviewCandidateType = {
    seekerId: '',
    evaluation: null,
    comment: '',
  }

  const formik = useFormik({
    initialValues: emptyValues,
    validationSchema: Validation,
    onSubmit: (values) => {
      // TODO check is this work ok after getting of real seekerid
      addCandidateReview({
        ...values,
        seekerId: reviewData?.seekerId,
        evaluation: star,
      })

      // TODO after all logic from backend will be finished add add/edit calling of actions, call add or edit when certain condition is fullfilled
      /*editCandidateReview({
         ...values,
         seekerId: reviewData?.seekerId,
         evaluation: star,
       })*/
    },
  })

  useEffect(() => {
    // TODO replace with real id, seekerId
    getCanidateReviewData('ac8e6965-fbe1-479a-8fbf-78196a754b34')
  }, [])

  return (
    <MainLayout>
      <div className={styles.reviewCandidate}>
        <div className={styles.titleContainer}>
          <Title text="Review candidate" className={styles.mainTitle} />
          <div className={styles.breadcrumbsWrapper}>
            <Breadcrumbs breadcrumbs={breadcrumbsArray} />
          </div>
          <Hr className={styles.hr} />
          <Title text="INTERVIEW EVALUATION" />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className={styles.reviewCardContainer}>
            {isDesktopOrLaptop && (
              <div className={styles.avatarHolder}>
                <AvatarContainer className={styles.profileAvatar} />
              </div>
            )}
            <div className={styles.formContainer}>
              {formRows.map(({title, value, avatar}) => {
                return (
                  <div className={styles.formFlexItem}>
                    <div key={title} className={styles.formRow}>
                      <p className={styles.title}>{title}</p>
                      <p className={styles.value}>{value}</p>
                    </div>
                    {!isDesktopOrLaptop && avatar}
                  </div>
                )
              })}

              <TextArea
                name="comment"
                {...OnChangeProps(formik, 'comment')}
                placeholderText="Type here..."
                className={styles.textArea}
              />

              <div className={styles.hrWrapper}>
                <Hr className={styles.hr} />
              </div>
            </div>
          </div>
          <div className={styles.yellowButtonRow}>
            <div className={styles.yellowButtonWrapper}>
              <YellowLink
                to={getRoute(AppRoute.EmployerInterviewInvitationsCompleted)}
                text="Save review"
              />
            </div>
          </div>
        </form>
      </div>
    </MainLayout>
  )
}
