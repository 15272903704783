import CheckIcon from '@mui/icons-material/Check'
import classnames from 'classnames'
import styles from './CheckIcon.module.scss'

export interface IconCheckProps {
  className?: string
}

export const IconCheck = ({className}: IconCheckProps): JSX.Element => {
  return <CheckIcon className={classnames(styles.checkIcon, className)} />
}
