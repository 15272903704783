import {FC} from 'react'
import Breadcrumbs from 'Components/Breadcrumbs'

import {AuthStateType} from 'Interfaces'
import './PrivacyPolicy.module.scss'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {DownloadAndPrintHeader} from 'Components/DownloadAndPrintHeader'
import {useIntl} from 'react-intl'
import {PrivacyPolicyTranslations as ppt} from 'Services/I18n/Constants'

interface PrivacyPolicyProps {
  auth: AuthStateType
}

export const PrivacyPolicy: FC<PrivacyPolicyProps> = ({auth}) => {
  const {formatMessage} = useIntl()

  const privacyHtml = formatMessage({id: ppt.privacyPolicyHtml}).replaceAll(
    '#general-terms-and-conditions',
    getRoute(AppRoute.GeneralTermsAndConditions)
  )

  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: getRoute(AppRoute.HomePageCandidate)},
    {name: 'Politika zasebnosti in varovanja osebnih podatkov'},
  ]
  return (
    <>
      <div className="privacyPolicyContainer">
        <DownloadAndPrintHeader
          downloadLink={
            process.env.PUBLIC_URL + '/files/politika_zasebnosti_31.01.2023.pdf'
          }
        />
        {auth.data && (
          <h1 className="privacyPolicyTermsAndConditionsTitle">
            Politika zasebnosti in varovanja osebnih podatkov
          </h1>
        )}
        {!auth.data && (
          <div className="privacyPolicyContainerUp">
            <div className="privacyPolicyContainerUpTitle">
              <div className="privacyPolicyTitleWrapper">
                <h1 className="privacyPolicyTermsAndConditionsTitle">
                  Politika zasebnosti in varovanja osebnih podatkov
                </h1>
              </div>
            </div>
          </div>
        )}
        <div className="privacyPolicyContainerMiddle">
          <Breadcrumbs
            className="privacyPolicySubNavigation"
            breadcrumbs={breadcrumbsArray}
          />
        </div>

        <div
          dangerouslySetInnerHTML={{
            __html: privacyHtml,
          }}
        />
      </div>
    </>
  )
}
