import {EmployerOnboardingTranslations as et} from 'Services/I18n/Constants'

export const EmployerOnboardingSteps: any = {
  1: {
    key: 'company-and-contact-info',
    title: et.step1Title,
    sidebar: et.step1SidebarTitle,
    description: et.companyContactInfoDescription,
    step: 2,
  },
  2: {
    key: 'twelveMonthHiringPlan',
    title: et.step2Title,
    sidebar: et.step2SidebarTitle,
    description: et.twelveMonthHiringPlanDescription,
    step: 3,
  },
  3: {
    key: 'done',
    title: et.step3Title,
    sidebar: et.step3SidebarTitle,
    description: et.statementDescription,
    step: 4,
  },
}
