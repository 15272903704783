import classNames from 'classnames'
import {FC, useEffect, useState} from 'react'
import {useHistory} from 'react-router-dom'
import {useMatchingRoute} from 'Services/I18n/Hooks/useMatchingRoute'
import {getRoute} from 'Services/I18n/Utils'
import styles from './EmployerJourneyTracker.module.scss'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'
import {useTranslation} from 'Hooks'

type EmployerJourneyTrackerProps = {
  getEmployerActiveRequests: () => void
  activeRequests: any
}

const defaultEmployerSteps = [
  {
    title: edt.contactRequests,
    routeKey: 'routes.employercontactrequests',
    requestKey: 'contactRequests',
    count: 0,
    active: false,
  },
  {
    title: edt.interviewInvitations,
    routeKey: 'routes.employerinterviewinvitations',
    requestKey: 'interviewInvitations',
    count: 0,
    active: false,
  },
  {
    title: edt.offers,
    routeKey: 'routes.employeroffers',
    requestKey: 'offers',
    count: 0,
    active: false,
  },
  {
    title: edt.placementsTitle,
    routeKey: 'routes.employerplacements',
    requestKey: 'placements',
    count: 0,
    active: false,
  },
]

export const EmployerJourneyTracker: FC<EmployerJourneyTrackerProps> = ({
  activeRequests,
  getEmployerActiveRequests,
}) => {
  const [employerSteps, setEmployerSteps] = useState(defaultEmployerSteps)
  const matchingRoute = useMatchingRoute()
  const history = useHistory()
  const translation = useTranslation()

  useEffect(() => {
    if (activeRequests) {
      const newEmployerStepsValue = employerSteps.map((step) => {
        const count = activeRequests[step.requestKey]
        return {
          ...step,
          active: false,
          count,
        }
      })
      const currentPageIdx = employerSteps.findIndex((step) =>
        matchingRoute.routeKey?.includes(step.routeKey)
      )

      if (currentPageIdx >= 0)
        newEmployerStepsValue[currentPageIdx].active = true

      setEmployerSteps(newEmployerStepsValue)
    }
  }, [matchingRoute, activeRequests])

  useEffect(() => {
    getEmployerActiveRequests()
  }, [])

  return (
    <div className={styles.journeyTrackerContainer}>
      <ul>
        {employerSteps.map((step, idx) => {
          return (
            <li
              onClick={() =>
                history.push(
                  getRoute(step.routeKey) +
                    `/${translation[edt.all].toLowerCase()}`
                )
              }
              key={'step-' + idx}
              className={classNames({[styles.current]: step.active})}
            >
              <div className={styles.content}>
                {translation[step.title]}
                {step.count ? <span> ({step.count})</span> : null}
              </div>
            </li>
          )
        })}
      </ul>
    </div>
  )
}
