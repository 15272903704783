import {
  ActionType,
  AddEmployerProjectPayloadType,
  DashboardSeekerEvaluationType,
  CandidateSearchFiltersType,
  ErrorType,
  IncomingRequestType,
  SeekerEvaluationType,
  PopularCandidateType,
} from 'Interfaces'
import {
  CandidateSearchResultsType,
  CandidateSearchStateType,
  SendContactRequestResponseType,
  EmployerProjectCandidatesItemType,
  RemoveCandidateFromProjectsType,
  TaxonomyWithCategoryType,
  SavedSearchType,
} from 'Interfaces'
import {cloneDeep} from 'lodash'
import * as type from '../types'

const initialState: CandidateSearchStateType = {
  searchResults: {
    data: [],
    total: 0,
    loading: true,
    error: undefined,
    loadMore: undefined,
    loadMoreProgress: false,
    sortBy: undefined,
    facets: {
      additionalBenefitFacets: [],
      // careerStatusFacets: [],
      currentJobCategoryFacets: [],
      educationFieldOfStudyFacets: [],
      educationLevelFacets: [],
      employmentTypeFacets: [],
      languageTaxonomyFacets: [],
      occupationFacets: [],
      regionFacets: [],
      salaryFacets: [],
      skillFacets: [],
      totalWorkExperienceFacets: [],
      workExperienceJobCategoryFacets: [],
    },
  },
  filtersType: 'employer',
  toastMessage: {
    show: false,
    message: undefined,
  },
  seekerEvaluation: 0,
  searchKeywords: {
    data: [],
    loading: false,
    error: undefined,
  },
  savedSearch: {
    data: [],
    loading: false,
    error: undefined,
  },
  popularCandidates: {
    data: [],
    loading: false,
    error: undefined,
  },
}

const CandidateSearchReducer = (
  state = initialState,
  action: ActionType<
    | SeekerEvaluationType
    | CandidateSearchResultsType
    | SendContactRequestResponseType
    | EmployerProjectCandidatesItemType
    | RemoveCandidateFromProjectsType
    | AddEmployerProjectPayloadType
    | TaxonomyWithCategoryType[]
    | SavedSearchType
    | SavedSearchType[]
    | IncomingRequestType[]
    | ErrorType
    | string
    | number
    | undefined
  >
): CandidateSearchStateType => {
  switch (action.type) {
    case type.searchCandidates.search.requested:
      return {...state, searchResults: {...state.searchResults, loading: true}}
    case type.searchCandidates.loadMore.requested:
      return {
        ...state,
        searchResults: {...state.searchResults, loadMoreProgress: true},
      }
    case type.searchKeywords.requested:
      return {
        ...state,
        searchKeywords: {
          data: state.searchKeywords.data,
          loading: true,
          error: undefined,
        },
      }
    case type.searchCandidates.sortResults.requested:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          sortBy: action.payload as string,
        },
      }
    case type.searchCandidates.saveSearch.requested:
    case type.searchCandidates.getSavedSearch.requested:
    case type.searchCandidates.getSavedSearchFavorites.requested:
    case type.searchCandidates.deleteSavedSearch.requested:
    case type.searchCandidates.updateSavedSearch.requested:
      return {
        ...state,
        savedSearch: {
          data: state.savedSearch.data,
          loading: true,
          error: undefined,
        },
      }
    case type.searchCandidates.clearSavedSearch.requested:
      return {
        ...state,
        savedSearch: {
          data: [],
          loading: false,
          error: undefined,
        },
      }
    case type.searchCandidates.clearSearch.requested:
      return {
        ...state,
        searchResults: cloneDeep(initialState.searchResults),
      }
    case type.searchCandidates.setFiltersType.requested:
      return {
        ...state,
        filtersType: action.payload as CandidateSearchFiltersType,
      }
    case type.searchCandidates.search.succeeded:
      const searchPayload = action.payload as CandidateSearchResultsType
      return {
        ...state,
        searchResults: {
          data: searchPayload.data,
          total: searchPayload.total,
          loading: false,
          error: undefined,
          loadMore: searchPayload.loadMore,
          loadMoreProgress: false,
          sortBy: state.searchResults.sortBy,
          facets: searchPayload.facets,
        },
      }
    case type.searchCandidates.loadMore.succeeded:
      const loadMorePayload = action.payload as CandidateSearchResultsType
      return {
        ...state,
        searchResults: {
          data: [...state.searchResults.data, ...loadMorePayload.data],
          total: loadMorePayload.total,
          loading: false,
          error: undefined,
          loadMore: loadMorePayload.loadMore,
          loadMoreProgress: false,
          sortBy: state.searchResults.sortBy,
          facets: loadMorePayload.facets,
        },
      }
    case type.searchCandidates.sendContactRequestsBulk.succeeded:
      const sendContactRequestBulkResponse =
        action.payload as IncomingRequestType[]
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          data: state.searchResults.data.map((item) => {
            let newItemValue = item
            sendContactRequestBulkResponse.forEach((resItem) => {
              if (item.userId === resItem.seekerUserId) {
                newItemValue = {
                  ...item,
                  contactRequest: resItem,
                }
              }
            })
            return newItemValue
          }),
        },
      }

    case type.searchCandidates.inviteToTestingBulk.succeeded:
      return {
        ...state,
        toastMessage: {
          show: true,
          message: action.payload as string,
        },
      }
    case type.candidateSearch.requestContact.save.succeeded:
      const sendContactRequestResponse =
        action.payload as SendContactRequestResponseType

      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          data: state.searchResults.data.map((item) => {
            if (item.userId === sendContactRequestResponse?.id) {
              return {
                ...item,
                contactRequest: sendContactRequestResponse.contactRequest,
              }
            }
            return item
          }),
        },
      }

    case type.myProjects.removeFromProjects.succeeded:
      const removeFromProjectPayload =
        action.payload as RemoveCandidateFromProjectsType
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          data: state.searchResults.data.map((result) => {
            if (removeFromProjectPayload?.seekerId === result.userId) {
              const oldProjectValues = cloneDeep(result.projects)
              const newProjectValues: EmployerProjectCandidatesItemType[] = []
              oldProjectValues.forEach((proj) => {
                if (
                  !removeFromProjectPayload.projectIds.includes(
                    proj.companyUserProject.id
                  )
                )
                  newProjectValues.push(proj)
              })
              return {
                ...result,
                projects: newProjectValues,
              }
            }
            return result
          }),
        },
      }
    case type.myProjects.removeFromAllProjects.succeeded:
      const removeFromAllProjectPayload =
        action.payload as RemoveCandidateFromProjectsType
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          data: state.searchResults.data.map((result) => {
            if (removeFromAllProjectPayload?.seekerId === result.userId) {
              const newProjectValues: EmployerProjectCandidatesItemType[] = []
              return {
                ...result,
                projects: newProjectValues,
              }
            }
            return result
          }),
        },
      }
    case type.myProjects.removeFromProjects.failed:
    case type.myProjects.removeFromAllProjects.failed:
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          data: state.searchResults.data,
          loading: false,
          error: action.payload as ErrorType,
        },
      }
    case type.myProjects.addNewProject.succeeded:
      const addNewProjectResponse =
        action.payload as AddEmployerProjectPayloadType
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          data: state.searchResults.data.map((result) => {
            if (addNewProjectResponse.seekerId === result.id) {
              return {
                ...result,
                projects: [
                  ...result.projects,
                  {
                    seeker: null,
                    id: '',
                    companyUserProject: addNewProjectResponse.project,
                  },
                ],
              }
            }
            return result
          }),
        },
      }
    case type.myProjects.addToProject.succeeded:
      const addToProjectResponse =
        action.payload as EmployerProjectCandidatesItemType[]
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          data: state.searchResults.data.map((result) => {
            if (addToProjectResponse[0]?.seeker?.id === result.id) {
              return {
                ...result,
                projects: [...result.projects, ...addToProjectResponse],
              }
            }
            return result
          }),
        },
      }
    case type.myProjects.addToNewProject.succeeded:
      const addToNewProjectResponse =
        action.payload as EmployerProjectCandidatesItemType
      let payloadArray: EmployerProjectCandidatesItemType[] = []
      payloadArray.push(addToNewProjectResponse)
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          data: state.searchResults.data.map((result) => {
            if (payloadArray[0].seeker?.id === result.id) {
              return {
                ...result,
                projects: [...result.projects, ...payloadArray],
              }
            }
            return result
          }),
        },
      }
    case type.searchKeywords.succeeded:
      return {
        ...state,
        searchKeywords: {
          data: action.payload as TaxonomyWithCategoryType[],
          loading: false,
          error: undefined,
        },
      }
    case type.searchCandidates.seekerEvaluation.add.succeeded:
    case type.searchCandidates.seekerEvaluation.edit.succeeded:
      const evaluationPayload = action.payload as SeekerEvaluationType
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          data: state.searchResults.data.map((result) => {
            if (evaluationPayload?.seekerId === result.id) {
              return {
                ...result,
                seekerEvaluation: [
                  {
                    seekerId: evaluationPayload.seekerId,
                    evaluation: evaluationPayload.evaluation,
                  },
                ],
              }
            }
            return result
          }),
        },
      }
    case type.employer.allRequests.rateCandidate.add.succeeded:
      const addRatePayload = action.payload as DashboardSeekerEvaluationType
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          data: state.searchResults.data.map((result) => {
            if (addRatePayload.requestId === result.userId) {
              return {
                ...result,
                seekerEvaluations: [{evaluation: addRatePayload.evaluation}],
              }
            }
            return result
          }),
        },
      }

    case type.employer.allRequests.rateCandidate.edit.succeeded:
      const editRatePayload = action.payload as DashboardSeekerEvaluationType
      return {
        ...state,
        searchResults: {
          ...state.searchResults,
          data: state.searchResults.data.map((result) => {
            if (editRatePayload.requestId === result.userId) {
              return {
                ...result,
                seekerEvaluations: result.seekerEvaluations.map(
                  (seekerEvaluation) => {
                    return {
                      ...seekerEvaluation,
                      evaluation: editRatePayload.evaluation,
                    }
                  }
                ),
              }
            }
            return result
          }),
        },
      }

    case type.searchCandidates.saveSearch.succeeded:
      return {
        ...state,
        savedSearch: {
          data: [...state.savedSearch.data, action.payload as SavedSearchType],
          loading: false,
          error: undefined,
        },
      }
    case type.searchCandidates.getSavedSearchFavorites.succeeded:
    case type.searchCandidates.getSavedSearch.succeeded:
      return {
        ...state,
        savedSearch: {
          ...state.savedSearch,
          data: action.payload as SavedSearchType[],
          loading: false,
          error: undefined,
        },
      }
    case type.searchCandidates.deleteSavedSearch.succeeded:
      return {
        ...state,
        savedSearch: {
          data: state.savedSearch.data.filter(
            (s) => s.id !== (action.payload as string)
          ),
          loading: false,
          error: undefined,
        },
      }
    case type.searchCandidates.updateSavedSearch.succeeded:
      const newSavedSearch = action.payload as SavedSearchType
      const index = state.savedSearch.data.findIndex(
        (s) => s.id === newSavedSearch.id
      )

      return {
        ...state,
        savedSearch: {
          data: [
            ...state.savedSearch.data.slice(0, index),
            newSavedSearch,
            ...state.savedSearch.data.slice(index + 1),
          ],
          loading: false,
          error: undefined,
        },
      }
    case type.searchCandidates.getPopularCandidates.succeeded:
      return {
        ...state,
        popularCandidates: {
          data: action.payload as PopularCandidateType[],
          loading: false,
          error: undefined,
        },
      }
    case type.searchCandidates.search.failed:
    case type.searchCandidates.loadMore.failed:
    case type.searchCandidates.inviteToTestingBulk.failed:
    case type.searchCandidates.seekerEvaluation.add.failed:
      return {
        ...state,
        searchResults: {
          data: state.searchResults.data,
          total: state.searchResults.total,
          loading: false,
          error: action.payload as ErrorType,
          loadMore: undefined,
          loadMoreProgress: false,
          sortBy: state.searchResults.sortBy,
          facets: state.searchResults.facets,
        },
      }
    case type.searchKeywords.failed:
      return {
        ...state,
        searchKeywords: {
          data: state.searchKeywords.data,
          loading: false,
          error: action.payload as ErrorType,
        },
      }
    case type.searchCandidates.saveSearch.failed:
    case type.searchCandidates.getSavedSearch.failed:
    case type.searchCandidates.getSavedSearchFavorites.failed:
    case type.searchCandidates.updateSavedSearch.failed:
    case type.searchCandidates.deleteSavedSearch.failed:
      return {
        ...state,
        savedSearch: {
          ...state.savedSearch,
          loading: false,
          error: action.payload as ErrorType,
        },
      }
    case type.searchCandidates.getPopularCandidates.failed:
      return {
        ...state,
        popularCandidates: {
          ...state.popularCandidates,
          loading: false,
          error: action.payload as ErrorType,
        },
      }
    default:
      return state
  }
}

export default CandidateSearchReducer
