import Title from 'Components/Title'
import {FC, useEffect, useState} from 'react'
import {
  BaseTaxonomyType,
  SystemLanguageType,
  TaxonomiesStateType,
  WelcomeMessageDataType,
  WelcomeMessageTranslatedTextDataType,
} from 'Interfaces'
import Hr from 'Components/Hr'
import {LanguagesCards} from '../LanguagesCards'
import styles from './EditWelcomeMessageForm.module.scss'
import TextField from 'Components/Inputs/TextField'
import {OnChangeProps} from 'Utils/FormikProps'
import {CompanyWelcomeMessageTranslations as cwmt} from 'Services/I18n/Constants'
import {Button} from 'Components/Button'
import {
  VisibilityOffOutlined,
  VisibilityOutlined,
  DeleteOutlineOutlined,
} from '@mui/icons-material'
import {setupJobCategoriesLanguages} from '../Utils'
import HTMLInput from 'Components/Inputs/HTMLInput'

interface EditWelcomeMessageFormProps {
  welcomeMessageIndex: number
  translationTextIndex: number
  formik: any
  taxonomies: TaxonomiesStateType
  remove: Function
  welcomeMessage: WelcomeMessageDataType
  translatedText: WelcomeMessageTranslatedTextDataType
  jobCategoriesLanguagesState: any
  setJobCategoriesLanguagesState: Function
}

export const EditWelcomeMessageForm: FC<EditWelcomeMessageFormProps> = ({
  welcomeMessageIndex,
  translationTextIndex,
  formik,
  taxonomies,
  remove,
  welcomeMessage,
  translatedText,
  jobCategoriesLanguagesState,
  setJobCategoriesLanguagesState,
}) => {
  const [selectedLanguageState, setSelectedLanguageState] = useState<any>()
  const [prevSelectedLanguageState, setPrevSelectedLanguageState] =
    useState<any>()
  const [visibilityStatus, setVisibilityStatus] = useState(
    formik.values.welcomeMessages[welcomeMessageIndex].translatedTexts[
      translationTextIndex
    ].visibility
  )

  useEffect(() => {
    setSelectedLanguageState(translatedText.language)
    setPrevSelectedLanguageState(translatedText.language)
  }, [])

  const translatedTextIndex = welcomeMessage.translatedTexts.findIndex(
    (item) => item.id === translatedText.id
  )

  const jobCategoryTaxonomy: BaseTaxonomyType | undefined =
    taxonomies.jobCategories.data.find(
      (jobCategory) => jobCategory.id === welcomeMessage.jobCategory.id
    )

  // Update JobCategoriesLanguages - When jobCategory and Language selected (job category is by default for editing messages)
  useEffect(() => {
    if (jobCategoryTaxonomy?.id && selectedLanguageState) {
      const selectedJobCategoryId = jobCategoryTaxonomy.id
      const oldLanguagesIds = jobCategoriesLanguagesState[selectedJobCategoryId]
      const newLanguagesIds = oldLanguagesIds?.filter(
        (languageId: string) => languageId !== selectedLanguageState.id
      )

      const newJobCategoriesLanguages = {
        ...jobCategoriesLanguagesState,
        [selectedJobCategoryId]: newLanguagesIds,
      }
      setJobCategoriesLanguagesState(newJobCategoriesLanguages)
    }
  }, [jobCategoryTaxonomy, selectedLanguageState])

  //  Set Visibility statuses
  useEffect(() => {
    formik.setFieldValue(
      `welcomeMessages[${welcomeMessageIndex}].translatedTexts[${translatedTextIndex}].visibility`,
      visibilityStatus
    )
  }, [visibilityStatus])

  // Update lang state & add back unselected to available
  const handleSetLanguageState = (
    selectedLanguage: SystemLanguageType,
    name: string
  ) => {
    const selectedJobCategoryId = jobCategoryTaxonomy?.id
    if (selectedJobCategoryId) {
      const availableLanguagesIds =
        jobCategoriesLanguagesState[selectedJobCategoryId]

      // If available - select it
      if (availableLanguagesIds.includes(selectedLanguage.id)) {
        setSelectedLanguageState(selectedLanguage)

        // After Selecting new lang - update list of available langs(add back prev selected)
        const newLanguagesForJobCategory = availableLanguagesIds.filter(
          (languageId: string) => languageId !== selectedLanguage.id
        )
        newLanguagesForJobCategory.push(prevSelectedLanguageState.id)
        const newJobCategoriesLanguages = {
          ...jobCategoriesLanguagesState,
          [selectedJobCategoryId]: newLanguagesForJobCategory,
        }
        formik.setFieldValue(name, selectedLanguage)
        setJobCategoriesLanguagesState(newJobCategoriesLanguages)
        setPrevSelectedLanguageState(selectedLanguage)
      }
    }
  }

  return (
    <>
      <div className={styles.container}>
        <div className={styles.titleAndLanguagesContainer}>
          <div className={styles.visibility}>
            <span
              onClick={() => {
                const prevStatus =
                  formik.values.welcomeMessages[welcomeMessageIndex]
                    .translatedTexts[translatedTextIndex].visibility
                setVisibilityStatus(!prevStatus)
              }}
            >
              {visibilityStatus ? (
                <VisibilityOutlined
                  color="disabled"
                  className={styles.visibilityIcon}
                />
              ) : (
                <VisibilityOffOutlined
                  color="disabled"
                  className={styles.visibilityIcon}
                />
              )}
            </span>

            <Title
              text={
                jobCategoryTaxonomy?.translation
                  ? jobCategoryTaxonomy?.translation
                  : welcomeMessage.jobCategory.translation
              }
              className={styles.title}
              theme="modal"
            />
          </div>

          {/* LANGUAGES BTNs */}
          <div className={styles.languagesWrapper}>
            <LanguagesCards
              taxonomies={taxonomies}
              selectedLanguageState={selectedLanguageState}
              handleSetLanguageState={(selectedValue) =>
                handleSetLanguageState(
                  selectedValue,
                  `welcomeMessages[${welcomeMessageIndex}].translatedTexts[${translatedTextIndex}].language`
                )
              }
            />
          </div>
        </div>

        {/* WELCOME TITLE TextInput */}
        <TextField
          theme="modal"
          name={`welcomeMessages[${welcomeMessageIndex}].translatedTexts[${translatedTextIndex}].welcomeTitle`}
          placeholder={cwmt.welcomeTitlePlaceholderText}
          {...OnChangeProps(
            formik,
            `welcomeMessages[${welcomeMessageIndex}].translatedTexts[${translatedTextIndex}].welcomeTitle`
          )}
          value={
            formik.values.welcomeMessages[welcomeMessageIndex]?.translatedTexts[
              translatedTextIndex
            ].welcomeTitle
          }
          error={
            formik?.submitCount &&
            formik?.errors?.welcomeMessages?.[welcomeMessageIndex]
              ?.translatedTexts[translatedTextIndex]?.welcomeTitle
              ? formik.errors?.welcomeMessages?.[welcomeMessageIndex]
                  ?.translatedTexts[translatedTextIndex]?.welcomeTitle
              : undefined
          }
        />
        <Hr theme="modalSmall" />
        {/* WELCOME TEXT TextInput */}
        <HTMLInput
          name={`welcomeMessages[${welcomeMessageIndex}].translatedTexts[${translatedTextIndex}].welcomeText`}
          onChange={formik.setFieldValue}
          setError={formik.setFieldError}
          charLimit={750}
          value={
            formik.values.welcomeMessages[welcomeMessageIndex]?.translatedTexts[
              translatedTextIndex
            ].welcomeText
          }
          error={
            formik?.errors?.welcomeMessages?.[welcomeMessageIndex]
              ?.translatedTexts[translatedTextIndex]?.welcomeText || undefined
          }
        />

        {/* DELETE BUTTON */}
        {formik.values.welcomeMessages.length > 0 && (
          <>
            <div className={styles.deleteFormikItemButtonWrapper}>
              <Button
                onClick={() => {
                  // If only this(1) text - remove whole message:
                  if (
                    formik.values.welcomeMessages[welcomeMessageIndex]
                      .translatedTexts.length === 1
                  ) {
                    remove(welcomeMessageIndex)

                    // Set removed jobCategory-language to be available option:
                    const newWelcomeMessages = [
                      ...formik.values.welcomeMessages,
                    ]
                    newWelcomeMessages?.splice(welcomeMessageIndex, 1)

                    const newJobCategoriesLanguages =
                      setupJobCategoriesLanguages(
                        taxonomies,
                        newWelcomeMessages
                      )
                    setJobCategoriesLanguagesState(newJobCategoriesLanguages)
                  }

                  // If more than 1 texts , remove only selected for deletion:
                  if (
                    formik.values.welcomeMessages[welcomeMessageIndex]
                      .translatedTexts.length > 1
                  ) {
                    const newTranslatedTexts = formik.values.welcomeMessages[
                      welcomeMessageIndex
                    ].translatedTexts.filter(
                      (translatedTextFormik: any) =>
                        translatedTextFormik.id !== translatedText.id
                    )

                    // Remove From formik -> UI and for request:
                    formik.setFieldValue(
                      `welcomeMessages[${welcomeMessageIndex}].translatedTexts`,
                      newTranslatedTexts
                    )

                    // Set removed jobCategory-language to be available option:
                    const newWelcomeMessages = [
                      ...formik.values.welcomeMessages,
                    ]
                    newWelcomeMessages?.[
                      welcomeMessageIndex
                    ]?.translatedTexts?.splice(translationTextIndex, 1)

                    const newJobCategoriesLanguages =
                      setupJobCategoriesLanguages(
                        taxonomies,
                        newWelcomeMessages
                      )
                    setJobCategoriesLanguagesState(newJobCategoriesLanguages)
                  }
                }}
                text={cwmt.deleteButtonText}
                className={styles.deleteFormikItemButton}
                icon={<DeleteOutlineOutlined />}
              />
            </div>
            <Hr className={styles.hr} />
          </>
        )}
      </div>
    </>
  )
}
