export enum EmailVerification {
  pending = 'emailverification.pending',
  pleaseWaitText = 'emailverification.pleaseWaitText',
  verificationFailed = 'emailverification.verificationFailed',
  verificationFailedDescription = 'emailverification.verificationFailedDescription',
  email = 'emailverification.email',
  verificationResend = 'emailverification.verificationResend',
  verifyYourAccount = 'emailverification.verifyYourAccount',
  verifyYourAccountDescription = 'emailverification.verifyYourAccountDescription',
  emailResentTitle = 'emailverification.emailResentTitle',
  emailResentDescription = 'emailverification.emailResentDescription',
  emailVerificationNoticeTitle = 'emailVerificationNotice.title',
  emailVerificationNoticeDesc = 'emailVerificationNotice.description',
  emailVerificationNoticeButton = 'emailVerificationNotice.button',
  emailVerificationNoticeEmail = 'emailVerificationNotice.email',
}
