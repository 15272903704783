export const changepasswordsettings = {
  'changepasswordsettings.title': 'My Account',
  'changepasswordsettings.titleChangePassword': 'CHANGE PASSWORD',
  'changepasswordsettings.oldPassword': 'Old password',
  'changepasswordsettings.newPassword': 'New password',
  'changepasswordsettings.repeatNewPassword': 'Repeat new password',
  'changepasswordsettings.cancel': 'Cancel',
  'changepasswordsettings.saveChanges': 'Save changes',
  'changepasswordsettings.successMessage':
    'Your password was changed successfully.',
}
