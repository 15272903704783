import {FC, ReactNode, useEffect, useMemo, useState} from 'react'
import classNames from 'classnames'
import {FormattedMessage} from 'react-intl'
import {useHistory} from 'react-router-dom'

import {Button} from 'Components/Button'
import DropdownMultiple from 'Components/Inputs/DropdownMultiple'
import TypeAheadImproved from 'Components/Inputs/TypeAheadImproved'
import {
  AppRoute,
  EmployerDashboardTranslations as edt,
} from 'Services/I18n/Constants'
import {
  AuthStateType,
  Candidate,
  CandidateDropdownOptionType,
  EmployerRequestsSearchParamsType,
  IncomingRequestSeekerType,
  ItemType,
} from 'Interfaces'
import styles from './EmployerRequestsLayout.module.scss'
import {
  CombinedRequestStatues,
  employerRequestStatusTranslationMap,
  InterviewRequestStatus,
} from 'Components/IncomingRequests/Constants'
import {useTranslation} from 'Hooks'
import {getRoute} from 'Services/I18n/Utils'
import EmployerJourneyTracker from 'Components/EmployerDashboard/EmployerJourneyTracker'
import MainLayout from 'Layouts/MainLayout'
import {WidgetBarContainer as WidgetBar} from 'Layouts/MainLayout/WidgetBar'
import MyOffers from 'Components/Widgets/MyOffers'
import HelpfulHint from 'Components/Widgets/HelpfulHint'
import Breadcrumbs from 'Components/Breadcrumbs'
import CandidateSidebarContent from 'Components/EmployerDashboard/Sidebar/CandidateSidebarContent'
import SidebarActions from 'Components/EmployerDashboard/Sidebar/SidebarActions'
import {isEqual} from 'lodash'

const requestsLayoutTitle = {
  requests: edt.contactRequests,
  interviews: edt.interviewInvitations,
  offers: edt.offers,
  placements: edt.placements,
}

type RequestsLayoutType = 'requests' | 'interviews' | 'offers' | 'placements'

const tabsMap = {
  requests: {
    all: {
      title: edt.allRequestsTitle,
      label: edt.allRequests,
      link: getRoute(AppRoute.EmployerContactRequestsAll),
      defaultParams: {},
    },
    pending: {
      title: edt.pendingRequestsTitle,
      label: edt.pendingRequests,
      link: getRoute(AppRoute.EmployerContactRequestsPending),
      defaultParams: {statuses: [CombinedRequestStatues.PENDING]},
    },
    approved: {
      title: edt.approvedRequestsTitle,
      label: edt.approvedRequests,
      link: getRoute(AppRoute.EmployerContactRequestsApproved),
      defaultParams: {statuses: [CombinedRequestStatues.APPROVED]},
    },
  },
  interviews: {
    all: {
      title: edt.allinterviewInvitationsTitle,
      label: edt.allInterviews,
      link: getRoute(AppRoute.EmployerInterviewInvitationsAll),
      defaultParams: {},
    },
    pending: {
      title: edt.pendinginterviewInvitationsTitle,
      label: edt.pendingInterviews,
      link: getRoute(AppRoute.EmployerInterviewInvitationsPending),
      defaultParams: {statuses: [InterviewRequestStatus.PENDING]},
    },
    accepted: {
      title: edt.acceptedinterviewInvitationsTitle,
      label: edt.acceptedInterviews,
      link: getRoute(AppRoute.EmployerInterviewInvitationsAccepted),
      defaultParams: {statuses: [InterviewRequestStatus.INTERVIEW_CONFIRMED]},
    },
    completed: {
      title: edt.completedinterviewInvitationsTitle,
      label: edt.completedInterviews,
      link: getRoute(AppRoute.EmployerInterviewInvitationsCompleted),
      defaultParams: {statuses: [InterviewRequestStatus.COMPLETED]},
    },
  },
  offers: {
    all: {
      title: edt.allOffersTitle,
      label: edt.allOffers,
      link: getRoute(AppRoute.EmployerOffersAll),
      defaultParams: {},
    },
    pending: {
      title: edt.pendingOffersTitle,
      label: edt.pendingOffers,
      link: getRoute(AppRoute.EmployerOffersPending),
      defaultParams: {statuses: [CombinedRequestStatues.PENDING]},
    },
    accepted: {
      title: edt.acceptedOffersTitle,
      label: edt.acceptedOffers,
      link: getRoute(AppRoute.EmployerOffersAccepted),
      defaultParams: {statuses: [CombinedRequestStatues.ACCEPTED]},
    },
  },
  placements: {
    all: {
      title: edt.allPlacements,
      label: edt.allPlacements,
      link: getRoute(AppRoute.EmployerPlacementsAll),
      defaultParams: {},
    },
    pending: {
      title: edt.pendingPlacements,
      label: edt.pendingPlacements,
      link: getRoute(AppRoute.EmployerPlacementsPending),
      defaultParams: {
        statuses: [
          CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_PENDING,
          CombinedRequestStatues.COMPANY_USER_PENDING_SEEKER_CONFIRMED,
          CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_PENDING,
        ],
      },
    },
    confirmed: {
      title: edt.confirmedPlacements,
      label: edt.confirmedPlacements,
      link: getRoute(AppRoute.EmployerPlacementsConfirmed),
      defaultParams: {
        statuses: [
          CombinedRequestStatues.COMPANY_USER_CONFIRMED_SEEKER_CONFIRMED,
        ],
      },
    },
  },
}

type TabsMapType = typeof tabsMap

const Tab: FC<{
  label: string
  active: boolean
  onClick: any
}> = ({label, active, onClick}) => {
  return (
    <div
      onClick={onClick}
      className={classNames({[styles.tab]: true, [styles.tabActive]: active})}
    >
      {label}
    </div>
  )
}

type EmployerRequestsLayoutProps = {
  auth: AuthStateType
  tabs: string[]
  activeTab: string
  type: RequestsLayoutType
  currentParams?: EmployerRequestsSearchParamsType
  onSearch: (params: EmployerRequestsSearchParamsType) => void
  getSeekers: (activeTab?: string) => void
  candidatesOptions: CandidateDropdownOptionType[]
  statusOptions: string[]
  candidateBasicData: Candidate | IncomingRequestSeekerType | null
  children: ReactNode
  isFromPlacements?: boolean
  clearRequests: () => void
}

export const EmployerRequestsLayout: FC<EmployerRequestsLayoutProps> = ({
  auth,
  tabs,
  activeTab,
  type,
  currentParams,
  candidatesOptions,
  statusOptions,
  onSearch,
  getSeekers,
  candidateBasicData,
  children,
  isFromPlacements,
  clearRequests,
}) => {
  const history = useHistory()
  const translations = useTranslation()
  const defaultParams = {
    ...tabsMap[type][activeTab].defaultParams,
    seekerUserIds: [],
  }
  const [selectedParams, setSelectedParams] = useState(defaultParams)
  const [resetToggle, setResetToggle] = useState(false)
  const [resetTextInput, setResetTextInput] = useState(true)

  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: getRoute(AppRoute.HomePageEmployer)},
    {
      name: auth.data?.companyStructure?.company?.name
        ? auth.data.companyStructure.company.name
        : '',
      link: getRoute(AppRoute.CompanyProfile),
    },
    {name: edt.dashboard, link: getRoute(AppRoute.EmployerDashboard)},
    {
      name: `${translations[requestsLayoutTitle[type]]}`,
      link: tabsMap[type].all.link,
    },
    {
      name: `${translations[tabsMap[type][activeTab].title]}`,
      link: tabsMap[type][activeTab].link,
    },
  ]

  const SidebarContent = useMemo(() => {
    if (candidateBasicData) {
      return <CandidateSidebarContent />
    }
    return (
      <>
        <div className={styles.linksContainer}>
          <SidebarActions />
        </div>

        <HelpfulHint />

        {activeTab === 'offers' && (
          <div className={styles.myOffersContainer}>
            <MyOffers />
          </div>
        )}
      </>
    )
  }, [candidateBasicData, activeTab])

  const typeaheadItems = useMemo(() => {
    const items = candidatesOptions
      .filter((c) => c.label)
      .map((c) => ({
        id: c.id,
        translation: c.label,
      }))
    const seen = new Set()
    const uniqueItems = items.filter((item) => {
      const duplicate = seen.has(item.translation)
      seen.add(item.translation)
      return !duplicate
    })
    return uniqueItems
  }, [candidatesOptions, resetToggle])

  const submitSearch = () => {
    let statuses = []
    if (activeTab !== 'all' || selectedParams.statuses) {
      statuses = selectedParams.statuses || currentParams?.statuses
      if (selectedParams.statuses.includes('company_user_rejected')) {
        statuses = [
          ...statuses,
          'company_user_rejected_seeker_pending',
          'company_user_rejected_seeker_confirmed',
        ].filter((item: string) => item !== 'company_user_rejected')
      }

      if (selectedParams.statuses.includes('seeker_user_rejected')) {
        statuses = [
          ...statuses,
          'company_user_pending_seeker_rejected',
          'company_user_confirmed_seeker_rejected',
        ].filter((item: string) => item !== 'seeker_user_rejected')
      }
    }

    onSearch({
      ...currentParams,
      ...selectedParams,
      statuses,
      seekerUserIds: selectedParams.seekerUserIds.map((sp: ItemType) => sp.id),
    })
  }

  const resetSearch = () => {
    setSelectedParams(defaultParams)
    setResetToggle(!resetToggle)
    onSearch({...currentParams, ...defaultParams})
    setResetTextInput(!resetTextInput)
  }

  useEffect(() => {
    clearRequests()
    submitSearch()
    getSeekers(activeTab !== 'all' ? activeTab : undefined)
  }, [])

  useEffect(() => {
    clearRequests()
    resetSearch()
    getSeekers(activeTab !== 'all' ? activeTab : undefined)
  }, [activeTab])

  return (
    <MainLayout
      fixedRight={false}
      rightSidebarContent={
        <WidgetBar
          sidebarContent={SidebarContent}
          containerClass=".requestItemWrap"
        />
      }
      SubNavigation={
        <div className={styles.breadcrumbsWrapper}>
          <Breadcrumbs breadcrumbs={breadcrumbsArray} />
        </div>
      }
    >
      <EmployerJourneyTracker />
      <div className={styles.employerRequestsWrap}>
        <div className={styles.heading}>
          {`${translations[tabsMap[type][activeTab].title]}`}
        </div>
        <div className={styles.tabsContainer}>
          <div className={styles.tabsWrap}>
            {tabs.map((tab, idx) =>
              type === 'interviews' ? (
                <Tab
                  key={idx}
                  label={`${translations[tabsMap[type][tab].label]}`}
                  active={activeTab === tab}
                  onClick={() => history.push(tabsMap[type][tab].link)}
                />
              ) : (
                <Tab
                  key={idx}
                  label={`${translations[tabsMap[type][tab].label]}`}
                  active={activeTab === tab}
                  onClick={() => history.push(tabsMap[type][tab].link)}
                />
              )
            )}
          </div>
        </div>
        <div
          className={
            activeTab === 'all' ? styles.allCtasWrap : styles.otherCtasWrap
          }
        >
          <TypeAheadImproved
            name="candidateId"
            defaultValue={selectedParams.seekerUserIds}
            items={typeaheadItems}
            theme="requests"
            placeholder={edt.searchCandidatesLabel}
            setValue={(_, values) => {
              if (!isEqual(values, selectedParams.seekerUserIds)) {
                const duplicatedItems = candidatesOptions.filter((option) =>
                  values.some((value) => value.translation === option.label)
                )
                const seen = new Set()
                const duplicates = duplicatedItems.filter((item) => {
                  const duplicate = seen.has(item.label)
                  seen.add(item.label)
                  return duplicate
                })
                setSelectedParams((old: TabsMapType) => ({
                  ...old,
                  seekerUserIds: duplicates.length
                    ? [...values, ...duplicates]
                    : values,
                }))
              }
            }}
            resetTextInput={resetTextInput}
          />

          {activeTab === 'all' && isFromPlacements && (
            <DropdownMultiple
              name="requestStatus"
              items={
                isFromPlacements
                  ? [
                      ...statusOptions,
                      'company_user_rejected',
                      'seeker_user_rejected',
                    ]
                      .filter(
                        (item) =>
                          item !== 'company_user_rejected_seeker_pending' &&
                          item !== 'company_user_rejected_seeler_confirmed' &&
                          item !== 'company_user_rejected_seeker_confirmed' &&
                          item !== 'company_user_pending_seeker_rejected' &&
                          item !== 'company_user_confirmed_seeker_rejected'
                      )
                      .map((s) => ({
                        id: `${s}`,
                        translation:
                          translations[employerRequestStatusTranslationMap[s]],
                      }))
                  : statusOptions.map((s) => ({
                      id: `${s}`,
                      translation:
                        translations[employerRequestStatusTranslationMap[s]],
                    }))
              }
              emptyValueLabel={edt.statusLabel}
              values={selectedParams.statuses}
              setValues={(_name, values) =>
                setSelectedParams((old: TabsMapType) => ({
                  ...old,
                  statuses: values,
                }))
              }
            />
          )}
          {activeTab === 'all' && !isFromPlacements && (
            <DropdownMultiple
              name="requestStatus"
              items={statusOptions.map((s) => ({
                id: `${s}`,
                translation:
                  translations[employerRequestStatusTranslationMap[s]],
              }))}
              emptyValueLabel={edt.statusLabel}
              values={selectedParams.statuses}
              setValues={(_name, values) =>
                setSelectedParams((old: TabsMapType) => ({
                  ...old,
                  statuses: values,
                }))
              }
            />
          )}
          <Button
            text={edt.search}
            theme="modalSubmit"
            className={styles.btnWidth}
            onClick={submitSearch}
          />
        </div>
        <div className={styles.resetSearchBtn} onClick={resetSearch}>
          <FormattedMessage id={edt.resetLabel} />
        </div>
        <div className={styles.searchResults}>{children}</div>
      </div>
    </MainLayout>
  )
}
