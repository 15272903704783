import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import {appStrings} from '..'

export const useMatchingRoute = () => {
  const {pathname} = useLocation()
  const {messages} = useIntl()

  // Todo: Improve logic..( '/' is not for sure localization separator)
  //  Assumes that every language has 2 char long prefix - /en/, /sr/.. (besides slovenian - default)
  let lang = 'sl'
  let route = pathname
  if (pathname[3] === '/') {
    lang = pathname.slice(1, 3)
    route = pathname.substring(3) // remove local part '/en' from the pathname /en/contact
  }

  const getMatchingRoute = () => {
    /**
     * Get the key of the route the user is currently on
     */
    const routeKey = Object.keys(messages).find((key) => {
      return messages[key] === route
    })

    /**
     * Find the matching route for the new language
     */

    const matchingRoute = appStrings[lang][routeKey]

    return {
      localizedRoute: `/${lang}` + matchingRoute,
      route,
      routeKey,
    }
  }

  const [currentRoute, setCurrentRoute] = useState(getMatchingRoute())

  useEffect(() => {
    setCurrentRoute(getMatchingRoute())
  }, [pathname, lang, messages])

  return currentRoute
}
