import {connect} from 'react-redux'

import {YourCV} from './YourCV'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {
  clearOnboardingErrors,
  getJobCategories,
  saveStepUploadCV,
} from 'Store/Actions'
import {SaveStepUploadCvType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  onboarding: state.onboarding,
  jobCategories: state.taxonomies.jobCategories,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getJobCategories: () => dispatch(getJobCategories()),
  clearOnboardingErrors: () => dispatch(clearOnboardingErrors()),
  saveStepUploadCV: (values: SaveStepUploadCvType) =>
    dispatch(saveStepUploadCV(values)),
})

export const YourCVContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(YourCV)
