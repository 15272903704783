import {connect} from 'react-redux'

import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {
  getAvailabilities,
  getCareerLevels,
  getCompanyData,
  getCountries,
  getEducationLevels,
  getJobCategories,
  getJobSearchStatuses,
  getLanguageLevels,
  getLanguages,
  getOccupations,
  getRegions,
  getSkillLevels,
  getSkills,
  getTowns,
  searchCandidates,
  setSidebarClosed,
  getSearchKeywords,
  loadMoreCandidates,
  getSavedSearch,
  setCandidateSearchFiltersType,
  getTotalWorkExperiences,
  getDrivingLicenseCategories,
  getPopularCandidates,
  clearSearch,
  saveCandidateSearch,
  updateSavedSearch,
  setCandidateBasicData,
} from 'Store/Actions'
import {CandidateSearch} from './CandidateSearch'
import {
  SearchControlFormikValuesType,
  CandidateSearchFiltersType,
  SavedSearchType,
  Candidate,
  IncomingRequestSeekerType,
} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  company: state.company,
  candidateBasicData: state.widgetBar.data.candidateBasicData,
  sidebarClosed: state.widgetBar.data.sidebarClosed,
  taxonomies: state.taxonomies,
  searchKeywords: state.searchCandidates.searchKeywords,
  searchResults: state.searchCandidates.searchResults,
  savedSearch: state.searchCandidates.savedSearch,
  filtersType: state.searchCandidates.filtersType,
  popularCandidates: state.searchCandidates.popularCandidates,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setCandidateBasicData: (
    candidate: Candidate | IncomingRequestSeekerType | null
  ) => dispatch(setCandidateBasicData(candidate)),
  getCompanyData: () => dispatch(getCompanyData()),
  getSearchResults: (values: SearchControlFormikValuesType) =>
    dispatch(searchCandidates(values)),
  setSidebarClosed: (sidebarClosed: boolean) =>
    dispatch(setSidebarClosed(sidebarClosed)),
  setFiltersType: (filtersType: CandidateSearchFiltersType) =>
    dispatch(setCandidateSearchFiltersType(filtersType)),
  getTaxonomies: () => {
    dispatch(getAvailabilities())
    dispatch(getJobCategories())
    dispatch(getOccupations())
    dispatch(getRegions())
    dispatch(getEducationLevels())
    dispatch(getLanguages())
    dispatch(getLanguageLevels())
    dispatch(getSkills())
    dispatch(getSkillLevels())
    dispatch(getJobSearchStatuses())
    dispatch(getCareerLevels())
    dispatch(getTowns())
    dispatch(getCountries())
    dispatch(getTotalWorkExperiences())
    dispatch(getDrivingLicenseCategories())
    dispatch(getAvailabilities())
  },
  getSearchKeywords: () => dispatch(getSearchKeywords()),
  loadMoreCandidates: () => dispatch(loadMoreCandidates()),
  getSavedSearch: () => dispatch(getSavedSearch()),
  clearSearch: () => {
    dispatch(clearSearch())
  },
  getPopularCandidates: () => dispatch(getPopularCandidates()),
  saveCandidateSearch: (value: SavedSearchType) =>
    dispatch(saveCandidateSearch(value)),
  updateSavedSearch: (value: SavedSearchType) =>
    dispatch(updateSavedSearch(value)),
})

export const CandidateSearchContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(CandidateSearch)
