import {FC} from 'react'
import classnames from 'classnames'
import {FormattedMessage} from 'react-intl'

import styles from './Title.module.scss'

interface TitleProps {
  text: string
  className?: string
}

export const Title: FC<TitleProps> = ({text, className, children}) => (
  <h1 className={classnames(styles.title, className)}>
    <FormattedMessage id={text} />
    {children}
  </h1>
)
