export const companyImpressions = {
  'companyImpressions.impressionsTitle': 'Impressions',
  'companyImpressions.featuredTitle': 'Featured',

  'companyImpressions.modalTitle': 'Edit Impressions',
  'companyImpressions.modalImpressionsTitle': 'IMPRESSIONS',
  'companyImpressions.modalImpressionsTitleTitle': ' IMPRESSIONS TITLE',
  'companyImpressions.filesNumberLimit': 'You can upload up to 12 pictures.',
  'companyImpressions.supportedFileFormats':
    'Supported file formats: .jpg, .jpeg, .png, .gif. (Max size: 5 MB) You can upload up to 12 pictures.',
  'companyImpressions.apiError': 'An error has occurred',
}
