import classNames from 'classnames'
import {FC} from 'react'
import styles from './AboutCandidate.module.scss'

interface AboutCandidateProps {
  name: string
  proffession: string
  town?: string
  country?: string
  isAccepted?: boolean
  image?: boolean
}

export const AboutCandidate: FC<AboutCandidateProps> = ({
  name,
  proffession,
  town,
  country,
  isAccepted,
  image,
}) => {
  return (
    <div className={styles.aboutCandidate}>
      {isAccepted ? (
        <>
          <h2 className={classNames(styles.name, {[styles.withNoImg]: !image})}>
            {name}
          </h2>
          <p className={styles.proffession}>{proffession}</p>
        </>
      ) : (
        <>
          <h2 className={styles.proffessionOnSearch}>{proffession}</h2>
          {town && country && (
            <p className={styles.location}>
              {town}, {country}
            </p>
          )}
        </>
      )}
    </div>
  )
}
