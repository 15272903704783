const profileValidation = {
  'Field required': 'Polje je obvezno',
  'Attachment is required': 'Priložiti morate priponko',
  'Attachment name is required': 'Polje naziv priponke je obvezno',
  'Attachment type is required': 'Polje vrsta priponke je obvezno',
  'Title is required': 'Polje naziv je obvezno',
  'Education institution is required':
    'Polje izobraževalna ustanova je obvezno',
  'Training title is required': 'Polje naziv izobraževanja je obvezno',
  'Education level is required': 'Polje stopnja izobrazbe je obvezno',
  'City is required': 'Polje mesto je obvezno',
  'Start year is required': 'Polje leto začetka je obvezno',
  'Enter valid start year': 'Vnesite veljavno leto začetka',
  'End year is in the future, must select in progress':
    'Leto zaključka je v prihodnosti, izberite možnost "V teku"',
  'End year is in the past, must deselect in progress':
    'Leto zaključka je v preteklosti, odstranite možnost "V teku"',
  'End year cannot be before start year':
    'Leto zaključka ne more biti manjše kot Leto začetka',
  'End year is required if not in progress':
    'Polje leto zaključka je obvezno, če niste izbrali možnosti "V teku"',
  'End year is required': 'Polje Leto zaključka je obvezno',
  'Career level is required': 'Polje karierni nivo je obvezno',
  'Desired regions are required': 'Polje želene regije je obvezno',
  'Desired job position is required': 'Polje želena delovna mesta je obvezno',
  'Desired employment is required': 'Polje želena vrsta zaposlitve je obvezno',
  'Desired salary is required': 'Polje želena plača je obvezno',
  'Language is required': 'Polje jezik je obvezno',
  'Language level is required': 'Polje raven jezika je obvezno',
  'Start month is required': 'Polje mesec začetka je obvezno',
  'End month is required': 'Polje mesec zaključka je obvezno',
  'Availability is required field': 'Polje razpoložljivost je obvezno',
  'Work from home is required field': 'Polje delo od doma je obvezno',
  'Job title is required field': 'Polje Naziv delovnega mesta je obvezno',
  'Job classification is required field':
    'Polje Klasifikacija poklica je obvezno',
  'Experience is required field': 'Polje delovne izkušnje je obvezno',
  'Total work experience is required field':
    'Polje skupne delovne izkušnje je obvezno',
  'Skill is required': 'Polje znanja in spretnosti je obvezno',
  'Skill level is required': 'Polje nivo znanja je obvezno',
  'Employer is required': 'Polje delodajalec je obvezno',
  'Employment type is required': 'Polje vrsta zaposlitve je obvezno',
  'Job description type is required': 'Polje opis dela in dosežki je obvezno',
  'Job description is required': 'Polje opis dela in dosežki je obvezno',
  'Birthdate is required': 'Polje Datum rojstva je obvezno',
  'Include street number': 'Vključite hišno številko',
  'Region is required': 'Polje Regija je obvezno',
  'Last name is required': 'Polje Priimek je obvezno',
  'First name is required': 'Polje Ime je obvezno',
  'Profile picture is required': 'Polje Fotografija je obvezno',
  "Can't end before it started":
    'Datum zaključka ne more biti manjši kot Datum začetka',
  'CV parsing failed. Please check the file and try again or choose another file':
    'Razčlenjevanje življenjepisa ni bilo uspešno. Preverite, ali je datoteka, ki ste jo izbrali ustrezna in poskusite ponovno ali izberite drugo datoteko.',
}

export default profileValidation
