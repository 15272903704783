import {ApproveRejectModalRequestType} from 'Interfaces'
import {connect} from 'react-redux'
import {AppDispatch} from 'Store'
import {
  approveInterviewInvitation,
  rejectInterviewInvitation,
} from 'Store/Actions'
import {ModalInterviewInvitation} from './ModalInterviewInvitation'

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  approveInterviewInvitation: (payload: ApproveRejectModalRequestType) =>
    dispatch(approveInterviewInvitation(payload)),
  rejectInterviewInvitation: (payload: ApproveRejectModalRequestType) =>
    dispatch(rejectInterviewInvitation(payload)),
})

export const ModalInterviewInvitationContainer = connect(
  null,
  mapDispatchToProps
)(ModalInterviewInvitation)
