import {FC, ReactNode} from 'react'
import styles from './SectionContainer.module.scss'

interface SectionContainerProps {
  children: ReactNode
}

export const SectionContainer: FC<SectionContainerProps> = ({children}) => {
  return <div className={styles.sectionContainer}>{children}</div>
}
