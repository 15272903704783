import {FC, useEffect, useState} from 'react'
import {Link, useHistory} from 'react-router-dom'
import {FormattedMessage} from 'react-intl'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import styles from './Profile.module.scss'
import {EducationContainer} from 'Components/Profile/Education'
import {DrivingLicencesContainer} from 'Components/Profile/DrivingLicences'
import {AttachmentsContainer} from 'Components/Profile/Attachments'
import JobPreferencesContainer from 'Components/Profile/JobPreferences'
import {ProjectsContainer} from 'Components/Profile/Projects/ProjectsContainer'
import {WorkExperiencesContainer} from 'Components/Profile/WorkExperiences/WorkExperiencesContainer'
import MainLayout from 'Layouts/MainLayout'
import {AwardsContainer} from 'Components/Profile/Awards'
import {CertificationsContainer} from 'Components/Profile/Certifications'
import TrainingTitlesContainer from 'Components/Profile/TrainingTitles'
import AboutMeContainer from 'Components/Profile/AboutMe'
import LanguagesContainer from 'Components/Profile/Languages'
import {SkillsContainer} from 'Components/Profile/Skills'
import Hr from 'Components/Hr'
import YellowButton from 'Components/YellowButton'
import Modal from 'Components/Modal'
import {AuthStateType, RjbProfileStatus, SeekerStateType} from 'Interfaces'
import {AppRoute, ProfileTranslations as pt} from 'Services/I18n/Constants'
import ProfileSidebarContainer from 'Components/Profile/Sidebar'
import {getRoute} from 'Services/I18n/Utils'
import {canSubmitProfile} from 'Utils/Profile'
import {useIsEmployer} from 'Hooks/useIsEmployer'
import {CheckCircle, Warning} from '@mui/icons-material'

interface ProfileProps {
  auth: AuthStateType
  user: SeekerStateType
  getSeekerProfile: () => void
  confirmProfile: () => void
  getCompleteness: () => void
  profileCompleteness: number
}

export const Profile: FC<ProfileProps> = ({
  auth,
  user,
  profileCompleteness,
  getSeekerProfile,
  confirmProfile,
  getCompleteness,
}: ProfileProps) => {
  const progressPercentage = parseInt(`${profileCompleteness * 100}`)
  const [canConfirm, setCanConfirm] = useState(false)

  useEffect(() => {
    if (progressPercentage >= 85) {
      setCanConfirm(true)
    }
  }, [profileCompleteness])

  useEffect(() => {
    if (auth.data) getSeekerProfile()
  }, [auth.data, getSeekerProfile])

  const [modalOpen, setModalOpen] = useState(false)
  const [areYouSuremodalOpen, setAreYouSuremodalOpen] = useState(false)
  const [profileConfirmed, setProfileConfirmed] = useState(false)
  const history = useHistory()

  const handleConfirmProfile = () => {
    setProfileConfirmed(true)
    setAreYouSuremodalOpen(false)
    confirmProfile()
  }

  useEffect(() => {
    getCompleteness()
  }, [profileConfirmed])

  useEffect(() => {
    if (
      profileConfirmed &&
      (auth.data?.rjbProfileStatus === RjbProfileStatus.PENDING_APPROVAL ||
        auth.data?.rjbProfileStatus === RjbProfileStatus.PENDING_REVIEW) &&
      !auth.error
    )
      setModalOpen(true)
  }, [auth.loading, auth.data?.rjbProfileStatus, profileConfirmed])

  const isEmployer = useIsEmployer(auth)

  return (
    <MainLayout
      content={
        <ProfileSidebarContainer
          profileCompleteness={profileCompleteness}
          auth={auth}
        />
      }
    >
      <div className={styles.profileContainer}>
        {user.data && (
          <>
            <AboutMeContainer isEmployer={isEmployer} />
            <JobPreferencesContainer />
            <WorkExperiencesContainer />
            <EducationContainer />
            <LanguagesContainer />
            <DrivingLicencesContainer />
            <SkillsContainer />
            <CertificationsContainer />
            <TrainingTitlesContainer />
            <ProjectsContainer />
            <AwardsContainer />
            <AttachmentsContainer />
            <Hr className={styles.hr} />
            {canSubmitProfile(auth.data?.rjbProfileStatus) && (
              <div className={styles.confirmContainer}>
                <div className={styles.confirmWrap}>
                  <p className={styles.message}>
                    <FormattedMessage id={pt.profileConfirmationTextPart1} />
                    <b>
                      <FormattedMessage id={pt.profileConfirmationBoldText} />
                    </b>
                    <FormattedMessage id={pt.profileConfirmationTextPart2} />
                    <Link
                      to={getRoute(AppRoute.CandidateDashboard)}
                      className={styles.link}
                    >
                      <FormattedMessage id={pt.profileConfirmationLink} />
                    </Link>
                    <FormattedMessage id={pt.profileConfirmationTextPart3} />
                  </p>
                  <div className={styles.button}>
                    <YellowButton
                      text={pt.confirmButton}
                      onClick={() => setAreYouSuremodalOpen(true)}
                      disabled={!canConfirm}
                    />
                  </div>
                </div>
              </div>
            )}
            <Modal
              isOpen={modalOpen}
              onAfterClose={() => {
                setModalOpen(false)
                history.push(getRoute(AppRoute.CandidateDashboard))
              }}
              title={pt.profileConfirmedTitle}
              icon={CheckCircle}
              ariaHideApp={false}
              className={styles.modal}
            >
              <FormattedMessage id={pt.profileConfirmedText} />
            </Modal>
            <Modal
              isOpen={areYouSuremodalOpen}
              onAfterClose={() => {
                setAreYouSuremodalOpen(false)
              }}
              title={pt.areYouSure}
              icon={Warning}
              ariaHideApp={false}
              className={styles.modalAreYouSure}
              Footer={
                <SubmitFooter
                  submitText={pt.confirmProfile}
                  onSubmit={handleConfirmProfile}
                  onCancel={() => {
                    setAreYouSuremodalOpen(false)
                  }}
                  theme="spaceBetween"
                />
              }
            >
              <FormattedMessage id={pt.areYouSureYouWant1} />
            </Modal>
          </>
        )}
      </div>
    </MainLayout>
  )
}
