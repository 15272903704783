import classNames from 'classnames'
import styles from './CommentBubble.module.scss'

export interface Props {
  text: string
  className?: string
}

export const CommentBubble = ({text, className}: Props) => {
  return (
    <>
      <div className={classNames(styles.bubbleContainer, className)}>
        <p className={styles.bubbleText}>{text}</p>
      </div>
    </>
  )
}
