export const aboutUs = {
  'aboutUs.mainTitle': 'ABOUT US',
  'aboutUs.paragraphFirst':
    'Preferenca came to life in 2022. What we lack in age, we make up for in our drive and experience. Our team consists of a group of innovative HR experts using advanced technology and an empathetic approach in order to connect ambitious individuals to successful companies.',
  'aboutUs.title': 'What is Preferenca?',
  'aboutUs.paragraphSecond':
    'Preferenca is the first employment platform in Slovenia focusing on jobseekers, particularly developers, mechanical engineers and electrical engineers. The platform helps them find employment that meets their requirements, be it state-of-the-art technology, an inspiring team, or simply a salary that cannot be turned down. Here at Preferenca, we have changed the rules of the game: companies are the one applying and the jobseekers get to choose.',
  'aboutUs.label':
    'Preferenca is a product trademark of the company Styria digital marketplaces d.o.o.',
}
