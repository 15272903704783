import {CandidateSearchTranslations as cs} from 'Services/I18n/Constants'
import {useTranslation} from 'Hooks'

export const getFacetName = (key: string): string => {
  const translation = useTranslation()
  const values = {
    availabilityFacets: translation[cs.availabilityFacets],
    // additionalBenefitFacets: 'Additional Benefit',
    // careerStatusFacets: translation[cs.careerStatusFacets],
    currentJobCategoryFacets: translation[cs.currentJobCategoryFacets],
    // educationFieldOfStudyFacets: 'Education',
    educationLevelFacets: translation[cs.educationLevelFacets],
    // employmentTypeFacets: 'Employment type',
    languageTaxonomyFacets: translation[cs.languagesFacet],
    languageLevelFacets: translation[cs.languageLevelFacets],
    occupationFacets: translation[cs.occupationFacet],
    regionFacets: translation[cs.regionsDropdown],
    // salaryFacets: 'Salary',
    skillFacets: translation[cs.skillsFacet],
    skillLevelFacets: translation[cs.skillLevelsFacet],
    totalWorkExperienceFacets: translation[cs.workExperienceFacet],
    drivingLicenseFacets: translation[cs.drivingLicenseFacet],
    // workExperienceJobCategoryFacets: 'Work experience job category',
  }
  return values[key] || ''
}
