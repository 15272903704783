import {SavedSearch} from './SavedSearch'
import {connect} from 'react-redux'
import {
  getSavedSearch,
  updateSavedSearch,
  deleteSavedSearch,
} from 'Store/Actions'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {SavedSearchType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  savedSearch: state.searchCandidates.savedSearch,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSavedSearch: () => dispatch(getSavedSearch()),
  updateSavedSearch: (values: SavedSearchType) =>
    dispatch(updateSavedSearch(values)),
  deleteSavedSearch: (id: string) => dispatch(deleteSavedSearch(id)),
})

export const SavedSearchContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SavedSearch)
