import {connect} from 'react-redux'

import {EmployerProposeNewTimeCalendar} from './EmployerProposeNewTimeCalendar'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {
  getCandidateSpectoId,
  getSpectoToken,
  setSpectoProposeNewTime,
} from 'Store/Actions'
import {SpectoProposeNewTimeParamsType} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  spectoState: state.specto,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSpectoToken: () => dispatch(getSpectoToken()),
  getCandidateSpectoId: () => dispatch(getCandidateSpectoId()),
  setSpectoProposeNewTime: (values: SpectoProposeNewTimeParamsType) =>
    dispatch(setSpectoProposeNewTime(values)),
})

export const EmployerProposeNewTimeCalendarContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EmployerProposeNewTimeCalendar)
