import {all, call, put, takeEvery, takeLatest} from 'redux-saga/effects'
import {push} from 'connected-react-router'
import {getRoute} from 'Services/I18n/Utils'
import API, {clearUserIds} from 'Services/API'
import * as type from 'Store/types'
import {
  ActionType,
  AttachmentType,
  AttachmentSaveType,
  WorkExperienceItem,
  WorkExperienceFormikValue,
  ProjectFormikValue,
  ProjectItem,
  CertificationSaveValueType,
  RegistrationType,
  SkillType,
  UserUpdateBioType,
  LanguageType,
  EducationFormikValue,
  AwardsSaveValue,
  ProfileJobPreferenceFormikType,
  CertificationFormikValue,
  PublicProfileJobPreferenceFormikType,
  PublicWorkExperienceFormikValue,
  PublicWorkExperiencesUpdateFormikValue,
  PersonalDataSaveType,
  PublicUserBioType,
  DrivingLicenseSaveType,
} from 'Interfaces'
import {AppRoute} from 'Services/I18n/Constants'
import {
  packEduValues,
  packWorkExperiencesValues,
  packProjectValues,
  packJobPreferenceValues,
  packPublicJobPreferenceValues,
} from 'Store/Packing'
import {initAPIHeaders} from 'Services/API/API'

const packCertValues = (
  formikValues: CertificationFormikValue
): CertificationSaveValueType => {
  let baseInfo = {
    endYear: parseInt(formikValues.endYear),
    description: formikValues.description,
  }

  let educationInstitution
  if (formikValues.educationInstitution?.id.includes('newValue~')) {
    educationInstitution = {
      educationInstitutionName:
        formikValues.educationInstitution?.translation || '',
    }
  } else
    educationInstitution = {
      educationInstitutionId: formikValues.educationInstitution?.id || '',
    }

  let trainingTitle
  if (formikValues.trainingTitle?.id.includes('newValue~')) {
    trainingTitle = {
      trainingTitleName: formikValues.trainingTitle?.translation || '',
    }
  } else
    trainingTitle = {
      trainingTitleId: formikValues.trainingTitle?.id || '',
    }

  return {
    ...baseInfo,
    ...educationInstitution,
    ...trainingTitle,
  }
}

function* fetchSeekerProfile() {
  const {response, error} = yield call(API.get, '/user/profile')

  if (response) {
    yield put({
      type: type.profile.full.succeeded,
      payload: response.data,
    })
  } else {
    yield put({
      type: type.profile.full.failed,
      payload: error,
    })
  }
}

function* fetchPublicSeekerProfile(action: ActionType<string>) {
  const {response, error} = yield call(
    API.get,
    `/user/public/${action.payload}`
  )
  if (response) {
    yield put({
      type: type.profile.public.succeeded,
      payload: response.data,
    })
  } else {
    yield put({
      type: type.profile.public.failed,
      payload: error,
    })
  }
}

function* registerUser(action: ActionType<RegistrationType>) {
  clearUserIds()
  const {response, error} = yield call(API.post, `/auth/register/seeker`, {
    ...action.payload,
  })
  if (response) {
    localStorage.setItem('userId', response.data.id)
    initAPIHeaders()
    yield put({
      type: type.seeker.register.succeeded,
      payload: response.data,
    })
    yield put(push(getRoute(AppRoute.NeedToVerifyEmail)))
  } else yield put({type: type.seeker.register.failed, payload: error})
}

function* addDrivingLicence(action: ActionType<DrivingLicenseSaveType>) {
  const {response, error} = yield call(
    API.post,
    '/user/seeker-driving-license',
    {
      drivingLicenseCategoryIds:
        action.payload?.drivingLicenseCategoryIds || [],
      ownVehicle: action.payload?.ownVehicle || false,
    }
  )
  if (response) {
    yield put({
      type: type.seeker.editDrivingLicence.succeeded,
      payload: {
        seekerDrivingLicenseCategories:
          action.payload?.seekerDrivingLicenseCategories || [],
        ownVehicle: action.payload?.ownVehicle || false,
      },
    })
  } else
    yield put({type: type.seeker.editDrivingLicence.failed, payload: error})
}

function* editDrivingLicence(action: ActionType<DrivingLicenseSaveType>) {
  const {response, error} = yield call(
    API.patch,
    '/user/seeker-driving-license',
    {
      drivingLicenseCategoryIds:
        action.payload?.drivingLicenseCategoryIds || [],
      ownVehicle: action.payload?.ownVehicle || false,
    }
  )
  if (response) {
    yield put({
      type: type.seeker.editDrivingLicence.succeeded,
      payload: {
        seekerDrivingLicenseCategories:
          action.payload?.seekerDrivingLicenseCategories || [],
        ownVehicle: action.payload?.ownVehicle || false,
      },
    })
  } else
    yield put({type: type.seeker.editDrivingLicence.failed, payload: error})
}

function* addEducation(action: ActionType<EducationFormikValue>) {
  const {response, error} = yield call(
    API.post,
    '/user/seeker-education',
    packEduValues(action.payload as EducationFormikValue)
  )
  if (response) {
    yield put({
      type: type.seeker.education.add.succeeded,
      payload: response.data,
    })
  } else yield put({type: type.seeker.education.add.failed, payload: error})
}

function* editEducation(action: ActionType<EducationFormikValue>) {
  const {response, error} = yield call(
    API.patch,
    `/user/seeker-education/${action.payload?.id}`,
    packEduValues(action.payload as EducationFormikValue)
  )
  if (response) {
    yield put({
      type: type.seeker.education.update.succeeded,
      payload: response.data,
    })
  } else yield put({type: type.seeker.education.update.failed, payload: error})
}

function* deleteEducation(action: ActionType<string>) {
  const {response, error} = yield call(
    API.delete,
    `/user/seeker-education/${action.payload}`
  )
  if (response) {
    yield put({
      type: type.seeker.education.delete.succeeded,
      payload: action.payload,
    })
  } else yield put({type: type.seeker.education.delete.failed, payload: error})
}

function* deleteWorkExperience(action: ActionType<string>) {
  const {response, error} = yield call(
    API.delete,
    `/user/seeker-work-experience/${action.payload}`
  )
  if (response) {
    yield put({
      type: type.SEEKER_WORK_EXPERIENCE_DELETE_SUCCEEDED,
      payload: action.payload,
    })
  } else
    yield put({type: type.SEEKER_WORK_EXPERIENCE_DELETE_FAILED, payload: error})
}

function* addAttachment(action: ActionType<AttachmentSaveType>) {
  const {response, error} = yield call(
    API.post,
    '/user/seeker-attachment',
    action.payload
  )
  if (response) {
    yield put({
      type: type.SEEKER_ATTACHMENTS_ADD_SUCCEEDED,
      payload: {...action.payload, id: response.data.id},
    })
    yield put({
      type: type.profile.full.requested,
      payload: null,
    })
  } else {
    yield put({type: type.SEEKER_ATTACHMENTS_ADD_FAILED, payload: error})
  }
}

function* editAttachment(action: ActionType<AttachmentSaveType>) {
  const {response, error} = yield call(
    API.patch,
    `/user/seeker-attachment/${action.payload?.id}`,
    {
      fileId: action.payload?.fileId,
      name: action.payload?.name,
      type: action.payload?.type,
    }
  )
  if (response) {
    yield put({
      type: type.SEEKER_ATTACHMENTS_EDIT_SUCCEEDED,
      payload: {...action.payload},
    })
  } else {
    yield put({type: type.SEEKER_ATTACHMENTS_EDIT_FAILED, payload: error})
  }
}

function* removeAttachment(action: ActionType<AttachmentType>) {
  const {response, error} = yield call(
    API.delete,
    `/user/seeker-attachment/${action.payload?.id}`
  )
  if (response) {
    yield put({
      type: type.SEEKER_ATTACHMENTS_DELETE_SUCCEEDED,
      payload: {...action.payload},
    })
  } else {
    yield put({type: type.SEEKER_ATTACHMENTS_DELETE_FAILED, payload: error})
  }
}

function* addSkill(action: ActionType<SkillType>) {
  const {response, error} = yield call(
    API.post,
    '/user/seeker-skill',
    action.payload
  )

  if (response) {
    yield put({
      type: type.seeker.skills.add.full.succeeded,
      payload: response.data,
    })
  } else {
    yield put({type: type.seeker.skills.add.full.failed, payload: error})
  }
}

function* editSkill(action: ActionType<SkillType>) {
  const {response, error} = yield call(
    API.patch,
    `/user/seeker-skill/${action.payload?.id}`,
    {
      skillId: action.payload?.skillId,
      skillName: action.payload?.skillName,
      skillLevelId: action.payload?.skillLevel.id,
    }
  )
  if (response) {
    yield put({
      type: type.seeker.skills.update.full.succeeded,
      payload: {...action.payload},
    })
  } else {
    yield put({type: type.seeker.skills.update.full.failed, payload: error})
  }
}

function* deleteSkill(action: ActionType<SkillType>) {
  const {response, error} = yield call(
    API.delete,
    `/user/seeker-skill/${action.payload?.id}`
  )
  if (response) {
    yield put({
      type: type.seeker.skills.delete.full.succeeded,
      payload: {...action.payload},
    })
  } else {
    yield put({type: type.seeker.skills.delete.full.failed, payload: error})
  }
}

function* addPublicSkill(action: ActionType<SkillType>) {
  const {response, error} = yield call(
    API.post,
    '/user/seeker-skill',
    action.payload
  )

  if (response) {
    yield put({
      type: type.seeker.skills.add.public.succeeded,
      payload: response.data,
    })
  } else {
    yield put({type: type.seeker.skills.add.public.failed, payload: error})
  }
}

function* editPublicSkill(action: ActionType<SkillType>) {
  const {response, error} = yield call(
    API.patch,
    `/user/seeker-skill/${action.payload?.id}`,
    {
      skillId: action.payload?.skillId,
      skillName: action.payload?.skillName,
      skillLevelId: action.payload?.skillLevel.id,
    }
  )
  if (response) {
    yield put({
      type: type.seeker.skills.update.public.succeeded,
      payload: {...action.payload},
    })
  } else {
    yield put({type: type.seeker.skills.update.public.failed, payload: error})
  }
}

function* deletePublicSkill(action: ActionType<SkillType>) {
  const {response, error} = yield call(
    API.delete,
    `/user/seeker-skill/${action.payload?.id}`
  )
  if (response) {
    yield put({
      type: type.seeker.skills.delete.public.succeeded,
      payload: {...action.payload},
    })
  } else {
    yield put({type: type.seeker.skills.delete.public.failed, payload: error})
  }
}

function* addLanguage(action: ActionType<LanguageType>) {
  const {response, error} = yield call(
    API.post,
    '/user/seeker-language',
    action.payload
  )

  if (response) {
    // yield put({
    //   type: type.SEEKER_LANGUAGES_ADD_SUCCEEDED,
    //   payload: {...action.payload, id: response.data.id} as LanguageType,
    // })
    yield put({
      type: type.profile.full.requested,
      payload: null,
    })
  } else {
    yield put({type: type.SEEKER_LANGUAGES_ADD_FAILED, payload: error})
  }
}

function* editLanguage(action: ActionType<LanguageType>) {
  const {response, error} = yield call(
    API.patch,
    `/user/seeker-language/${action.payload?.id}`,
    {
      languageTaxonomyId: action.payload?.languageTaxonomyId,
      languageLevelId: action.payload?.languageLevelId,
    }
  )
  if (response) {
    // yield put({
    //   type: type.SEEKER_LANGUAGES_EDIT_SUCCEEDED,
    //   payload: {...action.payload},
    // })

    yield put({
      type: type.profile.full.requested,
      payload: null,
    })
  } else {
    yield put({type: type.SEEKER_LANGUAGES_EDIT_FAILED, payload: error})
  }
}

function* deleteLanguage(action: ActionType<LanguageType>) {
  const {response, error} = yield call(
    API.delete,
    `/user/seeker-language/${action.payload?.id}`
  )
  if (response) {
    // yield put({
    //   type: type.SEEKER_LANGUAGES_DELETE_SUCCEEDED,
    //   payload: {...action.payload},
    // })

    yield put({
      type: type.profile.full.requested,
      payload: null,
    })
  } else {
    yield put({type: type.SEEKER_LANGUAGES_DELETE_FAILED, payload: error})
  }
}

function* addAwards(action: ActionType<AwardsSaveValue>) {
  if (!action.payload) return
  const {response, error} = yield call(API.post, '/user/seeker-award', {
    ...action.payload,
    year: parseInt(action.payload.year) || undefined,
  })
  if (response) {
    yield put({
      type: type.SEEKER_AWARDS_ADD_SUCCEEDED,
      payload: {...action.payload, id: response.data.id},
    })
  } else yield put({type: type.SEEKER_AWARDS_ADD_FAILED, error})
}

function* editAwards(action: ActionType<AwardsSaveValue>) {
  if (!action.payload) return
  const {response, error} = yield call(
    API.patch,
    `/user/seeker-award/${action.payload?.id}`,
    {
      ...action.payload,
      year: parseInt(action.payload.year) || undefined,
      id: undefined,
    }
  )
  if (response) {
    yield put({
      type: type.SEEKER_AWARDS_EDIT_SUCCEEDED,
      payload: {...action.payload, id: response.data.id},
    })
  } else yield put({type: type.SEEKER_AWARDS_EDIT_FAILED, error})
}
function* deleteAwards(action: ActionType<string>) {
  if (!action.payload) return
  const {response, error} = yield call(
    API.delete,
    `/user/seeker-award/${action.payload}`
  )
  if (response) {
    yield put({
      type: type.SEEKER_AWARDS_DELETE_SUCCEEDED,
      payload: action.payload,
    })
  } else yield put({type: type.SEEKER_AWARDS_EDIT_FAILED, error})
}

function* addCertification(action: ActionType<CertificationFormikValue>) {
  const {response, error} = yield call(
    API.post,
    '/user/seeker-training',
    packCertValues(action.payload as CertificationFormikValue)
  )

  if (response) {
    yield put({
      type: type.SEEKER_CERTIFICATION_ADD_SUCCEEDED,
      payload: response.data,
    })
  } else yield put({type: type.SEEKER_CERTIFICATION_ADD_FAILED, payload: error})
}

function* editCertification(action: ActionType<CertificationFormikValue>) {
  const {response, error} = yield call(
    API.patch,
    `/user/seeker-training/${action.payload?.id}`,
    packCertValues(action.payload as CertificationFormikValue)
  )
  if (response) {
    yield put({
      type: type.SEEKER_CERTIFICATION_EDIT_SUCCEEDED,
      payload: response.data,
    })
  } else
    yield put({type: type.SEEKER_CERTIFICATION_EDIT_FAILED, payload: error})
}

function* deleteCertification(action: ActionType<string>) {
  const {response, error} = yield call(
    API.delete,
    `/user/seeker-training/${action.payload}`
  )
  if (response) {
    yield put({
      type: type.SEEKER_CERTIFICATION_DELETE_SUCCEEDED,
      payload: action.payload,
    })
  } else
    yield put({type: type.SEEKER_CERTIFICATION_DELETE_FAILED, payload: error})
}

function* updateBioData(action: ActionType<UserUpdateBioType>) {
  const {response, error} = yield call(API.post, '/user/seeker-personal-data', {
    aboutMe: action.payload?.aboutMe,
    jobSearchStatusId: action.payload?.jobSearchStatus?.id,
  })
  if (response) {
    yield put({
      type: type.seeker.updateBioData.full.succeeded,
      payload: action.payload,
    })
  } else {
    yield put({type: type.seeker.updateBioData.full.failed, payload: error})
    yield put(push(getRoute(AppRoute.SeekerLogin)))
  }
}

function* updatePublicBioData(action: ActionType<PublicUserBioType>) {
  const {response, error} = yield call(API.post, '/user/seeker-personal-data', {
    //@ts-ignore
    jobSearchStatusId: action.payload?.jobSearchStatusId,
    shortDescription: action.payload?.shortDescription,
    ...(action.payload?.publicOccupation?.id.includes('newValue~')
      ? {publicOccupation: action.payload?.publicOccupation?.translation}
      : {publicOccupationId: action.payload?.publicOccupation?.id}),
  })
  if (response) {
    yield put({
      type: type.seeker.updateBioData.public.succeeded,
      payload: response.data,
    })
  } else {
    yield put({type: type.seeker.updateBioData.public.failed, payload: error})
  }
}

function* getPersonalData() {
  const {response, error} = yield call(API.get, '/user/seeker-personal-data')
  if (response) {
    yield put({
      type: type.seeker.personalData.get.succeeded,
      payload: response.data,
    })
  } else {
    yield put({type: type.seeker.personalData.get.failed, payload: error})
  }
}

function* savePersonalData(action: ActionType<PersonalDataSaveType>) {
  const {response, error} = yield call(
    API.patch,
    '/user/seeker-personal-data',
    action.payload
  )
  if (response) {
    yield put({
      type: type.seeker.personalData.save.succeeded,
      payload: response.data,
    })
  } else {
    yield put({type: type.seeker.personalData.save.failed, payload: error})
  }
}

function* addWorkExperience(action: ActionType<WorkExperienceItem>) {
  const {response, error} = yield call(
    API.post,
    '/user/seeker-work-experience',
    packWorkExperiencesValues(action.payload as WorkExperienceFormikValue)
  )

  if (response) {
    yield put({
      type: type.SEEKER_WORK_EXPERIENCE_ADD_SUCCEEDED,
      payload: response.data,
    })
  } else yield put({type: type.SEEKER_WORK_EXPERIENCE_ADD_FAILED, error})
}

function* editWorkExperience(action: ActionType<WorkExperienceItem>) {
  const {response, error} = yield call(
    API.patch,
    `/user/seeker-work-experience/${action.payload?.id}`,
    packWorkExperiencesValues(action.payload as WorkExperienceFormikValue)
  )

  if (response) {
    yield put({
      type: type.SEEKER_WORK_EXPERIENCE_EDIT_SUCCEEDED,
      payload: response.data,
    })
  } else yield put({type: type.SEEKER_WORK_EXPERIENCE_EDIT_FAILED, error})
}

function* addPublicWorkExperience(
  action: ActionType<PublicWorkExperienceFormikValue>
) {
  const {response, error} = yield call(
    API.post,
    '/user/seeker-public-work-experience',
    {
      ...(action.payload?.occupation?.id.includes('newValue~')
        ? {name: action.payload?.occupation?.translation}
        : {id: action.payload?.occupation?.id}),
      experience: action.payload?.experience,
    }
  )
  if (response) {
    yield put({
      type: type.seeker.workExperience.add.public.succeeded,
      payload: response.data,
    })
  } else
    yield put({
      type: type.seeker.workExperience.add.public.failed,
      payload: error,
    })
}

function* updatePublicWorkExperience(
  action: ActionType<PublicWorkExperiencesUpdateFormikValue>
) {
  const {response, error} = yield call(
    API.patch,
    '/user/seeker-public-work-experience',
    action.payload
  )
  if (response) {
    yield put({
      type: type.seeker.workExperience.update.public.succeeded,
      payload: response.data,
    })
  } else
    yield put({
      type: type.seeker.workExperience.update.public.failed,
      payload: error,
    })
}

function* addProject(action: ActionType<ProjectItem>) {
  const values = packProjectValues(action.payload as ProjectFormikValue)
  const {response, error} = yield call(API.post, '/user/seeker-project', values)

  if (response) {
    yield put({
      type: type.SEEKER_PROJECT_ADD_SUCCEEDED,
      payload: {...values, id: response.data.id},
    })
  } else yield put({type: type.SEEKER_PROJECT_ADD_FAILED, payload: error})
}

function* editProject(action: ActionType<ProjectItem>) {
  const values = packProjectValues(action.payload as ProjectFormikValue)

  const {response, error} = yield call(
    API.patch,
    `/user/seeker-project/${action.payload?.id}`,
    values
  )

  if (response) {
    yield put({
      type: type.SEEKER_PROJECT_EDIT_SUCCEEDED,
      payload: {...values, id: response.data.id},
    })
  } else yield put({type: type.SEEKER_PROJECT_EDIT_FAILED, payload: error})
}

function* deleteProject(action: ActionType<string>) {
  const {response, error} = yield call(
    API.delete,
    `/user/seeker-project/${action.payload}`
  )
  if (response) {
    yield put({
      type: type.SEEKER_PROJECT_DELETE_SUCCEEDED,
      payload: action.payload,
    })
  } else yield put({type: type.SEEKER_PROJECT_DELETE_FAILED, payload: error})
}

function* updateJobPreferences(
  action: ActionType<ProfileJobPreferenceFormikType>
) {
  const {response, error} = yield call(
    API.patch,
    '/user/seeker-job-preferences',
    packJobPreferenceValues(action.payload as ProfileJobPreferenceFormikType)
  )
  if (response) {
    yield put({
      type: type.seeker.updateJobPreferences.full.succeeded,
      payload: action.payload,
    })
  } else {
    yield put({
      type: type.seeker.updateJobPreferences.full.failed,
      payload: error,
    })
  }
}

function* updatePublicJobPreferences(
  action: ActionType<PublicProfileJobPreferenceFormikType>
) {
  const {response, error} = yield call(
    API.post,
    '/user/seeker-public-job-preferences',
    packPublicJobPreferenceValues(
      action.payload as PublicProfileJobPreferenceFormikType
    )
  )
  if (response) {
    yield put({
      type: type.seeker.updateJobPreferences.public.succeeded,
      payload: action.payload,
    })
  } else {
    yield put({
      type: type.seeker.updateJobPreferences.public.failed,
      payload: error,
    })
  }
}

function* reparseCv(action: ActionType<string>) {
  const {response, error} = yield call(API.post, 'user/seeker-reparse-cv', {
    fileId: action.payload,
  })

  if (response) {
    yield put({
      type: type.seeker.reparseCv.succeeded,
      payload: action.payload,
    })
    yield put({
      type: type.profile.full.requested,
    })
  } else {
    yield put({
      type: type.seeker.reparseCv.failed,
      payload: error,
    })
  }
}

export default function* SeekerSaga(): Generator {
  yield all([
    takeLatest(type.seeker.register.requested, registerUser),
    takeLatest(type.profile.full.requested, fetchSeekerProfile),
    takeLatest(type.profile.public.requested, fetchPublicSeekerProfile),
    takeLatest(type.seeker.education.add.requested, addEducation),
    takeLatest(type.seeker.education.update.requested, editEducation),
    takeLatest(type.seeker.education.delete.requested, deleteEducation),
    takeLatest(type.SEEKER_WORK_EXPERIENCE_ADD_REQUESTED, addWorkExperience),
    takeLatest(type.SEEKER_WORK_EXPERIENCE_EDIT_REQUESTED, editWorkExperience),
    takeLatest(
      type.SEEKER_WORK_EXPERIENCE_DELETE_REQUESTED,
      deleteWorkExperience
    ),
    takeLatest(type.SEEKER_PROJECT_ADD_REQUESTED, addProject),
    takeLatest(type.SEEKER_PROJECT_EDIT_REQUESTED, editProject),
    takeLatest(type.SEEKER_PROJECT_DELETE_REQUESTED, deleteProject),
    takeLatest(
      type.seeker.workExperience.add.public.requested,
      addPublicWorkExperience
    ),
    takeLatest(
      type.seeker.workExperience.update.public.requested,
      updatePublicWorkExperience
    ),

    takeEvery(type.SEEKER_ATTACHMENTS_ADD_REQUESTED, addAttachment),
    takeEvery(type.SEEKER_ATTACHMENTS_EDIT_REQUESTED, editAttachment),
    takeEvery(type.SEEKER_ATTACHMENTS_DELETE_REQUESTED, removeAttachment),

    takeLatest(type.SEEKER_AWARDS_ADD_REQUESTED, addAwards),
    takeLatest(type.SEEKER_AWARDS_EDIT_REQUESTED, editAwards),
    takeLatest(type.SEEKER_AWARDS_DELETE_REQUESTED, deleteAwards),

    takeLatest(type.seeker.skills.add.full.requested, addSkill),
    takeEvery(type.seeker.skills.update.full.requested, editSkill),
    takeEvery(type.seeker.skills.delete.full.requested, deleteSkill),

    takeLatest(type.seeker.skills.add.public.requested, addPublicSkill),
    takeEvery(type.seeker.skills.update.public.requested, editPublicSkill),
    takeEvery(type.seeker.skills.delete.public.requested, deletePublicSkill),

    takeLatest(type.SEEKER_CERTIFICATION_ADD_REQUESTED, addCertification),
    takeLatest(type.SEEKER_CERTIFICATION_EDIT_REQUESTED, editCertification),
    takeLatest(type.SEEKER_CERTIFICATION_DELETE_REQUESTED, deleteCertification),
    takeLatest(type.seeker.updateBioData.full.requested, updateBioData),
    takeLatest(type.seeker.updateBioData.public.requested, updatePublicBioData),

    takeLatest(type.SEEKER_LANGUAGES_ADD_REQUESTED, addLanguage),
    takeLatest(type.SEEKER_LANGUAGES_EDIT_REQUESTED, editLanguage),
    takeLatest(type.SEEKER_LANGUAGES_DELETE_REQUESTED, deleteLanguage),

    takeLatest(
      type.seeker.updateJobPreferences.full.requested,
      updateJobPreferences
    ),
    takeLatest(
      type.seeker.updateJobPreferences.public.requested,
      updatePublicJobPreferences
    ),
    takeLatest(type.seeker.addDrivingLicence.requested, addDrivingLicence),
    takeLatest(type.seeker.editDrivingLicence.requested, editDrivingLicence),
    takeLatest(type.seeker.personalData.get.requested, getPersonalData),
    takeLatest(type.seeker.personalData.save.requested, savePersonalData),
    takeLatest(type.seeker.reparseCv.requested, reparseCv),
  ])
}
