import {useState, FC, useMemo, useEffect} from 'react'
import {useFormik} from 'formik'
import SectionHeader from 'Components/Profile/SectionHeader'
import Dropdown from 'Components/Inputs/Dropdown'
import Modal from 'Components/Modal'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import TextError from 'Components/Error/TextError'
import {
  Warning,
  EditOutlined,
  InsertDriveFileOutlined,
  DeleteOutlineOutlined,
} from '@mui/icons-material'

import {cloneDeep} from 'lodash'
import TextField from 'Components/Inputs/TextField'

import styles from './Attachments.module.scss'
import Validation from './Validation'
import {
  AttachmentType,
  AttachmentSaveType,
  SeekerStateType,
  File,
} from 'Interfaces'
import Title from 'Components/Title'
import Hr from 'Components/Hr'
import FileUploadCombo from 'Components/Inputs/AttachmentFileUpload'
import {ProfileTranslations as pt} from 'Services/I18n/Constants'
import {useApiError, useTranslation} from 'Hooks'
import CvIcon from 'Assets/Images/attachment-type-icons/cv.svg'
import CertificateIcon from 'Assets/Images/attachment-type-icons/certificate.svg'
import ReferencesIcon from 'Assets/Images/attachment-type-icons/references.svg'
import DiplomaIcon from 'Assets/Images/attachment-type-icons/diploma.svg'
import AwardIcon from 'Assets/Images/attachment-type-icons/award.svg'
import OtherIcon from 'Assets/Images/attachment-type-icons/other.svg'
import {sort} from './Sort'
import classnames from 'classnames'

const attachmentTypes = [
  {id: 'CV', translationId: pt.cv},
  {id: 'Certificate', translationId: pt.certificate},
  {id: 'References', translationId: pt.references},
  {id: 'Diploma', translationId: pt.diploma},
  {id: 'Award', translationId: pt.award},
  {id: 'Other', translationId: pt.other},
]

const AttachmentIcon: FC<{type: string}> = ({type}) => {
  const translation = useTranslation()

  if (type === attachmentTypes[0].id) {
    return (
      <img
        className={styles.icon}
        src={CvIcon}
        alt={translation[attachmentTypes[0].translationId]}
      />
    )
  }
  if (type === attachmentTypes[1].id) {
    return (
      <img
        className={styles.icon}
        src={CertificateIcon}
        alt={translation[attachmentTypes[1].translationId]}
      />
    )
  }
  if (type === attachmentTypes[2].id) {
    return (
      <img
        className={styles.icon}
        src={ReferencesIcon}
        alt={translation[attachmentTypes[2].translationId]}
      />
    )
  }
  if (type === attachmentTypes[3].id) {
    return (
      <img
        className={styles.icon}
        src={DiplomaIcon}
        alt={translation[attachmentTypes[3].translationId]}
      />
    )
  }
  if (type === attachmentTypes[4].id) {
    return (
      <img
        className={styles.icon}
        src={AwardIcon}
        alt={translation[attachmentTypes[4].translationId]}
      />
    )
  }
  if (type === attachmentTypes[5].id) {
    return (
      <img
        className={styles.icon}
        src={OtherIcon}
        alt={translation[attachmentTypes[5].translationId]}
      />
    )
  }
  return null
}

interface iAttachmentsProps {
  profile: SeekerStateType
  attachments?: AttachmentType[]
  addAttachment: (value: AttachmentSaveType) => void
  editAttachment: (value: AttachmentSaveType) => void
  removeAttachment: (value: AttachmentType) => void
  canChange?: boolean
}

export const Attachments: FC<iAttachmentsProps> = ({
  profile,
  attachments = [],
  addAttachment,
  editAttachment,
  removeAttachment,
  canChange = false,
}: iAttachmentsProps) => {
  type EditAttachmentType = AttachmentType & {edit?: string}
  const translations = useTranslation()

  const file: File = {
    id: '',
    url: '',
    originalName: '',
  }
  const addAttachmentEmptyValues: AttachmentType = {
    type: '',
    name: '',
    file: file,
  }

  const editAttachmentEmptyValues: EditAttachmentType[] = []

  const [addModalOpen, setAddModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)

  const localAttachements = useMemo(() => sort(attachments), [attachments])

  const formikAddAttachment = useFormik({
    initialValues: addAttachmentEmptyValues,
    validationSchema: Validation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      addAttachment({
        type: values.type,
        name: values.name,
        fileId: values.file.id,
      })
    },
  })

  const formikEditAttachment = useFormik({
    initialValues: editAttachmentEmptyValues,
    onSubmit: (values) => {
      values.forEach((value) => {
        if (value.edit) {
          if (value.edit === 'delete') {
            removeAttachment(value)
          }
          if (value.edit === 'rename') {
            editAttachment({
              id: value.id,
              type: value.type,
              name: value.name,
              fileId: value.file.id,
            })
          }
        }
      })
    },
  })

  const deleteAttachment = (attachment: AttachmentType) => {
    const values = cloneDeep(formikEditAttachment.values)

    formikEditAttachment.setValues(
      values.map((value) => {
        if (attachment.id === value.id) {
          return {
            ...value,
            edit: 'delete',
          }
        }
        return value
      })
    )
  }

  const renameAttachment = (id: string, newName: string) => {
    const values = cloneDeep(formikEditAttachment.values)

    formikEditAttachment.setValues(
      values.map((value) => {
        if (id === value.id) {
          return {
            ...value,
            name: newName,
            edit: 'rename',
          }
        }
        return value
      })
    )
  }

  const openEdit = () => {
    setEditModalOpen(true)
    formikEditAttachment.setValues(attachments)
  }

  const openAdd = () => {
    formikAddAttachment.resetForm({
      values: addAttachmentEmptyValues,
    })
    setAddModalOpen(true)
  }

  const translatedAttachmentTypes = useMemo(() => {
    return attachmentTypes.map((type) => ({
      id: type.id,
      translation: translations[type.translationId],
    }))
  }, [attachmentTypes])

  useApiError(formikAddAttachment.setFieldError, profile.error, 'name')
  useApiError(
    formikEditAttachment.setFieldError,
    profile.error,
    formikEditAttachment.values[0]?.id || 'name'
  )
  useEffect(() => {
    if (!profile.loading && !profile.error) {
      setAddModalOpen(false)
      setEditModalOpen(false)
    }
  }, [profile.loading])

  // Attachment is required  err exists when File too large happen: clear is required err
  const clearAttachmentFileValidationError = () => {
    formikAddAttachment.setFieldError('file', undefined)
  }
  // File too large err exists when form submitted or new file is uploaded: clear FileUpload(file too large) err
  const [isSubmitted, setIsSubmitted] = useState(false)

  return (
    <div className={styles.section}>
      <div>
        <SectionHeader
          onAdd={canChange ? openAdd : undefined}
          title={pt.attachmentsTitle}
        />
      </div>
      {attachments.length > 0 && (
        <div className={styles.box}>
          <div className={styles.editWrap}>
            {canChange && (
              <button type={'button'} onClick={() => openEdit()}>
                <EditOutlined className={styles.editIcon} />
              </button>
            )}
          </div>

          {localAttachements.map((attachment: AttachmentType) => {
            if (attachment && attachment.file.url)
              return (
                <div key={attachment.id} className={styles.fileRow}>
                  <div className={styles.iconHolder}>
                    <AttachmentIcon type={attachment.type} />
                  </div>
                  <a href={attachment.file.url} target="_blank">
                    {attachment.name}
                  </a>
                </div>
              )
            return null
          })}
        </div>
      )}

      <Modal
        isOpen={addModalOpen}
        onAfterClose={() => {
          setIsSubmitted(false)
          setAddModalOpen(false)
        }}
        title={pt.addAttachment}
        icon={InsertDriveFileOutlined}
        ariaHideApp={false}
        className={styles.modal}
        theme="desktopFlex"
        Footer={
          <SubmitFooter
            onSubmit={() => {
              setIsSubmitted(true)
              formikAddAttachment.handleSubmit()
            }}
            onCancel={() => {
              setIsSubmitted(false)
              setAddModalOpen(false)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <Title
          text={pt.attachmentType}
          theme="modal"
          className={classnames(styles.title, styles.titleStrong)}
        />
        <Dropdown
          name="type"
          items={translatedAttachmentTypes}
          value={formikAddAttachment.values.type}
          error={formikAddAttachment.errors.type}
          setValue={formikAddAttachment.setFieldValue}
          theme="white"
        />
        <Hr theme="modal" className={classnames(styles.hr, styles.hrStrong)} />
        <Title
          text={pt.uploadAttachment}
          theme="modal"
          className={classnames(styles.titleUpload, styles.titleUploadStrong)}
        />
        <FileUploadCombo
          name="file"
          setValue={formikAddAttachment.setFieldValue}
          theme={'white'}
          integratedUploads={false}
          accept=".doc,.docx,.pdf"
          clearAttachmentFileValidationError={
            clearAttachmentFileValidationError
          }
          isSubmitted={isSubmitted}
          setIsSubmitted={setIsSubmitted}
        />
        {formikAddAttachment.errors && formikAddAttachment.errors.file && (
          <div className={styles.errorWrap}>
            <Warning className={styles.errorIcon} />
            <TextError text={formikAddAttachment.errors.file as string} />
          </div>
        )}
        <Hr theme="modalSmall" />
        <Title
          text={pt.attachmentName}
          theme="modal"
          className={classnames(styles.title, styles.titleStrong)}
        />
        <TextField
          name="name"
          onChange={formikAddAttachment.handleChange}
          value={formikAddAttachment.values.name}
          error={formikAddAttachment.errors.name}
          placeholder="John Smith CV"
          className={styles.textField}
          theme="modal"
        />
        <Hr theme="modalSmall" />
      </Modal>

      <Modal
        isOpen={editModalOpen}
        onAfterClose={() => {
          formikEditAttachment.setValues(editAttachmentEmptyValues)
          setEditModalOpen(false)
        }}
        title={pt.editAttachments}
        icon={InsertDriveFileOutlined}
        ariaHideApp={false}
        className={styles.modal}
        Footer={
          <SubmitFooter
            onSubmit={formikEditAttachment.handleSubmit}
            onCancel={() => {
              setEditModalOpen(false)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <div className={styles.editBox}>
          {formikEditAttachment.values.map((attachment: EditAttachmentType) => {
            if (
              attachment &&
              attachment.file.url &&
              attachment.edit !== 'delete'
            )
              return (
                <div key={attachment.id} className={styles.editFileRow}>
                  <div className={styles.iconHolder}>
                    <AttachmentIcon type={attachment.type} />
                  </div>
                  <TextField
                    name={attachment.id as string}
                    value={attachment.name}
                    className={styles.attachmentNameInput}
                    onChange={(e) =>
                      renameAttachment(
                        attachment.id as string,
                        e.currentTarget.value
                      )
                    }
                  />
                  <div className={styles.deleteHolder}>
                    <button
                      type={'button'}
                      onClick={() => deleteAttachment(attachment)}
                    >
                      <DeleteOutlineOutlined className={styles.deleteIcon} />
                    </button>
                  </div>
                </div>
              )
            return null
          })}
        </div>
      </Modal>
    </div>
  )
}
