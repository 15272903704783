import {FC} from 'react'
import styles from './CandidateInfo.module.scss'
import {PublicPagesHeader} from 'Components/PublicPagesHeader'
import YellowText from 'Components/YellowText'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import YellowLink from 'Components/YellowLink'
import {CandidateInfo as ci} from 'Services/I18n/Constants/Seeker/CandidateInfo'
import {FormattedMessage} from 'react-intl'
import {useTranslation} from 'Hooks'

export const CandidateInfo: FC = () => {
  const translation = useTranslation()
  return (
    <div>
      <div className={styles.layout}>
        <div className={styles.container}>
          <PublicPagesHeader page="candidate" />
          <h2 className={styles.title}>
            <FormattedMessage id={ci.title} />{' '}
            <YellowText text={translation[ci.titleYellow]} />.
          </h2>
          <div className={styles.titleContainer}>
            <h1 className={styles.titleMain}>
              <FormattedMessage id={ci.paragraphMain} />
            </h1>
          </div>

          <div className={styles.sectionBig}>
            <div className={styles.grid}>
              <div className={styles.gridItem}>
                <p className={styles.gridNumber}>1</p>
                <p className={styles.paragraphTitleGrid}>
                  <FormattedMessage id={ci.subtitle} />
                </p>
                <p className={styles.paragraph}>
                  <FormattedMessage id={ci.paragraphFirstPart} /> <br></br>
                  <br></br>
                  <FormattedMessage id={ci.paragraphSecondPart} />
                </p>
              </div>
              <div className={styles.gridItem}>
                <p className={styles.gridNumber}>2</p>
                <p className={styles.paragraphTitleGrid}>
                  <FormattedMessage id={ci.subtitleSecond} />
                </p>
                <p className={styles.paragraph}>
                  <FormattedMessage id={ci.paragraphSecond} />
                </p>
              </div>

              <div className={styles.gridItem}>
                <p className={styles.gridNumber}>3</p>
                <p className={styles.paragraphTitleGrid}>
                  <FormattedMessage id={ci.subtitleThird} />
                </p>
                <div className={styles.paragraph}>
                  <FormattedMessage id={ci.paragraphThird} />
                  <br></br>
                  <br></br>
                  <FormattedMessage id={ci.paragraphThirdTitle} />
                  <ul>
                    <li>
                      <FormattedMessage id={ci.paragraphThirdItemFirst} />
                    </li>
                    <li>
                      <FormattedMessage id={ci.paragraphThirdItemSecond} />
                    </li>
                    <li>
                      <FormattedMessage id={ci.paragraphThirdItemThird} />
                    </li>
                  </ul>
                </div>
              </div>
              <div className={styles.gridItem}>
                <p className={styles.gridNumber}>4</p>
                <p className={styles.paragraphTitleGrid}>
                  <FormattedMessage id={ci.subtitleFourth} />
                </p>
                <p className={styles.paragraph}>
                  <FormattedMessage id={ci.paragraphFourth} />
                </p>
              </div>
              <div className={styles.gridItem}>
                <p className={styles.gridNumber}>5</p>
                <p className={styles.paragraphTitleGrid}>
                  {' '}
                  <FormattedMessage id={ci.subtitleFifth} />
                </p>
                <p className={styles.paragraph}>
                  <FormattedMessage id={ci.paragraphFifth} />
                </p>
              </div>
              <div className={styles.gridItem}>
                <p className={styles.gridNumber}>6</p>
                <p className={styles.paragraphTitleGrid}>
                  <FormattedMessage id={ci.subtitleSixth} />
                </p>
                <p className={styles.paragraph}>
                  <FormattedMessage id={ci.paragraphSixthFirstPart} />
                  <br></br>
                  <br></br>
                  <FormattedMessage id={ci.paragraphSixthSecondPart} />
                </p>
              </div>
            </div>
          </div>
          <div className={styles.yellowButtonWrap}>
            <YellowLink
              className={styles.yellowButton}
              to={getRoute(AppRoute.CandidateFAQ)}
              text={translation[ci.yellowButton]}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
