import ReactModal from 'react-modal'
import styles from './Modal.module.scss'
import {ReactNode, useEffect, useState} from 'react'
import classnames from 'classnames'
import {FormattedMessage} from 'react-intl'
import {Close, EditOutlined} from '@mui/icons-material'
import {OverridableComponent} from '@mui/material/OverridableComponent'
import {SvgIconTypeMap} from '@mui/material'
export interface ModalType extends ReactModal.Props {
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & {
    muiName: string
  }
  title: string
  withActions?: boolean
  onSubmit?: () => void
  onBack?: () => void
  className?: string
  Footer?: ReactNode
  theme?: 'desktopFlex' | '' | 'error' | 'white'
}
export const Modal = ({
  children,
  icon = EditOutlined,
  title,
  isOpen,
  withActions,
  className,
  Footer,
  theme = '',
  ...props
}: ModalType): JSX.Element | null => {
  const [internalIsOpen, setInternalIsOpen] = useState(isOpen)
  useEffect(() => {
    setInternalIsOpen(isOpen)
  }, [isOpen])

  const HeaderIcon = icon
  return (
    <ReactModal
      {...props}
      className={classnames(styles.modal, className)}
      isOpen={internalIsOpen}
      bodyOpenClassName={styles.lockedBody}
      style={{
        overlay: {
          backgroundColor: 'rgba(0,0,0,0.85)',
          zIndex: 40,
        },
      }}
      onRequestClose={() => {
        setInternalIsOpen(false)
      }}
      ariaHideApp={false}
    >
      <div
        className={classnames(styles.header, {
          [styles.error]: theme === 'error',
        })}
      >
        <div className={styles.icon}>
          {HeaderIcon && <HeaderIcon sx={{fontSize: 28, color: '#fff'}} />}
        </div>
        <div className={styles.title}>
          <FormattedMessage id={title} />
        </div>
        <button
          className={styles.closeButton}
          onClick={() => setInternalIsOpen(false)}
        >
          <Close sx={{fontSize: 24}} />
        </button>
      </div>
      <div
        className={classnames(styles.content, 'content', {
          [styles[theme]]: theme !== '',
        })}
      >
        {children}
      </div>
      {Footer && (
        <div className={classnames(styles.footer, 'footer')}>{Footer}</div>
      )}
    </ReactModal>
  )
}
