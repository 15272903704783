import {connect} from 'react-redux'
import {ConsentToSend} from 'Interfaces'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {acceptSeekerLoginConsents, getSeekerLoginConsents} from 'Store/Actions'
import {SeekerLoginConsents} from './SeekerLoginConsents'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  consents: state.consents.data.consents,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getSeekerLoginConsents: () => dispatch(getSeekerLoginConsents()),
  acceptSeekerLoginConsents: (values: ConsentToSend[]) =>
    dispatch(acceptSeekerLoginConsents(values)),
})

export const SeekerLoginConsentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(SeekerLoginConsents)
