export const TypeAheadProps = (formik: any, name: string) => ({
  value: formik.values[name]?.id,
  setValue: formik.setFieldValue,
  setTouched: formik.getFieldHelpers(name).setTouched,
  error:
    formik.submitCount && formik.touched[name]
      ? formik.errors[name]
        ? formik.errors[name]['id']
        : undefined
      : undefined,
})
