import {useEffect} from 'react'
import {ErrorType} from '../Interfaces'
import {useTranslation} from './useTranslation'

export const useApiError = (
  setError: (name: string, message: string) => void,
  error?: ErrorType,
  primaryName?: string
): void => {
  const translation = useTranslation()
  useEffect(() => {
    if (error && 'details' in error && typeof error.details !== 'string') {
      error.details.forEach((e) => {
        if (e.property.match('.+Id$')) {
          setError(
            e.property.replace(/Id$/, ''),
            translation[e.constraints[0].message] || e.constraints[0].message
          )
        }
        let errorText = e.constraints ? e.constraints[0].message : 'ApiError'

        if (
          e.children &&
          e.children.length > 0 &&
          e.children[0]?.children &&
          e.children[0]?.children.length > 0 &&
          e.children[0]?.children[0] &&
          e.children[0]?.children[0]?.constraints &&
          e.children[0]?.children[0]?.constraints.length > 0 &&
          e.children[0]?.children[0]?.constraints[0]?.message
        ) {
          errorText = e.children[0]?.children[0]?.constraints[0].message
        }

        setError(e.property, translation[errorText] || errorText)
      })
    } else if (error?.message && primaryName) {
      setError(primaryName, translation[error?.message] || error?.message)
    }
  }, [error])
}
