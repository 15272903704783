import {FC, useState} from 'react'
import {FormattedMessage} from 'react-intl'
import {DateTime} from 'luxon'
import classnames from 'classnames'
import {
  Business,
  ConnectWithoutContact,
  Notifications,
  Update,
  HourglassBottom,
  DescriptionOutlined,
} from '@mui/icons-material'

import Modal from 'Components/Modal'
import {ThumbsFooter} from 'Components/Modal/ThumbsFooter'
import styles from './ModalContactRequest.module.scss'
import {ApproveRejectModalRequestType} from 'Interfaces'
import ModalRejectContactRequest from './ModalRejectContactRequest'
import ProposeNewTimeModal from 'Components/IncomingRequests/RequestActions/ProposeNewTimeModal'
import {IncomingRequestType} from 'Interfaces'
import {CandidateDashboardTranslations as cdt} from 'Services/I18n/Constants'
import {formatDate} from 'Utils/Date'
import {
  CombinedRequestStatues,
  ContactRequestStatus,
  seekerRequestStatusTranslationMap,
} from 'Components/IncomingRequests/Constants'

const ExpirationValue: FC<{
  date: string
}> = ({date}) => {
  const now = DateTime.now()
  // TODO candidate-dashboard: Set locale:
  // const expiration = DateTime.fromISO(date).setLocale()
  const expiration = DateTime.fromISO(date)
  const diff = Math.floor(expiration.diff(now, ['days']).days)
  let dayOfTheWeek = expiration.toFormat('cccc')
  if (now.day === expiration.day) {
    return (
      <div className={styles.expirationValueContainer}>
        <p className={styles.expirationValueTextRed}>
          <FormattedMessage id={cdt.expiresToday} />
        </p>
      </div>
    )
  }
  if (diff > 7 || expiration < now) {
    return (
      <p className={styles.expirationValueNormal}>
        <FormattedMessage id={dayOfTheWeek} />
        <span className={styles.date}>
          {expiration.toFormat(' (d.L.yyyy)')}
        </span>
      </p>
    )
  }

  return (
    <div className={styles.expirationValueContainer}>
      <p className={styles.expirationValueTextRed}>
        <FormattedMessage id={cdt.in} />
        {` ${diff} `}
        {diff === 1 ? (
          <FormattedMessage id={cdt.day} />
        ) : (
          <FormattedMessage id={cdt.days} />
        )}
      </p>
      <p className={styles.expirationValueText}>
        {expiration.toFormat('(d.L.yyyy)')}
      </p>
    </div>
  )
}

interface ModalContactRequestProps {
  request: IncomingRequestType
  isOpenContactRequest?: boolean
  setIsOpenContactRequest?: (open: boolean) => void
  approveContactRequest: (payload: ApproveRejectModalRequestType) => void
  thumbUp?: boolean
  modalDetails?: boolean
  setModalDetails?: (modalDetails: boolean) => void
  showDetails?: boolean
  approveModal?: boolean
  setApproveModal?: (approveModal: boolean) => void
}

export const ModalContactRequest: FC<ModalContactRequestProps> = ({
  request,
  isOpenContactRequest,
  setIsOpenContactRequest,
  thumbUp,
  modalDetails,
  setModalDetails,
  showDetails = true,
  setApproveModal,
}) => {
  const [details, setDetails] = useState(modalDetails)
  const [rejectModal, setRejectModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <Modal
        className={details ? styles.modalDetails : styles.modal}
        icon={DescriptionOutlined}
        isOpen={!!isOpenContactRequest}
        title={cdt.modalContactRequestTitle}
        onAfterClose={() => {
          setIsOpenContactRequest && setIsOpenContactRequest(false)
          setModalDetails && setModalDetails(false)
        }}
        shouldReturnFocusAfterClose={false}
        Footer={
          request.status === CombinedRequestStatues.PENDING && (
            <ThumbsFooter
              rejectText={cdt.reject}
              approveText={cdt.accept}
              theme={thumbUp ? 'thumbUp' : 'thumbs'}
              onApprove={() => {
                setApproveModal && setApproveModal(true)

                setIsOpenContactRequest && setIsOpenContactRequest(false)
              }}
              onReject={() => {
                setRejectModal(true)
              }}
              onCancel={() => {
                setIsOpenContactRequest && setIsOpenContactRequest(false)
                setModalDetails && setModalDetails(false)
              }}
            />
          )
        }
      >
        {!details ? (
          <div className={styles.containerInterview}>
            <p className={styles.text}>
              <FormattedMessage id={cdt.modalContactAprroveAreYouSure} />{' '}
              {request.companyName}?
            </p>
            <p className={styles.paragraph}>
              <FormattedMessage id={cdt.modalContactAprroveWhitThisApprove} />
            </p>

            <div className={styles.buttons}>
              <button
                onClick={() => {
                  setDetails(true)
                  setModalDetails && setModalDetails(false)
                }}
                className={styles.button}
              >
                <FormattedMessage id={cdt.viewDetails} />
              </button>
            </div>
          </div>
        ) : (
          <div className={styles.containerInterviewDetails}>
            <p className={styles.title}>{request.companyName}</p>
            <div className={styles.item}>
              <div className={styles.itemLeft}>
                <Business className={styles.icon} />
                <p className={styles.itemLeftText}>
                  <FormattedMessage id={cdt.company} />:
                </p>
              </div>
              <div className={styles.itemRight}>
                <p className={styles.itemRightText}>{request.companyName}</p>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.itemLeft}>
                <ConnectWithoutContact className={styles.icon} />
                <p className={styles.itemLeftText}>
                  {' '}
                  <FormattedMessage id={cdt.requestType} />:
                </p>
              </div>
              <div className={styles.itemRight}>
                <p className={styles.ContactRequestText}>
                  <FormattedMessage id={cdt.contactRequest} />
                </p>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.itemLeft}>
                <Notifications className={styles.icon} />
                <p className={styles.itemLeftText}>
                  <FormattedMessage id={cdt.requestStatus} />:
                </p>
              </div>
              <div className={styles.itemRight}>
                <p
                  className={classnames(styles.pending, {
                    [styles.rejected]:
                      request.status === ContactRequestStatus.REJECTED ||
                      request.status === ContactRequestStatus.CANCELLED,
                    [styles.accepted]:
                      request.status === ContactRequestStatus.APPROVED,
                    [styles.expired]:
                      request.status === ContactRequestStatus.EXPIRED,
                  })}
                >
                  <FormattedMessage
                    id={seekerRequestStatusTranslationMap[request.status]}
                  />
                </p>
              </div>
            </div>

            <div className={styles.item}>
              <div className={styles.itemLeft}>
                <Update className={styles.icon} />
                <p className={styles.itemLeftText}>
                  <FormattedMessage id={cdt.dateOfRequestSent} />:
                </p>
              </div>
              <div className={styles.itemRight}>
                <p className={styles.itemRightText}>
                  {request.sentDate && formatDate(request.sentDate)}
                </p>
              </div>
            </div>
            <div className={styles.item}>
              <div className={styles.itemLeft}>
                <HourglassBottom className={styles.icon} />
                <p className={styles.itemLeftText}>
                  <FormattedMessage id={cdt.expirationDate} />:
                </p>
              </div>
              <div className={styles.itemRight}>
                {request.expirationDate && (
                  <ExpirationValue date={request.expirationDate} />
                )}
              </div>
            </div>
          </div>
        )}
      </Modal>
      <ModalRejectContactRequest
        request={request}
        rejectModal={rejectModal}
        setRejectModal={setRejectModal}
        setDetails={setDetails}
        setIsOpenContactRequest={setIsOpenContactRequest}
        showDetails={!details ? showDetails : false}
      />

      <ProposeNewTimeModal
        request={request}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </>
  )
}
