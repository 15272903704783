import {FC, useEffect} from 'react'
import {useTranslation} from 'Hooks'
import {FormattedMessage} from 'react-intl'

import Modal from 'Components/Modal'
import {SubmitFooter} from 'Components/Modal'
import AttachmentFileUpload from 'Components/Inputs/AttachmentFileUpload'
import {useFormik} from 'formik'
import Title from 'Components/Title'
import Validation from './Validation'
import {SeekerStateType} from 'Interfaces'
import TextError from 'Components/Error/TextError'
import {ProfileTranslations as pt} from 'Services/I18n/Constants'
import styles from './ParseCvModal.module.scss'
import {
  DescriptionOutlined,
  Warning,
  WarningOutlined,
} from '@mui/icons-material'

interface ParseCvModalProps {
  parseCvModalOpen: boolean
  setParseCvModalOpen: Function
  reparseCv: (fileId: string) => void
  seeker: SeekerStateType
}

export const ParseCvModal: FC<ParseCvModalProps> = ({
  parseCvModalOpen,
  setParseCvModalOpen,
  reparseCv,
  seeker,
}) => {
  const translation = useTranslation()
  const formik = useFormik({
    initialValues: {
      file: {
        id: '',
      },
    },
    validationSchema: !seeker?.data?.full?.cvId && Validation,
    onSubmit: (values) => {
      if (!values.file.id && seeker?.data?.full?.cvId) {
        reparseCv(seeker?.data?.full?.cvId)
      } else {
        reparseCv(values.file.id)
      }
    },
  })

  useEffect(() => {
    if (!seeker.loading && !seeker.error) setParseCvModalOpen(false)
  }, [seeker.loading])

  return (
    <Modal
      isOpen={parseCvModalOpen}
      onAfterClose={() => {
        setParseCvModalOpen(false)
        formik.resetForm()
      }}
      title={
        seeker?.data?.full?.cvId
          ? translation[pt.reparseYourCv]
          : translation[pt.parseYourCv]
      }
      icon={DescriptionOutlined}
      ariaHideApp={false}
      className={styles.parseCvModal}
      Footer={
        <SubmitFooter
          disabled={seeker.loading}
          onSubmit={() => {
            if (!seeker.loading) formik.handleSubmit()
          }}
          onCancel={() => {
            setParseCvModalOpen(false)
            formik.resetForm()
          }}
        />
      }
    >
      <div className={styles.titleWrapper}>
        <Title
          text={
            seeker?.data?.full?.cvId
              ? translation[pt.reparseCv]
              : translation[pt.parseCv]
          }
          className={styles.title}
        />
      </div>
      <p className={styles.grayText}>
        <FormattedMessage id={pt.parsingNotificationAboutReadingData} />
      </p>
      <div className={styles.warningContainer}>
        <WarningOutlined />
        <p>
          <FormattedMessage id={pt.dataWillBeOverwrittenNotification} />
        </p>
      </div>
      <AttachmentFileUpload
        name="file"
        setValue={formik.setFieldValue}
        theme="white"
        integratedUploads={false}
        accept=".doc,.docx,.pdf"
      />
      {seeker.error || formik.errors.file ? (
        <div className={styles.errorWrap}>
          <Warning />
          <TextError
            text={seeker?.error?.message || formik?.errors?.file?.id || 'error'}
          />
        </div>
      ) : null}
    </Modal>
  )
}
