export const unsubscribeReason = {
  'unsubscribeReason.title': 'Uspešno ste se odjavili. ',
  'unsubscribeReason.description':
    'Ker nam vase mnenje veliko pomeni, vas prosimo, da nam sporočite kako zadovoljni ste z vsebino prejetih novic.',
  'unsubscribeReason.reasonSuccess': 'Uspešno ste se odjavili od novic.',
  'unsubscribeReason.warning':
    'Prišlo je do napake. Prosimo, poskusite ponovno.',
  'unsubscribeReason.checkBoxLabel1': 'Novice so prepogoste',
  'unsubscribeReason.checkBoxLabel2': 'Novice me ne zanimajo',
  'unsubscribeReason.checkBoxLabel3':
    'Vsebina novic ni takšna, kot bi si je želel/a',
  'unsubscribeReason.checkBoxLabel4': 'Ostalo',
  'unsubscribeReason.button': 'Pošlji',
}
