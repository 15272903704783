import {FC, useEffect} from 'react'
import {FormattedMessage} from 'react-intl'

import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact'
import OfferItem from './OfferItem'
import {EmployerRequestsStateType} from 'Interfaces'
import {SidebarTranslations as st} from 'Services/I18n/Constants'

import styles from './MyOffers.module.scss'

interface MyOffersProps {
  getEmployerPlacementConfirmedOffers: () => void
  placementConfirmedOffers: EmployerRequestsStateType
}

export const MyOffers: FC<MyOffersProps> = ({
  getEmployerPlacementConfirmedOffers,
  placementConfirmedOffers,
}) => {
  useEffect(() => {
    getEmployerPlacementConfirmedOffers()
  }, [])

  if (placementConfirmedOffers.searchResults.length < 1) {
    return null
  }

  return (
    <div className={styles.myOffersContainer}>
      <div className={styles.titleContainer}>
        <ConnectWithoutContactIcon />
        <h2>
          <FormattedMessage id={st.myOffers} />
        </h2>
      </div>
      <p>
        <FormattedMessage id={st.confirmHiringChoice} />
      </p>
      {placementConfirmedOffers.searchResults.map((placementConfirmedOffer) => (
        <OfferItem
          request={placementConfirmedOffer}
          key={placementConfirmedOffer.id}
        />
      ))}
    </div>
  )
}
