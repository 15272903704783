import {FC} from 'react'
import styles from './YellowLink.module.scss'
import paint from 'Components/YellowButton/paint.svg'
import paintBlue from 'Components/YellowButton/paintBlue.svg'
import classNames from 'classnames'
import {FormattedMessage} from 'react-intl'
import {Link} from 'react-router-dom'

interface ButtonType {
  text: string
  className?: string
  to: string
}

export const YellowLink: FC<ButtonType> = ({
  text,
  to,
  className,
}: ButtonType) => {
  return (
    <Link className={classNames(styles.link, className)} to={to}>
      <img className={styles.paint} src={paint} />
      <img className={styles.paintBlue} src={paintBlue} />
      <span className={styles.text}>
        <FormattedMessage id={text} />
      </span>
    </Link>
  )
}
