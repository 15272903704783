export enum OnboardingTranslations {
  step = 'onboarding.step',
  of = 'onboarding.of',
  titleCV = 'onboarding.titleCV',
  descriptionCV = 'onboarding.descriptionCV',
  titleOccupationalFields = 'onboarding.titleOccupationalFields',
  buttonNext = 'onboarding.buttonNext',
  buttonBack = 'onboarding.buttonBack',
  titleUploadCV = 'onboarding.titleUploadCV',
  cvDisclaimer = 'onboarding.cvDisclaimer',
  uploadFileFormats = 'onboarding.uploadFileFormats',
  uploadFileFormatsImage = 'onboarding.uploadFileFormatsImage',
  uploadDragAndDrop = 'onboarding.uploadDragAndDrop',
  uploadOr = 'onboarding.uploadOr',
  uploadBrowseFiles = 'onboarding.uploadBrowseFiles',
  uploadGoogleDrive = 'onboarding.uploadGoogleDrive',
  uploadiCloud = 'onboarding.uploadiCloud',
  uploadDropbox = 'onboarding.uploadDropbox',
  uploadOneDrive = 'onboarding.uploadOneDrive',
  uploadNoFile = 'onboarding.uploadNoFile',
  titleJobPreferences = 'onboarding.titleJobPreferences',
  descriptionJobPreferences = 'onboarding.descriptionJobPreferences',
  titleDesiredRegions = 'onboarding.titleDesiredRegions',
  titleWorkFromHome = 'onboarding.titleWorkFromHome',
  titleDesiredSalary = 'onboarding.titleDesiredSalary',
  titleAvailability = 'onboarding.titleAvailability',
  titleWorkExperience = 'onboarding.titleWorkExperience',
  titleTotalWorkExperience = 'onboarding.titleTotalWorkExperience',
  titleAddWorkExperience = 'onboarding.titleAddWorkExperience',
  titleEditWorkExperience = 'onboarding.titleEditWorkExperience',
  descriptionWorkExperience = 'onboarding.descriptionWorkExperience',
  titleTotalYearsWorkExperience = 'onboarding.titleTotalYearsWorkExperience',
  titleExperiencePerPosition = 'onboarding.titleExperiencePerPosition',
  typeAheadEnterPosition = 'onboarding.typeAheadEnterPosition',
  titleSkills = 'onboarding.titleSkills',
  selectMultiValue = 'onboarding.selectMultiValue',
  selectTypeYourSkills = 'onboarding.selectTypeYourSkills',
  titleBenefitsAndEducation = 'onboarding.titleBenefitsAndEducation',
  descriptionBenefitsAndEducation = 'onboarding.descriptionBenefitsAndEducation',
  titleWorkEnvironment = 'onboarding.titleWorkEnvironment',
  placeholderTextWorkEnvironment = 'onboarding.placeholderTextWorkEnvironment',
  titleAdditionalBenefits = 'onboarding.titleAdditionalBenefits',
  titleEducation = 'onboarding.titleEducation',
  titleAddEducation = 'onboarding.titleAddEducation',
  titleEditEducation = 'onboarding.titleEditEducation',
  dropDownSelectFromList = 'onboarding.dropDownSelectFromList',
  titleFieldOfStudy = 'onboarding.titleFieldOfStudy',
  titleCurrentSalary = 'onboarding.titleCurrentSalary',
  sideBarYourCV = 'onboarding.sideBarYourCV',
  sideBarJobPreferences = 'onboarding.sideBarJobPreferences',
  sideBarWorkExperience = 'onboarding.sideBarWorkExperience',
  sideBarBenefitsAndEducation = 'onboarding.sideBarBenefitsAndEducation',
  validationJobCategoryReq = 'onboarding.validationJobCategoryReq',
  uploadRemove = 'onboarding.uploadRemove',
  titleDesiredJobPositions = 'onboarding.titleDesiredJobPositions',
  titleJobSearchStatus = 'onboarding.titleJobSearchStatus',
  titleAreYouSureModal = 'onboarding.titleAreYouSureModal',
  descriptionAreYouSureModal = 'onboarding.descriptionAreYouSureModal',
  modalSaveChanges = 'onboarding.modalSaveChanges',
  modalDiscard = 'onboarding.modalDiscard',
  typeAheadTypeYour = 'onboarding.typeAheadTypeYour',
  typeAheadTypeYourSkills = 'onboarding.typeAheadTypeYourSkills',
  apiErrorMessage = 'onboarding.apiErrorMessage',
  continueWithoutParsedCV = 'onboarding.continueWithoutParsedCV',
  tryAgain = 'onboarding.tryAgain',
  cvParsing = 'onboarding.cvParsing',
  other = 'onboarding.other',
  otherJobPositionPlaceholder = 'onboarding.otherJobPositionPlaceholder',
  placeholderCurrentJobTitle = 'onboarding.placeholderCurrentJobTitle',
  currentJobTitle = 'onboarding.currentJobTitle',
  agree = 'onboarding.agree',
  generalTerms = 'onboardging.generalTerms',
  and = 'onboardging.and',
  termsAndConditions = 'onboardging.termsAndConditions',
  privacyPolicy = 'onboardging.privacyPolicy',
  thirdPartyAgreement = 'onboarding.thirdPartyAgreement',
  fileTooLarge = 'onboarding.fileTooLarge',
}
