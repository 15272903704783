import {ChangeEvent, FC, useEffect, useMemo, useState} from 'react'
import {Link} from 'react-router-dom'
import {Star, StarOutline} from '@mui/icons-material'

import styles from './MyProjects.module.scss'
import Hr from 'Components/Hr'
import TextField from 'Components/Inputs/TextField'
import {EmployerProjectItemType} from 'Interfaces'
import {cloneDeep} from 'lodash'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import {CandidateSearchTranslations as cs} from 'Services/I18n/Constants'
interface MyProjectsProps {
  projects: EmployerProjectItemType[]
  getProjects: (input?: string) => void
}

export const MyProjects: FC<MyProjectsProps> = ({projects, getProjects}) => {
  const [input, setInput] = useState('')

  useEffect(() => {
    getProjects()
  }, [])

  const filteredProjects = useMemo(() => {
    if (input === '') return projects
    return cloneDeep(projects).filter((project) =>
      project.title.toLowerCase().includes(input.toLowerCase())
    )
  }, [projects, input])

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.currentTarget.value)
    getProjects(e.currentTarget.value)
  }

  if (projects && projects.length > 0)
    return (
      <div className={styles.myProjectsWrap}>
        <Hr theme="widget" className={styles.hrBottom15} />
        <div className={styles.title}>
          <StarOutline className={styles.star} />
          <span className={styles.translation}>
            <FormattedMessage id={cs.myProjects} />
          </span>
        </div>
        <Hr theme="widget" className={styles.hrTop15} />
        <div className={styles.input}>
          <TextField
            name="widgetMyProjectsInput"
            value={input}
            onChange={handleInput}
            placeholder={cs.findProject}
          />
        </div>
        <Hr theme="widget" className={styles.hrBottom0} />
        <div className={styles.projectList}>
          {filteredProjects
            .filter((project) => project.status === 'active')
            .slice(0, 3)
            .map((project, idx) => (
              <div key={idx} className={styles.project}>
                <Star />
                <Link
                  to={getRoute(AppRoute.MyProjectCandidates).replace(
                    ':id',
                    project.id
                  )}
                  className={styles.translation}
                >
                  {project.title}
                </Link>
              </div>
            ))}
        </div>
        <Link
          className={styles.viewMoreLink}
          to={getRoute(AppRoute.MyProjects)}
        >
          <FormattedMessage id={cs.viewMoreProjects} />
        </Link>
      </div>
    )

  return null
}
