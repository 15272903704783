export const dataProtectionAgreement = {
  'dataProtectionAgreement.dataProtectionAgreementHtml': ` 
<div>
  <p class="subtitle">
    v skladu s tretjim odstavkom 28. člena Splošne uredbe EU o varstvu
    podatkov
  </p>
  <ol class="firstLevelOrderedList">
    <li>
      Preambula/predmet pogodbe
      <ol class="secondLevelOrderedList">
        <li class="titleTxt">  Pogodba o obdelavi osebnih podatkov (v nadaljevanju: <b>“Pogodba</b>”)
          vključno s pripadajočim Aneksom št. 1 tvori enotno celoto in je del
          Pravil in pogojev uporabe Preferenca.si, vsi navedeni dokumenti vključno
          z osnovno pogodbo o storitvah ali naročilnico (v nadaljevanju: »<b>Osnovna pogodba</b>«), na podlagi katere se naročajo posamične
          storitve, pa skupaj predstavljajo vsa določila pogodbe o izvajanju
          storitev. Ta Pogodba se sklepa skupaj s Pravili in pogoju uporabe
          Preferenca.si, in sicer med registriranim
          <b>Uporabnikom spletnega mesta Preferenca.si</b>, kadar tak uporabnik
          predstavlja pravno ali fizično osebo, ki storitve Preferenca.si naroča z
          namenom iskanja kadra in v tem svojstvu nastopa kot upravljavec osebnih
          podatkov posameznikov, ki jih obdeluje na spletnem mestu Preferenca.si
          (v nadaljevanju: »<b>upravljavec</b>«) in družbo
          <b>Styria digital marketplaces, d.o.o.</b>, Verovškova 55, 1000
          Ljubljana, Slovenija, davčna št.: SI58257551, ki na podlagi te Pogodbe
          obdeluje osebne podatke v imenu in za račun upravljavca ter v tem
          svojstvu nastopa kot pogodbeni obdelovalec podatkov (v nadaljevanju:
          »pogodbeni obdelovalec«).
          <span>
          V nadaljevanju tega dokumenta obe stranki skupno poimenovani »<b>pogodbeni stranki</b>«.
          </span>
        </li>
        <li>   V skladu s pogodbenimi ali pravnimi razmerji, ki so podrobneje navedena
          v Aneksu št. 1 (v nadaljevanju: »<b>Aneks</b>«), pogodbeni obdelovalec
          za upravljavca izvaja storitve, ki so v celoti ali delno sestavljene iz
          obdelave osebnih podatkov v skladu z 2. točko 4. člena Splošne uredbe EU
          o varstvu podatkov (EU General Data Protection Resolution, v
          nadaljevanju: »<b>GDPR</b>«). Ta Pogodba predstavlja osnovo za takšno
          obdelovanje podatkov v skladu s tretjim odstavkom 28. člena GDPR. V tem
          oziru je upravljavec podatkov odgovorna oseba, pogodbeni obdelovalec pa
          pogodbeno obdeluje podatke.
        </li>
        <li>V primeru dvomov ali nasprotij glede obdelave osebnih podatkov ima ta
          Pogodba v celoti prednost pred Osnovno pogodbo.
        </li>
        <li>Ne glede na podatke
          in/ali definicije, navedene v Aneksu, ta Pogodba pokriva vse osebne
          podatke, ki jih pogodbeni obdelovalec v katerikoli obliki obdeluje
          oziroma do njih dostopa ali lahko dostopi za namen polne izpolnitve
          Osnovne pogodbe.
        </li>
      </ol>
    </li>
    <li>
      Opis obdelovanja podatkov
      <ol class="secondLevelOrderedList">
        <li> Obdelovanje podatkov se izvaja v okviru obveznosti opredeljenih v
          Osnovni pogodbi; njeni bistveni deli so opredeljeni ali podrobneje
          predstavljeni kot referenca v členih Aneksa. Kot alternativa oziroma
          dopolnilo so v Aneksu opisani in/ali povzeti tudi predmet, trajanje,
          vrsta in nameni obdelovanja osebnih podatkov ter tisti osebni podatki,
          ki se prenašajo ali so dostopni pogodbenemu obdelovalcu za namen
          izpolnjevanja njegovih pogodbenih obveznosti, kot tudi kategorije
          posameznikov, katerih podatki se obdelujejo.
        </li>
        <li>Pogodbeni obdelovalec obdeluje osebne podatke izključno z namenom
          izpolnjevanja svojih pogodbenih obveznosti do upravljavca, kot je
          dogovorjeno v Osnovni pogodbi ali v tej Pogodbi ali v skladu z navodili
          upravljavca. Pogodbeni obdelovalec bo upravljavca vnaprej in v dopustnem
          obsegu obveščal o katerihkoli drugih pravnih obveznostih v zvezi z
          obdelovanjem.
        </li>
        <li> Pogodbeni obdelovalec v nobenih okoliščinah ne sme uporabljati podatkov
          za lastne namene, za namene tretjih strank ali jih posredovati tretjim
          strankam brez pisnega soglasja upravljavca. Kopije ali duplikati
          podatkov se lahko pripravijo brez ločenega soglasja upravljavca le v
          primeru, da so potrebne za zagotovitev ustreznega obdelovanja podatkov
          (t. i. varnostne kopije) ali če jih zahtevajo zakonske obveznosti glede
          hrambe podatkov.
        </li>
        <li> Podatki se obdelujejo v okviru ozemeljskega območja uporabe GDPR, razen
          v primeru, da je upravljavec dal pisno dovoljenje za prenos podatkov v
          tretje države in v skladu z zahtevami iz V. poglavja GDPR.
        </li>
        <li>V splošnem se morajo
          podatki obdelovati na način, ki upravljavcu v vsakem trenutku omogoča
          izpolnjevanje njegovih obveznosti do posameznikov, na katere se nanašajo
          podatki, in sicer skladno z zakonodajo s področja varstva osebnih
          podatkov in zahtevami pristojnih organov.
        </li>
        <li>Po zaključenem izvajanju
          dogovorjenih storitev (najkasneje pa ob izteku te Pogodbe) ali po
          predhodnem pisnem zahtevku upravljavca bo pogodbeni obdelovalec izbrisal
          vse osebne podatke, ki jih je pridobil v svojo posest v zvezi z
          izpolnjevanjem svojih obveznosti po Osnovni pogodbi, razen osebnih
          podatkov, za katere veljavni zakonski predpisi nalagajo daljši rok
          hrambe. Na zahtevo upravljavca se predloži na vpogled zapisnik oziroma
          dokazilo o takšnem izbrisu podatkov.
        </li>
        <li>Dokumentacija, ki
          predstavlja dokaz pravilnega obdelovanja podatkov v skladu s to Pogodbo,
          se v potrebnem obsegu hrani tudi po izteku veljavnosti te Pogodbe.
        </li>
        <li>Z vsakim preseganjem v
          tej Pogodbi in Aneksu določnega obsega obdelovanja, se pogodbeni
          obdelovalec šteje za upravljavca podatkov in za takšno prekoračitev
          pooblastil prevzema vso odgovornost v skladu z desetim odstavkom 28.
          člena GDPR.
        </li>
      </ol>
    </li>
    <li>
      Pravice in obveznosti upravljavca
      <ol class="secondLevelOrderedList">
        <li>V okviru tega pogodbenega
          razmerja upravljavec sam odloča o namenih in sredstvih obdelave osebnih
          podatkov, ki jih prenaša v obdelavo. Upravljavec zagotovi dopustnost
          naročene obdelave podatkov in ustrezno pravno podlago ter jamči za
          skladnost z vsemi predpisi o varstvu osebnih podatkov in spoštovanje
          pravic posameznikov, na katere se nanašajo osebni podatki, napram
          tretjim strankam.
        </li>
        <li>V tem smislu ima
          upravljavec celovito pravico dajati navodila pogodbenemu obdelovalcu v
          zvezi z vrsto in obsegom obdelovanja podatkov. Če po mnenju pogodbenega
          obdelovalca takšno navodilo krši veljavne zakonske predpise o varstvu
          osebnih podatkov, mora o tem nemudoma obvestiti upravljavca, lahko pa
          tudi opusti izvajanje navodil do prejema novih ali dopolnitve
          obstoječih. Nezakonitih navodil pogodbeni obdelovalec ni dolžan
          upoštevati.
        </li>
        <li>Pogodbeni obdelovalec omogoči izbris, popravek, omejitev, prenosljivost
          podatkov in pravico do dostopa do podatkov, če je takšna funkcionalnost
          del naročene storitve. Odločitev glede omejevanja, izbrisa ali popravka
          podatkovnih zapisov, ki so predmet te Pogodbe, pa je izključna pravica
          upravljavca.
        </li>
        <li>Če se posamezniki, na katere se nanašajo podatki, z vprašanjem oziroma
          zahtevki obrnejo neposredno na pogodbenega obdelovalca, mora ta takšne
          zahtevke nemudoma posredovati upravljavcu. Načeloma je komunikacija s
          tretjimi osebami naloga upravljavca kot odgovorne osebe. Pogodbeni
          obdelovalec pa ima pravico odgovoriti na vprašanja posameznikov le, če
          so ta vezana na tehnično delovanje storitev, ki jih zagotavlja pogodbeni
          obdelovalec.
        </li>
      </ol>
    </li>
    <li>
      Obveznosti pogodbenega obdelovalca
      <ol class="secondLevelOrderedList">
        <li> Pogodbeni obdelovalec je odgovoren za obdelavo podatkov v okviru zanj
          zavezujočih pogodbenih določil in veljavnih zakonskih predpisov o
          varstvu osebnih podatkov, pri čemer jamči, da je seznanjen z vsemi
          tozadevnimi predpisi v Republiki Sloveniji oziroma EU.
        </li>
        <li> Katerekoli specifične obveznosti, ki ne izhajajo neposredno iz Osnovne
          pogodb ali veljavne zakonodaje, kakor tudi ne iz objektivne pravice
          pogodbenega obdelovalca, se vpišejo v Aneks kot »Specifična navodila za
          obdelavo osebnih podatkov«. Upravljavec ima pravico občasno uskladiti že
          izdana navodila oziroma izdati nadaljnja in/ali drugačna navodila,
          vendar venomer v pisni obliki. Pogodbeni obdelovalec bo skrbno
          dokumentiral vsa navodila v skladu s tretjim odstavkom (a) 28. člena
          GDPR. Poleg tega velja za pogodbenega obdelovalca tudi obveznost
          opozarjanja v skladu s točko 3.2 te Pogodbe.
        </li>
        <li> Pogodbeni obdelovalec jamči, da so vse zaposlene oziroma pooblaščene
          osebe za obdelavo podatkov po tej Pogodbi, ustrezno usposobljene in
          zavezane k spoštovanju zaupnosti podatkov oziroma so zavezane k
          ustreznim, še zlasti pa zakonsko določenim obveznostim varovanja
          poslovne skrivnosti v skladu s tretjim (b) odstavkom 28. člena GDPR.
          Obveznost varovanja poslovne skrivnosti vključuje vse podatke, ki so
          predmet obdelave, in se jo spoštuje časovno neomejeno, to pomeni tako
          pred začetkom kot tudi po končanju obdelave podatkov. Pogodbeni
          obdelovalec mora ustrezno poučiti in usposobiti svoje zaposlene v zvezi
          z varstvom in varnostjo podatkov, zaupnostjo na splošno, jim dajati
          ustrezna navodila kot tudi nadzirati zaposlene pri obdelavi podatkov po
          tej Pogodbi.
        </li>
        <li> Pogodbeni obdelovalec se v skladu z 32. členom GDPR zavezuje izvesti vse
          tehnične in organizacijske ukrepe, ki so potrebni za zagotavljanje varne
          obdelave podatkov v skladu s točko (c) tretjega odstavka 28. člena GDPR.
          Še zlasti bo pogodbeni obdelovalec sprejel vse varnostne ukrepe, ki so
          potrebni za zagotavljanje zaupnosti, celovitosti, dostopnosti in
          odpornosti sistemov in storitev za obdelavo, ter v zvezi s tem zagotovil
          redno kontrole sprejetih ukrepov (preverjanje, ocenjevanje, vrednotenje
          in po potrebi prilagajanje). Ukrepi, ki jih uvede pogodbeni obdelovalec,
          so opisani v Aneksu, na zahtevo pa se lahko podrobneje opišejo v
          varnostnem konceptu, ki se posreduje upavljavcu.
        </li>
        <li>Pogodbeni obdelovalec bo
          po svojih najboljših močeh nudil podporo upravljavcu pri izvajanju
          njegovih obveznosti informiranja posameznikov ter pri obdelavi zahtevkov
          za uresničevanje pravic posameznikov, na katere se nanašajo podatki, v
          skladu s tretjim odstavkom (e) 28. člena GDPR. Še zlasti se v okviru
          nalog iz Osnovne pogodbe navedeno nanaša na izvajanje tehničnih in
          organizacijskih ukrepov, ki jih je potrebno strukturirati tako, da
          omogočajo upravljavcu izpolnjevanje obveznosti do posameznikov, na
          katere se nanašajo podatki, v skladu z določbami GDPR, ki urejajo
          pravice posameznikov, na katere se nanašajo osebni podatki (pravica do
          dostopa, popravka, izbrisa (pravica do pozabe), pravica do
          prenosljivosti podatkov in ugovora), in v določenih rokih. V ta namen bo
          pogodbeni obdelovalec zagotovil upravljavcu vse potrebne informacije, ki
          so mu na voljo.
        </li>
        <li>Ob upoštevanju vrste
          obdelovanja podatkov in razpoložljivih informacij, bo pogodbeni
          obdelovalec na najboljši možni način podpiral upravljavca tudi pri
          izpolnjevanju obveznosti v skladu s členi 30–36 GDPR (vodenje evidence
          dejavnosti obdelave osebnih podatkov, sodelovanje z nadzornimi organi,
          izvajanje tehničnih in organizacijskih zaščitnih ukrepov, ocenjevanje
          učinka v zvezi z varstvom podatkov in obveščanje o kršitvah varstva
          podatkov) v skladu s tretjim odstavkom (f) 28. člena GDPR.
        </li>
        <li>Pogodbeni obdelovalec bo
          upravljavca (ali njegovo imenovano pooblaščeno osebo za varstvo
          podatkov) v okviru svojega delovanja nemudoma obvestil o kakršnikoli
          kršitvi varstva oziroma varnosti podatkov, ki so predmet te Pogodbe,
          najkasneje pa v roku 24 ur po ugotovitvi, da je prišlo do takšnega
          dogodka. Pri tem bo navedel vrsto kršitve, kategorije in približno
          število prizadetih podatkovnih zapisov ter posameznikov, na katere se
          nanašajo osebni podatki, možne posledice kršitve, načrtovane in sprejete
          protiukrepe za odpravo kršitve ali za lajšanje njenih posledic, kot tudi
          kontaktne podatke odgovorne osebe ali drugo kontaktno točko pogodbenega
          obdelovalca, za pridobivanje nadaljnjih informacij.
        </li>
        <li>Upravljavca se takoj
          obvesti tudi o katerihkoli drugih pomembnih motnjah pri izvajanju nalog
          pogodbenega obdelovalca, njegovih zaposlenih in/ali njegovih pogodbenih
          podobdelovalcev ter njihovih kršitvah veljavnih zakonskih predpisov s
          področja varstva podatkov, vse v okviru izvajanja predmetne Pogodbe.
          Nadalje se pogodbeni obdelovalec zavezuje obvestiti upravljavca o vsakem
          uradnem/sodnem posegu ali postopku, ki se nanaša na naročeno obdelavo
          podatkov, če je tako obveščanje zakonsko dopustno.
        </li>
        <li>V primeru, da je
          pogodbeni obdelovalec zavezan, s kakšno pravno določbo znotraj prava EU
          ali veljavnega nacionalnega prava držav članic, obdelovati osebne
          podatke ter če zadevno pravo ne prepoveduje pošiljanja takšnega
          obvestila zaradi pomembnega javnega interesa, bo pogodbeni obdelovalec o
          tem obvestil upravljavca pred obdelavo podatkov.
        </li>
      </ol>
    </li>
    <li>
      Uporaba drugih pogodbenih podobdelovalcev
      <ol class="secondLevelOrderedList">
        <li>Pogodbeni obdelovalec ne
          bo za izpolnitev te Pogodbe ali njenih delov zaposlil drugega
          pogodbenega podobdelovalca brez predhodnega posebnega ali splošnega
          pisnega soglasja upravljavca. V primeru splošnega pisnega soglasja bo
          pogodbeni obdelovalec venomer obvestil upravljavca pred katerokoli
          načrtovano spremembo v zvezi z zaposlitvijo nadaljnjih ali zamenjavo
          obstoječih pogodbenih obdelovalcev. V tem primeru je upravljavec
          upravičen podati ugovor zoper takšne spremembe, in sicer v roku 14 dni.
        </li>
        <li>Vsako izdajanje posebnih
          ali splošnih soglasij za uporabo nadaljnjih pogodbenih podobdelovalcev
          je opredeljeno v Aneksu. Če na tem mestu ni podane izbire, se šteje, da
          ni bilo izdano ne posebno ne splošno soglasje.
          <span>
          Za družbe, ki imajo posebno odobritev v Aneksu ter na splošno odobreni
          podnalog, se to dovoljenje vedno šteje za pogojno, le v primeru in vse
          dokler je vsak pogodbeni podobdelovalec (i) objektivno primeren za
          izvajanje specifičnih pogodbenih nalog in (ii) če so, v izkazano
          sklenjeni pogodbi in v skladu s četrtim odstavkom 28. člena GDPR,
          zagotovljene vsaj enake obveznostih glede varstva podatkov kot v tej
          Pogodbi, še zlasti pa zadostna jamstva za izvajanje ustreznih
          tehničnih in organizacijskih ukrepov za obdelavo podatkov v skladu z
          zahtevami GDPR. Če pogodbeni podobdelovalec zagotavlja dogovorjeno
          delo v tretji državi v smislu določil GDPR (izven EU in/ali EGP),
          pogodbeni obdelovalec zagotavlja zanesljivost varstva podatkov v
          skladu z določili V. poglavja GDPR.
          </span>
        </li>
        <li>V vsakem primeru
          pogodbeni obdelovalec upravljavcu jamči brez omejitev za vsa pravna
          dejanja in opustitve (še zlasti relevantne v smislu veljavnih predpisov
          o varstvu podatkov) pogodbenega podobdelovalca, ki ga je zaposlil.
        </li>
      </ol>
    </li>
    <li>
      Dokaz o skladnosti / soglasje za izvajanje revizij
      <ol class="secondLevelOrderedList">
        <li>Pogodbeni obdelovalec
          mora upravljavcu zagotoviti vse potrebne informacije, kot dokaz
          skladnosti s svojimi obveznostmi po tej Pogodbi in 28. členom GDPR ter
          omogočati in pomagati pri pregledu (vključno z inšpekcijskimi pregledi),
          ki jih izvede upravljavec ali oseba, ki jo upravljavec za ta namen
          zaposli. Kadar je to mogoče, se takšni pregledi izvedejo po predhodnem
          dogovoru s pogodbenim obdelovalcem. Upravljavec teh pravic ne bo
          uveljavljal pogosteje kot enkrat v pogodbenem letu, razen kadar ima za
          to specifičen razlog.
        </li>
        <li>Skladnost z odobrenimi
          pravili ravnanja v skladu s 40. členom GDPR, potrjevanje v skladu z
          odobrenimi postopki po 42. členu GDPR ali aktualne potrditve ali
          poročila neodvisnih institucij (npr. revizorjev, pooblaščenih oseb za
          varstvo podatkov, oddelka za varnost informacijske tehnologije,
          revizorjev kakovosti) se lahko uporabijo za namen izkazovanja oziroma
          kot dokaz zgoraj navedene skladnosti.
        </li>
      </ol>
    </li>
    <li>
      Pooblaščena oseba za varstvo podatkov/predstavnik
      <ol class="secondLevelOrderedList">
        <li>Če so izpolnjeni
          predpogoji iz 37. člena GDPR, se pogodbeni obdelovalec zaveže (vsaj za
          čas trajanja te Pogodbe), da bo imenoval pooblaščeno osebo za varstvo
          podatkov ter o tem nemudoma obvestil upravljavca in mu sporočil
          kontaktne podatke kot tudi vsako spremembo tako imenovane osebe.
        </li>
        <li>Če se pogodbeni
          obdelovalec nahaja ali preseli svoj sedež izven ozemeljskega področja
          uporabe GDPR, bo upravljavca nemudoma obvestil o določitvi predstavnika
          s sedežem v Republiki Sloveniji, kot izhaja iz 27. člena GDPR.
        </li>
        <li>Pogodbeni obdelovalec
          določi vsaj eno pooblaščena osebo za izvajanje te Pogodbe, ki je dovolj
          poučena in enostavno dostopna.
        </li>
      </ol>
    </li>
    <li>
      Trajanje pogodbe/prekinitev
      <ol class="secondLevelOrderedList">
        <li>Ta Pogodba stopi v
          veljavo, ko jo podpišeta ali na drug ustrezen način potrdita obe
          pogodbeni stranki, in velja kot priloga k Osnovni pogodbi, in sicer vse
          dokler pogodbeni obdelovalec izvaja storitve iz Osnovne pogodbe, za
          katere se uporabljajo veljavni zakonski predpisi s področja varstva
          osebnih podatkov. Pogodba se prekine v primeru izteka Osnovne pogodbe in
          popolnega prenehanja izvajanja storitev na strani pogodbenega
          obdelovalca, vključno z zagotavljanjem uporabniškim dostopov (ne glede
          na razloge za to).
        </li>
        <li>V primeru težjih kršitev
          te Pogodbe ali veljavne zakonodaje lahko vsaka pogodbena stranka
          kadarkoli, predčasno in brez odpovednega roka (izredna odpoved), prekine
          to Pogodbo. Upravljavec lahko to še zlasti stori, če pogodbeni
          obdelovalec ne izvaja ali zavrača izvajanje dogovorjenih/obveznih
          varnostnih ukrepov ali zakonskih nalog oziroma ustrezne preglede
          upravljavca. V primeru drugih lažjih kršitev se pogodbeni stranki, ki je
          odgovorna za takšne kršitve, dodeli vsaj dvotedensko obdobje za odpravo
          kršitev. Če kršiteljica v danem roku ne odpravi kršitev, lahko pogodbi
          zvesta stranka odpove to Pogodbo enako kot v primeru težjih kršitev.
        </li>
        <li>Takšna izredna odpoved
          velja, ne glede na določila opredeljena v tej Pogodbi ali Osnovni
          pogodbi, enako tudi za Osnovno pogodbo, ker je njen predmet zajet s to
          Pogodbo. Osnovna pogodba ostane v veljavi samo v razmerju do preostalih
          storitev, ki jih pokriva, če je takšne storitve mogoče ločiti na
          ekonomsko smiseln in uresničljiv način. Pogodbena stranka, ki odpoveduje
          Pogodbo, o tem obvesti nasprotno pogodbeno stranko v pisni obliki.
        </li>
        <li>Po izteku Pogodbe in na
          podlagi izrecne pisne zahteve upravljavca pogodbeni obdelovalec vrne ali
          izbriše vse osebne podatke, ki so predmet te Pogodbe (razen v primeru
          obvezne hrambe takih podatkov v skladu z EU ali veljavno nacionalno
          zakonodajo), rezultate obdelave in druge dokumente oziroma sproži
          postopek vrnitve/izbrisa tovrstnih podatkov s strani pogodbenih
          podobdelovalcev. Obveznost varovanja tajnosti podatkov velja neomejeno
          časovno obdobje in tudi po izteku veljavnosti te Pogodbe.
        </li>
      </ol>
    </li>
    <li>
      Odgovornost
      <ol class="secondLevelOrderedList">
        <li>Obveznosti oziroma
          odgovornost pogodbenih strank se določi v skladu z določili Osnovne
          pogodbe. Če Osnovna pogodba vsebuje kakršnekoli privilegije glede
          odgovornosti, se njihova uporaba glede kršitev te Pogodbe izrecno
          izključuje.
        </li>
        <li>Vsaka pogodbena stranka
          je odgovorna brez omejitev za vsa svoja dejanja in škodljive posledice
          kršitev zakonskih predpisov o varstvu podatkov, in sicer na svojem
          področju odgovornosti, ki je določeno s to Pogodbo in veljavnimi
          zakonskimi predpisi glede odgovornosti, zato bo drugi pogodbeni stranki
          povrnila vso škodo in jo obvarovala pred zahtevki tretjih oseb v zvezi s
          tem.
        </li>
      </ol>
    </li>
    <li>
      Končne določbe
      <ol class="secondLevelOrderedList">
        <li>Dopolnitve in spremembe
          te Pogodbe morajo biti pripravljene v pisni obliki, pri čemer se
          elektronska komunikacija poslana na zadnji navedeni e-naslov pogodbene
          stranke, šteje za ustrezno.
        </li>
        <li>Pogodbeni stranki se
          zavezujeta spoštovati določila o varovanju tajnosti podatkov, vključno z
          obstojem in vsebino te Pogodbe in Osnovne pogodbe (poslovna skrivnost),
          če ne obstajajo zakonske obveznosti glede razkrivanja in posredovanja
          informacij tretjim osebam. V primeru dvoma se bo z vsemi informacijami,
          pridobljenimi v okviru tega pogodbenega razmerja, ki se nanašajo na
          notranje poslovanje, delovne procese in ukrepe varstva podatkov druge
          pogodbene stranke ravnalo kot z zaupnimi informacijami tudi po izteku
          Pogodbe.
        </li>
        <li>Če so posamezni deli
          oziroma določbe te Pogodbe neveljavni oziroma nični ali takšni postanejo
          po sklenitvi Pogodbe, to ne bo vplivalo na veljavnost preostalih delov
          oziroma določb Pogodbe, temveč se bo takšne dele nadomestilo z
          dopustnimi in/ali veljavnimi določbami, ki najbolj ustrezajo vsebini
          in/ali namenu, ki sta ga prvotno zasledovali pogodbeni stranki. Enako
          velja tudi za morebitne pravne praznine v tej Pogodbi.
        </li>
        <li>To Pogodbo ureja
          izključno slovensko pravo ter veljavno pravo Evropske unije, še zlasti
          pa določbe Splošne uredbe o varstvu podatkov. Pristojno sodišče je
          sodišče v Ljubljani.
        </li>
      </ol>
    </li>
  </ol>
  <div class="signatureContainer">
    <p class="place">Ljubljana, 01. 02. 2023</p>
    <p>
      <b>Styria digital marketplaces, d.o.o.</b>
    </p>
    <p class="director">Tomaž Gorjup, direktor</p>
    <img src="#signature-image" alt="CEO Signature" />
  </div>
</div>
<div class="annexContainer">
  <h1 class="annexTitle">Aneks št. 1 k Pogodba o obdelavi osebnih podatkov</h1>
  <p class="subtitle">(v nadaljevanju: »Aneks«)</p>
  <div class="articleContainer">
    <p class="articleTitle">1. člen <br/>Opredelitev obdelave podatkov</p>
    <p>
      Pogodbeni stranki z Aneksom opredelita obdelovanje osebnih podatkov in
      povezanih nalog ter naloge in odgovornosti pogodbenega obdelovalca.
    </p>
    <p>
      Poslovno sodelovanje, na podlagi katerega se izvaja obdelava osebnih
      podatkov iz te Pogodbe in Aneksa, je opredeljeno v Osnovni pogodbi
      oziroma naročilnici.
    </p>
    <p class="specificationTitle">SPECIFIKACIJA IZVAJANJA:</p>
    <ol class="annexFirstLevelOrderedList">
      <li>
        Vrste podatkov, ki jih pokriva obdelovanje po tej Pogodbi
        <ol class="annexSecondLevelOrderedList">
          <li><b>Podatki iz življenjepisa registriranega uporabnika Preferenca.si</b><br/> Osnovni podatki: ime in priimek/stopnja izobrazbe/datum rojstva/kraj
            rojstva/naziv/spol.<br/> Kontaktni podatki: telefonska številka/naslov za
            elektronsko pošto/naslov prebivališča/mesto dela/drugi naslovi.<br/> Ostali
            podatki, ki jih kandidat lahko prosto vnese v življenjepis (slika,
            državljanstvo, poklic in drugo).
          </li>
          <li><b>Rezultati psihometričnih in kognitivnih testiranj registriranega uporabnika Preferenca.si</b></br>Opisni podatki posameznika, ki je opravil navedena testiranja, o ustreznosti oziroma kompatibilnosti posameznika z določenim
            delovnim mestom oziroma področjem dela.
          </li>
          <li><b>Podatki iz ostalih dokazil potrebnih za izbor in selekcijo kadrov</b></br>Potrdila o delovnih izkušnjah in pridobljenih znanjih/licencah, priporočila, dokazila o uspešnosti in drugo.</li>
          <li><b>Kontaktni podatki zaposlenih pri pogodbenih strankah</b></br>Kontaktni podatki: ime in priimek, telefonska številka/naslov za elektronsko pošto.</li>
        </ol>
      </li>
      <li><b>V zvezi z upravljavcem podatkov so posamezniki, na katere se nanašajo podatki:</b></br><span class="basicListStyle">Registrirani uporabniki Preferenca.si, ki na kakršenkoli način razkrivajo podatke upravljavcu za namen zaposlovanja.<br/>
        Zaposleni pri pogodbenih strankah.</span>
      </li>
      <li><b>Pravna podlaga za obdelavo podatkov:</b></br><span class="basicListStyle">Soglasje – za obdelavo osebnih podatkov posameznika, ki išče zaposlitev.
        <br/>Pogodba – kadar se obdelujejo osebni podatki posameznikov zaposlenih pri pogodbenih strankah.</span>
      </li>
      <li><b>Načini obdelovanja podatkov:</b></br><span class="basicListStyle">Vpogled, zbiranje/evidentiranje, analiziranje, hramba, organizacija/urejanje, prilagajanje/popravljanje/dopolnjevanje, izpisovanje,
        primerjanje/povezovanje, omejevanje, izbris/uničenje, vzpostavljanje stika preko različnih kanalov (e-pošta, telefon).</span>
      </li>
      <li><b>Nameni obdelovanja podatkov:</b></br><span class="basicListStyle">Kadrovske zadeve (selekcijski izbori oz. »headhunting«), statistična analiza/arhiviranje, priprava vedenjskih ali osebnih profilov
        (psihometrična in kognitivna testiranja), druga elektronska ali telefonska komunikacija, sistemsko oziroma tehnično obveščanje.</span>
      </li>
      <li><b>Specifična navodila glede obdelave podatkov:</b></br><span class="basicListStyle">V primeru, da upravljavec sam ne bo brisal ali ne bo zmožen zagotoviti izbrisa osebnih podatkov iz svojega uporabniškega profila
        Preferenca.si po izpolnitvi namena obdelave oziroma v primeru druge nezmožnosti samostojne uporabe spletnega mesta
        Preferenca.si, bo o tem pravočasno pisno obvestil pogodbenega obdelovalca, da to stori v njegovem imenu.</span>
      </li>
    </ol>
  </div>
  <div class="articleContainer">
    <p class="articleTitle">2. člen <br/>Tehnični in organizacijski ukrepi</br>(prvi odstavek 32. člena GDPR)</p>
    <p>
      V zvezi z lastnimi aktivnostmi obdelovanja pogodbeni obdelovalec ponuja dovoljšnje jamstvo, da se bodo izvedli ustrezni tehnični
      in organizacijski ukrepi, da se bo obdelovanje podatkov izvajalo v skladu z zahtevami GDPR in ustrezno zaščito posameznikov,
      na katere se nanašajo podatki (ob upoštevanju stanja, stroškov implementacije ter vrste, obsega okoliščin in namenov
      obdelovanje ter različne verjetnosti pojavljanja in resnosti tveganja za pravice in svoboščine posameznikov, na katere se nanašajo
      podatki) in za katere se vse to zagotovi, še zlasti pa v zvezi z zaupnostjo, integriteto, razpoložljivostjo podatkov in vzdržljivostjo
      sistemov. 
    </p>
    <ol class="firstLevelOrderedList">
      <li>
        Zaupnost
        <ol class="noStyleOrderedList">
          <span class="underlinedText">Nadzor dostopa</span>
          <li class="indentedText">Preprečitev nepooblaščenega dostopa do sistemov za obdelovanje podatkov.</li>
          <li class="indentedText">Zagotovitev ustrezne zaščite vseh podatkovih centrov.</li>
          </li>
        </ol>
        <ol class="noStyleOrderedList">
          <span class="underlinedText">Nadzor hrambe podatkov</span>
          <li class="indentedText">Prepoved nepooblaščene rabe podatkov.</li>
          <li class="indentedText">Redna priprava novih gesel in standardov za podeljevanje gesel, samodejni mehanizmi za zaklepanje, šifriranje nosilcev digitalnih podatkov na prenosnih računalnikih v primeru izgube.
          </li>
        </ol>
        <ol class="noStyleOrderedList">
          <span class="underlinedText">Nadzor spomina</span>
          <li class="indentedText">Prepoved nepooblaščenega branja, kopiranja, spreminjanja ali odstranjevanja znotraj sistema.
          </li>
          <li class="indentedText">Zagotavljanje sledljivosti avtorizacije in pravice dostopa v skladu z zahtevki.</li>
        </ol>
      </li>
      <li>
        Celovitost
        <ol class="noStyleOrderedList">
          <span class="underlinedText">Nadzor prenosa</span>
          <li class="indentedText">Prepoved nepooblaščenega branja, kopiranja, spreminjanja ali
            odstranjevanja z elektronskim prenosom ali prevozom.
          </li>
          <li class="indentedText"> Virtualno zasebno omrežje (VPN) – nobena povezava ni možna brez
            avtentikacije.
          </li>
        </ol>
      </li>
      <li>
        Dostopnost in odpornost
        <ol class="noStyleOrderedList">
          <span class="underlinedText">Nadzor dostopnosti in odpornosti</span>
          </li>
          <li class="indentedText">Zaščita pred nenamernim ali namernim uničenjem ali izgubo.</li>
          <li class="indentedText">Strategija priprave varnostnih kopij (spletnih/nespletnih, na
            lokaciji/izven lokacije, napajalnik za neprekinjeno napajanje, zaščita
            pred virusi, požarni zid).
          </li>
        </ol>
        <ol class="noStyleOrderedList">
          <span class="underlinedText">Hitra obnovitev razpoložljivosti in dostopnosti do podatkov</span>
          </li>
          <li class="indentedText">Tudi pri fizičnem ali tehničnem incidentu.</li>
        </ol>
      </li>
    </ol>
  </div>
  <div class="articleContainer">
    <p class="articleTitle">3. člen <br/>Pogodbeni podobdelovalci <br/>(drugi pogodbeni obdelovalci)
    <p>3.1 Uporaba naslednjih pogodbenih podobdelovalcev se šteje za ločeni dogovor v skladu s predpogoji točke 5 te Pogodbe:</p>
    <ol class="annexSecondLevelOrderedList">
      <li class="indentedText">Podjetje: Alterset d.o.o.; naslov: Pod borovci 32, 1217 Vodice, Slovenija; matična številka: 8491054000, vzdržuje in razvija
        delovno okolje spletnega portala Preferenca.si.
      </li>
      <li class="indentedText">Podjetje: Specto informacijske tehnologije d.o.o.; naslov: Dunajska cesta 49, 1000 Ljubljana, Slovenija; matična številka:
        6699260000, vzdržuje in razvija delovno okolje spletnega portala Preferenca.si.
      </li>
      <li class="indentedText">Podjetje: Styria IT solutions d.o.o.; naslov: Oreškovičeva 3D, 10 010 Zagreb, Hrvaška; OIB: 33135361274 zagotavlja in
        vzdržuje fizično in/ali navidezno okolje operacijskega sistema ter zagotavlja storitve za varno hrambo podatkovnih baz.
      </li>
    </ol>
    </ol>
    </p>
    <p class="statement">
      3.2 Pogodbeni stranki ugotavljata, da na dan sklenitve tega Aneksa pogodbeni obdelovalec sodeluje s pogodbenimi
      podobdelovalci navedenimi v točki 3.1 tega Aneksa. Vse nadaljnje pogodbene podobdelovalce bo pogodbeni obdelovalec
      objavil na svoji spletni strani, upravljavec pa skladno s točno 5 te pogodbe podaja splošno soglasje pod pogojem, da
      pogodbeni obddelovalec sklene ustrezno pogodbo z novimi pogodbenimi podobdelovalci. 
    </p>
  </div>
  <div class="articleContainer">
    <p class="articleTitle">4. člen <br/>Kontaktni podatki</p>
    <p>
      Pooblaščena oseba za varstvo podatkov pri pogodbenemu obdelovalcu je dostopna na e-naslovu:<br/>
      dpo_styria-slo@styria.com. 
    </p>
  </div>
  <div class="signatureContainer">
    <p class="place">Ljubljana, 01. 02. 2023</p>
    <p>
      <b>Styria digital marketplaces, d.o.o.</b>
    </p>
    <p class="director">Tomaž Gorjup, direktor</p>
    <img src="#signature-image" alt="CEO Signature" />
  </div>
</div>
`,
}
