import {FC, useEffect} from 'react'
import {Link, useHistory, useLocation} from 'react-router-dom'

import {
  AuthStateType,
  CbtAccountStatus,
  RjbProfileStatus,
  UserType,
} from 'Interfaces'
import styles from './EmailVerificationNotice.module.scss'
import LoginLayout from 'Layouts/LoginLayout'
import {Description, Title} from 'Components/Login'
import {AppRoute, EmailVerification as ev} from 'Services/I18n/Constants'
import YellowLink from 'Components/YellowLink'
import {getRoute} from 'Services/I18n/Utils'
import {getQuerySearchParam} from 'Utils/Routes'
import classnames from 'classnames'
import {FormattedMessage} from 'react-intl'
import {hasGrantedMandatoryConsents} from 'Utils/Consents'

interface EmailVerificationProps {
  auth: AuthStateType
  getUser: () => void
}

export const EmailVerificationNotice: FC<EmailVerificationProps> = ({
  auth,
  getUser,
}) => {
  let location = useLocation()
  let history = useHistory()
  const token = getQuerySearchParam(location.search, 'token')

  useEffect(() => {
    if (!auth.dirty && !auth.data?.id) {
      getUser()
    }
  }, [])

  useEffect(() => {
    if (auth.dirty && !auth.loading && !auth.data?.id && !token) {
      history.push(getRoute(AppRoute.SeekerLogin))
    }
  }, [auth, token])

  useEffect(() => {
    if (auth.data?.cbtAccountStatus !== CbtAccountStatus.UNVERIFIED) {
      const grantedConsents = hasGrantedMandatoryConsents(auth.data)
      if (!grantedConsents) {
        history.push(getRoute(AppRoute.SeekerLoginConsents))
      } else if (
        auth.data &&
        auth.data?.rjbProfileStatus === RjbProfileStatus.DRAFT &&
        (auth.data.cbtAccountStatus === CbtAccountStatus.ACTIVE ||
          auth.data.cbtAccountStatus === CbtAccountStatus.NEW)
      ) {
        history.push(getRoute(AppRoute.Onboarding))
      }
    }
  }, [auth.data])

  const isEmployer = auth?.data?.type === UserType.EMPLOYER

  return auth.data ? (
    <LoginLayout>
      <div className={styles.formWrap}>
        <span className={styles.emailVerificationTitleWrap}>
          <Title
            text={ev.emailVerificationNoticeTitle}
            className={styles.emailVerificationTitle}
          />
        </span>
        <p className={styles.description}>
          <Description text={ev.emailVerificationNoticeDesc} />
        </p>
        <div className={styles.links}>
          <div className={styles.buttonWrap}>
            <YellowLink
              className={styles.resendButton}
              to={getRoute(
                isEmployer ? AppRoute.EmployerLogin : AppRoute.SeekerLogin
              )}
              text={ev.emailVerificationNoticeButton}
            />
          </div>
          <Link
            to={getRoute(AppRoute.EmailVerification)}
            className={classnames(styles.link)}
          >
            <FormattedMessage id={ev.emailVerificationNoticeEmail} />
          </Link>
        </div>
      </div>
    </LoginLayout>
  ) : null
}
