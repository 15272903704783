import * as Yup from 'yup'

export const ValidationNext = Yup.object({
  numberOfRecruits: Yup.object()
    .nullable()
    .required('Number of recruits field is required'),
  salary: Yup.object().nullable().required('Salary range is required'),
  jobCategories: Yup.array().of(Yup.object()),
  regions: Yup.array().of(Yup.object()).min(1, 'Regions are required'),
  educationOfCandidates: Yup.array().of(
    Yup.object({
      educationLevel: Yup.object({
        translation: Yup.string().test({
          name: 'translation',
          message: 'Fill education level field',
          test: (value, context) =>
            !!value ||
            !context.options['from'][1].value.hasOwnProperty('fieldOfStudy'),
        }),
        id: Yup.string().test({
          name: 'id',
          message: 'Fill education level field',
          test: (value, context) =>
            !!value ||
            !context.options['from'][1].value.hasOwnProperty('fieldOfStudy'),
        }),
      }),
      fieldOfStudy: Yup.object({
        translation: Yup.string().test({
          name: 'translation',
          message: 'Fill field of study field',
          test: (value, context) =>
            !!value ||
            !context.options['from'][1].value.hasOwnProperty('educationLevel'),
        }),
        id: Yup.string().test({
          name: 'id',
          message: 'Fill field of study field',
          test: (value, context) =>
            !!value ||
            !context.options['from'][1].value.hasOwnProperty('educationLevel'),
        }),
      }),
    })
  ),
  yearsOfExperiencePerPosition: Yup.array().of(
    Yup.object({
      occupation: Yup.object({
        translation: Yup.string().test({
          name: 'translation',
          message: 'Fill occupation field',
          test: (value, context) =>
            !!value ||
            !(
              context.options['from'][1].value.hasOwnProperty('salary') ||
              context.options['from'][1].value.hasOwnProperty(
                'totalWorkExperience'
              )
            ),
        }),
        id: Yup.string().test({
          name: 'id',
          message: 'Fill occupation field',
          test: (value, context) =>
            !!value ||
            !(
              context.options['from'][1].value.hasOwnProperty('salary') ||
              context.options['from'][1].value.hasOwnProperty(
                'totalWorkExperience'
              )
            ),
        }),
      }),
      salary: Yup.object({
        translation: Yup.string().test({
          name: 'translation',
          message: 'Fill salary field',
          test: (value, context) =>
            !!value ||
            !(
              context.options['from'][1].value.hasOwnProperty('occupation') ||
              context.options['from'][1].value.hasOwnProperty(
                'totalWorkExperience'
              )
            ),
        }),
        id: Yup.string().test({
          name: 'id',
          message: 'Fill salary field',
          test: (value, context) =>
            !!value ||
            !(
              context.options['from'][1].value.hasOwnProperty('occupation') ||
              context.options['from'][1].value.hasOwnProperty(
                'totalWorkExperience'
              )
            ),
        }),
      }),
      totalWorkExperience: Yup.object({
        translation: Yup.string().test({
          name: 'translation',
          message: 'Fill work experience field',
          test: (value, context) =>
            !!value ||
            !(
              context.options['from'][1].value.hasOwnProperty('occupation') ||
              context.options['from'][1].value.hasOwnProperty('salary')
            ),
        }),
        id: Yup.string().test({
          name: 'id',
          message: 'Fill work experience field',
          test: (value, context) =>
            !!value ||
            !(
              context.options['from'][1].value.hasOwnProperty('occupation') ||
              context.options['from'][1].value.hasOwnProperty('salary')
            ),
        }),
      }),
    })
  ),
})

export const ValidationBack = Yup.object({
  numberOfRecruits: Yup.object().nullable(),
  jobCategories: Yup.array().of(Yup.object()),
  regions: Yup.array().of(Yup.object()),
  educationOfCandidates: Yup.array().of(
    Yup.object({
      educationLevel: Yup.object({
        translation: Yup.string().test({
          name: 'translation',
          message: 'Fill education level field',
          test: (value, context) =>
            !!value ||
            !context.options['from'][1].value.hasOwnProperty('fieldOfStudy'),
        }),
        id: Yup.string().test({
          name: 'id',
          message: 'Fill education level field',
          test: (value, context) =>
            !!value ||
            !context.options['from'][1].value.hasOwnProperty('fieldOfStudy'),
        }),
      }),
      fieldOfStudy: Yup.object({
        translation: Yup.string().test({
          name: 'translation',
          message: 'Fill field of study field',
          test: (value, context) =>
            !!value ||
            !context.options['from'][1].value.hasOwnProperty('educationLevel'),
        }),
        id: Yup.string().test({
          name: 'id',
          message: 'Fill field of study field',
          test: (value, context) =>
            !!value ||
            !context.options['from'][1].value.hasOwnProperty('educationLevel'),
        }),
      }),
    })
  ),
  yearsOfExperiencePerPosition: Yup.array().of(
    Yup.object({
      occupation: Yup.object({
        translation: Yup.string().test({
          name: 'translation',
          message: 'Fill occupation field',
          test: (value, context) =>
            !!value ||
            !(
              context.options['from'][1].value.hasOwnProperty('salary') ||
              context.options['from'][1].value.hasOwnProperty(
                'totalWorkExperience'
              )
            ),
        }),
        id: Yup.string().test({
          name: 'id',
          message: 'Fill occupation field',
          test: (value, context) =>
            !!value ||
            !(
              context.options['from'][1].value.hasOwnProperty('salary') ||
              context.options['from'][1].value.hasOwnProperty(
                'totalWorkExperience'
              )
            ),
        }),
      }),
      salary: Yup.object({
        translation: Yup.string().test({
          name: 'translation',
          message: 'Fill salary field',
          test: (value, context) =>
            !!value ||
            !(
              context.options['from'][1].value.hasOwnProperty('occupation') ||
              context.options['from'][1].value.hasOwnProperty(
                'totalWorkExperience'
              )
            ),
        }),
        id: Yup.string().test({
          name: 'id',
          message: 'Fill salary field',
          test: (value, context) =>
            !!value ||
            !(
              context.options['from'][1].value.hasOwnProperty('occupation') ||
              context.options['from'][1].value.hasOwnProperty(
                'totalWorkExperience'
              )
            ),
        }),
      }),
      totalWorkExperience: Yup.object({
        translation: Yup.string().test({
          name: 'translation',
          message: 'Fill work experience field',
          test: (value, context) =>
            !!value ||
            !(
              context.options['from'][1].value.hasOwnProperty('occupation') ||
              context.options['from'][1].value.hasOwnProperty('salary')
            ),
        }),
        id: Yup.string().test({
          name: 'id',
          message: 'Fill work experience field',
          test: (value, context) =>
            !!value ||
            !(
              context.options['from'][1].value.hasOwnProperty('occupation') ||
              context.options['from'][1].value.hasOwnProperty('salary')
            ),
        }),
      }),
    })
  ),
})
