import {connect} from 'react-redux'
import {AppDispatch} from 'Store'
import {Questions} from './Questions'
import {RootState} from 'Store/Reducers'
import {sendQuestions} from 'Store/Actions'
import {QuestionValue} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  companyQuestions: state.company.data.companyQuestions || [],
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  sendQuestions: (value: QuestionValue[]) => dispatch(sendQuestions(value)),
})

export const QuestionsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Questions)
