import {FC, useState, useEffect, useCallback} from 'react'
import {useParams} from 'react-router-dom'
import {FormattedMessage} from 'react-intl'
import classNames from 'classnames'
import {CircularProgress} from '@mui/material'
import {useFormik} from 'formik'

import {
  MyProjectCandidatesSearchParams,
  MyProjectCandidatesStateType,
  MyProjectStateType,
  Candidate,
  IncomingRequestSeekerType,
  DataType,
  BaseTaxonomyType,
  SearchFormikValues,
  SearchMyProjectsParams,
} from 'Interfaces'
import MainLayout from 'Layouts/MainLayout'
import CandidateMyProjectContentContainer from 'Components/CandidateMyProject/CandidateMyProjectSidebarContent'
import styles from './MyProjectCandidates.module.scss'
import preferencaLogo from 'Assets/Images/preferenca-logo-white.svg'
import TextField from 'Components/Inputs/TextField'
import TypeAhead from 'Components/Inputs/TypeAhead'
import {OnChangeProps} from 'Utils/FormikProps'
import YellowButton from 'Components/YellowButton'
import {WidgetBarContainer} from 'Layouts/MainLayout/WidgetBar'
import Checkbox from 'Components/Inputs/Checkbox'
import PopoverList from 'Components/PopoverList'
import InviteToTestingModal from 'Components/RequestModals/InviteToTestingModal'
import {CandidateCardContainer} from 'Components/CandidateSearch/CandidateCard'
import MyProjects from 'Components/Widgets/MyProjects'
import InfiniteScroll from 'Components/InfiniteScroll'
import {
  MyProjectsTranslations as mpt,
  MyProjectCandidatesTranslations as mpct,
} from 'Services/I18n/Constants'
import {DropdownProps} from 'Utils/FormikProps'
import {useTranslation} from 'Hooks'
import {useHandleFocus} from 'Components/Employer/Hooks'
import {ArrowDropDown, SyncAlt, Search} from '@mui/icons-material'
import {NoLongerActiveCandidateModal} from 'Components/Modal/NoLongerActiveCandidateModal'

const SearchPopoverTitle = ({
  title,
  className,
}: {
  title: string
  className?: string
}) => (
  <div className={classNames(styles.popoverTitleWrap, className)}>
    <span>
      <FormattedMessage id={title} />
    </span>
    <ArrowDropDown
      style={{
        opacity: 0.3,
      }}
    />
  </div>
)

type MyProjectCandidatesProps = {
  project: MyProjectStateType
  candidates: MyProjectCandidatesStateType
  candidateBasicData: Candidate | IncomingRequestSeekerType | null
  getMyProject: (id: string) => void
  getMyProjectCandidates: (params: MyProjectCandidatesSearchParams) => void
  loadMore: (
    id: string,
    params: SearchMyProjectsParams | null | undefined
  ) => void
  getOccupations: () => void
  occupations: DataType<BaseTaxonomyType>
  getSavedCandidatesSearch: (
    searchParams: SearchMyProjectsParams,
    projectId: string | undefined
  ) => void
  sendContactRequestsBulk: (ids: string[]) => void
  setCandidateBasicData: (candidate: any) => void
}

export const MyProjectCandidates: FC<MyProjectCandidatesProps> = ({
  project,
  candidates,
  candidateBasicData,
  getMyProject,
  getMyProjectCandidates,
  loadMore,
  getOccupations,
  occupations,
  getSavedCandidatesSearch,
  sendContactRequestsBulk,
  setCandidateBasicData,
}) => {
  let {id} = useParams<{id: string}>()
  const [selectedResults, setSelectedResults] = useState<string[]>([])
  const [sortValue, setSortValue] = useState('')
  const [orderAscending, setOrderAscending] = useState(true)
  const [isTestingModalOpen, setIsTestingModalOpen] = useState(false)
  const translation = useTranslation()
  const [commentFocusTrigger, handleCommentFocus] = useHandleFocus()
  const [
    isNoLogerActiiveCandidateModalOpen,
    setIsNoLogerActiiveCandidateModalOpen,
  ] = useState(false)
  useEffect(() => {
    setCandidateBasicData(null)
  }, [])
  const initialSearchValues: SearchFormikValues = {
    firstName: '',
    lastName: '',
    occupations: [],
  }

  const onSendContactRequests = () => {
    sendContactRequestsBulk(selectedResults)
  }

  const updateSortValue = (value: string) => {
    setSortValue(value)
    formikSearch.submitForm()
  }

  const handleOrder = () => {
    setOrderAscending(!orderAscending)
    formikSearch.submitForm()
  }

  const sortActions = [
    {
      text: mpct.sortOptionJobTitle,
      onClick: () => updateSortValue('jobTitle'),
    },
    {
      text: mpct.sortOptionFirstName,
      onClick: () => updateSortValue('firstName'),
    },
    {
      text: mpct.sortOptionLastName,
      onClick: () => updateSortValue('lastName'),
    },
    {
      text: mpct.sortOptionDateAdded,
      onClick: () => updateSortValue('dateAdded'),
    },
    {
      text: mpct.sortOptionReviewed,
      onClick: () => updateSortValue('reviewed'),
    },
  ]

  const mobileActions = [
    {
      text: mpct.actionSendContactRequest,
      onClick: () => onSendContactRequests(),
    },
  ]

  const formikSearch = useFormik({
    initialValues: initialSearchValues,
    onSubmit: (values) => {
      getSavedCandidatesSearch(
        {
          firstName: values.firstName || undefined,
          lastName: values.lastName || undefined,
          publicOccupationId: values.occupations.length
            ? values.occupations[0].id
            : undefined,
          order: orderAscending ? 'ASC' : 'DESC',
          sort: sortValue ? sortValue : 'firstName',
        },
        project.data?.id
      )
    },
  })

  const toggleSelectAll = useCallback(() => {
    if (selectedResults.length === candidates.data.length) {
      setSelectedResults([])
    } else {
      setSelectedResults(candidates.data.map((candidate) => candidate.userId))
    }
  }, [selectedResults, setSelectedResults, candidates.data])

  const setSidebarContent = () => {
    if (candidateBasicData) {
      return (
        <CandidateMyProjectContentContainer
          commentFocusTrigger={commentFocusTrigger}
        />
      )
    }
    return <MyProjects />
  }

  const selectCandidate = (id: string) => {
    const selected = [...selectedResults]
    const idx = selected.indexOf(id)
    if (idx === -1) {
      selected.push(id)
    } else {
      selected.splice(idx, 1)
    }
    setSelectedResults(selected)
  }

  const setPopoverTitle = (value: string) => {
    switch (value) {
      case 'jobTitle':
        return translation[mpct.sortOptionJobTitle]
      case 'dateAdded':
        return translation[mpct.sortOptionDateAdded]
      case 'reviewed':
        return translation[mpct.sortOptionReviewed]
      case 'lastName':
        return translation[mpct.sortOptionLastName]
      default:
        return translation[mpct.sortOptionFirstName]
    }
  }

  useEffect(() => {
    getOccupations()
  }, [])

  useEffect(() => {
    if (id) {
      getMyProject(id)
      getMyProjectCandidates({id})
    }
  }, [id])

  if (project.loading) {
    return (
      <div className={styles.progressSpinner}>
        <CircularProgress />
      </div>
    )
  }

  return (
    <MainLayout
      fixedRight={false}
      theme="blue"
      withoutDesktopLogo
      logoType="white"
      rightSidebarContent={
        <WidgetBarContainer
          sidebarContent={setSidebarContent()}
          containerClass=".cardWrap"
        />
      }
    >
      <div className={styles.container}>
        {/* Search input section */}
        <div className={styles.headerWrap}>
          <div className={styles.logoWrap}>
            <img src={preferencaLogo} alt="Preferenca" />
          </div>
          <div className={styles.title}>{project.data?.title}</div>
          <div className={styles.searchHeaderWrap}>
            <div className={styles.searchFieldWrap}>
              <TextField
                name="firstName"
                placeholder={mpt.firstName}
                {...OnChangeProps(formikSearch, 'firstName')}
                className={styles.textField}
              />
              <div className={styles.searchIcon}>
                <Search />
              </div>
            </div>
            <div className={styles.searchFieldWrap}>
              <TextField
                name="lastName"
                placeholder={mpt.lastName}
                {...OnChangeProps(formikSearch, 'lastName')}
                className={styles.textField}
              />
              <div className={styles.searchIcon}>
                <Search />
              </div>
            </div>
            <div className={styles.searchFieldWrap}>
              <TypeAhead
                name="occupations"
                items={occupations.data}
                maxNumSelections={1}
                withoutArrow
                placeholder={mpt.jobTitle}
                className={styles.typeahead}
                {...DropdownProps(formikSearch, 'occupations')}
              />
              <div className={styles.searchIcon}>
                <Search />
              </div>
            </div>
            <YellowButton
              className={styles.submitBtn}
              text={mpct.searchSubmitButton}
              onClick={formikSearch.submitForm}
            />
          </div>
        </div>

        {/* Bulk actions section */}
        <div className={styles.actionsWrap}>
          <div className={styles.actions}>
            <div className={styles.actionsBulk}>
              <div className={styles.selectAllWrap}>
                <Checkbox
                  name="selectAllResults"
                  onChange={toggleSelectAll}
                  value={selectedResults.length === candidates.data.length}
                  className={styles.selectAll}
                />
                <label
                  className={styles.selectAllLabel}
                  htmlFor="selectAllResults"
                >
                  <FormattedMessage id={mpct.selectAllLabel} />
                </label>
              </div>

              {selectedResults.length > 0 && (
                <>
                  <div
                    role="button"
                    className={classNames(
                      styles.actionBtn,
                      styles.desktopActions
                    )}
                    onClick={onSendContactRequests}
                  >
                    <FormattedMessage id={mpct.actionSendContactRequest} />
                  </div>
                  {/* // Left this in case we need it after review */}
                  {/* <div
                    role="button"
                    className={classNames(
                      styles.actionBtn,
                      styles.desktopActions
                    )}
                    onClick={onSendContactRequests}
                  >
                    <FormattedMessage id={mpct.actionSendMessage} />
                  </div>
                  <PopoverList
                    className={styles.desktopTestingActions}
                    triggerIcon={
                      <SearchPopoverTitle title={mpct.testingTitle} />
                    }
                    items={[
                      {
                        text: mpct.testingOptionPsychometric,
                        onClick: () => onInviteToTesting(),
                      },
                      {
                        text: mpct.testingOptionCognitive,
                        onClick: () => onInviteToTesting(),
                      },
                    ]}
                  /> */}

                  <PopoverList
                    className={styles.mobileActions}
                    triggerIcon={
                      <SearchPopoverTitle title={mpct.actionsTitle} />
                    }
                    items={mobileActions}
                  />
                </>
              )}
            </div>

            <div className={styles.sortWrap}>
              <PopoverList
                className={styles.sortAction}
                triggerIcon={
                  <SearchPopoverTitle
                    title={`${translation[mpct.sortByTitle]}: ${setPopoverTitle(
                      sortValue
                    )}`}
                  />
                }
                items={sortActions}
                shouldCloseOnSelection={true}
              />
              <button className={styles.sortDirection} onClick={handleOrder}>
                <SyncAlt />
              </button>
            </div>
          </div>
        </div>

        {candidates.loading ? (
          <div className={styles.progressSpinner}>
            <CircularProgress />
          </div>
        ) : candidates.data && candidates.data.length > 0 ? (
          <div className={styles.candidateListWrap}>
            <InfiniteScroll
              loading={candidates.loadMoreProgress}
              hasMore={
                !!(
                  candidates.total && candidates.total > candidates.data.length
                )
              }
              loadMore={() => loadMore(id, candidates?.params)}
            >
              {(candidates.data as Candidate[]).map((candidate) => (
                <CandidateCardContainer
                  key={candidate.id}
                  candidate={candidate}
                  selected={selectedResults.includes(candidate.userId)}
                  handleCandidateCheck={() => selectCandidate(candidate.userId)}
                  page="myProject"
                  handleCommentFocus={handleCommentFocus}
                  setIsNoLogerActiiveCandidateModalOpen={
                    setIsNoLogerActiiveCandidateModalOpen
                  }
                />
              ))}
            </InfiniteScroll>
          </div>
        ) : (
          <div>No results</div>
        )}
      </div>
      <InviteToTestingModal
        modalOpen={isTestingModalOpen}
        setModalOpen={setIsTestingModalOpen}
        requestsIds={selectedResults}
      />
      <NoLongerActiveCandidateModal
        open={isNoLogerActiiveCandidateModalOpen}
        setOpen={setIsNoLogerActiiveCandidateModalOpen}
      />
    </MainLayout>
  )
}
