import {connect} from 'react-redux'

import {JobPreferences} from './JobPreferences'
import {RootState} from 'Store/Reducers'
import {AppDispatch} from 'Store'
import {
  getRegions,
  getWorkFromHome,
  getSalaries,
  getOccupations,
  getAvailabilities,
  saveStepJobPreferences,
  saveStepPartialJobPreferences,
  setOnboardingStep,
  getJobSearchStatuses,
} from 'Store/Actions'
import {SaveStepPreference, SavePartialStepPreference} from 'Interfaces'

const mapStateToProps = (state: RootState) => ({
  taxonomies: state.taxonomies,
  onboarding: state.onboarding,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getTaxonomies: (jobCategoryId?: string) => {
    dispatch(getRegions())
    dispatch(getWorkFromHome())
    dispatch(getSalaries())
    dispatch(
      getOccupations(jobCategoryId ? {jobCategoryId: jobCategoryId} : undefined)
    )
    dispatch(getAvailabilities())
    dispatch(getJobSearchStatuses())
  },
  saveStepJobPreferences: (values: SaveStepPreference) =>
    dispatch(saveStepJobPreferences(values)),
  saveStepPartialJobPreferences: (values: SavePartialStepPreference) =>
    dispatch(saveStepPartialJobPreferences(values)),
  setOnboardingStep: (n: number) => dispatch(setOnboardingStep(n)),
})

export const JobPreferencesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(JobPreferences)
