import {PublicPagesHeader} from 'Components/PublicPagesHeader'
import YellowText from 'Components/YellowText'
import {FC} from 'react'
import styles from './EmployerInfoLandingPage.module.scss'
import {HomePageFooter} from 'Components/HomePage'
import YellowButton from 'Components/YellowButton'
import {FormattedMessage} from 'react-intl'
import {EmployerInfoLandingPageTranslations as ei} from 'Services/I18n/Constants/Employer/EmployerInfoLandingPageTranslations'

type EmployerInfoLandingPageProps = {}

export const EmployerInfoLandingPage: FC<
  EmployerInfoLandingPageProps
> = ({}) => {
  return (
    <>
      <div className={styles.main}>
        <div className={styles.backgroundImg}></div>
        <div className={styles.backgroundColor}></div>
        <PublicPagesHeader page="employer" />
        <div className={styles.title}>
          <FormattedMessage id={ei.title} />
          <YellowText text={ei.yellowTitleTxt} />{' '}
          <FormattedMessage id={ei.titleLastPart} />
        </div>
        <div className={styles.content}>
          <div className={styles.row1}>
            <div className={styles.paragraphs1Wrap}>
              <p>
                <FormattedMessage id={ei.paragraphFirstPart} />
              </p>
              <p>
                <FormattedMessage id={ei.paragraphSecondPart} />
              </p>
              <p>
                <FormattedMessage id={ei.paragraphThirdPart} />
              </p>
              <p>
                <FormattedMessage id={ei.paragraphFourthPart} /> 😊
              </p>
              <p>
                <FormattedMessage id={ei.paragraphFifthPart} />
              </p>
            </div>
            <span />
            <div className={styles.paragraphs2Wrap}>
              <div className={styles.handwritingTitle}>
                <FormattedMessage id={ei.subtitle} />
              </div>
              <p>
                <FormattedMessage id={ei.subtitleParagraph} />
              </p>
              <p>
                <FormattedMessage id={ei.subtitleParagraphSecond} />
              </p>
            </div>
          </div>
          <div className={styles.title}>
            <FormattedMessage id={ei.titleSecond} />
            <YellowText text={ei.yellowTitleSecond} />
          </div>
          <div className={styles.row2}>
            <ol className={styles.list}>
              <div className={styles.subtitle}>
                <FormattedMessage id={ei.subTitleSecond} />
              </div>
              <li>
                <span className={styles.numbering}>1.</span>
                <span className={styles.text}>
                  <span className={styles.yellowText}>
                    <FormattedMessage id={ei.yellowTxtFirst} />
                    &nbsp;
                  </span>
                  <FormattedMessage id={ei.subParagraphFirst} />
                </span>
              </li>
              <li>
                <span className={styles.numbering}>2.</span>
                <span className={styles.text}>
                  <span className={styles.yellowText}>
                    <FormattedMessage id={ei.yellowTxtSecond} />
                    &nbsp;
                  </span>
                  <FormattedMessage id={ei.subParagraphSecond} />
                </span>
              </li>
              <li>
                <span className={styles.numbering}>3.</span>
                <span className={styles.text}>
                  <FormattedMessage id={ei.subParagraphThird} />
                  &nbsp;
                  <span className={styles.yellowText}>
                    <FormattedMessage id={ei.yellowTxtThird} />
                  </span>
                </span>
              </li>
              <li>
                <span className={styles.numbering}>4.</span>
                <span className={styles.text}>
                  <FormattedMessage id={ei.subParagraphFourthFirstPart} />
                  &nbsp;
                  <span className={styles.yellowText}>
                    <FormattedMessage id={ei.yellowTxtFourth} />
                  </span>{' '}
                  <FormattedMessage id={ei.subParagraphFourthSecondPart} />
                </span>
              </li>
              <li>
                <span className={styles.numbering}>5.</span>
                <span className={styles.text}>
                  <FormattedMessage id={ei.subParagraphFifthFirstPart} />
                  &nbsp;
                  <span className={styles.yellowText}>
                    <FormattedMessage id={ei.yellowTxtFifth} />
                    &nbsp;
                  </span>
                  <FormattedMessage id={ei.subParagraphFifthSecondPart} />
                </span>
              </li>
              <li className={styles.yellowText}>
                <span className={styles.numbering}>6.</span>
                <FormattedMessage id={ei.yellowTxtSixth} />
              </li>
              <li className={styles.yellowText}>
                <span className={styles.numbering}>7.</span>
                <FormattedMessage id={ei.yellowTxtSeventh} />
              </li>
            </ol>
            <div className={styles.paragraphs3Wrap}>
              <p>
                <FormattedMessage id={ei.paragraphsWrapFirst} />
              </p>
              <p>
                <FormattedMessage id={ei.paragraphsWrapSecond} />
              </p>
            </div>
          </div>
          {/* TODO: Add link to candidate page 2 when it gets created */}
          <YellowButton className={styles.cta} text={ei.yellowButton} />
        </div>
      </div>
      <HomePageFooter />
    </>
  )
}
