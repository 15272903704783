import {FC, useEffect, ReactNode} from 'react'
import {AuthStateType, SnackbarControlerType} from 'Interfaces'
import classnames from 'classnames'
import {Link} from 'react-router-dom'

import styles from './MainLayout.module.scss'
import Preferenca from 'Assets/Images/preferenca-logo.svg'
import PreferencaWhite from 'Assets/Images/preferenca-logo-white.svg'
import LeftSidebarContainer from 'Layouts/MainLayout/LeftSidebar'
import RightSidebar from 'Layouts/MainLayout/RightSidebar'
import {SnackbarNotification} from 'Components/Snackbar/SnackbarNotification'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'

// options: with RS, Rs=, withLogo,
interface iMainLayoutProps {
  auth?: AuthStateType
  children: ReactNode
  Sidebar?: ReactNode
  background?: boolean
  Footer?: ReactNode
  snackbars: SnackbarControlerType[]
  type?: string
  rightSidebarContent?: ReactNode
  content?: ReactNode
  SubNavigation?: ReactNode
  theme?: string
  sidebarClosed?: boolean
  fixedRight?: boolean
  withoutLogo?: boolean
  getUnreadConversationsCount: (participantsType?: string) => void
  cleanSnackbars: () => void
  withoutDesktopLogo?: boolean
  logoType?: string
  myProjectsLogo?: boolean
}

const MainLayout: FC<iMainLayoutProps> = ({
  auth,
  children,
  background = false,
  Sidebar,
  Footer,
  snackbars,
  type = 'contentWrap',
  rightSidebarContent,
  SubNavigation,
  theme = 'gray',
  content,
  sidebarClosed,
  fixedRight = true,
  withoutLogo = false,
  getUnreadConversationsCount,
  cleanSnackbars,
  withoutDesktopLogo = false,
  logoType = 'default',
  myProjectsLogo = false,
}) => {
  useEffect(() => {
    if (!auth?.data?.id) {
      console.log('whaat?')
    }
  })

  useEffect(() => {
    getUnreadConversationsCount()
  }, [])

  return (
    <>
      <LeftSidebarContainer />
      <div className={`${background && styles.background}`}>
        <div
          className={classnames(styles.contentWrap, {
            [styles.contentWrapBackground]: background,
            [styles[type]]: type,
            [styles[theme]]: theme,
            [styles.withoutLogo]: withoutLogo || withoutDesktopLogo,
            [styles.withoutDesktopLogo]: withoutDesktopLogo,
            [styles.rightSidebarClosed]: sidebarClosed && !fixedRight,
          })}
        >
          {!withoutLogo && (
            <div
              className={classnames(styles.contentLogoWrap, {
                [styles.contentLogoWrapFixedRight]: !fixedRight,
                [styles.withoutDesktopLogo]: withoutDesktopLogo,
                [styles.myProjectsLogo]: myProjectsLogo,
              })}
            >
              <Link
                to={
                  auth?.data?.type === 'company_user'
                    ? getRoute(AppRoute.HomePageEmployer)
                    : getRoute(AppRoute.HomePageCandidate)
                }
              >
                <img
                  className={styles.logo}
                  src={logoType === 'white' ? PreferencaWhite : Preferenca}
                  alt="Logo"
                />
              </Link>
            </div>
          )}

          <div
            className={classnames(styles.centralContent, {
              [styles.centralContentBackground]: background,
              [styles.contentLogoWrapFixedRight]: !fixedRight,
            })}
          >
            <div className={styles.rhsSidebarHolder}>
              {rightSidebarContent ? (
                rightSidebarContent
              ) : (
                <RightSidebar Sidebar={Sidebar} content={content} />
              )}
            </div>

            {/* Used to add conditionally subnavigation at the top of the page, under header*/}
            {SubNavigation && (
              <div className={styles.subNavigationWrapper}>{SubNavigation}</div>
            )}
            <div
              className={classnames(styles.mainContentFooterWrapper, {
                [styles.mainContentFooterWrapperFixedRight]: !fixedRight,
              })}
            >
              <div
                className={classnames(styles.content, {
                  [styles.contentLogoWrapFixedRight]: !fixedRight,
                })}
              >
                {children}
                <SnackbarNotification
                  snackbars={snackbars}
                  cleanSnackbars={cleanSnackbars}
                />
              </div>
              {Footer && <div className={styles.footerWrapper}>{Footer}</div>}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MainLayout
