import {FC} from 'react'
import classNames from 'classnames'
import styles from './ThumbsFooter.module.scss'
import {Button} from 'Components/Button'
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt'
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt'

interface ThumbsFooterProps {
  onApprove?: () => void
  onReject?: () => void
  onThirdBtn?: () => void
  onCancel?: () => void
  approveText?: string
  rejectText?: string
  thirdBtnText?: string
  cancelText?: string
  theme?: string
  disabled?: boolean
  noThumbIcon?: boolean
}

export const ThumbsFooter: FC<ThumbsFooterProps> = ({
  onApprove,
  onReject,
  onThirdBtn,
  onCancel,
  approveText = 'Approve',
  rejectText = 'Reject',
  thirdBtnText = 'Propose new time',
  cancelText = 'Cancel',
  theme = 'thumbs',
  disabled = false,
  noThumbIcon,
}) => {
  return (
    <div className={classNames(styles.footer)}>
      <div
        className={classNames(styles.footerButton, {
          [styles.marginThirdBtn]: theme === 'thirdBtn',
        })}
      >
        {(theme === 'thumbUp' || theme === 'thumbs') && (
          <Button
            className={styles.buttonThumb}
            text={approveText}
            theme={'thumbModal'}
            icon={
              noThumbIcon ? undefined : (
                <ThumbUpOffAltIcon className={styles.green} />
              )
            }
            onClick={onApprove}
          />
        )}

        {(theme === 'thumbDown' || theme === 'thumbs') && (
          <Button
            className={styles.buttonThumb}
            icon={
              noThumbIcon ? undefined : (
                <ThumbDownOffAltIcon className={styles.red} />
              )
            }
            text={rejectText}
            theme={'thumbModal'}
            onClick={onReject}
          />
        )}
        {theme === 'thirdBtn' && (
          <Button
            text={thirdBtnText}
            type="button"
            onClick={onThirdBtn}
            theme={'thumbModal'}
            className={styles.buttonThumb}
            disabled={disabled}
          />
        )}
      </div>
      <div className={styles.cancel}>
        <Button
          text={cancelText}
          type="button"
          theme="back"
          onClick={onCancel}
          className={styles.spaceBetweenBack}
        />
      </div>
    </div>
  )
}
