import {FC} from 'react'

import {ProfilePictureType} from 'Interfaces'

import styles from './MessageParticipantPicture.module.scss'

interface MessageParticipantPictureProps {
  profilePicture: ProfilePictureType
  firstName: string
  lastName: string
}

export const MessageParticipantPicture: FC<MessageParticipantPictureProps> = ({
  profilePicture,
  firstName,
  lastName,
}) => {
  return (
    <div className={styles.messageParticipantPictureWrap}>
      {profilePicture ? (
        <img
          className={styles.avatarImg}
          src={profilePicture.url}
          alt={`${firstName} ${lastName}`}
        />
      ) : (
        <div className={styles.initialsWrap}>
          {firstName && lastName ? firstName[0] + lastName[0] : ''}
        </div>
      )}
    </div>
  )
}
