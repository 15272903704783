import {FC, useEffect} from 'react'
import styles from './CompanyContactInfo.module.scss'
import {useFormik} from 'formik'
import Hr from 'Components/Hr'
import {
  BaseTaxonomyType,
  CompanyContactInfoFormikType,
  CompanyContactInfoSaveType,
  EmployerOnboardingStateType,
  TaxonomiesStateType,
} from 'Interfaces'
import {
  ContactDataTranslations as cd,
  EmployerOnboardingTranslations as eot,
  ProfileTranslations as pt,
} from 'Services/I18n/Constants'
import Title from 'Components/Title'
import Buttons from 'Components/Onboarding/Buttons'
import {ValidationNext} from './Validation'
import {useApiError} from 'Hooks'
import TextField from 'Components/Inputs/TextField'
import {OnChangeProps, TextProps} from 'Utils/FormikProps'
import TypeAheadImproved from 'Components/Inputs/TypeAheadImproved'
import PhoneNumberField from 'Components/Inputs/PhoneNumberField'
import {TypeAheadFetch} from 'Components/Inputs/TypeAheadFetch'

interface CompanyContactInfoProps {
  employerOnboarding: EmployerOnboardingStateType
  taxonomies: TaxonomiesStateType
  getTaxonomies: () => void
  saveStepCompanyContactInfo: (saveValues: CompanyContactInfoSaveType) => void
}

export const CompanyContactInfo: FC<CompanyContactInfoProps> = ({
  taxonomies,
  employerOnboarding,
  getTaxonomies,
  saveStepCompanyContactInfo,
}) => {
  const defaultValues: CompanyContactInfoFormikType = {
    companyLongName: employerOnboarding.data?.companyLongName || '',
    address: employerOnboarding.data?.address || '',
    postalCode: employerOnboarding.data?.postalCode || '',
    town: employerOnboarding.data?.town || null,
    country: employerOnboarding.data?.country || null,
    contactsPersonTelephoneNumber:
      employerOnboarding.data?.contactsPersonTelephoneNumber || '',
    contactsPersonPositionInCompany:
      employerOnboarding.data?.contactsPersonPositionInCompany || '',
  }
  const formikNext = useFormik({
    initialValues: defaultValues,
    validationSchema: ValidationNext,
    onSubmit: (formikValues) => {
      const data = {
        companyLongName: formikValues.companyLongName,
        address: formikValues.address,
        postalCode: `${formikValues.postalCode}`,
        countryId: formikValues.country?.id,
        contactsPersonTelephoneNumber:
          formikValues.contactsPersonTelephoneNumber,
        contactsPersonPositionInCompany:
          formikValues.contactsPersonPositionInCompany,
      }
      const town = formikValues.town?.id.includes('newValue~')
        ? {townName: formikValues.town.translation}
        : {townId: formikValues.town?.id}
      saveStepCompanyContactInfo({
        ...data,
        ...town,
      } as CompanyContactInfoSaveType)
    },
  })

  useEffect(() => {
    if (!employerOnboarding.loading) getTaxonomies()
  }, [employerOnboarding.data])

  const setTypeAheadField = (name: string, values: BaseTaxonomyType[]) => {
    formikNext.setFieldValue(name, values[0])
  }
  useApiError(formikNext.setFieldError, employerOnboarding.error)

  return (
    <div className={styles.container}>
      <form className={styles.form} onSubmit={formikNext.handleSubmit}>
        <div>
          <Title
            text={eot.companyLongName}
            onboarding
            className={styles.firstTitle}
          />
          <TextField
            name="companyLongName"
            placeholder={pt.placeholderCompanyName}
            {...OnChangeProps(formikNext, 'companyLongName')}
            theme={
              employerOnboarding.data
                ?.companyLongNamePulledFromBusinessAssistant
                ? 'disabledStrong'
                : 'grey'
            }
            disabled={
              !!employerOnboarding.data
                ?.companyLongNamePulledFromBusinessAssistant
            }
          />
          <Hr className={styles.hr} />
        </div>
        <div>
          <Title text={eot.address} onboarding />
          <TextField
            name="address"
            {...OnChangeProps(formikNext, 'address')}
            theme={
              employerOnboarding.data?.companyAddressPulledFromBusinessAssistant
                ? 'disabledStrong'
                : 'grey'
            }
            disabled={
              !!employerOnboarding.data
                ?.companyAddressPulledFromBusinessAssistant
            }
          />
          <Hr className={styles.hr} />
        </div>
        <div>
          <Title text={eot.postalCode} onboarding />
          <div className={styles.dropdown}>
            <TextField
              name="postalCode"
              {...OnChangeProps(formikNext, 'postalCode')}
              theme={
                employerOnboarding.data
                  ?.companyPostalCodePulledFromBusinessAssistant
                  ? 'disabledStrong'
                  : 'grey'
              }
              type="number"
              error={
                (formikNext.submitCount && formikNext.errors?.postalCode) ||
                undefined
              }
              disabled={
                !!employerOnboarding.data
                  ?.companyPostalCodePulledFromBusinessAssistant
              }
            />
          </div>
          <Hr className={styles.hr} />
        </div>
        <div>
          <Title text={eot.city} onboarding />
          <div>
            {employerOnboarding.data?.companyTownPulledFromBusinessAssistant ? (
              <TextField
                name="fakeTown"
                {...OnChangeProps(formikNext, 'fakeTown')}
                value={formikNext.values.town?.translation || ''}
                theme="disabledStrong"
                disabled
              />
            ) : (
              <TypeAheadFetch
                name="town"
                value={formikNext.values.town || null}
                options={[]}
                optionsQueryUrl={`/small-index/towns?${
                  formikNext.values.country
                    ? `countryId=${formikNext.values.country.id}&`
                    : ''
                }name=`}
                setValue={formikNext.setFieldValue}
                label={cd.city}
                placeholder={pt.placeholderTown}
                error={
                  formikNext.submitCount && formikNext.errors?.town
                    ? formikNext.errors.town
                    : undefined
                }
                smallIndex
                autoComplete
              />
            )}
          </div>
          <Hr className={styles.hr} />
        </div>
        <div>
          <Title text={eot.country} onboarding />
          {employerOnboarding.data
            ?.companyCountryPulledFromBusinessAssistant ? (
            <TextField
              name="fakeCountry"
              {...OnChangeProps(formikNext, 'fakeCountry')}
              value={formikNext.values.country?.translation || ''}
              theme="disabledStrong"
              disabled
            />
          ) : (
            <TypeAheadImproved
              name="country"
              theme="grey"
              items={taxonomies.countries.data}
              defaultValue={
                formikNext.values.country
                  ? [formikNext.values.country]
                  : undefined
              }
              setValue={setTypeAheadField}
              label={eot.selectValue}
              placeholder={eot.selectYourCountry}
              setTouched={formikNext.getFieldHelpers('country').setTouched}
              maxNumSelections={1}
              error={
                (formikNext.submitCount && formikNext.errors?.country) ||
                undefined
              }
            />
          )}
          <Hr className={styles.hr} />
        </div>
        <div>
          <Title text={eot.contactPersonTelephoneNumber} onboarding />
          <PhoneNumberField
            label={eot.phoneNumber}
            name="contactsPersonTelephoneNumber"
            {...TextProps(formikNext, 'contactsPersonTelephoneNumber')}
            placeholder="+386"
            setValue={formikNext.setFieldValue}
            error={
              (formikNext.submitCount &&
                formikNext.errors?.contactsPersonTelephoneNumber) ||
              undefined
            }
          />
          <Hr className={styles.hr} />
        </div>
        <div>
          <Title text={eot.contactPersonPosition} onboarding />
          <TextField
            name="contactsPersonPositionInCompany"
            {...OnChangeProps(formikNext, 'contactsPersonPositionInCompany')}
            theme="grey"
          />
          <Hr className={styles.hr} />
        </div>
        <Buttons />
      </form>
    </div>
  )
}
