import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {CheckboxProps, TextProps} from 'Utils/FormikProps'

import styles from './Registration.module.scss'
import TextField from 'Components/Inputs/TextField'
import CheckBox from 'Components/Inputs/Checkbox'
import Validation from './Validation'
import {RegistrationType, SeekerStateType} from 'Interfaces'
import YellowButton from 'Components/YellowButton'
import LoginLayout from 'Layouts/LoginLayout'
import {Title} from 'Components/Login/Title'
import {Description} from 'Components/Login/Description'
import {StepCircles} from 'Pages/Seeker/Onboarding/StepCircles'
import {
  AppRoute,
  GeneralTranslations as gt,
  OnboardingTranslations as ot,
  RegistrationTranslations as rt,
} from 'Services/I18n/Constants'
import {getRoute} from 'Services/I18n/Utils'
import {useApiError} from 'Hooks'
import {FormattedMessage} from 'react-intl'
import PasswordInput from 'Components/Inputs/PasswordInput'
import classNames from 'classnames'
import {LoadingModal} from 'Components/LoadingModal'

export interface RegistrationProps {
  seeker: SeekerStateType
  registerSeeker: (data: RegistrationType) => void
}

export const Registration = ({
  seeker,
  registerSeeker,
}: RegistrationProps): JSX.Element => {
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      verifyPassword: '',
      consent1B: false,
      consent2: false,
      consent3B: false,
    },
    validationSchema: Validation,
    onSubmit: (values) => {
      registerSeeker(values)
    },
  })

  useApiError(formik.setFieldError, seeker.error)

  return (
    <LoginLayout fullWidth>
      <div className={styles.container}>
        <LoadingModal isOpen={seeker.loading} text={rt.loaderText} />
        <div className={styles.formContainer}>
          <form className={styles.form} onSubmit={formik.handleSubmit}>
            <Title text={rt.title} />
            <div className={styles.description}>
              <Description text={rt.description} />
            </div>
            <StepCircles step={1} total={5} theme="mobileWhite" />
            <div className={styles.row}>
              <TextField
                label={rt.firstName}
                name="firstName"
                theme="mobileGrey"
                {...TextProps(formik, 'firstName')}
              />
              <TextField
                label={rt.lastName}
                name="lastName"
                theme="mobileGrey"
                {...TextProps(formik, 'lastName')}
              />
            </div>
            <TextField
              label={gt.emailAddress}
              name="email"
              type="email"
              theme="mobileGrey"
              {...TextProps(formik, 'email')}
            />
            <div className={classNames(styles.row, styles.passwordRow)}>
              <PasswordInput
                className={styles.passInput}
                label={gt.password}
                name="password"
                {...TextProps(formik, 'password')}
              />
              <PasswordInput
                className={styles.passInput}
                label={rt.repeatPassword}
                name="verifyPassword"
                {...TextProps(formik, 'verifyPassword')}
              />
            </div>
            <CheckBox
              name="consent1B"
              {...CheckboxProps(formik, 'consent1B')}
              onChange={formik.handleChange}
            >
              <FormattedMessage id={rt.agree} />
              <Link
                to={getRoute(AppRoute.PrivacyPolicy)}
                target={'_blank'}
                rel={'noopener noreferrer'}
                className={styles.link}
              >
                <FormattedMessage id={rt.privacyPolicy} />
              </Link>
            </CheckBox>
            <CheckBox name="consent2" {...CheckboxProps(formik, 'consent2')}>
              <FormattedMessage id={rt.agree} />
              <Link
                to={getRoute(AppRoute.GeneralTermsAndConditions)}
                target={'_blank'}
                rel={'noopener noreferrer'}
                className={styles.link}
              >
                <FormattedMessage id={rt.generalTermsOfService} />
              </Link>
              <FormattedMessage id={rt.and} />
              <Link
                to={getRoute(AppRoute.TermsAndConditions)}
                target={'_blank'}
                rel={'noopener noreferrer'}
                className={styles.link}
              >
                <FormattedMessage id={rt.termsOfService} />
              </Link>
            </CheckBox>
            <CheckBox name="consent3B" {...CheckboxProps(formik, 'consent3B')}>
              <FormattedMessage id={ot.thirdPartyAgreement} />
            </CheckBox>
            <div className={styles.buttonContainer}>
              <YellowButton text={rt.nextButton} />
            </div>
          </form>
        </div>
        <div className={styles.sidebar}>
          <Title text={rt.sideBarTitle} className={styles.sidebarTitle} />
          <ul>
            <li className={styles.listItem}>
              <span>
                <FormattedMessage id={rt.sideBarJobOffers} />
              </span>
            </li>
            <li className={styles.listItem}>
              <span>
                <FormattedMessage id={rt.sideBarCreateCv} />
              </span>
            </li>
            <li className={styles.listItem}>
              <span>
                <FormattedMessage id={rt.sideBarHrSpecialist} />
              </span>
            </li>
            <li className={styles.listItem}>
              <span>
                <FormattedMessage id={rt.sideBarContactCompany} />
              </span>
            </li>
            <li className={styles.listItem}>
              <span>
                <FormattedMessage id={rt.sideBarFindMatch} />
              </span>
            </li>
          </ul>
        </div>
      </div>
    </LoginLayout>
  )
}
