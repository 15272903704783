import {TestimonialsUserType, TestimonialType} from 'Interfaces'
import testimonial1 from 'Assets/Images/homepage/temp-testimonials/testimonial-woman-1000px.jpg'
import testimonial2 from 'Assets/Images/homepage/temp-testimonials/testimonial-man-1000px.jpg'

export const employerTestimonials: TestimonialType[] = [
  {
    id: '123321',
    text: 'Po letu in pol iskanja primernega vodje za naš oddelek tehnološkega razvoja, nam je to s Preferenco uspelo v mescu dni. Ne bi mogli biti bolj zadovoljni!',
    name: 'Jure, It',
    type: TestimonialsUserType.COMPANY_USER,
    image: {
      id: '123321',
      originalName: 'jureavatar',
      url: testimonial1,
    },
  },
  {
    id: '126621',
    text: 'Po letu in pol iskanja primernega vodje za naš oddelek tehnološkega razvoja, nam je to s Preferenco uspelo v mescu dni. Ne bi mogli biti bolj zadovoljni!',
    name: 'Valerija, HR specialistka',
    type: TestimonialsUserType.SEEKER,
    image: {
      id: '123321',
      originalName: 'jureavatar',
      url: testimonial1,
    },
  },
  {
    id: '123321',
    text: 'Po letu in pol iskanja primernega vodje za naš oddelek tehnološkega razvoja, nam je to s Preferenco uspelo v mescu dni. Ne bi mogli biti bolj zadovoljni!',
    name: 'Janez, IT',
    type: TestimonialsUserType.COMPANY_USER,
    image: {
      id: '123321',
      originalName: 'jureavatar',
      url: testimonial2,
    },
  },
  {
    id: '123321',
    text: 'Po letu in pol iskanja primernega vodje za naš oddelek tehnološkega razvoja, nam je to s Preferenco uspelo v mescu dni. Ne bi mogli biti bolj zadovoljni!',
    name: 'Janez, IT',
    type: TestimonialsUserType.SEEKER,
    image: {
      id: '123321',
      originalName: 'jureavatar',
      url: testimonial2,
    },
  },
  {
    id: '123321',
    text: 'Po letu in pol iskanja primernega vodje za naš oddelek tehnološkega razvoja, nam je to s Preferenco uspelo v mescu dni. Ne bi mogli biti bolj zadovoljni!',
    name: 'Mika, IT',
    type: TestimonialsUserType.SEEKER,
    image: {
      id: '123321',
      originalName: 'jureavatar',
      url: testimonial1,
    },
  },
  {
    id: '123321',
    text: 'Po letu in pol iskanja primernega vodje za naš oddelek tehnološkega razvoja, nam je to s Preferenco uspelo v mescu dni. Ne bi mogli biti bolj zadovoljni!',
    name: 'Janez, IT',
    type: TestimonialsUserType.COMPANY_USER,
    image: {
      id: '123321',
      originalName: 'jureavatar',
      url: testimonial1,
    },
  },
  {
    id: '1276321',
    text: 'Po letu in pol iskanja primernega vodje za naš oddelek tehnološkega razvoja, nam je to s Preferenco uspelo v mescu dni. Ne bi mogli biti bolj zadovoljni!',
    name: 'Janez, IT',
    type: TestimonialsUserType.SEEKER,
    image: {
      id: '127321',
      originalName: 'jureavatar',
      url: testimonial2,
    },
  },
  {
    id: '1276321',
    text: 'Po letu in pol iskanja primernega vodje za naš oddelek tehnološkega razvoja, nam je to s Preferenco uspelo v mescu dni. Ne bi mogli biti bolj zadovoljni!',
    name: 'Janez, IT',
    type: TestimonialsUserType.COMPANY_USER,
    image: {
      id: '127321',
      originalName: 'jureavatar',
      url: testimonial2,
    },
  },
]
