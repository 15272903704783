import {WorkExperienceItem} from 'Interfaces'

export const Sort = (
  ed1: WorkExperienceItem,
  ed2: WorkExperienceItem
): -1 | 1 => {
  if (ed1.currentEmployer) {
    if (ed2.currentEmployer) {
      if (ed1.startYear === ed2.startYear)
        return ed1.startMonth < ed2.startMonth ? 1 : -1
      return ed1.startYear < ed2.startYear ? 1 : -1
    } else return -1
  } else if (ed2.currentEmployer) {
    return 1
  } else {
    if (ed1.endYear === ed2.endYear) return ed1.endMonth < ed2.endMonth ? 1 : -1
    return ed1.endYear < ed2.endYear ? 1 : -1
  }
}
