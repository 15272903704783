import {FC, useMemo, useState} from 'react'
import styles from './CandidateDashboardHeader.module.scss'
import {CandidateProfileCompletenessDataType} from 'Interfaces/CandidateType'
import {
  PhoneIphone,
  WorkOutlined,
  FmdGoodOutlined,
  MailOutline,
} from '@mui/icons-material'
import {ProgressBar} from './ProgressPercent/ProgressBar'
import {Hr} from 'Components/Hr/Hr'
import {AvatarContainer} from 'Components/Avatar'
import {CandidateDashboardTranslations as cdt} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import {useWindowResize} from 'Hooks'
import {TooltipElement} from './TooltipElement/TooltipElement'
import {SeekerProfileType} from 'Interfaces'

export interface PublicPagesHeaderProps {
  fullProfile: SeekerProfileType
  profileCompleteness: CandidateProfileCompletenessDataType
}

export const CandidateDashboardHeader: FC<PublicPagesHeaderProps> = ({
  fullProfile,
  profileCompleteness,
}: PublicPagesHeaderProps) => {
  const {
    firstName,
    lastName,
    jobCategory,
    email,
    phone,
    profilePicture,
    publicOccupation,
    homeTown,
    country,
  } = fullProfile

  const TooltipBody = useMemo(() => {
    const BodyElement: FC = () => {
      return (
        <div>
          <div className={styles.completenessTitle}>
            <FormattedMessage id={cdt.completeness} />
          </div>
          <div className={styles.completenessWrapper}>
            <div>
              <TooltipElement
                conditionCheck={profileCompleteness.fields?.profilePicture}
                elementText={cdt.completenessProfilePicture}
              />
              <TooltipElement
                conditionCheck={profileCompleteness.fields?.firstName}
                elementText={cdt.completenessFirstName}
              />
              <TooltipElement
                conditionCheck={profileCompleteness.fields?.lastName}
                elementText={cdt.completenessLastName}
              />
              <TooltipElement
                conditionCheck={profileCompleteness.fields?.dateOfBirth}
                elementText={cdt.completenessDateOfBirth}
              />
              <TooltipElement
                conditionCheck={profileCompleteness.fields?.aboutMe}
                elementText={cdt.completenessAboutMe}
              />
              <TooltipElement
                conditionCheck={profileCompleteness.fields?.streetAddress}
                elementText={cdt.completenessStreetAddress}
              />
              <TooltipElement
                conditionCheck={profileCompleteness.fields?.postalCode}
                elementText={cdt.completenessPostalCode}
              />
              <TooltipElement
                conditionCheck={profileCompleteness.fields?.city}
                elementText={cdt.completenessCity}
              />
            </div>
            <div>
              <TooltipElement
                conditionCheck={profileCompleteness.fields?.country}
                elementText={cdt.completenessCountry}
              />
              <TooltipElement
                conditionCheck={profileCompleteness.fields?.jobPreferences}
                elementText={cdt.completenessJobPrefs}
              />
              <TooltipElement
                conditionCheck={profileCompleteness.fields?.workExperiences}
                elementText={cdt.completenessWorkExperiences}
              />
              <TooltipElement
                conditionCheck={profileCompleteness.fields?.skills}
                elementText={cdt.completenessSkills}
              />
              <TooltipElement
                conditionCheck={profileCompleteness.fields?.education}
                elementText={cdt.completenessEducation}
              />
              <TooltipElement
                conditionCheck={profileCompleteness.fields?.psychometricTest}
                elementText={cdt.completenessPsychometricTest}
              />
              <TooltipElement
                conditionCheck={profileCompleteness.fields?.cognitiveTest}
                elementText={cdt.completenessCognitiveTest}
              />
              <TooltipElement
                conditionCheck={profileCompleteness.fields?.drivingLicense}
                elementText={cdt.completenessDrivingLicence}
              />
            </div>
          </div>
        </div>
      )
    }
    return <BodyElement />
  }, [profileCompleteness.fields])

  const [tooltipPlacement, setTooltipPlacement] = useState<'bottom' | 'right'>(
    'bottom'
  )

  const changeTooltipPlacement = (clientWidth?: number) => {
    setTooltipPlacement(clientWidth && clientWidth < 991 ? 'bottom' : 'right')
  }

  useWindowResize(changeTooltipPlacement)

  return (
    <>
      <div className={styles.mainDiv}>
        <div className={styles.top}>
          <div className={styles.topContainer}>
            <h1 className={styles.topText}>
              <span className={styles.spanText}>
                <FormattedMessage id={cdt.headerTitle} /> {firstName}
              </span>
              <span>
                <FormattedMessage id={cdt.headerTitleTxt} />
              </span>
            </h1>
          </div>
        </div>
        <div className={styles.mainContent}>
          <div className={styles.leftContent}>
            <div className={styles.avatarWrap}>
              {profilePicture ? (
                <AvatarContainer />
              ) : (
                <span>
                  {firstName.charAt(0).toUpperCase() +
                    lastName.charAt(0).toUpperCase()}
                </span>
              )}
            </div>
            <div className={styles.leftSection}>
              <h1 className={styles.title}>{`${firstName} ${lastName}`}</h1>
              {publicOccupation && (
                <div className={styles.paragraph}>
                  <p>{publicOccupation.translation}</p>
                </div>
              )}
              <div className={styles.competnessContainer}>
                <div className={styles.completenessText}>
                  <FormattedMessage id={cdt.headerCv} />
                </div>

                <div className={styles.progressBar}>
                  <ProgressBar
                    tooltipBody={TooltipBody}
                    tooltipPlacement={tooltipPlacement}
                    progress={
                      profileCompleteness.value ? +profileCompleteness.value : 0
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <Hr className={styles.break} />

          <div className={styles.rightContent}>
            <div className={styles.rightParagraph}>
              {jobCategory && jobCategory?.translation && (
                <div className={styles.industry}>
                  <WorkOutlined color="disabled" />
                  <p className={styles.information}>
                    {jobCategory?.translation}
                  </p>
                </div>
              )}
              {homeTown?.translation && (
                <div className={styles.industry}>
                  <FmdGoodOutlined color="disabled" />
                  <p className={styles.information}>
                    {`${homeTown?.translation}${
                      homeTown?.translation && country?.translation ? ', ' : ''
                    }${country?.translation ? country?.translation : ''}`}
                  </p>
                </div>
              )}
              <div className={styles.industry}>
                <MailOutline color="disabled" />
                <p className={styles.information}>{email}</p>
              </div>
              {phone && (
                <div className={styles.industry}>
                  <PhoneIphone color="disabled" />
                  <p className={styles.information}>{phone}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
