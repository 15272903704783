import {FC, useEffect, useState} from 'react'
import styles from './Portfolio.module.scss'
import {CompanyStateType} from 'Interfaces'
import SectionHeader from 'Components/Profile/SectionHeader'
import SectionItem from 'Components/Profile/SectionItem'
import ItemHeader from 'Components/Profile/ItemHeader'
import Modal, {SubmitFooter} from 'Components/Modal'
import {useFormik} from 'formik'
import {Validation} from './Validation'
import ShowMoreContent from 'Components/ShowMoreContent'
import {useApiError} from 'Hooks'
import {CompanyPortfolioTranslations as cpt} from 'Services/I18n/Constants'
import HTMLInput from 'Components/Inputs/HTMLInput'
import {LocalOfferOutlined} from '@mui/icons-material'
import {HTMLText} from 'Components/HTMLText'

interface PortfolioProps {
  company: CompanyStateType
  savePortfolio: (portfolio: string) => void
  canChange: boolean
}

export const Portfolio: FC<PortfolioProps> = ({
  company,
  savePortfolio,
  canChange,
}) => {
  const [modalOpen, setModalOpen] = useState(false)

  const openEdit = () => {
    setModalOpen(true)
  }

  useEffect(() => {
    if (!company.loading && !company.error) {
      setModalOpen(false)
    }
  }, [company.loading])

  const formik = useFormik({
    initialValues: {
      portfolio: company.data.portfolio || '',
    },
    validationSchema: Validation,
    onSubmit: (values) => {
      savePortfolio(values.portfolio)
    },
  })

  useApiError(formik.setFieldError, company.error)

  useEffect(() => {
    formik.setFieldValue('portfolio', company.data.portfolio)
  }, [company.data.portfolio])

  return (
    <div className={styles.container}>
      {(company.data.portfolio || canChange) && (
        <SectionHeader
          title={cpt.portfolioTitle}
          onAdd={
            canChange && !company.data.portfolio
              ? () => setModalOpen(true)
              : undefined
          }
          className={styles.header}
        />
      )}
      {company.data.portfolio && (
        <SectionItem className={styles.sectionItem}>
          <ItemHeader
            title={cpt.productAndServices}
            icon={LocalOfferOutlined}
            onEdit={
              canChange
                ? () => {
                    openEdit()
                  }
                : undefined
            }
            className={styles.itemHeader}
          />
          <div className={styles.content}>
            <ShowMoreContent>
              <div className={styles.text}>
                <HTMLText htmlInputValue={company.data.portfolio} />
              </div>
            </ShowMoreContent>
          </div>
        </SectionItem>
      )}
      <Modal
        isOpen={modalOpen}
        onAfterClose={() => {
          setModalOpen(false)
          formik.setFieldValue('portfolio', company.data.portfolio)
        }}
        title={cpt.modalTitle}
        icon={LocalOfferOutlined}
        ariaHideApp={false}
        className={styles.portfolioModalContent}
        Footer={
          <SubmitFooter
            onSubmit={formik.handleSubmit}
            onCancel={() => {
              setModalOpen(false)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <HTMLInput
          name="portfolio"
          onChange={formik.setFieldValue}
          value={formik.values.portfolio || ''}
          error={
            formik.submitCount && formik?.errors?.portfolio
              ? formik.errors.portfolio
              : undefined
          }
          setError={formik.setFieldError}
          charLimit={3000}
        />
      </Modal>
    </div>
  )
}
