import {StatusType} from 'Interfaces/BaseType'
import {IconCheck} from 'Components/Icons/CheckIcon'
import {IconClose} from 'Components/Icons/CloseIcon'
import {ExpiredIcon} from 'Components/Icons/ExpiredIcon'
import {ConsentsTranslations as ct} from 'Services/I18n/Constants/Seeker/ConsentsTranslations'

import styles from './Status.module.scss'
import {FormattedMessage} from 'react-intl'

export const Status = ({status}: StatusType) => {
  const icon = () => {
    if (status === 'granted') {
      return (
        <div className={styles.checkIconWrapper}>
          <IconCheck className={styles.checkIcon} />
        </div>
      )
    } else if (status === 'expired') {
      return <ExpiredIcon className={styles.expiredIcon} />
    }
    return <IconClose className={styles.closeIcon} />
  }
  return (
    <div className={styles.statusContainer}>
      <div className={styles.iconContainer}>{icon()}</div>
      <p className={styles.text}>
        {status === 'granted' ? (
          <FormattedMessage id={ct.consentGranted} />
        ) : (
          <FormattedMessage id={ct.consentRevoked} />
        )}
      </p>
    </div>
  )
}
