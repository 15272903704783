import {connect} from 'react-redux'

import {Skills} from './Skills'

import {RootState} from 'Store/Reducers'
import {SkillType, AddSkillType} from 'Interfaces'
import {
  addPublicSkill,
  deletePublicSkill,
  editPublicSkill,
  getSkillLevels,
  getSkills,
} from 'Store/Actions'
import {AppDispatch} from 'Store'
import {canChangeProfile} from 'Utils/Profile'

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  addSkill: (value: AddSkillType) => dispatch(addPublicSkill(value)),
  editSkill: (value: SkillType) => dispatch(editPublicSkill(value)),
  deleteSkill: (value: SkillType) => dispatch(deletePublicSkill(value)),
  getTaxonomies: () => {
    dispatch(getSkillLevels())
    dispatch(getSkills())
  },
})

const mapStateToProps = (state: RootState) => ({
  profile: state.seeker,
  skills: state.seeker.data?.public?.skills,
  taxonomies: state.taxonomies,
  canChange: canChangeProfile(state.auth.data?.rjbProfileStatus),
})

export const PublicSkillsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Skills)
