import {connect} from 'react-redux'
import {
  getAssessments,
  getLanguageTests,
  getSkillTests,
  getSeekerAssessments,
  getCandidateProfile,
} from 'Store/Actions'
import {Assessments} from './Assessments'

const mapStateToProps = (state: any) => ({
  assessments: state.assessments,
  user: state.auth.data,
  languageTests: state.assessments.languageTests,
  skillTests: state?.assessments?.skillTests,
  auth: state.auth,
  candidate: state.employer.data.candidate,
})

const mapDispatchToProps = (dispatch: any) => ({
  getAssessments: () => dispatch(getAssessments()),
  getLanguageTests: (seekerUserId: string) =>
    dispatch(getLanguageTests(seekerUserId)),
  getSkillTests: (seekerUserId: string) =>
    dispatch(getSkillTests(seekerUserId)),
  getSeekerAssessments: (seekerId: string) =>
    dispatch(getSeekerAssessments(seekerId)),
  getCandidateProfile: (id: string) => dispatch(getCandidateProfile(id)),
})

export const AssessmentsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Assessments)
