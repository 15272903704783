import * as Yup from 'yup'
import {PhoneNumber, PostalCode} from 'Validations'
const includesNumber = /^.*\d.*$/

const isOlderThan16 = (date: string) => {
  const dateParts = date.split('/')
  const formattedDate = `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
  const dob = new Date(formattedDate)
  const dobPlus16 = new Date(
    dob.getFullYear() + 16,
    dob.getMonth(),
    dob.getDate()
  )

  return dobPlus16.valueOf() <= Date.now()
}
const ValidationSeeker = Yup.object().shape({
  profilePicture: Yup.object()
    .nullable()
    .required('Profile picture is required'),
  firstName: Yup.string()
    .max(50, 'First name is too long')
    .required('First name is required'),
  lastName: Yup.string()
    .max(50, 'Last name is too long')
    .required('Last name is required'),
  day: Yup.number()
    .required('Birthdate is required')
    .min(1, 'Birthdate is required')
    .test({
      name: 'day',
      message: 'You must be 16 to register.',
      test: (_, context): boolean => {
        const dateOfBirth =
          context?.parent?.year &&
          context?.parent?.month &&
          context?.parent?.day &&
          `${parseInt(context?.parent.day)}/${parseInt(
            context?.parent.month
          )}/${context?.parent.year}`
        const isOlderThanSixteen = dateOfBirth && isOlderThan16(dateOfBirth)
        if (isOlderThanSixteen === false) {
          return false
        } else return true
      },
    }),
  month: Yup.number()
    .required('Birthdate is required')
    .min(1, 'Birthdate is required'),
  year: Yup.number()
    .required('Birthdate is required')
    .min(1, 'Birthdate is required'),
  streetAddress: Yup.string()
    .typeError('Include street number')
    .matches(includesNumber, 'Include street number')
    .max(50, 'Must be 50 characters or less')
    .required('Street and number are required'),
  postalCode: PostalCode,
  homeTown: Yup.object().nullable().required('City is required'),
  homeRegion: Yup.object().nullable().required('Region is required'),
  homeCountry: Yup.object().nullable().required('Country is required'),
  phone: PhoneNumber.required('Phone number is required'),
})

export default ValidationSeeker
