import {FC, useState, useMemo, useEffect} from 'react'
import styles from './JobPreferences.module.scss'
import {useFormik} from 'formik'
import Hr from 'Components/Hr'
import {
  JobPreferencesStateType,
  OnboardingStateType,
  SavePartialStepPreference,
  SaveStepPreference,
  TaxonomiesStateType,
  TaxonomyWithCategoryType,
} from 'Interfaces'
import Title from 'Components/Title'
import Buttons from 'Components/Onboarding/Buttons'
import {ValidationNext} from './Validation'
import {SetValueProps, SetValuesProps} from 'Utils/FormikProps'
import {useApiError} from 'Hooks'
import CheckboxGroup from 'Components/Inputs/CheckboxGroup'
import RadioGroup from 'Components/Inputs/RadioGroup'

import {OnboardingTranslations as onb} from 'Services/I18n/Constants'
import {LoadingModal} from 'Components/LoadingModal'
import {CheckBoxIcon} from 'Components/Icons'
import TextField from 'Components/Inputs/TextField'
import {FormattedMessage} from 'react-intl'
import classNames from 'classnames'
import TextError from 'Components/Error/TextError'

type OccupationsPayloadType = ({id: string} | {name: string})[]

const delimiterValidationText =
  'Job position entered under “Other“ can not contain delimiter symbols ( , ; | )'
const maxCharValidationText =
  'Other job category value must be 50 characters or less'

interface JobPreferencesProps {
  onboarding: OnboardingStateType
  taxonomies: TaxonomiesStateType
  getTaxonomies: (jobCategoryId?: string) => void
  saveStepJobPreferences: (values: SaveStepPreference) => void
  saveStepPartialJobPreferences: (values: SavePartialStepPreference) => void
  setOnboardingStep: (step: number) => void
}

export const JobPreferences: FC<JobPreferencesProps> = ({
  taxonomies,
  onboarding,
  getTaxonomies,
  saveStepJobPreferences,
}) => {
  const otherTaxonomy = useMemo(
    () =>
      taxonomies.occupations.data.filter(
        (o: TaxonomyWithCategoryType) => !o.jobCategory
      ),
    [taxonomies.occupations.data]
  )
  const [otherOccupation, setOtherOccupation] = useState({
    name: otherTaxonomy[0] ? (otherTaxonomy[0].translation as string) : '',
    selected: otherTaxonomy && !!otherTaxonomy[0],
  })
  const defaultValues: JobPreferencesStateType = {
    regions: onboarding.data?.regions.map((region) => region.id) || [],
    workFromHomeId: onboarding.data?.workFromHome?.id || '',
    salaryId: onboarding.data?.desiredSalary?.id || '',
    occupations: onboarding.data?.occupations || [],
    jobSearchStatusId: onboarding.data?.careerStatus?.id || '',
    availabilityId: onboarding.data?.availability?.id || '',
  }
  const formikNext = useFormik({
    initialValues: defaultValues,
    validationSchema: ValidationNext,
    onSubmit: (values) => {
      const {regions, occupations, apiError, ...other} = values
      const occupationsValues: OccupationsPayloadType = occupations
        .filter((o: TaxonomyWithCategoryType) => o.jobCategory)
        .map((o) => ({id: o.id}))
      if (otherOccupation.selected && otherOccupation.name !== '') {
        occupationsValues.push({name: otherOccupation.name})
      }
      saveStepJobPreferences({
        values: {
          ...other,
          regions: regions.map((region) => ({id: region})),
          occupations: occupationsValues,
        },
        step: 3,
      })
    },
  })

  useEffect(() => {
    if (!onboarding.loading) getTaxonomies(onboarding.data?.jobCategory?.id)
  }, [onboarding.loading])

  // Other occupation field validation
  useEffect(() => {
    const isTouched = otherOccupation.selected && formikNext.touched.occupations
    if (
      isTouched &&
      (otherOccupation?.name?.length > 50 || otherOccupation?.name === '')
    ) {
      formikNext.setFieldError('occupations', maxCharValidationText)
    } else if (
      formikNext.errors.occupations === maxCharValidationText &&
      formikNext.touched.occupations &&
      otherOccupation?.name?.length < 50
    ) {
      formikNext.setFieldError('occupations', undefined)
    }
    if (isTouched && otherOccupation?.name.match('[0-9,;|]+')) {
      formikNext.setFieldError('occupations', delimiterValidationText)
    } else if (
      isTouched &&
      !otherOccupation?.name.match('[0-9,;]+') &&
      formikNext.errors.occupations === delimiterValidationText
    ) {
      formikNext.setFieldError('occupations', undefined)
    }
  }, [formikNext.errors, formikNext.touched.occupations, otherOccupation?.name])

  useApiError(formikNext.setFieldError, onboarding.error, 'apiError')

  return (
    <div className={styles.container}>
      <LoadingModal isOpen={onboarding.loading} />
      <form className={styles.form} onSubmit={formikNext.handleSubmit}>
        <div>
          <Title
            text={onb.titleDesiredRegions}
            onboarding
            className={styles.firstTitle}
          />
          <CheckboxGroup
            name="regions"
            items={taxonomies.regions.data}
            multiple
            {...SetValuesProps(formikNext, 'regions')}
          />
          <Hr className={styles.hr} />
        </div>
        <div>
          <Title text={onb.titleWorkFromHome} onboarding />
          <RadioGroup
            items={taxonomies.workFromHome.data}
            name="workFromHomeId"
            {...SetValueProps(formikNext, 'workFromHomeId')}
          />
          <Hr className={styles.hr} />
        </div>
        <div>
          <Title text={onb.titleDesiredSalary} onboarding />
          <div className={styles.dropdown}>
            <RadioGroup
              items={taxonomies.salaries.data}
              name="salaryId"
              {...SetValueProps(formikNext, 'salaryId')}
            />
          </div>
          <Hr className={styles.hr} />
        </div>
        <div>
          <Title text={onb.titleDesiredJobPositions} onboarding />
          <CheckboxGroup
            name="occupations"
            items={taxonomies.occupations.data.filter(
              (o: TaxonomyWithCategoryType) => o.jobCategory
            )}
            multiple
            {...SetValuesProps(formikNext, 'occupations')}
            values={formikNext.values.occupations.map((o) => o.id)}
            setValues={(name, value) => {
              const newValues: TaxonomyWithCategoryType[] =
                taxonomies.occupations.data.filter(
                  (t: TaxonomyWithCategoryType) =>
                    t.jobCategory && value.includes(t.id)
                )
              if (otherOccupation.selected)
                newValues.push({
                  id: `newValue~${otherOccupation.name}`,
                  translation: otherOccupation.name,
                })
              formikNext.setFieldValue(name, newValues)
            }}
          >
            <label
              htmlFor={'other-ocuppations'}
              className={styles.otherOcuppations}
            >
              <CheckBoxIcon
                checked={otherOccupation.selected}
                className={classNames({
                  [styles.errorIcon]: formikNext.touched.occupations
                    ? formikNext.errors.occupations
                    : undefined,
                })}
              />
              <input
                className={styles.hidden}
                type="checkbox"
                id="other-ocuppations"
                value="other-ocuppations"
                name={'occupations'}
                checked={otherOccupation.selected}
                onChange={(e) => {
                  setOtherOccupation((old) => ({
                    ...old,
                    selected: e.target.checked,
                  }))
                  const oldValues = formikNext.values.occupations.filter(
                    (o: TaxonomyWithCategoryType) => o.jobCategory
                  )
                  if (e.target.checked) {
                    formikNext.setFieldValue('occupations', [
                      ...oldValues,
                      {
                        id: `newValue~${otherOccupation.name}`,
                      },
                    ])
                  } else {
                    formikNext.setFieldValue(
                      'occupations',
                      oldValues.filter(
                        (value) => !value.id.startsWith('newValue~')
                      )
                    )
                  }
                }}
                multiple={true}
              />
              <div className={styles.checkBoxLabel}>
                <FormattedMessage id={onb.other} />
              </div>
              {otherOccupation.selected && (
                <TextField
                  name="other-ocuppations"
                  placeholder={onb.otherJobPositionPlaceholder}
                  className={styles.otherOcuppationsTextField}
                  value={otherOccupation.name}
                  onChange={(e) => {
                    setOtherOccupation((old) => ({
                      ...old,
                      name: e.target.value,
                    }))
                  }}
                />
              )}
            </label>
          </CheckboxGroup>
          <Hr className={styles.hr} />
        </div>
        <div>
          <Title text={onb.titleJobSearchStatus} onboarding />
          <RadioGroup
            name="jobSearchStatusId"
            items={taxonomies.jobSearchStatuses.data}
            className={styles.jobSearchRadioGroup}
            minRows={2}
            {...SetValueProps(formikNext, 'jobSearchStatusId')}
          />
          <Hr className={styles.hr} />
        </div>
        <div>
          <Title text={onb.titleAvailability} onboarding />
          <RadioGroup
            items={taxonomies.availabilities.data}
            name="availabilityId"
            className={styles.availabilityRadioGroup}
            minRows={4}
            {...SetValueProps(formikNext, 'availabilityId')}
          />
          <Hr className={styles.hr} />
        </div>
        {formikNext.errors.apiError && (
          <TextError text={formikNext.errors.apiError} />
        )}
        <Buttons />
      </form>
    </div>
  )
}
