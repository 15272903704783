import {connect} from 'react-redux'

import {RootLayout} from './RootLayout'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {getUser, pingAuth} from 'Store/Actions/AuthActions'

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
  openedConversation: state.messageCenter.openedConversation,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  getUser: () => dispatch(getUser()),
  pingAuth: () => dispatch(pingAuth()),
})

export const RootLayoutContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(RootLayout)
