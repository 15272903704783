import {LanguageStringsType} from './LanguageStringsType'
import routes from './Routes/en'
import {seeker} from './Seeker/en'
import {publicPages} from './PublicPages/en'
import {settings} from './Settings/en'
import validation from './Validation/en'
import {general} from './General/en'
import {layout} from './Layout/en'
import {employer} from './Employer/en'
import {assessments} from './Assessments/en'
import messageCenter from './MessageCenter/en'
import {company} from './Company/en'
import {unsubscribe} from './Unsubscribe/en'
import {emailverification} from './EmailVerification/en'
import {emailchangeverification} from './EmailChangeVerification/en'
import {aboutUs} from './AboutUs/en'

export const en: LanguageStringsType = {
  ...routes,
  ...seeker,
  ...settings,
  ...validation,
  ...publicPages,
  ...general,
  ...layout,
  ...employer,
  ...assessments,
  ...messageCenter,
  ...company,
  ...unsubscribe,
  ...emailverification,
  ...emailchangeverification,
  ...aboutUs,
  emailAddress: 'Email address',
  password: 'Password',
  next: 'Next',
  back: 'Back',
  skills: 'Skills',
  saveChanges: 'Save changes',
  cancel: 'Cancel',
  preview: 'Preview',
  add: 'Add',
  edit: 'Edit',
  employer: 'Employer',
  address: 'Address',
  'Postal code': 'Postal code',
  City: 'City',
  Country: 'Country',
  'Select your country': 'Select your country',
  'Phone number': 'Phone number',
  yes: 'Yes',
  no: 'No',
  'Save changes': 'Save changes',
  Cancel: 'Cancel',
  delete: 'Delete',
  warning: 'Warning!',
  undefinedInput: ' ',
  'Show fewer': 'Show fewer',
  'Show more': 'Show more',
  'Preferenca.com': 'Preferenca.si',
  'Preferenca.si': 'Preferenca.si',
  'Select from list': 'Select from list',
  'Select value': 'Select value',
  'No entry found': 'No entry found',
  Other: 'Other',
  /** Page content */
  'home.title': 'Home',
  'home.content': 'Home',
  'First name': 'First name',
  'Last name': 'Last name',

  'publicHome.title': 'Public home',

  'modal.modal': 'Modal',

  'assessments.title': 'Assessments',
  'assessmentssuccess.title': 'AssessmentsSuccess',

  'summary.title': 'Page',
  'summary.content': 'page',

  'error.title': 'Error',
  'error.content': 'Error',

  'alerts.title': 'Alert',

  'lazy.title': 'Lazy',

  'seekerlogin.title': 'Seeker Login',
  'employerlogin.title': 'Employer Login',

  'jobpreferences.title': 'Job Preferences',

  'formikexample.title': 'Formik Example',

  'onboarding.title': 'Onboarding',

  'forgotpassword.title': 'Forgot Password',

  'resetpassword.title': 'Reset Password',

  'profile.title': 'Edit CV',
  'publicprofile.title': 'Edit public profile',

  'homePage.title': 'HomePage',

  'homePageEmployer.title': 'HomePageEmployer',
  'homePageCandidate.title': 'HomePageCandidate',

  'employerdashboard.title': 'Employer Dashboard',

  'homepagecandidate.title': 'Home Page Candidate',

  'frequentlyaskedquestions.title': 'Frequently Asked Questions',
  /** Component content */
  'checkbox-error-img.alt': 'ErrorIcon-EN',

  'uploadtest.title': 'Upload Test',

  'workexperience.title': 'Work Experience',

  'consents.title': 'My Consents',

  'companyprofile.title': 'companyprofile',
  'termsndconditions.title': 'termsandconditions',

  /** Links */
  'links.medium':
    'https://medium.com/prototyped/multi-language-routing-in-react-d7eb7a0688e9',
  'links.dev.to': 'https://dev.to/prototyp/multi-language-routing-in-react-k9l',

  'gallery.title': 'gallery title',
  'dragndrop.title': 'dragndrop example',

  'languagesettings.title': 'Language settings',
  'logout.title': 'Logout',
  'calendar.title': 'Calendar',
  'employercontactrequests.title': 'Employer Contact Requests',
  'employerinterviewinvitations.title': 'Employer Interview Invitations',
  'employeroffers.title': 'Employer Offers',
  'employerplacements.title': 'Employer Placements',
  'reviewcandidate.title': 'Review Candidate',
  Monday: 'Monday',
  Tuesday: 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday: 'Thursday',
  Friday: 'Friday',
  Saturday: 'Saturday',
  Sunday: 'Sunday',
  Daily: 'Daily',
  Monthly: 'Monthly',
  Disabled: 'Disabled',
  monday: 'Monday',
  tuesday: 'Tuesday',
  wednesday: 'Wednesday',
  thursday: 'Thursday',
  friday: 'Friday',
  saturday: 'Saturday',
  sunday: 'Sunday',
  daily: 'Daily',
  monthly: 'Monthly',
  disabled: 'Disabled',
  or: 'or',
  'Log out warning': 'Log out warning',
  'You will be logged out in one minute. If you want to stay logged in, please move the mouse or click anywhere on the page.':
    'You will be logged out in one minute. If you want to stay logged in, please move the mouse or click anywhere on the page.',
  ' years old': ' years old',
  'You can find this candidate in following projects:':
    'You can find this candidate in following projects:',
  Occupation: 'Occupation',
  'Job category': 'Job category',
  Employer: 'Employer',
  Skill: 'Skill',
  town: 'town',
  region: 'region',
  country: 'country',
  'Preferenca is currently down for maintenance.':
    'Preferenca is currently down for maintenance.',
  'We expect to be back shortly. Thank you for your patience.':
    'We expect to be back shortly. Thank you for your patience.',
  'Refresh page and check again': 'Refresh page and check again',
  Keyword: 'Keyword',
  'Company user login forbidden, company owner action needed':
    'Company user login forbidden, company owner action needed',
  'Wrong identifier or password': 'Wrong identifier or password',
  'Company is inactive': 'Company is inactive'
}
