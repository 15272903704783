import {FC, useEffect} from 'react'
import {UserType} from 'Interfaces'
import {disconnectSocket} from 'Services/I18n/Utils'
import {Socket} from 'socket.io-client'

interface LogoutProps {
  role: UserType
  logoutUser: (role: UserType) => void
  socketClient?: Socket
}

export const Logout: FC<LogoutProps> = ({logoutUser, role, socketClient}) => {
  useEffect(() => {
    disconnectSocket(socketClient)
    logoutUser(role)
  }, [logoutUser])

  return <></>
}
