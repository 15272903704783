import {FC, useEffect, useState} from 'react'
import {
  WelcomeMessageDataFormikType,
  TaxonomiesStateType,
  WelcomeMessageDataType,
} from 'Interfaces'
import {FieldArray, FormikProvider, useFormik} from 'formik'
import styles from './WelcomeMessageForm.module.scss'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import AddIcon from '@mui/icons-material/Add'
import {AddWelcomeMessageForm} from '../AddWelcomeMessageForm'
import {Button} from 'Components/Button'
import {CompanyWelcomeMessageTranslations as cwmt} from 'Services/I18n/Constants'
import {setupJobCategoriesLanguages, setupJobCategoriesOptions} from '../Utils'
import Validation from '../Validation'
import {EditWelcomeMessageForm} from '../EditWelcomeMessageForm'

const emptyAddWelcomeMessage: WelcomeMessageDataType = {
  jobCategory: {id: '', translation: ''},
  translatedTexts: [
    {
      language: {id: '', translation: ''},
      visibility: false,
      welcomeTitle: '',
      welcomeText: '',
    },
  ],
}

type WelcomeMessageFormProps = {
  closeModal: () => void
  currentModalMode: string
  addWelcomeMessage: (value: WelcomeMessageDataFormikType) => void
  editWelcomeMessage: (value: WelcomeMessageDataFormikType) => void
  taxonomies: TaxonomiesStateType
  welcomeMessages: WelcomeMessageDataType[]
}

export const WelcomeMessageForm: FC<WelcomeMessageFormProps> = ({
  closeModal,
  currentModalMode,
  addWelcomeMessage,
  editWelcomeMessage,
  taxonomies,
  welcomeMessages,
}) => {
  const [numOfPersonasInAdd, setNumOfPersonasInAdd] = useState(1)
  const [numOfNewPersonasInEdit, setNumOfNewPersonasInEdit] = useState(0)
  const deepCopiedWelcomeMessages = JSON.parse(JSON.stringify(welcomeMessages))

  // Edit state: If some entires exist, show them, otherwise empty(same as for the add)
  const formikDefaultValuesEdit: WelcomeMessageDataFormikType = {
    welcomeMessages: deepCopiedWelcomeMessages
      ? [...deepCopiedWelcomeMessages]
      : [emptyAddWelcomeMessage],
  }
  const formikInitialValues =
    currentModalMode === 'add'
      ? {welcomeMessages: [emptyAddWelcomeMessage]}
      : formikDefaultValuesEdit

  const formik = useFormik({
    validationSchema: Validation,
    initialValues: formikInitialValues,
    onSubmit: (values) => {
      if (currentModalMode === 'add') addWelcomeMessage(values)
      if (currentModalMode === 'edit') editWelcomeMessage(values)
      closeModal()
    },
  })

  // JOB CATEGORIES LANGUAGES
  const [jobCategoriesLanguagesState, setJobCategoriesLanguagesState] =
    useState<any>({})

  // Initial Job Categories Languages:
  useEffect(() => {
    const initialJobCategoriesLanguages = setupJobCategoriesLanguages(
      taxonomies,
      deepCopiedWelcomeMessages
    )
    setJobCategoriesLanguagesState(initialJobCategoriesLanguages)
  }, [])

  // JOB CATEGORIES OPTIONS
  const [jobCategoriesOptionsState, setJobCategoriesOptionsState] =
    useState<any>([])

  // Initial Job Categories options:
  useEffect(() => {
    const initialJobCategoriesOptions = setupJobCategoriesOptions(taxonomies)
    setJobCategoriesOptionsState(initialJobCategoriesOptions)
  }, [])

  // On jobCategoriesLanguagesState change - Update Categories options as well:
  useEffect(() => {
    const newJobCategoriesOptions = setupJobCategoriesOptions(
      taxonomies,
      jobCategoriesLanguagesState
    )
    setJobCategoriesOptionsState(newJobCategoriesOptions)
  }, [jobCategoriesLanguagesState])

  return (
    <>
      <FormikProvider value={formik}>
        <FieldArray name="welcomeMessages">
          {({push, remove}) => {
            return (
              <>
                {/* ADD FORM */}
                {currentModalMode === 'add' && (
                  <>
                    {Array(numOfPersonasInAdd)
                      .fill('')
                      .map((_, i) => {
                        return (
                          <AddWelcomeMessageForm
                            key={i}
                            index={i}
                            formik={formik}
                            taxonomies={taxonomies}
                            remove={remove}
                            numOfPersonasInAdd={numOfPersonasInAdd}
                            setNumOfPersonasInAdd={setNumOfPersonasInAdd}
                            welcomeMessages={deepCopiedWelcomeMessages}
                            jobCategoriesLanguagesState={
                              jobCategoriesLanguagesState
                            }
                            setJobCategoriesLanguagesState={
                              setJobCategoriesLanguagesState
                            }
                            jobCategoriesOptionsState={
                              jobCategoriesOptionsState
                            }
                          />
                        )
                      })}

                    {/* ADD PERSONA(ADD): */}
                    {/* 3 job categories, 2 languages = 6 max messages */}
                    {/* Existing messages (eg 4) + newly added personas(new messages - eg. 2) is max */}
                    {welcomeMessages.reduce(
                      (prev, curr) => curr.translatedTexts.length + prev,
                      0
                    ) +
                      formik.values.welcomeMessages.length <
                      taxonomies.jobCategories.data.length *
                        taxonomies.systemLanguages.data.length && (
                      <Button
                        onClick={() => {
                          push(emptyAddWelcomeMessage)
                          setNumOfPersonasInAdd(numOfPersonasInAdd + 1)
                        }}
                        text={cwmt.addPersonaText}
                        className={styles.addPersonaButton}
                        icon={<AddIcon />}
                      />
                    )}
                  </>
                )}

                {/* EDIT FORM */}
                {currentModalMode === 'edit' && (
                  <>
                    {formik.values.welcomeMessages.map(
                      (welcomeMessage, welcomeMessageIndex) => {
                        // Id exists(not empty) = existing message -> edit
                        if (welcomeMessage.id) {
                          // Loop thru welcome messages -> Loop thru translated texts -> display each text as edit form
                          // Show only existing (in edit fashion), if add persona is clicked open add form inside edit
                          return welcomeMessage.translatedTexts.map(
                            (translatedText, translationTextIndex) => {
                              return (
                                <EditWelcomeMessageForm
                                  key={
                                    translatedText.id || translationTextIndex
                                  }
                                  welcomeMessageIndex={welcomeMessageIndex}
                                  translationTextIndex={translationTextIndex}
                                  formik={formik}
                                  taxonomies={taxonomies}
                                  remove={remove}
                                  welcomeMessage={welcomeMessage}
                                  translatedText={translatedText}
                                  jobCategoriesLanguagesState={
                                    jobCategoriesLanguagesState
                                  }
                                  setJobCategoriesLanguagesState={
                                    setJobCategoriesLanguagesState
                                  }
                                />
                              )
                            }
                          )
                        }
                        return null
                      }
                    )}

                    {/* Add Form for edits "Add Persona" */}
                    {Array(numOfNewPersonasInEdit)
                      .fill('')
                      .map((_, i) => {
                        const editAddPersonaIndex = welcomeMessages.length + i

                        return (
                          <AddWelcomeMessageForm
                            key={i}
                            index={editAddPersonaIndex}
                            editNewPersonasIndex={i}
                            formik={formik}
                            taxonomies={taxonomies}
                            remove={remove}
                            numOfPersonasInAdd={numOfNewPersonasInEdit}
                            setNumOfPersonasInAdd={setNumOfNewPersonasInEdit}
                            welcomeMessages={deepCopiedWelcomeMessages}
                            jobCategoriesLanguagesState={
                              jobCategoriesLanguagesState
                            }
                            setJobCategoriesLanguagesState={
                              setJobCategoriesLanguagesState
                            }
                            jobCategoriesOptionsState={
                              jobCategoriesOptionsState
                            }
                          />
                        )
                      })}

                    {/* ADD PERSONA(EDIT): */}
                    {/* 3 job categories, 2 languages = 6 max messages */}
                    {/* Existing messages (eg 4) + newly added personas(new messages - eg. 2) is max */}
                    {formik.values.welcomeMessages.reduce(
                      (prev, curr) => curr.translatedTexts.length + prev,
                      0
                    ) <
                      taxonomies.jobCategories.data.length *
                        taxonomies.systemLanguages.data.length && (
                      <Button
                        onClick={() => {
                          push(emptyAddWelcomeMessage)
                          setNumOfNewPersonasInEdit(numOfNewPersonasInEdit + 1)
                        }}
                        text={cwmt.addPersonaText}
                        className={styles.addPersonaButton}
                        icon={<AddIcon />}
                      />
                    )}
                  </>
                )}
              </>
            )
          }}
        </FieldArray>
      </FormikProvider>
      <div className={styles.submitWrap}>
        <SubmitFooter
          onSubmit={formik.handleSubmit}
          onCancel={closeModal}
          theme="modalProfileSubmit"
        />
      </div>
    </>
  )
}
