import * as Yup from 'yup'

export const AddValidation = Yup.object().shape({
  occupation: Yup.object().shape({
    id: Yup.string().required('Job title is required field.'),
    translation: Yup.string(),
  }),
  experience: Yup.number().required('Experience is required field.'),
})

export const EditValidation = Yup.object().shape({
  totalWorkExperience: Yup.object().shape({
    id: Yup.string().required('Total work experience is required field.'),
    translation: Yup.string(),
  }),
})
