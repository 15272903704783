export const emailverification = {
  'emailverification.pending': 'Verifikacija poteka',
  'emailverification.pleaseWaitText':
    'Prosimo počakajte trenutek. Samodejno boste preusmerjeni',
  'emailverification.verificationFailed': 'Verifikacija ni uspela.',
  'emailverification.verificationFailedDescription':
    'Prosimo vpišite vaš elektronski naslov v polje spodaj. Ponovno Vam bomo poslali verifikacijsko povezavo.',
  'emailverification.email': 'Elektronski naslov',
  'emailverification.verificationResend': 'Pošlji ponovno',
  'emailverification.verifyYourAccount': 'Potrdite svoj uporabniški račun',
  'emailverification.verifyYourAccountDescription':
    'Vaš uporabniški račun še ni potrjen. Prosimo preverite svoj predal z elektronsko pošto. Če elektronske pošte niste prejeli ali povezava ni več veljavna, vpišite svoj elektronski naslov v polje spodaj in poslali Vam jo bomo ponovno.',
  'emailverification.emailResentTitle': 'Poslali smo vam potrditveno povezavo',
  'emailverification.emailResentDescription':
    'Prosimo preverite svoj elektronski predal in potrdite svoj račun.',
  'emailVerificationNotice.title': 'Hvala za registracijo.',
  'emailVerificationNotice.description':
    'Na vaš elektronski naslov smo poslali aktivacijsko povezavo.',
  'emailVerificationNotice.button': 'Nazaj na Prijavo',
  'emailVerificationNotice.email': 'Niste prejeli aktivacijske povezave?',
}
