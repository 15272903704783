const company = {
  'Industry is required': 'Polje Dejavnost je obvezno',
  'Company name is required': 'Polje Naziv podjetja je obvezno',
  'Company description is required': 'Polje Opis podjetja je obvezno',
  'Portfolio is required.': 'Zahtevan je portfolio.',
  'Persona is required.': 'Zahtevana je persona.',
  'Welcome Message title is required.': 'Zahtevano je pozdravno sporočilo.',
  'Welcome Message description is required.':
    'Zahtevan je opis pozdravnega sporočila.',
  'Project name is too long': 'Ime projekta je predolgo',
  'Project name is required': 'Ime projekta je obvezno polje',
}

export default company
