import * as type from '../types'
import {
  ActionType,
  AttachmentSaveType,
  AwardsSaveValue,
  WorkExperienceFormikValue,
  ProjectFormikValue,
  SkillType,
  UserUpdateBioType,
  LanguageType,
  EducationFormikValue,
  ProfileJobPreferenceFormikType,
  CertificationFormikValue,
  AddSkillType,
  RegistrationType,
  PublicUserUpdateBioType,
  PublicWorkExperienceSaveValue,
  PublicProfileJobPreferenceFormikType,
  PublicWorkExperienceFormikValue,
  AttachmentType,
  PersonalDataSaveType,
  PublicWorkExperiencesSaveValue,
  DrivingLicenseSaveType,
} from 'Interfaces'

export const getSeekerProfile = (): ActionType<undefined> => {
  return {
    type: type.profile.full.requested,
  }
}

export const getSeekerPublicProfile = (id: string): ActionType<string> => {
  return {
    type: type.profile.public.requested,
    payload: id,
  }
}

export const registerSeeker = (
  user: RegistrationType
): ActionType<RegistrationType> => {
  return {
    type: type.seeker.register.requested,
    payload: {
      ...user,
    },
  }
}

export const addDrivingLicence = (
  value: DrivingLicenseSaveType
): ActionType<DrivingLicenseSaveType> => {
  return {
    type: type.seeker.addDrivingLicence.requested,
    payload: value,
  }
}

export const editDrivingLicence = (
  value: DrivingLicenseSaveType
): ActionType<DrivingLicenseSaveType> => {
  return {
    type: type.seeker.editDrivingLicence.requested,
    payload: value,
  }
}

export const addEducation = (
  value: EducationFormikValue
): ActionType<EducationFormikValue> => {
  return {
    type: type.seeker.education.add.requested,
    payload: value,
  }
}
export const deleteEducation = (id: string): ActionType<string> => {
  return {
    type: type.seeker.education.delete.requested,
    payload: id,
  }
}

export const editEducation = (
  value: EducationFormikValue
): ActionType<EducationFormikValue> => {
  return {
    type: type.seeker.education.update.requested,
    payload: value,
  }
}

export const addWorkExperience = (
  value: WorkExperienceFormikValue
): ActionType<WorkExperienceFormikValue> => {
  return {
    type: type.SEEKER_WORK_EXPERIENCE_ADD_REQUESTED,
    payload: value,
  }
}

export const addAttachment = (
  value: AttachmentSaveType
): ActionType<AttachmentSaveType> => {
  return {
    type: type.SEEKER_ATTACHMENTS_ADD_REQUESTED,
    payload: value,
  }
}

export const editAttachment = (
  value: AttachmentSaveType
): ActionType<AttachmentSaveType> => {
  return {
    type: type.SEEKER_ATTACHMENTS_EDIT_REQUESTED,
    payload: value,
  }
}

export const removeAttachment = (
  value: AttachmentType
): ActionType<AttachmentType> => {
  return {
    type: type.SEEKER_ATTACHMENTS_DELETE_REQUESTED,
    payload: value,
  }
}

export const addAwards = (
  value: AwardsSaveValue
): ActionType<AwardsSaveValue> => {
  return {
    type: type.SEEKER_AWARDS_ADD_REQUESTED,
    payload: value,
  }
}

export const editWorkExperience = (
  value: WorkExperienceFormikValue
): ActionType<WorkExperienceFormikValue> => {
  return {
    type: type.SEEKER_WORK_EXPERIENCE_EDIT_REQUESTED,
    payload: value,
  }
}
export const deleteWorkExperience = (id: string): ActionType<string> => {
  return {
    type: type.SEEKER_WORK_EXPERIENCE_DELETE_REQUESTED,
    payload: id,
  }
}
export const addProject = (
  value: ProjectFormikValue
): ActionType<ProjectFormikValue> => {
  return {
    type: type.SEEKER_PROJECT_ADD_REQUESTED,
    payload: value,
  }
}
export const editAwards = (
  value: AwardsSaveValue
): ActionType<AwardsSaveValue> => {
  return {
    type: type.SEEKER_AWARDS_EDIT_REQUESTED,
    payload: value,
  }
}
export const deleteAwards = (id: string): ActionType<string> => {
  return {
    type: type.SEEKER_AWARDS_DELETE_REQUESTED,
    payload: id,
  }
}

export const editProject = (
  value: ProjectFormikValue
): ActionType<ProjectFormikValue> => {
  return {
    type: type.SEEKER_PROJECT_EDIT_REQUESTED,
    payload: value,
  }
}
export const deleteProject = (id: string): ActionType<string> => {
  return {
    type: type.SEEKER_PROJECT_DELETE_REQUESTED,
    payload: id,
  }
}

export const addSkill = (value: AddSkillType): ActionType<AddSkillType> => {
  return {
    type: type.seeker.skills.add.full.requested,
    payload: value,
  }
}

export const editSkill = (value: SkillType): ActionType<SkillType> => {
  return {
    type: type.seeker.skills.update.full.requested,
    payload: value,
  }
}

export const deleteSkill = (value: SkillType): ActionType<SkillType> => {
  return {
    type: type.seeker.skills.delete.full.requested,
    payload: value,
  }
}

export const addPublicSkill = (
  value: AddSkillType
): ActionType<AddSkillType> => {
  return {
    type: type.seeker.skills.add.public.requested,
    payload: value,
  }
}

export const editPublicSkill = (value: SkillType): ActionType<SkillType> => {
  return {
    type: type.seeker.skills.update.public.requested,
    payload: value,
  }
}

export const deletePublicSkill = (value: SkillType): ActionType<SkillType> => {
  return {
    type: type.seeker.skills.delete.public.requested,
    payload: value,
  }
}

export const addCertification = (
  value: CertificationFormikValue
): ActionType<CertificationFormikValue> => {
  return {
    type: type.SEEKER_CERTIFICATION_ADD_REQUESTED,
    payload: value,
  }
}

export const editCertification = (
  value: CertificationFormikValue
): ActionType<CertificationFormikValue> => {
  return {
    type: type.SEEKER_CERTIFICATION_EDIT_REQUESTED,
    payload: value,
  }
}
export const deleteCertification = (id: string): ActionType<string> => {
  return {
    type: type.SEEKER_CERTIFICATION_DELETE_REQUESTED,
    payload: id,
  }
}

export const updateBioData = (
  value: UserUpdateBioType
): ActionType<UserUpdateBioType> => {
  return {
    type: type.seeker.updateBioData.full.requested,
    payload: value,
  }
}

export const updatePublicBioData = (
  value: PublicUserUpdateBioType
): ActionType<PublicUserUpdateBioType> => {
  return {
    type: type.seeker.updateBioData.public.requested,
    payload: value,
  }
}

export const addLanguage = (value: LanguageType): ActionType<LanguageType> => {
  return {
    type: type.SEEKER_LANGUAGES_ADD_REQUESTED,
    payload: value,
  }
}

export const editLanguage = (value: LanguageType): ActionType<LanguageType> => {
  return {
    type: type.SEEKER_LANGUAGES_EDIT_REQUESTED,
    payload: value,
  }
}

export const deleteLanguage = (
  value: LanguageType
): ActionType<LanguageType> => {
  return {
    type: type.SEEKER_LANGUAGES_DELETE_REQUESTED,
    payload: value,
  }
}

export const updateJobPreferences = (
  value: ProfileJobPreferenceFormikType
): ActionType<ProfileJobPreferenceFormikType> => {
  return {
    type: type.seeker.updateJobPreferences.full.requested,
    payload: value,
  }
}

export const updatePublicJobPreferences = (
  value: ProfileJobPreferenceFormikType
): ActionType<PublicProfileJobPreferenceFormikType> => {
  return {
    type: type.seeker.updateJobPreferences.public.requested,
    payload: value,
  }
}

export const updatePublicWorkExperience = (
  value: PublicWorkExperiencesSaveValue
): ActionType<PublicWorkExperienceSaveValue> => {
  return {
    type: type.seeker.workExperience.update.public.requested,
    payload: value,
  }
}

export const addPublicWorkExperience = (
  value: PublicWorkExperienceFormikValue
): ActionType<PublicWorkExperienceFormikValue> => {
  return {
    type: type.seeker.workExperience.add.public.requested,
    payload: value,
  }
}

export const getSeekerPersonalData = (): ActionType<undefined> => {
  return {
    type: type.seeker.personalData.get.requested,
  }
}

export const saveSeekerPersonalData = (
  value: PersonalDataSaveType
): ActionType<PersonalDataSaveType> => {
  return {
    type: type.seeker.personalData.save.requested,
    payload: value,
  }
}

export const reparseCv = (fileId: string): ActionType<string> => {
  return {
    type: type.seeker.reparseCv.requested,
    payload: fileId,
  }
}
