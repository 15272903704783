import {FC, useEffect, useState} from 'react'

import {YellowHeader} from 'Components/Assessments/PsychometricTestResults/YellowHeader'
import MainLayout from 'Layouts/MainLayout'
import {SidebarLink} from 'Components/SidebarLink'
import {
  AppRoute,
  GeneralAssessmentsTranslations as gat,
  PsychometricAssessmentsTranslations as pat,
  SidebarTranslations as st,
} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'
import {
  AuthStateType,
  PsychometricResultsStatementsType,
  PublicSeekerProfileType,
  SeekerProfileType,
  SeekerStateType,
  Test,
} from 'Interfaces'
import {Link, useParams} from 'react-router-dom'
import {getRoute} from 'Services/I18n/Utils'
import ResultGroupSection from 'Components/Assessments/PsychometricTestResults/ResultGroupSection'
import {DownloadAndPrintHeader} from 'Components/DownloadAndPrintHeader'
import {useTranslation} from 'Hooks'
import Breadcrumbs from 'Components/Breadcrumbs'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {MobileFooter} from 'Components/MobileFooter'
import {useMediaQuery} from 'react-responsive'
import {Footer} from 'Components/Footer/Footer'
import {footerItems} from 'Components/Footer'

import styles from './PsychometricTestResults.module.scss'

interface PsychometricTestResultsProps {
  psychometricResultsStatements: PsychometricResultsStatementsType[]
  getPsychometricResultsStatements: (candidateId: string) => void
  getCandidateProfile: (id: string) => void
  candidate?: SeekerProfileType | PublicSeekerProfileType
  seeker: SeekerStateType
  getSeekerData: () => void
  auth: AuthStateType
  getSeekerAssessments: (seekerId: string) => void
  assessments: []
  getPsychometricTestResults: (value: string) => void
  getAssessments: () => void
  psychometricDownloadLink: {
    url: string
  }
}

export const PsychometricTestResults: FC<PsychometricTestResultsProps> = ({
  psychometricResultsStatements,
  getPsychometricResultsStatements,
  getCandidateProfile,
  candidate,
  seeker,
  auth,
  getSeekerData,
  getSeekerAssessments,
  getPsychometricTestResults,
  getAssessments,
  assessments,
  psychometricDownloadLink,
}) => {
  const translation = useTranslation()
  const {candidateId} = useParams<{candidateId: string}>()
  const [psychometricTest, setPsychometricTest] = useState<Test | undefined>(
    undefined
  )
  const isMobile = useMediaQuery({
    query: '(max-width: 900px)',
  })

  const assessmentsUrl = candidateId
    ? getRoute(AppRoute.PublicAssessments).replace(':id', candidateId)
    : getRoute(AppRoute.Assessments)

  const candidateProfileUrl = candidateId
    ? getRoute(AppRoute.EmployerCandidateProfileFull).replace(
        ':id',
        candidateId
      )
    : getRoute(AppRoute.Profile)

  const breadcrumbsArray = [
    {name: 'Preferenca.si', link: getRoute(AppRoute.HomePageCandidate)},
    {
      name: `${
        candidateId ? candidate?.firstName : seeker.data?.full?.firstName
      } ${candidateId ? candidate?.lastName : seeker.data?.full?.lastName}`,
      link: candidateProfileUrl,
    },
    {
      name: translation[gat.title],
      link: assessmentsUrl,
    },
    {name: translation[pat.psychometricTestResults]},
  ]

  const links = [
    {
      name: translation[st.editPublicProfile],
      route: AppRoute.PublicProfile,
    },
    {
      name: translation[st.editCv],
      route: AppRoute.Profile,
    },
  ]

  useEffect(() => {
    if (candidateId) {
      getCandidateProfile(candidateId)
      getPsychometricResultsStatements(candidateId)
    } else {
      if (auth.data) {
        getSeekerData()
        getPsychometricResultsStatements(auth.data.id)
      }
    }
  }, [candidateId])

  useEffect(() => {
    if (candidate) {
      getSeekerAssessments(candidate.id)
    }
  }, [candidate])

  useEffect(() => {
    if (!candidateId) {
      getAssessments()
    }
  }, [])

  useEffect(() => {
    if (assessments.length > 0 && candidateId) {
      setPsychometricTest(
        assessments.find((test: Test) => test.name === 'Psychometric test')
      )
    } else {
      setPsychometricTest(
        (
          assessments as unknown as {
            items: []
          }
        ).items.find((test: Test) => test.name === 'Psychometric test')
      )
    }
  }, [assessments])

  useEffect(() => {
    if (psychometricTest?.id) {
      getPsychometricTestResults(psychometricTest?.id as string)
    }
  }, [psychometricTest])

  return (
    <MainLayout
      withoutLogo
      content={
        <div className={styles.linksWrap}>
          {candidateId ? (
            <>
              <SidebarLink
                text={st.candidateProfile}
                route={getRoute(AppRoute.EmployerCandidateProfileFull).replace(
                  ':id',
                  candidateId
                )}
              />
              <SidebarLink
                text={st.dashboard}
                routeName={AppRoute.EmployerDashboard}
              />
              <SidebarLink
                text={st.candidateSearch}
                routeName={AppRoute.CandidateSearch}
              />
            </>
          ) : (
            <>
              <SidebarLink
                text={st.editPublicProfile}
                routeName={AppRoute.PublicProfile}
              />
              <SidebarLink text={st.editCv} routeName={AppRoute.Profile} />
            </>
          )}
        </div>
      }
      Footer={
        <>
          {isMobile && <MobileFooter links={links} />}

          <Footer
            className={styles.psychometricTestResultsFooter}
            navigationItems={
              isMobile
                ? [
                    {
                      pageName: 'Preferenca.si',
                      props: {to: getRoute(AppRoute.HomePageCandidate)},
                    },
                  ]
                : footerItems
            }
          />
        </>
      }
    >
      <div className={styles.mainContainer}>
        <DownloadAndPrintHeader
          downloadLink={psychometricDownloadLink?.url}
          className={styles.downloadAndPrintHeader}
        />
        {(seeker?.data?.full || candidate) && psychometricResultsStatements && (
          <>
            <div className={styles.breadcrumbsWrapper}>
              <Breadcrumbs breadcrumbs={breadcrumbsArray} />
            </div>

            <div className={styles.psychometricTestResultsContainer}>
              <YellowHeader
                candidate={candidateId ? candidate : seeker?.data?.full}
              />
              <div className={styles.testResultsContent}>
                <h1>
                  <FormattedMessage id={pat.psychometricTestResultsUpperCase} />
                </h1>
                <div className={styles.resultsSearchContainer}>
                  <div className={styles.grayProfileBox}>
                    <p>
                      <FormattedMessage id={gat.generalProfile} />
                    </p>
                  </div>
                </div>
                {psychometricResultsStatements.map(
                  (psychometricResultStatement) => {
                    return (
                      <ResultGroupSection
                        key={psychometricResultStatement.id}
                        psychometricResultStatement={
                          psychometricResultStatement
                        }
                      />
                    )
                  }
                )}
              </div>
            </div>
            <Link
              to={
                candidateId
                  ? getRoute(AppRoute.PublicAssessments).replace(
                      ':id',
                      candidateId
                    )
                  : getRoute(AppRoute.Assessments)
              }
              className={styles.getBackToAssessmentsWrapper}
            >
              <span>
                <ArrowBackIcon />
                <span>
                  <FormattedMessage id={gat.backToAssessments} />
                </span>
              </span>
            </Link>
          </>
        )}
      </div>
    </MainLayout>
  )
}
