export enum CompanyDescriptionTranslations {
  descriptionTitle = 'companyDescription.descriptionTitle',

  basicDescriptionTitle = 'companyDescription.basicDescriptionTitle',
  mainActivitiesTitle = 'companyDescription.mainActivitiesTitle',
  visionAndGoalsTitle = 'companyDescription.visionAndGoalsTitle',
  structureAndCultureTitle = 'companyDescription.structureAndCultureTitle',
  customCompanyDescriptionTitle = 'companyDescription.customCompanyDescriptionTitle',

  companyLogoAlt = 'companyDescription.companyLogoAlt',

  biziSiIndustryTitle = 'companyDescription.biziSiIndustryTitle',
  biziSiNumberOfEmployersTitle = 'companyDescription.biziSiNumberOfEmployersTitle',
  biziSiRevenues = 'companyDescription.biziSiRevenues',

  biziSiLogoAlt = 'companyDescription.biziSiLogoAlt',

  modalTitle = 'companyDescription.modalTitle',
  modalAboutTitle = 'companyDescription.modalAboutTitle',
  modalAboutPlaceholderText = 'companyDescription.modalAboutPlaceholderText',
  modalMainActivitiesTitle = 'companyDescription.modalMainActivitiesTitle',
  modalMainActivitiesPlaceholderText = 'companyDescription.modalMainActivitiesPlaceholderText',
  modalVisionAndGoalsTitle = 'companyDescription.modalVisionAndGoalsTitle',
  modalVisionAndGoalsPlaceholderText = 'companyDescription.modalVisionAndGoalsPlaceholderText',
  modalStructureAndCultureTitle = 'companyDescription.modalStructureAndCultureTitle',
  modalStructureAndCulturePlaceholderText = 'companyDescription.modalStructureAndCulturePlaceholderText',
  modalCustomCompanyDescriptionTitle = 'companyDescription.modalCustomCompanyDescriptionTitle',
  modalCustomCompanyDescriptionPlaceholderText = 'companyDescription.modalCustomCompanyDescriptionPlaceholderText',

  modalAddText = 'companyDescription.modalAddText',
  modalEditText = 'companyDescription.modalEditText',
}
