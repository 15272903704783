export const companyFaq = {
  'companyFaq.faqTitle': 'FAQ',

  'companyFaq.basicInformation': 'Basic Information',

  'companyFaq.moreText': 'More',
  'companyFaq.lessText': 'Less',

  'companyFaq.modalFaqFullTitle': 'FREQUENTLY ASKED QUESTIONS',
  'companyFaq.modalAddQuestion': 'Add Question',

  'companyFaq.enterQuestionPlaceholderText': 'Enter question ...',

  'companyFaq.deleteText': 'Delete',

  'companyFaq.showMoreText': 'Show more ...',
  'companyFaq.showLessText': 'Show less ...',

  'companyFaq.modalAddText': 'Add',
  'companyFaq.modalEditText': 'Edit',

  'companyFaq.questionForCandidate1':
    'What resume formats does the platform support?',
  'companyFaq.questionForCandidate2':
    "I completed my profile but didn't get 100%, what does this mean?",
  'companyFaq.questionForCandidate3':
    'Is my profile visible to my current employer?',
  'companyFaq.questionForCandidate4':
    'What is the difference between a career profile and a resume/CV profile?',
  'companyFaq.questionForCandidate5':
    'Why do I need to solve psychometric and cognitive testing?',
  'companyFaq.questionForCandidate6':
    'What information should the Short description section contain?',
  'companyFaq.questionForCandidate7': "Why can't I edit my profile?",
  'companyFaq.questionForCandidate8':
    'How long does the HR specialist review the profile?',
  'companyFaq.answerForCandidate1':
    'The platform supports the following file formats: .doc, .docx, .pdf (maximum size is 5 MB).',
  'companyFaq.answerForCandidate2':
    'You can see the percentage of the completed profile on your desktop if you place your mouse over it. Sections marked in red are missing and need to be completed.',
  'companyFaq.answerForCandidate3':
    'No, your current employer does not have access to the information on your profile.',
  'companyFaq.answerForCandidate4':
    'Your career profile is anonymous and contains only information on the basis of which the employer cannot identify you.Your resume/CV profile will be visible to employers with your permission. When requesting contact from an employer, you can decide whether to approve the contact request and thus reveal more information about yourself or not.',
  'companyFaq.answerForCandidate5':
    "This may be the longest step of creating a profile, but an important one, as personality traits and social competence become an important factor in hiring. The results of the psychometric questionnaire provide insight into one's own personality characteristics and competencies, which cover most work activities. With cognitive testing, we learn more about the speed and efficiency of learning new skills and the ability to analyze and integrate information.",
  'companyFaq.answerForCandidate6':
    'Career Profile: This is where you present yourself to employers, but not personally identifiable information. Curriculum vitae/CV profile: You can enter any information here, as this description will only be visible to employers to whom you approve contact.',
  'companyFaq.answerForCandidate7':
    'You cannot edit your profile once you confirm your profile, as this sends it to HR specialists for review. Only after review will the profile be available for editing again.',
  'companyFaq.answerForCandidate8':
    'We strive to complete this review process as quickly as possible. The review is expected to be completed within 1-2 business days.',
  'companyFaq.questionForEmployer1':
    'How to approach talent/candidates most effectively?',
  'companyFaq.answerForEmployer1':
    'Preferenca.si is turning the employment process upside down, with you, as a company, applying for the profiles of the presented candidates. In doing so, it is important to look for candidates that meet your education, work experience, technical knowledge and skills requirements. This information will be available on your first search, where you will be able to see an anonymized list of candidates and their short descriptions. If you wish to obtain more information about a particular candidate, you can send them a request for disclosure of their full profile. Before that, we recommend that you thoroughly prepare your own profile on the platform and provide the talent you are interested in with enough information about the company, your vision and your work (read How to build your employer brand and profile on the platform to attract the right talents). If the candidate shows interest in the job you are offering, you will be able to view their resume, as well as their wishes and requirements for future employment. After reviewing this information, you can contact the candidate directly through the platform by inviting him to a video interview and establishing personal contact. If the interview is successful, you can send the candidate an offer and then and then negotiate a employment contract.',
  'companyFaq.questionForEmployer2':
    'How to build your employer brand and profile on the platform to attract the right talents?',
  'companyFaq.answerForEmployer2PartOne':
    'Employer branding is becoming increasingly important as it represents a way to market your business to the desired job seekers. You can do this by highlighting unique benefits and cultural characteristics of your company, which aim to generate interest from future job seekers, and present yourself as a desirable working environment. Focus on defining the essence of your company, which should include your vision and goals, and highlight what makes you unique. In doing so, you will come across as a reputable employer with a thriving and excellent working environment, which makes it easier to recruit and retain current employees. ',
  'companyFaq.answerForEmployer2PartTwo':
    'Forget about the logo and the cover image that represent your brand. Now you will have the opportunity to present to the talent pool what kind of candidates you are looking for, and especially which knowledge and skills are most important to you. At the same time, highlight the benefits you offer to your employees; these can be flexible working hours, remote work, childcare, additional education, fruit, and coffee in the workplace, etc. You can enrich your profile by adding images and videos that provide a more detailed presentation of the company. ',
  'companyFaq.questionForEmployer3':
    'Which candidates would best match you and your requirements?',
  'companyFaq.answerForEmployer3':
    'There is a lot of advice on how to assess soft skills in the process of selecting the right candidate. We have prepared for you a list of qualities to look for in candidates and summarised them into five essential groups:',
  'companyFaq.answerForEmployer3FirstPartTitle': 'Teamwork ',
  'companyFaq.answerForEmployer3FirstPart':
    '– most jobs require a certain level of collaboration with other people. Even work that often seems rather individual, such as programming, can include contributions from others. It is therefore important to focus on candidates who can work well with others.',
  'companyFaq.answerForEmployer3SecondPartTitle': 'Readiness to learn ',
  'companyFaq.answerForEmployer3SecondPart':
    '– lifelong learning is essential today. New technology and knowledge are constantly knocking on our door, and organisations and systems are changing. Willingness and, last but not least, the ability to learn are very important qualities of employees – not only when it comes to acquiring new skills, but also for growth and development as an expert and as a person.',
  'companyFaq.answerForEmployer3ThirdPartTitle': 'Communication ',
  'companyFaq.answerForEmployer3ThirdPart':
    '– this does not mean that every employee must be an expert in public speaking, but it does mean that every employee must be able to convey their message in a verbal or written manner and at the same time be able to understand the meaning of what is conveyed to them – especially by asking the right questions. Communication problems can have a drastic impact on work performance.',
  'companyFaq.answerForEmployer3FourthPartTitle': 'Self-motivation ',
  'companyFaq.answerForEmployer3FourthPart':
    'means that employees like what they do, and that they want to do quality work regardless of what reward is in store for them. Such employees usually also have self-initiative and are independent at work, able to take responsibility for the tasks performed and persevere in spite of obstacles.',
  'companyFaq.answerForEmployer3FifthPartTitle':
    'Adapting to the company culture ',
  'companyFaq.answerForEmployer3FifthPart':
    '– the focus is primarily on how the employee understands and accepts the workplace and the way of working, from the layout of the workplace itself all the way to the dress code. Company culture can change, but it is important that all employees know certain qualities and values of the company.',
  'companyFaq.answerForEmployer3FifthPart2':
    'These are just a few of the competencies and qualities that we have highlighted, and each company has its own requirements and priorities. For this purpose, Preferenca.si provides an overview of the work competencies and general cognitive abilities of each candidate. The results, obtained using standardised and normalised psychometric and cognitive tests, provide you as an employer with valuable information about potential candidates, which you can not get either from resumes or from interviews. The use of psychological testing is increasingly common in the selection and development of candidates, as it represents one of the best predictors of employee performance.',
  'companyFaq.questionForEmployer4':
    'From which fields are the candidates on Preferenca.si?',
  'companyFaq.answerForEmployer4':
    'Currently, the platform has candidates from the fields of stand engineering, electrical engineering and computer science. In the future, candidates from other fields of work will also be added.',
  'companyFaq.questionForEmployer5': 'How are candidates vetted on Preferenca?',
  'companyFaq.answerForEmployer5':
    'Candidates are screened by HR specialists and, if they meet the requirements, they are admitted to the platform.',
  'companyFaq.questionForEmployer6':
    'What happens if a candidate is simultaneously talking to several employers?',
  'companyFaq.answerForEmployer6':
    'A candidate may be talking to more than one employer until the offer is confirmed. After confirmation of employment the candidate is no longer visible/accessible to others.',
  'companyFaq.questionForEmployer7': 'Are video interviews recorded?',
  'companyFaq.answerForEmployer7':
    'No, video interviews are not being recorded and are not saved on the platform.',
  'companyFaq.questionForEmployer8':
    'What can I do if my OS system does not support video interview via the platform?',
  'companyFaq.answerForEmployer8':
    'We give you the option to conduct video interviews via your own internal platform, which you use and then record this step and all the following steps on Preferenca.si.',
  'companyFaq.questionForEmployer9':
    'Can a person who is not a Preferenca.si user join the video interview?',
  'companyFaq.answerForEmployer9':
    'Of course, you can share the video call link with other participants. 10 minutes before the video call, you will receive a reminder with a link you can share.',
  'companyFaq.questionForEmployer10':
    "Why the candidate doesn't accept my contact request?",
  'companyFaq.answerForEmployer10':
    'It is up to the candidate to decide which contact requests to accept. We therefore suggest, please edit your company profile carefully and thoughtfully.',
  'companyFaq.questionForEmployer11':
    'What should I write about on the company profile?',
  'companyFaq.answerForEmployer11':
    "It's important to present yourself as a company and focus primarily on addressing your potential candidates. You can highlight your company's unique strengths, add benefits, highlights, employment information, past projects, etc.",
  'companyFaq.questionForEmployer12':
    'Candidate has accepted my contact request but stopped responding.',
  'companyFaq.answerForEmployer12':
    'You can send candidate a message through the message centre. If candidate does not respond to that either, please contact our HR specialists.',
  'companyFaq.questionForEmployer13': 'What is the meaning of each status?',
  'companyFaq.answerForEmployer13Title': '·     accepted',
  'companyFaq.answerForEmployer13':
    ', if the candidate has accepted your contact request,',
  'companyFaq.answerForEmployer13SecondPartTitle': '·       pending',
  'companyFaq.answerForEmployer13SecondPart':
    ', if the candidate has not yet accepted or rejected the contact request,',
  'companyFaq.answerForEmployer13ThirdPartTitle': '·       rejected',
  'companyFaq.answerForEmployer13ThirdPart':
    ', if the candidate does not want to be in contact with you or share his CV profile with you',
  'companyFaq.answerForEmployer13FourthPartTitle': '·       expired',
  'companyFaq.answerForEmployer13FourthPart':
    ', if more than 14 days have passed from the contact request (you can send your contact request again in this case), and',
  'companyFaq.answerForEmployer13FifthPartTitle': '·       canceled',
  'companyFaq.answerForEmployer13FifthPart':
    ', if candidate has been placed with another company. In this case, all requests from other companies are automatically canceled.',
  'companyFaq.questionForEmployer14':
    'How many members of my team can use Preferenca.si?',
  'companyFaq.answerForEmployer14':
    "Each member od your team can use the platform with it's own account.",
  'companyFaq.forCandidate': 'FOR CANDIDATE',
  'companyFaq.support': 'Support',
  'companyFaq.faq': 'Frequently Asked Questions',
}
