import {connect} from 'react-redux'
import {AppDispatch} from 'Store'
import {RootState} from 'Store/Reducers'
import {GetConsentsParamsType, UpdateNewsletterConsentType} from 'Interfaces'
import {NewsletterUnsubscribe} from './NewsletterUnsubscribe'
import {getNewsletterConsents, updateGuestConsents} from 'Store/Actions'

const mapStateToProps = (state: RootState) => ({
  consents: state.homepage.consents,
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateGuestConsents: (data: UpdateNewsletterConsentType) =>
    dispatch(updateGuestConsents(data)),
  getNewsletterConsents: (data: GetConsentsParamsType) =>
    dispatch(getNewsletterConsents(data)),
})

export const NewsletterUnsubscribeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NewsletterUnsubscribe)
