import {CHANNELS, IChannel} from 'ChannelConfig'
import qs from 'qs'
import {reduce, isEqual} from 'lodash'
import {CompanyUserRole} from 'Interfaces'

export const getChannel = (): IChannel => {
  return (
    CHANNELS.find((channel) => channel.host === window.location.hostname) ||
    CHANNELS[5]
  )
}

export const generateURL = (
  baseApiUrl: string,
  params: Record<string, any> | undefined | {},
  startFrom: number = 0,
  pageSize: number = 20
) => baseApiUrl + '?' + qs.stringify({pageSize, startFrom, ...params})

export const diffProps = (a: any, b: any) => {
  return reduce(
    a,
    function (result, value, key) {
      //@ts-ignore
      return isEqual(value, b[key]) ? result : result.concat(key)
    },
    []
  )
}

export const isEmployerUser = (type: string): boolean => {
  return Object.values(CompanyUserRole).includes(type as CompanyUserRole)
}
export const isSeekerUser = (type: string): boolean => {
  return (
    type === 'seeker' || (type as CompanyUserRole) === CompanyUserRole.RECRUITER
  )
}
export const webSiteUrlFormatter = (website: string) => {
  return website.indexOf('http') === 0 ? website : `http://${website}`
}
