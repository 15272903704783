import {FC, useState} from 'react'
import {FormattedMessage} from 'react-intl'

import {SidebarLink} from 'Components/SidebarLink'
import {
  AppRoute,
  ProfileTranslations as pt,
  SidebarTranslations as st,
} from 'Services/I18n/Constants'
import {AccountCircleOutlined, PublishedWithChanges} from '@mui/icons-material'
import Hr from 'Components/Hr'
import {AuthStateType, RjbProfileStatus, SeekerProfileType} from 'Interfaces'
import {PublicProfileSidebarText} from 'Components/Profile/PublicProfileSidebarText'
import {useTranslation} from 'Hooks'
import ParseCvModalContainer from 'Components/Profile/ParseCvModal'

import styles from './ProfileSidebar.module.scss'

interface ProfileSidebarProps {
  profileCompleteness?: number
  auth: AuthStateType
  user?: SeekerProfileType
}

export const ProfileSidebar: FC<ProfileSidebarProps> = ({
  profileCompleteness = 0,
  auth,
  user,
}) => {
  const [parseCvModalOpen, setParseCvModalOpen] = useState(false)
  const translation = useTranslation()
  const parseCvClickHandler = () => {
    setParseCvModalOpen(true)
  }

  return (
    <div className={styles.container}>
      <div className={styles.linksWrap}>
        <SidebarLink
          text={st.continueToTests}
          routeName={AppRoute.Assessments}
        />
        <SidebarLink
          text={
            !user?.cvId ? translation[pt.parseCv] : translation[pt.reparseCv]
          }
          onClick={parseCvClickHandler}
        />
        {/* <SidebarLink text="Continue to Messages" routeName={'#'} /> link temporarily removed */}
      </div>
      <PublicProfileSidebarText
        title={translation[pt.myCv]}
        text={translation[pt.cvNotification]}
        linkText={pt.publicProfileLink}
      />
      {auth.data?.rjbProfileStatus === RjbProfileStatus.PENDING_APPROVAL && (
        <>
          <div className={styles.titleWrap}>
            <AccountCircleOutlined className={styles.icon} />

            <div className={styles.title}>
              <FormattedMessage id={st.profileStatus} />
            </div>
          </div>
          <Hr className={styles.hr} />
          <div>
            {' '}
            <div className={styles.approvalWrap}>
              <PublishedWithChanges className={styles.approvalIcon} />
              <div className={styles.approvalTitle}>
                <FormattedMessage id={st.waitingForApproval} />
              </div>
            </div>
            <div className={styles.text}>
              <FormattedMessage id={st.waitingForApprovalMessage} />
            </div>
            <div className={styles.progressContainer}>
              <div className={styles.progressNumber}>
                {Math.round(profileCompleteness * 100)}%
              </div>
              <div className={styles.progressWrap}>
                <div
                  className={styles.progress}
                  style={{width: `${profileCompleteness * 100}%`}}
                />
              </div>
            </div>
          </div>
        </>
      )}
      <ParseCvModalContainer
        parseCvModalOpen={parseCvModalOpen}
        setParseCvModalOpen={setParseCvModalOpen}
      />
    </div>
  )
}
