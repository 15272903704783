import {connect} from 'react-redux'

import {PublicWorkExperiences} from './PublicWorkExperiences'
import {AppDispatch} from 'Store'
import {
  getEmploymentTypes,
  updatePublicWorkExperience,
  addPublicWorkExperience,
  getTotalWorkExperiences,
  getOccupations,
} from 'Store/Actions'
import {RootState} from 'Store/Reducers'
import {
  PublicWorkExperienceFormikValue,
  PublicWorkExperiencesSaveValue,
} from 'Interfaces'
import {canChangeProfile} from 'Utils/Profile'

const mapStateToProps = (state: RootState) => ({
  profile: state.seeker,
  totalWorkExperience: state.seeker.data?.public?.totalWorkExperience,
  workExperiences: state.seeker.data?.public?.workExperiences,
  taxonomies: state.taxonomies,
  canChange: canChangeProfile(state.auth.data?.rjbProfileStatus),
})

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updatePublicWorkExperience: (value: PublicWorkExperiencesSaveValue) =>
    dispatch(updatePublicWorkExperience(value)),
  addPublicWorkExperience: (value: PublicWorkExperienceFormikValue) =>
    dispatch(addPublicWorkExperience(value)),
  getTaxonomies: () => (
    dispatch(getEmploymentTypes()),
    dispatch(getTotalWorkExperiences()),
    dispatch(getOccupations())
  ),
})

export const PublicWorkExperiencesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PublicWorkExperiences)
