export const emailverification = {
  'emailverification.pending': 'Verification pending',
  'emailverification.pleaseWaitText':
    'Please wait a moment. You will be redirected automatically.',
  'emailverification.verificationFailed': 'Verification failed.',
  'emailverification.verificationFailedDescription':
    'Please enter your e-mail into the field below. We will resend the verification link.',
  'emailverification.email': 'Email Address',
  'emailverification.verificationResend': 'Resend',
  'emailverification.verifyYourAccount': 'Verify your account',
  'emailverification.verifyYourAccountDescription':
    'Your account is not verified yet. Please check your email. If you did not recieve our email or your verification link is not valid anymore, you can enter your email below and we will resend it.',
  'emailverification.emailResentTitle': 'Verification sent',
  'emailverification.emailResentDescription':
    'Please check your inbox and confirm your account.',
  'emailVerificationNotice.title':
    'Thank you! You have successfully completed registration process.',
  'emailVerificationNotice.description':
    'We have just sent a verification link to your email address.',
  'emailVerificationNotice.button': 'Back to Login',
  'emailVerificationNotice.email': 'I did not receive the email?',
}
