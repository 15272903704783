import {SpectoProposeNewTimeParamsType} from 'Interfaces'
import {connect} from 'react-redux'

import {AppDispatch} from 'Store'
import {setSpectoProposeNewTime} from 'Store/Actions'
import {RequestActions} from './RequestActions'

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  setSpectoProposeNewTime: (payload: SpectoProposeNewTimeParamsType) =>
    dispatch(setSpectoProposeNewTime(payload)),
})

export const RequestActionsContainer = connect(
  null,
  mapDispatchToProps
)(RequestActions)
