export const employerFAQ = {
  'employerFAQ.titleYellow': 'Brezplačna',
  'employerFAQ.mainTitle': 'karierna podpora',
  'employerFAQ.titleFirst': 'Kako odločno pristopiti h kandidatom',
  'employerFAQ.titleSecond': 'Kako se predstaviti in pritegniti prave talente',
  'employerFAQ.titleThird':
    'Kateri kandidati bi se najbolje ujemali z vami in vašimi zahtevami',
  'employerFAQ.paragraphFirst':
    'Na Preferenci so pravila igre zaposlovanja zamenjana. Kot podjetje se prijavljate na profile predstavljenih kandidatov. Pri tem je pomembno, da poiščete kandidate, ki ustrezajo vašim zahtevam glede izobrazbe, delovnih izkušenj, tehničnih znanj in njihovih spretnosti. Informacije bodo na voljo že ob prvem iskanju, kjer si boste lahko ogledali anonimiziran seznam ter z njega izbrali le najboljše kandidate za svoje podjetje.',
  'employerFAQ.paragraphSecond':
    'Postavljanje blagovne znamke delodajalca je vse pomembneje, saj predstavlja način trženja vašega podjetja želenim iskalcem zaposlitve. To lahko storite tako, da poudarite edinstvene ugodnosti in kulturne značilnosti svojega podjetja.',
  'employerFAQ.paragraphThird':
    'Obstaja veliko nasvetov o tem, kako oceniti mehke veščine v procesu izbire primernega kandidata. Za vas smo strnili seznam najpomembnejših lastnosti, ki jih je smiselno iskati pri kandidatih:',

  'employerFAQ.subTitleFirst': 'Ekipno delo',
  'employerFAQ.subTitleSecond': 'Pripravljenost na učenje',
  'employerFAQ.subTitleThird': 'Komunikacija',
  'employerFAQ.subTitleFourth': 'Samomotivacija',
  'employerFAQ.subTitleFifth': 'Ujemanje s kulturo podjetja',
  'employerFAQ.subParagraphFirst':
    'Večina delovnih mest zahteva določeno stopnjo sodelovanja z drugimi ljudmi; tudi delo, ki je pogosto videti precej individualno, npr. programiranje, pogosto vključuje medsebojno usklajevanje. Pomembno je torej, da se osredotočite na kandidate, ki lahko dobro sodelujejo z drugimi.',
  'employerFAQ.subParagraphSecond':
    'Vseživljenjsko učenje je danes nujno; nova tehnologija in znanja napredujejo iz dneva v dan, organizacije in sistemi pa se nenehno spreminjajo. Pripravljenost in ne nazadnje tudi sposobnost učenja sta zelo pomembni lastnosti zaposlenih.',
  'employerFAQ.subParagraphThird':
    'To ne pomeni, da mora biti vsak zaposleni spreten z besedami ali vešč javnega nastopanja, pomembno pa je, da znajo zaposleni prenesti svoje sporočilo in obenem razumeti druge oziroma znajo postaviti prava vprašanja, kadar jih ne. Težave v komunikaciji lahko namreč korenito vplivajo na delovno uspešnost in klimo.',
  'employerFAQ.subParagraphFourth':
    'Kadar zaposleni delajo to, kar jih veseli, svoje delo opravljajo kakovostno, ne glede na nagrado. Ti zaposleni so po navadi tudi zelo samoiniciativni in samostojni pri delu, sposobni so prevzeti odgovornost za opravljene naloge in pri njih vztrajati kljub oviram.',
  'employerFAQ.subParagraphFifth':
    'Pomembno je, kako zaposleni razume in sprejme delovno mesto ter način dela – od organizacije delovnega mesta do kodeksa oblačenja. Kulturna prilagoditev se lahko spreminja, pomembno pa je predvsem, da zaposleni poznajo načela in vrednote podjetja.',
  'employerFAQ.subParagraphLast':
    'To je zgolj nekaj najpomembnejših kompetenc in lastnosti, ki smo jih poudarili. Vsako podjetje pa ima svoje zahteve in vrednote, ki jih ceni. Za ta namen Preferenca omogoča vpogled v delovne kompetence in splošne kognitivne sposobnosti posameznega kandidata. Ti rezultati so pridobljeni z uporabo standardiziranih in normiranih psihometričnih vprašalnikov ter kognitivnih preizkusov, ki vam, kot delodajalcem, ponujajo dragocene informacije o potencialnem kadru, ki jih ne morete pridobiti iz življenjepisov ali razgovorov. Uporaba psiholoških preizkusov je pri izbiri ter razvoju potencialnih bodočih zaposlenih v podjetju izjemno pomembno za zagotavljanje individualnega razvoja potencialov posameznika, ki je vpleten v delovni proces.',
}
