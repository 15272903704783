import {AccountCircleOutlined} from '@mui/icons-material'
import {FC} from 'react'
import styles from './HrIcon.module.scss'

export interface HrIconType {
  icon?: string
  HeaderIcon?: typeof AccountCircleOutlined
}

export const HrIcon: FC<HrIconType> = ({
  HeaderIcon = AccountCircleOutlined,
}: HrIconType) => {
  return (
    <div className={styles.deviderContainer}>
      <h1 className={styles.devider}>
        {HeaderIcon && <HeaderIcon sx={{fontSize: 40, color: 'gray'}} />}
      </h1>
    </div>
  )
}
