// based on html2plaintext from npm
import * as cheerio from 'cheerio'
import {Element} from 'cheerio'
const decode = require('he').decode
const plumb = require('plumb')

// "private" helper for ensuring html entities are properly escaped
const _escapeHtml = (input: string) => {
  return String(input)
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#039;')
}

// "private" helper for list processing into plaintext
const _list = (str: string, isOrdered: boolean) => {
  if (!str) return str

  const $ = cheerio.load(str)
  const listEl = isOrdered ? 'ol' : 'ul'

  $(listEl).each((_: number, el: Element) => {
    const $out = cheerio.load('<p></p>')
    const $el = $(el)

    $el.find('li').each((j: number, li: Element) => {
      const tick = isOrdered ? String(j + 1) + '' : ''

      $out('p').append(tick + ' ' + _escapeHtml($(li).text()) + '<br />')
    })

    // avoid excess spacing coming off last element
    // (we are wrapping with a <p> anyway)
    $out('br').last().remove()

    $el.replaceWith($out.html())
  })

  return $.html()
}

const stripStylesAndScripts = (str: string) => {
  const $ = cheerio.load(str)

  $('script').remove()
  $('style').remove()

  return $.html()
}

const stringify = (x: string) => {
  if (x === null || x === undefined) {
    return ''
  }

  return String(x)
}

const collapseWhitespace = (val: string) => {
  return val.replace(/\s+/g, ' ')
}

const linebreaks = (str: string) => {
  return str.replace(/<\s?(p|br)[^<]*>/gi, function (x, tag) {
    switch (tag.toLowerCase()) {
      case 'p':
        return '\n'
      case 'br':
        return '\n'
    }

    return x
  })
}

const listOrdered = (str: string) => {
  return _list(str, true)
}

const listUnordered = (str: string) => {
  return _list(str, false)
}

const stripCssConditionalComment = (str: string) => {
  return str.replace(/<!--\[if.*?<!\[endif\]-->/g, '')
}

const stripTags = (str: string) => {
  return str.replace(/<[^<]+>/g, '')
}

const trim = (str: string) => {
  return str.trim()
}

const htmlToPlainText = plumb(
  stringify,
  stripStylesAndScripts,
  listOrdered,
  listUnordered,
  collapseWhitespace,
  linebreaks,
  stripCssConditionalComment,
  stripTags,
  decode,
  trim
)

export default htmlToPlainText
