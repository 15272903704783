import {FC} from 'react'
import {IncomingRequestType, RjbProfileStatus} from 'Interfaces'
import {getAge} from 'Utils/Date'
import styles from './SeekerProfile.module.scss'
import LockOutlined from '@mui/icons-material/LockOutlined'
import {Link} from 'react-router-dom'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {ContactRequestStatus} from 'Components/EmployerDashboard/Constants'

type SeekerProfileProps = {
  request: IncomingRequestType
  setIsModalOpen: (v: boolean) => void
}

export const SeekerProfile: FC<SeekerProfileProps> = ({
  request,
  setIsModalOpen,
}) => {
  const {seeker, status} = request

  const isLocked = status !== ContactRequestStatus.APPROVED

  const noLongerActiveCandidate =
    !seeker.hasFinishedPlacementWithCurrentEmployer &&
    seeker.rjbProfileStatus === RjbProfileStatus.CLOSED

  const profileUrl = isLocked
    ? getRoute(AppRoute.EmployerCandidateProfilePublic).replace(
        ':id',
        seeker.userId
      )
    : getRoute(AppRoute.EmployerCandidateProfileFull).replace(
        ':id',
        seeker.userId
      )

  const avatarPicture = () => {
    if (isLocked) {
      return (
        <div className={styles.avatarLockedWrap}>
          {noLongerActiveCandidate ? (
            <button
              type="button"
              onClick={() => setIsModalOpen(true)}
              className={styles.avatarLockedWrap}
            >
              <LockOutlined className={styles.lockIcon} />
            </button>
          ) : (
            <Link to={profileUrl}>
              <LockOutlined className={styles.lockIcon} />
            </Link>
          )}
        </div>
      )
    }
    return seeker.profilePicture ? (
      noLongerActiveCandidate ? (
        <button
          type="button"
          onClick={() => setIsModalOpen(true)}
          className={styles.avatarImg}
        >
          <img
            className={styles.avatarImg}
            src={seeker.profilePicture.url}
            alt={`${seeker.firstName} ${seeker.lastName}`}
          />
        </button>
      ) : (
        <Link to={profileUrl}>
          <img
            className={styles.avatarImg}
            src={seeker.profilePicture.url}
            alt={`${seeker.firstName} ${seeker.lastName}`}
          />
        </Link>
      )
    ) : (
      <div className={styles.initialsAvatarWrap}>
        {noLongerActiveCandidate ? (
          <button
            type="button"
            onClick={() => setIsModalOpen(true)}
            className={styles.initialsAvatarWrap}
          >
            {seeker.firstName && seeker.lastName
              ? seeker.firstName[0] + seeker.lastName[0]
              : ''}
          </button>
        ) : (
          <Link to={profileUrl}>
            {seeker.firstName && seeker.lastName
              ? seeker.firstName[0] + seeker.lastName[0]
              : ''}
          </Link>
        )}
      </div>
    )
    // Not approved
  }

  let age = null
  if (seeker.dateOfBirth) age = getAge(seeker.dateOfBirth as string)

  return (
    <div className={styles.seekerProfileWrap}>
      {/* AVATAR Picture */}
      <div className={styles.avatarWrap}>{avatarPicture()}</div>

      <div className={styles.seekerInfo}>
        {/* Occupation */}
        {seeker.publicOccupation && (
          <div className={styles.jobTitle}>
            {noLongerActiveCandidate ? (
              <button
                type="button"
                onClick={() => setIsModalOpen(true)}
                className={styles.jobTitle}
              >
                <p>{seeker.publicOccupation.translation}</p>
              </button>
            ) : (
              <Link to={profileUrl}>
                <p>{seeker.publicOccupation.translation}</p>
              </Link>
            )}
          </div>
        )}

        <div>
          {/* First & Last Name */}
          {!isLocked && seeker.firstName && seeker.lastName && (
            <span className={styles.fullName}>
              {noLongerActiveCandidate ? (
                <button
                  type="button"
                  onClick={() => setIsModalOpen(true)}
                  className={styles.fullName}
                >
                  <span className={styles.withComma}>
                    {`${seeker.firstName} ${seeker.lastName}${
                      age || seeker?.town ? ', ' : ' '
                    }`}
                  </span>
                </button>
              ) : (
                <Link to={profileUrl}>
                  {`${seeker.firstName} ${seeker.lastName}${
                    age || seeker?.town ? ', ' : ' '
                  }`}
                </Link>
              )}
            </span>
          )}
          {/* Age */}
          {seeker.dateOfBirth && (
            <span className={styles.age}>
              {noLongerActiveCandidate ? (
                <button
                  type="button"
                  onClick={() => setIsModalOpen(true)}
                  className={styles.age}
                >
                  <span className={styles.withComma}>
                    {`${age}${seeker?.town ? ', ' : ' '}`}
                  </span>
                </button>
              ) : (
                <Link to={profileUrl}>
                  {`${age}${seeker?.town ? ', ' : ' '}`}
                </Link>
              )}
            </span>
          )}
          {/* Town */}
          {seeker?.town && (
            <span className={styles.location}>
              {noLongerActiveCandidate ? (
                <button
                  type="button"
                  onClick={() => setIsModalOpen(true)}
                  className={styles.location}
                >
                  {`${seeker.town.translation}`}
                </button>
              ) : (
                <Link to={profileUrl}>{`${seeker.town.translation}`}</Link>
              )}
            </span>
          )}
        </div>
      </div>
    </div>
  )
}
