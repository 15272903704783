import {FC, useEffect} from 'react'
import styles from './EmployerDashboardLandingHeader.module.scss'
import Hr from 'Components/Hr'
import {
  AccountCircleOutlined,
  EmailOutlined,
  PhoneIphoneOutlined,
} from '@mui/icons-material'
import classNames from 'classnames'
import defaultCover from 'Assets/Images/company-profile/default-cover.png'
import LogotypeSl from 'Assets/Images/company-profile/Logotip-sl.png'
import LogotypeEn from 'Assets/Images/company-profile/Logotip-en.png'
import {getLang} from 'Services/I18n/Utils'
import {AuthStateType, CompanyStateType} from 'Interfaces'
import {AvatarContainer} from 'Components/Avatar'
import {EmployerDashboardTranslations as edt} from 'Services/I18n/Constants'
import {useTranslation} from 'Hooks'

interface EmployerDashboardLandingHeaderProps {
  company: CompanyStateType
  getCompanyData: (id: string) => void
  auth: AuthStateType
}

export const EmployerDashboardLandingHeader: FC<
  EmployerDashboardLandingHeaderProps
> = ({auth, company, getCompanyData}) => {
  const translation = useTranslation()

  useEffect(() => {
    if (auth.data?.companyStructure?.company?.id) {
      getCompanyData(auth.data?.companyStructure.company?.id)
    }
  }, [auth.data])

  if (!company.data) return null

  const lang = getLang()
  const logotype = {en: LogotypeEn, sl: LogotypeSl}

  return (
    <div className={styles.welcomeNoteContainer}>
      <div>
        {company.data.coverImage ? (
          <img
            className={styles.coverImageContainer}
            src={company.data.coverImage?.url}
            alt="companyCoverImage"
          />
        ) : (
          <img
            className={styles.coverImageContainer}
            src={defaultCover}
            alt="companyCoverImage"
          />
        )}
      </div>
      <div className={styles.contentContainer}>
        <div className={styles.contentTop}>
          <div className={styles.logoContainer}>
            {company.data.logotype ? (
              <img
                src={company.data.logotype.url}
                alt="companyLogoImage"
                className={styles.logoStyle}
              />
            ) : (
              <img
                src={logotype[lang]}
                alt="companyLogoImage"
                className={styles.logoStyle}
              />
            )}
          </div>
          <div className={styles.headerWelcomeMessageContainer}>
            <div className={styles.welcomeMessage}>
              <AvatarContainer className={styles.avatar} />
              <div className={styles.welcomeMessageText}>
                {`${translation[edt.headerTitle]} ${auth.data?.firstName}${
                  translation[edt.headerTitleTxt]
                }`}
              </div>
            </div>
            <Hr theme="hrMargin" />
          </div>
        </div>
        <div className={styles.contentBottom}>
          <div className={styles.companyName}>{company.data.name}</div>
          <Hr theme="hrNoMarginMobileOnly" />
          <div className={styles.contactInfoContainer}>
            <div className={styles.iconAndTextContainer}>
              <AccountCircleOutlined className={styles.iconStyle} />
              <p className={styles.infoText}>
                {auth.data?.firstName} {auth.data?.lastName}
              </p>
            </div>
            <div
              className={classNames(
                styles.iconAndTextContainer,
                styles.iconAndTextPadding
              )}
            >
              <EmailOutlined className={styles.iconStyle} />
              <p className={styles.infoText}>{auth.data?.email}</p>
            </div>
            {auth.data?.phone && (
              <div
                className={classNames(
                  styles.iconAndTextContainer,
                  styles.iconAndTextPadding
                )}
              >
                <PhoneIphoneOutlined className={styles.iconStyle} />
                <p className={styles.infoText}>{auth.data?.phone}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
