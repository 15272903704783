import {generalAssessmentsTranslation} from './GeneralAssessmentsTranslation/en'
import {modalsAssessmentsTranslation} from './ModalsAssessmentsTranslation/en'
import {cognitiveAssessmentsTranslation} from './CognitiveAssessmentsTranslation/en'
import {psychometricAssessmentsTranslation} from './PsychometicAssessmentsTranslation/en'

export const assessments = {
  ...generalAssessmentsTranslation,
  ...modalsAssessmentsTranslation,
  ...cognitiveAssessmentsTranslation,
  ...psychometricAssessmentsTranslation,
}
