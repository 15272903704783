import {FC} from 'react'
import styles from './HowDoesItWorkSection.module.scss'
import YellowText from 'Components/YellowText'
import YellowButton from 'Components/YellowButton'
import {Link} from 'react-router-dom'
import {getRoute} from 'Services/I18n/Utils'
import {AppRoute} from 'Services/I18n/Constants'
import {EmployerHomePageTranslations as el} from 'Services/I18n/Constants'
import {FormattedMessage} from 'react-intl'

export const HowDoesItWorkSection: FC = () => {
  return (
    <div className={styles.mainContainer}>
      <div className={styles.titleWrapper}>
        <h1 className={styles.title}>
          <FormattedMessage id={el.howDoesItWorkTitlePartOne} />
          <YellowText
            text={el.howDoesItWorkTitlePartTwo}
            addCutPaintWidth={-32}
          />
        </h1>
      </div>
      <div className={styles.cardsContainer}>
        <div className={styles.card}>
          <h3>
            <FormattedMessage id={el.howDoesItWorksubTitleFirst} />
          </h3>
          <p>
            <FormattedMessage id={el.howDoesItWorkParagraphFirst} />
          </p>
        </div>
        <div className={styles.card}>
          <h3>
            <FormattedMessage id={el.howDoesItWorksubTitleSecond} />
          </h3>
          <p>
            <FormattedMessage id={el.howDoesItWorkParagraphSecond} />
          </p>
        </div>
        <div className={styles.card}>
          <h3>
            <FormattedMessage id={el.howDoesItWorksubTitleThird} />
          </h3>
          <p>
            <FormattedMessage id={el.howDoesItWorkParagraphThird} />
          </p>
        </div>
        <div className={styles.card}>
          <h3>
            <FormattedMessage id={el.howDoesItWorksubTitleFourth} />
          </h3>
          <p>
            <FormattedMessage id={el.howDoesItWorkParagraphFourth} />
          </p>
        </div>
      </div>
      <Link to={getRoute(AppRoute.HelpSupport)}>
        <div className={styles.buttonContainer}>
          <YellowButton text={el.buttonLandingTxt} className={styles.button} />
        </div>
      </Link>
    </div>
  )
}
