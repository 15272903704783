export const forgotPassword = {
  'forgotPassword.title': 'Pozabljeno geslo',
  'forgotPassword.description':
    'Vpišite svoj elektronski naslov in kmalu vam bomo poslali elektronsko pošto z navodili za ponastavitev gesla.',
  'forgotPassword.buttonResetPassword': 'Ponastavite geslo',
  'forgotPassword.backToLogIn': 'Nazaj na prijavo',
  'forgotPassword.successMessage':
    'Na vaš elektronski naslov smo pravkar poslali povezavo za ponastavitev gesla. Preverite svoj poštni predal in sledite navodilom na povezavi.',
  'Already used reset link':
    'Enkratno povezavo za resetiranje gesla ste že uporabili',
}
