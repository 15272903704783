import {connect} from 'react-redux'
import {AppDispatch} from 'Store'
import {archiveConversations, unreadConversationSocket} from 'Store/Actions'
import {HeaderMessagesSection} from './HeaderMessagesSection'

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  archiveConversations: (ids: string[]) => dispatch(archiveConversations(ids)),
  unreadConversation: (data: string[]) =>
    dispatch(unreadConversationSocket(data)),
})

export const HeaderMessagesSectionContainer = connect(
  null,
  mapDispatchToProps
)(HeaderMessagesSection)
