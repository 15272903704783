import {FC} from 'react'
import {SidebarLink} from 'Components/SidebarLink'
import {LinkType} from 'Interfaces'

interface LinksContainerProps {
  links: LinkType[]
  hash?: boolean
}

export const LinksContainer: FC<LinksContainerProps> = ({links, hash}) => {
  return (
    <div>
      {links.map((link, index) => {
        return (
          <SidebarLink
            key={`${link}-${index}`}
            onClick={link.onClick}
            route={link.route}
            text={link.name}
            icon={link.icon}
            hash={hash}
          />
        )
      })}
    </div>
  )
}
