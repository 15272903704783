import styles from './PublicProfileBioSection.module.scss'
import {
  BaseTaxonomyType,
  PublicSeekerProfileType,
  PublicUserUpdateBioType,
  SeekerStateType,
  TaxonomiesStateType,
} from 'Interfaces'
import {FC, useEffect, useMemo, useState} from 'react'
import {useFormik} from 'formik'
import Validation from './Validation'
import Modal from 'Components/Modal'
import {SubmitFooter} from 'Components/Modal/SubmitFooter'
import Title from 'Components/Title'
import Dropdown from 'Components/Inputs/Dropdown'
import Hr from 'Components/Hr'
import TextArea from 'Components/Inputs/TextArea'
import ItemTextWithIcon from 'Components/Profile/ItemTextWithIcon'
import {
  ProfileTranslations as pt,
  OnboardingTranslations as ot,
} from 'Services/I18n/Constants'
import TypeAheadImproved from 'Components/Inputs/TypeAheadImproved'
import {useApiError} from '../../../Hooks'
import {FormattedMessage, useIntl} from 'react-intl'
import classnames from 'classnames'
import {
  CakeOutlined,
  DescriptionOutlined,
  UpdateOutlined,
  Build,
  AccountCircleOutlined,
  EditOutlined,
  WorkOutline,
} from '@mui/icons-material'

interface PublicProfileBioSectionProps {
  user?: PublicSeekerProfileType
  profile: SeekerStateType
  updatePublicBioData: (value: PublicUserUpdateBioType) => void
  canChange?: boolean
  taxonomies: TaxonomiesStateType
  getTaxonomies: () => void
}

export const PublicProfileBioSection: FC<PublicProfileBioSectionProps> = ({
  user,
  profile,
  updatePublicBioData,
  canChange = false,
  taxonomies,
  getTaxonomies,
}: PublicProfileBioSectionProps) => {
  const [modalOpen, setModalOpen] = useState(false)
  const defaultValues: PublicUserUpdateBioType = {
    publicOccupation: user?.publicOccupation || null,
    jobSearchStatusId: user?.jobSearchStatus?.id || '',
    shortDescription: user?.shortDescription || '',
  }
  const openEdit = () => {
    getTaxonomies()
    formik.resetForm({
      values: {
        ...defaultValues,
      },
    })
    setModalOpen(true)
  }

  const {formatMessage} = useIntl()
  const isAdmin = !!localStorage.getItem('impersonatedUserId')

  const formik = useFormik({
    initialValues: defaultValues,
    validationSchema: Validation,
    validateOnBlur: false,
    validateOnChange: false,
    onSubmit: (values) => {
      let {publicOccupation, jobSearchStatusId} = values
      let withoutShortDescription: PublicUserUpdateBioType = {
        publicOccupation,
        jobSearchStatusId,
      }
      isAdmin
        ? updatePublicBioData(values)
        : updatePublicBioData(withoutShortDescription)
    },
  })

  const setTypeAheadField = (name: string, values: BaseTaxonomyType[]) => {
    formik.setFieldValue(name, values[0])
  }

  const memoizedOccupationDropdown = useMemo(() => {
    return (
      <TypeAheadImproved
        name="publicOccupation"
        defaultValue={
          formik.values.publicOccupation ? [formik.values.publicOccupation] : []
        }
        theme="modal"
        items={taxonomies.occupations?.data}
        maxNumSelections={1}
        placeholder={pt.placeholderJobTitle}
        setValue={setTypeAheadField}
        setTouched={formik.getFieldHelpers('publicOccupation').setTouched}
        error={
          formik.submitCount
            ? formik.errors.publicOccupation
              ? formik.errors.publicOccupation
              : undefined
            : undefined
        }
        selectNonExisting
      />
    )
  }, [
    formik.values.publicOccupation,
    taxonomies.occupations.data,
    formik.errors?.publicOccupation,
    formik.submitCount,
  ])

  useApiError(formik.setFieldError, profile.error)
  useEffect(() => {
    if (!profile.loading && !profile.error) setModalOpen(false)
  }, [profile.loading])

  return (
    <>
      <div className={styles.top}>
        <div className={styles.bg}></div>
        {canChange && (
          <button className={styles.editBtn} onClick={openEdit} type="button">
            <EditOutlined className={styles.editIcon} />
          </button>
        )}
      </div>

      <div className={styles.main}>
        <div className={styles.avatarHolder}>
          <AccountCircleOutlined />
        </div>
        <div className={styles.usernamePlaceholder}>
          <FormattedMessage id={pt.anonymousCandidate} />
        </div>
        <div className={styles.jobTitle}>
          <span className={styles.toolHolder}>
            <Build />
          </span>
          {user?.publicOccupation?.translation}
        </div>
        {user?.age && (
          <ItemTextWithIcon
            hidePrimaryOnMobile
            primaryText={pt.age}
            withColon
            icon={CakeOutlined}
            secondaryText={`${user?.age} ${formatMessage({id: pt.yearsOld})}`}
          />
        )}
        {user?.jobCategory?.translation && (
          <ItemTextWithIcon
            hidePrimaryOnMobile
            primaryText={pt.occupationalField}
            withColon
            icon={WorkOutline}
            secondaryText={user?.jobCategory?.translation}
          />
        )}
        {user?.jobSearchStatus && (
          <ItemTextWithIcon
            hidePrimaryOnMobile
            primaryText={pt.jobSearchStatus}
            withColon
            icon={UpdateOutlined}
            secondaryText={user?.jobSearchStatus.translation}
          />
        )}
        {user?.shortDescription && (
          <ItemTextWithIcon
            hidePrimaryOnMobile
            primaryText={pt.shortDescription}
            withColon
            icon={DescriptionOutlined}
            secondaryText={user?.shortDescription}
          />
        )}
      </div>
      <Modal
        isOpen={modalOpen}
        onAfterClose={() => {
          setModalOpen(false)
        }}
        title={pt.editAboutMe}
        icon={EditOutlined}
        ariaHideApp={false}
        className={styles.modal}
        theme="desktopFlex"
        Footer={
          <SubmitFooter
            onSubmit={formik.handleSubmit}
            onCancel={() => {
              setModalOpen(false)
            }}
            theme="modalProfileSubmit"
          />
        }
      >
        <div className={styles.aboutMeModalContent}>
          <Title text={pt.jobTitle} theme="modal" />
          {memoizedOccupationDropdown}
          <Hr theme="modalSmall" />
          <Title text={ot.titleJobSearchStatus} theme="modal" />
          <Dropdown
            name="jobSearchStatusId"
            items={taxonomies.jobSearchStatuses.data}
            setValue={formik.setFieldValue}
            value={formik.values.jobSearchStatusId}
            error={formik.errors.jobSearchStatusId}
            theme="white"
          />
          {isAdmin && (
            <>
              <Hr theme="modalSmall" />
              <Title text={pt.shortDescription} theme="modal" />
              <TextArea
                name="shortDescription"
                value={`${formik.values.shortDescription}`}
                onChange={formik.handleChange}
                placeholderText={pt.placeholderAboutMe}
                theme="modal"
                className={classnames(
                  styles.editAboutMeTxt,
                  styles.editAboutMeTxtArea
                )}
                error={
                  formik.touched.shortDescription ||
                  formik.values.shortDescription !== ''
                    ? formik.errors.shortDescription
                    : undefined
                }
                maximumCharactersNumber={1000}
                type="letterCalculator"
              />
            </>
          )}
        </div>
      </Modal>
    </>
  )
}
