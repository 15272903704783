import {
  CakeOutlined,
  LocationOnOutlined,
  MailOutline,
  PhoneIphone,
} from '@mui/icons-material'

import styles from './BasicCandidateInformations.module.scss'
import {FormattedDate} from 'react-intl'
import {SeekerProfileType} from 'Interfaces'
interface BasicCandidateInformationsProps {
  candidate: SeekerProfileType
}

export const BasicCandidateInformations: React.FC<
  BasicCandidateInformationsProps
> = ({candidate}) => {
  return (
    <div className={styles.candidateInformationsContainer}>
      {/*Basic informations data section*/}
      <div className={styles.namePositionContainer}>
        <h2>{`${candidate?.firstName ? candidate?.firstName : ''} ${
          candidate?.lastName ? candidate?.lastName : ''
        }`}</h2>
        <p>{candidate?.publicOccupation?.translation}</p>
      </div>
      <div className={styles.basicInfoContainer}>
        {candidate?.dateOfBirth && (
          <div className={styles.iconTextWrapper}>
            <div className={styles.iconWrapper}>
              <CakeOutlined className={styles.cakeIcon} />
            </div>
            <FormattedDate
              value={candidate.dateOfBirth}
              year="numeric"
              month="long"
              day="numeric"
            />
          </div>
        )}
        {(candidate?.streetAddress ||
          candidate?.postalCode ||
          candidate?.homeTown?.translation) && (
          <div className={styles.iconTextWrapper}>
            <div className={styles.iconWrapper}>
              <LocationOnOutlined className={styles.locationIcon} />
            </div>
            <p>
              {`${candidate?.streetAddress}, `} {candidate?.postalCode}{' '}
              {candidate?.homeTown?.translation}
            </p>
          </div>
        )}
        {candidate?.email && (
          <div className={styles.iconTextWrapper}>
            <div className={styles.iconWrapper}>
              <MailOutline className={styles.mailIcon} />
            </div>
            <p>{candidate?.email}</p>
          </div>
        )}
        {candidate?.phone && (
          <div className={styles.iconTextWrapper}>
            <div className={styles.iconWrapper}>
              <PhoneIphone className={styles.cakeIcon} />
            </div>
            <p>{candidate?.phone}</p>
          </div>
        )}
      </div>
    </div>
  )
}
