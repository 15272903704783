export const employerHomePage = {
  'employerHomePage.buttonLandingTxt': 'CONTACT US',
  'employerHomePage.titleLandingTxt': 'YOU ARE ONLY AS SUCCESSFUL AS ',
  'employerHomePage.yellowLandingTxt': 'YOUR TEAM',
  'employerHomePage.paragraphFirst':
    'Don`t waste precious time searching for candidates – make sure you only interview the right ones.',
  'employerHomePage.paragraphSecond':
    'Preferenca gives you immediate access to a database of verified candidates who are currently open for new employment.',

  'employerHomePage.titleSteps': 'WHY HIRE WITH',
  'employerHomePage.yellowTxtSteps': 'PREFERENCA?',
  'employerHomePage.subTitleStepsFirst': 'TOP-OF-THE-LINE',
  'employerHomePage.subTitleStepsBreakFirst': 'TALENT',
  'employerHomePage.subTitleStepsFirstParagraph':
    'Employ the best people in their field ready to tackle new challenges',

  'employerHomePage.subTitleStepsSecond': 'VERIFIED',
  'employerHomePage.subTitleStepsBreakSecond': 'CANDIDATES',
  'employerHomePage.subTitleStepsSecondParagraph':
    'We have already conducted initial interviews and confirmed their competencies',

  'employerHomePage.subTitleStepsThird': 'EASIER AND FASTER RECRUITMENT',
  'employerHomePage.subTitleStepsThirdParagraph':
    'We help you with a fast and smooth transition to a new workplace',

  'employerHomePage.howDoesItWorkTitlePartOne': 'HOW',
  'employerHomePage.howDoesItWorkTitlePartTwo': 'DOES IT WORK?',
  'employerHomePage.howDoesItWorksubTitleFirst':
    'Accurate insight into the candidate`s demonstrated skills and competences',
  'employerHomePage.howDoesItWorksubTitleSecond': 'The candidate`s wishes',
  'employerHomePage.howDoesItWorksubTitleThird': 'Quick and easy recruitment',
  'employerHomePage.howDoesItWorksubTitleFourth':
    'Control of recruitment costs',
  'employerHomePage.howDoesItWorkParagraphFirst':
    'We test the candidate`s knowledge and check the references for you.',
  'employerHomePage.howDoesItWorkParagraphSecond':
    'We facilitate negotiations by helping you understand their salary expectations and working conditions requirements.',
  'employerHomePage.howDoesItWorkParagraphThird':
    'We save you hours, days, and months, by significantly shortening the recruitment process and providing professional support to the candidate in their transition to a new job.',
  'employerHomePage.howDoesItWorkParagraphFourth':
    'We adapt to your employment preferences and offer models that meet the needs of both small and large businesses.',
}
