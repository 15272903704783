import {
  CompanyBenefitsSaveType,
  CompanyBenefitsType,
  WelcomeMessageDataFormikType,
  WelcomeMessageDataType,
  WelcomeMessageRequestPayloadType,
  WelcomeMessageTranslatedTextPayloadType,
} from 'Interfaces'

export const packBenefitsData = (
  data: CompanyBenefitsType
): CompanyBenefitsSaveType => ({
  benefitsText: data.benefitsText,
  customBenefitsTextHtml: data.customBenefitsTextHtml,
  additionalBenefitIds: data.additionalBenefits.map((benefit) => benefit.id),
})

const mapTranslatedTexts = (welcomeMessage: WelcomeMessageDataType) => {
  return welcomeMessage.translatedTexts.map((translatedText) => ({
    languageId: translatedText.language.id,
    visibility: translatedText.visibility,
    welcomeTitle: translatedText.welcomeTitle,
    welcomeText: translatedText.welcomeText,
  }))
}

export const packWelcomeMessageValues = (
  formikValues: WelcomeMessageDataFormikType
): WelcomeMessageRequestPayloadType[] => {
  return formikValues.welcomeMessages.map(
    (welcomeMessage: WelcomeMessageDataType) => ({
      jobCategoryId: welcomeMessage.jobCategory.id,
      translatedTexts: mapTranslatedTexts(welcomeMessage),
    })
  )
}

export const packWelcomeMessagesUpdateValues = (
  formikValues: WelcomeMessageDataFormikType
): WelcomeMessageRequestPayloadType[] => {
  const formattedMessagesRequestData: WelcomeMessageRequestPayloadType[] = []

  // First message = data to work with in formattedMessagesRequestData - doesn't exist
  for (const welcomeMessage of formikValues.welcomeMessages) {
    const existingJobCategoryInFormattedMessages =
      formattedMessagesRequestData.find((formattedMsg) => {
        return formattedMsg.jobCategoryId === welcomeMessage.jobCategory.id
      })
    const indexOfExistingJobCategoryMessage =
      formattedMessagesRequestData.findIndex((formattedMsg) => {
        return formattedMsg.jobCategoryId === welcomeMessage.jobCategory.id
      })

    if (formattedMessagesRequestData.length === 0 && welcomeMessage.id) {
      const formattedMessageData = {
        id: welcomeMessage.id,
        jobCategoryId: welcomeMessage.jobCategory.id,
        translatedTexts: mapTranslatedTexts(welcomeMessage),
      }
      formattedMessagesRequestData.push(formattedMessageData)
      continue
    }

    // 1+ Formatted Messages = Messages exist in formatted object
    if (formattedMessagesRequestData.length > 0) {
      // Same Job category already exists with other lang
      // Add additional translated text to existing message
      if (existingJobCategoryInFormattedMessages) {
        const newEntryTranslatedText: WelcomeMessageTranslatedTextPayloadType[] =
          mapTranslatedTexts(welcomeMessage)

        // Existing translated texts + all new
        formattedMessagesRequestData[
          indexOfExistingJobCategoryMessage
        ].translatedTexts = [
          ...formattedMessagesRequestData[indexOfExistingJobCategoryMessage]
            .translatedTexts,
          ...newEntryTranslatedText,
        ]
      }

      // New message category - doesn't exist already - add as new to the payload array
      if (!existingJobCategoryInFormattedMessages?.jobCategoryId) {
        const formattedMessageData = {
          id: welcomeMessage.id,
          jobCategoryId: welcomeMessage.jobCategory.id,
          translatedTexts: mapTranslatedTexts(welcomeMessage),
        }
        formattedMessagesRequestData.push(formattedMessageData)
      }
    }
  }

  return formattedMessagesRequestData
}
